import * as React from 'react';
import { useState } from 'react';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import Routes from '../../../config/Routes';
import * as GetSelfStudyConfigurationHandler from '../../../handlerModels/GetSelfStudyConfigurationHandler';
import * as GetSelfStudyResponsesHandler from '../../../handlerModels/GetSelfStudyResponsesHandler';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import SelfStudyAggregateReportDataTableDTO from '../../../models/SelfStudyAggregateReportDataTableDTO';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import SelfStudySectionComponentType from '../../../consts/SelfStudySectionComponentType';
import SelfStudySectionTemplateDTO from '../../../models/SelfStudySectionTemplateDTO';
import SelfStudySectionComponentTemplateDTO from '../../../models/SelfStudySectionComponentTemplateDTO';
import Guid from '../../../utils/Guid';
import SelfStudyTemplateApiService from '../../../api/SelfStudyTemplateApiService';
import SelfStudyTemplateDTO from '../../../models/SelfStudyTemplateDTO';
import * as GetSelfStudiesHandler from '../../../handlerModels/GetSelfStudiesHandler';
import { Skeleton } from 'antd';


interface SelfStudyAggregateDataTableProps {
  academicYear?: number;
  selectedDistrict: DistrictDTO | null;
  selectedRPP: RegionalPlanningPartnerDTO | null;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  isPublic?: boolean;
  districtId?: string;
  fromAcademicDashboard?: boolean;
}

function SelfStudyAggregateDataTable(props: SelfStudyAggregateDataTableProps) {
  let _dataTable: DataTable<SelfStudyAggregateReportDataTableDTO> | undefined = undefined;

  const [, setSelfStudyResponseTypes] = useState([] as TableFilterOption[]);
  const [selfStudyResponses, setSelfStudyResponses] = useState([] as SelfStudyResponseDTO[]);
  const [selfStudyTemplate, setSelfStudyTemplates] = useState({} as SelfStudyTemplateDTO);
  const [institutionOptions, setInstitutionOptions] = useState([] as TableFilterOption[]);
  const [selfStudies, setSelfStudies] = useState([] as SelfStudyDTO[]);
  const [, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const loaders = [];

    TableFilterUtil.SelfStudyResponseTypes()
      .then(x => {
        setSelfStudyResponseTypes(x);
      });
    TableFilterUtil.Institutions().then(x => setInstitutionOptions(x));

    triggerRefresh();
    loaders.push(loadSelfStudyTemplate());
    loaders.push(loadSelfStudyResponses());
    loaders.push(loadSelfStudies());

    Promise.all(loaders).then(() => {
      setLoading(false);
      triggerRefresh();
    });
  }, []);

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const getColumnDefinitions = () => {
    const column = [
      {
        title: 'Regional Planning Partner',
        dataIndex: SelfStudyAggregateReportDataTableDTO.rpp,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
          return row.rpp;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Community College',
        dataIndex: SelfStudyAggregateReportDataTableDTO.communityCollege,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
          return row.communityCollege;
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: institutionOptions
      },
      {
        title: 'District',
        dataIndex: SelfStudyAggregateReportDataTableDTO.district,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
          return row.district;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Service Area',
        dataIndex: SelfStudyAggregateReportDataTableDTO.serviceArea,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
          return row.serviceArea;
        },
        filterType: FilterType.Text
      },
      {
        title: 'CIP Number',
        dataIndex: SelfStudyAggregateReportDataTableDTO.cipNumber,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
          return row.cipNumber;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Program',
        dataIndex: SelfStudyAggregateReportDataTableDTO.program,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
          return row.program;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Submitter',
        dataIndex: SelfStudyAggregateReportDataTableDTO.submitter,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
          return row.submitter;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Review Year',
        dataIndex: SelfStudyAggregateReportDataTableDTO.reviewYear,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
          return row.reviewYear + ' ' + row.reviewType;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Career Academy',
        dataIndex: SelfStudyAggregateReportDataTableDTO.careerAcademy,
        sorter: true,
        width: 50,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: TableFilterUtil.GetTrueOrFalse()
      }
    ] as DataTableColumnProps<any>[];

    selfStudyTemplate.selfStudySectionTemplates?.forEach(x => {
      const components: any = [];
      x.selfStudySectionComponentTemplates?.forEach(y => {
        switch (y.selfStudySectionComponentTypeId) {
          case SelfStudySectionComponentType.INSTRUCTIONS:
            return getInstructionComponent(components, x, y)
          case SelfStudySectionComponentType.RANKINGQUESTION:
            return getRankingQuestionComponent(components, x, y)
          case SelfStudySectionComponentType.PREVIOUSGOALS:
            return getPreviousGoalComponent(components, x, y)
          case SelfStudySectionComponentType.NEWGOALS:
            return getNewGoalComponent(components, x, y)
          case SelfStudySectionComponentType.FREETEXTQUESTION:
            return getFreeTextComponent(components, x, y)
          case SelfStudySectionComponentType.PROGRAMOVERVIEW:
            return getProgramOverviewComponent(components, x, y)
          case SelfStudySectionComponentType.CONSULTANTREVIEW:
            return getConsultantReview(components, x, y)
        }
      });

      column.push({
        title: x.title,
        dataIndex: SelfStudyDTO.id,
        width: 50,
        children: components as any,
      })
    });

    return column;
  };

  const getComponent = (selfStudyResponseId: string, sectionTemplate: SelfStudySectionTemplateDTO, componentTemplate: SelfStudySectionComponentTemplateDTO) => {
    const selfStudyRepsonse = selfStudyResponses.find(x => x.id == selfStudyResponseId);
    const scenario = selfStudyRepsonse?.selfStudyResponseProgramOverview?.secondaryProgramScenarioId;
    const section = selfStudyRepsonse?.selfStudySectionResponses?.find(z => z.selfStudySectionTemplateId == sectionTemplate.id && (sectionTemplate.selfStudySectionTemplateSecondaryProgramScenarios?.findIndex(a => a == scenario) > -1));
    const component = section?.selfStudySectionComponentResponses?.find(z => z.selfStudySectionComponentTemplateId == componentTemplate.id && (componentTemplate.selfStudySectionComponentScenarios?.findIndex(a => a == scenario) > -1))

    return component;
  }

  const getRankingQuestionComponent = (components: any[], sectionTemplate: SelfStudySectionTemplateDTO, componentTemplate: SelfStudySectionComponentTemplateDTO) => {
    components.push({
      title: componentTemplate.rankingQuestionIdentifier ?? (sectionTemplate.order + 1) + '.' + (componentTemplate.order + 1),
      render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
        const component = getComponent(row.selfStudyResponseId ?? Guid.Empty(), sectionTemplate, componentTemplate);
        return component?.rating ?? 0;
      }
    })
  }

  const getFreeTextComponent = (components: any[], sectionTemplate: SelfStudySectionTemplateDTO, componentTemplate: SelfStudySectionComponentTemplateDTO) => {
    components.push({
      title: componentTemplate.rankingQuestionIdentifier ?? (sectionTemplate.order + 1) + '.' + (componentTemplate.order + 1),
      render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
        const component = getComponent(row.selfStudyResponseId ?? Guid.Empty(), sectionTemplate, componentTemplate);
        return component?.responseText ?? '';
      }
    })
  }

  const getInstructionComponent = (components: any[], sectionTemplate: SelfStudySectionTemplateDTO, componentTemplate: SelfStudySectionComponentTemplateDTO) => {
    components.push({
      title: componentTemplate.rankingQuestionIdentifier ?? (sectionTemplate.order + 1) + '.' + (componentTemplate.order + 1),
      render: () => {
        return '';
      }
    })
  }

  const getProgramOverviewComponent = (components: any[], sectionTemplate: SelfStudySectionTemplateDTO, componentTemplate: SelfStudySectionComponentTemplateDTO) => {
    components.push({
      title: componentTemplate.rankingQuestionIdentifier ?? (sectionTemplate.order + 1) + '.' + (componentTemplate.order + 1),
      children: [
        {
          title: 'Scenario',
          render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
            return selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyResponseProgramOverview?.secondaryProgramScenario?.title;
          },
        }],

    })
  }

  const getConsultantReview = (components: any[], sectionTemplate: SelfStudySectionTemplateDTO, componentTemplate: SelfStudySectionComponentTemplateDTO) => {
    components.push(
      {
        title: 'Status',
        render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
          let status = selfStudies.find(x => x.id == selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyId)?.secondaryProgram?.secondaryProgramReviewHistories?.find(x => x.reviewYear == props.academicYear)?.secondaryProgramStatusType?.name;
          if (!status) {
            status = selfStudies.find(x => x.id == selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyId)?.secondaryProgram?.secondaryProgramReviewHistories?.find(x => x.nextReviewYear == props.academicYear)?.secondaryProgramStatusType?.name
          }
          return status ?? 'Unknown';
        }
      }
    );

    componentTemplate.selfStudyResponseTemplateConsultantReviewQuestions?.forEach(x =>
      components.push(
        {
          title: x.question,
          children: [
            {
              title: 'Answer',
              render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
                const component = getComponent(row.selfStudyResponseId ?? Guid.Empty(), sectionTemplate, componentTemplate);
                return (component?.selfStudyResponseConsultantReviewQuestionDTOs ?? [])[x.order]?.consultantAnswer == true ? 'Yes' : 'No';
              },
            },
            {
              title: 'Comment',
              render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
                const component = getComponent(row.selfStudyResponseId ?? Guid.Empty(), sectionTemplate, componentTemplate);
                return (component?.selfStudyResponseConsultantReviewQuestionDTOs ?? [])[x.order]?.comment;
              },
            }
          ]
        },
      )
    )
  }

  const getPreviousGoalComponent = (components: any[], sectionTemplate: SelfStudySectionTemplateDTO, componentTemplate: SelfStudySectionComponentTemplateDTO) => {
    for (let i = 0; i < (componentTemplate?.numberOfPreviousGoals ?? 0); i++) {
      components.push({
        title: 'Goal ' + (i + 1),
        children: [
          {
            title: 'Section',
            render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
              return (selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyResponsePreviousGoals ?? [])[i]?.section;
            },
          },
          {
            title: 'Action Steps and Timeline',
            render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
              return (selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyResponsePreviousGoals ?? [])[i]?.actionStepsAndTimeline;
            },
          },
          {
            title: 'Measure of Success',
            render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
              return (selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyResponsePreviousGoals ?? [])[i]?.measureOfSuccess;
            },
          },
          {
            title: 'Result',
            render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
              return (selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyResponsePreviousGoals ?? [])[i]?.results;
            },
          }],
      })
    }
  }

  const getNewGoalComponent = (components: any[], sectionTemplate: SelfStudySectionTemplateDTO, componentTemplate: SelfStudySectionComponentTemplateDTO) => {
    for (let i = 0; i < (componentTemplate?.numberOfNewGoals ?? 0); i++) {
      components.push({
        title: 'Goal ' + (i + 1),
        children: [
          {
            title: 'Section',
            render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
              return (selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyResponseNewGoals ?? [])[i]?.section;
            },
          },
          {
            title: 'Action Steps and Timeline',
            render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
              return (selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyResponseNewGoals ?? [])[i]?.actionStepsAndTimeline;
            },
          },
          {
            title: 'Measure of Success',
            render: (data: string, row: SelfStudyAggregateReportDataTableDTO) => {
              return (selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudyResponseNewGoals ?? [])[i]?.measureOfSuccess;
            },
          }],

      })
    }
  }

  const loadSelfStudyTemplate = () => {
    return SelfStudyTemplateApiService.getSelfStudy(props.academicYear ?? 0)
      .then((results: GetSelfStudyConfigurationHandler.Result) => {
        if (results) {
          setSelfStudyTemplates(results.selfStudy ?? SelfStudyTemplateDTO.create());
        }
      })
      .catch(() => {
        setError(true);
      });
  }

  const loadSelfStudies = () => {
    const request = GetSelfStudiesHandler.Request.create({
      academicYear: props.academicYear,
    });

    return SelfStudyApiService.getSelfStudies(request)
      .then((results: GetSelfStudiesHandler.Result) => {
        if (results) {
          setSelfStudies(results.selfStudies ?? []);
        }
      })
      .catch(() => {
        setError(true);
      });
  }

  const loadSelfStudyResponses = () => {
    const request = GetSelfStudyResponsesHandler.Request.create({
      academicYear: props.academicYear
    });

    return SelfStudyApiService.getSelfStudyResponses(request)
      .then((results: GetSelfStudyResponsesHandler.Result) => {
        if (results.selfStudyResponses) {
          setSelfStudyResponses(results.selfStudyResponses);
        }
      });
  }

  const selfStudyResponseAggregateExport = () => {
    window.open(Routes.generateFull(Routes.SELF_STUDY_AGGREGATE_REPORT, {}, { academicYear: props.academicYear }), '_blank')
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());

  actionButtons.push(DataTableButtonUtil.Default('Export', selfStudyResponseAggregateExport));
  if (loading) {
    return <Skeleton active />
  }
  else {
    return (<>

      <DataTable
        // @ts-ignore
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => SelfStudyApiService.getSelfStudyAggregateReportTableData(
            requestState,
            props.academicYear == 0 ? 0 : props.academicYear ?? 0)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'self_study_list' }} />
    </>
    );
  }
}

export default SelfStudyAggregateDataTable;
