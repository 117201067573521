import { Card, Col, PageHeaderProps, Row } from 'antd';
import * as React from 'react';
import ReactDOM from 'react-dom';
import OpeningAcademicYearSteps from '../../components/displays/secondary/OpeningAcademicYearSteps';
import { ColumnWidths } from '../../config/ColumnWidths';

interface AcademicYearPortalProps extends PageHeaderProps {
  pageTitle?: string;
  showBack?: boolean;
  loading?: boolean;
  currentStep: number;
}

interface AcademicYearPortalState { }

class AcademicYearPortal extends React.Component<AcademicYearPortalProps, AcademicYearPortalState> {
  portalRoot: any;
  el: HTMLDivElement;
  constructor(props: AcademicYearPortalProps) {
    super(props);
    this.el = document.createElement('div');

    this.state = {};
  }

  componentDidMount = () => {
    this.portalRoot = document.getElementById('academicYearCardPortal');
    if (this.portalRoot) {
      this.portalRoot.appendChild(this.el);
    }
  };

  componentWillUnmount = () => {
    if (this.portalRoot) {
      this.portalRoot.removeChild(this.el);
    }
  };

  render() {
    const content = (
      <React.Fragment>
        <Card title={this.props.title}>
          <Row>
            <Col {...ColumnWidths.ONE_QUARTER}>
              <OpeningAcademicYearSteps currentStep={this.props.currentStep} />
            </Col>
            <Col {...ColumnWidths.HALF}>
              {this.props.children}
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );

    return ReactDOM.createPortal(content, this.el);
  }
}

export default AcademicYearPortal;
