import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import SecondaryCourseDetailsForm from '../forms/secondary/SecondaryCourseDetailsForm';


interface SecondaryCourseDetailsModalProps extends ModalProps {
  secondaryCourseId: string;
  academicYear: number;
  onSave?: () => void;
}

function SecondaryCourseDetailsModal(props: SecondaryCourseDetailsModalProps) {
  const _secondaryCourseDetailsRef = React.createRef<SecondaryCourseDetailsForm>();

  const isNew = props.secondaryCourseId == Guid.Empty();

  const submitSaveSecondaryCourse = () => {
    _secondaryCourseDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New Course' : 'Edit Course'}
      okText='Save'
      onOk={submitSaveSecondaryCourse}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <SecondaryCourseDetailsForm
        ref={_secondaryCourseDetailsRef}
        fromDataReview={true}
        secondaryCourseId={props.secondaryCourseId ?? Guid.Empty}
        isEditing={true}
        onSave={props.onSave}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default SecondaryCourseDetailsModal;