// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class WorkItemType {
    public static readonly REVIEWCHANGEREQUEST: number = 1;
    public static readonly CHANGEREQUESTDRAFT: number = 2;
    public static readonly APPROVALREDIRECT: number = 3;
    public static readonly RESPONSETOSELFSTUDY: number = 4;
    public static readonly SUBMITSELFSTUDY: number = 5;
    public static readonly ASSIGNDISTRICTUSER: number = 6;
    public static readonly REVISESELFSTUDY: number = 7;
    public static readonly ASSIGNRPPCONSULTANT: number = 8;
    public static readonly ASSIGNDECONSULTANT: number = 9;
}
