import { DownOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton, Space } from 'antd';
import * as React from 'react';
import AnnouncementsApiService from '../api/AnnouncementsApiService';
import * as GetAnnouncementsHandler from '../handlerModels/GetAnnouncementsHandler';
import AnnouncementDTO from '../models/AnnouncementDTO';
import BaseFormProps from '../redux/bases/BaseFormProps';
import BaseFormState from '../redux/bases/BaseFormState';
import AnnouncementDisplay from './displays/AnnouncementDisplay';

interface AnnouncementListingState extends BaseFormState {
  fetchAmount: number;
  announcements: AnnouncementDTO[];
  loadedAmount: number;
  actionAvailable: boolean;
}

interface AnnouncementListingProps extends BaseFormProps {
  fetchAmount?: number;
  includeShown?: boolean;
  actionButtonText?: any;
  actionButtonIcon?: any;
  actionButtonAction?: () => void;
  actionAvailable?: boolean;
}

class AnnouncementListing extends React.Component<AnnouncementListingProps, AnnouncementListingState> {

  constructor(props: AnnouncementListingProps) {
    super(props);

    this.state = {
      announcements: [],
      loadedAmount: 0,
      actionAvailable: false,
      fetchAmount: props.fetchAmount ?? 5
    };
  }

  componentDidMount() {
    this.loadAnnouncements();

    this.setState({
      fetchAmount: this.props.fetchAmount ?? 5,
      actionAvailable: this.props.actionAvailable ?? false,
    });
  }

  public loadAnnouncements = () => {
    this.setState({ loading: true });
    const request = GetAnnouncementsHandler.Request.create({
      includeShown: this.props.includeShown,
      fetchAmount: this.props.fetchAmount,
      loadedAmount: this.state.loadedAmount,
    });

    return AnnouncementsApiService.getAnnouncementsForUser(request)
      .then((results: GetAnnouncementsHandler.Result) => {
        const toAdd = results.announcement ?? [];
        const announcements = this.state.announcements;
        announcements.pushAll(toAdd);

        if (results.announcement) {
          this.setState({
            announcements: announcements,
            actionAvailable: this.props.actionAvailable ? true : toAdd?.length >= this.state.fetchAmount,
            loadedAmount: this.state.loadedAmount + results.announcement.length ?? 0,
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space direction="vertical">
        {this.renderAnnouncements()}
        {this.renderAction()}
      </ Space>
    );
  }

  renderAction() {
    if (this.state.actionAvailable) {
      return (
        <Button
          className="align-center"
          shape="round"
          icon={this.props.actionButtonIcon ? this.props.actionButtonIcon : <DownOutlined />}
          size="small"
          onClick={this.props.actionButtonAction ? this.props.actionButtonAction : this.loadAnnouncements}
          disabled={!this.state.actionAvailable}>
          {this.props.actionButtonText ? this.props.actionButtonText : 'Load More'}
        </Button>
      );
    }
  }

  renderAnnouncements() {
    if (this.state.announcements.length > 0) {
      return this.state.announcements.map(x => <AnnouncementDisplay key={x.id} announcement={x} />);
    }

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="There are no announcements at this time."></Empty>
  }
}

export default AnnouncementListing;
