import * as React from 'react';
import InstitutionCourseApiService from '../../../api/InstitutionCoursesApiService';
import Routes from '../../../config/Routes';
import InstitutionCourseDataTableDTO from '../../../models/InstitutionCourseDataTableDTO';
import TermDTO from '../../../models/TermDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import { TermYear } from '../../../utils/TermYearUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import { Skeleton } from 'antd';

interface InstitutionCoursesDataTableProps {
  institutionId: string;
  triggerUpdate?: () => void;
  termYear: TermYear | null;
  useSelectedInstitution?: boolean;
}

interface InstitutionCoursesDataTableState extends BaseDataTableState<InstitutionCourseDataTableDTO> {
  addCourse: boolean;
  terms: TermDTO[];
  statuses: TableFilterOption[];
  institutions: TableFilterOption[];
  categories: TableFilterOption[];
}

class InstitutionCoursesDataTable extends React.Component<InstitutionCoursesDataTableProps, InstitutionCoursesDataTableState> {
  private _dataTable: DataTable<InstitutionCourseDataTableDTO> | undefined;

  constructor(props: InstitutionCoursesDataTableProps) {
    super(props);
    this.state = ({
      addCourse: false,
      categories: [],
      statuses: [],
      terms: [],
      institutions: []
    });
  }

  private loadTerms = () => {
    return LookupsUtil.getAll<TermDTO>(TermDTO.className)
      .then((results: TermDTO[]) => {
        if (results) {
          this.setState({ terms: results ?? [] });
        }
      });
  }

  componentDidMount() {
    TableFilterUtil.StatusTypes()
      .then(x => {
        this.setState({ statuses: x })
      });

    TableFilterUtil.Institutions()
      .then(x => {
        this.setState({ institutions: x })
      });

    this.loadTerms();

    TableFilterUtil.Categories()
      .then(x => {
        this.setState({ categories: x })
      });
    this.setState({ loading: false })
  }

  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }


  private getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Title',
        dataIndex: InstitutionCourseDataTableDTO.title,
        render: (data: string, row: InstitutionCourseDataTableDTO) => {
          return row.title;
        },
        width: 500,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Discipline',
        dataIndex: InstitutionCourseDataTableDTO.discipline,
        render: (data: string, row: InstitutionCourseDataTableDTO) => {
          return row.discipline?.display;
        },
        sorter: true,
        width: 400,
        filterType: FilterType.Text
      },
      {
        title: 'Catalog Number',
        dataIndex: InstitutionCourseDataTableDTO.catalogNumber,
        render: (data: string, row: InstitutionCourseDataTableDTO) => {
          return row.catalogNumber;
        },
        sorter: true,
        width: 125,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];

    if ((!this.props.institutionId || this.props.institutionId == Guid.Empty() || !this.props.useSelectedInstitution)) {
      columns.pushAll([
        {
          title: 'Community College',
          dataIndex: InstitutionCourseDataTableDTO.institutionId,
          render: (data: string, row: InstitutionCourseDataTableDTO) => {
            return row.institutionName;
          },
          sorter: true,
          width: 125,
          filterType: FilterType.DropdownMulti,
          dropdownFilterOptions: this.state.institutions
        }]);
    }

    columns.pushAll([
      {
        title: 'Category',
        dataIndex: InstitutionCourseDataTableDTO.category,
        sorter: true,
        width: 125,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.categories
      },
      {
        title: 'Status',
        dataIndex: InstitutionCourseDataTableDTO.status,
        render: (data: string, row: InstitutionCourseDataTableDTO) => {
          return row.status?.name;
        },
        width: 100,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.statuses
      }
    ]);

    return columns;
  };

  private openInstitutionCourse = (record: InstitutionCourseDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.COURSE_DETAILS, { institutionCourseId: record.id ?? Guid.Empty() }, {}));
    }
  }

  render() {
    if (this.state.loading == undefined || this.state.loading) {
      return <Skeleton />
    }

    return (
      <>
        {this.renderDataTable()}
      </>
    );
  }


  renderDataTable() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openInstitutionCourse}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => InstitutionCourseApiService.getInstitutionCourseTableData(requestState, this.props.useSelectedInstitution ? this.props.institutionId : Guid.Empty(), this.props.termYear?.termId ?? Guid.Empty(), this.props.termYear?.year ?? 0)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'institution_list' }} />
    );
  }

}

export default InstitutionCoursesDataTable;
