import { Skeleton } from 'antd';
import * as React from 'react';
import CoursesApiService from '../../../api/CoursesApiService';
import Routes from '../../../config/Routes';
import CourseCourseBlocksDataTableDTO from '../../../models/CourseCourseBlocksDataTableDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface CourseCourseBlocksDataTableProps extends BaseFormProps {
  courseId?: string;
}

interface CourseCourseBlocksDataTableState extends BaseDataTableState<CourseCourseBlocksDataTableDTO>, BaseFormState {
  institutions: InstitutionDTO[];
}

class CourseCourseBlocksDataTable extends React.Component<CourseCourseBlocksDataTableProps, CourseCourseBlocksDataTableState> {
  private _dataTable: DataTable<CourseCourseBlocksDataTableDTO> | undefined;

  constructor(props: CourseCourseBlocksDataTableProps) {
    super(props);

    this.state = {
      institutions: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.institutions || this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Code',
        dataIndex: CourseCourseBlocksDataTableDTO.code,
        width: 125,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Title',
        dataIndex: CourseCourseBlocksDataTableDTO.title,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Course Count',
        dataIndex: CourseCourseBlocksDataTableDTO.courseCount,
        sorter: true,
        width: 125,
      }
    ] as DataTableColumnProps<CourseCourseBlocksDataTableDTO>[];
  };

  private openInstitutionCoureBlock = (record: CourseCourseBlocksDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.INSTITUITION_COURSE_BLOCK_DETAILS, { id: record.id }, {}));
    }
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openInstitutionCoureBlock}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => CoursesApiService.getCourseCourseBlocksTableData(requestState, this.props.courseId ?? Guid.Empty())
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'course_course_block_list' }} />
    );
  }
}

export default CourseCourseBlocksDataTable;
