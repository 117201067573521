import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Alert, Button, Descriptions, Input, InputNumber, message, Modal, Select, Skeleton, Space, Table, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import CoursesApiService from '../../../api/CoursesApiService';
import InstitutionCoursesApiService from '../../../api/InstitutionCoursesApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetCourseDetailsHandler from '../../../handlerModels/GetCourseDetailsHandler';
import * as SaveInstitutionCourseDetailsHandler from '../../../handlerModels/SaveInstitutionCourseDetailsHandler';
import CategoryDTO from '../../../models/CategoryDTO';
import CourseDetailDTO from '../../../models/CourseDetailDTO';
import CourseDTO from '../../../models/CourseDTO';
import CourseRequisiteDTO from '../../../models/CourseRequisiteDTO';
import CourseRequisiteTypeDTO from '../../../models/CourseRequisiteTypeDTO';
import CreditTypeDTO from '../../../models/CreditTypeDTO';
import DisciplineDTO from '../../../models/DisciplineDTO';
import InstitutionCourseCreditDTO from '../../../models/InstitutionCourseCreditDTO';
import InstitutionCourseDetailDTO from '../../../models/InstitutionCourseDetailDTO';
import InstitutionCourseDTO from '../../../models/InstitutionCourseDTO';
import StatusTypeDTO from '../../../models/StatusTypeDTO';
import TermDTO from '../../../models/TermDTO';
import YearTermDTO from '../../../models/YearTermDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import '../../../utils/StringUtil';
import StringUtil from '../../../utils/StringUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import Dropdown from '../../inputs/Dropdown';
import YesNoInput from '../../inputs/YesNoInput';
import CourseSearch from '../../postSecondary/CourseSearch';
import TermInput from '../../TermInput';
import FutureTerm from './FutureTermModal';

interface CourseDetailsFormState extends BaseFormState {
  categories: CategoryDTO[];
  courseId: string;
  course: CourseDTO;
  courseDetails: CourseDetailDTO[];
  selectedCourseDetail: CourseDetailDTO;
  statusTypes: StatusTypeDTO[];
  disciplines: DisciplineDTO[];
  terms: TermDTO[];
  selectedInstitutionCourseDetail: InstitutionCourseDetailDTO;
  selectedDetailId: string;
  creditTypes: CreditTypeDTO[];
  showModal: boolean;
  futureTerm: YearTermDTO;
  courseRequisiteTypes: CourseRequisiteTypeDTO[];
}

interface CourseDetailsFormProps extends BaseFormProps {
  courseId: string;
  readonly?: boolean;
  course?: CourseDTO;
  onSave?: (id: string, institutionCourseId: string) => void;
  institutionId: string;
  selectedInstitutionCourseDetail?: InstitutionCourseDetailDTO;
  selectedCourseDetail?: CourseDetailDTO;
  selectedInstitutionCourse: InstitutionCourseDTO;
}

class CourseDetailsForm extends React.Component<CourseDetailsFormProps, CourseDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _courseRequisiteFormRef = React.createRef<FormInstance>();
  private readonly _courseSearch = React.createRef<CourseSearch>();
  private getProgramAwardFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(CourseDetailDTO.title, {
        required: true,
        name: CourseDetailDTO.title,
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(150)
        ],
      })
      .set(CourseDTO.catalogNumber, {
        required: true,
        name: CourseDTO.catalogNumber,
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.onlyNumericCharacters(),
          ValidationRuleUtil.maxLength(3)
        ],
      })
      .set(CourseDTO.disciplineId, {
        required: true,
        name: CourseDTO.disciplineId,
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(InstitutionCourseDetailDTO.categoryId, {
        required: true,
        name: InstitutionCourseDetailDTO.categoryId,
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(InstitutionCourseDetailDTO.totalMinCredits, {
        required: true,
        name: [index, InstitutionCourseDetailDTO.totalMinCredits],
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(InstitutionCourseDetailDTO.totalMaxCredits, {
        required: true,
        name: [index, InstitutionCourseDetailDTO.totalMaxCredits],
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(InstitutionCourseDetailDTO.variableHours, {
        required: true,
        name: InstitutionCourseDetailDTO.variableHours,
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(CourseDetailDTO.title, {
        required: true,
        name: CourseDetailDTO.title,
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(InstitutionCourseDetailDTO.description, {
        required: true,
        name: InstitutionCourseDetailDTO.description,
        label: 'Description',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(InstitutionCourseDetailDTO.objectives, {
        name: InstitutionCourseDetailDTO.objectives,
        rules: [
          ValidationRuleUtil.maxLength(5000)
        ],
      })
      .set(CourseDTO.install, {
        required: true,
        name: CourseDTO.install,
        rules: [
          ValidationRuleUtil.required(),
          ({ getFieldValue }) => ({ validator: ValidationUtil.termInputYearValidator(this.state.terms, getFieldValue(CourseDetailDTO.retire), CourseDetailDTO.install) }),
          () => ({ validator: ValidationUtil.termAndYearValidator() })
        ],
      })
      .set(CourseDetailDTO.retire, {
        name: CourseDetailDTO.retire,
        rules: [({ getFieldValue }) => ({ validator: ValidationUtil.termInputYearValidator(this.state.terms, getFieldValue(CourseDetailDTO.install), CourseDetailDTO.retire) }),
        () => ({ validator: ValidationUtil.termAndYearValidatorNullable() })]
      })
      .set(CourseRequisiteDTO.courseRequisiteTypeId, {
        required: true,
        name: CourseRequisiteDTO.courseRequisiteTypeId,
        label: 'Course Requisite Type',
        requiredMark: true,
        rules: [ValidationRuleUtil.required()]
      })
  }

  private getCourseRequisiteColumns = () => {
    const columns = [
      {
        title: 'Course Name',
        sorter: false,
        dataIndex: CourseRequisiteDTO.requisiteCourseId,
        render: (data: any, row: any, index: number) => {
          const requisiteCourse = this._formRef.current?.getFieldValue(InstitutionCourseDetailDTO.courseRequisites)[index]
          return requisiteCourse?.requisiteCourse?.currentDetail?.display ?? requisiteCourse?.requisiteCourse?.display;
        }
      },
      {
        title: 'Course Requisite Type',
        dataIndex: CourseRequisiteDTO.courseRequisiteTypeId,
        render: (data: any, row: any, index: number) => {
          const requisiteCourse = this._formRef.current?.getFieldValue(InstitutionCourseDetailDTO.courseRequisites)[index]
          return (
            this.state.courseRequisiteTypes.find(x => x.id == requisiteCourse?.courseRequisiteTypeId)?.name
          );
        }
      },
    ];

    if (this.props.isEditing) {
      columns.pushAll([{
        title: 'Actions',
        dataIndex: 'actions',
        render: (data: any, row: any, index: number) => {
          const requisiteCourse = this._formRef.current?.getFieldValue(InstitutionCourseDetailDTO.courseRequisites)[index]
          return (<>
            <Button type="link" onClick={() => this.editCourseRequisite(requisiteCourse)}><FormOutlined /></Button>
            <Button type="link" onClick={() => this.removeCourseRequisite(requisiteCourse)}><DeleteOutlined /></Button>
          </>
          );
        }
      }]);
    }

    return columns;
  }

  constructor(props: CourseDetailsFormProps) {
    super(props);

    this.state = {
      courseId: Guid.Empty(),
      course: CourseDTO.create({
        installTermId: '',
        retireTermId: '',
        disciplineId: ''
      }),
      selectedCourseDetail: CourseDetailDTO.create({
        installTermId: '',
        retireTermId: '',
      }),
      courseDetails: [],
      statusTypes: [],
      disciplines: [],
      terms: [],
      selectedInstitutionCourseDetail: InstitutionCourseDetailDTO.create(),
      selectedDetailId: Guid.Empty(),
      categories: [],
      creditTypes: [],
      showModal: false,
      futureTerm: YearTermDTO.create(),
      courseRequisiteTypes: []
    };
  }

  componentDidMount() {
    this.fetchData();
    this.setState({ selectedInstitutionCourseDetail: this.props.selectedInstitutionCourseDetail ?? InstitutionCourseDetailDTO.create() }, () => this.resetForm());
  }

  componentDidUpdate(prevProps: CourseDetailsFormProps) {
    if (this.props.courseId && this.props.courseId.isNotEmpty() &&
      this.props.courseId != prevProps.courseId ||
      (this.props.course?.disciplineId != prevProps.course?.disciplineId)) {
      this.fetchData();

    }

    if (this.props.selectedInstitutionCourseDetail != prevProps.selectedInstitutionCourseDetail) {
      this.setState({ selectedInstitutionCourseDetail: this.props.selectedInstitutionCourseDetail ?? InstitutionCourseDetailDTO.create() }, () => this.resetForm());
    }

    if (this.props.selectedCourseDetail != prevProps.selectedCourseDetail) {
      this.setState({ selectedCourseDetail: this.props.selectedCourseDetail ?? CourseDetailDTO.create() })
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];
    if (this.state.statusTypes.length == 0) {
      loaders.push(this.loadStatusTypes());
    }

    if (this.state.disciplines.length == 0) {
      loaders.push(this.loadDisciplines());
    }

    if (this.state.terms.length == 0) {
      loaders.push(this.loadTerms());
    }

    if (this.state.categories.length == 0) {
      loaders.push(this.loadCategories());
    }

    if (this.state.creditTypes.length == 0) {
      loaders.push(this.loadCreditTypes());
    }

    if (!this.state.courseRequisiteTypes || this.state.courseRequisiteTypes.length == 0) {
      loaders.push(this.loadCourseRequisiteType());
    }

    if (this.props.course?.courseDetails) {
      this.setState({
        course: this.props.course,
        selectedCourseDetail: this.props.course.currentDetail ?? CourseDetailDTO.create()
      });
    }
    else if (this.props.courseId != Guid.Empty()) {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadDisciplines = () => {
    return LookupsUtil.getAll<DisciplineDTO>(DisciplineDTO.className)
      .then((results: DisciplineDTO[]) => {
        if (results) {
          this.setState({ disciplines: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCategories = () => {
    return LookupsUtil.getAll<CategoryDTO>(CategoryDTO.className)
      .then((results: CategoryDTO[]) => {
        if (results) {
          this.setState({ categories: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadStatusTypes = () => {
    return LookupsUtil.getAll<StatusTypeDTO>(StatusTypeDTO.className)
      .then((results: StatusTypeDTO[]) => {
        if (results) {
          this.setState({ statusTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTerms = () => {
    return LookupsUtil.getAll<TermDTO>(TermDTO.className)
      .then((results: TermDTO[]) => {
        if (results) {
          this.setState({ terms: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCreditTypes = () => {
    return LookupsUtil.getAll<CreditTypeDTO>(CreditTypeDTO.className)
      .then((results: CreditTypeDTO[]) => {
        if (results) {
          this.setState({ creditTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadExisting = () => {
    return CoursesApiService.getCourseDetails(this.props.courseId)
      .then((results: GetCourseDetailsHandler.Result) => {
        if (results.course?.courseDetails) {
          this.setState({
            course: results.course ?? CourseDTO.create(),
            courseDetails: results.course?.courseDetails ?? [],
            selectedCourseDetail: results.course?.courseDetails[results.course?.courseDetails.length - 1] ?? CourseDetailDTO.create()
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCourseRequisiteType = () => {
    return LookupsUtil.getAll<CourseRequisiteTypeDTO>(CourseRequisiteTypeDTO.className)
      .then((results: CourseRequisiteTypeDTO[]) => {
        if (results) {
          results = results.sort((a, b) => (a.displayOrder ?? '') > (b?.displayOrder ?? '') ? 1 : -1)
          this.setState({ courseRequisiteTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private openModal = () => {
    this.setState({ showModal: true, });
  }

  private cancelChange = () => {
    this.setState({ showModal: false });
  }

  private handleSubmit = (yearTerm?: YearTermDTO) => {
    this.setState({ submitting: true });

    const form = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    let index = 1;
    const credits = form.institutionCourseCredits.map((x: any) => {
      x.creditTypeId = this.state.creditTypes.find(x => x.displayOrder == index)?.id
      x.minimum = x.totalMinCredits;
      x.maximum = x.totalMaxCredits;
      index++;
      return x;
    });

    const institutionCourseDetail = InstitutionCourseDetailDTO.create(form);
    institutionCourseDetail.institutionCourseCredits = credits;
    institutionCourseDetail.id = this.state.selectedInstitutionCourseDetail.id ?? Guid.Empty();
    const courseDetail = CourseDetailDTO.create(form);
    courseDetail.id = this.props.selectedCourseDetail?.id ?? Guid.Empty();

    const request = SaveInstitutionCourseDetailsHandler.Request.create({
      courseId: this.props.selectedInstitutionCourse.courseId,
      formInstitutionCourseDetail: institutionCourseDetail,
      originalInstitutionCourseDetail: this.state.selectedInstitutionCourseDetail,
      institutionId: this.props.selectedInstitutionCourse.institutionId == Guid.Empty() ? this.props.institutionId : this.props.selectedInstitutionCourse.institutionId,
      formCourseDetail: courseDetail,
      courseDetail: this.state.selectedCourseDetail,
      futureTerm: yearTerm,
      course: this.state.course
    });

    InstitutionCoursesApiService.saveInstitutionCourseDetails(request)
      .then((result: SaveInstitutionCourseDetailsHandler.Result | null) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({
            selectedInstitutionCourseDetail: result.institutionCourse?.institutionCourseDetails?.find(x => x.id == this.state.selectedDetailId) ?? InstitutionCourseDetailDTO.create(),
            futureTerm: YearTermDTO.create(),
            fieldErrors: {},
            error: false
          });
          message.success('Saved');

          if (this.props.onSave && result.institutionCourse) {
            this.resetForm();
            this.props.onSave(result.institutionCourseDetailId ?? Guid.Empty(), result.institutionCourse.id ?? Guid.Empty());
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Error Saving');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, showModal: false });
      });
  }

  private handleAddCourseRequisite = () => {
    const values = this._formRef.current?.getFieldsValue();
    const form = this._courseSearch.current?.getValue();
    this._courseSearch.current?.getForm().current?.validateFields();

    let courseRequisites = values.courseRequisites;
    const loaders = [];
    loaders.push(this._courseSearch.current?.getForm().current?.validateFields());

    return Promise.all(loaders).then(() => {
      if (!courseRequisites) {
        courseRequisites = [];
      }
      courseRequisites.push({
        requisiteCourse: form?.course,
        requisiteCourseId: form?.courseId,
        courseRequisiteTypeId: form?.courseRequisiteTypeId
      })
      this.handleChange();
      this._formRef.current?.setFieldsValue({ courseRequisites: courseRequisites })
    });
  }

  private handleRemoveCourseRequisite = (record: CourseRequisiteDTO) => {
    const value = this._formRef.current?.getFieldValue(InstitutionCourseDetailDTO.courseRequisites);
    const deletedValue = value.find((x: any) => x.requisiteCourseId == record.requisiteCourseId);
    value.remove(deletedValue);
    this._formRef.current?.setFieldsValue({ courseRequisites: value });
    this.handleChange();
  }

  private handleEditCourseRequisite = (record: CourseRequisiteDTO) => {
    const value = this._formRef.current?.getFieldValue(InstitutionCourseDetailDTO.courseRequisites);
    const courseRequisiteTypeId = this._courseRequisiteFormRef.current?.getFieldValue(CourseRequisiteDTO.courseRequisiteTypeId);
    value.find((x: any) => x.requisiteCourseId == record.requisiteCourseId).courseRequisiteTypeId = courseRequisiteTypeId;
    this._formRef.current?.setFieldsValue({ courseRequisites: value });
    this.handleChange();
  }

  private addCourseRequisite = () => {
    Modal.confirm({
      title: 'Select Course',
      content: <CourseSearch isCourseRequisite={true} ref={this._courseSearch} institutionId={this.props.institutionId ?? Guid.Empty()} />,
      onOk: () => this.handleAddCourseRequisite(),
      width: 1000
    });
  }

  private removeCourseRequisite = (record: CourseRequisiteDTO) => {
    Modal.confirm({
      title: 'Remove Course Requisite',
      onOk: () => this.handleRemoveCourseRequisite(record),
      okType: 'danger',
      okText: 'Remove',
      width: 300
    });
  }

  private editCourseRequisite(record: CourseRequisiteDTO) {
    Modal.confirm({
      title: 'Edit Course Requisite',
      onOk: () => this.handleEditCourseRequisite(record),
      content: <Form
        ref={this._courseRequisiteFormRef}
        layout="vertical"
        initialValues={record}
        onFinish={() => this.handleEditCourseRequisite(record)}
        requiredMark={true}>
        <FormItem
          {...this.getProgramAwardFormItems(0).get(CourseRequisiteDTO.courseRequisiteTypeId)}>
          <Dropdown dropdownMatchSelectWidth={false}
            allowClear={true}
            showSearch
            optionFilterProp="children"
          >
            {this.renderCourseRequisiteTypes()}
          </Dropdown>
        </FormItem>
      </Form>,
      okText: 'Edit',
      okType: 'primary',
      width: 500
    });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const combinedInitialValuesFromCourse: any = { ...this.state.course, ...this.props.selectedInstitutionCourseDetail, ...this.state.selectedCourseDetail };
    combinedInitialValuesFromCourse.install = this.state.selectedInstitutionCourseDetail?.install;
    const requisiteLabel = this.state.isEditing ?
      'Please provide credits for at least one credit type in the table below.' :
      'Prerequisites/Co-Requisites';

    return (
      <Space size="small" direction="vertical">
        <PageStayPrompt when={this.state.altered} />
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={combinedInitialValuesFromCourse}
          onValuesChange={this.handleChange}
          onFinish={this.props.isNew ? () => this.handleSubmit(YearTermDTO.create()) : this.openModal}
          requiredMark={!this.props.readonly}>
          <Space direction="vertical">

            <Typography.Paragraph>{this.props.readonly || !this.props.isEditing ? this.props.selectedInstitutionCourseDetail?.description :
              <FormItem
                {...this.getProgramAwardFormItems(0).get(InstitutionCourseDetailDTO.description)}>
                <Input.TextArea maxLength={5000} disabled={this.props.readonly || !this.props.isEditing} showCount={true} autoSize={{ minRows: 5 }} />
              </FormItem>
            }</Typography.Paragraph>

            <Descriptions size='small' bordered={true} layout='vertical' column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 4, xxl: 4 }}>
              <Descriptions.Item label="Learning Objectives/Outcomes"> {this.props.readonly || !this.props.isEditing ? StringUtil.newLineCharacterLimit(this.props.selectedInstitutionCourseDetail?.objectives ?? '', 117) :

                <FormItem
                  {...this.getProgramAwardFormItems(0).get(InstitutionCourseDetailDTO.objectives)}>
                  <Input.TextArea maxLength={5000} disabled={this.props.readonly || !this.props.isEditing} showCount={true} autoSize={{ minRows: 5 }} />
                </FormItem>
              } </Descriptions.Item>
            </Descriptions>

            <Descriptions size='small' bordered={true} layout='vertical' column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 4, xxl: 4 }}>
              <Descriptions.Item label="Discipline">{this.state.selectedCourseDetail?.discipline?.display ?? this.state.disciplines.find(x => x.id == this.state.course.disciplineId)?.display}

              </Descriptions.Item>
              <Descriptions.Item label="Catalog Number">{this.state.selectedCourseDetail?.catalogNumber ?? this.state.course.catalogNumber}</Descriptions.Item>

              <Descriptions.Item label="Title">{this.props.readonly || !this.props.isEditing ?
                this.state.selectedCourseDetail.title
                :

                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(CourseDetailDTO.title)}>
                  <Input
                    readOnly={this.props.readonly}
                    disabled={(!this.props.isEditing && !this.props.readonly) || this.state.loading || this.state.submitting} />
                </FormItem>
              }</Descriptions.Item>

              <Descriptions.Item label="Variable hours">{

                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(InstitutionCourseDetailDTO.variableHours)}>
                  <YesNoInput
                    readonly={!this.props.isEditing || this.props.readonly || this.state.loading || this.state.submitting}
                    disabled={!this.props.isEditing || this.props.readonly || this.state.loading || this.state.submitting} />
                </FormItem>
              }</Descriptions.Item>

              <Descriptions.Item label="Category">{this.props.readonly || !this.props.isEditing ? this.state.selectedInstitutionCourseDetail?.category?.display :

                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(InstitutionCourseDetailDTO.categoryId)}
                  {...ValidationUtil.getValidation(InstitutionCourseDetailDTO.categoryId, this.state.fieldErrors, this.state.submitted)}>
                  <Select disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
                    {this.state.categories.map(x => { return this.renderCategoryOption(x) })}
                  </Select>
                </FormItem>
              }</Descriptions.Item>

              <Descriptions.Item label="First Available"> {!AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN]) ? this.state.selectedInstitutionCourseDetail?.install?.display : (this.props.readonly || !this.props.isEditing) ?
                this.state.selectedInstitutionCourseDetail?.install?.display :
                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(InstitutionCourseDetailDTO.install)}
                >
                  <TermInput anyYear={true}></TermInput>
                </FormItem>
              } </Descriptions.Item>

              <Descriptions.Item label="Last Available">{!AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN]) ? this.state.selectedInstitutionCourseDetail?.retire?.display : (this.props.readonly || !this.props.isEditing) ?
                this.state.selectedInstitutionCourseDetail?.retire?.display :
                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(InstitutionCourseDetailDTO.retire)}
                >
                  <TermInput anyYear={true}></TermInput>
                </FormItem>
              }</Descriptions.Item>

            </Descriptions>

            {this.renderInstitutionCourseCredits()}
            <FormItem
              {...this.getProgramAwardFormItems(0).get(InstitutionCourseDetailDTO.variableHours)}>
              {this.renderGetFutureTermModal()}
            </FormItem>

            <Typography.Paragraph italic={true}>
              {requisiteLabel}
            </Typography.Paragraph>

            <Space direction='vertical'>
              {!this.props.isEditing || this.state.loading || this.state.submitting ?
                null :
                <Button onClick={this.addCourseRequisite}>Add Course Requisite</Button>
              }
              <Form.List name={InstitutionCourseDetailDTO.courseRequisites}>
                {(newCourseChangeRequestCourseRequisite) => {
                  return (<Table dataSource={newCourseChangeRequestCourseRequisite} columns={this.getCourseRequisiteColumns()} pagination={false} />)
                }}
              </ Form.List>

            </ Space>

          </Space>
          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderInstitutionCourseCredits() {

    const form = (this._formRef?.current as any);
    let variableHours = this.state.selectedInstitutionCourseDetail.variableHours;
    if (form) {
      variableHours = form.getFieldValue([InstitutionCourseDetailDTO.variableHours]);
    }
    return (<Form.List name={InstitutionCourseDetailDTO.institutionCourseCredits}>
      {
        () => {
          return (
            <Table bordered={false} dataSource={this.state.creditTypes}

              summary={() => {
                if (this._formRef) {
                  const variableHours = this._formRef?.current?.getFieldValue(InstitutionCourseDetailDTO.variableHours);
                  const credits = this._formRef?.current?.getFieldValue(InstitutionCourseDetailDTO.institutionCourseCredits);

                  const min = credits && credits?.length > 0 ? credits?.map((x: any) => x.totalMinCredits || x.totalMinCredits == 0 ? x.totalMinCredits : x.minimum ?? 0).reduce((a: number, b: number) => a + b) ?? 0 : 0;
                  const max = credits && credits?.length > 0 ? credits?.map((x: any) => x.totalMaxCredits || x.totalMaxCredits == 0 ? x.totalMaxCredits : x.maximum ?? 0).reduce((a: number, b: number) => a + b) ?? 0 : 0;

                  const minLabel = min;
                  const maxLabel = max;

                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <Typography.Text strong={true} type="danger">{minLabel}</Typography.Text>
                      </Table.Summary.Cell>
                      {
                        variableHours ?
                          <Table.Summary.Cell index={1}>
                            <Typography.Text strong={true} type="danger">{maxLabel}</Typography.Text>
                          </Table.Summary.Cell> :
                          null
                      }
                    </Table.Summary.Row>
                  );
                }
              }} pagination={false} >
              <Table.Column
                title='Credit Type'
                render={(data: any, row: InstitutionCourseCreditDTO, index: number) => {
                  return this.state.creditTypes.find(x => x.displayOrder == (index + 1))?.name;
                }} />
              <Table.Column
                title={this.state.selectedCourseDetail.variableCredits ? 'Min Credits' : 'Credits'}
                render={(data: any, row: InstitutionCourseCreditDTO, index: number) => {

                  const minimum = this.state.selectedInstitutionCourseDetail.institutionCourseCredits?.find(x => x.creditTypeId == this.state.creditTypes.find(x => x.displayOrder == (index + 1))?.id)?.minimum;
                  return this.props.readonly || !this.props.isEditing ? minimum :
                    <FormItem initialValue={minimum}
                      {...this.getProgramAwardFormItems(index).get(InstitutionCourseDetailDTO.totalMinCredits)}
                      {...ValidationUtil.getValidation(InstitutionCourseDetailDTO.totalMinCredits + index, this.state.fieldErrors, this.state.submitted)}>
                      <InputNumber disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                    </FormItem>
                    ;
                }} />
              {
                variableHours ?
                  <Table.Column
                    title='Max Credits'
                    render={(data: any, row: InstitutionCourseCreditDTO, index: number) => {
                      const maximum = this.state.selectedInstitutionCourseDetail.institutionCourseCredits?.find(x => x.creditTypeId == this.state.creditTypes.find(x => x.displayOrder == (index + 1))?.id)?.maximum;
                      return this.props.readonly || !this.props.isEditing ? this.state.selectedInstitutionCourseDetail.institutionCourseCredits?.find(x => x.creditTypeId == this.state.creditTypes.find(x => x.displayOrder == (index + 1))?.id)?.maximum :
                        <FormItem
                          initialValue={maximum}
                          {...this.getProgramAwardFormItems(index).get(InstitutionCourseDetailDTO.totalMaxCredits)}
                          {...ValidationUtil.getValidation(InstitutionCourseDetailDTO.totalMaxCredits, this.state.fieldErrors, this.state.submitted)}>
                          <InputNumber disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                        </FormItem>;
                    }} />
                  : null
              }
            </Table>
          );
        }
      }
    </ Form.List>
    );
  }

  renderDisciplineOption(discipline: DisciplineDTO) {
    if (discipline.id) {
      return <Select.Option key={discipline.id} value={discipline.id}>{discipline.display}</Select.Option>;
    }
  }

  renderCategoryOption(category: CategoryDTO) {
    if (category.id) {
      return <Select.Option key={category.id} value={category.id}>{category.display}</Select.Option>;
    }
  }

  renderGetFutureTermModal() {

    return (
      <FutureTerm
        fieldErrors={this.state.fieldErrors}
        terms={this.state.terms}
        submit={this.handleSubmit}
        showModal={this.state.showModal}
        onCancel={this.cancelChange}
        installTerm={YearTermDTO.create(this._formRef.current?.getFieldValue(InstitutionCourseDTO.install))} />
    );
  }

  renderRetire() {
    if (this.state.selectedCourseDetail.retire?.termId != Guid.Empty() || !this.props.readonly)
      return (
        <FormItem
          {...this.getProgramAwardFormItems(0).get(CourseDetailDTO.retire)} >
          <TermInput readOnly={this.props.readonly} disabled={(!this.props.isEditing && !this.props.readonly) || this.state.loading || this.state.submitting} />
        </FormItem>
      );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_COURSE]} >
          <Space direction="horizontal" >
            <SaveButton disabled={!this.state.altered} saving={this.state.submitting} />
            <ResetButton disabled={!this.state.altered} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }

  renderCourseRequisiteType(courseRequisiteType: CourseRequisiteTypeDTO): any {
    if (courseRequisiteType.id) {
      return <Select.Option key={courseRequisiteType.id} value={courseRequisiteType.id} >{courseRequisiteType.name}</Select.Option>;
    }
  }

  renderCourseRequisiteTypes() {
    return this.state.courseRequisiteTypes.map(x => { return this.renderCourseRequisiteType(x) });
  }
}

export default CourseDetailsForm;
