import * as React from 'react';
import { useEffect } from 'react';
import UsersApiService from '../../api/UsersApiService';
import * as SearchForUserHandler from '../../handlerModels/SearchForUserHandler';
import UserDTO from '../../models/UserDTO';
import AutoCompleteInput, { AutoCompleteInputOption } from './AutoCompleteInput';

interface UserSearchInputProps {
  value?: UserDTO | null;
  defaultValue?: UserDTO | null;
  disabled?: boolean;
  onChange?: (value: UserDTO | null) => void;
}

function UserSearchInput(props: UserSearchInputProps) {

  const [options, setOptions] = React.useState([] as AutoCompleteInputOption[]);
  const [users, setUsers] = React.useState([] as UserDTO[]);
  const [selectedUser, setSelectedUser] = React.useState(props.defaultValue as UserDTO | null);
  const [searching, setSearching] = React.useState(false);
  console.log(props.defaultValue)

  useEffect(() => {
    setSelectedUser(props.defaultValue ?? null);
  }, [props.defaultValue]);

  if (options.length == 0 && props.value) {
    const opts = [];
    opts.push({
      value: props.value,
      display: props.value.fullDisplay
    });
    setOptions(opts);
  }

  if (options.length == 0 && props.value) {
    const opts = [];
    opts.push(props.value);
    setUsers(opts);
  }

  const handleSearch = (value: string) => {
    if (value.length > 2) {
      const request = SearchForUserHandler.Request.create(
        {
          searchString: value
        });
      setSearching(true);

      UsersApiService.searchForuser(request)
        .then((x: SearchForUserHandler.Result) => {
          const options = x.users?.map(x => ({
            value: x.id,
            display: x.fullDisplay
          } as AutoCompleteInputOption)) as AutoCompleteInputOption[];

          setUsers(x.users ?? []);
          setOptions(options);
          setSearching(false);
        });
    }
  }

  const handleChange = (value: string) => {
    const selected = users.filter(x => x.id === value || x.fullDisplay === value)[0] ?? value;
    setSelectedUser(selected);
    if (props.onChange) {
      props.onChange(selected);
    }
  }

  return (
    <AutoCompleteInput disabled={props.disabled} initialValue={props.defaultValue?.display} value={selectedUser} options={options} onSearch={handleSearch} onChange={handleChange} searching={searching} />
  );
}
export default UserSearchInput;
