import { Card, message, Skeleton } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import SelfStudyDetailDisplay from '../../../components/displays/secondary/SelfStudyDetailDisplay';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as GetAcademicYearHandler from '../../../handlerModels/GetAcademicYearHandler';
import * as GetSelfStudyDetailsHandler from '../../../handlerModels/GetSelfStudyDetailsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import { default as SelfStudyDTO } from '../../../models/SelfStudyDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface SelfStudyDetailDisplayPageState extends BaseFormState {
  id: string;
  selfStudy: SelfStudyDTO;
  academicYearId: number;
  programsForReview: boolean;
  districtId: string;
  academicYear: AcademicYearDTO;
}

interface SelfStudyDetailDisplayPageProps {
}

class SelfStudyDetailDisplayPage extends React.Component<SelfStudyDetailDisplayPageProps & RouteComponentProps<RouteObject>, SelfStudyDetailDisplayPageState> {

  constructor(props: SelfStudyDetailDisplayPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      id: Guid.Empty(),
      academicYearId: 0,
      programsForReview: false,
      selfStudy: SelfStudyDTO.create(),
      districtId: Guid.Empty(),
      academicYear: AcademicYearDTO.create()
    };
  }

  componentDidMount() {
    const selfStudyId = ParameterUtil.getPathPart(this.props.match, 'id');
    const academicYearId = ParameterUtil.getParameter('academicYearId');
    const programsForReview = ParameterUtil.getParameter('programsForReview');
    const districtId = ParameterUtil.getParameter('districtId');

    this.setState({
      academicYearId: Number.parseInt(academicYearId),
      id: selfStudyId,
      loading: true,
      programsForReview: programsForReview == 'true' ? true : false,
      districtId: districtId,
    });

    const loaders = [];
    loaders.push(this.loadSelfStudy(selfStudyId));

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadAcademicYear = (academicYearId: number) => {
    this.setState({ loading: true });
    const request = GetAcademicYearHandler.Request.create({
      academicYearId: academicYearId
    });

    return AcademicYearApiService.getAcademicYear(request)
      .then((results: GetAcademicYearHandler.Result) => {
        if (results) {
          this.setState({
            academicYear: results.academicYear ?? AcademicYearDTO.create()
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadSelfStudy = (id: string) => {
    this.setState({ loading: true });

    const request = GetSelfStudyDetailsHandler.Request.create({
      id: id
    });

    return SelfStudyApiService.getSelfStudyDetails(request)
      .then((results: GetSelfStudyDetailsHandler.Result) => {
        if (results.selfStudy) {
          this.setState({
            selfStudy: results.selfStudy,
            academicYearId: results.selfStudy.academicYearId
          });

          this.loadAcademicYear(results.selfStudy.academicYearId);
        }
      }).catch((error: any) => {
        message.success(error.toString());
        this.setState({ error: true });
      });
  }

  onAddUserSubmit = () => {
    const loaders = [];

    loaders.push(this.loadSelfStudy(this.state.id));

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  goToListing = () => {
    HistoryUtil.goBack();
  }

  goToReview = () => {
    HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: this.state.selfStudy?.id ?? Guid.Empty(), sectionIndex: 0 }));
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active />
    }

    const title = this.state.selfStudy?.secondaryProgram?.secondaryCipNumber?.description ?? '...';
    return (
      <Content >
        <HeaderPortal
          title={title}
          subTitle={this.state.selfStudy?.secondaryProgram?.district?.name}
          onBack={this.goToListing}
          breadcrumbs={this.state.programsForReview ? Breadcrumbs.selfStudyDisplayPageFromProgramForReview(this.state.selfStudy.id ?? Guid.Empty(), this.state.academicYearId, title ?? '...') : this.state.districtId == Guid.Empty() || this.state.districtId == '' ? Breadcrumbs.selfStudyDisplayPage(this.state.selfStudy.id ?? Guid.Empty(), this.state.academicYearId, title ?? '...') : Breadcrumbs.selfStudyDetailFromDistrict(title.toString(), this.state.id, this.state.academicYearId, this.state.selfStudy?.secondaryProgram?.district?.name ?? '', this.state.selfStudy?.secondaryProgram?.district?.id ?? Guid.Empty())}
        >
        </HeaderPortal>
        {this.renderDetailsCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    return (
      <Card title="Details" >
        <SelfStudyDetailDisplay
          selfStudyId={this.state.selfStudy.id ?? Guid.Empty()}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          academicYear={this.state.academicYearId}
          onUserAdd={this.onAddUserSubmit} />
      </Card>
    );
  }
}

export default withRouter(SelfStudyDetailDisplayPage);
