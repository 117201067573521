import { Typography } from 'antd';
import * as React from 'react';

interface PageTitleProps {
  style?: React.CSSProperties;
  show?: boolean | null;
  title?: string | null;
}

class PageTitle extends React.Component<PageTitleProps, unknown> {
  constructor(props: PageTitleProps) {
    super(props);
  }

  render() {
    document.title = 'STICS' + (this.props.title ? ' | ' + this.props.title : '');

    if (this.props.show) {
      return (
        <Typography.Title level={3} className="page-title" style={this.props.style}>
          {this.props.title}
        </Typography.Title>
      );
    }
    else {
      return null;
    }
  }
}

export default PageTitle;
