// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import CipNumberDataTableDTO from '../models/CipNumberDataTableDTO';
import * as GetCipDetailsHandler from '../handlerModels/GetCipDetailsHandler';
import * as SaveCipDetailsHandler from '../handlerModels/SaveCipDetailsHandler';
import * as GetActiveCipDetailsHandler from '../handlerModels/GetActiveCipDetailsHandler';
import * as GetCipNumbersHandler from '../handlerModels/GetCipNumbersHandler';
import * as GetNonTransferCipDetailsHandler from '../handlerModels/GetNonTransferCipDetailsHandler';
import * as ImportCipNumbersHandler from '../handlerModels/ImportCipNumbersHandler';
import * as DeleteCipNumberHandler from '../handlerModels/DeleteCipNumberHandler';
import * as SearchForCipHandler from '../handlerModels/SearchForCipHandler';

export class CipsApiService extends BaseApi {

    // post: api/cips
    public getCipTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<CipNumberDataTableDTO>> {
        let url = `api/cips`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<CipNumberDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/cips/export
    public getCipssDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/cips/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/cips/${encodeURIComponent(id)}
    public getCipDetails(id: string): Promise<GetCipDetailsHandler.Result> {
        let url = `api/cips/${encodeURIComponent(id)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCipDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/cips/save
    public saveCipDetails(saveCipDetails: SaveCipDetailsHandler.Request): Promise<SaveCipDetailsHandler.Result> {
        let url = `api/cips/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveCipDetailsHandler.Result>(saveCipDetails, 'post', url, true, false);
    }

    // post: api/cips/getActiveCips
    public getActiveCipDetails(): Promise<GetActiveCipDetailsHandler.Result> {
        let url = `api/cips/getActiveCips`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetActiveCipDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/cips/getCipNumbers
    public getCipNumbers(): Promise<GetCipNumbersHandler.Result> {
        let url = `api/cips/getCipNumbers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCipNumbersHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/cips/getCipsNonTransfer
    public getActiveNonTransferCipDetails(): Promise<GetNonTransferCipDetailsHandler.Result> {
        let url = `api/cips/getCipsNonTransfer`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNonTransferCipDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/cips/import
    public importCipNumbers(importCipNumbers: FormData): Promise<ImportCipNumbersHandler.Result> {
        let url = `api/cips/import`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ImportCipNumbersHandler.Result>(importCipNumbers, 'post', url, false, false);
    }

    // post: api/cips/deleteCipNumber
    public deleteCipNumber(deleteCipNumber: DeleteCipNumberHandler.Request): Promise<DeleteCipNumberHandler.Result> {
        let url = `api/cips/deleteCipNumber`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteCipNumberHandler.Result>(deleteCipNumber, 'post', url, true, false);
    }

    // post: api/cips/searchForCip
    public searchForCip(searchForCip: SearchForCipHandler.Request): Promise<SearchForCipHandler.Result> {
        let url = `api/cips/searchForCip`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForCipHandler.Result>(searchForCip, 'post', url, true, false);
    }
}
var service = new CipsApiService();
export default service;
