import { message, Steps } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import * as GetSelfStudyStatsForAcademicYearDashboardHandler from '../../../handlerModels/GetSelfStudyStatsForAcademicYearDashboardHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import AcademicYearSelfStudyStatsDTO from '../../../models/AcademicYearSelfStudyStatsDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import CommentReleaseStep from './dashboardSteps/CommentReleaseStep';
import ConfirmProgramsStep from './dashboardSteps/ConfirmProgramsStep';
import DataConfigurationStep from './dashboardSteps/DataConfigurationStep';
import DeResponseStep from './dashboardSteps/DeResponseStep';
import DistrictResponseStep from './dashboardSteps/DistrictResponseStep';
import FinalizeYearStep from './dashboardSteps/FinalizeYearStep';
import RppResponseStep from './dashboardSteps/RppResponseStep';
import SelfStudyConfigurationStep from './dashboardSteps/SelfStudyConfigurationStep';

interface AcademicYearDashboardStepsProps extends BaseFormProps {
  academicYear: AcademicYearDTO;
  fromAcademicYearList?: boolean;
  compactView?: boolean;
  loadAcademicYear?: (id: number) => void;
  selectedDistrict?: DistrictDTO | null;
  selectedRPP?: RegionalPlanningPartnerDTO | null;
}

function AcademicYearDashboardSteps(props: AcademicYearDashboardStepsProps) {
  const academicYear = props.academicYear;
  const [selfStudyStats, setSelfStudyStats] = useState<AcademicYearSelfStudyStatsDTO | null>(null);
  const [academicYearData, setAcademicYearData] = useState<AcademicYearDTO>(academicYear);

  if (academicYear != null) {
    academicYear.instructorStartDate = moment(academicYear.instructorStartDate);
    academicYear.instructorEndDate = moment(academicYear.instructorEndDate);
    academicYear.rppStartDate = moment(academicYear.rppStartDate);
    academicYear.rppEndDate = moment(academicYear.rppEndDate);
    academicYear.deStartDate = moment(academicYear.deStartDate);
    academicYear.deEndDate = moment(academicYear.deEndDate);
    academicYear.commentReleaseDate = moment(academicYear.commentReleaseDate);
    academicYear.finalizationDate = moment(academicYear.finalizationDate);
  }

  useEffect(() => {
    GetDashboardStats();
  }, [props.academicYear, props.selectedDistrict, props.selectedRPP]);

  const GetDashboardStats = () => {
    const request = GetSelfStudyStatsForAcademicYearDashboardHandler.Request.create(
      {
        academicYear: academicYear.id,
        districtId: props.selectedDistrict?.districtId,
        rppId: props.selectedRPP?.regionalPlanningPartnerIdentifier
      });

    SelfStudyApiService.getDashboardStats(request)
      .then((x: GetSelfStudyStatsForAcademicYearDashboardHandler.Result) => {
        if (x.succeeded) {
          setSelfStudyStats(x.stats);
          setAcademicYearData(x.academicYear ?? props.academicYear);
        }
        else {
          message.error('Could not retrieve data');
        }
      }).catch(() => {
        message.error('Could not retrieve data');
      });
  }

  return (
    <Steps direction="vertical" >
      {DataConfigurationStep({
        academicYear: academicYearData,
        compactView: props.compactView
      })}

      {SelfStudyConfigurationStep({
        academicYear: academicYearData,
        compactView: props.compactView,
      })}

      {ConfirmProgramsStep({
        academicYear: academicYearData,
        compactView: props.compactView,
      })}

      {DistrictResponseStep({
        academicYear: academicYearData,
        compactView: props.compactView,
        selfStudyStats: selfStudyStats
      })}

      {RppResponseStep({
        academicYear: academicYearData,
        compactView: props.compactView,
        selfStudyStats: selfStudyStats
      })}

      {DeResponseStep({
        academicYear: academicYearData,
        compactView: props.compactView,
        selfStudyStats: selfStudyStats
      })}

      {CommentReleaseStep({
        academicYear: academicYearData,
        compactView: props.compactView,
        selfStudyStats: selfStudyStats,
        loadAcademicYear: props.loadAcademicYear
      })}

      {FinalizeYearStep({
        academicYear: academicYearData,
        compactView: props.compactView,
        selfStudyStats: selfStudyStats,
        loadAcademicYear: props.loadAcademicYear
      })}

    </Steps>
  );
}

export default AcademicYearDashboardSteps;