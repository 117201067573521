// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import UserDTO from './UserDTO';
import DocumentTypeDTO from './DocumentTypeDTO';
import DocumentStatusDTO from './DocumentStatusDTO';
import AzureBlobFileDTO from './AzureBlobFileDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface DocumentArchiveDTO extends EntitySelectorDTO { 
	id: string | null;
	generatingUserId: string | null;
	user: UserDTO | null;
	queued: moment.Moment | string;
	documentTypeId: number;
	documentType: DocumentTypeDTO | null;
	parameters: string | null;
	documentStatusId: number;
	documentStatus: DocumentStatusDTO | null;
	message: string | null;
	azureBlobId: string | null | null;
	azureBlobFile: AzureBlobFileDTO | null;
	completed: moment.Moment | string | null;
}

const DocumentArchiveDTO = {
    className: 'DocumentArchiveDTO',
    create: (initValues?: Partial<DocumentArchiveDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			generatingUserId: '00000000-0000-0000-0000-000000000000',
			user: null,
			queued: new Date(0).toISOString(),
			documentTypeId: 0,
			documentType: null,
			parameters: "",
			documentStatusId: 0,
			documentStatus: null,
			message: "",
			azureBlobId: null,
			azureBlobFile: null,
			completed: null,
        },
        initValues);
    },
	id: 'id',
	generatingUserId: 'generatingUserId',
	user: 'user',
	queued: 'queued',
	documentTypeId: 'documentTypeId',
	documentType: 'documentType',
	parameters: 'parameters',
	documentStatusId: 'documentStatusId',
	documentStatus: 'documentStatus',
	message: 'message',
	azureBlobId: 'azureBlobId',
	azureBlobFile: 'azureBlobFile',
	completed: 'completed',
};

export default DocumentArchiveDTO;