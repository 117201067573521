import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import TransferMajorLactsCategoryApiService from '../../../api/TransferMajorLactsCategoryApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetTransferMajorLactsCategoryDetailsHandler from '../../../handlerModels/GetTransferMajorLactsCategoryDetailsHandler';
import * as SaveTransferMajorLactsCategoryDetailsHandler from '../../../handlerModels/SaveTransferMajorLactsCategoryDetailsHandler';
import TransferMajorLactsCategoryDTO from '../../../models/TransferMajorLactsCategoryDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface TransferMajorLactsCategoryDetailsFormState extends BaseFormState {
  transferMajorLactsCategoryId: number;
  transferMajorLactsCategory: TransferMajorLactsCategoryDTO | null;
}

interface TransferMajorLactsCategoryDetailsFormProps extends BaseFormProps {
  transferMajorLactsCategoryId: number;
  onSave?: (transferMajorLactsCategoryId: number) => void;
}

class TransferMajorLactsCategoryDetailsForm extends React.Component<TransferMajorLactsCategoryDetailsFormProps, TransferMajorLactsCategoryDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(TransferMajorLactsCategoryDTO.name, {
      required: true,
      name: TransferMajorLactsCategoryDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(50)
      ]
    });

  constructor(props: TransferMajorLactsCategoryDetailsFormProps) {
    super(props);

    this.state = {
      transferMajorLactsCategoryId: 0,
      transferMajorLactsCategory: TransferMajorLactsCategoryDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadTransferMajorLactsCategory();
  }

  componentDidUpdate(prevProps: TransferMajorLactsCategoryDetailsFormProps) {
    if (this.props.transferMajorLactsCategoryId && this.props.transferMajorLactsCategoryId != prevProps.transferMajorLactsCategoryId) {
      this.setState({ transferMajorLactsCategoryId: this.props.transferMajorLactsCategoryId });
      this.loadTransferMajorLactsCategory();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadTransferMajorLactsCategory() {
    if (this.props.transferMajorLactsCategoryId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.transferMajorLactsCategoryId) {
      TransferMajorLactsCategoryApiService.getTransferMajorLactsCategoryDetails(this.props.transferMajorLactsCategoryId)
        .then((results: GetTransferMajorLactsCategoryDetailsHandler.Result) => {
          if (results && results.transferMajorLactsCategory) {
            this.setState({ transferMajorLactsCategoryId: results.transferMajorLactsCategory.id, transferMajorLactsCategory: results.transferMajorLactsCategory, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ transferMajorLactsCategory: TransferMajorLactsCategoryDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveTransferMajorLactsCategoryDetailsHandler.Request.create({
      transferMajorLactsCategoryId: this.state.transferMajorLactsCategoryId,
      transferMajorLactsCategory: TransferMajorLactsCategoryDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    TransferMajorLactsCategoryApiService.saveTransferMajorLactsCategoryDetails(request)
      .then((result: SaveTransferMajorLactsCategoryDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          transferMajorLactsCategory: result?.transferMajorLactsCategory,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.transferMajorLactsCategory?.id) {
            this.props.onSave(result.transferMajorLactsCategory.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(TransferMajorLactsCategoryDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Transfer Major Lacts Category could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Transfer Major Lacts Category could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(TransferMajorLactsCategoryDTO.name)}
            initialValue={this.state.transferMajorLactsCategory?.name}
            {...ValidationUtil.getValidation(TransferMajorLactsCategoryDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_LACTS_CATEGORY]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default TransferMajorLactsCategoryDetailsForm;
