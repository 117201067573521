// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import TermDTO from './TermDTO';
import ProgramModificationProgramTermCourseGroupDTO from './ProgramModificationProgramTermCourseGroupDTO';
import FlatProgramModificationProgramTermCourseGroupOptionDTO from './FlatProgramModificationProgramTermCourseGroupOptionDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramModificationProgramTermDTO extends EntitySelectorDTO { 
	id: string | null | null;
	programAwardId: string | null;
	order: number;
	prerequisite: boolean;
	informationalContent: string | null;
	termId: string | null | null;
	term: TermDTO | null;
	groups: ProgramModificationProgramTermCourseGroupDTO[] | null;
	flatCourseGroupOptions: FlatProgramModificationProgramTermCourseGroupOptionDTO[] | null;
}

const ProgramModificationProgramTermDTO = {
    className: 'ProgramModificationProgramTermDTO',
    create: (initValues?: Partial<ProgramModificationProgramTermDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			programAwardId: '00000000-0000-0000-0000-000000000000',
			order: 0,
			prerequisite: false,
			informationalContent: "",
			termId: null,
			term: null,
			groups: [],
			flatCourseGroupOptions: [],
        },
        initValues);
    },
	id: 'id',
	programAwardId: 'programAwardId',
	order: 'order',
	prerequisite: 'prerequisite',
	informationalContent: 'informationalContent',
	termId: 'termId',
	term: 'term',
	groups: 'groups',
	flatCourseGroupOptions: 'flatCourseGroupOptions',
};

export default ProgramModificationProgramTermDTO;