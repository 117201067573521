import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, Modal, notification, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import InstitutionUsersApiService, { default as InstituionUsersApiService } from '../../../api/InstitutionUsersApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import InstitutionUserDetailsForm from '../../../components/forms/postSecondary/InstitutionUserDetailsForm';
import InstitutionUserRoles from '../../../components/forms/postSecondary/InstitutionUserRolesForm';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetInstitutionUserDetailsHandler from '../../../handlerModels/GetInstitutionUserDetailsHandler';
import * as RemoveInstitutionUserHandler from '../../../handlerModels/RemoveInstitutionUserHandler';
import InstitutionDTO from '../../../models/InstitutionDTO';
import InstitutionUserDTO from '../../../models/InstitutionUserDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ParameterUtil from '../../../utils/ParameterUtil';

interface InstitutionUserDetailsPageState extends BaseFormState {
  institutionId: string;
  userId: string;
  institutionUser?: InstitutionUserDTO;
  originatingPage: string;
}

interface InstitutionUserDetailsPageProps {
  institution: InstitutionDTO | null;
}

class InstitutionUserDetailsPage extends React.Component<InstitutionUserDetailsPageProps & RouteComponentProps<RouteObject>, InstitutionUserDetailsPageState> {
  private readonly _institutionUserDetailsRef = React.createRef<InstitutionUserDetailsForm>();
  private readonly _institutionUserRolesRef = React.createRef<InstitutionUserRoles>();

  constructor(props: InstitutionUserDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      originatingPage: '',
      institutionId: Guid.Empty(),
      userId: Guid.Empty()
    };
  }

  componentDidMount() {
    const institutionId = ParameterUtil.getPathPart(this.props.match, 'institutionId');
    const userId = ParameterUtil.getPathPart(this.props.match, 'userId');
    const originatingPage = this.props.match.path.split('/')[1];

    this.setState({ originatingPage: originatingPage });

    this.loadInstitutionUser(userId, institutionId);
  }

  private loadInstitutionUser = (userId: string, institutionId: string) => {
    this.setState({ loading: true })
    InstituionUsersApiService.getInstitutionUserDetails(userId, institutionId)
      .then((results: GetInstitutionUserDetailsHandler.Result) => {
        if (results.institutionUser) {
          this.setState({
            institutionId: institutionId,
            userId: userId,
            institutionUser: results.institutionUser
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._institutionUserRolesRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private instituitionUserRolesSaved = () => {
    this.setState({ isEditing: false });
  }

  private handleRemoveUser = () => {
    this.setState({ submitting: true });

    const request = RemoveInstitutionUserHandler.Request.create({
      institutionId: this.state.institutionId,
      userId: this.state.userId,
    });

    InstitutionUsersApiService.removeInstitutionUser(request)
      .then((result: RemoveInstitutionUserHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          notification.success({ message: 'Success!', description: 'Institution User has been removed', });
          this.state.originatingPage == 'users' ? this.goToUser() : this.goToInstitution();
        }
        else {
          notification.error({ message: 'Uh oh!', description: result?.errors.join('\n'), });
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        notification.error({ message: 'Uh oh!', description: 'Institution User could not be removed', });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private goToInstitution = () => {
    HistoryUtil.push(Routes.generate(Routes.INSTITUTION_DETAILS, { id: this.state.institutionId }));
  }

  private goToUser = () => {
    HistoryUtil.push(Routes.generate(Routes.USER_DETAILS, { id: this.state.userId }));
  }

  private goToUserList = () => {
    HistoryUtil.push(Routes.generate(Routes.USERS));
  }

  private promptConfirmRemove = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove this user from this institution?',
      okText: 'Remove User',
      okType: 'danger',
      onOk: this.handleRemoveUser
    });
  }

  private onBack = () => {
    HistoryUtil.goBack();
  }

  render() {
    const userName = this.state.loading ? '...' : this.state.institutionUser?.user?.fullName ?? '...';
    const institutionName = this.state.loading ? '...' : this.state.institutionUser?.institution?.name ?? '...';
    const institutionAbbreviation = this.state.loading ? '...' : this.state.institutionUser?.institution?.abbreviation ?? '...';

    let breadCrumbs = this.state.originatingPage == 'users' ?
      Breadcrumbs.userInstitutionDetials(userName, this.state.userId, institutionAbbreviation, this.state.institutionId) :
      Breadcrumbs.institutionUserDetails(institutionAbbreviation, this.state.institutionId, userName, this.state.userId);

    if (this.props.institution != null && this.props.institution != undefined) {
      breadCrumbs = Breadcrumbs.userList();
    }

    return (
      <Content >
        <HeaderPortal
          title={userName}
          breadcrumbs={breadCrumbs}
          extra={this.renderMoreActions()}
          onBack={this.onBack}
          subTitle={institutionName} />
        <PageStayPrompt when={this.state.isEditing} />

        <Space direction="vertical" size="large">
          {this.renderDetailsCard()}
          {this.renderRolesCard()}
        </ Space>
      </Content>
    );
  }

  renderDetailsCard() {
    return (
      <Card title="Details" >
        <InstitutionUserDetailsForm
          ref={this._institutionUserDetailsRef}
          institutionId={this.state.institutionId}
          userId={this.state.userId} />
      </Card>
    );
  }

  renderRolesCard() {
    const editButton = this.state.institutionId == Guid.Empty() ?
      null :
      <AuthorizedContent validFeatureFlags={[FeatureFlag.MANAGE_INSTITUTION_USER]}>
        <EditCancelButton onClick={this.toggleEdit} isEditing={this.state.isEditing} />
      </AuthorizedContent>

    return (
      <Card title="Roles" extra={editButton} >
        <InstitutionUserRoles
          ref={this._institutionUserRolesRef}
          institutionId={this.state.institutionId}
          userId={this.state.userId}
          isEditing={this.state.isEditing}
          onSave={this.instituitionUserRolesSaved}
        />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        <Menu.Item key={this.state.userId} onClick={() => this.promptConfirmRemove()} >
          Remove User
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu}>
        <Button size='large' type='link' >
          <MoreOutlined />
        </Button>
      </Dropdown>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}
export default withRouter(connect(mapStateToProps)(InstitutionUserDetailsPage));
