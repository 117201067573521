// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveRegionalPlanningPartnerDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import RegionalPlanningPartnerDTO from '../models/RegionalPlanningPartnerDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYear: number;
    regionalPlanningPartnerId: string | null | null;
    regionalPlanningPartner: RegionalPlanningPartnerDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYear: 0,
            regionalPlanningPartnerId: null,
            regionalPlanningPartner: null,
        },
        initValues);
    },
    academicYear: 'academicYear',
    regionalPlanningPartnerId: 'regionalPlanningPartnerId',
    regionalPlanningPartner: 'regionalPlanningPartner',
}

interface Result extends ResultDTO {
    regionalPlanningPartner: RegionalPlanningPartnerDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            regionalPlanningPartner: null,
        },
        initValues);
    },
    regionalPlanningPartner: 'regionalPlanningPartner',
}

export { Request, Result };