import { Card, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AreaEducationAgencyDataTable from '../../../components/datatables/secondary/AreaEducationAgencyDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface AcademicYearAreaEducationAgencyConfigurationPageState {
  loading: boolean;
  academicYear: number;
}

interface AcademicYearAreaEducationAgencyConfigurationPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class AcademicYearAreaEducationAgencyConfigurationPage extends React.Component<AcademicYearAreaEducationAgencyConfigurationPageProps & RouteComponentProps<RouteObject>, AcademicYearAreaEducationAgencyConfigurationPageState> {

  constructor(props: AcademicYearAreaEducationAgencyConfigurationPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    this.setState({ academicYear: id });
  }

  private onBack = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content >
        <HeaderPortal title='Area Education Agencies' breadcrumbs={Breadcrumbs.academicYearAreaEducationAgencyConfigurationList(this.state.academicYear)}
          onBack={this.onBack} />
        < Space direction="vertical" size="large">
          <Card title="Area Education Agencies">
            <Space direction='vertical' size="large">
              <AreaEducationAgencyDataTable
                academicYear={this.state.academicYear} />
            </Space>
          </Card>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(AcademicYearAreaEducationAgencyConfigurationPage));
