// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CipNumberDTO from './CipNumberDTO';
import UserDTO from './UserDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface UserCIPAssignmentDTO extends EntitySelectorDTO { 
	id: string | null;
	cipNumberId: string | null;
	userId: string | null;
	cipNumberDisplay: string | null;
	title: string | null;
	cipNumber: CipNumberDTO | null;
	user: UserDTO | null;
}

const UserCIPAssignmentDTO = {
    className: 'UserCIPAssignmentDTO',
    create: (initValues?: Partial<UserCIPAssignmentDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			cipNumberId: '00000000-0000-0000-0000-000000000000',
			userId: '00000000-0000-0000-0000-000000000000',
			cipNumberDisplay: "",
			title: "",
			cipNumber: null,
			user: null,
        },
        initValues);
    },
	id: 'id',
	cipNumberId: 'cipNumberId',
	userId: 'userId',
	cipNumberDisplay: 'cipNumberDisplay',
	title: 'title',
	cipNumber: 'cipNumber',
	user: 'user',
};

export default UserCIPAssignmentDTO;