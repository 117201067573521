// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// AddUserHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    institutionId: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            institutionId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phoneNumber: 'phoneNumber',
    institutionId: 'institutionId',
}

interface Result extends ResultDTO {
    userId: string | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            userId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    userId: 'userId',
}

export { Request, Result };