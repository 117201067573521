// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import RoleDTO from '../models/RoleDTO';
import * as GetActiveRolesForUserHandler from '../handlerModels/GetActiveRolesForUserHandler';
import * as SaveRoleStatusesForUserHandler from '../handlerModels/SaveRoleStatusesForUserHandler';
import * as SaveRoleDetailsHandler from '../handlerModels/SaveRoleDetailsHandler';
import * as GetRoleDetailsHandler from '../handlerModels/GetRoleDetailsHandler';
import * as GetFeatureFlagsForRoleHandler from '../handlerModels/GetFeatureFlagsForRoleHandler';
import * as SaveFeatureFlagsForRoleHandler from '../handlerModels/SaveFeatureFlagsForRoleHandler';
import * as GetRoleStatusesForUserHandler from '../handlerModels/GetRoleStatusesForUserHandler';
import * as SaveRoleStatusesForInstitutionUserHandler from '../handlerModels/SaveRoleStatusesForInstitutionUserHandler';
import * as DeleteRoleHandler from '../handlerModels/DeleteRoleHandler';

export class RolesApiService extends BaseApi {

    // post: api/roles
    public getRolesTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<RoleDTO>> {
        let url = `api/roles`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<RoleDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/roles/export
    public exportRolesList(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/roles/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/roles/getUserRoles
    public getUserRoles(): Promise<GetActiveRolesForUserHandler.Result> {
        let url = `api/roles/getUserRoles`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetActiveRolesForUserHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/roles/saveUserRoles
    public saveUserRoles(saveRoleStatusesForUser: SaveRoleStatusesForUserHandler.Request): Promise<SaveRoleStatusesForUserHandler.Result> {
        let url = `api/roles/saveUserRoles`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveRoleStatusesForUserHandler.Result>(saveRoleStatusesForUser, 'post', url, true, false);
    }

    // post: api/roles/saveRole
    public saveRole(saveRoleDetails: SaveRoleDetailsHandler.Request): Promise<SaveRoleDetailsHandler.Result> {
        let url = `api/roles/saveRole`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveRoleDetailsHandler.Result>(saveRoleDetails, 'post', url, true, false);
    }

    // post: api/roles/${encodeURIComponent(roleId)}
    public getRoleDetails(roleId: string): Promise<GetRoleDetailsHandler.Result> {
        let url = `api/roles/${encodeURIComponent(roleId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetRoleDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/roles/${encodeURIComponent(roleId)}/featureFlags
    public getFeatureFlagsForRole(roleId: string): Promise<GetFeatureFlagsForRoleHandler.Result> {
        let url = `api/roles/${encodeURIComponent(roleId)}/featureFlags`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetFeatureFlagsForRoleHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/roles/saveFeatureFlagsForRole
    public saveFeatureFlagsForRole(saveFeatureFlagsForRole: SaveFeatureFlagsForRoleHandler.Request): Promise<SaveFeatureFlagsForRoleHandler.Result> {
        let url = `api/roles/saveFeatureFlagsForRole`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveFeatureFlagsForRoleHandler.Result>(saveFeatureFlagsForRole, 'post', url, true, false);
    }

    // post: api/roles/userRoles/${encodeURIComponent(userId)}
    public getUserRolesForUser(userId: string): Promise<GetRoleStatusesForUserHandler.Result> {
        let url = `api/roles/userRoles/${encodeURIComponent(userId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetRoleStatusesForUserHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/roles/saveInstitutionUserRoles
    public saveInstitutionUserRoles(saveRoleStatusesForInstitutionUser: SaveRoleStatusesForInstitutionUserHandler.Request): Promise<SaveRoleStatusesForInstitutionUserHandler.Result> {
        let url = `api/roles/saveInstitutionUserRoles`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveRoleStatusesForInstitutionUserHandler.Result>(saveRoleStatusesForInstitutionUser, 'post', url, true, false);
    }

    // post: api/roles/deleteRole
    public deleteRole(deleteRole: DeleteRoleHandler.Request): Promise<DeleteRoleHandler.Result> {
        let url = `api/roles/deleteRole`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteRoleHandler.Result>(deleteRole, 'post', url, true, false);
    }
}
var service = new RolesApiService();
export default service;
