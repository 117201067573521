import { DownloadOutlined } from '@ant-design/icons';
import { List, Skeleton, Typography } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../config/Routes';
import LaunchOutlined from '../../icons/LaunchOutlined';
import ResourceDTO from '../../models/ResourceDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import Guid from '../../utils/Guid';

interface ResourcesDisplayProps extends BaseFormProps {
  resources: ResourceDTO[];
}

function ResourcesDisplay(props: ResourcesDisplayProps) {
  if (props.resources != null) {
    const groupings = new Map<string, any[]>();

    for (let i = 0; i < props.resources.length; i++) {
      const resource = props.resources[i];

      const resourceCategoryName = resource.resourceCategory?.name && resource.resourceCategory?.name != '' ? resource.resourceCategory?.name ?? 'Other' : 'Other';
      let grouping = groupings.get(resourceCategoryName);

      if (grouping == null || grouping == undefined) {
        grouping = [];
      }

      grouping.push({
        title: resource.title,
        description: resource.description,
        azureBlobFileId: resource.azureBlobFileId,
        url: resource.url
      });

      groupings.set(resourceCategoryName, grouping);
    }

    return (
      <>
        {Array.from(groupings.keys()).map(x => {
          return (
            <>
              <Typography.Title level={5}>{x}</Typography.Title>
              <List
                itemLayout="horizontal"
                dataSource={groupings.get(x)}
                renderItem={item => (
                  <List.Item >
                    <List.Item.Meta
                      avatar={item.azureBlobFileId ? <DownloadOutlined /> : <LaunchOutlined />}
                      title={<Link
                        target='_blank'
                        rel="noopener noreferrer"
                        to={{ pathname: item.azureBlobFileId ? Routes.generateDownload(item.azureBlobFileId ?? Guid.Empty()) : item.url ?? '' }} >{item.title}</ Link>}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </>);
        })
        }
      </>
    );
  }

  return <Skeleton active={true} />
}

export default ResourcesDisplay;
