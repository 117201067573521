import history from '../utils/HistoryUtil';
import Routes from '../config/Routes';
import { VersionActions } from '../redux/actions/VersionActions';
import getStore from '../redux/getStore';

export class ApiError {
  public description: string | undefined;
  constructor(public title: string, public message?: string, public errors?: [], public exception?: any) { }
}

class ApiResultHandler {
  public static handle(response: Response): Response | Promise<Response> {
    if (response.ok || response.redirected) {
      const v = response.headers.get('api-version');
      if (v != null && $apiVersion !== v) {
        const store = getStore();
        if (store) {
          store.dispatch(VersionActions.check($apiVersion, v));
        }
      }
      if (response.redirected) {
        window.location.href = response.url;
      }
      return response;
    } else if (response.status === 401 || response.status === 405) {
      const url = history.location.pathname + history.location.search;
      history.push(Routes.generate(Routes.LOGIN, {}, { returnUrl: url }));
    } else {
      const error = new ApiError(response.status + ' ' + response.statusText);
      const ct = response.headers.get('Content-Type');
      if (ct && ct.indexOf('/json') > 0) {
        return response.json().then((result: any) => {
          error.message = ((result?.errors || []) as []).join('\n');
          error.errors = result.errors;
          error.exception = result.exception;
          return Promise.reject(error);
        });
      } else {
        error.message += (error.message != null ? '\n\n\n' : '') + '{RESPONSE}: ' + JSON.stringify(response);
      }
      throw error;
    }
    return response;
  }
}

export default ApiResultHandler;
