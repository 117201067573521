import { Alert, InputNumber, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import CipsApiService from '../../../api/CipsApiService';
import FileUpload from '../../FileUpload';
import FeatureFlag from '../../../consts/FeatureFlag';
import '../../../extensions/StringExtensions';
import * as ImportCipNumbersHandler from '../../../handlerModels/ImportCipNumbersHandler';
import TermDTO from '../../../models/TermDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import FileUploadUtil from '../../../utils/FileUploadUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import CipNumberDataTable from '../../datatables/postSecondary/CipNumberDataTable';

interface CipNumberImportFormState extends BaseFormState {
  terms: TermDTO[];
}

interface CipNumberImportFormProps extends BaseFormProps {
  handler: () => void;
}

class CipNumberImportForm extends React.Component<CipNumberImportFormProps, CipNumberImportFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _cipNumberDataTableRef = React.createRef<CipNumberDataTable>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(ImportCipNumbersHandler.Request.selectedFile, {
      required: true,
      name: ImportCipNumbersHandler.Request.selectedFile,
      label: 'File',
      rules: [
        ValidationRuleUtil.required()
      ]
    })
    .set(ImportCipNumbersHandler.Request.installedYear, {
      required: true,
      name: ImportCipNumbersHandler.Request.installedYear,
      label: 'Installed Year',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(ImportCipNumbersHandler.Request.installedTermId, {
      required: true,
      name: ImportCipNumbersHandler.Request.installedTermId,
      label: 'Installed Term',
      rules: [
        ValidationRuleUtil.required()
      ],
    });

  constructor(props: CipNumberImportFormProps) {
    super(props);

    this.state = {
      terms: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  public submit = () => {
    this.handleSubmit();
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.terms.length <= 0) {
      loaders.push(this.loadTerms());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadTerms = () => {
    return LookupsUtil.getAll<TermDTO>(TermDTO.className)
      .then((results: TermDTO[]) => {
        if (results) {
          this.setState({ terms: results ?? [], loading: false });
        }
      }).catch(() => {
        this.setState({ error: true, loading: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = this._formRef.current?.getFieldsValue() ?? null;

    if (!FormUtil.canSubmit(this._formRef, this._formItems)) {
      message.error('Please fill out all required fields.');
      this.setState({ loading: false, submitting: false });
      this.props.handler();
    }
    else {
      const file = this._formRef.current?.getFieldValue(ImportCipNumbersHandler.Request.selectedFile).fileList[0];
      const request = FileUploadUtil.attachFileToModel('importCipNumbers', model, ImportCipNumbersHandler.Request.selectedFile, file)

      CipsApiService.importCipNumbers(request)
        .then((result: ImportCipNumbersHandler.Result) => {

          if (result?.succeeded) {
            message.success('Imported');
          }
          else {
            this.setState({
              error: !result?.succeeded,
              message: result?.errors.join('\n'),
              fieldErrors: result?.fieldErrors
            });
            message.error('Import failed. \n' + this.state.message);
          }
        })
        .catch((results: any) => {
          this.setState({ error: results });
          message.error('Import Failed.');
        })
        .finally(() => {
          this.props.handler();
          this.setState({ loading: false, submitting: false });
        });
    }
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Form
        ref={this._formRef}
        layout="vertical"
      >
        {this.renderErrors()}

        <FormItem
          {...this._formItems.get(ImportCipNumbersHandler.Request.selectedFile)}
          {...ValidationUtil.getValidation(ImportCipNumbersHandler.Request.selectedFile, this.state.fieldErrors, this.state.submitted)}>
          <FileUpload accept='.xlsx' maxCount={1} />
        </FormItem>

        <FormItem
          {...this._formItems.get(ImportCipNumbersHandler.Request.installedTermId)}
          {...ValidationUtil.getValidation(ImportCipNumbersHandler.Request.installedTermId, this.state.fieldErrors, this.state.submitted)}>
          <Select disabled={this.state.loading || this.state.submitting}>
            {this.state.terms.map(x => { return this.renderTermOption(x) })}
          </Select>
        </FormItem>

        <FormItem
          {...this._formItems.get(ImportCipNumbersHandler.Request.installedYear)}
          {...ValidationUtil.getValidation(ImportCipNumbersHandler.Request.installedYear, this.state.fieldErrors, this.state.submitted)}>
          <InputNumber disabled={this.state.loading || this.state.submitting} />
        </FormItem>
      </Form>
    );
  }

  renderTermOption(term: TermDTO) {
    if (term.id) {
      return <Select.Option key={term.id} value={term.id}>{term.name}</Select.Option>;
    }
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CIP_NUMBER]} >
          <Space direction="horizontal" >
            <SaveButton disabled={!FormUtil.canSubmit(this._formRef, this._formItems)} saving={this.state.submitting} />
            <ResetButton disabled={!FormUtil.hasChanged(this._formRef, this._formItems)} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default CipNumberImportForm;