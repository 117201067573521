import { Card, Space } from 'antd';
import * as React from 'react';
import ChangeRequestType from '../../../consts/ChangeRequestType';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import NewProgramProgramAwardDTO from '../../../models/NewProgramProgramAwardDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ChangeRequestAdditionalDetailsForm from '../../forms/postSecondary/ChangeRequestAdditionalDetailsForm';
import NewProgramChangeRequestStep10Form from '../../forms/postSecondary/NewProgramChangeRequestStep10Form';
import NewProgramChangeRequestStep11Form from '../../forms/postSecondary/NewProgramChangeRequestStep11Form';
import NewProgramChangeRequestStep12Form from '../../forms/postSecondary/NewProgramChangeRequestStep12Form';
import NewProgramChangeRequestStep13Form from '../../forms/postSecondary/NewProgramChangeRequestStep13Form';
import NewProgramChangeRequestStep14Form from '../../forms/postSecondary/NewProgramChangeRequestStep14Form';
import NewProgramChangeRequestStep1Form from '../../forms/postSecondary/NewProgramChangeRequestStep1Form';
import NewProgramChangeRequestStep2Form from '../../forms/postSecondary/NewProgramChangeRequestStep2Form';
import NewProgramChangeRequestStep3Form from '../../forms/postSecondary/NewProgramChangeRequestStep3Form';
import NewProgramChangeRequestStep4Form from '../../forms/postSecondary/NewProgramChangeRequestStep4Form';
import NewProgramChangeRequestStep5Form from '../../forms/postSecondary/NewProgramChangeRequestStep5Form';
import NewProgramChangeRequestStep6Form from '../../forms/postSecondary/NewProgramChangeRequestStep6Form';
import NewProgramChangeRequestStep7Form from '../../forms/postSecondary/NewProgramChangeRequestStep7Form';
import NewProgramChangeRequestStep8Form from '../../forms/postSecondary/NewProgramChangeRequestStep8Form';

interface NewProgramChangeRequestDetailsState extends BaseFormState {
}

interface NewProgramChangeRequestDetailsProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequest: ChangeRequestDTO;
  onIsValid?: (isValid: boolean) => void;
}

class NewProgramChangeRequestDetails extends React.Component<NewProgramChangeRequestDetailsProps, NewProgramChangeRequestDetailsState> {
  constructor(props: NewProgramChangeRequestDetailsProps) {
    super(props);

    this.state = {};
  }

  public render = () => {

    return (
      <Space size="large" direction="vertical">
        <Card title="Notice Of Intent" type='inner'>
          <NewProgramChangeRequestStep1Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title="Program Plan" type='inner'>
          <NewProgramChangeRequestStep2Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title="Contact Information" type='inner'>
          <NewProgramChangeRequestStep3Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        {
          this.props.changeRequest.changeRequestTypeId == ChangeRequestType.NEWPROGRAM ?
            <Card title="Consultant Meetings" type='inner'>
              <NewProgramChangeRequestStep4Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
            </Card>
            : null
        }

        {
          this.props.changeRequest.changeRequestTypeId == ChangeRequestType.NEWPROGRAM ?
            <Card title="Occupational Information" type='inner'>
              <NewProgramChangeRequestStep5Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
            </Card>
            : null
        }

        {
          this.props.changeRequest.changeRequestTypeId == ChangeRequestType.NEWPROGRAM ?
            <Card title="Industry Information" type='inner'>
              <NewProgramChangeRequestStep6Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
            </Card>
            : null
        }

        <Card title="Linking And Existing Entities" type='inner'>
          <NewProgramChangeRequestStep7Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title="Program Details" type='inner'>
          <NewProgramChangeRequestStep8Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        {this.renderAwards()}

        <Card title="ICCPHSE" type='inner'>
          <NewProgramChangeRequestStep12Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} readonly={true} />
        </Card>

        {
          this.props.changeRequest.changeRequestTypeId == ChangeRequestType.NEWPROGRAM ?
            <Card title="Advisory Board" type='inner'>
              <NewProgramChangeRequestStep13Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
            </Card>
            : null
        }

        <Card title="Sign Off" type='inner'>
          <NewProgramChangeRequestStep14Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title='Additional Information' type='inner'>
          <ChangeRequestAdditionalDetailsForm loading={this.props.loading} changeRequest={this.props.changeRequest} readonly={true} />
        </Card>

      </Space>
    );
  }

  renderAwards() {
    let awardCount = 0;
    if (this.props.changeRequest.id != Guid.Empty()) {
      return this.props.changeRequest.newProgramChangeRequest?.newProgramProgramAwards?.map(x => this.renderAward(x, ++awardCount));
    }
  }
  renderAward(award: NewProgramProgramAwardDTO, awardCount: number) {
    return (
      <Space direction="vertical">
        <Card title={'Award ' + awardCount} type='inner' size="small">
          <NewProgramChangeRequestStep10Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} awardId={award.id ?? Guid.Empty()} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
          <NewProgramChangeRequestStep11Form onIsValid={this.props.onIsValid} review={true} loading={this.props.loading} changeRequestId={this.props.changeRequest.id} awardId={award.id ?? Guid.Empty()} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>
      </Space>);
  }
}

export default NewProgramChangeRequestDetails;