// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionDTO extends EntitySelectorDTO { 
	id: string | null;
	name: string | null;
	abbreviation: string | null;
	code: number;
	display: string | null;
}

const InstitutionDTO = {
    className: 'InstitutionDTO',
    create: (initValues?: Partial<InstitutionDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			name: "",
			abbreviation: "",
			code: 0,
			display: "",
        },
        initValues);
    },
	id: 'id',
	name: 'name',
	abbreviation: 'abbreviation',
	code: 'code',
	display: 'display',
};

export default InstitutionDTO;