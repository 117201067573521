// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import TransferMajorCourseBlockDTO from '../models/TransferMajorCourseBlockDTO';
import * as GetTransferMajorCourseBlockDetailsHandler from '../handlerModels/GetTransferMajorCourseBlockDetailsHandler';
import * as SaveTransferMajorCourseBlockDetailsHandler from '../handlerModels/SaveTransferMajorCourseBlockDetailsHandler';
import * as DeleteTransferMajorCourseBlockHandler from '../handlerModels/DeleteTransferMajorCourseBlockHandler';

export class TransferMajorCourseBlockApiService extends BaseApi {

    // post: api/transferMajorCourseBlock
    public getTransferMajorCourseBlocksDataTable(tableRequest: TableRequestDTO): Promise<TableResponseDTO<TransferMajorCourseBlockDTO>> {
        let url = `api/transferMajorCourseBlock`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<TransferMajorCourseBlockDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/transferMajorCourseBlock/export
    public getTransferMajorCourseBlockDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/transferMajorCourseBlock/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/transferMajorCourseBlock/${id}
    public getTransferMajorCourseBlockDetails(id: number): Promise<GetTransferMajorCourseBlockDetailsHandler.Result> {
        let url = `api/transferMajorCourseBlock/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetTransferMajorCourseBlockDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/transferMajorCourseBlock/save
    public saveTransferMajorCourseBlockDetails(saveTransferMajorCourseBlockDetails: SaveTransferMajorCourseBlockDetailsHandler.Request): Promise<SaveTransferMajorCourseBlockDetailsHandler.Result> {
        let url = `api/transferMajorCourseBlock/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveTransferMajorCourseBlockDetailsHandler.Result>(saveTransferMajorCourseBlockDetails, 'post', url, true, false);
    }

    // post: api/transferMajorCourseBlock/deleteTransferMajorCourseBlock
    public deleteTransferMajorCourseBlock(deleteTransferMajorCourseBlock: DeleteTransferMajorCourseBlockHandler.Request): Promise<DeleteTransferMajorCourseBlockHandler.Result> {
        let url = `api/transferMajorCourseBlock/deleteTransferMajorCourseBlock`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteTransferMajorCourseBlockHandler.Result>(deleteTransferMajorCourseBlock, 'post', url, true, false);
    }
}
var service = new TransferMajorCourseBlockApiService();
export default service;
