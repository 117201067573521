import { Skeleton } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import LookupsApiService from '../../../api/LookupsApiService';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import * as GetServiceAreasHandler from '../../../handlerModels/GetServiceAreasHandler';
import CareerAcademyReportDTO from '../../../models/CareerAcademyReportDTO';
import LookupTableDTO from '../../../models/LookupTableDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import SelfStudyResponseStatusTypeDTO from '../../../models/SelfStudyResponseStatusTypeDTO';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface CareerAcademyReportDataTableProps {
  academicYear?: number;
}

function CareerAcademyReportDataTable(props: CareerAcademyReportDataTableProps) {
  let _dataTable: DataTable<CareerAcademyReportDTO> | undefined = undefined;

  const [statusTypes, setStatusTypes] = useState<LookupTableDTO[]>([]);
  const [exporting, setExporting] = useState(false);
  const [rpps, setRPPs] = useState<RegionalPlanningPartnerDTO[]>([]);
  const [serviceAreas, setServiceAreas] = useState<ServiceAreaDTO[]>([]);

  useEffect(() => {
    RegionalPlanningPartnerApiService.getRegionalPlanningPartners(props.academicYear ?? 0)
      .then(x => {
        setRPPs(x.regions ?? []);
      });

    LookupsApiService.getAll(SelfStudyResponseStatusTypeDTO.className)
      .then(x => {
        setStatusTypes(x);
        triggerRefresh();
      });


    ServiceAreaApiService.getServiceAreas(props.academicYear ?? 0).then((results: GetServiceAreasHandler.Result) => {
      if (results.serviceAreas) {
        setServiceAreas(results.serviceAreas);
      }
    });

    triggerRefresh();
  }, [props.academicYear]);

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const getColumnDefinitions = () => {
    return [
      {
        title: 'Status',
        dataIndex: CareerAcademyReportDTO.status,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: statusTypes.map(c => { return { text: c.name, value: c.key.toString() } }) as TableFilterOption[],
        render: (data: string, row: CareerAcademyReportDTO) => {
          return statusTypes.find(x => x.key == row.status)?.name;
        },
      },
      {
        title: 'RPP',
        dataIndex: CareerAcademyReportDTO.rppId,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: rpps.map(c => { return { text: c.regionalPlanningPartnerIdentifier, value: c.regionalPlanningPartnerIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: CareerAcademyReportDTO) => {
          return rpps.find(x => x.regionalPlanningPartnerIdentifier == row.rppId)?.name;
        },
      },
      {
        title: 'Service Area',
        dataIndex: CareerAcademyReportDTO.serviceAreaIdentifier,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: serviceAreas.map(c => { return { text: c.serviceAreaIdentifier.toString(), value: c.serviceAreaIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: CareerAcademyReportDTO) => {
          return serviceAreas.find(x => x.serviceAreaIdentifier == row.serviceAreaIdentifier)?.description;
        },
      },
      {
        title: 'District',
        dataIndex: CareerAcademyReportDTO.district,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'CIP',
        dataIndex: CareerAcademyReportDTO.cip,
        sorter: true,
        render: (data: string, row: CareerAcademyReportDTO) => {
          return row.cip
        },
        filterType: FilterType.Text
      },
      {
        title: 'Program',
        dataIndex: CareerAcademyReportDTO.program,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Submitter',
        dataIndex: CareerAcademyReportDTO.submitter,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Review Year',
        dataIndex: CareerAcademyReportDTO.reviewYear,
        sorter: true,
      },
      {
        title: 'Career Academy',
        dataIndex: CareerAcademyReportDTO.careerAcademy,
        sorter: true,
        filterType: FilterType.BooleanRadio
      },
    ] as DataTableColumnProps<any>[];
  };

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  actionButtons.push(
    DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        return SelfStudyApiService.getCareerAcademyReportExport(tableRequest, props.academicYear ?? 0).then(file => {
          setExporting(false);
          FileDownload.downloadBase64StringAsFile(`Career_Academy_Report_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
        });
      },
      exporting
    )
  );

  if (rpps.length == 0 && statusTypes.length == 0 && serviceAreas.length == 0) {
    return <Skeleton />;
  }

  return (<>
    <DataTable
      // @ts-ignore
      ref={(element: any) => (_dataTable = element)}
      serverSide={true}
      tableProps={{
        rowKey: 'id',
        scroll: { x: 500 }
      }}
      globalSearch={true}
      buttonBar={actionButtons}
      columns={getColumnDefinitions()}
      fetchData={{
        fetch: (requestState: TableRequestDTO) => SelfStudyApiService.getCareerAcademyReport(requestState, props.academicYear ?? 0)
      }}
      stateSaving={{ enabled: true, tableUniqueKey: 'career_academy_report_list' }} />
  </>
  );
}

export default CareerAcademyReportDataTable;
