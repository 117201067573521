import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionApiService from '../../../api/InstitutionsApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetInstitutionDetailsHandler from '../../../handlerModels/GetInstitutionDetailsHandler';
import * as SaveInstitutionDetailsHandler from '../../../handlerModels/SaveInstitutionDetailsHandler';
import InstitutionDTO from '../../../models/InstitutionDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface InstitutionDetailsFormState extends BaseFormState {
  institution: InstitutionDTO;
}

interface InstitutionDetailsFormProps extends BaseFormProps {
  institution: InstitutionDTO;
  onSave?: (id: string) => void;
}

class InstitutionDetailsForm extends React.Component<InstitutionDetailsFormProps, InstitutionDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(InstitutionDTO.name, {
      required: true,
      name: InstitutionDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150),
      ],
    })
    .set(InstitutionDTO.abbreviation, {
      required: true,
      name: InstitutionDTO.abbreviation,
      label: 'Abbreviation',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(10),
      ],
    })
    .set(InstitutionDTO.code, {
      required: true,
      name: InstitutionDTO.code,
      label: 'Code',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters(),
      ],
    });

  constructor(props: InstitutionDetailsFormProps) {
    super(props);



    this.state = {
      institution: InstitutionDTO.create({
        Code: ''
      })
    };
  }

  componentDidMount() {
    this.setState({ institution: this.props.institution }, () => this.resetForm());
  }

  componentDidUpdate(prevProps: InstitutionDetailsFormProps) {
    if (this.props.institution && this.props.institution != prevProps.institution) {
      this.loadInstitution();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private loadInstitution() {
    this.setState({ loading: true });

    const loaders = [];
    if (this.props.institution) {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadExisting = () => {
    this.setState({ loading: true });
    return InstitutionApiService.getInstitutionDetails(this.props.institution.id ?? Guid.Empty())
      .then((results: GetInstitutionDetailsHandler.Result) => {
        if (results) {
          this.setState({ institution: results.institution ?? InstitutionDTO.create() });
          this._formRef.current?.resetFields();
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveInstitutionDetailsHandler.Request.create({
      institutionId: this.state.institution.id,
      institution: InstitutionDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    InstitutionApiService.saveInstitutionDetails(request)
      .then((result: SaveInstitutionDetailsHandler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          this.setState({ institution: result?.institution ?? InstitutionDTO.create() });

          message.success('Saved');

          if (this.props.onSave && result.institution?.id) {
            this.props.onSave(result.institution.id);
            this.resetForm();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });

          message.error('Error Saving');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.institution}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(InstitutionDTO.name)}
            {...ValidationUtil.getValidation(InstitutionDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(InstitutionDTO.abbreviation)}
            {...ValidationUtil.getValidation(InstitutionDTO.abbreviation, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(InstitutionDTO.code)}
            {...ValidationUtil.getValidation(InstitutionDTO.code, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space >
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTITUTION]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!FormUtil.canSubmit(this._formRef, this._formItems)} saving={this.state.submitting} />
            <ResetButton disabled={!FormUtil.hasChanged(this._formRef, this._formItems)} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.message) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default InstitutionDetailsForm
