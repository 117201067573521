import * as React from 'react';
import DisciplinesApiService from '../../../api/DisciplinesApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import DisciplineDTO from '../../../models/DisciplineDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface DisciplineDataTableProps {
}

interface DisciplineDataTableState extends BaseDataTableState<DisciplineDTO> {
  statusTypes: TableFilterOption[]
}

class DisciplineDataTable extends React.Component<DisciplineDataTableProps, DisciplineDataTableState> {
  private _dataTable: DataTable<DisciplineDTO> | undefined;

  constructor(props: DisciplineDataTableProps) {
    super(props);

    this.state = {
      statusTypes: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    TableFilterUtil.StatusTypes()
      .then(x => this.setState({ statusTypes: x }));
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Code',
        dataIndex: DisciplineDTO.code,
        sorter: true,
        filterType: FilterType.Text,
        width: 50,
      },
      {
        title: 'Name',
        dataIndex: DisciplineDTO.name,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Status',
        dataIndex: DisciplineDTO.statusTypeId,
        render: (data: string, row: DisciplineDTO) => {
          return row.statusType?.name;
        },
        sorter: true,
        filterType: FilterType.DropdownMulti,
        width: 75,
        dropdownFilterOptions: this.state.statusTypes,
      }
    ] as DataTableColumnProps<any>[];
  }

  private openDiscipline = (record: DisciplineDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.DISCIPLINE_DETAILS, { id: record.id }, {}));
    }
  }

  private addDiscipline = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_DISCIPLINE));
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return DisciplinesApiService.exportDisciplineList(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Discipline_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );
    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISCIPLINE])) {
      actionButtons.push(DataTableButtonUtil.Default('New Discipline', () => this.addDiscipline()));
    }

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openDiscipline}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => DisciplinesApiService.getDisciplineTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'disciplines_list' }} />
    );
  }
}

export default DisciplineDataTable;
