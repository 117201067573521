import * as React from 'react';
import Routes from '../../../config/Routes';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';
import { Steps } from 'antd';

interface NewCourseChangeRequestStepsProps extends BaseFormProps {
  current: number;
  changeRequestId: string;
}

function NewCourseChangeRequestSteps(props: NewCourseChangeRequestStepsProps) {
  return (
    <CollapsibleSteps current={props.current} direction='vertical' >
      <Steps.Step title={<NavigationStep enabled={props.current != 0 && props.changeRequestId != Guid.Empty()} title='Course Details' route={Routes.generate(Routes.EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_1, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 1 && props.changeRequestId != Guid.Empty()} title='Additional Information' route={Routes.generate(Routes.EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_2, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 2 && props.changeRequestId != Guid.Empty()} title='Review' route={Routes.generate(Routes.EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_REVIEW, { id: props.changeRequestId })} />}/>
      <Steps.Step title={<NavigationStep title='Submitted' />}/>
    </CollapsibleSteps>
  );
}

export default NewCourseChangeRequestSteps;