// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import UserCIPAssignmentDTO from '../models/UserCIPAssignmentDTO';
import * as AssignCipNumberToUserHandler from '../handlerModels/AssignCipNumberToUserHandler';
import * as UnassignCipNumberFromUserHandler from '../handlerModels/UnassignCipNumberFromUserHandler';
import * as SearchForCipNumberHandler from '../handlerModels/SearchForCipNumberHandler';

export class UserCipAssignmentApiService extends BaseApi {

    // post: api/userCipAssignments/cipAssignmentsForUser?userId=${encodeURIComponent(userId)}
    public getCipAssignmentsForUserTableData(tableRequest: TableRequestDTO, userId: string): Promise<TableResponseDTO<UserCIPAssignmentDTO>> {
        let url = `api/userCipAssignments/cipAssignmentsForUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserCIPAssignmentDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/userCipAssignments/assignCipNumberToUser
    public assignCipNumberToUser(assignCipNumberToUser: AssignCipNumberToUserHandler.Request): Promise<AssignCipNumberToUserHandler.Result> {
        let url = `api/userCipAssignments/assignCipNumberToUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AssignCipNumberToUserHandler.Result>(assignCipNumberToUser, 'post', url, true, false);
    }

    // post: api/userCipAssignments/export?userId=${encodeURIComponent(userId)}
    public getUserCipAssignmentDataTableExport(tableRequest: TableRequestDTO, userId: string): Promise<string> {
        let url = `api/userCipAssignments/export`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/userCipAssignments/unassignCipNumberToUser
    public unassignCipNumberToUser(unassignCipNumberFromUser: UnassignCipNumberFromUserHandler.Request): Promise<UnassignCipNumberFromUserHandler.Result> {
        let url = `api/userCipAssignments/unassignCipNumberToUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UnassignCipNumberFromUserHandler.Result>(unassignCipNumberFromUser, 'post', url, true, false);
    }

    // post: api/userCipAssignments/searchCipNumbers?searchText=${encodeURIComponent(searchText)}&userId=${encodeURIComponent(userId)}
    public searchForCipNumbers(searchText: string, userId: string): Promise<SearchForCipNumberHandler.Result> {
        let url = `api/userCipAssignments/searchCipNumbers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (searchText != null) {
            url += `${prefix}searchText=${encodeURIComponent(searchText)}`;
            prefix = '&';
        }
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForCipNumberHandler.Result>(null, 'post', url, true, false);
    }
}
var service = new UserCipAssignmentApiService();
export default service;
