import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TermsApiService from '../../../../api/TermsApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import TermDetailsForm from '../../../../components/forms/postSecondary/TermDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteTermHandler from '../../../../handlerModels/DeleteTermHandler';
import * as GetTermDetailsHandler from '../../../../handlerModels/GetTermDetailsHandler';
import TermDTO from '../../../../models/TermDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface TermDetailsPageState extends BaseFormState {
  termId: string;
  term?: TermDTO;
}

interface TermDetailsPageProps {
}

class TermDetailsPage extends React.Component<TermDetailsPageProps & RouteComponentProps<RouteObject>, TermDetailsPageState> {
  private readonly _termDetailsRef = React.createRef<TermDetailsForm>();

  constructor(props: TermDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      termId: Guid.Empty()
    };
  }

  componentDidMount() {
    const termId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (termId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      this.loadTerm(termId);
    }
  }

  private loadTerm = (termId: string) => {
    this.setState({ termId: termId });

    TermsApiService.getTermDetails(termId)
      .then((results: GetTermDetailsHandler.Result) => {
        if (results.term) {
          this.setState({ term: results.term });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._termDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private termDetailsSaved = (termId: string) => {
    this.setState({ isEditing: false });
    if (termId != this.state.termId) {
      HistoryUtil.replace(Routes.generate(Routes.TERM_DETAILS, { id: termId }));
    }
    else {
      this.loadTerm(termId);
    }
  }

  private deleteConfirmation = (id: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Term?',
      okText: 'Delete',
      onOk: () => this.deleteTerm(id)
    });
  }

  private deleteTerm = (termId: string) => {
    this.setState({ submitting: true });

    const request = DeleteTermHandler.Request.create({
      termId: termId,
    });

    TermsApiService.deleteTerm(request)
      .then((result: DeleteTermHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Term was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Term could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToTerms();
      });
  }

  private goToTerms = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.termId == Guid.Empty() ? 'New Term' : this.state.term?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToTerms}
          breadcrumbs={Breadcrumbs.termDetails(title, this.state.termId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.termId == Guid.Empty() ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TERM]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <TermDetailsForm
          ref={this._termDetailsRef}
          termId={this.state.termId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.termDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Term' key={this.state.termId} onClick={() => this.deleteConfirmation(this.state.termId)}  >
          Delete Term
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TERM]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(TermDetailsPage);