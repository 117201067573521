// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetSecondaryProgramScenarioDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SecondaryProgramScenarioDTO from '../models/SecondaryProgramScenarioDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    id: string | null;
    academicYear: number | null | null;
    secondaryProgramScenarioId: number | null | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: '00000000-0000-0000-0000-000000000000',
            academicYear: null,
            secondaryProgramScenarioId: null,
        },
        initValues);
    },
    id: 'id',
    academicYear: 'academicYear',
    secondaryProgramScenarioId: 'secondaryProgramScenarioId',
}

interface Result extends ResultDTO {
    secondaryProgramScenario: SecondaryProgramScenarioDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            secondaryProgramScenario: null,
        },
        initValues);
    },
    secondaryProgramScenario: 'secondaryProgramScenario',
}

export { Request, Result };