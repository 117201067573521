import { Input } from 'antd';
import * as React from 'react';

export interface MinMaxInputValue {
  min: number | undefined;
  max: number | undefined;
}

interface MinMaxInputState {
  min: number | undefined;
  max: number | undefined;
}

interface MinMaxInputProps {
  value?: MinMaxInputValue | null;
  onChange?: (value: MinMaxInputValue) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
  placeholder?: { min: string, max: string };
  prefix?: { min: string, max: string };
  showPrefix?: boolean;
  allowClear?: boolean;
  onOpenChange?: (open: boolean) => void;
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
  size?: 'large' | 'small' | 'default';
}

class MinMaxInput extends React.Component<MinMaxInputProps, MinMaxInputState> {
  constructor(props: MinMaxInputProps) {
    super(props);

    this.state = {
      min: undefined,
      max: undefined,
    }
  }

  private handleChanged = (min: number | undefined, max: number | undefined) => {
    if (this.props.onChange) {
      this.props.onChange({ min: min, max: max })
    }
  }

  private handleMinimumChanged = (value: any) => {
    const minValue = value.target.valueAsNumber;

    this.setState({
      min: minValue
    });

    this.handleChanged(minValue, this.state.max);
  }

  private handleMaximumChanged = (value: any) => {
    const maxValue = value.target.valueAsNumber;

    this.setState({
      max: maxValue
    });

    this.handleChanged(this.state.min, maxValue);
  }

  private handleCheckMax = (value: any) => {
    const maxValue = value.target.valueAsNumber;

    let max = this.state.min && maxValue < this.state.min ? this.state.min : maxValue;
    if (this.props.max) {
      max = (this.props.max ?? Number.MAX_SAFE_INTEGER) < max ? this.props.max : max;
      if (this.props.min) {
        max = this.props.min > max ? this.props.min : max;
      }
    }

    this.setState({
      max: max
    });

    this.handleChanged(this.state.min, max);
  }

  private handleCheckMin = (value: any) => {
    const minValue = value.target.valueAsNumber;

    let min = this.state.max && minValue > this.state.max ? this.state.max : minValue;
    if (this.props.min) {
      min = this.props.min > min ? this.props.min : min;
      if (this.props.max) {
        min = this.props.max < min ? this.props.max : min;
      }
    }

    this.setState({
      min: min
    });

    this.handleChanged(min, this.state.max);
  }

  componentDidMount() {
    this.setState({ max: this.props.value?.max, min: this.props.value?.min });
  }

  componentDidUpdate(prevProps: MinMaxInputProps) {
    if (this.props.value && this.props.value != prevProps.value) {
      this.setState({ max: this.props.value?.max, min: this.props.value?.min });
    }
  }

  render() {
    const max = this.props.max ?? Number.MAX_SAFE_INTEGER;
    const prefix = { min: '', max: '' };

    if (this.props.prefix?.max && this.props.prefix.min) {
      const propsPrefix = this.props.prefix;
      prefix.min = propsPrefix.min;
      prefix.max = propsPrefix.max;
    }
    else if (this.props.showPrefix) {
      prefix.min = 'Min';
      prefix.max = 'Max';
    }
    const min = this.props.min ?? 0;

    return (
      <Input.Group compact size={this.props.size ?? 'default'} >
        <label title="Minimum" htmlFor={'Minimum'} >
          <Input
            type='number'
            className={'min-max-input'}
            prefix={prefix.min}
            value={this.state.min}
            min={min}
            max={max}
            disabled={this.props.disabled}
            onPressEnter={this.handleCheckMin}
            onBlur={this.handleCheckMin}
            allowClear={this.props.allowClear}
            onChange={this.handleMinimumChanged} />
        </label>
        <label title="Maximum" htmlFor={'Maximum'} >
          <Input
            type='number'
            className={'min-max-input'}
            prefix={prefix.max}
            value={this.state.max}
            min={min}
            max={max}
            disabled={this.props.disabled}
            onPressEnter={this.handleCheckMax}
            onBlur={this.handleCheckMax}
            allowClear={this.props.allowClear}
            onChange={this.handleMaximumChanged} />
        </label>
      </Input.Group >
    );
  }
}

export default MinMaxInput;