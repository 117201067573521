// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// ConfirmSelfStudiesHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import AcademicYearDTO from '../models/AcademicYearDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYear: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYear: 0,
        },
        initValues);
    },
    academicYear: 'academicYear',
}

interface Result extends ResultDTO {
    academicYear: number;
    academicYearObj: AcademicYearDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            academicYear: 0,
            academicYearObj: null,
        },
        initValues);
    },
    academicYear: 'academicYear',
    academicYearObj: 'academicYearObj',
}

export { Request, Result };