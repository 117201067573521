import { Store } from 'redux';
import { StateStoreModel } from './state/StateStoreModel';

type AppStore = Store<StateStoreModel, any>;

let storeVar: AppStore | null = null;
/**
 * This will be used by the main index.tsx page to set the store after in has been created in the app.
 */
export function setStore(store: AppStore) {
  storeVar = store;
}

/**
 * This allows access to the redux store without taking a dependency on the store file
 * and all of its dependencies that can cause cyclic references if used in certain places.
 */
export default function getStore(): AppStore | null {
  return storeVar;
}
