// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SearchForDistrictUserHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import UserDTO from '../models/UserDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    districtIdentifier: number;
    academicYear: number;
    searchString: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            districtIdentifier: 0,
            academicYear: 0,
            searchString: "",
        },
        initValues);
    },
    districtIdentifier: 'districtIdentifier',
    academicYear: 'academicYear',
    searchString: 'searchString',
}

interface Result extends ResultDTO {
    users: UserDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            users: [],
        },
        initValues);
    },
    users: 'users',
}

export { Request, Result };