import { Card, Descriptions, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AuthorizedContent from '../../components/AuthorizedContent';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import HeaderPortal from '../../portals/HeaderPortal';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import ParameterUtil from '../../utils/ParameterUtil';

interface SecondaryLandingPageProps {
}

interface SecondaryLandingPageState {
  loading: boolean;
  academicYear: number;
}

class SecondaryLandingPage extends React.Component<SecondaryLandingPageProps & RouteComponentProps<RouteObject>, SecondaryLandingPageState> {
  constructor(props: SecondaryLandingPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    this.setState({ academicYear: id });
  }

  private goBack = () => {
    HistoryUtil.replace(Routes.generate(Routes.HOME));
  }

  render() {
    return (
      <Content >
        <HeaderPortal
          title="Secondary Home"
          onBack={this.goBack}
          breadcrumbs={Breadcrumbs.secondary()} />

        <Space direction='vertical' size='large'>
          <Card title="" >
            <Descriptions >
              <Descriptions.Item>
                <Link to={Routes.generate(Routes.ACADEMIC_YEARS)} >Program Review Years</Link>
              </Descriptions.Item>

              {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT, FeatureFlag.MANAGE_DISTRICT_CONTACT, FeatureFlag.MANAGE_DISTRICT_USERS]) ?
                <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_DISTRICT, FeatureFlag.MANAGE_DISTRICT_CONTACT, FeatureFlag.MANAGE_DISTRICT_USERS]}>
                  <Descriptions.Item>
                    <Link to={Routes.generate(Routes.ALL_DISTRICTS)} >Districts</Link>
                  </Descriptions.Item>
                </AuthorizedContent> :
                null}

              {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER]) ?
                <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER]}>
                  <Descriptions.Item>
                    <Link to={Routes.generate(Routes.REGIONAL_PLANNING_PARTNERS)} >Regional Planning Partner</Link>
                  </Descriptions.Item>
                </AuthorizedContent> :
                null}

              {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_PROGRAM]) ?
                <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_PROGRAM]}>
                  <Descriptions.Item>
                    <Link to={Routes.generate(Routes.ALL_SECONDARY_PROGRAMS)} >Programs</Link>
                  </Descriptions.Item>
                </AuthorizedContent> :
                null}

              {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_COURSE]) ?
                <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_COURSE]}>
                  <Descriptions.Item>
                    <Link to={Routes.generate(Routes.ALL_SECONDARY_COURSES)} >Courses</Link>
                  </Descriptions.Item>
                </AuthorizedContent> :
                null}
            </Descriptions>
          </Card>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(SecondaryLandingPage));
