import { Button, message, Row, Steps } from 'antd';
import moment from 'moment';
import * as React from 'react';
import AcademicYearStatusType from '../../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../../consts/FeatureFlag';
import AcademicYearDTO from '../../../../models/AcademicYearDTO';
import BaseFormProps from '../../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../../utils/AuthorizationUtil';
import DateUtil from '../../../../utils/DateTimeUtil';
import AcademicYearSelfStudyStatsDTO from '../../../../models/AcademicYearSelfStudyStatsDTO';
import * as SaveSelfStudyDECommentReleaseHandler from '../../../../handlerModels/SaveSelfStudyDECommentReleaseHandler';
import { SendOutlined } from '@ant-design/icons';
import SelfStudyApiService from '../../../../api/SelfStudyApiService';
import modal from 'antd/lib/modal';

interface CommentReleaseStepProps extends BaseFormProps {
  academicYear: AcademicYearDTO;
  compactView?: boolean;
  selfStudyStats: AcademicYearSelfStudyStatsDTO | null;
  loadAcademicYear?: (id: number) => void;
}

const finishedStatusTypes = [
  AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS,
  AcademicYearStatusType.CLOSED,
]


export default function CommentReleaseStep(props: CommentReleaseStepProps) {
  let notSubmittedCount = (props.selfStudyStats?.instructorNotStarted ?? 0) + (props.selfStudyStats?.instructorInProgress ?? 0) + (props.selfStudyStats?.instructorPendingApproval ?? 0);
  notSubmittedCount += (props.selfStudyStats?.rppNotStarted ?? 0) + (props.selfStudyStats?.rppInProgress ?? 0) + (props.selfStudyStats?.rppPendingApproval ?? 0);
  notSubmittedCount += (props.selfStudyStats?.doeNotStarted ?? 0) + (props.selfStudyStats?.doeInProgress ?? 0) + (props.selfStudyStats?.doePendingApproval ?? 0);

  const active = props.academicYear?.academicYearStatusTypeId === AcademicYearStatusType.REVIEWSELFSTUDYPROGRAMS && notSubmittedCount == 0 && AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR]);
  const finished = finishedStatusTypes.indexOf(props.academicYear.academicYearStatusTypeId) >= 0;
  const [submitting, setSubmitting] = React.useState(false);

  const handleReleaseDEReleaseComments = () => {
    const request = SaveSelfStudyDECommentReleaseHandler.Request.create({
      deCommentRelease: true,
      academicYear: props.academicYear.id
    });

    SelfStudyApiService.saveSelfStudyDECommentRelease(request)
      .then((result: SaveSelfStudyDECommentReleaseHandler.Result) => {
        if (result?.succeeded) {
          if (props.loadAcademicYear) {
            props.loadAcademicYear(result.academicYear?.id ?? 0);
          }

          message.success('Comments Released');

        }
        else {
          message.error('Comments could not be released');
        }
      })
      .catch(() => {
        message.error('Comments could not be released');
      })
      .finally(() => {
        setSubmitting(false);
      });;
  }

  const releaseDECommentsModal = () => {
    setSubmitting(true);
    return modal.confirm({
      title: 'Release DE Comments',
      content: 'Are you sure you want to release DE Comments?',
      onCancel: () => { setSubmitting(false) },
      onOk: handleReleaseDEReleaseComments,
      okText: 'Release',
    });
  }

  const date = DateUtil.shortFormat(props.academicYear.commentReleaseDate);

  const renderContent = () => {
    if (active && AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR]) && active) {
      if (props.compactView === false && DateUtil.isAfterRange(props.academicYear.commentReleaseDate ?? moment())) {
        return <Row>
          <Button title='Release Comments' type='primary' icon={<SendOutlined />} loading={submitting} onClick={releaseDECommentsModal} >Release Comments</Button>
        </Row>;
      }
    }
  }

  return <Steps.Step status={finished ? 'finish' : active ? 'process' : 'wait'} title='Approval Comments Release' subTitle={date} description={renderContent()} />;
}