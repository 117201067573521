// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import InstructionalLevelDataTableDTO from '../models/InstructionalLevelDataTableDTO';
import * as GetInstructionalLevelDetailsHandler from '../handlerModels/GetInstructionalLevelDetailsHandler';
import * as SaveInstructionalLevelDetailsHandler from '../handlerModels/SaveInstructionalLevelDetailsHandler';
import * as GetInstructionalLevelHandler from '../handlerModels/GetInstructionalLevelHandler';
import * as DeleteInstructionalLevelHandler from '../handlerModels/DeleteInstructionalLevelHandler';

export class InstructionalLevelApiService extends BaseApi {

    // post: api/instructionalLevel
    public getInstructionalLevelTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<InstructionalLevelDataTableDTO>> {
        let url = `api/instructionalLevel`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstructionalLevelDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/instructionalLevel/export
    public getInstructionalLevelDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/instructionalLevel/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/instructionalLevel/${id}
    public getInstructionalLevelDetails(id: number): Promise<GetInstructionalLevelDetailsHandler.Result> {
        let url = `api/instructionalLevel/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstructionalLevelDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/instructionalLevel/save
    public saveInstructionalLevelDetails(saveInstructionalLevelDetails: SaveInstructionalLevelDetailsHandler.Request): Promise<SaveInstructionalLevelDetailsHandler.Result> {
        let url = `api/instructionalLevel/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveInstructionalLevelDetailsHandler.Result>(saveInstructionalLevelDetails, 'post', url, true, false);
    }

    // post: api/instructionalLevel/getInstructionalLevels
    public getInstructionalLevels(): Promise<GetInstructionalLevelHandler.Result> {
        let url = `api/instructionalLevel/getInstructionalLevels`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstructionalLevelHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/instructionalLevel/deleteInstructionalLevel
    public deleteInstructionalLevel(deleteInstructionalLevel: DeleteInstructionalLevelHandler.Request): Promise<DeleteInstructionalLevelHandler.Result> {
        let url = `api/instructionalLevel/deleteInstructionalLevel`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteInstructionalLevelHandler.Result>(deleteInstructionalLevel, 'post', url, true, false);
    }
}
var service = new InstructionalLevelApiService();
export default service;
