import { RightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Layout, Row, Space } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AnnouncementListing from '../components/AnnouncementListing';
import WorkQueueDataTable from '../components/datatables/WorkQueueDataTable';
import Breadcrumbs from '../config/Breadcrumbs';
import { ColumnWidths } from '../config/ColumnWidths';
import Routes from '../config/Routes';
import UserSecurity from '../models/UserSecurity';
import HeaderPortal from '../portals/HeaderPortal';
import BasePageState from '../redux/bases/BasePageState';
import { StateStoreModel } from '../redux/state/StateStoreModel';
import DateTimeUtil from '../utils/DateTimeUtil';
import HistoryUtil from '../utils/HistoryUtil';

interface DashboardState extends BasePageState {
}

interface DashboardProps {
  userInfo: UserSecurity | null;
}

class DashboardPage extends React.Component<DashboardProps & RouteComponentProps<RouteObject>, DashboardState> {
  constructor(props: DashboardProps & RouteComponentProps<RouteObject>) {
    super(props);

  }

  private goToPastAnnouncements = () => {
    HistoryUtil.push(Routes.generate(Routes.PAST_ANNOUNCEMENTS));
  }

  private goToWorkItems = () => {
    HistoryUtil.push(Routes.generate(Routes.WORK_ITEMS));
  }

  render() {
    let greating = 'Good ' + DateTimeUtil.getTimeOfDayDisplay();
    if (this.props.userInfo) {
      greating += ', ' + this.props.userInfo.firstName;
    }
    greating += '!'

    return (
      <Layout>
        <HeaderPortal
          title={greating}
          pageTitle="Home"
          breadcrumbs={Breadcrumbs.dashboard()} />

        <Row gutter={[16, 16]}>
          <Col {...ColumnWidths.HALF}>
            <Card title="Work Queue" >
              <Space direction='vertical'>
                <WorkQueueDataTable active={true} />

                <Button
                  className="align-center"
                  shape="round"
                  icon={<RightOutlined />}
                  size="small"
                  onClick={this.goToWorkItems}>
                  View All
                </Button>
              </Space>
            </Card>
          </Col>

          <Col {...ColumnWidths.HALF}>
            <Card title="Announcements" >
              <AnnouncementListing
                includeShown={false}
                fetchAmount={4}
                actionButtonIcon={<RightOutlined />}
                actionButtonText="View All"
                actionButtonAction={this.goToPastAnnouncements}
                actionAvailable={true} />
            </Card >
          </Col>
        </Row>
      </Layout>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    userInfo: state.UserSession.Value
  };
}

export default withRouter(connect(mapStateToProps)(DashboardPage));
