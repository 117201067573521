// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SecondaryProgramDTO from './SecondaryProgramDTO';
import SecondaryCourseDTO from './SecondaryCourseDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SecondaryProgramCourseDTO extends EntitySelectorDTO { 
	id: string | null;
	secondaryProgramCourseId: number;
	academicYear: number;
	programId: string | null;
	programIdentifier: number;
	program: SecondaryProgramDTO | null;
	courseIdentifier: number;
	courseId: string | null;
	course: SecondaryCourseDTO | null;
	isOfferTeach: boolean | null | null;
	isCoreCourse: boolean | null | null;
	districtId: string | null;
	programTitle: string | null;
}

const SecondaryProgramCourseDTO = {
    className: 'SecondaryProgramCourseDTO',
    create: (initValues?: Partial<SecondaryProgramCourseDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			secondaryProgramCourseId: 0,
			academicYear: 0,
			programId: '00000000-0000-0000-0000-000000000000',
			programIdentifier: 0,
			program: null,
			courseIdentifier: 0,
			courseId: '00000000-0000-0000-0000-000000000000',
			course: null,
			isOfferTeach: null,
			isCoreCourse: null,
			districtId: '00000000-0000-0000-0000-000000000000',
			programTitle: "",
        },
        initValues);
    },
	id: 'id',
	secondaryProgramCourseId: 'secondaryProgramCourseId',
	academicYear: 'academicYear',
	programId: 'programId',
	programIdentifier: 'programIdentifier',
	program: 'program',
	courseIdentifier: 'courseIdentifier',
	courseId: 'courseId',
	course: 'course',
	isOfferTeach: 'isOfferTeach',
	isCoreCourse: 'isCoreCourse',
	districtId: 'districtId',
	programTitle: 'programTitle',
};

export default SecondaryProgramCourseDTO;