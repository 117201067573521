import { Alert, Input, message, Modal, notification, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import { SelectValue } from 'antd/lib/select';
import * as React from 'react';
import RolesApiService from '../../api/RolesApiService';
import FeatureFlag from '../../consts/FeatureFlag';
import * as GetRoleDetailsHandler from '../../handlerModels/GetRoleDetailsHandler';
import * as SaveRoleDetailsHandler from '../../handlerModels/SaveRoleDetailsHandler';
import AARoleTypeDTO from '../../models/AARoleTypeDTO';
import RoleDTO from '../../models/RoleDTO';
import RoleTypeDTO from '../../models/RoleTypeDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import FormUtil from '../../utils/FormUtil';
import Guid from '../../utils/Guid';
import LookupsUtil from '../../utils/LookupsUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import ResetButton from '../buttons/ResetButton';
import SaveButton from '../buttons/SaveButton';

interface RoleDetailsFormState extends BaseFormState {
  role: RoleDTO;
  roleTypes: RoleTypeDTO[];
  aaRoleTypes: AARoleTypeDTO[];
}

interface RoleDetailsFormProps extends BaseFormProps {
  roleId: string;
  onSave?: (roleId: string) => void;
}

class RoleDetailsForm extends React.Component<RoleDetailsFormProps, RoleDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RoleDetailsFormProps) {
    super(props);

    this.state = {
      role: RoleDTO.create({
        roleTypeId: '',
        aaRoleTypeId: null
      }),
      roleTypes: [],
      aaRoleTypes: []
    };
  }

  componentDidMount() {
    this.loadRole();
  }

  componentDidUpdate(prevProps: RoleDetailsFormProps) {
    if (this.props.roleId != prevProps.roleId) {
      this.loadRole();
    }
  }

  public resetForm = () => {
    this.setState({ altered: false });
    this._formRef.current?.resetFields();
  }

  private loadRole = () => {
    this.setState({ loading: true });
    const loaders = [];

    if (this.state.roleTypes.length == 0) {
      loaders.push(this.loadRoleTypes());
    }

    if (this.state.aaRoleTypes.length == 0) {
      loaders.push(this.loadAARoleTypes());
    }

    if (this.props.roleId == Guid.Empty()) {
      this.loadNew();
    }
    else {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders)
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private loadNew = () => {
    this.setState({
      isEditing: true,
    });
  }

  private loadExisting = () => {
    return RolesApiService.getRoleDetails(this.props.roleId)
      .then((results: GetRoleDetailsHandler.Result) => {
        if (results.role) {
          this.setState({ role: results.role });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadRoleTypes = () => {
    return LookupsUtil.getAll<RoleTypeDTO>(RoleTypeDTO.className)
      .then((results: RoleTypeDTO[]) => {
        this.setState({ roleTypes: results });
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAARoleTypes = () => {
    return LookupsUtil.getAll<AARoleTypeDTO>(AARoleTypeDTO.className)
      .then((results: AARoleTypeDTO[]) => {
        this.setState({ aaRoleTypes: results });
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true })
  }

  private promptConfirmRoleTypeChange = (value: SelectValue) => {
    if (this.props.roleId != Guid.Empty() && value != this.state.role?.roleTypeId) {
      Modal.confirm({
        title: 'Are you sure you want to change the role type? This may cause changes to the actions users can take in the application.',
        okText: 'Change Role Type',
        okType: 'danger',
        onCancel: () => {
          this._formRef.current?.resetFields(['roleTypeId']);
        },
      });
    }
  }

  private promptConfirmAARoleTypeChange = (value: SelectValue) => {
    if (this.props.roleId != Guid.Empty() && value != this.state.role?.roleTypeId) {
      Modal.confirm({
        title: 'An AA role can only be assigned to one STICS Role, assigning this aa role may remove it from another role.',
        okText: 'Assign AA Role Type',
        okType: 'danger',
        onCancel: () => {
          this._formRef.current?.resetFields(['aaroleTypeId']);
        },
      });
    }
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveRoleDetailsHandler.Request.create(
      {
        roleId: this.props.roleId,
        role: RoleDTO.create((this._formRef ? (this._formRef.current as any).getFieldsValue() : null))
      }
    );

    RolesApiService.saveRole(request)
      .then((result: SaveRoleDetailsHandler.Result) => {
        if (result.succeeded) {
          this.setState({
            submitted: true, altered: false
          });

          message.success('Saved');
          if (this.props.onSave && result.roleId) {
            this.props.onSave(result.roleId);
          }

          LookupsUtil.invalidateCache(RoleDTO.className);
        }
        else {
          this.setState({
            submitted: true, altered: false
          });
          this.setState({ fieldErrors: result.fieldErrors })
          notification.error({ message: 'Error!', description: 'Role could not be saved', });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        notification.error({ message: 'Error!', description: 'Role could not be saved', });
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private _formItems = new Map<string, FormItemProps>()
    .set(RoleDTO.name, {
      required: true,
      name: RoleDTO.name,
      label: 'Name',
      rules: [ValidationRuleUtil.required()]
    })
    .set(RoleDTO.roleTypeId, {
      required: true,
      name: RoleDTO.roleTypeId,
      label: 'Role Type',
      rules: [ValidationRuleUtil.required()]
    })
    .set(RoleDTO.aaRoleTypeId, {
      required: false,
      name: RoleDTO.aaRoleTypeId,
      label: 'AA Role Type',
    })

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form ref={this._formRef} layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}
          initialValues={this.state.role}>
          <FormItem
            key={RoleDTO.name}
            {...ValidationUtil.getValidation(RoleDTO.name, this.state.fieldErrors, this.state.submitted)}
            {...this._formItems.get(RoleDTO.name)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            key={RoleDTO.roleTypeId}
            {...ValidationUtil.getValidation(RoleDTO.roleTypeId, this.state.fieldErrors, this.state.submitted)}
            {...this._formItems.get(RoleDTO.roleTypeId)}>
            <Select onChange={(value) => this.promptConfirmRoleTypeChange(value)}
              disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
              {this.state.roleTypes.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option> })}
            </Select>
          </FormItem>

          <FormItem
            key={RoleDTO.aaRoleTypeId}
            {...ValidationUtil.getValidation(RoleDTO.aaRoleTypeId, this.state.fieldErrors, this.state.submitted)}
            {...this._formItems.get(RoleDTO.aaRoleTypeId)}>
            <Select onChange={(value) => this.promptConfirmAARoleTypeChange(value)}
              disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
              {this.state.aaRoleTypes.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option> })}
            </Select>
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing && AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_ROLE])) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveButton disabled={this.state.loading} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton disabled={!FormUtil.hasChanged(this._formRef, this._formItems)} resetting={this.state.resetting} onConfirm={this.resetForm} />
        </ Space>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default RoleDetailsForm;
