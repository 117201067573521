// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as SaveNoticeOfIntentChangeRequestStep1Handler from '../handlerModels/SaveNoticeOfIntentChangeRequestStep1Handler';
import * as SaveNoticeOfIntentChangeRequestStep2Handler from '../handlerModels/SaveNoticeOfIntentChangeRequestStep2Handler';
import * as SaveNoticeOfIntentChangeRequestStep3Handler from '../handlerModels/SaveNoticeOfIntentChangeRequestStep3Handler';
import * as SaveNoticeOfIntentChangeRequestStep4Handler from '../handlerModels/SaveNoticeOfIntentChangeRequestStep4Handler';
import * as SaveNoticeOfIntentChangeRequestStep5Handler from '../handlerModels/SaveNoticeOfIntentChangeRequestStep5Handler';
import * as SaveNoticeOfIntentChangeRequestStep6Handler from '../handlerModels/SaveNoticeOfIntentChangeRequestStep6Handler';
import * as SaveNoticeOfIntentChangeRequestStep7Handler from '../handlerModels/SaveNoticeOfIntentChangeRequestStep7Handler';
import * as SubmitNoticeOfIntentChangeRequestStep1Handler from '../handlerModels/SubmitNoticeOfIntentChangeRequestStep1Handler';
import * as SubmitNoticeOfIntentChangeRequestStep2Handler from '../handlerModels/SubmitNoticeOfIntentChangeRequestStep2Handler';
import * as SubmitNoticeOfIntentChangeRequestStep3Handler from '../handlerModels/SubmitNoticeOfIntentChangeRequestStep3Handler';
import * as SubmitNoticeOfIntentChangeRequestStep4Handler from '../handlerModels/SubmitNoticeOfIntentChangeRequestStep4Handler';
import * as SubmitNoticeOfIntentChangeRequestStep5Handler from '../handlerModels/SubmitNoticeOfIntentChangeRequestStep5Handler';
import * as SubmitNoticeOfIntentChangeRequestStep6Handler from '../handlerModels/SubmitNoticeOfIntentChangeRequestStep6Handler';
import * as SubmitNoticeOfIntentChangeRequestStep7Handler from '../handlerModels/SubmitNoticeOfIntentChangeRequestStep7Handler';
import * as GetNoticeOfIntentChangeRequestHandler from '../handlerModels/GetNoticeOfIntentChangeRequestHandler';
import * as GetNoticeOfIntentChangeRequestStep1Handler from '../handlerModels/GetNoticeOfIntentChangeRequestStep1Handler';
import * as GetNoticeOfIntentChangeRequestStep2Handler from '../handlerModels/GetNoticeOfIntentChangeRequestStep2Handler';
import * as GetNoticeOfIntentChangeRequestStep3Handler from '../handlerModels/GetNoticeOfIntentChangeRequestStep3Handler';
import * as GetNoticeOfIntentChangeRequestStep4Handler from '../handlerModels/GetNoticeOfIntentChangeRequestStep4Handler';
import * as GetNoticeOfIntentChangeRequestStep5Handler from '../handlerModels/GetNoticeOfIntentChangeRequestStep5Handler';
import * as GetNoticeOfIntentChangeRequestStep6Handler from '../handlerModels/GetNoticeOfIntentChangeRequestStep6Handler';
import * as GetNoticeOfIntentChangeRequestStep7Handler from '../handlerModels/GetNoticeOfIntentChangeRequestStep7Handler';
import * as GetNoticeOfIntentChangeRequestsHandler from '../handlerModels/GetNoticeOfIntentChangeRequestsHandler';
import * as GetNoticeOfIntentChangeRequestByCipReclassificationHandler from '../handlerModels/GetNoticeOfIntentChangeRequestByCipReclassificationHandler';

export class NoticeOfIntentChangeRequestApiService extends BaseApi {

    // post: api/noticeOfIntentChangeRequests/saveStep1
    public saveStep1(saveNoticeOfIntentChangeRequestStep1: SaveNoticeOfIntentChangeRequestStep1Handler.Request): Promise<SaveNoticeOfIntentChangeRequestStep1Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/saveStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNoticeOfIntentChangeRequestStep1Handler.Result>(saveNoticeOfIntentChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/saveStep2
    public saveStep2(saveNoticeOfIntentChangeRequestStep2: SaveNoticeOfIntentChangeRequestStep2Handler.Request): Promise<SaveNoticeOfIntentChangeRequestStep2Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/saveStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNoticeOfIntentChangeRequestStep2Handler.Result>(saveNoticeOfIntentChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/saveStep3
    public saveStep3(saveNoticeOfIntentChangeRequestStep3: SaveNoticeOfIntentChangeRequestStep3Handler.Request): Promise<SaveNoticeOfIntentChangeRequestStep3Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/saveStep3`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNoticeOfIntentChangeRequestStep3Handler.Result>(saveNoticeOfIntentChangeRequestStep3, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/saveStep4
    public saveStep4(saveNoticeOfIntentChangeRequestStep4: SaveNoticeOfIntentChangeRequestStep4Handler.Request): Promise<SaveNoticeOfIntentChangeRequestStep4Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/saveStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNoticeOfIntentChangeRequestStep4Handler.Result>(saveNoticeOfIntentChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/saveStep5
    public saveStep5(saveNoticeOfIntentChangeRequestStep5: SaveNoticeOfIntentChangeRequestStep5Handler.Request): Promise<SaveNoticeOfIntentChangeRequestStep5Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/saveStep5`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNoticeOfIntentChangeRequestStep5Handler.Result>(saveNoticeOfIntentChangeRequestStep5, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/saveStep6
    public saveStep6(saveNoticeOfIntentChangeRequestStep6: FormData): Promise<SaveNoticeOfIntentChangeRequestStep6Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/saveStep6`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNoticeOfIntentChangeRequestStep6Handler.Result>(saveNoticeOfIntentChangeRequestStep6, 'post', url, false, false);
    }

    // post: api/noticeOfIntentChangeRequests/saveStep7
    public saveStep7(saveNoticeOfIntentChangeRequestStep7: FormData): Promise<SaveNoticeOfIntentChangeRequestStep7Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/saveStep7`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNoticeOfIntentChangeRequestStep7Handler.Result>(saveNoticeOfIntentChangeRequestStep7, 'post', url, false, false);
    }

    // post: api/noticeOfIntentChangeRequests/submitStep1
    public submitStep1(submitNoticeOfIntentChangeRequestStep1: SubmitNoticeOfIntentChangeRequestStep1Handler.Request): Promise<SubmitNoticeOfIntentChangeRequestStep1Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/submitStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNoticeOfIntentChangeRequestStep1Handler.Result>(submitNoticeOfIntentChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/submitStep2
    public submitStep2(submitNoticeOfIntentChangeRequestStep2: SubmitNoticeOfIntentChangeRequestStep2Handler.Request): Promise<SubmitNoticeOfIntentChangeRequestStep2Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/submitStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNoticeOfIntentChangeRequestStep2Handler.Result>(submitNoticeOfIntentChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/submitStep3
    public submitStep3(submitNoticeOfIntentChangeRequestStep3: SubmitNoticeOfIntentChangeRequestStep3Handler.Request): Promise<SubmitNoticeOfIntentChangeRequestStep3Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/submitStep3`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNoticeOfIntentChangeRequestStep3Handler.Result>(submitNoticeOfIntentChangeRequestStep3, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/submitStep4
    public submitStep4(submitNoticeOfIntentChangeRequestStep4: SubmitNoticeOfIntentChangeRequestStep4Handler.Request): Promise<SubmitNoticeOfIntentChangeRequestStep4Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/submitStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNoticeOfIntentChangeRequestStep4Handler.Result>(submitNoticeOfIntentChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/submitStep5
    public submitStep5(submitNoticeOfIntentChangeRequestStep5: SubmitNoticeOfIntentChangeRequestStep5Handler.Request): Promise<SubmitNoticeOfIntentChangeRequestStep5Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/submitStep5`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNoticeOfIntentChangeRequestStep5Handler.Result>(submitNoticeOfIntentChangeRequestStep5, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/submitStep6
    public submitStep6(submitNoticeOfIntentChangeRequestStep6: FormData): Promise<SubmitNoticeOfIntentChangeRequestStep6Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/submitStep6`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNoticeOfIntentChangeRequestStep6Handler.Result>(submitNoticeOfIntentChangeRequestStep6, 'post', url, false, false);
    }

    // post: api/noticeOfIntentChangeRequests/submitStep7
    public submitStep7(submitNoticeOfIntentChangeRequestStep7: FormData): Promise<SubmitNoticeOfIntentChangeRequestStep7Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/submitStep7`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNoticeOfIntentChangeRequestStep7Handler.Result>(submitNoticeOfIntentChangeRequestStep7, 'post', url, false, false);
    }

    // post: api/noticeOfIntentChangeRequests/get
    public get(getNoticeOfIntentChangeRequest: GetNoticeOfIntentChangeRequestHandler.Request): Promise<GetNoticeOfIntentChangeRequestHandler.Result> {
        let url = `api/noticeOfIntentChangeRequests/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestHandler.Result>(getNoticeOfIntentChangeRequest, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/getStep1
    public getStep1(getNoticeOfIntentChangeRequestStep1: GetNoticeOfIntentChangeRequestStep1Handler.Request): Promise<GetNoticeOfIntentChangeRequestStep1Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/getStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestStep1Handler.Result>(getNoticeOfIntentChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/getStep2
    public getStep2(getNoticeOfIntentChangeRequestStep2: GetNoticeOfIntentChangeRequestStep2Handler.Request): Promise<GetNoticeOfIntentChangeRequestStep2Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/getStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestStep2Handler.Result>(getNoticeOfIntentChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/getStep3
    public getStep3(getNoticeOfIntentChangeRequestStep3: GetNoticeOfIntentChangeRequestStep3Handler.Request): Promise<GetNoticeOfIntentChangeRequestStep3Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/getStep3`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestStep3Handler.Result>(getNoticeOfIntentChangeRequestStep3, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/getStep4
    public getStep4(getNoticeOfIntentChangeRequestStep4: GetNoticeOfIntentChangeRequestStep4Handler.Request): Promise<GetNoticeOfIntentChangeRequestStep4Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/getStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestStep4Handler.Result>(getNoticeOfIntentChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/getStep5
    public getStep5(getNoticeOfIntentChangeRequestStep5: GetNoticeOfIntentChangeRequestStep5Handler.Request): Promise<GetNoticeOfIntentChangeRequestStep5Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/getStep5`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestStep5Handler.Result>(getNoticeOfIntentChangeRequestStep5, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/getStep6
    public getStep6(getNoticeOfIntentChangeRequestStep6: GetNoticeOfIntentChangeRequestStep6Handler.Request): Promise<GetNoticeOfIntentChangeRequestStep6Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/getStep6`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestStep6Handler.Result>(getNoticeOfIntentChangeRequestStep6, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/getStep7
    public getStep7(getNoticeOfIntentChangeRequestStep7: GetNoticeOfIntentChangeRequestStep7Handler.Request): Promise<GetNoticeOfIntentChangeRequestStep7Handler.Result> {
        let url = `api/noticeOfIntentChangeRequests/getStep7`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestStep7Handler.Result>(getNoticeOfIntentChangeRequestStep7, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/getNoticeOfIntent
    public getAllNoticeOfIntents(getNoticeOfIntentChangeRequests: GetNoticeOfIntentChangeRequestsHandler.Request): Promise<GetNoticeOfIntentChangeRequestsHandler.Result> {
        let url = `api/noticeOfIntentChangeRequests/getNoticeOfIntent`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestsHandler.Result>(getNoticeOfIntentChangeRequests, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequests/getByCipReclassification
    public getByCipReclassification(getNoticeOfIntentChangeRequestByCipReclassification: GetNoticeOfIntentChangeRequestByCipReclassificationHandler.Request): Promise<GetNoticeOfIntentChangeRequestByCipReclassificationHandler.Result> {
        let url = `api/noticeOfIntentChangeRequests/getByCipReclassification`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestByCipReclassificationHandler.Result>(getNoticeOfIntentChangeRequestByCipReclassification, 'post', url, true, false);
    }
}
var service = new NoticeOfIntentChangeRequestApiService();
export default service;
