import { Card, Space } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EPRExport from '../../../components/postSecondary/EPRExport';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import InstitutionDTO from '../../../models/InstitutionDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import Guid from '../../../utils/Guid';
import ComplianceReportDownloadForm from '../../../components/forms/postSecondary/ComplianceReportDownloadForm';
import ProgramMasterReportDownloadForm from '../../../components/forms/postSecondary/ProgramMasterReportDownloadForm';
import HistoryUtil from '../../../utils/HistoryUtil';

interface DownloadsState extends BaseFormState {
  institutionId: string;
  institution?: InstitutionDTO;
}

interface DownloadsProps {
  institution: InstitutionDTO | null;
}

class DownloadsPage extends React.Component<DownloadsProps & RouteComponentProps<RouteObject>, DownloadsState> {


  constructor(props: DownloadsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      institutionId: Guid.Empty()
    };
  }

  private goHome = () => {
    HistoryUtil.push(Routes.generate(Routes.POST_SECONDARY_HOME));
  }

  render() {

    return (
      <Space direction='vertical'>
        <HeaderPortal title="Downloads" onBack={this.goHome} breadcrumbs={Breadcrumbs.downLoads()} />
        <Card title='Compliance Report'>
          <ComplianceReportDownloadForm institutionId={this.props.institution?.id ?? undefined} />
        </Card>
        <Card title='Program Master Report'>
          <ProgramMasterReportDownloadForm institutionId={this.props.institution?.id ?? undefined} />
        </Card>
        <Card title='EPR Download'>
          <EPRExport institutionId={this.props.institution?.id ?? Guid.Empty()} />
        </Card>
      </Space>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(DownloadsPage));
