import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import TransferMajorDisciplineFrameworkElementApiService from '../../../api/TransferMajorDisciplineFrameworkElementApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetTransferMajorDisciplineFrameworkElementDetailsHandler from '../../../handlerModels/GetTransferMajorDisciplineFrameworkElementDetailsHandler';
import * as SaveTransferMajorDisciplineFrameworkElementDetailsHandler from '../../../handlerModels/SaveTransferMajorDisciplineFrameworkElementDetailsHandler';
import TransferMajorDisciplineFrameworkElementDTO from '../../../models/TransferMajorDisciplineFrameworkElementDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface TransferMajorDisciplineFrameworkElementDetailsFormState extends BaseFormState {
  transferMajorDisciplineFrameworkElementId: number;
  transferMajorDisciplineFrameworkElement: TransferMajorDisciplineFrameworkElementDTO | null;
}

interface TransferMajorDisciplineFrameworkElementDetailsFormProps extends BaseFormProps {
  transferMajorDisciplineFrameworkElementId: number;
  onSave?: (transferMajorDisciplineFrameworkElementId: number) => void;
}

class TransferMajorDisciplineFrameworkElementDetailsForm extends React.Component<TransferMajorDisciplineFrameworkElementDetailsFormProps, TransferMajorDisciplineFrameworkElementDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(TransferMajorDisciplineFrameworkElementDTO.name, {
      required: true,
      name: TransferMajorDisciplineFrameworkElementDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
      ]
    });

  constructor(props: TransferMajorDisciplineFrameworkElementDetailsFormProps) {
    super(props);

    this.state = {
      transferMajorDisciplineFrameworkElementId: 0,
      transferMajorDisciplineFrameworkElement: TransferMajorDisciplineFrameworkElementDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadTransferMajorDisciplineFrameworkElement();
  }

  componentDidUpdate(prevProps: TransferMajorDisciplineFrameworkElementDetailsFormProps) {
    if (this.props.transferMajorDisciplineFrameworkElementId && this.props.transferMajorDisciplineFrameworkElementId != prevProps.transferMajorDisciplineFrameworkElementId) {
      this.setState({ transferMajorDisciplineFrameworkElementId: this.props.transferMajorDisciplineFrameworkElementId });
      this.loadTransferMajorDisciplineFrameworkElement();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadTransferMajorDisciplineFrameworkElement() {
    if (this.props.transferMajorDisciplineFrameworkElementId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.transferMajorDisciplineFrameworkElementId) {
      TransferMajorDisciplineFrameworkElementApiService.getTransferMajorDisciplineFrameworkElementDetails(this.props.transferMajorDisciplineFrameworkElementId)
        .then((results: GetTransferMajorDisciplineFrameworkElementDetailsHandler.Result) => {
          if (results && results.transferMajorDisciplineFrameworkElement) {
            this.setState({ transferMajorDisciplineFrameworkElementId: results.transferMajorDisciplineFrameworkElement.id, transferMajorDisciplineFrameworkElement: results.transferMajorDisciplineFrameworkElement, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ transferMajorDisciplineFrameworkElement: TransferMajorDisciplineFrameworkElementDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveTransferMajorDisciplineFrameworkElementDetailsHandler.Request.create({
      transferMajorDisciplineFrameworkElementId: this.state.transferMajorDisciplineFrameworkElementId,
      transferMajorDisciplineFrameworkElement: TransferMajorDisciplineFrameworkElementDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    TransferMajorDisciplineFrameworkElementApiService.saveTransferMajorDisciplineFrameworkElementDetails(request)
      .then((result: SaveTransferMajorDisciplineFrameworkElementDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          transferMajorDisciplineFrameworkElement: result?.transferMajorDisciplineFrameworkElement,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.transferMajorDisciplineFrameworkElement?.id) {
            this.props.onSave(result.transferMajorDisciplineFrameworkElement.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(TransferMajorDisciplineFrameworkElementDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Discipline Framewrork Element could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Discipline Framewrork Element could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(TransferMajorDisciplineFrameworkElementDTO.name)}
            initialValue={this.state.transferMajorDisciplineFrameworkElement?.name}
            {...ValidationUtil.getValidation(TransferMajorDisciplineFrameworkElementDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default TransferMajorDisciplineFrameworkElementDetailsForm;
