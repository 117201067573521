import { ArrowRightOutlined } from '@ant-design/icons';
import { Card, Col, Layout, List, Row, Space, Typography } from 'antd';
import * as queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AnnouncementListing from '../components/AnnouncementListing';
import Breadcrumbs from '../config/Breadcrumbs';
import { ColumnWidths } from '../config/ColumnWidths';
import Routes from '../config/Routes';
import UserSecurity from '../models/UserSecurity';
import HeaderPortal from '../portals/HeaderPortal';
import { StateStoreModel } from '../redux/state/StateStoreModel';
import HistoryUtil from '../utils/HistoryUtil';

interface PublicLandingPageState {
  returnUrl?: string;
}

interface PublicLandingPageProps {
  user: UserSecurity | null;
}

class PublicLandingPage extends React.Component<PublicLandingPageProps & RouteComponentProps<RouteObject>, PublicLandingPageState> {

  constructor(props: PublicLandingPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    const deepLinkState = (queryString.parse(props.location.search) as unknown) as PublicLandingPageState;

    this.state = {
      returnUrl: deepLinkState.returnUrl,
    };
  }

  private goToPastAnnouncements = () => {
    HistoryUtil.push(Routes.generate(Routes.PAST_ANNOUNCEMENTS))
  }

  render() {
    return (
      <Layout>
        <HeaderPortal title="Welcome to STICS!" breadcrumbs={Breadcrumbs.home()} >
          <Typography.Paragraph>
            The State of Iowa Curriculum System (STICS) is a curriculum database and program approval system. At the post-secondary level, STICS is used for approval of Career and Technical (CTE) and arts and sciences (A&S) courses and programs at Iowa&apos;s fifteen community colleges and the storage of the Iowa community college course numbering system (CCN). At the secondary level, STICS is used for approval of CTE programs at Iowa&apos;s public school districts.
          </Typography.Paragraph>
        </HeaderPortal>

        <Space direction="vertical">
          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.THREE_QUARTERS}>
              <Card title="Announcements" >
                <AnnouncementListing
                  includeShown={false}
                  fetchAmount={4}
                  actionButtonIcon={<ArrowRightOutlined />}
                  actionButtonText="View More"
                  actionButtonAction={this.goToPastAnnouncements}
                  actionAvailable={true} />
              </Card >
            </Col>

            <Col {...ColumnWidths.ONE_QUARTER}>
              <Card title="Links" >
                <Space direction="vertical">
                  <Card type='inner' title='Secondary'>
                    <List>
                      <List.Item>
                        <Link to={Routes.generate(Routes.PUBLIC_DISTRICTS)}>View Districts</Link>
                      </List.Item>
                      <List.Item>
                        <Link to={Routes.generate(Routes.PUBLIC_PROGRAMS)}>View Secondary CTE Programs</Link>
                      </List.Item>
                    </List>
                  </Card>
                  <Card type='inner' title='Post-Secondary'>
                    <List>
                      <List.Item>
                        <Link to={Routes.generate(Routes.COURSES)}>View Courses</Link>
                      </List.Item>
                      <List.Item>
                        <Link to={Routes.generate(Routes.PROGRAMS)}>View Programs</Link>
                      </List.Item>
                      <List.Item>
                        <Link to={Routes.generate(Routes.CONSULTANTS)}>View Consultants</Link>
                      </List.Item>
                      <List.Item>
                        <Link to={Routes.generate(Routes.RESOURCES)}>View Resources</Link>
                      </List.Item>
                    </List>
                  </Card>
                </Space>
              </Card >
            </Col>
          </Row>
        </Space>
      </Layout>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    user: state.UserSession.Value
  };
}

export default withRouter(connect(mapStateToProps)(PublicLandingPage));
