// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import UserDTO from '../models/UserDTO';
import ConsultantDTO from '../models/ConsultantDTO';
import * as AddUserHandler from '../handlerModels/AddUserHandler';
import * as GetUserDetailsHandler from '../handlerModels/GetUserDetailsHandler';
import * as GetFeatureFlagsAndRolesForUserHandler from '../handlerModels/GetFeatureFlagsAndRolesForUserHandler';
import * as SaveUserDetailsHandler from '../handlerModels/SaveUserDetailsHandler';
import * as SaveFeatureFlagsAndRolesForUserHandler from '../handlerModels/SaveFeatureFlagsAndRolesForUserHandler';
import * as DeleteUserHandler from '../handlerModels/DeleteUserHandler';
import * as DownloadUsersHandler from '../handlerModels/DownloadUsersHandler';
import * as LockUserHandler from '../handlerModels/LockUserHandler';
import * as UnlockUserHandler from '../handlerModels/UnlockUserHandler';
import * as SearchForUserHandler from '../handlerModels/SearchForUserHandler';
import * as SearchForIDOEConsultantUserHandler from '../handlerModels/SearchForIDOEConsultantUserHandler';
import * as ResendUserConfirmationEmailHandler from '../handlerModels/ResendUserConfirmationEmailHandler';
import * as ResendResetPasswordEmailHandler from '../handlerModels/ResendResetPasswordEmailHandler';

export class UsersApiService extends BaseApi {

    // post: api/users/addUser
    public addUser(addUser: AddUserHandler.Request): Promise<AddUserHandler.Result> {
        let url = `api/users/addUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddUserHandler.Result>(addUser, 'post', url, true, false);
    }

    // post: api/users/list/${encodeURIComponent(institutionId)}?districtIdentifier=${districtIdentifier}&rppId=${rppId}
    public getUsersList(tableRequest: TableRequestDTO, institutionId: string, districtIdentifier: number, rppId: number): Promise<TableResponseDTO<UserDTO>> {
        let url = `api/users/list/${encodeURIComponent(institutionId)}`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (districtIdentifier != null) {
            url += `${prefix}districtIdentifier=${districtIdentifier}`;
            prefix = '&';
        }
        if (rppId != null) {
            url += `${prefix}rppId=${rppId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/users/list/${encodeURIComponent(institutionId)}/export
    public exportUsersList(tableRequest: TableRequestDTO, institutionId: string): Promise<string> {
        let url = `api/users/list/${encodeURIComponent(institutionId)}/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/users/${encodeURIComponent(userId)}
    public getUserDetails(userId: string): Promise<GetUserDetailsHandler.Result> {
        let url = `api/users/${encodeURIComponent(userId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetUserDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/users/getFeatureFlagsAndRolesForUser?userId=${encodeURIComponent(userId)}
    public getFeatureFlagsAndRolesForUser(userId: string): Promise<GetFeatureFlagsAndRolesForUserHandler.Result> {
        let url = `api/users/getFeatureFlagsAndRolesForUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetFeatureFlagsAndRolesForUserHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/users/save
    public saveUser(user: SaveUserDetailsHandler.Request): Promise<SaveUserDetailsHandler.Result> {
        let url = `api/users/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveUserDetailsHandler.Result>(user, 'post', url, true, false);
    }

    // post: api/users/saveFeatureFlagsAndRolesForUser
    public saveFeatureFlagsAndRolesForUser(user: SaveFeatureFlagsAndRolesForUserHandler.Request): Promise<SaveFeatureFlagsAndRolesForUserHandler.Result> {
        let url = `api/users/saveFeatureFlagsAndRolesForUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveFeatureFlagsAndRolesForUserHandler.Result>(user, 'post', url, true, false);
    }

    // post: api/users/deleteUser
    public deleteUser(deleteUser: DeleteUserHandler.Request): Promise<DeleteUserHandler.Result> {
        let url = `api/users/deleteUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteUserHandler.Result>(deleteUser, 'post', url, true, false);
    }

    // post: api/users/consultantSearch?cipNumberId=${encodeURIComponent(cipNumberId)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(email)}
    public searchForConsultants(tableRequest: TableRequestDTO, cipNumberId: string | null, firstName: string, lastName: string, email: string): Promise<TableResponseDTO<ConsultantDTO>> {
        let url = `api/users/consultantSearch`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (cipNumberId != null) {
            url += `${prefix}cipNumberId=${cipNumberId}`;
            prefix = '&';
        }
        if (firstName != null) {
            url += `${prefix}firstName=${encodeURIComponent(firstName)}`;
            prefix = '&';
        }
        if (lastName != null) {
            url += `${prefix}lastName=${encodeURIComponent(lastName)}`;
            prefix = '&';
        }
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ConsultantDTO>>(tableRequest, 'post', url, true, false);
    }

    // get: api/users/consultantCipNumberAssignment?cipNumberId=${encodeURIComponent(cipNumberId)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(email)}
    public consultantCipNumberAssignment(cipNumberId: string | null, firstName: string, lastName: string, email: string): Promise<any> {
        let url = `api/users/consultantCipNumberAssignment`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (cipNumberId != null) {
            url += `${prefix}cipNumberId=${cipNumberId}`;
            prefix = '&';
        }
        if (firstName != null) {
            url += `${prefix}firstName=${encodeURIComponent(firstName)}`;
            prefix = '&';
        }
        if (lastName != null) {
            url += `${prefix}lastName=${encodeURIComponent(lastName)}`;
            prefix = '&';
        }
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // post: api/users/downloadUsers
    public downloadUsers(): Promise<DownloadUsersHandler.Result> {
        let url = `api/users/downloadUsers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DownloadUsersHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/users/lock
    public lockUser(lockUser: LockUserHandler.Request): Promise<LockUserHandler.Result> {
        let url = `api/users/lock`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LockUserHandler.Result>(lockUser, 'post', url, true, false);
    }

    // post: api/users/unlock
    public unlockUser(unlockUser: UnlockUserHandler.Request): Promise<UnlockUserHandler.Result> {
        let url = `api/users/unlock`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UnlockUserHandler.Result>(unlockUser, 'post', url, true, false);
    }

    // post: api/users/search
    public searchForuser(searchForUser: SearchForUserHandler.Request): Promise<SearchForUserHandler.Result> {
        let url = `api/users/search`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForUserHandler.Result>(searchForUser, 'post', url, true, false);
    }

    // post: api/users/searchIDOEConsultantUser
    public searchForIDOEConsultantUser(searchForIDOEConsultantUser: SearchForIDOEConsultantUserHandler.Request): Promise<SearchForIDOEConsultantUserHandler.Result> {
        let url = `api/users/searchIDOEConsultantUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForIDOEConsultantUserHandler.Result>(searchForIDOEConsultantUser, 'post', url, true, false);
    }

    // post: api/users/resendUserConfirmationEmail
    public resendUserConfirmationEmail(resendUserConfirmationEmail: ResendUserConfirmationEmailHandler.Request): Promise<ResendUserConfirmationEmailHandler.Result> {
        let url = `api/users/resendUserConfirmationEmail`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResendUserConfirmationEmailHandler.Result>(resendUserConfirmationEmail, 'post', url, true, false);
    }

    // post: api/users/resendResetPasswordEmail
    public resendResetPasswordEmail(resendResetPasswordEmail: ResendResetPasswordEmailHandler.Request): Promise<ResendResetPasswordEmailHandler.Result> {
        let url = `api/users/resendResetPasswordEmail`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResendResetPasswordEmailHandler.Result>(resendResetPasswordEmail, 'post', url, true, false);
    }
}
var service = new UsersApiService();
export default service;
