// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import RacialEthnicGroupDataTableDTO from '../models/RacialEthnicGroupDataTableDTO';
import * as GetRacialEthnicGroupDetailsHandler from '../handlerModels/GetRacialEthnicGroupDetailsHandler';
import * as SaveRacialEthnicGroupDetailsHandler from '../handlerModels/SaveRacialEthnicGroupDetailsHandler';
import * as DeleteRacialEthnicGroupHandler from '../handlerModels/DeleteRacialEthnicGroupHandler';

export class RacialEthnicGroupApiService extends BaseApi {

    // post: api/racialEthnicGroups
    public getRacialEthnicGroupTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<RacialEthnicGroupDataTableDTO>> {
        let url = `api/racialEthnicGroups`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<RacialEthnicGroupDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/racialEthnicGroups/export
    public getRacialEthnicGroupDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/racialEthnicGroups/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/racialEthnicGroups/${id}
    public getRacialEthnicGroupDetails(id: number): Promise<GetRacialEthnicGroupDetailsHandler.Result> {
        let url = `api/racialEthnicGroups/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetRacialEthnicGroupDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/racialEthnicGroups/save
    public saveRacialEthnicGroupDetails(saveRacialEthnicGroupDetails: SaveRacialEthnicGroupDetailsHandler.Request): Promise<SaveRacialEthnicGroupDetailsHandler.Result> {
        let url = `api/racialEthnicGroups/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveRacialEthnicGroupDetailsHandler.Result>(saveRacialEthnicGroupDetails, 'post', url, true, false);
    }

    // post: api/racialEthnicGroups/deleteRacialEthnicGroup
    public deleteRacialEthnicGroup(deleteRacialEthnicGroup: DeleteRacialEthnicGroupHandler.Request): Promise<DeleteRacialEthnicGroupHandler.Result> {
        let url = `api/racialEthnicGroups/deleteRacialEthnicGroup`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteRacialEthnicGroupHandler.Result>(deleteRacialEthnicGroup, 'post', url, true, false);
    }
}
var service = new RacialEthnicGroupApiService();
export default service;
