import * as React from 'react';
import { useEffect, useState } from 'react';
import SecondaryProgramHistoryApiService from '../../../api/SecondaryProgramHistoryApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetSelfStudiesHandler from '../../../handlerModels/GetSelfStudiesHandler';
import SecondaryProgramReviewHistoryDTO from '../../../models/SecondaryProgramReviewHistoryDTO';
import SecondaryProgramStatusTypeDTO from '../../../models/SecondaryProgramStatusTypeDTO';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface SecondaryProgramHistoryDataTableProps {
  secondaryProgramId?: number;
  academicYear?: number;
}

function SecondaryProgramHistoryDataTable(props: SecondaryProgramHistoryDataTableProps) {
  let _dataTable: DataTable<SecondaryProgramReviewHistoryDTO> | undefined = undefined;

  const [, setLoading] = useState(false);
  const [secondaryProgramStatusTypes, setSecondaryProgramStatusTypes] = useState([] as SecondaryProgramStatusTypeDTO[]);
  const [selfStudy, setSelfStudy] = useState([] as SelfStudyDTO[]);
  const [, setError] = useState(false);

  useEffect(() => {
    if (props.academicYear && props.secondaryProgramId) {
      setLoading(true);
      const loaders = [];
      loaders.push(loadSelfStudy());
      loaders.push(loadSelfStudyResponseStatusTypes());

      Promise.all(loaders).then(() => {
        setLoading(false);
      });
    }
    triggerRefresh();
  }, [props.secondaryProgramId]);

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const loadSelfStudy = () => {
    const request = GetSelfStudiesHandler.Request.create({
      academicYear: props.academicYear,
      secondaryProgramIdentifier: props.secondaryProgramId
    });

    return SelfStudyApiService.getSelfStudies(request)
      .then((results: GetSelfStudiesHandler.Result) => {
        if (results.selfStudies) {
          setSelfStudy(results.selfStudies);
        }
      }).catch(() => {
        setError(true);
      });
  }

  const loadSelfStudyResponseStatusTypes = () => {
    return LookupsUtil.getAll<SecondaryProgramStatusTypeDTO>(SecondaryProgramStatusTypeDTO.className)
      .then((results: SecondaryProgramStatusTypeDTO[]) => {
        setSecondaryProgramStatusTypes(results);
      }).catch(() => {
        setError(true);
      });
  }

  const getColumnDefinitions = () => {
    return [
      {
        title: 'Status',
        dataIndex: SecondaryProgramReviewHistoryDTO.secondaryProgramStatusTypeId,
        sorter: true,
        render: (data: string, row: SecondaryProgramReviewHistoryDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_CURRENT_PROGRAM_STATUS])) {
            return row.secondaryProgramStatusType?.name
          }
          else if ((row.reviewYear ?? 0) <= (props.academicYear ?? 0)) {
            return row.secondaryProgramStatusType?.name
          }

          return 'Pending';

        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: secondaryProgramStatusTypes?.map(x => { return { value: x.id, text: x.name } }) as TableFilterOption[],
        filterDropdownVisible: true
      },
      {
        title: 'Review Year',
        dataIndex: SecondaryProgramReviewHistoryDTO.reviewYear,
        sorter: true,
        render: (data: string, row: SecondaryProgramReviewHistoryDTO) => {
          return row.reviewYear
        },
        filterType: FilterType.Text
      },
      {
        title: 'Next Review Year',
        dataIndex: SecondaryProgramReviewHistoryDTO.nextReviewYear,
        sorter: true,
        render: (data: string, row: SecondaryProgramReviewHistoryDTO) => {
          return row.nextReviewYear
        },
        filterType: FilterType.Text
      },
    ] as DataTableColumnProps<any>[];
  };

  const openSelfStudy = (record: SecondaryProgramReviewHistoryDTO) => {
    const selfStudyId = selfStudy.find(x => x.academicYearId == (record.reviewYear ?? 0))?.id;
    if (selfStudyId && selfStudyId != Guid.Empty()) {
      HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: selfStudyId ?? Guid.Empty() }, {}));
    }
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());

  return (<>
    <DataTable
      // @ts-ignore
      ref={(element: any) => (_dataTable = element)}
      onRowClick={openSelfStudy}
      serverSide={true}
      tableProps={{
        rowKey: 'id',
        scroll: { x: 500 }
      }}
      globalSearch={true}
      buttonBar={actionButtons}
      columns={getColumnDefinitions()}
      fetchData={{
        fetch: (requestState: TableRequestDTO) => SecondaryProgramHistoryApiService.getSecondaryProgramTableData(requestState, props.secondaryProgramId ?? 0, props.academicYear ?? 0)
      }}
      stateSaving={{ enabled: true, tableUniqueKey: 'contacts_report_list' }} />
  </>
  );
}

export default SecondaryProgramHistoryDataTable;
