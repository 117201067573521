import * as React from 'react';
import AnnouncementApiService from '../../api/AnnouncementsApiService';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import AnnouncementDTO from '../../models/AnnouncementDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import AnnouncementUtil from '../../utils/AnnouncementUtil';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import DateTimeUtil from '../../utils/DateTimeUtil';
import HistoryUtil from '../../utils/HistoryUtil';
import StringUtil from '../../utils/StringUtil';
import TableFilterUtil, { TableFilterOption } from '../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from './core/DataTable';
import DataTableButtonUtil from './core/DataTableButtonUtil';
import TableRequestDTO from './core/models/TableRequestDTO';

interface AnnouncementsDataTableProps {
}

interface AnnouncementsDataTableState extends BaseDataTableState<AnnouncementDTO> {
  announcementTypes: TableFilterOption[];
  announcementStatusTypes: TableFilterOption[];
  institutions: TableFilterOption[];
  roleTypes: TableFilterOption[];
}

class AnnouncementsDataTable extends React.Component<AnnouncementsDataTableProps, AnnouncementsDataTableState> {
  private _dataTable: DataTable<AnnouncementDTO> | undefined;

  constructor(props: AnnouncementsDataTableProps) {
    super(props);

    this.state = {
      institutions: [],
      roleTypes: [],
      announcementTypes: [],
      announcementStatusTypes: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    TableFilterUtil.AnnouncementTypes()
      .then(x => {
        this.setState({ announcementTypes: x })
      });

    this.setState({ announcementStatusTypes: TableFilterUtil.AnnouncementStatusTypes() });

    TableFilterUtil.Institutions()
      .then(x => {
        this.setState({ institutions: x })
      });

    TableFilterUtil.Roles()
      .then(x => {
        this.setState({ roleTypes: x })
      });
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Title',
        dataIndex: AnnouncementDTO.title,
        sorter: true,
        width: 200,
        filterType: FilterType.Text,
        ellipsis: true
      },
      {
        title: 'Message',
        dataIndex: AnnouncementDTO.message,
        sorter: true,
        filterType: FilterType.Text,
        width: 300,
        ellipsis: true,
      },
      {
        title: 'Type',
        dataIndex: AnnouncementDTO.announcementTypeId,
        width: 150,
        render: (data: string, row: AnnouncementDTO) => {
          return row.announcementType?.name;
        },
        sorter: true,
        ellipsis: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.announcementTypes,
      },
      {
        title: 'Institutions',
        dataIndex: AnnouncementDTO.announcementInstitutions,
        width: 150,
        render: (data: string, row: AnnouncementDTO) => {
          return row.announcementInstitutions != null ? StringUtil.newLineFormat(row.announcementInstitutions.map(r => r.institution?.abbreviation), true) : '';
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.institutions
      },
      {
        title: 'Roles',
        dataIndex: AnnouncementDTO.announcementRoles,
        width: 150,
        render: (data: string, row: AnnouncementDTO) => {
          return row.announcementRoles != null ? StringUtil.newLineFormat(row.announcementRoles.map(r => r.role?.name), true) : '';
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.roleTypes
      },
      {
        title: 'Show Dates',
        dataIndex: AnnouncementDTO.showOnDate,
        width: 150,
        render: (data: string, row: AnnouncementDTO) => {
          return DateTimeUtil.dateRangeShortFormat(row.showOnDate, row.showUntilDate);
        },
        sorter: true,
        filterType: FilterType.DateRange
      },
      {
        title: 'Status',
        dataIndex: AnnouncementDTO.status,
        width: 150,
        render: (data: string, row: AnnouncementDTO) => {
          return AnnouncementUtil.getAnnouncementStatus(row.showOnDate, row.showUntilDate);
        },
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.announcementStatusTypes
      },
    ] as DataTableColumnProps<any>[];
  };

  private openAnnouncement = (record: AnnouncementDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.ANNOUNCEMENT_DETAILS, { id: record.id }, {}));
    }
  }

  private addAnnouncement = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_ANNOUNCEMENT));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_ANNOUNCEMENT])) {
      actionButtons.push(DataTableButtonUtil.Default('New Announcement', () => this.addAnnouncement()));
    }

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openAnnouncement}
        serverSide={true}
        tableProps={{ rowKey: 'id', scroll: { x: 1750 } }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => AnnouncementApiService.getAnnouncementTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'announcement_list' }} />
    );
  }
}

export default AnnouncementsDataTable;
