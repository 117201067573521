import { Card } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NewDisciplineChangeRequestApiService from '../../../../api/NewDisciplineChangeRequestApiService';
import ChangeRequestLayout from '../../../../components/postSecondary/ChangeRequestLayout';
import ChangeRequestAdditionalDetailsForm from '../../../../components/forms/postSecondary/ChangeRequestAdditionalDetailsForm';
import Routes from '../../../../config/Routes';
import * as GetNewDisciplineChangeRequestHandler from '../../../../handlerModels/GetNewDisciplineChangeRequestHandler';
import ChangeRequestDTO from '../../../../models/ChangeRequestDTO';
import UserSecurity from '../../../../models/UserSecurity';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface NewDisciplineChangeRequestStep1PageState extends BaseFormState {
  changeRequestId: string;
  changeRequest: ChangeRequestDTO;
}

interface NewDisciplineChangeRequestStep1PageProps {
  currentUser: UserSecurity | null;
}

class NewDisciplineChangeRequestStep1Page extends React.Component<NewDisciplineChangeRequestStep1PageProps & RouteComponentProps<RouteObject>, NewDisciplineChangeRequestStep1PageState> {
  constructor(props: NewDisciplineChangeRequestStep1PageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequestId: Guid.Empty(),
      changeRequest: ChangeRequestDTO.create(),
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');
    if (id !== Guid.Empty() && this.state.changeRequestId != id) {
      this.setState({ changeRequestId: id });
      this.fetchData(id);
    }
  }

  private fetchData = (id: string) => {
    this.setState({ loading: true });

    const loaders = [];

    if (id !== Guid.Empty()) {
      loaders.push(this.loadChangeRequest(id));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadChangeRequest = (id: string) => {
    const request = GetNewDisciplineChangeRequestHandler.Request.create({
      changeRequestId: id
    });

    return NewDisciplineChangeRequestApiService.get(request)
      .then((results: GetNewDisciplineChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  goToPreviousStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_1, { id: this.state.changeRequestId }, {}))
  }

  goToNextStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_REVIEW, { id: this.state.changeRequestId }, {}))
  }

  render() {
    return (
      <ChangeRequestLayout
        changeRequest={this.state.changeRequest ?? ChangeRequestDTO.create()}
        stepNumber={1}
        currentUser={this.props.currentUser ?? {} as UserSecurity}>
        <Card title="Additional Information" >
          <ChangeRequestAdditionalDetailsForm
            changeRequest={this.state.changeRequest}
            onPrevious={this.goToPreviousStep}
            onSubmit={this.goToNextStep}
            loading={this.state.loading} />
        </Card>
      </ChangeRequestLayout>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
  };
}

export default withRouter(connect(mapStateToProps)(NewDisciplineChangeRequestStep1Page));