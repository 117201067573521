import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Card, message, Skeleton, Space } from 'antd';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import * as GetNewProgramChangeRequestStep11Handler from '../../../handlerModels/GetNewProgramChangeRequestStep11Handler';
import * as GetNewProgramChangeRequestStep11TermHandler from '../../../handlerModels/GetNewProgramChangeRequestStep11TermHandler';
import * as SaveNewProgramChangeRequestStep11Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep11Handler';
import * as SubmitNewProgramChangeRequestStep11Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep11Handler';
import * as ValidateNewProgramChangeRequestStep11Handler from '../../../handlerModels/ValidateNewProgramChangeRequestStep11Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import FlatNewProgramProgramTermCourseGroupOptionDTO from '../../../models/FlatNewProgramProgramTermCourseGroupOptionDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import NewProgramProgramAwardDTO from '../../../models/NewProgramProgramAwardDTO';
import NewProgramProgramTermDTO from '../../../models/NewProgramProgramTermDTO';
import ProgramAwardDTO from '../../../models/ProgramAwardDTO';
import ProgramTermDTO from '../../../models/ProgramTermDTO';
import YearTermDTO from '../../../models/YearTermDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import RichTextEditor from '../../inputs/RichTextEditor';
import YesNoInput from '../../inputs/YesNoInput';
import ProgramTermEditor, { ProgramTermEditorValue } from '../../postSecondary/ProgramTermEditor';
import TermOnlyInput from '../../TermOnlyInput';

interface NewProgramChangeRequestStep11FormState extends BaseFormState {
  addTerm: boolean;
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
  newProgramAward: NewProgramProgramAwardDTO;
  existingProgramTerms: ProgramTermDTO[];
  allTermsLoaded: boolean;
  validating: boolean;
  termLoading: Map<string, boolean>;
  totalCredits: Map<number, number>;
  totalTechCoreCredits: Map<number, number>;
}

interface NewProgramChangeRequestStep11FormProps extends BaseFormProps, BaseChangeRequestProps {
  awardId?: string;
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: (id: string) => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  onIsValid?: (isValid: boolean) => void;
  readonly?: boolean;
  review?: boolean;
}

class NewProgramChangeRequestStep11Form extends React.Component<NewProgramChangeRequestStep11FormProps, NewProgramChangeRequestStep11FormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _programTermEditor = React.createRef<ProgramTermEditor>();
  private termsLoaded = false;

  private getNewProgramFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(NewProgramProgramAwardDTO.prerequisite, {
        name: NewProgramProgramAwardDTO.prerequisite,
        label: 'Does this award have any prerequisites?',
      })
      .set(NewProgramProgramAwardDTO.terms, {
        name: [index, NewProgramProgramAwardDTO.terms],
      })
      .set(NewProgramProgramAwardDTO.terms, {
        name: [index, NewProgramProgramAwardDTO.terms],
      })
  }

  private getProgramTermsFormItems = (programTerm?: any) => {
    return new Map<string, FormItemProps>()
      .set(NewProgramProgramTermDTO.informationalContent, {
        label: 'Term Description',
        name: [programTerm, NewProgramProgramTermDTO.informationalContent],
      })
      .set(NewProgramProgramTermDTO.termId, {
        label: 'Term Type',
        name: [programTerm, NewProgramProgramTermDTO.termId],
      });
  }

  constructor(props: NewProgramChangeRequestStep11FormProps) {
    super(props);

    this.state = {
      addTerm: false,
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      newProgramChangeRequest: NewProgramChangeRequestDTO.create(),
      newProgramAward: NewProgramProgramAwardDTO.create(),
      existingProgramTerms: [],
      validating: false,
      allTermsLoaded: false,
      termLoading: new Map<string, boolean>(),
      totalCredits: new Map<number, number>(),
      totalTechCoreCredits: new Map<number, number>()
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      this.setState({ loading: true });
      const loaders = [];

      loaders.push(this.loadChangeRequest());

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
        this.resetForm()
      });
    }
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep11FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty() && prevProps.changeRequestId != this.props.changeRequestId) {
      const loaders = [];
      loaders.push(this.loadChangeRequest());
      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
        this.resetForm()
      });
    }

    if (this.props.review && !this.allTermsLoaded() && !this.state.allTermsLoaded && this.termsLoaded && !this.state.validating && this.state.termLoading.size > 0 && this.state.newProgramAward.id != Guid.Empty()) {
      const loaders = [];
      loaders.push(this.ValidateStep11());
      Promise.all(loaders).then(() => {
        this.setState({
          loading: false,
          allTermsLoaded: true,
          validating: false
        });
      });
    }
  }

  private allTermsLoaded = () => {
    let termsLoaded = false;
    this.state.termLoading.forEach(x => {
      if (x.valueOf()) {
        termsLoaded = true;
      }
    });
    return termsLoaded;
  }

  private onTermLoaded = (termId: string) => {
    const termLoadings = this.state.termLoading;
    termLoadings.set(termId, false);
    this.setState({ termLoading: termLoadings });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields([NewProgramProgramAwardDTO.terms]);
  }

  private ValidateStep11 = () => {
    this.setState({ loading: true, validating: true });
    let credits = 0;
    let techCoreCredits = 0;
    this.state.totalCredits.forEach(x => credits += x);
    this.state.totalTechCoreCredits.forEach(x => techCoreCredits += x);

    const request = ValidateNewProgramChangeRequestStep11Handler.Request.create({
      changeRequestId: this.props.changeRequestId,
      programAward: this.state.newProgramAward,
      totalCredits: credits,
      totalTechCoreCredits: techCoreCredits
    });

    return NewProgramChangeRequestApiService.validateNewProgramChangeRequestStep11(request)
      .then((results: ValidateNewProgramChangeRequestStep11Handler.Result) => {
        if (results) {

          const fieldErrors = this.state.fieldErrors ?? {};
          for (const fieldErrorName in results.fieldErrors) {
            if (!fieldErrors[fieldErrorName]) {
              fieldErrors[fieldErrorName] = results.fieldErrors[fieldErrorName];
            }
          }

          if (this.props.onIsValid) {
            this.props.onIsValid(results.succeeded);
          }
          this.setState({
            fieldErrors: this.props.changeRequestDetailsPage ? null : fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private loadChangeRequest = () => {
    this.setState({ loading: true });
    const request = GetNewProgramChangeRequestStep11Handler.Request.create({
      changeRequestId: this.props.changeRequestId,
      programAwardId: this.props.awardId
    });
    let terms: (string | null)[] = [];
    return NewProgramChangeRequestApiService.getStep11(request)
      .then((results: GetNewProgramChangeRequestStep11Handler.Result) => {
        if (results) {
          const programAward = (results.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards?.find(x => x.id == this.props.awardId) ?? NewProgramProgramAwardDTO.create())
          if (programAward?.terms == null && !this.props.readonly) {
            programAward.terms = [ProgramTermDTO.create({ order: 1 })];
          }
          if (programAward?.terms?.length == 0 && !this.props.readonly) {
            programAward.terms.push(ProgramTermDTO.create({ order: 1 }));
          }

          terms = programAward.terms?.map(x => x.id) ?? [];

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            newProgramAward: programAward,
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      })
      .finally(() => {
        if (terms.length == 0) {
          this.setState({ allTermsLoaded: true });
        }
        else {
          const loaders: any = [];

          terms.forEach(x => loaders.push(this.loadTerm(x ?? Guid.Empty())));
          Promise.all(loaders).then(() => {
            this.termsLoaded = true;
            this.resetForm();
          });
        }
      });
  }

  private loadTerm = (termId: string) => {
    const termLoadings = this.state.termLoading;
    termLoadings.set(termId, true);
    this.setState({ termLoading: termLoadings });

    const request = GetNewProgramChangeRequestStep11TermHandler.Request.create({
      changeRequestId: this.props.changeRequestId,
      programAwardId: this.props.awardId,
      institutionId: this.props.selectedInstitution,
      termId: termId
    });

    return NewProgramChangeRequestApiService.getStep11Term(request)
      .then((results: GetNewProgramChangeRequestStep11TermHandler.Result) => {
        if (results) {
          const programTerms = this.state.newProgramAward;
          if (programTerms.terms) {
            programTerms.terms[programTerms.terms?.findIndex(x => x.order == results.term?.order) ?? 0] = results.term ?? NewProgramProgramTermDTO.create();
          }

          const fieldErrors = this.state.fieldErrors ?? {};
          for (const fieldErrorName in results.fieldErrors) {
            fieldErrors[fieldErrorName] = results.fieldErrors[fieldErrorName];
          }

          this.setState({
            newProgramAward: programTerms,
            fieldErrors: this.props.changeRequestDetailsPage ? null : fieldErrors,
            allTermsLoaded: false
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      }).finally(() => {
        if (termId == Guid.Empty()) {
          const loadings = this.state.termLoading;
          loadings.set(termId, false);
          this.setState({ termLoading: loadings });
        }
      });
  }

  calculateTotalCredits = (termOrder: number, termCredits: number, termTechCoreCredits: number) => {
    let totalCredits = this.state.totalCredits;
    totalCredits = totalCredits ? totalCredits : new Map<number, number>().set(termOrder, termCredits);
    totalCredits.set(termOrder, termCredits);

    let totalTechCoreCredits = this.state.totalTechCoreCredits;
    totalTechCoreCredits = totalTechCoreCredits ? totalTechCoreCredits : new Map<number, number>().set(termOrder, termTechCoreCredits);
    totalTechCoreCredits.set(termOrder, termTechCoreCredits);

    this.setState({
      totalCredits: totalCredits,
      totalTechCoreCredits: totalTechCoreCredits
    });
  }

  private handleChange = (altered: boolean) => {
    this.setState({ altered: altered });
  }

  private handleSave = () => {
    this.setState({ saving: true });
    const model = NewProgramProgramAwardDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    const terms: never[] | (never[] & NewProgramProgramAwardDTO[]) = [];

    for (let i = 0; i < model.terms.length; i++) {
      let term = model.terms[i];
      if (model.terms[i][NewProgramProgramAwardDTO.terms]) {
        term = model.terms[i][NewProgramProgramAwardDTO.terms];
        term[NewProgramProgramTermDTO.informationalContent] = model.terms[i][NewProgramProgramTermDTO.informationalContent];
        term[NewProgramProgramTermDTO.termId] = model.terms[i][NewProgramProgramTermDTO.termId];
        term[FlatNewProgramProgramTermCourseGroupOptionDTO.order] = model.terms[i][FlatNewProgramProgramTermCourseGroupOptionDTO.order];
      }
      else {
        term = model.terms[i];
        term[NewProgramProgramTermDTO.informationalContent] = model.terms[i][NewProgramProgramTermDTO.informationalContent];
        term[NewProgramProgramTermDTO.termId] = model.terms[i][NewProgramProgramTermDTO.termId];
        term[FlatNewProgramProgramTermCourseGroupOptionDTO.order] = model.terms[i][FlatNewProgramProgramTermCourseGroupOptionDTO.order]
      }
      terms.push(term);
    }

    model.terms = terms;
    model[NewProgramProgramAwardDTO.id] = this.props.awardId;

    const award = NewProgramProgramAwardDTO.create(model);

    let credits = 0;
    let techCoreCredits = 0;
    this.state.totalCredits.forEach(x => credits += x);
    this.state.totalTechCoreCredits.forEach(x => techCoreCredits += x);

    const request = SaveNewProgramChangeRequestStep11Handler.Request.create({
      newProgramAward: award,
      changeRequestId: this.state.changeRequest.id,
      totalCredits: credits,
      totalTechCoreCredits: techCoreCredits
    });

    NewProgramChangeRequestApiService.saveStep11(request)
      .then((result: SaveNewProgramChangeRequestStep11Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');
          const programAward = result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards?.find((x: any) => x.id == this.props.awardId) ?? NewProgramProgramAwardDTO.create();
          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: result.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            newProgramAward: result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards?.find((x: any) => x.id == this.props.awardId) ?? NewProgramProgramAwardDTO.create()
          });
          this._formRef.current?.setFieldsValue({ terms: programAward.terms });
          this.resetForm();
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const model = NewProgramProgramAwardDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    const terms: never[] | (never[] & NewProgramProgramAwardDTO[]) = [];

    for (let i = 0; i < model.terms.length; i++) {
      let term = model.terms[i];
      if (model.terms[i][NewProgramProgramAwardDTO.terms]) {
        term = model.terms[i][NewProgramProgramAwardDTO.terms];
        term[FlatNewProgramProgramTermCourseGroupOptionDTO.order] = model.terms[i][FlatNewProgramProgramTermCourseGroupOptionDTO.order];
        term[NewProgramProgramTermDTO.informationalContent] = model.terms[i][NewProgramProgramTermDTO.informationalContent];
        term[NewProgramProgramTermDTO.termId] = model.terms[i][NewProgramProgramTermDTO.termId];
        term[NewProgramProgramTermDTO.programAwardId] = this.props.awardId as never ?? Guid.Empty();
      }
      else {
        term = model.terms[i];
        term[FlatNewProgramProgramTermCourseGroupOptionDTO.order] = model.terms[i][FlatNewProgramProgramTermCourseGroupOptionDTO.order]
        term[NewProgramProgramTermDTO.informationalContent] = model.terms[i][NewProgramProgramTermDTO.informationalContent];
        term[NewProgramProgramTermDTO.termId] = model.terms[i][NewProgramProgramTermDTO.termId];
        term[NewProgramProgramTermDTO.programAwardId] = this.props.awardId as never ?? Guid.Empty();
      }
      terms.push(term);
    }

    model.terms = terms;
    model[NewProgramProgramAwardDTO.id] = this.props.awardId;
    model[NewProgramProgramAwardDTO.awardType] = this.state.newProgramAward?.awardType;
    model[NewProgramProgramAwardDTO.awardTypeId] = this.state.newProgramAward.awardTypeId;
    model[NewProgramProgramAwardDTO.newProgramChangeRequestId] = this.state.newProgramChangeRequest.id;

    const award = NewProgramProgramAwardDTO.create(model);
    award.order = this.state.newProgramAward.order;

    let credits = 0;
    let techCoreCredits = 0;
    this.state.totalCredits.forEach(x => credits += x);
    this.state.totalTechCoreCredits.forEach(x => techCoreCredits += x);

    const request = SubmitNewProgramChangeRequestStep11Handler.Request.create({
      newProgramAward: award,
      changeRequestId: this.state.changeRequest.id,
      totalCredits: credits,
      totalTechCoreCredits: techCoreCredits
    });

    NewProgramChangeRequestApiService.submitStep11(request)
      .then((result: SubmitNewProgramChangeRequestStep11Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }

          if (this.props.onSave) {
            if (result.changeRequest) {
              this.props.onSave(result.changeRequest.id ?? Guid.Empty());
            }
            this.resetForm();
          }
        }
        else {
          this.setState({

            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  private handleNewAwardPrequisiteOnClick = (e: any) => {
    const formValue = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const programAward = this.state.newProgramAward;

    const terms = formValue.terms;
    const newProgramTerms: ProgramTermDTO[] = [];

    if (e) {
      newProgramTerms.push(ProgramTermDTO.create({ order: 0 }));

      newProgramTerms.pushAll(terms)
      this._formRef.current?.resetFields([0, NewProgramProgramAwardDTO.terms]);
    }
    else if (programAward.prerequisite == true) {
      const totalCredits = this.state.totalCredits;
      const totalTechCredits = this.state.totalTechCoreCredits;
      totalTechCredits.delete(0);
      totalCredits.delete(0);
      this.setState({ totalCredits: totalCredits, totalTechCoreCredits: totalTechCredits });
      terms?.remove(terms[0])

      newProgramTerms.pushAll(terms);
    }
    else {
      newProgramTerms.pushAll(terms);
    }

    programAward.terms?.removeAll(programAward.terms);
    programAward.terms?.pushAll(newProgramTerms);
    programAward.prerequisite = e;
    this._formRef.current?.setFieldsValue({ terms: programAward.terms });

    this.setState({ newProgramAward: programAward });
  }

  render() {
    if (this.state.loading || this.state.loading == undefined || this.props.loading) {
      return <Skeleton active={true} />;
    }
    return (
      <Space size="small" direction="vertical">
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.newProgramAward}
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <Space direction="vertical">

            <FormItem
              {...this.getNewProgramFormItems(0).get(NewProgramProgramAwardDTO.prerequisite)}
              {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.prerequisite + this.state.newProgramAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}
            >
              <YesNoInput disabled={this.props.readonly} onChange={this.handleNewAwardPrequisiteOnClick} />
            </FormItem>

            {this.renderProgramTerm()}
            {this.renderTermsErrors()}
            {this.renderCreditLimitErrors()}
            {this.renderTechCoreCreditsErrors()}
            {this.renderActions()}
          </Space>
        </Form>
      </Space >
    );
  }

  private renderActions() {
    if (!this.props.readonly) {
      return <Space direction='horizontal' wrap={true}>
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton onClick={this.handleSave} type='default' htmlType='button' saving={this.state.saving} />
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space>
    }
  }

  renderProgramTerm() {
    let credits = 0;
    this.state.totalCredits.forEach(x => credits += x);

    let techCoreCredits = 0;
    this.state.totalTechCoreCredits.forEach(x => techCoreCredits += x);

    return (
      <Form.List name={NewProgramProgramAwardDTO.terms}>
        {

          (terms, { add, remove }) => {
            let formTerms = this._formRef.current?.getFieldValue(ProgramAwardDTO.terms) as NewProgramProgramTermDTO[];
            if (formTerms == undefined || formTerms.length == 0) {
              formTerms = (this.state.newProgramAward.terms ?? []);
            }

            return (
              <Space direction="vertical">
                {
                  terms.map((term, index) => {
                    const prerequisite = this._formRef.current?.getFieldValue(NewProgramProgramAwardDTO.prerequisite) ? this._formRef.current?.getFieldValue(NewProgramProgramAwardDTO.prerequisite) : this.state.newProgramAward ? this.state.newProgramAward.prerequisite : null;
                    const title = prerequisite && index == 0 ? 'Prerequisite' : 'Term ' + (index + (prerequisite ? 0 : 1));
                    const deleteTerm = (prerequisite && index == 0) || this.props.readonly ? null : <Button onClick={() => this.removeTerm(term, remove, prerequisite)} type="link" icon={<DeleteOutlined />} />
                    const formTerm = (formTerms ?? [ProgramTermDTO.create])[index];
                    const termValue = {
                      id: formTerm?.id,
                      prerequisite: formTerm?.prerequisite,
                      groups: formTerm?.groups,
                      flatCourseGroupOptions: formTerm?.flatCourseGroupOptions,
                      order: formTerm?.order
                    } as ProgramTermEditorValue;
                    const transferMajor = this.state.newProgramChangeRequest.isTransferMajorProgram ?? false;
                    return (
                      <Card type="inner" size="small" key={term.key} title={title} extra={deleteTerm}>
                        <FormItem
                          {...this.getProgramTermsFormItems(index).get(ProgramTermDTO.termId)}
                          {...ValidationUtil.getValidation(ProgramTermDTO.termId + this.state.newProgramAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                          <TermOnlyInput readOnly={this.props.readonly} />
                        </FormItem>

                        <FormItem
                          {...this.getProgramTermsFormItems(index).get(ProgramTermDTO.informationalContent)}
                          {...ValidationUtil.getValidation(ProgramTermDTO.informationalContent + this.state.newProgramAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                          <RichTextEditor readonly={this.props.readonly} />
                        </FormItem>

                        <FormItem initialValue={termValue}
                          {...this.getNewProgramFormItems(index).get(NewProgramProgramAwardDTO.terms)}
                          {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.terms, this.state.fieldErrors, this.state.submitted || this.props.readonly)}
                        >
                          <ProgramTermEditor
                            ref={this._programTermEditor}
                            key={index}
                            programAward={this.state.newProgramAward}
                            transferMajor={transferMajor}
                            selectedInstitution={this.state.changeRequest.institutionId}
                            loading={this.state.newProgramAward.terms ? this.state.termLoading?.get(termValue.id ?? Guid.Empty()) : false}
                            readonly={this.props.readonly}
                            termIndex={index}
                            fieldErrors={this.state.fieldErrors}
                            submitted={this.state.submitted || this.props.readonly}
                            isEditing={true}
                            onCreditsChanged={this.calculateTotalCredits}
                            onTermLoaded={this.onTermLoaded}
                            prerequisite={prerequisite}
                            installTerm={this.state.newProgramChangeRequest.install ?? YearTermDTO.create()}
                          />
                        </FormItem>
                      </Card>
                    );
                  })
                }
                {!this.props.readonly ?
                  <Button onClick={() => this.addTerm(formTerms, add)}>
                    Add Term
                  </Button>
                  : null
                }

                <b>Award Total Credits: {credits}</b>
                {this.state.newProgramChangeRequest.isTransferMajorProgram ? null : <b>Award Total Tech Core Credits: {techCoreCredits}</b>}
              </Space>
            );
          }
        }
      </Form.List >
    );
  }

  private addTerm = (formTerms: any, add: any) => {
    add(ProgramTermDTO.create({
      order: formTerms && formTerms.length != 0 && formTerms[formTerms.length - 1] != undefined ? formTerms[formTerms.length - 1].order + 1 : 1
    }))
  }

  private removeTerm = (term: any, remove: any, prerequisite: boolean) => {
    const totalCredits = this.state.totalCredits;
    const totalTechCoreCredits = this.state.totalTechCoreCredits;
    const termIndex = prerequisite ? term.name : (term.name + 1);
    totalCredits.delete(termIndex);
    totalTechCoreCredits.delete(termIndex);
    this.setState({ totalCredits: totalCredits, totalTechCoreCredits: totalTechCoreCredits });
    remove(term.name);
  }

  renderCreditLimitErrors = () => {
    if (this.state.fieldErrors) {
      return ValidationUtil.getValidation('awardCredits' + this.state.newProgramAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)?.messages.map(x => this.renderErrors(x));
    }
  }

  renderTechCoreCreditsErrors = () => {
    if (this.state.fieldErrors) {
      return ValidationUtil.getValidation('techCoreCredits' + this.state.newProgramAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)?.messages.map(x => this.renderErrors(x));
    }
  }

  renderTermsErrors = () => {
    if (this.state.fieldErrors) {
      return ValidationUtil.getValidation('termData' + this.state.newProgramAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)?.messages.map(x => this.renderErrors(x));
    }
  }

  private renderErrors = (message?: string) => {
    return <Alert type="error" message='Error' showIcon={true} description={message ? message : this.state.message} />;
  }
}

export default NewProgramChangeRequestStep11Form