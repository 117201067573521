import { Alert, Form, Input, message, Skeleton, Space } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import CourseDeactivationChangeRequestApiService from '../../../api/CourseDeactivationChangeRequestApiService';
import * as SaveCourseDeactivationChangeRequestStep1Handler from '../../../handlerModels/SaveCourseDeactivationChangeRequestStep1Handler';
import * as SubmitCourseDeactivationChangeRequestStep1Handler from '../../../handlerModels/SubmitCourseDeactivationChangeRequestStep1Handler';
import * as GetCourseDeactivationChangeRequestHandler from '../../../handlerModels/GetCourseDeactivationChangeRequestHandler';
import * as ExistingCourseModificationSearchHandler from '../../../handlerModels/ExistingCourseModificationSearchHandler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import CourseDeactivationChangeRequestDTO from '../../../models/CourseDeactivationChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import CourseSearch from '../../postSecondary/CourseSearch';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import ValueLabel from '../../general/ValueLabel';
import TermInput from '../../TermInput';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import CourseModificationChangeRequestApiService from '../../../api/CourseModificationChangeRequestApiService';
import modal from 'antd/lib/modal';

interface CourseDeactivationChangeRequestStep1Props extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  institutionId: string;
  loading?: boolean;

  onSubmit?: (changeRequestId: string) => void;
  onSave?: (changeRequestId: string) => void;
  readonly?: boolean;
}

interface CourseDeactivationChangeRequestStep1State extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  courseDeactivation: CourseDeactivationChangeRequestDTO;
}

class CourseDeactivationChangeRequestStep1Form extends React.Component<CourseDeactivationChangeRequestStep1Props, CourseDeactivationChangeRequestStep1State> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(CourseDeactivationChangeRequestDTO.courseId, {
        required: true,
        name: CourseDeactivationChangeRequestDTO.courseId,
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(CourseDeactivationChangeRequestDTO.rationale, {
        label: 'What is the rationale for deactivating this course?',
        required: true,
        name: CourseDeactivationChangeRequestDTO.rationale,
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(5000)
        ],
      })
      .set(CourseDeactivationChangeRequestDTO.deactivationYearTerm, {
        label: 'When will this course be deactivated?',
        required: true,
        name: CourseDeactivationChangeRequestDTO.deactivationYearTerm,
        rules: [
          ValidationRuleUtil.required()
        ],
      });
  }

  constructor(props: CourseDeactivationChangeRequestStep1Props) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      courseDeactivation: CourseDeactivationChangeRequestDTO.create(),
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      this.setState({
        fieldErrors: this.props.fieldErrors
      });
      if (this.props.changeRequestId != Guid.Empty()) {
        this.loadChangeRequest();
      }
    }
  }

  componentDidUpdate(prevProps: CourseDeactivationChangeRequestStep1Props) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.setState({
        fieldErrors: this.props.fieldErrors
      });
      if (this.props.changeRequestId != Guid.Empty()) {
        this.loadChangeRequest();
      }
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private loadChangeRequest = () => {
    const request = GetCourseDeactivationChangeRequestHandler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return CourseDeactivationChangeRequestApiService.get(request)
      .then((results: GetCourseDeactivationChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            courseDeactivation: results.changeRequest?.courseDeactivationChangeRequest ?? CourseDeactivationChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          });
          this.resetForm()
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private checkExisting = (finailize?: boolean) => {
    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const request = ExistingCourseModificationSearchHandler.Request.create({
      courseId: model.courseId.courseId == undefined ? model.courseId : model.courseId.courseId,
      installTerm: model.deactivationYearTerm?.termId,
      installYear: model.deactivationYearTerm?.year
    }) as ExistingCourseModificationSearchHandler.Request;

    CourseModificationChangeRequestApiService.checkCourseModificationExisting(request)
      .then((result: ExistingCourseModificationSearchHandler.Result) => {
        if (result.existingCourseModification) {

          modal.confirm({
            title: 'Existing Course Modification',
            content: 'There is already a Course Modification scheduled for this course and term combination. Click confirm to continue with the deactivation.',
            onOk: finailize ? this.handleSubmit : this.handleSave,
            okText: 'Confirm',
            okButtonProps: { danger: true }
          });
        }
        else {
          finailize ? this.handleSubmit() : this.handleSave();
        }
      })
      .catch(() => {
        message.error('Incomplete form');
      });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const model = CourseDeactivationChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();
    model.courseId = this._formRef.current?.getFieldValue(CourseDeactivationChangeRequestDTO.courseId)?.courseId ?? this.state.courseDeactivation.course?.id ?? Guid.Empty();
    model.rationale = this._formRef.current?.getFieldValue(CourseDeactivationChangeRequestDTO.rationale);
    model.deactivationTerm = model.deactivationTerm == '' ? null : model.deactivationTerm;

    const request = SaveCourseDeactivationChangeRequestStep1Handler.Request.create({
      institutionId: this.props.institutionId,
      courseDeactivationChangeRequest: model
    }) as SaveCourseDeactivationChangeRequestStep1Handler.Request;

    CourseDeactivationChangeRequestApiService.saveStep1(request)
      .then((result: SaveCourseDeactivationChangeRequestStep1Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            courseDeactivation: result.changeRequest?.courseDeactivationChangeRequest ?? CourseDeactivationChangeRequestDTO.create()
          });

          this.resetForm();

          if (this.props.onSave && result.changeRequest?.id) {
            this.props.onSave(result.changeRequest.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ saving: true });

    const model = CourseDeactivationChangeRequestDTO.create();
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();
    model.courseId = this._formRef.current?.getFieldValue(CourseDeactivationChangeRequestDTO.courseId)?.courseId ?? this.state.courseDeactivation.course?.id ?? Guid.Empty();
    model.rationale = this._formRef.current?.getFieldValue(CourseDeactivationChangeRequestDTO.rationale);
    model.deactivationYearTerm = this._formRef.current?.getFieldValue(CourseDeactivationChangeRequestDTO.deactivationYearTerm);

    const request = SubmitCourseDeactivationChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      institutionId: this.props.institutionId,
      CourseDeactivationChangeRequest: model
    }) as SubmitCourseDeactivationChangeRequestStep1Handler.Request;

    CourseDeactivationChangeRequestApiService.submitStep1(request)
      .then((result: SubmitCourseDeactivationChangeRequestStep1Handler.Result) => {
        this.setState({ loading: false, submitted: true });

        if (result?.succeeded) {
          if (this.props.onSubmit && result.changeRequest?.id) {
            this.props.onSubmit(result.changeRequest.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors,
            submitted: true
          });

          message.error('Submit Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Submit Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }
    const formItems = this.getFormItems();


    return (
      <Content>
        <Form ref={this._formRef}
          layout='vertical'
          onFinish={() => this.checkExisting(true)} >

          <Space direction='vertical'>
            {this.renderSearch()}
            {this.renderCourse()}

            <FormItem
              initialValue={this.state.courseDeactivation.rationale}
              {...formItems.get(CourseDeactivationChangeRequestDTO.rationale)}
              {...ValidationUtil.getValidation(CourseDeactivationChangeRequestDTO.rationale, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea maxLength={5000} autoSize={{ minRows: 5 }} showCount={true} disabled={this.props.readonly} />
            </FormItem>

            <FormItem
              initialValue={this.state.courseDeactivation.deactivationYearTerm}
              {...formItems.get(CourseDeactivationChangeRequestDTO.deactivationYearTerm)}
              {...ValidationUtil.getValidation(CourseDeactivationChangeRequestDTO.deactivationYearTerm, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <TermInput futureYears={true} disabled={this.props.readonly} />
            </FormItem>

            {this.renderActions()}
          </Space>
        </Form>
      </Content>
    );
  }

  renderCourse() {
    let title = this.state.courseDeactivation?.course?.discipline?.code ?? '';
    title += ' ' + this.state.courseDeactivation?.course?.catalogNumber ?? '';

    if (this.props.readonly) {
      return <ValueLabel text={title} />
    }
  }

  renderSearch() {
    const formItems = this.getFormItems();

    if (!this.props.readonly) {
      return <FormItem
        initialValue={this.state.courseDeactivation.courseId}
        {...formItems.get(CourseDeactivationChangeRequestDTO.courseId)}
        {...ValidationUtil.getValidation(CourseDeactivationChangeRequestDTO.courseId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
        <CourseSearch existingCourseId={this.state.courseDeactivation.course?.id ?? Guid.Empty()} institutionId={this.props.institutionId} />
      </FormItem>
    }
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveAndContinueButton submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={() => this.checkExisting()} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  renderErrors() {
    if (this.state.fieldErrors) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default CourseDeactivationChangeRequestStep1Form;