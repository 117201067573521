import { Card, Space, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProgramsDataTable from '../../../components/datatables/postSecondary/ProgramsDataTable';
import TermInput, { TermInputValue } from '../../../components/TermInput';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import InstitutionDTO from '../../../models/InstitutionDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';
import TermYearUtil, { TermYear } from '../../../utils/TermYearUtil';

interface ProgramListProps {
  institution: InstitutionDTO | null;
}

interface ProgramListState {
  selectedTermYear: TermInputValue | undefined;
  loading: boolean;
  activeTab: string;
}

class ProgramListPage extends React.Component<ProgramListProps & RouteComponentProps<RouteObject>, ProgramListState> {
  constructor(props: ProgramListProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      selectedTermYear: undefined,
      loading: false,
      activeTab: 'institution_code'
    };
  }

  componentDidMount() {
    const activeTab = ParameterUtil.getParameter('activeTab', 'institution_code');
    this.setState({ activeTab: activeTab });
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.selectedTermYear == null) {
      loaders.push(TermYearUtil.getCurrent().then((x: TermYear) => this.setState({
        selectedTermYear: {
          termId: x.termId ?? Guid.Empty(),
          year: x.year ?? 2021
        }
      })));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private handleTermYearChange = (termYear: TermInputValue) => {
    this.setState({
      selectedTermYear: termYear
    });
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  render() {
    const institutionAbbr = this.props.institution?.abbreviation;

    const tabs = (
      <Tabs activeKey={this.state.activeTab} onChange={this.changeTab} hidden={this.props.institution == null} >
        <Tabs.TabPane tab={institutionAbbr + ' Programs'} key="institution_code" />
        <Tabs.TabPane tab="All Programs" key="all" />
      </Tabs>
    );

    const institution = this.state.activeTab == 'all' ? null : this.props.institution;

    const termYear = {
      termId: this.state.selectedTermYear?.termId,
      year: this.state.selectedTermYear?.year
    } as TermYear;

    return (
      <Content >
        <HeaderPortal title="Programs" breadcrumbs={Breadcrumbs.programList()}
          extra={<TermInput value={this.state.selectedTermYear} onChange={this.handleTermYearChange} />}
          footer={tabs} />

        <Space direction='vertical' size='large'>
          <Card title="Programs">
            <ProgramsDataTable institution={institution} termYear={termYear} />
          </Card>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ProgramListPage));
