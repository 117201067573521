import { Collapse, Form, FormInstance, message, Skeleton, Space } from 'antd';
import * as React from 'react';
import UsersApiService from '../../api/UsersApiService';
import FeatureFlag from '../../consts/FeatureFlag';
import * as GetFeatureFlagsAndRolesForUserHandler from '../../handlerModels/GetFeatureFlagsAndRolesForUserHandler';
import * as SaveFeatureFlagsAndRolesForUserHandler from '../../handlerModels/SaveFeatureFlagsAndRolesForUserHandler';
import RoleDTO from '../../models/RoleDTO';
import BaseFormState from '../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import LookupsUtil from '../../utils/LookupsUtil';
import FeatureFlagEditor from '../postSecondary/FeatureFlagEditor';
import ResetButton from '../buttons/ResetButton';
import SaveButton from '../buttons/SaveButton';
import UserRoleEditor from '../UserRoleEditor';

interface UserRolesFormState extends BaseFormState {
  actionVisibility: string;
  previousSelectedFeatureFlagIds: string[];
  previousSelectedUserRoleIds: string[];
  selectedFeatureFlagIds: string[];
  selectedUserRoleIds: string[];
  roles: RoleDTO[];
}

interface UserRolesFormProps {
  userId: string;
  isEditing: boolean | undefined;
  onSave?: () => void;
}

class UserRolesForm extends React.Component<UserRolesFormProps, UserRolesFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private static readonly ACTIONS_OPEN = 'ACTIONS_OPEN';
  private static readonly ACTIONS_CLOSED = 'ACTIONS_CLOSED';

  constructor(props: UserRolesFormProps) {
    super(props);

    this.state = {
      actionVisibility: UserRolesForm.ACTIONS_CLOSED,
      previousSelectedFeatureFlagIds: [],
      previousSelectedUserRoleIds: [],
      selectedFeatureFlagIds: [],
      selectedUserRoleIds: [],
      roles: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: UserRolesFormProps) {
    if (prevProps.userId != this.props.userId) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    const loaders = [];

    if (this.state.selectedUserRoleIds.length == 0) {
      loaders.push(this.loadFeatureFlagsAndRolesForUser());
    }

    if (this.state.roles.length == 0) {
      loaders.push(this.loadRoles());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadFeatureFlagsAndRolesForUser = () => {
    this.setState({ loading: true });

    return UsersApiService.getFeatureFlagsAndRolesForUser(this.props.userId)
      .then((results: GetFeatureFlagsAndRolesForUserHandler.Result) => {
        if (results) {
          this.setState({
            selectedFeatureFlagIds: results.selectedFeatureFlagIds,
            previousSelectedFeatureFlagIds: JSON.parse(JSON.stringify(results.selectedFeatureFlagIds)),
            selectedUserRoleIds: results.userRoleIds ?? [],
            previousSelectedUserRoleIds: JSON.parse(JSON.stringify(results.userRoleIds))
          });
        }
      })
      .catch((error: any) => {
        this.setState({ error: true, message: error });
      });
  }

  private loadRoles = () => {
    this.setState({ loading: true });
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        if (results) {
          this.setState({ roles: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveFeatureFlagsAndRolesForUserHandler.Request.create({
      userId: this.props.userId,
      selectedFeatureFlagIds: this.state.selectedFeatureFlagIds,
      userRoleIds: this.state.selectedUserRoleIds
    });

    UsersApiService.saveFeatureFlagsAndRolesForUser(request)
      .then((result: SaveFeatureFlagsAndRolesForUserHandler.Result) => {
        if (result) {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors,
            altered: !result?.succeeded
          });

          if (result.succeeded) {
            this.setState({
              previousSelectedFeatureFlagIds: JSON.parse(JSON.stringify(this.state.selectedFeatureFlagIds)),
              previousSelectedUserRoleIds: JSON.parse(JSON.stringify(this.state.selectedUserRoleIds))
            });
            message.success('Saved');
            if (this.props.onSave) {
              this.props.onSave();
            }
          }
          else {
            message.error('Saved');
          }
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Saved');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
      selectedUserRoleIds: JSON.parse(JSON.stringify(this.state.previousSelectedUserRoleIds)),
      selectedFeatureFlagIds: JSON.parse(JSON.stringify(this.state.previousSelectedFeatureFlagIds))
    });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private onRoleChange = (userRoleIds: string[]) => {
    this.setState({ selectedUserRoleIds: userRoleIds });
  }

  private onUserFeatureFlagChange = (selectedFeatureFlagIds: string[]) => {
    selectedFeatureFlagIds.removeAll(this.getFeatureFlagIdsForRoles(this.state.selectedUserRoleIds));
    this.setState({ selectedFeatureFlagIds: selectedFeatureFlagIds });
  }

  private onActionsCollapseChange = () => {
    this.setState({
      actionVisibility: this.state.actionVisibility == UserRolesForm.ACTIONS_CLOSED
        ? UserRolesForm.ACTIONS_OPEN
        : UserRolesForm.ACTIONS_CLOSED
    });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Form ref={this._formRef} layout="vertical" onChange={this.handleChange} onFinish={this.handleSubmit} requiredMark={true}>
        <Space direction="vertical">
          {this.renderRoles()}
          {/* {this.renderFeatureFlags()} */}
          {this.renderActions()}
        </Space>
      </Form>
    );
  }

  renderActions() {
    if (this.props.isEditing && AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_USER])) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveButton disabled={this.state.loading} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
        </ Space>
      );
    }
  }

  renderRoles() {
    return (
      <UserRoleEditor
        selectedIds={this.state.selectedUserRoleIds}
        onChange={this.onRoleChange}
        disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
    );
  }

  renderFeatureFlags() {
    if (this.state.roles.length > 0 && AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_USER_ROLE, FeatureFlag.EDIT_USER_ROLE])) {

      const selectedFeatureFlagIds = JSON.parse(JSON.stringify(this.state.selectedFeatureFlagIds));
      const roleFeatureFlagIds = this.getFeatureFlagIdsForRoles(this.state.selectedUserRoleIds);
      selectedFeatureFlagIds.pushAll(roleFeatureFlagIds);
      selectedFeatureFlagIds.distinct();

      return (
        <Collapse onChange={this.onActionsCollapseChange} ghost={true} collapsible="header" defaultActiveKey={this.state.actionVisibility} >
          <Collapse.Panel header='Allowed Actions' key='ACTIONS_OPEN'>
            <Space direction="vertical" size="large">
              <FeatureFlagEditor
                selectedIds={selectedFeatureFlagIds}
                disabledIds={roleFeatureFlagIds}
                onChange={this.onUserFeatureFlagChange}
                isEditing={this.props.isEditing} />
            </ Space>
          </Collapse.Panel>
        </Collapse >
      );
    }
  }

  private getFeatureFlagIdsForRoles(userRoleIds: string[]) {
    const featureFlagIds: string[] = [];
    this.state.roles.forEach(r => {
      if (r.id) {
        if (userRoleIds.includes(r.id)) {
          featureFlagIds.pushAll(r.roleFeatureFlagIds);
        }
      }
    });

    featureFlagIds.distinct();
    return featureFlagIds;
  }
}

export default UserRolesForm;
