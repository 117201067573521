// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ExternalInstitutionDTO from './ExternalInstitutionDTO';
import NoticeOfIntentChangeRequestDTO from './NoticeOfIntentChangeRequestDTO';
import AzureBlobFileDTO from './AzureBlobFileDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoticeOfIntentChangeRequestInstitutionLinkageDTO extends EntitySelectorDTO { 
	id: string | null;
	externalInstitutionId: number;
	externalInstitution: ExternalInstitutionDTO | null;
	noticeOfIntentChangeRequestId: string | null;
	noticeOfIntentChangeRequest: NoticeOfIntentChangeRequestDTO | null;
	description: string | null;
	azureBlobFileId: string | null | null;
	azureBlobFile: AzureBlobFileDTO | null;
}

const NoticeOfIntentChangeRequestInstitutionLinkageDTO = {
    className: 'NoticeOfIntentChangeRequestInstitutionLinkageDTO',
    create: (initValues?: Partial<NoticeOfIntentChangeRequestInstitutionLinkageDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			externalInstitutionId: 0,
			externalInstitution: null,
			noticeOfIntentChangeRequestId: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequest: null,
			description: "",
			azureBlobFileId: null,
			azureBlobFile: null,
        },
        initValues);
    },
	id: 'id',
	externalInstitutionId: 'externalInstitutionId',
	externalInstitution: 'externalInstitution',
	noticeOfIntentChangeRequestId: 'noticeOfIntentChangeRequestId',
	noticeOfIntentChangeRequest: 'noticeOfIntentChangeRequest',
	description: 'description',
	azureBlobFileId: 'azureBlobFileId',
	azureBlobFile: 'azureBlobFile',
};

export default NoticeOfIntentChangeRequestInstitutionLinkageDTO;