// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudySectionComponentTypeDTO extends EntitySelectorDTO { 
	id: number;
	name: string | null;
	isEditable: boolean;
}

const SelfStudySectionComponentTypeDTO = {
    className: 'SelfStudySectionComponentTypeDTO',
    create: (initValues?: Partial<SelfStudySectionComponentTypeDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: 0,
			name: "",
			isEditable: false,
        },
        initValues);
    },
	id: 'id',
	name: 'name',
	isEditable: 'isEditable',
};

export default SelfStudySectionComponentTypeDTO;