// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetNewProgramChangeRequestStep11TermHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import NewProgramProgramTermDTO from '../models/NewProgramProgramTermDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    changeRequestId: string | null;
    programAwardId: string | null;
    termId: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            changeRequestId: '00000000-0000-0000-0000-000000000000',
            programAwardId: '00000000-0000-0000-0000-000000000000',
            termId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    changeRequestId: 'changeRequestId',
    programAwardId: 'programAwardId',
    termId: 'termId',
}

interface Result extends ResultDTO {
    term: NewProgramProgramTermDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            term: null,
        },
        initValues);
    },
    term: 'term',
}

export { Request, Result };