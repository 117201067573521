/* eslint-disable */
// @ts-ignore
interface TableRequestFilterDTO {
  columnProp: string | null;
  filter: string | null;
}

const TableRequestFilterDTO = {
  create: (initValues?: Partial<TableRequestFilterDTO> | Record<string, unknown> | null) => {
    return Object.assign(
      {
        columnProp: null,
        filter: null,
      },
      initValues);
  },
  columnProp: 'columnProp',
  filter: 'filter',
};

export default TableRequestFilterDTO;