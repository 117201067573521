import * as React from 'react';
import ChangeRequestTypeApprovalFlowApiService from '../../../api/ChangeRequestTypeApprovalFlowApiService';
import Routes from '../../../config/Routes';
import ChangeRequestTypeDTO from '../../../models/ChangeRequestTypeDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface ChangeRequestApprovalWorkflowDataTableProps extends BaseFormProps {
}

interface ChangeRequestApprovalWorkflowDataTableState extends BaseDataTableState<ChangeRequestTypeDTO>, BaseFormState {
}

class ChangeRequestApprovalWorkflowDataTable extends React.Component<ChangeRequestApprovalWorkflowDataTableProps, ChangeRequestApprovalWorkflowDataTableState> {
  private _dataTable: DataTable<ChangeRequestTypeDTO> | undefined;

  constructor(props: ChangeRequestApprovalWorkflowDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Title',
        dataIndex: ChangeRequestTypeDTO.name,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Days Valid',
        dataIndex: ChangeRequestTypeDTO.daysValid,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Cutoff Window',
        dataIndex: ChangeRequestTypeDTO.cutoffWindow,
        sorter: true,
        filterType: FilterType.Text,
      }
    ] as DataTableColumnProps<any>[];
  };

  private openWorkflow = (record: ChangeRequestTypeDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_TYPE_DETAILS, { id: record.id }, {}));
    }
  }
  private changeRequestApprovalWorkFlowExport = () => {
    window.open(Routes.generateFull(Routes.CHANGE_REQUEST_APPROVAL_FLOW_REPORT, {}, {}), '_blank')
  }
  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Default('Export', this.changeRequestApprovalWorkFlowExport));

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openWorkflow}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => ChangeRequestTypeApprovalFlowApiService.getChangeRequestTableData(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'ChangeRequestTypeApprovalFlow_list' }} />
      </>
    );
  }
}

export default ChangeRequestApprovalWorkflowDataTable;
