import { Card, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearsDataTable from '../../../components/datatables/secondary/AcademicYearsDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import HistoryUtil from '../../../utils/HistoryUtil';

interface AcademicYearListPageState {
}

interface AcademicYearListPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class AcademicYearListPage extends React.Component<AcademicYearListPageProps & RouteComponentProps<RouteObject>, AcademicYearListPageState> {

  constructor(props: AcademicYearListPageProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEARS, {}, {}));
  }

  render() {
    return (
      <Content >
        <HeaderPortal title='Program Review Years' breadcrumbs={Breadcrumbs.academicYearList()}
          onBack={this.onBack} />
        <Space direction="vertical" size="large">
          <Card title="Program Review Years">
            <Space direction='vertical' size="large">
              <AcademicYearsDataTable />
            </Space>
          </Card>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(AcademicYearListPage));
