import { Card, Layout } from 'antd';
import * as queryString from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LoginForm from '../../components/forms/LoginForm';
import BaseFormState from '../../redux/bases/BaseFormState';

interface LoginState extends BaseFormState {
  returnUrl?: string;
}

interface LoginProps {
}

class LoginPage extends React.Component<LoginProps & RouteComponentProps<RouteObject>, LoginState> {

  constructor(props: LoginProps & RouteComponentProps<RouteObject>) {
    super(props);

    const deepLinkState = (queryString.parse(props.location.search) as unknown) as LoginState;

    this.state = {
      returnUrl: deepLinkState.returnUrl,
    };
  }

  render() {
    return (
      <Layout>
        <Card>
          <LoginForm
            returnUrl={this.state.returnUrl} />
        </Card>
      </Layout>
    );
  }
}

export default withRouter(LoginPage);
