import { Button, Card, Col, message, Row, Skeleton, Space, Statistic, Typography } from 'antd';
import modal from 'antd/lib/modal';
import Link from 'antd/lib/typography/Link';
import * as React from 'react';
import { useEffect, useState } from 'react';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import Routes from '../../../config/Routes';
import * as ConfirmSelfStudiesHandler from '../../../handlerModels/ConfirmSelfStudiesHandler';
import * as GenerateSelfStudiesForProgramReviewHandler from '../../../handlerModels/GenerateSelfStudiesForProgramReviewHandler';
import * as ProgramsForReviewHandler from '../../../handlerModels/ProgramsForReviewHandler';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import HistoryUtil from '../../../utils/HistoryUtil';
import SelfStudyDataTable from '../../datatables/secondary/SelfStudyDataTable';

interface ProgramsForReviewFormProps extends BaseFormProps {
  academicYear?: number;
  selectedDistrict: DistrictDTO | null;
  selectedRPP: RegionalPlanningPartnerDTO | null;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  onSuccess?: (value: string) => void;
  resetForm?: (value: string) => void;
}

const ProgramsForReviewForm = React.forwardRef((props: ProgramsForReviewFormProps) => {

  const [loading, setLoading] = useState(false);
  const [confirmingPrograms, setConfirmingPrograms] = useState(false);
  const [totalPrograms, setTotalPrograms] = useState(0);
  const [approvedPrograms, setApprovedPrograms] = useState(0);
  const [extendedPrograms, setExtendedPrograms] = useState(0);
  const [pendingPrograms, setPendingPrograms] = useState(0);
  const [conditionalPrograms, setConditionalPrograms] = useState(0);
  const [newPrograms, setNewPrograms] = useState(0);

  useEffect(() => {
    fetchData()
  }, [props.academicYear, props.selectedRPP, props.selectedDistrict]);

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (props.academicYear && props.academicYear != 0) {
      loaders.push(GetSelfStudies());
    }

    Promise.all(loaders).then(() => {
      setLoading(false);
      if (props.resetForm) {
        props.resetForm;
      }
    });
  }

  const handleConfirmPrograms = () => {
    setConfirmingPrograms(true);
    const request = ConfirmSelfStudiesHandler.Request.create(
      {
        academicYear: props.academicYear
      });

    AcademicYearApiService.confirmSelfStudiesHandler(request)
      .then((x: ConfirmSelfStudiesHandler.Result) => {
        if (x.succeeded) {
          HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: props.academicYear ?? 0 }));

          message.success('Programs Confirmed');
          setConfirmingPrograms(false);
        }
        else {
          message.error('Failed to Confirm Programs');
          setConfirmingPrograms(false);
        }
      }).catch(() => {
        message.error('Failed to Confirm Programs');
        setConfirmingPrograms(false);
      })
      .finally(() => {
        setConfirmingPrograms(false);
      });
  }

  const GetSelfStudies = () => {
    const request = GenerateSelfStudiesForProgramReviewHandler.Request.create({
      academicYear: props.academicYear
    })

    return SelfStudyApiService.generateSelfStudiesForProgramReview(request).then((x: GenerateSelfStudiesForProgramReviewHandler.Result) => {
      if (x.succeeded) {
        GetProgramReviewStats();
      }
    })
      .catch(() => {
        message.error('Could not retrive data');
      });
  }

  const GetProgramReviewStats = () => {
    const request = ProgramsForReviewHandler.Request.create({
      academicYear: props.academicYear
    })

    return SelfStudyApiService.programsForReviewStats(request).then((x: ProgramsForReviewHandler.Result) => {
      if (x.succeeded) {
        setTotalPrograms(x.totalPrograms);
        setApprovedPrograms(x.approvedPrograms);
        setConditionalPrograms(x.conditionalPrograms);
        setExtendedPrograms(x.extendedPrograms);
        setPendingPrograms(x.pendingPrograms);
        setNewPrograms(x.newPrograms);
      }
    })
      .catch(() => {
        message.error('Could not retrive data');
      });
  }

  const onUserPageLinkClick = () => {
    HistoryUtil.push(Routes.generate(Routes.USERS, {}));
  }

  const promptConfirm = () => {
    modal.confirm({
      title: 'Users Updated?',
      content:
        <Space direction='vertical'>
          <Typography.Text>Have users been updated in the system? Confirming the programs will assign the initial users based on the current users in the system. <Link onClick={onUserPageLinkClick}>Users can be updated here.</Link></Typography.Text>
        </Space>,
      okText: 'Users have been updated',
      onOk: handleConfirmPrograms
    })
  }

  if (loading) {
    return (
      <Space direction='vertical'>
        <Typography.Title level={4}>Please wait while we fetch the program reviews.</Typography.Title >
        <Skeleton active />
      </Space>
    );
  }
  else {

    return (
      <Space size="small" direction="vertical" className={'ant-form ant-form-vertical'}>
        <SelfStudyDataTable
          programsForReview={true}
          academicYear={props.academicYear}
          selectedDistrict={props.selectedDistrict}
          selectedRPP={props.selectedRPP}
          fromSelfStudies={props.fromSelfStudies}
          fromAcademicYearList={props.fromAcademicYearList}
          refreshStats={GetSelfStudies} />

        <Card title='Breakdown' type='inner'>
          <Space direction='vertical' size='large'>
            <Row>
              <Col flex="auto" >
                <Statistic title={<Typography.Title level={5}>Total Programs for Review</Typography.Title>} value={totalPrograms} />
              </Col>
            </Row>
            <Row>
              <Space size='large'>
                <Col flex="auto" >
                  <Statistic title="Approved Programs" value={approvedPrograms} />
                </Col>
                <Col flex="auto" >
                  <Statistic title="Conditional Programs" value={conditionalPrograms} />
                </Col>
                <Col flex="auto" >
                  <Statistic title="Extended Programs" value={extendedPrograms} />
                </Col>
                <Col flex="auto" >
                  <Statistic title="New Programs" value={newPrograms} />
                </Col>
                <Col flex="auto" >
                  <Statistic title="Pending" value={pendingPrograms} />
                </Col>
              </Space>
            </Row>
          </Space>
        </Card>

        <Button title='Confirm Programs' type='primary' loading={confirmingPrograms} onClick={promptConfirm}>Confirm Programs</Button>
      </Space >
    );
  }

})

ProgramsForReviewForm.displayName = 'ProgramsForReviewForm';

export default ProgramsForReviewForm;