import { FormInstance, Rule } from 'antd/lib/form';
import { RefObject } from 'react';

export default class ValidationRuleUtil {
  public static maxLength(maxLength: number, field?: string): Rule {
    return { max: maxLength, message: ValidationRuleUtil.formatMessage('cannot be longer than ' + maxLength + ' characters.', field) };
  }

  public static minLength(minLength: number, field?: string): Rule {
    return { min: minLength, message: ValidationRuleUtil.formatMessage('must be longer than ' + minLength + ' characters.', field) };
  }

  public static onlyAlphanumericCharacters(field?: string): Rule {
    return { pattern: new RegExp(/^[A-Za-z0-9]+$/), message: ValidationRuleUtil.formatMessage('can only be alpha characters.', field) };
  }

  public static onlyAlphaCharacters(field?: string): Rule {
    return { pattern: new RegExp(/^[A-Za-z]+$/), message: ValidationRuleUtil.formatMessage('can only be alpha characters.', field) };
  }

  public static onlyNumericCharacters(field?: string): Rule {
    return { pattern: new RegExp(/^[0-9]+$/), message: ValidationRuleUtil.formatMessage('can only be numeric characters.', field) };
  }

  public static required(field?: string): Rule {
    return {
      required: true,
      message: ValidationRuleUtil.formatMessage('is required.', field)
    };
  }

  public static https(field?: string): Rule {
    return {
      pattern: new RegExp(/^(http|https):\/\//),
      message: ValidationRuleUtil.formatMessage('must include https:// or http://', field)
    };
  }

  public static cipNumberValidator(field?: string): Rule {
    return {
      pattern: new RegExp(/^[0-9]{1,2}$|^[0-9]{1,2}(\.\d{2})$|^[0-9]{1,2}(\.\d{4})$/),
      message: ValidationRuleUtil.formatMessage('Invalid formtat', field)
    };
  }

  public static phoneNumberValidator(field?: string): Rule {
    return {
      pattern: new RegExp(/^\d{3}-\d{3}-\d{4}$/),
      message: ValidationRuleUtil.formatMessage('Invalid formtat', field)
    };
  }

  static fieldsMatchValidator(form: RefObject<FormInstance>, matchField: string): Rule {
    return {
      validator: (rule: any, value: string, callback: any) => {
        if (value) {
          if (form.current) {
            const matchValue = form.current.getFieldValue(matchField);
            if (matchValue !== value) {
              return callback('error');
            }
          }
        }

        return callback();
      },
      message: 'Fields do not match'
    };
  }

  private static formatMessage(message: string, field?: string) {
    return (field ? field : 'Field') + ' ' + message;
  }
}
