// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ServiceAreaDTO from './ServiceAreaDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface FederalClusterDTO extends EntitySelectorDTO { 
	id: string | null;
	federalClusterIdentifier: number;
	academicYear: number;
	description: string | null;
	serviceAreaIdentifier: number | null | null;
	serviceAreaId: string | null | null;
	serviceArea: ServiceAreaDTO | null;
	display: string | null;
}

const FederalClusterDTO = {
    className: 'FederalClusterDTO',
    create: (initValues?: Partial<FederalClusterDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			federalClusterIdentifier: 0,
			academicYear: 0,
			description: "",
			serviceAreaIdentifier: null,
			serviceAreaId: null,
			serviceArea: null,
			display: "",
        },
        initValues);
    },
	id: 'id',
	federalClusterIdentifier: 'federalClusterIdentifier',
	academicYear: 'academicYear',
	description: 'description',
	serviceAreaIdentifier: 'serviceAreaIdentifier',
	serviceAreaId: 'serviceAreaId',
	serviceArea: 'serviceArea',
	display: 'display',
};

export default FederalClusterDTO;