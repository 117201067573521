import LookupTableDTO from '../models/LookupTableDTO';
import LookupsApiService from '../api/LookupsApiService'
import Guid from './Guid';


export class LookupsUtil {
  protected static _allActiveCache: Map<string, LookupTableDTO[]> = new Map<string, LookupTableDTO[]>();
  protected static _allCache: Map<string, LookupTableDTO[]> = new Map<string, LookupTableDTO[]>();

  protected static _singleActiveCache: Map<string, LookupTableDTO[]> = new Map<string, LookupTableDTO[]>();
  protected static _singleCache: Map<string, LookupTableDTO[]> = new Map<string, LookupTableDTO[]>();

  public getAll<TObj>(type: string, includeInactive?: boolean): Promise<TObj[]> {
    return this.getAllDTO(type, includeInactive)
      .then(r => {
        return r.map(d => d.data) as TObj[];
      });
  }

  public async getOne<TObj>(type: string, key: string | number, includeInactive?: boolean): Promise<TObj | undefined> {
    const all = await this.getAllDTO(type, includeInactive);
    return all.find(x => x.key === key)?.data;
  }

  private getAllDTO(type: string, includeInactive?: boolean): Promise<LookupTableDTO[]> {
    const cache = includeInactive ?? false ? LookupsUtil._allCache : LookupsUtil._allActiveCache;
    const data = cache.get(type);
    if (data) {
      return this.fromLocal(data as LookupTableDTO[], type);
    }

    else {
      return LookupsApiService.getAll(type, includeInactive)
        .then(r => {
          if (includeInactive) {
            // this._allCache[type] = r;
            LookupsUtil._allCache.set(type, r);
          }
          else {
            // this._allActiveCache[type] = r;
            LookupsUtil._allActiveCache.set(type, r);
          }

          return r;
        });
    }
  }

  private getOneDTO(type: string, key: string | number, includeInactive?: boolean): Promise<LookupTableDTO | undefined> {
    const singleCache = includeInactive ? LookupsUtil._singleCache : LookupsUtil._singleActiveCache;
    const allCache = includeInactive ? LookupsUtil._allCache : LookupsUtil._allActiveCache;

    if (allCache[type]) {
      singleCache[type] = allCache[type];
    }

    return this.fromLocal(singleCache[type], type, key)
      .then((r: LookupTableDTO[]) => {
        if (includeInactive) {
          LookupsUtil._singleCache[type] = r;
        }
        else {
          LookupsUtil._singleActiveCache[type] = r;
        }

        return r.find(x => x.key === key);
      });
  }

  public invalidateCache(type?: string | undefined) {
    if (type) {
      LookupsUtil._allCache[type] = null;
      LookupsUtil._allActiveCache[type] = null;
      LookupsUtil._singleCache[type] = null;
      LookupsUtil._singleActiveCache[type] = null;
    }
    else {
      LookupsUtil._allCache = new Map<string, LookupTableDTO[]>();
      LookupsUtil._allActiveCache = new Map<string, LookupTableDTO[]>();
      LookupsUtil._singleCache = new Map<string, LookupTableDTO[]>();
      LookupsUtil._singleActiveCache = new Map<string, LookupTableDTO[]>();
    }

    LookupsApiService.invalidateCache(type);
  }

  protected fromLocal(cache: LookupTableDTO[], name: string, key?: Guid | number | string | null): Promise<LookupTableDTO[]> {
    let promise = Promise.resolve<LookupTableDTO[]>([]);
    if (!cache) {
      cache = [];
    }

    if (key != null && !cache.find((a) => a.key === key)) {
      if (typeof key === 'number') {
        promise = LookupsApiService.getOneIntId(name, key as number).then((r: LookupTableDTO) => {
          return this.updateCache(cache, r);
        });
      }
      else if (key instanceof Guid) {
        promise = LookupsApiService.getOneStringId(name, key as string).then((r: LookupTableDTO) => {
          return this.updateCache(cache, r);
        });
      }
      else if (typeof key === 'string') {
        promise = LookupsApiService.getOneStringId(name, key as string).then((r: LookupTableDTO) => {
          return this.updateCache(cache, r);
        });
      }
    } else {
      promise = Promise.resolve<LookupTableDTO[]>(cache);
    }

    return promise.then((r) => this.filterAndSort(r));
  }

  protected updateCache(cache: LookupTableDTO[], r: LookupTableDTO): LookupTableDTO[] {
    if (r && !cache.find((x) => x.key === r.key)) {
      cache.push(r);
    }

    return cache;
  }

  protected filterAndSort(r: LookupTableDTO[], include?: Guid | number | string | null): LookupTableDTO[] {
    return r
      .filter((f) => f.isActive || f.key === include)
      .sort((a, b) => {
        let s = a.displayOrder - b.displayOrder;
        if (s === 0) {
          s = a.name?.localeCompare(b.name ?? '') ?? 0;
        }

        return s;
      });
  }
}

const util = new LookupsUtil();
export default util;
