import { Card, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import WorkItemDataTable from '../../components/datatables/WorkItemDataTable';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import InstitutionDTO from '../../models/InstitutionDTO';
import UserSecurity from '../../models/UserSecurity';
import HeaderPortal from '../../portals/HeaderPortal';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import Guid from '../../utils/Guid';
import HistoryUtil from '../../utils/HistoryUtil';
import ParameterUtil from '../../utils/ParameterUtil';

interface WorkItemListProps {
  institution: InstitutionDTO | null;
  currentUser: UserSecurity | null;
}

interface WorkItemListState {
  activeTab: string;
}

class WorkItemListPage extends React.Component<WorkItemListProps & RouteComponentProps<RouteObject>, WorkItemListState> {
  constructor(props: WorkItemListProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      activeTab: 'your_work_items'
    }
  }

  componentDidMount = () => {
    const activeTab = ParameterUtil.getParameter('activeTab', 'your_work_items');
    this.setState({ activeTab: activeTab });
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  render() {
    return (
      <Content >
        <HeaderPortal title="Work Items" breadcrumbs={Breadcrumbs.workItems()}
          footer={
            AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_INSTITUTION_WORK_ITEMS, FeatureFlag.VIEW_ALL_WORK_ITEMS]) ?
              <Tabs activeKey={this.state.activeTab} onChange={this.changeTab}>
                <Tabs.TabPane key='your_work_items' tab='You' />
                {
                  this.props.institution && AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_INSTITUTION_WORK_ITEMS]) ?
                    <Tabs.TabPane key='institution_work_items' tab={this.props.institution.abbreviation + ' Users'} />
                    : null
                }
                {
                  AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_ALL_WORK_ITEMS]) ?
                    <Tabs.TabPane key='all_work_items' tab='All Users' />
                    : null
                }
              </Tabs>
              : null
          } />

        {this.renderYourItems()}
        {this.renderInstitutionItems()}
        {this.renderAllItems()}

      </Content >
    );
  }

  renderYourItems() {
    if (this.state.activeTab === 'your_work_items') {
      return (
        <Card title='Personal'>
          <WorkItemDataTable currentUserId={this.props.currentUser?.userId ?? Guid.Empty()} userId={this.props.currentUser?.userId} institutionId={this.props.institution?.id} />
        </Card>
      );
    }
  }

  renderInstitutionItems() {
    if (this.state.activeTab === 'institution_work_items') {
      return (
        <Card title={this.props.institution?.name + ' Users'}>
          <WorkItemDataTable currentUserId={this.props.currentUser?.userId ?? Guid.Empty()} institutionId={this.props.institution?.id} />
        </Card>
      );
    }
  }

  renderAllItems() {
    if (this.state.activeTab === 'all_work_items') {
      return (
        <Card title='All Users'>
          <WorkItemDataTable currentUserId={this.props.currentUser?.userId ?? Guid.Empty()} />
        </Card>
      );
    }
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected,
    currentUser: state.UserSession.Value
  };
}

export default withRouter(connect(mapStateToProps)(WorkItemListPage));
