import { Alert, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionCourseBlockApiService from '../../../api/InstitutionCourseBlockApiService';
import * as GetInstitutionCourseBlocksHandler from '../../../handlerModels/GetInstitutionCourseBlocksHandler';
import InstitutionCourseBlockDTO from '../../../models/InstitutionCourseBlockDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';

interface SearchForCourseBlockState extends BaseFormState {
  courseBlocks: InstitutionCourseBlockDTO[];
  selectedCourseBlockId: string;
}

interface SearchForCourseBlockProps extends BaseFormProps {
  onSelect?: (courseBlock: InstitutionCourseBlockDTO) => void;
  courseBlocks?: InstitutionCourseBlockDTO[];
  courseBlockId?: string;
  institutionId: string;
}

class SearchForCourseBlock extends React.Component<SearchForCourseBlockProps, SearchForCourseBlockState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(InstitutionCourseBlockDTO.id, {
      required: true,
      name: InstitutionCourseBlockDTO.id,
      label: 'Course Block',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })

  constructor(props: SearchForCourseBlockProps) {
    super(props);

    this.state = {
      selectedCourseBlockId: '',
      courseBlocks: [],
    };
  }

  componentDidMount() {
    const loaders = [];

    if (this.state.selectedCourseBlockId == '') {
      this.setState({ selectedCourseBlockId: this.props.courseBlockId ?? '' });
      this._formRef.current?.setFieldsValue({ id: this.props.courseBlockId });
    }

    if (this.props.courseBlocks && this.props.courseBlocks.length != 0) {
      this.setState({ courseBlocks: this.props.courseBlocks })
    }
    else
      if (!this.state.courseBlocks || this.state.courseBlocks.length == 0) {
        loaders.push(this.loadInstitutionCourseBlocks());
        Promise.all(loaders).then(() => {
          this.setState({ loading: false });
        });
      }
  }
  componentDidUpdate(prevProps: SearchForCourseBlockProps) {
    if (prevProps.courseBlockId != this.props.courseBlockId) {
      const loaders = [];

      if (this.state.selectedCourseBlockId == '') {
        this.setState({ selectedCourseBlockId: this.props.courseBlockId ?? '' });
      }
      if (this.props.courseBlocks && this.props.courseBlocks.length != 0) {
        this.setState({ courseBlocks: this.props.courseBlocks })
      }
      else if (!this.state.courseBlocks || this.state.courseBlocks.length == 0) {
        loaders.push(this.loadInstitutionCourseBlocks());
        Promise.all(loaders).then(() => {
          this.setState({ loading: false });
        });
      }
    }
  }

  private loadInstitutionCourseBlocks = () => {

    const request = GetInstitutionCourseBlocksHandler.Request.create({
      institutionId: this.props.institutionId
    })

    InstitutionCourseBlockApiService.getInstitutionCourseBlocks(request)
      .then((results: GetInstitutionCourseBlocksHandler.Result) => {
        if (results) {
          this.setState({ courseBlocks: results.institutionCourseBlocks ?? [] })
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  public submit = () => {
    const form = this._formRef ? (this._formRef.current as any).getFieldsValue() : null
    if (this.props.onSelect) {
      this.props.onSelect(form.id)
    }
    return this.state.courseBlocks.find(x => x.id == form.id);
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public validateFields = () => {
    return this._formRef.current?.validateFields();
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }
    const value = { id: this.state.selectedCourseBlockId };

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={value}
          onValuesChange={this.handleChange}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(InstitutionCourseBlockDTO.id)}
            {...ValidationUtil.getValidation(InstitutionCourseBlockDTO.id, this.state.fieldErrors, this.state.submitted)}>
            <Select showSearch
              optionFilterProp="children"
            >
              {this.state.courseBlocks.map(x => this.renderCourseBlocks(x))}
            </Select>
          </FormItem>

        </Form >
      </Space >
    );
  }

  renderCourseBlocks(courseBlock: InstitutionCourseBlockDTO) {
    if (courseBlock.id) {
      return <Select.Option key={courseBlock.id ?? Guid.Empty()} value={courseBlock.id ?? Guid.Empty()}>{courseBlock.currentDetail?.title}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default SearchForCourseBlock;
