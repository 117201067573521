import * as React from 'react';
import AuthorizationUtil from '../utils/AuthorizationUtil';
import CurrentUser from '../utils/CurrentUser';

interface AuthorizedContentState { }

interface AuthorizedContentProps {
  validFeatureFlags?: string[];
  institution?: string | null;
}

class AuthorizedContent extends React.Component<AuthorizedContentProps, AuthorizedContentState> {
  constructor(props: AuthorizedContentProps) {
    super(props);

    this.state = {};
  }

  render() {
    let content = null as React.ReactNode;

    if (this.props.validFeatureFlags && AuthorizationUtil.isAuthorized(this.props.validFeatureFlags, this.props.institution)) {
      content = this.props.children;
    }
    else if (!this.props.validFeatureFlags && CurrentUser.Get()) {
      content = this.props.children
    }

    return content;
  }
}

export default AuthorizedContent;
