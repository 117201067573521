import { SaveOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import * as React from 'react';

interface SaveButtonState { }

interface SaveButtonProps extends ButtonProps {
  saving?: boolean;
  saved?: boolean;
}

class SaveButton extends React.Component<SaveButtonProps, SaveButtonState> {
  constructor(props: SaveButtonProps) {
    super(props);

    this.state = {};
  }

  render() {
    const htmlType = this.props.htmlType ? this.props.htmlType : 'submit'
    const buttonType = this.props.type ? this.props.type : 'primary'

    return (
      <Button
        {...this.props}
        htmlType={htmlType}
        type={buttonType}
        icon={this.props.saved ? <CheckOutlined /> : <SaveOutlined />}
        loading={this.props.saving || this.props.loading} >
        Save
      </Button>
    );
  }
}

export default SaveButton;
