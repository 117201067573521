// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetProgramsByInstitutionIdHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ProgramDTO from '../models/ProgramDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    instituionId: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            instituionId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    instituionId: 'instituionId',
}

interface Result extends ResultDTO {
    program: ProgramDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            program: [],
        },
        initValues);
    },
    program: 'program',
}

export { Request, Result };