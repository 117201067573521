import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CipNumberDataTable from '../../../../components/datatables/postSecondary/CipNumberDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import TermDTO from '../../../../models/TermDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface CipListProps {
}

interface CipListState {
  terms: TermDTO[];
}

class CipListPage extends React.Component<CipListProps & RouteComponentProps<RouteObject>, CipListState> {
  constructor(props: CipListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="CIP Numbers" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.cipNumberList()} />

        <Card >
          <CipNumberDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(CipListPage);
