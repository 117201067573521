// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudyResponseStatusTypeDTO from './SelfStudyResponseStatusTypeDTO';
import SelfStudyResponseTypeDTO from './SelfStudyResponseTypeDTO';
import UserDTO from './UserDTO';
import SelfStudySectionResponseDTO from './SelfStudySectionResponseDTO';
import SelfStudyResponsePreviousGoalDTO from './SelfStudyResponsePreviousGoalDTO';
import SelfStudyResponseNewGoalDTO from './SelfStudyResponseNewGoalDTO';
import SelfStudyResponseProgramOverviewDTO from './SelfStudyResponseProgramOverviewDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudyResponseDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudyId: string | null;
	selfStudyReponseTypeId: number;
	selfStudyResponseStatusTypeId: number;
	selfStudyResponseStatusType: SelfStudyResponseStatusTypeDTO | null;
	selfStudyResponseType: SelfStudyResponseTypeDTO | null;
	respondingUserId: string | null | null;
	respondingUser: UserDTO | null;
	selfStudySectionResponses: SelfStudySectionResponseDTO[] | null;
	selfStudyResponsePreviousGoals: SelfStudyResponsePreviousGoalDTO[] | null;
	selfStudyResponseNewGoals: SelfStudyResponseNewGoalDTO[] | null;
	selfStudyResponseProgramOverview: SelfStudyResponseProgramOverviewDTO | null;
}

const SelfStudyResponseDTO = {
    className: 'SelfStudyResponseDTO',
    create: (initValues?: Partial<SelfStudyResponseDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudyId: '00000000-0000-0000-0000-000000000000',
			selfStudyReponseTypeId: 0,
			selfStudyResponseStatusTypeId: 0,
			selfStudyResponseStatusType: null,
			selfStudyResponseType: null,
			respondingUserId: null,
			respondingUser: null,
			selfStudySectionResponses: [],
			selfStudyResponsePreviousGoals: [],
			selfStudyResponseNewGoals: [],
			selfStudyResponseProgramOverview: null,
        },
        initValues);
    },
	id: 'id',
	selfStudyId: 'selfStudyId',
	selfStudyReponseTypeId: 'selfStudyReponseTypeId',
	selfStudyResponseStatusTypeId: 'selfStudyResponseStatusTypeId',
	selfStudyResponseStatusType: 'selfStudyResponseStatusType',
	selfStudyResponseType: 'selfStudyResponseType',
	respondingUserId: 'respondingUserId',
	respondingUser: 'respondingUser',
	selfStudySectionResponses: 'selfStudySectionResponses',
	selfStudyResponsePreviousGoals: 'selfStudyResponsePreviousGoals',
	selfStudyResponseNewGoals: 'selfStudyResponseNewGoals',
	selfStudyResponseProgramOverview: 'selfStudyResponseProgramOverview',
};

export default SelfStudyResponseDTO;