import { Card, Select, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import SecondaryCourseApiService from '../../../api/SecondaryCourseApiService';
import SecondaryCoursesDataTable from '../../../components/datatables/secondary/SecondaryCoursesDataTable';
import Dropdown from '../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import * as GetSecondaryCoursesHandler from '../../../handlerModels/GetSecondaryCoursesHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import SecondaryCourseDTO from '../../../models/SecondaryCourseDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';


interface SecondaryCourseListPageState {
  loading: boolean;
  fromSelfStudies: boolean;
  academicYear: number;
  academicYears: AcademicYearDTO[];
  fromAcademicYearList: boolean;
  secondaryCourses: SecondaryCourseDTO[];
}

interface SecondaryCourseListPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class SecondaryCourseListPage extends React.Component<SecondaryCourseListPageProps & RouteComponentProps<RouteObject>, SecondaryCourseListPageState> {

  constructor(props: SecondaryCourseListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
      academicYears: [],
      fromSelfStudies: false,
      fromAcademicYearList: false,
      secondaryCourses: []
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYear'));
    this.setState({
      fromAcademicYearList: this.props.match.path.split('/').findIndex(x => x == 'academicYearList') > -1,
      fromSelfStudies: this.props.match.path.split('/').findIndex(x => x == 'selfStudy') > -1
    });

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id, fromSelfStudies: true, loading: true });
      this.loadSecondaryCourses(id);
      this.loadAcademicYears(id);
    }
    else {
      this.loadAcademicYears(id);
    }
  }

  private loadAcademicYears = (id: number) => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
      academicYearId: isNaN(id) ? null : id
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
            academicYear: DateTimeUtil.getCurrentYear()
          });
        }
      })
      .catch()
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private loadSecondaryCourses = (id: number) => {
    const request = GetSecondaryCoursesHandler.Request.create({
      academicYearId: isNaN(id) ? DateTimeUtil.getCurrentYear() : id
    });

    SecondaryCourseApiService.getSecondaryCourses(request)
      .then((results: GetSecondaryCoursesHandler.Result) => {
        if (results) {
          this.setState({
            secondaryCourses: results.secondaryCourse ?? []
          });
        }

      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private onSelect = (value: any) => {
    this.setState({ academicYear: value });
    this.loadSecondaryCourses(value);
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.SECONDARY_HOME, {}, {}));
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }

    return (
      <Content >
        <HeaderPortal title='Courses' breadcrumbs={Breadcrumbs.secondaryCourseList()}
          onBack={this.onBack}
          extra={<>
            <label title={'academicYear'} htmlFor={'academicYear'} />
            <Dropdown
              id='academicYear'
              value={this.state.academicYear}
              onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.academicYears.map(x => { return this.renderAcademicYear(x); })}</Dropdown>
          </>} />
        < Space direction="vertical" size="large">
          <Card title="Courses">
            <Space direction='vertical' size="large">
              <SecondaryCoursesDataTable
                academicYear={this.state.academicYear}
                fromSelfStudies={this.state.fromSelfStudies}
                fromAcademicYearList={this.state.fromAcademicYearList}
              />
            </Space>
          </Card>
        </Space>
      </Content>
    );
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</Select.Option>
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(SecondaryCourseListPage));
