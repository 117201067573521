import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space } from 'antd';
import * as React from 'react';
import UserCipAssignmentApiService from '../../api/UserCipAssignmentApiService';
import FeatureFlag from '../../consts/FeatureFlag';
import * as UnassignCipNumberFromUserHandler from '../../handlerModels/UnassignCipNumberFromUserHandler';
import TableRequestDTO from '../../models/TableRequestDTO';
import UserCIPAssignmentDTO from '../../models/UserCIPAssignmentDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import BaseFormState from '../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import DateTimeUtil from '../../utils/DateTimeUtil';
import FileDownload from '../../utils/FileDownload';
import AssignUserCipNumberForm from '../forms/AssignUserCipNumberForm';
import DataTable, { DataTableColumnProps, FilterType } from './core/DataTable';
import DataTableButtonUtil from './core/DataTableButtonUtil';

interface UserCipAssignmentIsInDataTableProps {
  userId: string;
  triggerUpdate?: () => void;
}

interface UserCipAssignmentIsInDataTableState extends BaseDataTableState<UserCIPAssignmentDTO>, BaseFormState {
  showCipAssignmentModal: boolean | false;
}

class UserCipAssignmentDataTable extends React.Component<UserCipAssignmentIsInDataTableProps, UserCipAssignmentIsInDataTableState> {
  private readonly _assignUserCipNumberFormRef = React.createRef<AssignUserCipNumberForm>();

  constructor(props: UserCipAssignmentIsInDataTableProps) {
    super(props);
    this.state = {
      showCipAssignmentModal: false
    };
  }

  private _dataTable: DataTable<UserCIPAssignmentDTO> | undefined;

  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'CIP Number',
        dataIndex: UserCIPAssignmentDTO.cipNumberId,
        render: (data: string, row: UserCIPAssignmentDTO) => {
          const details = row.cipNumber?.cipNumberDetails ?? [];
          const detail = details[0];
          return detail?.display ?? 'Unknown';
        },
        sorter: true,
        filterType: FilterType.Text,
        width: 300
      },
      {
        title: 'Title',
        dataIndex: UserCIPAssignmentDTO.cipNumber,
        render: (data: string, row: UserCIPAssignmentDTO) => {
          const details = row.cipNumber?.cipNumberDetails ?? [];
          const detail = details[0];
          return detail?.title ?? 'Unknown';
        },
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Actions',
        dataIndex: UserCIPAssignmentDTO.cipNumber,
        render: (data: string, row: UserCIPAssignmentDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CIP_NUMBER_ASSIGNMENTS])) {
            return <Button type="link" icon={<DeleteOutlined />} className="ant-btn" onClick={() => this.promptConfirmRemove(row.cipNumberId)} />;
          }
        },
        sorter: false,
        filterType: FilterType.NONE,
        width: 50
      }
    ] as DataTableColumnProps<any>[];
  };

  private addCipAssignment = () => {
    this.setState({ showCipAssignmentModal: true });
  }

  private submitAssignCipNumber = () => {
    this._assignUserCipNumberFormRef.current?.submit();
    this.setState({ showCipAssignmentModal: false });
    this.triggerRefresh();
  }

  private cancelAssignCipNumber = () => {
    this.setState({ showCipAssignmentModal: false });
    this._assignUserCipNumberFormRef.current?.reset();
    this.triggerRefresh();
  }

  private cipNumberAssigned = () => {
    this.setState({ showCipAssignmentModal: false });
    this.triggerRefresh();
  }

  private handleUnassignCipNumber = (cipNumberId: string | null) => {
    this.setState({ submitting: true });

    const request = UnassignCipNumberFromUserHandler.Request.create({
      cipNumberId: cipNumberId,
      userId: this.props.userId,
    });

    UserCipAssignmentApiService.unassignCipNumberToUser(request)
      .then((result: UnassignCipNumberFromUserHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('CIP Number has been unassigned.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('CIP Number could not be unassigned.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.triggerRefresh();
      });
  }

  private promptConfirmRemove = (cipNumberId: string | null) => {
    Modal.confirm({
      title: 'Are you sure you want to unassign this CIP Number',
      okText: 'Unassigning this CIP Number',
      onOk: () => this.handleUnassignCipNumber(cipNumberId)
    });
  }

  render() {
    return (
      <>
        {this.renderDataTable()}
        {this.renderAddCipAssignmentModal()}
      </>
    )

  }

  renderDataTable() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return UserCipAssignmentApiService.getUserCipAssignmentDataTableExport(tableRequest, this.props.userId).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`User_Cip_Assignment_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );
    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CIP_NUMBER_ASSIGNMENTS])) {
      actionButtons.push(DataTableButtonUtil.Default('Assign CIP Number', () => this.addCipAssignment()));
    }

    if (AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_CIP_NUMBER_ASSIGNMENTS])) {
      return (
        <Space direction='vertical' >
          <DataTable
            ref={(element: any) => (this._dataTable = element)}
            serverSide={true}
            tableProps={{
              rowKey: 'id',
              scroll: { x: 500 }
            }}
            globalSearch={true}
            buttonBar={actionButtons}
            columns={this.getColumnDefinitions()}
            fetchData={{
              fetch: (requestState: TableRequestDTO) => UserCipAssignmentApiService.getCipAssignmentsForUserTableData(requestState, this.props.userId)
            }}
            stateSaving={{ enabled: true, tableUniqueKey: 'user_cip_assignment_list' }} />
        </Space>
      );
    }
    else {
      return (
        <></>
      )
    }
  }

  renderAddCipAssignmentModal() {
    return (
      <Modal
        visible={this.state.showCipAssignmentModal}
        title='Assign CIP Number'
        okText='Assign'
        onOk={this.submitAssignCipNumber}
        onCancel={this.cancelAssignCipNumber}>
        <AssignUserCipNumberForm onAdd={this.cipNumberAssigned} userId={this.props.userId} ref={this._assignUserCipNumberFormRef} />
      </Modal>
    );
  }
}

export default UserCipAssignmentDataTable;
