import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Skeleton, Space, Table, Typography } from 'antd';
import * as React from 'react';
import ProgramModificationChangeRequestApiService from '../../../api/ProgramModificationChangeRequestApiService';
import Routes from '../../../config/Routes';
import * as RemoveAwardFromProgramModificationProgramHandler from '../../../handlerModels/RemoveAwardFromProgramModificationProgramHandler';
import * as GetProgramModificationChangeRequestStep2Handler from '../../../handlerModels/GetProgramModificationChangeRequestStep2Handler';
import * as SubmitProgramModificationChangeRequestStep2Handler from '../../../handlerModels/SubmitProgramModificationChangeRequestStep2Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import ProgramModificationChangeRequestDTO from '../../../models/ProgramModificationChangeRequestDTO';
import ProgramModificationProgramAwardDTO from '../../../models/ProgramModificationProgramAwardDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import PreviousButton from '../../buttons/PreviousButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import ValueLabel from '../../general/ValueLabel';

interface ProgramModificationChangeRequestStepFormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  programModification: ProgramModificationChangeRequestDTO;
}

interface ProgramModificationChangeRequestStepFormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  onSubmit?: () => void;
  onSave?: (id: string) => void;
  onPrevious?: () => void;
  onRemove?: (id: string) => void;
  onChange?: (percentage: number) => void;
  selectedInstitution: string | null;
}

class ProgramModificationChangeRequestStep2Form extends React.Component<ProgramModificationChangeRequestStepFormProps, ProgramModificationChangeRequestStepFormState> {

  constructor(props: ProgramModificationChangeRequestStepFormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      programModification: ProgramModificationChangeRequestDTO.create(),
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      this.loadChangeRequest();
    }
  }

  componentDidUpdate(prevProps: ProgramModificationChangeRequestStepFormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty() && prevProps.changeRequestId != this.props.changeRequestId) {
      this.loadChangeRequest();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private loadChangeRequest = () => {
    const request = GetProgramModificationChangeRequestStep2Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return ProgramModificationChangeRequestApiService.getStep2(request)
      .then((results: GetProgramModificationChangeRequestStep2Handler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            programModification: results.changeRequest?.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create()
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private openAward = (awardId: string) => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_3, { id: this.state.changeRequest.id ?? Guid.Empty(), awardId: awardId == 'new' ? Guid.Empty() : awardId }, {}))
  }

  private removeAwardModal = (awardId: string) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this award?',
      okText: 'Remove',
      onOk: () => this.removeAward(awardId),
      width: 500
    });
  }

  private removeAward = (awardId: string) => {
    const programModificationChangeRequest = this.state.programModification;
    programModificationChangeRequest.changeRequestId = this.state.changeRequest.id;

    const request = RemoveAwardFromProgramModificationProgramHandler.Request.create({
      programModificationChangeRequest: programModificationChangeRequest,
      awardId: awardId,
    });

    return ProgramModificationChangeRequestApiService.removeAwardFromProgramModificationProgramHandler(request)
      .then((results: RemoveAwardFromProgramModificationProgramHandler.Result) => {
        if (results) {
          this.setState({
            programModification: results.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create()
          });
          message.success('Award Removed')
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public render = () => {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }
    const fieldErrorText = this.state.fieldErrors ? this.state.fieldErrors[ProgramModificationChangeRequestDTO.programModificationAwards]?.map(x => x.message) : '';

    return (
      <Space direction="vertical">
        {this.renderPlannedAwards()}
        <ValueLabel errorMessage={this.state.fieldErrors ? true : false} text={fieldErrorText} />
        {this.renderActions()}

      </Space >
    );
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SubmitProgramModificationChangeRequestStep2Handler.Request.create({
      programModificationChangeRequest: this.state.programModification ?? null
    }) as SubmitProgramModificationChangeRequestStep2Handler.Request;

    ProgramModificationChangeRequestApiService.submitStep2(request)
      .then((result: SubmitProgramModificationChangeRequestStep2Handler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          message.success('Saved');
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  renderActions() {
    return (
      <Space direction='horizontal' wrap={true}>
        <PreviousButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton onClick={this.handleSubmit} submitting={this.state.submitting} />
      </Space>
    );
  }

  private getColumnDefinitionsForContactInformation = () => {
    return [
      {
        title: 'Order',
        dataIndex: ProgramModificationProgramAwardDTO.order,
        width: 15
      },
      {
        title: 'Title',
        dataIndex: ProgramModificationProgramAwardDTO.title,
        render: (data: string, row: ProgramModificationProgramAwardDTO) => {
          return <Button type="link" onClick={() => this.openAward(row.id ?? Guid.Empty())}>{data} </Button>
        },
      },
      {
        title: 'Award Type',
        dataIndex: ProgramModificationProgramAwardDTO.awardTypeId,
        render: (data: string, row: ProgramModificationProgramAwardDTO) => {
          return row.awardType?.code + ' - ' + row.awardType?.name;
        },
      },
      {
        title: 'Actions',
        dataIndex: ProgramModificationProgramAwardDTO.id,
        width: '15px',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: ProgramModificationProgramAwardDTO, index: any) => {
          return (this.state.programModification.programModificationAwards?.length ?? 0) > 0 ?
            <Button type="link" onClick={() => this.removeAwardModal(row.id ?? Guid.Empty())}><DeleteOutlined /></Button> :
            null;
        },
      }
    ] as any[];
  };

  renderPlannedAwards() {

    return (
      <Space direction='vertical'>
        <Table
          size='small'
          dataSource={this.state.programModification.programModificationAwards ?? []}
          pagination={false}
          columns={this.getColumnDefinitionsForContactInformation()}
        />
        <Button onClick={() => this.openAward('new')} icon={<PlusOutlined />}>
          Add Award
        </Button>
        <Typography.Text strong>Please review each award before continuing. Click on the title of the award to review.</ Typography.Text>
      </Space>
    );
  }
}

export default ProgramModificationChangeRequestStep2Form;