import { Button, Card, Row, Skeleton, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import SelfStudyDataTable from '../../../components/datatables/secondary/SelfStudyDataTable';
import AcademicYearDashboardSteps from '../../../components/displays/secondary/AcademicYearDashboardSteps';
import AcademicYearConfigurationForm from '../../../components/forms/secondary/AcademicYearConfigurationForm';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import AcademicYearStatusType from '../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetAcademicYearHandler from '../../../handlerModels/GetAcademicYearHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface AcademicYearDashboardPageProps {
  institution: InstitutionDTO | null;
  selectedDistrict: DistrictDTO | null;
  selectedRPP: RegionalPlanningPartnerDTO | null;
}

interface AcademicYearDashboardPageState {
  loading: boolean;
  academicYear: number;
  academicYearDto: AcademicYearDTO;
  activeTab: string;
}

class AcademicYearDashboardPage extends React.Component<AcademicYearDashboardPageProps & RouteComponentProps<RouteObject>, AcademicYearDashboardPageState> {
  constructor(props: AcademicYearDashboardPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
      activeTab: 'dashboard',
      academicYearDto: AcademicYearDTO.create()
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));
    const activeTab = ParameterUtil.getParameter('activeTab', 'dashboard');
    this.setState({ activeTab: activeTab });
    this.setState({ loading: true })
    if (this.state.academicYear != id) {
      this.loadAcademicYear(id);
    }
  }

  private loadAcademicYear = (id: number) => {
    this.setState({ loading: true });
    const request = GetAcademicYearHandler.Request.create({
      academicYearId: id
    });
    return AcademicYearApiService.getAcademicYear(request)
      .then((results: GetAcademicYearHandler.Result) => {
        if (results && results.academicYear) {
          this.setState({
            academicYearDto: this.cleanResultDates(results.academicYear) ?? AcademicYearDTO.create(),
            academicYear: id
          });
        }
      })
      .catch()
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  private cleanResultDates = (academicYear: AcademicYearDTO) => {
    if (academicYear != null) {
      academicYear.instructorStartDate = moment(academicYear.instructorStartDate);
      academicYear.instructorEndDate = moment(academicYear.instructorEndDate);
      academicYear.rppStartDate = moment(academicYear.rppStartDate);
      academicYear.rppEndDate = moment(academicYear.rppEndDate);
      academicYear.deStartDate = moment(academicYear.deStartDate);
      academicYear.deEndDate = moment(academicYear.deEndDate);
      academicYear.commentReleaseDate = moment(academicYear.commentReleaseDate);
      academicYear.finalizationDate = moment(academicYear.finalizationDate);
    }

    return academicYear;
  }

  private onBack = (fromAcademicYearList: boolean) => {
    if (fromAcademicYearList) {
      HistoryUtil.push(Routes.generate(Routes.ALL_ACADEMIC_YEARS, { academicYearId: this.state.academicYear }, {}));
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEARS, { academicYearId: this.state.academicYear }, {}));
    }
  }

  private onProgramConsultantCommentsReportClick = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEAR_PROGRAM_CONSULTANT_COMMENT_REPORT, { academicYearId: this.state.academicYear }));
  }

  private onRankingReportClick = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEAR_SELF_STUDY_RANKING_REPORT, { academicYearId: this.state.academicYear }));
  }

  private onSelfStudyAggregateReportClick = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEAR_SELF_STUDY_AGGREGATE_REPORT, { academicYearId: this.state.academicYear }));
  }

  private goToContactReport = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEAR_CONTACTS_REPORT, { academicYearId: this.state.academicYear }, {}));
  }

  private goToCareerAcademyReport = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEAR_CAREER_ACADEMY_REPORT, { academicYearId: this.state.academicYear }, {}));
  }

  private goToActionPlanReport = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEAR_ACTION_PLAN_REPORT, { academicYearId: this.state.academicYear }, {}));
  }

  private goToScenarioReport = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEAR_SCENARIO_REPORT, { academicYearId: this.state.academicYear }, {}));
  }

  private goToProgramMasterReport = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEAR_PROGRAM_MASTER_REPORT, { academicYearId: this.state.academicYear }, {}));
  }

  private goBack = () => {
    HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEARS, {}, {}));
  }

  render() {
    if (this.state.loading || this.state.academicYear == 0) {
      return <Skeleton active={true} />
    }

    let fromAcademicYearList = false;
    if (this.props.match.path.split('/').findIndex(x => x == 'academicYearList') > -1) {
      fromAcademicYearList = true;
    }

    const invalidStatusTypes = [AcademicYearStatusType.OPEN, AcademicYearStatusType.SELFSTUDYPREPARATION, AcademicYearStatusType.SELFSTUDYSUBMITTED];
    return (
      <Content >
        <HeaderPortal
          title={this.state.academicYear + ' - ' + (this.state.academicYear + 1)}
          onBack={this.goBack}
          breadcrumbs={Breadcrumbs.academicYearDashboard(this.state.academicYear)}
          footer={
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab} >
              <Tabs.TabPane id='dashboard' tab="Dashboard" key="dashboard" />
              {invalidStatusTypes.findIndex(x => x == this.state.academicYearDto.academicYearStatusTypeId) <= -1 ? <Tabs.TabPane tab="Program Reviews" key="programs" /> : null}
              {AuthorizationUtil.isAuthorized([FeatureFlag.DOWNLOAD_REPORTS]) ? <Tabs.TabPane tab="Reports" key="reports" /> : null}
              {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM_REVIEW, FeatureFlag.CONFIG_ACADEMIC_YEAR]) ? <Tabs.TabPane tab="Configuration" key="configuration" /> : null}
            </Tabs>
          } />
        {this.renderWorkFlowDashboard(fromAcademicYearList)}
        {this.renderSeflStudies()}
        {this.renderReportsTab()}
        {this.renderConfigurationPage()}
      </Content>
    );
  }

  renderWorkFlowDashboard(fromAcademicYearList: boolean) {
    if (this.state.activeTab == 'dashboard') {
      return (
        <Card title="Work Flow" >
          <AcademicYearDashboardSteps
            compactView={false}
            fromAcademicYearList={fromAcademicYearList}
            academicYear={this.state.academicYearDto}
            loadAcademicYear={this.loadAcademicYear}
            selectedDistrict={this.props.selectedDistrict}
            selectedRPP={this.props.selectedRPP} />
        </Card>
      );
    }
  }

  renderConfigurationPage() {
    if (this.state.activeTab == 'configuration') {
      return (
        <Card title="Configuration" >
          <AcademicYearConfigurationForm
            academicYear={this.state.academicYear}
          />
        </Card>
      );
    }
  }

  renderSeflStudies() {
    if (this.state.activeTab == 'programs') {
      return (
        <Card title="Programs" >
          <SelfStudyDataTable
            academicYearData={this.state.academicYearDto ?? undefined}
            academicYear={this.state.academicYear}
            selectedDistrict={this.props.selectedDistrict}
            selectedRPP={this.props.selectedRPP}
            fromAcademicDashboard={true} />
        </Card>
      );
    }
  }

  renderReportsTab() {
    if (AuthorizationUtil.isAuthorized([FeatureFlag.DOWNLOAD_REPORTS]) && this.state.activeTab == 'reports') {
      return (
        <Card title="Reports" >
          <Row>
            <Button type='link' onClick={this.goToActionPlanReport}>Action Plan Report</Button>
          </Row>
          <Row>
            <Button type='link' onClick={this.goToCareerAcademyReport}>Career Academy Report</Button>
          </Row>
          <Row>
            <Button type='link' onClick={this.goToContactReport}>Contact Information Report</Button>
          </Row>
          <Row>
            <Button type='link' onClick={this.onProgramConsultantCommentsReportClick}>Program Consultant Comment Report</Button>
          </Row>
          <Row>
            <Button type='link' onClick={this.onRankingReportClick}>Ranking Report</Button>
          </Row>
          <Row>
            <Button type='link' onClick={this.onSelfStudyAggregateReportClick}>Aggregate Report</Button>
          </Row>
          <Row>
            <Button type='link' onClick={this.goToScenarioReport}>Scenario Report</Button>
          </Row>
          <Row>
            <Button type='link' onClick={this.goToProgramMasterReport}>Program Master Report</Button>
          </Row>
        </Card>
      );
    }
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected,
    selectedDistrict: state.District.Selected,
    selectedRPP: state.RegionalPlanningPartner.Selected
  };
}

export default withRouter(connect(mapStateToProps)(AcademicYearDashboardPage));
