import { ArrowRightOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Select, Skeleton, Space, Typography } from 'antd';
import * as React from 'react';
import Routes from '../../../config/Routes';
import ChangeRequestType from '../../../consts/ChangeRequestType';
import ChangeRequestTypeDTO from '../../../models/ChangeRequestTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import Dropdown from '../../inputs/Dropdown';

interface NewChangeRequestFormState extends BaseFormState {
  changeRequestType: ChangeRequestTypeDTO[];
  selected: number | null;
}

interface NewChangeRequestFormProps extends BaseFormProps {
  institutionId: string;
  onSuccess?: () => void;
}

class NewChangeRequestForm extends React.Component<NewChangeRequestFormProps, NewChangeRequestFormState> {
  constructor(props: NewChangeRequestFormProps) {
    super(props);

    this.state = {
      changeRequestType: [],
      selected: null
    };
  }

  componentDidMount() {
    this.loadChangeRequestType();
  }

  componentDidUpdate() {
    if (this.state.changeRequestType.length == 0) {
      this.loadChangeRequestType();
    }
  }

  private loadChangeRequestType = () => {
    return LookupsUtil.getAll<ChangeRequestTypeDTO>(ChangeRequestTypeDTO.className)
      .then((results: ChangeRequestTypeDTO[]) => {
        if (results) {
          this.setState({ changeRequestType: results.filter(x => x.selectable) ?? [] });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private handleSubmit = () => {
    switch (this.state.selected) {
      case ChangeRequestType.ADMINISTRATIVECHANGE:
        HistoryUtil.push(Routes.generate(Routes.ADMINISTRATIVE_CHANGE_REQUEST_ENTRY));
        break;
      case ChangeRequestType.NEWDISCIPLINE:
        HistoryUtil.push(Routes.generate(Routes.NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY));
        break;
      case ChangeRequestType.NOTICEOFINTENT:
        HistoryUtil.push(Routes.generate(Routes.NEW_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY));
        break;
      case ChangeRequestType.NEWCOURSE:
        HistoryUtil.push(Routes.generate(Routes.NEW_COURSE_CHANGE_REQUEST_ENTRY));
        break;
      case ChangeRequestType.COURSEDEACTIVATION:
        HistoryUtil.push(Routes.generate(Routes.COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY));
        break;
      case ChangeRequestType.COURSEMODIFICATION:
        HistoryUtil.push(Routes.generate(Routes.COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY));
        break;
      case ChangeRequestType.NEWPROGRAM:
        HistoryUtil.push(Routes.generate(Routes.NEW_PROGRAM_CHANGE_REQUEST_ENTRY));
        break;
      case ChangeRequestType.PROGRAMDEACTIVATION:
        HistoryUtil.push(Routes.generate(Routes.PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY));
        break;
      case ChangeRequestType.PROGRAMMODIFICATION:
        HistoryUtil.push(Routes.generate(Routes.NEW_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY));
        break;
      default:
        break;
    }
  }

  private handleChange = (value: number) => {
    this.setState({
      selected: value
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <Input.Group compact>
          <Skeleton.Input active={true} />;
          <Skeleton.Button active={true} />;
        </Input.Group>
      );
    }

    return (
      <Space direction="vertical">
        <Space direction="vertical">
          <Typography.Title level={5}>Start New Change Requests</Typography.Title>
          <Typography.Text >Please select a change request type.</Typography.Text>
          <label title="New Change Request" htmlFor="New Change Request" >
            <Dropdown
            style={{width: '24%'}}
              dropdownMatchSelectWidth={false}
              onChange={this.handleChange}
              placeholder="Select Type">
              {this.state.changeRequestType.map(x => { return this.renderChangeRequestType(x) })}
            </Dropdown>
          </label>
          <Button htmlType="submit" type="primary" onClick={this.handleSubmit} icon={<ArrowRightOutlined />} >Start Change Request</Button>
        </Space >
      </Space >
    );
  }

  renderChangeRequestType(x: ChangeRequestTypeDTO) {
    return <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>;
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default NewChangeRequestForm;
