import AnnouncementStatus from '../consts/AnnouncementStatus';
import RoleType from '../consts/RoleType';
import WorkItemStatusType from '../consts/WorkItemStatusType';
import WorkItemType from '../consts/WorkItemType';
import AcademicYearStatusTypeDTO from '../models/AcademicYearStatusTypeDTO';
import AnnouncementTypeDTO from '../models/AnnouncementTypeDTO';
import ApprovalStatusTypeDTO from '../models/ApprovalStatusTypeDTO';
import AwardTypeDTO from '../models/AwardTypeDTO';
import CategoryDTO from '../models/CategoryDTO';
import ChangeRequestStatusTypeDTO from '../models/ChangeRequestStatusTypeDTO';
import ChangeRequestTypeDTO from '../models/ChangeRequestTypeDTO';
import ContactTypeDTO from '../models/ContactTypeDTO';
import InstitutionDTO from '../models/InstitutionDTO';
import IowaVariationDTO from '../models/IowaVariationDTO';
import ResourceCategoryDTO from '../models/ResourceCategoryDTO';
import RoleDTO from '../models/RoleDTO';
import RoleTypeDTO from '../models/RoleTypeDTO';
import SelfStudyStatusTypeDTO from '../models/SelfStudyStatusTypeDTO';
import SelfStudyResponseTypeDTO from '../models/SelfStudyResponseTypeDTO';
import StatusTypeDTO from '../models/StatusTypeDTO';
import LookupsUtil from './LookupsUtil';
import SecondaryProgramStatusTypeDTO from '../models/SecondaryProgramStatusTypeDTO';
import ProgramClassificationDTO from '../models/ProgramClassificationDTO';
import DocumentStatusDTO from '../models/DocumentStatusDTO';
import DocumentTypeDTO from '../models/DocumentTypeDTO';

export interface TableFilterOption {
  text: any,
  value: any
}

export default class TableFilterUtil {
  static RoleTypes() {
    return LookupsUtil.getAll<RoleTypeDTO>(RoleTypeDTO.className)
      .then((results: RoleTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id?.toString() } }) as TableFilterOption[];
      });
  }

  static ResourceCategories(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<ResourceCategoryDTO>(ResourceCategoryDTO.className)
      .then((results: ResourceCategoryDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id?.toString() } }) as TableFilterOption[];
      });
  }

  static Categories(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<CategoryDTO>(CategoryDTO.className)
      .then((results: CategoryDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id?.toString() } }) as TableFilterOption[];
      });
  }

  public static AwardTypes(includeInactive?: boolean): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<AwardTypeDTO>(AwardTypeDTO.className, includeInactive)
      .then((results: AwardTypeDTO[]) => {
        return results.map(c => { return { text: c.code, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static ApprovalStatus(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<ApprovalStatusTypeDTO>(ApprovalStatusTypeDTO.className)
      .then((results: ApprovalStatusTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static ChangeRequestTypeOptions(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<ChangeRequestTypeDTO>(ChangeRequestTypeDTO.className)
      .then((results: ChangeRequestTypeDTO[]) => {
        if (results) {
          return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
        }

        return [] as TableFilterOption[];
      });
  }

  public static Institutions(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        const insts = results.sort((a, b) => a.code - b.code);
        return insts.map(c => { return { text: c.code + ' - ' + c.name, value: c.id } }) as TableFilterOption[];
      });
  }

  public static CommunityCollegeids(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        return results.map(c => { return { text: c.code, value: c.code } }) as TableFilterOption[];
      });
  }

  public static ContactTypes(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<ContactTypeDTO>(ContactTypeDTO.className)
      .then((results: ContactTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static ChangeRequestStatusTypes(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<ChangeRequestStatusTypeDTO>(ChangeRequestStatusTypeDTO.className)
      .then((results: ChangeRequestStatusTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static IowaVariations(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<IowaVariationDTO>(IowaVariationDTO.className)
      .then((results: IowaVariationDTO[]) => {
        return results.map(c => { return { text: c.display, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static StatusTypes(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<StatusTypeDTO>(StatusTypeDTO.className)
      .then((results: StatusTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static ProgramClassificationTypes(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<ProgramClassificationDTO>(ProgramClassificationDTO.className)
      .then((results: ProgramClassificationDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static GetWorkItemStatusTypes(): TableFilterOption[] {
    return [
      { value: WorkItemStatusType.ACTIVE.toString(), text: 'Active' },
      { value: WorkItemStatusType.CANCELLED.toString(), text: 'Cancelled' },
      { value: WorkItemStatusType.COMPLETE.toString(), text: 'Complete' },
      { value: WorkItemStatusType.REMOVED.toString(), text: 'Removed' }
    ]
  }

  public static GetWorkItemTypes(): TableFilterOption[] {
    return [
      { value: WorkItemType.APPROVALREDIRECT.toString(), text: 'Approval Redirect' },
      { value: WorkItemType.CHANGEREQUESTDRAFT.toString(), text: 'Change Request Draft' },
      { value: WorkItemType.REVIEWCHANGEREQUEST.toString(), text: 'Review Change Request' },
      { value: WorkItemType.RESPONSETOSELFSTUDY.toString(), text: 'Response To Program' },
    ]
  }

  public static GetTrueOrFalse(): TableFilterOption[] {
    return [
      { value: 'true', text: 'Yes' },
      { value: 'false', text: 'No' },
    ]
  }

  public static AnnouncementTypes(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<AnnouncementTypeDTO>(AnnouncementTypeDTO.className)
      .then((results: AnnouncementTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static DocumentTypes(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<DocumentTypeDTO>(DocumentTypeDTO.className)
      .then((results: DocumentTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static DocumentStatuses(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<DocumentStatusDTO>(DocumentStatusDTO.className)
      .then((results: DocumentStatusDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static AcademicYearStatusTypes(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<AcademicYearStatusTypeDTO>(AcademicYearStatusTypeDTO.className)
      .then((results: AcademicYearStatusTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[];
      });
  }

  public static AnnouncementStatusTypes(): TableFilterOption[] {
    return [
      { text: AnnouncementStatus.ACTIVE, value: AnnouncementStatus.ACTIVE },
      { text: AnnouncementStatus.UPCOMING, value: AnnouncementStatus.UPCOMING },
      { text: AnnouncementStatus.SHOWN, value: AnnouncementStatus.SHOWN },
    ]
  }

  public static Roles(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id?.toString() } }) as TableFilterOption[];
      });
  }

  static InstitutionRoles() {
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        return results.filter(x => x.roleTypeId == RoleType.INSTITUTION).map(c => { return { text: c.name, value: c.id?.toString() } }) as TableFilterOption[];
      });
  }

  static StateRoles() {
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        return results.filter(x => x.roleTypeId == RoleType.STATE).map(c => { return { text: c.name, value: c.id?.toString() } }) as TableFilterOption[];
      });
  }

  static SecondaryProgramStatus() {
    return LookupsUtil.getAll<SecondaryProgramStatusTypeDTO>(SecondaryProgramStatusTypeDTO.className)
      .then((results: SecondaryProgramStatusTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id?.toString() } }) as TableFilterOption[];
      });
  }

  public static SelfStudyStatusTypes(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<SelfStudyStatusTypeDTO>(SelfStudyStatusTypeDTO.className)
      .then((results: SelfStudyStatusTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id } }) as TableFilterOption[];
      });
  }

  public static SelfStudyResponseTypes(): Promise<TableFilterOption[]> {
    return LookupsUtil.getAll<SelfStudyResponseTypeDTO>(SelfStudyResponseTypeDTO.className)
      .then((results: SelfStudyResponseTypeDTO[]) => {
        return results.map(c => { return { text: c.name, value: c.id } }) as TableFilterOption[];
      });
  }
}