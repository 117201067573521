import * as React from 'react';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';
import { Steps } from 'antd';

interface OpeningAcademicYearStepsProps extends BaseFormProps {
  currentStep: number
}

function OpeningAcademicYearSteps(props: OpeningAcademicYearStepsProps) {

  return (
    <CollapsibleSteps direction="vertical" current={props.currentStep}>
      <Steps.Step title={<NavigationStep title='Set Milestone Dates' />}/>
      <Steps.Step title={<NavigationStep title='Review Area Education Agency'/>} />
      <Steps.Step title={<NavigationStep title='Review Active Users' />}/>
      <Steps.Step title={<NavigationStep title='Review Course Data'/>} />
      <Steps.Step title={<NavigationStep title='Review Program Data'/>} />
      <Steps.Step title={<NavigationStep title='Start Program Review Year'/>} />
    </CollapsibleSteps>
  );
}

export default OpeningAcademicYearSteps;