// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ChangeRequestTypeDTO from '../models/ChangeRequestTypeDTO';
import * as GetWorkflowDetailsHandler from '../handlerModels/GetWorkflowDetailsHandler';
import * as SaveWorkflowDetailsHandler from '../handlerModels/SaveWorkflowDetailsHandler';

export class ChangeRequestTypeApprovalFlowApiService extends BaseApi {

    // post: api/changeRequestsApprovalWorkFlow/list
    public getChangeRequestTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ChangeRequestTypeDTO>> {
        let url = `api/changeRequestsApprovalWorkFlow/list`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ChangeRequestTypeDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/changeRequestsApprovalWorkFlow/${changeRequestTypeApprovalWorkflowId}
    public getChangeRequestApprovalWorkflowDetailsHandler(changeRequestTypeApprovalWorkflowId: number): Promise<GetWorkflowDetailsHandler.Result> {
        let url = `api/changeRequestsApprovalWorkFlow/${changeRequestTypeApprovalWorkflowId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetWorkflowDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/changeRequestsApprovalWorkFlow/export
    public getChangeRequestApprovalFlowDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/changeRequestsApprovalWorkFlow/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/changeRequestsApprovalWorkFlow/save
    public saveChangeRequestApprovalWorkflowDetailsHandler(saveWorkflowDetails: SaveWorkflowDetailsHandler.Request): Promise<SaveWorkflowDetailsHandler.Result> {
        let url = `api/changeRequestsApprovalWorkFlow/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveWorkflowDetailsHandler.Result>(saveWorkflowDetails, 'post', url, true, false);
    }

    // get: api/changeRequestsApprovalWorkFlow/changeRequestApprovalWorkFlowReport
    public exportChangeRequestApprovalWorkFlows(): Promise<any> {
        let url = `api/changeRequestsApprovalWorkFlow/changeRequestApprovalWorkFlowReport`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new ChangeRequestTypeApprovalFlowApiService();
export default service;
