import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import NoticeOfIntentChangeRequestApiService from '../../../api/NoticeOfIntentChangeRequestApiService';
import Routes from '../../../config/Routes';
import * as SaveNoticeOfIntentChangeRequestStep6Handler from '../../../handlerModels/SaveNoticeOfIntentChangeRequestStep6Handler';
import * as SubmitNoticeOfIntentChangeRequestStep6Handler from '../../../handlerModels/SubmitNoticeOfIntentChangeRequestStep6Handler';
import * as GetNoticeOfIntentChangeRequestStep6Handler from '../../../handlerModels/GetNoticeOfIntentChangeRequestStep6Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import GenderDTO from '../../../models/GenderDTO';
import LocationTypeDTO from '../../../models/LocationTypeDTO';
import NoticeOfIntentChangeRequestDTO from '../../../models/NoticeOfIntentChangeRequestDTO';
import NoticeOfIntentChangeRequestOccupationDTO from '../../../models/NoticeOfIntentChangeRequestOccupationDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FileUploadUtil from '../../../utils/FileUploadUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import FileUpload from '../../FileUpload';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';

interface NoticeOfIntentChangeRequestStep6FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  noticeOfIntent: NoticeOfIntentChangeRequestDTO;
  locationTypes: LocationTypeDTO[];
  genders: GenderDTO[];
}

interface NoticeOfIntentChangeRequestStep6FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  institutionId: string | null;
  onSave?: () => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  onChange?: (altered: boolean) => void;
  readonly?: boolean;
}

class NoticeOfIntentChangeRequestStep6Form extends React.Component<NoticeOfIntentChangeRequestStep6FormProps, NoticeOfIntentChangeRequestStep6FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    const externalAccreditationRequirement = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements);
    const formItems = new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestDTO.additionalLaborMarketInformation, {
        name: NoticeOfIntentChangeRequestDTO.additionalLaborMarketInformation,
        label: 'Please provide any additional labor and market information.'
      })
      .set(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements, {
        required: true,
        name: NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements,
        label: 'Are there any external accreditation requirements for this program?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirementDetails, {
        required: externalAccreditationRequirement,
        name: NoticeOfIntentChangeRequestDTO.externalAccreditationRequirementDetails,
        label: 'Please provide any information about the external accreditation requirements.',
        rules: externalAccreditationRequirement ? [ValidationRuleUtil.required()] : []
      })
      .set(SaveNoticeOfIntentChangeRequestStep6Handler.Request.industryFiles, {
        name: SaveNoticeOfIntentChangeRequestStep6Handler.Request.industryFiles,
        label: 'Please provide any supporting labor market documents or information regarding industry standards and benchmarks.',
      });

    return formItems;
  }

  constructor(props: NoticeOfIntentChangeRequestStep6FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      locationTypes: [],
      genders: [],
      noticeOfIntent: NoticeOfIntentChangeRequestDTO.create({
        externalAccreditationRequirements: null,
      }),
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      this.loadChangeRequest();
    }
  }

  componentDidUpdate(prevProps: NoticeOfIntentChangeRequestStep6FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty() && this.props.changeRequestId != prevProps.changeRequestId) {
      this.loadChangeRequest();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private loadChangeRequest = () => {
    const request = GetNoticeOfIntentChangeRequestStep6Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NoticeOfIntentChangeRequestApiService.getStep6(request)
      .then((results: GetNoticeOfIntentChangeRequestStep6Handler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            noticeOfIntent: results.changeRequest?.noticeOfIntentChangeRequest ?? NoticeOfIntentChangeRequestDTO.create(),
            fieldErrors: this.props.fieldErrors,
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });

    this._formRef.current?.resetFields();
    if (this.props.onChange) {
      this.props.onChange(false)
    }
  }

  private handleSave = () => {
    const noi = this._formRef.current?.getFieldsValue() ?? null;
    noi.changeRequestId = this.props.changeRequestId;

    const model = SaveNoticeOfIntentChangeRequestStep6Handler.Request.create({
      noticeOfIntentChangeRequest: noi,
      removedFiles: noi.industryFiles?.removedFiles ?? []
    })

    const request = FileUploadUtil.attachFilesToModel('saveNoticeOfIntentChangeRequestStep6', model, SaveNoticeOfIntentChangeRequestStep6Handler.Request.industryFiles, noi.industryFiles?.newFiles);

    NoticeOfIntentChangeRequestApiService.saveStep6(request)
      .then((result: SaveNoticeOfIntentChangeRequestStep6Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false, altered: false });
      });
  }

  private handleSubmit = () => {
    const noi = this._formRef.current?.getFieldsValue() ?? null;
    noi.changeRequestId = this.props.changeRequestId;

    const model = SaveNoticeOfIntentChangeRequestStep6Handler.Request.create({
      noticeOfIntentChangeRequest: noi,
      removedFiles: noi.industryFiles?.removedFiles ?? []
    })

    const request = FileUploadUtil.attachFilesToModel('submitNoticeOfIntentChangeRequestStep6', model, SaveNoticeOfIntentChangeRequestStep6Handler.Request.industryFiles, noi.industryFiles?.newFiles);

    NoticeOfIntentChangeRequestApiService.submitStep6(request)
      .then((result: SubmitNoticeOfIntentChangeRequestStep6Handler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
    if (this.props.onChange) {
      this.props.onChange(true)
    }
  }

  render() {
    if (this.props.loading) {
      return <Skeleton active={true} />;
    }

    const existingIndustryResources = this.state.changeRequest?.noticeOfIntentChangeRequest?.industryResources?.map(x => {
      return {
        uid: x.azureBlobFileId,
        url: Routes.generateDownload(x.azureBlobFileId ?? Guid.Empty()),
        name: x.azureBlobFile?.fileName
      }
    });

    const formItems = this.getFormItems();

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.noticeOfIntent}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}>
          <Space direction="vertical">
            <Form.Item
              {...formItems.get(NoticeOfIntentChangeRequestDTO.additionalLaborMarketInformation)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.additionalLaborMarketInformation, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
            </Form.Item>

            <Form.Item {...formItems.get(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
              <YesNoInput disabled={this.props.readonly} />
            </Form.Item>

            {this.renderExternalAccreditationRequirementDetails(formItems)}

            <Form.Item
              {...formItems.get(SaveNoticeOfIntentChangeRequestStep6Handler.Request.industryFiles)}
              {...ValidationUtil.getValidation(SaveNoticeOfIntentChangeRequestStep6Handler.Request.industryFiles, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <FileUpload disabled={this.props.readonly} existingList={existingIndustryResources} />
            </Form.Item>

            {this.renderActions()}
          </Space>
        </Form >
      </Space >
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <GoBackButton onClick={this.props.onPrevious} />
          <SaveAndContinueButton submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  renderExternalAccreditationRequirementDetails(formItems: Map<string, FormItemProps>) {
    if (this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements)) {
      return (
        <Form.Item
          {...formItems.get(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirementDetails)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirementDetails, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
          <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
        </Form.Item >
      );
    }
  }

  renderProfessionalCredentialRequirementDetails(name: string, index: number, formItems: Map<string, FormItemProps>) {
    if (this._formRef.current?.getFieldValue([name, NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirements])) {
      return (
        <Form.Item
          {...formItems.get(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirementDetails)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirementDetails + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
          <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
        </Form.Item>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NoticeOfIntentChangeRequestStep6Form