import { Modal, Typography } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import UserSecurity from '../models/UserSecurity';
import { UserSessionActions } from '../redux/actions/UserSessionActions';
import { StateStoreModel } from '../redux/state/StateStoreModel';
import IdleTimer from 'react-idle-timer';

interface AutoLogoutState {
}

interface AutoLogoutProps {
  userInfo: UserSecurity | null;
  logoutAction: () => void;
  onActive?: any;
  idleMinutes: number;
  warnMinutes?: number;
}

class AutoLogout extends React.Component<AutoLogoutProps, AutoLogoutState> {
  idleTimer: any;
  idleRef: any;
  warnRef: any;

  constructor(props: AutoLogoutProps) {
    super(props);

    this.state = {
    };
  }

  private handleIdle = () => {
    const warnTime = this.props.warnMinutes ?? 0;

    if (warnTime === 0) {
      this.handleLogout();
    }
    else {
      Modal.warn({
        title: 'Are you still there?',
        okText: 'I\'m Still Here',
        onOk: this.handleActive,
        content: < div >
          <Typography.Paragraph>
            You are about to be logged out due to inactivity.
          </Typography.Paragraph>
          <IdleTimer ref={(ref: any) => this.warnRef = ref} key='warn_timer' timeout={1000 * 60 * warnTime} onIdle={this.handleLogout} />
        </div>
      });
    }
  }

  private handleActive = () => {
    if (this.props.onActive) {
      this.props.onActive();
    }
  }

  private handleLogout = () => {
    this.props.logoutAction();
    Modal.destroyAll();
    Modal.info({
      title: 'Logged Out',
      content: 'You have been logged out due to inactivity.'
    });
  }

  render() {
    const idleTime = this.props.idleMinutes > 0 ? this.props.idleMinutes : 15;
    const preWarnTime = idleTime - (this.props.warnMinutes ?? 0);

    if (this.props.userInfo) {
      return <IdleTimer ref={(ref: any) => this.idleRef = ref} key='idle_timer' timeout={1000 * 60 * preWarnTime} onIdle={this.handleIdle} />
    }

    return null;
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    logoutAction: () => dispatch(UserSessionActions.logout())
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    userInfo: state.UserSession.Value,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogout);
