import { CaretDownOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Layout, Popover, Row, Space, Typography } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Routes from '../../config/Routes';
import InstitutionDTO from '../../models/InstitutionDTO';
import UserSecurity from '../../models/UserSecurity';
import { UserSessionActions } from '../../redux/actions/UserSessionActions';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import LookupsUtil from '../../utils/LookupsUtil';
import AuthorizedContent from '../AuthorizedContent';
import LoginForm from '../forms/LoginForm';
import UserHeaderMenu from './UserHeaderMenu';
import OrganizationSelector from './OrganizationSelector';

const { Header } = Layout;

interface SticsHeaderState {
  institutions: InstitutionDTO[];
  loading: boolean;
}

interface SticsHeaderProps {
  collapsed: boolean;
  userInfo?: UserSecurity | null;
  logoutAction?: () => void | undefined;
  toggleMenu?: () => void | undefined;
}

class SticsHeader extends React.Component<SticsHeaderProps, SticsHeaderState>  {

  constructor(props: SticsHeaderProps) {
    super(props);

    this.state = {
      institutions: [],
      loading: false
    };
  }

  componentDidMount() {
    const loaders = [];

    loaders.push(this.loadInstitutions());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      });
  }

  render() {
    return (
      <Header className="app-header">
        <Row style={{ paddingLeft: '20px', paddingRight: '20px' }} className="align-center">
          <AuthorizedContent>
            {this.renderMenuToggle()}
          </AuthorizedContent>
          <Space style={{ marginLeft: '20px', marginRight: 'auto' }}>
            <Link to={Routes.HOME} >
              <Space direction="horizontal" size="large">
                <img src={process.env.PUBLIC_URL + '/IDOE-logo-grayscale.svg'} alt="IDOE.STICS" style={{ width: '40px', height: '40px' }} />

                <div>
                  <Typography.Title level={4} style={{ color: 'white' }} className="header-title">
                    Iowa Department of Education
                  </Typography.Title>
                </div>
              </Space>
            </Link>
          </Space>
          {this.renderUserDropdown()}
        </Row>
      </Header>
    );
  }

  renderMenuToggle() {
    const icon = this.props.collapsed ? <MenuUnfoldOutlined onClick={this.props.toggleMenu} className="header-icon" /> : <MenuFoldOutlined onClick={this.props.toggleMenu} className="header-icon" />;

    return (
      <div className="align-center" >
        {icon}
      </div>
    )
  }

  renderUserDropdown() {
    if (this.props.userInfo) {
      return (
        <Space>
          <OrganizationSelector />
          <UserHeaderMenu />
        </Space>
      );
    }

    return this.renderLogin();
  }

  renderLogin() {
    const content = (
      <LoginForm style={{ width: '300px' }} />
    );

    return (
      <Popover key='login_popover' placement="bottomRight" title='' content={content} trigger="click">
        <Button shape='round' size='large' type='primary'>
          Login <CaretDownOutlined />
        </Button>
      </Popover>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    logoutAction: () => dispatch(UserSessionActions.logout()),
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    userInfo: state.UserSession.Value,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SticsHeader);
