// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import InstitutionDTO from '../models/InstitutionDTO';
import * as GetInstitutionDetailsHandler from '../handlerModels/GetInstitutionDetailsHandler';
import * as SaveInstitutionDetailsHandler from '../handlerModels/SaveInstitutionDetailsHandler';
import * as CheckInstitutionNameExistsHandler from '../handlerModels/CheckInstitutionNameExistsHandler';
import * as DeleteInstitutionHandler from '../handlerModels/DeleteInstitutionHandler';
import * as GetInstitutionCreditTypeMinuteTableDataHandler from '../handlerModels/GetInstitutionCreditTypeMinuteTableDataHandler';
import * as SaveInstitutionCreditTypeMinuteHandler from '../handlerModels/SaveInstitutionCreditTypeMinuteHandler';

export class InstitutionsApiService extends BaseApi {

    // post: api/institutions
    public getInstitutionTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<InstitutionDTO>> {
        let url = `api/institutions`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstitutionDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutions/export
    public getInstitutionsDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/institutions/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutions/${encodeURIComponent(institutionId)}
    public getInstitutionDetails(institutionId: string): Promise<GetInstitutionDetailsHandler.Result> {
        let url = `api/institutions/${encodeURIComponent(institutionId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutions/save
    public saveInstitutionDetails(saveInstitutionDetails: SaveInstitutionDetailsHandler.Request): Promise<SaveInstitutionDetailsHandler.Result> {
        let url = `api/institutions/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveInstitutionDetailsHandler.Result>(saveInstitutionDetails, 'post', url, true, false);
    }

    // post: api/institutions/checkNameExists
    public checkInstitutionNameExists(checkInstitutionNameExists: CheckInstitutionNameExistsHandler.Request): Promise<CheckInstitutionNameExistsHandler.Result> {
        let url = `api/institutions/checkNameExists`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, CheckInstitutionNameExistsHandler.Result>(checkInstitutionNameExists, 'post', url, true, false);
    }

    // post: api/institutions/deleteInstitution
    public deleteInstitution(deleteInstitution: DeleteInstitutionHandler.Request): Promise<DeleteInstitutionHandler.Result> {
        let url = `api/institutions/deleteInstitution`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteInstitutionHandler.Result>(deleteInstitution, 'post', url, true, false);
    }

    // post: api/institutions/getInstitutionCreditTypeMinutes?institutionId=${encodeURIComponent(institutionId)}
    public getInstituionCreditTypeMinutesTableData(institutionId: string): Promise<GetInstitutionCreditTypeMinuteTableDataHandler.Result> {
        let url = `api/institutions/getInstitutionCreditTypeMinutes`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionCreditTypeMinuteTableDataHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutions/saveInstitutionCreditTypeMinute
    public saveInstitutionCreditTypeMinute(saveInstitutionCreditTypeMinute: SaveInstitutionCreditTypeMinuteHandler.Request): Promise<SaveInstitutionCreditTypeMinuteHandler.Result> {
        let url = `api/institutions/saveInstitutionCreditTypeMinute`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveInstitutionCreditTypeMinuteHandler.Result>(saveInstitutionCreditTypeMinute, 'post', url, true, false);
    }
}
var service = new InstitutionsApiService();
export default service;
