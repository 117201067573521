import { Alert, Form, Input, message, Select, Skeleton, Space } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import ProgramApiService from '../../../api/ProgramApiService';
import ProgramDeactivationChangeRequestApiService from '../../../api/ProgramDeactivationChangeRequestApiService';
import * as GetProgramsByInstitutionIdHandler from '../../../handlerModels/GetProgramsByInstitutionIdHandler';
import * as SaveProgramDeactivationChangeRequestStep1Handler from '../../../handlerModels/SaveProgramDeactivationChangeRequestStep1Handler';
import * as SubmitProgramDeactivationChangeRequestStep1Handler from '../../../handlerModels/SubmitProgramDeactivationChangeRequestStep1Handler';
import * as GetProgramDeactivationChangeRequestHandler from '../../../handlerModels/GetProgramDeactivationChangeRequestHandler';
import * as ExistingProgramModificationSearchHandler from '../../../handlerModels/ExistingProgramModificationSearchHandler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import ProgramDeactivationChangeRequestDTO from '../../../models/ProgramDeactivationChangeRequestDTO';
import ProgramDTO from '../../../models/ProgramDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import TermInput from '../../TermInput';
import Dropdown from '../../inputs/Dropdown';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import ProgramModificationChangeRequestApiService from '../../../api/ProgramModificationChangeRequestApiService';
import modal from 'antd/lib/modal';

interface ProgramDeactivationChangeRequestStep1Props extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  institutionId: string | null;
  loading?: boolean;

  onSubmit?: (changeRequestId: string) => void;
  onSave?: (changeRequestId: string) => void;
  readonly?: boolean;
}

interface ProgramDeactivationChangeRequestStep1State extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  programDeactivation: ProgramDeactivationChangeRequestDTO;
  programs: ProgramDTO[];
}

class ProgramDeactivationChangeRequestStep1Form extends React.Component<ProgramDeactivationChangeRequestStep1Props, ProgramDeactivationChangeRequestStep1State> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(ProgramDeactivationChangeRequestDTO.programId, {
        required: true,
        name: ProgramDeactivationChangeRequestDTO.programId,
        label: 'What program are you deactivating?',
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramDeactivationChangeRequestDTO.rationale, {
        label: 'Why are you deactivating this program?',
        required: true,
        name: ProgramDeactivationChangeRequestDTO.rationale,
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(5000)
        ],
      })
      .set(ProgramDeactivationChangeRequestDTO.studentPlan, {
        label: 'What is your plans for any residual students?',
        required: true,
        name: ProgramDeactivationChangeRequestDTO.studentPlan,
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(5000)
        ],
      })
      .set(ProgramDeactivationChangeRequestDTO.deactivation, {
        label: 'When will this program be deactivated?',
        required: true,
        name: ProgramDeactivationChangeRequestDTO.deactivation,
        rules: [
          ValidationRuleUtil.required()
        ],
      });
  }

  constructor(props: ProgramDeactivationChangeRequestStep1Props) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      programDeactivation: ProgramDeactivationChangeRequestDTO.create(),
      programs: []
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: ProgramDeactivationChangeRequestStep1Props) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if ((!this.state.programs || this.state.programs.length == 0) && this.props.institutionId != Guid.Empty()) {
      loaders.push(this.getPrograms());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false }, () => this.resetForm());
    });
  }

  private loadChangeRequest = () => {
    const request = GetProgramDeactivationChangeRequestHandler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return ProgramDeactivationChangeRequestApiService.get(request)
      .then((results: GetProgramDeactivationChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            programDeactivation: results.changeRequest?.programDeactivationChangeRequest ?? ProgramDeactivationChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private getPrograms = () => {
    const request = GetProgramsByInstitutionIdHandler.Request.create({
      instituionId: this.props.institutionId
    });

    return ProgramApiService.getProgramsByInstitutionId(request)
      .then((results: GetProgramsByInstitutionIdHandler.Result) => {
        if (results) {

          this.setState({ programs: results.program ?? [], });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load programs.' });
      });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private checkExisting = (finailize?: boolean) => {
    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const request = ExistingProgramModificationSearchHandler.Request.create({
      programId: model.programId,
      installTerm: model.deactivation?.termId,
      installYear: model.deactivation?.year
    }) as ExistingProgramModificationSearchHandler.Request;

    ProgramModificationChangeRequestApiService.checkProgramModificationExisting(request)
      .then((result: ExistingProgramModificationSearchHandler.Result) => {
        if (result.existingProgramModification) {

          modal.confirm({
            title: 'Existing Program Modification',
            content: 'There is already a Program Modification scheduled for this program and term combination. Click confirm to continue with the deactivation.',
            onOk: finailize ? this.handleSubmit : this.handleSave,
            okText: 'Confirm',
            okButtonProps: { danger: true }
          });
        }
        else {
          finailize ? this.handleSubmit() : this.handleSave();
        }
      })
      .catch(() => {
        message.error('Incomplete form');
      });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const model = ProgramDeactivationChangeRequestDTO.create({ ...this._formRef.current?.getFieldsValue() });
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();

    const request = SaveProgramDeactivationChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      institutionId: this.props.institutionId,
      ProgramDeactivationChangeRequest: model
    }) as SaveProgramDeactivationChangeRequestStep1Handler.Request;

    ProgramDeactivationChangeRequestApiService.saveStep1(request)
      .then((result: SaveProgramDeactivationChangeRequestStep1Handler.Result) => {
        this.setState({ loading: false, submitted: true });

        if (result?.succeeded) {
          message.success('Saved');
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors,
            submitted: true
          });

          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = ProgramDeactivationChangeRequestDTO.create({ ...this._formRef.current?.getFieldsValue() });
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();

    const request = SubmitProgramDeactivationChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      institutionId: this.props.institutionId,
      ProgramDeactivationChangeRequest: model
    }) as SubmitProgramDeactivationChangeRequestStep1Handler.Request;

    ProgramDeactivationChangeRequestApiService.submitStep1(request)
      .then((result: SubmitProgramDeactivationChangeRequestStep1Handler.Result) => {
        this.setState({ loading: false, submitted: true });

        if (result?.succeeded) {
          if (this.props.onSubmit && result.changeRequest?.id) {
            this.props.onSubmit(result.changeRequest.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors,
            submitted: true
          });

          message.error('Submit Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Submit Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }
    const formItems = this.getFormItems();


    return (
      <Content>
        <Form ref={this._formRef}
          layout='vertical'
          onFinish={() => this.checkExisting(true)}
          initialValues={this.state.programDeactivation} >

          <Space direction='vertical'>
            <FormItem
              key={ProgramDeactivationChangeRequestDTO.programId}
              {...formItems.get(ProgramDeactivationChangeRequestDTO.programId)}
              {...ValidationUtil.getValidation(ProgramDeactivationChangeRequestDTO.programId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Dropdown
                showSearch
                disabled={this.props.readonly || this.state.saving || this.state.submitting}
                showArrow={!this.props.readonly}
                optionFilterProp="children"
                placeholder='Select Program'>
                {this.state.programs.map(x => {
                  return this.renderProgram(x)
                })}
              </Dropdown>
            </FormItem>

            <FormItem
              {...formItems.get(ProgramDeactivationChangeRequestDTO.rationale)}
              {...ValidationUtil.getValidation(ProgramDeactivationChangeRequestDTO.rationale, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea maxLength={5000} autoSize={{ minRows: 5 }} showCount={true} disabled={this.props.readonly || this.state.saving || this.state.submitting} />
            </FormItem>
            <FormItem
              {...formItems.get(ProgramDeactivationChangeRequestDTO.studentPlan)}
              {...ValidationUtil.getValidation(ProgramDeactivationChangeRequestDTO.studentPlan, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea maxLength={5000} autoSize={{ minRows: 5 }} showCount={true} disabled={this.props.readonly || this.state.saving || this.state.submitting} />
            </FormItem>

            <FormItem
              {...formItems.get(ProgramDeactivationChangeRequestDTO.deactivation)}
              {...ValidationUtil.getValidation(ProgramDeactivationChangeRequestDTO.deactivation, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <TermInput futureYears={true} disabled={this.props.readonly || this.state.saving || this.state.submitting} />
            </FormItem>

            {this.renderActions()}
          </Space>
        </Form>
      </Content>
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveAndContinueButton submitting={this.state.submitting || this.state.saving} />
          <SaveButton type='default' htmlType='button' onClick={() => this.checkExisting()} saving={this.state.submitting || this.state.saving} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  renderProgram(program: ProgramDTO) {
    if (program.id && program.programDetails) {
      return <Select.Option key={program.id} value={program.id}>{program.programDetails[0].display}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.fieldErrors) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default ProgramDeactivationChangeRequestStep1Form;