import { Alert, Input, message, notification, Skeleton, Space } from 'antd';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import * as React from 'react';
import UsersApiService from '../../api/UsersApiService';
import FeatureFlag from '../../consts/FeatureFlag';
import * as GetUserDetailsHandler from '../../handlerModels/GetUserDetailsHandler';
import * as SaveUserDetailsHandler from '../../handlerModels/SaveUserDetailsHandler';
import UserDTO from '../../models/UserDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import Guid from '../../utils/Guid';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import ResetButton from '../buttons/ResetButton';
import SaveButton from '../buttons/SaveButton';
import ValueLabel from '../general/ValueLabel';

interface UserDetailsFormState extends BaseFormState {
  user: UserDTO | null | undefined;
}

interface UserDetailsFormProps extends BaseFormProps {
  userId: string;
  onSave?: (id: string) => void;
}

class UserDetailsForm extends React.Component<UserDetailsFormProps, UserDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _formItems = new Map<string, FormItemProps>()
    .set(UserDTO.username, {
      required: true,
      name: UserDTO.username,
      label: 'Email',
      rules: [ValidationRuleUtil.required()]
    })
    .set(UserDTO.firstName, {
      required: true,
      name: UserDTO.firstName,
      label: 'First Name',
      rules: [ValidationRuleUtil.required()]
    })
    .set(UserDTO.lastName, {
      required: true,
      name: UserDTO.lastName,
      label: 'Last Name',
      rules: [ValidationRuleUtil.required()]
    })
    .set(UserDTO.phoneNumber, {
      required: false,
      name: UserDTO.phoneNumber,
      label: 'Phone Number',
      rules: []
    })
    .set(UserDTO.aaUserLogin, {
      name: UserDTO.aaUserLogin,
      label: 'AA User Login'
    });

  constructor(props: UserDetailsFormProps) {
    super(props);

    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  componentDidUpdate(prevProps: UserDetailsFormProps) {
    if (this.props.userId && this.props.userId != prevProps.userId) {
      this.loadUser();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false })
  }

  private loadUser() {
    this.setState({ loading: true });

    const loaders = [];
    if (this.props.userId != Guid.Empty()) {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadExisting = () => {
    UsersApiService.getUserDetails(this.props.userId)
      .then((results: GetUserDetailsHandler.Result) => {
        if (results) {
          this.setState({
            user: results.user
          });

          this._formRef.current?.resetFields();
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveUserDetailsHandler.Request.create({
      userId: this.props.userId,
      user: UserDTO.create((this._formRef ? (this._formRef.current as any).getFieldsValue() : null))
    });

    UsersApiService.saveUser(request)
      .then((result: SaveUserDetailsHandler.Result) => {
        this.setState({
          submitted: true
        });

        if (result?.succeeded) {
          message.success('Saved');
          this.setState({
            user: result?.user,
            altered: !result.succeeded
          });

          if (result.user?.id) {
            if (this.props.onSave) {
              this.props.onSave(result.user.id);
            }
          }

          this._formRef.current?.resetFields();
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          notification.error({ message: 'Uh oh!', description: result?.errors.join('\n'), });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        notification.error({ message: 'Uh oh!', description: 'User could not be saved.', });
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  render() {
    if (this.state.loading || this.state.user == null || this.state.user == undefined) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form ref={this._formRef}
          layout="vertical"
          onChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}
          initialValues={this.state.user}>

          <Form.Item
            {...ValidationUtil.getValidation(UserDTO.username, this.state.fieldErrors, this.state.submitted)}
            {...this._formItems.get(UserDTO.username)}>
            {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_USER])
              ? <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
              : <ValueLabel />}
          </Form.Item>

          <Form.Item
            {...ValidationUtil.getValidation(UserDTO.firstName, this.state.fieldErrors, this.state.submitted)}
            {...this._formItems.get(UserDTO.firstName)}>
            {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_USER])
              ? <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
              : <ValueLabel />}
          </Form.Item>

          <Form.Item
            {...ValidationUtil.getValidation(UserDTO.lastName, this.state.fieldErrors, this.state.submitted)}
            {...this._formItems.get(UserDTO.lastName)}>
            {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_USER])
              ? <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
              : <ValueLabel />}
          </Form.Item>

          <Form.Item
            {...ValidationUtil.getValidation(UserDTO.phoneNumber, this.state.fieldErrors, this.state.submitted)}
            {...this._formItems.get(UserDTO.phoneNumber)}>
            {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_USER])
              ? <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
              : <ValueLabel />}
          </Form.Item>

          <Form.Item
            {...this._formItems.get(UserDTO.aaUserLogin)}>
            {AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_AA_USER])
              ? <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
              : <ValueLabel />}
          </Form.Item>

          {this.renderActions()}
        </Form>
      </Space>
    );
  }

  renderActions() {
    if (this.props.isEditing && AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_USER])) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveButton disabled={this.state.loading} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
        </ Space>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default UserDetailsForm;
