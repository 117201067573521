// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveSecondaryCourseDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SecondaryCourseDTO from '../models/SecondaryCourseDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    secondaryCourseId: string | null | null;
    secondaryCourse: SecondaryCourseDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            secondaryCourseId: null,
            secondaryCourse: null,
        },
        initValues);
    },
    secondaryCourseId: 'secondaryCourseId',
    secondaryCourse: 'secondaryCourse',
}

interface Result extends ResultDTO {
    secondaryCourse: SecondaryCourseDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            secondaryCourse: null,
        },
        initValues);
    },
    secondaryCourse: 'secondaryCourse',
}

export { Request, Result };