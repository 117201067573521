// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ChangeRequestDTO from './ChangeRequestDTO';
import ProgramDTO from './ProgramDTO';
import YearTermDTO from './YearTermDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramDeactivationChangeRequestDTO extends EntitySelectorDTO { 
	id: string | null;
	changeRequestId: string | null;
	changeRequest: ChangeRequestDTO | null;
	programId: string | null | null;
	program: ProgramDTO | null;
	rationale: string | null;
	studentPlan: string | null;
	deactivation: YearTermDTO | null;
}

const ProgramDeactivationChangeRequestDTO = {
    className: 'ProgramDeactivationChangeRequestDTO',
    create: (initValues?: Partial<ProgramDeactivationChangeRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			changeRequestId: '00000000-0000-0000-0000-000000000000',
			changeRequest: null,
			programId: null,
			program: null,
			rationale: "",
			studentPlan: "",
			deactivation: null,
        },
        initValues);
    },
	id: 'id',
	changeRequestId: 'changeRequestId',
	changeRequest: 'changeRequest',
	programId: 'programId',
	program: 'program',
	rationale: 'rationale',
	studentPlan: 'studentPlan',
	deactivation: 'deactivation',
};

export default ProgramDeactivationChangeRequestDTO;