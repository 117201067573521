import { Card, Skeleton, Space } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SelfStudySectionComponentResponseApiService from '../../api/SelfStudySectionComponentResponseApiService';
import SelfStudyTemplateApiService from '../../api/SelfStudyTemplateApiService';
import SelfStudyResponseType from '../../consts/SelfStudyResponseType';
import * as GetSelfStudySectionComponentResponseByTypeHandler from '../../handlerModels/GetSelfStudySectionComponentResponseByTypeHandler';
import * as GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler from '../../handlerModels/GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler';
import SelfStudyResponseConsultantReviewQuestionDTO from '../../models/SelfStudyResponseConsultantReviewQuestionDTO';
import SelfStudySectionComponentResponseDTO from '../../models/SelfStudySectionComponentResponseDTO';
import SelfStudySectionComponentTemplateDTO from '../../models/SelfStudySectionComponentTemplateDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import Guid from '../../utils/Guid';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import ReadableTextBox from '../inputs/ReadableTextBox';
import YesNoInput from '../inputs/YesNoInput';
import { Text, View } from '@react-pdf/renderer';
import { Html } from 'react-pdf-html';

interface SelfStudyConsultantReviewQuestionProps extends BaseFormProps {
  academicYear?: number;
  selfStudyResponseId?: string;
  selfStudyId?: string;
  sectionIndex?: number;
  readonly?: boolean;
  disable?: boolean;
  componentIndex: number;
  previewComponent?: boolean;
  consultantReviewQuestionCount?: number;
  pdfView?: boolean;
}

const SelfStudyConsultantReviewQuestion = React.forwardRef((props: SelfStudyConsultantReviewQuestionProps) => {
  const getConsultantReview = (index: number) => {
    const required = !props.disable;
    return new Map<string, FormItemProps>()
      .set(SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer, {
        required: required,
        name: [index, SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer],
        rules: [ValidationRuleUtil.required()],
      })
      .set(SelfStudyResponseConsultantReviewQuestionDTO.comment, {
        name: [index, SelfStudyResponseConsultantReviewQuestionDTO.comment],
        label: 'Comment',
      })
      .set(SelfStudySectionComponentResponseDTO.responseText, {
        label: 'Comments',
        required: true,
        name: [index, SelfStudySectionComponentResponseDTO.responseText],
        rules: [ValidationRuleUtil.required()],
      });
  };

  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(props.fieldErrors);
  const [submitted, setSubmitted] = useState(props.submitted);
  const [selfStudySectionComponentTemplate, setSelfStudySectionComponentTemplate] = useState(
    {} as SelfStudySectionComponentTemplateDTO
  );
  const [doeSelfStudySectionComponentResponse, setDOESelfStudySectionComponentResponse] = useState(
    {} as SelfStudySectionComponentResponseDTO
  );
  useEffect(() => {
    fetchData();
  }, [props.academicYear, props.selfStudyResponseId]);

  useEffect(() => {
    setFieldErrors(props.fieldErrors);
    setSubmitted(props.submitted);
  }, [props.submitted, props.fieldErrors]);

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (props.academicYear != 0 && props.selfStudyResponseId != Guid.Empty() && !props.previewComponent) {
      loaders.push(loadSelfStudySectionComponentTemplate());
      if (props.selfStudyId && props.selfStudyId != Guid.Empty()) {
        loaders.push(loadDOESelfStudySectionResponse());
      }

      Promise.all(loaders).then(() => {
        setLoading(false);
      });
    } else if (props.previewComponent) {
      setLoading(false);
    }
  };

  const loadDOESelfStudySectionResponse = () => {
    const request = GetSelfStudySectionComponentResponseByTypeHandler.Request.create({
      selfStudyId: props.selfStudyId ?? Guid.Empty(),
      selfStudyResponseTypeId: SelfStudyResponseType.DOE,
      sectionIndex: props.sectionIndex,
      componentIndex: props.componentIndex,
    });

    return SelfStudySectionComponentResponseApiService.getSelfStudySectionComponentResponseByType(request).then(
      (results: GetSelfStudySectionComponentResponseByTypeHandler.Result) => {
        if (results.selfStudySectionComponentResponse) {
          setDOESelfStudySectionComponentResponse(
            results.selfStudySectionComponentResponse ?? SelfStudySectionComponentResponseDTO.create()
          );
        }
      }
    );
  };

  const loadSelfStudySectionComponentTemplate = () => {
    const request = GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Request.create({
      academicYear: props.academicYear,
      selfStudySectionOrder: props.sectionIndex,
      selfStudySectionComponentOrder: props.componentIndex,
    });
    return SelfStudyTemplateApiService.getSelfStudySectionComponentTemplateBySectionOrder(request).then(
      (results: GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Result) => {
        if (results.selfStudySectionComponentTemplate) {
          setSelfStudySectionComponentTemplate(
            results.selfStudySectionComponentTemplate ?? SelfStudySectionComponentTemplateDTO.create()
          );
        }
      }
    );
  };

  const reviewTypeForm = () => {
    let count = -1;
    const consultantReview = selfStudySectionComponentTemplate?.selfStudyResponseTemplateConsultantReviewQuestions?.map(
      (x) => {
        const selfStudyComponent = doeSelfStudySectionComponentResponse;
        count++;
        return (
          <Space key={count} direction="vertical">
            <Card type="inner" className="sub-inner-card">
              <FormItem
                label={x.question}
                initialValue={
                  (selfStudyComponent?.selfStudyResponseConsultantReviewQuestionDTOs ?? [])[count]?.consultantAnswer ??
                  null
                }
                key={x.id}
                {...getConsultantReview(count).get(SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer)}
                {...ValidationUtil.getValidation(
                  SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer +
                    props.sectionIndex +
                    props.componentIndex +
                    count,
                  fieldErrors,
                  submitted
                )}
              >
                <YesNoInput key={count} disabled={props.readonly || props.disable} />
              </FormItem>

              <FormItem
                initialValue={(selfStudyComponent?.selfStudyResponseConsultantReviewQuestionDTOs ?? [])[count]?.comment}
                key={x.id}
                {...getConsultantReview(count).get(SelfStudyResponseConsultantReviewQuestionDTO.comment)}
                {...ValidationUtil.getValidation(
                  SelfStudyResponseConsultantReviewQuestionDTO.comment,
                  fieldErrors,
                  submitted
                )}
              >
                <ReadableTextBox disabled={props.disable} readonly={props.readonly}></ReadableTextBox>
              </FormItem>
            </Card>
          </Space>
        );
      }
    );

    return consultantReview;
  };

  const previewQuestions = () => {
    const questions: any = [];
    for (let i = 0; i < (props.consultantReviewQuestionCount ?? 0); i++) {
      questions.push(
        <Space key={i} direction="vertical">
          <Card title={'Question ' + (i + 1)} type="inner">
            <FormItem
              {...getConsultantReview(i).get(SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer)}
              {...ValidationUtil.getValidation(
                SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer,
                fieldErrors,
                submitted
              )}
            >
              <YesNoInput key={i} disabled={props.readonly || props.disable}></YesNoInput>
            </FormItem>

            <FormItem
              {...getConsultantReview(i).get(SelfStudyResponseConsultantReviewQuestionDTO.comment)}
              {...ValidationUtil.getValidation(
                SelfStudyResponseConsultantReviewQuestionDTO.comment,
                fieldErrors,
                submitted
              )}
            >
              <ReadableTextBox disabled={props.disable} readonly={props.readonly}></ReadableTextBox>
            </FormItem>
          </Card>
        </Space>
      );
    }

    return questions;
  };

  const pdfQuestions = () => {
    const questions: any = [];
    const selfStudyComponent = doeSelfStudySectionComponentResponse;
    selfStudyComponent.selfStudyResponseConsultantReviewQuestionDTOs;
    for (let i = 0; i < (selfStudyComponent.selfStudyResponseConsultantReviewQuestionDTOs?.length ?? 0); i++) {
      questions.push(
        <View style={{ border: '1pt solid #f0f0f0', margin: 10 }}>
          <Text style={{ padding: 5, fontSize: 12, border: '1pt solid #f0f0f0', backgroundColor: '#f0f0f0' }}>{'Question ' + (i + 1)}</Text>
          <View style={{ margin: 10 }}>
            <Text style={{ paddingBottom: '10pt', fontSize: 8 }}>
              {(selfStudyComponent?.selfStudyResponseConsultantReviewQuestionDTOs ?? [])[i]?.consultantAnswer
                ? 'Yes'
                : 'No'}
            </Text>
            <Text style={{ paddingBottom: '10pt', fontSize: 10 }}> Comments</Text>
            <Html style={{ paddingBottom: '10pt', fontSize: 8 }}>
              {(selfStudyComponent?.selfStudyResponseConsultantReviewQuestionDTOs ?? [])[i]?.comment ?? ''}
            </Html>
          </View>
        </View>
      );
    }

    return questions;
  };

  if (props.pdfView) {
    return pdfQuestions();
  } else if (loading) {
    return <Skeleton active />;
  } else {
    if (props.previewComponent) {
      return previewQuestions();
    } else {
      return (
        <Space size="small" direction="vertical">
          {reviewTypeForm()}
        </Space>
      );
    }
  }
});

SelfStudyConsultantReviewQuestion.displayName = 'SelfStudyConsultantReviewQuestion';

export default SelfStudyConsultantReviewQuestion;
