import { Card, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import UserSettingsApiService from '../../api/UserSettingsApiService';
import AuthorizedContent from '../../components/AuthorizedContent';
import ResetPasswordForm from '../../components/forms/ResetPasswordForm';
import UserDetailsForm from '../../components/forms/UserDetailsForm';
import EditCancelButton from '../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import * as GetUserDetailsHandler from '../../handlerModels/GetUserDetailsHandler';
import UserDTO from '../../models/UserDTO';
import UserSecurity from '../../models/UserSecurity';
import HeaderPortal from '../../portals/HeaderPortal';
import BaseFormState from '../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import Guid from '../../utils/Guid';
import HistoryUtil from '../../utils/HistoryUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';

interface UserDetailsState extends BaseFormState {
  user: UserDTO;
}

interface UserDetailsProps {
  user: UserSecurity | null;
}

class UserSettingsPage extends React.Component<UserDetailsProps & RouteComponentProps<RouteObject>, UserDetailsState> {
  private static readonly USER_DETAILS = 'USER_DETAILS';
  private static readonly RESET_PASSWORD = 'RESET_PASSWORD';

  private readonly _userDetailsRef = React.createRef<UserDetailsForm>();
  private readonly _resetPasswordRef = React.createRef<ResetPasswordForm>();

  constructor(props: UserDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      user: UserDTO.create(),
      editings: new Map<string, boolean>()
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  private loadUser = () => {
    this.setState({ loading: true });
    UserSettingsApiService.getUserDetails()
      .then((results: GetUserDetailsHandler.Result) => {
        this.setState({
          user: results.user ?? UserDTO.create(),
        });
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private toggleIsEditing = (key: string, value?: boolean) => {
    if (this.state.editings) {
      const editings = this.state.editings;

      if (value !== undefined) {
        editings.set(key, value);
      }
      else {
        editings.set(key, !editings.get(key) ?? true);
      }

      if (!editings.get(key)) {
        switch (key) {
          case UserSettingsPage.USER_DETAILS:
            this._userDetailsRef.current?.resetForm();
            break;
          case UserSettingsPage.RESET_PASSWORD:
            this._resetPasswordRef.current?.reset();
            break;
        }
      }

      this.setState({ editings: editings })
    }
  }

  private isEditingCheck = () => {
    if (this.state.editings) {
      return Array.from(this.state.editings.values()).includes(true);
    }

    return false;
  }

  private userDetailsSaved = () => {
    this.toggleIsEditing(UserSettingsPage.USER_DETAILS, false);

    this.loadUser();
  }

  private resetPasswordSaved = () => {
    this.toggleIsEditing(UserSettingsPage.RESET_PASSWORD, false);
  }

  private goHome = () => {
    HistoryUtil.push(Routes.generate(Routes.HOME));
  }

  render() {
    return (
      <Content >
        <HeaderPortal title='User Settings' onBack={this.goHome} breadcrumbs={Breadcrumbs.userSettings()} />
        <PageStayPrompt when={this.isEditingCheck} />
        <Space direction="vertical" size="large">
          {this.renderDetailsCard()}
          {this.renderPasswordCard()}
        </Space>
      </Content>
    );
  }

  renderDetailsCard() {
    if (this.state.editings) {
      const editButton = this.props.user?.userId == Guid.Empty() ?
        null :
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_USER]}>
          <EditCancelButton onClick={() => this.toggleIsEditing(UserSettingsPage.USER_DETAILS)} isEditing={this.state.editings.get(UserSettingsPage.USER_DETAILS)} />
        </AuthorizedContent>;

      return (
        <Card title="User Details" extra={editButton}>
          <UserDetailsForm
            ref={this._userDetailsRef}
            userId={this.props.user?.userId ?? Guid.Empty()}
            isEditing={this.state.editings.get(UserSettingsPage.USER_DETAILS)}
            onSave={this.userDetailsSaved} />
        </Card>
      );
    }
  }

  renderPasswordCard() {
    return (
      <Card title="Change Password" >
        <ResetPasswordForm
          userId={this.props.user?.userId ?? Guid.Empty()}
          isEditing={true}
          onSave={this.resetPasswordSaved} />
      </Card>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    user: state.UserSession?.Value ?? null,
  };
}

export default withRouter(connect(mapStateToProps)(UserSettingsPage));
