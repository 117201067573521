// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import InstitutionLocationDataTableDTO from '../models/InstitutionLocationDataTableDTO';
import * as GetInstitutionLocationDetailsHandler from '../handlerModels/GetInstitutionLocationDetailsHandler';
import * as GetInstitutionLocationsHandler from '../handlerModels/GetInstitutionLocationsHandler';
import * as SaveInstitutionLocationHandler from '../handlerModels/SaveInstitutionLocationHandler';
import * as RemoveInstitutionLocationHandler from '../handlerModels/RemoveInstitutionLocationHandler';

export class InstitutionLocationApiService extends BaseApi {

    // post: api/institutionLocations?institutionId=${encodeURIComponent(institutionId)}
    public getInstitutionLocationsDataTable(tableRequest: TableRequestDTO, institutionId: string): Promise<TableResponseDTO<InstitutionLocationDataTableDTO>> {
        let url = `api/institutionLocations`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstitutionLocationDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutionLocations/${encodeURIComponent(id)}
    public getInstitutionLocationDetails(id: string): Promise<GetInstitutionLocationDetailsHandler.Result> {
        let url = `api/institutionLocations/${encodeURIComponent(id)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionLocationDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionLocations/get?institutionId=${encodeURIComponent(institutionId)}
    public getInstitutionLocations(institutionId: string): Promise<GetInstitutionLocationsHandler.Result> {
        let url = `api/institutionLocations/get`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionLocationsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionLocations/save
    public saveInstitutionLocation(saveInstitutionLocation: SaveInstitutionLocationHandler.Request): Promise<SaveInstitutionLocationHandler.Result> {
        let url = `api/institutionLocations/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveInstitutionLocationHandler.Result>(saveInstitutionLocation, 'post', url, true, false);
    }

    // post: api/institutionLocations/removeCourseFromInstitutionCourseBlockDetail
    public removeInstitutionLocation(removeInstitutionLocation: RemoveInstitutionLocationHandler.Request): Promise<RemoveInstitutionLocationHandler.Result> {
        let url = `api/institutionLocations/removeCourseFromInstitutionCourseBlockDetail`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveInstitutionLocationHandler.Result>(removeInstitutionLocation, 'post', url, true, false);
    }
}
var service = new InstitutionLocationApiService();
export default service;
