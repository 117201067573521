// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveTermDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import TermDTO from '../models/TermDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    termId: string | null | null;
    term: TermDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            termId: null,
            term: null,
        },
        initValues);
    },
    termId: 'termId',
    term: 'term',
}

interface Result extends ResultDTO {
    term: TermDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            term: null,
        },
        initValues);
    },
    term: 'term',
}

export { Request, Result };