import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import SecondaryProgramCourseDetailsForm from '../forms/secondary/SecondaryProgramCourseDetailsForm';


interface SecondaryProgramCourseDetailsModalProps extends ModalProps {
  secondaryProgramCourseId: string;
  districtId?: string;
  secondaryProgramId?: string;
  academicYear: number;
  onSave?: () => void;
}

function SecondaryProgramCourseDetailsModal(props: SecondaryProgramCourseDetailsModalProps) {
  const _secondaryProgramCourseDetailsRef = React.createRef<SecondaryProgramCourseDetailsForm>();

  const isNew = props.secondaryProgramCourseId == Guid.Empty();

  const submitSaveSecondaryProgramCourse = () => {
    _secondaryProgramCourseDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New Program Course' : 'Edit Program Course'}
      okText='Save'
      onOk={submitSaveSecondaryProgramCourse}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <SecondaryProgramCourseDetailsForm
        ref={_secondaryProgramCourseDetailsRef}
        secondaryProgramCourseId={props.secondaryProgramCourseId ?? Guid.Empty}
        districtId={props.districtId}
        secondaryProgramId={props.secondaryProgramId}
        isEditing={true}
        onSave={props.onSave}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default SecondaryProgramCourseDetailsModal;