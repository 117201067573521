import { Alert, Input, message, notification, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import DisciplinesApiService from '../../../api/DisciplinesApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetDisciplineDetailsHandler from '../../../handlerModels/GetDisciplineDetailsHandler';
import * as SaveDisciplineDetailsHandler from '../../../handlerModels/SaveDisciplineDetailsHandler';
import DisciplineDTO from '../../../models/DisciplineDTO';
import StatusTypeDTO from '../../../models/StatusTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface DisciplineDetailsFormState extends BaseFormState {
  discipline: DisciplineDTO;
  statusTypes: StatusTypeDTO[];
}

interface DisciplineDetailsFormProps extends BaseFormProps {
  disciplineId: string;
  onSave?: (termId: string) => void;
}

class DisciplineDetailsForm extends React.Component<DisciplineDetailsFormProps, DisciplineDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(DisciplineDTO.code, {
      required: true,
      name: DisciplineDTO.code,
      label: 'Code',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(3),
        ValidationRuleUtil.onlyAlphaCharacters(),
      ],
    })
    .set(DisciplineDTO.name, {
      required: true,
      name: DisciplineDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ],
    })
    .set(DisciplineDTO.statusTypeId, {
      required: true,
      name: DisciplineDTO.statusTypeId,
      label: 'Status',
      rules: [
        ValidationRuleUtil.required()
      ],
    });

  constructor(props: DisciplineDetailsFormProps) {
    super(props);

    this.state = {
      discipline: DisciplineDTO.create({
        statusTypeId: ''
      }),
      statusTypes: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadDiscipline();
  }

  componentDidUpdate(prevProps: DisciplineDetailsFormProps) {
    if (this.props.disciplineId && this.props.disciplineId != prevProps.disciplineId) {
      this.loadDiscipline();
    }
  }

  public resetForm = () => {
    this.setState({ altered: false });
    this._formRef.current?.resetFields();
  }

  private loadDiscipline() {
    const loaders = [];
    if (this.state.statusTypes.length == 0) {
      loaders.push(this.loadStatusTypes());
    }

    if (this.props.disciplineId != Guid.Empty()) {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadStatusTypes = () => {
    return LookupsUtil.getAll<StatusTypeDTO>(StatusTypeDTO.className)
      .then((results: StatusTypeDTO[]) => {
        if (results) {
          this.setState({ statusTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadExisting = () => {
    return DisciplinesApiService.getDisciplineDetails(this.props.disciplineId)
      .then((results: GetDisciplineDetailsHandler.Result) => {
        if (results) {
          this.setState({ discipline: results.discipline ?? DisciplineDTO.create() });

          this.resetForm();
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveDisciplineDetailsHandler.Request.create({
      disciplineId: this.props.disciplineId,
      discipline: DisciplineDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    DisciplinesApiService.saveDisciplineDetails(request)
      .then((result: SaveDisciplineDetailsHandler.Result) => {
        this.setState({ discipline: result?.discipline ?? DisciplineDTO.create(), submitted: true });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.discipline?.id) {
            this.props.onSave(result.discipline.id);
            this._formRef.current?.resetFields();
          }

          LookupsUtil.invalidateCache(DisciplineDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          notification.error({ message: 'Uh oh!', description: result?.errors.join('\n'), });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        notification.error({ message: 'Uh oh!', description: 'Discipline could not be saved.', });
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.discipline}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <FormItem
            {...this._formItems.get(DisciplineDTO.code)}
            {...ValidationUtil.getValidation(DisciplineDTO.code, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(DisciplineDTO.name)}
            {...ValidationUtil.getValidation(DisciplineDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(DisciplineDTO.statusTypeId)}
            {...ValidationUtil.getValidation(DisciplineDTO.statusTypeId, this.state.fieldErrors, this.state.submitted)}>
            <Select disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
              {this.state.statusTypes.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option> })}
            </Select>
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_DISCIPLINE]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!FormUtil.canSubmit(this._formRef, this._formItems)} saving={this.state.submitting} />
            <ResetButton disabled={!FormUtil.hasChanged(this._formRef, this._formItems)} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default DisciplineDetailsForm;
