import * as React from 'react';
import TransferMajorLactsCategoryApiService from '../../../api/TransferMajorLactsCategoryApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import TransferMajorLactsCategoryDTO from '../../../models/TransferMajorLactsCategoryDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import FileDownload from '../../../utils/FileDownload';
import DateUtil from '../../../utils/DateTimeUtil';

interface TransferMajorLactsCategoryDataTableProps extends BaseFormProps {
}

interface TransferMajorLactsCategoryDataTableState extends BaseDataTableState<TransferMajorLactsCategoryDTO>, BaseFormState {
}

class TransferMajorLactsCategoryDataTable extends React.Component<TransferMajorLactsCategoryDataTableProps, TransferMajorLactsCategoryDataTableState> {
  private _dataTable: DataTable<TransferMajorLactsCategoryDTO> | undefined;

  constructor(props: TransferMajorLactsCategoryDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Name',
        dataIndex: TransferMajorLactsCategoryDTO.name,
        sorter: true,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];
  };

  private openTransferMajorLactsCategory = (record: TransferMajorLactsCategoryDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.TRANSFER_MAJOR_LACTS_CATEGORY_DETAILS, { id: record.id }, {}));
    }
  }

  private addTransferMajorLactsCategory = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_TRANSFER_MAJOR_LACTS_CATEGORY));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return TransferMajorLactsCategoryApiService.getTransferMajorLactsCategoryDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Transfer_Major_Lacts_Category_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_TRANSFER_MAJOR_LACTS_CATEGORY])) {
      actionButtons.push(DataTableButtonUtil.Default('New Transfer Major Lacts Category', () => this.addTransferMajorLactsCategory()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openTransferMajorLactsCategory}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => TransferMajorLactsCategoryApiService.getTransferMajorLactsCategorysDataTable(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'transferMajorLactsCategory_list' }} />
      </>
    );
  }
}

export default TransferMajorLactsCategoryDataTable;
