import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, FormItemProps, Input, message, Modal, Row, Select } from 'antd';
import * as React from 'react';
import ProgramApiService from '../../../api/ProgramApiService';
import { ColumnWidths } from '../../../config/ColumnWidths';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as SaveProgramAdvisoryBoardHandler from '../../../handlerModels/SaveProgramAdvisoryBoardHandler';
import GenderDTO from '../../../models/GenderDTO';
import ProgramAdvisoryBoardDataTableDTO from '../../../models/ProgramAdvisoryBoardDataTableDTO';
import RacialEthnicGroupDTO from '../../../models/RacialEthnicGroupDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TermDTO from '../../../models/TermDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import SaveButton from '../../buttons/SaveButton';
import ValueLabel from '../../general/ValueLabel';
import YesNoInput from '../../inputs/YesNoInput';
import DataTable, { DataTableColumnProps } from '../core/DataTable';

interface ProgramAdvisoryBoardDataTableProps extends BaseFormProps {
  programDetailId: string;
}

interface ProgramAdvisoryBoardDataTableState extends BaseDataTableState<ProgramAdvisoryBoardDataTableDTO>, BaseFormState {
  member: ProgramAdvisoryBoardDataTableDTO | null;
  terms: TermDTO[];
  genders: GenderDTO[];
  racialEthnicGroups: RacialEthnicGroupDTO[];
}

class ProgramAdvisoryBoardDataTable extends React.Component<ProgramAdvisoryBoardDataTableProps, ProgramAdvisoryBoardDataTableState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _dataTable: DataTable<ProgramAdvisoryBoardDataTableDTO> | undefined;

  constructor(props: ProgramAdvisoryBoardDataTableProps) {
    super(props);
    this.state = {
      member: null,
      terms: [],
      genders: [],
      racialEthnicGroups: []
    };
  }

  private getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Name',
        dataIndex: ProgramAdvisoryBoardDataTableDTO.name,
      },
      {
        title: 'Employer',
        dataIndex: ProgramAdvisoryBoardDataTableDTO.employer,
      },
      {
        title: 'Position',
        dataIndex: ProgramAdvisoryBoardDataTableDTO.position,
      },
      {
        title: 'Joined',
        dataIndex: ProgramAdvisoryBoardDataTableDTO.joinTerm,
        render: (value, record: ProgramAdvisoryBoardDataTableDTO) => {
          return record.joinTerm?.display
        }
      },
    ] as DataTableColumnProps<any>[];

    columns.push({
      title: 'Edit',
      dataIndex: ProgramAdvisoryBoardDataTableDTO.id,
      render: (data, row) => {
        const editable = AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM]);
        return <Button size='small' onClick={() => this.openEditModal(row)} type='link' icon={editable ? <EditOutlined /> : <EyeOutlined />} />;
      }
    });

    return columns;
  };

  private openEditModal = (record: ProgramAdvisoryBoardDataTableDTO) => {
    this.setState({
      member: record,
    })
  }

  private closeEditModal = (refresh: boolean) => {
    this.setState({
      member: null,
    });

    if (refresh) {
      this._dataTable?.refresh();
    }
  }

  renderGender(gender: GenderDTO) {
    if (gender.id) {
      return <Select.Option key={gender.id ?? Guid.Empty()} value={gender.id ?? Guid.Empty()}>{gender.name}</Select.Option>
    }
  }

  renderEthnicGroup(ethnicGroup: RacialEthnicGroupDTO) {
    if (ethnicGroup.id) {
      return <Select.Option key={ethnicGroup.id ?? Guid.Empty()} value={ethnicGroup.id ?? Guid.Empty()}>{ethnicGroup.name}</Select.Option>
    }
  }

  private getEditFormItems() {
    const fields = new Map<string, FormItemProps>()
      .set(ProgramAdvisoryBoardDataTableDTO.firstName, {
        name: ProgramAdvisoryBoardDataTableDTO.firstName,
        label: 'First Name',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramAdvisoryBoardDataTableDTO.lastName, {
        name: ProgramAdvisoryBoardDataTableDTO.lastName,
        label: 'Last Name',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramAdvisoryBoardDataTableDTO.employer, {
        name: ProgramAdvisoryBoardDataTableDTO.employer,
        label: 'Employer',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramAdvisoryBoardDataTableDTO.position, {
        name: ProgramAdvisoryBoardDataTableDTO.position,
        label: 'Postition',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramAdvisoryBoardDataTableDTO.genderId, {
        name: ProgramAdvisoryBoardDataTableDTO.genderId,
        label: 'Gender',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramAdvisoryBoardDataTableDTO.ethnicGroupId, {
        name: ProgramAdvisoryBoardDataTableDTO.ethnicGroupId,
        label: 'Ethnic Group',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramAdvisoryBoardDataTableDTO.disability, {
        name: ProgramAdvisoryBoardDataTableDTO.disability,
        label: 'Has the advisory board member self-identified as having a disability?',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramAdvisoryBoardDataTableDTO.organizedLabor, {
        name: ProgramAdvisoryBoardDataTableDTO.organizedLabor,
        label: 'Is this member a part of organized labor?',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramAdvisoryBoardDataTableDTO.joinedTermId, {
        name: ProgramAdvisoryBoardDataTableDTO.joinedTermId,
        label: 'Joined',
      })
    return fields;
  }

  private saveMember = () => {
    this.setState({ submitting: true });
    const request = SaveProgramAdvisoryBoardHandler.Request.create({
      member: {
        id: this.state.member?.id,
        ...this._formRef.current?.getFieldsValue()
      }
    });

    ProgramApiService.saveAdvisoryBoard(request)
      .then((result: SaveProgramAdvisoryBoardHandler.Result) => {
        if (result.succeeded) {
          message.success('Member Saved');
          this.closeEditModal(true);
        }
        else {
          message.error('Error Saving');
          this.setState({
            fieldErrors: result.fieldErrors
          })
        }
      })
      .catch(() => {
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ submitting: false });
      })
  }

  private renderAdvisorModal() {
    if (this.state.member) {
      const editable = AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM]);
      return <Modal
        width={750}
        title='Advisory Board Member'
        visible={this.state.member != null}
        footer={editable ? <SaveButton saving={this.state.submitting} onClick={this.saveMember} /> : null}
        onCancel={() => this.closeEditModal(false)}>
        <Form
          layout='vertical'
          initialValues={this.state.member}
          requiredMark={editable}
          ref={this._formRef}
          onFinish={this.saveMember}>
          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.HALF}>
              <Form.Item
                {...this.getEditFormItems().get(ProgramAdvisoryBoardDataTableDTO.firstName)}
                {...ValidationUtil.getValidation(ProgramAdvisoryBoardDataTableDTO.firstName.toLowerCase(), this.state.fieldErrors, this.state.submitted)}>
                {editable ? <Input /> : <ValueLabel />}
              </Form.Item>
            </Col>
            <Col {...ColumnWidths.HALF}>
              <Form.Item
                {...this.getEditFormItems().get(ProgramAdvisoryBoardDataTableDTO.lastName)}
                {...ValidationUtil.getValidation(ProgramAdvisoryBoardDataTableDTO.lastName.toLowerCase(), this.state.fieldErrors, this.state.submitted)}>
                {editable ? <Input /> : <ValueLabel />}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.HALF}>
              <Form.Item
                {...this.getEditFormItems().get(ProgramAdvisoryBoardDataTableDTO.employer)}
                {...ValidationUtil.getValidation(ProgramAdvisoryBoardDataTableDTO.employer.toLowerCase(), this.state.fieldErrors, this.state.submitted)}>
                {editable ? <Input /> : <ValueLabel />}
              </Form.Item>
            </Col>
            <Col {...ColumnWidths.HALF}>
              <Form.Item
                {...this.getEditFormItems().get(ProgramAdvisoryBoardDataTableDTO.position)}
                {...ValidationUtil.getValidation(ProgramAdvisoryBoardDataTableDTO.position.toLowerCase(), this.state.fieldErrors, this.state.submitted)}>
                {editable ? <Input /> : <ValueLabel />}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.HALF}>
              <Form.Item
                {...this.getEditFormItems().get(ProgramAdvisoryBoardDataTableDTO.genderId)}
                {...ValidationUtil.getValidation(ProgramAdvisoryBoardDataTableDTO.genderId.toLowerCase(), this.state.fieldErrors, this.state.submitted)}>
                <Select showArrow={true} dropdownMatchSelectWidth={false}>{this.state.genders.map(x => this.renderGender(x))}</Select>
              </Form.Item>
            </Col>
            <Col {...ColumnWidths.HALF}>
              <Form.Item
                {...this.getEditFormItems().get(ProgramAdvisoryBoardDataTableDTO.ethnicGroupId)}
                {...ValidationUtil.getValidation(ProgramAdvisoryBoardDataTableDTO.ethnicGroupId.toLowerCase(), this.state.fieldErrors, this.state.submitted)}>
                <Select showArrow={true} dropdownMatchSelectWidth={false}>{this.state.racialEthnicGroups.map(x => this.renderEthnicGroup(x))}</Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.FULL}>
              <Form.Item
                {...this.getEditFormItems().get(ProgramAdvisoryBoardDataTableDTO.organizedLabor)}
                {...ValidationUtil.getValidation(ProgramAdvisoryBoardDataTableDTO.organizedLabor.toLowerCase(), this.state.fieldErrors, this.state.submitted)}>
                <YesNoInput readonly={!editable} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.FULL}>
              <Form.Item
                {...this.getEditFormItems().get(ProgramAdvisoryBoardDataTableDTO.disability)}
                {...ValidationUtil.getValidation(ProgramAdvisoryBoardDataTableDTO.disability.toLowerCase(), this.state.fieldErrors, this.state.submitted)}>
                <YesNoInput readonly={!editable} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.FULL}>
              <Form.Item
                initialValue={this.state.member.joinTerm?.display}
                {...this.getEditFormItems().get(ProgramAdvisoryBoardDataTableDTO.joinedTermId)}>
                <ValueLabel text={this.state.member.joinTerm?.display} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal >
    }
  }

  render() {
    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={false}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => ProgramApiService.getAdvisoryBoard(requestState, this.props.programDetailId)
          }} />
        {this.renderAdvisorModal()}
      </>
    );
  }
}

export default ProgramAdvisoryBoardDataTable;
