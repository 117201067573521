// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ChangeRequestDTO from '../models/ChangeRequestDTO';
import * as GetChangeRequestDetailsHandler from '../handlerModels/GetChangeRequestDetailsHandler';
import * as GetBaseChangeRequestDetailsHandler from '../handlerModels/GetBaseChangeRequestDetailsHandler';
import * as GetChangeRequestDraftHandler from '../handlerModels/GetChangeRequestDraftHandler';
import * as GetChangeRequestForWorkItemApprovalHandler from '../handlerModels/GetChangeRequestForWorkItemApprovalHandler';
import * as GetChangeRequestApprovalOnDetailsHandler from '../handlerModels/GetChangeRequestApprovalOnDetailsHandler';
import * as SaveChangeRequestDetailsHandler from '../handlerModels/SaveChangeRequestDetailsHandler';
import * as SubmitChangeRequestHandler from '../handlerModels/SubmitChangeRequestHandler';
import * as SaveChangeRequestAdditionalDetailsHandler from '../handlerModels/SaveChangeRequestAdditionalDetailsHandler';
import * as GetChangeRequestAdditionalDetailsHandler from '../handlerModels/GetChangeRequestAdditionalDetailsHandler';
import * as CancelChangeRequestHandler from '../handlerModels/CancelChangeRequestHandler';
import * as AddChangeRequestCommentHandler from '../handlerModels/AddChangeRequestCommentHandler';
import * as ApproveChangeRequestHandler from '../handlerModels/ApproveChangeRequestHandler';
import * as DenyChangeRequestHandler from '../handlerModels/DenyChangeRequestHandler';
import * as GetChangeRequestCommentsHandler from '../handlerModels/GetChangeRequestCommentsHandler';
import * as RedirectApprovalHandler from '../handlerModels/RedirectApprovalHandler';
import * as ManualApprovalHandler from '../handlerModels/ManualApprovalHandler';
import * as ManualRejectHandler from '../handlerModels/ManualRejectHandler';
import * as ResendEmailsHandler from '../handlerModels/ResendEmailsHandler';

export class ChangeRequestApiService extends BaseApi {

    // post: api/changeRequests/list/${encodeURIComponent(institutionId)}/${encodeURIComponent(userId)}?programId=${encodeURIComponent(programId)}&courseId=${encodeURIComponent(courseId)}
    public getChangeRequestTableData(tableRequest: TableRequestDTO, institutionId: string, userId: string, programId: string | null, courseId: string | null): Promise<TableResponseDTO<ChangeRequestDTO>> {
        let url = `api/changeRequests/list/${encodeURIComponent(institutionId)}/${encodeURIComponent(userId)}`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (programId != null) {
            url += `${prefix}programId=${programId}`;
            prefix = '&';
        }
        if (courseId != null) {
            url += `${prefix}courseId=${courseId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ChangeRequestDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/changeRequests/list/${encodeURIComponent(institutionId)}/${encodeURIComponent(userId)}/export
    public getChangeRequestsDataTableExport(tableRequest: TableRequestDTO, institutionId: string, userId: string): Promise<string> {
        let url = `api/changeRequests/list/${encodeURIComponent(institutionId)}/${encodeURIComponent(userId)}/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/changeRequests/${encodeURIComponent(changeRequestId)}
    public getChangeRequestDetails(changeRequestId: string): Promise<GetChangeRequestDetailsHandler.Result> {
        let url = `api/changeRequests/${encodeURIComponent(changeRequestId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetChangeRequestDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/changeRequests/base/${encodeURIComponent(changeRequestId)}
    public getBaseChangeRequestDetails(changeRequestId: string): Promise<GetBaseChangeRequestDetailsHandler.Result> {
        let url = `api/changeRequests/base/${encodeURIComponent(changeRequestId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetBaseChangeRequestDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/changeRequests/draft
    public getChangeRequestDraft(getChangeRequestDraft: GetChangeRequestDraftHandler.Request): Promise<GetChangeRequestDraftHandler.Result> {
        let url = `api/changeRequests/draft`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetChangeRequestDraftHandler.Result>(getChangeRequestDraft, 'post', url, true, false);
    }

    // post: api/changeRequests/getApproval
    public getChangeRequestApproval(getChangeRequestForWorkItemApproval: GetChangeRequestForWorkItemApprovalHandler.Request): Promise<GetChangeRequestForWorkItemApprovalHandler.Result> {
        let url = `api/changeRequests/getApproval`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetChangeRequestForWorkItemApprovalHandler.Result>(getChangeRequestForWorkItemApproval, 'post', url, true, false);
    }

    // post: api/changeRequests/getChangeRequestApprovalOnDetailsHandler
    public getChangeRequestApprovalOnDetailsHandler(getChangeRequestApprovalOnDetails: GetChangeRequestApprovalOnDetailsHandler.Request): Promise<GetChangeRequestApprovalOnDetailsHandler.Result> {
        let url = `api/changeRequests/getChangeRequestApprovalOnDetailsHandler`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetChangeRequestApprovalOnDetailsHandler.Result>(getChangeRequestApprovalOnDetails, 'post', url, true, false);
    }

    // post: api/changeRequests/save
    public saveChangeRequestDetails(saveChangeRequestDetails: SaveChangeRequestDetailsHandler.Request): Promise<SaveChangeRequestDetailsHandler.Result> {
        let url = `api/changeRequests/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveChangeRequestDetailsHandler.Result>(saveChangeRequestDetails, 'post', url, true, false);
    }

    // post: api/changeRequests/submit
    public submitChangeRequest(submitChangeRequest: SubmitChangeRequestHandler.Request): Promise<SubmitChangeRequestHandler.Result> {
        let url = `api/changeRequests/submit`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitChangeRequestHandler.Result>(submitChangeRequest, 'post', url, true, false);
    }

    // post: api/changeRequests/saveAdditionalDetails
    public saveChangeRequestAdditionalDetails(saveChangeRequestAdditionalDetails: FormData): Promise<SaveChangeRequestAdditionalDetailsHandler.Result> {
        let url = `api/changeRequests/saveAdditionalDetails`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveChangeRequestAdditionalDetailsHandler.Result>(saveChangeRequestAdditionalDetails, 'post', url, false, false);
    }

    // post: api/changeRequests/getAdditionalDetails
    public getAdditionalDetails(getChangeRequestAdditionalDetails: GetChangeRequestAdditionalDetailsHandler.Request): Promise<GetChangeRequestAdditionalDetailsHandler.Result> {
        let url = `api/changeRequests/getAdditionalDetails`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetChangeRequestAdditionalDetailsHandler.Result>(getChangeRequestAdditionalDetails, 'post', url, true, false);
    }

    // post: api/changeRequests/cancelChangeRequest
    public cancelChangeRequest(cancelChangeRequest: CancelChangeRequestHandler.Request): Promise<CancelChangeRequestHandler.Result> {
        let url = `api/changeRequests/cancelChangeRequest`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, CancelChangeRequestHandler.Result>(cancelChangeRequest, 'post', url, true, false);
    }

    // post: api/changeRequests/addComment
    public addComment(addChangeRequestComment: AddChangeRequestCommentHandler.Request): Promise<AddChangeRequestCommentHandler.Result> {
        let url = `api/changeRequests/addComment`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddChangeRequestCommentHandler.Result>(addChangeRequestComment, 'post', url, true, false);
    }

    // post: api/changeRequests/approve
    public approve(approveChangeRequest: ApproveChangeRequestHandler.Request): Promise<ApproveChangeRequestHandler.Result> {
        let url = `api/changeRequests/approve`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ApproveChangeRequestHandler.Result>(approveChangeRequest, 'post', url, true, false);
    }

    // post: api/changeRequests/deny
    public deny(denyChangeRequest: DenyChangeRequestHandler.Request): Promise<DenyChangeRequestHandler.Result> {
        let url = `api/changeRequests/deny`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DenyChangeRequestHandler.Result>(denyChangeRequest, 'post', url, true, false);
    }

    // post: api/changeRequests/comments
    public comments(getChangeRequestComments: GetChangeRequestCommentsHandler.Request): Promise<GetChangeRequestCommentsHandler.Result> {
        let url = `api/changeRequests/comments`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetChangeRequestCommentsHandler.Result>(getChangeRequestComments, 'post', url, true, false);
    }

    // post: api/changeRequests/redirectApproval
    public redirectApproval(redirectApproval: RedirectApprovalHandler.Request): Promise<RedirectApprovalHandler.Result> {
        let url = `api/changeRequests/redirectApproval`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RedirectApprovalHandler.Result>(redirectApproval, 'post', url, true, false);
    }

    // post: api/changeRequests/manualApproval
    public manualApproval(manualApproval: ManualApprovalHandler.Request): Promise<ManualApprovalHandler.Result> {
        let url = `api/changeRequests/manualApproval`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ManualApprovalHandler.Result>(manualApproval, 'post', url, true, false);
    }

    // post: api/changeRequests/manualReject
    public manualReject(manualReject: ManualRejectHandler.Request): Promise<ManualRejectHandler.Result> {
        let url = `api/changeRequests/manualReject`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ManualRejectHandler.Result>(manualReject, 'post', url, true, false);
    }

    // post: api/changeRequests/resendEmails/${encodeURIComponent(changeRequestId)}
    public resendEmails(changeRequestId: string): Promise<ResendEmailsHandler.Result> {
        let url = `api/changeRequests/resendEmails/${encodeURIComponent(changeRequestId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResendEmailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/changeRequests/bulkCourseRequest
    public bulkCourseRequest(bulkCourseRequest: FormData): Promise<any> {
        let url = `api/changeRequests/bulkCourseRequest`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(bulkCourseRequest, 'post', url, false, false);
    }

    // get: api/changeRequests/coursesTemplate
    public coursesTemplate(): Promise<any> {
        let url = `api/changeRequests/coursesTemplate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new ChangeRequestApiService();
export default service;
