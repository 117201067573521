import { Select, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import ReviewAreaEducationAgencyForm from '../../../components/forms/secondary/ReviewAreaEducationAgencyForm';
import Dropdown from '../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import AcademicYearPortal from '../../../portals/secondary/AcademicYearPortal';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface ReviewAreaEducationAgencyAcdemicYearPageState {
  loading: boolean;
  academicYearId: number;
  academicYears: AcademicYearDTO[];
  fromSelfStudies: boolean;
  fromAcademicYearList: boolean;
  reviewAcademicYear: boolean;
}

interface ReviewAreaEducationAgencyAcdemicYearPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class ReviewAreaEducationAgencyAcdemicYearPage extends React.Component<ReviewAreaEducationAgencyAcdemicYearPageProps & RouteComponentProps<RouteObject>, ReviewAreaEducationAgencyAcdemicYearPageState> {

  constructor(props: ReviewAreaEducationAgencyAcdemicYearPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYearId: 0,
      academicYears: [],
      fromSelfStudies: false,
      fromAcademicYearList: false,
      reviewAcademicYear: false
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'academicYearId');
    const fromAcademicYearList = ParameterUtil.getParameter('fromAcademicYearList');
    const reviewAcademicYear = ParameterUtil.getParameter('reviewAcademicYear');
    this.setState({
      fromAcademicYearList: fromAcademicYearList == 'true',
      reviewAcademicYear: reviewAcademicYear == 'true'
    });

    if (this.state.academicYearId != Number.parseInt(id)) {
      this.setState({ academicYearId: Number.parseInt(id), fromSelfStudies: true });
    }
    else {
      this.loadAcademicYears(Number.parseInt(id));
    }
  }

  private loadAcademicYears = (id: number) => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
      academicYearId: id
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
            academicYearId: DateTimeUtil.getCurrentYear()
          });
        }
      })
      .catch()
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private onSelect = (value: any) => {
    this.setState({ academicYearId: value });
  }

  private onBack = () => {
    if (this.state.reviewAcademicYear) {
      HistoryUtil.push(Routes.generate(Routes.NEW_ACADEMIC_YEAR_FROM_LIST, { academicYearId: this.state.academicYearId }, { fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList, reviewAcademicYear: true }));
    }
    else if (this.state.fromSelfStudies) {
      HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: this.state.academicYearId }, {}));
    }
  }

  render() {
    return (
      <Content >
        <HeaderPortal title='Area Education Agencies' breadcrumbs={Breadcrumbs.newAcademicYearFromAcademicList(this.state.academicYearId)}
          onBack={this.onBack}
          extra={this.state.fromSelfStudies ? null : <Dropdown value={this.state.academicYearId} onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.academicYears.map(x => { return this.renderAcademicYear(x); })}</Dropdown>} />
        < Space direction="vertical" size="large">

          <Space direction='vertical' size="large">
            <div id="academicYearCardPortal" />
            <AcademicYearPortal currentStep={1} title={'Opening ' + this.state.academicYearId + '-' + (this.state.academicYearId + 1) + ' Program Review Year'} >
              <ReviewAreaEducationAgencyForm fromAcademicYearList={this.state.fromAcademicYearList} academicYearId={this.state.academicYearId}></ReviewAreaEducationAgencyForm>
            </AcademicYearPortal>
          </Space>
        </Space>
      </Content >
    );
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</Select.Option>
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ReviewAreaEducationAgencyAcdemicYearPage));
