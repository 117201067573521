import { Alert, Input, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionCourseDTO from '../../../models/InstitutionCourseDTO';
import ProgramTermCourseGroupOptionDTO from '../../../models/ProgramTermCourseGroupOptionDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import ValidationUtil from '../../../utils/ValidationUtil';

interface AddFootnoteModalState extends BaseFormState {
  programTermCourseOption: any;
}

interface AddFootnoteModalProps extends BaseFormProps {
  onSelect?: (course: InstitutionCourseDTO) => void;
  institutionId: string;
  footnote?: string;
}

class AddFootnoteModal extends React.Component<AddFootnoteModalProps, AddFootnoteModalState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(ProgramTermCourseGroupOptionDTO.footnote, {
      name: ProgramTermCourseGroupOptionDTO.footnote,
      label: 'Footnote',
    })

  constructor(props: AddFootnoteModalProps) {
    super(props);

    this.state = {
      programTermCourseOption: {}
    };
  }

  public submit = () => {
    const form = this._formRef ? (this._formRef.current as any).getFieldsValue() : null
    return form.footnote;
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const footnoteObject = {};
    footnoteObject[ProgramTermCourseGroupOptionDTO.footnote] = this.props.footnote;

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={footnoteObject}
          onValuesChange={this.handleChange}>

          <FormItem
            {...this._formItems.get(ProgramTermCourseGroupOptionDTO.footnote)}
            {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.footnote, this.state.fieldErrors, this.state.submitted)}>
            <Input.TextArea rows={7} ></Input.TextArea>
          </FormItem>

        </Form >
      </Space >
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AddFootnoteModal;
