// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GenerateSelfStudiesHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SelfStudyDTO from '../models/SelfStudyDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYearId: number;
    secondaryProgramId: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYearId: 0,
            secondaryProgramId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    academicYearId: 'academicYearId',
    secondaryProgramId: 'secondaryProgramId',
}

interface Result extends ResultDTO {
    selfStudies: SelfStudyDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            selfStudies: [],
        },
        initValues);
    },
    selfStudies: 'selfStudies',
}

export { Request, Result };