import { MoreOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Button, Descriptions, Dropdown, Form, FormItemProps, Menu, message, Modal, Skeleton, Space } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import * as React from 'react';
import { useEffect, useState } from 'react';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import Routes from '../../../config/Routes';
import AcademicYearStatusType from '../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../consts/FeatureFlag';
import SelfStudyResponseStatusType from '../../../consts/SelfStudyResponseStatusType';
import SelfStudyResponseType from '../../../consts/SelfStudyResponseType';
import * as GetSelfStudyDetailsHandler from '../../../handlerModels/GetSelfStudyDetailsHandler';
import * as ReopenSelfStudyResponseHandler from '../../../handlerModels/ReopenSelfStudyResponseHandler';
import * as SaveSelfStudyResponseUserHandler from '../../../handlerModels/SaveSelfStudyResponseUserHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import Validator from '../../../models/Validator';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import CurrentUser from '../../../utils/CurrentUser';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import DistrictUserSearchInput from '../../general/DistrictUserSearchInput';
import IDOEConsultantUserSearchInput from '../../general/IDOEConsultantUserSearchInput';
import RppUserSearchInput from '../../general/RppUserSearchInput';
import * as GetAcademicYearHandler from '../../../handlerModels/GetAcademicYearHandler';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import PdfFileDownloadButton from './SelfStudyPdfDocument';

interface SelfStudyDetailDisplayProps extends BaseFormProps {
  selfStudyId: string;
  academicYear: number;
  onUserAdd?: () => void;
}

function SelfStudyDetailDisplay(props: SelfStudyDetailDisplayProps) {

  const _formItems = new Map<string, FormItemProps>()
    .set('user', {
      label: 'Please Select a User',
      required: true,
      name: 'user',
    });

  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);
  const [showDistrictUserAddModal, setShowDistrictUserAddModal] = useState(false);
  const [showRppUserAddModal, setShowRppUserAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignUserSubmitting, setAssignUserSubmitting] = useState(false);
  const [showConsultantUserAddModal, setShowConsultantUserAddModal] = useState(false);
  const [responseType, setResponseType] = useState(0);
  const [fieldErrors,] = useState({} as ({ [key: string]: Validator[]; } | null));
  const [submitted,] = useState(false);
  const [academicYear, setAcademicYear] = useState({} as AcademicYearDTO)
  const _formRef = React.useRef<any>();

  useEffect(() => {
    const loaders = [];
    loaders.push(loadSelfStudy(props.selfStudyId));

    Promise.all(loaders).then(() => {
      setLoading(false);
    });
  }, [])

  const loadSelfStudy = (id: string) => {
    setLoading(true);

    const request = GetSelfStudyDetailsHandler.Request.create({
      id: id
    });

    return SelfStudyApiService.getSelfStudyDetails(request)
      .then((results: GetSelfStudyDetailsHandler.Result) => {
        if (results.selfStudy) {
          setSelfStudy(results?.selfStudy ?? SelfStudyDTO.create());
          loadAcademicYear(results.selfStudy.academicYearId);
        }
      }).catch((error: any) => {
        message.success(error.toString());
      });
  }

  const loadAcademicYear = (academicYearId: number) => {
    setLoading(true);
    const request = GetAcademicYearHandler.Request.create({
      academicYearId: academicYearId
    });

    return AcademicYearApiService.getAcademicYear(request)
      .then((results: GetAcademicYearHandler.Result) => {
        if (results) {
          setAcademicYear(results.academicYear ?? AcademicYearDTO.create())
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onResponseClick = (selfStudyResponseTypeId: number) => {
    HistoryUtil.push(Routes.generate(Routes.COMPLETE_SELF_STUDIES, { academicYearId: props.academicYear ?? 0, selfStudyResponseId: selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == selfStudyResponseTypeId)?.id ?? Guid.Empty(), sectionIndex: 0 }, { selfStudyResponseType: selfStudyResponseTypeId, step: 0 }));
  }

  const onReopenClick = (selfStudyResponseId: string) => {
    const request = ReopenSelfStudyResponseHandler.Request.create({
      selfStudyResponseId: selfStudyResponseId
    });

    return SelfStudyApiService.reopenSelfStudyResponse(request)
      .then((results: ReopenSelfStudyResponseHandler.Result) => {
        if (results) {
          HistoryUtil.push(Routes.generate(Routes.COMPLETE_SELF_STUDIES, { academicYearId: props.academicYear ?? 0, selfStudyResponseId: selfStudyResponseId ?? Guid.Empty(), sectionIndex: 0 }, { selfStudyResponseType: selfStudyResponseId, step: 0 }));
        }
      }).catch((error: any) => {
        message.success(error.toString());
      });
  }

  const openAddUserModal = (responseType: number) => {
    setResponseType(responseType);
    if (responseType == SelfStudyResponseType.INSTRUCTOR) {
      setShowDistrictUserAddModal(true);
    }
    else if (responseType == SelfStudyResponseType.RPP) {
      setShowRppUserAddModal(true);
    }
    else if (responseType == SelfStudyResponseType.DOE) {
      setShowConsultantUserAddModal(true);
    }
  }

  const closeAddUserModal = () => {
    setResponseType(0);
    setShowDistrictUserAddModal(false);
    setShowRppUserAddModal(false);
    setShowConsultantUserAddModal(false);
  }

  const modalSubmit = () => {
    const model = _formRef.current?.getFieldsValue();
    setAssignUserSubmitting(true);
    if (model.user != undefined && model.user != null) {
      const request = SaveSelfStudyResponseUserHandler.Request.create({
        selfStudyId: selfStudy.id,
        userId: model.user.id,
        selfStudyResponseTypeId: responseType
      });

      SelfStudyApiService.saveResponseUser(request)
        .then((result: SaveSelfStudyResponseUserHandler.Result) => {
          if (result?.succeeded) {
            message.success('User Added Successfully!');
            closeAddUserModal();
            if (props.onUserAdd) {
              props.onUserAdd()
            }
          }
          else {
            message.error('Error Adding User');
          }
        })
        .catch(() => {
          message.error('Error Adding User');
        })
        .finally(() => {
          setAssignUserSubmitting(false);
        })
    }
    else {
      message.error('Error Adding User');
    }
  }

  const resetModalForm = () => {
    _formRef.current?.resetFields();
  }

  const renderAddDistrictReponseUser = () => {
    const title = 'Assign District Responder';

    return (
      <Modal
        visible={showDistrictUserAddModal}
        title={title}
        onOk={modalSubmit}
        onCancel={closeAddUserModal}
        footer={[
          <Button key='clear' type='link' onClick={resetModalForm}>Clear Selected</Button>,
          <Button key='back' loading={assignUserSubmitting} onClick={closeAddUserModal}>
            Cancel
          </Button>,
          <Button key='submit' loading={assignUserSubmitting} type='primary' onClick={modalSubmit}>
            Assign User
          </Button>
        ]}>
        <Form
          ref={_formRef}
          layout="vertical"
          requiredMark={true}>
          <Form.Item
            {..._formItems.get('user')}
            {...ValidationUtil.getValidation('user', fieldErrors, submitted)}>
            <DistrictUserSearchInput districtIdentifier={selfStudy?.secondaryProgram?.district?.districtId ?? 0}
              academicYear={props.academicYear} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  const renderAddRPPReponseUser = () => {
    const title = 'Assign RPP Responder';

    return (
      <Modal
        visible={showRppUserAddModal}
        title={title}
        onOk={modalSubmit}
        onCancel={closeAddUserModal}
        footer={[
          <Button key='clear' type='link' onClick={resetModalForm}>Clear Selected</Button>,
          <Button key='back' onClick={closeAddUserModal}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={modalSubmit}>
            Assign User
          </Button>
        ]}>
        <Form
          ref={_formRef}
          layout="vertical"
          requiredMark={true}>
          <Form.Item
            {..._formItems.get('user')}
            {...ValidationUtil.getValidation('user', fieldErrors, submitted)}>
            <RppUserSearchInput rppId={selfStudy?.secondaryProgram?.district?.regionIdentifier ?? 0} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  const renderAddConsultantReponseUser = () => {
    const title = 'Assign IDOE Consultant Responder';

    return (
      <Modal
        visible={showConsultantUserAddModal}
        title={title}
        onOk={modalSubmit}
        onCancel={closeAddUserModal}
        footer={[
          <Button key='clear' type='link' onClick={resetModalForm}>Clear Selected</Button>,
          <Button key='back' onClick={closeAddUserModal}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={modalSubmit}>
            Assign User
          </Button>
        ]}>
        <Form
          ref={_formRef}
          layout="vertical"
          requiredMark={true}>
          <Form.Item
            {..._formItems.get('user')}
            {...ValidationUtil.getValidation('user', fieldErrors, submitted)}>
            <IDOEConsultantUserSearchInput
              serviceAreaIdentifier={selfStudy.secondaryProgram?.secondaryCipNumber?.serviceAreaIdentifier} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }


  const showButtons = (((selfStudy.selfStudyResponses?.findIndex(x => x.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.COMPLETE) ?? -1) > -1) ||
    AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_CURRENT_PROGRAM_STATUS, FeatureFlag.SYSTEM_ADMIN])) &&
    (academicYear.academicYearStatusTypeId != AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS && academicYear.academicYearStatusTypeId != AcademicYearStatusType.CLOSED);

  const renderAddInstructorResponder = () => {
    if (showButtons) {
      if (AuthorizationUtil.isAuthorized([FeatureFlag.ASSIGN_DISTRICT_RESPONDER])) {
        return <Button title='Add District User' onClick={() => openAddUserModal(SelfStudyResponseType.INSTRUCTOR)} type='link'><UserSwitchOutlined /></Button>;
      }
    }
  }

  const renderAddRPPResponder = () => {
    if (showButtons) {
      if (AuthorizationUtil.isAuthorized([FeatureFlag.ASSIGN_RPP_RESPONDER])) {
        return <Button title='Add RPP Consultant' onClick={() => openAddUserModal(SelfStudyResponseType.RPP)} type='link'><UserSwitchOutlined /></Button>;
      }
    }
  }

  const renderAddDOEResponser = () => {
    if (showButtons) {
      if (AuthorizationUtil.isAuthorized([FeatureFlag.ASSIGN_DE_CONSULTANT_RESPONDER])) {
        return <Button title='Add IDOE Consultant' onClick={() => openAddUserModal(SelfStudyResponseType.DOE)} type='link'><UserSwitchOutlined /></Button>;
      }
    }
  }

  const instructorResponse = selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR);
  const rppResponse = selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.RPP);
  const doeConsultantResponse = selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.DOE);
  const validStatusTypes = [SelfStudyResponseStatusType.PENDINGAPPROVAL, SelfStudyResponseStatusType.COMPLETE];

  const renderActions = (buttonText: string, response: SelfStudyResponseDTO) => {
    const menu = (
      <Menu>
        {
          <Menu.Item title='Reopen Response' onClick={() => onReopenClick(response?.id ?? Guid.Empty())}>
            Reopen Response
          </Menu.Item>
        }
      </Menu>
    );
    if (response.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.COMPLETE) {
      return <Button title={buttonText} onClick={() => onResponseClick(response.selfStudyReponseTypeId)} type='primary'>{buttonText}</Button>;

    }
    else {
      return <Dropdown.Button type='primary' onClick={() => onResponseClick(response.selfStudyReponseTypeId)} overlay={menu}
        icon={<MoreOutlined />}>{buttonText}</Dropdown.Button>
    }
  }

  const renderInstructorResponseButton = () => {
    if (showButtons || AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT_RESPONSE, FeatureFlag.SYSTEM_ADMIN])) {
      if ((instructorResponse && (instructorResponse?.respondingUserId != null && instructorResponse.respondingUserId != Guid.Empty())) || AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN])) {
        const extraHeaderButtonText = validStatusTypes.findIndex(x => x == instructorResponse?.selfStudyResponseStatusTypeId) >= 0 ? 'View Self-Study' : instructorResponse?.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.INPROGRESS || instructorResponse?.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISING ? 'Edit Self-Study' : 'Start Self-Study';

        if (selfStudy?.secondaryProgram?.district?.districtApprovalUserId && selfStudy?.secondaryProgram?.district?.districtApprovalUserId == CurrentUser.Get()?.userId && instructorResponse?.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.PENDINGAPPROVAL) {
          return <Space>
                  <Button title={extraHeaderButtonText} onClick={() => onResponseClick(SelfStudyResponseType.INSTRUCTOR)} type='primary'>Approve Self-Study</Button>
                  <PdfFileDownloadButton 
                      selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.id ?? Guid.Empty()} 
                      selfStudyResponseType={SelfStudyResponseType.INSTRUCTOR}
                      academicYear={props.academicYear}
                      selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
                      /> 
                </Space>
        }
        else if ((AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT_RESPONSE]) && instructorResponse?.respondingUserId == CurrentUser.Get()?.userId) || AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN])) {
          return (
            <Space>
              {renderActions(extraHeaderButtonText, instructorResponse ?? SelfStudyResponseDTO.create())}
              <PdfFileDownloadButton
                      selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.id ?? Guid.Empty()} 
                      selfStudyResponseType={SelfStudyResponseType.INSTRUCTOR}
                      academicYear={props.academicYear}
                      selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
                      /> 
            </Space>)
        }
        else if (AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_DISTRICT_RESPONSE, FeatureFlag.SYSTEM_ADMIN])) {
          return <Space>
                  <Button title={extraHeaderButtonText} onClick={() => onResponseClick(SelfStudyResponseType.INSTRUCTOR)} type='primary'>View Response</Button>
                  <PdfFileDownloadButton 
                      selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.id ?? Guid.Empty()} 
                      selfStudyResponseType={SelfStudyResponseType.INSTRUCTOR}
                      academicYear={props.academicYear}
                      selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
                      /> 
                 </Space>
        }
      }
      else if (selfStudy?.secondaryProgram?.district?.districtApprovalUserId && selfStudy?.secondaryProgram?.district?.districtApprovalUserId == CurrentUser.Get()?.userId && instructorResponse?.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.PENDINGAPPROVAL) {
        return <Space>
                <Button title={'Approve Response'} onClick={() => onResponseClick(SelfStudyResponseType.INSTRUCTOR)} type='primary'>Approve Response</Button>
                <PdfFileDownloadButton
                      selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.id ?? Guid.Empty()} 
                      selfStudyResponseType={SelfStudyResponseType.INSTRUCTOR}
                      academicYear={props.academicYear}
                      selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
                      /> 
               </Space>
      }
    }
  }

  const renderRPPResponseButton = () => {
    if (showButtons || AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.SYSTEM_ADMIN])) {
      if (instructorResponse?.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE && (rppResponse && (rppResponse?.respondingUserId != null && rppResponse.respondingUserId != Guid.Empty())) || AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN])) {
        const extraHeaderButtonText = validStatusTypes.findIndex(x => x == rppResponse?.selfStudyResponseStatusTypeId) >= 0 ? 'View Self-Study Review' : 'Review Self-Study';

        if (selfStudy?.secondaryProgram?.district?.districtApprovalUserId && selfStudy?.secondaryProgram?.district?.districtApprovalUserId == CurrentUser.Get()?.userId && rppResponse?.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.PENDINGAPPROVAL) {
          return <Space>
                    <Button title={extraHeaderButtonText} onClick={() => onResponseClick(SelfStudyResponseType.RPP)} type='primary'>Approve Response</Button>
                    <PdfFileDownloadButton 
                      selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.RPP)?.id ?? Guid.Empty()} 
                      selfStudyResponseType={SelfStudyResponseType.RPP}
                      academicYear={props.academicYear}
                      selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
                      /> 
                  </Space>
        }
        else if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.SYSTEM_ADMIN])) {
          return (
            <Space>
              {renderActions(extraHeaderButtonText, rppResponse ?? SelfStudyResponseDTO.create())}
              <PdfFileDownloadButton 
                      selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.RPP)?.id ?? Guid.Empty()} 
                      selfStudyResponseType={SelfStudyResponseType.RPP}
                      academicYear={props.academicYear}
                      selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
                      />  
            </Space>
          )
        }
        else if (AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_RPP_RESPONSE, FeatureFlag.SYSTEM_ADMIN])) {
          return <Space>
                    <Button title={extraHeaderButtonText} onClick={() => onResponseClick(SelfStudyResponseType.RPP)} type='primary'>View Response</Button>
                    <PdfFileDownloadButton 
                      selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.RPP)?.id ?? Guid.Empty()} 
                      selfStudyResponseType={SelfStudyResponseType.RPP}
                      academicYear={props.academicYear}
                      selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
                      /> 
                  </Space>
        }
      }
    }
  }

  const renderDOEConsultantResponseButton = () => {
    if (showButtons || AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.SYSTEM_ADMIN])) {
      if (instructorResponse?.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE && (doeConsultantResponse && (doeConsultantResponse?.respondingUserId != null && doeConsultantResponse.respondingUserId != Guid.Empty())) || AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN])) {
        const extraHeaderButtonText = validStatusTypes.findIndex(x => x == doeConsultantResponse?.selfStudyResponseStatusTypeId) >= 0 ? 'View Self-Study Review' : 'Review Self-Study';
        if (selfStudy?.secondaryProgram?.district?.districtApprovalUserId && selfStudy?.secondaryProgram?.district?.districtApprovalUserId == CurrentUser.Get()?.userId && doeConsultantResponse?.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.PENDINGAPPROVAL) {
          return <Space> 
                    <Button title={extraHeaderButtonText} onClick={() => onResponseClick(SelfStudyResponseType.DOE)} type='primary'>Approve Response</Button>
                    <PdfFileDownloadButton 
                      selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.DOE)?.id ?? Guid.Empty()} 
                      selfStudyResponseType={SelfStudyResponseType.DOE}
                      academicYear={props.academicYear}
                      selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
                      />   
                  </Space>
        }
        else if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.SYSTEM_ADMIN])) {
          return (
            <Space>
              {renderActions(extraHeaderButtonText, doeConsultantResponse ?? SelfStudyResponseDTO.create())}
              <PdfFileDownloadButton 
              selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.DOE)?.id ?? Guid.Empty()} 
              selfStudyResponseType={SelfStudyResponseType.DOE}
              academicYear={props.academicYear}
              selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
              />            
              </Space>)
        }
        else if (AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE, FeatureFlag.SYSTEM_ADMIN])) {
          return <Space>
                    <Button title={extraHeaderButtonText} onClick={() => onResponseClick(SelfStudyResponseType.DOE)} type='primary'>View Response</Button>
                    <PdfFileDownloadButton 
                      selfStudyResponseId={selfStudy?.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.DOE)?.id ?? Guid.Empty()} 
                      selfStudyResponseType={SelfStudyResponseType.DOE}
                      academicYear={props.academicYear}
                      selfStudy={selfStudy}
                      selfStudyId={selfStudy.id ?? ''}
sectionIndex={0}
                      />   
                  </Space>
        }
      }
    }
  }
  if (loading) {
    return (<Skeleton active />);
  }
  else {
    return (
      <>
        <Space direction='vertical'>
          <Descriptions bordered={true} size='small' layout='vertical'>
            <DescriptionsItem label="Program" >{selfStudy?.secondaryProgram?.secondaryProgramId}</DescriptionsItem>
            <DescriptionsItem label="District" >{selfStudy?.secondaryProgram?.district?.name}</DescriptionsItem>
            <DescriptionsItem label="RPP" >{selfStudy?.secondaryProgram?.district?.region?.name}</DescriptionsItem>
            <DescriptionsItem label="District Status" >
              {instructorResponse?.selfStudyResponseStatusType?.name}
            </DescriptionsItem>
            <DescriptionsItem label="RPP Status" >
              {rppResponse?.selfStudyResponseStatusType?.name}
            </DescriptionsItem>
            <DescriptionsItem label="DOE Status" >
              {doeConsultantResponse?.selfStudyResponseStatusType?.name}
            </DescriptionsItem>
            <DescriptionsItem label="District Responder" >
              <Space direction='vertical'>
                <Space>
                  {instructorResponse?.respondingUser?.display ?? 'Unassigned'}
                  {renderAddInstructorResponder()}
                </Space>
                {renderInstructorResponseButton()}
              </Space>
            </DescriptionsItem>
            <DescriptionsItem label="RPP Responder" >
              <Space direction='vertical'>
                <Space>
                  {rppResponse?.respondingUser?.display ?? 'Unassigned'}
                  {renderAddRPPResponder()}
                </Space>
                {renderRPPResponseButton()}
              </Space>
            </DescriptionsItem>
            <DescriptionsItem label="DOE Responder" >
              <Space direction='vertical'>
                <Space>
                  {doeConsultantResponse?.respondingUser?.display ?? 'Unassigned'}
                  {renderAddDOEResponser()}
                </Space>
                {renderDOEConsultantResponseButton()}
              </Space>
            </DescriptionsItem>
          </Descriptions>
        </Space>

        {renderAddDistrictReponseUser()}
        {renderAddRPPReponseUser()}
        {renderAddConsultantReponseUser()}
      </>
    );
  }
}

export default SelfStudyDetailDisplay;