import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CipsApiService from '../../../../api/CipsApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import CipNumberDetailsForm from '../../../../components/forms/postSecondary/CipNumberDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteCipNumberHandler from '../../../../handlerModels/DeleteCipNumberHandler';
import * as GetCipDetailsHandler from '../../../../handlerModels/GetCipDetailsHandler';
import CipNumberDetailDTO from '../../../../models/CipNumberDetailDTO';
import CipNumberDTO from '../../../../models/CipNumberDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface CipDetailsPageState extends BaseFormState {
  cipId: string;
  cip: CipNumberDTO;
  selectedCipDetail: CipNumberDetailDTO;
  cipDetails: CipNumberDetailDTO[];
}

interface CipDetailsPageProps {
}

class CipDetailsPage extends React.Component<CipDetailsPageProps & RouteComponentProps<RouteObject>, CipDetailsPageState> {
  private readonly _cipDetailsRef = React.createRef<CipNumberDetailsForm>();

  constructor(props: CipDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      cipId: Guid.Empty(),
      cip: CipNumberDTO.create(),
      selectedCipDetail: CipNumberDetailDTO.create(),
      cipDetails: []
    };
  }

  componentDidMount() {
    const cipId = ParameterUtil.getPathPart(this.props.match, 'id');
    if (cipId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      this.loadCip(cipId);
    }
  }

  private loadCip = (cipId: string) => {
    this.setState({ cipId: cipId });

    CipsApiService.getCipDetails(cipId)
      .then((results: GetCipDetailsHandler.Result) => {
        if (results.cip?.cipNumberDetails) {
          this.setState({
            cipId: cipId,
            cip: results.cip,
            cipDetails: results.cip?.cipNumberDetails ?? [],
            selectedCipDetail: results.cip?.currentDetail ?? CipNumberDetailDTO.create()
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._cipDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private cipDetailsSaved = (cipId: string) => {
    this.setState({ isEditing: false });

    HistoryUtil.replace(Routes.generate(Routes.CIP_DETAILS, { id: cipId }));
  }

  private deleteConfirmation = (id: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this CIP Number?',
      okText: 'Delete',
      onOk: () => this.deleteCip(id)
    });
  }

  private deleteCip = (cipNumberId: string) => {
    this.setState({ submitting: true });

    const request = DeleteCipNumberHandler.Request.create({
      cipNumberId: cipNumberId,
    });

    CipsApiService.deleteCipNumber(request)
      .then((result: DeleteCipNumberHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('CIP Number was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('CIP Number could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToCips();
      });
  }

  private goToCips = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.cipId == Guid.Empty() ? 'New CIP Number' : this.state.selectedCipDetail?.title ?? '...';
    const breadcrumb = this.state.loading ? '...' : this.state.cipId == Guid.Empty() ? 'New' : this.state.selectedCipDetail?.display ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToCips}
          breadcrumbs={Breadcrumbs.cipNumberDetails(breadcrumb, this.state.cipId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />
        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.cipId == Guid.Empty() ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CIP_NUMBER]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <CipNumberDetailsForm
          ref={this._cipDetailsRef}
          cipId={this.state.cipId}
          cip={this.state.cip}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.cipDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete CIP Number' key={this.state.cipId} onClick={() => this.deleteConfirmation(this.state.cipId)}  >
          Delete CIP Number
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CIP_NUMBER]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(CipDetailsPage);
