import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, AutoComplete, Button, Input, message, Skeleton, Space, Table, Typography } from 'antd';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionUsersApiService from '../../../api/InstitutionUsersApiService';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import * as SaveNewProgramChangeRequestStep3Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep3Handler';
import * as SearchForUsersForNoticeOfIntentHandler from '../../../handlerModels/SearchForUsersForNoticeOfIntentHandler';
import * as SubmitNewProgramChangeRequestStep3Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep3Handler';
import * as GetNewProgramChangeRequestStep3Handler from '../../../handlerModels/GetNewProgramChangeRequestStep3Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import NewProgramChangeRequestContactDTO from '../../../models/NewProgramChangeRequestContactDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import UserDTO from '../../../models/UserDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import { FilterType } from '../../datatables/core/DataTable';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import Guid from '../../../utils/Guid';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep3FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
  userSearch: UserDTO[];
  consultantSearch: UserDTO[];
  openSelect: Map<string, boolean>;
}

interface NewProgramChangeRequestStep3FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: () => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep3Form extends React.Component<NewProgramChangeRequestStep3FormProps, NewProgramChangeRequestStep3FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  firstNameIdentifier = 'firstName';
  lastNameIdentifier = 'lastName';
  emailIdentifier = 'email';

  private getContactFormItems = (index: number) => {
    const fields = new Map<string, FormItemProps>()
      .set(NewProgramChangeRequestContactDTO.userId, {
        name: [index, NewProgramChangeRequestContactDTO.userId],
      })
      .set(NewProgramChangeRequestContactDTO.firstName, {
        name: [index, NewProgramChangeRequestContactDTO.firstName],
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NewProgramChangeRequestContactDTO.lastName, {
        name: [index, NewProgramChangeRequestContactDTO.lastName],
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NewProgramChangeRequestContactDTO.email, {
        name: [index, NewProgramChangeRequestContactDTO.email],
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NewProgramChangeRequestContactDTO.phone, {
        name: [index, NewProgramChangeRequestContactDTO.phone],
      });
    return fields;
  }

  private getColumnDefinitionsForContactInformation = (remove: any) => {
    const contactOptions = this.state.userSearch;
    const autoCompleteOptions: any[] = contactOptions.map(x => {
      return { label: x.fullDisplay, key: x.id, value: x.id }
    });

    return [
      {
        title: 'First Name',
        dataIndex: NewProgramChangeRequestContactDTO.firstName,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getContactFormItems(index).get(NewProgramChangeRequestContactDTO.firstName)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestContactDTO.firstName + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <AutoComplete
              open={this.state.openSelect.get(this.firstNameIdentifier + index)}
              onClick={() => this.handleClick(this.firstNameIdentifier + index)}
              onBlur={() => this.handleBlur(this.firstNameIdentifier + index)}
              disabled={this.props.readonly}
              options={autoCompleteOptions}
              onSearch={this.handleUserSearch}
              onSelect={(e) => this.handleContactSelected(e, contactOptions, index)}>
              <Input title='First Name' disabled={this.props.readonly} />
            </AutoComplete>
          </FormItem>
        },
      },
      {
        title: 'Last Name',
        dataIndex: NewProgramChangeRequestContactDTO.lastName,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getContactFormItems(index).get(NewProgramChangeRequestContactDTO.lastName)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestContactDTO.lastName + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}
          >
            <AutoComplete
              open={this.state.openSelect.get(this.lastNameIdentifier + index)}
              onClick={() => this.handleClick(this.lastNameIdentifier + index)}
              onBlur={() => this.handleBlur(this.lastNameIdentifier + index)}
              disabled={this.props.readonly}
              options={autoCompleteOptions}
              onSearch={this.handleUserSearch}
              onSelect={(e) => this.handleContactSelected(e, contactOptions, index)}>
              <Input title='Last Name' disabled={this.props.readonly} />
            </AutoComplete>
          </FormItem>
        },
      },
      {
        title: 'Email',
        dataIndex: NewProgramChangeRequestContactDTO.email,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getContactFormItems(index).get(NewProgramChangeRequestContactDTO.email)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestContactDTO.email + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <AutoComplete
              open={this.state.openSelect.get(this.emailIdentifier + index)}
              onClick={() => this.handleClick(this.emailIdentifier + index)}
              onBlur={() => this.handleBlur(this.emailIdentifier + index)}
              disabled={this.props.readonly}
              options={autoCompleteOptions}
              onSearch={this.handleUserSearch}
              onSelect={(e) => this.handleContactSelected(e, contactOptions, index)}>
              <Input title='Email' disabled={this.props.readonly} />
            </AutoComplete>
          </FormItem>
        },
      },
      {
        title: 'Phone Number',
        dataIndex: NewProgramChangeRequestContactDTO.phone,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getContactFormItems(index).get(NewProgramChangeRequestContactDTO.phone)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestContactDTO.phone + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input title='Phone Number' disabled={this.props.readonly}></Input>
          </FormItem>
        },
      },
      {
        title: 'Actions',
        dataIndex: NewProgramChangeRequestContactDTO.userId,
        width: '15px',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: any, index: any) => {
          return this.props.readonly || this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.contacts)?.length <= 1 ?
            null :
            <Button type="link" onClick={() => remove(row.name)}><DeleteOutlined /></Button>
        },
        sorter: false,
        filterType: FilterType.NONE
      }
    ] as any[];
  };

  constructor(props: NewProgramChangeRequestStep3FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      newProgramChangeRequest: NewProgramChangeRequestDTO.create(),
      consultantSearch: [],
      userSearch: [],
      openSelect: new Map<string, boolean>(),
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
        this.loadChangeRequest();
      }
    }
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep3FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
        this.loadChangeRequest();
      }
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({
        loading: this.props.loading
      });

    }
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep3Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep3(request)
      .then((results: GetNewProgramChangeRequestStep3Handler.Result) => {
        if (results) {
          const open = this.state.openSelect;
          let count = 0;
          this.state.newProgramChangeRequest?.contacts?.forEach(() => {
            open.set(this.firstNameIdentifier + count, false);
            open.set(this.lastNameIdentifier + count, false);
            open.set(this.emailIdentifier + count, false);
            count++;
          });
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors,
            openSelect: open
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private handleChange = (altered: boolean) => {
    this.setState({ altered: altered });
  }

  toggleOpen = (key: string, state: boolean) => {
    const open = this.state.openSelect;
    open.set(this.firstNameIdentifier, false);
    open.set(this.lastNameIdentifier, false);
    open.set(this.emailIdentifier, false);
    open.set(key, state);

    this.setState({
      openSelect: open
    });
  }

  handleClick = (key: string) => {
    this.toggleOpen(key, !(this.state.openSelect?.get(key) ?? false));
  }

  handleBlur = (key: string) => {
    this.toggleOpen(key, false);
  }

  private handleContactSelected = (value: string, options: UserDTO[], index: number) => {
    const selected = options.filter(x => x.id == value)[0];

    const contacts = this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.contacts);
    contacts[index] = {
      ...contacts[index],
      firstName: selected.firstName,
      lastName: selected.lastName,
      phoneNumber: selected.phoneNumber,
      email: selected.username,
      userId: selected.id
    }

    if (selected) {
      this._formRef.current?.setFieldsValue({
        contacts: contacts,
      });
    }

    this.setState({
      userSearch: []
    });
  }

  private handleUserSearch = (searchText: string) => {
    if (searchText.length >= 3) {
      const request = SearchForUsersForNoticeOfIntentHandler.Request.create({
        institutionId: this.props.selectedInstitution,
        searchText: searchText,
      })

      InstitutionUsersApiService.searchForUsers(request)
        .then((results: SearchForUsersForNoticeOfIntentHandler.Result) => {
          if (results.users) {
            this.setState({ userSearch: results.users })
          }
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false })
        });
    }
  }

  private handleSave = () => {
    this.setState({ saving: true });
    let model = this._formRef.current?.getFieldsValue();
    model = { ...this.state.changeRequest.newProgramChangeRequest, ...model }

    const request = SaveNewProgramChangeRequestStep3Handler.Request.create({
      newProgramChangeRequest: model,
    });

    NewProgramChangeRequestApiService.saveStep3(request)
      .then((result: SaveNewProgramChangeRequestStep3Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
          });

          if (this.props.onSave) {
            this.props.onSave();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    let model = this._formRef.current?.getFieldsValue() ?? NewProgramChangeRequestDTO.create();
    model = { ...this.state.changeRequest.newProgramChangeRequest, ...model }

    const request = SubmitNewProgramChangeRequestStep3Handler.Request.create({
      newProgramChangeRequest: model,
    });

    NewProgramChangeRequestApiService.submitStep3(request)
      .then((result: SubmitNewProgramChangeRequestStep3Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }
    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}


        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.newProgramChangeRequest}
          onFinish={this.handleSubmit}
          onValuesChange={this.handleChange}
        >

          <Space direction="vertical" >
            <Typography.Paragraph italic={true}>
              Please review the contact information for the point(s) of contact for this program.
            </Typography.Paragraph>

            <Form.List name={NewProgramChangeRequestDTO.contacts}>
              {
                (contacts, { add, remove }) => {
                  return (
                    <Space direction='vertical'>
                      <Table
                        size='small'
                        dataSource={contacts}
                        pagination={false}
                        columns={this.getColumnDefinitionsForContactInformation(remove)} />
                      {this.renderAddAdditionalContact(add)}
                    </Space>);
                }
              }
            </Form.List>

            {this.renderActions()}
          </Space>
        </Form >
      </Space >
    );
  }

  private renderActions() {
    if (!this.props.readonly) {
      return < Space direction='horizontal' wrap={true} >
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved}></SaveButton>
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space >
    }
  }

  renderAddAdditionalContact(add: any) {
    if (!this.props.readonly) {
      return (
        <Button onClick={() => add()} icon={<PlusOutlined />}>
          Add Additional Contact
        </Button>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      const message = ValidationUtil.getValidation(NewProgramChangeRequestDTO.contacts, this.state.fieldErrors, this.state.submitted || this.props.readonly)?.messages.join('\n');
      return <Alert type="error" message='Error' showIcon={true} description={message} />;
    }
  }
}

export default NewProgramChangeRequestStep3Form