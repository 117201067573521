import { Skeleton, Tabs } from 'antd';
import * as React from 'react';
import ServiceAreaAssignmentDataTable from '../datatables/secondary/ServiceAreaAssignmentDataTable';
import UserCipAssignmentDataTable from '../datatables/UserCipAssignmentDataTable';

interface AssignmentsDisplayProps {
  userId: string;
  subTab?: string;
  onChangeTab?: (tabName: string) => void;
}

function AssignmentsDisplay(props: AssignmentsDisplayProps) {
  if (props.userId) {
    return (
      <Tabs activeKey={props.subTab} onChange={props.onChangeTab}>
        <Tabs.TabPane key='serviceArea' tab='Service Areas' >
          <ServiceAreaAssignmentDataTable userId={props.userId} />
        </Tabs.TabPane>

        <Tabs.TabPane key='cipNumber' tab='CIP Numbers' >
          <UserCipAssignmentDataTable userId={props.userId} />
        </Tabs.TabPane>
      </Tabs>
    );
  }

  return <Skeleton />;
}

export default AssignmentsDisplay
