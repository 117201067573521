import { Input, InputNumber, Select, Space } from 'antd';
import * as React from 'react';
import TermDTO from '../models/TermDTO';
import DateUtil from '../utils/DateTimeUtil';
import Guid from '../utils/Guid';
import LookupsUtil from '../utils/LookupsUtil';
import Dropdown from './inputs/Dropdown';
import ValueLabel from './general/ValueLabel';

export interface TermInputValue {
  termId: string,
  year: number | undefined
}

interface TermInputState {
  year: number | undefined;
  termId: string;
  terms: TermDTO[];
  openSelect: Map<string, boolean>;
}

interface TermInputProps {
  value?: TermInputValue;
  onChange?: (value: TermInputValue) => void;
  disabled?: boolean;
  readOnly?: boolean;
  minYear?: number;
  futureYears?: boolean;
  anyYear?: boolean;
}

class TermInput extends React.Component<TermInputProps, TermInputState> {
  termIdentifier = 'term';
  yearIdentifier = 'year';

  constructor(props: TermInputProps) {
    super(props);

    this.state = {
      year: undefined,
      termId: '',
      terms: [],
      openSelect: new Map<string, boolean>()
    }
  }

  private handleChanged = (year: number | undefined, termId: string) => {
    if (this.props.onChange) {
      this.props.onChange({ year: year, termId: termId })
    }
  }

  private handleYearChanged = (value: string | number | null | undefined) => {
    if (Number.isNaN(value)) {
      return;
    }

    const year = value as number;

    this.setState({
      year: year
    });

    this.handleChanged(year, this.state.termId);
  }

  private handleTermChanged = (value: string) => {
    this.handleChanged(this.state.year, value);
  }

  componentDidMount() {
    this.loadTerms();
    const open = this.state.openSelect;
    open.set(this.termIdentifier, false);
    open.set(this.yearIdentifier, false);

    this.setState({
      termId: this.props.value?.termId ?? '',
      year: this.props.value?.year,
      openSelect: open
    });
  }

  loadTerms() {
    LookupsUtil.getAll<TermDTO>(TermDTO.className).then(terms => { this.setState({ terms: terms }); });
  }

  componentDidUpdate(prevProps: TermInputProps) {
    if (this.props.value && this.props.value != prevProps.value) {
      this.setState({
        termId: this.props.value.termId, year: this.props.value.year
      });
    }
  }

  handleTermChange = (termId: string) => {
    this.setState({
      termId: termId
    });
  }

  handleYearChange = (year: number) => {
    this.setState({
      year: year
    });
  }

  handleClick = (key: string) => {
    this.toggleOpen(key, !(this.state.openSelect?.get(key) ?? false));
  }

  handleBlur = (key: string) => {
    this.toggleOpen(key, false);
  }

  toggleOpen = (key: string, state: boolean) => {
    const open = this.state.openSelect;
    open.set(this.termIdentifier, false);
    open.set(this.yearIdentifier, false);
    open.set(key, state);

    this.setState({
      openSelect: open
    });
  }

  render() {
    const termId = this.state.termId == Guid.Empty() || this.state.termId == '' ? undefined : this.state.termId;
    const year = this.state.year == 0 ? undefined : this.state.year;

    const maxYear = DateUtil.getCurrentYear() + 2
    const minYear = this.props.futureYears ? DateUtil.getCurrentYear() : 2021;

    const years = [];
    for (let i = maxYear; i >= minYear; i--) {
      years.push(i);
    }

    return (
      <div>
        {this.props.readOnly ?
          <Space direction="horizontal" >
            <ValueLabel value={this.state.terms.find(x => x.id == termId)?.name} />
            <ValueLabel value={year} />
          </Space> :

          <Input.Group compact>
            <label title="Term" htmlFor={this.termIdentifier} >
              <Dropdown
                dropdownMatchSelectWidth={false}
                showArrow={!this.props.disabled}
                disabled={this.props.disabled || this.props.readOnly}
                value={termId}
                onSelect={this.handleTermChanged}
                onChange={this.handleTermChanged}
                placeholder='Term'
                allowClear={true}>
                {this.state.terms.map(x => { return this.renderTermOption(x); })}
              </Dropdown>
            </label>

            <label title="Year" htmlFor={this.yearIdentifier} >
              {this.props.anyYear ?
                <InputNumber
                  onChange={this.handleYearChanged}
                  disabled={this.props.disabled || this.props.readOnly}
                  value={year}
                  placeholder='Year' /> :
                <Dropdown
                  open={this.state.openSelect.get(this.yearIdentifier)}
                  onClick={() => this.handleClick(this.yearIdentifier)}
                  onBlur={() => this.handleBlur(this.yearIdentifier)}
                  dropdownMatchSelectWidth={false}
                  showArrow={!this.props.disabled}
                  onSelect={this.handleYearChange}
                  onChange={this.handleYearChanged}
                  disabled={this.props.disabled || this.props.readOnly}
                  value={year}
                  placeholder='Year'
                  allowClear={true}>
                  {years.map(x => { return this.renderYear(x); })}
                </Dropdown>
              }
            </label>
          </Input.Group>
        }
      </div>
    );
  }

  renderTermOption(term: TermDTO) {
    if (term.id && term.name) {
      return <Select.Option aria-label={term.name} title={term.name} key={term.id} value={term.id}>{term.name}</Select.Option>
    }
  }

  renderYear(year: number) {
    return <Select.Option title={year.toString()} key={year} value={year}>{year}</Select.Option>
  }
}

export default TermInput;
