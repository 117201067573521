export class FullColumnWidths {
  public static FULL = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
  }

  public static EXTRA_EXTRA_WIDE = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 22, offset: 1 },
    xl: { span: 20, offset: 2 },
    xxl: { span: 18, offset: 3 },
  }

  public static EXTRA_WIDE = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 22, offset: 1 },
    lg: { span: 20, offset: 2 },
    xl: { span: 18, offset: 3 },
    xxl: { span: 16, offset: 4 },
  }

  public static WIDE = {
    xs: { span: 24, offset: 0 },
    sm: { span: 22, offset: 1 },
    md: { span: 20, offset: 2 },
    lg: { span: 18, offset: 3 },
    xl: { span: 16, offset: 4 },
    xxl: { span: 14, offset: 5 },
  }

  public static MEDIUM = {
    xs: { span: 24, offset: 0 },
    sm: { span: 22, offset: 1 },
    md: { span: 18, offset: 4 },
    lg: { span: 14, offset: 5 },
    xl: { span: 12, offset: 6 },
    xxl: { span: 10, offset: 7 },
  }

  public static SMALLISH = {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 4 },
    md: { span: 14, offset: 5 },
    lg: { span: 12, offset: 6 },
    xl: { span: 10, offset: 7 },
    xxl: { span: 8, offset: 8 },
  }

  public static SMALL = {
    xs: { span: 24, offset: 0 },
    sm: { span: 14, offset: 5 },
    md: { span: 12, offset: 6 },
    lg: { span: 10, offset: 7 },
    xl: { span: 8, offset: 8 },
    xxl: { span: 6, offset: 9 },
  }

  public static TINY = {
    xs: { span: 24, offset: 0 },
    sm: { span: 12, offset: 6 },
    md: { span: 10, offset: 7 },
    lg: { span: 8, offset: 8 },
    xl: { span: 6, offset: 9 },
    xxl: { span: 4, offset: 10 },
  }
}

export class ColumnWidths {
  public static FULL = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  }

  public static ONE_EIGHTH = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 3 },
    xl: { span: 3 },
    xxl: { span: 3 },
  }

  public static ONE_SIXTH = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 4 },
    xl: { span: 4 },
    xxl: { span: 4 },
  }

  public static THREE_EIGHTHS = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 9 },
    xl: { span: 9 },
    xxl: { span: 9 },
  }

  public static FIVE_EIGHTHS = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 15 },
    xl: { span: 15 },
    xxl: { span: 15 },
  }

  public static THREE_QUARTERS = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 18 },
    xl: { span: 18 },
    xxl: { span: 18 },
  }

  public static TWO_THIRDS = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 16 },
    xl: { span: 16 },
    xxl: { span: 16 },
  }

  public static HALF = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 12 },
    xxl: { span: 12 },
  }

  public static ONE_THIRD = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 8 },
    xl: { span: 8 },
    xxl: { span: 8 },
  }

  public static ONE_QUARTER = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 6 },
  }

  public static FIVE_SIXTH = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 20 },
    xl: { span: 20 },
    xxl: { span: 20 },
  }

  public static CENTERED_HALF = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 12, offset: 6 },
    xxl: { span: 12, offset: 6 },
  }

  public static CENTERED_THREE_QUARTERS = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 18, offset: 3 },
    xxl: { span: 18, offset: 3 },
  }
}