import { Empty } from 'antd';
import * as React from 'react';
import WorkItemApiService from '../../api/WorkItemApiService';
import Routes from '../../config/Routes';
import WorkItemStatusType from '../../consts/WorkItemStatusType';
import WorkItemType from '../../consts/WorkItemType';
import TableRequestDTO from '../../models/TableRequestDTO';
import WorkQueueItemDTO from '../../models/WorkQueueItemDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import DateTimeUtil from '../../utils/DateTimeUtil';
import Guid from '../../utils/Guid';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from './core/DataTable';

interface WorkQueueDataTableProps {
  active: boolean;
}

interface WorkQueueDataTableState extends BaseDataTableState<WorkQueueItemDTO> {
}

class WorkQueueDataTable extends React.Component<WorkQueueDataTableProps, WorkQueueDataTableState> {
  private _dataTable: DataTable<WorkQueueItemDTO> | undefined;

  constructor(props: WorkQueueDataTableProps) {
    super(props);
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Type',
        dataIndex: WorkQueueItemDTO.workItemTypeId,
        sorter: false,
        filterType: FilterType.NONE,
        render: (data: string, row: WorkQueueItemDTO) => {
          return row.workItemType?.name;
        },
        width: '200px'
      },
      {
        title: 'Details',
        dataIndex: WorkQueueItemDTO.key,
        sorter: false,
        filterType: FilterType.NONE,
        render: (data: string, row: WorkQueueItemDTO) => {
          return row.description;
        }
      },
      {
        title: 'Created',
        dataIndex: WorkQueueItemDTO.createdOn,
        filterType: FilterType.NONE,
        render: (data: string, row: WorkQueueItemDTO) => {
          return DateTimeUtil.shortDate(row.createdOn);
        }
      },
      {
        title: 'Status',
        dataIndex: WorkQueueItemDTO.workItemStatusTypeId,
        filterType: FilterType.NONE,
        render: (data: number, row: WorkQueueItemDTO) => {
          if (data === WorkItemStatusType.ACTIVE && DateTimeUtil.isBeforeRange(row.activeOn)) {
            return 'Upcoming';
          }
          else {
            return row.workItemStatusType?.name;
          }
        }
      }
    ] as DataTableColumnProps<any>[];
  };

  private openWorkQueue = (record: WorkQueueItemDTO) => {
    if (record.key) {
      const key = record.key.split('_')[1];

      switch (record.workItemTypeId) {
        case WorkItemType.CHANGEREQUESTDRAFT:
          HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_DRAFT, { key: key }, {}));
          break;
        case WorkItemType.REVIEWCHANGEREQUEST:
          HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_APPROVAL, { approvalId: key ?? Guid.Empty() }, {}));
          break;
        case WorkItemType.APPROVALREDIRECT:
          HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_APPROVAL_REDIRECT, { approvalId: key }, {}));
          break;
        case WorkItemType.RESPONSETOSELFSTUDY:
        case WorkItemType.REVISESELFSTUDY:
        case WorkItemType.SUBMITSELFSTUDY:
          HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: record.key.split('_')[2], }, {}))
          break;
        case WorkItemType.ASSIGNDECONSULTANT:
        case WorkItemType.ASSIGNDISTRICTUSER:
        case WorkItemType.ASSIGNRPPCONSULTANT:
          HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: key }, {}))
          break;
      }
    }
  }

  render() {
    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openWorkQueue}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          locale: {
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='You are all caught up!' />
          },
          scroll: { x: 400 }
        }}
        globalSearch={false}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => WorkItemApiService.getWorkQueue(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'work_queue_list' }} />
    );
  }
}

export default WorkQueueDataTable;
