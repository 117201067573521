import { Space, Typography } from 'antd';
import * as React from 'react';
import CheckboxButton from './inputs/CheckboxButtons';

export interface CheckboxInputGroupOption {
  id: any;
  display: string;
}

interface CheckboxInputGroupState {
  selectedValue: any;
}

interface CheckboxInputGroupProps {
  checkBox?: boolean;
  value?: any | null;
  options: CheckboxInputGroupOption[];
  onChange?: (value: any) => void;
  disabled?: boolean;
  direction?: 'vertical' | 'horizontal';
  readonly?: boolean;
  emptyDescription?: string;
}

class CheckboxInputGroup extends React.Component<CheckboxInputGroupProps, CheckboxInputGroupState> {
  constructor(props: CheckboxInputGroupProps) {
    super(props);

    this.state = {
      selectedValue: null,
    }
  }

  private handleClicked = (value: any, checked: boolean) => {
    const selected = checked ? value : null;
    this.setState({
      selectedValue: selected,
    });

    if (this.props.onChange) {
      this.props.onChange(selected)
    }
  }

  componentDidMount() {
    this.setState({ selectedValue: this.props.value });
  }

  componentDidUpdate(prevProps: CheckboxInputGroupProps) {
    if (this.props.value && this.props.value != prevProps.value) {
      this.setState({ selectedValue: this.props.value });
    }
  }

  public resetSelected = () => {
    this.setState({ selectedValue: null });
  }

  render() {
    if (this.props.readonly) {
      return this.renderSelected();
    }

    return (
      <Space direction={this.props.direction} size='small'>
        {this.renderCheckBoxes()}
      </Space >
    );
  }

  renderCheckBoxes() {
    return this.props.options.map(x => this.renderCheckBox(x))
  }

  renderSelected() {
    const selected = this.props.options.filter(x => x.id == this.state.selectedValue)[0]?.display;
    if (selected) {
      return <Typography.Text strong={true} >{selected}</Typography.Text>;
    }
    else if (this.props.emptyDescription) {
      return <Typography.Text italic={true}>{this.props.emptyDescription}</Typography.Text>
    }

    return null;
  }

  renderCheckBox(option: CheckboxInputGroupOption) {
    if (option) {
      return <CheckboxButton
        checkBox={this.props.checkBox}
        disabled={this.props.disabled}
        value={option.id}
        onClick={(checked: boolean) => this.handleClicked(option.id, checked)}
        checked={option.id == this.state.selectedValue} >
        {option.display}
      </CheckboxButton>
    }

    return null;
  }

  static defaultProps = {
    direction: 'vertical'
  }
}

export default CheckboxInputGroup;
