import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import ServiceAreaAssignmentApiService from '../../../api/ServiceAreaAssignmentApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetServiceAreasHandler from '../../../handlerModels/GetServiceAreasHandler';
import * as RemoveServiceAreaAssignmentHandler from '../../../handlerModels/RemoveServiceAreaAssignmentHandler';
import ServiceAreaAssignmentDTO from '../../../models/ServiceAreaAssignmentDTO';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import ServiceAreaAssignmentModal from '../../modals/ServiceAreaAssignmentModal';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';


interface ServiceAreaAssignmentsDataTableProps {
  userId: string;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
}

function ServiceAreaAssignmentDataTable(props: ServiceAreaAssignmentsDataTableProps) {
  let _dataTable: DataTable<ServiceAreaAssignmentDTO> | undefined = undefined;

  const [serviceArea, setServiceArea] = useState([] as ServiceAreaDTO[]);
  const [showAddServiceAreaModal, setShowAddServiceAreaModal] = useState<boolean>(false);

  React.useEffect(() => {
    const loaders = [];
    loaders.push(loadServiceArea());

    Promise.all(loaders).then(() => {
      triggerRefresh();
    });
  }, [props.userId]);


  const loadServiceArea = () => {
    return ServiceAreaApiService.getServiceAreas(0)
      .then((results: GetServiceAreasHandler.Result) => {
        if (results.serviceAreas) {
          setServiceArea(results.serviceAreas);
        }
      })
  }

  const getColumnDefinitions = () => {
    return [
      {
        title: 'Identifier',
        dataIndex: ServiceAreaAssignmentDTO.serviceAreaIdentifier,
        sorter: true,
        width: 25,
        filterType: FilterType.Text
      },
      {
        title: 'Title',
        dataIndex: ServiceAreaDTO.description,
        render: (data: string, row: ServiceAreaAssignmentDTO) => {
          return serviceArea.find(x => x.serviceAreaIdentifier == row.serviceAreaIdentifier)?.description;
        },
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: serviceArea?.map(c => { return { text: c.description ?? '', value: c.serviceAreaIdentifier.toString() ?? '' } }),
        filterDropdownVisible: true
      },
      {
        title: 'Actions',
        align: 'center',
        dataIndex: ServiceAreaAssignmentDTO.id,
        sorter: false,
        width: 50,
        render: (data: string, row: ServiceAreaAssignmentDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => promptConfirmRemove(row.serviceAreaIdentifier, row.userId ?? Guid.Empty())}><DeleteOutlined /></Button>
            </>
          }
        },
      },
    ] as DataTableColumnProps<any>[];
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openServiceAreaDetail = () => {
    setShowAddServiceAreaModal(true);
  }

  const closeServiceAreaDetail = () => {
    setShowAddServiceAreaModal(false);
  }

  const promptConfirmRemove = (serviceAreaIdentifier: number, userId: string) => {

    Modal.confirm({
      title: 'Are you sure you want to remove this Service Area?',
      okText: 'Delete',
      onOk: () => deleteServiceArea(serviceAreaIdentifier, userId),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteServiceArea = (serviceAreaIdentifier: number, userId: string) => {
    const request = RemoveServiceAreaAssignmentHandler.Request.create({
      serviceAreaIdentifier: serviceAreaIdentifier,
      userId: userId
    });

    ServiceAreaAssignmentApiService.removeServiceAreaAssignment(request)
      .then((result: RemoveServiceAreaAssignmentHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Service Area could not be removed.');
      });
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SERVICE_AREA])) {
    actionButtons.push(DataTableButtonUtil.Default('Add Service Area', () => openServiceAreaDetail()));
  }

  const renderModal = () => {
    return <ServiceAreaAssignmentModal
      onCancel={closeServiceAreaDetail}
      addServiceAreaModal={showAddServiceAreaModal}
      visible={showAddServiceAreaModal}
      userId={props.userId}
      onSave={closeServiceAreaDetail} />
  }

  return (
    <>
      {renderModal()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => ServiceAreaAssignmentApiService.getServiceAreaAssignmentTableData(requestState, props.userId)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'service_area_list' }} />
    </>
  );
}

export default ServiceAreaAssignmentDataTable;
