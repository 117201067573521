// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveSecondaryProgramScenarioDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SecondaryProgramScenarioDTO from '../models/SecondaryProgramScenarioDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    secondaryProgramScenarioId: string | null | null;
    secondaryProgramScenario: SecondaryProgramScenarioDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            secondaryProgramScenarioId: null,
            secondaryProgramScenario: null,
        },
        initValues);
    },
    secondaryProgramScenarioId: 'secondaryProgramScenarioId',
    secondaryProgramScenario: 'secondaryProgramScenario',
}

interface Result extends ResultDTO {
    secondaryProgramScenario: SecondaryProgramScenarioDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            secondaryProgramScenario: null,
        },
        initValues);
    },
    secondaryProgramScenario: 'secondaryProgramScenario',
}

export { Request, Result };