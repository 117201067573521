import { StepProps } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface NavigationStepProps extends StepProps {
  route?: string;
  enabled?: boolean;
}

function NavigationStep(props: React.PropsWithChildren<NavigationStepProps>) {
  let title = <div>{props.title}</div>
  if (props.enabled && props.route) {
    title = (
      <Link to={props.route}>{title}</Link>
    );
  }

  return title;
}

export default NavigationStep;
