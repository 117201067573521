import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProgramClassificationApiService from '../../../../api/ProgramClassificationApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import ProgramClassificationDetailsForm from '../../../../components/forms/postSecondary/ProgramClassificationDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteProgramClassificationHandler from '../../../../handlerModels/DeleteProgramClassificationHandler';
import * as GetProgramClassificationDetailsHandler from '../../../../handlerModels/GetProgramClassificationDetailsHandler';
import ProgramClassificationDTO from '../../../../models/ProgramClassificationDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface ProgramClassificationDetailsPageState extends BaseFormState {
  programClassificationId: number;
  programClassification?: ProgramClassificationDTO;
}

interface ProgramClassificationDetailsPageProps {
}

class ProgramClassificationDetailsPage extends React.Component<ProgramClassificationDetailsPageProps & RouteComponentProps<RouteObject>, ProgramClassificationDetailsPageState> {
  private readonly _detailsRef = React.createRef<ProgramClassificationDetailsForm>();

  constructor(props: ProgramClassificationDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      programClassificationId: 0
    };
  }

  componentDidMount() {
    const programClassification = ParameterUtil.getPathPart(this.props.match, 'id');

    if (programClassification == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const programClassificationId = Number.parseInt(programClassification);
      this.loadProgramClassification(programClassificationId);
    }
  }

  private loadProgramClassification = (programClassificationId: number) => {
    this.setState({ programClassificationId: programClassificationId });

    ProgramClassificationApiService.getProgramClassificationDetails(programClassificationId)
      .then((results: GetProgramClassificationDetailsHandler.Result) => {
        if (results.programClassification) {
          this.setState({ programClassification: results.programClassification });
        }
      }).catch(() => {
        this.setState({ error: true });
        message.error('Program Classification could not be loaded')
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._detailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private programClassificationDetailsSaved = (programClassificationId: number) => {
    this.setState({ isEditing: false });
    if (programClassificationId != this.state.programClassificationId) {
      HistoryUtil.replace(Routes.generate(Routes.PROGRAM_CLASSIFICATION_DETAILS, { id: programClassificationId }));
    }
    else {
      this.loadProgramClassification(programClassificationId);
    }
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Program Classification?',
      okText: 'Delete',
      onOk: () => this.deleteProgramClassification(id)
    });
  }

  private deleteProgramClassification = (programClassificationId: number) => {
    this.setState({ submitting: true });

    const request = DeleteProgramClassificationHandler.Request.create({
      programClassificationId: programClassificationId,
    });

    ProgramClassificationApiService.deleteProgramClassification(request)
      .then((result: DeleteProgramClassificationHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Program Classification was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Program Classification could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goProgramClassification();
      });
  }

  private goProgramClassification = () => {
    HistoryUtil.push(Routes.generate(Routes.PROGRAM_CLASSIFICATION));
  }

  render() {
    const title = this.state.loading ? '...' : this.state.programClassificationId == 0 ? 'New Program Classification' : this.state.programClassification?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goProgramClassification}
          breadcrumbs={Breadcrumbs.programClassificationDetails(title, this.state.programClassificationId ?? 0)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.programClassificationId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_PROGRAM_CLASSIFICATION]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <ProgramClassificationDetailsForm
          ref={this._detailsRef}
          programClassificationId={this.state.programClassificationId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.programClassificationDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Program Classification' key={this.state.programClassificationId} onClick={() => this.deleteConfirmation(this.state.programClassificationId)}  >
          Delete Program Classification
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_PROGRAM_CLASSIFICATION]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(ProgramClassificationDetailsPage);
