import { Alert, Typography } from 'antd';
import * as React from 'react';

interface PasswordTipsState { }

interface PasswordTipsProps {
}

class PasswordTips extends React.Component<PasswordTipsProps, PasswordTipsState> {
  constructor(props: PasswordTipsProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Alert
        message='Password Tips'
        description={
          <Typography.Paragraph >
            <text>To help keep your data safe, we require the use of strong passwords. Follow these tips for creating a strong password:</text>
            <ul>
              <li>Use a longer password</li>
              <li>Include numbers, symbols, and uppercase and lowercase letters</li>
              <li>Avoid using common words or phrases</li>
              <li>Avoid using personal information</li>
            </ul>
          </Typography.Paragraph>}
        type="info"
        showIcon={true}
      />
    );
  }
}

export default PasswordTips;
