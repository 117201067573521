import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import APSGeneralEducationDataTable from '../../../../components/datatables/postSecondary/APSGeneralEducationDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface APSGeneralEducationListProps {
}

interface APSGeneralEducationListState {
}

class APSGeneralEducationListPage extends React.Component<APSGeneralEducationListProps & RouteComponentProps<RouteObject>, APSGeneralEducationListState> {
  constructor(props: APSGeneralEducationListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="APS General Education" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.apsGeneralEducationList()} />

        <Card >
          <APSGeneralEducationDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(APSGeneralEducationListPage);
