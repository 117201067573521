// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveGenderDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import GenderDTO from '../models/GenderDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    genderId: number;
    gender: GenderDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            genderId: 0,
            gender: null,
        },
        initValues);
    },
    genderId: 'genderId',
    gender: 'gender',
}

interface Result extends ResultDTO {
    gender: GenderDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            gender: null,
        },
        initValues);
    },
    gender: 'gender',
}

export { Request, Result };