import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChangeRequestApiService from '../../../../api/ChangeRequestApiService';
import ChangeRequestLayout from '../../../../components/postSecondary/ChangeRequestLayout';
import ProgramDeactivationChangeRequestStep1Form from '../../../../components/forms/postSecondary/ProgramDeactivationChangeRequestStep1Form';
import Routes from '../../../../config/Routes';
import ChangeRequestType from '../../../../consts/ChangeRequestType';
import * as GetBaseChangeRequestDetailsHandler from '../../../../handlerModels/GetBaseChangeRequestDetailsHandler';
import ChangeRequestDTO from '../../../../models/ChangeRequestDTO';
import InstitutionDTO from '../../../../models/InstitutionDTO';
import UserSecurity from '../../../../models/UserSecurity';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface ProgramDeactivationChangeRequestStep1PageState extends BaseFormState {
  changeRequestId: string;
  changeRequest: ChangeRequestDTO;
  programId: string | null;
}

interface ProgramDeactivationChangeRequestStep1PageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class ProgramDeactivationChangeRequestStep1Page extends React.Component<ProgramDeactivationChangeRequestStep1PageProps & RouteComponentProps<RouteObject>, ProgramDeactivationChangeRequestStep1PageState> {

  constructor(props: ProgramDeactivationChangeRequestStep1PageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequestId: Guid.Empty(),
      changeRequest: ChangeRequestDTO.create({
        changeRequestTypeId: ChangeRequestType.PROGRAMDEACTIVATION
      }),
      programId: Guid.Empty()
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id == 'new' || id == ParameterUtil.DEFAULT_STRING) {
      this.setState({ isNew: true });
    }

    else if (id !== Guid.Empty() && this.state.changeRequestId != id) {
      this.setState({ changeRequestId: id });
      this.fetchData(id);
    }
  }

  private fetchData = (id: string) => {
    this.setState({ loading: true });

    const loaders = [];

    if (id !== Guid.Empty()) {
      loaders.push(this.loadChangeRequest(id));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadChangeRequest = (id: string) => {
    return ChangeRequestApiService.getBaseChangeRequestDetails(id)
      .then((results: GetBaseChangeRequestDetailsHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private handleSave = (id: string) => {
    this.setState({ isEditing: false });
    if (id != this.state.changeRequestId) {
      HistoryUtil.replace(Routes.generate(Routes.EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_1, { id: id }));
    }
    else {
      this.loadChangeRequest(id);
    }
  }

  private handleSubmitted = (id: string) => {
    HistoryUtil.replace(Routes.generate(Routes.EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_1, { id: id }));
    HistoryUtil.push(Routes.generate(Routes.EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_2, { id: id }));
  }

  render() {
    return (
      <Content >
        <ChangeRequestLayout
          changeRequest={this.state.changeRequest ?? ChangeRequestDTO.create()}
          stepNumber={0}
          currentUser={this.props.currentUser ?? {} as UserSecurity}>
          <Card title="Deactivation Information" >
            <ProgramDeactivationChangeRequestStep1Form
              changeRequestId={this.state.changeRequest.id}
              institutionId={this.props.selectedInstitution ? this.props.selectedInstitution.id : this.state.changeRequest.institutionId}
              loading={this.state.loading}
              onSubmit={this.handleSubmitted}
              onSave={this.handleSave} />
          </Card>
        </ChangeRequestLayout>
      </Content >
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ProgramDeactivationChangeRequestStep1Page));