import { VersionStoreState } from '../state/StateStoreModel';
import { VersionActionTypes, VersionAction } from '../actions/VersionActions';

export const InitialVersionStoreState: VersionStoreState = {
  Client: $apiVersion,
  Api: null,
  Outdated: false,
};

export function Version(state: VersionStoreState = InitialVersionStoreState, action: VersionAction): VersionStoreState {
  let newState: VersionStoreState = state;
  switch (action.type) {
    case VersionActionTypes.VERSION_OUTDATED:
      newState = { ...state, ...(action.data || {}), Outdated: true };
      break;

    case VersionActionTypes.VERSION_RESET:
      newState = { ...state, Outdated: false };
      break;

    default:
      break;
  }
  return newState;
}
