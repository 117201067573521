import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ProgramClassificationApiService from '../../../api/ProgramClassificationApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetProgramClassificationDetailsHandler from '../../../handlerModels/GetProgramClassificationDetailsHandler';
import * as SaveProgramClassificationDetailsHandler from '../../../handlerModels/SaveProgramClassificationDetailsHandler';
import ProgramClassificationDTO from '../../../models/ProgramClassificationDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface ProgramClassificationDetailsFormState extends BaseFormState {
  programClassificationId: number;
  programClassification: ProgramClassificationDTO;
}

interface ProgramClassificationDetailsFormProps extends BaseFormProps {
  programClassificationId: number;
  onSave?: (programClassificationId: number) => void;
}

class ProgramClassificationDetailsForm extends React.Component<ProgramClassificationDetailsFormProps, ProgramClassificationDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(ProgramClassificationDTO.name, {
      required: true,
      name: ProgramClassificationDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    });

  constructor(props: ProgramClassificationDetailsFormProps) {
    super(props);

    this.state = {
      programClassificationId: 0,
      programClassification: ProgramClassificationDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const loaders = [];

    loaders.push(this.loadProgramClassification());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(prevProps: ProgramClassificationDetailsFormProps) {
    if (this.props.programClassificationId && this.props.programClassificationId != prevProps.programClassificationId) {
      this.setState({ programClassificationId: this.props.programClassificationId, loading: true });
      const loaders = [];

      loaders.push(this.loadProgramClassification());

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadProgramClassification() {
    this.setState({ loading: true });

    const loaders = [];

    if (this.props.programClassificationId == 0) {
      this.loadNew();
    }
    else {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadExisting = () => {
    if (this.props.programClassificationId) {
      return ProgramClassificationApiService.getProgramClassificationDetails(this.props.programClassificationId)
        .then((results: GetProgramClassificationDetailsHandler.Result) => {
          if (results.programClassification) {
            this.setState({ programClassificationId: results.programClassification.id, programClassification: results.programClassification });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        });
    }
  }

  private loadNew = () => {
    this.setState({ programClassification: ProgramClassificationDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveProgramClassificationDetailsHandler.Request.create({
      programClassificationId: this.state.programClassificationId,
      programClassification: ProgramClassificationDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    ProgramClassificationApiService.saveProgramClassificationDetails(request)
      .then((result: SaveProgramClassificationDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          programClassification: result?.programClassification ?? ProgramClassificationDTO.create(),
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.programClassification) {
            this.props.onSave(result.programClassification.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(ProgramClassificationDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Program Classification could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Program Classification could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading || this.state.programClassification == null) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.programClassification}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <Space direction='vertical'>
            <FormItem
              {...this._formItems.get(ProgramClassificationDTO.name)}
              {...ValidationUtil.getValidation(ProgramClassificationDTO.name, this.state.fieldErrors, this.state.submitted)}>
              <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} />
            </FormItem>

            {this.renderSave()}
          </Space>
        </Form >
      </Space >
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_PROGRAM_CLASSIFICATION]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default ProgramClassificationDetailsForm;
