// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import GeneralEducationDTO from './GeneralEducationDTO';
import AwardTypeDTO from './AwardTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AwardTypeGeneralEducationCreditsDTO extends EntitySelectorDTO { 
	id: number;
	generalEducationId: number;
	generalEducation: GeneralEducationDTO | null;
	awardTypeId: number;
	awardType: AwardTypeDTO | null;
	minimumCredits: number;
}

const AwardTypeGeneralEducationCreditsDTO = {
    className: 'AwardTypeGeneralEducationCreditsDTO',
    create: (initValues?: Partial<AwardTypeGeneralEducationCreditsDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: 0,
			generalEducationId: 0,
			generalEducation: null,
			awardTypeId: 0,
			awardType: null,
			minimumCredits: 0,
        },
        initValues);
    },
	id: 'id',
	generalEducationId: 'generalEducationId',
	generalEducation: 'generalEducation',
	awardTypeId: 'awardTypeId',
	awardType: 'awardType',
	minimumCredits: 'minimumCredits',
};

export default AwardTypeGeneralEducationCreditsDTO;