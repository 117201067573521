// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetUserDetailsHandler from '../handlerModels/GetUserDetailsHandler';
import * as SaveUserDetailsHandler from '../handlerModels/SaveUserDetailsHandler';

export class UserSettingsApiService extends BaseApi {

    // post: api/userSettings/getUserDetails
    public getUserDetails(): Promise<GetUserDetailsHandler.Result> {
        let url = `api/userSettings/getUserDetails`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetUserDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/userSettings/save
    public saveUser(user: SaveUserDetailsHandler.Request): Promise<SaveUserDetailsHandler.Result> {
        let url = `api/userSettings/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveUserDetailsHandler.Result>(user, 'post', url, true, false);
    }
}
var service = new UserSettingsApiService();
export default service;
