// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SelfStudySectionComponentTemplateDTO from '../models/SelfStudySectionComponentTemplateDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYear: number;
    selfStudySectionOrder: number;
    selfStudySectionComponentOrder: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYear: 0,
            selfStudySectionOrder: 0,
            selfStudySectionComponentOrder: 0,
        },
        initValues);
    },
    academicYear: 'academicYear',
    selfStudySectionOrder: 'selfStudySectionOrder',
    selfStudySectionComponentOrder: 'selfStudySectionComponentOrder',
}

interface Result extends ResultDTO {
    selfStudySectionComponentTemplate: SelfStudySectionComponentTemplateDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            selfStudySectionComponentTemplate: null,
        },
        initValues);
    },
    selfStudySectionComponentTemplate: 'selfStudySectionComponentTemplate',
}

export { Request, Result };