// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import NewProgramChangeRequestDTO from './NewProgramChangeRequestDTO';
import GenderDTO from './GenderDTO';
import RacialEthnicGroupDTO from './RacialEthnicGroupDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NewProgramAdvisoryBoardContactDTO extends EntitySelectorDTO { 
	id: string | null;
	newProgramChangeRequestId: string | null;
	newProgramChangeRequest: NewProgramChangeRequestDTO | null;
	firstName: string | null;
	lastName: string | null;
	genderId: number | null | null;
	gender: GenderDTO | null;
	employer: string | null;
	position: string | null;
	disability: boolean | null | null;
	organizedLabor: boolean | null | null;
	ethnicGroupId: number | null | null;
	ethnicGroup: RacialEthnicGroupDTO | null;
}

const NewProgramAdvisoryBoardContactDTO = {
    className: 'NewProgramAdvisoryBoardContactDTO',
    create: (initValues?: Partial<NewProgramAdvisoryBoardContactDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			newProgramChangeRequestId: '00000000-0000-0000-0000-000000000000',
			newProgramChangeRequest: null,
			firstName: "",
			lastName: "",
			genderId: null,
			gender: null,
			employer: "",
			position: "",
			disability: null,
			organizedLabor: null,
			ethnicGroupId: null,
			ethnicGroup: null,
        },
        initValues);
    },
	id: 'id',
	newProgramChangeRequestId: 'newProgramChangeRequestId',
	newProgramChangeRequest: 'newProgramChangeRequest',
	firstName: 'firstName',
	lastName: 'lastName',
	genderId: 'genderId',
	gender: 'gender',
	employer: 'employer',
	position: 'position',
	disability: 'disability',
	organizedLabor: 'organizedLabor',
	ethnicGroupId: 'ethnicGroupId',
	ethnicGroup: 'ethnicGroup',
};

export default NewProgramAdvisoryBoardContactDTO;