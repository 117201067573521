import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import * as React from 'react';

interface YesNoInputState {
  value: boolean | null;
}

interface YesNoInputProps {
  value?: boolean | null;
  onChange?: (value: boolean | null) => void;
  disabled?: boolean;
  readonly?: boolean;
  size?: SizeType;
  icon?: boolean;
  yesText?: string;
  noText?: string;
}

class YesNoInput extends React.Component<YesNoInputProps, YesNoInputState> {
  constructor(props: YesNoInputProps) {
    super(props);

    this.state = {
      value: null
    }
  }

  private handleChanged = (value: boolean) => {
    const localValue = this.state.value == value ? null : value;
    this.setState({ value: localValue });

    if (this.props.onChange) {
      this.props.onChange(localValue);
    }
  }

  componentDidMount() {
    if (this.props.value != undefined) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidUpdate(prevProps: YesNoInputProps) {
    if (this.props.value != undefined && this.props.value != prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const defaultType = this.props.disabled ? 'text' : 'default'
    return (
      <div className="yes-no-input">
        {this.renderReadOnlySelected(defaultType)}
      </div>
    );
  }


  renderReadOnlySelected = (defaultType: 'text' | 'default') => {
    const yesText = this.props.yesText ?? 'Yes';
    const noText = this.props.noText ?? 'No';

    if (this.props.readonly) {
      return this.props.value ? <CheckCircleFilled /> : null;
    }
    else {
      return (
        <Space direction='horizontal' wrap={false}>
          <Button size={this.props.size ?? 'middle'} disabled={this.props.disabled} className="yes-no-input-button" type={this.state.value != null && this.state.value == true ? 'primary' : defaultType} onClick={() => this.handleChanged(true)}>{yesText}</Button>
          <Button size={this.props.size ?? 'middle'} disabled={this.props.disabled} type={this.state.value != null && this.state.value == false ? 'primary' : defaultType} onClick={() => this.handleChanged(false)}>{noText}</Button>
        </Space>
      );
    }

  }
}

export default YesNoInput;
