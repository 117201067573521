import { Modal, ModalProps } from 'antd';
import React from 'react';
import ServiceAreaAssignmentDetailForm from '../forms/secondary/ServiceAreaAssignmentDetailForm';


interface ServiceAreaDetailsModalProps extends ModalProps {
  addServiceAreaModal?: boolean;
  userId?: string;
  onSave?: () => void;
}

function ServiceAreaAssignmentModal(props: ServiceAreaDetailsModalProps) {
  const _serviceAreaDetailsRef = React.createRef<ServiceAreaAssignmentDetailForm>();

  const submitSaveServiceArea = () => {
    _serviceAreaDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={props.addServiceAreaModal}
      title={'New Assigment'}
      okText='Save'
      onOk={submitSaveServiceArea}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      cancelText={'Close'}
      destroyOnClose={true}>
      <ServiceAreaAssignmentDetailForm
        ref={_serviceAreaDetailsRef}
        isEditing={true}
        userId={props.userId}
      />
    </Modal>
  );
}

export default ServiceAreaAssignmentModal;