import { Alert, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ChangeRequestApiService from '../../../api/ChangeRequestApiService';
import Routes from '../../../config/Routes';
import ChangeRequestType from '../../../consts/ChangeRequestType';
import * as SaveChangeRequestAdditionalDetailsHandler from '../../../handlerModels/SaveChangeRequestAdditionalDetailsHandler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FileUploadUtil from '../../../utils/FileUploadUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import FileUpload, { FileUploadValue } from '../../FileUpload';
import RichTextEditor from '../../inputs/RichTextEditor';

interface ChangeRequestAdditionalDetailsFormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  additionalComments: string;
  additionalResources: FileUploadValue;
}

interface ChangeRequestAdditionalDetailsFormProps extends BaseFormProps {
  changeRequest: ChangeRequestDTO;
  onSubmit?: () => void;
  onPrevious?: () => void;
  onSave?: () => void;
  onChange?: (percentage: number) => void;
  readonly?: boolean;
}

class ChangeRequestAdditionalDetailsForm extends React.Component<ChangeRequestAdditionalDetailsFormProps, ChangeRequestAdditionalDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    const validTypes = [ChangeRequestType.COURSEMODIFICATION, ChangeRequestType.PROGRAMMODIFICATION, ChangeRequestType.TRANSFERMAJORPROGRAMMODIFICATION]
    let required = false;
    let label = 'Do you have any additional comments to add to this request?'
    const validationRules = [
      ValidationRuleUtil.maxLength(5000)
    ];

    if (validTypes.findIndex(x => x == this.props.changeRequest.changeRequestTypeId) > -1) {
      validationRules.push(ValidationRuleUtil.required());
      required = true;
      label = 'Please summarize the changes you are making and add any additional comments you may have regarding this request.'
    }

    return new Map<string, FormItemProps>()
      .set(ChangeRequestDTO.additionalComments, {
        required: required,
        name: ChangeRequestDTO.additionalComments,
        label: label,
        rules: validationRules,
      })
      .set(ChangeRequestDTO.additionalResources, {
        name: ChangeRequestDTO.additionalResources,
        label: 'Additional Files',
      });
  }

  constructor(props: ChangeRequestAdditionalDetailsFormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      additionalComments: '',
      additionalResources: {} as FileUploadValue
    };
  }

  componentDidMount() {
    if (this.props.changeRequest) {
      this.setState({
        fieldErrors: this.props.fieldErrors,
        submitted: this.props.submitted,
        changeRequest: this.props.changeRequest,
      }, () => this.resetForm());
    }
  }

  componentDidUpdate(prevProps: ChangeRequestAdditionalDetailsFormProps) {
    if (this.props.changeRequest && prevProps.changeRequest != this.props.changeRequest) {
      this.setState({ changeRequest: this.props.changeRequest, fieldErrors: this.props.fieldErrors, submitted: this.props.submitted, });
      this.resetForm();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  public resetForm = () => {
    this.setState({ altered: false });
    this._formRef.current?.resetFields();
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    this.save((result: SaveChangeRequestAdditionalDetailsHandler.Result) => {
      if (result?.succeeded) {
        this.setState({
          changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
        });

        this.resetForm();

        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
      }
      else {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          submitted: true
        });
        message.error('Save Failed');
      }
    }, true);
  }

  private handleSave = () => {
    this.setState({ submitting: true });
    this.save((result: SaveChangeRequestAdditionalDetailsHandler.Result) => {
      if (result?.succeeded) {
        message.success('Saved');

        this.setState({
          changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
          submitted: false,
          error: false
        });

        this.resetForm();

        if (this.props.onSave) {
          this.props.onSave();
        }
      }
      else {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          submitted: false
        });
        message.error('Save Failed');
      }
    }, false);
  }

  private save = (postAction: (result: SaveChangeRequestAdditionalDetailsHandler.Result) => void, finalize: boolean) => {
    this.setState({ saving: true });

    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    model.changeRequestId = this.props.changeRequest.id;
    model.finalize = finalize;
    model.removedFiles = model.additionalResources?.removedFiles;

    const request = FileUploadUtil.attachFilesToModel('saveChangeRequestAdditionalDetails', model, SaveChangeRequestAdditionalDetailsHandler.Request.additionalFiles, model.additionalResources?.newFiles);

    ChangeRequestApiService.saveChangeRequestAdditionalDetails(request)
      .then((result: SaveChangeRequestAdditionalDetailsHandler.Result) => {
        postAction(result);
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public render = () => {
    if (this.state.loading || this.state.changeRequest.id == Guid.Empty()) {
      return <Skeleton active={true} />
    }

    const existingFiles = this.state.changeRequest.additionalResources?.map(x => {
      return {
        uid: x.azureBlobStorageId,
        url: Routes.generateDownload(x.azureBlobStorageId ?? Guid.Empty()),
        name: x.azureBlobFile?.fileName
      }
    })

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.changeRequest ?? ChangeRequestDTO.create()}
          onChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this.getFormItems().get(ChangeRequestDTO.additionalComments)}
            {...ValidationUtil.getValidation(ChangeRequestDTO.additionalComments, this.state.fieldErrors, this.state.submitted)}>
            <RichTextEditor readonly={this.props.readonly} />
          </FormItem>

          <Form.Item
            {...this.getFormItems().get(ChangeRequestDTO.additionalResources)}
            {...ValidationUtil.getValidation(ChangeRequestDTO.additionalResources, this.state.fieldErrors, this.state.submitted)}>
            <FileUpload disabled={this.props.readonly} existingList={existingFiles} />
          </Form.Item>

          {this.renderActions()}
        </Form>
      </Space >
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <GoBackButton onClick={this.props.onPrevious} />
          <SaveAndContinueButton submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={() => this.handleSave()} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  private renderErrors = () => {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default ChangeRequestAdditionalDetailsForm;