import { Card, Space, Tag, Typography } from 'antd';
import * as React from 'react';
import AnnouncementType from '../../consts/AnnouncementType';
import AnnouncementDTO from '../../models/AnnouncementDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import DateTimeUtil from '../../utils/DateTimeUtil';
import RichTextEditor from '../inputs/RichTextEditor';

interface AnnouncementDisplayState extends BaseFormState {
}

interface AnnouncementDisplayProps extends BaseFormProps {
  announcement: AnnouncementDTO;
}

class AnnouncementDisplay extends React.Component<AnnouncementDisplayProps, AnnouncementDisplayState> {

  constructor(props: AnnouncementDisplayProps) {
    super(props);

  }

  render() {
    let cardClass = '';
    switch (this.props.announcement.announcementType?.id) {
      case AnnouncementType.SUCCESS:
        cardClass = 'card-success';
        break;
      case AnnouncementType.INFORMATIONAL:
        cardClass = 'card-informational';
        break;
      case AnnouncementType.DANGER:
        cardClass = 'card-danger';
        break;
      case AnnouncementType.WARNING:
        cardClass = 'card-warning';
        break;
    }

    return (
      <Space direction='vertical'>
        <Card
          className={cardClass}
          key={this.props.announcement.id}
          type="inner"
          title={this.props.announcement.title}
          extra={<Typography.Text >{DateTimeUtil.mediumDate(this.props.announcement.showOnDate)}</Typography.Text>} >
          <RichTextEditor
            value={this.props.announcement.message}
            defaultValue={this.props.announcement.message}
            readonly={true} />
          {this.renderInstitutionTags(this.props.announcement)}
          {this.renderRoleTags(this.props.announcement)}
        </Card >
      </Space>
    );
  }

  renderInstitutionTags(announcement: AnnouncementDTO) {
    return (
      announcement.announcementInstitutions?.map(x => {
        return (<Tag key={x.institutionId}>{x.institution?.abbreviation}</Tag>);
      })
    );
  }

  renderRoleTags(announcement: AnnouncementDTO) {
    return (
      announcement.announcementRoles?.map(x => {
        return (<Tag key={x.roleId}>{x.role?.name}</Tag>);
      })
    );
  }
}

export default AnnouncementDisplay;
