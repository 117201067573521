import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CategoriesDataTable from '../../../../components/datatables/postSecondary/CategoriesDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface CategoryListState {
}

interface CategoryListProps {
}

class CategoryListPage extends React.Component<CategoryListProps & RouteComponentProps<RouteObject>, CategoryListState> {
  constructor(props: CategoryListProps & RouteComponentProps<RouteObject>) {
    super(props);

  }

  goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Categories" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.categoryList()} />

        <Card>
          <CategoriesDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(CategoryListPage);
