// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SecondaryProgramThirdPartyCredentialDTO from './SecondaryProgramThirdPartyCredentialDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramMasterReportDataTableProviderDTO extends EntitySelectorDTO { 
	id: string | null;
	identifier: number;
	title: string | null;
	academicYear: number;
	districtName: string | null;
	districtIdentifier: number;
	regionalPlanningPartner: number;
	aea: string | null;
	cipNumberId: string | null;
	secondaryProgramThirdPartyCredential: SecondaryProgramThirdPartyCredentialDTO[] | null;
	secondaryCipNumber: string | null;
	communityCollege: string | null;
}

const ProgramMasterReportDataTableProviderDTO = {
    className: 'ProgramMasterReportDataTableProviderDTO',
    create: (initValues?: Partial<ProgramMasterReportDataTableProviderDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			identifier: 0,
			title: "",
			academicYear: 0,
			districtName: "",
			districtIdentifier: 0,
			regionalPlanningPartner: 0,
			aea: "",
			cipNumberId: '00000000-0000-0000-0000-000000000000',
			secondaryProgramThirdPartyCredential: [],
			secondaryCipNumber: "",
			communityCollege: "",
        },
        initValues);
    },
	id: 'id',
	identifier: 'identifier',
	title: 'title',
	academicYear: 'academicYear',
	districtName: 'districtName',
	districtIdentifier: 'districtIdentifier',
	regionalPlanningPartner: 'regionalPlanningPartner',
	aea: 'aea',
	cipNumberId: 'cipNumberId',
	secondaryProgramThirdPartyCredential: 'secondaryProgramThirdPartyCredential',
	secondaryCipNumber: 'secondaryCipNumber',
	communityCollege: 'communityCollege',
};

export default ProgramMasterReportDataTableProviderDTO;