import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Input, InputNumber, message, Select, Skeleton, Space, Table, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { Link } from 'react-router-dom';
import NoticeOfIntentChangeRequestApiService from '../../../api/NoticeOfIntentChangeRequestApiService';
import ProgramApiService from '../../../api/ProgramApiService';
import Routes from '../../../config/Routes';
import * as SaveNoticeOfIntentChangeRequestStep7Handler from '../../../handlerModels/SaveNoticeOfIntentChangeRequestStep7Handler';
import * as SearchForProgramsHandler from '../../../handlerModels/SearchForProgramsHandler';
import * as SubmitNoticeOfIntentChangeRequestStep7Handler from '../../../handlerModels/SubmitNoticeOfIntentChangeRequestStep7Handler';
import * as GetNoticeOfIntentChangeRequestStep7Handler from '../../../handlerModels/GetNoticeOfIntentChangeRequestStep7Handler';
import AwardTypeDTO from '../../../models/AwardTypeDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import ExternalInstitutionDTO from '../../../models/ExternalInstitutionDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import NoticeOfIntentChangeRequestCompleterDataDTO from '../../../models/NoticeOfIntentChangeRequestCompleterDataDTO';
import NoticeOfIntentChangeRequestDTO from '../../../models/NoticeOfIntentChangeRequestDTO';
import NoticeOfIntentChangeRequestInstitutionLinkageDTO from '../../../models/NoticeOfIntentChangeRequestInstitutionLinkageDTO';
import ProgramAwardDTO from '../../../models/ProgramAwardDTO';
import ProgramDTO from '../../../models/ProgramDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FileUploadUtil from '../../../utils/FileUploadUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import FileUpload from '../../FileUpload';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import ValueLabel from '../../general/ValueLabel';
import Dropdown from '../../inputs/Dropdown';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NoticeOfIntentChangeRequestStep7FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  noticeOfIntent: NoticeOfIntentChangeRequestDTO;
  institutions: InstitutionDTO[];
  externalInstitutions: ExternalInstitutionDTO[];
  awardTypes: AwardTypeDTO[];
  programs: ProgramDTO[];
}

interface NoticeOfIntentChangeRequestStep7FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  onSave?: () => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  onChange?: (altered: boolean) => void;
  readonly?: boolean;
}

class NoticeOfIntentChangeRequestStep7Form extends React.Component<NoticeOfIntentChangeRequestStep7FormProps, NoticeOfIntentChangeRequestStep7FormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private getFormItems = () => {
    const hasOtherPrograms = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.noticeOfIntentCompleterData).length > 0;

    return new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestDTO.secondaryPathways, {
        name: NoticeOfIntentChangeRequestDTO.secondaryPathways,
        label: 'Please describe any linkages this program will have with any secondary pathways.',
      })
      .set(NoticeOfIntentChangeRequestDTO.postSecondaryPathways, {
        name: NoticeOfIntentChangeRequestDTO.postSecondaryPathways,
        label: 'Please describe any linkages this program will have with any post-secondary pathways.',
      })
      .set(NoticeOfIntentChangeRequestDTO.uniqueProgramFeatures, {
        required: hasOtherPrograms,
        name: NoticeOfIntentChangeRequestDTO.uniqueProgramFeatures,
        label: 'Please describe any unique features this program will offer.',
        rules: hasOtherPrograms ? [ValidationRuleUtil.required()] : []
      })
      .set(NoticeOfIntentChangeRequestDTO.sharedProgramEntities, {
        name: NoticeOfIntentChangeRequestDTO.sharedProgramEntities,
        label: 'Please provide any shared content this program will have with any other colleges.',
      })
      .set(NoticeOfIntentChangeRequestDTO.collaborationEfforts, {
        name: NoticeOfIntentChangeRequestDTO.collaborationEfforts,
        label: 'Describe efforts to collaborate with other postsecondary institutions with similar programs. Provide any correspondence with affected institutions regarding the proposed program and other evidence of collaboration.',
      })
      .set(NoticeOfIntentChangeRequestDTO.statePriority, {
        name: NoticeOfIntentChangeRequestDTO.statePriority,
        label: 'If applicable, describe any special need for this program in the state of Iowa.',
      })
      .set(NoticeOfIntentChangeRequestDTO.programDuplicationAdditionalComments, {
        name: NoticeOfIntentChangeRequestDTO.programDuplicationAdditionalComments,
        label: 'Provide any additional comments regarding possible program duplication (e.g., why the program is unique and justified even though a similar program is offered, why harmful competition does not exist, etc).',
      })
      .set(NoticeOfIntentChangeRequestDTO.institutionSimilarOfferings, {
        name: NoticeOfIntentChangeRequestDTO.institutionSimilarOfferings,
        label: 'List public and private institutions in the state which have similar offerings.',
      })
      .set(NoticeOfIntentChangeRequestDTO.institutionProgramsAtNewLocation, {
        name: NoticeOfIntentChangeRequestDTO.institutionProgramsAtNewLocation,
        label: 'List same or similar programs in new location.',
      })
  }

  private getCompleterDataFormItems = (index: number) => {
    const fields = new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestCompleterDataDTO.institutionId, {
        name: [index, NoticeOfIntentChangeRequestCompleterDataDTO.institutionId]
      })
      .set(NoticeOfIntentChangeRequestCompleterDataDTO.awardTypeId, {
        name: [index, NoticeOfIntentChangeRequestCompleterDataDTO.awardTypeId]
      })
      .set(NoticeOfIntentChangeRequestCompleterDataDTO.completersPerYear, {
        name: [index, NoticeOfIntentChangeRequestCompleterDataDTO.completersPerYear]
      })
      .set(NoticeOfIntentChangeRequestCompleterDataDTO.dataYear, {
        name: [index, NoticeOfIntentChangeRequestCompleterDataDTO.dataYear]
      });
    return fields;
  }

  private getLinkagesDataFormItems = (index: number) => {
    const fields = new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestInstitutionLinkageDTO.externalInstitutionId, {
        label: 'Please select the corresponding external institution.',
        name: [index, NoticeOfIntentChangeRequestInstitutionLinkageDTO.externalInstitutionId],
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestInstitutionLinkageDTO.description, {
        label: 'Please provide a description of the agreement.',
        name: [index, NoticeOfIntentChangeRequestInstitutionLinkageDTO.description],
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestInstitutionLinkageDTO.azureBlobFile, {
        label: 'Please provide the agreement file between the institutions.',
        name: [index, NoticeOfIntentChangeRequestInstitutionLinkageDTO.azureBlobFile],
        required: true,
        rules: [ValidationRuleUtil.required()]
      });
    return fields;
  }

  constructor(props: NoticeOfIntentChangeRequestStep7FormProps) {
    super(props);

    this.state = {
      awardTypes: [],
      changeRequest: ChangeRequestDTO.create(),
      noticeOfIntent: NoticeOfIntentChangeRequestDTO.create(),
      institutions: [],
      externalInstitutions: [],
      programs: []
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: NoticeOfIntentChangeRequestStep7FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.awardTypes.length == 0) {
      loaders.push(this.loadAwardTypes());
    }

    if (this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    if (this.state.externalInstitutions.length == 0) {
      loaders.push(this.loadExternalInstitutions());
    }

    if (!this.state.programs && !this.state.noticeOfIntent) {
      loaders.push(this.SearchForPrograms());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadChangeRequest = () => {
    const request = GetNoticeOfIntentChangeRequestStep7Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NoticeOfIntentChangeRequestApiService.getStep7(request)
      .then((results: GetNoticeOfIntentChangeRequestStep7Handler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            noticeOfIntent: results.changeRequest?.noticeOfIntentChangeRequest ?? NoticeOfIntentChangeRequestDTO.create(),
            fieldErrors: this.props.fieldErrors,
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private SearchForPrograms = () => {
    const request = SearchForProgramsHandler.Request.create({
      cipNumberId: this.state?.noticeOfIntent?.cipNumberId ?? Guid.Empty(),
      awardTypeIds: this.state?.noticeOfIntent?.plannedAwardTypes?.map(x => x.awardTypeId)
    });

    ProgramApiService.searchForPrograms(request)
      .then((results: SearchForProgramsHandler.Result) => {
        if (results.program) {
          this.setState({ programs: results.program ?? [] })
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
  }

  private loadAwardTypes = () => {
    return LookupsUtil.getAll<AwardTypeDTO>(AwardTypeDTO.className)
      .then((results: AwardTypeDTO[]) => {
        if (results) {
          this.setState({ awardTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadExternalInstitutions = () => {
    return LookupsUtil.getAll<ExternalInstitutionDTO>(ExternalInstitutionDTO.className)
      .then((results: ExternalInstitutionDTO[]) => {
        if (results) {
          this.setState({ externalInstitutions: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });

    this._formRef.current?.resetFields();
    if (this.props.onChange) {
      this.props.onChange(false)
    }
  }

  private handleSave = () => {
    const noi = this._formRef.current?.getFieldsValue() ?? null;
    noi.changeRequestId = this.props.changeRequestId;

    const model = SaveNoticeOfIntentChangeRequestStep7Handler.Request.create({
      noticeOfIntentChangeRequest: noi
    })

    const newFiles: any[] = [];

    if (noi.externalInstitutionLinkages) {
      for (let i = 0; i < noi.externalInstitutionLinkages.length; i++) {
        const link = noi.externalInstitutionLinkages[i];
        if (link.azureBlobFile != undefined) {
          const record = link.azureBlobFile?.newFiles;
          if (record != undefined) {
            newFiles.push(record[0]);
          }
        }
      }
    }

    const request = FileUploadUtil.attachFilesToModel('saveNoticeOfIntentChangeRequestStep7', model, SaveNoticeOfIntentChangeRequestStep7Handler.Request.additionalFiles, newFiles);

    NoticeOfIntentChangeRequestApiService.saveStep7(request)
      .then((result: SaveNoticeOfIntentChangeRequestStep7Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false, altered: false });
      });
  }

  private handleSubmit = () => {
    const noi = this._formRef.current?.getFieldsValue() ?? null;
    noi.changeRequestId = this.props.changeRequestId;

    const model = SaveNoticeOfIntentChangeRequestStep7Handler.Request.create({
      noticeOfIntentChangeRequest: noi
    })

    const newFiles: any[] = [];

    if (noi.externalInstitutionLinkages) {
      for (let i = 0; i < noi.externalInstitutionLinkages.length; i++) {
        const link = noi.externalInstitutionLinkages[i];
        if (link.azureBlobFile != undefined) {
          const record = link.azureBlobFile?.newFiles;
          if (record != undefined) {
            newFiles.push(record[0]);
          }
        }
      }
    }

    const request = FileUploadUtil.attachFilesToModel('submitNoticeOfIntentChangeRequestStep7', model, SaveNoticeOfIntentChangeRequestStep7Handler.Request.additionalFiles, newFiles);

    NoticeOfIntentChangeRequestApiService.submitStep7(request)
      .then((result: SubmitNoticeOfIntentChangeRequestStep7Handler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
    if (this.props.onChange) {
      this.props.onChange(true)
    }
  }

  private getColumnDefinitionsForOtherPrograms = (remove: any) => {


    const columns = [
      // {
      //   dataIndex: ProgramDTO.className,
      //   render: (data: string, row: any) => {
      //     const program = (this.state.programs ?? []).find(x => {
      //       if (x.programDetails && x.programDetails.length > 0 && x.programDetails[0].awards) {
      //         return x.institutionId == row.institutionId && x.programDetails[0].awards.findIndex(y => y.awardTypeId == row.awardTypeId) >= 0;
      //       }
      //     }
      //     );
      //     if (program) {
      //       // TODO Link to public program page.
      //       return <Button type='link'>{SvgIcons.Launch()}</Button>
      //     }
      //   }
      // },
      {
        title: 'Community College',
        dataIndex: NoticeOfIntentChangeRequestCompleterDataDTO.institutionId,
        render: (data: string, row: any, index: any) => {
          const program = (this.state.programs ?? []).find(x => {
            if (x.programDetails && x.programDetails.length > 0 && x.programDetails[0].awards) {
              return x.institutionId == row.institutionId && x.programDetails[0].awards.findIndex((y: any) => y.awardTypeId == row.awardTypeId) >= 0;
            }
          }
          );

          if (program) {
            return <FormItem
              {...this.getCompleterDataFormItems(index).get(NoticeOfIntentChangeRequestCompleterDataDTO.institutionId)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestCompleterDataDTO.institutionId + row.name, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
              <ValueLabel text={this.state.institutions.find(x => x.id == program.institutionId)?.name}></ValueLabel>
            </FormItem>
          }
          else {
            return <label title="Community College" htmlFor="Community College" >
              <FormItem
                {...this.getCompleterDataFormItems(index).get(NoticeOfIntentChangeRequestCompleterDataDTO.institutionId)}
                {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestCompleterDataDTO.institutionId + row.name, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >

                <Dropdown
                  disabled={this.props.readonly}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.state.institutions.map(x => this.renderInstitution(x))}
                </Dropdown>
              </FormItem >
            </label>
          }
        },
      },
      {
        title: 'Award Type',
        dataIndex: NoticeOfIntentChangeRequestCompleterDataDTO.awardTypeId,
        render: (data: string, row: any, index: any) => {
          const program = (this.state.programs ?? []).find(x => {
            if (x.programDetails && x.programDetails.length > 0 && x.programDetails[0].awards) {
              return x.institutionId == row.institutionId && x.programDetails[0].awards.findIndex((y: any) => y.awardTypeId == row.awardTypeId) >= 0;
            }
          }
          );

          if (program) {
            let award: ProgramAwardDTO = ProgramAwardDTO.create();
            if (program.programDetails && program.programDetails.length > 0 && program.programDetails[0].awards) {
              award = program.programDetails[0].awards.find((y: any) => y.awardTypeId == row.awardTypeId) ?? ProgramAwardDTO.create()
            }
            if (award) {
              return (
                <label title="Award Type" htmlFor="Award Type" >
                  <FormItem
                    {...this.getCompleterDataFormItems(index).get(NoticeOfIntentChangeRequestCompleterDataDTO.awardTypeId)}
                    {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestCompleterDataDTO.awardTypeId + row.name, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >

                    <ValueLabel text={this.state.awardTypes.find(x => x.id == award.awardTypeId)?.name}></ValueLabel>
                  </FormItem >
                </label>
              );
            }
            else {
              return <label title="Award Type" htmlFor="Award Type" >
                <FormItem
                  {...this.getCompleterDataFormItems(index).get(NoticeOfIntentChangeRequestCompleterDataDTO.awardTypeId)}
                  {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestCompleterDataDTO.awardTypeId + row.name, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
                  <Dropdown
                    disabled={this.props.readonly}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {this.state.awardTypes.map(x => this.renderAwardType(x))}
                  </Dropdown>
                </FormItem>
              </label>
            }
          }
          else {
            return <label title="Award Type" htmlFor="Award Type" >
              <FormItem
                {...this.getCompleterDataFormItems(index).get(NoticeOfIntentChangeRequestCompleterDataDTO.awardTypeId)}
                {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestCompleterDataDTO.awardTypeId + row.name, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >

                <Dropdown
                  disabled={this.props.readonly}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.state.awardTypes.map(x => this.renderAwardType(x))}
                </Dropdown>
              </FormItem >
            </label>
          }
        },
      },
      {
        title: 'State Program Completers',
        dataIndex: NoticeOfIntentChangeRequestCompleterDataDTO.completersPerYear,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getCompleterDataFormItems(index).get(NoticeOfIntentChangeRequestCompleterDataDTO.completersPerYear)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestCompleterDataDTO.completersPerYear + row.name, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <InputNumber title='State Program Completers' disabled={this.props.readonly} />
          </FormItem>;
        },
      },
      {
        title: 'Data Year',
        dataIndex: NoticeOfIntentChangeRequestCompleterDataDTO.dataYear,
        render: (data: string, row: any, index: any) => {
          return (
            <FormItem
              {...this.getCompleterDataFormItems(index).get(NoticeOfIntentChangeRequestCompleterDataDTO.dataYear)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestCompleterDataDTO.dataYear + row.name, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <InputNumber title='Data Year' disabled={this.props.readonly} />
            </FormItem>
          );
        },
      },
      {
        title: 'Actions',
        dataIndex: NoticeOfIntentChangeRequestCompleterDataDTO.className,
        render: (data: string, row: any) => {
          const program = (this.state.programs ?? []).find(x => {
            if (x.programDetails && x.programDetails.length > 0 && x.programDetails[0].awards) {
              return x.institutionId == row.institutionId && x.programDetails[0].awards.findIndex((y: any) => y.awardTypeId == row.awardTypeId) >= 0;
            }
          }
          );

          if (!this.props.readonly && !program) {
            return <Button type="link" icon={<DeleteOutlined />} onClick={() => remove(row.name)} />
          }
        }
      }
    ];

    return columns;
  };

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.noticeOfIntent}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.secondaryPathways)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.secondaryPathways, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.postSecondaryPathways)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.postSecondaryPathways, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.sharedProgramEntities)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.sharedProgramEntities, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

          <Space direction="vertical">
            <Typography.Text>
              Please provide completer information on any other programs offered at other colleges in the state. Refer to the <b>Programs and Awards by CIP</b> file on the <Link target="_blank" to={Routes.generate(Routes.RESOURCES)} >resource page</Link>.
            </Typography.Text>

            {this.renderCompletersData()}

            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestDTO.uniqueProgramFeatures)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.uniqueProgramFeatures, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
            </FormItem>

            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestDTO.collaborationEfforts)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.collaborationEfforts, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
            </FormItem>

            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestDTO.statePriority)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.statePriority, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
            </FormItem>

            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestDTO.programDuplicationAdditionalComments)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.programDuplicationAdditionalComments, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
            </FormItem>

            {this.renderInstitutionLinkages()}

            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestDTO.institutionSimilarOfferings)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.institutionSimilarOfferings, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
            </FormItem>

            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestDTO.institutionProgramsAtNewLocation)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.institutionProgramsAtNewLocation, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
            </FormItem>

            <br />
            {this.renderActions()}
          </Space>
        </Form>
      </Space >
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <GoBackButton onClick={this.props.onPrevious} />
          <SaveAndContinueButton submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  renderCompletersData() {
    return (
      <Form.List name={NoticeOfIntentChangeRequestDTO.noticeOfIntentCompleterData}>
        {
          (completers, { add, remove }) => {
            return (
              <Space direction='vertical'>
                <Table pagination={false} dataSource={completers} columns={this.getColumnDefinitionsForOtherPrograms(remove)} />
                {this.renderAddCompleterData(add)}
              </Space>);
          }
        }
      </Form.List>
    )
  }

  renderAddCompleterData(add: any) {
    if (!this.props.readonly) {
      return <Button onClick={() => add()} icon={<PlusOutlined />}>
        Add Completer Data
      </Button>
    }
  }

  renderInstitutionLinkages() {
    return (
      <Space direction='vertical'>
        <Typography.Paragraph >
          If there are any linkages to external institutions, please add them here.
        </Typography.Paragraph>
        <Form.List name={NoticeOfIntentChangeRequestDTO.externalInstitutionLinkages}>
          {
            (links, { add, remove }) => {
              return (
                <Space direction='vertical'>
                  {
                    links.map((link, index) => {
                      if (link) {
                        return this.renderInstitutionLinkage(link, index, remove);
                      }
                    })
                  }
                  {this.renderAdditionalLinkage(add)}
                </Space>
              );
            }
          }
        </Form.List>
      </Space>
    );
  }

  renderAdditionalLinkage(add: any) {
    if (!this.props.readonly) {
      return (
        <Button onClick={() => add()} icon={<PlusOutlined />}>
          Add External Institution Linkage
        </Button>
      );
    }
  }

  renderInstitutionLinkage(link: any, linkIndex: number, remove: any) {
    const multipleLinkages = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.externalInstitutionLinkages)?.length > 1
    const title = 'External Institution Linkage ' + (multipleLinkages ? (linkIndex + 1) : '');
    const deleteButton = !this.props.readonly ?
      <Button type="link" size="small" onClick={() => remove(link.name)} icon={<DeleteOutlined />} /> :
      null;

    const existingFiles: any[] | undefined = [];
    if (this.state.noticeOfIntent?.externalInstitutionLinkages) {
      const externalLink = this.state.noticeOfIntent?.externalInstitutionLinkages[linkIndex];
      if (externalLink != undefined) {
        existingFiles.push({
          uid: externalLink.azureBlobFileId,
          url: Routes.generateDownload(externalLink.azureBlobFileId ?? Guid.Empty()),
          name: externalLink.azureBlobFile?.fileName
        });
      }
    }

    return (
      <Card type='inner' size='small' title={title} extra={deleteButton}>
        <Space direction="vertical" >

          <FormItem
            {...this.getLinkagesDataFormItems(linkIndex).get(NoticeOfIntentChangeRequestInstitutionLinkageDTO.externalInstitutionId)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestInstitutionLinkageDTO.externalInstitutionId + linkIndex, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Dropdown
              disabled={this.props.readonly}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {this.state.externalInstitutions.map(x => this.renderExternalInstitution(x))}
            </Dropdown>
          </FormItem>

          <FormItem
            {...this.getLinkagesDataFormItems(linkIndex).get(NoticeOfIntentChangeRequestInstitutionLinkageDTO.description)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestInstitutionLinkageDTO.description + linkIndex, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

          <FormItem
            {...this.getLinkagesDataFormItems(linkIndex).get(NoticeOfIntentChangeRequestInstitutionLinkageDTO.azureBlobFile)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestInstitutionLinkageDTO.azureBlobFile + linkIndex, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <FileUpload existingList={existingFiles} maxCount={1} disabled={this.props.readonly} />
          </FormItem>

        </Space >
      </Card >
    )
  }


  renderInstitution(institution: InstitutionDTO) {
    if (institution.id) {
      return <Select.Option title={institution.name ?? ''} key={institution.id ?? Guid.Empty()} value={institution.id ?? Guid.Empty()}>{institution.name}</Select.Option>
    }
  }

  renderExternalInstitution(institution: ExternalInstitutionDTO) {
    if (institution.id) {
      return <Select.Option title={institution.name ?? ''} key={institution.id ?? Guid.Empty()} value={institution.id ?? Guid.Empty()}>{institution.name}</Select.Option>
    }
  }

  renderAwardType(awardType: AwardTypeDTO) {
    if (awardType.id) {
      const title = awardType.code + ' - ' + awardType.name;
      return <Select.Option title={title} key={awardType.id ?? Guid.Empty()} value={awardType.id ?? Guid.Empty()}>{title}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NoticeOfIntentChangeRequestStep7Form
