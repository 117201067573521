import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import * as React from 'react';
import ResourceApiService from '../../../api/ResourceApiService';
import * as RemoveResourceLinkHandler from '../../../handlerModels/RemoveResourceLinkHandler';
import ResourceLinkDTO from '../../../models/ResourceLinkDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import Guid from '../../../utils/Guid';
import AddResourceLinkForm from '../../forms/postSecondary/AddResourceLinkForm';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { Link } from 'react-router-dom';
import LaunchOutlined from '../../../icons/LaunchOutlined';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';

interface ResourceLinkDataTableProps {
  triggerUpdate?: () => void;
}

interface ResourceLinkDataTableState extends BaseFormState, BaseDataTableState<ResourceLinkDTO> {
  showModal: boolean;
  resourceLink: ResourceLinkDTO;
  resourceCategories: TableFilterOption[];
}

class ResourceLinkDataTable extends React.Component<ResourceLinkDataTableProps, ResourceLinkDataTableState> {
  private _dataTable: DataTable<ResourceLinkDTO> | undefined;
  private readonly _addResourceLinkFormRef = React.createRef<AddResourceLinkForm>();

  constructor(props: ResourceLinkDataTableProps) {
    super(props);
    this.state = ({
      showModal: false,
      resourceLink: ResourceLinkDTO.create(),
      resourceCategories: []
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    TableFilterUtil.ResourceCategories().then(x => this.setState({ resourceCategories: x }));
  }

  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Title',
        dataIndex: ResourceLinkDTO.title,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'URL',
        dataIndex: ResourceLinkDTO.url,
        sorter: true,
        filterType: FilterType.Text,
        render: (data: string, row: ResourceLinkDTO) => {
          return (
            <Link
              to={{ pathname: row.url ?? '' }}
              target='_blank'
              rel="noopener noreferrer">
              {row.url ?? ''} <LaunchOutlined />
            </Link>
          );
        }
      },
      {
        title: 'Category',
        dataIndex: ResourceLinkDTO.categoryTitle,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.resourceCategories
      },
      {
        title: '',
        dataIndex: ResourceLinkDTO.id,
        width: '25px',
        render: (data: string, row: ResourceLinkDTO) => {
          return <>
            {<Button type="link" className="ant-btn" onClick={() => this.addResource(row)}><EditOutlined /></Button>}
            {<Button type="link" className="ant-btn" onClick={() => this.promptConfirmRemove(row.id ?? Guid.Empty())}><DeleteOutlined /></Button>}
          </>
        },
        sorter: false,
        filterType: FilterType.NONE
      }
    ] as DataTableColumnProps<any>[];
  };

  private handleRemoveResource = (resourceLinkId: string | null) => {
    this.setState({ submitting: true });

    const request = RemoveResourceLinkHandler.Request.create({
      resourceLinkId: resourceLinkId ?? Guid.Empty()
    });

    ResourceApiService.removeResourceLink(request)
      .then((result: RemoveResourceLinkHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Resource was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Resource could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.triggerRefresh();
      });
  }

  private promptConfirmRemove = (resourceLinkId: string | null) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this resource?',
      okText: 'Remove Resource',
      onOk: () => this.handleRemoveResource(resourceLinkId)
    });
  }

  private addResource = (record: ResourceLinkDTO) => {
    this.setState({ resourceLink: record }, () => this.openModal());
  }

  private openModal = () => {
    this.setState({ showModal: true });
  }

  private submitResourceChange = () => {
    this._addResourceLinkFormRef.current?.submit();
  }

  private cancelResourceChange = () => {
    this.setState({ showModal: false });
    this._addResourceLinkFormRef.current?.reset();
  }

  private resourcesUpdated = () => {
    this.setState({ showModal: false });
    this._dataTable?.refresh();
  }

  render() {
    return (
      <div>
        {this.renderAddResourceModal()}
        {this.renderDataTable()}
      </div>
    );
  }

  renderAddResourceModal() {
    return (
      <Modal
        visible={this.state.showModal}
        title='Resource Link'
        okText='Save'
        onOk={this.submitResourceChange}
        onCancel={this.cancelResourceChange}>
        <AddResourceLinkForm
          ref={this._addResourceLinkFormRef}
          onSuccess={this.resourcesUpdated}
          resourceLink={this.state.resourceLink} />
      </Modal>
    );
  }

  renderDataTable() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Default('Add Resource', () => this.addResource(ResourceLinkDTO.create())));

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => ResourceApiService.getResourceLinkTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'resource_link_list' }} />
    );
  }

}

export default ResourceLinkDataTable;
