// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ContactDTO from '../models/ContactDTO';
import * as RemoveContactHandler from '../handlerModels/RemoveContactHandler';
import * as GetContactsByInstitutionHandler from '../handlerModels/GetContactsByInstitutionHandler';
import * as SaveContactDetailHandler from '../handlerModels/SaveContactDetailHandler';

export class ContactApiService extends BaseApi {

    // post: api/contacts?institutionId=${encodeURIComponent(institutionId)}
    public getContactTableData(tableRequest: TableRequestDTO, institutionId: string): Promise<TableResponseDTO<ContactDTO>> {
        let url = `api/contacts`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ContactDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/contacts/removeContact
    public removeContact(removeContact: RemoveContactHandler.Request): Promise<RemoveContactHandler.Result> {
        let url = `api/contacts/removeContact`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveContactHandler.Result>(removeContact, 'post', url, true, false);
    }

    // post: api/contacts/getContactByInstitutionId
    public getContactByInstitionId(getContactsByInstitution: GetContactsByInstitutionHandler.Request): Promise<GetContactsByInstitutionHandler.Result> {
        let url = `api/contacts/getContactByInstitutionId`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetContactsByInstitutionHandler.Result>(getContactsByInstitution, 'post', url, true, false);
    }

    // post: api/contacts/save
    public saveContactDetails(saveContactDetail: FormData): Promise<SaveContactDetailHandler.Result> {
        let url = `api/contacts/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveContactDetailHandler.Result>(saveContactDetail, 'post', url, false, false);
    }
}
var service = new ContactApiService();
export default service;
