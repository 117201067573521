import { Card, Skeleton, Space } from 'antd';
import * as React from 'react';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import ChangeRequestAdditionalDetailsForm from '../../forms/postSecondary/ChangeRequestAdditionalDetailsForm';
import NewCourseChangeRequestStep1Form from '../../forms/postSecondary/NewCourseChangeRequestStep1Form';

interface NewCourseChangeRequestDetailsState extends BaseFormState {
}

interface NewCourseChangeRequestDetailsProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequest: ChangeRequestDTO;
}

class NewCourseChangeRequestDetails extends React.Component<NewCourseChangeRequestDetailsProps, NewCourseChangeRequestDetailsState> {

  constructor(props: NewCourseChangeRequestDetailsProps) {
    super(props);

    this.state = {};
  }

  public render = () => {
    if (this.props.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space direction="vertical">
        <Card type="inner" size="small" title="Course Details" >
          <NewCourseChangeRequestStep1Form readonly={true} selectedInstitution={this.props.changeRequest.institutionId} loading={this.props.loading} changeRequestId={this.props.changeRequest.id} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card type="inner" size="small" title="Additional Details" >
          <ChangeRequestAdditionalDetailsForm readonly={true} changeRequest={this.props.changeRequest} loading={this.props.loading} />
        </Card>
      </Space>
    );
  }
}

export default NewCourseChangeRequestDetails;