import { PageHeader, PageHeaderProps } from 'antd';
import Breadcrumb, { BreadcrumbProps, Route } from 'antd/lib/breadcrumb/Breadcrumb';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import PageTitle from '../components/shared/PageTitle';
import HistoryUtil from '../utils/HistoryUtil';

interface HeaderPortalProps extends PageHeaderProps {
  pageTitle?: string;
  breadcrumbs?: Route[];
  preserveLastBreadcrumb?: boolean;
  showBack?: boolean;
  loading?: boolean;
}

interface HeaderPortalState { }

class HeaderPortal extends React.Component<HeaderPortalProps, HeaderPortalState> {
  portalRoot: any;
  el: HTMLDivElement;
  constructor(props: HeaderPortalProps) {
    super(props);
    this.el = document.createElement('div');

    this.state = {};
  }

  componentDidMount = () => {
    this.portalRoot = document.getElementById('headerPortal');
    if (this.portalRoot) {
      this.portalRoot.appendChild(this.el);
    }
  };

  componentWillUnmount = () => {
    if (this.portalRoot) {
      this.portalRoot.removeChild(this.el);
    }
  };

  render() {
    const props = this.props;

    let breadcrumbProps = (props.breadcrumb ? props.breadcrumb : undefined) as any; // as BreadcrumbProps | React.ReactElement<typeof Breadcrumb> | undefined;

    if (props.breadcrumbs) {
      const routes = this.props.breadcrumbs;
      if (routes && routes.length > 0) {
        breadcrumbProps = {
          routes: routes,
          itemRender: this.itemRender,
          separator: '|'
        } as BreadcrumbProps | React.ReactElement<typeof Breadcrumb>;
      }
    }

    const onBack = props.onBack ?
      props.onBack :
      props.showBack ? () => { HistoryUtil.goBack() } : undefined;

    const content = (
      <React.Fragment>
        {this.renderPageTitle()}
        <PageHeader
          {...props}
          onBack={onBack}
          breadcrumb={{ ...breadcrumbProps }}>
          {this.props.children}
        </PageHeader>
      </React.Fragment>
    );

    return ReactDOM.createPortal(content, this.el);
  }

  itemRender(route: Route) {
    return <Link to={route.path}>{route.breadcrumbName}</Link>;
  }

  renderPageTitle() {
    if (this.props.pageTitle || this.props.title) {
      const pageTitle = this.props.pageTitle ? this.props.pageTitle : this.props.title?.toString() ?? '';
      return <PageTitle title={pageTitle} />
    }

    return null;
  }
}

export default HeaderPortal;
