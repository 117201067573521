import { BackTop, Col, ConfigProvider, Layout, Row, Space } from 'antd';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AccountApiService from '../api/AccountApiService';
import AuthorizedContent from '../components/AuthorizedContent';
import AutoLogout from '../components/AutoLogout';
import SticsHeader from '../components/layoutItems/SticsHeader';
import SticsSider from '../components/layoutItems/SticsSider';
import { FullColumnWidths } from '../config/ColumnWidths';
import UserSecurity from '../models/UserSecurity';
import { UserSessionActions } from '../redux/actions/UserSessionActions';
import { StateStoreModel } from '../redux/state/StateStoreModel';
import RegionalPlanningPartnerDTO from '../models/RegionalPlanningPartnerDTO';
import DistrictDTO from '../models/DistrictDTO';

interface AuthenticatedLayoutState {
  collapsed: boolean;
}

interface AuthenticatedLayoutProps {
  logoutAction: () => void;
  width?: string;
  breadcrumbs: Route[];
  organization: any;
  selectedDistrict: DistrictDTO | null;
  selectedRPP: RegionalPlanningPartnerDTO | null;
  userInfo: UserSecurity | null;
}

class AuthenticatedLayout extends React.Component<AuthenticatedLayoutProps & RouteComponentProps<Record<string, string | undefined>>, AuthenticatedLayoutState> {
  constructor(props: AuthenticatedLayoutProps & RouteComponentProps<Record<string, string | undefined>>) {
    super(props);

    this.onCollapse = this.onCollapse.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);

    this.state = {
      collapsed: false,
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  toggleMenu() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  onCollapse(collapsed: boolean) {
    this.setState({ collapsed });
  }

  handlePing() {
    AccountApiService.ping();
  }

  render() {
    const idleTime = $idleTimer;
    return (
      <ConfigProvider componentSize="middle" >
        <Layout style={{ minHeight: '100vh' }}>
          <AutoLogout idleMinutes={idleTime} warnMinutes={1} onActive={this.handlePing} />
          <SticsHeader toggleMenu={this.toggleMenu} collapsed={this.state.collapsed} />
          <Layout>
            <Layout>
              <AuthorizedContent>
                <SticsSider 
                collapseMenu={this.toggleMenu} 
                collapsed={this.state.collapsed} 
                institution={this.props.organization} 
                selectedDistrict={this.props.selectedDistrict} 
                selectedRPP={this.props.selectedRPP}
                userInfo={this.props.userInfo} />
              </AuthorizedContent>
              {this.renderContent()}
            </Layout>
          </Layout>
          <BackTop />
        </Layout>
      </ConfigProvider>
    )
  }

  renderContent(): React.ReactNode {
    let columnProps = {};
    let className = '';

    switch (this.props.width) {
      case 'full':
        columnProps = FullColumnWidths.FULL;
        className = 'width-full';
        break;
      case 'tiny':
        columnProps = FullColumnWidths.TINY;
        className = 'width-tiny';
        break;
      case 'small':
        columnProps = FullColumnWidths.SMALL;
        className = 'width-small';
        break;
      case 'smallish':
        columnProps = FullColumnWidths.SMALLISH;
        className = 'width-small';
        break;
      case 'medium':
        columnProps = FullColumnWidths.MEDIUM;
        className = 'width-medium';
        break;
      case 'extraWide':
        columnProps = FullColumnWidths.EXTRA_WIDE;
        className = 'width-extraWide';
        break;
      case 'extraExtraWide':
        columnProps = FullColumnWidths.EXTRA_EXTRA_WIDE;
        className = 'width-extraWide';
        break;
      case 'wide':
      case 'default':
      default:
        columnProps = FullColumnWidths.WIDE;
        className = 'width-wide';
        break;
    }

    return (
      <Layout>
        <Content>
          <Space direction="vertical" size="small">
            <Row>
              <div id="headerPortal" className="breadcrumb-area" />
            </Row>
            <Row>
              <Col {...columnProps} className={className} >
                {this.props.children}
              </Col>
            </Row>
          </Space>
        </Content>
      </Layout>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    organization: state.Organization.Selected,
    selectedDistrict: state.District.Selected,
    selectedRPP: state.RegionalPlanningPartner.Selected,
    userInfo: state.UserSession.Value
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    logoutAction: () => dispatch(UserSessionActions.logout()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedLayout));
