// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionPresidentDTO extends EntitySelectorDTO { 
	id: string | null;
	instituionId: string | null;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	phone: string | null;
}

const InstitutionPresidentDTO = {
    className: 'InstitutionPresidentDTO',
    create: (initValues?: Partial<InstitutionPresidentDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			instituionId: '00000000-0000-0000-0000-000000000000',
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
        },
        initValues);
    },
	id: 'id',
	instituionId: 'instituionId',
	firstName: 'firstName',
	lastName: 'lastName',
	email: 'email',
	phone: 'phone',
};

export default InstitutionPresidentDTO;