import { UserSwitchOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, FormItemProps, message, Modal } from 'antd';
import * as React from 'react';
import ChangeRequestApprovalApiService from '../../../api/ChangeRequestApprovalApiService';
import ApprovalStatusType from '../../../consts/ApprovalStatusType';
import ChangeRequestStatusType from '../../../consts/ChangeRequestStatusType';
import FeatureFlag from '../../../consts/FeatureFlag';
import ChangeRequestApprovalDTO from '../../../models/ChangeRequestApprovalDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserDTO from '../../../models/UserDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ChangeRequestApprovalReviewForm from '../../forms/postSecondary/ChangeRequestApprovalReviewForm';
import * as SaveChangeRequestApprovalUserHandler from '../../../handlerModels/SaveChangeRequestApprovalUserHandler';
import UserSearchInput from '../../general/UserSearchInput';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';


interface ChangeRequestApprovalDataTableProps {
  changeRequestId?: string;
  approvalId?: string;
  changeRequest: ChangeRequestDTO;
  institution?: InstitutionDTO | null;
  approvalSubmitted?: (changeRequestId: string) => void
  disableActions: boolean;
}

interface ChangeRequestApprovalDataTableState extends BaseDataTableState<ChangeRequestApprovalDTO>, BaseFormState {
  addNewChangeRequest: boolean;
  roles: TableFilterOption[];
  changeRequestApproval: ChangeRequestApprovalDTO;
  approvalStatus: TableFilterOption[];
  selectedApprovalId?: string;
  showModal: boolean;
  showSwitchUserModal: boolean;
  selectedUser: UserDTO | null;
}

class ChangeRequestApprovalDataTable extends React.Component<ChangeRequestApprovalDataTableProps, ChangeRequestApprovalDataTableState> {
  private _dataTable: DataTable<ChangeRequestApprovalDTO> | undefined;
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: ChangeRequestApprovalDataTableProps) {
    super(props);

    this.state = {
      addNewChangeRequest: false,
      roles: [],
      changeRequestApproval: ChangeRequestApprovalDTO.create(),
      approvalStatus: [],
      showModal: false,
      showSwitchUserModal: false,
      selectedUser: null,
      selectedApprovalId: Guid.Empty()
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private _formItems = new Map<string, FormItemProps>()
    .set('user', {
      label: 'Please Select a User',
      required: true,
      name: 'user',
    });

  private fetchData = () => {
    TableFilterUtil.Roles()
      .then((x: TableFilterOption[]) => {
        this.setState({
          roles: x ?? []
        });
      });

    TableFilterUtil.ApprovalStatus()
      .then((x: TableFilterOption[]) => {
        this.setState({
          approvalStatus: x ?? []
        });
      });
  }

  private getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Step',
        dataIndex: ChangeRequestApprovalDTO.stepNumber,
        filterType: FilterType.DropdownMulti,
        sorter: true,
        width: 10,
      },
      {
        title: 'Role',
        dataIndex: ChangeRequestApprovalDTO.roleId,
        render: (data: string, row: ChangeRequestApprovalDTO) => {
          return this.state.roles.find(x => x.value == row.roleId)?.text;
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.roles,
        sorter: true,
        width: 50,
      },
      {
        title: 'User',
        dataIndex: ChangeRequestApprovalDTO.userId,
        render: (data: string, row: ChangeRequestApprovalDTO) => {
          const action = AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_APPROVAL_USER]) ?
            <Button title='Switch Approval User' onClick={() => this.openAddUserModal(row.user, row.id ?? Guid.Empty())} type='link'><UserSwitchOutlined /></Button> :
            null;
          return <>{row.user?.display} {action}</>;
        },
        sorter: true,
        filterType: FilterType.Text,
        width: 100,
      },

    ] as DataTableColumnProps<any>[];

    columns.push({
      title: 'Status',
      dataIndex: ChangeRequestApprovalDTO.approvalStatusTypeId,
      render: (data: string, row: ChangeRequestApprovalDTO) => {
        return this.state.approvalStatus.find(x => x.value == row.approvalStatusTypeId)?.text;
      },
      sorter: true,
      filterType: FilterType.DropdownMulti,
      dropdownFilterOptions: this.state.approvalStatus,
      width: 100,
    });

    columns.push({
      title: 'Reviewed',
      dataIndex: ChangeRequestApprovalDTO.reviewed,
      render: (data: string, row: ChangeRequestApprovalDTO) => {
        return DateTimeUtil.shortDateAndTime(row.reviewed);
      },
      width: 100,
    });

    const approved = this.props.changeRequest.statusTypeId === ChangeRequestStatusType.APPROVED;
    const isExpired = this.props.changeRequest.isExpired
    const nonApprovedOrRejected = this.props.changeRequest.changeRequestApprovals?.findIndex(x => x.approvalStatusTypeId != ApprovalStatusType.APPROVED && x.approvalStatusTypeId != ApprovalStatusType.DECLINED) ?? -1;
    if (!this.props.disableActions && !approved && !isExpired && nonApprovedOrRejected > -1 && AuthorizationUtil.isAuthorized([FeatureFlag.OVERRIDE_APPROVAL_USER])) {
      columns.push({
        dataIndex: ChangeRequestApprovalDTO.className,
        render: (data: string, row: ChangeRequestApprovalDTO) => {
          if (row?.approvalStatusTypeId == ApprovalStatusType.PENDING) {
            return <Button key={row.id} onClick={() => this.openModal(row.id ?? Guid.Empty())}>Approve/Reject</Button>;
          }
        },
        width: 125,
      });
    }

    return columns;
  }

  private openAddUserModal = (user: UserDTO | null, approvalId: string) => {
    this.setState({ showSwitchUserModal: true, selectedUser: user, selectedApprovalId: approvalId });
  }

  private closeAddUserModal = () => {
    this.setState({ showSwitchUserModal: false, selectedUser: null, selectedApprovalId: undefined });
  }

  private modalSubmit = () => {
    const model = this._formRef.current?.getFieldsValue();

    if (model.user != undefined && model.user != null) {
      const request = SaveChangeRequestApprovalUserHandler.Request.create({
        userId: model.user.id,
        changeRequestApprovalId: this.state.selectedApprovalId
      });

      ChangeRequestApprovalApiService.saveChangeRequestApprovalUser(request)
        .then((result: SaveChangeRequestApprovalUserHandler.Result) => {
          if (result?.succeeded) {
            message.success('User Added Successfully!');
            this.closeAddUserModal();

          }
          else {
            message.error('Error Adding User');
          }
        })
        .catch(() => {
          message.error('Error Adding User');
        });
    }
    else {
      message.error('Error Adding User');
    }
  }

  private renderAddReponseUser = () => {
    return (
      <Modal
        visible={this.state.showSwitchUserModal}
        title={'Switch Approval User'}
        onOk={this.modalSubmit}
        onCancel={this.closeAddUserModal}
        footer={[
          <Button key='back' onClick={this.closeAddUserModal}>
            Close
          </Button>,
          <Button key='submit' type='primary'
            onClick={this.modalSubmit}
          >
            Select
          </Button>
        ]}>
        <Form
          ref={this._formRef}
          layout="vertical"
          requiredMark={true}>
          <Form.Item
            {...this._formItems.get('user')}
            {...ValidationUtil.getValidation('user', this.state.fieldErrors, this.state.submitted)}
          >
            <UserSearchInput defaultValue={this.state.selectedUser ?? UserDTO.create()} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  private openModal = (approvalId: string) => {
    this.setState({ showModal: true, selectedApprovalId: approvalId });
  }

  private handleApprovalSubmitted = () => {
    this.setState({ showModal: false, selectedApprovalId: undefined });
    if (this.props.approvalSubmitted) {
      this.props.approvalSubmitted(this.props.changeRequestId ?? Guid.Empty());
    }

    this._dataTable?.refresh();
  }

  renderApproveRejectModal() {
    return (
      <Modal
        visible={this.state.showModal}
        title='Approve/Reject'
        footer={null}
        onCancel={this.handleApprovalSubmitted}
      >
        <ChangeRequestApprovalReviewForm
          changeRequest={this.props.changeRequest}
          approvalId={this.state.selectedApprovalId}
          onSubmit={this.handleApprovalSubmitted} />
      </Modal>
    );
  }
  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <div>
        {this.renderAddReponseUser()}
        {this.renderApproveRejectModal()}
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          stateSaving={{ enabled: true, tableUniqueKey: 'changeRequest_approvals_list' }}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) =>
              ChangeRequestApprovalApiService.getChangeRequestApprovalTableData(requestState, this.props.changeRequestId ?? Guid.Empty())
          }}
        />
      </div>
    );
  }
}

export default ChangeRequestApprovalDataTable;
