// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import TermDTO from './TermDTO';
import ProgramTermCourseGroupDTO from './ProgramTermCourseGroupDTO';
import FlatProgramTermCourseGroupOptionDTO from './FlatProgramTermCourseGroupOptionDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramTermDTO extends EntitySelectorDTO { 
	id: string | null | null;
	order: number;
	prerequisite: boolean;
	programAwardId: string | null;
	informationalContent: string | null;
	termId: string | null | null;
	term: TermDTO | null;
	groups: ProgramTermCourseGroupDTO[] | null;
	flatCourseGroupOptions: FlatProgramTermCourseGroupOptionDTO[] | null;
}

const ProgramTermDTO = {
    className: 'ProgramTermDTO',
    create: (initValues?: Partial<ProgramTermDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			order: 0,
			prerequisite: false,
			programAwardId: '00000000-0000-0000-0000-000000000000',
			informationalContent: "",
			termId: null,
			term: null,
			groups: [],
			flatCourseGroupOptions: [],
        },
        initValues);
    },
	id: 'id',
	order: 'order',
	prerequisite: 'prerequisite',
	programAwardId: 'programAwardId',
	informationalContent: 'informationalContent',
	termId: 'termId',
	term: 'term',
	groups: 'groups',
	flatCourseGroupOptions: 'flatCourseGroupOptions',
};

export default ProgramTermDTO;