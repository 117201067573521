import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import TypeOfProgramApiService from '../../../api/TypeOfProgramApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetTypeOfProgramDetailsHandler from '../../../handlerModels/GetTypeOfProgramDetailsHandler';
import * as SaveTypeOfProgramDetailsHandler from '../../../handlerModels/SaveTypeOfProgramDetailsHandler';
import TypeOfProgramDTO from '../../../models/TypeOfProgramDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';

interface TypeOfProgramDetailsFormState extends BaseFormState {
  typeOfProgramId: number;
  typeOfProgram: TypeOfProgramDTO | null;
}

interface TypeOfProgramDetailsFormProps extends BaseFormProps {
  typeOfProgramId: number;
  onSave?: (typeOfProgramId: number) => void;
}

class TypeOfProgramDetailsForm extends React.Component<TypeOfProgramDetailsFormProps, TypeOfProgramDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(TypeOfProgramDTO.name, {
      required: true,
      name: TypeOfProgramDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    })
    .set(TypeOfProgramDTO.code, {
      required: true,
      name: TypeOfProgramDTO.code,
      label: 'Code',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(10)
      ],
    })
    .set(TypeOfProgramDTO.apsGeneralEducation, {
      name: TypeOfProgramDTO.apsGeneralEducation,
      label: 'APS General Education',
    });


  constructor(props: TypeOfProgramDetailsFormProps) {
    super(props);

    this.state = {
      typeOfProgramId: 0,
      typeOfProgram: TypeOfProgramDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadTypeOfProgram();
  }

  componentDidUpdate(prevProps: TypeOfProgramDetailsFormProps) {
    if (this.props.typeOfProgramId && this.props.typeOfProgramId != prevProps.typeOfProgramId) {
      this.setState({ typeOfProgramId: this.props.typeOfProgramId });
      this.loadTypeOfProgram();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadTypeOfProgram() {
    if (this.props.typeOfProgramId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.typeOfProgramId) {
      TypeOfProgramApiService.getTypeOfProgramDetails(this.props.typeOfProgramId)
        .then((results: GetTypeOfProgramDetailsHandler.Result) => {
          if (results.typeOfProgram) {
            this.setState({ typeOfProgramId: results.typeOfProgram.id, typeOfProgram: results.typeOfProgram, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ typeOfProgram: TypeOfProgramDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveTypeOfProgramDetailsHandler.Request.create({
      typeOfProgramId: this.state.typeOfProgramId,
      typeOfProgram: TypeOfProgramDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    TypeOfProgramApiService.saveTypeOfProgramDetails(request)
      .then((result: SaveTypeOfProgramDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          typeOfProgram: result?.typeOfProgram,
        });

        if (result?.succeeded && result.typeOfProgram) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave(result.typeOfProgram.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(TypeOfProgramDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Type Of Program could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Type Of Program could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(TypeOfProgramDTO.code)}
            initialValue={this.state.typeOfProgram?.code}
            {...ValidationUtil.getValidation(TypeOfProgramDTO.code, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(TypeOfProgramDTO.name)}
            initialValue={this.state.typeOfProgram?.name}
            {...ValidationUtil.getValidation(TypeOfProgramDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(TypeOfProgramDTO.apsGeneralEducation)}
            initialValue={this.state.typeOfProgram?.apsGeneralEducation}
            {...ValidationUtil.getValidation(TypeOfProgramDTO.apsGeneralEducation, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TYPE_OF_PROGRAM]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default TypeOfProgramDetailsForm;
