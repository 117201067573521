import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { default as InstituionsApiService } from '../../../api/InstitutionsApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import InstitutionLocationDataTable from '../../../components/datatables/postSecondary/InstitutionLocationDataTable';
import InstitutionCreditTypeMinuteForm from '../../../components/forms/postSecondary/InstitutionCreditTypeMinuteForm';
import InstitutionDetailsForm from '../../../components/forms/postSecondary/InstitutionDetailsForm';
import InstitutionPresidentDetailsForm from '../../../components/forms/postSecondary/InstitutionPresidentDetailsForm';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteInstitutionHandler from '../../../handlerModels/DeleteInstitutionHandler';
import * as GetInstitutionDetailsHandler from '../../../handlerModels/GetInstitutionDetailsHandler';
import InstitutionDTO from '../../../models/InstitutionDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ParameterUtil from '../../../utils/ParameterUtil';

interface InstitutionDetailsState extends BaseFormState {
  institutionId: string;
  institution?: InstitutionDTO;
}

interface InstitutionDetailsProps {
  institution: InstitutionDTO | null;
}

class InstitutionDetailsPage extends React.Component<InstitutionDetailsProps & RouteComponentProps<RouteObject>, InstitutionDetailsState> {
  private static readonly INSTITUTION_DETIALS = 'INSTITUTION_DETIALS';
  private static readonly INSTITUTION_MINUTES = 'INSTITUTION_MINUTES';
  private static readonly INSTITUTION_PRESIDENT = 'INSTITUTION_PRESIDENT';

  private readonly _institutionDetailsRef = React.createRef<InstitutionDetailsForm>();
  private readonly _institutionlocationDataTableRef = React.createRef<InstitutionLocationDataTable>();
  private readonly _institutionPresidentFormRef = React.createRef<InstitutionPresidentDetailsForm>();
  private readonly _institutionCreditTypeMinuteFormeRef = React.createRef<InstitutionCreditTypeMinuteForm>();

  constructor(props: InstitutionDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      institutionId: Guid.Empty(),
      editings: new Map<string, boolean>()
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id == 'new') {
      this.setState({ isEditing: true })
      this.toggleIsEditing(InstitutionDetailsPage.INSTITUTION_DETIALS);
    }
    else {
      this.loadInstitution(id);
    }
  }

  private loadInstitution = (id: string) => {
    this.setState({ loading: true });
    InstituionsApiService.getInstitutionDetails(id)
      .then((results: GetInstitutionDetailsHandler.Result) => {
        if (results.institution) {
          this.setState({
            institutionId: id,
            institution: results.institution
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleIsEditing = (key: string, value?: boolean) => {
    if (this.state.editings) {
      const editings = this.state.editings;

      if (value !== undefined) {
        editings.set(key, value);
      }
      else {
        editings.set(key, !editings.get(key) ?? true);
      }

      if (!editings.get(key)) {
        switch (key) {
          case InstitutionDetailsPage.INSTITUTION_DETIALS:
            this._institutionDetailsRef.current?.resetForm();
            break;
          case InstitutionDetailsPage.INSTITUTION_MINUTES:
            this._institutionCreditTypeMinuteFormeRef.current?.resetForm();
            break;
          case InstitutionDetailsPage.INSTITUTION_PRESIDENT:
            this._institutionPresidentFormRef.current?.reset();
            break;
        }
      }

      this.setState({ editings: editings });
    }
  }

  private instituitionDetailsSaved = (id: string) => {
    this.setState({ isEditing: false });
    if (id != this.state.institutionId) {
      HistoryUtil.replace(Routes.generate(Routes.INSTITUTION_DETAILS, { id: id }));
    }
    else {
      this.loadInstitution(id);
    }
  }

  private deleteConfirmation = (id: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Institution?',
      okText: 'Delete',
      onOk: () => this.deleteInstitution(id)
    });
  }

  private deleteInstitution = (institutionId: string) => {
    this.setState({ submitting: true });

    const request = DeleteInstitutionHandler.Request.create({
      institutionId: institutionId,
    });

    InstituionsApiService.deleteInstitution(request)
      .then((result: DeleteInstitutionHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Institution was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Institution could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToInstitutions();
      });
  }

  private institutionLocationAdded = () => {
    this._institutionlocationDataTableRef?.current?.triggerRefresh();
  }

  private institutionCreditTypeMinuteUpdated = () => {
    this.toggleIsEditing(InstitutionDetailsPage.INSTITUTION_MINUTES);
  }

  private institutionPresidentUpdated = () => {
    this.toggleIsEditing(InstitutionDetailsPage.INSTITUTION_PRESIDENT);
  }

  private goToInstitutions = () => {
    HistoryUtil.push(Routes.generate(Routes.INSTITUTIONS))
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const abbreviation = this.state.loading ? '...' : this.state.institutionId == Guid.Empty() ? 'New Institution' : this.state.institution?.abbreviation ?? '...';
    const name = this.state.loading ? '...' : this.state.institutionId == Guid.Empty() ? 'New Institution' : this.state.institution?.name ?? '...';

    let breadcrumbs = Breadcrumbs.institutionDetails(abbreviation, this.state.institutionId)
    let goBack = undefined;

    if (this.props.institution != null && this.props.institution != undefined) {
      breadcrumbs = Breadcrumbs.singleInstitutionDetails(abbreviation, this.state.institutionId);
      goBack = undefined;
    }
    else {
      goBack = this.goToInstitutions;
    }

    return (
      <Content >
        <HeaderPortal
          title={name}
          pageTitle={abbreviation}
          onBack={goBack}
          showBack={this.props.institution == null || this.props.institution == undefined}
          breadcrumbs={breadcrumbs}
          loading={this.state.loading}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        <Space direction="vertical" size="middle">
          {this.renderDetailsCard()}
          {this.renderInstitutionCreditTypeMinutesCard()}
          {this.renderInstitutionLocationsCard()}
          {/* {this.renderPresident()} */}
        </Space>
      </Content>
    );
  }

  renderDetailsCard() {
    if (this.state.editings) {
      const editButton = this.state.institutionId == Guid.Empty() ?
        null :
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTITUTION]}>
          <EditCancelButton onClick={() => this.toggleIsEditing(InstitutionDetailsPage.INSTITUTION_DETIALS)} isEditing={this.state.editings.get(InstitutionDetailsPage.INSTITUTION_DETIALS)} />
        </AuthorizedContent>

      return (
        <Card title="Details" extra={editButton} >
          <InstitutionDetailsForm
            ref={this._institutionDetailsRef}
            institution={this.state.institution ?? InstitutionDTO.create()}
            isEditing={this.state.editings.get(InstitutionDetailsPage.INSTITUTION_DETIALS)}
            onSave={this.instituitionDetailsSaved} />
        </Card>
      );
    }
  }

  renderInstitutionCreditTypeMinutesCard() {
    if (this.state.editings) {
      const editButton = this.state.institutionId == Guid.Empty() ?
        null :
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTITUTION_CREDIT_TYPE_MINUTE]}>
          <EditCancelButton onClick={() => this.toggleIsEditing(InstitutionDetailsPage.INSTITUTION_MINUTES)} isEditing={this.state.editings.get(InstitutionDetailsPage.INSTITUTION_MINUTES)} />
        </AuthorizedContent>

      if (this.state.institutionId != Guid.Empty() && AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_INSTITUTION_CREDIT_TYPE_MINUTE])) {
        return (
          <Card title={'Minutes Per Credit Breakdowns'} extra={editButton} >
            <Space direction='vertical' >
              <InstitutionCreditTypeMinuteForm ref={this._institutionCreditTypeMinuteFormeRef}
                isEditing={this.state.editings.get(InstitutionDetailsPage.INSTITUTION_MINUTES)}
                onSave={this.institutionCreditTypeMinuteUpdated}
                institutionId={this.state.institutionId} />
            </Space>
          </Card>
        );
      }
    }
  }

  renderInstitutionLocationsCard() {
    if (this.state.institutionId != Guid.Empty() && AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_INSTITUTION_LOCATION])) {
      return (
        <Card title={'Locations'}>
          <Space direction='vertical' >
            <InstitutionLocationDataTable ref={this._institutionlocationDataTableRef}
              triggerUpdate={this.institutionLocationAdded}
              institutionId={this.state.institutionId} />
          </Space>
        </Card>
      );
    }
  }

  renderPresident() {
    if (this.state.editings) {
      const editButton = this.state.institutionId == Guid.Empty() ?
        null :
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTITUTION_PRESIDENT]}>
          <EditCancelButton onClick={() => this.toggleIsEditing(InstitutionDetailsPage.INSTITUTION_PRESIDENT)} isEditing={this.state.editings.get(InstitutionDetailsPage.INSTITUTION_PRESIDENT)} />
        </AuthorizedContent>

      if (this.state.institutionId != Guid.Empty() && AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_INSTITUTION_PRESIDENT])) {
        return (
          <Card title={'President'} extra={editButton} >
            <Space direction='vertical' >
              <InstitutionPresidentDetailsForm ref={this._institutionPresidentFormRef}
                isEditing={this.state.editings.get(InstitutionDetailsPage.INSTITUTION_PRESIDENT)}
                onSuccess={this.institutionPresidentUpdated}
                institutionId={this.state.institutionId} />
            </Space>
          </Card>
        );
      }
    }
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Institution' key={this.state.institutionId} onClick={() => this.deleteConfirmation(this.state.institutionId)}  >
          Delete Institution
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTITUTION]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(InstitutionDetailsPage));
