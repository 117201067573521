import * as React from 'react';
import CipsApiService from '../../api/CipsApiService';
import * as SearchForCipHandler from '../../handlerModels/SearchForCipHandler';
import * as GetCipDetailsHandler from '../../handlerModels/GetCipDetailsHandler';
import CipNumberDetailDTO from '../../models/CipNumberDetailDTO';
import Guid from '../../utils/Guid';
import AutoCompleteInput, { AutoCompleteInputOption } from './AutoCompleteInput';

interface CipNumberInputProps {
  value?: string | null;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
}

function CipNumberInput(props: React.PropsWithChildren<CipNumberInputProps>) {

  const [options, setOptions] = React.useState([] as AutoCompleteInputOption[]);
  const [cipNumbers, setCipNumbers] = React.useState([] as CipNumberDetailDTO[]);
  const [selectedCip, setSelectedCip] = React.useState(props.value as CipNumberDetailDTO | null);
  const [searching, setSearching] = React.useState(false);

  const handleSearch = (value: string) => {
    if (value.length > 2) {
      const request = SearchForCipHandler.Request.create(
        {
          searchString: value
        });
      setSearching(true);

      CipsApiService.searchForCip(request)
        .then((x: SearchForCipHandler.Result) => {
          const options = x.cips?.map(x => ({
            value: x.cipNumberId,
            display: x.friendlyDisplay
          } as AutoCompleteInputOption)) as AutoCompleteInputOption[];

          setCipNumbers(x.cips ?? []);
          setOptions(options);
          setSearching(false);
        });
    }
  }

  const handleChange = (value: string) => {
    if (Guid.IsGuid(value)) {
      const selected = cipNumbers.find(x => x.cipNumberId == value) ?? null;
      setSelectedCip(selected);
      if (props.onChange) {
        props.onChange(value);
      }
    }
    else if (value == null || value == undefined) {
      if (props.onChange) {
        props.onChange(null);
      }
    }
  }

  const handleClear = () => {
    aciRef.current?.clear();
    setSelectedCip(null);
  }

  const aciRef = React.createRef<AutoCompleteInput>();

  if (props.value != null && props.value != undefined && selectedCip == null) {
    setSearching(true);
    CipsApiService.getCipDetails(props.value)
      .then((x: GetCipDetailsHandler.Result) => {
        const newOptions = options;
        if (newOptions.filter(x => x.value == props.value).length === 0) {
          newOptions.push({
            value: x.cip?.id,
            display: x.cip?.currentDetail?.friendlyDisplay
          })
        }

        setSelectedCip(x.cip?.currentDetail ?? null);
        setOptions(newOptions);
        setSearching(false);
      });
  }

  return (
    <AutoCompleteInput ref={aciRef} disabled={props.disabled} value={selectedCip} options={options} onSearch={handleSearch} onChange={handleChange} allowClear={true} onClear={handleClear} searching={searching} />
  );
}
export default CipNumberInput;
