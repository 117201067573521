import { Select, Skeleton, Table, Tooltip } from 'antd';
import Form, { FormInstance } from 'antd/lib/form/Form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useEffect, useImperativeHandle, useState } from 'react';
import TransferMajorDisciplineFrameworkElementCourseApiService from '../api/TransferMajorDisciplineFrameworkElementCourseApiService';
import * as GetTransferMajorDisciplineFrameworkElementCoursesHandler from '../handlerModels/GetTransferMajorDisciplineFrameworkElementCoursesHandler';
import InstitutionCourseDetailDTO from '../models/InstitutionCourseDetailDTO';
import TransferMajorDisciplineFrameworkElementCourseDTO from '../models/TransferMajorDisciplineFrameworkElementCourseDTO';
import TransferMajorDisciplineFrameworkElementDTO from '../models/TransferMajorDisciplineFrameworkElementDTO';
import YearTermDTO from '../models/YearTermDTO';
import BaseFormProps from '../redux/bases/BaseFormProps';
import Guid from '../utils/Guid';
import LookupsUtil from '../utils/LookupsUtil';
import ValidationRuleUtil from '../utils/ValidationRuleUtil';
import ValidationUtil from '../utils/ValidationUtil';
import Dropdown from './inputs/Dropdown';

interface DisciplineFrameworkCourseValue {
  institutionCourseId?: string | null;
  institutionCourseDetail?: InstitutionCourseDetailDTO | null;
  transferMajorDisciplineFrameworkElementId?: number;
}

interface DisciplineFrameworkCourseSearchProps extends BaseFormProps {
  institutionId?: string;
  transferMajorDisciplineFrameworkElementId?: number;
  existingCourseId?: string;
  installTerm?: YearTermDTO;
  onSave?: (secondaryProgramId: string) => void;
}

const DisciplineFrameworkCourseSearch = React.forwardRef((props: DisciplineFrameworkCourseSearchProps, ref) => {
  const _formRef = React.createRef<FormInstance>();
  const getComponentFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(TransferMajorDisciplineFrameworkElementDTO.id, {
        required: true,
        name: TransferMajorDisciplineFrameworkElementDTO.id,
        label: 'Discipline Framework Elements',
        rules: [ValidationRuleUtil.required()]
      })
      .set(TransferMajorDisciplineFrameworkElementCourseDTO.courseId, {
        required: true,
        name: TransferMajorDisciplineFrameworkElementCourseDTO.courseId,
        label: 'Courses',
        rules: [ValidationRuleUtil.required()]
      });
  }

  const [loading, setLoading] = useState(false);
  const [fieldErrors] = useState({} as any);
  const [value, setValue] = useState({
    institutionCourseId: props?.existingCourseId ?? Guid.Empty(),
    transferMajorDisciplineFrameworkElementId: props.transferMajorDisciplineFrameworkElementId
  } as DisciplineFrameworkCourseValue);
  const [existingCourseId, setExisitngCourseId] = useState(props.existingCourseId);
  const [disciplineFrameworkElement, setDisciplineFrameworkElement] = useState({} as TransferMajorDisciplineFrameworkElementDTO);
  const [disciplineFrameworkElements, setDisciplineFrameworkElements] = useState([] as TransferMajorDisciplineFrameworkElementDTO[])
  const [disciplineFrameworkElementCoruses, setDisciplineFrameworkElementCourses] = useState([] as TransferMajorDisciplineFrameworkElementCourseDTO[])
  const [submitted] = useState(false);

  useImperativeHandle(ref, () => ({
    getValue: () => getValue(),
    validateFields: () => validateFields()
  }));

  const getValue = () => {
    return value;
  }

  const validateFields = () => {
    return _formRef.current?.validateFields();
  }

  useEffect(() => {
    setLoading(true);
    setExisitngCourseId(props.existingCourseId);
    const loaders = [];
    if (!disciplineFrameworkElements || disciplineFrameworkElements.length == 0) {
      loaders.push(loadTransferMajorDisciplineFrameworkElement());
    }
    if (props.transferMajorDisciplineFrameworkElementId) {
      loaders.push(loadTransferMajorDisciplineFrameworkElementCourses(props.transferMajorDisciplineFrameworkElementId));
    }

    Promise.all(loaders).then(() => {
      setLoading(false);
    });
  }, []);

  const loadTransferMajorDisciplineFrameworkElement = () => {
    return LookupsUtil.getAll<TransferMajorDisciplineFrameworkElementDTO>(TransferMajorDisciplineFrameworkElementDTO.className)
      .then((results: TransferMajorDisciplineFrameworkElementDTO[]) => {
        if (results) {
          setDisciplineFrameworkElements(results ?? []);
        }
      });
  }

  const loadTransferMajorDisciplineFrameworkElementCourses = (id: number) => {
    const request = GetTransferMajorDisciplineFrameworkElementCoursesHandler.Request.create({
      transferMajorDisciplineFrameworkId: id,
      instutionId: props.institutionId,
      installTerm: props.installTerm
    });

    return TransferMajorDisciplineFrameworkElementCourseApiService.getTransferMajorDisciplineFrameworkElementCourses(request)
      .then((results: GetTransferMajorDisciplineFrameworkElementCoursesHandler.Result) => {
        if (results) {
          setDisciplineFrameworkElementCourses(results.transferMajorDisciplineFrameworkElementCourses ?? []);
        }
      });
  }

  const renderDisciplineFrameworkOptions = () => {
    return disciplineFrameworkElements.map(x => { return renderDisciplineFrameworkOption(x) });
  }

  const renderDisciplineFrameworkOption = (element: TransferMajorDisciplineFrameworkElementDTO) => {
    if (element.id) {
      return <Select.Option key={element.id} value={element.id} >{element.name}</Select.Option>;
    }
  }

  const onSelect = (e: any) => {
    setExisitngCourseId(undefined);
    loadTransferMajorDisciplineFrameworkElementCourses(e);
    setDisciplineFrameworkElement(disciplineFrameworkElements.find(x => x.id == e) ?? TransferMajorDisciplineFrameworkElementDTO.create());
  }

  const handleSelected = (selectedRows: TransferMajorDisciplineFrameworkElementCourseDTO[]) => {
    const courseDetail = selectedRows[0].course?.institutionCourses?.find(x => x.institutionId == props.institutionId)?.currentDetail;
    setExisitngCourseId(courseDetail?.courseId ?? Guid.Empty());
    setValue({
      institutionCourseId: courseDetail?.institutionCourseId ?? Guid.Empty(),
      institutionCourseDetail: courseDetail ?? null,
      transferMajorDisciplineFrameworkElementId: disciplineFrameworkElement.id
    });
  }

  if (loading) {
    return <Skeleton active />
  }
  else {
    return (
      <Form
        ref={_formRef}
        layout="vertical"
        initialValues={{ id: props.transferMajorDisciplineFrameworkElementId }}
        requiredMark={true}>
        <FormItem
          {...getComponentFormItems().get(TransferMajorDisciplineFrameworkElementDTO.id)}
          {...ValidationUtil.getValidation(TransferMajorDisciplineFrameworkElementDTO.id, fieldErrors, submitted)} >
          <Dropdown
            onSelect={onSelect}
            dropdownMatchSelectWidth={false}
            allowClear={true}
            showSearch
            optionFilterProp="children" >
            {renderDisciplineFrameworkOptions()}
          </Dropdown>
        </FormItem>

        <Table dataSource={disciplineFrameworkElementCoruses}
          pagination={false}
          rowKey={InstitutionCourseDetailDTO.courseId}
          rowSelection={{
            columnTitle: 'Select',
            columnWidth: 100,
            type: 'radio',
            onChange: (selectedRowKeys: React.Key[], selectedRows: TransferMajorDisciplineFrameworkElementCourseDTO[]) => {
              handleSelected(selectedRows);
            },
            defaultSelectedRowKeys: [existingCourseId ?? Guid.Empty()],
            selectedRowKeys: [existingCourseId ?? Guid.Empty()]
          }} >
          <Table.Column
            title='Discipline'
            dataIndex={InstitutionCourseDetailDTO.discipline}
            width={125}
            render={
              (value: any, record: TransferMajorDisciplineFrameworkElementCourseDTO) => {
                return (
                  <Tooltip title={record.course?.discipline?.name} placement='right'>
                    {record.course?.discipline?.code}
                  </Tooltip>
                );
              }} />
          <Table.Column title='Catalog Number' dataIndex={InstitutionCourseDetailDTO.catalogNumber} width={125}
            render={
              (value: any, record: TransferMajorDisciplineFrameworkElementCourseDTO) => {
                return (
                  record.course?.catalogNumber
                );
              }} />
          <Table.Column title='Title' dataIndex={InstitutionCourseDetailDTO.title}
            render={
              (value: any, record: TransferMajorDisciplineFrameworkElementCourseDTO) => {
                return (
                  record.course?.institutionCourses?.find(x => x.institutionId == props.institutionId)?.currentDetail?.title
                );
              }} />
        </Table>
      </Form>
    );
  }
})

DisciplineFrameworkCourseSearch.displayName = 'DisciplineFrameworkCourseSearch';

export default DisciplineFrameworkCourseSearch;