import moment from 'moment';
import zxcvbn from 'zxcvbn';
import ValidationStatus from '../consts/ValidationStatus';
import TermDTO from '../models/TermDTO';
import ValidationResult from '../models/Validator';
import YearTermDTO from '../models/YearTermDTO';
import Guid from './Guid';

export default class ValidationUtil {
  static getValidation(
    key: string,
    fieldErrors: { [key: string]: ValidationResult[]; } | null | undefined,
    showValidation: boolean | undefined) {
    if (showValidation && fieldErrors && fieldErrors[key]?.length > 0) {
      let status = ValidationStatus.NONE;
      const message = [] as string[];

      fieldErrors[key].forEach(function (value) {
        if (value.status === ValidationStatus.ERROR) {
          status = ValidationStatus.ERROR;
        }
        else if (status != ValidationStatus.ERROR && value.status == ValidationStatus.WARNING) {
          status = ValidationStatus.WARNING;
        }
        else if (status != ValidationStatus.ERROR && status != ValidationStatus.WARNING && value.status == ValidationStatus.VALIDATING) {
          status = ValidationStatus.VALIDATING;
        }
        else if (status != ValidationStatus.ERROR && status != ValidationStatus.WARNING && status != ValidationStatus.VALIDATING && value.status == ValidationStatus.SUCCESS) {
          status = ValidationStatus.SUCCESS;
        }

        if (value.message) {
          message.push(value.message);
        }
      });

      const returnValue = {
        hasFeedback: showValidation,
        validateStatus: getValidateStatus(status),
        help: message.join('\n'),
        messages: message
      }

      return returnValue;
    }

    return null;
  }

  public static passwordStrengthValidator(rule: any, value: string, callback: any) {
    if (value === null || value === undefined || value === '') {
      callback('error');
    }

    const strength = zxcvbn(value).score;
    if (strength >= $zxcvbnMin) {
      callback();
    }

    callback('error');
  }

  public static passwordsMatchValidator(password: string) {
    return (rule: any, value: string) => {
      if (!value || password === value) {
        return Promise.resolve();
      }

      return Promise.reject('Passwords do not match.');
    }
  }

  public static termInputYearValidator(terms: TermDTO[], otherYearTerm: YearTermDTO, installOrRetire: string) {
    return (rule: any, value: any) => {
      if (value && otherYearTerm && otherYearTerm.year) {
        const currentTermMoment = moment(terms.find(x => x.id == value.termId)?.startDate);
        const otherYearTermMoment = moment(terms.find(x => x.id == otherYearTerm.termId)?.startDate);
        const currentMonth = (currentTermMoment.month() + 2) > 12 ? currentTermMoment.month() + 2 - 12 : currentTermMoment.month() + 2;
        const otherTermMonth = (otherYearTermMoment.month() + 2) > 12 ? otherYearTermMoment.month() + 2 - 12 : otherYearTermMoment.month() + 2;;

        if (installOrRetire == 'retire' && value.year != 0 && value.year != null && value.year < otherYearTerm.year) {
          return Promise.reject('Retire year cannot be less than Install year.');
        }
        else if (installOrRetire == 'install' && value.year > otherYearTerm.year) {
          return Promise.reject('Install year cannot be greater than Retire year.');
        }
        if (value.year != 0 &&
          value.year == otherYearTerm.year) {
          if (installOrRetire == 'retire' &&
            (currentTermMoment.isSame(otherYearTermMoment) ||
              currentMonth < otherTermMonth)) {
            return Promise.reject('Term cannot be the same or before install term.');
          }
          else if (installOrRetire == 'install' &&
            (currentTermMoment.isSame(otherYearTermMoment) ||
              currentMonth > otherTermMonth)) {
            return Promise.reject('Term cannot be the same or after retire term.');
          }
        }
      }

      if (!value || value == Guid.Empty()) {
        return Promise.reject('Value is Required');
      }

      return Promise.resolve();
    }
  }

  public static termAndYearValidatorNullable() {
    return (rule: any, value: any) => {
      if (!value || value != Guid.Empty()) {
        return Promise.resolve();
      }
      if (value) {
        if ((!value.year && value.termId != Guid.Empty())) {
          return Promise.reject('Enter a Year.');
        }
        else if ((value.year && (value.termId == '' || value.termId == Guid.Empty()))) {
          return Promise.reject('Select a Term.');
        }
      }
    }
  }

  public static termAndYearValidator() {
    return (rule: any, value: any) => {
      if (value) {
        if ((!value.year && value.termId != Guid.Empty())) {
          return Promise.reject('Enter a Year.');
        }
        else if ((value.year && (value.termId == '' || value.termId == Guid.Empty()))) {
          return Promise.reject('Select a Term.');
        }
      }
      if (!value || value != Guid.Empty()) {
        return Promise.resolve();
      }
    }
  }

  public static minMaxValueValidator() {
    return (rule: any, value: any) => {
      if (value) {
        if ((value.min == undefined || Number.isNaN(value.min)) && (value.max == undefined || Number.isNaN(value.max))) {
          return Promise.resolve();
        }
        else if ((value.max == undefined || Number.isNaN(value.max)) && !Number.isNaN(value.min)) {
          return Promise.reject('Enter Maximum Value.');
        }
        else if ((value.min == undefined || Number.isNaN(value.min)) && !Number.isNaN(value.max)) {
          return Promise.reject('Enter Minimum Value.');
        }
      }

      return Promise.resolve();
    }
  }

  public static valueExistsValidator(fields: Map<string, any>) {
    return () => {
      let isEntry = false;
      fields.forEach(x => {
        if (x != undefined) {
          isEntry = true;
        }
      });

      if (isEntry) {
        return Promise.resolve();
      }

      return Promise.reject('Atleast one field or field group needs a value.');
    }
  }
}

function getValidateStatus(status: ValidationStatus): '' | 'success' | 'warning' | 'error' | 'validating' | undefined {
  switch (status) {
    case ValidationStatus.SUCCESS:
      return 'success';
    case ValidationStatus.WARNING:
      return 'warning';
    case ValidationStatus.ERROR:
      return 'error';
    case ValidationStatus.VALIDATING:
      return 'validating';
    case ValidationStatus.NONE:
    default:
      return '';
  }
}
