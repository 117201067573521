// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SecondaryProgramScenarioDTO from '../models/SecondaryProgramScenarioDTO';
import * as GetSecondaryProgramScenarioDetailsHandler from '../handlerModels/GetSecondaryProgramScenarioDetailsHandler';
import * as SaveSecondaryProgramScenarioDetailsHandler from '../handlerModels/SaveSecondaryProgramScenarioDetailsHandler';
import * as DeleteSecondaryProgramScenarioHandler from '../handlerModels/DeleteSecondaryProgramScenarioHandler';
import * as GetSecondaryProgramScenariosHandler from '../handlerModels/GetSecondaryProgramScenariosHandler';

export class SecondaryProgramScenarioApiService extends BaseApi {

    // post: api/SecondaryProgramScenarios?academicYearId=${academicYearId}
    public getSecondaryProgramScenarioTableData(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<SecondaryProgramScenarioDTO>> {
        let url = `api/SecondaryProgramScenarios`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SecondaryProgramScenarioDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/SecondaryProgramScenarios/get
    public getSecondaryProgramScenarioDetails(getSecondaryProgramScenarioDetails: GetSecondaryProgramScenarioDetailsHandler.Request): Promise<GetSecondaryProgramScenarioDetailsHandler.Result> {
        let url = `api/SecondaryProgramScenarios/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryProgramScenarioDetailsHandler.Result>(getSecondaryProgramScenarioDetails, 'post', url, true, false);
    }

    // post: api/SecondaryProgramScenarios/save
    public saveSecondaryProgramScenarioDetails(saveSecondaryProgramScenarioDetails: SaveSecondaryProgramScenarioDetailsHandler.Request): Promise<SaveSecondaryProgramScenarioDetailsHandler.Result> {
        let url = `api/SecondaryProgramScenarios/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSecondaryProgramScenarioDetailsHandler.Result>(saveSecondaryProgramScenarioDetails, 'post', url, true, false);
    }

    // post: api/SecondaryProgramScenarios/deleteSecondaryProgramScenario
    public deleteSecondaryProgramScenario(deleteSecondaryProgramScenario: DeleteSecondaryProgramScenarioHandler.Request): Promise<DeleteSecondaryProgramScenarioHandler.Result> {
        let url = `api/SecondaryProgramScenarios/deleteSecondaryProgramScenario`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteSecondaryProgramScenarioHandler.Result>(deleteSecondaryProgramScenario, 'post', url, true, false);
    }

    // post: api/SecondaryProgramScenarios/SecondaryProgramScenarios
    public getSecondaryProgramScenarios(getSecondaryProgramScenarios: GetSecondaryProgramScenariosHandler.Request): Promise<GetSecondaryProgramScenariosHandler.Result> {
        let url = `api/SecondaryProgramScenarios/SecondaryProgramScenarios`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryProgramScenariosHandler.Result>(getSecondaryProgramScenarios, 'post', url, true, false);
    }
}
var service = new SecondaryProgramScenarioApiService();
export default service;
