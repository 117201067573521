import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DisciplineDataTable from '../../../../components/datatables/postSecondary/DisciplinesDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface DisciplineListProps {
}

interface DisciplineListState {
}

class DisciplineListPage extends React.Component<DisciplineListProps & RouteComponentProps<RouteObject>, DisciplineListState> {
  constructor(props: DisciplineListProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      disciplineTableData: [],
      loading: false,
      exporting: false,
      statuses: []
    };
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Disciplines" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.disciplineList()} />
        <Card>
          <DisciplineDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(DisciplineListPage);
