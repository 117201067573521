import Guid from '../utils/Guid';

declare global {
  export interface String {
    isNotEmpty(): boolean;
    isEmpty(): boolean;
  }
}

String.prototype.isNotEmpty = () => {
  return this != Guid.Empty() && this != '';
};

String.prototype.isEmpty = () => {
  return this == Guid.Empty() || this == '';
};
