// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ResourceLinkDTO from '../models/ResourceLinkDTO';
import ResourceFileDTO from '../models/ResourceFileDTO';
import * as SaveResourceLinkDetailHandler from '../handlerModels/SaveResourceLinkDetailHandler';
import * as SaveResourceFileDetailHandler from '../handlerModels/SaveResourceFileDetailHandler';
import * as SearchForResourceCategoryHandler from '../handlerModels/SearchForResourceCategoryHandler';
import * as RemoveResourceLinkHandler from '../handlerModels/RemoveResourceLinkHandler';
import * as RemoveResourceFileHandler from '../handlerModels/RemoveResourceFileHandler';
import * as GetResourcesHandler from '../handlerModels/GetResourcesHandler';

export class ResourceApiService extends BaseApi {

    // post: api/resources/getResourceLinkTableData
    public getResourceLinkTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ResourceLinkDTO>> {
        let url = `api/resources/getResourceLinkTableData`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ResourceLinkDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/resources/getResourceFileTableData
    public getResourceFileTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ResourceFileDTO>> {
        let url = `api/resources/getResourceFileTableData`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ResourceFileDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/resources/saveResourceLink
    public saveResourceLink(saveResourceLinkDetail: SaveResourceLinkDetailHandler.Request): Promise<SaveResourceLinkDetailHandler.Result> {
        let url = `api/resources/saveResourceLink`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveResourceLinkDetailHandler.Result>(saveResourceLinkDetail, 'post', url, true, false);
    }

    // post: api/resources/saveResourceFile
    public saveResourceFile(saveResourceFileDetail: FormData): Promise<SaveResourceFileDetailHandler.Result> {
        let url = `api/resources/saveResourceFile`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveResourceFileDetailHandler.Result>(saveResourceFileDetail, 'post', url, false, false);
    }

    // post: api/resources/searchForResourceCategory?searchText=${encodeURIComponent(searchText)}
    public searchForResourceCategory(searchText: string): Promise<SearchForResourceCategoryHandler.Result> {
        let url = `api/resources/searchForResourceCategory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (searchText != null) {
            url += `${prefix}searchText=${encodeURIComponent(searchText)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForResourceCategoryHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/resources/removeResourceLink
    public removeResourceLink(removeResourceLink: RemoveResourceLinkHandler.Request): Promise<RemoveResourceLinkHandler.Result> {
        let url = `api/resources/removeResourceLink`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveResourceLinkHandler.Result>(removeResourceLink, 'post', url, true, false);
    }

    // post: api/resources/removeResourceFile
    public removeResourceFile(removeResourceFile: RemoveResourceFileHandler.Request): Promise<RemoveResourceFileHandler.Result> {
        let url = `api/resources/removeResourceFile`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveResourceFileHandler.Result>(removeResourceFile, 'post', url, true, false);
    }

    // post: api/resources/getResources
    public getResources(getResources: GetResourcesHandler.Request): Promise<GetResourcesHandler.Result> {
        let url = `api/resources/getResources`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetResourcesHandler.Result>(getResources, 'post', url, true, false);
    }
}
var service = new ResourceApiService();
export default service;
