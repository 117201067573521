import { Alert, Button, Input, message, Result, Space, Spin, Typography } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';
import Form, { FormInstance } from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import PasswordTips from '../../components/PasswordTips';
import Routes from '../../config/Routes';
import * as ResetPasswordHandler from '../../handlerModels/ResetPasswordHandler';
import * as ValidateResetPasswordTokenHandler from '../../handlerModels/ValidateResetPasswordTokenHandler';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import FormUtil from '../../utils/FormUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import SubmitButton from '../buttons/SubmitButton';

interface ResetPasswordFormState extends BaseFormState {
  linkVerified: boolean;
  resetSuccess: boolean;
}

interface ResetPasswordFormProps extends BaseFormProps {
  code?: string;
  userId: string;
  onSave?: () => void;
}

class ResetPasswordForm extends React.Component<ResetPasswordFormProps, ResetPasswordFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFields = () => {
    const fields = new Map<string, FormItemProps>()
      .set(ResetPasswordHandler.Request.password, {
        required: true,
        name: ResetPasswordHandler.Request.password,
        label: 'New Password',
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(150),
        ],
      })
      .set(ResetPasswordHandler.Request.confirmPassword, {
        required: true,
        name: ResetPasswordHandler.Request.confirmPassword,
        label: 'Confirm New Password',
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(150),
          ValidationRuleUtil.fieldsMatchValidator(this._formRef, ResetPasswordHandler.Request.password)
        ],
      });

    if (!this.props.code) {
      fields
        .set(ResetPasswordHandler.Request.currentPassword, {
          required: true,
          name: ResetPasswordHandler.Request.currentPassword,
          label: 'Current Password',
          rules: [
            ValidationRuleUtil.required(),
            ValidationRuleUtil.maxLength(150),
          ],
        })
    }

    return fields;
  }

  constructor(props: ResetPasswordFormProps) {
    super(props);

    this.state = {
      linkVerified: false,
      resetSuccess: false,
    };
  }

  componentDidUpdate(prevProps: ResetPasswordFormProps) {
    if (this.props.userId && this.props.code && this.props.userId != prevProps.userId && this.props.code != prevProps.code) {
      const loaders = [];

      if (this.props.code && this.props.userId) {
        loaders.push(this.validateToken());
      }

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  public submit = () => {
    this.handleSubmit();
  }

  public reset = () => {
    this._formRef.current?.resetFields();
    this.setState({
      altered: false,
      submitted: false,
    })
  }

  private validateToken = () => {
    const request = ValidateResetPasswordTokenHandler.Request.create({
      code: this.props.code,
      userId: this.props.userId,
    });

    return AccountApiService.validateResetPasswordToken(request)
      .then((result: ValidateResetPasswordTokenHandler.Result) => {
        if (result.succeeded) {
          this.setState({
            linkVerified: true,
          })
        }
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    if (FormUtil.canSubmit(this._formRef, this.getFields())) {
      this.setState({ submitting: true });
      const formData = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;

      const request = ResetPasswordHandler.Request.create({
        ...formData,
        code: this.props.code,
        userId: this.props.userId,
      });

      AccountApiService.resetPassword(request)
        .then((results: ResetPasswordHandler.Result) => {
          if (results.succeeded) {
            message.success('Password Updated');
            this.reset();
            if (this.props.onSave) {
              this.props.onSave();
              this._formRef.current?.resetFields();
            }

            this.setState({
              fieldErrors: null,
              errors: null,
              submitted: true,
              error: false
            })
          } else {
            message.error('Not Updated');
            this.setState({
              fieldErrors: results.fieldErrors,
              errors: results.errors,
              submitted: true,
              error: true
            })
          }

          this.setState({ resetSuccess: true });
        })
        .catch((error) => {
          this.setState({ error: error, resetSuccess: false });
        })
        .finally(() => {
          this.setState({ loading: false, submitting: false });
        });
    }
  }

  render() {
    if (!this.state.linkVerified && this.props.code) {
      return this.renderChecking();
    }
    else if (!this.state.error && this.state.resetSuccess && this.props.code) {
      return this.renderSuccss();
    }
    else if (this.state.linkVerified || this.props.code == undefined) {
      return this.renderForm();
    }

    return this.renderChecking();
  }

  renderSuccss() {
    return (
      <Result
        status="success"
        title="Password Reset"
        subTitle="Your password has successfully been reset. Please login to continue."
        extra={
          <Link to={Routes.generate(Routes.LOGIN)}>
            <Button size="large" disabled={this.state.loading} >
              Go to Login
            </Button>
          </Link>
        } />
    );
  }

  renderForm() {
    const title = !this.props.code ? '' : 'Please enter a new password.';

    return (
      <Form ref={this._formRef}
        layout="vertical"
        onValuesChange={this.handleChange}
        onFinish={this.handleSubmit}
        requiredMark={true}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Space direction='vertical'>
          <PasswordTips />
          {this.renderErrors()}
          {this.renderCurrentPassword()}

          <Form.Item
            {...this.getFields().get(ResetPasswordHandler.Request.password)}
            {...ValidationUtil.getValidation(ResetPasswordHandler.Request.password, this.state.fieldErrors, this.state.submitted)}>
            <PasswordInput inputProps={{ disabled: !this.props.isEditing || this.state.loading || this.state.submitting }} />
          </Form.Item>

          <Form.Item
            {...this.getFields().get(ResetPasswordHandler.Request.confirmPassword)}
            {...ValidationUtil.getValidation(ResetPasswordHandler.Request.confirmPassword, this.state.fieldErrors, this.state.submitted)}>
            <Input.Password disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </Form.Item>

          {this.renderActions()}
        </Space>
      </Form>
    );
  }

  renderErrors() {
    if (this.state.error) {
      const messages = this.state.errors?.join('\n') ?? '';
      return (
        <Alert description={messages} showIcon={true} type='error' message='Error' />
      );
    }
    return null;
  }

  renderCurrentPassword() {
    if (!this.props.code) {
      return (
        <Form.Item
          {...this.getFields().get(ResetPasswordHandler.Request.currentPassword)}
          {...ValidationUtil.getValidation(ResetPasswordHandler.Request.currentPassword, this.state.fieldErrors, this.state.submitted)}>
          <Input.Password disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
        </Form.Item >
      );
    }

    return null;
  }

  renderActions() {
    if (this.props.isEditing) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SubmitButton disabled={this.state.loading || this.state.submitting} submitting={this.state.submitting} />
        </ Space>
      );
    }

    return null;
  }

  renderError() {
    let message = 'The link you used was invalid. Please ensure you are using the correct link to reset your password. If you need to request a new link, please request a new one below.';
    if (this.state.error && this.state.message) {
      message = this.state.message;
    }

    return (
      <Result
        status="error"
        title="Error"
        subTitle={message}
        extra={
          <Link to={Routes.generate(Routes.REQUEST_RESET_PASSWORD)}>
            <Button htmlType="button" size="large" loading={this.state.submitted} disabled={this.state.loading} >
              Request Password Reset
            </Button>
          </Link>
        } />
    );
  }

  renderChecking() {
    return (
      <Spin className='center' tip="Validating Link..." size="large" />
    );
  }
}

export default ResetPasswordForm;
