import { InstitutionAction, InstitutionActionTypes } from '../actions/InstitutionActions';
import { OrganizationStoreState } from '../state/StateStoreModel';

export const InitialOrganizationStoreState: OrganizationStoreState = {
  Selected: null,
  OrganizationId: null
};

export function Organization(
  state: OrganizationStoreState = InitialOrganizationStoreState,
  action: InstitutionAction
): OrganizationStoreState {
  let newState: OrganizationStoreState = state;

  switch (action.type) {
    case InstitutionActionTypes.INSTITUTION_SELECT:
      newState = {
        ...state, Selected: action.data, OrganizationId: action.data?.Id ?? null
      };
      break;

    default:
      return newState;
  }
  return Object.freeze(newState);
}
