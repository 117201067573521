import { Alert, Form, message, Skeleton, Space } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import CourseModificationChangeRequestApiService from '../../../api/CourseModificationChangeRequestApiService';
import * as GetCourseModificationChangeRequestHandler from '../../../handlerModels/GetCourseModificationChangeRequestHandler';
import * as SubmitCourseModificationChangeRequestStep1Handler from '../../../handlerModels/SubmitCourseModificationChangeRequestStep1Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import CourseModificationChangeRequestDTO from '../../../models/CourseModificationChangeRequestDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import CourseSearch from '../../postSecondary/CourseSearch';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import ValueLabel from '../../general/ValueLabel';

interface CourseModificationChangeRequestStep1Props extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  institutionId: string;
  loading?: boolean;
  onSubmit?: (changeRequestId: string) => void;
  onSave?: (changeRequestId: string) => void;
  readonly?: boolean;
  disciplineId?: string;
  catalogNumber?: string;
}

interface CourseModificationChangeRequestStep1State extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  courseModification: CourseModificationChangeRequestDTO;
}

class CourseModificationChangeRequestStep1Form extends React.Component<CourseModificationChangeRequestStep1Props, CourseModificationChangeRequestStep1State> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(CourseModificationChangeRequestDTO.courseId, {
        required: true,
        name: CourseModificationChangeRequestDTO.courseId,
        rules: [
          ValidationRuleUtil.required()
        ],
      });
  }

  constructor(props: CourseModificationChangeRequestStep1Props) {
    super(props);
    const modification = CourseModificationChangeRequestDTO.create({
      disciplineId: this.props.disciplineId,
      catalogNumber: this.props.catalogNumber
    });

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      courseModification: modification,
    };
  }

  componentDidUpdate(prevProps: CourseModificationChangeRequestStep1Props) {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty() && this.props.changeRequestId != prevProps.changeRequestId) {
      this.setState({
        fieldErrors: this.props.fieldErrors
      });

      this.loadChangeRequest();
    }

    if (this.props.disciplineId && this.props.disciplineId != prevProps.disciplineId) {
      this.setState({
        fieldErrors: this.props.fieldErrors
      });
    }

    if (this.props.catalogNumber && this.props.catalogNumber != prevProps.catalogNumber) {
      this.setState({
        fieldErrors: this.props.fieldErrors
      });

    }

    if (this.props.changeRequestId != Guid.Empty() && this.props.loading != prevProps.loading) {
      this.loadChangeRequest();
      this.setState({ loading: this.props.loading });
    }
  }

  private loadChangeRequest = () => {
    const request = GetCourseModificationChangeRequestHandler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return CourseModificationChangeRequestApiService.get(request)
      .then((results: GetCourseModificationChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            courseModification: results.changeRequest?.courseModificationChangeRequest ?? CourseModificationChangeRequestDTO.create({
              disciplineId: this.props.disciplineId,
              catalogNumber: this.props.catalogNumber
            }),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private handleSubmit = () => {
    this.setState({ saving: true });

    const model = CourseModificationChangeRequestDTO.create();
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();
    model.courseId = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.courseId)?.courseId ?? Guid.Empty();

    const request = SubmitCourseModificationChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      institutionId: this.props.institutionId,
      CourseModificationChangeRequest: model
    }) as SubmitCourseModificationChangeRequestStep1Handler.Request;

    CourseModificationChangeRequestApiService.submitStep1(request)
      .then((result: SubmitCourseModificationChangeRequestStep1Handler.Result) => {
        this.setState({ loading: false, submitted: true });

        if (result?.succeeded) {
          if (this.props.onSubmit && result.changeRequest?.id) {
            this.props.onSubmit(result.changeRequest.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors,
            submitted: true
          });

          message.error('Submit Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Submit Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }


    return (
      <Content>
        <Form ref={this._formRef}
          layout='vertical'
          initialValues={this.state.courseModification}
          onFinish={this.handleSubmit} >

          <Space direction='vertical'>
            {this.renderSearch()}
            {this.renderCourse()}

            {this.renderActions()}
          </Space>
        </Form>
      </Content>
    );
  }

  renderCourse() {
    let title = this.state.courseModification?.discipline?.code ?? '';
    title += ' ' + this.state.courseModification?.catalogNumber ?? '';

    if (this.props.readonly) {
      return <ValueLabel text={title} />
    }
  }

  renderSearch() {
    const formItems = this.getFormItems();

    if (!this.props.readonly) {
      return <FormItem
        initialValue={{
          courseId: this.state.courseModification.courseId,
          course: this.state.courseModification.courseId,
          catalogNumber: this.state.courseModification.catalogNumber,
          disiplineId: this.state.courseModification.disciplineId
        }}
        {...formItems.get(CourseModificationChangeRequestDTO.courseId)}
        {...ValidationUtil.getValidation(CourseModificationChangeRequestDTO.courseId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
        <CourseSearch includeRetired={true}
          existingCourseId={this.state.courseModification.courseId ?? ''}
          institutionId={this.props.institutionId}
          catalogNumber={this.props.catalogNumber}
          disciplineId={this.props.disciplineId} />
      </FormItem>
    }
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveAndContinueButton submitting={this.state.submitting} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  renderErrors() {
    if (this.state.fieldErrors) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default CourseModificationChangeRequestStep1Form;