// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import CreditTypeDTO from '../models/CreditTypeDTO';
import * as GetCreditTypeDetailsHandler from '../handlerModels/GetCreditTypeDetailsHandler';
import * as SaveCreditTypeDetailsHandler from '../handlerModels/SaveCreditTypeDetailsHandler';
import * as DeleteCreditTypeHandler from '../handlerModels/DeleteCreditTypeHandler';

export class CreditTypesApiService extends BaseApi {

    // post: api/creditTypes
    public getCreditTypeTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<CreditTypeDTO>> {
        let url = `api/creditTypes`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<CreditTypeDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/creditTypes/export
    public exportCreditTypeList(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/creditTypes/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/creditTypes/${encodeURIComponent(creditTypeId)}
    public getCreditTypeDetails(creditTypeId: string): Promise<GetCreditTypeDetailsHandler.Result> {
        let url = `api/creditTypes/${encodeURIComponent(creditTypeId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCreditTypeDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/creditTypes/save
    public saveCreditTypeDetails(saveCreditTypeDetails: SaveCreditTypeDetailsHandler.Request): Promise<SaveCreditTypeDetailsHandler.Result> {
        let url = `api/creditTypes/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveCreditTypeDetailsHandler.Result>(saveCreditTypeDetails, 'post', url, true, false);
    }

    // post: api/creditTypes/deleteCreditType
    public deleteCreditType(deleteCreditType: DeleteCreditTypeHandler.Request): Promise<DeleteCreditTypeHandler.Result> {
        let url = `api/creditTypes/deleteCreditType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteCreditTypeHandler.Result>(deleteCreditType, 'post', url, true, false);
    }
}
var service = new CreditTypesApiService();
export default service;
