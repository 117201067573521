// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import LookupTableDTO from '../models/LookupTableDTO';

export class LookupsApiService extends BaseApi {

    // get: api/lookups/getOne/${encodeURIComponent(name)}/string/${encodeURIComponent(id)}
    public getOneStringId(name: string, id: string): Promise<LookupTableDTO> {
        let url = `api/lookups/getOne/${encodeURIComponent(name)}/string/${encodeURIComponent(id)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO>(null, 'get', url, true, false);
    }

    // get: api/lookups/getOne/${encodeURIComponent(name)}/int/${id}
    public getOneIntId(name: string, id: number): Promise<LookupTableDTO> {
        let url = `api/lookups/getOne/${encodeURIComponent(name)}/int/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO>(null, 'get', url, true, false);
    }

    // get: api/lookups/getOne/${encodeURIComponent(name)}/guid/${encodeURIComponent(id)}
    public getOneGuidId(name: string, id: string): Promise<LookupTableDTO> {
        let url = `api/lookups/getOne/${encodeURIComponent(name)}/guid/${encodeURIComponent(id)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO>(null, 'get', url, true, false);
    }

    // get: api/lookups/getAll/${encodeURIComponent(name)}?includeInactive=${includeInactive}
    public getAll(name: string, includeInactive?: boolean | null): Promise<LookupTableDTO[]> {
        let url = `api/lookups/getAll/${encodeURIComponent(name)}`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (includeInactive != null) {
            url += `${prefix}includeInactive=${includeInactive}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false);
    }

    // get: api/lookups/InvalidateCache?name=${encodeURIComponent(name)}
    public invalidateCache(name?: string | null): Promise<void> {
        let url = `api/lookups/InvalidateCache`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (name != null) {
            url += `${prefix}name=${encodeURIComponent(name || '')}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'get', url, true, false);
    }
}
var service = new LookupsApiService();
export default service;
