import * as React from 'react';
import { useEffect, useState } from 'react';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import * as GetServiceAreasHandler from '../../../handlerModels/GetServiceAreasHandler';
import ContactReportDTO from '../../../models/ContactReportDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface ContactsReportDataTableProps {
  academicYear?: number;
}

function ContactsReportDataTable(props: ContactsReportDataTableProps) {
  let _dataTable: DataTable<ContactReportDTO> | undefined = undefined;

  const [exporting, setExporting] = useState(false);
  const [rpps, setRPPs] = useState<RegionalPlanningPartnerDTO[]>([]);
  const [serviceAreas, setServiceAreas] = useState<ServiceAreaDTO[]>([]);

  useEffect(() => {
    RegionalPlanningPartnerApiService.getRegionalPlanningPartners(props.academicYear ?? 0)
      .then(x => {
        setRPPs(x.regions ?? []);
      });

    ServiceAreaApiService.getServiceAreas(props.academicYear ?? 0).then((results: GetServiceAreasHandler.Result) => {
      if (results.serviceAreas) {
        setServiceAreas(results.serviceAreas);
      }
    });

    triggerRefresh();
  }, [props.academicYear]);

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const getColumnDefinitions = () => {
    return [
      {
        title: 'RPP ID',
        dataIndex: ContactReportDTO.rppId,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: rpps.map(c => { return { text: c.regionalPlanningPartnerIdentifier, value: c.regionalPlanningPartnerIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: ContactReportDTO) => {
          return rpps.find(x => x.regionalPlanningPartnerIdentifier == row.rppId)?.display;
        },
      },
      {
        title: 'Service Area',
        dataIndex: ContactReportDTO.serviceArea,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: serviceAreas.map(c => { return { text: c.serviceAreaIdentifier.toString(), value: c.serviceAreaIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: ContactReportDTO) => {
          return row.serviceArea;
        },
      },
      {
        title: 'District',
        dataIndex: ContactReportDTO.district,
        sorter: true,
        render: (data: string, row: ContactReportDTO) => {
          return row.district
        },
        filterType: FilterType.Text
      },
      {
        title: 'CIP Number',
        dataIndex: ContactReportDTO.cip,
        sorter: true,
        render: (data: string, row: ContactReportDTO) => {
          return row.cip
        },
        filterType: FilterType.Text
      },
      {
        title: 'User',
        dataIndex: ContactReportDTO.user,
        sorter: true,
        render: (data: string, row: ContactReportDTO) => {
          return row.user
        },
        filterType: FilterType.Text
      },
      {
        title: 'Email',
        dataIndex: ContactReportDTO.email,
        sorter: true,
        render: (data: string, row: ContactReportDTO) => {
          return row.email
        },
        filterType: FilterType.Text
      },
      {
        title: 'Phone',
        dataIndex: ContactReportDTO.phone,
        sorter: true,
        render: (data: string, row: ContactReportDTO) => {
          return row.phone
        },
        filterType: FilterType.Text
      },
    ] as DataTableColumnProps<any>[];
  };

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  actionButtons.push(
    DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        return SelfStudyApiService.getContactsReportExport(tableRequest, props.academicYear ?? 0).then(file => {
          setExporting(false);
          FileDownload.downloadBase64StringAsFile(`Contact_Information_Report_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
        });
      },
      exporting
    )
  );

  return (<>
    <DataTable
      // @ts-ignore
      ref={(element: any) => (_dataTable = element)}
      serverSide={true}
      tableProps={{
        rowKey: 'id',
        scroll: { x: 500 }
      }}
      globalSearch={true}
      buttonBar={actionButtons}
      columns={getColumnDefinitions()}
      fetchData={{
        fetch: (requestState: TableRequestDTO) => SelfStudyApiService.getContactsReport(requestState, props.academicYear ?? 0)
      }}
      stateSaving={{ enabled: true, tableUniqueKey: 'contacts_report_list' }} />
  </>
  );
}

export default ContactsReportDataTable;
