import { Card, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SecondaryProgramApiService from '../../../api/SecondaryProgramApiService';
import ConfirmAndContinueButton from '../../../components/buttons/ConfirmAndContinueButton';
import PreviousButton from '../../../components/buttons/PreviousButton';
import SecondaryProgramsDataTable from '../../../components/datatables/secondary/SecondaryProgramsDataTable';
import DataReviewLayout from '../../../components/secondary/DataReviewLayout';
import SecondaryProgramsImporter from '../../../components/secondary/SecondaryProgramsImporter';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as AcademicYearHasSecondaryProgramsHandler from '../../../handlerModels/AcademicYearHasSecondaryProgramsHandler';
import BaseFormState from '../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface SecondaryProgramListPageState extends BaseFormState {
  academicYear: number;
  hasPrograms?: boolean;
  errors: string[];
}

interface SecondaryProgramListPageProps {
}

class DataReviewSecondaryProgramListPage extends React.Component<SecondaryProgramListPageProps & RouteComponentProps<RouteObject>, SecondaryProgramListPageState> {

  constructor(props: SecondaryProgramListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0,
      errors: []
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });
      this.loadSecondaryPrograms(id);
    }
  }

  private loadSecondaryPrograms = (id: number, force?: boolean) => {
    if (force || (this.state.hasPrograms == undefined && id > 0)) {
      SecondaryProgramApiService.academicYearHasSecondaryPrograms(id)
        .then((results: AcademicYearHasSecondaryProgramsHandler.Result) => {
          if (results) {
            this.setState({
              hasPrograms: results.hasPrograms
            });
          }
        });
    }
  }

  private onSubmit = (errors?: string[]) => {
    this.loadSecondaryPrograms(this.state.academicYear, true);
    this.setState({ errors: errors ?? [] });
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_DISTRICT_CONTACTS, { academicYearId: this.state.academicYear }, {}));
  }

  private onContinue = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SECONDARY_COURSES, { academicYearId: this.state.academicYear }, {}));
  }

  render() {
    const academicYear = this.state.academicYear;

    if (academicYear != 0) {
      return (
        <Content >
          <DataReviewLayout breadcrumbs={Breadcrumbs.academicYearDataReview(academicYear)} stepNumber={8} academicYear={academicYear}>
            <Card title="Programs">
              <Space direction='vertical' size="middle">
                {this.state.hasPrograms ?
                  <SecondaryProgramsDataTable errors={this.state.errors} academicYear={this.state.academicYear} fromAcademicYearDataReview={true} /> :
                  <SecondaryProgramsImporter onSubmit={this.onSubmit} academicYear={this.state.academicYear} />}
                <Space>
                  <PreviousButton onClick={this.onBack} />
                  {this.state.hasPrograms ? <ConfirmAndContinueButton onClick={this.onContinue} /> : null}
                </Space>
              </Space>
            </Card>
          </DataReviewLayout>
        </Content>
      );
    }

    return <Skeleton />;
  }
}

export default withRouter(DataReviewSecondaryProgramListPage);
