import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import WorkflowDataTable from '../../../components/datatables/postSecondary/WorkflowDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import TermDTO from '../../../models/TermDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import HistoryUtil from '../../../utils/HistoryUtil';

interface WorkflowListPageProps {
}

interface WorkflowListPageState {
  terms: TermDTO[];
}

class WorkflowListPage extends React.Component<WorkflowListPageProps & RouteComponentProps<RouteObject>, WorkflowListPageState> {
  constructor(props: WorkflowListPageProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Change Request Approval Flows" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.changeRequestTypes()} />

        <Card >
          <WorkflowDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(WorkflowListPage);
