import { FormItemProps, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import * as React from 'react';
import LookupTableDTO from '../models/LookupTableDTO';

export default class FormUtil {
  public static getProgress(form: React.RefObject<FormInstance<any>>, fields: Map<string, FormItemProps>) {
    if (form) {
      let requiredCount = 0;
      let filledCount = 0;

      for (const [key, value] of Array.from(fields)) {
        requiredCount += value.required ? 1 : 0;
        filledCount += value.required && form.current?.getFieldValue(key) ? 1 : 0;
      }

      if (requiredCount > 0) {
        return (100 / requiredCount) * filledCount;
      }

      return filledCount >= 0 ? 100 : 0;
    }

    return 0;
  }

  public static canSubmit(form: React.RefObject<FormInstance<any>>, fields: Map<string, FormItemProps>) {
    const submittable = this.getProgress(form, fields) >= 100;
    return submittable;
  }

  public static hasChanged(form: React.RefObject<FormInstance<any>>, fields: Map<string, FormItemProps>): boolean {
    for (const [key] of Array.from(fields)) {
      if (form.current?.isFieldTouched(key)) {
        return true;
      }
    }

    return false;
  }

  public static getOptions(items: LookupTableDTO[] | undefined) {
    if (items) {
      return items.map((s) => { return <Select.Option key={s.key} value={s.key}>{s.name}</Select.Option> });
    }

    return null;
  }
}