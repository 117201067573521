import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import * as SaveNewProgramChangeRequestStep12Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep12Handler';
import * as SubmitNewProgramChangeRequestStep12Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep12Handler';
import * as GetNewProgramChangeRequestStep12Handler from '../../../handlerModels/GetNewProgramChangeRequestStep12Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep12FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
}

interface NewProgramChangeRequestStep12FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  onSubmit?: () => void;
  onPrevious?: () => void;
  onSave?: () => void;
  onChange?: (percentage: number) => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep12Form extends React.Component<NewProgramChangeRequestStep12FormProps, NewProgramChangeRequestStep12FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(NewProgramChangeRequestDTO.additionlCommentsICCPHSE, {
        name: NewProgramChangeRequestDTO.additionlCommentsICCPHSE,
        label: 'Please provide any additional comments that would be beneficial and informative for the state\'s public and private colleges in the ICCPHSE (Iowa Coordinating Council for Post High School Education).',
        rules: [
          ValidationRuleUtil.maxLength(5000),
        ],
      })
  }

  constructor(props: NewProgramChangeRequestStep12FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      this.loadChangeRequest();
    }
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep12FormProps) {
    if (this.props.changeRequestId && prevProps.changeRequestId != this.props.changeRequestId) {
      if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
        this.loadChangeRequest();
      }
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep12Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep12(request)
      .then((results: GetNewProgramChangeRequestStep12Handler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({ altered: false });
    this._formRef.current?.resetFields();
  }

  private handleSave = () => {
    this.setState({ saving: true });
    const newProgramChangeRequestDTO = NewProgramChangeRequestDTO.create(this._formRef.current?.getFieldsValue());
    newProgramChangeRequestDTO.changeRequestId = this.state.changeRequest.id ?? Guid.Empty();

    const request = SaveNewProgramChangeRequestStep12Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequestDTO
    });

    NewProgramChangeRequestApiService.saveStep12(request)
      .then((result: SaveNewProgramChangeRequestStep12Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');


          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
          });


          if (this.props.onSave) {
            this.props.onSave();
            this.resetForm();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const newProgramChangeRequestDTO = NewProgramChangeRequestDTO.create(this._formRef.current?.getFieldsValue());
    newProgramChangeRequestDTO.changeRequestId = this.state.changeRequest.id ?? Guid.Empty();

    const request = SaveNewProgramChangeRequestStep12Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequestDTO
    });

    NewProgramChangeRequestApiService.submitStep12(request)
      .then((result: SubmitNewProgramChangeRequestStep12Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');


          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
          });

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }

          if (this.props.onSave) {
            this.props.onSave();
            this.resetForm();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public render = () => {
    if (this.state.loading || this.state.changeRequest.id == Guid.Empty()) {
      return <Skeleton active={true} />
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.changeRequest.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create()}
          onChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this.getFormItems().get(NewProgramChangeRequestDTO.additionlCommentsICCPHSE)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.additionlCommentsICCPHSE, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

          {this.renderActions()}
        </Form>
      </Space >
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <GoBackButton onClick={this.props.onPrevious} />
          <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.saving} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  private renderErrors = () => {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NewProgramChangeRequestStep12Form;