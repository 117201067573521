import { Card, Space } from 'antd';
import * as React from 'react';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import ChangeRequestAdditionalDetailsForm from '../../forms/postSecondary/ChangeRequestAdditionalDetailsForm';
import NoticeOfIntentChangeRequestStep1Form from '../../forms/postSecondary/NoticeOfIntentChangeRequestStep1Form';
import NoticeOfIntentChangeRequestStep2Form from '../../forms/postSecondary/NoticeOfIntentChangeRequestStep2Form';
import NoticeOfIntentChangeRequestStep3Form from '../../forms/postSecondary/NoticeOfIntentChangeRequestStep3Form';
import NoticeOfIntentChangeRequestStep4Form from '../../forms/postSecondary/NoticeOfIntentChangeRequestStep4Form';
import NoticeOfIntentChangeRequestStep5Form from '../../forms/postSecondary/NoticeOfIntentChangeRequestStep5Form';
import NoticeOfIntentChangeRequestStep6Form from '../../forms/postSecondary/NoticeOfIntentChangeRequestStep6Form';
import NoticeOfIntentChangeRequestStep7Form from '../../forms/postSecondary/NoticeOfIntentChangeRequestStep7Form';

interface NoticeOfIntentChangeRequestDetailsState extends BaseFormState {
}

interface NoticeOfIntentChangeRequestDetailsProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequest: ChangeRequestDTO;
}

class NoticeOfIntentChangeRequestDetails extends React.Component<NoticeOfIntentChangeRequestDetailsProps, NoticeOfIntentChangeRequestDetailsState> {
  constructor(props: NoticeOfIntentChangeRequestDetailsProps) {
    super(props);

    this.state = {};
  }

  public render = () => {

    return (
      <Space size="large" direction="vertical">
        <Card title="Program Plan" type='inner'>
          <NoticeOfIntentChangeRequestStep1Form loading={this.props.loading} fieldErrors={this.props.fieldErrors} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title='Planned Awards' type='inner'>
          <NoticeOfIntentChangeRequestStep2Form loading={this.props.loading} fieldErrors={this.props.fieldErrors} changeRequestId={this.props.changeRequest.id} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title="Contact Information" type='inner'>
          <NoticeOfIntentChangeRequestStep3Form loading={this.props.loading} fieldErrors={this.props.fieldErrors} institutionId={this.props.changeRequest.institutionId} changeRequestId={this.props.changeRequest.id} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title='Consultant Meetings' type='inner'>
          <NoticeOfIntentChangeRequestStep4Form loading={this.props.loading} fieldErrors={this.props.fieldErrors} institutionId={this.props.changeRequest.institutionId} changeRequestId={this.props.changeRequest.id} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title="Occupational Information" type='inner'>
          <NoticeOfIntentChangeRequestStep5Form loading={this.props.loading} fieldErrors={this.props.fieldErrors} institutionId={this.props.changeRequest.institutionId} changeRequestId={this.props.changeRequest.id} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title='Industry Information' type='inner'>
          <NoticeOfIntentChangeRequestStep6Form loading={this.props.loading} fieldErrors={this.props.fieldErrors} institutionId={this.props.changeRequest.institutionId} changeRequestId={this.props.changeRequest.id} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title='Linking And Existing Entities' type='inner'>
          <NoticeOfIntentChangeRequestStep7Form loading={this.props.loading} fieldErrors={this.props.fieldErrors} changeRequestId={this.props.changeRequest.id} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title='Additional Information' type='inner'>
          <ChangeRequestAdditionalDetailsForm loading={this.props.loading} fieldErrors={this.props.fieldErrors} changeRequest={this.props.changeRequest} readonly={true} />
        </Card>
      </Space>
    );
  }
}

export default NoticeOfIntentChangeRequestDetails;