// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AcademicYearDTO from '../models/AcademicYearDTO';
import * as IsValidAcademicYearHandler from '../handlerModels/IsValidAcademicYearHandler';
import * as GetAcademicYearHandler from '../handlerModels/GetAcademicYearHandler';
import * as GetAcademicYearsHandler from '../handlerModels/GetAcademicYearsHandler';
import * as SaveAcademicYearHandler from '../handlerModels/SaveAcademicYearHandler';
import * as CompleteAcademicYearDataReviewHandler from '../handlerModels/CompleteAcademicYearDataReviewHandler';
import * as ConfirmSelfStudiesHandler from '../handlerModels/ConfirmSelfStudiesHandler';
import * as SendSelfStudyReminderEmailHandler from '../handlerModels/SendSelfStudyReminderEmailHandler';
import * as ReleaseApprovalLettersHandler from '../handlerModels/ReleaseApprovalLettersHandler';

export class AcademicYearApiService extends BaseApi {

    // post: api/academicYear/isValid/${academicYear}
    public isValidAcademicYear(academicYear: number): Promise<IsValidAcademicYearHandler.Result> {
        let url = `api/academicYear/isValid/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, IsValidAcademicYearHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/academicYear
    public getAcademicYearTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AcademicYearDTO>> {
        let url = `api/academicYear`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<AcademicYearDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/academicYear/get
    public getAcademicYear(getAcademicYear: GetAcademicYearHandler.Request): Promise<GetAcademicYearHandler.Result> {
        let url = `api/academicYear/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAcademicYearHandler.Result>(getAcademicYear, 'post', url, true, false);
    }

    // post: api/academicYear/getAcademicYears
    public getAcademicYears(getAcademicYears: GetAcademicYearsHandler.Request): Promise<GetAcademicYearsHandler.Result> {
        let url = `api/academicYear/getAcademicYears`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAcademicYearsHandler.Result>(getAcademicYears, 'post', url, true, false);
    }

    // post: api/academicYear/save
    public saveAcademicYear(saveAcademicYear: SaveAcademicYearHandler.Request): Promise<SaveAcademicYearHandler.Result> {
        let url = `api/academicYear/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveAcademicYearHandler.Result>(saveAcademicYear, 'post', url, true, false);
    }

    // post: api/academicYear/completeDataReview
    public completeAcademicYearDataReview(completeAcademicYearDataReview: CompleteAcademicYearDataReviewHandler.Request): Promise<CompleteAcademicYearDataReviewHandler.Result> {
        let url = `api/academicYear/completeDataReview`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, CompleteAcademicYearDataReviewHandler.Result>(completeAcademicYearDataReview, 'post', url, true, false);
    }

    // post: api/academicYear/confirmSelfStudiesHandler
    public confirmSelfStudiesHandler(confirmSelfStudies: ConfirmSelfStudiesHandler.Request): Promise<ConfirmSelfStudiesHandler.Result> {
        let url = `api/academicYear/confirmSelfStudiesHandler`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ConfirmSelfStudiesHandler.Result>(confirmSelfStudies, 'post', url, true, false);
    }

    // post: api/academicYear/sendSelfStudyReminderEmails
    public sendSelfStudyReminderEmails(sendSelfStudyReminderEmail: SendSelfStudyReminderEmailHandler.Request): Promise<SendSelfStudyReminderEmailHandler.Result> {
        let url = `api/academicYear/sendSelfStudyReminderEmails`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SendSelfStudyReminderEmailHandler.Result>(sendSelfStudyReminderEmail, 'post', url, true, false);
    }

    // post: api/academicYear/releaseApprovalLetters
    public releaseApprovalLetters(releaseApprovalLetters: ReleaseApprovalLettersHandler.Request): Promise<ReleaseApprovalLettersHandler.Result> {
        let url = `api/academicYear/releaseApprovalLetters`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ReleaseApprovalLettersHandler.Result>(releaseApprovalLetters, 'post', url, true, false);
    }
}
var service = new AcademicYearApiService();
export default service;
