export default class FileUploadUtil {
  public static attachFileToModel(requestName: string, model: any, filePropertyName: string, fileToAdd: any) {
    const formData = new FormData();

    formData.append(requestName, JSON.stringify(model));
    formData.append(filePropertyName, fileToAdd);

    return formData;
  }

  public static attachFilesToModel(requestName: string, model: any, filesPropertyName: string, filesToAdd: any[]) {
    const formData = new FormData();
    formData.append(requestName, JSON.stringify(model));

    if (filesToAdd) {
      for (let i = 0; i < filesToAdd.length; i++) {
        formData.append(filesPropertyName, filesToAdd[i]);
      }
    }

    return formData;
  }
}