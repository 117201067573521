// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ActionPlanReportPriorityDTO from './ActionPlanReportPriorityDTO';
import SelfStudyResponsePreviousGoalDTO from './SelfStudyResponsePreviousGoalDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ActionPlanReportDTO extends EntitySelectorDTO { 
	status: number;
	rppId: number;
	district: string | null;
	serviceArea: number;
	cip: string | null;
	program: string | null;
	newGoals: ActionPlanReportPriorityDTO[] | null;
	previousGoals: SelfStudyResponsePreviousGoalDTO[] | null;
}

const ActionPlanReportDTO = {
    className: 'ActionPlanReportDTO',
    create: (initValues?: Partial<ActionPlanReportDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			status: 0,
			rppId: 0,
			district: "",
			serviceArea: 0,
			cip: "",
			program: "",
			newGoals: [],
			previousGoals: [],
        },
        initValues);
    },
	status: 'status',
	rppId: 'rppId',
	district: 'district',
	serviceArea: 'serviceArea',
	cip: 'cip',
	program: 'program',
	newGoals: 'newGoals',
	previousGoals: 'previousGoals',
};

export default ActionPlanReportDTO;