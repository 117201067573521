import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import Modal from 'antd/lib/modal';
import * as React from 'react';
import { useState } from 'react';
import DistrictUserApiService from '../../../api/DistrictUserApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as RemoveDistrictFromUserHandler from '../../../handlerModels/RemoveDistrictFromUserHandler';
import DistrictDTO from '../../../models/DistrictDTO';
import DistrictUserDTO from '../../../models/DistrictUserDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import StringUtil from '../../../utils/StringUtil';
import AddDistrictToUserForm from '../../forms/secondary/AddDistrictToUserForm';
import AddUserToDistrictForm from '../../forms/secondary/AddUserToDistrictForm';
import EditDistrictRoleForm from '../../forms/secondary/EditDistrictRoleForm';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface DistrictUsersDataTableProps {
  userId?: string;
  districtName?: string;
  districtIdentifier?: number;
  isPublic?: boolean;
}

function DistrictUsersDataTable(props: DistrictUsersDataTableProps) {
  let _dataTable: DataTable<DistrictUserDTO> | undefined = undefined;

  const [showEditDistrictRolesModal, setShowEditDistrictModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddDistrictModal, setShowAddDistrictModal] = useState(false);

  const [editDistrictRolesData, setEditDistrictRolesData] = useState<DistrictUserDTO | null>();

  const _addDistrictRef = React.useRef<any>();
  const _addUserRef = React.useRef<any>();
  const _editDistrictRolesRef = React.useRef<any>();

  const getColumnDefinitions = () => {
    const columns = [
    ] as DataTableColumnProps<any>[];
    if (props.districtIdentifier) {
      columns.pushAll([
        {
          title: 'First Name',
          dataIndex: DistrictUserDTO.firstName,
          sorter: true,
          render: (data: string, row: DistrictUserDTO) => {
            return row.firstName;
          },
          filterType: FilterType.Text
        },
        {
          title: 'Last Name',
          dataIndex: DistrictUserDTO.lastName,
          sorter: true,
          render: (data: string, row: DistrictUserDTO) => {
            return row.lastName;
          },
          filterType: FilterType.Text
        },
        {
          title: 'Email',
          dataIndex: DistrictUserDTO.emailAddress,
          sorter: true,
          render: (data: string, row: DistrictUserDTO) => {
            return row.emailAddress;
          },
          filterType: FilterType.Text
        }])
    } else {
      columns.pushAll([
        {
          title: 'Name',
          dataIndex: DistrictUserDTO.districtName,
          render: (data: string, row: DistrictUserDTO) => {
            return row.districtName;
          },
          width: 180
        },])
    }
    columns.pushAll([
      {
        title: 'Roles',
        dataIndex: DistrictUserDTO.roles,
        renderDataTransform: (value: string[]) => {
          return StringUtil.newLineFormat(value, true);
        },
      },
      {
        title: 'Date Added',
        dataIndex: DistrictUserDTO.dateAdded,
        render: (data: string, row: DistrictUserDTO) => {
          return row.dateAdded;
        }
      },
      {
        title: 'Actions',
        dataIndex: DistrictDTO.className,
        render: (data: string, row: DistrictUserDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_DISTRICT_USERS])) {
            return <>
              <Button type="link" onClick={() => openEditDistrictRolesModal(row)}><FormOutlined /></Button>
              <Button type="link" className="ant-btn" onClick={() => promptConfirmRemoveDistrict(row)}><DeleteOutlined /></Button>
            </>
          }
        },
        sorter: false,
        filterType: FilterType.NONE,
        width: 50
      }]);

    return columns;
  }

  const openEditDistrictRolesModal = (record: DistrictUserDTO) => {
    setShowEditDistrictModal(true);
    setEditDistrictRolesData(record);
  }

  const closeEditDistrictRolesModal = () => {
    setShowEditDistrictModal(false);
    setEditDistrictRolesData(null);
    refreshDataTable();
    _editDistrictRolesRef?.current?.reset();
  }

  const onEditDistrictRolesSubmit = () => {
    _editDistrictRolesRef?.current?.handleSubmit(editDistrictRolesData)
  }

  const handleEditDistrictRolesSubmit = () => {
    closeEditDistrictRolesModal();
  }

  const renderEditDistrictUserRolesModal = () => {
    return (
      <Modal
        visible={showEditDistrictRolesModal}
        title={props.districtName}
        onCancel={closeEditDistrictRolesModal}
        onOk={onEditDistrictRolesSubmit}
        okText='Submit'>
        <EditDistrictRoleForm
          ref={_editDistrictRolesRef}
          userId={props.userId ?? Guid.Empty()}
          districtIdentifier={editDistrictRolesData?.districtIdentifier ?? 0}
          district={editDistrictRolesData ?? DistrictUserDTO.create()}
          onSubmit={handleEditDistrictRolesSubmit} />
      </Modal>
    );
  }

  const openAddUserModal = () => {
    setShowAddUserModal(true);
  }

  const closeAddUserModal = () => {
    setShowAddUserModal(false);
    refreshDataTable();
    _addUserRef?.current?.reset();
  }

  const onAddUserSubmit = () => {
    _addUserRef?.current?.handleSubmit();
  }

  const handleAddUserSubmit = () => {
    closeAddUserModal();
  }

  const renderAddUserModal = () => {
    return (
      <Modal
        visible={showAddUserModal}
        title='Add User'
        okText='Submit'
        onOk={onAddUserSubmit}
        onCancel={closeAddUserModal}>
        <AddUserToDistrictForm
          ref={_addUserRef}
          districtIdentifier={props.districtIdentifier ?? 0}
          onSubmit={handleAddUserSubmit} />
      </Modal>
    );
  }

  const openAddDistrictModal = () => {
    setShowAddDistrictModal(true);
  }

  const closeAddDistrictModal = () => {
    setShowAddDistrictModal(false);
    refreshDataTable();
    _addDistrictRef?.current?.reset();
  }

  const onAddDistrictSubmit = () => {
    _addDistrictRef?.current?.handleSubmit()
  }

  const handleAddDistrictSubmit = () => {
    closeAddDistrictModal()
  }

  const renderAddToDistrictModal = () => {
    return (
      <Modal
        visible={showAddDistrictModal}
        title='Add District'
        okText='Submit'
        onOk={onAddDistrictSubmit}
        onCancel={closeAddDistrictModal} >
        <AddDistrictToUserForm
          ref={_addDistrictRef}
          userId={props.userId ?? Guid.Empty()}
          onSubmit={handleAddDistrictSubmit} />
      </Modal>
    );
  }

  const refreshDataTable = () => {
    _dataTable?.refresh()
  }

  const handleRemoveDistrict = (districtUser: DistrictUserDTO) => {
    const request = RemoveDistrictFromUserHandler.Request.create({
      districtIdentifier: districtUser.districtIdentifier,
      userId: districtUser.userId,
    });

    DistrictUserApiService.removeDistrictFromUser(request)
      .then((result: RemoveDistrictFromUserHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('District has been unassigned.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('District could not be unassigned.');
      })
      .finally(() => {
        refreshDataTable();
      });
  }

  const promptConfirmRemoveDistrict = (districtUser: DistrictUserDTO) => {
    const removeEntity = props.districtIdentifier ? 'User.' : 'District.';
    Modal.confirm({
      title: 'Are you sure you want to unassign this ' + removeEntity,
      okText: 'Unassign',
      okButtonProps: { type: 'primary', danger: true },
      onOk: () => handleRemoveDistrict(districtUser),
      width: 500
    });
  }

  const actionButtons = [];

  if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_DISTRICT_USERS]) && props.userId) {
    actionButtons.push(DataTableButtonUtil.Default('Add District', () => openAddDistrictModal()));
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_DISTRICT_USERS]) && props.districtIdentifier) {
    actionButtons.push(DataTableButtonUtil.Default('Add User', () => openAddUserModal()));
  }

  return (
    <>
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => DistrictUserApiService.getDistrictsForUserTableData(requestState, props.userId ?? Guid.Empty(), props.districtIdentifier ?? 0)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'district_user_list' }} />
      {renderEditDistrictUserRolesModal()}
      {renderAddToDistrictModal()}
      {renderAddUserModal()}
    </>
  );
}

export default DistrictUsersDataTable;
