import { Card, Cascader, Skeleton, Space } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SelfStudyApiService from '../../api/SelfStudyApiService';
import SelfStudyTemplateApiService from '../../api/SelfStudyTemplateApiService';
import SelfStudyResponseType from '../../consts/SelfStudyResponseType';
import SelfStudySectionComponentType from '../../consts/SelfStudySectionComponentType';
import * as GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler from '../../handlerModels/GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler';
import * as GetSelfStudyTemplateSectionsHandler from '../../handlerModels/GetSelfStudyTemplateSectionsHandler';
import * as GetSelfStudyWithNewGoalsHandler from '../../handlerModels/GetSelfStudyWithNewGoalsHandler';
import SelfStudyDTO from '../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../models/SelfStudyResponseDTO';
import SelfStudyResponseNewGoalDTO from '../../models/SelfStudyResponseNewGoalDTO';
import SelfStudySectionComponentTemplateDTO from '../../models/SelfStudySectionComponentTemplateDTO';
import SelfStudySectionTemplateDTO from '../../models/SelfStudySectionTemplateDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import Guid from '../../utils/Guid';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import LabeledValue from '../general/LabeledValue';
import ReadableTextArea from '../inputs/ReadableTextArea';
import RichTextEditor from '../inputs/RichTextEditor';
import { Text, View } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import ValueLabel from '../general/ValueLabel';

interface SelfStudyNewGoalsProps extends BaseFormProps {
  academicYear?: number;
  selfStudyResponseId?: string;
  componentIndex: number;
  sectionIndex?: number;
  readonly?: boolean;
  disable?: boolean;
  previewComponent?: boolean;
  newGoalCount?: number;
  pdfView?: boolean;
  renderComplete?: (sectionIndex: number,componentIndex: number, value: boolean) => void;
}

const SelfStudyNewGoals = React.forwardRef((props: SelfStudyNewGoalsProps) => {

  const getNewGoalsFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(SelfStudyResponseNewGoalDTO.section, {
        required: !props.disable && !props.readonly,
        name: [index, SelfStudyResponseNewGoalDTO.section],
        label: 'Section',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudyResponseNewGoalDTO.actionStepsAndTimeline, {
        label: 'Action Step(s) And Timeline',
        required: !props.disable && !props.readonly,
        name: [index, SelfStudyResponseNewGoalDTO.actionStepsAndTimeline],
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudyResponseNewGoalDTO.measureOfSuccess, {
        label: 'Measure Of Success',
        required: !props.disable && !props.readonly,
        name: [index, SelfStudyResponseNewGoalDTO.measureOfSuccess],
        rules: [ValidationRuleUtil.required()]
      })
  }

  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(props.fieldErrors);
  const [submitted, setSubmitted] = useState(props.submitted);
  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);
  const [selfStudyResponse, setSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [selfStudySectionComponentTemplate, setSelfStudySectionComponentTemplate] = useState({} as SelfStudySectionComponentTemplateDTO);
  const [selfStudySectionTemplates, setSelfStudySectionTemplates] = useState([] as SelfStudySectionTemplateDTO[]);

  useEffect(() => {
    fetchData();
  }, [props.academicYear, props.selfStudyResponseId]
  )

  useEffect(() => {
    setFieldErrors(props.fieldErrors);
    setSubmitted(props.submitted);
  }, [props.submitted, props.fieldErrors])

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (props.academicYear != 0 &&
      props.selfStudyResponseId != Guid.Empty() &&
      !props.previewComponent) {
      loaders.push(loadSelfStudy(props.selfStudyResponseId ?? Guid.Empty()));
      loaders.push(loadSelfStudySectionComponentTemplate());
      loaders.push(loadSelfStudySectionTemplates(props.academicYear));

      Promise.all(loaders).then(() => {
        setLoading(false);
        if(props.renderComplete){
          props.renderComplete(props.sectionIndex ?? 0,props.componentIndex, true);
        }
      });
    }
    else if (props.previewComponent) {
      loaders.push(loadSelfStudySectionComponentTemplate());
      setLoading(false);
      if(props.renderComplete){
        props.renderComplete(props.sectionIndex ?? 0,props.componentIndex, true);
      }
    }
  }

  const loadSelfStudy = (id: string) => {
    const request = GetSelfStudyWithNewGoalsHandler.Request.create({
      selfStudyResponseId: id,
      academicYear: props.academicYear
    });

    return SelfStudyApiService.getSelfStudyWithNewGoals(request)
      .then((results: GetSelfStudyWithNewGoalsHandler.Result) => {
        if (results.selfStudy) {
          setSelfStudy(results.selfStudy);
          setSelfStudyResponse(results.selfStudy.selfStudyResponses?.find(x => x.id == id) ?? SelfStudyResponseDTO.create());
        }
      });
  }

  const loadSelfStudySectionComponentTemplate = () => {
    const request = GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Request.create({
      academicYear: props.academicYear,
      selfStudySectionOrder: props.sectionIndex,
      selfStudySectionComponentOrder: props.componentIndex
    })
    return SelfStudyTemplateApiService.getSelfStudySectionComponentTemplateBySectionOrder(request)
      .then((results: GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Result) => {
        if (results.selfStudySectionComponentTemplate) {
          setSelfStudySectionComponentTemplate(results.selfStudySectionComponentTemplate ?? SelfStudySectionComponentTemplateDTO.create());
        }
      });
  }

  const loadSelfStudySectionTemplates = (academicYear: any) => {
    return SelfStudyTemplateApiService.getSelfStudySectionTemplates(academicYear)
      .then((results: GetSelfStudyTemplateSectionsHandler.Result) => {
        if (results.selfStudySectionTemplates) {
          setSelfStudySectionTemplates(results.selfStudySectionTemplates ?? []);
        }
      });
  }

  const getOptions = () => {
    const options: any[] = [];
    const scenario = selfStudy.selfStudyResponses?.find(y => y.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.selfStudyResponseProgramOverview?.secondaryProgramScenarioId;
    selfStudySectionTemplates?.forEach(x => {
      if ((x.selfStudySectionTemplateSecondaryProgramScenarios.length == 0 || (x.selfStudySectionTemplateSecondaryProgramScenarios?.findIndex(x => x == scenario) ?? -1) > -1) &&
        (x?.selfStudySectionComponentTemplates?.findIndex(y => y.selfStudySectionComponentTypeId == SelfStudySectionComponentType.RANKINGQUESTION && ((y.selfStudySectionComponentScenarios?.findIndex(x => x == scenario) ?? -1) > -1 || !y.selfStudySectionComponentScenarios || y.selfStudySectionComponentScenarios.length == 0)) ?? -1) > -1) {
        const section = {
          value: x.title,
          label: x.title,
          children: [{}]
        }

        x.selfStudySectionComponentTemplates?.forEach(y => {
          if ((y.selfStudySectionComponentScenarios.length == 0 || (y.selfStudySectionComponentScenarios?.findIndex(x => x == scenario) ?? -1) > -1) &&
            y.selfStudySectionComponentTypeId == SelfStudySectionComponentType.RANKINGQUESTION) {
            section.children.push({
              value: y.rankingQuestionIdentifier + ' - ' + y.programOfStudyTenets,
              label: y.rankingQuestionIdentifier + ' - ' + y.programOfStudyTenets,
            })
          }
        });

        options.push(section);
      }
    });

    return options;
  }

  const renderPreviousResponse = (goalIndex: number) => {
    const instructorResponseNewGoals = (selfStudy.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.selfStudyResponseNewGoals ?? [])[goalIndex];
    return (
      <>
        <LabeledValue label={'Section'} text={instructorResponseNewGoals?.section} />
        <LabeledValue label={'Action Steps and Timeline'} text={instructorResponseNewGoals?.actionStepsAndTimeline} />
        <LabeledValue label={'Measure Of Success'} text={instructorResponseNewGoals?.measureOfSuccess} />
      </>
    );
  }

  const renderPdfPreviousResponse = (goalIndex: number) => {
    const instructorResponseNewGoals = (selfStudy.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.selfStudyResponseNewGoals ?? [])[goalIndex];
    return (
      <View style={{ border: '1pt solid #f0f0f0', margin: 10 }}>
        <Text style={{ padding: 5, fontSize: 12, border: '1pt solid #f0f0f0', backgroundColor: '#f0f0f0' }}>Goal {goalIndex + 1}</Text>
        <View style={{ margin: 10 }}>
          <Text style={{ paddingBottom: '10pt', fontSize: 10 }}>Section</Text>
          <Text style={{ paddingBottom: '10pt', fontSize: 8 }}>{instructorResponseNewGoals?.section}</Text>
          <Text style={{ paddingBottom: '10pt', fontSize: 10 }}>Action Step(s) And Timeline</Text>
          <Text style={{ paddingBottom: '10pt', fontSize: 8 }}>{instructorResponseNewGoals?.actionStepsAndTimeline}</Text>
          <Text style={{ paddingBottom: '10pt', fontSize: 10 }}>Measure Of Success</Text>
          <Text style={{ paddingBottom: '10pt', fontSize: 8 }}>{instructorResponseNewGoals?.measureOfSuccess}</Text>
        </View>
      </ View>
    );
  }

  if (props.pdfView) {
    let newGoalsCount = selfStudySectionComponentTemplate?.numberOfNewGoals;
    if (props.previewComponent) {
      newGoalsCount = props.newGoalCount ?? 0;
    }
    const newGoalNodes: any = [];

    for (let i = 0; i < (newGoalsCount ?? 0); i++) {
      const newGoals = selfStudyResponse?.selfStudyResponseNewGoals ?
        (selfStudyResponse?.selfStudyResponseNewGoals ?? [])[i] :
        SelfStudyResponseNewGoalDTO.create();
      newGoalNodes.push(
        <View style={{ paddingBottom: ((newGoalsCount ?? 0) - 1) > i ? '10pt' : '0pt' }}>
          {selfStudyResponse?.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR && !props.previewComponent ?
            <View>
              {
                renderPdfPreviousResponse(i)
              }
            </ View>
            :
            <View style={{ border: '1pt solid #f0f0f0', margin: 10 }}>
              <Text style={{ padding: 5, fontSize: 12, border: '1pt solid #f0f0f0', backgroundColor: '#f0f0f0' }}>Goal {i + 1}</Text>
              <View style={{ margin: 10 }}>
                <Text style={{ paddingBottom: '10pt', fontSize: 10 }}>Section</Text>
                <Text style={{ paddingBottom: '10pt', fontSize: 8 }}>{newGoals?.section?.split(' / ')}</Text>
                <Text style={{ paddingBottom: '10pt', fontSize: 10 }}>Action Step(s) And Timeline</Text>
                <Text style={{ paddingBottom: '10pt', fontSize: 8 }}>{newGoals?.actionStepsAndTimeline}</Text>
                <Text style={{ paddingBottom: '10pt', fontSize: 10 }}>Measure Of Success</Text>
                <Text style={{ paddingBottom: '10pt', fontSize: 8 }}>{newGoals?.measureOfSuccess}</Text>
              </ View>
            </View>}
        </View>
      )
    }
    const fontSizeFix = selfStudySectionComponentTemplate?.primaryText?.replaceAll(new RegExp('ptpx','g'), 'px') ?? '';
    const decimal = fontSizeFix.replaceAll(new RegExp('font-size:?[0-9]{1,2}px','g'), 'font-size:10px');
    const fontFamily = decimal.replaceAll(new RegExp('font-size:?[0-9]{1,2}.[0-9]{1,2}px','g'), 'font-size:10px');
    const finalText = fontFamily.replaceAll(new RegExp('font-family:','g'), '');

    return (
      <View style={{ paddingBottom: '10pt' }}>
        <View style={{ border: '1pt solid #f0f0f0', margin: 10 }} >
          <Text style={{ fontSize: 12, border: '1pt solid #f0f0f0', backgroundColor: '#f0f0f0', padding: 5}}>Program Goals</Text>
          <View style={{ margin: 10 }}>
            <Html style={{ fontSize: 10 }}>{finalText}</Html>
            {newGoalNodes}
          </View>
        </View >
      </View>)
  }
  else if (loading) {
    return <Skeleton active />
  }
  else {
    const newGoalNodes: any = [];
    const componentIndex = props.componentIndex;

    let newGoalsCount = selfStudySectionComponentTemplate?.numberOfNewGoals;
    if (props.previewComponent) {
      newGoalsCount = props.newGoalCount ?? 0;
    }

    for (let i = 0; i < (newGoalsCount ?? 0); i++) {

      const newGoals = selfStudyResponse?.selfStudyResponseNewGoals ?
        (selfStudyResponse?.selfStudyResponseNewGoals ?? [])[i] :
        SelfStudyResponseNewGoalDTO.create();

      newGoalNodes.push(
        <Space direction='vertical'>
          <Card type='inner' title={'Goal ' + (i + 1)}>
            {selfStudyResponse?.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR && !props.previewComponent ?
              <Space direction='vertical'>
                {
                  renderPreviousResponse(i)
                }
              </Space>
              :
              <>
                <FormItem
                  initialValue={newGoals?.section?.split(' / ')}
                  {...getNewGoalsFormItems(i).get(SelfStudyResponseNewGoalDTO.section)}
                  {...ValidationUtil.getValidation(SelfStudyResponseNewGoalDTO.section + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
                    { !props.disable && (props.readonly) ? <ValueLabel text={newGoals?.section?.split(' / ')?.join(' / ')} />:
                    <Cascader 
                      style={{ width: '100%' }} 
                      dropdownMatchSelectWidth={false}
                      options={getOptions()}
                      disabled={props.disable}  />}
                </FormItem>

                <FormItem
                  initialValue={newGoals?.actionStepsAndTimeline}
                  {...getNewGoalsFormItems(i).get(SelfStudyResponseNewGoalDTO.actionStepsAndTimeline)}
                  {...ValidationUtil.getValidation(SelfStudyResponseNewGoalDTO.actionStepsAndTimeline + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
                  <ReadableTextArea
                    showCount
                    disabled={props.disable}
                    readonly={!props.disable && (props.readonly)} />
                </FormItem>

                <FormItem
                  initialValue={newGoals?.measureOfSuccess}
                  {...getNewGoalsFormItems(i).get(SelfStudyResponseNewGoalDTO.measureOfSuccess)}
                  {...ValidationUtil.getValidation(SelfStudyResponseNewGoalDTO.measureOfSuccess + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
                  <ReadableTextArea
                    showCount
                    maxLength={2000}
                    disabled={props.disable}
                    readonly={!props.disable && (props.readonly)} />
                </FormItem>
              </>
            }
          </Card>
        </Space>);
    }

    return (
      <Card type='inner' title='Program Goals'>
        <RichTextEditor readonly={true} value={selfStudySectionComponentTemplate?.primaryText} />
        {newGoalNodes}
      </Card >
    );
  }

})

SelfStudyNewGoals.displayName = 'SelfStudyNewGoals';

export default SelfStudyNewGoals;