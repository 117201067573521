// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CourseDTO from './CourseDTO';
import InstitutionCourseDTO from './InstitutionCourseDTO';
import GroupOptionTypeDTO from './GroupOptionTypeDTO';
import WorkBasedLearningTypeDTO from './WorkBasedLearningTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface FlatNewProgramProgramTermCourseGroupOptionDTO extends EntitySelectorDTO { 
	id: string | null | null;
	groupId: string | null | null;
	courseId: string | null | null;
	course: CourseDTO | null;
	institutionCourseId: string | null | null;
	institutionCourse: InstitutionCourseDTO | null;
	courseBlockId: string | null | null;
	description: string | null;
	groupOptionType: GroupOptionTypeDTO | null;
	groupOptionTypeId: number | null | null;
	workBasedLearningTypeId: number | null | null;
	workBasedLearningType: WorkBasedLearningTypeDTO | null;
	order: number;
	generalEducationId: number | null | null;
	apsGeneralEducationId: number | null | null;
	transferMajorCourseBlockId: number | null | null;
	transferMajorLactsCategoryId: number | null | null;
	transferMajorGeneralEducationTypeId: number | null | null;
	transferMajorDisciplineFrameworkElementId: number | null | null;
	credits: MinMaxDTO<number | null> | null;
	footnote: string | null;
	techCore: boolean | null | null;
	embeddedCredits: number | null | null;
	groupIndex: number;
	isVariableCredits: boolean | null | null;
}

const FlatNewProgramProgramTermCourseGroupOptionDTO = {
    className: 'FlatNewProgramProgramTermCourseGroupOptionDTO',
    create: (initValues?: Partial<FlatNewProgramProgramTermCourseGroupOptionDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			groupId: null,
			courseId: null,
			course: null,
			institutionCourseId: null,
			institutionCourse: null,
			courseBlockId: null,
			description: "",
			groupOptionType: null,
			groupOptionTypeId: null,
			workBasedLearningTypeId: null,
			workBasedLearningType: null,
			order: 0,
			generalEducationId: null,
			apsGeneralEducationId: null,
			transferMajorCourseBlockId: null,
			transferMajorLactsCategoryId: null,
			transferMajorGeneralEducationTypeId: null,
			transferMajorDisciplineFrameworkElementId: null,
			credits: null,
			footnote: "",
			techCore: null,
			embeddedCredits: null,
			groupIndex: 0,
			isVariableCredits: null,
        },
        initValues);
    },
	id: 'id',
	groupId: 'groupId',
	courseId: 'courseId',
	course: 'course',
	institutionCourseId: 'institutionCourseId',
	institutionCourse: 'institutionCourse',
	courseBlockId: 'courseBlockId',
	description: 'description',
	groupOptionType: 'groupOptionType',
	groupOptionTypeId: 'groupOptionTypeId',
	workBasedLearningTypeId: 'workBasedLearningTypeId',
	workBasedLearningType: 'workBasedLearningType',
	order: 'order',
	generalEducationId: 'generalEducationId',
	apsGeneralEducationId: 'apsGeneralEducationId',
	transferMajorCourseBlockId: 'transferMajorCourseBlockId',
	transferMajorLactsCategoryId: 'transferMajorLactsCategoryId',
	transferMajorGeneralEducationTypeId: 'transferMajorGeneralEducationTypeId',
	transferMajorDisciplineFrameworkElementId: 'transferMajorDisciplineFrameworkElementId',
	credits: 'credits',
	footnote: 'footnote',
	techCore: 'techCore',
	embeddedCredits: 'embeddedCredits',
	groupIndex: 'groupIndex',
	isVariableCredits: 'isVariableCredits',
};

export default FlatNewProgramProgramTermCourseGroupOptionDTO;