// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// ResetPasswordHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    userId: string | null;
    code: string | null;
    isUserSettings: boolean;
    currentPassword: string | null;
    password: string | null;
    confirmPassword: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            userId: '00000000-0000-0000-0000-000000000000',
            code: "",
            isUserSettings: false,
            currentPassword: "",
            password: "",
            confirmPassword: "",
        },
        initValues);
    },
    userId: 'userId',
    code: 'code',
    isUserSettings: 'isUserSettings',
    currentPassword: 'currentPassword',
    password: 'password',
    confirmPassword: 'confirmPassword',
}

interface Result extends ResultDTO {
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
        },
        initValues);
    },
}

export { Request, Result };