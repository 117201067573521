// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import AARoleTypeDTO from './AARoleTypeDTO';
import RoleTypeDTO from './RoleTypeDTO';
import RoleFeatureFlagDTO from './RoleFeatureFlagDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface RoleDTO extends EntitySelectorDTO { 
	id: string | null;
	name: string | null;
	concurrencyStamp: string | null;
	aaRoleTypeId: number | null | null;
	aaRoleType: AARoleTypeDTO | null;
	roleTypeId: number;
	roleType: RoleTypeDTO | null;
	roleFeatureFlags: RoleFeatureFlagDTO[] | null;
	roleFeatureFlagIds: string[];
}

const RoleDTO = {
    className: 'RoleDTO',
    create: (initValues?: Partial<RoleDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			name: "",
			concurrencyStamp: "",
			aaRoleTypeId: null,
			aaRoleType: null,
			roleTypeId: 0,
			roleType: null,
			roleFeatureFlags: [],
			roleFeatureFlagIds: [],
        },
        initValues);
    },
	id: 'id',
	name: 'name',
	concurrencyStamp: 'concurrencyStamp',
	aaRoleTypeId: 'aaRoleTypeId',
	aaRoleType: 'aaRoleType',
	roleTypeId: 'roleTypeId',
	roleType: 'roleType',
	roleFeatureFlags: 'roleFeatureFlags',
	roleFeatureFlagIds: 'roleFeatureFlagIds',
};

export default RoleDTO;