// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ThirdPartyCredentialTypeDTO from './ThirdPartyCredentialTypeDTO';

interface BaseProgramThirdPartyCredentialDTO {
  id: number;
  programId: string | null;
  thirdPartyCredentialTypeId: number;
  thirdPartyCredentialType: ThirdPartyCredentialTypeDTO | null;
  title: string | null;
  organization: string | null;
  requirement: number | null;
}

const BaseProgramThirdPartyCredentialDTO = {
  className: 'BaseProgramThirdPartyCredentialDTO',
  create: (initValues?: Partial<BaseProgramThirdPartyCredentialDTO> | Record<string, unknown> | null) => {
    return Object.assign(
      {
        id: 0,
        programId: '00000000-0000-0000-0000-000000000000',
        thirdPartyCredentialTypeId: 0,
        thirdPartyCredentialType: null,
        title: "",
        organization: "",
        requirement: null,
      },
      initValues);
  },
  id: 'id',
  programId: 'programId',
  thirdPartyCredentialTypeId: 'thirdPartyCredentialTypeId',
  thirdPartyCredentialType: 'thirdPartyCredentialType',
  title: 'title',
  organization: 'organization',
  requirement: 'requirement',
};

export default BaseProgramThirdPartyCredentialDTO;