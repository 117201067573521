import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Input, InputNumber, message, Select, Skeleton, Space, Table, Typography } from 'antd';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import * as React from 'react';
import { Link } from 'react-router-dom';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import LocationType from '../../../consts/LocationType';
import * as SaveNewProgramChangeRequestStep5Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep5Handler';
import * as SubmitNewProgramChangeRequestStep5Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep5Handler';
import * as GetNewProgramChangeRequestStep5Handler from '../../../handlerModels/GetNewProgramChangeRequestStep5Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import GenderDTO from '../../../models/GenderDTO';
import LocationTypeDTO from '../../../models/LocationTypeDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import NoticeOfIntentChangeRequestDTO from '../../../models/NoticeOfIntentChangeRequestDTO';
import NoticeOfIntentChangeRequestOccupationDTO from '../../../models/NoticeOfIntentChangeRequestOccupationDTO';
import NoticeOfIntentChangeRequestRegionalStatsDTO from '../../../models/NoticeOfIntentChangeRequestRegionalStatsDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import SelectLabel from '../../general/SelectLabel';
import YesNoInput from '../../inputs/YesNoInput';
import Dropdown from '../../inputs/Dropdown';
import Guid from '../../../utils/Guid';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep5FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
  noticeOfIntent: NoticeOfIntentChangeRequestDTO;
  locationTypes: LocationTypeDTO[];
  genders: GenderDTO[];
}

interface NewProgramChangeRequestStep5FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: () => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep5Form extends React.Component<NewProgramChangeRequestStep5FormProps, NewProgramChangeRequestStep5FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getOccupationFormItems = (index: number) => {
    const nonTraditionalByGender = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.occupations)[index]?.nontraditionalByGender;
    const professionalCredentialRequirements = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.occupations)[index]?.professionalCredentialRequirements;

    return new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestOccupationDTO.title, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestOccupationDTO.title],
        label: 'What is the name of the occupation?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestOccupationDTO.averageEntryLevelSalary, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestOccupationDTO.averageEntryLevelSalary],
        label: 'What is the average entry level salary for this occupation?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestOccupationDTO.minimumEducationRequirements, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestOccupationDTO.minimumEducationRequirements],
        label: 'Please provide the minimum educational requirements for this occupation.',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirements, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirements],
        label: 'Are there any professional credential requirements for this occupation?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirementDetails, {
        required: professionalCredentialRequirements,
        name: [index, NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirementDetails],
        label: 'Please provide the professional credential requirements for this occupation.',
        rules: professionalCredentialRequirements ? [ValidationRuleUtil.required()] : []
      })
      .set(NoticeOfIntentChangeRequestOccupationDTO.nontraditionalByGender, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestOccupationDTO.nontraditionalByGender],
        label: 'Is this occupation nontraditional by gender? (Less than 25% of the workforce)',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestOccupationDTO.genderId, {
        required: nonTraditionalByGender,
        name: [index, NoticeOfIntentChangeRequestOccupationDTO.genderId],
        label: 'Which gender is this occupation nontraditional by?',
        rules: nonTraditionalByGender ? [ValidationRuleUtil.required()] : []
      });
  }

  private getRegionalStatsFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestRegionalStatsDTO.locationTypeId, {
        name: [index, NoticeOfIntentChangeRequestRegionalStatsDTO.locationTypeId],

      })
      .set(NoticeOfIntentChangeRequestRegionalStatsDTO.numberOfOpenings, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestRegionalStatsDTO.numberOfOpenings],
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestRegionalStatsDTO.shortTermGrowthRate, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestRegionalStatsDTO.shortTermGrowthRate],
        rules: [
          ValidationRuleUtil.required()
        ]
      })
      .set(NoticeOfIntentChangeRequestRegionalStatsDTO.longTermGrowthRate, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestRegionalStatsDTO.longTermGrowthRate],
        rules: [
          ValidationRuleUtil.required()
        ]
      })
  }


  constructor(props: NewProgramChangeRequestStep5FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      newProgramChangeRequest: NewProgramChangeRequestDTO.create(),
      locationTypes: [],
      genders: [],
      noticeOfIntent: NoticeOfIntentChangeRequestDTO.create({
        externalAccreditationRequirements: null,
        occupations: [
          NoticeOfIntentChangeRequestOccupationDTO.create()
        ]
      }),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep5FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private fetchData = () => {
    const loaders = [];

    if (this.state.locationTypes.length == 0) {
      loaders.push(this.loadLocationTypes());
    }

    if (this.state.genders.length == 0) {
      loaders.push(this.loadGenders());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest())
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadLocationTypes = () => {
    this.setState({
      loading: true
    });
    return LookupsUtil.getAll<LocationTypeDTO>(LocationTypeDTO.className)
      .then((results: LocationTypeDTO[]) => {
        this.setState({ locationTypes: results ?? [] });
      }).catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadGenders = () => {
    this.setState({
      loading: true
    });

    return LookupsUtil.getAll<GenderDTO>(GenderDTO.className)
      .then((results: GenderDTO[]) => {
        this.setState({ genders: results ?? [] });
      }).catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep5Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep5(request)
      .then((results: GetNewProgramChangeRequestStep5Handler.Result) => {
        if (results) {

          let noi = NoticeOfIntentChangeRequestDTO.create({ ...results.changeRequest?.newProgramChangeRequest }) as NoticeOfIntentChangeRequestDTO ?? NoticeOfIntentChangeRequestDTO.create();

          if (noi == null) {
            noi = NoticeOfIntentChangeRequestDTO.create();
          }

          if (noi?.occupations?.length == 0) {
            noi.occupations = [
              NoticeOfIntentChangeRequestOccupationDTO.create({
                regionalStats: [
                  NoticeOfIntentChangeRequestRegionalStatsDTO.create({
                    locationTypeId: LocationType.REGIONAL,
                  }),
                  NoticeOfIntentChangeRequestRegionalStatsDTO.create({
                    locationTypeId: LocationType.STATE,
                  }),
                  NoticeOfIntentChangeRequestRegionalStatsDTO.create({
                    locationTypeId: LocationType.NATIONAL,
                  }),
                ],
                nontraditionalByGender: null,
                professionalCredentialRequirements: null,
                genderId: null
              })
            ]
          }

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            noticeOfIntent: noi,
            newProgramChangeRequest: results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private handleChange = (altered: boolean) => {
    this.setState({ altered: altered });
  }

  private handleSave = () => {
    this.setState({ saving: true });
    const noticeOfIntent = this.state.newProgramChangeRequest;
    noticeOfIntent.occupations = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.occupations) ?? [];
    let newProgramChangeRequest = this.state.changeRequest.newProgramChangeRequest;
    newProgramChangeRequest = noticeOfIntent;

    const request = SaveNewProgramChangeRequestStep5Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest,
    });

    NewProgramChangeRequestApiService.saveStep5(request)
      .then((result: SaveNewProgramChangeRequestStep5Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const noticeOfIntent = this.state.newProgramChangeRequest;
    noticeOfIntent.occupations = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.occupations) ?? [];
    let newProgramChangeRequest = this.state.changeRequest.newProgramChangeRequest;
    newProgramChangeRequest = noticeOfIntent;

    const request = SubmitNewProgramChangeRequestStep5Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest,
    });

    NewProgramChangeRequestApiService.submitStep5(request)
      .then((result: SubmitNewProgramChangeRequestStep5Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  private getRegionalStatsColumnDefinitions = (occupationIndex: number) => {
    return [
      {
        title: 'Location Types',
        dataIndex: NoticeOfIntentChangeRequestRegionalStatsDTO.locationTypeId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: any, index: number) => {
          return (
            <Form.Item
              {...this.getRegionalStatsFormItems(index).get(NoticeOfIntentChangeRequestRegionalStatsDTO.locationTypeId)}>
              <SelectLabel options={this.state.locationTypes.map(x => { return { value: x.id, label: x.name } })} />
            </Form.Item>
          );
        }
      },
      {
        title: 'Number of Openings',
        dataIndex: NoticeOfIntentChangeRequestRegionalStatsDTO.numberOfOpenings,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: any, index: number) => {
          return (
            <Form.Item
              {...this.getRegionalStatsFormItems(index).get(NoticeOfIntentChangeRequestRegionalStatsDTO.numberOfOpenings)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestRegionalStatsDTO.numberOfOpenings + (row.name + 1) + occupationIndex, this.state.fieldErrors, this.state.submitted || this.props.readonly || this.props.readonly)} >
              <InputNumber title='Number of Openings' disabled={this.props.readonly} min={0} />
            </Form.Item>
          );
        },
      },
      {
        title: 'Short Term Growth Rate',
        dataIndex: NoticeOfIntentChangeRequestRegionalStatsDTO.shortTermGrowthRate,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: any, index: number) => {
          return (
            <Form.Item
              {...this.getRegionalStatsFormItems(index).get(NoticeOfIntentChangeRequestRegionalStatsDTO.shortTermGrowthRate)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestRegionalStatsDTO.shortTermGrowthRate + (row.name + 1) + occupationIndex, this.state.fieldErrors, this.state.submitted || this.props.readonly || this.props.readonly)}  >
              <InputNumber title='Short Term Growth Rate' max={999999} disabled={this.props.readonly} prefix='%' min={0} />
            </Form.Item>
          );
        },
      },
      {
        title: 'Long Term Growth Rate',
        dataIndex: NoticeOfIntentChangeRequestRegionalStatsDTO.longTermGrowthRate,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: any, index: number) => {
          return (
            <Form.Item
              {...this.getRegionalStatsFormItems(row.name).get(NoticeOfIntentChangeRequestRegionalStatsDTO.longTermGrowthRate)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestRegionalStatsDTO.longTermGrowthRate + (row.name + 1) + occupationIndex, this.state.fieldErrors, this.state.submitted || this.props.readonly || this.props.readonly)} >
              <InputNumber title='Long Term Growth Rate' max={999999} disabled={this.props.readonly} prefix='%' min={0} />
            </Form.Item>
          );
        },
      }
    ]
  };

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.noticeOfIntent}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}>
          <Space direction="vertical">
            <Typography.Paragraph italic={true}>
              Please review the labor market information below for occupations related to the new program. Ensure that you have added all occupations for which you have information.
            </Typography.Paragraph>

            <Alert type='info' message='The following links contain information to aid you in determining the labor market information for this program.' description={
              <ul>
                <li>
                  <Link to={{ pathname: 'https://iowa.emsicc.com/' }} target="_blank" >Career Coach</Link>
                </li>
                <li>
                  <Link to={{ pathname: 'https://www.iowaworkforcedevelopment.gov/iowa-wage-report' }} target="_blank" >Iowa Workforce Development (Wages)</Link>
                </li>
                <li>
                  <Link to={{ pathname: 'https://www.iowaworkforcedevelopment.gov/occupational-projections' }} target="_blank" >Iowa Workforce Development (Occupational Projections)</Link>
                </li>
                <li>
                  <Link to={{ pathname: 'https://www.edeps.org/' }} target="_blank" >EDEPS</Link>
                </li>
                <li>
                  <Link to={{ pathname: 'https://www.economicmodeling.com/data/' }} target="_blank" >EMSI</Link>
                </li>
              </ul>} />

            <Form.List name={NoticeOfIntentChangeRequestDTO.occupations}>
              {
                (occupations, { add, remove }) => {
                  return (
                    <Space direction='vertical'>
                      {occupations.map((occupation, index) => {
                        return this.renderOccupation(occupation, remove, index);
                      })}
                      {this.renderAddOccupation(add)}
                    </Space>);
                }
              }
            </Form.List>
            {this.renderActions()}
          </Space>
        </Form >
      </Space >
    );
  }

  private renderActions() {
    if (!this.props.readonly) {
      return < Space direction='horizontal' wrap={true} >
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved}></SaveButton>
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space >
    }
  }

  renderAddOccupation(add: any) {
    if (!this.props.readonly) {
      return (<Button onClick={() => add(
        NoticeOfIntentChangeRequestOccupationDTO.create({
          regionalStats: [
            NoticeOfIntentChangeRequestRegionalStatsDTO.create({
              locationTypeId: LocationType.REGIONAL,
            }),
            NoticeOfIntentChangeRequestRegionalStatsDTO.create({
              locationTypeId: LocationType.STATE,
            }),
            NoticeOfIntentChangeRequestRegionalStatsDTO.create({
              locationTypeId: LocationType.NATIONAL,
            }),
          ],
          nontraditionalByGender: null,
          professionalCredentialRequirements: null,
          genderId: null
        }))} icon={<PlusOutlined />}>Add Additional Occupation</Button>);
    }
  }

  renderOccupation(occupation: any, remove: any, index: number) {
    const canRemove = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.occupations)?.length > 1 ?? false;
    const title = 'Occupation ' + (canRemove ? (index + 1) : '');
    const deleteButton = <Button type="link" size="small" onClick={() => remove(occupation.name)} icon={<DeleteOutlined />} />;

    const formItems = this.getOccupationFormItems(index);

    return (
      <Card type='inner' size='small' title={title} extra={canRemove && !this.props.readonly ? deleteButton : null}>
        <Space direction='vertical'>
          <Form.Item
            {...formItems.get(NoticeOfIntentChangeRequestOccupationDTO.title)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestOccupationDTO.title + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Input disabled={this.props.readonly} />
          </Form.Item>

          <Form.Item
            {...formItems.get(NoticeOfIntentChangeRequestOccupationDTO.averageEntryLevelSalary)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestOccupationDTO.averageEntryLevelSalary + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <InputNumber disabled={this.props.readonly} min={0} addonBefore='$' />
          </Form.Item>

          <Typography.Text italic={true}>
            Please provide the following statistics about this occupation for each of the levels outlined below.
          </Typography.Text>

          <Form.List name={[index, NoticeOfIntentChangeRequestOccupationDTO.regionalStats]}>
            {
              (regionalStats) => {
                return (
                  <Space direction='vertical'>
                    <Table pagination={false} dataSource={regionalStats} columns={this.getRegionalStatsColumnDefinitions(index)} />
                  </Space>
                );
              }
            }
          </Form.List>

          <Form.Item label="Is this occupation nontraditional by gender? (Less than 25% of the workforce)"
            {...formItems.get(NoticeOfIntentChangeRequestOccupationDTO.nontraditionalByGender)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestOccupationDTO.nontraditionalByGender + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <YesNoInput disabled={this.props.readonly} />
          </Form.Item>

          {this.renderGender(index, formItems)}

          <Form.Item
            {...formItems.get(NoticeOfIntentChangeRequestOccupationDTO.minimumEducationRequirements)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestOccupationDTO.minimumEducationRequirements + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </Form.Item>

          <Form.Item
            {...formItems.get(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirements)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirements + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <YesNoInput disabled={this.props.readonly} />
          </Form.Item>
          {this.renderProfessionalCredentialRequirementDetails(index, formItems)}
        </Space >
      </Card >
    );
  }

  renderGender(index: number, formItems: Map<string, FormItemProps>) {
    if (this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.occupations)[index]?.nontraditionalByGender) {
      return (
        <Form.Item
          {...formItems.get(NoticeOfIntentChangeRequestOccupationDTO.genderId)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestOccupationDTO.genderId + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
          <Dropdown disabled={this.props.readonly} dropdownMatchSelectWidth={false}>
            {this.state.genders.map(y => { return <Select.Option key={y.id} value={y.id}>{y.name}</Select.Option> })}
          </Dropdown>
        </Form.Item>
      );
    }
  }

  renderProfessionalCredentialRequirementDetails(index: number, formItems: Map<string, FormItemProps>) {
    if (this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.occupations)[index]?.professionalCredentialRequirements) {
      return (
        <Form.Item
          {...formItems.get(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirementDetails)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirementDetails + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
          <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
        </Form.Item>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NewProgramChangeRequestStep5Form