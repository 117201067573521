import { WarningFilled } from '@ant-design/icons';
import * as React from 'react';
import ChangeRequestApiService from '../../../api/ChangeRequestApiService';
import Routes from '../../../config/Routes';
import ChangeRequestStatusType from '../../../consts/ChangeRequestStatusType';
import FeatureFlag from '../../../consts/FeatureFlag';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface ChangeRequestDataTableProps {
  courseId?: string;
  institution?: InstitutionDTO | null;
  userId?: string | null;
  programId?: string;
  changeRequestTypeId?: number;
  instituionId?: string | null;
}

interface ChangeRequestDataTableState extends BaseDataTableState<ChangeRequestDTO> {
  addNewChangeRequest: boolean;
  changeRequestTypes: TableFilterOption[];
  institutions: TableFilterOption[];
  changeRequestStatusTypes: TableFilterOption[];
}

class ChangeRequestDataTable extends React.Component<ChangeRequestDataTableProps, ChangeRequestDataTableState> {
  private _dataTable: DataTable<ChangeRequestDTO> | undefined;

  constructor(props: ChangeRequestDataTableProps) {
    super(props);

    this.state = {
      addNewChangeRequest: false,
      changeRequestTypes: [],
      institutions: [],
      changeRequestStatusTypes: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    TableFilterUtil.ChangeRequestTypeOptions()
      .then((x: TableFilterOption[]) => {
        this.setState({
          changeRequestTypes: x ?? []
        });
      });

    TableFilterUtil.Institutions()
      .then((x: TableFilterOption[]) => {
        this.setState({
          institutions: x ?? []
        });
      });

    TableFilterUtil.ChangeRequestStatusTypes()
      .then((x: TableFilterOption[]) => {
        this.setState({
          changeRequestStatusTypes: x ?? []
        });
      });
  }

  private getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Number',
        dataIndex: ChangeRequestDTO.displayNumber,
        filterType: FilterType.Text,
        sorter: true,
        width: 125,
      },
      {
        title: 'Type',
        dataIndex: ChangeRequestDTO.changeRequestTypeId,
        render: (data: string, row: ChangeRequestDTO) => {
          return row.changeRequestType?.name;
        },
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.changeRequestTypes,
        width: 200,
      },

    ] as DataTableColumnProps<any>[];

    if (!this.props.programId && !this.props.courseId) {
      columns.push({
        title: 'Description',
        dataIndex: ChangeRequestDTO.title,
        filterType: FilterType.Text,
        width: 200,
      });
    }

    if (this.props.userId == null || this.props.userId == undefined || this.props.userId == Guid.Empty()) {
      columns.push({
        title: 'Requesting User',
        dataIndex: ChangeRequestDTO.requestingUserId,
        render: (data: string, row: ChangeRequestDTO) => {
          return row.requestingUser?.fullName;
        },
        filterType: FilterType.Text,
        sorter: true,
        width: 200,
      });
    }

    if (this.props.institution == null && !this.props.programId && !this.props.courseId) {
      columns.push({
        title: 'Community College',
        dataIndex: ChangeRequestDTO.institutionId,
        render: (data: InstitutionDTO, row: ChangeRequestDTO) => {
          return row.institution?.name;
        },
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.institutions,
        width: 200,
      });
    }

    columns.push({
      title: 'Created',
      dataIndex: ChangeRequestDTO.createdOn,
      render: (data: string, row: ChangeRequestDTO) => {
        if (row.createdOn) {
          return DateTimeUtil.shortDate(row.createdOn);
        }
      },
      filterType: FilterType.DateRange,
      sorter: true,
      width: 175,
    });

    columns.push({
      title: 'Submitted',
      dataIndex: ChangeRequestDTO.requestedOn,
      render: (data: string, row: ChangeRequestDTO) => {
        if (row.requestedOn) {
          return DateTimeUtil.shortDate(row.requestedOn);
        }
      },
      filterType: FilterType.DateRange,
      sorter: true,
      width: 175,
    });

    columns.push({
      title: 'Approved',
      dataIndex: ChangeRequestDTO.approvedOn,
      render: (data: string, row: ChangeRequestDTO) => {
        if (row.approvedOn) {
          return DateTimeUtil.shortDate(row.approvedOn);
        }
      },
      filterType: FilterType.DateRange,
      sorter: true,
      width: 175,
    });

    columns.push({
      title: 'Status',
      dataIndex: ChangeRequestDTO.statusTypeId,
      render: (data: string, row: ChangeRequestDTO) => {
        return row.changeRequestStatusType?.name;
      },
      sorter: true,
      filterType: FilterType.DropdownMulti,
      dropdownFilterOptions: this.state.changeRequestStatusTypes,
      width: 100,
    });

    columns.push({
      title: 'Expired',
      dataIndex: ChangeRequestDTO.isExpired,
      align: 'center',
      render: (data: string, row: ChangeRequestDTO) => {
        return row.isExpired ? <WarningFilled /> : null;
      },
      sorter: false,
      filterType: FilterType.NONE,
      width: 25,
    });

    return columns;
  }

  private openChangeRequest = (record: ChangeRequestDTO) => {
    if (record.id) {
      switch (record.statusTypeId) {
        case ChangeRequestStatusType.DRAFT:
        case ChangeRequestStatusType.ATTENTIONREQUIRED:
          this.openDraft(record);
          break;
        default:
          this.openDetails(record);
          break;
      }
    }
  }

  private openDraft = (changeRequest: ChangeRequestDTO) => {
    if (changeRequest.id) {
      if (changeRequest.requestingUserId == this.props.userId || AuthorizationUtil.isAuthorized([FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER])) {
        HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_DRAFT_ID, { id: changeRequest.id }, {}));
      }
      else {
        this.openDetails(changeRequest);
      }
    }
  }

  private openDetails = (changeRequest: ChangeRequestDTO) => {
    if (changeRequest.id) {
      HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_DETAILS, { id: changeRequest.id }, {}));
    }
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    if (AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_CHANGE_REQUEST, FeatureFlag.EDIT_CHANGE_REQUEST])) {
      actionButtons.push(
        DataTableButtonUtil.Export(
          (tableRequest: TableRequestDTO) => {
            this.setState({ exporting: true });
            return ChangeRequestApiService.getChangeRequestsDataTableExport(tableRequest, this.props.institution?.id ?? Guid.Empty(), this.props.userId ?? Guid.Empty()).then(file => {
              this.setState({ exporting: false });
              FileDownload.downloadBase64StringAsFile(`ChangeRequest_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
            });
          },
          this.state.exporting
        )
      );
    }

    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        stateSaving={{ enabled: true, tableUniqueKey: 'changeRequest_list' }}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) =>
            ChangeRequestApiService.getChangeRequestTableData(requestState, this.props.institution?.id ?? this.props.instituionId ?? Guid.Empty(), this.props.userId ?? Guid.Empty(), this.props.programId ?? Guid.Empty(), this.props.courseId ?? Guid.Empty())
        }}
        onRowClick={this.openChangeRequest} />
    );
  }
}

export default ChangeRequestDataTable;
