// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import NoticeOfIntentChangeRequestOccupationDTO from './NoticeOfIntentChangeRequestOccupationDTO';
import LocationTypeDTO from './LocationTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoticeOfIntentChangeRequestRegionalStatsDTO extends EntitySelectorDTO { 
	id: string | null;
	noticeOfIntentChangeRequestOccupationId: string | null;
	noticeOfIntentChangeRequestOccupation: NoticeOfIntentChangeRequestOccupationDTO | null;
	locationTypeId: number;
	locationType: LocationTypeDTO | null;
	numberOfOpenings: number | null | null;
	shortTermGrowthRate: number | null | null;
	longTermGrowthRate: number | null | null;
}

const NoticeOfIntentChangeRequestRegionalStatsDTO = {
    className: 'NoticeOfIntentChangeRequestRegionalStatsDTO',
    create: (initValues?: Partial<NoticeOfIntentChangeRequestRegionalStatsDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequestOccupationId: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequestOccupation: null,
			locationTypeId: 0,
			locationType: null,
			numberOfOpenings: null,
			shortTermGrowthRate: null,
			longTermGrowthRate: null,
        },
        initValues);
    },
	id: 'id',
	noticeOfIntentChangeRequestOccupationId: 'noticeOfIntentChangeRequestOccupationId',
	noticeOfIntentChangeRequestOccupation: 'noticeOfIntentChangeRequestOccupation',
	locationTypeId: 'locationTypeId',
	locationType: 'locationType',
	numberOfOpenings: 'numberOfOpenings',
	shortTermGrowthRate: 'shortTermGrowthRate',
	longTermGrowthRate: 'longTermGrowthRate',
};

export default NoticeOfIntentChangeRequestRegionalStatsDTO;