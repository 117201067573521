import { message, Space, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ChangeRequestApiService from '../../../api/ChangeRequestApiService';
import * as RedirectApprovalHandler from '../../../handlerModels/RedirectApprovalHandler';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import ValidationUtil from '../../../utils/ValidationUtil';
import SubmitButton from '../../buttons/SubmitButton';
import UserSearchInput from '../../general/UserSearchInput';

interface ApprovalRedirectFormState extends BaseFormState {
}

interface ApprovalRedirectFormProps extends BaseFormProps {
  onSuccess: () => void;
  approvalId: string;
}

class ApprovalRedirectForm extends React.Component<ApprovalRedirectFormProps, ApprovalRedirectFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set('user', {
      required: true,
      name: 'user',
    });

  constructor(props: ApprovalRedirectFormProps) {
    super(props);

    this.state = {};
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = this._formRef.current?.getFieldsValue();

    if (model.user != undefined && model.user != null) {
      const request = RedirectApprovalHandler.Request.create({
        changeRequestApprovalId: this.props.approvalId,
        userId: model.user.id
      });

      ChangeRequestApiService.redirectApproval(request)
        .then((result: RedirectApprovalHandler.Result) => {
          if (result?.succeeded) {
            message.success('Success!');

            if (this.props.onSuccess) {
              this.props.onSuccess();
            }
          }
          else {
            this.setState({
              error: !result?.succeeded,
              message: result?.errors.join('\n'),
              fieldErrors: result?.fieldErrors
            });

            message.error('Error Saving');
          }
        })
        .catch((results: any) => {
          this.setState({ error: results });
          message.error('Gender could not be saved.');
        })
        .finally(() => {
          this.setState({ loading: false, submitting: false });
        });
    }
    else {
      message.error('Error Redirecting');
    }
  }

  render() {
    return (
      <Space size="small" direction="vertical">
        <Typography.Paragraph>
          Please search for and select a user that you would like to redirect this approval to.
        </Typography.Paragraph>
        <Form ref={this._formRef}
          layout="vertical"
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <Form.Item
            {...this._formItems.get('user')}
            {...ValidationUtil.getValidation('user', this.state.fieldErrors, this.state.submitted)}>
            <UserSearchInput />
          </Form.Item>
          <SubmitButton />
        </Form>
      </Space>
    );
  }
}

export default ApprovalRedirectForm;
