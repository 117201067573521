// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SpecialEmphasisDataTableDTO from '../models/SpecialEmphasisDataTableDTO';
import * as GetSpecialEmphasisDetailsHandler from '../handlerModels/GetSpecialEmphasisDetailsHandler';
import * as SaveSpecialEmphasisDetailsHandler from '../handlerModels/SaveSpecialEmphasisDetailsHandler';
import * as GetSpecialEmphasisHandler from '../handlerModels/GetSpecialEmphasisHandler';
import * as DeleteSpecialEmphasisHandler from '../handlerModels/DeleteSpecialEmphasisHandler';

export class SpecialEmphasisApiService extends BaseApi {

    // post: api/specialEmphases
    public getSpecialEmphasisTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<SpecialEmphasisDataTableDTO>> {
        let url = `api/specialEmphases`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SpecialEmphasisDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/specialEmphases/export
    public getSpecialEmphasisDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/specialEmphases/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/specialEmphases/${id}
    public getSpecialEmphasisDetails(id: number): Promise<GetSpecialEmphasisDetailsHandler.Result> {
        let url = `api/specialEmphases/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSpecialEmphasisDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/specialEmphases/save
    public saveSpecialEmphasisDetails(saveSpecialEmphasisDetails: SaveSpecialEmphasisDetailsHandler.Request): Promise<SaveSpecialEmphasisDetailsHandler.Result> {
        let url = `api/specialEmphases/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSpecialEmphasisDetailsHandler.Result>(saveSpecialEmphasisDetails, 'post', url, true, false);
    }

    // post: api/specialEmphases/getSpecialEmphases
    public getSpecialEmphases(): Promise<GetSpecialEmphasisHandler.Result> {
        let url = `api/specialEmphases/getSpecialEmphases`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSpecialEmphasisHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/specialEmphases/deleteSpecialEmphasis
    public deleteSpecialEmphasis(deleteSpecialEmphasis: DeleteSpecialEmphasisHandler.Request): Promise<DeleteSpecialEmphasisHandler.Result> {
        let url = `api/specialEmphases/deleteSpecialEmphasis`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteSpecialEmphasisHandler.Result>(deleteSpecialEmphasis, 'post', url, true, false);
    }
}
var service = new SpecialEmphasisApiService();
export default service;
