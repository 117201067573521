// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetFeatureFlagGroupsHandler from '../handlerModels/GetFeatureFlagGroupsHandler';

export class FeatureFlagsApiService extends BaseApi {

    // post: api/roles/getFeatureFlags
    public getFeatureFlags(getFeatureFlagGroups: GetFeatureFlagGroupsHandler.Request): Promise<GetFeatureFlagGroupsHandler.Result> {
        let url = `api/roles/getFeatureFlags`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetFeatureFlagGroupsHandler.Result>(getFeatureFlagGroups, 'post', url, true, false);
    }
}
var service = new FeatureFlagsApiService();
export default service;
