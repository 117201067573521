// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class ChangeRequestType {
    public static readonly ADMINISTRATIVECHANGE: number = 1;
    public static readonly NOTICEOFINTENT: number = 2;
    public static readonly NEWCOURSE: number = 3;
    public static readonly NEWDISCIPLINE: number = 4;
    public static readonly NEWPROGRAM: number = 5;
    public static readonly PROGRAMDEACTIVATION: number = 6;
    public static readonly PROGRAMMODIFICATION: number = 7;
    public static readonly COURSEMODIFICATION: number = 8;
    public static readonly TRANSFERMAJORPROGRAMMODIFICATION: number = 9;
    public static readonly NEWTRANSFERMAJORPROGRAM: number = 10;
    public static readonly COURSEDEACTIVATION: number = 11;
}
