import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import SecondaryProgramDetailsForm from '../forms/secondary/SecondaryProgramDetailsForm';


interface SecondaryProgramDetailsModalProps extends ModalProps {
  secondaryProgramId: string;
  academicYear: number;
  onSave?: () => void;
}

function SecondaryProgramDetailsModal(props: SecondaryProgramDetailsModalProps) {
  const _secondaryProgramDetailsRef = React.createRef<SecondaryProgramDetailsForm>();

  const isNew = props.secondaryProgramId == Guid.Empty();

  const submitSaveSecondaryProgram = () => {
    _secondaryProgramDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New Program' : 'Edit Program'}
      okText='Save'
      onOk={submitSaveSecondaryProgram}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <SecondaryProgramDetailsForm
        ref={_secondaryProgramDetailsRef}
        fromDataReview={true}
        secondaryProgramId={props.secondaryProgramId ?? Guid.Empty}
        isEditing={true}
        onSave={props.onSave}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default SecondaryProgramDetailsModal;