import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ContactTypeDataTable from '../../../../components/datatables/postSecondary/ContactTypeDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface ContactTypeListProps {
}

interface ContactTypeListState {
}

class ContactTypeListPage extends React.Component<ContactTypeListProps & RouteComponentProps<RouteObject>, ContactTypeListState> {
  constructor(props: ContactTypeListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Contact Types" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.contactTypeList()} />

        <Card>
          <ContactTypeDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(ContactTypeListPage);
