import { Steps, version } from 'antd';
import * as React from 'react';
import Routes from '../../../config/Routes';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import Guid from '../../../utils/Guid';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';

interface ProgramModificationChangeRequestStepsProps {
  current: number;
  changeRequestId: string;
  changeRequest?: ChangeRequestDTO;
  awardId?: string;
}

function ProgramModificationChangeRequestSteps(props: ProgramModificationChangeRequestStepsProps) {
  const subAwards = props.changeRequest ? props.changeRequest?.programModificationChangeRequest?.programModificationAwards?.map(x => {
    const currentAward = props.awardId == x.id;
    const current = props.current != 2 && props.current != 3 ? -1 : props.current - 2;
    return (
      <Steps.Step key={x.id} title={x.title ?? 'New Award'} description={
        <Steps current={current} direction="vertical" size="small" progressDot={true}>
          <Steps.Step title={<NavigationStep enabled={(!currentAward || props.current != 2) && props.changeRequestId != Guid.Empty()} title={x.title + ' Award Details'} route={Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_3, { id: props.changeRequestId, awardId: x.id ?? Guid.Empty() })}/>} />
          <Steps.Step  title={<NavigationStep enabled={(!currentAward || props.current != 3) && props.changeRequestId != Guid.Empty()} title={x.title + ' Term Details'} route={Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_4, { id: props.changeRequestId, awardId: x.id ?? Guid.Empty() })}/>} />
        </Steps>
      } />);
  }) : [];

  const awardSteps = (
    <Steps direction="vertical" size="small" progressDot={true}>
      {subAwards}
    </Steps>
  )

  let currentStep = props.current;
  if (currentStep == 2) {
    currentStep -= 1;
  }
  else if (currentStep >= 3) {
    currentStep -= 2;
  }
console.log(version, props.current)
  return (
    <CollapsibleSteps current={currentStep} direction='vertical'>
      <Steps.Step  title={<NavigationStep  enabled={props.current != 0 && props.changeRequestId != Guid.Empty()} title='Program Details' route={Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_1, { id: props.changeRequestId })}/>} />
      <Steps.Step  description={awardSteps} title={<NavigationStep enabled={props.current != 1 && props.changeRequestId != Guid.Empty()} title='Awards' route={Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_2, { id: props.changeRequestId })} />} />
      <Steps.Step  title={<NavigationStep enabled={props.current != 4 && props.changeRequestId != Guid.Empty()} title='Advisory Board and Curriculum Committee' route={Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_5, { id: props.changeRequestId })}/>} />
      <Steps.Step  title={<NavigationStep enabled={props.current != 5 && props.changeRequestId != Guid.Empty()} title='Additional Information' route={Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_6, { id: props.changeRequestId })}/>} />
      <Steps.Step  title={<NavigationStep enabled={props.current != 6 && props.changeRequestId != Guid.Empty()} title='Review' route={Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_REVIEW, { id: props.changeRequestId })} />} />
      <Steps.Step title='Submitted' />
    </CollapsibleSteps>
  );
}

export default ProgramModificationChangeRequestSteps;
