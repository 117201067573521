import { Card, Skeleton, Space } from 'antd';
import * as React from 'react';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import ChangeRequestAdditionalDetailsForm from '../../forms/postSecondary/ChangeRequestAdditionalDetailsForm';
import NewDisciplineChangeRequestStep1Form from '../../forms/postSecondary/NewDisciplineChangeRequestStep1Form';

interface NewDisciplineChangeRequestDetailsState extends BaseFormState {
}

interface NewDisciplineChangeRequestDetailsProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequest: ChangeRequestDTO;
}

class NewDisciplineChangeRequestDetails extends React.Component<NewDisciplineChangeRequestDetailsProps, NewDisciplineChangeRequestDetailsState> {
  constructor(props: NewDisciplineChangeRequestDetailsProps) {
    super(props);

    this.state = {};
  }

  public render = () => {
    if (this.props.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space direction="vertical">
        <Card type="inner" size="small" title="Discipline Details" >
          <NewDisciplineChangeRequestStep1Form readonly={true} loading={this.props.loading} selectedInstitution={this.props.changeRequest.institutionId} changeRequestId={this.props.changeRequest.id} fieldErrors={this.props.fieldErrors} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card type="inner" size="small" title="Additional Details" >
          <ChangeRequestAdditionalDetailsForm readonly={true} loading={this.props.loading} changeRequest={this.props.changeRequest} />
        </Card>
      </Space>
    );
  }
}

export default NewDisciplineChangeRequestDetails;