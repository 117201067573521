import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RacialEthnicGroupDataTable from '../../../components/datatables/RacialEthnicGroupsDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import HeaderPortal from '../../../portals/HeaderPortal';
import HistoryUtil from '../../../utils/HistoryUtil';

interface RacialEthnicGroupListProps {
}

interface RacialEthnicGroupListState {
}

class RacialEthnicGroupListPage extends React.Component<RacialEthnicGroupListProps & RouteComponentProps<RouteObject>, RacialEthnicGroupListState> {
  constructor(props: RacialEthnicGroupListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Racial/Ethnic Groups" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.racialEthnicGroupList()} />

        <Card >
          <RacialEthnicGroupDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(RacialEthnicGroupListPage);
