import { Alert, Checkbox, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ThirdPartyCredentialTypeApiService from '../../api/ThirdPartyCredentialTypeApiService';
import FeatureFlag from '../../consts/FeatureFlag';
import * as GetThirdPartyCredentialTypeDetailsHandler from '../../handlerModels/GetThirdPartyCredentialTypeDetailsHandler';
import * as SaveThirdPartyCredentialTypeDetailsHandler from '../../handlerModels/SaveThirdPartyCredentialTypeDetailsHandler';
import ThirdPartyCredentialTypeDTO from '../../models/ThirdPartyCredentialTypeDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import LookupsUtil from '../../utils/LookupsUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import AuthorizedContent from '../AuthorizedContent';
import ResetButton from '../buttons/ResetButton';
import SaveButton from '../buttons/SaveButton';

interface ThirdPartyCredentialTypeDetailsFormState extends BaseFormState {
  thirdPartyCredentialTypeId: number;
  thirdPartyCredentialType: ThirdPartyCredentialTypeDTO | null;
}

interface ThirdPartyCredentialTypeDetailsFormProps extends BaseFormProps {
  thirdPartyCredentialTypeId: number;
  onSave?: (thirdPartyCredentialTypeId: number) => void;
}

class ThirdPartyCredentialTypeDetailsForm extends React.Component<ThirdPartyCredentialTypeDetailsFormProps, ThirdPartyCredentialTypeDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(ThirdPartyCredentialTypeDTO.name, {
      required: true,
      name: ThirdPartyCredentialTypeDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(50)
      ]
    })
    .set(ThirdPartyCredentialTypeDTO.enabledForPostSecondaryProgram, {
      name: ThirdPartyCredentialTypeDTO.enabledForPostSecondaryProgram,
      label: 'Enable for Post Secondary Program',
      rules: [
      ]
    })
    .set(ThirdPartyCredentialTypeDTO.enabledForSecondaryProgram, {
      name: ThirdPartyCredentialTypeDTO.enabledForSecondaryProgram,
      label: 'Enable for Secondary Program',
      rules: [
      ]
    });

  constructor(props: ThirdPartyCredentialTypeDetailsFormProps) {
    super(props);

    this.state = {
      thirdPartyCredentialTypeId: 0,
      thirdPartyCredentialType: ThirdPartyCredentialTypeDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadThirdPartyCredentialType();
  }

  componentDidUpdate(prevProps: ThirdPartyCredentialTypeDetailsFormProps) {
    if (this.props.thirdPartyCredentialTypeId && this.props.thirdPartyCredentialTypeId != prevProps.thirdPartyCredentialTypeId) {
      this.setState({ thirdPartyCredentialTypeId: this.props.thirdPartyCredentialTypeId });
      this.loadThirdPartyCredentialType();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadThirdPartyCredentialType() {
    if (this.props.thirdPartyCredentialTypeId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.thirdPartyCredentialTypeId) {
      ThirdPartyCredentialTypeApiService.getThirdPartyCredentialTypeDetails(this.props.thirdPartyCredentialTypeId)
        .then((results: GetThirdPartyCredentialTypeDetailsHandler.Result) => {
          if (results && results.thirdPartyCredentialType) {
            this.setState({ thirdPartyCredentialTypeId: results.thirdPartyCredentialType.id, thirdPartyCredentialType: results.thirdPartyCredentialType, loading: false });
          }
          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ thirdPartyCredentialType: ThirdPartyCredentialTypeDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveThirdPartyCredentialTypeDetailsHandler.Request.create({
      thirdPartyCredentialTypeId: this.state.thirdPartyCredentialTypeId,
      thirdPartyCredentialType: ThirdPartyCredentialTypeDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    ThirdPartyCredentialTypeApiService.saveThirdPartyCredentialTypeDetails(request)
      .then((result: SaveThirdPartyCredentialTypeDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          thirdPartyCredentialType: result?.thirdPartyCredentialType,
        });

        if (result?.succeeded) {
          message.success('Saved');
          if (this.props.onSave && result.thirdPartyCredentialType?.id) {
            this.props.onSave(result.thirdPartyCredentialType.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(ThirdPartyCredentialTypeDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('ThirdPartyCredentialType could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('ThirdPartyCredentialType could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.thirdPartyCredentialType ?? ThirdPartyCredentialTypeDTO.create()}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(ThirdPartyCredentialTypeDTO.name)}
            {...ValidationUtil.getValidation(ThirdPartyCredentialTypeDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            valuePropName='checked'
            {...this._formItems.get(ThirdPartyCredentialTypeDTO.enabledForPostSecondaryProgram)}
            {...ValidationUtil.getValidation(ThirdPartyCredentialTypeDTO.enabledForPostSecondaryProgram, this.state.fieldErrors, this.state.submitted)}>
            <Checkbox
              disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
            </Checkbox>
          </FormItem>
          <FormItem
            valuePropName='checked'
            {...this._formItems.get(ThirdPartyCredentialTypeDTO.enabledForSecondaryProgram)}
            {...ValidationUtil.getValidation(ThirdPartyCredentialTypeDTO.enabledForSecondaryProgram, this.state.fieldErrors, this.state.submitted)}>
            <Checkbox
              disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
            </Checkbox>
          </FormItem>

          {this.renderSave()}
        </Form >
      </Space >
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_THIRD_PARTY_CREDENTIAL_TYPE]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default ThirdPartyCredentialTypeDetailsForm;
