import { Select, Skeleton, Space } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useEffect, useImperativeHandle, useState } from 'react';
import DistrictApiService from '../../../api/DistrictApiService';
import * as GetDistrictsHandler from '../../../handlerModels/GetDistrictsHandler';
import DistrictContactDTO from '../../../models/DistrictContactDTO';
import DistrictContactTypeDTO from '../../../models/DistrictContactTypeDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import Dropdown from '../../inputs/Dropdown';
import ReadableTextBox from '../../inputs/ReadableTextBox';

interface DistrictContactDetailsFormProps extends BaseFormProps {
  districtContact: DistrictContactDTO | undefined;
  academicYear: number;
  districtId?: string;
  submitted?: boolean
  onSuccess?: (value: string) => void;
  resetForm?: (value: string) => void;
}

const DistrictContactDetailsForm = React.forwardRef((props: DistrictContactDetailsFormProps, ref) => {

  const _formItems = new Map<string, FormItemProps>()
    .set(DistrictContactDTO.fullName, {
      label: 'Full Name',
      required: true,
      name: DistrictContactDTO.fullName,
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(DistrictContactDTO.title, {
      label: 'Title',
      required: false,
      name: DistrictContactDTO.title,
    })
    .set(DistrictContactDTO.email, {
      label: 'Email',
      required: true,
      name: DistrictContactDTO.email,
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(DistrictContactDTO.phone, {
      label: 'Phone Number',
      required: false,
      name: DistrictContactDTO.phone
    })
    .set(DistrictContactDTO.districtId, {
      name: DistrictContactDTO.districtId,
      label: 'District',
    })
    .set(DistrictContactDTO.districtContactTypeId, {
      required: true,
      name: DistrictContactDTO.districtContactTypeId,
      label: 'Contact Type',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    });

  useImperativeHandle(ref, () => ({
    resetForm: () => props.resetForm
  }));

  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [districtContactTypes, setDistrictContactTypes] = useState([] as DistrictContactTypeDTO[]);
  const [districtContact, setDistrictContact] = useState<DistrictContactDTO | undefined>(undefined);
  const [districts, setDistricts] = useState<DistrictDTO[]>([] as DistrictDTO[]);

  useEffect(() => {
    setDistrictContact(props.districtContact);
  }, [props.districtContact]
  )

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (!districtContactTypes || districtContactTypes.length == 0) {
      loaders.push(loadDistrictContactTypes());
    }

    if (!districts || districts.length == 0) {
      loaders.push(loadDistricts());
    }

    Promise.all(loaders).then(() => {
      setLoading(false);
      if (props.resetForm) {
        props.resetForm;
      }
    });
  }

  const loadDistricts = () => {
    const request = GetDistrictsHandler.Request.create({
      academicYearId: props.academicYear ?? 0
    });

    DistrictApiService.getDistricts(request)
      .then((results: GetDistrictsHandler.Result) => {
        if (results) {
          setDistricts(results.districts ?? []);
        }
      });
  }

  const loadDistrictContactTypes = () => {
    return LookupsUtil.getAll<DistrictContactTypeDTO>(DistrictContactTypeDTO.className)
      .then((results: DistrictContactTypeDTO[]) => {
        if (results) {
          setDistrictContactTypes(results);
        }
      }).catch(() => {
        setError(true);
      });
  }

  const renderDistrictContact = (contactType: DistrictContactTypeDTO) => {
    if (contactType.id) {
      return <Select.Option key={contactType.id ?? Guid.Empty()} value={contactType.id ?? Guid.Empty()}> {contactType.name}</Select.Option>
    }
  }

  const renderDistrict = (district: DistrictDTO) => {
    if (district.id) {
      return <Select.Option key={district.id ?? Guid.Empty()} value={district.id ?? Guid.Empty()}> {district.display}</Select.Option>
    }
  }

  if (loading) {
    return <Skeleton active />
  }
  else {

    return (
      <Space size="small" direction="vertical" className={'ant-form ant-form-vertical'}>
        <FormItem
          className={'description-container'}
          initialValue={districtContact?.fullName}
          {..._formItems.get(DistrictContactDTO.fullName)}
          {...ValidationUtil.getValidation(DistrictContactDTO.fullName, props.fieldErrors, props.submitted)}>
          <ReadableTextBox disabled={loading} />
        </FormItem>

        <FormItem
          className={'description-container'}
          initialValue={districtContact?.title}
          {..._formItems.get(DistrictContactDTO.title)}
          {...ValidationUtil.getValidation(DistrictContactDTO.title, props.fieldErrors, props.submitted)}>
          <ReadableTextBox disabled={loading} />
        </FormItem>

        <FormItem
          className={'description-container'}
          initialValue={districtContact?.email}
          {..._formItems.get(DistrictContactDTO.email)}
          {...ValidationUtil.getValidation(DistrictContactDTO.email, props.fieldErrors, props.submitted)}>
          <ReadableTextBox disabled={loading} />
        </FormItem>

        <FormItem
          className={'description-container'}
          initialValue={districtContact?.phone}
          {..._formItems.get(DistrictContactDTO.phone)}
          {...ValidationUtil.getValidation(DistrictContactDTO.phone, props.fieldErrors, props.submitted)}>
          <ReadableTextBox disabled={loading} />
        </FormItem>

        <FormItem
          className={'description-container'}
          initialValue={props.districtId == undefined ? districtContact?.districtId : props.districtId}
          {..._formItems.get(DistrictContactDTO.districtId)}
          {...ValidationUtil.getValidation(DistrictContactDTO.districtId, props.fieldErrors, props.submitted)}>
          <Dropdown dropdownMatchSelectWidth={false} disabled={loading || props.districtId != undefined}  > {districts.map(x => renderDistrict(x))}</Dropdown>
        </FormItem>

        <FormItem
          className={'description-container'}
          initialValue={districtContact?.districtContactTypeId}
          {..._formItems.get(DistrictContactDTO.districtContactTypeId)}
          {...ValidationUtil.getValidation(DistrictContactDTO.districtContactTypeId, props.fieldErrors, props.submitted)}>
          <Dropdown dropdownMatchSelectWidth={false} disabled={loading} > {districtContactTypes.map(x => renderDistrictContact(x))}</Dropdown>
        </FormItem>
      </Space >
    );
  }

})

DistrictContactDetailsForm.displayName = 'DistrictContactDetailsForm'
export default DistrictContactDetailsForm;