import { Button, message, Row, Steps } from 'antd';
import moment from 'moment';
import * as React from 'react';
import AcademicYearApiService from '../../../../api/AcademicYearApiService';
import AcademicYearStatusType from '../../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../../consts/FeatureFlag';
import AcademicYearDTO from '../../../../models/AcademicYearDTO';
import BaseFormProps from '../../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../../utils/AuthorizationUtil';
import AcademicYearSelfStudyStatsDTO from '../../../../models/AcademicYearSelfStudyStatsDTO';
import * as ReleaseApprovalLettersHandler from '../../../../handlerModels/ReleaseApprovalLettersHandler';
import { SendOutlined } from '@ant-design/icons';
import modal from 'antd/lib/modal';

interface FinalizeYearStepProps extends BaseFormProps {
  academicYear: AcademicYearDTO;
  compactView?: boolean;
  selfStudyStats: AcademicYearSelfStudyStatsDTO | null;
  loadAcademicYear?: (id: number) => void;
}

const finishedStatusTypes = [
  AcademicYearStatusType.CLOSED,
]

export default function FinalizeYearStep(props: FinalizeYearStepProps) {
  const active = props.academicYear?.academicYearStatusTypeId === AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS;
  const finished = finishedStatusTypes.indexOf(props.academicYear.academicYearStatusTypeId) >= 0;
  const [submitting, setSubmitting] = React.useState(false);

  const handleReleaseApprovalLetters = () => {
    const request = ReleaseApprovalLettersHandler.Request.create(
      {
        academicYear: props.academicYear
      });

    AcademicYearApiService.releaseApprovalLetters(request)
      .then((x: ReleaseApprovalLettersHandler.Result) => {
        if (x.succeeded) {
          message.success('Approval Letters Sent!');
          if (props.loadAcademicYear) {
            props.loadAcademicYear(x.academicYearObj?.id ?? 0);
          }
        }
        else {
          message.error('Failed to Send Approval Letters');
        }
      }).catch(() => {
        message.error('Failed to Send Approval Letters');
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const releaseApprovalLettersModal = () => {
    setSubmitting(true);
    return modal.confirm({
      title: 'Release DE Comments',
      content: 'Are you sure you want to finalize the Program Review Year?',
      onCancel: () => { setSubmitting(false) },
      onOk: handleReleaseApprovalLetters,
      okText: 'Release',
    });
  }


  const finalizeDateRange = moment(props.academicYear.finalizationDate).format('MMMM Do YYYY');

  const renderContent = () => {
    if (props.compactView === false && AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR]) && props.academicYear.academicYearStatusTypeId == AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS) {
      if (active) {
        return <Row>
          <Button title='Release Approval Letters' type={'link'}
            loading={submitting}
            icon={<SendOutlined />} onClick={releaseApprovalLettersModal}> Release Approval Letters</Button>
        </Row>;
      }
    }

    return null;
  }

  return <Steps.Step status={finished ? 'finish' : active ? 'process' : 'wait'} title='Program Review Year Finalized' subTitle={finalizeDateRange} description={renderContent()} />;
}