// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import GeneralEducationDTO from '../models/GeneralEducationDTO';
import * as GetGeneralEducationDetailsHandler from '../handlerModels/GetGeneralEducationDetailsHandler';
import * as SaveGeneralEducationDetailsHandler from '../handlerModels/SaveGeneralEducationDetailsHandler';
import * as DeleteGeneralEducationHandler from '../handlerModels/DeleteGeneralEducationHandler';
import * as GetAvailableGeneralEducationParentsHandler from '../handlerModels/GetAvailableGeneralEducationParentsHandler';

export class GeneralEducationApiService extends BaseApi {

    // post: api/generalEducation
    public getGeneralEducationsDataTable(tableRequest: TableRequestDTO): Promise<TableResponseDTO<GeneralEducationDTO>> {
        let url = `api/generalEducation`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<GeneralEducationDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/generalEducation/${id}
    public getGeneralEducationDetails(id: number): Promise<GetGeneralEducationDetailsHandler.Result> {
        let url = `api/generalEducation/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetGeneralEducationDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/generalEducation/export
    public getGeneralEducationDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/generalEducation/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/generalEducation/save
    public saveGeneralEducationDetails(saveGeneralEducationDetails: SaveGeneralEducationDetailsHandler.Request): Promise<SaveGeneralEducationDetailsHandler.Result> {
        let url = `api/generalEducation/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveGeneralEducationDetailsHandler.Result>(saveGeneralEducationDetails, 'post', url, true, false);
    }

    // post: api/generalEducation/deleteGeneralEducation
    public deleteGeneralEducation(deleteGeneralEducation: DeleteGeneralEducationHandler.Request): Promise<DeleteGeneralEducationHandler.Result> {
        let url = `api/generalEducation/deleteGeneralEducation`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteGeneralEducationHandler.Result>(deleteGeneralEducation, 'post', url, true, false);
    }

    // post: api/generalEducation/getAvailableParents
    public getAvailableParents(getAvailableGeneralEducationParents: GetAvailableGeneralEducationParentsHandler.Request): Promise<GetAvailableGeneralEducationParentsHandler.Result> {
        let url = `api/generalEducation/getAvailableParents`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAvailableGeneralEducationParentsHandler.Result>(getAvailableGeneralEducationParents, 'post', url, true, false);
    }
}
var service = new GeneralEducationApiService();
export default service;
