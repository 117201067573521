import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, DatePicker, Input, message, Row, Select, Skeleton, Space, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import { ColumnWidths } from '../../../config/ColumnWidths';
import * as SaveNewProgramChangeRequestStep13Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep13Handler';
import * as SubmitNewProgramChangeRequestStep13Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep13Handler';
import * as GetNewProgramChangeRequestStep13Handler from '../../../handlerModels/GetNewProgramChangeRequestStep13Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import RacialEthnicGroupDTO from '../../../models/RacialEthnicGroupDTO';
import GenderDTO from '../../../models/GenderDTO';
import NewProgramAdvisoryBoardContactDTO from '../../../models/NewProgramAdvisoryBoardContactDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import moment from 'moment';
import Dropdown from '../../inputs/Dropdown';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep13FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  genders: GenderDTO[];
  racialEthnicGroups: RacialEthnicGroupDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
}

interface NewProgramChangeRequestStep13FormProps extends BaseFormProps, BaseChangeRequestProps {
  awardId?: string;
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: (id: string) => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep13Form extends React.Component<NewProgramChangeRequestStep13FormProps, NewProgramChangeRequestStep13FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: NewProgramChangeRequestStep13FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      genders: [],
      racialEthnicGroups: [],
      newProgramChangeRequest: NewProgramChangeRequestDTO.create({ advisoryBoardApprovalDate: '' })
    };
  }

  private cleanDates = (newProgram: NewProgramChangeRequestDTO) => {
    newProgram.advisoryBoardApprovalDate = moment(newProgram.advisoryBoardApprovalDate);

    return newProgram;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep13FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.genders.length == 0) {
      loaders.push(this.loadGenders());
    }

    if (this.state.racialEthnicGroups.length == 0) {
      loaders.push(this.loadEthnicGroups());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest())
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadGenders = () => {
    return LookupsUtil.getAll<GenderDTO>(GenderDTO.className)
      .then((results: GenderDTO[]) => {
        if (results) {
          this.setState({ genders: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadEthnicGroups = () => {
    return LookupsUtil.getAll<RacialEthnicGroupDTO>(RacialEthnicGroupDTO.className)
      .then((results: RacialEthnicGroupDTO[]) => {
        if (results) {
          this.setState({ racialEthnicGroups: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep13Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep13(request)
      .then((results: GetNewProgramChangeRequestStep13Handler.Result) => {
        if (results) {

          let newProgram = results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create();

          if (newProgram.advisoryBoardApprovalDate == '1970-01-01T00:00:00.000Z' || newProgram.advisoryBoardApprovalDate == '0001-01-01T00:00:00+00:00') {
            newProgram.advisoryBoardApprovalDate = '';
          }
          else {
            newProgram = this.cleanDates(newProgram);
          }

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: newProgram ?? NewProgramChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private getAvisoryBoardFormItems = () => {
    const fields = new Map<string, FormItemProps>()
      .set(NewProgramChangeRequestDTO.advisoryBoardApprovalDate, {
        label: 'Date Of Approval',
        name: NewProgramChangeRequestDTO.advisoryBoardApprovalDate,
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
    return fields;
  }

  private getContactFormItems = (index: number) => {
    const fields = new Map<string, FormItemProps>()
      .set(NewProgramChangeRequestDTO.advisoryBoardApprovalDate, {
        label: 'Date Of Approval',
        name: NewProgramChangeRequestDTO.advisoryBoardApprovalDate,
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NewProgramAdvisoryBoardContactDTO.firstName, {
        name: [index, NewProgramAdvisoryBoardContactDTO.firstName],
        label: 'First Name',
      })
      .set(NewProgramAdvisoryBoardContactDTO.lastName, {
        name: [index, NewProgramAdvisoryBoardContactDTO.lastName],
        label: 'Last Name',
      })
      .set(NewProgramAdvisoryBoardContactDTO.employer, {
        name: [index, NewProgramAdvisoryBoardContactDTO.employer],
        label: 'Employer',
      })
      .set(NewProgramAdvisoryBoardContactDTO.position, {
        name: [index, NewProgramAdvisoryBoardContactDTO.position],
        label: 'Postition',
      })
      .set(NewProgramAdvisoryBoardContactDTO.genderId, {
        name: [index, NewProgramAdvisoryBoardContactDTO.genderId],
        label: 'Gender',
      })
      .set(NewProgramAdvisoryBoardContactDTO.ethnicGroupId, {
        name: [index, NewProgramAdvisoryBoardContactDTO.ethnicGroupId],
        label: 'Ethnic Group',
      })
      .set(NewProgramAdvisoryBoardContactDTO.disability, {
        name: [index, NewProgramAdvisoryBoardContactDTO.disability],
        label: 'Has the advisory board member self-identified as having a disability?',
      })
      .set(NewProgramAdvisoryBoardContactDTO.organizedLabor, {
        name: [index, NewProgramAdvisoryBoardContactDTO.organizedLabor],
        label: 'Is this member a part of organized labor?',
      })
    return fields;
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSave = () => {
    this.setState({ saving: true });
    const newProgramChangeRequestDTO = NewProgramChangeRequestDTO.create(this._formRef.current?.getFieldsValue());
    newProgramChangeRequestDTO.changeRequestId = this.state.changeRequest.id ?? Guid.Empty();
    const request = SaveNewProgramChangeRequestStep13Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequestDTO
    });

    NewProgramChangeRequestApiService.saveStep13(request)
      .then((result: SaveNewProgramChangeRequestStep13Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: this.cleanDates(result.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create())
          });

          this.resetForm();
          if (this.props.onSave) {
            if (result.changeRequest) {
              this.props.onSave(result.changeRequest.id ?? Guid.Empty());
            }
            this.resetForm();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const newProgramChangeRequestDTO = NewProgramChangeRequestDTO.create({
      ...this._formRef.current?.getFieldsValue(),
      isTransferMajorProgram: this.state.newProgramChangeRequest.isTransferMajorProgram
    });
    newProgramChangeRequestDTO.changeRequestId = this.state.changeRequest.id ?? Guid.Empty();
    const request = SaveNewProgramChangeRequestStep13Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequestDTO
    });

    NewProgramChangeRequestApiService.submitStep13(request)
      .then((result: SubmitNewProgramChangeRequestStep13Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: this.cleanDates(result.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create())
          });

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }

          if (this.props.onSave) {
            if (result.changeRequest) {
              this.props.onSave(result.changeRequest.id ?? Guid.Empty());
            }
            this.resetForm();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.newProgramChangeRequest}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <Space direction="vertical" >

            <FormItem
              {...this.getAvisoryBoardFormItems().get(NewProgramChangeRequestDTO.advisoryBoardApprovalDate)}
              {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.advisoryBoardApprovalDate, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <DatePicker format='M/D/YYYY' disabled={this.props.readonly} disabledDate={DateTimeUtil.isFutureDate}></DatePicker>
            </FormItem>

            <Typography.Paragraph italic={true}>
              Please provide the advisor information for this program.
            </Typography.Paragraph>

            <Form.List name={NewProgramChangeRequestDTO.newProgramAdvisoryBoardContacts}>
              {
                (advisors, { add, remove }) => {
                  return (
                    <Space direction='vertical'>
                      {advisors.map((value, index) => this.renderAdvisors(index, remove))}
                      {this.renderAddAdditionalAdvisor(add)}
                    </Space>);
                }
              }
            </Form.List>
            {this.renderActions()}
          </Space>
        </ Form>
      </Space >
    );
  }

  private renderAdvisors(index: number, remove: any) {
    const title = 'Advisor ' + (index + 1);
    const deleteButton = this.props.readonly ? null : <Button type="link" icon={<DeleteOutlined />} onClick={() => remove(index)}></Button>;

    return <Card type="inner" title={title} extra={deleteButton}>
      <Row gutter={[16, 16]}>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(NewProgramAdvisoryBoardContactDTO.firstName)}
            {...ValidationUtil.getValidation(NewProgramAdvisoryBoardContactDTO.className.toLowerCase() + NewProgramAdvisoryBoardContactDTO.firstName.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>
        </Col>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(NewProgramAdvisoryBoardContactDTO.lastName)}
            {...ValidationUtil.getValidation(NewProgramAdvisoryBoardContactDTO.className.toLowerCase() + NewProgramAdvisoryBoardContactDTO.lastName.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(NewProgramAdvisoryBoardContactDTO.employer)}
            {...ValidationUtil.getValidation(NewProgramAdvisoryBoardContactDTO.className.toLowerCase() + NewProgramAdvisoryBoardContactDTO.employer.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>
        </Col>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(NewProgramAdvisoryBoardContactDTO.position)}
            {...ValidationUtil.getValidation(NewProgramAdvisoryBoardContactDTO.className.toLowerCase() + NewProgramAdvisoryBoardContactDTO.position.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(NewProgramAdvisoryBoardContactDTO.genderId)}
            {...ValidationUtil.getValidation(NewProgramAdvisoryBoardContactDTO.className.toLowerCase() + NewProgramAdvisoryBoardContactDTO.genderId.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Dropdown showArrow={!this.props.readonly} disabled={this.props.readonly} dropdownMatchSelectWidth={false}>{this.state.genders.map(x => this.renderGender(x))}</Dropdown>
          </FormItem>
        </Col>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(NewProgramAdvisoryBoardContactDTO.ethnicGroupId)}
            {...ValidationUtil.getValidation(NewProgramAdvisoryBoardContactDTO.className.toLowerCase() + NewProgramAdvisoryBoardContactDTO.ethnicGroupId.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Dropdown showArrow={!this.props.readonly} disabled={this.props.readonly} dropdownMatchSelectWidth={false}>{this.state.racialEthnicGroups.map(x => this.renderEthnicGroup(x))}</Dropdown>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <FormItem
          {...this.getContactFormItems(index).get(NewProgramAdvisoryBoardContactDTO.organizedLabor)}
          {...ValidationUtil.getValidation(NewProgramAdvisoryBoardContactDTO.className.toLowerCase() + NewProgramAdvisoryBoardContactDTO.organizedLabor.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
          <YesNoInput disabled={this.props.readonly}></YesNoInput>
        </FormItem>
      </Row>
      <Row gutter={[16, 16]}>
        <FormItem
          {...this.getContactFormItems(index).get(NewProgramAdvisoryBoardContactDTO.disability)}
          {...ValidationUtil.getValidation(NewProgramAdvisoryBoardContactDTO.className.toLowerCase() + NewProgramAdvisoryBoardContactDTO.disability.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
          <YesNoInput disabled={this.props.readonly}></YesNoInput>
        </FormItem>
      </Row>
    </Card>
  }

  private renderActions() {
    if (!this.props.readonly) {
      return <Space direction='horizontal' wrap={true}>
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton onClick={this.handleSave} type="default" htmlType='button' saving={this.state.saving} />
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space>
    }
  }

  renderAddAdditionalAdvisor(add: any) {
    if (!this.props.readonly) {
      return (
        <Button onClick={() => add()} icon={<PlusOutlined />}>
          Add Additional Advisor
        </Button>
      );
    }
  }

  renderGender(gender: GenderDTO) {
    if (gender.id) {
      return <Select.Option key={gender.id ?? Guid.Empty()} value={gender.id ?? Guid.Empty()}>{gender.name}</Select.Option>
    }
  }

  renderEthnicGroup(ethnicGroup: RacialEthnicGroupDTO) {
    if (ethnicGroup.id) {
      return <Select.Option key={ethnicGroup.id ?? Guid.Empty()} value={ethnicGroup.id ?? Guid.Empty()}>{ethnicGroup.name}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NewProgramChangeRequestStep13Form