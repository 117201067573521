import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import RegionalPlanningPartnerDetailsModal from '../../modals/RegionalPlanningPartnerDetailsModal';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import * as DeleteRegionalPlanningPartnerHandler from '../../../handlerModels/DeleteRegionalPlanningPartnerHandler';
import HistoryUtil from '../../../utils/HistoryUtil';
import Routes from '../../../config/Routes';

interface RegionalPlanningPartnerDataTableProps {
  academicYear?: number;
  fromAcademicYear?: boolean;
  configuration?: boolean;
}

function RegionalPlanningPartnerDataTable(props: RegionalPlanningPartnerDataTableProps) {
  let _dataTable: DataTable<RegionalPlanningPartnerDTO> | undefined = undefined;

  const [institutions, setInstitutions] = useState([] as TableFilterOption[]);
  const [rppId, setRppId] = useState<string | null>();

  useEffect(() => {
    TableFilterUtil.Institutions()
      .then(x => {
        setInstitutions(x);
      });
  }, []);

  const getColumnDefinitions = () => {
    const column = [
      {
        title: 'Identifier',
        dataIndex: RegionalPlanningPartnerDTO.regionalPlanningPartnerIdentifier,
        sorter: true,
        width: 50,
        render: (data: string, row: RegionalPlanningPartnerDTO) => {
          return row.regionalPlanningPartnerIdentifier;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Title',
        dataIndex: RegionalPlanningPartnerDTO.name,
        sorter: true,
        width: 250,
        render: (data: string, row: RegionalPlanningPartnerDTO) => {
          return row.name;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Community College',
        dataIndex: RegionalPlanningPartnerDTO.institutionId,
        sorter: true,
        render: (data: string, row: RegionalPlanningPartnerDTO) => {
          return row.institution?.code + ' - ' + row.institution?.name;
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: institutions
      }
    ] as DataTableColumnProps<any>[];
    if (props.fromAcademicYear) {
      column.push({
        title: 'Actions',
        align: 'center',
        dataIndex: RegionalPlanningPartnerDTO.id,
        sorter: false,
        render: (data: string, row: RegionalPlanningPartnerDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => editRegionalPlanningPartner(row)} icon={<FormOutlined />} />
              <Button type="link" onClick={() => promptConfirmRemove(row.id)} icon={<DeleteOutlined />} />
            </>
          }
        },
        width: 50
      })
    }
    return column;
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const promptConfirmRemove = (rppId: string | null) => {
    if (rppId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this Regional Planning Partner?',
      okText: 'Delete',
      onOk: () => deleteRPP(rppId),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteRPP = (rppId: string) => {
    const request = DeleteRegionalPlanningPartnerHandler.Request.create({
      RegionalPlanningPartnerId: rppId,
    });

    RegionalPlanningPartnerApiService.deleteRegionalPlanningPartner(request)
      .then((result: DeleteRegionalPlanningPartnerHandler.Result | null) => {
        if (result?.succeeded === true) {
          triggerRefresh();
          message.success('Regional Planning Partner was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Regional Planning Partner could not be removed.');
      });
  }

  const openRegionPlanningPartnerDetail = (record: RegionalPlanningPartnerDTO) => {
    HistoryUtil.push(Routes.generate(Routes.REGIONAL_PLANNING_PARTNER_DETAIL, { id: record?.id ?? Guid.Empty() }, { academicYear: props.academicYear, configuration: props.configuration }));
  }

  const openNewRegionPlanningPartnerDetail = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_REGIONAL_PLANNING_PARTNER_DETAIL, {}, { academicYear: props.academicYear }));
  }

  const addRegionalPlanningPartner = () => {
    setRppId(Guid.Empty())
  }

  const editRegionalPlanningPartner = (record: RegionalPlanningPartnerDTO) => {
    if (record.id) {
      setRppId(record.id ?? Guid.Empty());
    }
  }

  const closeRegionalPlanningPartnerDetail = () => {
    setRppId(null);
  }

  const renderModal = () => {
    if (rppId) {
      return <RegionalPlanningPartnerDetailsModal
        onCancel={closeRegionalPlanningPartnerDetail}
        visible={true}
        regionalPlanningPartnerId={rppId}
        academicYear={props.academicYear ?? 0}
        onSave={closeRegionalPlanningPartnerDetail} />
    }
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
    actionButtons.push(DataTableButtonUtil.Default('New Regional Planning Partner', props.fromAcademicYear ? () => addRegionalPlanningPartner() : () => openNewRegionPlanningPartnerDetail()));
  }

  return (
    <>
      {renderModal()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        onRowClick={props.fromAcademicYear ? undefined : openRegionPlanningPartnerDetail}

        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => RegionalPlanningPartnerApiService.getRegionalPlanningPartnerTableData(requestState, props.academicYear ?? 0)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'rpp_list' }} />
    </>
  );
}

export default RegionalPlanningPartnerDataTable;

