import { Button, Result } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

class NotFound extends React.Component<unknown, unknown> {
  constructor(props: unknown) {
    super(props);
  }

  render() {
    return (
      <Result
        status="404"
        title="Unauthorized"
        subTitle="Sorry, we couldn't find what you are looking for."
        extra={<Link to="/"><Button type="primary">Home</Button></Link>} />
    );
  }
}

export default NotFound;
