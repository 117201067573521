// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import TransferMajorGeneralEducationTypeDTO from '../models/TransferMajorGeneralEducationTypeDTO';
import * as GetTransferMajorGeneralEducationTypeDetailsHandler from '../handlerModels/GetTransferMajorGeneralEducationTypeDetailsHandler';
import * as SaveTransferMajorGeneralEducationTypeDetailsHandler from '../handlerModels/SaveTransferMajorGeneralEducationTypeDetailsHandler';
import * as DeleteTransferMajorGeneralEducationTypeHandler from '../handlerModels/DeleteTransferMajorGeneralEducationTypeHandler';

export class TransferMajorGeneralEducationTypeApiService extends BaseApi {

    // post: api/TransferMajorGeneralEducationType
    public getTransferMajorGeneralEducationTypesDataTable(tableRequest: TableRequestDTO): Promise<TableResponseDTO<TransferMajorGeneralEducationTypeDTO>> {
        let url = `api/TransferMajorGeneralEducationType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<TransferMajorGeneralEducationTypeDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/TransferMajorGeneralEducationType/${id}
    public getTransferMajorGeneralEducationTypeDetails(id: number): Promise<GetTransferMajorGeneralEducationTypeDetailsHandler.Result> {
        let url = `api/TransferMajorGeneralEducationType/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetTransferMajorGeneralEducationTypeDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/TransferMajorGeneralEducationType/export
    public getTransferMajorGeneralEducationTypeDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/TransferMajorGeneralEducationType/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/TransferMajorGeneralEducationType/save
    public saveTransferMajorGeneralEducationTypeDetails(saveTransferMajorGeneralEducationTypeDetails: SaveTransferMajorGeneralEducationTypeDetailsHandler.Request): Promise<SaveTransferMajorGeneralEducationTypeDetailsHandler.Result> {
        let url = `api/TransferMajorGeneralEducationType/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveTransferMajorGeneralEducationTypeDetailsHandler.Result>(saveTransferMajorGeneralEducationTypeDetails, 'post', url, true, false);
    }

    // post: api/TransferMajorGeneralEducationType/deleteTransferMajorGeneralEducationType
    public deleteTransferMajorGeneralEducationType(deleteTransferMajorGeneralEducationType: DeleteTransferMajorGeneralEducationTypeHandler.Request): Promise<DeleteTransferMajorGeneralEducationTypeHandler.Result> {
        let url = `api/TransferMajorGeneralEducationType/deleteTransferMajorGeneralEducationType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteTransferMajorGeneralEducationTypeHandler.Result>(deleteTransferMajorGeneralEducationType, 'post', url, true, false);
    }
}
var service = new TransferMajorGeneralEducationTypeApiService();
export default service;
