import * as React from 'react';
import WorkItemApiService from '../../api/WorkItemApiService';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import WorkItemStatusType from '../../consts/WorkItemStatusType';
import WorkItemType from '../../consts/WorkItemType';
import TableRequestDTO from '../../models/TableRequestDTO';
import WorkQueueItemDTO from '../../models/WorkQueueItemDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import DateTimeUtil from '../../utils/DateTimeUtil';
import Guid from '../../utils/Guid';
import HistoryUtil from '../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from './core/DataTable';
import DataTableButtonUtil from './core/DataTableButtonUtil';

interface WorkItemDataTableProps {
  userId?: string | null;
  institutionId?: string | null;
  currentUserId: string | null;
}

interface WorkItemDataTableState extends BaseDataTableState<WorkQueueItemDTO> {
  institutions: TableFilterOption[];
}

class WorkItemDataTable extends React.Component<WorkItemDataTableProps, WorkItemDataTableState> {
  private _dataTable: DataTable<WorkQueueItemDTO> | undefined;

  constructor(props: WorkItemDataTableProps) {
    super(props);

    this.state = {
      institutions: []
    }
  }

  componentDidMount() {
    TableFilterUtil.Institutions()
      .then(x => {
        this.setState({ institutions: x })
      });
  }

  private getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Type',
        dataIndex: WorkQueueItemDTO.workItemTypeId,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: TableFilterUtil.GetWorkItemTypes(),
        render: (data: string, row: WorkQueueItemDTO) => {
          return row.workItemType?.name;
        },
        width: '200px'
      },
      {
        title: 'Details',
        dataIndex: WorkQueueItemDTO.description,
        sorter: false,
        filterType: FilterType.NONE,
        render: (data: string, row: WorkQueueItemDTO) => {
          return row.description;
        }
      }
    ] as DataTableColumnProps<any>[];

    if (!this.props.userId) {
      columns.push({
        title: 'User',
        sorter: true,
        dataIndex: WorkQueueItemDTO.userId,
        filterType: FilterType.Text,
        render: (data: string, row: WorkQueueItemDTO) => {
          return row.user?.display;
        }
      });
    }

    columns.push(
      {
        title: 'Created',
        dataIndex: WorkQueueItemDTO.createdOn,
        sorter: true,
        filterType: FilterType.DateRange,
        render: (data: string, row: WorkQueueItemDTO) => {
          return DateTimeUtil.shortDate(row.createdOn);
        }
      },
      {
        title: 'Status',
        dataIndex: WorkQueueItemDTO.workItemStatusTypeId,
        filterType: FilterType.DropdownMulti,
        sorter: true,
        dropdownFilterOptions: TableFilterUtil.GetWorkItemStatusTypes(),
        render: (data: number, row: WorkQueueItemDTO) => {
          if (data === WorkItemStatusType.ACTIVE && DateTimeUtil.isBeforeRange(row.activeOn)) {
            return 'Upcoming';
          }
          else {
            return row.workItemStatusType?.name;
          }
        }
      });

    return columns
  };

  private openWorkItem = (record: WorkQueueItemDTO) => {
    if (record.key) {
      const key = record.key.split('_')[1];

      switch (record.workItemTypeId) {
        case WorkItemType.CHANGEREQUESTDRAFT:
          this.openChangeRequestDraft(record, key);
          break;
        case WorkItemType.REVIEWCHANGEREQUEST:
          this.openChangeRequestApproval(record, key);
          break;
        case WorkItemType.APPROVALREDIRECT:
          this.openApprovalRedirect(record, key);
          break;
        case WorkItemType.RESPONSETOSELFSTUDY:
        case WorkItemType.REVISESELFSTUDY:
        case WorkItemType.SUBMITSELFSTUDY:
          HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: record.key.split('_')[2], }, {}))
          break;
        case WorkItemType.ASSIGNDISTRICTUSER:
        case WorkItemType.ASSIGNRPPCONSULTANT:
        case WorkItemType.ASSIGNDECONSULTANT:
          HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: key }, {}))
          break;
      }
    }
  }

  private openChangeRequestDraft = (record: WorkQueueItemDTO, key: string) => {
    if (record.workItemStatusTypeId == WorkItemStatusType.ACTIVE && (record.userId == this.props.currentUserId || AuthorizationUtil.isAuthorized([FeatureFlag.OVERRIDE_CHANGE_REQUEST_USER]))) {
      HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_DRAFT, { key: key }, {}));
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_DETAILS, { id: key }, {}));
    }
  }

  private openChangeRequestApproval = (record: WorkQueueItemDTO, key: string) => {
    HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_APPROVAL, { approvalId: key }, {}));
  }

  private openApprovalRedirect = (record: WorkQueueItemDTO, key: string) => {
    HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUEST_APPROVAL_REDIRECT, { approvalId: key }, {}));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openWorkItem}
        buttonBar={actionButtons}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 400 }
        }}
        globalSearch={false}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) =>
            this.props.userId ? WorkItemApiService.getWorkItemsForUser(this.props.userId ?? Guid.Empty(), requestState) :
              this.props.institutionId ? WorkItemApiService.getWorkItemsForInstitution(this.props.institutionId ?? Guid.Empty(), requestState) :
                WorkItemApiService.getWorkItems(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'work_item_list' + this.props.userId }} />
    );
  }
}

export default WorkItemDataTable;
