// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import NoticeOfIntentChangeRequestDTO from './NoticeOfIntentChangeRequestDTO';
import NoticeOfIntentChangeRequestRegionalStatsDTO from './NoticeOfIntentChangeRequestRegionalStatsDTO';
import GenderDTO from './GenderDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoticeOfIntentChangeRequestOccupationDTO extends EntitySelectorDTO { 
	id: string | null;
	noticeOfIntentChangeRequestId: string | null;
	noticeOfIntentChangeRequest: NoticeOfIntentChangeRequestDTO | null;
	title: string | null;
	averageEntryLevelSalary: number | null | null;
	regionalStats: NoticeOfIntentChangeRequestRegionalStatsDTO[] | null;
	minimumEducationRequirements: string | null;
	professionalCredentialRequirements: boolean | null | null;
	professionalCredentialRequirementDetails: string | null;
	nontraditionalByGender: boolean | null | null;
	genderId: number | null | null;
	gender: GenderDTO | null;
}

const NoticeOfIntentChangeRequestOccupationDTO = {
    className: 'NoticeOfIntentChangeRequestOccupationDTO',
    create: (initValues?: Partial<NoticeOfIntentChangeRequestOccupationDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequestId: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequest: null,
			title: "",
			averageEntryLevelSalary: null,
			regionalStats: [],
			minimumEducationRequirements: "",
			professionalCredentialRequirements: null,
			professionalCredentialRequirementDetails: "",
			nontraditionalByGender: null,
			genderId: null,
			gender: null,
        },
        initValues);
    },
	id: 'id',
	noticeOfIntentChangeRequestId: 'noticeOfIntentChangeRequestId',
	noticeOfIntentChangeRequest: 'noticeOfIntentChangeRequest',
	title: 'title',
	averageEntryLevelSalary: 'averageEntryLevelSalary',
	regionalStats: 'regionalStats',
	minimumEducationRequirements: 'minimumEducationRequirements',
	professionalCredentialRequirements: 'professionalCredentialRequirements',
	professionalCredentialRequirementDetails: 'professionalCredentialRequirementDetails',
	nontraditionalByGender: 'nontraditionalByGender',
	genderId: 'genderId',
	gender: 'gender',
};

export default NoticeOfIntentChangeRequestOccupationDTO;