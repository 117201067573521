import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Tooltip } from 'antd';
import * as React from 'react';
import InstitutionCourseBlockApiService from '../../../api/InstitutionCourseBlockApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as AddCourseToInstitutionCourseBlockDetailHandler from '../../../handlerModels/AddCourseToInstitutionCourseBlockDetailHandler';
import * as RemoveCourseFromInstitutionCourseBlockDetailHandler from '../../../handlerModels/RemoveCourseFromInstitutionCourseBlockDetailHandler';
import InstitutionCourseBlockCourseDataTableDTO from '../../../models/InstitutionCourseBlockCourseDataTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import CourseSearch from '../../postSecondary/CourseSearch';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface InstitutionCourseBlockCoursesDataTableProps {
  institutionCourseBlockDetailId: string;
  institutionId: string;
  triggerUpdate?: () => void;
}

interface InstitutionCourseBlockCoursesDataTableState extends BaseDataTableState<InstitutionCourseBlockCourseDataTableDTO>, BaseFormState {
  showAddCourseModal: boolean | false;
}

class InstitutionCourseBlockCoursesDataTable extends React.Component<InstitutionCourseBlockCoursesDataTableProps, InstitutionCourseBlockCoursesDataTableState> {
  private readonly _courseSearchRef = React.createRef<CourseSearch>();

  constructor(props: InstitutionCourseBlockCoursesDataTableProps) {
    super(props);
    this.state = {
      showAddCourseModal: false
    };
  }

  componentDidUpdate(prevProps: InstitutionCourseBlockCoursesDataTableProps) {
    if (prevProps.institutionCourseBlockDetailId != this.props.institutionCourseBlockDetailId) {
      this.triggerRefresh();
    }
  }

  private _dataTable: DataTable<InstitutionCourseBlockCourseDataTableDTO> | undefined;

  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Disipline',
        dataIndex: InstitutionCourseBlockCourseDataTableDTO.discipline,
        sorter: true,
        filterType: FilterType.Text,
        render: (value: any, row: InstitutionCourseBlockCourseDataTableDTO) => {
          return (
            <Tooltip title={row.discipline?.name} placement='right'>
              <span>{row.discipline?.code}</span>
            </Tooltip>
          );
        }
      },
      {
        title: 'Catalog Number',
        dataIndex: InstitutionCourseBlockCourseDataTableDTO.catalogNumber,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Title',
        dataIndex: InstitutionCourseBlockCourseDataTableDTO.title,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: '',
        dataIndex: InstitutionCourseBlockCourseDataTableDTO.id,
        width: '25px',
        render: (data: string, row: InstitutionCourseBlockCourseDataTableDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_INSTITUTION_COURSE_BLOCK])) {
            return <>
              <Button type="link" className="ant-btn" onClick={() => this.promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
        sorter: false,
        filterType: FilterType.NONE
      }
    ] as DataTableColumnProps<any>[];
  };

  private addCourse = () => {
    this.setState({ showAddCourseModal: true });
  }

  private submitAddCourse = () => {
    const value = this._courseSearchRef.current?.getValue();
    this.setState({ submitting: true });
    const request = AddCourseToInstitutionCourseBlockDetailHandler.Request.create({
      courseId: value?.courseId,
      institutionCourseBlockDetailId: this.props.institutionCourseBlockDetailId,
    });

    InstitutionCourseBlockApiService.addCourseToInstitutionCourseBlockDetail(request)
      .then((result: AddCourseToInstitutionCourseBlockDetailHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        this.triggerRefresh();
        this._courseSearchRef.current?.reset()

        if (result?.succeeded === true) {
          this.setState({
            showAddCourseModal: false
          });
          message.success('Course has been added');
        }
        else {
          message.error(result?.errors.join('\n'));
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Course could not be added');
      })
      .finally(() => {
        this.setState({ loading: false });
      });

  }

  private cancelAddCourse = () => {
    this.setState({ showAddCourseModal: false });
    this.triggerRefresh();
  }

  private courseAdded = () => {
    this._dataTable?.refresh();
  }

  private handleRemoveCourse = (courseId: string | null) => {
    this.setState({ submitting: true });

    const request = RemoveCourseFromInstitutionCourseBlockDetailHandler.Request.create({
      courseId: courseId,
      institutionCourseBlockDetailId: this.props.institutionCourseBlockDetailId,
    });

    InstitutionCourseBlockApiService.removeCourseFromInstitutionCourseBlockDetail(request)
      .then((result: RemoveCourseFromInstitutionCourseBlockDetailHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Course was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Course could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.triggerRefresh();
      });
  }

  private promptConfirmRemove = (courseId: string | null) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this course?',
      okText: 'Remove Course',
      onOk: () => this.handleRemoveCourse(courseId)
    });
  }

  render() {
    return (
      <>
        {this.renderDataTable()}
        {this.renderAddCourseModal()}
      </>
    )

  }

  renderDataTable() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    if (this.props.institutionCourseBlockDetailId != Guid.Empty() && AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_INSTITUTION_COURSE_BLOCK])) {
      actionButtons.push(DataTableButtonUtil.Default('Add Course', () => this.addCourse()));
    }

    return (
      <Space direction='vertical' >
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => InstitutionCourseBlockApiService.getCoursesForInstitutionCourseBlockDetailTableData(requestState, this.props.institutionCourseBlockDetailId)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'course_icb_list' }} />
      </Space>
    );
  }

  renderAddCourseModal() {
    return (
      <Modal
        visible={this.state.showAddCourseModal}
        title='Add Course'
        okText='Add'
        onOk={this.submitAddCourse}
        onCancel={this.cancelAddCourse}>
        <CourseSearch ref={this._courseSearchRef} institutionId={this.props.institutionId ?? Guid.Empty()} />
      </Modal>
    );
  }
}

export default InstitutionCourseBlockCoursesDataTable;
