import { Alert, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import SecondaryProgramScenarioApiService from '../../../api/SecondaryProgramScenarioApiService';
import * as GetSecondaryProgramScenarioDetailsHandler from '../../../handlerModels/GetSecondaryProgramScenarioDetailsHandler';
import * as SaveSecondaryProgramScenarioDetailsHandler from '../../../handlerModels/SaveSecondaryProgramScenarioDetailsHandler';
import SecondaryProgramScenarioDTO from '../../../models/SecondaryProgramScenarioDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ReadableTextArea from '../../inputs/ReadableTextArea';
import ReadableTextBox from '../../inputs/ReadableTextBox';

interface SecondaryProgramScenarioDetailsFormState extends BaseFormState {
  SecondaryProgramScenario: SecondaryProgramScenarioDTO;
}

interface SecondaryProgramScenarioDetailsFormProps extends BaseFormProps {
  SecondaryProgramScenarioId: string;
  academicYear?: number;
  onSave?: (SecondaryProgramScenarioId: string) => void;
}

class SecondaryProgramScenarioDetailsForm extends React.Component<SecondaryProgramScenarioDetailsFormProps, SecondaryProgramScenarioDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(SecondaryProgramScenarioDTO.secondaryProgramScenarioId, {
      required: true,
      name: SecondaryProgramScenarioDTO.secondaryProgramScenarioId,
      label: 'Id',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    })
    .set(SecondaryProgramScenarioDTO.title, {
      required: true,
      name: SecondaryProgramScenarioDTO.title,
      label: 'Title',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(100)
      ],
    })
    .set(SecondaryProgramScenarioDTO.description, {
      required: true,
      name: SecondaryProgramScenarioDTO.description,
      label: 'Description',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(5000)
      ],
    });

  constructor(props: SecondaryProgramScenarioDetailsFormProps) {
    super(props);

    this.state = {
      SecondaryProgramScenario: SecondaryProgramScenarioDTO.create({
        id: undefined,
        name: undefined,
        secondaryProgramScenarioId: undefined,
      }),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: SecondaryProgramScenarioDetailsFormProps) {
    if (this.props != prevProps) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];
    loaders.push(this.loadSecondaryProgramScenario());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadSecondaryProgramScenario() {
    if (!this.state.loading) {
      if (this.props.SecondaryProgramScenarioId == Guid.Empty() || this.props.isNew) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.SecondaryProgramScenarioId && this.props.academicYear) {
      const request = GetSecondaryProgramScenarioDetailsHandler.Request.create({
        id: this.props.SecondaryProgramScenarioId
      });

      SecondaryProgramScenarioApiService.getSecondaryProgramScenarioDetails(request)
        .then((results: GetSecondaryProgramScenarioDetailsHandler.Result) => {
          if (results) {
            this.setState({
              SecondaryProgramScenario: results.secondaryProgramScenario ?? SecondaryProgramScenarioDTO.create({
                id: this.props.SecondaryProgramScenarioId == Guid.Empty() ? undefined : this.props.SecondaryProgramScenarioId,
                secondaryProgramScenarioId: undefined,
                name: undefined,
              })
            }, () => this.resetForm());

            if (this.props.SecondaryProgramScenarioId == Guid.Empty()) {
              this.setState({ altered: true })
            }

          }
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({
      loading: false,
      SecondaryProgramScenario: SecondaryProgramScenarioDTO.create({
        id: this.props.SecondaryProgramScenarioId == Guid.Empty() ? undefined : this.props.SecondaryProgramScenarioId,
        secondaryProgramScenarioId: undefined,
        name: undefined,
      }),
      altered: true
    });
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      () => this.handleSubmit(),
      () => this.setState({ error: true })
    );
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveSecondaryProgramScenarioDetailsHandler.Request.create({
      SecondaryProgramScenarioId: this.props.SecondaryProgramScenarioId,
      SecondaryProgramScenario: SecondaryProgramScenarioDTO.create({
        ...(this._formRef ? (this._formRef.current as any).getFieldsValue() : null),
        academicYear: this.props.academicYear,
      })
    });

    SecondaryProgramScenarioApiService.saveSecondaryProgramScenarioDetails(request)
      .then((result: SaveSecondaryProgramScenarioDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ SecondaryProgramScenario: result.secondaryProgramScenario ?? SecondaryProgramScenarioDTO.create(), fieldErrors: undefined, error: false });

          message.success('Saved');

          if (this.props.onSave && result.secondaryProgramScenario?.id) {
            this.resetForm();
            this.props.onSave(result.secondaryProgramScenario.id);
          }

        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('ProgramType could not be saved');
          this.setState({ loading: false, submitting: false });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results, loading: false, submitting: false });
        message.error('ProgramType could not be saved');
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.SecondaryProgramScenario}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(SecondaryProgramScenarioDTO.secondaryProgramScenarioId)}
            {...ValidationUtil.getValidation(SecondaryProgramScenarioDTO.secondaryProgramScenarioId, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryProgramScenarioDTO.title)}
            {...ValidationUtil.getValidation(SecondaryProgramScenarioDTO.title, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryProgramScenarioDTO.description)}
            {...ValidationUtil.getValidation(SecondaryProgramScenarioDTO.description, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextArea maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
        </Form>
      </Space>
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default SecondaryProgramScenarioDetailsForm;
