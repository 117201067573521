import { Input, Select, Space } from 'antd';
import * as React from 'react';
import TermDTO from '../models/TermDTO';
import Guid from '../utils/Guid';
import LookupsUtil from '../utils/LookupsUtil';
import ValueLabel from './general/ValueLabel';
import Dropdown from './inputs/Dropdown';

interface TermOnlyInputState {
  termId: string;
  terms: TermDTO[];
  openSelect: Map<string, boolean>;
}

interface TermOnlyInputProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

class TermOnlyInput extends React.Component<TermOnlyInputProps, TermOnlyInputState> {
  termIdentifier = 'term';

  constructor(props: TermOnlyInputProps) {
    super(props);

    this.state = {
      termId: '',
      terms: [],
      openSelect: new Map<string, boolean>()
    }
  }

  private handleChanged = (termId: string) => {
    if (this.props.onChange) {
      this.props.onChange(termId)
    }
  }

  private handleTermChanged = (value: string) => {
    this.handleChanged(value);
  }

  componentDidMount() {
    this.loadTerms();
    const open = this.state.openSelect;
    open.set(this.termIdentifier, false);

    this.setState({
      termId: this.props.value ?? '',
      openSelect: open
    });
  }

  loadTerms() {
    LookupsUtil.getAll<TermDTO>(TermDTO.className).then(terms => { this.setState({ terms: terms }); });
  }

  componentDidUpdate(prevProps: TermOnlyInputProps) {
    if (this.props.value && this.props.value != prevProps.value) {
      this.setState({
        termId: this.props.value
      });
    }
  }

  handleTermChange = (termId: string) => {
    this.setState({
      termId: termId
    });
  }

  handleClick = (key: string) => {
    this.toggleOpen(key, !(this.state.openSelect?.get(key) ?? false));
  }

  handleBlur = (key: string) => {
    this.toggleOpen(key, false);
  }

  toggleOpen = (key: string, state: boolean) => {
    const open = this.state.openSelect;
    open.set(this.termIdentifier, false);
    open.set(key, state);

    this.setState({
      openSelect: open
    });
  }

  render() {
    const termId = this.state.termId == Guid.Empty() || this.state.termId == '' ? undefined : this.state.termId;

    return (
      <div>
        {this.props.readOnly ?
          <Space direction="horizontal" >
            <ValueLabel value={this.state.terms.find(x => x.id == termId)?.name} />
          </Space> :

          <Input.Group compact>
            <label title="Term" htmlFor={this.termIdentifier} >
              <Dropdown
                dropdownMatchSelectWidth={false}
                showArrow={!this.props.disabled}
                disabled={this.props.disabled || this.props.readOnly}
                value={termId}
                onSelect={this.handleTermChanged}
                onChange={this.handleTermChanged}
                placeholder='Term'
                allowClear={true}>
                {this.state.terms.map(x => { return this.renderTermOption(x); })}
              </Dropdown>
            </label>
          </Input.Group>
        }
      </div>
    );
  }

  renderTermOption(term: TermDTO) {
    if (term.id && term.name) {
      return <Select.Option aria-label={term.name} title={term.name} key={term.id} value={term.id}>{term.name}</Select.Option>
    }
  }
}

export default TermOnlyInput;
