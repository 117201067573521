import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import TransferMajorCourseBlockApiService from '../../../api/TransferMajorCourseBlockApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetTransferMajorCourseBlockDetailsHandler from '../../../handlerModels/GetTransferMajorCourseBlockDetailsHandler';
import * as SaveTransferMajorCourseBlockDetailsHandler from '../../../handlerModels/SaveTransferMajorCourseBlockDetailsHandler';
import TransferMajorCourseBlockDTO from '../../../models/TransferMajorCourseBlockDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface TransferMajorCourseBlockDetailsFormState extends BaseFormState {
  transferMajorCourseBlockId: number;
  transferMajorCourseBlock: TransferMajorCourseBlockDTO | null;
}

interface TransferMajorCourseBlockDetailsFormProps extends BaseFormProps {
  transferMajorCourseBlockId: number;
  onSave?: (transferMajorCourseBlockId: number) => void;
}

class TransferMajorCourseBlockDetailsForm extends React.Component<TransferMajorCourseBlockDetailsFormProps, TransferMajorCourseBlockDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(TransferMajorCourseBlockDTO.name, {
      required: true,
      name: TransferMajorCourseBlockDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(50)
      ]
    });

  constructor(props: TransferMajorCourseBlockDetailsFormProps) {
    super(props);

    this.state = {
      transferMajorCourseBlockId: 0,
      transferMajorCourseBlock: TransferMajorCourseBlockDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadTransferMajorCourseBlock();
  }

  componentDidUpdate(prevProps: TransferMajorCourseBlockDetailsFormProps) {
    if (this.props.transferMajorCourseBlockId && this.props.transferMajorCourseBlockId != prevProps.transferMajorCourseBlockId) {
      this.setState({ transferMajorCourseBlockId: this.props.transferMajorCourseBlockId });
      this.loadTransferMajorCourseBlock();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadTransferMajorCourseBlock() {
    if (this.props.transferMajorCourseBlockId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.transferMajorCourseBlockId) {
      TransferMajorCourseBlockApiService.getTransferMajorCourseBlockDetails(this.props.transferMajorCourseBlockId)
        .then((results: GetTransferMajorCourseBlockDetailsHandler.Result) => {
          if (results && results.transferMajorCourseBlock) {
            this.setState({ transferMajorCourseBlockId: results.transferMajorCourseBlock.id, transferMajorCourseBlock: results.transferMajorCourseBlock, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ transferMajorCourseBlock: TransferMajorCourseBlockDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveTransferMajorCourseBlockDetailsHandler.Request.create({
      transferMajorCourseBlockId: this.state.transferMajorCourseBlockId,
      transferMajorCourseBlock: TransferMajorCourseBlockDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    TransferMajorCourseBlockApiService.saveTransferMajorCourseBlockDetails(request)
      .then((result: SaveTransferMajorCourseBlockDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          transferMajorCourseBlock: result?.transferMajorCourseBlock,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.transferMajorCourseBlock?.id) {
            this.props.onSave(result.transferMajorCourseBlock.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(TransferMajorCourseBlockDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Transfer Major Course Block could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Transfer Major Course Block could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(TransferMajorCourseBlockDTO.name)}
            initialValue={this.state.transferMajorCourseBlock?.name}
            {...ValidationUtil.getValidation(TransferMajorCourseBlockDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_COURSE_BLOCKS]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default TransferMajorCourseBlockDetailsForm;
