import { MoreOutlined } from '@ant-design/icons';
import * as antd from 'antd';
import { Card, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import DistrictApiService from '../../../api/DistrictApiService';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import SelfStudyDataTable from '../../../components/datatables/secondary/SelfStudyDataTable';
import DistrictContactsDataTable from '../../../components/datatables/secondary/DistrictContactsDataTable';
import DistrictUsersDataTable from '../../../components/datatables/secondary/DistrictUsersDataTable';
import SecondaryProgramsDataTable from '../../../components/datatables/secondary/SecondaryProgramsDataTable';
import DistrictDetailsForm from '../../../components/forms/secondary/DistrictDetailsForm';
import Dropdown from '../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteDistrictHandler from '../../../handlerModels/DeleteDistrictHandler';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import * as GetDistrictDetailsHandler from '../../../handlerModels/GetDistrictDetailsHandler';
import * as GetDistrictsHandler from '../../../handlerModels/GetDistrictsHandler';
import * as GetRegionalPlanningPartnersHandler from '../../../handlerModels/GetRegionalPlanningPartnersHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import CurrentUser from '../../../utils/CurrentUser';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface DistrictDetailsPageState extends BaseFormState {
  activeTab: string;
  id: string;
  rppId: string;
  districtIdentifier: number;
  district?: DistrictDTO;
  districts: DistrictDTO[];
  academicYears: AcademicYearDTO[];
  academicYearId: number;
  fromAcademicYearList: boolean;
  fromSelfStudies: boolean;
  regions: RegionalPlanningPartnerDTO[];
}

interface DistrictDetailsPageProps {
}

class DistrictDetailsPage extends React.Component<DistrictDetailsPageProps & RouteComponentProps<RouteObject>, DistrictDetailsPageState> {
  private readonly _districtDetailsRef = React.createRef<DistrictDetailsForm>();

  constructor(props: DistrictDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      activeTab: 'details',
      id: Guid.Empty(),
      districtIdentifier: 0,
      districts: [],
      academicYears: [],
      academicYearId: 0,
      fromAcademicYearList: false,
      fromSelfStudies: false,
      regions: [],
      rppId: Guid.Empty()
    };
  }

  componentDidMount() {
    const districtId = ParameterUtil.getPathPart(this.props.match, 'id');
    const academicYear = ParameterUtil.getParameter('academicYear');
    const fromAcademicYearList = ParameterUtil.getParameter('fromAcademicYearList');
    const fromSelfStudies = ParameterUtil.getParameter('fromSelfStudies');
    const rppId = ParameterUtil.getParameter('rppId');
    const activeTab = ParameterUtil.getParameter('activeTab', 'details');
    this.setState({
      academicYearId: Number.parseInt(academicYear),
      fromSelfStudies: fromSelfStudies == 'true',
      fromAcademicYearList: fromAcademicYearList == 'true',
      rppId: rppId,
      activeTab: activeTab
    });

    if (this.props.match.path.split('/').findIndex(x => x == 'new') > -1) {
      this.setState({ isEditing: true, isNew: true, loading: false });
      const loaders = [];
      loaders.push(this.loadAcademicYears());
      loaders.push(this.loadRegionalPlanningPartners(academicYear));
      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
    else {
      const loaders = [];
      loaders.push(this.loadDistrict(districtId));
      loaders.push(this.loadAcademicYears());
      loaders.push(this.loadRegionalPlanningPartners(academicYear));

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  private loadDistricts = (districtId: any, academicYear: any) => {
    const request = GetDistrictsHandler.Request.create({
      academicYearId: academicYear,
      districtId: districtId
    });

    DistrictApiService.getDistricts(request)
      .then((results: GetDistrictsHandler.Result) => {
        if (results) {
          this.setState({
            districts: results.districts ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadDistrict = (id: string, academicYear?: any, districtId?: any) => {
    this.setState({ id: id });
    const request = GetDistrictDetailsHandler.Request.create({
      id: id,
      districtId: districtId,
      academicYear: academicYear
    });

    DistrictApiService.getDistrictDetails(request)
      .then((results: GetDistrictDetailsHandler.Result) => {
        if (results.district) {
          this.setState({
            district: results.district,
            id: results.district.id ?? Guid.Empty(),
            isNew: false,
            isEditing: false,
            districtIdentifier: results.district.districtId,
            academicYearId: results.district.academicYear
          });

          if (this.state.district && this.state.districts.length == 0) {
            this.loadDistricts(results.district.districtId, results.district.academicYear);
          }
        }
        else {
          this.setState({
            isNew: true,
            id: Guid.Empty(),
            district: undefined,
            isEditing: true
          })
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAcademicYears = () => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  private loadRegionalPlanningPartners = (academicYear: any) => {
    RegionalPlanningPartnerApiService.getRegionalPlanningPartners(academicYear)
      .then((results: GetRegionalPlanningPartnersHandler.Result) => {
        if (results) {
          this.setState({
            regions: results.regions ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._districtDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab, academicYear: this.state.academicYearId }), { activeTab: activeTab });
  }

  private districtDetailsPageSaved = (districtId: string) => {
    this.setState({ isEditing: false, isNew: false });
    if (districtId != this.state.id) {
      HistoryUtil.push(Routes.generate(Routes.DISTRICT_DETAILS, { id: districtId }, { academicYearId: this.state.academicYearId, fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList }));
      this.loadDistrict(districtId);
    }
    else {
      this.loadDistrict(districtId);
    }
  }

  private deleteConfirmation = (id: string, academicYearId: number) => {
    antd.Modal.confirm({
      title: 'Are you sure you want to delete this District?',
      okText: 'Delete',
      onOk: () => this.deleteDistrict(id, academicYearId)
    });
  }

  private deleteDistrict = (districtId: string, academicYearId: number) => {
    this.setState({ submitting: true });

    const request = DeleteDistrictHandler.Request.create({
      districtId: districtId,
      academicYearId: academicYearId
    });

    DistrictApiService.deleteDistrict(request)
      .then((result: DeleteDistrictHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          antd.message.success('District was removed.');
        }
        else {
          antd.message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        antd.message.error('District could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToListing();
      });
  }

  goToListing = () => {
    if (this.state.rppId != Guid.Empty() && this.state.rppId != '') {
      HistoryUtil.goBack();
    }
    else if (this.state.fromAcademicYearList) {
      HistoryUtil.push(Routes.generate(Routes.DISTRICTS_ACADEMIC_YEAR_LIST, { academicYearId: this.state.academicYearId }, {}));
    }
    else if (this.state.fromSelfStudies) {
      HistoryUtil.push(Routes.generate(Routes.DISTRICTS, { academicYearId: this.state.academicYearId }));
    }
    else {
      if (CurrentUser.Get() == null) {
        HistoryUtil.push(Routes.generate(Routes.ALL_DISTRICTS, {}));
      }
      else {
        HistoryUtil.push(Routes.generate(Routes.ALL_DISTRICTS));
      }
    }
  }

  private onSelect = (value: any) => {
    this.setState({ academicYearId: value, id: Guid.Empty() });
    this.loadDistrict(Guid.Empty(), value, this.state.districtIdentifier);
  }

  render() {
    if (!this.state.academicYearId || this.state.academicYearId == 0) {
      return (<antd.Skeleton active></antd.Skeleton>);
    }
    const title = this.state.loading ? '...' : this.state.isNew ? 'New District' : this.state.district?.name ?? '...';
    const isPublic = CurrentUser.Get() == null;

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToListing}
          breadcrumbs={this.state.rppId == Guid.Empty() || this.state.rppId == '' ? Breadcrumbs.districtDetailPage(title, this.state.id, this.state.academicYearId) : Breadcrumbs.districtDetailPageFromRPP(title, this.state.regions.find(x => x.regionalPlanningPartnerIdentifier == this.state.district?.regionIdentifier)?.name ?? '', this.state.id, this.state.academicYearId, this.state.rppId)}
          extra={this.renderMoreActions()}
          footer={<Tabs activeKey={this.state.activeTab} onChange={this.changeTab} >
            <Tabs.TabPane tab="Details" key="details" />
            <Tabs.TabPane tab="Programs" key="programs" />
            {isPublic ? null : <Tabs.TabPane tab="Users" key="users" />}
            <Tabs.TabPane tab="Contacts" key="contacts" />
            {isPublic ? null : <Tabs.TabPane tab="Program Reviews" key="programReviews" />}
          </Tabs>
          }
        />

        {this.renderDetailsCard()}
        {this.renderProgramsCard(isPublic)}
        {this.renderDistrictUsersCard(isPublic)}
        {this.renderProgramsForDistricts()}
        {this.renderDistrictContactsCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    if (this.state.activeTab == 'details') {
      const editButton = this.state.id == Guid.Empty() ?
        null :
        (
          <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_DISTRICT]}>
            <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
          </AuthorizedContent>
        );

      return (
        <antd.Card title="Details" extra={editButton} >
          <DistrictDetailsForm
            ref={this._districtDetailsRef}
            districtId={this.state.id}
            isEditing={this.state.isEditing}
            isNew={this.state.isNew}
            onSave={this.districtDetailsPageSaved}
            academicYear={this.state.academicYearId} />
        </antd.Card>
      );
    }
  }

  renderProgramsCard(isPublic: boolean) {
    if (this.state.activeTab == 'programs') {
      return (
        <antd.Card title="Programs" >
          <SecondaryProgramsDataTable
            isPublic={isPublic}
            districtId={this.state.id ?? Guid.Empty()}
            academicYear={this.state.academicYearId} />
        </antd.Card>
      );
    }
  }

  renderDistrictUsersCard(isPublic: boolean) {
    if (this.state.activeTab === 'users') {
      if (this.state.id != Guid.Empty()) {
        return (
          <Card title="Districts">
            <DistrictUsersDataTable
              districtName={this.state.district?.name ?? ''}
              districtIdentifier={this.state.districtIdentifier}
              isPublic={isPublic} />
          </Card >
        );
      }
    }
  }

  renderProgramsForDistricts() {
    if (this.state.activeTab === 'programReviews') {
      if (this.state.id != Guid.Empty()) {
        return (
          <Card title="Program Reviews">
            <SelfStudyDataTable
              programsForReview={true}
              academicYear={this.state.academicYearId}
              selectedDistrict={this.state.district ?? null}
              selectedRPP={null}
              userActions={true} />
          </Card >
        );
      }
    }
  }

  renderDistrictContactsCard() {
    if (this.state.activeTab === 'contacts') {
      if (this.state.id != Guid.Empty()) {
        return (
          <Card title="District Contacts">
            <DistrictContactsDataTable districtId={this.state.id} academicYear={this.state.academicYearId} />
          </Card >
        );
      }
    }
  }

  renderMoreActions() {
    const menu = (
      <antd.Menu>
        < antd.Menu.Item title='Delete District' key={this.state.id} onClick={() => this.deleteConfirmation(this.state.id, this.state.academicYearId)}  >
          Delete District
        </antd.Menu.Item>
      </antd.Menu>
    );
    return (
      <>
        <label title={'academicYear'} htmlFor={'academicYear'} />
        <Dropdown
          id='academicYear'
          value={this.state.academicYearId}
          onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.academicYears.map(x => { return this.renderAcademicYear(x); })}</Dropdown>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_DISTRICT]}>
          {this.state.isNew ? null
            : <antd.Dropdown overlay={menu}>
              <antd.Button size='large' type='link' >
                <MoreOutlined />
              </antd.Button>
            </antd.Dropdown>}
        </AuthorizedContent>
      </>
    );
  }

  renderDistrictAcademicYear(districsAcademicYear: DistrictDTO) {
    return <antd.Select.Option key={districsAcademicYear.districtId} value={districsAcademicYear.academicYear}>{districsAcademicYear.academicYear}</antd.Select.Option>
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <antd.Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</antd.Select.Option>
  }
}

export default withRouter(DistrictDetailsPage);
