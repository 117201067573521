interface EntitySelectorDTO { }

const EntitySelectorDTO = {
    create: (initValues?: Partial<EntitySelectorDTO> | Record<string, unknown> | null) => {
    return Object.assign({
    },
      initValues);
  }
};

export default EntitySelectorDTO;