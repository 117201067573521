// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
enum ValidationStatus {
    NONE = 0,
    SUCCESS = 1,
    ERROR = 2,
    WARNING = 3,
    VALIDATING = 4,
}
export default ValidationStatus;
