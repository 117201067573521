// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import RegionalPlanningPartnerDTO from './RegionalPlanningPartnerDTO';
import AreaEducationAgencyDTO from './AreaEducationAgencyDTO';
import UserDTO from './UserDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface DistrictDTO extends EntitySelectorDTO { 
	id: string | null;
	districtId: number;
	academicYear: number;
	areaEducationAgencyIdentifier: number;
	name: string | null;
	regionIdentifier: number;
	region: RegionalPlanningPartnerDTO | null;
	regionPlanningPartnerId: string | null;
	areaEducationAgency: AreaEducationAgencyDTO | null;
	areaEducationAgencyId: string | null;
	districtApprovalUserId: string | null;
	districtApprovalUser: UserDTO | null;
	display: string | null;
}

const DistrictDTO = {
    className: 'DistrictDTO',
    create: (initValues?: Partial<DistrictDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			districtId: 0,
			academicYear: 0,
			areaEducationAgencyIdentifier: 0,
			name: "",
			regionIdentifier: 0,
			region: null,
			regionPlanningPartnerId: '00000000-0000-0000-0000-000000000000',
			areaEducationAgency: null,
			areaEducationAgencyId: '00000000-0000-0000-0000-000000000000',
			districtApprovalUserId: '00000000-0000-0000-0000-000000000000',
			districtApprovalUser: null,
			display: "",
        },
        initValues);
    },
	id: 'id',
	districtId: 'districtId',
	academicYear: 'academicYear',
	areaEducationAgencyIdentifier: 'areaEducationAgencyIdentifier',
	name: 'name',
	regionIdentifier: 'regionIdentifier',
	region: 'region',
	regionPlanningPartnerId: 'regionPlanningPartnerId',
	areaEducationAgency: 'areaEducationAgency',
	areaEducationAgencyId: 'areaEducationAgencyId',
	districtApprovalUserId: 'districtApprovalUserId',
	districtApprovalUser: 'districtApprovalUser',
	display: 'display',
};

export default DistrictDTO;