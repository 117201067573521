import { CloseOutlined, CommentOutlined } from '@ant-design/icons';
import { Button, Card, Col, Drawer, Row, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import ChangeRequestCommentsForm from '../forms/postSecondary/ChangeRequestCommentsForm';
import Breadcrumbs from '../../config/Breadcrumbs';
import ChangeRequestType from '../../consts/ChangeRequestType';
import ChangeRequestDTO from '../../models/ChangeRequestDTO';
import UserSecurity from '../../models/UserSecurity';
import ChangeRequestHeaderPortal from '../../portals/postSecondary/ChangeRequestHeaderPortal';
import Guid from '../../utils/Guid';
import AdministrativeChangeRequestSteps from '../displays/postSecondary/AdministrativeChangeRequestSteps';
import CourseDeactivationChangeRequestSteps from '../displays/postSecondary/CourseDeactivationChangeRequestSteps';
import CourseModificationChangeRequestSteps from '../displays/postSecondary/CourseModificationChangeRequestSteps';
import NewCourseChangeRequestSteps from '../displays/postSecondary/NewCourseChangeRequestSteps';
import NewDisciplineChangeRequestSteps from '../displays/postSecondary/NewDisiplineChangeRequestSteps';
import NewProgramChangeRequestSteps from '../displays/postSecondary/NewProgramChangeRequestSteps';
import NewTransferMajorProgramChangeRequestSteps from '../displays/postSecondary/NewTransferMajorProgramChangeRequestSteps';
import NoticeOfIntentChangeRequestSteps from '../displays/postSecondary/NoticeOfIntentChangeRequestSteps';
import ProgramDeactivationChangeRequestSteps from '../displays/postSecondary/ProgramDeactivationChangeRequestSteps';
import ProgramModificationChangeRequestSteps from '../displays/postSecondary/ProgramModificationChangeRequestSteps';
import ProgramModificationTransferMajorChangeRequestSteps from '../displays/postSecondary/ProgramModificationTransferMajorChangeRequestSteps';

interface ChangeRequestLayoutProps {
  changeRequest?: ChangeRequestDTO;
  awardId?: string;
  stepNumber: number;
  currentUser: UserSecurity;
}

function ChangeRequestLayout(props: React.PropsWithChildren<ChangeRequestLayoutProps>) {
  const stepWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 6,
    xxl: 6,
  }

  const contentWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 18,
    xxl: 18,
  }

  const formWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24
  }

  let title = '';
  switch (props?.changeRequest?.changeRequestTypeId) {
    case ChangeRequestType.ADMINISTRATIVECHANGE:
      title = 'Administrative Change Request';
      break;
    case ChangeRequestType.COURSEDEACTIVATION:
      title = 'Course Deactivation Request';
      break;
    case ChangeRequestType.COURSEMODIFICATION:
      title = 'Course Modification Request';
      break;
    case ChangeRequestType.NEWCOURSE:
      title = 'New Course Request';
      break;
    case ChangeRequestType.NEWDISCIPLINE:
      title = 'New Discipline Request';
      break;
    case ChangeRequestType.NOTICEOFINTENT:
      title = 'Notice of Intent';
      break;
    case ChangeRequestType.PROGRAMDEACTIVATION:
      title = 'Program Deactivation Request';
      break;
    case ChangeRequestType.PROGRAMMODIFICATION:
      title = 'Program Modification Request';
      break;
    case ChangeRequestType.TRANSFERMAJORPROGRAMMODIFICATION:
      title = 'Transfer Program Modification Request';
      break;
    case ChangeRequestType.NEWPROGRAM:
      title = 'New Program Request';
      break;
    case ChangeRequestType.NEWTRANSFERMAJORPROGRAM:
      title = 'New Transfer Program Request';
      break;
  }

  const display = props.changeRequest?.display ?? title;

  let breadcrumbs = [] as any;
  switch (props?.changeRequest?.changeRequestTypeId) {
    case ChangeRequestType.ADMINISTRATIVECHANGE:
      breadcrumbs = Breadcrumbs.administrativeChangeRequestEntry(display, props?.changeRequest?.id ?? Guid.Empty());
      break;
    case ChangeRequestType.COURSEDEACTIVATION:
      breadcrumbs = Breadcrumbs.courseDeactivationChangeRequestEntry(display, props?.changeRequest?.id ?? Guid.Empty());
      break;
    case ChangeRequestType.COURSEMODIFICATION:
      breadcrumbs = Breadcrumbs.courseModificationChangeRequestEntry(display, props?.changeRequest?.id ?? Guid.Empty());
      break;
    case ChangeRequestType.NEWCOURSE:
      breadcrumbs = Breadcrumbs.newCourseChangeRequestEntry(display, props?.changeRequest?.id ?? Guid.Empty());
      break;
    case ChangeRequestType.NEWDISCIPLINE:
      breadcrumbs = Breadcrumbs.newDisciplineChangeRequestEntry(display, props?.changeRequest?.id ?? Guid.Empty());
      break;
    case ChangeRequestType.NOTICEOFINTENT:
      breadcrumbs = Breadcrumbs.noticeOfIntentChangeRequestEntry(display, props?.changeRequest?.id ?? Guid.Empty());
      break;
    case ChangeRequestType.PROGRAMDEACTIVATION:
      breadcrumbs = Breadcrumbs.programDeactivationChangeRequestEntry(display, props?.changeRequest?.id ?? Guid.Empty());
      break;
    case ChangeRequestType.PROGRAMMODIFICATION:
    case ChangeRequestType.TRANSFERMAJORPROGRAMMODIFICATION:
      breadcrumbs = Breadcrumbs.programModificationChangeRequestEntry(display, props?.changeRequest?.id ?? Guid.Empty());
      break;
    case ChangeRequestType.NEWPROGRAM:
    case ChangeRequestType.NEWTRANSFERMAJORPROGRAM:
      breadcrumbs = Breadcrumbs.newProgramChangeRequestEntry(display, props?.changeRequest?.id ?? Guid.Empty());
      break;
  }

  let steps = null;
  switch (props?.changeRequest?.changeRequestTypeId) {
    case ChangeRequestType.ADMINISTRATIVECHANGE:
      steps = <AdministrativeChangeRequestSteps changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.COURSEDEACTIVATION:
      steps = <CourseDeactivationChangeRequestSteps changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.COURSEMODIFICATION:
      steps = <CourseModificationChangeRequestSteps changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.NEWCOURSE:
      steps = <NewCourseChangeRequestSteps changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.NEWDISCIPLINE:
      steps = <NewDisciplineChangeRequestSteps changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.NOTICEOFINTENT:
      steps = <NoticeOfIntentChangeRequestSteps changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.PROGRAMDEACTIVATION:
      steps = <ProgramDeactivationChangeRequestSteps changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.PROGRAMMODIFICATION:
      steps = <ProgramModificationChangeRequestSteps changeRequest={props.changeRequest} awardId={props.awardId} changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.TRANSFERMAJORPROGRAMMODIFICATION:
      steps = <ProgramModificationTransferMajorChangeRequestSteps changeRequest={props.changeRequest} awardId={props.awardId} changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.NEWPROGRAM:
      steps = <NewProgramChangeRequestSteps changeRequest={props.changeRequest} awardId={props.awardId} changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
    case ChangeRequestType.NEWTRANSFERMAJORPROGRAM:
      steps = <NewTransferMajorProgramChangeRequestSteps changeRequest={props.changeRequest} awardId={props.awardId} changeRequestId={props.changeRequest?.id ?? Guid.Empty()} current={props.stepNumber} />
      break;
  }

  const size = useBreakpoint();

  const [visible, setVisible] = React.useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  let stepsAndComments = <div />;
  if (size.xxl || size.xl) {
    stepsAndComments = (
      <Col >
        <Card title='Steps'>
          {steps}
        </Card>

        <br />
        <Card title='Comments'>
          <ChangeRequestCommentsForm
            approvalActiveOn={null}
            disabled={props.changeRequest?.isExpired ?? true} changeRequestId={props.changeRequest?.id ?? Guid.Empty()} changeRequestStatusTypeId={props.changeRequest?.statusTypeId ?? 0} currentUser={props.currentUser} />
        </Card>
      </Col>);
  }
  else {
    stepsAndComments = (
      <Card>
        <Space direction='horizontal' split='|' align='center'>
          <Button style={{ float: 'right' }} icon={<CommentOutlined />} size='large' type='link' onClick={showDrawer} />
          {steps}
        </Space>

        <Drawer width='100%' closeIcon={<CloseOutlined />} title="Comments" placement="left" onClose={onClose} visible={visible}>
          <ChangeRequestCommentsForm
            approvalActiveOn={null}
            disabled={props.changeRequest?.isExpired ?? true}
            changeRequestStatusTypeId={props.changeRequest?.statusTypeId ?? 0}
            changeRequestId={props.changeRequest?.id ?? Guid.Empty()} currentUser={props.currentUser} />
        </Drawer>
      </Card >
    );
  }


  return (
    <Content >
      <ChangeRequestHeaderPortal
        breadcrumbs={breadcrumbs}
        pageTitle={title}
        changeRequestId={props.changeRequest?.id ?? Guid.Empty()}
        current={props.stepNumber}
        changeReqeustType={props?.changeRequest?.changeRequestTypeId ?? 0} />

      <Row gutter={[16, 16]}>
        <Col {...stepWidth} >
          {stepsAndComments}
        </Col>

        <Col {...contentWidth} >
          <Row gutter={[16, 16]}>
            <Col {...formWidth}>
              {props.children}
            </Col >
          </Row>
        </Col>
      </Row>
    </Content >
  );
}

export default ChangeRequestLayout;