import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DownloadOutlined, MoreOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Dropdown, Form, FormInstance, Input, List, Menu, message, Modal, Result, Row, Space, Tabs, Typography } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChangeRequestApiService from '../../../api/ChangeRequestApiService';
import ProgramApiService from '../../../api/ProgramApiService';
import ChangeRequestApprovalDataTable from '../../../components/datatables/postSecondary/ChangeRequestApprovalDataTable';
import AdministrativeChangeRequestDetails from '../../../components/displays/postSecondary/AdministrativeChangeRequestDetails';
import ChangeRequestMainDetails from '../../../components/displays/postSecondary/ChangeRequestMainDetails';
import CourseDeactivationChangeRequestDetails from '../../../components/displays/postSecondary/CourseDeactivationChangeRequestDetails';
import CourseModificationChangeRequestDetails from '../../../components/displays/postSecondary/CourseModificationChangeRequestDetails';
import NewCourseChangeRequestDetails from '../../../components/displays/postSecondary/NewCourseChangeRequestDetails';
import NewDisciplineChangeRequestDetails from '../../../components/displays/postSecondary/NewDisciplineChangeRequestDetails';
import NewProgramChangeRequestReviewForm from '../../../components/displays/postSecondary/NewProgramChangeRequestDetails';
import NoticeOfIntentChangeRequestDetails from '../../../components/displays/postSecondary/NoticeOfIntentChangeRequestDetails';
import ProgramDeactivationChangeRequestDetails from '../../../components/displays/postSecondary/ProgramDeactivationChangeRequestDetails';
import ProgramModificationChangeRequestDetails from '../../../components/displays/postSecondary/ProgramModificationChangeRequestDetails';
import ApprovalRedirectForm from '../../../components/forms/postSecondary/ApprovalRedirectForm';
import ChangeRequestCommentsForm from '../../../components/forms/postSecondary/ChangeRequestCommentsForm';
import PageTitle from '../../../components/shared/PageTitle';
import Breadcrumbs from '../../../config/Breadcrumbs';
import { ColumnWidths } from '../../../config/ColumnWidths';
import Routes from '../../../config/Routes';
import ApprovalStatusType from '../../../consts/ApprovalStatusType';
import ChangeRequestStatusType from '../../../consts/ChangeRequestStatusType';
import ChangeRequestType from '../../../consts/ChangeRequestType';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetBaseChangeRequestDetailsHandler from '../../../handlerModels/GetBaseChangeRequestDetailsHandler';
import * as GetChangeRequestApprovalOnDetailsHandler from '../../../handlerModels/GetChangeRequestApprovalOnDetailsHandler';
import * as GetChangeRequestDetailsHandler from '../../../handlerModels/GetChangeRequestDetailsHandler';
import * as GetProgramWithAllDetailsHandler from '../../../handlerModels/GetProgramWithAllDetailsHandler';
import * as GetChangeRequestForWorkItemApprovalHandler from '../../../handlerModels/GetChangeRequestForWorkItemApprovalHandler';
import * as ManualApprovalHandler from '../../../handlerModels/ManualApprovalHandler';
import * as ManualRejectHandler from '../../../handlerModels/ManualRejectHandler';
import ChangeRequestApprovalDTO from '../../../models/ChangeRequestApprovalDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import ParameterUtil from '../../../utils/ParameterUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ProgramDTO from '../../../models/ProgramDTO';
import HistoryUtil from '../../../utils/HistoryUtil';

interface ChangeRequestDetailsState extends BaseFormState {
  changeRequestId: string;
  approvalId: string;
  approvalUserId: string;
  approvalStatusTypeId?: number;
  changeRequestApproval: ChangeRequestApprovalDTO;
  changeRequest: ChangeRequestDTO;
  activeTab: string;
  redirectNeeded: boolean;
  isRedirect: boolean;
  showManualApprove: boolean;
  showManualReject: boolean;
  approvalNotAvailable: boolean;
  program: ProgramDTO;
}

interface ChangeRequestDetailsProps extends BaseChangeRequestProps {
  currentUser: UserSecurity | null;
}

class ChangeRequestDetailsPage extends React.Component<ChangeRequestDetailsProps & RouteComponentProps<RouteObject>, ChangeRequestDetailsState> {
  private readonly _manualApproveFormRef = React.createRef<FormInstance>();
  private readonly _manualRejectFormRef = React.createRef<FormInstance>();
  private readonly _commentsRef = React.createRef<ChangeRequestCommentsForm>();

  private getManualApproveFormItems = () => {
    const fields = new Map<string, FormItemProps>()
      .set(ManualApprovalHandler.Request.message, {
        label: 'Please provide a reason for manually approving this change request.',
        name: ManualApprovalHandler.Request.message,
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
    return fields;
  }

  private getManualRejectFormItems = () => {
    const fields = new Map<string, FormItemProps>()
      .set(ManualRejectHandler.Request.message, {
        label: 'Please provide a reason for manually rejecting this change request.',
        name: ManualRejectHandler.Request.message,
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
    return fields;
  }

  constructor(props: ChangeRequestDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      approvalId: Guid.Empty(),
      changeRequestId: Guid.Empty(),
      changeRequestApproval: ChangeRequestApprovalDTO.create(),
      activeTab: 'details',
      loading: true,
      redirectNeeded: false,
      approvalUserId: Guid.Empty(),
      isRedirect: false,
      showManualApprove: false,
      showManualReject: false,
      approvalNotAvailable: false,
      program: ProgramDTO.create()
    };
  }

  componentDidMount() {
    const changeRequestId = ParameterUtil.getPathPart(this.props.match, 'id', Guid.Empty());
    const approvalId = ParameterUtil.getPathPart(this.props.match, 'approvalId', Guid.Empty());

    if (changeRequestId != Guid.Empty()) {
      this.getBaseChangeRequest(changeRequestId);
    }
    else if (Routes.isMatch(Routes.CHANGE_REQUEST_APPROVAL, this.props.location.pathname) && approvalId != Guid.Empty()) {
      this.loadApproval(approvalId);
    }
    else if (Routes.isMatch(Routes.CHANGE_REQUEST_APPROVAL_REDIRECT, this.props.location.pathname) && approvalId != Guid.Empty()) {
      this.loadRedirectApproval(approvalId);
    }
  }

  private loadRedirectApproval = (approvalId: string) => {
    const request = GetChangeRequestForWorkItemApprovalHandler.Request.create({
      approvalId: approvalId,
    });

    ChangeRequestApiService.getChangeRequestApproval(request)
      .then((results: GetChangeRequestForWorkItemApprovalHandler.Result) => {
        if (results.changeRequest) {
          this.setState({
            changeRequestId: results.changeRequest.id ?? Guid.Empty(),
            approvalId: approvalId,
            changeRequest: results.changeRequest,
            approvalStatusTypeId: results.approvalStatusTypeId,
            redirectNeeded: results.redirectNeeded,
            isRedirect: true,
          });
        }
        else {
          this.setState({
            approvalNotAvailable: true
          })
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadApproval = (approvalId: string) => {
    const request = GetChangeRequestForWorkItemApprovalHandler.Request.create({
      approvalId: approvalId
    });

    ChangeRequestApiService.getChangeRequestApproval(request)
      .then((results: GetChangeRequestForWorkItemApprovalHandler.Result) => {
        if (results.changeRequest) {
          this.setState({
            changeRequestId: results.changeRequest.id ?? Guid.Empty(),
            approvalId: approvalId,
            changeRequest: results.changeRequest,
            changeRequestApproval: results.changeRequestApproval ?? ChangeRequestApprovalDTO.create(),
            approvalStatusTypeId: results.approvalStatusTypeId,
            approvalUserId: results.approvalUserId ?? Guid.Empty()
          });
        }
        else {
          this.setState({
            approvalNotAvailable: true
          })
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadChangeRequestApprovalOnDetails = (changeRequestId: string) => {
    this.setState({ loading: true })
    const request = GetChangeRequestApprovalOnDetailsHandler.Request.create({
      changeRequestId: changeRequestId
    });

    ChangeRequestApiService.getChangeRequestApprovalOnDetailsHandler(request)
      .then((results: GetChangeRequestApprovalOnDetailsHandler.Result) => {
        if (results.changeRequestApproval) {
          this.setState({
            approvalId: results.changeRequestApproval.id ?? Guid.Empty(),
            approvalStatusTypeId: results.changeRequestApproval.approvalStatusTypeId,
            changeRequestApproval: results.changeRequestApproval ?? ChangeRequestApprovalDTO.create(),
            approvalUserId: results.changeRequestApproval.userId ?? Guid.Empty()
          })
        }
        else {
          this.setState({
            approvalId: Guid.Empty(),
            approvalStatusTypeId: 0,
            changeRequestApproval: ChangeRequestApprovalDTO.create(),
            approvalUserId: Guid.Empty()
          })
        }
      });
  }

  private loadExisting = (changeRequestId: string) => {
    ChangeRequestApiService.getChangeRequestDetails(changeRequestId)
      .then((results: GetChangeRequestDetailsHandler.Result) => {
        if (results.changeRequest) {
          this.setState({
            changeRequestId: changeRequestId,
            changeRequest: results.changeRequest
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadProgramDetails = (programId: string) => {
    ProgramApiService.getProgramWithAllDetails(programId)
      .then((results: GetProgramWithAllDetailsHandler.Result) => {
        if (results.program) {

          this.setState({
            program: results.program
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private getChangeRequestStatus = () => {
    ChangeRequestApiService.getBaseChangeRequestDetails(this.state.changeRequestId)
      .then((results: GetBaseChangeRequestDetailsHandler.Result) => {
        if (results.changeRequest) {
          const changeRequest = this.state.changeRequest;
          changeRequest.statusTypeId = results.changeRequest.statusTypeId;
          changeRequest.approvedOn = results.changeRequest.approvedOn;
          this.setState({ changeRequest: changeRequest });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private getBaseChangeRequest = (changeRequestId: string) => {
    ChangeRequestApiService.getBaseChangeRequestDetails(changeRequestId)
      .then((results: GetBaseChangeRequestDetailsHandler.Result) => {
        if (results.changeRequest) {
          this.loadChangeRequestApprovalOnDetails(changeRequestId);
          const changeRequest = results.changeRequest;
          this.loadProgramDetails(changeRequest.programModificationChangeRequest?.programId ?? Guid.Empty());
          changeRequest.statusTypeId = results.changeRequest.statusTypeId;
          changeRequest.approvedOn = results.changeRequest.approvedOn;
          this.setState({ changeRequest: changeRequest, changeRequestId: changeRequestId });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private fetchComments = () => {
    this.getBaseChangeRequest(this.state.changeRequestId);
  }

  private tabChanged = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
  }

  private NOIExport = () => {
    window.open(Routes.generateFull(Routes.NOTICE_OF_INTENT_REPORT, {}, { changeRequestId: this.state.changeRequestId }), '_blank')
  }

  private NOIICCPHSEExport = () => {
    if (this.state.changeRequest.changeRequestTypeId == ChangeRequestType.NEWPROGRAM && this.state.changeRequest.newProgramChangeRequest?.noticeOfIntentChangeRequestId) {
      window.open(Routes.generateFull(Routes.NOTICE_OF_INTENT_ICCPHSE_REPORT, {}, { changeRequestId: this.state.changeRequestId ,noticeOfIntentId: this.state.changeRequest.newProgramChangeRequest?.noticeOfIntentChangeRequestId }), '_blank')
    }
    else {
      window.open(Routes.generateFull(Routes.NOTICE_OF_INTENT_ICCPHSE_REPORT, {}, { changeRequestId: this.state.changeRequestId }), '_blank')
    }
  }

  private ProgramExport = () => {
    window.open(Routes.generateFull(Routes.PROGRAM_REPORT, {}, { changeRequestId: this.state.changeRequestId }), '_blank')
  }

  private NewProgramProposalDownload = () => {
    window.open(Routes.generateFull(Routes.NEW_PROGRAM_PROPOSAL, {}, { changeRequestId: this.state.changeRequestId }), '_blank')
  }

  private DraftAS28Download = () => {
    window.open(Routes.generateFull(Routes.DRAFT_AS28_REPORT, { changeRequestId: this.state.changeRequestId ?? Guid.Empty() }), '_blank')
  }

  private ProgramComparisonReport = () => {
    window.open(Routes.generateFull(Routes.PROGRAM_COMPARISON_REPORT, { changeRequestId: this.state.changeRequestId ?? Guid.Empty() }), '_blank')
  }

  private ProgramSideBySideComparisonReport = () => {
    window.open(Routes.generateFull(Routes.SIDE_BY_SIDE_REPORT, { changeRequestId: this.state.changeRequestId ?? Guid.Empty() }), '_blank')
  }

  private PreviousProgramReport = () => {
    const detailId = this.getDetailId();
    window.open(Routes.generateFull(Routes.PREVIOUS_PROGRAM_CURRICULUM_REPORT, { programDetailId: detailId ?? Guid.Empty() }), '_blank')
  }

  private LaborMarketInformationReport = () => {
    window.open(Routes.generateFull(Routes.LABOR_MARKET_INFO_REPORT, {}, { changeRequestId: this.state.changeRequestId }), '_blank')
  }

  private promptManualApprove = () => {
    this.setState({
      showManualApprove: true
    });
  }

  private closeManualApprove = () => {
    this.setState({
      showManualApprove: false
    });
  }

  private promptManualReject = () => {
    this.setState({
      showManualReject: true
    });
  }

  private closeManualReject = () => {
    this.setState({
      showManualReject: false
    });
  }

  private getNOIDownloadList = () => {
    return [
      {
        title: 'Notice of Intent Form',
        action: () => this.NOIExport()
      },
      {
        title: 'Notice of Intent ICCPHSE Form',
        action: () => this.NOIICCPHSEExport()
      },
      {
        title: 'Labor Market Information Report',
        action: () => this.LaborMarketInformationReport()
      }
    ];
  }

  private getProgramModDownloadList = () => {
    return [
      {
        title: 'Preview Program Curriculum Report (AS-28)',
        action: () => this.ProgramComparisonReport()
      },
      {
        title: 'Existing Program Curriculum Report (AS-28)',
        action: () => this.PreviousProgramReport()
      },
      {
        title: 'Side By Side Comparison Report',
        description: 'File contains a side by side view of the program modification and previous program.',
        action: () => this.ProgramSideBySideComparisonReport()
      }
    ];
  }

  private getNewProgramDownloadList = () => {

    const list = [
      {
        title: 'Program Summary',
        action: () => this.ProgramExport()
      },
      {
        title: 'New Program Proposal',
        action: () => this.NewProgramProposalDownload()
      },
      {
        title: 'Preview Program Curriculum Report (AS-28)',
        description: 'File contains a draft version of the Program Curriculum Report (AS-28).',
        action: () => this.DraftAS28Download()
      }
    ];

    if (this.state.changeRequest.changeRequestTypeId == ChangeRequestType.NEWPROGRAM) {
      list.push({
        title: 'Notice of Intent ICCPHSE Form',
        action: () => this.NOIICCPHSEExport()
      });
    }

    return list;
  }

  private handleManualApprove = () => {
    this.setState({
      submitting: true
    });

    const model = this._manualApproveFormRef.current?.getFieldsValue() ?? {};
    const request = ManualApprovalHandler.Request.create({
      changeRequestId: this.state.changeRequest.id,
      message: model.message
    });

    ChangeRequestApiService.manualApproval(request)
      .then((result: ManualApprovalHandler.Result) => {
        if (result?.succeeded) {
          message.success('Approved');
          this.getChangeRequestStatus();
          this._commentsRef.current?.triggerUpdate();
          this.closeManualApprove();
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Error Approving');
        }
      }).catch(() => {
        message.error('Error Approving');
        this.setState({ error: true });
      }).finally(() => {
        this.setState({
          submitting: false,
          submitted: true
        });
      });
  }

  private handleManualReject = () => {
    this.setState({
      submitting: true
    });

    const model = this._manualRejectFormRef.current?.getFieldsValue() ?? {};
    const request = ManualApprovalHandler.Request.create({
      changeRequestId: this.state.changeRequest.id,
      message: model.message
    });

    ChangeRequestApiService.manualReject(request)
      .then((result: ManualApprovalHandler.Result) => {
        if (result?.succeeded) {
          message.success('Rejected');
          this.getChangeRequestStatus();
          this._commentsRef.current?.triggerUpdate();
          this.closeManualReject();
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Error Rejecting');
        }
      }).catch(() => {
        message.error('Error Rejecting');
        this.setState({ error: true });
      }).finally(() => {
        this.setState({
          submitting: false,
          submitted: true
        });
      });
  }

  goBack = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.changeRequest.display ?? '...';

    if (this.state.approvalNotAvailable) {
      return (
        <Content >
          <Result
            status="warning"
            title="No Longer Available"
            extra={
              <Typography.Paragraph>This approval is no longer available.</Typography.Paragraph>
            }
          />
        </Content>
      )
    }

    return (
      <Content >
        <PageTitle title={title} />
        <HeaderPortal title={title}
          subTitle={this.state.changeRequest?.isExpired ? <span><WarningFilled /> Expired</span> : null}
          breadcrumbs={Breadcrumbs.changeRequestDetails(this.state.changeRequest?.display ?? '', this.state.changeRequestId)}
          extra={this.renderAdditionalActions()}
          onBack={this.goBack}
          footer={
            <Tabs onChange={this.tabChanged}>
              <Tabs.TabPane key='details' tab='Details' />
              <Tabs.TabPane key='approvals' tab='Approval History' />
              <Tabs.TabPane key='downloads' tab='Downloads' />
            </Tabs>
          } >
          <ChangeRequestMainDetails loading={this.state.loading} changeRequest={this.state.changeRequest} />
        </HeaderPortal>

        {this.renderManualApprove()}
        {this.renderManualReject()}
        {this.renderDetails()}
        {this.renderApprovals()}
        {this.renderDownloads()}
      </Content >
    );
  }

  renderManualApprove() {
    if (this.state.showManualApprove) {
      return <Modal
        title='Manual Approve?'
        width={750}
        closable={true}
        visible={true}
        footer={null}
        onCancel={this.closeManualApprove}
      >
        <Typography.Paragraph >
          Manual approval should only be used when a change request is stuck or needs to skip over the approval process.
        </Typography.Paragraph>

        <Typography.Paragraph >
          <strong>Are you sure you want to manually approve this change request?</strong>
        </Typography.Paragraph>

        <Form ref={this._manualApproveFormRef}
          layout="vertical"
          requiredMark={true}
          onFinish={this.handleManualApprove}>
          <Form.Item
            {...this.getManualApproveFormItems().get(ManualApprovalHandler.Request.message)}
            {...ValidationUtil.getValidation(ManualApprovalHandler.Request.message, this.state.fieldErrors, this.state.submitted)}>
            <Input.TextArea maxLength={5000} disabled={this.state.submitting} showCount={true} autoSize={{ minRows: 5 }} />
          </Form.Item>
        </Form>

        <Space direction='horizontal' wrap={true}>
          <Button type='primary' onClick={this.handleManualApprove} htmlType="submit" loading={this.state.submitting} icon={<CheckOutlined />}>Approve</Button>
        </Space>
      </Modal >
    }
  }

  renderManualReject() {
    if (this.state.showManualReject) {
      return <Modal
        title='Manual Reject?'
        width={750}
        closable={true}
        visible={true}
        footer={null}
        onCancel={this.closeManualReject}
      >
        <Typography.Paragraph >
          Manual reject should only be used when a change request is stuck or needs to skip over the deny process.
        </Typography.Paragraph>

        <Typography.Paragraph >
          <strong>Are you sure you want to manually reject this change request?</strong>
        </Typography.Paragraph>

        <Form ref={this._manualRejectFormRef}
          layout="vertical"
          requiredMark={true}
          onFinish={this.handleManualReject}>
          <Form.Item
            {...this.getManualRejectFormItems().get(ManualRejectHandler.Request.message)}
            {...ValidationUtil.getValidation(ManualRejectHandler.Request.message, this.state.fieldErrors, this.state.submitted)}>
            <Input.TextArea maxLength={5000} disabled={this.state.submitting} showCount={true} autoSize={{ minRows: 5 }} />
          </Form.Item>
        </Form>

        <Space direction='horizontal' wrap={true}>
          <Button type='primary' danger onClick={this.handleManualReject} htmlType="submit" loading={this.state.submitting} icon={<CloseOutlined />}>Reject</Button>
        </Space>
      </Modal >
    }
  }

  promptResendEmail = () => {
    Modal.confirm({
      title: 'Resend Approval Emails?',
      content: 'Are you sure you would like to resend the approval emails?',
      onOk: () => this.resendApprovalEmails(),
    });
  }

  resendApprovalEmails = () => {
    ChangeRequestApiService.resendEmails(this.state.changeRequestId)
      .then(() => {
        message.success('Emails Sent');
      })
      .catch(() => {
        message.error('Error Sending Emails');
      });
  }

  renderAdditionalActions() {
    if (AuthorizationUtil.isAuthorized([FeatureFlag.OVERRIDE_APPROVAL_USER])) {
      const menu = (
        <Menu>
          {
            this.state.changeRequest.statusTypeId != ChangeRequestStatusType.APPROVED ?
              <Menu.Item title='Manual Approve' onClick={this.promptManualApprove} >
                Manual Approve
              </Menu.Item>
              : null
          }
          {
            this.state.changeRequest.statusTypeId != ChangeRequestStatusType.APPROVED ?
              <Menu.Item title='Manual Reject' onClick={this.promptManualReject} >
                Manual Reject
              </Menu.Item>
              : null
          }
          {
            this.state.changeRequest.statusTypeId == ChangeRequestStatusType.APPROVED ?
              <Menu.Item title='Resend Emails' onClick={this.promptResendEmail} >
                Resend Emails
              </Menu.Item>
              : null
          }
        </Menu>
      );

      return (
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      );
    }
  }

  renderInstructions() {
    if (this.state.activeTab === 'details') {
      if (this.state.isRedirect) {
        if (this.state.redirectNeeded) {
          return this.renderRedirect();
        }
        else {
          return this.renderRedirectComplete();
        }
      }
      else if (DateTimeUtil.isFutureDate(moment(this.state.changeRequestApproval.activeOn))) {
        return this.renderDayToWaitApprovalInstructions();
      }
      else if (this.state.approvalId != Guid.Empty() && this.state.approvalUserId == this.props.currentUser?.userId) {
        return this.renderApprovalInstructions();
      }
    }
  }

  private readonly _formRef = React.createRef<FormInstance>();

  private handleRedirect = () => {
    this.setState({ redirectNeeded: false });
  }

  renderRedirect() {
    return (
      <div>
        <Card title={<span><WarningOutlined /> Redirect Required</span>}>
          <ApprovalRedirectForm onSuccess={this.handleRedirect} approvalId={this.state.approvalId} />
        </Card>
      </div>
    )
  }

  renderRedirectComplete() {
    return (
      <div>
        <Alert type='success' icon={true} description={
          <Typography.Text strong={true} >
            <span><CheckCircleOutlined />  Approval has been redirected.</span>
          </Typography.Text>
        } />
        <br />
      </div>
    );
  }

  renderDayToWaitApprovalInstructions() {
    return (
      <div>
        <Alert type={'warning'} description={
          <Typography.Text strong={true} >
            <span> <WarningOutlined /> This approval is not yet active. Please check back on {DateTimeUtil.shortDate(moment(this.state.changeRequestApproval.activeOn))}.</span>
          </Typography.Text>
        } />
        <br />
      </div>
    );
  }

  renderApprovalInstructions() {
    let instructions = {};
    let type = 'warning' as 'warning' | 'error' | 'success';

    if (this.state.approvalStatusTypeId == ApprovalStatusType.PENDING || this.state.approvalStatusTypeId == ApprovalStatusType.WAITING) {
      instructions = 'Please review the following change request to either approve or deny.';
    }
    else if (this.state.approvalStatusTypeId == ApprovalStatusType.APPROVED || this.state.approvalStatusTypeId == ApprovalStatusType.SKIPPED) {
      type = 'success';
      instructions = (<span><CheckCircleOutlined />  You have approved this change request.</span>);
    }
    else if (this.state.approvalStatusTypeId == ApprovalStatusType.DECLINED) {
      type = 'error';
      instructions = (<span><CloseCircleOutlined />  You have denied this change request.</span>);
    }

    return (
      <div>
        <Alert type={type} description={
          <Typography.Text strong={true} >
            {instructions}
          </Typography.Text>
        } />
        <br />
      </div>
    );
  }

  renderApprovals() {
    if (this.state.activeTab === 'approvals') {
      return (
        <Card title='Approval History' >
          <ChangeRequestApprovalDataTable disableActions={this.state.changeRequest?.isExpired ?? true} approvalSubmitted={this.getChangeRequestStatus} changeRequestId={this.state.changeRequest?.id ?? Guid.Empty()} changeRequest={this.state.changeRequest} />
        </Card>
      )
    }
  }

  renderDetails() {
    if (this.state.activeTab === 'details') {
      return (
        <Space direction='vertical'>
          {this.renderInstructions()}

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.TWO_THIRDS}>
              <Card title="Details" >
                {this.renderRequestDetails()}
              </Card>
            </Col>

            <Col {...ColumnWidths.ONE_THIRD}>
              <Card title="Comments" >
                <ChangeRequestCommentsForm
                  ref={this._commentsRef}
                  onSubmit={this.fetchComments}
                  currentUser={this.props.currentUser}
                  approvalUserId={this.state.approvalUserId}
                  changeRequestId={this.state.changeRequest?.id ?? Guid.Empty()}
                  changeRequestStatusTypeId={this.state.changeRequest.statusTypeId ?? Guid.Empty()}
                  approvalId={this.state.approvalId}
                  approvalActiveOn={this.state.changeRequestApproval.activeOn}
                  redirect={this.state.redirectNeeded}
                  approvalStatusTypeId={this.state.approvalStatusTypeId} />
              </Card>
            </Col>
          </Row>
        </Space >
      );
    }
  }

  renderRequestDetails() {
    switch (this.state.changeRequest.changeRequestTypeId) {
      case ChangeRequestType.ADMINISTRATIVECHANGE:
        return <AdministrativeChangeRequestDetails loading={this.state.loading} changeRequest={this.state.changeRequest} changeRequestDetailsPage={true} />
      case ChangeRequestType.NEWDISCIPLINE:
        return <NewDisciplineChangeRequestDetails loading={this.state.loading} changeRequest={this.state.changeRequest} changeRequestDetailsPage={true} />
      case ChangeRequestType.NEWCOURSE:
        return <NewCourseChangeRequestDetails loading={this.state.loading} changeRequest={this.state.changeRequest} changeRequestDetailsPage={true} />
      case ChangeRequestType.COURSEDEACTIVATION:
        return <CourseDeactivationChangeRequestDetails loading={this.state.loading} changeRequest={this.state.changeRequest} changeRequestDetailsPage={true} />
      case ChangeRequestType.COURSEMODIFICATION:
        return <CourseModificationChangeRequestDetails loading={this.state.loading} changeRequest={this.state.changeRequest} changeRequestDetailsPage={true} />
      case ChangeRequestType.NOTICEOFINTENT:
        return <NoticeOfIntentChangeRequestDetails loading={this.state.loading} changeRequest={this.state.changeRequest} changeRequestDetailsPage={true} />
      case ChangeRequestType.PROGRAMDEACTIVATION:
        return <ProgramDeactivationChangeRequestDetails loading={this.state.loading} changeRequest={this.state.changeRequest} changeRequestDetailsPage={true} />
      case ChangeRequestType.PROGRAMMODIFICATION:
      case ChangeRequestType.TRANSFERMAJORPROGRAMMODIFICATION:
        return <ProgramModificationChangeRequestDetails loading={this.state.loading} changeRequest={this.state.changeRequest} changeRequestDetailsPage={true} />
      case ChangeRequestType.NEWPROGRAM:
      case ChangeRequestType.NEWTRANSFERMAJORPROGRAM:
        return <NewProgramChangeRequestReviewForm loading={this.state.loading} changeRequest={this.state.changeRequest} changeRequestDetailsPage={true} />
    }
  }

  renderDownloads() {
    return (
      <Card title="Downloads" hidden={this.state.activeTab !== 'downloads'}>
        {this.renderRequestDownloads()}
      </Card>
    );
  }

  renderRequestDownloads() {
    switch (this.state.changeRequest.changeRequestTypeId) {
      case ChangeRequestType.ADMINISTRATIVECHANGE:
        return null;
      case ChangeRequestType.NEWDISCIPLINE:
        return null;
      case ChangeRequestType.NEWCOURSE:
        return null;
      case ChangeRequestType.COURSEDEACTIVATION:
        return null;
      case ChangeRequestType.COURSEMODIFICATION:
        return null;
      case ChangeRequestType.NOTICEOFINTENT:
        return this.renderDownloadList(this.getNOIDownloadList());
      case ChangeRequestType.PROGRAMDEACTIVATION:
        return null;
      case ChangeRequestType.PROGRAMMODIFICATION:
      case ChangeRequestType.TRANSFERMAJORPROGRAMMODIFICATION:
        return this.renderDownloadList(this.getProgramModDownloadList());
      case ChangeRequestType.NEWPROGRAM:
      case ChangeRequestType.NEWTRANSFERMAJORPROGRAM:
        return this.renderDownloadList(this.getNewProgramDownloadList());
    }
  }

  private getDetailId() {
    if (this.state.changeRequest.programModificationChangeRequest && (this.state.changeRequest.changeRequestTypeId == ChangeRequestType.PROGRAMMODIFICATION ||
      this.state.changeRequest.changeRequestTypeId == ChangeRequestType.TRANSFERMAJORPROGRAMMODIFICATION)) {
      const programModification = this.state.changeRequest.programModificationChangeRequest;
      return this.state.program.programDetails?.find(x => (x.install?.year ?? 0) < (programModification.install?.year ?? 0) ||
        ((x.install?.year ?? 0) == (programModification.install?.year ?? 0) && (x.install?.term?.startDate ?? 0) < (programModification.install?.term?.startDate ?? 0)))?.id ?? Guid.Empty();
    }
  }

  renderDownloadList(items: any[]) {
    return (
      <List
        itemLayout="horizontal"
        dataSource={items}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Button type="link" onClick={item.action}><DownloadOutlined /></Button>}
              title={<Button type="link" onClick={item.action}>{item.title}</Button>}
            />
          </List.Item>
        )}
      />
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value
  };
}

export default withRouter(connect(mapStateToProps)(ChangeRequestDetailsPage));