import { Button, Result } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

class UnauthorizedComponent extends React.Component<unknown, unknown> {
  constructor(props: unknown) {
    super(props);
  }

  render() {
    return (
      <Result
        status="403"
        title="Unauthorized"
        subTitle="Sorry, you are not authorized to access this page."
        extra={<Link to="/"><Button type="primary">Home</Button></Link>} />
    );
  }
}

export default UnauthorizedComponent;
