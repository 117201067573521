import { Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import modal from 'antd/lib/modal';
import * as React from 'react';
import CipsApiService from '../../../api/CipsApiService';
import * as DownloadProgramMasterReportHandler from '../../../handlerModels/DownloadProgramMasterReportHandler';
import * as GetCipNumbersHandler from '../../../handlerModels/GetCipNumbersHandler';
import CipNumberDetailDTO from '../../../models/CipNumberDetailDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import ModeOfDeliveryDTO from '../../../models/ModeOfDeliveryDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import TermYearUtil, { TermYear } from '../../../utils/TermYearUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import DownloadButton from '../../buttons/DownloadButton';
import ResetButton from '../../buttons/ResetButton';
import Dropdown from '../../inputs/Dropdown';
import TermInput, { TermInputValue } from '../../TermInput';
import DownloadsApiService from '../../../api/DownloadsApiService';

interface ProgramMasterReportDownloadFormState extends BaseFormState {
  modesOfDelivery: ModeOfDeliveryDTO[];
  cipNumbers: CipNumberDetailDTO[];
  institutions: InstitutionDTO[];
  currentYearTerm: TermYear | null;
  institutionId: string;
}

interface ProgramMasterReportDownloadFormProps extends BaseFormProps {
  institutionId?: string;
}

class ProgramMasterReportDownloadForm extends React.Component<
  ProgramMasterReportDownloadFormProps,
  ProgramMasterReportDownloadFormState
> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(DownloadProgramMasterReportHandler.Request.term, {
      name: DownloadProgramMasterReportHandler.Request.term,
      label: 'Please Select a Term.',
      required: true,
      rules: [ValidationRuleUtil.required()],
    })
    .set(DownloadProgramMasterReportHandler.Request.institutionId, {
      name: DownloadProgramMasterReportHandler.Request.institutionId,
      label: 'Please select the institutions you want. If none are selected then all institutions will be displayed ',
    });

  constructor(props: ProgramMasterReportDownloadFormProps) {
    super(props);

    this.state = {
      modesOfDelivery: [],
      cipNumbers: [],
      institutions: [],
      currentYearTerm: null,
      institutionId: Guid.Empty(),
    };
  }

  componentDidMount() {
    this.setState({ institutionId: this.props.institutionId ?? Guid.Empty() });

    this.fetchData();
  }

  componentDidUpdate(prevProps: ProgramMasterReportDownloadFormProps) {
    if (prevProps.institutionId != this.props.institutionId) {
      this.setState({ institutionId: this.props.institutionId ?? Guid.Empty() }, () => this.resetForm());
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.currentYearTerm == null) {
      loaders.push(this.loadCurrentYearTerm());
    }

    if (!this.state.modesOfDelivery || this.state.modesOfDelivery.length == 0) {
      loaders.push(this.loadModesOfDelivery());
    }

    if (!this.state.cipNumbers || this.state.cipNumbers.length == 0) {
      loaders.push(this.loadCipNumbers());
    }

    if (!this.state.institutions || this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  };

  private loadCipNumbers = () => {
    return CipsApiService.getCipNumbers()
      .then((results: GetCipNumbersHandler.Result) => {
        if (results) {
          this.setState({
            cipNumbers: results.cipNumbers ?? [],
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  private loadCurrentYearTerm = () => {
    return TermYearUtil.getCurrent()
      .then((results: TermYear) => {
        if (results) {
          this.setState({ currentYearTerm: results });
          this._formRef.current?.setFieldsValue({
            term: {
              termId: results.termId,
              year: results.year,
            } as TermInputValue,
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  private loadModesOfDelivery = () => {
    return LookupsUtil.getAll<ModeOfDeliveryDTO>(ModeOfDeliveryDTO.className)
      .then((results: ModeOfDeliveryDTO[]) => {
        if (results) {
          this.setState({ modesOfDelivery: results ?? [] });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  };

  private handleSubmit = () => {
    const form = DownloadProgramMasterReportHandler.Request.create(this._formRef.current?.getFieldsValue());
    const termId = this._formRef.current?.getFieldValue(DownloadProgramMasterReportHandler.Request.term).termId;
    const year = this._formRef.current?.getFieldValue(DownloadProgramMasterReportHandler.Request.term).year;

    modal.info({
      title: 'File Requested',
      content:
        'The file you have requested has been queued. You will receive an email once the file has been generated.',
    });

    DownloadsApiService.exportProgramMasterReport(
      termId,
      year,
      form.institutionId ?? this.state.institutionId ?? Guid.Empty()
    );

    // window.open(Routes.generateFull(Routes.PROGRAM_MASTER_REPORT, {}, {
    //   termId: termId,
    //   year: year,
    //   institutionId: form.institutionId ?? this.state.institutionId ?? Guid.Empty(),
    //   cipNumberId: form.cipNumberId ?? Guid.Empty(),
    //   isActive: form.isActive,
    //   modeOfDeliveryIds: form.modeOfDeliveryIds ?? [],
    //   programName: form.programName ?? ''
    // }), '_blank');
  };

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <Form ref={this._formRef} layout="vertical" onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(DownloadProgramMasterReportHandler.Request.term)}
            {...ValidationUtil.getValidation(
              DownloadProgramMasterReportHandler.Request.term,
              this.state.fieldErrors,
              this.state.submitted
            )}
          >
            <TermInput />
          </FormItem>

          {this.state.institutionId == Guid.Empty() ? (
            <FormItem
              {...this._formItems.get(DownloadProgramMasterReportHandler.Request.institutionId)}
              {...ValidationUtil.getValidation(
                DownloadProgramMasterReportHandler.Request.institutionId,
                this.state.fieldErrors,
                this.state.submitted
              )}
              initialValue={this.state.institutionId == Guid.Empty() ? undefined : this.state.institutionId}
            >
              <Dropdown disabled={this.state.institutionId != Guid.Empty()}>
                {' '}
                {this.state.institutions.map((x) => this.renderInstituion(x))}
              </Dropdown>
            </FormItem>
          ) : null}

          <Space direction={'horizontal'}>
            <DownloadButton downloading={this.state.submitting} />
            <ResetButton onConfirm={this.resetForm} />
          </Space>
        </Form>
      </Space>
    );
  }

  renderInstituion(institution: InstitutionDTO) {
    if (institution.id) {
      return (
        <Select.Option
          title={institution.name ?? ''}
          key={institution.id ?? Guid.Empty()}
          value={institution.id ?? Guid.Empty()}
        >
          {institution.name}
        </Select.Option>
      );
    }
  }

  renderModesOfDelivery(modesOfDelivery: ModeOfDeliveryDTO) {
    if (modesOfDelivery.id) {
      return (
        <Select.Option
          title={modesOfDelivery.name ?? ''}
          key={modesOfDelivery.id ?? Guid.Empty()}
          value={modesOfDelivery.id ?? Guid.Empty()}
        >
          {modesOfDelivery.name}
        </Select.Option>
      );
    }
  }

  renderCipNumbers(cipNumber: CipNumberDetailDTO) {
    if (cipNumber.id) {
      return (
        <Select.Option
          title={cipNumber?.friendlyDisplay ?? ''}
          key={cipNumber.cipNumberId ?? Guid.Empty()}
          value={cipNumber.cipNumberId ?? Guid.Empty()}
        >
          {cipNumber?.friendlyDisplay}
        </Select.Option>
      );
    }
  }
}

export default ProgramMasterReportDownloadForm;
