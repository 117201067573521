// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveCategoryDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import CategoryDTO from '../models/CategoryDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    categoryId: string | null | null;
    category: CategoryDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            categoryId: null,
            category: null,
        },
        initValues);
    },
    categoryId: 'categoryId',
    category: 'category',
}

interface Result extends ResultDTO {
    category: CategoryDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            category: null,
        },
        initValues);
    },
    category: 'category',
}

export { Request, Result };