// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import NoticeOfIntentChangeRequestDTO from './NoticeOfIntentChangeRequestDTO';
import ChangeRequestDTO from './ChangeRequestDTO';
import ProgramDTO from './ProgramDTO';
import StatusTypeDTO from './StatusTypeDTO';
import CipNumberDTO from './CipNumberDTO';
import IowaVariationDTO from './IowaVariationDTO';
import AwardTypeDTO from './AwardTypeDTO';
import YearTermDTO from './YearTermDTO';
import NewProgramProgramAwardDTO from './NewProgramProgramAwardDTO';
import NewProgramAdvisoryBoardContactDTO from './NewProgramAdvisoryBoardContactDTO';
import InstitutionDTO from './InstitutionDTO';
import NewProgramChangeRequestContactDTO from './NewProgramChangeRequestContactDTO';
import NewProgramChangeRequestConsultantMeetingDTO from './NewProgramChangeRequestConsultantMeetingDTO';
import NewProgramChangeRequestOccupationDTO from './NewProgramChangeRequestOccupationDTO';
import NewProgramChangeRequestIndustryResourceDTO from './NewProgramChangeRequestIndustryResourceDTO';
import NewProgramChangeRequestCompleterDataDTO from './NewProgramChangeRequestCompleterDataDTO';
import NewProgramChangeRequestExternalInstitutionDTO from './NewProgramChangeRequestExternalInstitutionDTO';
import NewProgramChangeRequestInstitutionLinkageDTO from './NewProgramChangeRequestInstitutionLinkageDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NewProgramChangeRequestDTO extends EntitySelectorDTO { 
	id: string | null;
	changeRequestId: string | null;
	additionlCommentsICCPHSE: string | null;
	noticeOfIntentChangeRequestId: string | null | null;
	noticeOfIntentChangeRequest: NoticeOfIntentChangeRequestDTO | null;
	changeRequest: ChangeRequestDTO | null;
	cipReclassification: boolean | null | null;
	programId: string | null | null;
	program: ProgramDTO | null;
	isTransferMajorProgram: boolean | null | null;
	institutionId: string | null | null;
	statusTypeId: number | null | null;
	statusType: StatusTypeDTO | null;
	cipNumberId: string | null | null;
	cipNumber: CipNumberDTO | null;
	iowaVariationId: number | null | null;
	iowaVariation: IowaVariationDTO | null;
	primrayAwardTypeId: number | null | null;
	primrayAwardType: AwardTypeDTO | null;
	title: string | null;
	install: YearTermDTO | null;
	boardOfTrusteesDateOfApproval: moment.Moment | string;
	curriculumApprovalProcessDateOfApproval: moment.Moment | string;
	advisoryBoardApprovalDate: moment.Moment | string;
	newProgramProgramAwards: NewProgramProgramAwardDTO[] | null;
	newProgramAdvisoryBoardContacts: NewProgramAdvisoryBoardContactDTO[] | null;
	institution: InstitutionDTO | null;
	previousCipNumberId: string | null | null;
	needForProgram: string | null;
	anticipatedAnnualEnrollment: number | null | null;
	anticipatedAnnualCompleters: number | null | null;
	requiresWorkExperiences: boolean | null | null;
	requiredWorkExperiences: string | null;
	secondaryPathways: string | null;
	postSecondaryPathways: string | null;
	uniqueProgramFeatures: string | null;
	sharedProgramEntities: string | null;
	collaborationEfforts: string | null;
	statePriority: string | null;
	programDuplicationAdditionalComments: string | null;
	additionalLaborMarketInformation: string | null;
	externalAccreditationRequirements: boolean | null | null;
	externalAccreditationRequirementDetails: string | null;
	programMarketingLink: string | null;
	standardsAndBenchmarks: string | null;
	contacts: NewProgramChangeRequestContactDTO[] | null;
	consultantMeetings: NewProgramChangeRequestConsultantMeetingDTO[] | null;
	occupations: NewProgramChangeRequestOccupationDTO[] | null;
	industryResources: NewProgramChangeRequestIndustryResourceDTO[] | null;
	noticeOfIntentCompleterData: NewProgramChangeRequestCompleterDataDTO[] | null;
	externalInstitutions: NewProgramChangeRequestExternalInstitutionDTO[] | null;
	externalInstitutionLinkages: NewProgramChangeRequestInstitutionLinkageDTO[] | null;
	externalInstitutionIds: number[];
}

const NewProgramChangeRequestDTO = {
    className: 'NewProgramChangeRequestDTO',
    create: (initValues?: Partial<NewProgramChangeRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			changeRequestId: '00000000-0000-0000-0000-000000000000',
			additionlCommentsICCPHSE: "",
			noticeOfIntentChangeRequestId: null,
			noticeOfIntentChangeRequest: null,
			changeRequest: null,
			cipReclassification: null,
			programId: null,
			program: null,
			isTransferMajorProgram: null,
			institutionId: null,
			statusTypeId: null,
			statusType: null,
			cipNumberId: null,
			cipNumber: null,
			iowaVariationId: null,
			iowaVariation: null,
			primrayAwardTypeId: null,
			primrayAwardType: null,
			title: "",
			install: null,
			boardOfTrusteesDateOfApproval: new Date(0).toISOString(),
			curriculumApprovalProcessDateOfApproval: new Date(0).toISOString(),
			advisoryBoardApprovalDate: new Date(0).toISOString(),
			newProgramProgramAwards: [],
			newProgramAdvisoryBoardContacts: [],
			institution: null,
			previousCipNumberId: null,
			needForProgram: "",
			anticipatedAnnualEnrollment: null,
			anticipatedAnnualCompleters: null,
			requiresWorkExperiences: null,
			requiredWorkExperiences: "",
			secondaryPathways: "",
			postSecondaryPathways: "",
			uniqueProgramFeatures: "",
			sharedProgramEntities: "",
			collaborationEfforts: "",
			statePriority: "",
			programDuplicationAdditionalComments: "",
			additionalLaborMarketInformation: "",
			externalAccreditationRequirements: null,
			externalAccreditationRequirementDetails: "",
			programMarketingLink: "",
			standardsAndBenchmarks: "",
			contacts: [],
			consultantMeetings: [],
			occupations: [],
			industryResources: [],
			noticeOfIntentCompleterData: [],
			externalInstitutions: [],
			externalInstitutionLinkages: [],
			externalInstitutionIds: [],
        },
        initValues);
    },
	id: 'id',
	changeRequestId: 'changeRequestId',
	additionlCommentsICCPHSE: 'additionlCommentsICCPHSE',
	noticeOfIntentChangeRequestId: 'noticeOfIntentChangeRequestId',
	noticeOfIntentChangeRequest: 'noticeOfIntentChangeRequest',
	changeRequest: 'changeRequest',
	cipReclassification: 'cipReclassification',
	programId: 'programId',
	program: 'program',
	isTransferMajorProgram: 'isTransferMajorProgram',
	institutionId: 'institutionId',
	statusTypeId: 'statusTypeId',
	statusType: 'statusType',
	cipNumberId: 'cipNumberId',
	cipNumber: 'cipNumber',
	iowaVariationId: 'iowaVariationId',
	iowaVariation: 'iowaVariation',
	primrayAwardTypeId: 'primrayAwardTypeId',
	primrayAwardType: 'primrayAwardType',
	title: 'title',
	install: 'install',
	boardOfTrusteesDateOfApproval: 'boardOfTrusteesDateOfApproval',
	curriculumApprovalProcessDateOfApproval: 'curriculumApprovalProcessDateOfApproval',
	advisoryBoardApprovalDate: 'advisoryBoardApprovalDate',
	newProgramProgramAwards: 'newProgramProgramAwards',
	newProgramAdvisoryBoardContacts: 'newProgramAdvisoryBoardContacts',
	institution: 'institution',
	previousCipNumberId: 'previousCipNumberId',
	needForProgram: 'needForProgram',
	anticipatedAnnualEnrollment: 'anticipatedAnnualEnrollment',
	anticipatedAnnualCompleters: 'anticipatedAnnualCompleters',
	requiresWorkExperiences: 'requiresWorkExperiences',
	requiredWorkExperiences: 'requiredWorkExperiences',
	secondaryPathways: 'secondaryPathways',
	postSecondaryPathways: 'postSecondaryPathways',
	uniqueProgramFeatures: 'uniqueProgramFeatures',
	sharedProgramEntities: 'sharedProgramEntities',
	collaborationEfforts: 'collaborationEfforts',
	statePriority: 'statePriority',
	programDuplicationAdditionalComments: 'programDuplicationAdditionalComments',
	additionalLaborMarketInformation: 'additionalLaborMarketInformation',
	externalAccreditationRequirements: 'externalAccreditationRequirements',
	externalAccreditationRequirementDetails: 'externalAccreditationRequirementDetails',
	programMarketingLink: 'programMarketingLink',
	standardsAndBenchmarks: 'standardsAndBenchmarks',
	contacts: 'contacts',
	consultantMeetings: 'consultantMeetings',
	occupations: 'occupations',
	industryResources: 'industryResources',
	noticeOfIntentCompleterData: 'noticeOfIntentCompleterData',
	externalInstitutions: 'externalInstitutions',
	externalInstitutionLinkages: 'externalInstitutionLinkages',
	externalInstitutionIds: 'externalInstitutionIds',
};

export default NewProgramChangeRequestDTO;