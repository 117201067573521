// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveSelfStudySectionFileHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYear: number;
    selfStudyResponseId: string | null;
    sectionTemplateIndex: number;
    componentTemplateIndex: number;
    responseTypeId: number;
    file: any | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYear: 0,
            selfStudyResponseId: '00000000-0000-0000-0000-000000000000',
            sectionTemplateIndex: 0,
            componentTemplateIndex: 0,
            responseTypeId: 0,
            file: null,
        },
        initValues);
    },
    academicYear: 'academicYear',
    selfStudyResponseId: 'selfStudyResponseId',
    sectionTemplateIndex: 'sectionTemplateIndex',
    componentTemplateIndex: 'componentTemplateIndex',
    responseTypeId: 'responseTypeId',
    file: 'file',
}

interface Result extends ResultDTO {
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
        },
        initValues);
    },
}

export { Request, Result };