// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import StatusTypeDTO from './StatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface UserDTO extends EntitySelectorDTO { 
	id: string | null | null;
	username: string | null;
	firstName: string | null;
	lastName: string | null;
	fullName: string | null;
	phoneNumber: string | null;
	display: string | null;
	statusTypeId: number;
	aaUserLogin: string | null;
	status: StatusTypeDTO | null;
	lockoutStart: moment.Moment | string | null;
	lockoutEnd: moment.Moment | string | null;
	institutionNames: string[];
	districtNames: string[];
	districtIdentifiers: number[];
	rppIds: number[];
	roleIds: string[];
	roles: string[];
	isLocked: boolean;
	fullDisplay: string | null;
	initials: string | null;
}

const UserDTO = {
    className: 'UserDTO',
    create: (initValues?: Partial<UserDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			username: "",
			firstName: "",
			lastName: "",
			fullName: "",
			phoneNumber: "",
			display: "",
			statusTypeId: 0,
			aaUserLogin: "",
			status: null,
			lockoutStart: null,
			lockoutEnd: null,
			institutionNames: [],
			districtNames: [],
			districtIdentifiers: [],
			rppIds: [],
			roleIds: [],
			roles: [],
			isLocked: false,
			fullDisplay: "",
			initials: "",
        },
        initValues);
    },
	id: 'id',
	username: 'username',
	firstName: 'firstName',
	lastName: 'lastName',
	fullName: 'fullName',
	phoneNumber: 'phoneNumber',
	display: 'display',
	statusTypeId: 'statusTypeId',
	aaUserLogin: 'aaUserLogin',
	status: 'status',
	lockoutStart: 'lockoutStart',
	lockoutEnd: 'lockoutEnd',
	institutionNames: 'institutionNames',
	districtNames: 'districtNames',
	districtIdentifiers: 'districtIdentifiers',
	rppIds: 'rppIds',
	roleIds: 'roleIds',
	roles: 'roles',
	isLocked: 'isLocked',
	fullDisplay: 'fullDisplay',
	initials: 'initials',
};

export default UserDTO;