import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import FederalClusterApiService from '../../../api/FederalClusterApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import FederalClusterDTO from '../../../models/FederalClusterDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import * as DeleteFederalClusterHandler from '../../../handlerModels/DeleteFederalClusterHandler';
import FederalClusterDetailsModal from '../../modals/FederalClusterDetailsModal';
import Guid from '../../../utils/Guid';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import * as GetServiceAreasHandler from '../../../handlerModels/GetServiceAreasHandler';
import { TableFilterOption } from '../../../utils/TableFilterUtil';

interface FederalClustersDataTableProps {
  academicYear: number;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
}

function FederalClustersDataTable(props: FederalClustersDataTableProps) {
  let _dataTable: DataTable<FederalClusterDTO> | undefined = undefined;

  const [federalClusterId, setFederalClusterId] = useState<string | null>();
  const [serviceAreas, setServiceAreas] = useState<ServiceAreaDTO[]>([]);

  React.useEffect(() => {
    if (props.academicYear > 0) {
      ServiceAreaApiService.getServiceAreas(props.academicYear).then((results: GetServiceAreasHandler.Result) => {
        if (results.serviceAreas) {
          setServiceAreas(results.serviceAreas);
        }
      })
    }
  }, [props.academicYear]);

  const getColumnDefinitions = () => {
    return [
      {
        title: 'Identifier',
        dataIndex: FederalClusterDTO.federalClusterIdentifier,
        sorter: true,
        width: 25,
        render: (data: string, row: FederalClusterDTO) => {
          return row.federalClusterIdentifier;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Title',
        dataIndex: FederalClusterDTO.description,
        sorter: true,
        render: (data: string, row: FederalClusterDTO) => {
          return row.description;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Service Area',
        dataIndex: FederalClusterDTO.serviceAreaIdentifier,
        sorter: true,
        width: 250,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: serviceAreas.map(c => { return { text: c.display, value: c.serviceAreaIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: FederalClusterDTO) => {
          if (serviceAreas.length > 0) {
            const sa = serviceAreas.filter((x: ServiceAreaDTO) => x.serviceAreaIdentifier === row.serviceAreaIdentifier);
            return sa[0]?.description ?? ''
          }

          return '';
        },
      },
      {
        title: 'Actions',
        align: 'center',
        dataIndex: FederalClusterDTO.id,
        sorter: false,
        render: (data: string, row: FederalClusterDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => openFederalClusterDetail(row)}><FormOutlined /></Button>
              <Button type="link" onClick={() => promptConfirmRemove(row.id ?? Guid.Empty())}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 50
      },
    ] as DataTableColumnProps<any>[];
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openFederalClusterDetail = (record: FederalClusterDTO) => {
    if (record.id) {
      setFederalClusterId(record.id);
    }
  }

  const addFederalCluster = () => {
    setFederalClusterId(Guid.Empty());
  }

  const closeFederalClusterDetail = () => {
    setFederalClusterId(null);
  }

  const promptConfirmRemove = (federalClusterId: string) => {
    if (federalClusterId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this Federal Cluster?',
      okText: 'Delete',
      onOk: () => deleteFederalCluster(federalClusterId),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteFederalCluster = (federalClusterId: string) => {
    const request = DeleteFederalClusterHandler.Request.create({
      federalClusterId: federalClusterId,
    });

    FederalClusterApiService.deleteFederalCluster(request)
      .then((result: DeleteFederalClusterHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Federal Cluster was removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Federal Cluster could not be removed.');
      });
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_FEDERAL_CLUSTER])) {
    actionButtons.push(DataTableButtonUtil.Default('New Federal Cluster', () => addFederalCluster()));
  }

  const renderModal = () => {
    if (federalClusterId != null) {
      return <FederalClusterDetailsModal
        onCancel={closeFederalClusterDetail}
        visible={true}
        federalClusterId={federalClusterId}
        academicYear={props.academicYear}
        onSave={closeFederalClusterDetail} />
    }
  }

  return (
    <>
      {renderModal()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestFederal: TableRequestDTO) => FederalClusterApiService.getFederalClusterTableData(requestFederal, props.academicYear)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'federal_cluster_list' }} />
    </>
  );
}

export default FederalClustersDataTable;
