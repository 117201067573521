// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ProgramClassificationDTO from './ProgramClassificationDTO';
import AwardTypeGeneralEducationCreditsDTO from './AwardTypeGeneralEducationCreditsDTO';
import AwardTypeAPSGeneralEducationCreditsDTO from './AwardTypeAPSGeneralEducationCreditsDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AwardTypeDTO extends EntitySelectorDTO { 
	id: number;
	name: string | null;
	code: string | null;
	order: number;
	maxWeeks: number;
	minCredits: number;
	maxCredits: number;
	transferMajor: boolean;
	averageCredits: number | null | null;
	generalEducationCredits: number | null | null;
	generalEducationCategories: number | null | null;
	techCorePercentage: number | null | null;
	display: string | null;
	nonTrasferMajorProgramTypeId: number | null | null;
	transferMajorProgramTypeId: number | null | null;
	nonTransferMajorProgramType: ProgramClassificationDTO | null;
	transferMajorProgramType: ProgramClassificationDTO | null;
	iowaVariationIds: number[];
	instructionalLevels: number[];
	typeOfPrograms: number[];
	specialEmphases: number[];
	objectPurposes: number[];
	generalEducationCreditLimits: AwardTypeGeneralEducationCreditsDTO[] | null;
	apsGeneralEducationCreditLimits: AwardTypeAPSGeneralEducationCreditsDTO[] | null;
}

const AwardTypeDTO = {
    className: 'AwardTypeDTO',
    create: (initValues?: Partial<AwardTypeDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: 0,
			name: "",
			code: "",
			order: 0,
			maxWeeks: 0,
			minCredits: 0,
			maxCredits: 0,
			transferMajor: false,
			averageCredits: null,
			generalEducationCredits: null,
			generalEducationCategories: null,
			techCorePercentage: null,
			display: "",
			nonTrasferMajorProgramTypeId: null,
			transferMajorProgramTypeId: null,
			nonTransferMajorProgramType: null,
			transferMajorProgramType: null,
			iowaVariationIds: [],
			instructionalLevels: [],
			typeOfPrograms: [],
			specialEmphases: [],
			objectPurposes: [],
			generalEducationCreditLimits: [],
			apsGeneralEducationCreditLimits: [],
        },
        initValues);
    },
	id: 'id',
	name: 'name',
	code: 'code',
	order: 'order',
	maxWeeks: 'maxWeeks',
	minCredits: 'minCredits',
	maxCredits: 'maxCredits',
	transferMajor: 'transferMajor',
	averageCredits: 'averageCredits',
	generalEducationCredits: 'generalEducationCredits',
	generalEducationCategories: 'generalEducationCategories',
	techCorePercentage: 'techCorePercentage',
	display: 'display',
	nonTrasferMajorProgramTypeId: 'nonTrasferMajorProgramTypeId',
	transferMajorProgramTypeId: 'transferMajorProgramTypeId',
	nonTransferMajorProgramType: 'nonTransferMajorProgramType',
	transferMajorProgramType: 'transferMajorProgramType',
	iowaVariationIds: 'iowaVariationIds',
	instructionalLevels: 'instructionalLevels',
	typeOfPrograms: 'typeOfPrograms',
	specialEmphases: 'specialEmphases',
	objectPurposes: 'objectPurposes',
	generalEducationCreditLimits: 'generalEducationCreditLimits',
	apsGeneralEducationCreditLimits: 'apsGeneralEducationCreditLimits',
};

export default AwardTypeDTO;