import { message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useImperativeHandle, useState } from 'react';
import UserRegionalPlanningPartnerAssignmentApiService from '../../../api/UserRegionalPlanningPartnerAssignmentApiService';
import RoleType from '../../../consts/RoleType';
import * as EditUserRegionalPlanningPartnerAssignmentRolesHandler from '../../../handlerModels/EditUserRegionalPlanningPartnerAssignmentRolesHandler';
import RoleDTO from '../../../models/RoleDTO';
import UserRegionalPlanningPartnerAssignmentDTO from '../../../models/UserRegionalPlanningPartnerAssignmentDTO';
import Validator from '../../../models/Validator';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import Dropdown from '../../inputs/Dropdown';


interface EditRegionalPlanningPartnerRoleFormProps extends BaseFormProps {
  userId: string;
  rppId: number;
  onSubmit?: () => void;
  resetForm?: (value: string) => void;
}

const EditRegionalPlanningPartnerRoleForm = React.forwardRef((props: EditRegionalPlanningPartnerRoleFormProps, ref) => {
  const _formRef = React.createRef<FormInstance>();
  const _formItems = new Map<string, FormItemProps>()
    .set(UserRegionalPlanningPartnerAssignmentDTO.roleIds, {
      label: 'RPP Roles',
      required: true,
      name: UserRegionalPlanningPartnerAssignmentDTO.roleIds,
      rules: [
        ValidationRuleUtil.required()
      ]
    });

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(),
    reset: () => reset()
  }));

  const reset = () => {
    setFieldErrors(null);
    setSubmitted(false);
    _formRef.current?.resetFields();
  }

  const [loading, setLoading] = useState(true);
  const [rpp] = useState({} as UserRegionalPlanningPartnerAssignmentDTO);
  const [, setError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({} as ({ [key: string]: Validator[]; } | null));
  const [submitted, setSubmitted] = useState(false);
  const [roles, setRoles] = useState([] as RoleDTO[]);

  React.useEffect(() => {
    fetchData();
  }, [props.rppId]);

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (!roles || roles.length == 0) {
      loaders.push(loadRoles());
    }

    Promise.all(loaders).then(() => {
      setLoading(false);
      if (props.resetForm) {
        props.resetForm;
      }
    });
  }

  const loadRoles = () => {
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        if (results) {
          setRoles(results);
        }
      }).catch(() => {
        setError(true);
      });
  }

  const handleSubmit = () => {
    setSubmitted(true);

    const form = (_formRef ? (_formRef.current as any).getFieldsValue() : null);
    const request = EditUserRegionalPlanningPartnerAssignmentRolesHandler.Request.create({
      userId: props.userId,
      regionalPlanningPartnerId: props.rppId,
      roleIds: form.roleIds
    });

    UserRegionalPlanningPartnerAssignmentApiService.editUserRegionalPlanningPartnerAssignmentRoles(request)
      .then((result: EditUserRegionalPlanningPartnerAssignmentRolesHandler.Result) => {
        setSubmitted(true);
        if (result?.succeeded) {
          message.success('Roles Edited');
          setSubmitted(false);
          setFieldErrors(null);

          if (props.onSubmit) {
            props.onSubmit();
          }
        }
        else {
          setError(!result?.succeeded);
          setFieldErrors(result?.fieldErrors);
          message.error('Roles could not be Edited');
        }
      })
      .catch(() => {
        setError(true);
        message.error('Roles could not be Edited');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const renderRole = (role: RoleDTO) => {
    if (role.id && role.roleTypeId == RoleType.RPP) {
      return <Select.Option key={role.id ?? Guid.Empty()} value={role.id ?? Guid.Empty()}> {role.name}</Select.Option>
    }
  }

  if (loading) {
    return <Skeleton active />
  }
  else {
    return (
      <Form
        ref={_formRef}
        layout="vertical"
        initialValues={rpp}
        requiredMark={true}>
        <Space size="small" direction="vertical">
          <FormItem
            {..._formItems.get(UserRegionalPlanningPartnerAssignmentDTO.roleIds)}
            {...ValidationUtil.getValidation(UserRegionalPlanningPartnerAssignmentDTO.roleIds, fieldErrors, submitted)}  >
            <Dropdown dropdownMatchSelectWidth={false}
              mode="multiple">
              {roles.map(x => { return renderRole(x) })}
            </Dropdown>
          </FormItem>
        </Space >
      </ Form>
    );
  }

})

EditRegionalPlanningPartnerRoleForm.displayName = 'EditRegionalPlanningPartnerRoleForm'
export default EditRegionalPlanningPartnerRoleForm;