// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// ExistingProgramModificationSearchHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    programId: string | null;
    installTerm: string | null;
    installYear: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            programId: '00000000-0000-0000-0000-000000000000',
            installTerm: '00000000-0000-0000-0000-000000000000',
            installYear: 0,
        },
        initValues);
    },
    programId: 'programId',
    installTerm: 'installTerm',
    installYear: 'installYear',
}

interface Result extends ResultDTO {
    existingProgramModification: boolean;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            existingProgramModification: false,
        },
        initValues);
    },
    existingProgramModification: 'existingProgramModification',
}

export { Request, Result };