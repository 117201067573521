// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudySectionComponentTemplateDTO from './SelfStudySectionComponentTemplateDTO';
import SelfStudySectionResponseDTO from './SelfStudySectionResponseDTO';
import SelfStudySectionComponentResponseResourceDTO from './SelfStudySectionComponentResponseResourceDTO';
import SelfStudyResponseConsultantReviewQuestionDTO from './SelfStudyResponseConsultantReviewQuestionDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudySectionComponentResponseDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudySectionComponentTemplateId: string | null;
	selfStudySectionComponentTemplate: SelfStudySectionComponentTemplateDTO | null;
	selfStudySectionReponseId: string | null;
	selfStudySectionResponse: SelfStudySectionResponseDTO | null;
	rating: number;
	responseText: string | null;
	rppApproval: boolean | null | null;
	selfStudySectionComponentResponseResources: SelfStudySectionComponentResponseResourceDTO[] | null;
	selfStudyResponseConsultantReviewQuestionDTOs: SelfStudyResponseConsultantReviewQuestionDTO[] | null;
}

const SelfStudySectionComponentResponseDTO = {
    className: 'SelfStudySectionComponentResponseDTO',
    create: (initValues?: Partial<SelfStudySectionComponentResponseDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudySectionComponentTemplateId: '00000000-0000-0000-0000-000000000000',
			selfStudySectionComponentTemplate: null,
			selfStudySectionReponseId: '00000000-0000-0000-0000-000000000000',
			selfStudySectionResponse: null,
			rating: 0,
			responseText: "",
			rppApproval: null,
			selfStudySectionComponentResponseResources: [],
			selfStudyResponseConsultantReviewQuestionDTOs: [],
        },
        initValues);
    },
	id: 'id',
	selfStudySectionComponentTemplateId: 'selfStudySectionComponentTemplateId',
	selfStudySectionComponentTemplate: 'selfStudySectionComponentTemplate',
	selfStudySectionReponseId: 'selfStudySectionReponseId',
	selfStudySectionResponse: 'selfStudySectionResponse',
	rating: 'rating',
	responseText: 'responseText',
	rppApproval: 'rppApproval',
	selfStudySectionComponentResponseResources: 'selfStudySectionComponentResponseResources',
	selfStudyResponseConsultantReviewQuestionDTOs: 'selfStudyResponseConsultantReviewQuestionDTOs',
};

export default SelfStudySectionComponentResponseDTO;