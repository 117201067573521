import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IowaVariationDataTable from '../../../../components/datatables/postSecondary/IowaVariationDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface IowaVariationListProps {
}

interface IowaVariationListState {
}

class IowaVariationListPage extends React.Component<IowaVariationListProps & RouteComponentProps<RouteObject>, IowaVariationListState> {
  constructor(props: IowaVariationListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Iowa Variations" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.iowaVariationList()} />

        <Card >
          <IowaVariationDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(IowaVariationListPage);
