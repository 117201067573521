// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionCourseDTO from './InstitutionCourseDTO';
import InstitutionCourseCreditDTO from './InstitutionCourseCreditDTO';
import CourseRequisiteDTO from './CourseRequisiteDTO';
import YearTermDTO from './YearTermDTO';
import CategoryDTO from './CategoryDTO';
import DisciplineDTO from './DisciplineDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionCourseDetailDTO extends EntitySelectorDTO { 
	id: string | null;
	courseId: string | null;
	description: string | null;
	institutionCourseId: string | null;
	institutionCourse: InstitutionCourseDTO | null;
	institutionCourseCredits: InstitutionCourseCreditDTO[] | null;
	courseRequisites: CourseRequisiteDTO[] | null;
	install: YearTermDTO | null;
	retire: YearTermDTO | null;
	variableHours: boolean;
	categoryId: string | null | null;
	category: CategoryDTO | null;
	objectives: string | null;
	disciplineId: string | null | null;
	discipline: DisciplineDTO | null;
	catalogNumber: string | null;
	title: string | null;
	installDisplay: string | null;
	retireDisplay: string | null;
	totalMinCredits: number;
	totalMaxCredits: number;
	totalCredits: string | null;
	versionDisplay: string | null;
	display: string | null;
}

const InstitutionCourseDetailDTO = {
    className: 'InstitutionCourseDetailDTO',
    create: (initValues?: Partial<InstitutionCourseDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			courseId: '00000000-0000-0000-0000-000000000000',
			description: "",
			institutionCourseId: '00000000-0000-0000-0000-000000000000',
			institutionCourse: null,
			institutionCourseCredits: [],
			courseRequisites: [],
			install: null,
			retire: null,
			variableHours: false,
			categoryId: null,
			category: null,
			objectives: "",
			disciplineId: null,
			discipline: null,
			catalogNumber: "",
			title: "",
			installDisplay: "",
			retireDisplay: "",
			totalMinCredits: 0,
			totalMaxCredits: 0,
			totalCredits: "",
			versionDisplay: "",
			display: "",
        },
        initValues);
    },
	id: 'id',
	courseId: 'courseId',
	description: 'description',
	institutionCourseId: 'institutionCourseId',
	institutionCourse: 'institutionCourse',
	institutionCourseCredits: 'institutionCourseCredits',
	courseRequisites: 'courseRequisites',
	install: 'install',
	retire: 'retire',
	variableHours: 'variableHours',
	categoryId: 'categoryId',
	category: 'category',
	objectives: 'objectives',
	disciplineId: 'disciplineId',
	discipline: 'discipline',
	catalogNumber: 'catalogNumber',
	title: 'title',
	installDisplay: 'installDisplay',
	retireDisplay: 'retireDisplay',
	totalMinCredits: 'totalMinCredits',
	totalMaxCredits: 'totalMaxCredits',
	totalCredits: 'totalCredits',
	versionDisplay: 'versionDisplay',
	display: 'display',
};

export default InstitutionCourseDetailDTO;