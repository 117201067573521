// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudyTemplateDTO from './SelfStudyTemplateDTO';
import AcademicYearDTO from './AcademicYearDTO';
import SecondaryProgramDTO from './SecondaryProgramDTO';
import SelfStudyResponseStatusTypeDTO from './SelfStudyResponseStatusTypeDTO';
import SelfStudyResponseDTO from './SelfStudyResponseDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudyDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudyTemplateId: string | null;
	selfStudyTemplate: SelfStudyTemplateDTO | null;
	academicYearId: number;
	academicYear: AcademicYearDTO | null;
	secondaryProgramId: string | null;
	secondaryProgram: SecondaryProgramDTO | null;
	deCommentRelease: boolean;
	currentSelfStudyResponseType: string | null;
	currentSelfStudyResponseTypeId: number | null | null;
	activeSelfStudyResponseStatusId: number | null | null;
	secondaryProgramStatusTypeId: number | null | null;
	activeSelfStudyResponseStatus: SelfStudyResponseStatusTypeDTO | null;
	selfStudyResponses: SelfStudyResponseDTO[] | null;
}

const SelfStudyDTO = {
    className: 'SelfStudyDTO',
    create: (initValues?: Partial<SelfStudyDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudyTemplateId: '00000000-0000-0000-0000-000000000000',
			selfStudyTemplate: null,
			academicYearId: 0,
			academicYear: null,
			secondaryProgramId: '00000000-0000-0000-0000-000000000000',
			secondaryProgram: null,
			deCommentRelease: false,
			currentSelfStudyResponseType: "",
			currentSelfStudyResponseTypeId: null,
			activeSelfStudyResponseStatusId: null,
			secondaryProgramStatusTypeId: null,
			activeSelfStudyResponseStatus: null,
			selfStudyResponses: [],
        },
        initValues);
    },
	id: 'id',
	selfStudyTemplateId: 'selfStudyTemplateId',
	selfStudyTemplate: 'selfStudyTemplate',
	academicYearId: 'academicYearId',
	academicYear: 'academicYear',
	secondaryProgramId: 'secondaryProgramId',
	secondaryProgram: 'secondaryProgram',
	deCommentRelease: 'deCommentRelease',
	currentSelfStudyResponseType: 'currentSelfStudyResponseType',
	currentSelfStudyResponseTypeId: 'currentSelfStudyResponseTypeId',
	activeSelfStudyResponseStatusId: 'activeSelfStudyResponseStatusId',
	secondaryProgramStatusTypeId: 'secondaryProgramStatusTypeId',
	activeSelfStudyResponseStatus: 'activeSelfStudyResponseStatus',
	selfStudyResponses: 'selfStudyResponses',
};

export default SelfStudyDTO;