// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SubmitProgramModificationChangeRequestStep4Handler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ProgramModificationProgramAwardDTO from '../models/ProgramModificationProgramAwardDTO';
import ChangeRequestDTO from '../models/ChangeRequestDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    changeRequestId: string | null;
    totalCredits: number;
    totalTechCoreCredits: number;
    programModificationProgramAward: ProgramModificationProgramAwardDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            changeRequestId: '00000000-0000-0000-0000-000000000000',
            totalCredits: 0,
            totalTechCoreCredits: 0,
            programModificationProgramAward: null,
        },
        initValues);
    },
    changeRequestId: 'changeRequestId',
    totalCredits: 'totalCredits',
    totalTechCoreCredits: 'totalTechCoreCredits',
    programModificationProgramAward: 'programModificationProgramAward',
}

interface Result extends ResultDTO {
    changeRequest: ChangeRequestDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            changeRequest: null,
        },
        initValues);
    },
    changeRequest: 'changeRequest',
}

export { Request, Result };