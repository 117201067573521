// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CipNumberDetailDTO from './CipNumberDetailDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ConsultantDTO extends EntitySelectorDTO { 
	id: string | null | null;
	firstName: string | null;
	lastName: string | null;
	fullName: string | null;
	email: string | null;
	cipNumbers: CipNumberDetailDTO[] | null;
}

const ConsultantDTO = {
    className: 'ConsultantDTO',
    create: (initValues?: Partial<ConsultantDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			firstName: "",
			lastName: "",
			fullName: "",
			email: "",
			cipNumbers: [],
        },
        initValues);
    },
	id: 'id',
	firstName: 'firstName',
	lastName: 'lastName',
	fullName: 'fullName',
	email: 'email',
	cipNumbers: 'cipNumbers',
};

export default ConsultantDTO;