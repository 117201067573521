import { Card, Select } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import RegionalPlanningPartnerDataTable from '../../../components/datatables/secondary/RegionalPlanningPartnersDataTable';
import Dropdown from '../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../config/Breadcrumbs';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface RegionalPlanningPartnerListPageState {
  loading: boolean;
  academicYear: number;
  academicYears: AcademicYearDTO[];
}

interface RegionalPlanningPartnerListPageProps {
}

class RegionalPlanningPartnerListPage extends React.Component<RegionalPlanningPartnerListPageProps & RouteComponentProps<RouteObject>, RegionalPlanningPartnerListPageState> {

  constructor(props: RegionalPlanningPartnerListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
      academicYears: []
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });
      this.loadAcademicYears(id);
    }
  }

  private loadAcademicYears = (id: number) => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
      academicYearId: isNaN(id) ? null : id
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
            academicYear: DateTimeUtil.getCurrentYear()
          });
        }
      })
      .catch()
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private onSelect = (value: any) => {
    this.setState({ academicYear: value });
  }

  private onBack = () => {
    HistoryUtil.goBack();
  }

  render() {
    const configuration = (ParameterUtil.getParameter('configuration') == 'true');

    return (
      <Content>
        <HeaderPortal
          title="Regional Planning Partners"
          onBack={this.onBack}
          breadcrumbs={configuration ? Breadcrumbs.regionConfigurationListPage() : Breadcrumbs.regionListPage()}
          extra={<>
            <label title={'academicYear'} htmlFor={'academicYear'} />
            <Dropdown
              id='academicYear'
              value={this.state.academicYear}
              onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.academicYears.map(x => { return this.renderAcademicYear(x); })}</Dropdown>
          </>} />

        <Card>
          <RegionalPlanningPartnerDataTable
            configuration={configuration}
            academicYear={isNaN(this.state.academicYear) ? 0 : this.state.academicYear} />
        </Card>
      </Content>
    );
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</Select.Option>
  }
}

export default withRouter((RegionalPlanningPartnerListPage));
