import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import CipsApiService from '../../../api/CipsApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetCipDetailsHandler from '../../../handlerModels/GetCipDetailsHandler';
import * as SaveCipDetailsHandler from '../../../handlerModels/SaveCipDetailsHandler';
import CipNumberDetailDTO from '../../../models/CipNumberDetailDTO';
import CipNumberDTO from '../../../models/CipNumberDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';
import TermInput from '../../TermInput';

interface CipNumberDetailsFormState extends BaseFormState {
  cipId: string;
  cip: CipNumberDTO | null;
  cipDetails: CipNumberDetailDTO[];
  selectedCipDetail: CipNumberDetailDTO;
}

interface CipNumberDetailsFormProps extends BaseFormProps {
  cipId: string;
  cip?: CipNumberDTO;
  onSave?: (cipId: string) => void;
}

class CipNumberDetailsForm extends React.Component<CipNumberDetailsFormProps, CipNumberDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(CipNumberDetailDTO.title, {
      required: true,
      name: CipNumberDetailDTO.title,
      label: 'Title',
      rules: [
        ValidationRuleUtil.required()
      ]
    })
    .set(CipNumberDetailDTO.display, {
      required: true,
      name: CipNumberDetailDTO.display,
      label: 'CIP Number',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.cipNumberValidator()
      ],
    })
    .set(CipNumberDetailDTO.install, {
      required: true,
      name: CipNumberDetailDTO.install,
      label: 'Installed Term',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(CipNumberDetailDTO.install, {
      required: true,
      name: CipNumberDetailDTO.install,
      label: 'Installed Year',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(CipNumberDetailDTO.definition, {
      required: false,
      name: CipNumberDetailDTO.definition,
      label: 'Definition',
      rules: []
    })
    .set(CipNumberDetailDTO.transferMajor, {
      required: true,
      name: CipNumberDetailDTO.transferMajor,
      label: 'Allow Transfer Major',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(CipNumberDetailDTO.nonTransferMajor, {
      required: true,
      name: CipNumberDetailDTO.nonTransferMajor,
      label: 'Allow Non-Transfer Major',
      rules: [
        ValidationRuleUtil.required(),
      ],
    });

  constructor(props: CipNumberDetailsFormProps) {
    super(props);

    this.state = {
      cipId: Guid.Empty(),
      cip: CipNumberDTO.create({ installedTermId: '', installedYear: '' }),
      cipDetails: [],
      selectedCipDetail: CipNumberDetailDTO.create({
        installTermId: ''
      }),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: CipNumberDetailsFormProps) {
    if (this.props.cipId && this.props.cipId.isNotEmpty() && this.props.cipId != prevProps.cipId
      || (this.props.cip?.cipNumberDetails?.length != prevProps.cip?.cipNumberDetails?.length)) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.props.cip?.cipNumberDetails) {
      this.setState({
        cipId: this.props.cipId,
        cip: this.props.cip,
        selectedCipDetail: this.props.cip.cipNumberDetails[this.props.cip.cipNumberDetails.length - 1] ?? CipNumberDetailDTO.create()
      });
    }
    else if (this.props.cipId != Guid.Empty()) {
      loaders.push(this.loadExisting());
    }
    else {
      this.setState({ cip: CipNumberDTO.create() });
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.cipId) {
      return CipsApiService.getCipDetails(this.props.cipId)
        .then((results: GetCipDetailsHandler.Result) => {
          if (results.cip?.id) {
            this.setState({ cipId: results.cip.id, cip: results.cip });
          }
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveCipDetailsHandler.Request.create({
      cipId: this.state.cipId,
      cip: CipNumberDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    CipsApiService.saveCipDetails(request)
      .then((result: SaveCipDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          cip: result?.cip,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.cip?.id) {
            this.props.onSave(result.cip.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(CipNumberDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('CIP could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('CIP could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }
    const combinedInitialValuesFromCip: any = { ...this.state.cip, ...this.state.selectedCipDetail };

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={combinedInitialValuesFromCip}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>

          <FormItem
            {...this._formItems.get(CipNumberDetailDTO.display)}
            {...ValidationUtil.getValidation(CipNumberDetailDTO.display, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(CipNumberDetailDTO.title)}
            {...ValidationUtil.getValidation(CipNumberDetailDTO.title, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(CipNumberDetailDTO.install)}>
            <TermInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(CipNumberDetailDTO.definition)}
            {...ValidationUtil.getValidation(CipNumberDetailDTO.definition, this.state.fieldErrors, this.state.submitted)}>
            <Input.TextArea disabled={!this.props.isEditing || this.state.loading || this.state.submitting} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

          <FormItem
            {...this._formItems.get(CipNumberDetailDTO.transferMajor)}
            {...ValidationUtil.getValidation(CipNumberDetailDTO.transferMajor, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(CipNumberDetailDTO.nonTransferMajor)}
            {...ValidationUtil.getValidation(CipNumberDetailDTO.nonTransferMajor, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CIP_NUMBER]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default CipNumberDetailsForm;
