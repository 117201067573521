import { Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import Routes from '../../../config/Routes';
import * as DownloadComplianceReportHandler from '../../../handlerModels/DownloadComplianceReportHandler';
import AwardTypeDTO from '../../../models/AwardTypeDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import TermYearUtil, { TermYear } from '../../../utils/TermYearUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import DownloadButton from '../../buttons/DownloadButton';
import Dropdown from '../../inputs/Dropdown';
import ResetButton from '../../buttons/ResetButton';
import TermInput, { TermInputValue } from '../../TermInput';

interface ComplianceReportDownloadFormState extends BaseFormState {
  awardTypes: AwardTypeDTO[];
  institutions: InstitutionDTO[];
  currentYearTerm: TermYear | null;
}

interface ComplianceReportDownloadFormProps extends BaseFormProps {
  institutionId?: string;
}

class ComplianceReportDownloadForm extends React.Component<ComplianceReportDownloadFormProps, ComplianceReportDownloadFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(DownloadComplianceReportHandler.Request.term, {
      name: DownloadComplianceReportHandler.Request.term,
      label: 'Term',
      required: true,
      rules: [ValidationRuleUtil.required()]
    })
    .set(DownloadComplianceReportHandler.Request.institutionId, {
      name: DownloadComplianceReportHandler.Request.institutionId,
      label: 'Community College',
      required: true,
      rules: [ValidationRuleUtil.required()]
    });

  constructor(props: ComplianceReportDownloadFormProps) {
    super(props);

    this.state = {
      awardTypes: [],
      institutions: [],
      currentYearTerm: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.currentYearTerm == null) {
      loaders.push(this.loadCurrentYearTerm());
    }

    if (!this.state.awardTypes || this.state.awardTypes.length == 0) {
      loaders.push(this.loadAwardTypes());
    }

    if (!this.state.institutions || this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadCurrentYearTerm = () => {
    return TermYearUtil.getCurrent()
      .then((results: TermYear) => {
        if (results) {
          this.setState({ currentYearTerm: results });
          this._formRef.current?.setFieldsValue({
            term: {
              termId: results.termId,
              year: results.year
            } as TermInputValue
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAwardTypes = () => {
    return LookupsUtil.getAll<AwardTypeDTO>(AwardTypeDTO.className)
      .then((results: AwardTypeDTO[]) => {
        if (results) {
          this.setState({ awardTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private handleSubmit = () => {
    const termId = this._formRef.current?.getFieldValue(DownloadComplianceReportHandler.Request.term).termId;
    const year = this._formRef.current?.getFieldValue(DownloadComplianceReportHandler.Request.term).year;
    const institutionId = this.props.institutionId ?
      this.props.institutionId :
      this._formRef.current?.getFieldValue(DownloadComplianceReportHandler.Request.institutionId);

    window.open(Routes.generateFull(Routes.COMPLIANCE_REPORT, {}, { termId: termId, year: year, institutionId: institutionId }), '_blank');
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          layout="vertical"
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get('term')}
            {...ValidationUtil.getValidation('term', this.state.fieldErrors, this.state.submitted)} >
            <TermInput />
          </FormItem>

          {this.props.institutionId ?
            null :
            <FormItem
              {...this._formItems.get(DownloadComplianceReportHandler.Request.institutionId)}
              {...ValidationUtil.getValidation(DownloadComplianceReportHandler.Request.institutionId, this.state.fieldErrors, this.state.submitted)}>
              <Dropdown
                disabled={this.state.loading || this.state.submitting}
                showArrow={true}
                options={this.state.institutions.map(x => { return { value: x.id ?? Guid.Empty(), label: x.name } })} />
            </FormItem>
          }

          <Space direction={'horizontal'} >
            <DownloadButton downloading={this.state.submitting} />
            <ResetButton onConfirm={this.resetForm} />
          </Space>
        </Form>
      </Space >
    );
  }
}

export default ComplianceReportDownloadForm;
