import * as React from 'react';
import AwardTypeApiService from '../../../api/AwardTypeApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import AwardTypeDataTableDTO from '../../../models/AwardTypeDataTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface AwardTypeDataTableProps extends BaseFormProps {
}

interface AwardTypeDataTableState extends BaseDataTableState<AwardTypeDataTableDTO>, BaseFormState {
}

class AwardTypeDataTable extends React.Component<AwardTypeDataTableProps, AwardTypeDataTableState> {
  private _dataTable: DataTable<AwardTypeDataTableDTO> | undefined;

  constructor(props: AwardTypeDataTableProps) {
    super(props);
    this.state = {
    };

  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Order',
        dataIndex: AwardTypeDataTableDTO.order,
        sorter: true,
        filterType: FilterType.Text,
        width: 15
      },
      {
        title: 'Code',
        dataIndex: AwardTypeDataTableDTO.code,
        sorter: true,
        filterType: FilterType.Text,
        width: 50
      },
      {
        title: 'Name',
        dataIndex: AwardTypeDataTableDTO.name,
        sorter: true,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];
  };

  private openAwardType = (record: AwardTypeDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.AWARD_TYPE_DETAILS, { id: record.id }, {}));
    }
  }

  private addAwardType = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_AWARD_TYPE));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return AwardTypeApiService.getAwardTypeDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Award_Types_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_AWARD_TYPE])) {
      actionButtons.push(DataTableButtonUtil.Default('New Award Type', () => this.addAwardType()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openAwardType}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => AwardTypeApiService.getAwardTypeTableData(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'award_type_list' }} />
      </>
    );
  }
}

export default AwardTypeDataTable;
