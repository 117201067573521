import { Alert, Input, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import FederalClusterApiService from '../../../api/FederalClusterApiService';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import * as GetFederalClusterDetailsHandler from '../../../handlerModels/GetFederalClusterDetailsHandler';
import * as GetServiceAreasHandler from '../../../handlerModels/GetServiceAreasHandler';
import * as SaveFederalClusterDetailsHandler from '../../../handlerModels/SaveFederalClusterDetailsHandler';
import FederalClusterDTO from '../../../models/FederalClusterDTO';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import Dropdown from '../../inputs/Dropdown';

interface FederalClusterDetailsFormState extends BaseFormState {
  federalCluster: FederalClusterDTO;
  serviceAreas: ServiceAreaDTO[];
}

interface FederalClusterDetailsFormProps extends BaseFormProps {
  federalClusterId: string;
  academicYear?: number;
  onSave?: (federalClusterId: string) => void;
}

class FederalClusterDetailsForm extends React.Component<FederalClusterDetailsFormProps, FederalClusterDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(FederalClusterDTO.federalClusterIdentifier, {
      required: true,
      name: FederalClusterDTO.federalClusterIdentifier,
      label: 'Identifier',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(FederalClusterDTO.description, {
      required: true,
      name: FederalClusterDTO.description,
      label: 'Title',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(FederalClusterDTO.serviceAreaIdentifier, {
      required: false,
      name: FederalClusterDTO.serviceAreaIdentifier,
      label: 'Service Area',
    });

  constructor(props: FederalClusterDetailsFormProps) {
    super(props);

    this.state = {
      federalCluster: FederalClusterDTO.create({
        id: '',
        serviceAreaIdentifier: ''
      }),
      serviceAreas: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: FederalClusterDetailsFormProps) {
    if (this.props.federalClusterId && this.props != prevProps
      || this.props.academicYear && this.props.academicYear != prevProps.academicYear) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];
    loaders.push(this.loadFederalCluster());

    if (!this.state.serviceAreas || this.state.serviceAreas.length == 0) {
      loaders.push(this.loadServiceAreas());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadServiceAreas = () => {
    if (this.props.academicYear) {
      ServiceAreaApiService.getServiceAreas(this.props.academicYear)
        .then((results: GetServiceAreasHandler.Result) => {
          if (results) {
            this.setState({
              serviceAreas: results.serviceAreas ?? []
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadFederalCluster() {
    if (!this.state.loading) {
      if (this.props.federalClusterId == Guid.Empty() || this.props.isNew) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.federalClusterId && this.props.academicYear) {

      FederalClusterApiService.getFederalClusterDetails(this.props.federalClusterId)
        .then((results: GetFederalClusterDetailsHandler.Result) => {
          if (results) {
            this.setState({
              federalCluster: results.federalCluster ?? FederalClusterDTO.create({
                id: this.props.federalClusterId == Guid.Empty() ? Guid.Empty() : this.props.federalClusterId,
                serviceAreaId: undefined
              })
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({
      loading: false, federalCluster: FederalClusterDTO.create({
        id: Guid.Empty(),
        federalClusterIdentifier: '',
        serviceAreaIdentifier: '',
        code: ''
      })
    });
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      this.handleSubmit,
      () => this.setState({ error: true })
    );
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const data = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;

    if (data.serviceAreaIdentifier === '') {
      data.serviceAreaIdentifier = null;
    }

    const request = SaveFederalClusterDetailsHandler.Request.create({
      federalClusterId: this.props.federalClusterId,
      federalCluster: FederalClusterDTO.create({
        ...data,
        id: this.props.federalClusterId ?? Guid.Empty(),
        academicYear: this.props.academicYear
      })
    });


    FederalClusterApiService.saveFederalClusterDetails(request)
      .then((result: SaveFederalClusterDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ federalCluster: result.federalCluster ?? FederalClusterDTO.create() });

          message.success('Saved');

          if (this.props.onSave && result.federalCluster?.id) {
            this.resetForm();
            this.props.onSave(result.federalCluster.id ?? Guid.Empty());
          }

        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error(result?.errors.join('\n'));
          this.setState({ loading: false, submitting: false });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results, loading: false, submitting: false });
        message.error('FederalCluster could not be saved');
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.federalCluster}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(FederalClusterDTO.federalClusterIdentifier)}
            {...ValidationUtil.getValidation(FederalClusterDTO.federalClusterIdentifier, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(FederalClusterDTO.description)}
            {...ValidationUtil.getValidation(FederalClusterDTO.description, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(FederalClusterDTO.serviceAreaIdentifier)}
            {...ValidationUtil.getValidation(FederalClusterDTO.serviceAreaIdentifier, this.state.fieldErrors, this.state.submitted)}>
            <Dropdown allowClear={true} disabled={!this.props.isEditing || this.state.loading || this.state.submitting}> {this.state.serviceAreas.sort((a, b) => a.serviceAreaIdentifier - b.serviceAreaIdentifier).map(x => this.renderServiceArea(x))}</Dropdown>
          </FormItem>
        </Form>
      </Space>
    );
  }

  renderServiceArea(serviceArea: ServiceAreaDTO) {
    if (serviceArea.id) {
      return <Select.Option title={serviceArea.display ?? ''} key={serviceArea.id ?? Guid.Empty()} value={serviceArea.serviceAreaIdentifier}>{serviceArea.display}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default FederalClusterDetailsForm;
