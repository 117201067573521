import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import AreaEducationAgencyDetailsForm from '../forms/secondary/AreaEducationAgencyDetailsForm';


interface AreaEducationAgencyDetailsModalProps extends ModalProps {
  areaEducationAgencyId: string;
  academicYear: number;
  onSave?: () => void;
}

function AreaEducationAgencyDetailsModal(props: AreaEducationAgencyDetailsModalProps) {
  const _areaEducationAgencyDetailsRef = React.createRef<AreaEducationAgencyDetailsForm>();

  const isNew = props.areaEducationAgencyId == Guid.Empty();

  const submitSaveAreaEducationAgency = () => {
    _areaEducationAgencyDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New Area Education Agency' : 'Edit Area Education Agency'}
      okText='Save'
      onOk={submitSaveAreaEducationAgency}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <AreaEducationAgencyDetailsForm
        ref={_areaEducationAgencyDetailsRef}
        areaEducationAgencyId={props.areaEducationAgencyId}
        isEditing={true}
        onSave={props.onSave}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default AreaEducationAgencyDetailsModal;