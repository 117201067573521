import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal, Skeleton } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import AddSelfStudyForm from '../../../components/forms/secondary/AddSelfStudyForm';
import Routes from '../../../config/Routes';
import AcademicYearStatusType from '../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../consts/FeatureFlag';
import SelfStudyResponseType from '../../../consts/SelfStudyResponseType';
import * as DeleteSelfStudyHandler from '../../../handlerModels/DeleteSelfStudyHandler';
import * as GetRegionalPlanningPartnersByAcademicYearHandler from '../../../handlerModels/GetRegionalPlanningPartnersByAcademicYearHandler';
import * as AssignMissingDERespondersHandler from '../../../handlerModels/AssignMissingDERespondersHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import SecondaryCipNumberDTO from '../../../models/SecondaryCipNumberDTO';
import SecondaryProgramDTO from '../../../models/SecondaryProgramDTO';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import SelfStudyResponseStatusTypeDTO from '../../../models/SelfStudyResponseStatusTypeDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import SecondaryProgramStatusTypeDTO from '../../../models/SecondaryProgramStatusTypeDTO';

interface SelfStudiesDataTableProps {
  programsForReview?: boolean;
  academicYear?: number;
  academicYearData?: AcademicYearDTO;
  selectedDistrict: DistrictDTO | null;
  selectedRPP: RegionalPlanningPartnerDTO | null;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  isPublic?: boolean;
  districtId?: string;
  fromAcademicDashboard?: boolean;
  userActions?: boolean;
  rppId?: string;
  refreshStats?: () => void;
  userId?: string;
}

function SelfStudiesDataTable(props: SelfStudiesDataTableProps) {
  let _dataTable: DataTable<SelfStudyDTO> | undefined = undefined;
  const _addSelfStudyRef = React.createRef<any>();

  const [selfStudyResponseTypes, setSelfStudyResponseTypes] = useState([] as TableFilterOption[]);
  const [secondaryProgramStatusTypes, setSecondaryProgramStatusTypes] = useState([] as SecondaryProgramStatusTypeDTO[]);
  const [rpps, setRPPs] = useState([] as RegionalPlanningPartnerDTO[]);
  const [showModal, setShowModal] = useState(false);
  const [, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selfStudyTypes, setSelfStudyStatusTypes] = useState([] as SelfStudyResponseStatusTypeDTO[]);

  React.useEffect(() => {
    const loaders = [];
    setLoading(true);

    loaders.push(loadRpps(props.academicYear));
    loaders.push(loadApprovalStatusTypes());
    loaders.push(TableFilterUtil.SelfStudyResponseTypes()
      .then(x => {
        setSelfStudyResponseTypes(x);
      }));

    loaders.push(loadSelfStudyResponseStatusTypes());

    Promise.all(loaders).then(() => {
      setLoading(false);
      triggerRefresh();
    });
  }, [props.academicYear]);

  const loadRpps = (academicYear: any) => {
    const request = GetRegionalPlanningPartnersByAcademicYearHandler.Request.create({
      academicYearId: academicYear
    });

    return RegionalPlanningPartnerApiService.getRegionalPlanningPartnersByAcademicYear(request)
      .then((results: GetRegionalPlanningPartnersByAcademicYearHandler.Result) => {
        if (results) {
          setRPPs(results.regions ?? []);
        }
      })
      .catch(() => {
        setError(true);
      });
  }

  const loadApprovalStatusTypes = () => {
    return LookupsUtil.getAll<SecondaryProgramStatusTypeDTO>(SecondaryProgramStatusTypeDTO.className)
      .then((results: SecondaryProgramStatusTypeDTO[]) => {
        setSecondaryProgramStatusTypes(results);
      }).catch(() => {
        setError(true);
      });
  }

  const loadSelfStudyResponseStatusTypes = () => {
    return LookupsUtil.getAll<SelfStudyResponseStatusTypeDTO>(SelfStudyResponseStatusTypeDTO.className)
      .then((results: SelfStudyResponseStatusTypeDTO[]) => {
        setSelfStudyStatusTypes(results);
      }).catch(() => {
        setError(true);
      });
  }

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Identifier',
        dataIndex: SelfStudyDTO.secondaryProgramId,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyDTO) => {
          return row.secondaryProgram?.secondaryProgramId;
        },
        filterType: FilterType.Text
      },
      {
        title: 'RPP',
        dataIndex: DistrictDTO.regionIdentifier,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyDTO) => {
          return row.secondaryProgram?.district?.region?.name;
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: rpps?.map(c => { return { text: c.name ?? '', value: c.regionalPlanningPartnerIdentifier.toString() ?? 0 } }),
      },
      {
        title: 'District',
        dataIndex: SecondaryProgramDTO.districtId,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyDTO) => {
          return row.secondaryProgram?.district?.name;
        },
        filterType: FilterType.Text
      },
      {
        title: 'CIP Number',
        dataIndex: SecondaryCipNumberDTO.code,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyDTO) => {
          return row.secondaryProgram?.secondaryCipNumber?.code;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Title',
        dataIndex: SecondaryCipNumberDTO.description,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyDTO) => {
          return row.secondaryProgram?.secondaryCipNumber?.description;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Status',
        dataIndex: SecondaryProgramDTO.secondaryProgramReviewHistories,
        width: 50,
        render: (data: string, row: SelfStudyDTO) => {
          if (row.activeSelfStudyResponseStatus?.name) {
            return selfStudyResponseTypes.find(x => x.value == row.currentSelfStudyResponseTypeId)?.text + ': ' + row.activeSelfStudyResponseStatus?.name ?? 'Unknown';
          }
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: selfStudyTypes?.map(c => { return { text: c.name ?? '', value: c.id.toString() ?? 0 } }),
        filterDropdownVisible: true
      },
    ] as DataTableColumnProps<any>[];

    if (props.academicYearData?.academicYearStatusTypeId == AcademicYearStatusType.CLOSED || AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_CURRENT_PROGRAM_STATUS || FeatureFlag.SYSTEM_ADMIN])) {
      columns.push({
        title: 'Approval Status',
        dataIndex: SelfStudyDTO.secondaryProgramStatusTypeId,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyDTO) => {
          return secondaryProgramStatusTypes.find(x => x.id == row.secondaryProgramStatusTypeId)?.name ?? 'Unknown';
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: secondaryProgramStatusTypes?.map(c => { return { text: c.name ?? '', value: c.id.toString() ?? 0 } })
      });
    }

    columns.pushAll([
      {
        title: 'Users',
        dataIndex: SelfStudyResponseDTO.respondingUserId,
        width: 50,
        render: (data: string, row: SelfStudyDTO) => {
          const districtUser = row.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR)?.respondingUser?.fullDisplay;
          const RPPUser = row.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.RPP)?.respondingUser?.fullDisplay;
          const IDOEUser = row.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.DOE)?.respondingUser?.fullDisplay;
          const userList = (districtUser ? 'District: ' + districtUser + ', \n' : '') + (RPPUser ? 'RPP: ' + RPPUser + ', \n' : '') + (IDOEUser ? 'IDOE : ' + IDOEUser : '')
          return userList;
        },
        filterType: FilterType.Text
      }
    ]);

    if (!props.userActions && props.academicYearData?.academicYearStatusTypeId != AcademicYearStatusType.CLOSED) {
      columns.push({
        title: 'Actions',
        align: 'center',
        dataIndex: SelfStudyDTO.className,
        sorter: false,
        render: (data: string, row: SelfStudyDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM_REVIEW])) {
            return <>
              <Button type="link" onClick={(e: any) => promptConfirmRemove(e, row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 50
      });
    }

    return columns;
  };

  const openSelfStudyDetail = (record: SelfStudyDTO) => {
    if (record.id) {
      if (props.selectedDistrict) {
        HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: record.id ?? 0 }, { academicYearId: props.academicYear, districtId: record.secondaryProgram?.districtId ?? Guid.Empty(), rppId: props.rppId }));
      }
      else if (AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_DISTRICT_RESPONSE, FeatureFlag.VIEW_RPP_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE, FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE])) {
        HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: record.id ?? 0 }, { academicYearId: props.academicYear, fromSelfStudies: props.fromSelfStudies, programsForReview: props.programsForReview }));
      }
    }
  }

  const deleteSelfStudy = (selfStudyId: string, academicYearId: number) => {
    const request = DeleteSelfStudyHandler.Request.create({
      selfStudyId: selfStudyId,
      academicYearId: academicYearId
    });

    SelfStudyApiService.deleteSelfStudy(request)
      .then((result: DeleteSelfStudyHandler.Result | null) => {

        if (result?.succeeded === true) {
          message.success('Program was removed.');
          _dataTable?.refresh();
          if (props.refreshStats) {
            props.refreshStats();
          }
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Program could not be removed.');
      })
  }

  const promptConfirmRemove = (e: any, secondaryProgramCourseId: string | null) => {
    e.stopPropagation();
    if (secondaryProgramCourseId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to remove this program for review?',
      okText: 'Delete',
      onOk: () => deleteSelfStudy(secondaryProgramCourseId, props.academicYear ?? 0),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const onClickshowModal = () => {
    setShowModal(true);
  }

  const onCloseModal = () => {
    setShowModal(false);
    _dataTable?.refresh();
  }

  const onOkay = () => {
    _addSelfStudyRef.current?.handleSubmit();
    if (props.refreshStats) {
      props.refreshStats();
    }
  }

  const onAssignMissingDeConsultantsOkay = () => {
    SelfStudyApiService.assignMissingDEConsultants(AssignMissingDERespondersHandler.Request.create({ academicYear: props.academicYear }))
      .then((result: AssignMissingDERespondersHandler.Result) => {
        if (result.failedSurvieAreas.length > 0) {
          Modal.info({
            title: 'Service Area Assignments Failed',
            content: 'Could not assing responders for the following service areas: ' + result.failedSurvieAreas
          });
        }
      });
  }

  const showAssignMissingDEConsultantsModal = () => {
    Modal.confirm({
      title: 'Assign Missing Consultants?',
      content: 'Are you sure you would like to assign all missing DE consultants?',
      onOk: () => {
        onAssignMissingDeConsultantsOkay();
      },
      okText: 'Assign Consultants'
    })
  }

  const renderAddSelfStudyModal = () => {
    if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
      return (
        <Modal
          visible={showModal}
          title='Add Program'
          okText='Save'
          onOk={onOkay}
          onCancel={onCloseModal}
          width={1000}
        >
          <AddSelfStudyForm
            ref={_addSelfStudyRef}
            academicYear={props.academicYear ?? 0}
          />
        </Modal>
      );
    }
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());

  if (!props.userActions && AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
    actionButtons.push(DataTableButtonUtil.Default('Add Program', onClickshowModal));
  }


  if (!props.userActions && AuthorizationUtil.isAuthorized([FeatureFlag.ASSIGN_DE_CONSULTANT_RESPONDER])) {
    actionButtons.push(DataTableButtonUtil.Default('Assign Missing DE Consultants', showAssignMissingDEConsultantsModal));
  }



  if (loading) {
    return <Skeleton active />
  }
  else {

    return (<>

      <DataTable
        // @ts-ignore
        ref={(element: any) => (_dataTable = element)}
        onRowClick={openSelfStudyDetail}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => SelfStudyApiService.getSelfStudyTableData(
            requestState,
            props.academicYear == 0 ? 0 : props.academicYear ?? 0,
            props.selectedDistrict?.districtId ?? 0,
            props.selectedRPP?.regionalPlanningPartnerIdentifier ?? 0)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'self_study_list' }} />
      {renderAddSelfStudyModal()}
    </>
    );
  }
}

export default SelfStudiesDataTable;
