import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal, Select, Skeleton } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import SelfStudyDetailsForm from '../../../components/forms/secondary/SelfStudyDetailsForm';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteSelfStudyHandler from '../../../handlerModels/DeleteSelfStudyHandler';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import * as GetSelfStudyDetailsHandler from '../../../handlerModels/GetSelfStudyDetailsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import { default as SelfStudyCourseDTO, default as SelfStudyDTO } from '../../../models/SelfStudyDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import CurrentUser from '../../../utils/CurrentUser';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface SelfStudyDetailsPageState extends BaseFormState {
  id: string;
  selfStudyId: number;
  selfStudy?: SelfStudyDTO;
  selfStudies: SelfStudyDTO[];
  selfStudyCourses: SelfStudyCourseDTO[];
  academicYears: AcademicYearDTO[];
  academicYearId: number;
  fromAcademicYearList: boolean;
  fromSelfStudies: boolean;
}

interface SelfStudyDetailsPageProps {
}

class SelfStudyDetailsPage extends React.Component<SelfStudyDetailsPageProps & RouteComponentProps<RouteObject>, SelfStudyDetailsPageState> {
  private readonly _selfStudyDetailsRef = React.createRef<any>();


  constructor(props: SelfStudyDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      id: Guid.Empty(),
      selfStudyId: 0,
      academicYears: [],
      academicYearId: 0,
      fromAcademicYearList: false,
      fromSelfStudies: false,
      selfStudies: [],
      selfStudyCourses: []
    };
  }

  componentDidMount() {
    const selfStudyId = ParameterUtil.getPathPart(this.props.match, 'id');
    const academicYearId = ParameterUtil.getParameter('academicYearId');
    const fromAcademicYearList = ParameterUtil.getParameter('fromAcademicYearList');
    const fromSelfStudies = ParameterUtil.getParameter('fromSelfStudies');
    this.setState({
      academicYearId: Number.parseInt(academicYearId),
      fromSelfStudies: fromSelfStudies == 'true',
      fromAcademicYearList: fromAcademicYearList == 'true',
      id: selfStudyId,
      loading: true
    });

    if (this.props.match.path.split('/').findIndex(x => x == 'new') > -1) {
      this.setState({ isEditing: true, isNew: true, loading: false });
      const loaders = [];
      loaders.push(this.loadAcademicYears());
      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
    else {
      const loaders = [];
      loaders.push(this.loadSelfStudy(selfStudyId));
      loaders.push(this.loadAcademicYears());

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  private loadSelfStudy = (id: string, academicYear?: any, selfStudyId?: any) => {
    this.setState({ id: id });
    const request = GetSelfStudyDetailsHandler.Request.create({
      id: id,
      selfStudyId: selfStudyId,
      academicYear: academicYear
    });

    SelfStudyApiService.getSelfStudyDetails(request)
      .then((results: GetSelfStudyDetailsHandler.Result) => {
        if (results.selfStudy) {
          this.setState({
            selfStudy: results.selfStudy,
            id: results.selfStudy.id ?? Guid.Empty(),
            isNew: false,
            isEditing: false
          });

        }
        else {
          this.setState({
            isNew: true,
            id: Guid.Empty(),
            selfStudyId: this.state.selfStudyId,
            selfStudy: undefined,
            isEditing: true
          })
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAcademicYears = () => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._selfStudyDetailsRef.current.resetForm()
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private selfStudyDetailsPageSaved = (selfStudyId: string) => {
    this.setState({ isEditing: false, isNew: false });
    if (selfStudyId != this.state.id) {
      HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_DETAILS, { id: selfStudyId }, { academicYearId: this.state.academicYearId, fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList }));
      this.loadSelfStudy(selfStudyId);
    }
    else {
      this.loadSelfStudy(selfStudyId);
    }
  }

  private deleteConfirmation = (id: string, academicYearId: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this program for review?',
      okText: 'Delete',
      onOk: () => this.deleteSelfStudy(id, academicYearId)
    });
  }

  private deleteSelfStudy = (selfStudyId: string, academicYearId: number) => {
    this.setState({ submitting: true });

    const request = DeleteSelfStudyHandler.Request.create({
      selfStudyId: selfStudyId,
      academicYearId: academicYearId
    });

    SelfStudyApiService.deleteSelfStudy(request)
      .then((result: DeleteSelfStudyHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Removed');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Program could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToListing();
      });
  }

  goToListing = () => {
    HistoryUtil.goBack();
  }

  render() {
    if (this.state.loading || this.state.loading == undefined) {
      return <Skeleton active />
    }

    const title = this.state.loading ? '...' : this.state.selfStudy?.secondaryProgram?.secondaryCipNumber?.description ?? '...';
    const sub = this.state.loading ? '...' : this.state.selfStudy?.secondaryProgram?.district?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          subTitle={sub}
          onBack={this.goToListing}
          breadcrumbs={Breadcrumbs.selfStudyDetailPage(title.toString(), this.state.id, this.state.academicYearId, this.state.fromAcademicYearList, this.state.fromSelfStudies)}
          extra={this.renderMoreActions()}
        />
        {this.renderDetailsCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    const editButton = this.state.id == Guid.Empty() ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_PROGRAM_REVIEW]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton} >
        <SelfStudyDetailsForm
          ref={this._selfStudyDetailsRef}
          selfStudyId={this.state.id}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          readonly={CurrentUser.Get() == null}
          onSave={this.selfStudyDetailsPageSaved}
          academicYearId={this.state.academicYearId} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        <Menu.Item title='Remove From Review' key={this.state.id} onClick={() => this.deleteConfirmation(this.state.id, this.state.academicYearId)}  >
          Remove From Review
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_PROGRAM_REVIEW]}>
          {this.state.isNew ? null
            : <Dropdown overlay={menu}>
              <Button icon={<MoreOutlined />} type='link' />
            </Dropdown>}
        </AuthorizedContent>
      </>
    );
  }

  renderSelfStudyAcademicYear(selfStudyAcademicYear: SelfStudyDTO) {
    return <Select.Option key={selfStudyAcademicYear.id ?? Guid.Empty()} value={selfStudyAcademicYear.id ?? Guid.Empty()}>{selfStudyAcademicYear.academicYearId}</Select.Option>
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</Select.Option>
  }
}

export default withRouter(SelfStudyDetailsPage);
