import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, message, Select, Skeleton, Space, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import * as SaveNewProgramChangeRequestStep14Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep14Handler';
import * as SubmitNewProgramChangeRequestStep14Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep14Handler';
import * as GetNewProgramChangeRequestStep14Handler from '../../../handlerModels/GetNewProgramChangeRequestStep14Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import GenderDTO from '../../../models/GenderDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep14FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
  isTrusteesChecked: boolean;
  isCurriculumChecked: boolean;
}

interface NewProgramChangeRequestStep14FormProps extends BaseFormProps, BaseChangeRequestProps {
  awardId?: string;
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: () => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep14Form extends React.Component<NewProgramChangeRequestStep14FormProps, NewProgramChangeRequestStep14FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: NewProgramChangeRequestStep14FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      newProgramChangeRequest: NewProgramChangeRequestDTO.create({ boardOfTrusteesDateOfApproval: '', curriculumApprovalProcessDateOfApproval: '' }),
      changeRequests: [],
      isTrusteesChecked: false,
      isCurriculumChecked: false
    };
  }

  private getSignOffsFormItems = () => {
    const fields = new Map<string, FormItemProps>()
      .set(NewProgramChangeRequestDTO.changeRequestId, {
        name: NewProgramChangeRequestDTO.changeRequestId,
        label: 'Do you agree to the terms above?'
      })
      .set(NewProgramChangeRequestDTO.boardOfTrusteesDateOfApproval, {
        label: 'Board of Trustees Date of Approval',
        name: NewProgramChangeRequestDTO.boardOfTrusteesDateOfApproval,
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NewProgramChangeRequestDTO.curriculumApprovalProcessDateOfApproval, {
        label: 'Curriculum Approval Process Date of Approval ',
        name: NewProgramChangeRequestDTO.curriculumApprovalProcessDateOfApproval,
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
    return fields;
  }

  private cleanDates = (newProgram: NewProgramChangeRequestDTO) => {
    newProgram.boardOfTrusteesDateOfApproval = moment(newProgram.boardOfTrusteesDateOfApproval);
    newProgram.curriculumApprovalProcessDateOfApproval = moment(newProgram.curriculumApprovalProcessDateOfApproval);

    return newProgram;
  }

  componentDidMount() {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      this.loadChangeRequest();
    }
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep14FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {

      if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
        this.loadChangeRequest();
      }
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep14Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep14(request)
      .then((results: GetNewProgramChangeRequestStep14Handler.Result) => {
        if (results) {

          const newProgram = results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create();
          if (newProgram.boardOfTrusteesDateOfApproval == '1970-01-01T00:00:00.000Z' || newProgram.boardOfTrusteesDateOfApproval == '0001-01-01T00:00:00+00:00') {
            newProgram.boardOfTrusteesDateOfApproval = '';
          }
          else {
            // newProgram.boardOfTrusteesDateOfApproval = moment(newProgram.boardOfTrusteesDateOfApproval);
            this.cleanDates(newProgram);
          }

          if (newProgram.curriculumApprovalProcessDateOfApproval == '1970-01-01T00:00:00.000Z' || newProgram.curriculumApprovalProcessDateOfApproval == '0001-01-01T00:00:00+00:00') {
            newProgram.curriculumApprovalProcessDateOfApproval = '';
          }
          else {
            this.cleanDates(newProgram);
            // newProgram.curriculumApprovalProcessDateOfApproval = moment(newProgram.curriculumApprovalProcessDateOfApproval);
          }


          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: newProgram ?? NewProgramChangeRequestDTO.create(),
            isTrusteesChecked: newProgram.boardOfTrusteesDateOfApproval == '' ? false : true,
            isCurriculumChecked: newProgram.curriculumApprovalProcessDateOfApproval == '' ? false : true,
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const newProgramChangeRequestDTO = NewProgramChangeRequestDTO.create(this._formRef.current?.getFieldsValue());
    newProgramChangeRequestDTO.changeRequestId = this.state.changeRequest.id ?? Guid.Empty();

    const request = SubmitNewProgramChangeRequestStep14Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequestDTO
    });

    NewProgramChangeRequestApiService.submitStep14(request)
      .then((result: SubmitNewProgramChangeRequestStep14Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');


          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
          });

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }

          if (this.props.onSave) {
            this.props.onSave();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  private handleSave = () => {
    this.setState({ submitting: true });
    const newProgramChangeRequestDTO = NewProgramChangeRequestDTO.create(this._formRef.current?.getFieldsValue());

    newProgramChangeRequestDTO.changeRequestId = this.state.changeRequest.id ?? Guid.Empty();

    const request = SaveNewProgramChangeRequestStep14Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequestDTO
    });

    NewProgramChangeRequestApiService.saveStep14(request)
      .then((result: SaveNewProgramChangeRequestStep14Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');


          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
          });

          if (this.props.onSave) {
            this.props.onSave();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  private onTrusteesCheckboxClick = (e: any) => {
    this.setState({ isTrusteesChecked: e.target.checked });
    if (!e.target.checked) {
      this._formRef.current?.setFieldsValue({
        'boardOfTrusteesDateOfApproval': null
      });
    }
  }

  private onCurriculumCheckboxClick = (e: any) => {
    this.setState({ isCurriculumChecked: e.target.checked });
    if (!e.target.checked) {
      this._formRef.current?.setFieldsValue({
        'curriculumApprovalProcessDateOfApproval': null
      });
    }
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}


        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.newProgramChangeRequest}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <Space direction="vertical" >
            <ul >
              <li>The applicant assures that in carrying out this program it will comply with federal and state laws which prohibit discrimination on the basis of race, color, national origin, gender, disability, religion, creed, age, or marital status in educational programs.</li>
              <li>The applicant assures that equitable access will be provided to all students.</li>
              <li>The applicant assures that multicultural, gender fair approaches will be used in planning and implementing programs.</li>
              <li>The applicant assures that employers in work-site based training programs do not discriminate against employees or customers and provide an environment free of racial and sexual harassment.</li>
              <li>Students will be accepted for on-the job training, clinical or work experiences, assigned to jobs and otherwise treated without regard to race, color, national origin, gender, marital status or disability.</li>
              <li>The applicant assures that services to students with disabilities are provided in the least restrictive environment that will meet their needs.</li>
              <li>The applicant assures that reasonable accommodations are provided to students with disabilities.</li>
              <li>The applicant assures that the programs include competency-based applied learning which contributes to an individuals academic knowledge, higher-order reasoning and problem-solving skills, work attitudes, general employability skills, and occupational-specific skills.</li>
              <li>If this program is a shared program with another community college, the applicant assures that the appropriate contracts and agreements are in place.</li>
              <li>The applicant assures that instructors teaching in this program will be appropriately prepared.</li>
              <li>The applicant assures that the college is in compliance with the Code of Iowa, Chapter 260C.14(1) concerning duplication of programs.</li>
            </ul>

            <Checkbox disabled={this.props.readonly} checked={this.state.isCurriculumChecked} onChange={this.onCurriculumCheckboxClick} >
              <Typography.Text strong={true} >{'This program was approved through the college\'s curriculum approval process.'}</Typography.Text>
            </Checkbox>

            <Checkbox disabled={this.props.readonly} checked={this.state.isTrusteesChecked} onChange={this.onTrusteesCheckboxClick} >
              <Typography.Text strong={true} >The Board of Trustees have approved this program and the terms above.</Typography.Text>
            </Checkbox>

            <FormItem
              {...this.getSignOffsFormItems().get(NewProgramChangeRequestDTO.curriculumApprovalProcessDateOfApproval)}
              {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.curriculumApprovalProcessDateOfApproval, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <DatePicker format='M/D/YYYY' disabled={!this.state.isCurriculumChecked || this.props.readonly} disabledDate={DateTimeUtil.isFutureDate}></DatePicker>
            </FormItem>

            <FormItem
              {...this.getSignOffsFormItems().get(NewProgramChangeRequestDTO.boardOfTrusteesDateOfApproval)}
              {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.boardOfTrusteesDateOfApproval, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <DatePicker format='M/D/YYYY' disabled={!this.state.isTrusteesChecked || this.props.readonly} disabledDate={DateTimeUtil.isFutureDate}></DatePicker>
            </FormItem>

            {this.renderActions()}
          </Space>
        </ Form>
      </Space >
    );
  }

  private renderActions() {
    if (!this.props.readonly) {
      return <Space direction='horizontal' wrap={true}>
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
      </Space>
    }
  }

  renderAddAdditionalAdvisor(add: any) {
    if (!this.props.readonly) {
      return (
        <Button onClick={() => add()} icon={<PlusOutlined />}>
          Add Additional Advisor
        </Button>
      );
    }
  }

  renderGender(gender: GenderDTO) {
    if (gender.id) {
      return <Select.Option key={gender.id ?? Guid.Empty()} value={gender.id ?? Guid.Empty()}>{gender.name}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NewProgramChangeRequestStep14Form