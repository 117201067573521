import { DownOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import InstitutionCourseBlockApiService from '../../../api/InstitutionCourseBlockApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import InstitutionCourseBlockCoursesDataTable from '../../../components/datatables/postSecondary/InstitutionCourseBlockCoursesDataTable';
import InstitutionCourseBlockProgramsDataTable from '../../../components/datatables/postSecondary/InstitutionCourseBlockProgramsDataTable';
import InstitutionCourseBlockDetailsForm from '../../../components/forms/postSecondary/InstitutionCourseBlockDetailsForm';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetInstitutionCourseBlockDetailsHandler from '../../../handlerModels/GetInstitutionCourseBlockDetailsHandler';
import InstitutionCourseBlockDetailDTO from '../../../models/InstitutionCourseBlockDetailDTO';
import InstitutionCourseBlockDTO from '../../../models/InstitutionCourseBlockDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ParameterUtil from '../../../utils/ParameterUtil';

interface InstitutionCourseBlockDetailsState extends BaseFormState {
  institutionCourseBlockId: string;
  institutionCourseBlock: InstitutionCourseBlockDTO;
  activeTab: string;
  selectedDetailId: string;
}

interface InstitutionCourseBlockDetailsProps {
  selectedInstitution: InstitutionDTO | null;
}

class InstitutionCourseBlockDetailPage extends React.Component<InstitutionCourseBlockDetailsProps & RouteComponentProps<RouteObject>, InstitutionCourseBlockDetailsState> {
  private static readonly ICB_DETAILS = 'ICB_DETAILS';

  private readonly _institutionCourseBlockDetailsFormRef = React.createRef<InstitutionCourseBlockDetailsForm>();

  constructor(props: InstitutionCourseBlockDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      institutionCourseBlockId: Guid.Empty(),
      institutionCourseBlock: InstitutionCourseBlockDTO.create(),
      isEditing: false,
      activeTab: 'details',
      selectedDetailId: Guid.Empty()
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');
    const activeTab = ParameterUtil.getParameter('activeTab', 'details');

    this.setState({ activeTab: activeTab });
    if (id == 'new') {
      this.setState({ isEditing: true });
    }
    else {
      this.loadInstitutionCourseBlock(id);
    }
  }

  private loadInstitutionCourseBlock = (id: string) => {
    this.setState({ loading: true, institutionCourseBlockId: id });
    InstitutionCourseBlockApiService.getInstitutionCourseBlockDetails(id)
      .then((results: GetInstitutionCourseBlockDetailsHandler.Result) => {
        if (results.institutionCourseBlock) {

          let selectedId = Guid.Empty();
          if (results.institutionCourseBlock?.institutionCourseBlockDetails?.length ?? 0 > 0) {
            const selected = results.institutionCourseBlock?.institutionCourseBlockDetails != null ?
              (results.institutionCourseBlock?.currentDetail ?? InstitutionCourseBlockDetailDTO.create()) :
              InstitutionCourseBlockDetailDTO.create();

            if (selected.id) {
              selectedId = selected.id;
            }
          }

          this.setState({
            institutionCourseBlock: results.institutionCourseBlock ?? InstitutionCourseBlockDTO.create(),
            selectedDetailId: selectedId
          });
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private toggleIsEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  }

  private handleVersionSelect = (e: any) => {
    this.setState({
      selectedDetailId: e.key
    });
  }

  private isEditingCheck = () => {
    if (this.state.editings) {
      return Array.from(this.state.editings.values()).includes(true);
    }

    return false;
  };

  private institutionCourseBlockDetailsSaved = (id: string) => {
    this.setState({ isEditing: false });

    if (id != this.state.institutionCourseBlockId) {
      HistoryUtil.replace(Routes.generate(Routes.INSTITUITION_COURSE_BLOCK_DETAILS, { id: id }));
    }
    else {
      this.loadInstitutionCourseBlock(id);
    }
  }

  private goBack = () => {
    HistoryUtil.goBack();
  }

  private tabChanged = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  render() {
    const title = this.state.loading ? '...' : this.state.institutionCourseBlockId == Guid.Empty() ? 'New Institution Course Block' : this.state.institutionCourseBlock.currentDetail?.title ?? '...';

    const menu = (
      <Menu onSelect={this.handleVersionSelect} selectable={true} selectedKeys={[this.state.selectedDetailId]}>
        {this.state.institutionCourseBlock.institutionCourseBlockDetails?.map(x => <Menu.Item key={x.id} >{x.versionDisplay}</Menu.Item>)}
      </Menu>
    );

    const selected = this.state.institutionCourseBlock?.institutionCourseBlockDetails?.filter(x => x.id == this.state.selectedDetailId)[0] ?? InstitutionCourseBlockDetailDTO.create();
    const versionTitle = selected ? selected.versionDisplay : 'Versions';

    const versionsDropdown = (
      <Dropdown overlay={menu} >
        <Button icon={<DownOutlined />} >{versionTitle}</Button>
      </Dropdown>
    );

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goBack}
          breadcrumbs={Breadcrumbs.institutionCourseBlockDetails(title, this.state.institutionCourseBlockId)}
          extra={versionsDropdown}
          footer={
            <Tabs activeKey={this.state.activeTab} onChange={this.tabChanged}>
              <Tabs.TabPane key='details' tab='Details' />
              {
                this.state?.selectedDetailId ?? Guid.Empty() != Guid.Empty() ?
                  <Tabs.TabPane key='courses' tab='Courses' /> :
                  null
              }
              {
                this.state?.selectedDetailId ?? Guid.Empty() != Guid.Empty() ?
                  <Tabs.TabPane key='programs' tab='Programs' /> :
                  null
              }
            </Tabs>
          } />
        <PageStayPrompt when={this.isEditingCheck} />

        {this.renderDetailsCard(selected)}
        {this.renderInstitutionCourseBlockCoursesTable()}
        {this.renderInstitutionCourseBlockProgramsTable()}

      </Content>
    );
  }

  renderDetailsCard(selectedDetail: InstitutionCourseBlockDetailDTO) {
    const editButton = this.state.institutionCourseBlockId == Guid.Empty() && selectedDetail.retireTermId != null ?
      null :
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTITUTION_COURSE_BLOCK]}>
        <EditCancelButton onClick={this.toggleIsEditing} isEditing={this.state.isEditing} />
      </AuthorizedContent>;

    return (
      <Card title="Details" extra={editButton} hidden={this.state.activeTab !== 'details'}>
        <InstitutionCourseBlockDetailsForm key={this.state.selectedDetailId}
          ref={this._institutionCourseBlockDetailsFormRef}
          institutionCourseBlockDetail={selectedDetail}
          isEditing={this.state.isEditing}
          selectedInstitution={this.props.selectedInstitution}
          onSave={this.institutionCourseBlockDetailsSaved} />
      </Card>
    );
  }

  renderInstitutionCourseBlockCoursesTable() {
    if (this.state.institutionCourseBlockId != Guid.Empty()) {
      return (
        <Card title="Courses" hidden={this.state.activeTab !== 'courses'}>
          <InstitutionCourseBlockCoursesDataTable institutionId={this.state.institutionCourseBlock?.institutionId ?? Guid.Empty()} institutionCourseBlockDetailId={this.state.selectedDetailId} />
        </Card >
      );
    }
  }

  renderInstitutionCourseBlockProgramsTable() {
    if (this.state.institutionCourseBlockId != Guid.Empty()) {
      return (
        <Card title="Programs" hidden={this.state.activeTab !== 'programs'}>
          <InstitutionCourseBlockProgramsDataTable institutionId={this.state.institutionCourseBlock?.institutionId ?? Guid.Empty()} institutionCourseBlocklId={this.state.institutionCourseBlockId} />
        </Card >
      );
    }
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(InstitutionCourseBlockDetailPage));
