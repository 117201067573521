import { DeleteOutlined, EditOutlined, StarOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import * as React from 'react';
import InstitutionLocationApiService from '../../../api/InstitutionLocationApiService';
import * as RemoveInstitutionLocationHandler from '../../../handlerModels/RemoveInstitutionLocationHandler';
import FeatureFlag from '../../../consts/FeatureFlag';
import InstitutionLocationDataTableDTO from '../../../models/InstitutionLocationDataTableDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import AddInstitutionLocationForm from '../../forms/postSecondary/AddInstitutionLocationForm';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import BaseFormState from '../../../redux/bases/BaseFormState';

interface InstitutionLocationDataTableProps {
  institutionId: string;
  triggerUpdate?: () => void;
}

interface InstitutionLocationDataTableState extends BaseFormState, BaseDataTableState<InstitutionLocationDataTableDTO> {
  addLocation: boolean;
  institutionLocationId: string;
}

class InstitutionLocationDataTable extends React.Component<InstitutionLocationDataTableProps, InstitutionLocationDataTableState> {
  private _dataTable: DataTable<InstitutionLocationDataTableDTO> | undefined;
  private readonly _addInstitutionLocationFormRef = React.createRef<AddInstitutionLocationForm>();

  constructor(props: InstitutionLocationDataTableProps) {
    super(props);
    this.state = ({
      addLocation: false,
      institutionLocationId: Guid.Empty()
    });
  }

  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: '',
        width: 15,
        align: 'center',
        dataIndex: InstitutionLocationDataTableDTO.primary,
        render: (data: string, row: InstitutionLocationDataTableDTO) => {
          if (row.primary) {
            return <StarOutlined />
          }
        },
      },
      {
        title: 'Name',
        dataIndex: InstitutionLocationDataTableDTO.name,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Address',
        dataIndex: InstitutionLocationDataTableDTO.address,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Phone',
        dataIndex: InstitutionLocationDataTableDTO.phone,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Fax',
        dataIndex: InstitutionLocationDataTableDTO.fax,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: '',
        dataIndex: InstitutionLocationDataTableDTO.id,
        width: '25px',
        render: (data: string, row: InstitutionLocationDataTableDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_INSTITUTION_LOCATION])) {
            return <>
              {<Button type="link" className="ant-btn" onClick={() => this.addLocation(row.id ?? Guid.Empty())}><EditOutlined /></Button>}
              {<Button type="link" className="ant-btn" onClick={() => this.promptConfirmRemove(row.id ?? Guid.Empty())}><DeleteOutlined /></Button>}
            </>
          }
        },
        sorter: false,
        filterType: FilterType.NONE
      }
    ] as DataTableColumnProps<any>[];
  };

  private handleRemoveLocation = (locationId: string | null) => {
    this.setState({ submitting: true });

    const request = RemoveInstitutionLocationHandler.Request.create({
      institutionLocationId: locationId,
    });

    InstitutionLocationApiService.removeInstitutionLocation(request)
      .then((result: RemoveInstitutionLocationHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Location was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Location could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.triggerRefresh();
      });
  }

  private promptConfirmRemove = (locationId: string | null) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this loation?',
      okText: 'Remove Location',
      onOk: () => this.handleRemoveLocation(locationId)
    });
  }

  private addLocation = (id: string) => {
    this.setState({ institutionLocationId: id }, () => this.openModal());
  }

  private openModal = () => {
    this.setState({ addLocation: true });
  }

  private submitAddLocation = () => {
    this._addInstitutionLocationFormRef.current?.submit();
  }

  private cancelAddLocation = () => {
    this.setState({ addLocation: false });
    this._addInstitutionLocationFormRef.current?.reset();
  }

  private locationAdded = () => {
    this.setState({ addLocation: false });
    this._dataTable?.refresh();
  }

  render() {
    return (
      <>
        {this.renderAddLocationModal()}
        {this.renderDataTable()}
      </>
    );
  }

  renderAddLocationModal() {
    return (
      <Modal
        visible={this.state.addLocation}
        title='Institution Location'
        okText='Save'
        onOk={this.submitAddLocation}
        onCancel={this.cancelAddLocation}>
        <AddInstitutionLocationForm ref={this._addInstitutionLocationFormRef} institutionId={this.props.institutionId} onSuccess={this.locationAdded} institutionLocationId={this.state.institutionLocationId} />
      </Modal>
    );
  }


  renderDataTable() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_INSTITUTION_LOCATION])) {
      actionButtons.push(DataTableButtonUtil.Default('Add Location', () => this.addLocation(Guid.Empty())));
    }

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => InstitutionLocationApiService.getInstitutionLocationsDataTable(requestState, this.props.institutionId)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'institution_location_list' }} />
    );
  }

}

export default InstitutionLocationDataTable;
