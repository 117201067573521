// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetChangeRequestForWorkItemApprovalHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ChangeRequestDTO from '../models/ChangeRequestDTO';
import ChangeRequestApprovalDTO from '../models/ChangeRequestApprovalDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    approvalId: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            approvalId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    approvalId: 'approvalId',
}

interface Result extends ResultDTO {
    changeRequest: ChangeRequestDTO | null;
    changeRequestApproval: ChangeRequestApprovalDTO | null;
    approvalStatusTypeId: number;
    redirectNeeded: boolean;
    approvalUserId: string | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            changeRequest: null,
            changeRequestApproval: null,
            approvalStatusTypeId: 0,
            redirectNeeded: false,
            approvalUserId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    changeRequest: 'changeRequest',
    changeRequestApproval: 'changeRequestApproval',
    approvalStatusTypeId: 'approvalStatusTypeId',
    redirectNeeded: 'redirectNeeded',
    approvalUserId: 'approvalUserId',
}

export { Request, Result };