import { Alert, Button, Col, message, Row, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import DistrictApiService from '../../../api/DistrictApiService';
import InstitutionCoursesApiService from '../../../api/InstitutionCoursesApiService';
import SecondaryCourseApiService from '../../../api/SecondaryCourseApiService';
import { ColumnWidths } from '../../../config/ColumnWidths';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetDistrictsHandler from '../../../handlerModels/GetDistrictsHandler';
import * as GetSecondaryCourseDetailsHandler from '../../../handlerModels/GetSecondaryCourseDetailsHandler';
import * as SaveSecondaryCourseDetailsHandler from '../../../handlerModels/SaveSecondaryCourseDetailsHandler';
import * as SearchForInstitutionCourseByStringHandler from '../../../handlerModels/SearchForInstitutionCourseByStringHandler';
import LaunchOutlined from '../../../icons/LaunchOutlined';
import DistrictDTO from '../../../models/DistrictDTO';
import InstitutionCourseDTO from '../../../models/InstitutionCourseDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import SecondaryCourseDTO from '../../../models/SecondaryCourseDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import CurrentUser from '../../../utils/CurrentUser';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import AutoCompleteInput, { AutoCompleteInputOption } from '../../general/AutoCompleteInput';
import Dropdown from '../../inputs/Dropdown';
import ReadableNumberInput from '../../inputs/ReadableNumberInput';
import ReadableTextBox from '../../inputs/ReadableTextBox';


interface SecondaryCourseDetailsFormState extends BaseFormState {
  secondaryCourse: SecondaryCourseDTO;
  institutions: InstitutionDTO[];
  districts: DistrictDTO[];
  institutionCourses: InstitutionCourseDTO[]
  loadingInstitutionCourse: boolean;
  searching: boolean;
}

interface SecondaryCourseDetailsFormProps extends BaseFormProps {
  secondaryCourseId: string;
  academicYear?: number;
  readonly?: boolean;
  fromDataReview?: boolean;
  onSave?: (secondaryCourseId: string) => void;
}

class SecondaryCourseDetailsForm extends React.Component<SecondaryCourseDetailsFormProps, SecondaryCourseDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(SecondaryCourseDTO.secondaryCourseId, {
      required: true,
      name: SecondaryCourseDTO.secondaryCourseId,
      label: 'Identifier',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    })
    .set(SecondaryCourseDTO.districtId, {
      required: true,
      name: SecondaryCourseDTO.districtId,
      label: 'District',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(SecondaryCourseDTO.localCourseNumber, {
      required: true,
      name: SecondaryCourseDTO.localCourseNumber,
      label: 'Local Course Number',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(50)
      ],
    })
    .set(SecondaryCourseDTO.courseName, {
      required: true,
      name: SecondaryCourseDTO.courseName,
      label: 'Course Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(500)
      ],
    })
    .set(SecondaryCourseDTO.sced, {
      required: true,
      name: SecondaryCourseDTO.sced,
      label: 'SCED',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(50)
      ],
    })
    .set(SecondaryCourseDTO.carnegieUnit, {
      required: true,
      name: SecondaryCourseDTO.carnegieUnit,
      label: 'Carnegie Unit',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(SecondaryCourseDTO.communityCollegeId, {
      name: SecondaryCourseDTO.communityCollegeId,
      label: 'Community College',
    })
    .set(SecondaryCourseDTO.institutionCourseId, {
      name: SecondaryCourseDTO.institutionCourseId,
      label: 'Institution Course',
    }).set(SecondaryCourseDTO.enrollment, {
      required: true,
      name: SecondaryCourseDTO.enrollment,
      label: 'Enrollemnt',
      rules: [
        ValidationRuleUtil.required()
      ],
    });

  constructor(props: SecondaryCourseDetailsFormProps) {
    super(props);

    this.state = {
      secondaryCourse: SecondaryCourseDTO.create({
        id: undefined,
        secondaryCourseId: undefined,
        districtId: undefined,
        localCourseNumber: undefined,
        courseName: undefined,
        carnegieUnit: undefined,
      }),
      institutions: [],
      districts: [],
      institutionCourses: [],
      loadingInstitutionCourse: false,
      searching: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: SecondaryCourseDetailsFormProps) {
    if (this.props.secondaryCourseId != prevProps.secondaryCourseId) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];
    loaders.push(this.loadSecondaryCourse());

    if (!this.state.institutions || this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    if (!this.state.districts || this.state.districts.length == 0 || (this.props.academicYear ?? 0) > 0) {
      loaders.push(this.loadDistricts(this.props.academicYear));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadSecondaryCourse() {
    if (!this.state.loading) {
      if (this.props.secondaryCourseId == Guid.Empty() || this.props.isNew) {
        this.loadNew();
      }
      else {
        if (!this.state.secondaryCourse.id) {
          this.loadExisting();
        }
      }
    }
  }

  private loadDistricts = (academicYear: any) => {
    const request = GetDistrictsHandler.Request.create({
      academicYearId: academicYear
    });

    DistrictApiService.getDistricts(request)
      .then((results: GetDistrictsHandler.Result) => {
        if (results) {
          this.setState({ districts: results.districts ?? [] });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          results = results.sort((a, b) => (a?.code ?? '') > (b?.code ?? '') ? 1 : -1)
          this.setState({ institutions: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.secondaryCourseId && this.props.academicYear) {
      const request = GetSecondaryCourseDetailsHandler.Request.create({
        id: this.props.secondaryCourseId
      });
      SecondaryCourseApiService.getSecondaryCourseDetails(request)
        .then((results: GetSecondaryCourseDetailsHandler.Result) => {
          if (results) {
            if (results.secondaryCourse && results.secondaryCourse.institutionCourse) {
              this.setState({
                institutionCourses: [results.secondaryCourse.institutionCourse]
              });
            }

            this.setState({
              secondaryCourse: results.secondaryCourse ?? SecondaryCourseDTO.create({
                id: this.props.secondaryCourseId == Guid.Empty() ? undefined : this.props.secondaryCourseId,
                secondaryCourseId: undefined,
                districtId: undefined,
                localCourseNumber: undefined,
                courseName: undefined,
                carnegieUnit: undefined,
              })
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private handleInstitutionCourseSearch = (value: string) => {

    if (value.length > 2) {
      this.setState({ searching: true });
      const request = SearchForInstitutionCourseByStringHandler.Request.create(
        {
          searchText: value,
          institutionId: (this._formRef ? (this._formRef.current as any).getFieldsValue() : null).communityCollegeId
        });

      InstitutionCoursesApiService.searchForInstitutionCourseByString(request)
        .then((x: SearchForInstitutionCourseByStringHandler.Result) => {
          if (x) {
            this.setState({ searching: false, institutionCourses: x.institutionCourses ?? [] });
          }
        })
        .finally(() => {
          this.setState({ searching: false });
        });
    }
  }

  private handleInstitutionCourseSelect = () => {
    // no-op
  }

  private loadNew = () => {
    this.setState({
      loading: false, secondaryCourse: SecondaryCourseDTO.create({
        id: this.props.secondaryCourseId == Guid.Empty() ? undefined : this.props.secondaryCourseId,
        secondaryCourseId: undefined,
        districtId: undefined,
        localCourseNumber: undefined,
        courseName: undefined,
        carnegieUnit: undefined,
        enrollment: ''
      })
    }, () => this.resetForm());
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      () => this.handleSubmit(),
      () => this.setState({ error: true })
    );
  }

  public onGoToInstitutionCourse = () => {
    const form = (this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    HistoryUtil.push(Routes.generate(Routes.COURSE_DETAILS, { institutionCourseId: form.institutionCourseId ?? Guid.Empty() }, { fromSecondaryCourses: this.state.secondaryCourse.id, academicYear: this.props.academicYear ?? 0 }));
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveSecondaryCourseDetailsHandler.Request.create({
      secondaryCourseId: this.props.secondaryCourseId,
      secondaryCourse: SecondaryCourseDTO.create({
        ...(this._formRef ? (this._formRef.current as any).getFieldsValue() : null),
        academicYear: this.props.academicYear,
      })
    });

    SecondaryCourseApiService.saveSecondaryCourseDetails(request)
      .then((result: SaveSecondaryCourseDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ secondaryCourse: result.secondaryCourse ?? SecondaryCourseDTO.create(), fieldErrors: undefined, error: false });

          message.success('Saved');

          if (this.props.onSave && result.secondaryCourse?.id) {
            this.resetForm();
            this.props.onSave(result.secondaryCourse.id);
          }

        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Course could not be saved');
          this.setState({ loading: false, submitting: false });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results, loading: false, submitting: false });
        message.error('Course could not be saved');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading || this.state.loadingInstitutionCourse) {
      return <Skeleton active={true} />;
    }

    const isPublic = CurrentUser.Get() == null;
    const institutionCourses = this.state.institutionCourses.map(x => {
      return {
        value: x.id,
        display: x.currentDetail?.display
      } as AutoCompleteInputOption;
    });
    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.secondaryCourse}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={!isPublic}>
          <FormItem
            {...this._formItems.get(SecondaryCourseDTO.secondaryCourseId)}
            {...ValidationUtil.getValidation(SecondaryCourseDTO.secondaryCourseId, this.state.fieldErrors, this.state.submitted)}>
            <ReadableNumberInput readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCourseDTO.districtId)}
            {...ValidationUtil.getValidation(SecondaryCourseDTO.districtId, this.state.fieldErrors, this.state.submitted)}>
            <Dropdown dropdownMatchSelectWidth={false} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} optionFilterProp="title"
              filterOption={(input, option) => (option?.title as unknown as string)?.toLowerCase().includes(input?.toLowerCase())} showSearch={true} allowClear={true}>
              {this.state.districts.sort((a, b) => a.districtId - b.districtId).map(x => this.renderDistrict(x))}
            </Dropdown>
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCourseDTO.localCourseNumber)}
            {...ValidationUtil.getValidation(SecondaryCourseDTO.localCourseNumber, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCourseDTO.courseName)}
            {...ValidationUtil.getValidation(SecondaryCourseDTO.courseName, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCourseDTO.sced)}
            {...ValidationUtil.getValidation(SecondaryCourseDTO.sced, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCourseDTO.carnegieUnit)}
            {...ValidationUtil.getValidation(SecondaryCourseDTO.carnegieUnit, this.state.fieldErrors, this.state.submitted)}>
            <ReadableNumberInput readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCourseDTO.communityCollegeId)}
            {...ValidationUtil.getValidation(SecondaryCourseDTO.communityCollegeId, this.state.fieldErrors, this.state.submitted)}>
            <Dropdown dropdownMatchSelectWidth={false} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} optionFilterProp="title"
              filterOption={(input, option) => (option?.title as unknown as string)?.toLowerCase().includes(input?.toLowerCase())} showSearch={true} allowClear={true}>
              {this.state.institutions.sort((a, b) => a.code - b.code).map(x => this.renderInstitution(x))}
            </Dropdown>
          </FormItem>

          <Row>
            <Col  {...ColumnWidths.THREE_QUARTERS}>
              <FormItem
                {...this._formItems.get(SecondaryCourseDTO.institutionCourseId)}
                {...ValidationUtil.getValidation(SecondaryCourseDTO.institutionCourseId, this.state.fieldErrors, this.state.submitted)}>
                <AutoCompleteInput onSelect={this.handleInstitutionCourseSelect} options={institutionCourses ?? []} searching={this.state.searching} onSearch={this.handleInstitutionCourseSearch} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
              </FormItem>
            </Col>
            <Col  {...ColumnWidths.ONE_QUARTER}>
              {this.state.secondaryCourse.institutionCourseId ?
                < FormItem
                  label=' '
                  {...this._formItems.get(SecondaryCourseDTO.className)}
                >

                  <Button
                    title='Institution Course'
                    type="link"
                    onClick={this.onGoToInstitutionCourse} icon={<LaunchOutlined />}
                  />
                </FormItem> :
                null}
            </Col>
          </Row>

          <FormItem
            {...this._formItems.get(SecondaryCourseDTO.enrollment)}
            {...ValidationUtil.getValidation(SecondaryCourseDTO.enrollment, this.state.fieldErrors, this.state.submitted)}>
            <ReadableNumberInput readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space >
    );
  }

  renderInstitution(institutiton: InstitutionDTO) {
    if (institutiton.id) {
      return <Select.Option title={institutiton.display ?? ''} key={institutiton.id ?? Guid.Empty()} value={institutiton.id ?? Guid.Empty()}>{institutiton.display}</Select.Option>
    }
  }

  renderInstitutionCourse(institutitonCourse: InstitutionCourseDTO) {
    if (institutitonCourse.id) {
      return <Select.Option title={institutitonCourse.currentDetail?.title ?? ''} key={institutitonCourse.id ?? Guid.Empty()} value={institutitonCourse.id ?? Guid.Empty()}>{institutitonCourse.currentDetail?.title ?? ''}</Select.Option>
    }
  }

  renderDistrict(district: DistrictDTO) {
    if (district.id) {
      return <Select.Option title={district.display ?? ''} key={district.id ?? Guid.Empty()} value={district.id ?? Guid.Empty()}>{district.display}</Select.Option>
    }
  }

  renderSave() {
    if (this.props.isEditing && !this.props.fromDataReview) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_COURSE]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!this.state.altered} saving={this.state.submitting} />
            <ResetButton disabled={!this.state.altered} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default SecondaryCourseDetailsForm;
