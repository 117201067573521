// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SubmitNoticeOfIntentChangeRequestStep5Handler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import NoticeOfIntentChangeRequestDTO from '../models/NoticeOfIntentChangeRequestDTO';
import ChangeRequestDTO from '../models/ChangeRequestDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    noticeOfIntentChangeRequest: NoticeOfIntentChangeRequestDTO | null;
    industryFiles: any[] | null;
    removedFiles: string[];
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            noticeOfIntentChangeRequest: null,
            industryFiles: [],
            removedFiles: [],
        },
        initValues);
    },
    noticeOfIntentChangeRequest: 'noticeOfIntentChangeRequest',
    industryFiles: 'industryFiles',
    removedFiles: 'removedFiles',
}

interface Result extends ResultDTO {
    changeRequest: ChangeRequestDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            changeRequest: null,
        },
        initValues);
    },
    changeRequest: 'changeRequest',
}

export { Request, Result };