// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class RoleType {
    public static readonly STATE: number = 1;
    public static readonly INSTITUTION: number = 2;
    public static readonly CONSULTANT: number = 3;
    public static readonly DISTRICT: number = 4;
    public static readonly RPP: number = 5;
}
