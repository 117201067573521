import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import SecondaryCipNumberDetailsForm from '../forms/secondary/SecondaryCipNumberDetailsForm';


interface SecondaryCipNumberDetailsModalProps extends ModalProps {
  secondaryCipNumberId: string;
  academicYear: number;
  onSave?: () => void;
}

function SecondaryCipNumberDetailsModal(props: SecondaryCipNumberDetailsModalProps) {
  const _secondaryCipNumberDetailsRef = React.createRef<SecondaryCipNumberDetailsForm>();

  const isNew = props.secondaryCipNumberId == Guid.Empty();

  const submitSaveCipNumber = () => {
    _secondaryCipNumberDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New CIP Number' : 'Edit CIP Number'}
      okText='Save'
      onOk={submitSaveCipNumber}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <SecondaryCipNumberDetailsForm
        ref={_secondaryCipNumberDetailsRef}
        secondaryCipNumberId={props.secondaryCipNumberId ?? Guid.Empty}
        isEditing={true}
        onSave={props.onSave}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default SecondaryCipNumberDetailsModal;