// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import UserDTO from './UserDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO extends EntitySelectorDTO { 
	id: string | null | null;
	noticeOfIntentChangeRequestMeetingId: string | null;
	userId: string | null;
	consultant: UserDTO | null;
}

const NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO = {
    className: 'NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO',
    create: (initValues?: Partial<NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			noticeOfIntentChangeRequestMeetingId: '00000000-0000-0000-0000-000000000000',
			userId: '00000000-0000-0000-0000-000000000000',
			consultant: null,
        },
        initValues);
    },
	id: 'id',
	noticeOfIntentChangeRequestMeetingId: 'noticeOfIntentChangeRequestMeetingId',
	userId: 'userId',
	consultant: 'consultant',
};

export default NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO;