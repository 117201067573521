import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, message, Modal, Skeleton, Space, Table, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import Routes from '../../../config/Routes';
import * as RemoveAwardFromNewProgramProgramHandler from '../../../handlerModels/RemoveAwardFromNewProgramProgramHandler';
import * as GetNewProgramChangeRequestStep9Handler from '../../../handlerModels/GetNewProgramChangeRequestStep9Handler';
import * as SubmitNewProgramChangeRequestStep9Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep9Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import NewProgramProgramAwardDTO from '../../../models/NewProgramProgramAwardDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import GoBackButton from '../../buttons/GoBackButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import ValueLabel from '../../general/ValueLabel';

interface NewProgramChangeRequestStep9FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
}

interface NewProgramChangeRequestStep9FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: (id: string) => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep9Form extends React.Component<NewProgramChangeRequestStep9FormProps, NewProgramChangeRequestStep9FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: NewProgramChangeRequestStep9FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      newProgramChangeRequest: NewProgramChangeRequestDTO.create()
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      this.loadChangeRequest();
    }
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep9FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {

      if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
        this.loadChangeRequest();
      }
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep9Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep9(request)
      .then((results: GetNewProgramChangeRequestStep9Handler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private openAward = (awardId: string) => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_10, { id: this.state.changeRequest.id ?? Guid.Empty(), awardId: awardId == 'new' ? Guid.Empty() : awardId }, {}))
  }

  private removeAwardModal = (awardId: string) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this award?',
      okText: 'Remove',
      onOk: () => this.removeNewProgramAward(awardId),
      width: 500
    });
  }

  private removeNewProgramAward = (awardId: string) => {
    const newProgramChangeRequest = this.state.changeRequest.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create();
    newProgramChangeRequest.changeRequestId = this.state.changeRequest.id;

    const request = RemoveAwardFromNewProgramProgramHandler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest,
      awardId: awardId,
    });

    return NewProgramChangeRequestApiService.removeAwardFromNewProgramProgram(request)
      .then((results: RemoveAwardFromNewProgramProgramHandler.Result) => {
        if (results) {
          this.setState({
            newProgramChangeRequest: results.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create()
          });
          message.success('Award Removed')
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public render = () => {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }
    const fieldErrorText = this.state.fieldErrors ? this.state.fieldErrors[NewProgramChangeRequestDTO.newProgramProgramAwards]?.map(x => x.message) : '';
    return (
      <Space direction="vertical">
        {this.renderPlannedAwards()}
        <ValueLabel errorMessage={this.state.fieldErrors ? true : false} text={fieldErrorText} />
        {this.renderActions()}

      </Space >
    );
  }
  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SubmitNewProgramChangeRequestStep9Handler.Request.create({
      newProgramChangeRequest: this.state.newProgramChangeRequest ?? null
    }) as SubmitNewProgramChangeRequestStep9Handler.Request;

    NewProgramChangeRequestApiService.submitStep9(request)
      .then((result: SubmitNewProgramChangeRequestStep9Handler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          message.success('Saved');
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }
  renderActions() {

    return (
      <Space direction='horizontal' wrap={true}>
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton onClick={this.handleSubmit} submitting={this.state.submitting} />
      </Space>
    );
  }

  private getColumnDefinitionsForContactInformation = () => {
    return [
      {
        title: 'Order',
        dataIndex: NewProgramProgramAwardDTO.order,
        width: 15
      },
      {
        title: 'Title',
        dataIndex: NewProgramProgramAwardDTO.awardTitle,
        render: (data: string, row: NewProgramProgramAwardDTO) => {
          return <Button type="link" onClick={() => this.openAward(row.id ?? Guid.Empty())}>{data} </Button>
        },
      },
      {
        title: 'Award Type',
        dataIndex: NewProgramProgramAwardDTO.awardTypeId,
        render: (data: string, row: NewProgramProgramAwardDTO) => {
          return row.awardType?.code + ' - ' + row.awardType?.name;
        },
      },
      {
        title: 'Actions',
        dataIndex: NewProgramProgramAwardDTO.id,
        width: '15px',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: NewProgramProgramAwardDTO, index: any) => {
          return (this.state.newProgramChangeRequest?.newProgramProgramAwards?.length ?? 0) > 1 && !this.state.newProgramChangeRequest?.isTransferMajorProgram ?
            <Button type="link" onClick={() => this.removeAwardModal(row.id ?? Guid.Empty())}><DeleteOutlined /></Button> :
            null
        },
      }
    ] as any[];
  };

  renderPlannedAwards() {

    return (
      <Space direction='vertical'>
        <Table
          size='small'
          dataSource={this.state.newProgramChangeRequest.newProgramProgramAwards ?? []}
          pagination={false}
          columns={this.getColumnDefinitionsForContactInformation()}
        />
        {this.state.newProgramChangeRequest.isTransferMajorProgram ? null :
          <Button onClick={() => this.openAward('new')} icon={<PlusOutlined />}>
            Add Award
          </Button>
        }
        <Typography.Text strong>Please review each award before continuing. Click on the title of the award to review.</ Typography.Text>
      </Space>
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NewProgramChangeRequestStep9Form