import { Alert, Form, FormInstance, Input, message, Skeleton, Space, Table } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionsApiService from '../../../api/InstitutionsApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetInstitutionCreditTypeMinuteTableDataHandler from '../../../handlerModels/GetInstitutionCreditTypeMinuteTableDataHandler';
import * as SaveInstitutionCreditTypeMinuteHandler from '../../../handlerModels/SaveInstitutionCreditTypeMinuteHandler';
import InstitutionCreditTypeMinuteDTO from '../../../models/InstitutionCreditTypeMinuteDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import { DataTableColumnProps } from '../../datatables/core/DataTable';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface InstitutionCreditTypeMinuteDataTableProps extends BaseFormProps {
  institutionId: string;
  onSave?: () => void;
}

interface InstitutionCreditTypeMinuteDataTableState extends BaseFormState, BaseDataTableState<InstitutionCreditTypeMinuteDTO> {
  updateMinutes: boolean;
  institutionCreditTypeMinutes: InstitutionCreditTypeMinuteDTO[];
}

class InstitutionCreditTypeMinuteForm extends React.Component<InstitutionCreditTypeMinuteDataTableProps, InstitutionCreditTypeMinuteDataTableState> {
  private readonly _formItems = new Map<string, FormItemProps>();
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: InstitutionCreditTypeMinuteDataTableProps) {
    super(props);
    this.state = ({
      updateMinutes: false,
      institutionCreditTypeMinutes: []
    });
  }

  componentDidMount() {
    this.loadCreditMinutes();
  }

  private loadCreditMinutes = () => {
    return InstitutionsApiService.getInstituionCreditTypeMinutesTableData(this.props.institutionId)
      .then((results: GetInstitutionCreditTypeMinuteTableDataHandler.Result) => {
        this.setState({ institutionCreditTypeMinutes: results.creditMinutes ?? [] });
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const formValues = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const minutes: any[] = [];

    for (let i = 0; i < this.state.institutionCreditTypeMinutes.length; i++) {
      const row = this.state.institutionCreditTypeMinutes[i];
      const record = InstitutionCreditTypeMinuteDTO.create(
        {
          creditTypeId: row.creditTypeId,
          institutionId: this.props.institutionId,
          minutes: formValues[row.creditTypeId + 'minutes']
        });
      minutes.push(record);
    }

    const request = SaveInstitutionCreditTypeMinuteHandler.Request.create({
      creditMinutes: minutes
    });

    InstitutionsApiService.saveInstitutionCreditTypeMinute(request)
      .then((result: SaveInstitutionCreditTypeMinuteHandler.Result) => {
        if (result.succeeded) {
          message.success('Saved');

          this.reloadTableData().then(() => {
            if (this.props.onSave) {
              this.props.onSave();
            }
          });
        }
        else {
          message.error(result?.errors.join('\n'));
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error(results.message);
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private reloadTableData = () => {
    return this.loadCreditMinutes().then(() => {
      this.forceUpdate();
    });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Credit Type',
        dataIndex: InstitutionCreditTypeMinuteDTO.creditTypeId,
        render: (data: string, row: any) => {
          return row.creditType?.name;
        },
      },
      {
        title: 'Minutes',
        dataIndex: InstitutionCreditTypeMinuteDTO.minutes,
        render: (data: string, row: any) => {
          const inputTitle = row.creditType?.name + ' Minutes';
          return (
            <FormItem
              {...this._formItems.get(row.creditTypeId + InstitutionCreditTypeMinuteDTO.minutes)}
              initialValue={row.minutes}
              {...ValidationUtil.getValidation(row.creditTypeId + InstitutionCreditTypeMinuteDTO.minutes, this.state.fieldErrors, this.state.submitted)}>
              <Input title={inputTitle} type='number' min={0} max={100000} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} defaultValue={row.minutes} />
            </FormItem>
          )
        },
      }
    ] as DataTableColumnProps<any>[];
  };

  private addRowToFormItems = (creditTypeId: string) => {
    this._formItems
      .set(creditTypeId + InstitutionCreditTypeMinuteDTO.minutes, {
        name: creditTypeId + InstitutionCreditTypeMinuteDTO.minutes
      })
      .set(creditTypeId, {
        name: creditTypeId,
      })
  }


  render() {
    if (this.state.loading && this.props.institutionId == Guid.Empty()) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}>

          <Space direction="vertical">
            {this.renderTableInputs()}
          </Space>

          <Space direction="vertical" style={{ marginTop: 24 }}>
            {this.renderSave()}
          </Space>

        </Form>
      </Space >
    );
  }

  renderTableInputs() {
    const data: any[] = [];

    for (let i = 0; i < this.state.institutionCreditTypeMinutes.length; i++) {
      const row = this.state.institutionCreditTypeMinutes[i];
      this.addRowToFormItems(row.creditTypeId ?? Guid.Empty());
      data.push(row)
    }

    return <Table pagination={false} dataSource={data} columns={this.getColumnDefinitions()}></Table>;
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTITUTION_CREDIT_TYPE_MINUTE]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!FormUtil.canSubmit(this._formRef, this._formItems) || !this.props.isEditing || this.state.loading || this.state.submitting} saving={this.state.submitting} />
            <ResetButton disabled={!this.props.isEditing || this.state.loading || this.state.submitting} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.message) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default InstitutionCreditTypeMinuteForm;
