// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import NoticeOfIntentChangeRequestDTO from './NoticeOfIntentChangeRequestDTO';
import NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO from './NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoticeOfIntentChangeRequestConsultantMeetingDTO extends EntitySelectorDTO { 
	id: string | null;
	noticeOfIntentChangeRequestId: string | null;
	consultantIds: string[];
	noticeOfIntentChangeRequest: NoticeOfIntentChangeRequestDTO | null;
	date: moment.Moment | string | null;
	consultants: NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO[] | null;
}

const NoticeOfIntentChangeRequestConsultantMeetingDTO = {
    className: 'NoticeOfIntentChangeRequestConsultantMeetingDTO',
    create: (initValues?: Partial<NoticeOfIntentChangeRequestConsultantMeetingDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequestId: '00000000-0000-0000-0000-000000000000',
			consultantIds: [],
			noticeOfIntentChangeRequest: null,
			date: null,
			consultants: [],
        },
        initValues);
    },
	id: 'id',
	noticeOfIntentChangeRequestId: 'noticeOfIntentChangeRequestId',
	consultantIds: 'consultantIds',
	noticeOfIntentChangeRequest: 'noticeOfIntentChangeRequest',
	date: 'date',
	consultants: 'consultants',
};

export default NoticeOfIntentChangeRequestConsultantMeetingDTO;