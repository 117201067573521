import { Dispatch } from 'redux';
import InstitutionDTO from '../../models/InstitutionDTO';
import UserSecurity from '../../models/UserSecurity';
import Guid from '../../utils/Guid';
import LookupsUtil from '../../utils/LookupsUtil';
import BaseAction from './BaseAction';


export type InstitutionAction = BaseAction<InstitutionActionTypes, any>;

export enum InstitutionActionTypes {
  INSTITUTION_SELECT = 'INSTITUTION_SELECT',
  INSTITUTION_ID_SELECT = 'INSTITUTION_SELECT',
  INSTITUTION_DEFAULT_SELECT = 'INSTITUTION_DEFAULT_SELECT',
}

export const InstitutionActions = {
  select: InstitutionSelectAction,
  idSelect: InstitutionIdSelectAction,
  defaultSelect: InstitutionDefaultSelectAction
};

function InstitutionSelectAction(institution: InstitutionDTO | null): InstitutionAction {
  return {
    type: InstitutionActionTypes.INSTITUTION_SELECT,
    data: institution,
  }
}

function InstitutionIdSelectAction(institutionId: string) {
  return async (dispatch: Dispatch<any>) => {
    const institution = await LookupsUtil.getOne<InstitutionDTO>(InstitutionDTO.className, institutionId);
    dispatch(InstitutionSelectAction(institution ?? null));
  };
}

function InstitutionDefaultSelectAction(us: UserSecurity | null) {
  return (dispatch: Dispatch<any>) => {
    if (!us?.isStateUser) {
      const institutionId = us?.institutionIds?.length ?? 0 > 0 ? us?.institutionIds[0] : null;
      return dispatch(InstitutionIdSelectAction(institutionId ?? Guid.Empty()));
    }

    return dispatch(InstitutionSelectAction(null));
  };
}
