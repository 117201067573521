// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import AcademicYearStatusTypeDTO from './AcademicYearStatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AcademicYearDTO extends EntitySelectorDTO { 
	id: number;
	programInfoYear: number;
	academicYearStatusTypeId: number;
	academicYearStatusType: AcademicYearStatusTypeDTO | null;
	instructorStartDate: moment.Moment | string | null;
	instructorEndDate: moment.Moment | string | null;
	rppStartDate: moment.Moment | string | null;
	rppEndDate: moment.Moment | string | null;
	deStartDate: moment.Moment | string | null;
	deEndDate: moment.Moment | string | null;
	commentReleaseDate: moment.Moment | string | null;
	finalizationDate: moment.Moment | string | null;
}

const AcademicYearDTO = {
    className: 'AcademicYearDTO',
    create: (initValues?: Partial<AcademicYearDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: 0,
			programInfoYear: 0,
			academicYearStatusTypeId: 0,
			academicYearStatusType: null,
			instructorStartDate: null,
			instructorEndDate: null,
			rppStartDate: null,
			rppEndDate: null,
			deStartDate: null,
			deEndDate: null,
			commentReleaseDate: null,
			finalizationDate: null,
        },
        initValues);
    },
	id: 'id',
	programInfoYear: 'programInfoYear',
	academicYearStatusTypeId: 'academicYearStatusTypeId',
	academicYearStatusType: 'academicYearStatusType',
	instructorStartDate: 'instructorStartDate',
	instructorEndDate: 'instructorEndDate',
	rppStartDate: 'rppStartDate',
	rppEndDate: 'rppEndDate',
	deStartDate: 'deStartDate',
	deEndDate: 'deEndDate',
	commentReleaseDate: 'commentReleaseDate',
	finalizationDate: 'finalizationDate',
};

export default AcademicYearDTO;