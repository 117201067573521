
import moment from 'moment';
import * as React from 'react';

export default class DateUtil {
  public static getCurrentTime(): moment.Moment {
    return moment();
  }

  static isFutureDate = (current: moment.Moment) => {
    return current > moment().endOf('day')
  }

  static getTimeOfDayDisplay() {
    const hour = this.now().hour();
    if (hour < 12 && hour >= 4) {
      return 'Morning';
    }
    else if (hour >= 12 && hour < 17) {
      return 'Afternoon';
    }

    return 'Evening';
  }

  static getCurrentYear() {
    return Number.parseInt(this.now().format('YYYY'));
  }

  static getCurrentMonth() {
    return Number.parseInt(this.now().format('M'));
  }

  static getCurrentDay() {
    return Number.parseInt(this.now().format('D'));
  }

  public static now() {
    // @ts-ignore
    return moment();
  }

  public static getShortMonthString(monthNumber: number | null): string {
    if (!monthNumber) {
      return '';
    }
    return moment.monthsShort(monthNumber - 1);
  }

  public static getLongMonthString(monthNumber: number | null): string {
    if (!monthNumber) {
      return '';
    }
    return moment.months(monthNumber - 1);
  }

  public static getDateStamp() {
    return this.now().format('YYYYMMDD');
  }

  public static shortDateAndTime(value: string | moment.Moment | null | undefined) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return date.format('M/D/YYYY h:mm a');
  }

  public static shortDate(value: string | moment.Moment | null | undefined, format?: string) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format(format || 'M/D/YYYY')}</span>;
  }

  public static shortTime(value: string | moment.Moment) {
    if (!value) {
      return;
    }

    let date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      date = moment(value, 'h:mm:ss a');

      if (!date.isValid()) {
        return '';
      }
    }

    return <span>{date.format('h:mm a')}</span>;
  }

  public static longDate(value: string | moment.Moment | null | undefined) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format('MMMM D, YYYY')}</span>;
  }

  public static longDateString(value: string | moment.Moment | null | undefined) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return date.format('MMMM D, YYYY');
  }

  public static longMonthAndDay(value: string | moment.Moment) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format('MMMM D')}</span>;
  }

  public static mediumDate(value: string | moment.Moment) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format('MMMM D, YYYY')}</span>;
  }

  public static dateAndTime(value: string | moment.Moment | undefined) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format('M/D/YYYY h:mm a')}</span>;
  }

  static shortFormat(date: string | moment.Moment | undefined | null) {
    return moment(date).format('M/D/YYYY');
  }

  public static dateRangeShortFormat(beginDate: string | moment.Moment | undefined | null, endDate: string | moment.Moment | undefined | null) {
    return DateUtil.shortFormat(beginDate) + ' - ' + DateUtil.shortFormat(endDate);
  }

  public static isWithinRange(beginDate: string | moment.Moment | undefined, endDate: string | moment.Moment | undefined) {
    if (moment(new Date()) >= moment(beginDate) && moment(new Date()) <= moment(endDate)) {
      return true;
    }

    return false
  }

  public static isBeforeRange(beginDate: string | moment.Moment | undefined) {
    if (moment(new Date()) < moment(beginDate)) {
      return true;
    }

    return false
  }

  public static isBefore(activeDate: string | moment.Moment | undefined, beginDate: string | moment.Moment | undefined) {
    if (moment(activeDate) < moment(beginDate)) {
      return true;
    }

    return false
  }

  public static isAfterRange(endDate: string | moment.Moment | undefined) {
    if (moment(new Date()) > moment(endDate)) {
      return true;
    }

    return false
  }

  public static isExpired(requestedDate: moment.Moment) {
    if (parseInt(this.now().year().toString()) > parseInt(moment(requestedDate).year().toString()) + 1) {
      return true;
    }

    return false;
  }
}
