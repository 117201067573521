import { DeleteOutlined, DownloadOutlined, FormOutlined } from '@ant-design/icons';
import { Alert, Button, message, Modal } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import SecondaryProgramCourseApiService from '../../../api/SecondaryProgramCourseApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import SecondaryCourseDTO from '../../../models/SecondaryCourseDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import SecondaryProgramCoursesImporter from '../../secondary/SecondaryProgramCoursesImporter';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import * as DeleteSecondaryProgramCourseHandler from '../../../handlerModels/DeleteSecondaryProgramCourseHandler';
import SecondaryProgramCourseDTO from '../../../models/SecondaryProgramCourseDTO';
import SecondaryProgramCourseDetailsModal from '../../modals/SecondaryProgramCourseDetailsModal';
import TableFilterUtil from '../../../utils/TableFilterUtil';
import Routes from '../../../config/Routes';

interface SecondaryProgramCoursesDataTableProps {
  academicYear: number;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  programId?: string;
  secondaryProgramId?: number;
  isPublic?: boolean;
  errors?: string[];
  fromAcademicYearDataReview?: boolean;

}

function SecondaryProgramCoursesDataTable(props: SecondaryProgramCoursesDataTableProps) {
  let _dataTable: DataTable<SecondaryCourseDTO> | undefined = undefined;
  const _secondaryProgramCourseImporterRef = React.createRef<SecondaryProgramCoursesImporter>();
  const [loading, setLoading] = useState(false);

  const [secondaryProgramCourseId, setSecondaryProgramCourseId] = useState<string | null>();
  const [errors, setErrors] = useState(props.errors ?? []);

  const getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Program',
        dataIndex: SecondaryProgramCourseDTO.programIdentifier,
        sorter: true,
        width: 350,
        filterType: FilterType.Text,
        render: (data: string, row: SecondaryProgramCourseDTO) => {
          return row?.program?.secondaryCipNumber?.display;
        }
      },
      {
        title: 'Course',
        dataIndex: SecondaryProgramCourseDTO.courseIdentifier,
        sorter: true,
        width: 350,
        filterType: FilterType.Text,
        render: (data: string, row: SecondaryProgramCourseDTO) => {
          return row?.course?.display;
        },
      },
      {
        title: 'Is Offer Teach',
        dataIndex: SecondaryProgramCourseDTO.isOfferTeach,
        sorter: true,
        width: 50,
        render: (data: string, row: SecondaryProgramCourseDTO) => {
          return row.isOfferTeach == true ? 'Yes' : 'No';
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: TableFilterUtil.GetTrueOrFalse(),
        filterDropdownVisible: true
      },
      {
        title: 'Core Course',
        dataIndex: SecondaryProgramCourseDTO.isCoreCourse,
        sorter: true,
        width: 50,
        render: (data: string, row: SecondaryProgramCourseDTO) => {
          return row.isCoreCourse == true ? 'Yes' : 'No';
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: TableFilterUtil.GetTrueOrFalse(),
      },
      {
        title: 'Actions',
        align: 'center',
        dataIndex: SecondaryProgramCourseDTO.secondaryProgramCourseId,
        sorter: false,
        render: (data: string, row: SecondaryProgramCourseDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => openSecondaryProgramCourseDetail(row)}><FormOutlined /></Button>
              <Button type="link" onClick={() => promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 50
      },
    ] as DataTableColumnProps<any>[];

    return columns;
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openSecondaryProgramCourseDetail = (record: SecondaryProgramCourseDTO) => {
    if (record.id) {
      setSecondaryProgramCourseId(record.id);
    }
  }

  const newSecondaryProgramCourse = () => {
    setSecondaryProgramCourseId(Guid.Empty());
  }

  const closeSecondaryProgramCourseDetail = () => {
    setSecondaryProgramCourseId(null);
  }

  const promptConfirmRemove = (secondaryProgramCourseId: string | null) => {
    if (secondaryProgramCourseId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this Program Course?',
      okText: 'Delete',
      onOk: () => deleteSecondaryProgramCourse(secondaryProgramCourseId, props.academicYear),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteSecondaryProgramCourse = (secondaryProgramCourseId: string, academicYearId: number) => {
    const request = DeleteSecondaryProgramCourseHandler.Request.create({
      secondaryProgramCourseId: secondaryProgramCourseId,
      academicYearId: academicYearId
    });

    SecondaryProgramCourseApiService.deleteSecondaryProgramCourse(request)
      .then((result: DeleteSecondaryProgramCourseHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Program Course was removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Program Course could not be removed.');
      });
  }

  const openSecondaryProgramCoursesModal = () => {
    setLoading(true);
    Modal.confirm({
      title: 'Import',
      content: <SecondaryProgramCoursesImporter
        ref={_secondaryProgramCourseImporterRef}
        academicYear={props.academicYear}
        refreshDataTable={_dataTable}
        isInModal={true}
        onSubmit={modalSubmit} programId={0} />,
      onCancel: () => { setLoading(false); },
      onOk: () => _secondaryProgramCourseImporterRef.current?.confirmImport(),
      width: 500
    });
  }

  const modalSubmit = (errors?: string[]) => {
    setLoading(false);
    setErrors(errors ?? []);
  }

  const downloadTemplate = () => {
    window.open(Routes.generateFull(Routes.SECONDARY_PROGRAM_COURSES_IMPORT_TEMPLATE, {}, {}), '_blank')
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_COURSE]) && !props.programId) {
    actionButtons.push(DataTableButtonUtil.Default('New Program Course', () => newSecondaryProgramCourse()));
  }
  if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR]) && !props.programId && props.fromAcademicYearDataReview) {
    actionButtons.push(DataTableButtonUtil.Default('Re-import Program Courses', () => openSecondaryProgramCoursesModal(), undefined, loading));
  }
  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_COURSE]) && props.fromAcademicYearDataReview) {
    actionButtons.push(DataTableButtonUtil.Default('Import Template', () => downloadTemplate(), <DownloadOutlined />, loading));
  }

  const renderModal = () => {
    if (secondaryProgramCourseId) {
      return <SecondaryProgramCourseDetailsModal
        onCancel={closeSecondaryProgramCourseDetail}
        visible={true}
        secondaryProgramCourseId={secondaryProgramCourseId}
        academicYear={props.academicYear}
        onSave={closeSecondaryProgramCourseDetail} />
    }
  }

  const renderErrors = () => {
    if (errors && errors.length > 0) {
      return <Alert showIcon={true} type='error' description={renderErrorMessages()} message='Error Importing' />
    }
  }

  const renderErrorMessages = () => {
    if (errors != undefined && errors.length > 0) {
      return <>{errors.map((e: any) => <p key={e}>{e}</p>)}</>
    }
  }

  return (
    <>
      {renderErrors()}
      {renderModal()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => SecondaryProgramCourseApiService.getSecondaryProgramCourseTableData(requestState, props.academicYear)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'secondary_program_course_list' }} />
    </>
  );
}

export default SecondaryProgramCoursesDataTable;
