import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RacialEthnicGroupApiService from '../../../api/RacialEthnicGroupApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import RacialEthnicGroupDetailsForm from '../../../components/forms/RacialEthnicGroupDetailsForm';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteRacialEthnicGroupHandler from '../../../handlerModels/DeleteRacialEthnicGroupHandler';
import * as GetRacialEthnicGroupDetailsHandler from '../../../handlerModels/GetRacialEthnicGroupDetailsHandler';
import RacialEthnicGroupDTO from '../../../models/RacialEthnicGroupDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ParameterUtil from '../../../utils/ParameterUtil';

interface RacialEthnicGroupDetailsPageState extends BaseFormState {
  racialEthnicGroupId: number;
  racialEthnicGroup?: RacialEthnicGroupDTO;
}

interface RacialEthnicGroupDetailsPageProps {
}

class RacialEthnicGroupDetailsPage extends React.Component<RacialEthnicGroupDetailsPageProps & RouteComponentProps<RouteObject>, RacialEthnicGroupDetailsPageState> {
  private readonly _detailsRef = React.createRef<RacialEthnicGroupDetailsForm>();

  constructor(props: RacialEthnicGroupDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      racialEthnicGroupId: 0
    };
  }

  componentDidMount() {
    const racialEthnicGroupId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (racialEthnicGroupId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const racialEthnicGroupIdNumber = Number.parseInt(racialEthnicGroupId);
      this.loadRacialEthnicGroup(racialEthnicGroupIdNumber);
    }
  }

  private loadRacialEthnicGroup = (racialEthnicGroupId: number) => {
    this.setState({ racialEthnicGroupId: racialEthnicGroupId });

    RacialEthnicGroupApiService.getRacialEthnicGroupDetails(racialEthnicGroupId)
      .then((results: GetRacialEthnicGroupDetailsHandler.Result) => {
        if (results.racialEthnicGroup) {
          this.setState({ racialEthnicGroup: results.racialEthnicGroup });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._detailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private specialEmphasisDetailsSaved = (racialEthnicGroupId: number) => {
    this.setState({ isEditing: false });
    if (racialEthnicGroupId != this.state.racialEthnicGroupId) {
      HistoryUtil.replace(Routes.generate(Routes.RACIAL_ETHNIC_GROUP_DETAILS, { id: racialEthnicGroupId }));
    }
    else {
      this.loadRacialEthnicGroup(racialEthnicGroupId);
    }
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Special Emphasis?',
      okText: 'Delete',
      onOk: () => this.deleteRacialEthnicGroup(id)
    });
  }

  private deleteRacialEthnicGroup = (racialEthnicGroupId: number) => {
    this.setState({ submitting: true });

    const request = DeleteRacialEthnicGroupHandler.Request.create({
      racialEthnicGroupId: racialEthnicGroupId,
    });

    RacialEthnicGroupApiService.deleteRacialEthnicGroup(request)
      .then((result: DeleteRacialEthnicGroupHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Item has been removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Special emphasis could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToRacialEthnicGroups();
      });
  }

  private goToRacialEthnicGroups = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.racialEthnicGroupId == 0 ? 'New Racial/Ethnic Group' : this.state.racialEthnicGroup?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToRacialEthnicGroups}
          breadcrumbs={Breadcrumbs.racialEthnicGroupDetails(title, this.state.racialEthnicGroupId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.racialEthnicGroupId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_RACIAL_EHTNIC_GROUPS]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <RacialEthnicGroupDetailsForm
          ref={this._detailsRef}
          racialEthnicGroupId={this.state.racialEthnicGroupId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.specialEmphasisDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Racial/Ethnic Group' key={this.state.racialEthnicGroupId} onClick={() => this.deleteConfirmation(this.state.racialEthnicGroupId)}  >
          Delete Racial/Ethnic Group
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_RACIAL_EHTNIC_GROUPS]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(RacialEthnicGroupDetailsPage);
