// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionLocationDataTableDTO extends EntitySelectorDTO { 
	id: string | null;
	institutionId: string | null;
	primary: boolean;
	name: string | null;
	address: string | null;
	phone: string | null;
	fax: string | null;
}

const InstitutionLocationDataTableDTO = {
    className: 'InstitutionLocationDataTableDTO',
    create: (initValues?: Partial<InstitutionLocationDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			institutionId: '00000000-0000-0000-0000-000000000000',
			primary: false,
			name: "",
			address: "",
			phone: "",
			fax: "",
        },
        initValues);
    },
	id: 'id',
	institutionId: 'institutionId',
	primary: 'primary',
	name: 'name',
	address: 'address',
	phone: 'phone',
	fax: 'fax',
};

export default InstitutionLocationDataTableDTO;