// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class SecondaryProgramStatusType {
    public static readonly APPROVED: number = 1;
    public static readonly DENIED: number = 2;
    public static readonly CONDITIONAL: number = 3;
    public static readonly EXTENSION: number = 4;
    public static readonly NEWPROGRAM: number = 5;
    public static readonly PENDING: number = 6;
}
