import { Alert, Input, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ContactApiService from '../../../api/ContactApiService';
import Routes from '../../../config/Routes';
import * as SaveContactDetailHandler from '../../../handlerModels/SaveContactDetailHandler';
import ContactDTO from '../../../models/ContactDTO';
import ContactTypeDTO from '../../../models/ContactTypeDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FileUploadUtil from '../../../utils/FileUploadUtil';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import FileUpload from '../../FileUpload';

interface AddContactFormState extends BaseFormState {
  contactType: ContactTypeDTO[];
  institutions: InstitutionDTO[];
  contact: ContactDTO;
}

interface AddContactFormProps extends BaseFormProps {
  onSuccess?: () => void;
  contact: ContactDTO;
  institutionId: string;
}

class AddContactForm extends React.Component<AddContactFormProps, AddContactFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(ContactDTO.firstName, {
      required: true,
      name: ContactDTO.firstName,
      label: 'First Name',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(ContactDTO.lastName, {
      required: true,
      name: ContactDTO.lastName,
      label: 'Last Name',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(ContactDTO.title, {
      name: ContactDTO.title,
      label: 'Title',
    })
    .set(ContactDTO.email, {
      name: ContactDTO.email,
      label: 'Email',
    })
    .set(ContactDTO.phone, {
      name: ContactDTO.phone,
      label: 'Phone',
      rules: [
        ValidationRuleUtil.phoneNumberValidator(),
      ],
    })
    .set(ContactDTO.contactTypeId, {
      required: true,
      name: ContactDTO.contactTypeId,
      label: 'Contact Type',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(ContactDTO.institutionId, {
      name: ContactDTO.institutionId,
      label: 'Community College',
    })
    .set(ContactDTO.signatureAzureBlobFile, {
      name: ContactDTO.signatureAzureBlobFile,
      label: 'File',
    });

  constructor(props: AddContactFormProps) {
    super(props);

    this.state = {
      contact: ContactDTO.create({ institutionId: null }),
      contactType: [],
      institutions: []
    };
  }

  componentDidMount() {
    this.fetchData();

    const contact = this.props.contact;
    if (contact.id == Guid.Empty()) {
      contact.institutionId = null;
    }

    this.setState({ contact: contact }, () => this.reset());
  }

  componentDidUpdate(prevProps: AddContactFormProps) {
    if (this.props.contact && this.props.contact != prevProps.contact) {
      this.fetchData();
      const contact = this.props.contact;
      this.setState({ contact: contact }, () => this.reset());
    }
  }

  private fetchData = () => {
    const loaders = [] as any[];
    if (this.state.contactType.length == 0) {
      loaders.push(this.loadContactTypes());
    }

    if (this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }
    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });

  }

  private loadContactTypes = () => {
    return LookupsUtil.getAll<ContactTypeDTO>(ContactTypeDTO.className)
      .then((results: ContactTypeDTO[]) => {
        if (results) {
          this.setState({ contactType: results });
        }
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results });
        }
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  public submit = () => {
    this.handleSubmit();
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }


  private handleSubmit = () => {
    this.setState({ submitting: true });

    if (!FormUtil.canSubmit(this._formRef, this._formItems)) {
      message.error('Please fill out all required fields.');
      this.setState({ loading: false, submitting: false });
      return;
    }

    const contact = ContactDTO.create(this._formRef.current?.getFieldsValue()) as ContactDTO;
    contact.id = this.props.contact.id ?? Guid.Empty();
    if (this.props.institutionId && this.props.institutionId != Guid.Empty()) {
      contact.institutionId = this.props.institutionId;
    }


    const contactRequest = SaveContactDetailHandler.Request.create({
      contact: contact
    });

    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : SaveContactDetailHandler.Request.create() as SaveContactDetailHandler.Request;

    const request = FileUploadUtil.attachFilesToModel('saveContactDetail', contactRequest, SaveContactDetailHandler.Request.additionalFile, model.signatureAzureBlobFile?.newFiles);

    ContactApiService.saveContactDetails(request)
      .then((result: SaveContactDetailHandler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSuccess) {
            this.props.onSuccess();
            this.reset();
          }

          this.setState({
            error: false,
            submitted: false,
            fieldErrors: {}
          });
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Contact could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Contact could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  render() {

    const existingFiles = [];
    existingFiles.push({
      uid: this.props.contact.signatureAzureBlobFileId,
      url: Routes.generateDownload(this.props.contact.signatureAzureBlobFileId ?? Guid.Empty()),
      name: this.props.contact.signatureAzureBlobFile?.fileName
    });

    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          initialValues={this.state.contact}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(ContactDTO.firstName)}
            {...ValidationUtil.getValidation(ContactDTO.firstName, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(ContactDTO.lastName)}
            {...ValidationUtil.getValidation(ContactDTO.lastName, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(ContactDTO.title)}
            {...ValidationUtil.getValidation(ContactDTO.title, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(ContactDTO.contactTypeId)}
            {...ValidationUtil.getValidation(ContactDTO.contactTypeId, this.state.fieldErrors, this.state.submitted)}>
            <Select disabled={this.state.loading || this.state.submitting} >{this.state.contactType.map(y => this.renderContactType(y))} </Select>
          </FormItem>

          {
            this.props.institutionId && this.props.institutionId != Guid.Empty() ?
              null :
              <FormItem
                {...this._formItems.get(ContactDTO.institutionId)}
                {...ValidationUtil.getValidation(ContactDTO.institutionId, this.state.fieldErrors, this.state.submitted)}>
                <Select disabled={this.state.loading || this.state.submitting} allowClear={true}>{this.state.institutions.map(y => this.renderInstitution(y))} </Select>
              </FormItem>
          }

          <FormItem
            {...this._formItems.get(ContactDTO.email)}
            {...ValidationUtil.getValidation(ContactDTO.email, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(ContactDTO.phone)}
            {...ValidationUtil.getValidation(ContactDTO.phone, this.state.fieldErrors, this.state.submitted)}>
            <Input placeholder='###-###-#####' disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(ContactDTO.signatureAzureBlobFile)}
            {...ValidationUtil.getValidation(ContactDTO.signatureAzureBlobFile, this.state.fieldErrors, this.state.submitted)}>
            <FileUpload cropImage={true} existingList={existingFiles} maxCount={1} />
          </FormItem>

        </Form >
      </Space >
    );
  }

  renderContactType(x: ContactTypeDTO) {
    if (x.id) {
      return <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>
    }
  }

  renderInstitution(x: InstitutionDTO) {
    if (x.id) {
      return <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AddContactForm;
