import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, AutoCompleteProps, Input } from 'antd';
import * as React from 'react';

export interface AutoCompleteInputOption {
  value: any;
  display: any;
}

interface AutoCompleteInputState {
  value?: any | null;
  selectedOption?: AutoCompleteInputOption | null;
}

interface AutoCompleteInputProps extends AutoCompleteProps {
  value?: any | null;
  options: AutoCompleteInputOption[];
  initialValue?: any | null;
  disabled?: boolean;
  searching: boolean;
  onChange?: (value: any) => void;
  onSearch?: (value: string) => void;
  onSelect?: (value: any) => void;
  onClear?: () => void;
}

class AutoCompleteInput extends React.Component<AutoCompleteInputProps, AutoCompleteInputState> {
  constructor(props: AutoCompleteInputProps) {
    super(props);

    this.state = {
    }
  }

  private handleSearch = (value: string) => {
    if (this.props.onSearch) {
      this.props.onSearch(value);
    }
  }

  private handleSelect = (e: any) => {
    const selectedOption = this.props.options?.filter(x => x.display == e)[0];

    this.setState({
      value: selectedOption.value,
      selectedOption: selectedOption
    })

    if (this.props.onChange) {
      this.props.onChange(selectedOption.value);
    }

    if (this.props.onSelect) {
      this.props.onSelect(selectedOption.value);
    }
  }

  public clear = () => {
    this.setState({
      selectedOption: null,
      value: null,
    });
  }

  componentDidMount() {
    if (this.props.value != undefined) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidUpdate(prevProps: AutoCompleteInputProps) {
    if (this.props.value != undefined && this.props.value != prevProps.value) {
      this.setState({ value: this.props.value, selectedOption: null });
    }

    const selected = this.props.options?.filter(x => x.value == this.props.value)[0];

    if (selected != undefined && selected != null) {
      if (this.state.selectedOption == null || this.state.selectedOption == undefined) {
        this.handleSelect(selected.display);
      }
    }
  }

  render() {
    let options = undefined;
    if (this.props.options.length > 0) {
      options = this.props.options.map((x: AutoCompleteInputOption) => {
        return { key: x.display, value: x.display, label: x.display }
      });
    }
    else if (this.state.selectedOption) {
      options = [{ key: this.state.selectedOption.display, value: this.state.selectedOption.display, label: this.state.selectedOption.display }];
    }

    return (
      <Input.Group>
        <AutoComplete allowClear={true} onClear={this.clear} {...this.props} showSearch={true} options={options} defaultValue={this.props.initialValue} onSelect={this.handleSelect} onSearch={this.handleSearch} value={this.state.selectedOption?.display} style={!this.props.searching ? { width: '100%' } : { width: '95%' }} />
        {this.props.searching ? <LoadingOutlined style={{ width: '5%', alignSelf: 'center' }} /> : null}
      </Input.Group>
    );
  }
}

export default AutoCompleteInput;
