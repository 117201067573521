import { Alert, Form, InputNumber, message, Skeleton, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ChangeRequestTypeApiService from '../../../api/ChangeRequestTypeApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import '../../../extensions/StringExtensions';
import * as GetChangeRequestTypeDetailsHandler from '../../../handlerModels/GetChangeRequestTypeHandler';
import * as SaveChangeRequestTypeHandler from '../../../handlerModels/SaveChangeRequestTypeHandler';
import ChangeRequestTypeDTO from '../../../models/ChangeRequestTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import ValueLabel from '../../general/ValueLabel';

interface ChangeRequestTypeDetailsFormState extends BaseFormState {
  changeRequestType: ChangeRequestTypeDTO;
}

interface ChangeRequestTypeDetailsFormProps extends BaseFormProps {
  changeRequestTypeId: number;
  onSave?: () => void;
}

class ChangeRequestTypeDetailsForm extends React.Component<ChangeRequestTypeDetailsFormProps, ChangeRequestTypeDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(ChangeRequestTypeDTO.name, {
        label: 'Name',
        name: ChangeRequestTypeDTO.name
      })
      .set(ChangeRequestTypeDTO.code, {
        label: 'Code',
        name: ChangeRequestTypeDTO.code
      })
      .set(ChangeRequestTypeDTO.daysValid, {
        label: 'Days Valid',
        name: ChangeRequestTypeDTO.daysValid
      })
      .set(ChangeRequestTypeDTO.cutoffWindow, {
        required: true,
        label: 'Cutoff Window',
        extra: 'Minimum number of days before the start a term that a submission may be submitted.',
        name: ChangeRequestTypeDTO.cutoffWindow,
        rules: [
          ValidationRuleUtil.required(),
        ]
      });
  }

  constructor(props: ChangeRequestTypeDetailsFormProps) {
    super(props);

    this.state = {
      changeRequestType: ChangeRequestTypeDTO.create()
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: ChangeRequestTypeDetailsFormProps) {
    if (this.props.changeRequestTypeId != prevProps.changeRequestTypeId) {
      this.fetchData();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private fetchData = () => {
    this.setState({ loading: true });

    ChangeRequestTypeApiService.getDetails(this.props.changeRequestTypeId)
      .then((result: GetChangeRequestTypeDetailsHandler.Result) => {
        this.setState({ changeRequestType: result.changeRequestType ?? ChangeRequestTypeDTO.create() });
        this.resetForm();
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const form = ChangeRequestTypeDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    const request = SaveChangeRequestTypeHandler.Request.create({
      changeRequestType: form,
      changeRequestTypeId: this.props.changeRequestTypeId
    });

    ChangeRequestTypeApiService.save(request)
      .then((result: SaveChangeRequestTypeHandler.Result | null) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({
            changeRequestType: result.changeRequestType ?? ChangeRequestTypeDTO.create(),
            submitted: false,
            error: false
          });

          this._formRef?.current?.setFieldsValue(result.changeRequestType);
          LookupsUtil.invalidateCache(ChangeRequestTypeDTO.className);

          if (this.props.onSave) {
            this.resetForm();
            this.props.onSave();
          }

          message.success('Saved');
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });

          message.error('Error Saving');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.changeRequestType}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>

          <Form.Item {...this.getFormItems().get(ChangeRequestTypeDTO.name)} >
            <ValueLabel />
          </Form.Item>

          <Form.Item {...this.getFormItems().get(ChangeRequestTypeDTO.code)} >
            <ValueLabel />
          </Form.Item>

          <Form.Item {...this.getFormItems().get(ChangeRequestTypeDTO.daysValid)}>
            <InputNumber min={1} disabled={!this.props.isEditing} />
          </Form.Item>

          <Form.Item {...this.getFormItems().get(ChangeRequestTypeDTO.cutoffWindow)}>
            <InputNumber min={0} disabled={!this.props.isEditing} />
          </Form.Item>

          {this.renderSave()}
        </Form>
      </Space >
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_WORKFLOW]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default ChangeRequestTypeDetailsForm;
