import CurrentUser from './CurrentUser';

export default class AuthorizationUtil {
  static isAuthorized(validFeatureFlags: string[], institution?: string | null): boolean {
    const user = CurrentUser.Get();

    for (let i = 0; i < validFeatureFlags.length; i++) {
      if (user?.hasFeatureFlag(validFeatureFlags[i])) {
        if (institution != null || institution != undefined) {
          // TODO need to make institution specific
          return true
        }

        return true;
      }
    }

    return false;
  }
}
