import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NoticeOfIntentChangeRequestWorkForceLinkDataTable from '../../../../components/datatables/postSecondary/NoticeOfIntentChangeRequestWorkForceLinkDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface NoticeOfIntentChangeRequestWorkForceLinkListProps {
}

interface NoticeOfIntentChangeRequestWorkForceLinkListState {
}

class NoticeOfIntentChangeRequestWorkForceLinkListPage extends React.Component<NoticeOfIntentChangeRequestWorkForceLinkListProps & RouteComponentProps<RouteObject>, NoticeOfIntentChangeRequestWorkForceLinkListState> {
  constructor(props: NoticeOfIntentChangeRequestWorkForceLinkListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.push(Routes.generate(Routes.CONFIGURATIONS));
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Workforce Informational Links" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.NoticeOfIntentChangeRequestWorkForceLinkList()} />

        <Card >
          <NoticeOfIntentChangeRequestWorkForceLinkDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(NoticeOfIntentChangeRequestWorkForceLinkListPage);
