import { Select, SelectProps } from 'antd';
import * as React from 'react';
import ValueLabel from '../general/ValueLabel';

interface DropdownProps extends SelectProps<any> {
  readOnly?: boolean;
  selectIdentifier?: string;
}

function Dropdown(props: React.PropsWithChildren<DropdownProps>) {
  const _selectRef = React.createRef<any>();

  const [openSelect, setOpenSelect] = React.useState(false as boolean | undefined);

  const handleChanged = (value: any, options: any[number] | any) => {
    if (props.onChange) {
      props.onChange(value, options)
    }
  }

  const handleClick = () => {
    setOpenSelect(!openSelect);
  }

  const onKeyBoardInput = (t: any) => {
    if (!openSelect &&
      t.code != '' &&
      t.code != 'Escape' &&
      t.code != 'Tab' &&
      t.code != 'Backspace') {
      setOpenSelect(true);
    }
    else if ((t.code == 'Enter' && openSelect && props.mode != 'multiple') || t.code == 'Escape') {
      setOpenSelect(false);
    }
  }

  const handleBlur = () => {
    setOpenSelect(false)
  }

  if (props.readOnly) {
    return (
      <ValueLabel text={props.value} />);
  }

  return (
    <Select
      ref={_selectRef}
      {...props}
      open={openSelect}
      onInputKeyDown={(e: any) => onKeyBoardInput(e)}
      onClick={handleClick}
      onBlur={handleBlur}
      onChange={handleChanged}
    >
      {props.children}
    </Select>
  );
}

export default Dropdown;
