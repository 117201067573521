import { RightOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearOverview from '../../components/forms/secondary/AcademicYearOverview';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import InstitutionDTO from '../../models/InstitutionDTO';
import HeaderPortal from '../../portals/HeaderPortal';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import HistoryUtil from '../../utils/HistoryUtil';

interface SelfStudiesPageProps {
  institution: InstitutionDTO | null;
}

interface SelfStudiesPageState {
  loading: boolean;
}

class SelfStudiesPage extends React.Component<SelfStudiesPageProps & RouteComponentProps<RouteObject>, SelfStudiesPageState> {
  constructor(props: SelfStudiesPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  private goToAllSelfStudies = () => {
    HistoryUtil.replace(Routes.generate(Routes.ALL_ACADEMIC_YEARS));
  }

  private goBack = () => {
    HistoryUtil.replace(Routes.generate(Routes.SECONDARY_HOME));
  }

  render() {
    return (
      <Content >
        <HeaderPortal
          title="Program Review Years"
          onBack={this.goBack}
          breadcrumbs={Breadcrumbs.academicYears()} />

        <Space direction='vertical' size='large'>
          <Card title="Program Review Years" extra={<Button type='link' onClick={() => this.goToAllSelfStudies()}><RightOutlined />View All</Button>}>
            <AcademicYearOverview institution={this.props.institution} />
          </Card>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(SelfStudiesPage));
