import { Button, Col, message, Row, Space, Statistic, Steps } from 'antd';
import moment from 'moment';
import * as React from 'react';
import AcademicYearApiService from '../../../../api/AcademicYearApiService';
import { ColumnWidths } from '../../../../config/ColumnWidths';
import AcademicYearStatusType from '../../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../../consts/FeatureFlag';
import SelfStudyResponseType from '../../../../consts/SelfStudyResponseType';
import AcademicYearDTO from '../../../../models/AcademicYearDTO';
import BaseFormProps from '../../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../../utils/AuthorizationUtil';
import DateUtil from '../../../../utils/DateTimeUtil';
import * as SendSelfStudyReminderEmailHandler from '../../../../handlerModels/SendSelfStudyReminderEmailHandler';
import AcademicYearSelfStudyStatsDTO from '../../../../models/AcademicYearSelfStudyStatsDTO';
import { WarningFilled } from '@ant-design/icons';

interface DistrictResponseStepProps extends BaseFormProps {
  academicYear: AcademicYearDTO;
  compactView?: boolean;
  selfStudyStats: AcademicYearSelfStudyStatsDTO | null;
}

const finishedStatusTypes = [
  AcademicYearStatusType.CLOSED,
  AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS,
]

export default function DistrictResponseStep(props: DistrictResponseStepProps) {
  const active = props.academicYear?.academicYearStatusTypeId === AcademicYearStatusType.REVIEWSELFSTUDYPROGRAMS && DateUtil.isAfterRange(props.academicYear.instructorStartDate ?? '');
  const finished = finishedStatusTypes.indexOf(props.academicYear.academicYearStatusTypeId) >= 0;

  const notSubmittedCount = (props.selfStudyStats?.instructorNotStarted ?? 0) + (props.selfStudyStats?.instructorInProgress ?? 0) + (props.selfStudyStats?.instructorPendingApproval ?? 0);
  const error = DateUtil.isAfterRange(props.academicYear.instructorEndDate ?? '') && notSubmittedCount > 0;

  const dateRange = DateUtil.dateRangeShortFormat(props.academicYear.instructorStartDate, props.academicYear.instructorEndDate);

  const [sending, setSending] = React.useState(false);

  const handleSendReminderEmails = () => {
    setSending(true);

    const request = SendSelfStudyReminderEmailHandler.Request.create(
      {
        academicYear: props.academicYear,
        selfStudyResponseTypeId: SelfStudyResponseType.INSTRUCTOR
      });

    AcademicYearApiService.sendSelfStudyReminderEmails(request)
      .then((x: SendSelfStudyReminderEmailHandler.Result) => {
        if (x.succeeded) {
          message.success('Reminder Emails Sent!');
        }
        else {
          message.error('Failed to Send Reminder Emails');
        }
      }).catch(() => {
        message.error('Failed to Send Reminder Emails');
      }).finally(() => {
        setSending(false);
      });
  }

  const renderContent = () => {
    if (!active || props.compactView === true || DateUtil.isBeforeRange(props.academicYear.instructorStartDate ?? moment())) {
      return null;
    }

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT_RESPONSE, FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE])) {
      return <Space direction='vertical'>
        <Row>
          <Col {...ColumnWidths.ONE_QUARTER}>
            <Statistic title="Not Started" value={props.selfStudyStats?.instructorNotStarted} />
          </Col>
          <Col {...ColumnWidths.ONE_QUARTER}>
            <Statistic title="In Progress" value={props.selfStudyStats?.instructorInProgress} />
          </Col>
          <Col {...ColumnWidths.ONE_QUARTER}>
            <Statistic title="Pending Approval" value={props.selfStudyStats?.instructorPendingApproval} />
          </Col>
          <Col {...ColumnWidths.ONE_QUARTER}>
            <Statistic title="Completed" value={props.selfStudyStats?.instructorCompleted} />
          </Col>
        </Row>
        {renderReminderButton()}
      </Space>;
    }

    return null
  }

  const renderReminderButton = () => {
    if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR]) && DateUtil.isAfterRange(props.academicYear.instructorEndDate ?? moment())) {
      return <Button type={error ? 'primary' : 'default'} danger={error} loading={sending} onClick={handleSendReminderEmails}>Send Reminder Emails</Button>;
    }

    return null;
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT_RESPONSE, FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE])) {
    return <Steps.Step title='District Response Window'
      className='ant-title-warning'
      icon={error ? <WarningFilled /> : null}
      status={error ? 'error' : finished ? 'finish' : active ? 'process' : 'wait'}
      subTitle={dateRange}
      description={renderContent()} />;
  }

  return null;
}