import { MoreOutlined } from '@ant-design/icons';
import * as antd from 'antd';
import { Card, Skeleton, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import SecondaryProgramApiService from '../../../api/SecondaryProgramApiService';
import SecondaryProgramCourseApiService from '../../../api/SecondaryProgramCourseApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import SecondaryCoursesDataTable from '../../../components/datatables/secondary/SecondaryCoursesDataTable';
import SecondaryProgramHistoryDataTable from '../../../components/datatables/secondary/SecondaryProgramHistoryDataTable';
import SecondaryProgramDetailsForm from '../../../components/forms/secondary/SecondaryProgramDetailsForm';
import ValueLabel from '../../../components/general/ValueLabel';
import Dropdown from '../../../components/inputs/Dropdown';
import SecondaryProgramCoursesImporter from '../../../components/secondary/SecondaryProgramCoursesImporter';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteSecondaryProgramHandler from '../../../handlerModels/DeleteSecondaryProgramHandler';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import * as GetSecondaryProgramCoursesHandler from '../../../handlerModels/GetSecondaryProgramCoursesHandler';
import * as GetSecondaryProgramDetailsHandler from '../../../handlerModels/GetSecondaryProgramDetailsHandler';
import * as GetSecondaryProgramsHandler from '../../../handlerModels/GetSecondaryProgramsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import SecondaryProgramCourseDTO from '../../../models/SecondaryProgramCourseDTO';
import SecondaryProgramDTO from '../../../models/SecondaryProgramDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import CurrentUser from '../../../utils/CurrentUser';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';
import * as GetRegionalPlanningPartnersHandler from '../../../handlerModels/GetRegionalPlanningPartnersHandler';

interface SecondaryProgramDetailsPageState extends BaseFormState {
  activeTab: string;
  configuration: boolean;
  secondaryProgramId: string;
  secondaryProgramIdentifier: number;
  secondaryProgram: SecondaryProgramDTO;
  secondaryPrograms: SecondaryProgramDTO[];
  secondaryProgramCourses: SecondaryProgramCourseDTO[];
  academicYears: AcademicYearDTO[];
  academicYear: number;
  fromAcademicYearList: boolean;
  fromSelfStudies: boolean;
  districtId: string;
  regions: RegionalPlanningPartnerDTO[];
}

interface SecondaryProgramDetailsPageProps {
}

class SecondaryProgramDetailsPage extends React.Component<SecondaryProgramDetailsPageProps & RouteComponentProps<RouteObject>, SecondaryProgramDetailsPageState> {
  private readonly _secondaryProgramDetailsRef = React.createRef<SecondaryProgramDetailsForm>();

  constructor(props: SecondaryProgramDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      activeTab: 'program_details',
      configuration: false,
      secondaryProgramId: Guid.Empty(),
      secondaryProgramIdentifier: 0,
      academicYears: [],
      academicYear: 0,
      fromAcademicYearList: false,
      fromSelfStudies: false,
      secondaryPrograms: [],
      secondaryProgramCourses: [],
      regions: [],
      districtId: Guid.Empty(),
      secondaryProgram: SecondaryProgramDTO.create()
    };
  }

  componentDidMount() {
    const secondaryProgramId = ParameterUtil.getPathPart(this.props.match, 'id');
    const academicYear = ParameterUtil.getParameter('academicYear');
    const fromAcademicYearList = ParameterUtil.getParameter('fromAcademicYearList');
    const fromSelfStudies = ParameterUtil.getParameter('fromSelfStudies');
    const districtId = ParameterUtil.getParameter('districtId');
    const activeTab = ParameterUtil.getParameter('activeTab', 'program_details');
    const configuration = ParameterUtil.getParameter('configuration');

    this.setState({
      academicYear: Number.parseInt(academicYear),
      fromSelfStudies: fromSelfStudies == 'true',
      fromAcademicYearList: fromAcademicYearList == 'true',
      districtId: districtId,
      activeTab: activeTab,
      configuration: configuration == 'true'
    });

    if (Number.parseInt(academicYear) != 0) {
      if (this.props.match.path.split('/').findIndex(x => x == 'new') > -1) {
        this.setState({ isEditing: true, isNew: true, loading: false });
        const loaders = [];
        loaders.push(this.loadAcademicYears());
        loaders.push(this.loadRegionalPlanningPartners(academicYear));
        Promise.all(loaders).then(() => {
          this.setState({ loading: false });
        });
      }
      else {
        const loaders = [];
        loaders.push(this.loadSecondaryProgram(secondaryProgramId));
        loaders.push(this.loadRegionalPlanningPartners(academicYear));
        loaders.push(this.loadAcademicYears());

        Promise.all(loaders).then(() => {
          this.setState({ loading: false });
        });
      }
    }
  }

  private loadRegionalPlanningPartners = (academicYear: any) => {
    RegionalPlanningPartnerApiService.getRegionalPlanningPartners(academicYear)
      .then((results: GetRegionalPlanningPartnersHandler.Result) => {
        if (results) {
          this.setState({
            regions: results.regions ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadSecondaryPrograms = (secondaryProgramId: any, academicYear: any) => {
    const request = GetSecondaryProgramsHandler.Request.create({
      academicYearId: academicYear,
      secondaryProgramId: secondaryProgramId
    });

    SecondaryProgramApiService.getSecondaryPrograms(request)
      .then((results: GetSecondaryProgramsHandler.Result) => {
        if (results) {
          this.setState({
            secondaryPrograms: results.secondaryPrograms ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadSecondaryProgramCourses = (secondaryProgramId: any, academicYear: any) => {
    const request = GetSecondaryProgramCoursesHandler.Request.create({
      academicYearId: academicYear,
      secondaryProgramId: secondaryProgramId
    });

    SecondaryProgramCourseApiService.getSecondaryProgramCourses(request)
      .then((results: GetSecondaryProgramCoursesHandler.Result) => {
        if (results) {
          this.setState({
            secondaryProgramCourses: results.secondaryProgramCourse ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }


  private loadSecondaryProgram = (id: string, academicYear?: any, secondaryProgramId?: any) => {
    this.setState({ secondaryProgramId: id });
    const request = GetSecondaryProgramDetailsHandler.Request.create({
      id: id,
      secondaryProgramId: secondaryProgramId,
      academicYear: academicYear
    });

    SecondaryProgramApiService.getSecondaryProgramDetails(request)
      .then((results: GetSecondaryProgramDetailsHandler.Result) => {
        if (results.secondaryProgram) {
          this.setState({
            secondaryProgram: results.secondaryProgram,
            secondaryProgramId: results.secondaryProgram.id ?? Guid.Empty(),
            secondaryProgramIdentifier: results.secondaryProgram.secondaryProgramId,
            isNew: false,
            isEditing: false
          });

          if (this.state.secondaryPrograms && this.state.secondaryPrograms.length == 0) {
            this.loadSecondaryPrograms(results.secondaryProgram.secondaryProgramId, results.secondaryProgram.academicYear);
          }
          if (this.state.secondaryProgramCourses && this.state.secondaryProgramCourses.length == 0) {
            this.loadSecondaryProgramCourses(results.secondaryProgram.secondaryProgramId, results.secondaryProgram.academicYear);
          }
        }
        else {
          this.setState({
            isNew: true,
            secondaryProgramId: Guid.Empty(),
            secondaryProgramIdentifier: this.state.secondaryProgramIdentifier,
            secondaryProgram: SecondaryProgramDTO.create(),
            isEditing: true
          })
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAcademicYears = () => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab, academicYear: this.state.academicYear }), { activeTab: activeTab, academicYear: this.state.academicYear });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._secondaryProgramDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private secondaryProgramDetailsPageSaved = (secondaryProgramId: string) => {
    this.setState({ isEditing: false, isNew: false });
    if (secondaryProgramId != this.state.secondaryProgramId) {
      HistoryUtil.push(Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId }, { academicYearId: this.state.academicYear, fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList }));
      this.loadSecondaryProgram(secondaryProgramId);
    }
    else {
      this.loadSecondaryProgram(secondaryProgramId);
    }
  }

  private deleteConfirmation = (id: string, academicYearId: number) => {
    antd.Modal.confirm({
      title: 'Are you sure you want to delete this Program?',
      okText: 'Delete',
      onOk: () => this.deleteSecondaryProgram(id, academicYearId)
    });
  }

  private deleteSecondaryProgram = (secondaryProgramId: string, academicYearId: number) => {
    this.setState({ submitting: true });

    const request = DeleteSecondaryProgramHandler.Request.create({
      secondaryProgramId: secondaryProgramId,
      academicYearId: academicYearId
    });

    SecondaryProgramApiService.deleteSecondaryProgram(request)
      .then((result: DeleteSecondaryProgramHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          antd.message.success('Programwas removed.');
        }
        else {
          antd.message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        antd.message.error('Programcould not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToListing();
      });
  }

  goToListing = () => {
    HistoryUtil.goBack();
  }

  private onSelect = (value: any) => {
    this.setState({ academicYear: value, secondaryProgramId: Guid.Empty() });
    this.loadSecondaryProgram(Guid.Empty(), value, this.state.secondaryProgramIdentifier);
  }

  render() {
    if (this.state.academicYear == 0) {
      return <Skeleton active></Skeleton>
    }

    const title = this.state.loading ? '...' : this.state.isNew ? 'New Program' : (this.state.secondaryProgram?.secondaryCipNumber?.code + ' - ' + this.state.secondaryProgram?.secondaryCipNumber?.description) ?? '...';
    let breadcrumbs = this.state.districtId == Guid.Empty() || this.state.districtId == '' ? Breadcrumbs.secondaryProgramDetailPage(title, this.state.secondaryProgramId, this.state.academicYear, this.state.fromAcademicYearList, this.state.districtId ?? Guid.Empty(), this.state.secondaryProgram?.district?.name + ' - ' + this.state.regions.find(x => x.regionalPlanningPartnerIdentifier == this.state.secondaryProgram?.district?.regionIdentifier)?.name ?? '') : Breadcrumbs.secondaryProgramDetailFromDistrict(title, this.state.secondaryProgramId, this.state.academicYear, this.state.fromAcademicYearList, this.state.secondaryProgram?.districtId ?? Guid.Empty(), this.state.secondaryProgram?.district?.name + ' - ' + this.state.regions.find(x => x.regionalPlanningPartnerIdentifier == this.state.secondaryProgram?.district?.regionIdentifier)?.name ?? '');

    if (this.state.configuration) {
      breadcrumbs = Breadcrumbs.academicYearSecondaryProgramsConfigurationDetail(this.state.academicYear, this.state.secondaryProgramId);
    }
    const isPublic = CurrentUser.Get() == null;
    return (
      <Content >
        <HeaderPortal
          title={title}
          subTitle={this.state.secondaryProgram?.district?.name}
          onBack={this.goToListing}
          breadcrumbs={breadcrumbs}
          extra={this.renderMoreActions()}
          footer={
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab} >
              <Tabs.TabPane tab="Details" key="program_details" />
              <Tabs.TabPane tab="Courses" key="courses" />
              {isPublic ? null : <Tabs.TabPane tab="Program History" key="program_history" />}
            </Tabs>
          }
        />
        {this.renderDetailsCard()}
        {this.renderProgramCourses()}
        {this.renderProgramHistoryCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    if (this.state.activeTab == 'program_details') {
      const editButton = this.state.secondaryProgramId == Guid.Empty() ?
        null :
        (
          <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_PROGRAM]}>
            <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
          </AuthorizedContent>
        );

      return (
        <antd.Card title="Details" extra={editButton} >
          <SecondaryProgramDetailsForm
            ref={this._secondaryProgramDetailsRef}
            secondaryProgramId={this.state.secondaryProgramId}
            isEditing={this.state.isEditing}
            isNew={this.state.isNew}
            readonly={CurrentUser.Get() == null}
            onSave={this.secondaryProgramDetailsPageSaved}
            academicYear={this.state.academicYear} />
        </antd.Card>
      );
    }
  }

  renderProgramHistoryCard() {
    if (this.state.activeTab == 'program_history') {

      return (
        <antd.Card title="Details"  >
          <SecondaryProgramHistoryDataTable
            secondaryProgramId={this.state.secondaryProgramIdentifier}
            academicYear={this.state.academicYear} />
        </antd.Card>
      );
    }
  }

  renderMoreActions() {
    const menu = (
      <antd.Menu>
        < antd.Menu.Item title='Delete Program' key={this.state.secondaryProgramId} onClick={() => this.deleteConfirmation(this.state.secondaryProgramId, this.state.academicYear)}  >
          Delete Program
        </antd.Menu.Item>
      </antd.Menu>
    );
    return (
      <>
        {CurrentUser.Get() == null ? null : <> <label title={'academicYear'} htmlFor={'academicYear'} />
          <Dropdown
            id='academicYear'
            value={this.state.academicYear}
            onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.secondaryPrograms.map(x => { return this.renderSecondaryProgramAcademicYear(x); })}</Dropdown></>}

        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_PROGRAM]}>
          {this.state.isNew ? null
            : <antd.Dropdown overlay={menu}>
              <antd.Button size='large' type='link' >
                <MoreOutlined />
              </antd.Button>
            </antd.Dropdown>}
        </AuthorizedContent>
      </>
    );
  }

  renderProgramCourses() {
    if (this.state.activeTab == 'courses') {
      return (

        <Card title='Program Courses'>
          {this.state.secondaryProgramCourses.length > 0 || !this.state.fromSelfStudies ?
            <SecondaryCoursesDataTable
              academicYear={this.state.academicYear}
              fromSelfStudies={this.state.fromSelfStudies}
              fromAcademicYearList={this.state.fromAcademicYearList}
              fromSecondaryProgramCourse={true}
              districtId={this.state.secondaryProgram?.districtId ?? Guid.Empty()}
              secondaryProgramId={this.state.secondaryProgram?.id ?? Guid.Empty()}
              programId={this.state.secondaryProgramIdentifier ?? 0}
            /> :
            AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR]) ?
              <SecondaryProgramCoursesImporter
                onSubmit={() => this.loadSecondaryProgramCourses(this.state.secondaryProgram?.secondaryProgramId, this.state.academicYear)}
                academicYear={this.state.academicYear}
                programId={this.state.secondaryProgramIdentifier} />
              :
              <ValueLabel text={'There is no data for this program review year and user does not have the permission to import program courses.'}></ValueLabel>
          }
        </Card>
      );
    }
  }

  renderSecondaryProgramAcademicYear(secondaryProgramAcademicYear: SecondaryProgramDTO) {
    return <antd.Select.Option key={secondaryProgramAcademicYear.secondaryProgramId} value={secondaryProgramAcademicYear.academicYear}>{secondaryProgramAcademicYear.academicYear}</antd.Select.Option>
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <antd.Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</antd.Select.Option>
  }
}

export default withRouter(SecondaryProgramDetailsPage);
