// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SearchForSecondaryCoursesHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SecondaryCourseDTO from '../models/SecondaryCourseDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    searchValue: string | null;
    programId: string | null;
    districtId: string | null;
    academicYear: number;
    courseSearch: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            searchValue: "",
            programId: '00000000-0000-0000-0000-000000000000',
            districtId: '00000000-0000-0000-0000-000000000000',
            academicYear: 0,
            courseSearch: 0,
        },
        initValues);
    },
    searchValue: 'searchValue',
    programId: 'programId',
    districtId: 'districtId',
    academicYear: 'academicYear',
    courseSearch: 'courseSearch',
}

interface Result extends ResultDTO {
    courseSearch: number;
    secondaryCourses: SecondaryCourseDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            courseSearch: 0,
            secondaryCourses: [],
        },
        initValues);
    },
    courseSearch: 'courseSearch',
    secondaryCourses: 'secondaryCourses',
}

export { Request, Result };