import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DisciplinesApiService from '../../../../api/DisciplinesApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import DisciplineDetials from '../../../../components/forms/postSecondary/DisciplineDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as GetDisciplineDetailsHandler from '../../../../handlerModels/GetDisciplineDetailsHandler';
import DisciplineDTO from '../../../../models/DisciplineDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface DisciplineDetailsState extends BaseFormState {
  disciplineId: string;
  discipline?: DisciplineDTO
}

interface DisciplineDetailsProps {
}

class DisciplineDetailsPage extends React.Component<DisciplineDetailsProps & RouteComponentProps<RouteObject>, DisciplineDetailsState> {
  private readonly _termDetailsRef = React.createRef<DisciplineDetials>();

  constructor(props: DisciplineDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      disciplineId: Guid.Empty(),
    };
  }

  componentDidMount() {
    const disciplineId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (disciplineId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      this.loadDiscipline(disciplineId);
    }
  }

  private loadDiscipline = (disciplineId: string) => {
    this.setState({ disciplineId: disciplineId });

    DisciplinesApiService.getDisciplineDetails(disciplineId)
      .then((results: GetDisciplineDetailsHandler.Result) => {
        if (results.discipline) {
          this.setState({ discipline: results.discipline });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._termDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private disciplineDetailsSaved = (disciplineId: string) => {
    this.setState({ isEditing: false });
    if (disciplineId != this.state.disciplineId) {
      HistoryUtil.replace(Routes.generate(Routes.DISCIPLINE_DETAILS, { id: disciplineId }));
    }
    else {
      this.loadDiscipline(disciplineId);
    }
  }

  private goToDisciplines = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.disciplineId == Guid.Empty() ? 'New Discipline' : this.state.discipline?.name ?? '...';

    return (
      <Content >
        <HeaderPortal title={title} onBack={this.goToDisciplines} breadcrumbs={Breadcrumbs.disciplineDetails(title, this.state.disciplineId)} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    const editButton = this.state.disciplineId == Guid.Empty() ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_DISCIPLINE]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <DisciplineDetials
          disciplineId={this.state.disciplineId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.disciplineDetailsSaved} />
      </Card>
    );
  }
}

export default withRouter(DisciplineDetailsPage);