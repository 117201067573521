import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import * as GetServiceAreaDetailsHandler from '../../../handlerModels/GetServiceAreaDetailsHandler';
import * as SaveServiceAreaDetailsHandler from '../../../handlerModels/SaveServiceAreaDetailsHandler';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';

interface ServiceAreaDetailsFormState extends BaseFormState {
  serviceArea: ServiceAreaDTO;
}

interface ServiceAreaDetailsFormProps extends BaseFormProps {
  serviceAreaId: string;
  academicYear?: number;
  onSave?: (serviceAreaId: string) => void;
}

class ServiceAreaDetailsForm extends React.Component<ServiceAreaDetailsFormProps, ServiceAreaDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(ServiceAreaDTO.serviceAreaIdentifier, {
      required: true,
      name: ServiceAreaDTO.serviceAreaIdentifier,
      label: 'Identifier',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(ServiceAreaDTO.description, {
      required: true,
      name: ServiceAreaDTO.description,
      label: 'Title',
      rules: [
        ValidationRuleUtil.required()
      ],
    });

  constructor(props: ServiceAreaDetailsFormProps) {
    super(props);

    this.state = {
      serviceArea: ServiceAreaDTO.create({
        id: '',
        code: '',
        serviceAreaIdentifier: ''
      })
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: ServiceAreaDetailsFormProps) {
    if (this.props.serviceAreaId && this.props != prevProps) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];
    loaders.push(this.loadServiceArea());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadServiceArea() {
    if (!this.state.loading) {
      if (this.props.serviceAreaId == Guid.Empty() || this.props.isNew) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.serviceAreaId) {
      ServiceAreaApiService.getServiceAreaDetails(this.props.serviceAreaId)
        .then((results: GetServiceAreaDetailsHandler.Result) => {
          if (results) {
            this.setState({
              serviceArea: results.serviceArea ?? this.state.serviceArea
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({
      loading: false
    });
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      this.handleSubmit,
      () => this.setState({ error: true })
    );
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveServiceAreaDetailsHandler.Request.create({
      serviceAreaId: this.props.serviceAreaId,
      serviceArea: ServiceAreaDTO.create({
        ...(this._formRef ? (this._formRef.current as any).getFieldsValue() : null),
        academicYear: this.props.academicYear
      })
    });

    ServiceAreaApiService.saveServiceAreaDetails(request)
      .then((result: SaveServiceAreaDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ serviceArea: result.serviceArea ?? ServiceAreaDTO.create() });

          message.success('Saved');

          if (this.props.onSave && result.serviceArea?.id) {
            this.resetForm();
            this.props.onSave(result.serviceArea.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error(result?.errors.join('\n'));
          this.setState({ loading: false, submitting: false });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results, loading: false, submitting: false });
        message.error('Service Area could not be saved');
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.serviceArea}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(ServiceAreaDTO.serviceAreaIdentifier)}
            {...ValidationUtil.getValidation(ServiceAreaDTO.serviceAreaIdentifier, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(ServiceAreaDTO.description)}
            {...ValidationUtil.getValidation(ServiceAreaDTO.description, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
        </Form>
      </Space>
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default ServiceAreaDetailsForm;
