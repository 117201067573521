import { CloseOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import * as React from 'react';

interface ClearButtonState { }

interface ClearButtonProps extends ButtonProps {
  clearing?: boolean;
}

class ClearButton extends React.Component<ClearButtonProps, ClearButtonState> {
  constructor(props: ClearButtonProps) {
    super(props);

    this.state = {};
  }

  render() {

    return (
      <Button
        {...this.props}
        icon={<CloseOutlined />}
        loading={this.props.clearing || this.props.loading} >
        Clear
      </Button>
    );
  }
}

export default ClearButton;
