import { Input } from 'antd';
import * as React from 'react';
import RegionalPlanningPartnerApiService from '../../api/RegionalPlanningPartnerApiService';
import * as SearchForRppUserHandler from '../../handlerModels/SearchForRppUserHandler';
import UserDTO from '../../models/UserDTO';
import AutoCompleteInput, { AutoCompleteInputOption } from './AutoCompleteInput';

interface RPPUserSearchInputProps {
  rppId: number;
  value?: UserDTO | null;
  disabled?: boolean;
  onChange?: (value: UserDTO | null) => void;
}

function RPPUserSearchInput(props: React.PropsWithChildren<RPPUserSearchInputProps>) {

  const [options, setOptions] = React.useState([] as AutoCompleteInputOption[]);
  const [users, setUsers] = React.useState([] as UserDTO[]);
  const [selectedUser, setSelectedUser] = React.useState(props.value as UserDTO | null);
  const [searching, setSearching] = React.useState(false);

  const handleSearch = (value: string) => {
    if (value.length > 2) {
      const request = SearchForRppUserHandler.Request.create(
        {
          rppId: props.rppId,
          searchString: value
        });
      setSearching(true);

      RegionalPlanningPartnerApiService.searchRPPUsers(request)
        .then((x: SearchForRppUserHandler.Result) => {
          const options = x.users?.map(x => ({
            value: x.id,
            display: x.fullDisplay
          } as AutoCompleteInputOption)) as AutoCompleteInputOption[];

          setUsers(x.users ?? []);
          setOptions(options);
          setSearching(false);
        });
    }
  }

  const handleChange = (value: string) => {
    const selected = users.find(x => x.id == value) ?? null;
    setSelectedUser(selected);
    if (props.onChange) {
      props.onChange(selected);
    }
  }

  return (
    <Input.Group compact>
      <AutoCompleteInput disabled={props.disabled} initialValue={props.value?.display} value={selectedUser} options={options} onSearch={handleSearch} onChange={handleChange} searching={searching} />
    </Input.Group>
  );
}
export default RPPUserSearchInput;
