import { Alert, Input, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import NoticeOfIntentChangeRequestApiService from '../../../api/NoticeOfIntentChangeRequestApiService';
import ProgramApiService from '../../../api/ProgramApiService';
import ProgramModificationChangeRequestApiService from '../../../api/ProgramModificationChangeRequestApiService';
import * as GetNoticeOfIntentChangeRequestByCipReclassificationHandler from '../../../handlerModels/GetNoticeOfIntentChangeRequestByCipReclassificationHandler';
import * as GetProgramModificationChangeRequestStep1Handler from '../../../handlerModels/GetProgramModificationChangeRequestStep1Handler';
import * as GetProgramsByInstitutionIdHandler from '../../../handlerModels/GetProgramsByInstitutionIdHandler';
import * as SaveProgramModificationChangeRequestStep1Handler from '../../../handlerModels/SaveProgramModificationChangeRequestStep1Handler';
import * as SubmitProgramModificationChangeRequestStep1Handler from '../../../handlerModels/SubmitProgramModificationChangeRequestStep1Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import ExternalInstitutionDTO from '../../../models/ExternalInstitutionDTO';
import IowaVariationDTO from '../../../models/IowaVariationDTO';
import ProgramDetailDTO from '../../../models/ProgramDetailDTO';
import ProgramDTO from '../../../models/ProgramDTO';
import ProgramModificationChangeRequestDTO from '../../../models/ProgramModificationChangeRequestDTO';
import ProgramModificationProgramDTO from '../../../models/ProgramModificationProgramDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import Dropdown from '../../inputs/Dropdown';
import ReadableTextBox from '../../inputs/ReadableTextBox';
import YesNoInput from '../../inputs/YesNoInput';
import TermInput from '../../TermInput';

interface ProgramModificationChangeRequestStep1FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  programModification: ProgramModificationChangeRequestDTO;
  programs: ProgramDTO[];
  programModificationPrograms: ProgramModificationProgramDTO[];
  searchForPrograms: ProgramDTO[];
  noticeOfIntent: ChangeRequestDTO[];
  existingProgramModificationProgram: string;
  externalInstitution: ExternalInstitutionDTO[];
  iowaVariation: IowaVariationDTO[];
}

interface ProgramModificationChangeRequestStep1FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  onSubmit?: (id: string) => void;
  onSave?: (id: string) => void;
  onChange?: (altered: boolean) => void;
  selectedInstitution: string | null;
  readonly?: boolean;
}

class ProgramModificationChangeRequestStep1Form extends React.Component<ProgramModificationChangeRequestStep1FormProps, ProgramModificationChangeRequestStep1FormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _formItems = new Map<string, FormItemProps>()
    .set(ProgramModificationChangeRequestDTO.programId, {
      required: true,
      name: ProgramModificationChangeRequestDTO.programId,
      label: 'Please select a program to modify.',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(ProgramModificationChangeRequestDTO.noticeOfIntentChangeRequestId, {
      required: true,
      name: ProgramModificationChangeRequestDTO.noticeOfIntentChangeRequestId,
      label: 'Please select the notice of intent corresponding to this reclassification.',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationChangeRequestDTO.cipReclassification, {
      required: true,
      name: ProgramModificationChangeRequestDTO.cipReclassification,
      label: 'Are you reclassifying the CIP number for this program?',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationChangeRequestDTO.install, {
      required: true,
      name: ProgramModificationChangeRequestDTO.install,
      label: 'When will these modifications take effect?',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationChangeRequestDTO.programMarketingLink, {
      required: true,
      name: ProgramModificationChangeRequestDTO.programMarketingLink,
      label: 'Please provide a link to the website used to market this program.',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.https()
      ]
    })
    .set(ProgramModificationChangeRequestDTO.iowaVariationId, {
      required: true,
      name: ProgramModificationChangeRequestDTO.iowaVariationId,
      label: 'Please select an Iowa Variation.',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationChangeRequestDTO.externalInstitutionIds, {
      required: true,
      name: ProgramModificationChangeRequestDTO.externalInstitutionIds,
      label: 'Please select the external institution.',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationChangeRequestDTO.title, {
      required: true,
      name: ProgramModificationChangeRequestDTO.title,
      label: 'Program Title',
      rules: [ValidationRuleUtil.required()]
    });

  constructor(props: ProgramModificationChangeRequestStep1FormProps) {
    super(props);

    this.state = {
      noticeOfIntent: [],
      changeRequest: ChangeRequestDTO.create(),
      programs: [],
      programModification: ProgramModificationChangeRequestDTO.create({
        programId: null,
        externalInstitutionIds: []
      }),
      searchForPrograms: [],
      programModificationPrograms: [],
      existingProgramModificationProgram: Guid.Empty(),
      externalInstitution: [],
      iowaVariation: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: ProgramModificationChangeRequestStep1FormProps) {
    if (this.props.changeRequestId && prevProps.changeRequestId != this.props.changeRequestId) {
      this.fetchData();
    }
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });

    this._formRef.current?.resetFields();
    if (this.props.onChange) {
      this.props.onChange(false)
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.programs || this.state.programs.length == 0) {
      loaders.push(this.getPrograms());
    }

    loaders.push(this.GetNoticeOfIntentByCipReclassification());

    if (!this.state.iowaVariation || this.state.iowaVariation.length == 0) {
      loaders.push(this.loadIowaVariation());
    }

    if (!this.state.externalInstitution || this.state.externalInstitution.length == 0) {
      loaders.push(this.loadExternalInstitution());
    }

    if (!this.props.changeRequestId || this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false }, () => this.resetForm());
    });
  }

  private loadExternalInstitution = () => {
    return LookupsUtil.getAll<ExternalInstitutionDTO>(ExternalInstitutionDTO.className)
      .then((results: ExternalInstitutionDTO[]) => {
        if (results) {
          this.setState({ externalInstitution: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadIowaVariation = () => {
    return LookupsUtil.getAll<IowaVariationDTO>(IowaVariationDTO.className)
      .then((results: IowaVariationDTO[]) => {
        if (results) {
          this.setState({ iowaVariation: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private getPrograms = () => {
    const request = GetProgramsByInstitutionIdHandler.Request.create({
      instituionId: this.props.selectedInstitution
    });

    return ProgramApiService.getProgramsByInstitutionId(request)
      .then((results: GetProgramsByInstitutionIdHandler.Result) => {
        if (results) {

          this.setState({ programs: results.program ?? [], });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private GetNoticeOfIntentByCipReclassification = () => {
    const request = GetNoticeOfIntentChangeRequestByCipReclassificationHandler.Request.create({
      institutionId: this.props.selectedInstitution,
      changeRequestId: this.props.changeRequestId ?? Guid.Empty()
    });

    return NoticeOfIntentChangeRequestApiService.getByCipReclassification(request)
      .then((results: GetNoticeOfIntentChangeRequestByCipReclassificationHandler.Result) => {
        if (results) {
          this.setState({
            noticeOfIntent: results.changeRequests ?? [],
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private loadChangeRequest = () => {
    const request = GetProgramModificationChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return ProgramModificationChangeRequestApiService.getStep1(request)
      .then((results: GetProgramModificationChangeRequestStep1Handler.Result) => {
        if (results) {
          const programModificationChangeRequest: ProgramModificationChangeRequestDTO = results.changeRequest?.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create({ externalInstitutionIds: [] });
          if (!programModificationChangeRequest.program) {
            programModificationChangeRequest.program = ProgramDTO.create({ programId: null });
          }
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            programModification: programModificationChangeRequest,
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const model = ProgramModificationChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null) as ProgramModificationChangeRequestDTO;
    model.program = this.state.programModification.program;
    model.institutionId = this.props.selectedInstitution;
    model.noticeOfIntentChangeRequestId = model.noticeOfIntentChangeRequestId ?? Guid.Empty();

    const request = SubmitProgramModificationChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      institutionId: this.props.selectedInstitution,
      programModificationChangeRequest: model
    }) as SubmitProgramModificationChangeRequestStep1Handler.Request;

    ProgramModificationChangeRequestApiService.submitStep1(request)
      .then((result: SubmitProgramModificationChangeRequestStep1Handler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          message.success('Saved');
          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            programModification: result.changeRequest?.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create()
          });
          this.resetForm();

          if (this.props.onSave) {
            if (result.changeRequest) {
              this.props.onSave(result.changeRequest.id ?? Guid.Empty());
            }
          }

          if (this.props.onSubmit) {
            if (result.changeRequest) {
              this.props.onSubmit(result.changeRequest.id ?? Guid.Empty());
            }
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const model = ProgramModificationChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null) as ProgramModificationChangeRequestDTO;
    model.noticeOfIntentChangeRequestId = model.noticeOfIntentChangeRequestId ?? Guid.Empty();

    const request = SaveProgramModificationChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      institutionId: this.props.selectedInstitution,
      programModificationChangeRequest: model,
    }) as SaveProgramModificationChangeRequestStep1Handler.Request;

    ProgramModificationChangeRequestApiService.saveStep1(request)
      .then((result: SaveProgramModificationChangeRequestStep1Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            programModification: result.changeRequest?.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create()
          });

          this.resetForm();

          if (this.props.onSave) {
            if (result.changeRequest) {
              this.props.onSave(result.changeRequest.id ?? Guid.Empty());
            }
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
    if (this.props.onChange) {
      this.props.onChange(true)
    }
  }

  render() {
    if (this.state.loading || this.props.loading || this.props.loading == undefined) {
      return <Skeleton active={true} />
    }

    return (
      <Space size="small" direction="vertical">
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.programModification}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={!this.props.readonly}>

          {this.renderSelectProgram()}

          {this.renderProgramTitle()}

          <FormItem
            {...this._formItems.get(ProgramModificationChangeRequestDTO.iowaVariationId)}
            {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.iowaVariationId, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Dropdown
              showSearch
              showArrow={!this.props.readonly}
              optionFilterProp="children"
              placeholder='Select Iowa Variation'
              disabled={this.props.readonly || this.state.saving || this.state.submitting}>
              {this.state.iowaVariation.map(x => {
                return this.renderIowaVariation(x);
              })}
            </Dropdown>
          </FormItem>

          {this.renderExternalInstitution()}

          < FormItem key={ProgramModificationChangeRequestDTO.cipReclassification}
            {...this._formItems.get(ProgramModificationChangeRequestDTO.cipReclassification)}
            {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.cipReclassification, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <YesNoInput disabled={this.props.readonly || this.state.saving || this.state.submitting} />
          </ FormItem >

          {this.renderNOIForCipReclassification()}

          <FormItem
            {...this._formItems.get(ProgramModificationChangeRequestDTO.install)}
            {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.install, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <TermInput futureYears={true} readOnly={this.props.readonly} disabled={this.state.saving || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(ProgramModificationChangeRequestDTO.programMarketingLink)}
            {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.programMarketingLink, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Input disabled={this.props.readonly || this.state.saving || this.state.submitting} />
          </FormItem>

          {this.renderActions()}
        </Form>
      </Space >
    );
  }

  private onProgramSelect = (e: any) => {
    const program = this.state.programs.find(x => x.id == e);
    const formValue = this._formRef ? (this._formRef.current) : null;

    const programModification = this.state.programModification;
    programModification.program = program ?? ProgramDTO.create();
    if (program?.currentDetail) {
      programModification.title = program?.currentDetail.title;
      programModification.programMarketingLink = program?.currentDetail.programMarketingLink;
      programModification.iowaVariationId = program.currentDetail.iowaVariationId;
    }
    formValue?.setFieldsValue({
      title: programModification.title,
      programMarketingLink: programModification.programMarketingLink,
      iowaVariationId: programModification.iowaVariationId
    });
  }

  renderActions() {
    if (!this.props.readonly) {
      return <Space direction='horizontal' wrap={true}>
        <SaveAndContinueButton submitting={this.state.submitting || this.state.saving} />
        <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting || this.state.saving} saved={this.state.saved} />
        <ResetButton disabled={this.state.submitting || this.state.saving} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space>
    }
  }

  renderSelectProgram() {
    const options =
      this.state.programs.map(x => {
        return this.renderProgram(x);
      });

    return (
      <FormItem
        key={ProgramModificationChangeRequestDTO.programId}
        {...this._formItems.get(ProgramModificationChangeRequestDTO.programId)}
        {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.programId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
        <Dropdown
          showSearch
          showArrow={!this.props.readonly}
          optionFilterProp="children"
          placeholder='Select Program'
          onChange={this.onProgramSelect}
          disabled={this.props.readonly || this.state.saving || this.state.submitting}>
          {options}
        </Dropdown>
      </FormItem>
    );
  }

  renderNOIForCipReclassification() {
    if (this._formRef.current?.getFieldValue(ProgramModificationChangeRequestDTO.cipReclassification) || (!this._formRef.current && this.state.programModification.cipReclassification)) {
      return <FormItem
        key={ProgramModificationChangeRequestDTO.noticeOfIntentChangeRequestId}
        {...this._formItems.get(ProgramModificationChangeRequestDTO.noticeOfIntentChangeRequestId)}
        {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.noticeOfIntentChangeRequestId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
        <Dropdown
          showSearch
          placeholder='Select Notice of Intent'
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          disabled={this.props.readonly}>
          {this.state.noticeOfIntent.map(x => {
            return this.renderNOI(x)
          })}
        </Dropdown>
      </FormItem>
    }

    return null;
  }

  renderExternalInstitution() {
    const programId = this._formRef.current?.getFieldValue(ProgramDetailDTO.programId);
    const isTransferMajor = this.state.programs.find(x => x.id == programId)?.currentDetail?.isTransferMajor;
    if (isTransferMajor || this.state.programModification.program?.currentDetail?.isTransferMajor) {
      return <FormItem
        {...this._formItems.get(ProgramModificationChangeRequestDTO.externalInstitutionIds)}
        {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.externalInstitutionIds, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
        <Dropdown mode="multiple" disabled={this.props.readonly || this.state.saving || this.state.submitting} >{this.state.externalInstitution.map(x => this.renderExternalInstitutionOptions(x))}</Dropdown>
      </FormItem>
    }
  }

  renderProgramTitle() {
    const programId = this._formRef.current?.getFieldValue(ProgramDetailDTO.programId);
    const programDetail = this.state.programs.find(x => x.id == programId)?.currentDetail;
    if (programDetail) {
      return <FormItem
        {...this._formItems.get(ProgramModificationChangeRequestDTO.title)}
        {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.title, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
        <ReadableTextBox disabled={this.props.readonly || this.state.saving || this.state.submitting} />
      </FormItem>
    }
  }

  renderExternalInstitutionOptions(externalInsitution: ExternalInstitutionDTO) {
    if (externalInsitution.id) {
      return <Select.Option key={externalInsitution.id ?? Guid.Empty()} value={externalInsitution.id ?? Guid.Empty()}> {externalInsitution.name}</Select.Option>
    }
  }

  renderProgram(program: ProgramDTO) {
    if (program.id && program.programDetails) {
      return <Select.Option key={program.id} value={program.id}>{program.programDetails[0].display}</Select.Option>
    }
  }

  renderIowaVariation(iowaVariation: IowaVariationDTO) {
    if (iowaVariation.id) {
      return <Select.Option key={iowaVariation.id} value={iowaVariation.id}>{iowaVariation.display}</Select.Option>
    }
  }

  renderNOI(changeRequest: ChangeRequestDTO) {
    if (changeRequest.id) {
      return <Select.Option key={changeRequest.noticeOfIntentChangeRequest?.id ?? Guid.Empty()} value={changeRequest.noticeOfIntentChangeRequest?.id ?? Guid.Empty()}>{changeRequest.display}</Select.Option>
    }
  }

  private renderErrors = () => {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default ProgramModificationChangeRequestStep1Form;