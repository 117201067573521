import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import InstitutionsDataTable from '../../../components/datatables/postSecondary/InstitutionsDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import HeaderPortal from '../../../portals/HeaderPortal';
import HistoryUtil from '../../../utils/HistoryUtil';

interface InstitutionListProps {
}

interface InstitutionListState {
}

class InstitutionListPage extends React.Component<InstitutionListProps & RouteComponentProps<RouteObject>, InstitutionListState> {
  constructor(props: InstitutionListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.push(Routes.generate(Routes.POST_SECONDARY_HOME));
  }

  render() {
    return (
      <Content >
        <HeaderPortal title="Community Colleges" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.institutionList()} />

        <Card >
          <InstitutionsDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(InstitutionListPage);
