import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ThirdPartyCredentialTypeDataTable from '../../../components/datatables/ThirdPartyCredentialTypeDatatable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import HeaderPortal from '../../../portals/HeaderPortal';
import HistoryUtil from '../../../utils/HistoryUtil';

interface ThirdPartyCredentialTypeListProps {
}

interface ThirdPartyCredentialTypeListState {
}

class ThirdPartyCredentialTypeListPage extends React.Component<ThirdPartyCredentialTypeListProps & RouteComponentProps<RouteObject>, ThirdPartyCredentialTypeListState> {
  constructor(props: ThirdPartyCredentialTypeListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Third Party Credential Type" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.thirdPartyCredentialTypeList()} />

        <Card >
          <ThirdPartyCredentialTypeDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(ThirdPartyCredentialTypeListPage);
