// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ContactTypeDTO from '../models/ContactTypeDTO';
import * as GetContactTypeDetailsHandler from '../handlerModels/GetContactTypeDetailsHandler';
import * as SaveContactTypeDetailsHandler from '../handlerModels/SaveContactTypeDetailsHandler';
import * as DeleteContactTypeHandler from '../handlerModels/DeleteContactTypeHandler';

export class ContactTypeApiService extends BaseApi {

    // post: api/contactTypes
    public getContactTypeTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ContactTypeDTO>> {
        let url = `api/contactTypes`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ContactTypeDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/contactTypes/${contactTypeId}
    public getContactTypeDetails(contactTypeId: number): Promise<GetContactTypeDetailsHandler.Result> {
        let url = `api/contactTypes/${contactTypeId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetContactTypeDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/contactTypes/export
    public getContactTypeDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/contactTypes/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/contactTypes/save
    public saveContactTypeDetails(saveContactTypeDetails: SaveContactTypeDetailsHandler.Request): Promise<SaveContactTypeDetailsHandler.Result> {
        let url = `api/contactTypes/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveContactTypeDetailsHandler.Result>(saveContactTypeDetails, 'post', url, true, false);
    }

    // post: api/contactTypes/deleteContactType
    public deleteContactType(deleteContactType: DeleteContactTypeHandler.Request): Promise<DeleteContactTypeHandler.Result> {
        let url = `api/contactTypes/deleteContactType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteContactTypeHandler.Result>(deleteContactType, 'post', url, true, false);
    }
}
var service = new ContactTypeApiService();
export default service;
