import { Input, InputNumber, message, Select, Skeleton, Space, Table } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { default as InstituionCoursesApiService } from '../../../api/InstitutionCoursesApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetInstitutionCourseDetailByCourseAndInstitutionHandler from '../../../handlerModels/GetInstitutionCourseDetailByCourseAndInstitutionHandler';
import * as SaveInstitutionCourseDetailsHandler from '../../../handlerModels/SaveInstitutionCourseDetailsHandler';
import CategoryDTO from '../../../models/CategoryDTO';
import CreditTypeDTO from '../../../models/CreditTypeDTO';
import InstitutionCourseCreditDTO from '../../../models/InstitutionCourseCreditDTO';
import InstitutionCourseDetailDTO from '../../../models/InstitutionCourseDetailDTO';
import InstitutionCourseDTO from '../../../models/InstitutionCourseDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import ValueLabel from '../../general/ValueLabel';
import YesNoInput from '../../inputs/YesNoInput';
import TermInput from '../../TermInput';

interface InstitutionCourseDetailsFormState extends BaseFormState {
  institutionCourseDetail: InstitutionCourseDetailDTO;

  creditTypes: CreditTypeDTO[];
  categories: CategoryDTO[];
}

interface InstitutionCourseDetailsFormProps extends BaseFormProps {
  institutionId: string;
  courseId: string;
  onSave?: (id: string) => void;
}

class InstitutionCourseDetailsForm extends React.Component<InstitutionCourseDetailsFormProps, InstitutionCourseDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: InstitutionCourseDetailsFormProps) {
    super(props);

    this.state = {
      institutionCourseDetail: InstitutionCourseDetailDTO.create({
        installTermId: '',
        retireTermId: '',
      }),
      creditTypes: [],
      categories: []
    };
  }

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(InstitutionCourseDetailDTO.description, {
        name: InstitutionCourseDetailDTO.description,
        label: 'Description',
        required: true,
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(5000)
        ],
      })
      .set(InstitutionCourseDetailDTO.categoryId, {
        required: true,
        name: InstitutionCourseDetailDTO.categoryId,
        label: 'Category',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(InstitutionCourseDTO.install, {
        name: InstitutionCourseDTO.install,
        label: 'Install Term',
        required: true,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(InstitutionCourseDTO.retire, {
        name: InstitutionCourseDTO.retire,
        label: 'Retire Term',
      })
      .set(InstitutionCourseDetailDTO.variableHours, {
        name: InstitutionCourseDetailDTO.variableHours,
        label: 'Variable Hours',
      });
  }


  private getCreditFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(InstitutionCourseCreditDTO.minimum, {
        name: [index, InstitutionCourseCreditDTO.minimum],
      })
      .set(InstitutionCourseCreditDTO.maximum, {
        name: [index, InstitutionCourseCreditDTO.maximum],
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: InstitutionCourseDetailsFormProps) {
    if (this.props.institutionId != Guid.Empty() && this.props.courseId != Guid.Empty()) {
      if (this.props.institutionId != prevProps.institutionId || this.props.courseId != prevProps.courseId && !this.props.isNew) {
        this.loadExisting(this.props.courseId, this.props.institutionId)
      }
    }
  }

  private fetchData() {
    this.setState({ loading: true });

    const loaders = [];

    if (this.props.institutionId != Guid.Empty() && this.props.courseId != Guid.Empty() && !this.props.isNew) {
      loaders.push(this.loadExisting(this.props.courseId, this.props.institutionId));
    }

    if (this.state.categories.length == 0) {
      loaders.push(this.loadCategories());
    }

    if (this.state.creditTypes.length == 0) {
      loaders.push(this.loadCreditTypes());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadExisting = (courseId: string, institutionId: string) => {
    return InstituionCoursesApiService.getInstitutionCourseDetailByCourseIdandInstitutionId(courseId, institutionId)
      .then((results: GetInstitutionCourseDetailByCourseAndInstitutionHandler.Result) => {
        if (results.institutionCourseDetails && results.courseDetails) {
          const selectedInstitutionCourseDetail = results.institutionCourseDetails[results.institutionCourseDetails.length - 1];

          this.setState({
            institutionCourseDetail: selectedInstitutionCourseDetail ?? InstitutionCourseDetailDTO.create(),
          });

          this.setState({
            institutionCourseDetail: selectedInstitutionCourseDetail
          });

          this.checkCredits();

          this.resetForm();
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCategories = () => {
    return LookupsUtil.getAll<CategoryDTO>(CategoryDTO.className)
      .then((results: CategoryDTO[]) => {
        if (results) {
          this.setState({ categories: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCreditTypes = () => {
    this.setState({ loading: true });
    return LookupsUtil.getAll<CreditTypeDTO>(CreditTypeDTO.className)
      .then((results: CreditTypeDTO[]) => {
        if (results) {
          this.setState({ creditTypes: results ?? [] });
        }

        this.checkCredits();
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private checkCredits = () => {
    if (this.state.institutionCourseDetail.institutionCourseCredits?.length == 0) {
      const courseDetails = this.state.institutionCourseDetail;
      const sorted = this.state.creditTypes.sort((a, b) => { return a.displayOrder - b.displayOrder });

      courseDetails.institutionCourseCredits = sorted.map(x => {
        return InstitutionCourseCreditDTO.create({
          creditTypeId: x.id,
          creditType: x
        });
      });
    }
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private handleSave = () => {
    this.setState({ submitting: true });

    const institutionCourseDetail = InstitutionCourseDetailDTO.create(this._formRef.current?.getFieldsValue() ?? null);

    const request = SaveInstitutionCourseDetailsHandler.Request.create({
      institutionCourseDetail: institutionCourseDetail,

      courseId: this.props.courseId,
      institutionId: this.props.institutionId
    });

    InstituionCoursesApiService.saveInstitutionCourseDetails(request)
      .then((result: SaveInstitutionCourseDetailsHandler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });

          message.error('Error Saving');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private getCreditColumns = () => {
    const variableHours = this._formRef.current?.getFieldValue(InstitutionCourseDetailDTO.variableHours) ?? this.state.institutionCourseDetail.variableHours;
    const columns = [
      {
        title: '',
        dataIndex: InstitutionCourseCreditDTO.creditTypeId,
        render: (data: any, row: any, index: number) => {
          const creditTypeId = this._formRef.current?.getFieldValue(InstitutionCourseDetailDTO.institutionCourseCredits)[index].creditTypeId;
          if (creditTypeId) {
            const creditType = this.state.creditTypes.filter(x => x.id == creditTypeId)[0];
            if (creditType) {
              return <ValueLabel text={creditType.name} />;
            }
          }
        }
      },
      {
        title: variableHours ? 'Min Credits' : 'Credits',
        dataIndex: InstitutionCourseCreditDTO.minimum,
        render: (data: any, row: any, index: number) => {

          return (
            <FormItem
              {...this.getCreditFormItems(index).get(InstitutionCourseCreditDTO.minimum)}
              {...ValidationUtil.getValidation(InstitutionCourseCreditDTO.minimum + index, this.state.fieldErrors, this.state.submitted)}>
              <InputNumber disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </FormItem>
          );
        }
      },
    ];

    if (variableHours) {
      columns.push(
        {
          title: 'Max Credits',
          dataIndex: InstitutionCourseCreditDTO.maximum,
          render: (data: any, row: any, index: number) => {
            return (
              <FormItem
                {...this.getCreditFormItems(index).get(InstitutionCourseCreditDTO.maximum)}
                {...ValidationUtil.getValidation(InstitutionCourseCreditDTO.maximum + index, this.state.fieldErrors, this.state.submitted)}>
                <InputNumber disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
              </FormItem>
            );
          }
        });
    }

    return columns;
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();

    return (
      <Form
        ref={this._formRef}
        layout="vertical"
        requiredMark={true}
        initialValues={this.state.institutionCourseDetail}
        onValuesChange={this.handleChange}
        onFinish={this.handleSave}>
        <Space direction='vertical'>
          <FormItem
            {...formItems.get(InstitutionCourseDetailDTO.description)}
            {...ValidationUtil.getValidation(InstitutionCourseDetailDTO.description, this.state.fieldErrors, this.state.submitted)}>
            <Input.TextArea maxLength={5000} showCount={true} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...formItems.get(InstitutionCourseDetailDTO.categoryId)}
            {...ValidationUtil.getValidation(InstitutionCourseDetailDTO.categoryId, this.state.fieldErrors, this.state.submitted)}>
            <Select disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
              {this.state.categories.map(x => { return this.renderCategories(x) })}
            </Select>
          </FormItem>

          <FormItem
            {...formItems.get(InstitutionCourseDetailDTO.variableHours)}
            {...ValidationUtil.getValidation(InstitutionCourseDetailDTO.variableHours, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <Form.List name={InstitutionCourseDetailDTO.institutionCourseCredits}>
            {(credits) => {
              return <Table dataSource={credits} columns={this.getCreditColumns()} pagination={false} />
            }}
          </Form.List>

          <FormItem
            {...formItems.get(InstitutionCourseDTO.install)}
            rules={[]} >
            <TermInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...formItems.get(InstitutionCourseDTO.retire)}
            rules={[]}>
            <TermInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderActions()}
        </Space>
      </Form >
    );
  }

  renderCategories(category: CategoryDTO) {
    if (category.id) {
      return <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>;
    }
  }

  renderActions() {
    const formItems = this.getFormItems();
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_COURSE]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!FormUtil.canSubmit(this._formRef, formItems)} saving={this.state.submitting} />
            <ResetButton disabled={!FormUtil.hasChanged(this._formRef, formItems)} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }
}

export default InstitutionCourseDetailsForm