// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SecondaryProgramDTO from '../models/SecondaryProgramDTO';
import ProgramMasterReportDataTableProviderDTO from '../models/ProgramMasterReportDataTableProviderDTO';
import * as AcademicYearHasSecondaryProgramsHandler from '../handlerModels/AcademicYearHasSecondaryProgramsHandler';
import * as GetSecondaryProgramDetailsHandler from '../handlerModels/GetSecondaryProgramDetailsHandler';
import * as GetSecondaryProgramsHandler from '../handlerModels/GetSecondaryProgramsHandler';
import * as SaveSecondaryProgramDetailsHandler from '../handlerModels/SaveSecondaryProgramDetailsHandler';
import * as DeleteSecondaryProgramHandler from '../handlerModels/DeleteSecondaryProgramHandler';
import * as SearchForSecondaryProgramsHandler from '../handlerModels/SearchForSecondaryProgramsHandler';

export class SecondaryProgramApiService extends BaseApi {

    // post: api/secondaryPrograms?academicYearId=${academicYearId}&isPublic=${isPublic}&id=${encodeURIComponent(id)}
    public getSecondaryProgramTableData(tableRequest: TableRequestDTO, academicYearId: number, isPublic: boolean, id: string): Promise<TableResponseDTO<SecondaryProgramDTO>> {
        let url = `api/secondaryPrograms`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }
        if (isPublic != null) {
            url += `${prefix}isPublic=${isPublic}`;
            prefix = '&';
        }
        if (id != null) {
            url += `${prefix}id=${encodeURIComponent(id)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SecondaryProgramDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/secondaryPrograms/programMasterReport?academicYearId=${academicYearId}
    public getProgramMasterReportTableData(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<ProgramMasterReportDataTableProviderDTO>> {
        let url = `api/secondaryPrograms/programMasterReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ProgramMasterReportDataTableProviderDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/secondaryPrograms/export?AcademicYearId=${AcademicYearId}
    public getProgramMasterReportDataTableExport(tableRequest: TableRequestDTO, academicYearId: number): Promise<string> {
        let url = `api/secondaryPrograms/export`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}AcademicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/secondaryPrograms/hasPrograms/${academicYear}
    public academicYearHasSecondaryPrograms(academicYear: number): Promise<AcademicYearHasSecondaryProgramsHandler.Result> {
        let url = `api/secondaryPrograms/hasPrograms/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AcademicYearHasSecondaryProgramsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/secondaryPrograms/get
    public getSecondaryProgramDetails(getSecondaryProgramDetails: GetSecondaryProgramDetailsHandler.Request): Promise<GetSecondaryProgramDetailsHandler.Result> {
        let url = `api/secondaryPrograms/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryProgramDetailsHandler.Result>(getSecondaryProgramDetails, 'post', url, true, false);
    }

    // post: api/secondaryPrograms/getSecondaryPrograms
    public getSecondaryPrograms(getSecondaryPrograms: GetSecondaryProgramsHandler.Request): Promise<GetSecondaryProgramsHandler.Result> {
        let url = `api/secondaryPrograms/getSecondaryPrograms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryProgramsHandler.Result>(getSecondaryPrograms, 'post', url, true, false);
    }

    // post: api/secondaryPrograms/save
    public saveSecondaryProgramDetails(saveSecondaryProgramDetails: SaveSecondaryProgramDetailsHandler.Request): Promise<SaveSecondaryProgramDetailsHandler.Result> {
        let url = `api/secondaryPrograms/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSecondaryProgramDetailsHandler.Result>(saveSecondaryProgramDetails, 'post', url, true, false);
    }

    // post: api/secondaryPrograms/deleteSecondaryProgram
    public deleteSecondaryProgram(deleteSecondaryProgram: DeleteSecondaryProgramHandler.Request): Promise<DeleteSecondaryProgramHandler.Result> {
        let url = `api/secondaryPrograms/deleteSecondaryProgram`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteSecondaryProgramHandler.Result>(deleteSecondaryProgram, 'post', url, true, false);
    }

    // post: api/secondaryPrograms/secondaryProgramImporter
    public secondaryProgramImporter(secondaryProgramImporter: FormData): Promise<any> {
        let url = `api/secondaryPrograms/secondaryProgramImporter`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(secondaryProgramImporter, 'post', url, false, false);
    }

    // post: api/secondaryPrograms/searchForSecondaryPrograms
    public searchForSecondaryPrograms(searchForSecondaryPrograms: SearchForSecondaryProgramsHandler.Request): Promise<SearchForSecondaryProgramsHandler.Result> {
        let url = `api/secondaryPrograms/searchForSecondaryPrograms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForSecondaryProgramsHandler.Result>(searchForSecondaryPrograms, 'post', url, true, false);
    }

    // get: api/secondaryPrograms/secondaryProgramTemplate
    public secondaryProgramTemplate(): Promise<any> {
        let url = `api/secondaryPrograms/secondaryProgramTemplate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new SecondaryProgramApiService();
export default service;
