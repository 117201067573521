import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AnnouncementListing from '../../components/AnnouncementListing';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import HeaderPortal from '../../portals/HeaderPortal';
import BaseFormState from '../../redux/bases/BaseFormState';
import HistoryUtil from '../../utils/HistoryUtil';

interface PastAnnouncementsPageState extends BaseFormState {
}

interface PastAnnouncementsPageProps {
}

class PastAnnouncementsPage extends React.Component<PastAnnouncementsPageProps & RouteComponentProps<RouteObject>, PastAnnouncementsPageState> {

  constructor(props: PastAnnouncementsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

  }

  private goToHome = () => {
    HistoryUtil.push(Routes.generate(Routes.HOME));
  }

  render() {
    return (
      <Content >
        <HeaderPortal title="Announcements" onBack={this.goToHome} breadcrumbs={Breadcrumbs.allAnnouncements()} />
        <Card >
          <AnnouncementListing includeShown={true} fetchAmount={15} />
        </Card>
      </Content>
    );
  }
}

export default withRouter(PastAnnouncementsPage);