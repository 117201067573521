// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import TransferMajorLactsCategoryDTO from '../models/TransferMajorLactsCategoryDTO';
import * as GetTransferMajorLactsCategoryDetailsHandler from '../handlerModels/GetTransferMajorLactsCategoryDetailsHandler';
import * as SaveTransferMajorLactsCategoryDetailsHandler from '../handlerModels/SaveTransferMajorLactsCategoryDetailsHandler';
import * as DeleteTransferMajorLactsCategoryHandler from '../handlerModels/DeleteTransferMajorLactsCategoryHandler';

export class TransferMajorLactsCategoryApiService extends BaseApi {

    // post: api/transferMajorLactsCategory
    public getTransferMajorLactsCategorysDataTable(tableRequest: TableRequestDTO): Promise<TableResponseDTO<TransferMajorLactsCategoryDTO>> {
        let url = `api/transferMajorLactsCategory`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<TransferMajorLactsCategoryDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/transferMajorLactsCategory/${id}
    public getTransferMajorLactsCategoryDetails(id: number): Promise<GetTransferMajorLactsCategoryDetailsHandler.Result> {
        let url = `api/transferMajorLactsCategory/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetTransferMajorLactsCategoryDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/transferMajorLactsCategory/export
    public getTransferMajorLactsCategoryDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/transferMajorLactsCategory/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/transferMajorLactsCategory/save
    public saveTransferMajorLactsCategoryDetails(saveTransferMajorLactsCategoryDetails: SaveTransferMajorLactsCategoryDetailsHandler.Request): Promise<SaveTransferMajorLactsCategoryDetailsHandler.Result> {
        let url = `api/transferMajorLactsCategory/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveTransferMajorLactsCategoryDetailsHandler.Result>(saveTransferMajorLactsCategoryDetails, 'post', url, true, false);
    }

    // post: api/transferMajorLactsCategory/deleteTransferMajorLactsCategory
    public deleteTransferMajorLactsCategory(deleteTransferMajorLactsCategory: DeleteTransferMajorLactsCategoryHandler.Request): Promise<DeleteTransferMajorLactsCategoryHandler.Result> {
        let url = `api/transferMajorLactsCategory/deleteTransferMajorLactsCategory`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteTransferMajorLactsCategoryHandler.Result>(deleteTransferMajorLactsCategory, 'post', url, true, false);
    }
}
var service = new TransferMajorLactsCategoryApiService();
export default service;
