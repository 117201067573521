import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, DatePicker, message, Select, Skeleton, Space, Typography } from 'antd';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import InstitutionUsersApiService from '../../../api/InstitutionUsersApiService';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import * as SaveNewProgramChangeRequestStep4Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep4Handler';
import * as SearchForConsultantsHandler from '../../../handlerModels/SearchForConsultantsHandler';
import * as GetNewProgramChangeRequestStep4Handler from '../../../handlerModels/GetNewProgramChangeRequestStep4Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO from '../../../models/NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO';
import NoticeOfIntentChangeRequestConsultantMeetingDTO from '../../../models/NoticeOfIntentChangeRequestConsultantMeetingDTO';
import NoticeOfIntentChangeRequestDTO from '../../../models/NoticeOfIntentChangeRequestDTO';
import UserDTO from '../../../models/UserDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import Dropdown from '../../inputs/Dropdown';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep4FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
  noticeOfIntent: NoticeOfIntentChangeRequestDTO;
  userSearch: UserDTO[];
  consultants: UserDTO[];
}

interface NewProgramChangeRequestStep4FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: () => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep4Form extends React.Component<NewProgramChangeRequestStep4FormProps, NewProgramChangeRequestStep4FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getConsultantMeetingFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestConsultantMeetingDTO.date, {
        label: 'When was this consultant meeting?',
        name: [index, NoticeOfIntentChangeRequestConsultantMeetingDTO.date],
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestConsultantMeetingDTO.consultantIds, {
        label: 'Consultants',
        name: [index, NoticeOfIntentChangeRequestConsultantMeetingDTO.consultantIds],
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
  }

  constructor(props: NewProgramChangeRequestStep4FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      newProgramChangeRequest: NewProgramChangeRequestDTO.create(),
      noticeOfIntent: NoticeOfIntentChangeRequestDTO.create(),
      consultants: [],
      userSearch: []
    };
  }

  private cleanDates = (noi: NoticeOfIntentChangeRequestDTO) => {
    noi?.consultantMeetings?.forEach(meeting => {
      meeting.date = moment(meeting.date);
    });

    return noi;
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep4FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.consultants.length == 0) {
      loaders.push(this.getConsultants());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest())
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private getConsultants = () => {
    const request = SearchForConsultantsHandler.Request.create({
      institutionId: this.props.selectedInstitution,
    })

    return InstitutionUsersApiService.searchForConsultants(request)
      .then((results: SearchForConsultantsHandler.Result) => {
        if (results.users) {
          this.setState({ consultants: results.users })
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep4Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep4(request)
      .then((results: GetNewProgramChangeRequestStep4Handler.Result) => {
        if (results) {
          let noi = NoticeOfIntentChangeRequestDTO.create({ ...results.changeRequest?.newProgramChangeRequest }) as NoticeOfIntentChangeRequestDTO ?? NoticeOfIntentChangeRequestDTO.create();

          noi = this.cleanDates(noi);

          if (noi.consultantMeetings?.length == 0) {
            noi.consultantMeetings = [
              NoticeOfIntentChangeRequestConsultantMeetingDTO.create({
                consultants: [
                  NoticeOfIntentChangeRequestConsultantMeetingConsultantDTO.create({
                    userId: Guid.Empty()
                  })
                ],
                date: ''
              })
            ]
          }

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            noticeOfIntent: noi,
            newProgramChangeRequest: results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private handleChange = (altered: boolean) => {
    this.setState({ altered: altered });
  }

  private handleSave = () => {
    this.setState({ saving: true });
    const noticeOfIntent = this.state.newProgramChangeRequest;
    noticeOfIntent.consultantMeetings = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.consultantMeetings) ?? [];
    let newProgramChangeRequest = this.state.changeRequest.newProgramChangeRequest;
    newProgramChangeRequest = noticeOfIntent;

    const request = SaveNewProgramChangeRequestStep4Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest,
    });

    NewProgramChangeRequestApiService.saveStep4(request)
      .then((result: SaveNewProgramChangeRequestStep4Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const noticeOfIntent = this.state.newProgramChangeRequest;
    noticeOfIntent.consultantMeetings = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.consultantMeetings) ?? [];
    let newProgramChangeRequest = this.state.changeRequest.newProgramChangeRequest;
    newProgramChangeRequest = noticeOfIntent;

    const request = SaveNewProgramChangeRequestStep4Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest,
    });

    NewProgramChangeRequestApiService.submitStep4(request)
      .then((result: SaveNewProgramChangeRequestStep4Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.noticeOfIntent}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}>

          <Space direction="vertical" >
            <Typography.Paragraph italic={true}>
              The IDOE requires at least one meeting with our consultants prior to submission. Please review these meetings below.
            </Typography.Paragraph>

            {this.renderConsultantMeetings()}
            {this.renderActions()}
          </Space>
        </Form >
      </Space >
    );
  }

  private renderActions() {
    if (!this.props.readonly) {
      return < Space direction='horizontal' wrap={true} >
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved}></SaveButton>
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space >
    }
  }

  renderConsultantMeetings() {
    return (
      <Form.List name={NoticeOfIntentChangeRequestDTO.consultantMeetings}>
        {
          (meetings, { add, remove }) => {
            return (
              <Space direction='vertical'>
                {
                  meetings.map((meeting, index) => {
                    if (meeting) {
                      return this.renderConsultantMeeting(meeting, index, remove);
                    }
                  })
                }
                {this.renderAdditionalMeeting(add)}
              </Space>
            );
          }
        }
      </Form.List>
    );
  }

  renderAdditionalMeeting(add: any) {
    if (!this.props.readonly) {
      return (
        <Button onClick={() => add()} icon={<PlusOutlined />}>
          Add Additional Consultant Meeting
        </Button>
      );
    }
  }

  renderConsultantMeeting(meeting: any, meetingIndex: number, remove: any) {
    const multipleMeetings = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.consultantMeetings)?.length > 1
    const title = 'Consultant Meeting ' + (multipleMeetings ? (meetingIndex + 1) : '');
    const deleteButton = multipleMeetings && !this.props.readonly ?
      <Button type="link" size="small" onClick={() => remove(meeting.name)} icon={<DeleteOutlined />} /> :
      null;

    return (
      <Card type='inner' size='small' title={title} extra={deleteButton}>
        <Space direction="vertical" >
          <FormItem
            {...this.getConsultantMeetingFormItems(meetingIndex).get(NoticeOfIntentChangeRequestConsultantMeetingDTO.date)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestConsultantMeetingDTO.date + meetingIndex, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <DatePicker format='M/D/YYYY' disabled={this.props.readonly} disabledDate={DateTimeUtil.isFutureDate} />
          </FormItem>

          <Typography.Paragraph >
            Please list all IDOE consultants that you met with.
          </Typography.Paragraph>
          <FormItem
            {...this.getConsultantMeetingFormItems(meetingIndex).get(NoticeOfIntentChangeRequestConsultantMeetingDTO.consultantIds)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestConsultantMeetingDTO.consultantIds + meetingIndex, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Dropdown
              disabled={this.props.readonly}
              mode="multiple"
              showSearch
              optionFilterProp="children">
              {this.state.consultants.map(x => { return this.renderConsultantOption(x); })}
            </Dropdown>
          </FormItem>
        </Space >
      </Card >
    )
  }

  renderConsultantOption(user: UserDTO) {
    return <Select.Option key={user.id ?? Guid.Empty()} value={user.id ?? Guid.Empty()}>{user.fullDisplay}</Select.Option>;
  }

  renderAddConsultant(add: any) {
    if (!this.props.readonly) {
      return <Button icon={<PlusOutlined />} onClick={() => add()}>Add Consultant</Button>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }

}

export default NewProgramChangeRequestStep4Form