
import { LockOutlined, MoreOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal, Space, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import UsersApiService from '../../api/UsersApiService';
import AuthorizedContent from '../../components/AuthorizedContent';
import EditCancelButton from '../../components/buttons/EditCancelButton';
import WorkItemDataTable from '../../components/datatables/WorkItemDataTable';
import AssignmentsDisplay from '../../components/displays/AssignmentsDisplay';
import OrganizationDisplay from '../../components/displays/OrganizationsDisplay';
import UserDetailsForm from '../../components/forms/UserDetailsForm';
import UserRolesForm from '../../components/forms/UserRolesForm';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import StatusType from '../../consts/StatusType';
import * as DeleteUserHandler from '../../handlerModels/DeleteUserHandler';
import * as GetUserDetailsHandler from '../../handlerModels/GetUserDetailsHandler';
import * as LockUserHandler from '../../handlerModels/LockUserHandler';
import * as UnlockUserHandler from '../../handlerModels/UnlockUserHandler';
import * as ResendUserConfirmationEmailHandler from '../../handlerModels/ResendUserConfirmationEmailHandler';
import * as ResendResetPasswordEmailHandler from '../../handlerModels/ResendResetPasswordEmailHandler';
import * as ResendConfirmEmailHandler from '../../handlerModels/ResendConfirmEmailHandler';
import UserDTO from '../../models/UserDTO';
import UserSecurity from '../../models/UserSecurity';
import HeaderPortal from '../../portals/HeaderPortal';
import BaseFormState from '../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import DateUtil from '../../utils/DateTimeUtil';
import Guid from '../../utils/Guid';
import HistoryUtil from '../../utils/HistoryUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';
import ParameterUtil from '../../utils/ParameterUtil';
import AccountApiService from '../../api/AccountApiService';

interface UserDetailsState extends BaseFormState {
  userId: string;
  user: UserDTO;
  activeTab: string;
  subTabOrganizations: string;
  subTabCipNumber: string;
}

interface UserDetailsProps {
  currentUser: UserSecurity | null;
}

class UserDetailsPage extends React.Component<UserDetailsProps & RouteComponentProps<RouteObject>, UserDetailsState> {
  private static readonly USER_DETAILS = 'USER_DETAILS';
  private static readonly USER_ROLES = 'USER_ROLES';

  private readonly _userDetailsRef = React.createRef<UserDetailsForm>();
  private readonly _userRolesRef = React.createRef<UserRolesForm>();

  constructor(props: UserDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      userId: Guid.Empty(),
      user: UserDTO.create(),
      editings: new Map<string, boolean>(),
      activeTab: 'details',
      subTabOrganizations: 'districts',
      subTabCipNumber: 'serviceArea'
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');
    const activeTab = ParameterUtil.getParameter('activeTab', 'details');
    const subTabOrganization = ParameterUtil.getParameter('subTabOrganization', 'districts');
    const subTabCipNumber = ParameterUtil.getParameter('subTabCipNumber', 'serviceArea');
    this.setState({ activeTab: activeTab, subTabOrganizations: subTabOrganization, subTabCipNumber: subTabCipNumber });

    if (id == 'new') {
      this.toggleIsEditing(UserDetailsPage.USER_DETAILS, true);
    }
    else {
      this.loadUser(id);
    }
  }

  private loadUser = (id: string) => {
    this.setState({ loading: true, userId: id });
    UsersApiService.getUserDetails(id)
      .then((results: GetUserDetailsHandler.Result) => {
        if (results.user) {
          this.setState({
            user: results.user
          });
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private toggleIsEditing = (key: string, value?: boolean) => {
    if (this.state.editings) {
      const editings = this.state.editings;

      if (value !== undefined) {
        editings.set(key, value);
      }
      else {
        editings.set(key, !editings.get(key) ?? true);
      }

      if (!editings.get(key)) {
        switch (key) {
          case UserDetailsPage.USER_DETAILS:
            this._userDetailsRef.current?.resetForm();
            break;
          case UserDetailsPage.USER_ROLES:
            this._userRolesRef.current?.resetForm();
            break;
        }
      }

      this.setState({ editings: editings });
    }
  }

  private isEditingCheck = () => {
    if (this.state.editings) {
      return Array.from(this.state.editings.values()).includes(true);
    }

    return false;
  };

  private userDetailsSaved = (id: string) => {
    this.toggleIsEditing(UserDetailsPage.USER_DETAILS, false);

    if (id != this.state.userId) {
      HistoryUtil.replace(Routes.generate(Routes.USER_DETAILS, { id: id }));
    }
    else {
      this.loadUser(id);
    }
  }

  private deleteConfirmation = (id: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this User?',
      okText: 'Delete',
      onOk: () => this.deleteUser(id)
    });
  }

  private resendConfirmation = (userId: string) => {
    this.setState({ submitting: true });

    const request = ResendConfirmEmailHandler.Request.create({
      userId: userId,
    });

    AccountApiService.resendConfirmEmail(request)
      .then((result: ResendConfirmEmailHandler.Result | null) => {
        if (result) {
          message.success('Email Sent');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Failed Sending Confirmation Email');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private deleteUser = (userId: string) => {
    this.setState({ submitting: true });

    const request = DeleteUserHandler.Request.create({
      userId: userId,
    });

    UsersApiService.deleteUser(request)
      .then((result: DeleteUserHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('User was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('User could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToUsers();
      });
  }

  private resendUserConfirmation = (userId: string) => {
    this.setState({ submitting: true });

    const request = ResendUserConfirmationEmailHandler.Request.create({
      userId: userId,
    });

    UsersApiService.resendUserConfirmationEmail(request)
      .then((result: ResendUserConfirmationEmailHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('User Confirmation Email sent');
        }
        else {
          message.error(result?.errors.join('\n'));
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('User Confirmation Email could not be sent.');
      })
      .finally(() => {
        this.setState({ submitting: false, loading: false });
      });
  }

  private resendResetPassword = (userId: string) => {
    this.setState({ submitting: true });

    const request = ResendResetPasswordEmailHandler.Request.create({
      userId: userId,
    });

    UsersApiService.resendResetPasswordEmail(request)
      .then((result: ResendResetPasswordEmailHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Reset Password Email sent');
        }
        else {
          message.error(result?.errors.join('\n'));
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Reset Password Email could not be sent.');
      })
      .finally(() => {
        this.setState({ submitting: false, loading: false });
      });
  }

  private userRolesSaved = () => {
    this.toggleIsEditing(UserDetailsPage.USER_ROLES, false);
  }

  private goToUsers = () => {
    HistoryUtil.push(Routes.generate(Routes.USERS));
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  private changeSubTabOrganization = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: this.state.activeTab, subTabOrganization: activeTab }), { activeTab: this.state.activeTab, subTabOrganization: activeTab });
  }

  private changeSubTabCipNumber = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: this.state.activeTab, subTabCipNumber: activeTab }), { activeTab: this.state.activeTab, subTabCipNumber: activeTab });
  }

  private handleLock = () => {
    this.setState({ submitting: true });

    const request = LockUserHandler.Request.create({
      userId: this.state.userId,
    });

    UsersApiService.lockUser(request)
      .then(() => {
        this.loadUser(this.state.userId);
      })
      .catch(() => {
        message.error('Could not lock user');
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  private handleUnlock = () => {
    this.setState({ submitting: true });

    const request = UnlockUserHandler.Request.create({
      userId: this.state.userId,
    });

    UsersApiService.unlockUser(request)
      .then(() => {
        this.loadUser(this.state.userId);
      })
      .catch(() => {
        message.error('Could not unlock user');
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    let title = this.state.loading ? '...' : this.state.userId == Guid.Empty() ? 'New User' : this.state.user.fullName ?? '...' as any;
    let subtitle = null;
    if (this.state.user.isLocked) {
      title = <span><LockOutlined /> {title}</span>;
      subtitle = 'Locked on ' + DateUtil.shortDateAndTime(this.state.user.lockoutStart);
    }

    return (
      <Content >
        <HeaderPortal
          title={title}
          subTitle={subtitle}
          onBack={this.goToUsers}
          breadcrumbs={Breadcrumbs.userDetails(title, this.state.userId)}
          extra={this.renderMoreActions()}
          footer={
            <Tabs activeKey={this.state.activeTab} key='content' onChange={this.changeTab}>
              <Tabs.TabPane key='details' tab='Details' />
              <Tabs.TabPane key='work_items' tab='Work Items' />
              <Tabs.TabPane key='organizations' tab='Organizations' />
              <Tabs.TabPane key='assignments' tab='Assignments' />
              <Tabs.TabPane key='roles' tab='Roles' />
            </Tabs>
          }>
        </HeaderPortal>

        <PageStayPrompt when={this.isEditingCheck} />

        {this.renderDetailsCard()}
        {this.renderWorkItemsCard()}
        {this.renderCipAssignments()}
        {this.renderRolesCard()}
        {this.renderOrganizations()}
      </Content >
    );
  }

  renderDetailsCard() {
    if (this.state.activeTab === 'details') {
      if (this.state.editings) {
        const editButton = this.state.userId == Guid.Empty() ?
          null :
          <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_USER, FeatureFlag.MANAGE_AA_USER]}>
            <EditCancelButton onClick={() => this.toggleIsEditing(UserDetailsPage.USER_DETAILS)} isEditing={this.state.editings.get(UserDetailsPage.USER_DETAILS)} />
          </AuthorizedContent>;

        return (
          <Card title="Details" extra={editButton}>
            <UserDetailsForm
              ref={this._userDetailsRef}
              userId={this.state.userId}
              isEditing={this.state.editings.get(UserDetailsPage.USER_DETAILS)}
              onSave={this.userDetailsSaved} />
          </Card>
        );
      }
    }
  }

  renderRolesCard() {
    if (this.state.activeTab === 'roles') {
      if (this.state.userId != Guid.Empty()) {
        if (this.state.editings) {
          const editButton = this.state.userId == Guid.Empty() ?
            null :
            <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_USER]}>
              <EditCancelButton onClick={() => this.toggleIsEditing(UserDetailsPage.USER_ROLES)} isEditing={this.state.editings.get(UserDetailsPage.USER_ROLES)} />
            </AuthorizedContent>

          return (
            <Card title="Roles" extra={editButton}>
              <UserRolesForm
                ref={this._userRolesRef}
                userId={this.state.userId}
                isEditing={this.state.editings.get(UserDetailsPage.USER_ROLES)}
                onSave={this.userRolesSaved} />
            </Card >
          );
        }
      }
    }
  }

  renderOrganizations() {
    if (this.state.activeTab === 'organizations' && this.state.userId) {
      if (this.state.userId != Guid.Empty()) {
        return (
          <Card title="Organizations">
            <OrganizationDisplay subTab={this.state.subTabOrganizations} onChangeTab={this.changeSubTabOrganization} userId={this.state.userId} />
          </Card >
        );
      }
    }
  }

  renderWorkItemsCard() {
    if (this.state.activeTab === 'work_items') {
      if (this.state.userId != Guid.Empty()) {
        return (
          <Card title="Work Items">
            <WorkItemDataTable currentUserId={this.props.currentUser?.userId ?? Guid.Empty()} userId={this.state.userId} />
          </Card >
        );
      }
    }
  }

  renderCipAssignments() {
    if (this.state.activeTab === 'assignments') {
      if (this.state.userId != Guid.Empty()) {
        return (
          <Card title="Assignments">
            <AssignmentsDisplay onChangeTab={this.changeSubTabCipNumber} subTab={this.state.subTabCipNumber} userId={this.state.userId} />
          </Card >
        );
      }
    }
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete User' key={'delete_' + this.state.userId} onClick={() => this.deleteConfirmation(this.state.userId)}  >
          Delete User
        </Menu.Item>
        {
          this.state.user.statusTypeId === StatusType.PENDING && AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN]) ?
            < Menu.Item title='Resend Confirmation Email' key={'resend_' + this.state.userId} onClick={() => this.resendConfirmation(this.state.userId)}  >
              Resend Confirmation Email
            </Menu.Item>
            : null}
        {
          AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN]) ?
            < Menu.Item title='Send Password Reset' key={this.state.userId} onClick={() => this.resendResetPassword(this.state.userId)}  >
              Send Password Reset
            </Menu.Item>
            : null
        }
      </Menu>
    );

    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_USER]}>
        <Space direction="horizontal">
          {this.renderUnlockLock()}
          <Dropdown overlay={menu}>
            <Button size='large' type='link' >
              <MoreOutlined />
            </Button>
          </Dropdown>
        </Space>
      </AuthorizedContent>
    );
  }

  renderUnlockLock() {
    if (this.state.user.isLocked) {
      return <Button onClick={this.handleUnlock} loading={this.state.submitting} icon={<UnlockOutlined />}>Unlock User</Button>
    }
    else if (!this.state.user.isLocked) {
      return <Button type='default' danger={true} onClick={this.handleLock} loading={this.state.submitting} icon={<LockOutlined />}>Lock User</Button>
    }
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected,
    currentUser: state.UserSession.Value
  };
}

export default withRouter(connect(mapStateToProps)(UserDetailsPage));
