import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Form, FormInstance, FormItemProps, message, Modal, Space } from 'antd';
import * as React from 'react';
import SecondaryCourseApiService from '../../api/SecondaryCourseApiService';
import Routes from '../../config/Routes';
import * as SecondaryCourseImporterHandler from '../../handlerModels/SecondaryCourseImporterHandler';
import SecondaryCourseDTO from '../../models/SecondaryCourseDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import FileDownload from '../../utils/FileDownload';
import FileUploadUtil from '../../utils/FileUploadUtil';
import FormUtil from '../../utils/FormUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import DataTable from '../datatables/core/DataTable';
import FileUpload from '../FileUpload';

interface SecondaryCoursesImporterProps extends BaseFormProps {
  academicYear: number;
  refreshDataTable?: DataTable<SecondaryCourseDTO>;
  isInModal?: boolean;
  onSubmit?: (errors?: string[]) => void
}

interface SecondaryCoursesImporterState extends BaseFormState {
}

class SecondaryCoursesImporter extends React.Component<SecondaryCoursesImporterProps, SecondaryCoursesImporterState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(SecondaryCourseImporterHandler.Request.file, {
        name: SecondaryCourseImporterHandler.Request.file,
        label: 'Please select a file to import courses',
      });
  }

  constructor(props: SecondaryCoursesImporterProps) {
    super(props);

    this.state = {
    };
  }

  private resetForm = () => {
    this._formRef.current?.resetFields();
  }

  public confirmImport = () => {
    const model = this._formRef.current?.getFieldsValue() ?? {} as SecondaryCourseImporterHandler.Request;
    Modal.confirm({
      title: 'Reset Data',
      content: 'Importing this file will delete the current data.',
      onOk: () => this.handleSubmit(model),
      width: 500
    });
  }

  public handleSubmit = (model: any) => {
    this.setState({ submitting: true });

    if (!FormUtil.canSubmit(this._formRef, this.getFormItems())) {
      message.error('Please fill out all required fields.');
      this.setState({ loading: false, submitting: false });
    }

    else {
      const file = model.file.newFiles[0];
      const request = FileUploadUtil.attachFileToModel('secondaryCourseImporter', { academicYearId: this.props.academicYear }, SecondaryCourseImporterHandler.Request.file, file)
      this.setState({ loading: true });

      SecondaryCourseApiService.secondaryCourseImporter(request)
        .then((result: SecondaryCourseImporterHandler.Result) => {
          this.resetForm();
          this.setState({ submitting: false });
          this.props.refreshDataTable?.resetTable();
          const fileName = 'Course Summary Report.xlsx';
          FileDownload.downloadBase64StringAsExcel(fileName, result ?? undefined);
        }).catch(() => {
          message.error('Import Failed. Please check formatting in file.');
          this.setState({ error: true })
        }).finally(() => {
          this.setState({ loading: false });
          if (this.props.onSubmit) {
            this.props.onSubmit([]);
          }
        });
    }
  }

  public downloadTemplate = () => {
    window.open(Routes.generateFull(Routes.SECONDARY_COURSE_IMPORT_TEMPLATE, {}, {}), '_blank')
  }

  render() {
    return (
      <Space direction="vertical">
        <Form layout='vertical' onFinish={this.handleSubmit} ref={this._formRef}>
          <Space direction="vertical">
            <Form.Item
              {...this.getFormItems().get(SecondaryCourseImporterHandler.Request.file)}
              {...ValidationUtil.getValidation(SecondaryCourseImporterHandler.Request.file, this.state.fieldErrors, this.state.submitted)}>
              <FileUpload maxCount={1} style={{ width: 300 }} />
            </Form.Item>
            {this.props.isInModal ? null :
              <Space direction='horizontal'>
                <Button
                  htmlType='submit'
                  type='primary'
                  icon={<UploadOutlined />}
                  loading={this.state.loading} >Import</Button>
                <Button icon={<DownloadOutlined />} type='link' onClick={this.downloadTemplate}>Download Template</Button>
              </Space>}
            {this.renderErrors()}
          </Space>
        </Form>
      </Space>
    );
  }

  renderErrors() {
    if (this.state.error && this.state.message) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default SecondaryCoursesImporter;
