import { PercentageOutlined } from '@ant-design/icons';
import { Alert, Col, Input, InputNumber, message, Row, Select, Skeleton, Space, Table, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import AwardTypeApiService from '../../../api/AwardTypeApiService';
import { ColumnWidths } from '../../../config/ColumnWidths';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetAwardTypeDetailsHandler from '../../../handlerModels/GetAwardTypeDetailsHandler';
import * as SaveAwardTypeDetailsHandler from '../../../handlerModels/SaveAwardTypeDetailsHandler';
import AwardTypeAPSGeneralEducationCreditsDTO from '../../../models/AwardTypeAPSGeneralEducationCreditsDTO';
import AwardTypeDTO from '../../../models/AwardTypeDTO';
import AwardTypeGeneralEducationCreditsDTO from '../../../models/AwardTypeGeneralEducationCreditsDTO';
import InstructionalLevelDTO from '../../../models/InstructionalLevelDTO';
import IowaVariationDTO from '../../../models/IowaVariationDTO';
import ObjectPurposeDTO from '../../../models/ObjectPurposeDTO';
import SpecialEmphasisDTO from '../../../models/SpecialEmphasisDTO';
import TypeOfProgramDTO from '../../../models/TypeOfProgramDTO';
import ProgramClassificationDTO from '../../../models/ProgramClassificationDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import Dropdown from '../../inputs/Dropdown';
import YesNoInput from '../../inputs/YesNoInput';

interface AwardTypeDetailsFormState extends BaseFormState {
  awardTypeId: number;
  awardType: AwardTypeDTO;
  iowaVariations: IowaVariationDTO[];
  instructionalLevels: InstructionalLevelDTO[];
  typeOfProgram: TypeOfProgramDTO[];
  specialEmphases: SpecialEmphasisDTO[];
  objectPurpose: ObjectPurposeDTO[];
  programClassifications: ProgramClassificationDTO[];
}

interface AwardTypeDetailsFormProps extends BaseFormProps {
  awardTypeId: number;
  onSave?: (awardTypeId: number) => void;
}

class AwardTypeDetailsForm extends React.Component<AwardTypeDetailsFormProps, AwardTypeDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(AwardTypeDTO.name, {
      required: true,
      name: AwardTypeDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    })
    .set(AwardTypeDTO.code, {
      required: true,
      name: AwardTypeDTO.code,
      label: 'Code',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(10)
      ],
    })
    .set(AwardTypeDTO.order, {
      required: true,
      name: AwardTypeDTO.order,
      label: 'Order',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(AwardTypeDTO.minCredits, {
      required: true,
      name: AwardTypeDTO.minCredits,
      label: 'Minimum Total Credits',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(AwardTypeDTO.maxCredits, {
      required: true,
      name: AwardTypeDTO.maxCredits,
      label: 'Maximum Total Credits',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(AwardTypeDTO.maxWeeks, {
      required: true,
      name: AwardTypeDTO.maxWeeks,
      label: 'Maximum Total Weeks',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(AwardTypeDTO.averageCredits, {
      required: true,
      name: AwardTypeDTO.averageCredits,
      label: 'Maximum Average Term Credits',
      rules: [
      ],
    })
    .set(AwardTypeDTO.generalEducationCredits, {
      required: true,
      name: AwardTypeDTO.generalEducationCredits,
      label: 'Minimum General Education Credits',
      rules: [],
    })
    .set(AwardTypeDTO.generalEducationCategories, {
      required: true,
      name: AwardTypeDTO.generalEducationCategories,
      label: 'Minimum General Education Categories',
      rules: [],
    })
    .set(AwardTypeDTO.techCorePercentage, {
      required: true,
      name: AwardTypeDTO.techCorePercentage,
      label: 'Minimum Tech Core Percentage',
      rules: [],
    })
    .set(AwardTypeDTO.nonTrasferMajorProgramTypeId, {
      name: AwardTypeDTO.nonTrasferMajorProgramTypeId,
      label: 'Program Classification',
    })
    .set(AwardTypeDTO.transferMajorProgramTypeId, {
      name: AwardTypeDTO.transferMajorProgramTypeId,
      label: 'Transfer Major Program Classification',
    })
    .set(AwardTypeDTO.transferMajor, {
      required: true,
      name: AwardTypeDTO.transferMajor,
      label: 'Available for Transfer Major Programs',
      rules: [],
    })
    .set(AwardTypeDTO.iowaVariationIds, {
      required: true,
      name: AwardTypeDTO.iowaVariationIds,
      label: 'Available Iowa Variations',
      rules: [],
    })
    .set(AwardTypeDTO.instructionalLevels, {
      required: true,
      name: AwardTypeDTO.instructionalLevels,
      label: 'Available Instructional Levels',
      rules: [],
    })
    .set(AwardTypeDTO.typeOfPrograms, {
      required: true,
      name: AwardTypeDTO.typeOfPrograms,
      label: 'Available Type of Programs',
      rules: [],
    })
    .set(AwardTypeDTO.specialEmphases, {
      required: true,
      name: AwardTypeDTO.specialEmphases,
      label: 'Available Special Emphases',
      rules: [],
    })
    .set(AwardTypeDTO.objectPurposes, {
      required: true,
      name: AwardTypeDTO.objectPurposes,
      label: 'Available Object Purposes',
      rules: [],
    });

  private getGenEdFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(AwardTypeGeneralEducationCreditsDTO.minimumCredits, {
        required: true,
        name: [index, AwardTypeGeneralEducationCreditsDTO.minimumCredits],
        rules: [ValidationRuleUtil.required()]
      });
  }

  private getApsGenEdFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(AwardTypeGeneralEducationCreditsDTO.minimumCredits, {
        required: true,
        name: [index, AwardTypeGeneralEducationCreditsDTO.minimumCredits],
        rules: [ValidationRuleUtil.required()]
      });
  }

  constructor(props: AwardTypeDetailsFormProps) {
    super(props);

    this.state = {
      awardTypeId: 0,
      awardType: AwardTypeDTO.create({ iowaVariationIds: [], programClassificationId: null }),
      iowaVariations: [],
      instructionalLevels: [],
      typeOfProgram: [],
      specialEmphases: [],
      objectPurpose: [],
      programClassifications: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const loaders = [];

    loaders.push(this.loadAwardType());
    loaders.push(this.loadInstructionalLevels());
    loaders.push(this.loadTypeOfPrograms());
    loaders.push(this.loadSpecialEmphases());
    loaders.push(this.loadObjectPurposes());
    loaders.push(this.loadProgramClassification());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(prevProps: AwardTypeDetailsFormProps) {
    if (this.props.awardTypeId && this.props.awardTypeId != prevProps.awardTypeId) {
      this.setState({ awardTypeId: this.props.awardTypeId, loading: true });
      const loaders = [];

      loaders.push(this.loadAwardType());
      loaders.push(this.loadInstructionalLevels());
      loaders.push(this.loadTypeOfPrograms());
      loaders.push(this.loadSpecialEmphases());
      loaders.push(this.loadObjectPurposes());
      loaders.push(this.loadProgramClassification());

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadAwardType() {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.iowaVariations.length == 0) {
      loaders.push(this.loadIowaVariations());
    }

    if (this.props.awardTypeId == 0) {
      this.loadNew();
    }
    else {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadProgramClassification = () => {
    return LookupsUtil.getAll<ProgramClassificationDTO>(ProgramClassificationDTO.className)
      .then((results: ProgramClassificationDTO[]) => {
        if (results) {
          this.setState({ programClassifications: results });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadIowaVariations = () => {
    return LookupsUtil.getAll<IowaVariationDTO>(IowaVariationDTO.className)
      .then((results: IowaVariationDTO[]) => {
        if (results) {
          this.setState({ iowaVariations: results });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadInstructionalLevels = () => {
    return LookupsUtil.getAll<InstructionalLevelDTO>(InstructionalLevelDTO.className)
      .then((results: InstructionalLevelDTO[]) => {
        if (results) {
          this.setState({ instructionalLevels: results });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTypeOfPrograms = () => {
    return LookupsUtil.getAll<TypeOfProgramDTO>(TypeOfProgramDTO.className)
      .then((results: TypeOfProgramDTO[]) => {
        if (results) {
          this.setState({ typeOfProgram: results });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadSpecialEmphases = () => {
    return LookupsUtil.getAll<SpecialEmphasisDTO>(SpecialEmphasisDTO.className)
      .then((results: SpecialEmphasisDTO[]) => {
        if (results) {
          this.setState({ specialEmphases: results });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadObjectPurposes = () => {
    return LookupsUtil.getAll<ObjectPurposeDTO>(ObjectPurposeDTO.className)
      .then((results: ObjectPurposeDTO[]) => {
        if (results) {
          this.setState({ objectPurpose: results });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadExisting = () => {
    if (this.props.awardTypeId) {
      return AwardTypeApiService.getAwardTypeDetails(this.props.awardTypeId)
        .then((results: GetAwardTypeDetailsHandler.Result) => {
          if (results.awardType) {
            this.setState({ awardTypeId: results.awardType.id, awardType: results.awardType });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        });
    }
  }

  private loadNew = () => {
    this.setState({ awardType: AwardTypeDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveAwardTypeDetailsHandler.Request.create({
      awardTypeId: this.state.awardTypeId,
      awardType: AwardTypeDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    AwardTypeApiService.saveAwardTypeDetails(request)
      .then((result: SaveAwardTypeDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          awardType: result?.awardType ?? AwardTypeDTO.create(),
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.awardType) {
            this.props.onSave(result.awardType.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(AwardTypeDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Award Type could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Award Type could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading || this.state.awardType == null) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.awardType}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <Space direction='vertical'>
            <Row gutter={[16, 16]}>
              <Col {...ColumnWidths.ONE_QUARTER}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.code)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.code, this.state.fieldErrors, this.state.submitted)}>
                  <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} />
                </FormItem>
              </Col>

              <Col {...ColumnWidths.THREE_QUARTERS}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.name)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.name, this.state.fieldErrors, this.state.submitted)}>
                  <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col {...ColumnWidths.ONE_QUARTER}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.order)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.order, this.state.fieldErrors, this.state.submitted)}>
                  <InputNumber min={1} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                </FormItem>
              </Col>

              <Col {...ColumnWidths.THREE_QUARTERS}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.transferMajor)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.transferMajor, this.state.fieldErrors, this.state.submitted)}>
                  <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                </FormItem>
              </Col>
            </Row>

            <FormItem
              {...this._formItems.get(AwardTypeDTO.nonTrasferMajorProgramTypeId)}
              {...ValidationUtil.getValidation(AwardTypeDTO.nonTrasferMajorProgramTypeId, this.state.fieldErrors, this.state.submitted)}>
              <Dropdown disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} >
                {this.state.programClassifications.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option> })}
              </Dropdown>
            </FormItem>

            {this.renderTransferMajorProgramType()}

            <FormItem
              {...this._formItems.get(AwardTypeDTO.iowaVariationIds)}
              {...ValidationUtil.getValidation(AwardTypeDTO.iowaVariationIds, this.state.fieldErrors, this.state.submitted)}>
              <Dropdown mode="multiple" disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} >
                {this.state.iowaVariations.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.display}</Select.Option> })}
              </Dropdown>
            </FormItem>

            <FormItem
              {...this._formItems.get(AwardTypeDTO.instructionalLevels)}
              {...ValidationUtil.getValidation(AwardTypeDTO.instructionalLevels, this.state.fieldErrors, this.state.submitted)}>
              <Dropdown mode="multiple" disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} >
                {this.state.instructionalLevels.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.display}</Select.Option> })}
              </Dropdown>
            </FormItem>

            <FormItem
              {...this._formItems.get(AwardTypeDTO.typeOfPrograms)}
              {...ValidationUtil.getValidation(AwardTypeDTO.typeOfPrograms, this.state.fieldErrors, this.state.submitted)}>
              <Dropdown mode="multiple" disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} >
                {this.state.typeOfProgram.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.display}</Select.Option> })}
              </Dropdown>
            </FormItem>

            <FormItem
              {...this._formItems.get(AwardTypeDTO.specialEmphases)}
              {...ValidationUtil.getValidation(AwardTypeDTO.specialEmphases, this.state.fieldErrors, this.state.submitted)}>
              <Dropdown mode="multiple" disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} >
                {this.state.specialEmphases.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.display}</Select.Option> })}
              </Dropdown>
            </FormItem>

            <FormItem
              {...this._formItems.get(AwardTypeDTO.objectPurposes)}
              {...ValidationUtil.getValidation(AwardTypeDTO.objectPurposes, this.state.fieldErrors, this.state.submitted)}>
              <Dropdown mode="multiple" disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} >
                {this.state.objectPurpose.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.display}</Select.Option> })}
              </Dropdown>
            </FormItem>

            <Typography.Title level={5}>
              Compliance Configuration
            </Typography.Title>

            <Row gutter={[16, 16]}>
              <Col {...ColumnWidths.ONE_THIRD}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.minCredits)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.minCredits, this.state.fieldErrors, this.state.submitted)}>
                  <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                </FormItem>
              </Col>

              <Col {...ColumnWidths.ONE_THIRD}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.maxCredits)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.maxCredits, this.state.fieldErrors, this.state.submitted)}>
                  <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                </FormItem>
              </Col>

              <Col {...ColumnWidths.ONE_THIRD}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.averageCredits)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.averageCredits, this.state.fieldErrors, this.state.submitted)}>
                  <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                </FormItem>
              </Col>

              <Col {...ColumnWidths.ONE_THIRD}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.generalEducationCategories)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.averageCredits, this.state.fieldErrors, this.state.submitted)}>
                  <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                </FormItem>
              </Col>

              <Col {...ColumnWidths.ONE_THIRD}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.generalEducationCredits)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.generalEducationCredits, this.state.fieldErrors, this.state.submitted)}>
                  <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                </FormItem>
              </Col>

              <Col {...ColumnWidths.ONE_THIRD}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.techCorePercentage)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.techCorePercentage, this.state.fieldErrors, this.state.submitted)}>
                  <InputNumber addonAfter={<PercentageOutlined />} min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                </FormItem>
              </Col>

              <Col {...ColumnWidths.ONE_THIRD}>
                <FormItem
                  {...this._formItems.get(AwardTypeDTO.maxWeeks)}
                  {...ValidationUtil.getValidation(AwardTypeDTO.order, this.state.fieldErrors, this.state.submitted)}>
                  <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
                </FormItem>
              </Col>
            </Row >

            <Typography.Title level={5}>
              General Education Credit Requirements
            </Typography.Title>
            <Form.List name={AwardTypeDTO.generalEducationCreditLimits}>
              {
                (genEds) => {
                  return (
                    <Space direction='vertical'>
                      <Table pagination={false} dataSource={genEds} columns={this.getGenEdColumns()} />
                    </Space>
                  );
                }
              }
            </Form.List>

            <Typography.Title level={5}>
              APS General Education Credit Requirements
            </Typography.Title>
            <Form.List name={AwardTypeDTO.apsGeneralEducationCreditLimits}>
              {
                (apsGenEds) => {
                  return (
                    <Space direction='vertical'>
                      <Table pagination={false} dataSource={apsGenEds} columns={this.getApsColumns()} />
                    </Space>
                  );
                }
              }
            </Form.List>

            {this.renderSave()}
          </Space>
        </Form >
      </Space >
    );
  }

  private getApsColumns = () => {
    return [
      {
        title: 'Type',
        dataIndex: AwardTypeAPSGeneralEducationCreditsDTO.apsGeneralEducation,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: any, index: number) => {
          const apsGenEd = this._formRef.current?.getFieldValue(AwardTypeDTO.apsGeneralEducationCreditLimits)[index] as AwardTypeAPSGeneralEducationCreditsDTO;
          return apsGenEd.apsGeneralEducation?.name ?? '';
        }
      },
      {
        title: 'Minimum Credits',
        dataIndex: AwardTypeAPSGeneralEducationCreditsDTO.minimumCredits,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: any, index: number) => {
          return (
            <FormItem {...this.getApsGenEdFormItems(index).get(AwardTypeAPSGeneralEducationCreditsDTO.minimumCredits)}>
              <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </FormItem>
          );
        }
      }
    ];
  }

  private getGenEdColumns = () => {
    return [
      {
        title: 'Type',
        dataIndex: AwardTypeGeneralEducationCreditsDTO.generalEducation,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: any, index: number) => {
          const apsGenEd = this._formRef.current?.getFieldValue(AwardTypeDTO.generalEducationCreditLimits)[index] as AwardTypeGeneralEducationCreditsDTO;
          return apsGenEd.generalEducation?.name ?? '';
        }
      },
      {
        title: 'Minimum Credits',
        dataIndex: AwardTypeGeneralEducationCreditsDTO.minimumCredits,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (data: string, row: any, index: number) => {
          return (
            <FormItem {...this.getGenEdFormItems(index).get(AwardTypeGeneralEducationCreditsDTO.minimumCredits)}>
              <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </FormItem>
          );
        }
      }
    ];
  }

  renderTransferMajorProgramType = () => {
    const form = this._formRef ? (this._formRef.current as any)?.getFieldsValue() : null;
    if (form && form?.transferMajor)
      return (
        <FormItem
          {...this._formItems.get(AwardTypeDTO.transferMajorProgramTypeId)}
          {...ValidationUtil.getValidation(AwardTypeDTO.transferMajorProgramTypeId, this.state.fieldErrors, this.state.submitted)}>
          <Dropdown disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true} >
            {this.state.programClassifications.map((s) => { return <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option> })}
          </Dropdown>
        </FormItem>);
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_AWARD_TYPE]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AwardTypeDetailsForm;
