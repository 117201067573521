import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select, Space, Table } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ThirdPartyCredentialTypeApiService from '../../api/ThirdPartyCredentialTypeApiService';
import Requirements from '../../consts/Requirements';
import BaseProgramThirdPartyCredentialDTO from '../../models/BaseProgramThirdPartyCredentialDTO';
import ProgramAwardDTO from '../../models/ProgramAwardDTO';
import ThirdPartyCredentialTypeDTO from '../../models/ThirdPartyCredentialTypeDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import { TableFilterOption } from '../../utils/TableFilterUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import ValueLabel from '../general/ValueLabel';
import Dropdown from '../inputs/Dropdown';
import ReadableTextBox from '../inputs/ReadableTextBox';

interface ThirdPartyCredentialDisplayProps extends BaseFormProps {
  thirdPartyCredentials: BaseProgramThirdPartyCredentialDTO[];
  awardIndex?: number;
  componentIndex?: number;
  sectionIndex?: number;
  isSecondaryProgram?: boolean;
  readonly?: boolean;
  pdfView?: boolean;
}

function ThirdPartyCredentialDisplay(props: ThirdPartyCredentialDisplayProps) {
  const [thirdPartyCredentials, setThirdPartyCredentials] = useState<ThirdPartyCredentialTypeDTO[]>([]);
  const [requirements] = useState<TableFilterOption[]>([
    { value: Requirements.OPTIONAL, text: 'Optional' },
    { value: Requirements.REQUIRED, text: 'Required' },
  ]);
  useEffect(() => {
    ThirdPartyCredentialTypeApiService.getThirdPartyCredentialTypes(props.isSecondaryProgram ?? false).then((x) => {
      setThirdPartyCredentials(x.thirdPartyCredentialType ?? []);
    });
  }, []);

  const getThirdPartyCredentialFormItems = (thirdPartyCredentialIndex: any) => {
    return new Map<string, FormItemProps>()
      .set(BaseProgramThirdPartyCredentialDTO.title, {
        name: [thirdPartyCredentialIndex, BaseProgramThirdPartyCredentialDTO.title],
        rules: [ValidationRuleUtil.required()],
      })
      .set(BaseProgramThirdPartyCredentialDTO.thirdPartyCredentialTypeId, {
        name: [thirdPartyCredentialIndex, BaseProgramThirdPartyCredentialDTO.thirdPartyCredentialTypeId],
        rules: [ValidationRuleUtil.required()],
      })
      .set(BaseProgramThirdPartyCredentialDTO.organization, {
        name: [thirdPartyCredentialIndex, BaseProgramThirdPartyCredentialDTO.organization],
        rules: [ValidationRuleUtil.required()],
      })
      .set(BaseProgramThirdPartyCredentialDTO.requirement, {
        name: [thirdPartyCredentialIndex, BaseProgramThirdPartyCredentialDTO.requirement],
        rules: [ValidationRuleUtil.required()],
      });
  };

  const renderThirdPartyCredentialType = (thirdPartyCredentialType: ThirdPartyCredentialTypeDTO) => {
    if (thirdPartyCredentialType.id && thirdPartyCredentialType.name) {
      return (
        <Select.Option
          aria-label={thirdPartyCredentialType.name}
          title={thirdPartyCredentialType.name}
          key={thirdPartyCredentialType.id}
          value={thirdPartyCredentialType.id}
        >
          {thirdPartyCredentialType.name}
        </Select.Option>
      );
    }
  };

  const renderRequirements = (requirement: TableFilterOption) => {
    return (
      <Select.Option
        aria-label={requirement.text}
        title={requirement.text}
        key={requirement.value}
        value={requirement.value}
      >
        {requirement.text}
      </Select.Option>
    );
  };

  const fromProgramOverview = props.componentIndex != null || props.componentIndex != undefined;
  const formListName =
    props.componentIndex != null || props.componentIndex != undefined
      ? [props.componentIndex, ProgramAwardDTO.thirdPartyCredentials]
      : ProgramAwardDTO.thirdPartyCredentials ?? '';
  const validationTitle = props.isSecondaryProgram
    ? fromProgramOverview
      ? BaseProgramThirdPartyCredentialDTO.title + props.sectionIndex + props.componentIndex
      : BaseProgramThirdPartyCredentialDTO.title
    : BaseProgramThirdPartyCredentialDTO.title + props.awardIndex ?? '';
  const validationThirdPartyType = props.isSecondaryProgram
    ? fromProgramOverview
      ? BaseProgramThirdPartyCredentialDTO.thirdPartyCredentialTypeId + props.sectionIndex + props.componentIndex
      : BaseProgramThirdPartyCredentialDTO.thirdPartyCredentialTypeId
    : BaseProgramThirdPartyCredentialDTO.thirdPartyCredentialTypeId + props.awardIndex ?? '';
  const validationThirdPartyOrg = props.isSecondaryProgram
    ? fromProgramOverview
      ? BaseProgramThirdPartyCredentialDTO.organization + props.sectionIndex + props.componentIndex
      : BaseProgramThirdPartyCredentialDTO.organization
    : BaseProgramThirdPartyCredentialDTO.organization + props.awardIndex ?? '';
  const validationThirdPartyReq = props.isSecondaryProgram
    ? fromProgramOverview
      ? BaseProgramThirdPartyCredentialDTO.requirement + props.sectionIndex + props.componentIndex
      : BaseProgramThirdPartyCredentialDTO.requirement
    : BaseProgramThirdPartyCredentialDTO.requirement + props.awardIndex ?? '';
  const renderRow = (index: number, value: any) => {
    return (
      <div style={{ height: 30, background: index % 2 === 0 ? undefined : '#f0f0f0', border: '1pt solid #f0f0f0' }}>
        {value}
      </div>
    );
  };

  if (props.pdfView) {
    return (
      <Space>
        <ValueLabel text={'Third Party Credential'}></ValueLabel>
        <Table
          style={{ fontSize: 8, border: '1pt solid #f0f0f0' }}
          tableLayout="auto"
          className="table-half-size"
          dataSource={props.thirdPartyCredentials}
          pagination={false}
          locale={{ emptyText: 'No Data' }}
        >
          <Table.Column
            title={<div style={{ paddingBottom: 10 }}>Title</div>}
            dataIndex={BaseProgramThirdPartyCredentialDTO.title}
            align="left"
            render={(data: any, row: BaseProgramThirdPartyCredentialDTO, index: number) => {
              return renderRow(index, row.title);
            }}
          />
          <Table.Column
            title="Type"
            dataIndex={BaseProgramThirdPartyCredentialDTO.thirdPartyCredentialTypeId}
            align="left"
            render={(data: any, row: BaseProgramThirdPartyCredentialDTO, index: number) => {
              return renderRow(index, row.thirdPartyCredentialType?.name);
            }}
          />
          <Table.Column
            title="Organization"
            dataIndex={BaseProgramThirdPartyCredentialDTO.organization}
            align="left"
            render={(data: any, row: BaseProgramThirdPartyCredentialDTO, index: number) => {
              return renderRow(index, row.organization);
            }}
          />
          <Table.Column
            title="Requirement"
            dataIndex={BaseProgramThirdPartyCredentialDTO.requirement}
            align="left"
            render={(data: any, row: BaseProgramThirdPartyCredentialDTO, index: number) => {
              return renderRow(index, row.requirement == Requirements.REQUIRED ? 'Required' : 'Optional');
            }}
          />
        </Table>
      </Space>
    );
  }

  return (
    <Form.List initialValue={props.thirdPartyCredentials} name={formListName}>
      {(options, { add, remove }) => {
        return (
          <Space direction="vertical">
            <ValueLabel text={'Third Party Credential'}></ValueLabel>
            <Table
              tableLayout="auto"
              className="table-half-size"
              dataSource={options }
              pagination={false}
              footer={() => (
                <div>
                  {props.readonly ? null : (
                    <Button
                      size="small"
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={() =>
                        add(BaseProgramThirdPartyCredentialDTO.create({ thirdPartyCredentialTypeId: null }))
                      }
                    >
                      Add Third Party Credential
                    </Button>
                  )}
                </div>
              )}
            >
              <Table.Column
                title='Title'
                dataIndex={BaseProgramThirdPartyCredentialDTO.title}
                align="left"
                render={(data: any, row: any, index: number) => {
                  return  (
                    <FormItem
                      {...getThirdPartyCredentialFormItems(index).get(BaseProgramThirdPartyCredentialDTO.title)}
                      {...ValidationUtil.getValidation(validationTitle + index, props.fieldErrors, props.submitted)}
                    >
                      <ReadableTextBox strong={false} readonly={props.readonly}></ReadableTextBox>
                    </FormItem>
                  );
                }}
              />
              <Table.Column
                title="Type"
                dataIndex={BaseProgramThirdPartyCredentialDTO.thirdPartyCredentialTypeId}
                align="left"
                render={(data: any, row: any, index: number) => {
                  return  (
                    <FormItem
                      {...getThirdPartyCredentialFormItems(index).get(
                        BaseProgramThirdPartyCredentialDTO.thirdPartyCredentialTypeId
                      )}
                      {...ValidationUtil.getValidation(
                        validationThirdPartyType + index,
                        props.fieldErrors,
                        props.submitted
                      )}
                    >
                      {props.readonly ? (
                        thirdPartyCredentials.find(
                          (x) => x.id == props.thirdPartyCredentials[index]?.thirdPartyCredentialTypeId
                        )?.name
                      ) : (
                        <Dropdown disabled={props.readonly} dropdownMatchSelectWidth={false} allowClear={true}>
                          {thirdPartyCredentials.map((x) => renderThirdPartyCredentialType(x))}
                        </Dropdown>
                      )}
                    </FormItem>
                  );
                }}
              />
              <Table.Column
                title="Organization"
                dataIndex={BaseProgramThirdPartyCredentialDTO.organization}
                align="left"
                render={(data: any, row: any, index: number) => {
                  return (
                    <FormItem
                      {...getThirdPartyCredentialFormItems(index).get(BaseProgramThirdPartyCredentialDTO.organization)}
                      {...ValidationUtil.getValidation(
                        validationThirdPartyOrg + index,
                        props.fieldErrors,
                        props.submitted
                      )}
                    >
                      <ReadableTextBox strong={false} readonly={props.readonly}></ReadableTextBox>
                    </FormItem>
                  );
                }}
              />
              <Table.Column
                title="Requirement"
                dataIndex={BaseProgramThirdPartyCredentialDTO.requirement}
                align="left"
                render={(data: any, row: any, index: number) => {
                  return (
                    <FormItem
                      {...getThirdPartyCredentialFormItems(index).get(BaseProgramThirdPartyCredentialDTO.requirement)}
                      {...ValidationUtil.getValidation(
                        validationThirdPartyReq + index,
                        props.fieldErrors,
                        props.submitted
                      )}
                    >
                      {props.readonly ? (
                        requirements.find((x) => x.value == props.thirdPartyCredentials[index]?.requirement)?.text
                      ) : (
                        <Dropdown
                          disabled={props.readonly}
                          value={props.thirdPartyCredentials[index]?.requirement}
                          dropdownMatchSelectWidth={false}
                          allowClear={true}
                        >
                          {requirements.map((x) => renderRequirements(x))}
                        </Dropdown>
                      )}
                    </FormItem>
                  );
                }}
              />
              {!props.readonly ? (
                <Table.Column
                  title={'Actions'}
                  dataIndex={BaseProgramThirdPartyCredentialDTO.id}
                  align="center"
                  render={(data: number, row: any, index: number) => {
                    return (
                      <Button
                        style={{
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          marginLeft: '-15px',
                          marginRight: '0',
                        }}
                        type="link"
                        onClick={() => remove(index)}
                        icon={<DeleteOutlined />}
                      />
                    );
                  }}
                />
              ) : null}
            </Table>
          </Space>
        );
      }}
    </Form.List>
  );
}

export default ThirdPartyCredentialDisplay;
