// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ExternalInstitutionDTO from '../models/ExternalInstitutionDTO';
import * as GetExternalInstitutionDetailsHandler from '../handlerModels/GetExternalInstitutionDetailsHandler';
import * as SaveExternalInstitutionDetailsHandler from '../handlerModels/SaveExternalInstitutionDetailsHandler';
import * as DeleteExternalInstitutionHandler from '../handlerModels/DeleteExternalInstitutionHandler';

export class ExternalInstitutionApiService extends BaseApi {

    // post: api/externalInstitution
    public getExternalInstitutionsDataTable(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ExternalInstitutionDTO>> {
        let url = `api/externalInstitution`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ExternalInstitutionDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/externalInstitution/export
    public getExternalInstitutionDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/externalInstitution/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/externalInstitution/${id}
    public getExternalInstitutionDetails(id: number): Promise<GetExternalInstitutionDetailsHandler.Result> {
        let url = `api/externalInstitution/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetExternalInstitutionDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/externalInstitution/save
    public saveExternalInstitutionDetails(saveExternalInstitutionDetails: SaveExternalInstitutionDetailsHandler.Request): Promise<SaveExternalInstitutionDetailsHandler.Result> {
        let url = `api/externalInstitution/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveExternalInstitutionDetailsHandler.Result>(saveExternalInstitutionDetails, 'post', url, true, false);
    }

    // post: api/externalInstitution/deleteExternalInstitution
    public deleteExternalInstitution(deleteExternalInstitution: DeleteExternalInstitutionHandler.Request): Promise<DeleteExternalInstitutionHandler.Result> {
        let url = `api/externalInstitution/deleteExternalInstitution`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteExternalInstitutionHandler.Result>(deleteExternalInstitution, 'post', url, true, false);
    }
}
var service = new ExternalInstitutionApiService();
export default service;
