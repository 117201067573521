import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GeneralEducationApiService from '../../../../api/GeneralEducationApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import GeneralEducationDetailsForm from '../../../../components/forms/postSecondary/GeneralEducationDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteGeneralEducationHandler from '../../../../handlerModels/DeleteGeneralEducationHandler';
import * as GetGeneralEducationDetailsHandler from '../../../../handlerModels/GetGeneralEducationDetailsHandler';
import GeneralEducationDTO from '../../../../models/GeneralEducationDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface GeneralEducationDetailsPageState extends BaseFormState {
  generalEducationId: number;
  generalEducation?: GeneralEducationDTO;
}

interface GeneralEducationDetailsPageProps {
}

class GeneralEducationDetailsPage extends React.Component<GeneralEducationDetailsPageProps & RouteComponentProps<RouteObject>, GeneralEducationDetailsPageState> {
  private readonly _GeneralEducationDetailsRef = React.createRef<GeneralEducationDetailsForm>();

  constructor(props: GeneralEducationDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      generalEducationId: 0
    };
  }

  componentDidMount() {
    const GeneralEducationId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (GeneralEducationId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const GeneralEducationIdNumber = Number.parseInt(GeneralEducationId);
      this.loadGeneralEducation(GeneralEducationIdNumber);
    }
  }

  private loadGeneralEducation = (generalEducationId: number) => {
    this.setState({ generalEducationId: generalEducationId });

    GeneralEducationApiService.getGeneralEducationDetails(generalEducationId)
      .then((results: GetGeneralEducationDetailsHandler.Result) => {
        if (results.generalEducation) {
          this.setState({ generalEducation: results.generalEducation });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this General Education?',
      okText: 'Delete',
      onOk: () => this.deleteGeneralEducation(id)
    });
  }

  private deleteGeneralEducation = (generalEducationId: number) => {
    this.setState({ submitting: true });

    const request = DeleteGeneralEducationHandler.Request.create({
      generalEducationId: generalEducationId,
    });

    GeneralEducationApiService.deleteGeneralEducation(request)
      .then((result: DeleteGeneralEducationHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToGeneralEducation();
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._GeneralEducationDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private GeneralEducationDetailsSaved = (generalEducationId: number) => {
    this.setState({ isEditing: false });
    if (generalEducationId != this.state.generalEducationId) {
      HistoryUtil.replace(Routes.generate(Routes.GENERAL_EDUCATION_DETAILS, { id: generalEducationId }));
    }
    else {
      this.loadGeneralEducation(generalEducationId);
    }
  }

  private goToGeneralEducation = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.generalEducationId == 0 ? 'New General Education' : this.state.generalEducation?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToGeneralEducation}
          breadcrumbs={Breadcrumbs.generalEducationDetails(title, this.state.generalEducationId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.generalEducationId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_GENERAL_EDUCATION]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <GeneralEducationDetailsForm
          ref={this._GeneralEducationDetailsRef}
          generalEducationId={this.state.generalEducationId}
          isEditing={this.state.isEditing}
          loading={this.state.loading}
          isNew={this.state.isNew}
          onSave={this.GeneralEducationDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete General Education' key={this.state.generalEducationId} onClick={() => this.deleteConfirmation(this.state.generalEducationId)}  >
          Delete General Education
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_GENERAL_EDUCATION]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(GeneralEducationDetailsPage);
