import { HomeOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import * as React from 'react';
import Routes from '../../../config/Routes';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../utils/HistoryUtil';

interface ChangeRequestSubmittedState extends BaseFormState {
}

interface ChangeRequestSubmittedProps extends BaseFormProps {
}

class ChangeRequestSubmitted extends React.Component<ChangeRequestSubmittedProps, ChangeRequestSubmittedState> {

  private backToListing = () => {
    HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUESTS));
  }

  private goToDashboard = () => {
    HistoryUtil.push(Routes.generate(Routes.HOME));
  }

  public render = () => {
    return (
      <Result
        status="success"
        title="Submitted"
        subTitle="Your request has successfully been submitted and is in review. You can check the status of your request from the Change Requests tab."
        extra={[
          <Button key="buy" onClick={this.backToListing}>Back to Listing</Button>,
          <Button type="primary" key="console" onClick={this.goToDashboard}>
            <HomeOutlined /> Go to Dashboard
          </Button>
        ]}
      />
    );
  }
}

export default ChangeRequestSubmitted;