// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionCourseBlockDTO from './InstitutionCourseBlockDTO';
import YearTermDTO from './YearTermDTO';
import InstitutionCourseBlockDetailCourseDTO from './InstitutionCourseBlockDetailCourseDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionCourseBlockDetailDTO extends EntitySelectorDTO { 
	id: string | null;
	institutionCourseBlockId: string | null;
	institutionId: string | null;
	institutionCourseBlock: InstitutionCourseBlockDTO | null;
	title: string | null;
	code: string | null;
	installTermId: string | null;
	install: YearTermDTO | null;
	installYear: number;
	retireTermId: string | null | null;
	retire: YearTermDTO | null;
	retireYear: number | null | null;
	institutionCourseBlockDetailCourses: InstitutionCourseBlockDetailCourseDTO[] | null;
	versionDisplay: string | null;
}

const InstitutionCourseBlockDetailDTO = {
    className: 'InstitutionCourseBlockDetailDTO',
    create: (initValues?: Partial<InstitutionCourseBlockDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			institutionCourseBlockId: '00000000-0000-0000-0000-000000000000',
			institutionId: '00000000-0000-0000-0000-000000000000',
			institutionCourseBlock: null,
			title: "",
			code: "",
			installTermId: '00000000-0000-0000-0000-000000000000',
			install: null,
			installYear: 0,
			retireTermId: null,
			retire: null,
			retireYear: null,
			institutionCourseBlockDetailCourses: [],
			versionDisplay: "",
        },
        initValues);
    },
	id: 'id',
	institutionCourseBlockId: 'institutionCourseBlockId',
	institutionId: 'institutionId',
	institutionCourseBlock: 'institutionCourseBlock',
	title: 'title',
	code: 'code',
	installTermId: 'installTermId',
	install: 'install',
	installYear: 'installYear',
	retireTermId: 'retireTermId',
	retire: 'retire',
	retireYear: 'retireYear',
	institutionCourseBlockDetailCourses: 'institutionCourseBlockDetailCourses',
	versionDisplay: 'versionDisplay',
};

export default InstitutionCourseBlockDetailDTO;