import { message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useImperativeHandle, useState } from 'react';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import UserRegionalPlanningPartnerAssignmentApiService from '../../../api/UserRegionalPlanningPartnerAssignmentApiService';
import RoleType from '../../../consts/RoleType';
import * as AddUserRegionalPlanningPartnerAssignmentHandler from '../../../handlerModels/AddUserRegionalPlanningPartnerAssignmentHandler';
import * as GetAllRegionalPlanningPartnersHandler from '../../../handlerModels/GetRegionalPlanningPartnersByAcademicYearHandler';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import RoleDTO from '../../../models/RoleDTO';
import UserDTO from '../../../models/UserDTO';
import Validator from '../../../models/Validator';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import Dropdown from '../../inputs/Dropdown';


interface AddRegionalPlanningPartnerToUserFormProps extends BaseFormProps {
  userId: string;
  onSubmit?: () => void;
  resetForm?: (value: string) => void;
}

const AddRegionalPlanningPartnerToUserForm = React.forwardRef((props: AddRegionalPlanningPartnerToUserFormProps, ref) => {
  const _formRef = React.createRef<FormInstance>();
  const _formItems = new Map<string, FormItemProps>()
    .set(AddUserRegionalPlanningPartnerAssignmentHandler.Request.regionalPlanningPartnerIdentifier, {
      label: 'Regional Planning Partner',
      required: true,
      name: AddUserRegionalPlanningPartnerAssignmentHandler.Request.regionalPlanningPartnerIdentifier,
      rules: [
        ValidationRuleUtil.required()
      ]
    })
    .set(AddUserRegionalPlanningPartnerAssignmentHandler.Request.roleIds, {
      label: 'RPP Roles',
      required: true,
      name: UserDTO.roles,
      rules: [
        ValidationRuleUtil.required()
      ]
    });

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(),
    reset: () => reset()
  }));

  const reset = () => {
    setFieldErrors(null);
    setSubmitted(false);
    _formRef.current?.resetFields();
  }

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [, setError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({} as ({ [key: string]: Validator[]; } | null));
  const [submitted, setSubmitted] = useState(false);
  const [rpps, setRPPs] = useState([] as RegionalPlanningPartnerDTO[]);
  const [roles, setRoles] = useState([] as RoleDTO[]);

  React.useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (!roles || roles.length === 0) {
      loaders.push(loadRoles());
    }

    if (!rpps || rpps.length === 0) {
      loaders.push(loadRpps());
    }

    Promise.all(loaders).then(() => {
      setLoading(false);
      if (props.resetForm) {
        props.resetForm;
      }
    });
  }

  const loadRpps = () => {
    return RegionalPlanningPartnerApiService.getAllRpps()
      .then((results: GetAllRegionalPlanningPartnersHandler.Result) => {
        if (results) {
          setRPPs(results.regions ?? []);
        }
      })
      .catch(() => {
        setError(true);
      });
  }

  const loadRoles = () => {
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        if (results) {
          setRoles(results);
        }
      }).catch(() => {
        setError(true);
      });
  }

  const handleSubmit = () => {
    setSubmitting(true);

    const form = (_formRef ? (_formRef.current as any).getFieldsValue() : null);
    const request = AddUserRegionalPlanningPartnerAssignmentHandler.Request.create({
      userId: props.userId,
      regionalPlanningPartnerIdentifier: form.regionalPlanningPartnerIdentifier,
      roleIds: form.roles
    });

    UserRegionalPlanningPartnerAssignmentApiService.addRegionalPartnerPlanningUser(request)
      .then((result: AddUserRegionalPlanningPartnerAssignmentHandler.Result) => {
        setSubmitted(true);
        if (result?.succeeded) {
          message.success('Regional Planning Partner Added');
          setFieldErrors(null);
          setSubmitted(false);

          if (props.onSubmit) {
            props.onSubmit();
          }
        }
        else {
          setError(!result?.succeeded);
          setFieldErrors(result?.fieldErrors);
          message.error('Regional Planning Partner could not be added');
        }
      })
      .catch(() => {
        setError(true);
        message.error('Regional Planning Partner could not be added');
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const renderRPP = (rpp: RegionalPlanningPartnerDTO) => {
    if (rpp.id) {
      return <Select.Option key={rpp.regionalPlanningPartnerIdentifier ?? 0} value={rpp.regionalPlanningPartnerIdentifier ?? 0}>{rpp.name}</Select.Option>
    }
  }

  const renderRole = (role: RoleDTO) => {
    if (role.id && role.roleTypeId == RoleType.RPP) {
      return <Select.Option key={role.id ?? Guid.Empty()} value={role.id ?? Guid.Empty()}>{role.name}</Select.Option>
    }
  }

  if (loading) {
    return <Skeleton active />
  }
  else {
    return (
      <Form
        ref={_formRef}
        layout="vertical"
        requiredMark={true}
        onFinish={handleSubmit}>
        <Space size="small" direction="vertical">
          <FormItem
            {..._formItems.get(AddUserRegionalPlanningPartnerAssignmentHandler.Request.regionalPlanningPartnerIdentifier)}
            {...ValidationUtil.getValidation(RegionalPlanningPartnerDTO.regionalPlanningPartnerIdentifier, fieldErrors, submitted)}  >
            <Dropdown dropdownMatchSelectWidth={false} disabled={submitting} >
              {rpps.sort((a, b) => a.regionalPlanningPartnerIdentifier - b.regionalPlanningPartnerIdentifier).map(x => { return renderRPP(x) })}
            </Dropdown>
          </FormItem>

          <FormItem
            {..._formItems.get(AddUserRegionalPlanningPartnerAssignmentHandler.Request.roleIds)}
            {...ValidationUtil.getValidation(UserDTO.roleIds, fieldErrors, submitted)}  >
            <Dropdown dropdownMatchSelectWidth={false} disabled={submitting} mode="multiple">
              {roles.map(x => { return renderRole(x) })}
            </Dropdown>
          </FormItem>
        </Space >
      </ Form>
    );
  }

})

AddRegionalPlanningPartnerToUserForm.displayName = 'AddRegionalPlanningPartnerToUserForm'
export default AddRegionalPlanningPartnerToUserForm;