import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import GenderApiService from '../../api/GenderApiService';
import FeatureFlag from '../../consts/FeatureFlag';
import * as GetGenderDetailsHandler from '../../handlerModels/GetGenderDetailsHandler';
import * as SaveGenderDetailsHandler from '../../handlerModels/SaveGenderDetailsHandler';
import GenderDTO from '../../models/GenderDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import LookupsUtil from '../../utils/LookupsUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import AuthorizedContent from '../AuthorizedContent';
import ResetButton from '../buttons/ResetButton';
import SaveButton from '../buttons/SaveButton';

interface GenderDetailsFormState extends BaseFormState {
  genderId: number;
  gender: GenderDTO | null;
}

interface GenderDetailsFormProps extends BaseFormProps {
  genderId: number;
  onSave?: (genderId: number) => void;
}

class GenderDetailsForm extends React.Component<GenderDetailsFormProps, GenderDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(GenderDTO.name, {
      required: true,
      name: GenderDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(50)
      ]
    })
    .set(GenderDTO.abbreviation, {
      required: true,
      name: GenderDTO.abbreviation,
      label: 'Abbreviation',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(10)
      ],
    });

  constructor(props: GenderDetailsFormProps) {
    super(props);

    this.state = {
      genderId: 0,
      gender: GenderDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadGender();
  }

  componentDidUpdate(prevProps: GenderDetailsFormProps) {
    if (this.props.genderId && this.props.genderId != prevProps.genderId) {
      this.setState({ genderId: this.props.genderId });
      this.loadGender();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadGender() {
    if (this.props.genderId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.genderId) {
      GenderApiService.getGenderDetails(this.props.genderId)
        .then((results: GetGenderDetailsHandler.Result) => {
          if (results && results.gender) {
            this.setState({ genderId: results.gender.id, gender: results.gender, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ gender: GenderDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveGenderDetailsHandler.Request.create({
      genderId: this.state.genderId,
      gender: GenderDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    GenderApiService.saveGenderDetails(request)
      .then((result: SaveGenderDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          gender: result?.gender,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.gender?.id) {
            this.props.onSave(result.gender.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(GenderDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Gender could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Gender could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(GenderDTO.abbreviation)}
            initialValue={this.state.gender?.abbreviation}
            {...ValidationUtil.getValidation(GenderDTO.abbreviation, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(GenderDTO.name)}
            initialValue={this.state.gender?.name}
            {...ValidationUtil.getValidation(GenderDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_GENDER]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default GenderDetailsForm;
