import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import NoticeOfIntentChangeRequestWorkForceLinkApiService from '../../../api/NoticeOfIntentChangeRequestWorkForceLinkApiService';
import * as RemoveNoticeOfIntentChangeRequestWorkForceLink from '../../../handlerModels/RemoveNoticeOfIntentChangeRequestWorkForceLinkHandler';
import LaunchOutlined from '../../../icons/LaunchOutlined';
import NoticeOfIntentChangeRequestWorkForceLinkDTO from '../../../models/NoticeOfIntentChangeRequestWorkForceLinkDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import AddNoticeOfIntentChangeRequestWorkForceLinkForm from '../../forms/postSecondary/AddNoticeOfIntentChangeRequestWorkForceLinkForm';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface NoticeOfIntentChangeRequestWorkForceLinkDataTableProps {
  triggerUpdate?: () => void;
}

interface NoticeOfIntentChangeRequestWorkForceLinkDataTableState extends BaseFormState, BaseDataTableState<NoticeOfIntentChangeRequestWorkForceLinkDTO> {
  showModal: boolean;
  noticeOfIntentChangeRequestWorkForceLink: NoticeOfIntentChangeRequestWorkForceLinkDTO;
}

class NoticeOfIntentChangeRequestWorkForceLinkDataTable extends React.Component<NoticeOfIntentChangeRequestWorkForceLinkDataTableProps, NoticeOfIntentChangeRequestWorkForceLinkDataTableState> {
  private _dataTable: DataTable<NoticeOfIntentChangeRequestWorkForceLinkDTO> | undefined;
  private readonly _addResourceLinkFormRef = React.createRef<AddNoticeOfIntentChangeRequestWorkForceLinkForm>();

  constructor(props: NoticeOfIntentChangeRequestWorkForceLinkDataTableProps) {
    super(props);
    this.state = ({
      showModal: false,
      noticeOfIntentChangeRequestWorkForceLink: NoticeOfIntentChangeRequestWorkForceLinkDTO.create(),
    });
  }


  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Title',
        dataIndex: NoticeOfIntentChangeRequestWorkForceLinkDTO.title,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'URL',
        dataIndex: NoticeOfIntentChangeRequestWorkForceLinkDTO.url,
        sorter: true,
        filterType: FilterType.Text,
        render: (data: string, row: NoticeOfIntentChangeRequestWorkForceLinkDTO) => {
          return (
            <Link
              to={{ pathname: row.url ?? '' }}
              target='_blank'
              rel="noopener noreferrer">
              {row.url ?? ''} <LaunchOutlined />
            </Link>
          );
        }
      },
      {
        title: '',
        dataIndex: NoticeOfIntentChangeRequestWorkForceLinkDTO.id,
        width: '25px',
        render: (data: string, row: NoticeOfIntentChangeRequestWorkForceLinkDTO) => {
          return <>
            {<Button type="link" className="ant-btn" onClick={() => this.addResource(row)}><EditOutlined /></Button>}
            {<Button type="link" className="ant-btn" onClick={() => this.promptConfirmRemove(row.id ?? Guid.Empty())}><DeleteOutlined /></Button>}
          </>
        },
        sorter: false,
        filterType: FilterType.NONE
      }
    ] as DataTableColumnProps<any>[];
  };

  private handleRemoveResource = (noticeOfIntentChangeRequestWorkForceLinkId: string | null) => {
    this.setState({ submitting: true });

    const request = RemoveNoticeOfIntentChangeRequestWorkForceLink.Request.create({
      noticeOfIntentChangeRequestWorkForceLinkId: noticeOfIntentChangeRequestWorkForceLinkId ?? Guid.Empty()
    });

    NoticeOfIntentChangeRequestWorkForceLinkApiService.removeNoticeOfIntentChangeRequestWorkForceLink(request)
      .then((result: RemoveNoticeOfIntentChangeRequestWorkForceLink.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Link was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Link could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.triggerRefresh();
      });
  }

  private promptConfirmRemove = (resourceLinkId: string | null) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this link?',
      okText: 'Remove Link',
      onOk: () => this.handleRemoveResource(resourceLinkId)
    });
  }

  private addResource = (record: NoticeOfIntentChangeRequestWorkForceLinkDTO) => {
    this.setState({ noticeOfIntentChangeRequestWorkForceLink: record }, () => this.openModal());
  }

  private openModal = () => {
    this.setState({ showModal: true });
  }

  private submitResourceChange = () => {
    this._addResourceLinkFormRef.current?.submit();
  }

  private cancelResourceChange = () => {
    this.setState({ showModal: false });
    this._addResourceLinkFormRef.current?.reset();
  }

  private resourcesUpdated = () => {
    this.setState({ showModal: false });
    this._dataTable?.refresh();
  }

  render() {
    return (
      <div>
        {this.renderAddResourceModal()}
        {this.renderDataTable()}
      </div>
    );
  }

  renderAddResourceModal() {
    return (
      <Modal
        visible={this.state.showModal}
        title='Link'
        okText='Save'
        onOk={this.submitResourceChange}
        onCancel={this.cancelResourceChange}>
        <AddNoticeOfIntentChangeRequestWorkForceLinkForm
          ref={this._addResourceLinkFormRef}
          onSuccess={this.resourcesUpdated}
          noticeOfIntentChangeRequestWorkForceLink={this.state.noticeOfIntentChangeRequestWorkForceLink} />
      </Modal>
    );
  }

  renderDataTable() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Default('Add Link', () => this.addResource(NoticeOfIntentChangeRequestWorkForceLinkDTO.create())));

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => NoticeOfIntentChangeRequestWorkForceLinkApiService.getNoticeOfIntentChangeRequestWorkForceLinkTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'resource_link_list' }} />
    );
  }

}

export default NoticeOfIntentChangeRequestWorkForceLinkDataTable;
