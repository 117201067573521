// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import DisciplineDTO from './DisciplineDTO';
import CategoryDTO from './CategoryDTO';
import YearTermDTO from './YearTermDTO';
import TermDTO from './TermDTO';
import NewCourseChangeRequestCreditDTO from './NewCourseChangeRequestCreditDTO';
import NewCourseChangeRequestCourseRequisiteDTO from './NewCourseChangeRequestCourseRequisiteDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NewCourseChangeRequestDTO extends EntitySelectorDTO { 
	id: string | null;
	changeRequestId: string | null;
	catalogNumber: string | null;
	disciplineId: string | null | null;
	discipline: DisciplineDTO | null;
	title: string | null;
	description: string | null;
	objectives: string | null;
	variableHours: boolean | null | null;
	categoryId: string | null | null;
	category: CategoryDTO | null;
	install: YearTermDTO | null;
	installTermId: string | null | null;
	installYear: number | null | null;
	installTerm: TermDTO | null;
	existingCourse: boolean;
	minCredits: number;
	maxCredits: number;
	newCourseRequestCredits: NewCourseChangeRequestCreditDTO[] | null;
	newCourseChangeRequestCourseRequisites: NewCourseChangeRequestCourseRequisiteDTO[] | null;
}

const NewCourseChangeRequestDTO = {
    className: 'NewCourseChangeRequestDTO',
    create: (initValues?: Partial<NewCourseChangeRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			changeRequestId: '00000000-0000-0000-0000-000000000000',
			catalogNumber: "",
			disciplineId: null,
			discipline: null,
			title: "",
			description: "",
			objectives: "",
			variableHours: null,
			categoryId: null,
			category: null,
			install: null,
			installTermId: null,
			installYear: null,
			installTerm: null,
			existingCourse: false,
			minCredits: 0,
			maxCredits: 0,
			newCourseRequestCredits: [],
			newCourseChangeRequestCourseRequisites: [],
        },
        initValues);
    },
	id: 'id',
	changeRequestId: 'changeRequestId',
	catalogNumber: 'catalogNumber',
	disciplineId: 'disciplineId',
	discipline: 'discipline',
	title: 'title',
	description: 'description',
	objectives: 'objectives',
	variableHours: 'variableHours',
	categoryId: 'categoryId',
	category: 'category',
	install: 'install',
	installTermId: 'installTermId',
	installYear: 'installYear',
	installTerm: 'installTerm',
	existingCourse: 'existingCourse',
	minCredits: 'minCredits',
	maxCredits: 'maxCredits',
	newCourseRequestCredits: 'newCourseRequestCredits',
	newCourseChangeRequestCourseRequisites: 'newCourseChangeRequestCourseRequisites',
};

export default NewCourseChangeRequestDTO;