import { Button, Select, Skeleton, Space, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import TermInput from '../../components/TermInput';
import * as EPRExportHandler from '../../handlerModels/EPRExportHandler';
import InstitutionDTO from '../../models/InstitutionDTO';
import TermDTO from '../../models/TermDTO';
import YearTermDTO from '../../models/YearTermDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import Guid from '../../utils/Guid';
import LookupsUtil from '../../utils/LookupsUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import Dropdown from '../../components/inputs/Dropdown';
import ResetButton from '../../components/buttons/ResetButton';
import modal from 'antd/lib/modal';
import DownloadsApiService from '../../api/DownloadsApiService';

export interface EPRExportValue {
  termYear: YearTermDTO | null;
  institutionIds: string[] | null;
}

interface EPRExportState extends BaseFormState {
  institutions: InstitutionDTO[];
  institutionId: string;
}

interface EPRExportProps extends BaseFormProps {
  onChange?: () => void;
  institutionId?: string;
  isModal?: boolean;
  onComplete?: () => void;
}

class EPRExport extends React.Component<EPRExportProps, EPRExportState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(EPRExportHandler.Request.institutionIds, {
        name: EPRExportHandler.Request.institutionIds,
        label:
          'Please select the institutions you want in the EPR. If none are selected then all institutions will be included.',
      })
      .set(EPRExportHandler.Request.termId, {
        name: EPRExportHandler.Request.termId,
        label: 'Term',
        required: true,
        rules: [ValidationRuleUtil.required(), () => ({ validator: ValidationUtil.termAndYearValidator() })],
      });
  };

  constructor(props: EPRExportProps) {
    super(props);

    this.state = {
      institutions: [],
      institutionId: Guid.Empty(),
    };
  }

  componentDidMount() {
    this.setState({ loading: true, institutionId: this.props.institutionId ?? Guid.Empty() });

    this.fetchData();
  }

  componentDidUpdate(prevProps: EPRExportProps) {
    if (prevProps.institutionId != this.props.institutionId) {
      this.setState({ institutionId: this.props.institutionId ?? Guid.Empty() }, () => this.resetForm());
      this.fetchData();
    }
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  };

  private fetchData = () => {
    const loaders = [];

    if (!this.state.institutions || this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  };

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  public reset = () => {
    this._formRef.current?.resetFields();
  };

  getValue = () => {
    const formValue = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    return {
      institutionIds: formValue?.institutionIds ?? [],
      termYear: formValue?.term ?? TermDTO.create(),
    } as EPRExportValue;
  };

  public handleSubmit = () => {
    const formValue = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    modal.info({
      title: 'File Requested',
      content:
        'The file you have requested has been queued. You will receive an email once the file has been generated.',
    });
    
    const request = EPRExportHandler.Request.create({
      institutionIds: formValue.institutionIds ?? [],
      termId: formValue.termId.termId,
      year: formValue.termId.year
    });

    DownloadsApiService.exportEPR(request);

    if (this.props.onComplete) {
      this.props.onComplete();
    }
  };

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space direction="vertical">
        <Form ref={this._formRef} onFinish={this.handleSubmit} layout="vertical">
          <Space direction="vertical" size="small">
            <Form.Item
              initialValue={this.state.institutionId == Guid.Empty() ? undefined : [this.state.institutionId]}
              {...this.getFormItems().get(EPRExportHandler.Request.institutionIds)}
            >
              <Dropdown
                mode={'multiple'}
                allowClear={true}
                showSearch
                optionFilterProp="children"
                disabled={this.props.institutionId != Guid.Empty()}
              >
                {this.renderInstitutionOptions()}
              </Dropdown>
            </Form.Item>
            <Form.Item
              {...this.getFormItems().get(EPRExportHandler.Request.termId)}
              {...ValidationUtil.getValidation(
                EPRExportHandler.Request.termId,
                this.state.fieldErrors,
                this.state.submitted
              )}
            >
              <TermInput />
            </Form.Item>

            <Typography.Text italic>This process may take a while to complete.</Typography.Text>

            <Space direction="horizontal">
              {!this.props.isModal ? (
                <Button type="primary" htmlType="submit">
                  Download EPR
                </Button>
              ) : null}

              <ResetButton onConfirm={this.resetForm} />
            </Space>
          </Space>
        </Form>
      </Space>
    );
  }

  renderInstitutionOptions() {
    return this.state.institutions.map((x) => {
      return this.renderInstitutionOption(x);
    });
  }

  renderInstitutionOption(institution: InstitutionDTO): any {
    if (institution.id) {
      return (
        <Select.Option key={institution.id} value={institution.id}>
          {institution.name}
        </Select.Option>
      );
    }
  }
}

export default EPRExport;
