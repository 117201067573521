import { Alert, Input, InputNumber, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import CreditTypesApiService from '../../../api/CreditTypesApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetCreditTypeDetailsHandler from '../../../handlerModels/GetCreditTypeDetailsHandler';
import * as SaveCreditTypeDetailsHandler from '../../../handlerModels/SaveCreditTypeDetailsHandler';
import CreditTypeDTO from '../../../models/CreditTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface CreditTypeDetailsFormState extends BaseFormState {
  creditType: CreditTypeDTO;
}

interface CreditTypeDetailsFormProps extends BaseFormProps {
  creditTypeId: string;
  onSave?: (termId: string) => void;
}

class CreditTypeDetailsForm extends React.Component<CreditTypeDetailsFormProps, CreditTypeDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(CreditTypeDTO.name, {
      required: true,
      name: CreditTypeDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ],
    })
    .set(CreditTypeDTO.displayOrder, {
      required: true,
      name: CreditTypeDTO.displayOrder,
      label: 'Display Order',
      rules: [
        ValidationRuleUtil.required()
      ],
    });

  constructor(props: CreditTypeDetailsFormProps) {
    super(props);

    this.state = {
      creditType: CreditTypeDTO.create({
        displayOrder: ''
      }),
    };
  }

  componentDidMount() {
    this.loadCreditType();
  }

  componentDidUpdate(prevProps: CreditTypeDetailsFormProps) {
    if (this.props.creditTypeId && this.props.creditTypeId != prevProps.creditTypeId) {
      this.loadCreditType();
    }
  }

  private loadCreditType() {
    if (!this.state.loading) {
      if (this.props.creditTypeId == Guid.Empty()) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.creditTypeId) {
      this.setState({ loading: true });

      CreditTypesApiService.getCreditTypeDetails(this.props.creditTypeId)
        .then((results: GetCreditTypeDetailsHandler.Result) => {
          if (results) {
            this.setState({
              creditType: results.creditType ?? CreditTypeDTO.create()
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveCreditTypeDetailsHandler.Request.create({
      creditTypeId: this.props.creditTypeId,
      creditType: CreditTypeDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    CreditTypesApiService.saveCreditTypeDetails(request)
      .then((result: SaveCreditTypeDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ creditType: result.creditType ?? CreditTypeDTO.create() });

          message.success('Saved');

          if (this.props.onSave && result.creditType?.id) {
            this.props.onSave(result.creditType.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(CreditTypeDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error(result?.errors.join('\n'));
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Credit Type could not be saved');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <PageStayPrompt when={this.state.altered} />
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.creditType}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(CreditTypeDTO.name)}
            {...ValidationUtil.getValidation(CreditTypeDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(CreditTypeDTO.displayOrder)}
            {...ValidationUtil.getValidation(CreditTypeDTO.displayOrder, this.state.fieldErrors, this.state.submitted)}>
            <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CREDIT_TYPE]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!FormUtil.canSubmit(this._formRef, this._formItems)} saving={this.state.submitting} />
            <ResetButton disabled={!FormUtil.hasChanged(this._formRef, this._formItems)} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default CreditTypeDetailsForm;
