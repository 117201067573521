import { Alert, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import AreaEducationAgencyApiService from '../../../api/AreaEducationAgencyApiService';
import * as GetAreaEducationAgencyDetailsHandler from '../../../handlerModels/GetAreaEducationAgencyDetailsHandler';
import * as SaveAreaEducationAgencyDetailsHandler from '../../../handlerModels/SaveAreaEducationAgencyDetailsHandler';
import AreaEducationAgencyDTO from '../../../models/AreaEducationAgencyDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ReadableTextBox from '../../inputs/ReadableTextBox';

interface AreaEducationAgencyDetailsFormState extends BaseFormState {
  areaEducationAgency: AreaEducationAgencyDTO;
}

interface AreaEducationAgencyDetailsFormProps extends BaseFormProps {
  areaEducationAgencyId: string;
  academicYear?: number;
  onSave?: (areaEducationAgencyId: string) => void;
}

class AreaEducationAgencyDetailsForm extends React.Component<AreaEducationAgencyDetailsFormProps, AreaEducationAgencyDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(AreaEducationAgencyDTO.areaEducationAgencyIdentifier, {
      required: true,
      name: AreaEducationAgencyDTO.areaEducationAgencyIdentifier,
      label: 'Id',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    })
    .set(AreaEducationAgencyDTO.name, {
      required: true,
      name: AreaEducationAgencyDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(100)
      ],
    });

  constructor(props: AreaEducationAgencyDetailsFormProps) {
    super(props);

    this.state = {
      areaEducationAgency: AreaEducationAgencyDTO.create({
        id: undefined,
        name: undefined,
        areaEducationAgencyId: undefined,
      }),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: AreaEducationAgencyDetailsFormProps) {
    if (this.props != prevProps) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];
    loaders.push(this.loadAreaEducationAgency());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadAreaEducationAgency() {
    if (!this.state.loading) {
      if (this.props.areaEducationAgencyId == Guid.Empty() || this.props.isNew) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.areaEducationAgencyId && this.props.academicYear) {
      const request = GetAreaEducationAgencyDetailsHandler.Request.create({
        id: this.props.areaEducationAgencyId
      });

      AreaEducationAgencyApiService.getAreaEducationAgencyDetails(request)
        .then((results: GetAreaEducationAgencyDetailsHandler.Result) => {
          if (results) {
            this.setState({
              areaEducationAgency: results.areaEducationAgency ?? AreaEducationAgencyDTO.create({
                id: this.props.areaEducationAgencyId == Guid.Empty() ? undefined : this.props.areaEducationAgencyId,
                areaEducationAgencyId: undefined,
                name: undefined,
              })
            }, () => this.resetForm());

            if (this.props.areaEducationAgencyId == Guid.Empty()) {
              this.setState({ altered: true })
            }

          }
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({
      loading: false,
      areaEducationAgency: AreaEducationAgencyDTO.create({
        id: this.props.areaEducationAgencyId == Guid.Empty() ? undefined : this.props.areaEducationAgencyId,
        areaEducationAgencyIdentifier: '',
      }),
      altered: true
    });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      () => this.handleSubmit(),
      () => this.setState({ error: true })
    );
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveAreaEducationAgencyDetailsHandler.Request.create({
      areaEducationAgencyId: this.props.areaEducationAgencyId,
      areaEducationAgency: AreaEducationAgencyDTO.create({
        ...(this._formRef ? (this._formRef.current as any).getFieldsValue() : null),
        academicYear: this.props.academicYear,
      })
    });

    AreaEducationAgencyApiService.saveAreaEducationAgencyDetails(request)
      .then((result: SaveAreaEducationAgencyDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ areaEducationAgency: result.areaEducationAgency ?? AreaEducationAgencyDTO.create(), fieldErrors: undefined, error: false });

          message.success('Saved');

          if (this.props.onSave && result.areaEducationAgency?.id) {
            this.resetForm();
            this.props.onSave(result.areaEducationAgency.id);
          }

        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Area Education Agency could not be saved');
          this.setState({ loading: false, submitting: false });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results, loading: false, submitting: false });
        message.error('Area Education Agency could not be saved');
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.areaEducationAgency}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(AreaEducationAgencyDTO.areaEducationAgencyIdentifier)}
            {...ValidationUtil.getValidation(AreaEducationAgencyDTO.areaEducationAgencyIdentifier, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(AreaEducationAgencyDTO.name)}
            {...ValidationUtil.getValidation(AreaEducationAgencyDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
        </Form>
      </Space>
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AreaEducationAgencyDetailsForm;
