import { Document, Image, Page, Text, View, pdf } from '@react-pdf/renderer';
import { Button } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { Html } from 'react-pdf-html';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import SelfStudyResponseApiService from '../../../api/SelfStudyResponseApiService';
import SelfStudyTemplateApiService from '../../../api/SelfStudyTemplateApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import SelfStudyResponseStatusType from '../../../consts/SelfStudyResponseStatusType';
import SelfStudyResponseType from '../../../consts/SelfStudyResponseType';
import SelfStudySectionComponentType from '../../../consts/SelfStudySectionComponentType';
import * as GetSelfStudyBySelfStudyResponseHandler from '../../../handlerModels/GetSelfStudyBySelfStudyResponseHandler';
import * as GetSelfStudyConfigurationHandler from '../../../handlerModels/GetSelfStudyConfigurationHandler';
import * as GetSelfStudyResponseByTypeHandler from '../../../handlerModels/GetSelfStudyResponseByTypeHandler';
import * as GetSelfStudyResponseLiteHandler from '../../../handlerModels/GetSelfStudyResponseLiteHandler';
import * as GetSelfStudyResponseProgramOverviewHandler from '../../../handlerModels/GetSelfStudyResponseProgramOverviewHandler';
import * as GetSelfStudySectionBySectionOrderHandler from '../../../handlerModels/GetSelfStudySectionBySectionOrderHandler';
import * as GetSelfStudySectionTemplateBySectionOrderHandler from '../../../handlerModels/GetSelfStudySectionTemplateBySectionOrderHandler';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import SelfStudyResponseProgramOverviewDTO from '../../../models/SelfStudyResponseProgramOverviewDTO';
import SelfStudyResponseTemplateConsultantReviewQuestionDTO from '../../../models/SelfStudyResponseTemplateConsultantReviewQuestionDTO';
import SelfStudySectionComponentTemplateDTO from '../../../models/SelfStudySectionComponentTemplateDTO';
import SelfStudySectionComponentTypeDTO from '../../../models/SelfStudySectionComponentTypeDTO';
import SelfStudySectionResponseDTO from '../../../models/SelfStudySectionResponseDTO';
import SelfStudySectionTemplateDTO from '../../../models/SelfStudySectionTemplateDTO';
import SelfStudyTemplateDTO from '../../../models/SelfStudyTemplateDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import SelfStudyConsultantReview from '../../secondary/SelfStudyConsultantReview';
import SelfStudyFreeTextQuestion from '../../secondary/SelfStudyFreeTextQuestion';
import SelfStudyInstruction from '../../secondary/SelfStudyInstruction';
import SelfStudyNewGoals from '../../secondary/SelfStudyNewGoals';
import SelfStudyPreviousGoals from '../../secondary/SelfStudyPreviousGoals';
import SelfStudyProgramOverview from '../../secondary/SelfStudyProgramOverview';
import SelfStudyRankingQuestion from '../../secondary/SelfStudyRankingQuestion';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import saveAs from 'file-saver';
import AcademicYearStatusType from '../../../consts/AcademicYearStatusType';

interface SelfStudyPdfDocumentProps extends BaseFormProps {
  selfStudyResponseId: string;
  selfStudyId?: string;
  academicYear?: number;
  readonly?: boolean;
  onSave?: (sectionIndex: number, fieldErrors?: any, disableNavigate?: boolean) => void;
  sectionIndex?: number;
  stepSection?: number;
  selfStudyResponseType: number;
  detailsPage?: boolean;
  sectionLoaded?: boolean;
  pdfView?: boolean;
  fileName?: string;
  selfStudy?: SelfStudyDTO;
  downloadText?: string;
}

const SelfStudyPdfDocument = React.forwardRef((props: SelfStudyPdfDocumentProps) => {
  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);
  const [selfStudyResponse, setSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [selfStudySection, setSelfStudySection] = useState({} as SelfStudySectionResponseDTO);
  const [responseComplete, setResponseComplete] = useState(false);
  const [selfStudySectionTemplate, setSelfStudySectionTemplate] = useState<SelfStudySectionTemplateDTO | null>(null);
  const [selfStudyTemplate, setSelfStudyTemplate] = useState<SelfStudyTemplateDTO | null>(null);
  const [selfStudyResponseProgramOverview, setSelfStudyResponseProgramOverview] = useState(
    {} as SelfStudyResponseProgramOverviewDTO
  );
  const [sectionsLoaded, setSectionLoaded] = useState<Record<number, Record<number, boolean>>>({});
  const [loadings, setLoading] = useState(true);
  const [sectionChanged, setSectionChanged] = useState(false);
  const [Pdf, setPdf] = useState<any>();
  const [pdfDownloadClicked, setPdfDownloadClicked] = useState(false);
  const [pdfDownloadLoading, setPdfDownloadLoading] = useState(false);
  const [sectionLoadingSetup, setSectionLoadingSetup] = useState(false);
  const [, setError] = useState(false);
  const [componentTypes, setComponetTypes] = useState<SelfStudySectionComponentTypeDTO[]>([]);
  const [educatorSelfStudyResponse, setEducatorSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [rppSelfStudyResponse, setRPPSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [deSelfStudyResponse, setDOESelfStudyResponse] = useState({} as SelfStudyResponseDTO);

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, [props.sectionIndex, props.sectionLoaded]);

  React.useEffect(() => {
if(selfStudyTemplate && selfStudyResponseProgramOverview){
  selfStudyTemplate?.selfStudySectionTemplates?.forEach((x) =>
  initializeSectionsLoaded(x.order, x ,x.selfStudySectionComponentTemplates ?? [])
);
}
  }, [selfStudyTemplate, selfStudyResponseProgramOverview]);

  React.useEffect(() => {
    let rendered = true;

    Object.entries(sectionsLoaded).forEach((y) =>
      Object.entries(y[1]).forEach((x) => {
        if (rendered && !x[1]) {
          rendered = false;
          return;
        }
      })
    );
    
    if (rendered && Pdf && pdfDownloadLoading) {
      setPdfDownloadClicked(true);
    }
    setSectionChanged(false);
  }, [sectionChanged]);

  const getTypeName = () => {
    switch (props.selfStudyResponseType) {
      case SelfStudyResponseType.INSTRUCTOR:
        return 'Instructor';
      case SelfStudyResponseType.RPP:
        return 'RPP';
      case SelfStudyResponseType.DOE:
        return 'DE';
    }
  };

React.useEffect(() => {
    const downloadFile = async () => {
      if (pdfDownloadClicked) {
        const blob = await Pdf.toBlob();
        saveAs(
          blob,
          props.fileName
            ? props.fileName
            : props.academicYear +
                '_' +
                getTypeName() +
                '_' +
                props.selfStudy?.secondaryProgram?.secondaryCipNumber?.description +
                '_' +
                props.selfStudy?.secondaryProgram?.district?.name +
                '.pdf'
        );
        resetSectionLoaded();
        setPdfDownloadClicked(false);
        setPdfDownloadLoading(false);
      }
    };

    downloadFile();
  }, [pdfDownloadClicked]);

  const resetSectionLoaded = () => {
    const sections = sectionsLoaded;
    Object.entries(sections).forEach((y) => Object.entries(y[1]).forEach((x) => (sections[y[0]][x[0]] = false)));
    setSectionLoaded(sections);
    setSectionChanged(true);
  };

  const initializeSectionsLoaded = (sectionIndex: number, sectionTemplate: SelfStudySectionTemplateDTO,dtos: SelfStudySectionComponentTemplateDTO[]) => {
    const sections = sectionsLoaded;
    sections[sectionIndex] = {};

    dtos?.forEach((y) =>  { 
      if(y.selfStudySectionComponentTypeId != null && 
        (sectionTemplate.selfStudySectionTemplateSecondaryProgramScenarios.length == 0  || (sectionTemplate.selfStudySectionTemplateSecondaryProgramScenarios.findIndex(x => x == selfStudyResponseProgramOverview.secondaryProgramScenarioId) > -1)) && 
      (y.selfStudySectionComponentScenarios.length == 0 ||(y.selfStudySectionComponentScenarios.findIndex(x => x == selfStudyResponseProgramOverview.secondaryProgramScenarioId) > -1)) ){
    (sections[sectionIndex][y.order] = false)
  }});
    setSectionLoaded(sections);
    setSectionChanged(true);
  };
  const sectionComplete = (sectionIndex: number, componentIndex: number, value: boolean) => {
    const sections = sectionsLoaded;
    sections[sectionIndex][componentIndex] = value;
    setSectionLoaded(sections);
    setSectionChanged(true);
  };

  const fetchData = () => {
    setLoading(true);
    const loaders = [];
    window.scrollTo(0, 0);
    if (props.selfStudyResponseId && props.selfStudyResponseId != Guid.Empty()) {
      if (!selfStudyResponse.id || selfStudyResponse.id == Guid.Empty()) {
        loaders.push(loadSelfStudyResponse());
      }

      if (!selfStudy.id || selfStudy.id == Guid.Empty()) {
        loaders.push(loadSelfStudy(props.selfStudyResponseId));
      }

      if (!componentTypes || componentTypes.length == 0) {
        loaders.push(loadSelfStudySectionComponentTypes());
      }

      if (!selfStudySectionTemplate?.id || selfStudySectionTemplate?.id == Guid.Empty()) {
        loaders.push(loadSelfStudySectionTemplate());
        loaders.push(
          SelfStudyTemplateApiService.getSelfStudy(props.academicYear ?? 0).then(
            (results: GetSelfStudyConfigurationHandler.Result) => {
              if (results.selfStudy) {
                setSelfStudyTemplate(results.selfStudy);
                setSectionLoadingSetup(true);
              } else {
                setSelfStudyTemplate(
                  SelfStudyTemplateDTO.create({
                    academicYear: props.academicYear,
                    selfStudySectionTemplates: [
                      SelfStudySectionTemplateDTO.create({
                        selfStudySectionScenarios: [],
                        selfStudySectionTypeId: null,
                        title: '',
                        selfStudySectionComponentTemplates: [
                          SelfStudySectionComponentTemplateDTO.create({
                            selfStudySectionComponentScenarios: [],
                            selfStudySectionComponentTypeId: null,
                            selfStudyResponseTemplateConsultantReviewQuestions: [
                              SelfStudyResponseTemplateConsultantReviewQuestionDTO.create(),
                            ],
                          }),
                        ],
                      }),
                    ],
                  })
                );
              }
            }
          )
        );
      }

      if (!selfStudySection.id || selfStudySection.id == Guid.Empty()) {
        loaders.push(loadExisting());
      }

      if (props.selfStudyId && props.selfStudyId != Guid.Empty()) {
        loaders.push(loadEducatorSelfStudyResponse());
        loaders.push(loadRPPSelfStudyResponse());
        loaders.push(loadDOESelfStudyResponse());
      }

      Promise.all(loaders).then(() => {
        setLoading(false);
      });
    }
  };

  const loadSelfStudy = (id: string) => {
    const request = GetSelfStudyBySelfStudyResponseHandler.Request.create({
      selfStudyResponseId: id,
    });

    return SelfStudyApiService.getSelfStudyByResponse(request)
      .then((results: GetSelfStudyBySelfStudyResponseHandler.Result) => {
        if (results.selfStudy) {
          setSelfStudy(results.selfStudy);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  const loadEducatorSelfStudyResponse = () => {
    const request = GetSelfStudyResponseByTypeHandler.Request.create({
      selfStudyId: props.selfStudyId,
      selfStudyResponseTypeId: SelfStudyResponseType.INSTRUCTOR,
    });

    return SelfStudyResponseApiService.getSelfStudyResponseByType(request)
      .then((results: GetSelfStudyResponseByTypeHandler.Result) => {
        if (results.selfStudyResponse) {
          setEducatorSelfStudyResponse(results.selfStudyResponse ?? SelfStudyResponseDTO.create());
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  const loadRPPSelfStudyResponse = () => {
    const request = GetSelfStudyResponseByTypeHandler.Request.create({
      selfStudyId: props.selfStudyId,
      selfStudyResponseTypeId: SelfStudyResponseType.RPP,
    });

    return SelfStudyResponseApiService.getSelfStudyResponseByType(request)
      .then((results: GetSelfStudyResponseByTypeHandler.Result) => {
        if (results.selfStudyResponse) {
          setRPPSelfStudyResponse(results.selfStudyResponse ?? SelfStudyResponseDTO.create());
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  const loadDOESelfStudyResponse = () => {
    const request = GetSelfStudyResponseByTypeHandler.Request.create({
      selfStudyId: props.selfStudyId,
      selfStudyResponseTypeId: SelfStudyResponseType.DOE,
    });

    return SelfStudyResponseApiService.getSelfStudyResponseByType(request)
      .then((results: GetSelfStudyResponseByTypeHandler.Result) => {
        if (results.selfStudyResponse) {
          setDOESelfStudyResponse(results.selfStudyResponse ?? SelfStudyResponseDTO.create());
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  const loadSelfStudyResponseProgramOverview = (responseId: string) => {
    const request = GetSelfStudyResponseProgramOverviewHandler.Request.create({
      id: responseId,
    });

    return SelfStudyApiService.getSelfStudyResponseProgramOverview(request).then(
      (results: GetSelfStudyResponseProgramOverviewHandler.Result) => {
        if (results.selfStudyResponseProgramOverview) {
          setSelfStudyResponseProgramOverview(
            results?.selfStudyResponseProgramOverview ?? SelfStudyResponseProgramOverviewDTO.create()
          );
        }
      }
    );
  };

  const loadSelfStudyResponse = () => {
    const request = GetSelfStudyResponseLiteHandler.Request.create({
      id: props.selfStudyResponseId,
    });

    return SelfStudyApiService.getSelfStudyResponseLite(request).then(
      (results: GetSelfStudyResponseLiteHandler.Result) => {
        if (results.selfStudyResponse) {
          const completed =
            results.selfStudyResponse.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.INPROGRESS &&
            results.selfStudyResponse.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.REVISING &&
            results.selfStudyResponse.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.REVISIONSNEEDED &&
            results.selfStudyResponse.selfStudyResponseStatusTypeId != SelfStudyResponseStatusType.PENDING;

          setSelfStudyResponse(results.selfStudyResponse);
          setResponseComplete(completed);
          loadSelfStudyResponseProgramOverview(results.selfStudyResponse?.id ?? Guid.Empty());
        }
      }
    );
  };

  const loadExisting = () => {
    const request = GetSelfStudySectionBySectionOrderHandler.Request.create({
      selfStudyResponseId: props.selfStudyResponseId,
      academicYear: props.academicYear ?? 0,
      selfStudySectionOrder: props.sectionIndex,
    });

    return SelfStudyApiService.getSelfStudySectionBySectionOrder(request)
      .then((results: GetSelfStudySectionBySectionOrderHandler.Result) => {
        if (results.selfStudySection) {
          setSelfStudySection(results.selfStudySection);
        } else {
          setSelfStudySection({} as SelfStudySectionResponseDTO);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  const loadSelfStudySectionTemplate = () => {
    return SelfStudyTemplateApiService.getSelfStudySectionTemplateBySectionOrder(
      props.academicYear ?? 0,
      props.sectionIndex ?? 0
    ).then((results: GetSelfStudySectionTemplateBySectionOrderHandler.Result) => {
      if (results.selfStudySectionTemplate) {
        setSelfStudySectionTemplate(results.selfStudySectionTemplate);
      }
    });
  };

  const loadSelfStudySectionComponentTypes = () => {
    return LookupsUtil.getAll<SelfStudySectionComponentTypeDTO>(SelfStudySectionComponentTypeDTO.className)
      .then((results: SelfStudySectionComponentTypeDTO[]) => {
        setComponetTypes(results);
      })
      .catch(() => {
        setError(true);
      });
  };

  const renderPdfDistrictResponse = (sectionIndex: number) => {
    const districtComments =
      educatorSelfStudyResponse.selfStudySectionResponses?.find(
        (x) => x.selfStudySectionTemplate?.order == sectionIndex
      )?.sectionComments ?? '';
    if (
      selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR ||
      selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR
    ) {
      return renderPdfExistingResponse('District Comments', districtComments);
    }
  };

  const renderPdfRppResponse = (sectionIndex: number) => {
    const rppComments =
      rppSelfStudyResponse.selfStudySectionResponses?.find((x) => x.selfStudySectionTemplate?.order == sectionIndex)
        ?.sectionComments ?? '';
    if (
      ((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE ||
        selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP) &&
        AuthorizationUtil.isAuthorized([
          FeatureFlag.EDIT_RPP_RESPONSE,
          FeatureFlag.VIEW_RPP_RESPONSE,
          FeatureFlag.EDIT_DE_RESPONSE,
          FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE,
        ])) ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISING ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISIONSNEEDED ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE
    ) {
      return renderPdfExistingResponse('Regional Planning Partner Comments', rppComments);
    }
  };

  const renderPdfDEResponse = (sectionIndex: number) => {
    const deComments =
      deSelfStudyResponse.selfStudySectionResponses?.find((x) => x.selfStudySectionTemplate?.order == sectionIndex)
        ?.sectionComments ?? '';
    if (selfStudy.academicYear?.academicYearStatusTypeId == AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS || (
      selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE &&
      AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))
    ) {
      return renderPdfExistingResponse('DE Comments', deComments);
    }
  };

  const renderPdfExistingResponse = (title: string, comments: string) => {
    const fontSizeFix = comments.replaceAll(new RegExp('ptpx','g'), 'px') ?? '';
    const decimal = fontSizeFix.replaceAll(new RegExp('font-size:?[0-9]{1,2}px','g'), 'font-size:8px');
    const fontFamily = decimal.replaceAll(new RegExp('font-size:?[0-9]{1,2}.[0-9]{1,2}px','g'), 'font-size:8px');
    const finalText = fontFamily.replaceAll(new RegExp('font-family:','g'), '');
    return (
      <View style={{ border: '1pt solid #f0f0f0', margin: 10 }}>
        <Text style={{ fontSize: 10, border: '1pt solid #f0f0f0', backgroundColor: '#f0f0f0', padding: 5 }}>
          {title}
        </Text>
        <Html style={{ fontSize: 8 }}>{finalText}</Html>
      </View>
    );
  };

  const renderPdfSection = (sectionComponents: SelfStudySectionComponentTemplateDTO[] | null, sectionIndex: number) => {
    let scenarios: string[] = [];
    if (
      (selfStudyTemplate?.selfStudySectionTemplates ?? [])[sectionIndex]
        ?.selfStudySectionTemplateSecondaryProgramScenarios
    ) {
      scenarios =
        (selfStudyTemplate?.selfStudySectionTemplates ?? [])[sectionIndex]
          ?.selfStudySectionTemplateSecondaryProgramScenarios ?? [];
    }

    const sectionNode = sectionComponents?.map((component, index) => renderPdfComponents(sectionIndex, index));

    if (
      !selfStudyResponseProgramOverview.secondaryProgramScenarioId ||
      !scenarios ||
      scenarios?.length == 0 ||
      (scenarios &&
        scenarios.findIndex((x: any) => x == selfStudyResponseProgramOverview.secondaryProgramScenarioId) > -1)
    ) {
      return (
        <View style={{ paddingBottom: '10pt' }}>
          <View style={{ border: '1pt solid #f0f0f0' }}>
            <Text style={{ border: '1pt solid #f0f0f0', fontSize: 12, backgroundColor: '#f0f0f0', padding: 5 }}>
              {(selfStudyTemplate?.selfStudySectionTemplates ?? [])[sectionIndex]?.title}
            </Text>
            {sectionNode}
            {(selfStudyTemplate?.selfStudySectionTemplates
              ?.at(sectionIndex)
              ?.selfStudySectionComponentTemplates?.findIndex(
                (x) =>
                  x.selfStudySectionComponentTypeId != SelfStudySectionComponentType.INSTRUCTIONS &&
                  (!selfStudyResponseProgramOverview.secondaryProgramScenarioId ||
                    !x.selfStudySectionComponentScenarios ||
                    x.selfStudySectionComponentScenarios?.length == 0 ||
                    (x.selfStudySectionComponentScenarios &&
                      x.selfStudySectionComponentScenarios.findIndex(
                        (x: any) => x == selfStudyResponseProgramOverview.secondaryProgramScenarioId
                      ) > -1))
              ) ?? -1) > -1 &&
            selfStudySectionTemplate?.selfStudySectionComponentTemplates?.findIndex(
              (x) => x.selfStudySectionComponentTypeId == SelfStudySectionComponentType.CONSULTANTREVIEW
            ) == -1 ? (
              <View>
                {renderPdfDistrictResponse(sectionIndex)}
                {renderPdfRppResponse(sectionIndex)}
                {renderPdfDEResponse(sectionIndex)}
              </View>
            ) : null}
          </View>
        </View>
      );
    }
  };

  const renderPdfComponents = (sectionIndex: number, componentIndex: number) => {
    if (componentIndex != -1) {
      let scenarios: string[] = [];
      if (
        (selfStudyTemplate?.selfStudySectionTemplates ?? [])[sectionIndex]?.selfStudySectionComponentTemplates?.find(
          (x: any) => x.order == componentIndex
        )?.selfStudySectionComponentScenarios
      ) {
        scenarios =
          (selfStudyTemplate?.selfStudySectionTemplates ?? [])[sectionIndex]?.selfStudySectionComponentTemplates?.find(
            (x: any) => x.order == componentIndex
          )?.selfStudySectionComponentScenarios ?? [];
      }

      if (
        !selfStudyResponseProgramOverview.secondaryProgramScenarioId ||
        (scenarios?.length ?? 0) == 0 ||
        (scenarios &&
          scenarios.findIndex((x: any) => x == selfStudyResponseProgramOverview.secondaryProgramScenarioId) > -1)
      ) {
        return renderPdfComponent(sectionIndex, componentIndex);
      }

      return null;
    }
  };

  const renderPdfComponent = (sectionIndex: number, componentIndex: number) => {
    switch (
      (selfStudyTemplate?.selfStudySectionTemplates?.at(sectionIndex)?.selfStudySectionComponentTemplates ?? []).at(
        componentIndex
      )?.selfStudySectionComponentTypeId
    ) {
      case SelfStudySectionComponentType.INSTRUCTIONS:
        return (
          <SelfStudyInstruction
            academicYear={props.academicYear}
            sectionIndex={sectionIndex}
            componentIndex={componentIndex}
            pdfView={true}
            renderComplete={sectionComplete}
          />
        );
      case SelfStudySectionComponentType.RANKINGQUESTION:
        return (
          <SelfStudyRankingQuestion
            academicYear={props.academicYear}
            sectionIndex={sectionIndex}
            selfStudyId={props.selfStudyId}
            selfStudyResponseId={props.selfStudyResponseId}
            componentIndex={componentIndex}
            readonly={props.readonly || responseComplete}
            pdfView={true}
            renderComplete={sectionComplete}
          />
        );
      case SelfStudySectionComponentType.PREVIOUSGOALS:
        return (
          <SelfStudyPreviousGoals
            selfStudyResponseId={props.selfStudyResponseId}
            academicYear={props.academicYear}
            sectionIndex={sectionIndex}
            componentIndex={componentIndex}
            readonly={
              props.readonly ||
              selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR ||
              responseComplete
            }
            pdfView={true}
            renderComplete={sectionComplete}
          />
        );
      case SelfStudySectionComponentType.NEWGOALS:
        return (
          <SelfStudyNewGoals
            selfStudyResponseId={props.selfStudyResponseId}
            academicYear={props.academicYear}
            sectionIndex={sectionIndex}
            componentIndex={componentIndex}
            readonly={
              props.readonly ||
              selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR ||
              responseComplete
            }
            pdfView={true}
            renderComplete={sectionComplete}
          />
        );
      case SelfStudySectionComponentType.FREETEXTQUESTION:
        return (
          <SelfStudyFreeTextQuestion
            academicYear={props.academicYear}
            selfStudyId={props.selfStudyId}
            selfStudyResponseId={props.selfStudyResponseId}
            sectionIndex={sectionIndex}
            componentIndex={componentIndex}
            readonly={props.readonly || responseComplete}
            pdfView={true}
            renderComplete={sectionComplete}
          />
        );
      case SelfStudySectionComponentType.PROGRAMOVERVIEW:
        return (
          <SelfStudyProgramOverview
            academicYear={props.academicYear ?? 0}
            selfStudyResponseId={props.selfStudyResponseId}
            readonly={props.readonly || responseComplete}
            componentIndex={componentIndex}
            sectionIndex={sectionIndex}
            loading={loadings}
            pdfView={true}
            renderComplete={sectionComplete}
          />
        );
      case SelfStudySectionComponentType.CONSULTANTREVIEW:
        return (
          <SelfStudyConsultantReview
            academicYear={props.academicYear ?? 0}
            selfStudyResponseId={props.selfStudyResponseId}
            selfStudyId={props.selfStudyId}
            sectionIndex={sectionIndex}
            readonly={props.readonly || responseComplete}
            componentIndex={componentIndex}
            pdfView={true}
            selfStudyResponseTypeId={selfStudyResponse.selfStudyReponseTypeId}
            renderComplete={sectionComplete}
          />
        );
    }
  };

  const renderSectionPdfs = () => {
    return (
      <Page style={{ padding: 20 }}>
        <View style={{ margin: 10 }}>
          <Text>
            {' '}
            <Image
              src={process.env.PUBLIC_URL + '/IDOE-logo-grayscale.png'}
              style={{ width: '50px', height: '50px', paddingBottom: '10pt' }}
            ></Image>{' '}
            Iowa Department of Education
          </Text>
        </View>

        {selfStudyTemplate?.selfStudySectionTemplates?.map((section, index) =>
          renderPdfSection(section.selfStudySectionComponentTemplates, index)
        )}
      </Page>
    );
  };

  const documentDownload = async () => {
    setPdfDownloadLoading(true);
    const document = (
      <Document title="Self Study Response Pdf" subject="Self Study Response Pdf">
        {renderSectionPdfs()}
      </Document>
    );
  
    const pdfs = await pdf(document);
    setPdf(pdfs);
  };

  return props.downloadText ? (
    <Button type="link" onClick={documentDownload} loading={pdfDownloadLoading || !sectionLoadingSetup}>
      {props.downloadText}
    </Button>
  ) : (
    <Button
      type="link"
      onClick={documentDownload}
      loading={pdfDownloadLoading || !sectionLoadingSetup || loadings}
      icon={<VerticalAlignBottomOutlined />}
    ></Button>
  );
});

SelfStudyPdfDocument.displayName = 'SelfStudyPdfDocument';
export default SelfStudyPdfDocument;
