// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import AnnouncementTypeDTO from './AnnouncementTypeDTO';
import AnnouncementInstitutionDTO from './AnnouncementInstitutionDTO';
import AnnouncementRoleDTO from './AnnouncementRoleDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AnnouncementDTO extends EntitySelectorDTO { 
	id: string | null | null;
	institutionIds: string[];
	roleIds: string[];
	announcementTypeId: number;
	announcementType: AnnouncementTypeDTO | null;
	announcementInstitutions: AnnouncementInstitutionDTO[] | null;
	announcementRoles: AnnouncementRoleDTO[] | null;
	title: string | null;
	message: string | null;
	showOnDate: moment.Moment | string;
	showUntilDate: moment.Moment | string;
	showDates: moment.Moment[] | string[];
	isPublic: boolean;
	status: string | null;
}

const AnnouncementDTO = {
    className: 'AnnouncementDTO',
    create: (initValues?: Partial<AnnouncementDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			institutionIds: [],
			roleIds: [],
			announcementTypeId: 0,
			announcementType: null,
			announcementInstitutions: [],
			announcementRoles: [],
			title: "",
			message: "",
			showOnDate: new Date(0).toISOString(),
			showUntilDate: new Date(0).toISOString(),
			showDates: [],
			isPublic: false,
			status: "",
        },
        initValues);
    },
	id: 'id',
	institutionIds: 'institutionIds',
	roleIds: 'roleIds',
	announcementTypeId: 'announcementTypeId',
	announcementType: 'announcementType',
	announcementInstitutions: 'announcementInstitutions',
	announcementRoles: 'announcementRoles',
	title: 'title',
	message: 'message',
	showOnDate: 'showOnDate',
	showUntilDate: 'showUntilDate',
	showDates: 'showDates',
	isPublic: 'isPublic',
	status: 'status',
};

export default AnnouncementDTO;