// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ChangeRequestApprovalDTO from '../models/ChangeRequestApprovalDTO';
import * as SaveChangeRequestApprovalUserHandler from '../handlerModels/SaveChangeRequestApprovalUserHandler';

export class ChangeRequestApprovalApiService extends BaseApi {

    // post: api/changeRequestApprovalApiController/list/${encodeURIComponent(changeRequestId)}
    public getChangeRequestApprovalTableData(tableRequest: TableRequestDTO, changeRequestId: string): Promise<TableResponseDTO<ChangeRequestApprovalDTO>> {
        let url = `api/changeRequestApprovalApiController/list/${encodeURIComponent(changeRequestId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ChangeRequestApprovalDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/changeRequestApprovalApiController/saveChangeRequestApprovalUser
    public saveChangeRequestApprovalUser(saveChangeRequestApprovalUser: SaveChangeRequestApprovalUserHandler.Request): Promise<SaveChangeRequestApprovalUserHandler.Result> {
        let url = `api/changeRequestApprovalApiController/saveChangeRequestApprovalUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveChangeRequestApprovalUserHandler.Result>(saveChangeRequestApprovalUser, 'post', url, true, false);
    }
}
var service = new ChangeRequestApprovalApiService();
export default service;
