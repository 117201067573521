// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import DisciplineDTO from './DisciplineDTO';
import YearTermDTO from './YearTermDTO';
import InstitutionCourseDTO from './InstitutionCourseDTO';
import CourseDetailDTO from './CourseDetailDTO';
import StatusTypeDTO from './StatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CourseDTO extends EntitySelectorDTO { 
	id: string | null;
	disciplineId: string | null;
	discipline: DisciplineDTO | null;
	catalogNumber: string | null;
	install: YearTermDTO | null;
	retire: YearTermDTO | null;
	deletedOn: moment.Moment | string | null;
	deletedBy: string | null;
	createdOn: moment.Moment | string | null;
	createdBy: string | null;
	updatedOn: moment.Moment | string | null;
	updatedBy: string | null;
	institutionCourses: InstitutionCourseDTO[] | null;
	courseDetails: CourseDetailDTO[] | null;
	currentDetail: CourseDetailDTO | null;
	display: string | null;
	installDisplay: string | null;
	status: StatusTypeDTO | null;
}

const CourseDTO = {
    className: 'CourseDTO',
    create: (initValues?: Partial<CourseDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			disciplineId: '00000000-0000-0000-0000-000000000000',
			discipline: null,
			catalogNumber: "",
			install: null,
			retire: null,
			deletedOn: null,
			deletedBy: "",
			createdOn: null,
			createdBy: "",
			updatedOn: null,
			updatedBy: "",
			institutionCourses: [],
			courseDetails: [],
			currentDetail: null,
			display: "",
			installDisplay: "",
			status: null,
        },
        initValues);
    },
	id: 'id',
	disciplineId: 'disciplineId',
	discipline: 'discipline',
	catalogNumber: 'catalogNumber',
	install: 'install',
	retire: 'retire',
	deletedOn: 'deletedOn',
	deletedBy: 'deletedBy',
	createdOn: 'createdOn',
	createdBy: 'createdBy',
	updatedOn: 'updatedOn',
	updatedBy: 'updatedBy',
	institutionCourses: 'institutionCourses',
	courseDetails: 'courseDetails',
	currentDetail: 'currentDetail',
	display: 'display',
	installDisplay: 'installDisplay',
	status: 'status',
};

export default CourseDTO;