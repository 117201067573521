import { Alert, Input, InputNumber, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ContactTypesApiService from '../../../api/ContactTypeApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetContactTypeDetailsHandler from '../../../handlerModels/GetContactTypeDetailsHandler';
import * as SaveContactTypeDetailsHandler from '../../../handlerModels/SaveContactTypeDetailsHandler';
import ContactTypeDTO from '../../../models/ContactTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface ContactTypeDetailsFormState extends BaseFormState {
  contactType: ContactTypeDTO;
}

interface ContactTypeDetailsFormProps extends BaseFormProps {
  contactTypeId: number;
  onSave?: (contactYypeId: number) => void;
}

class ContactTypeDetailsForm extends React.Component<ContactTypeDetailsFormProps, ContactTypeDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(ContactTypeDTO.name, {
      required: true,
      name: ContactTypeDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ],
    })
    .set(ContactTypeDTO.displayOrder, {
      required: true,
      name: ContactTypeDTO.displayOrder,
      label: 'Display Order',
      rules: [
        ValidationRuleUtil.required()
      ],
    });

  constructor(props: ContactTypeDetailsFormProps) {
    super(props);

    this.state = {
      contactType: ContactTypeDTO.create({
        displayOrder: ''
      }),
    };
  }

  componentDidMount() {
    this.loadContactType();
  }

  componentDidUpdate(prevProps: ContactTypeDetailsFormProps) {
    if (this.props.contactTypeId && this.props.contactTypeId != prevProps.contactTypeId) {
      this.loadContactType();
    }
  }

  private loadContactType() {
    if (!this.state.loading) {
      if (this.props.contactTypeId == 0) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.contactTypeId) {
      this.setState({ loading: true });

      ContactTypesApiService.getContactTypeDetails(this.props.contactTypeId)
        .then((results: GetContactTypeDetailsHandler.Result) => {
          if (results) {
            this.setState({
              contactType: results.contactType ?? ContactTypeDTO.create()
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveContactTypeDetailsHandler.Request.create({
      contactTypeId: this.props.contactTypeId,
      contactType: ContactTypeDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    ContactTypesApiService.saveContactTypeDetails(request)
      .then((result: SaveContactTypeDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ contactType: result.contactType ?? ContactTypeDTO.create() });

          message.success('Saved');

          if (this.props.onSave && result.contactType?.id) {
            this.props.onSave(result.contactType.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(ContactTypeDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error(result?.errors.join('\n'));
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Contact Type could not be saved');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <PageStayPrompt when={this.state.altered} />
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.contactType}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(ContactTypeDTO.name)}
            {...ValidationUtil.getValidation(ContactTypeDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(ContactTypeDTO.displayOrder)}
            {...ValidationUtil.getValidation(ContactTypeDTO.displayOrder, this.state.fieldErrors, this.state.submitted)}>
            <InputNumber min={0} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CREDIT_TYPE]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!FormUtil.canSubmit(this._formRef, this._formItems)} saving={this.state.submitting} />
            <ResetButton disabled={!FormUtil.hasChanged(this._formRef, this._formItems)} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default ContactTypeDetailsForm;
