// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import UserDTO from './UserDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface DistrictUserDTO extends EntitySelectorDTO { 
	id: string | null;
	districtIdentifier: number;
	userId: string | null;
	user: UserDTO | null;
	emailAddress: string | null;
	firstName: string | null;
	lastName: string | null;
	districtName: string | null;
	roles: string[];
	roleIds: string[];
	dateAdded: string | null;
	status: string | null;
}

const DistrictUserDTO = {
    className: 'DistrictUserDTO',
    create: (initValues?: Partial<DistrictUserDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			districtIdentifier: 0,
			userId: '00000000-0000-0000-0000-000000000000',
			user: null,
			emailAddress: "",
			firstName: "",
			lastName: "",
			districtName: "",
			roles: [],
			roleIds: [],
			dateAdded: "",
			status: "",
        },
        initValues);
    },
	id: 'id',
	districtIdentifier: 'districtIdentifier',
	userId: 'userId',
	user: 'user',
	emailAddress: 'emailAddress',
	firstName: 'firstName',
	lastName: 'lastName',
	districtName: 'districtName',
	roles: 'roles',
	roleIds: 'roleIds',
	dateAdded: 'dateAdded',
	status: 'status',
};

export default DistrictUserDTO;