import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import DistrictDetailsForm from '../forms/secondary/DistrictDetailsForm';


interface DistrictDetailsModalProps extends ModalProps {
  districtId: string;
  academicYear: number;
  onSave?: () => void;
}

function DistrictDetailsModal(props: DistrictDetailsModalProps) {
  const _districtDetailsRef = React.createRef<DistrictDetailsForm>();

  const isNew = props.districtId == Guid.Empty();

  const submitSaveDistrict = () => {
    _districtDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New District' : 'Edit District'}
      okText='Save'
      onOk={submitSaveDistrict}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <DistrictDetailsForm
        ref={_districtDetailsRef}
        districtId={props.districtId ?? Guid.Empty}
        isEditing={true}
        onSave={props.onSave}
        modal={true}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default DistrictDetailsModal;