import { message } from 'antd';
import { Dispatch } from 'redux';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../config/Routes';
import * as LocalLoginHandler from '../../handlerModels/LocalLoginHandler';
import UserSecurity from '../../models/UserSecurity';
import UserSecurityDTO from '../../models/UserSecurityDTO';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import History from '../../utils/HistoryUtil';
import BaseAction from './BaseAction';
import { DistrictActions } from './DistrictActions';
import { InstitutionActions } from './InstitutionActions';
import { RPPActions } from './RPPActions';

export type UserSessionAction = BaseAction<UserSessionActionTypes, any>;

export enum UserSessionActionTypes {
  USER_SESSION_CHECKING = 'USER_SESSION_CHECKING',
  USER_SESSION_CHECK = 'USER_SESSION_CHECK',
  USER_SESSION_LOADING = 'USER_SESSION_LOADING',
  USER_SESSION_LOGIN = 'USER_SESSION_LOGIN',
  USER_SESSION_LOGIN_ERROR = 'USER_SESSION_LOGIN_ERROR',
  USER_SESSION_LOGOUT = 'USER_SESSION_LOGOUT',
  USER_SESSION_CHANGED = 'USER_SESSION_CHANGED',
  USER_SESSION_SET_LAST_INTERACTION = 'USER_SESSION_SET_LAST_INTERACTION',
}

export const UserSessionActions = {
  login: UserSessionLoginAction,
  logout: UserSessionLogoutAction,
  check: UserSessionCheckAction,
  refresh: UserSessionRefreshAction,
  setLastInteraction: UserSessionSetLastInteractionAction,
};

function UserSessionSetLastInteractionAction() {
  return {
    type: UserSessionActionTypes.USER_SESSION_SET_LAST_INTERACTION,
    data: null
  };
}

function UserSessionLogoutAction() {
  return (dispatch: Dispatch<any>) => {
    AccountApiService.logout()
      .then(() => {
        dispatch(UserSessionChangedAction(null));
        dispatch(InstitutionActions.select(null));
        dispatch(DistrictActions.select(null));
        dispatch(RPPActions.select(null));
        message.success('Log Out Successful');
        History.push(Routes.HOME);
      })
      .catch(() => {
        dispatch(UserSessionLoadingAction(false));
      });
  };
}

function UserSessionLoginAction(model: LocalLoginHandler.Request) {
  return (dispatch: Dispatch<any>) => {
    dispatch(UserSessionLoadingAction(true));
    AccountApiService.login(model)
      .then((response: LocalLoginHandler.Result) => {
        if (response.succeeded) {
          _UserSessionCheckAction(dispatch, true);
        } else {

          dispatch(UserSessionLoginErrorAction(response.errors.join('\n')));
          dispatch(UserSessionLoadingAction(false));
        }
      })
      .catch(() => {
        dispatch(UserSessionLoginErrorAction('Login failed.'));
        dispatch(UserSessionLoadingAction(false));
      });
  };
}

function UserSessionCheckAction() {
  return (dispatch: Dispatch<any>, getState: () => StateStoreModel) => {
    const current = getState().UserSession;
    if (current.Value) {
      dispatch(UserSessionChangedAction(current.Value));
      return;
    }
    dispatch(UserSessionCheckingAction(true));
    _UserSessionCheckAction(dispatch, false);
  };
}

function _UserSessionCheckAction(dispatch: Dispatch<any>, isLoading: boolean) {
  const userAction = isLoading ? UserSessionLoadingAction : UserSessionCheckingAction;
  AccountApiService.getCurrentUser()
    .then((results: UserSecurityDTO | null) => {
      if (results) {
        const userSecurity = new UserSecurity(results);
        dispatch(UserSessionChangedAction(userSecurity));
        dispatch(InstitutionActions.defaultSelect(userSecurity));
        dispatch(DistrictActions.defaultSelect(userSecurity));
        dispatch(RPPActions.defaultSelect(userSecurity));
      } else {
        dispatch(UserSessionChangedAction(null));
      }
      dispatch(userAction(false));
    })
    .catch(() => {
      dispatch(userAction(false));
    });
}

function UserSessionRefreshAction(newUserSession: UserSecurityDTO | null) {
  return (dispatch: Dispatch<any>) => {
    // Start session loading
    if (newUserSession) {
      dispatch(UserSessionChangedAction(new UserSecurity(newUserSession)));
    } else {
      dispatch(UserSessionChangedAction(null));
    }
  };
}

function UserSessionLoginErrorAction(message: string): UserSessionAction {
  return {
    type: UserSessionActionTypes.USER_SESSION_LOGIN_ERROR,
    data: message,
  };
}

function UserSessionCheckingAction(isChecking: boolean): UserSessionAction {
  return {
    type: UserSessionActionTypes.USER_SESSION_CHECKING,
    data: isChecking,
  };
}

function UserSessionLoadingAction(isLoading: boolean): UserSessionAction {
  return {
    type: UserSessionActionTypes.USER_SESSION_LOADING,
    data: isLoading,
  };
}

function UserSessionChangedAction(model?: UserSecurity | null | undefined): UserSessionAction {
  return {
    type: UserSessionActionTypes.USER_SESSION_CHANGED,
    data: model,
  };
}