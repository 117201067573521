// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// EPRExportHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    institutionIds: string[];
    termId: string | null;
    year: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            institutionIds: [],
            termId: '00000000-0000-0000-0000-000000000000',
            year: 0,
        },
        initValues);
    },
    institutionIds: 'institutionIds',
    termId: 'termId',
    year: 'year',
}

interface Result extends ResultDTO {
    file: any | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            file: null,
        },
        initValues);
    },
    file: 'file',
}

export { Request, Result };