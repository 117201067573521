import { Alert, Input, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import CategoriesApiService from '../../../api/CategoriesApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetCategoryDetailsHandler from '../../../handlerModels/GetCategoryDetailsHandler';
import * as SaveCategoryDetailsHandler from '../../../handlerModels/SaveCategoryDetailsHandler';
import CategoryDTO from '../../../models/CategoryDTO';
import StatusTypeDTO from '../../../models/StatusTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface CategoryDetailsFormState extends BaseFormState {
  category: CategoryDTO;
  statusTypes: StatusTypeDTO[];
}

interface CategoryDetailsFormProps extends BaseFormProps {
  categoryId: string;
  onSave?: (id: string) => void;
}

class CategoryDetailsForm extends React.Component<CategoryDetailsFormProps, CategoryDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(CategoryDTO.code, {
      required: true,
      name: CategoryDTO.code,
      label: 'Code',
      rules: [ValidationRuleUtil.required()],
    })
    .set(CategoryDTO.name, {
      required: true,
      name: CategoryDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(100)
      ]
    })
    .set(CategoryDTO.statusTypeId, {
      required: true,
      name: CategoryDTO.statusTypeId,
      label: 'Status',
      rules: [
        ValidationRuleUtil.required()
      ]
    });

  constructor(props: CategoryDetailsFormProps) {
    super(props);

    this.state = {
      category: CategoryDTO.create({
        statusTypeId: ''
      }),
      statusTypes: []
    };
  }

  componentDidMount() {
    this.loadCategory();
  }

  componentDidUpdate(prevProps: CategoryDetailsFormProps) {
    if (this.props.categoryId && this.props.categoryId != prevProps.categoryId) {
      this.loadCategory();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false, submitted: false });
  }

  private loadCategory() {
    this.setState({ loading: true });

    const loaders = [];
    if (this.state.statusTypes.length == 0) {
      loaders.push(this.loadStatusTypes());
    }

    if (this.props.categoryId != Guid.Empty()) {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadStatusTypes = () => {
    return LookupsUtil.getAll<StatusTypeDTO>(StatusTypeDTO.className)
      .then((results: StatusTypeDTO[]) => {
        if (results) {
          this.setState({ statusTypes: results });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadExisting = () => {
    return CategoriesApiService.getCategoryDetails(this.props.categoryId)
      .then((results: GetCategoryDetailsHandler.Result) => {
        if (results.category) {
          this.setState({
            category: results.category,
          });

          this.resetForm();
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveCategoryDetailsHandler.Request.create({
      categoryId: this.props.categoryId,
      category: CategoryDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    CategoriesApiService.saveCategoryDetails(request)
      .then((result: SaveCategoryDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          category: result.category ?? CategoryDTO.create(),
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.category?.id) {
            this.props.onSave(result.category.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(CategoryDTO.className);

          this.setState({ altered: false });
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Error Saving');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.category}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>

          <FormItem
            {...this._formItems.get(CategoryDTO.name)}
            {...ValidationUtil.getValidation(CategoryDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(CategoryDTO.code)}
            {...ValidationUtil.getValidation(CategoryDTO.code, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(CategoryDTO.statusTypeId)}
            {...ValidationUtil.getValidation(CategoryDTO.statusTypeId, this.state.fieldErrors, this.state.submitted)}>
            <Select disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
              {this.state.statusTypes.map(x => { return this.renderStatusTypeOption(x) })}
            </Select>
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space >
    );
  }

  renderStatusTypeOption(statusType: StatusTypeDTO) {
    if (statusType.id) {
      return <Select.Option key={statusType.id} value={statusType.id}>{statusType.name}</Select.Option>;
    }
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CATEGORY]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!FormUtil.canSubmit(this._formRef, this._formItems)} saving={this.state.submitting} />
            <ResetButton disabled={!FormUtil.hasChanged(this._formRef, this._formItems)} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default CategoryDetailsForm;
