// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import DistrictDTO from '../models/DistrictDTO';
import DistrictContactDTO from '../models/DistrictContactDTO';
import * as AcademicYearHasDistrictsHandler from '../handlerModels/AcademicYearHasDistrictsHandler';
import * as GetDistrictDetailsHandler from '../handlerModels/GetDistrictDetailsHandler';
import * as GetDistrictsHandler from '../handlerModels/GetDistrictsHandler';
import * as GetAllDistrictsHandler from '../handlerModels/GetAllDistrictsHandler';
import * as SaveDistrictDetailsHandler from '../handlerModels/SaveDistrictDetailsHandler';
import * as DeleteDistrictHandler from '../handlerModels/DeleteDistrictHandler';
import * as SearchForDistrictsHandler from '../handlerModels/SearchForDistrictsHandler';
import * as GetDistrictsForUserHandler from '../handlerModels/GetDistrictsForUserHandler';
import * as GetDistrictContactsHandler from '../handlerModels/GetDistrictContactsHandler';
import * as SaveDistrictContactHandler from '../handlerModels/SaveDistrictContactHandler';
import * as DeleteDistrictContactHandler from '../handlerModels/DeleteDistrictContactHandler';

export class DistrictApiService extends BaseApi {

    // post: api/districts?academicYearId=${academicYearId}&isPublic=${isPublic}&rppId=${encodeURIComponent(rppId)}
    public getDistrictTableData(tableRequest: TableRequestDTO, academicYearId: number, isPublic: boolean, rppId: string | null): Promise<TableResponseDTO<DistrictDTO>> {
        let url = `api/districts`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }
        if (isPublic != null) {
            url += `${prefix}isPublic=${isPublic}`;
            prefix = '&';
        }
        if (rppId != null) {
            url += `${prefix}rppId=${rppId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<DistrictDTO>>(tableRequest, 'post', url, true, false);
    }

    // get: api/districts/districtTemplate
    public districtTemplate(): Promise<any> {
        let url = `api/districts/districtTemplate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // post: api/districts/hasDistricts/${academicYear}
    public academicYearHasDistricts(academicYear: number): Promise<AcademicYearHasDistrictsHandler.Result> {
        let url = `api/districts/hasDistricts/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AcademicYearHasDistrictsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/districts/get
    public getDistrictDetails(getDistrictDetails: GetDistrictDetailsHandler.Request): Promise<GetDistrictDetailsHandler.Result> {
        let url = `api/districts/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetDistrictDetailsHandler.Result>(getDistrictDetails, 'post', url, true, false);
    }

    // post: api/districts/getDistricts
    public getDistricts(getDistricts: GetDistrictsHandler.Request): Promise<GetDistrictsHandler.Result> {
        let url = `api/districts/getDistricts`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetDistrictsHandler.Result>(getDistricts, 'post', url, true, false);
    }

    // post: api/districts/getAllDistricts
    public getAllDistricts(getAllDistricts: GetAllDistrictsHandler.Request): Promise<GetAllDistrictsHandler.Result> {
        let url = `api/districts/getAllDistricts`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAllDistrictsHandler.Result>(getAllDistricts, 'post', url, true, false);
    }

    // post: api/districts/save
    public saveDistrictDetails(saveDistrictDetails: SaveDistrictDetailsHandler.Request): Promise<SaveDistrictDetailsHandler.Result> {
        let url = `api/districts/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveDistrictDetailsHandler.Result>(saveDistrictDetails, 'post', url, true, false);
    }

    // post: api/districts/deleteDistrict
    public deleteDistrict(deleteDistrict: DeleteDistrictHandler.Request): Promise<DeleteDistrictHandler.Result> {
        let url = `api/districts/deleteDistrict`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteDistrictHandler.Result>(deleteDistrict, 'post', url, true, false);
    }

    // post: api/districts/districtImporter
    public districtImporter(districtImporter: FormData): Promise<any> {
        let url = `api/districts/districtImporter`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(districtImporter, 'post', url, false, false);
    }

    // post: api/districts/searchForDistricts
    public searchForDistricts(searchForDistricts: SearchForDistrictsHandler.Request): Promise<SearchForDistrictsHandler.Result> {
        let url = `api/districts/searchForDistricts`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForDistrictsHandler.Result>(searchForDistricts, 'post', url, true, false);
    }

    // post: api/districts/getDistrictsUser
    public getDistrictsForUser(getDistrictsForUser: GetDistrictsForUserHandler.Request): Promise<GetDistrictsForUserHandler.Result> {
        let url = `api/districts/getDistrictsUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetDistrictsForUserHandler.Result>(getDistrictsForUser, 'post', url, true, false);
    }

    // get: api/districts/districtContactTemplate
    public districtContactTemplate(): Promise<any> {
        let url = `api/districts/districtContactTemplate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // post: api/districts/getDistrictContactsDataTable?academicYearId=${academicYearId}&districtId=${encodeURIComponent(districtId)}
    public getDistrictContactsDataTable(tableRequest: TableRequestDTO, academicYearId: number, districtId: string): Promise<TableResponseDTO<DistrictContactDTO>> {
        let url = `api/districts/getDistrictContactsDataTable`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }
        if (districtId != null) {
            url += `${prefix}districtId=${encodeURIComponent(districtId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<DistrictContactDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/districts/getDistrictContacts
    public getDistrictContacts(getDistrictContacts: GetDistrictContactsHandler.Request): Promise<GetDistrictContactsHandler.Result> {
        let url = `api/districts/getDistrictContacts`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetDistrictContactsHandler.Result>(getDistrictContacts, 'post', url, true, false);
    }

    // post: api/districts/saveContact
    public saveDistrictContactDetails(saveDistrictContact: SaveDistrictContactHandler.Request): Promise<SaveDistrictContactHandler.Result> {
        let url = `api/districts/saveContact`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveDistrictContactHandler.Result>(saveDistrictContact, 'post', url, true, false);
    }

    // post: api/districts/deleteDistrictContact
    public deleteDistrictContact(deleteDistrictContact: DeleteDistrictContactHandler.Request): Promise<DeleteDistrictContactHandler.Result> {
        let url = `api/districts/deleteDistrictContact`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteDistrictContactHandler.Result>(deleteDistrictContact, 'post', url, true, false);
    }

    // post: api/districts/districtContactImporter
    public districtContactImporter(districtContactImporter: FormData): Promise<any> {
        let url = `api/districts/districtContactImporter`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(districtContactImporter, 'post', url, false, false);
    }
}
var service = new DistrictApiService();
export default service;
