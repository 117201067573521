import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CreditTypesDataTable from '../../../../components/datatables/postSecondary/CreditTypesDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface CreditTypeListProps {
}

interface CreditTypeListState {
}

class CreditTypeListPage extends React.Component<CreditTypeListProps & RouteComponentProps<RouteObject>, CreditTypeListState> {
  constructor(props: CreditTypeListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Credit Types" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.creditTypeList()} />

        <Card>
          <CreditTypesDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(CreditTypeListPage);
