import { Alert, AutoComplete, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ResourceApiService from '../../../api/ResourceApiService';
import * as SaveResourceLinkDetailHandler from '../../../handlerModels/SaveResourceLinkDetailHandler';
import * as SearchForResourceCategoryHandler from '../../../handlerModels/SearchForResourceCategoryHandler';
import ResourceCategoryDTO from '../../../models/ResourceCategoryDTO';
import ResourceLinkDTO from '../../../models/ResourceLinkDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';

interface AddResourceLinkFormState extends BaseFormState {
  categories: string[];
  resourceLink: ResourceLinkDTO;
}

interface AddResourceLinkFormProps extends BaseFormProps {
  onSuccess?: () => void;
  resourceLink: ResourceLinkDTO;
}

class AddResourceLinkForm extends React.Component<AddResourceLinkFormProps, AddResourceLinkFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(SaveResourceLinkDetailHandler.Request.title, {
      required: true,
      name: SaveResourceLinkDetailHandler.Request.title,
      label: 'Title',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(SaveResourceLinkDetailHandler.Request.url, {
      required: true,
      name: SaveResourceLinkDetailHandler.Request.url,
      label: 'URL (Must include http:// or https://)',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.https(),
      ]
    })
    .set(SaveResourceLinkDetailHandler.Request.category, {
      name: SaveResourceLinkDetailHandler.Request.category,
      label: 'Category'
    })
    .set(SaveResourceLinkDetailHandler.Request.description, {
      name: SaveResourceLinkDetailHandler.Request.description,
      label: 'Description'
    });

  constructor(props: AddResourceLinkFormProps) {
    super(props);

    this.state = {
      categories: [],
      resourceLink: ResourceLinkDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ resourceLink: this.props.resourceLink }, () => this.reset());
  }

  componentDidUpdate(prevProps: AddResourceLinkFormProps) {
    if (this.props.resourceLink && this.props.resourceLink != prevProps.resourceLink) {
      this.setState({ resourceLink: this.props.resourceLink }, () => this.reset());
    }
  }

  public submit = () => {
    this.handleSubmit();
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private searchForResourceCategory = (searchText: string) => {
    ResourceApiService.searchForResourceCategory(searchText)
      .then((results: SearchForResourceCategoryHandler.Result) => {
        if (results.categories) {
          this.setState({ categories: results.categories })
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private handleSearch = (value: any) => {
    value.length >= 2 ? this.searchForResourceCategory(value) : this.setState({ categories: [] });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    if (!FormUtil.canSubmit(this._formRef, this._formItems)) {
      message.error('Please fill out all required fields.');
      this.setState({ loading: false, submitting: false });
      return;
    }

    const request = SaveResourceLinkDetailHandler.Request.create({
      resourceLinkId: this.props.resourceLink.id,
      url: this._formRef.current?.getFieldValue(SaveResourceLinkDetailHandler.Request.url),
      title: this._formRef.current?.getFieldValue(SaveResourceLinkDetailHandler.Request.title),
      category: this._formRef.current?.getFieldValue(SaveResourceLinkDetailHandler.Request.category),
      description: this._formRef.current?.getFieldValue(SaveResourceLinkDetailHandler.Request.description)
    });

    ResourceApiService.saveResourceLink(request)
      .then((result: SaveResourceLinkDetailHandler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSuccess) {
            LookupsUtil.invalidateCache(ResourceCategoryDTO.className);
            this.props.onSuccess();
            this.reset();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Resource could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Resource could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const autoCompleteOptions: any[] = this.state.categories.map(x => {
      return { value: x, key: x }
    });

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          initialValues={this.state.resourceLink}
          layout="vertical"
          onValuesChange={this.handleChange}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(SaveResourceLinkDetailHandler.Request.title)}
            {...ValidationUtil.getValidation(SaveResourceLinkDetailHandler.Request.title, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SaveResourceLinkDetailHandler.Request.url)}
            {...ValidationUtil.getValidation(SaveResourceLinkDetailHandler.Request.url, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SaveResourceLinkDetailHandler.Request.category)}
            initialValue={this.state.resourceLink.categoryTitle}
            {...ValidationUtil.getValidation(SaveResourceLinkDetailHandler.Request.category, this.state.fieldErrors, this.state.submitted)}>
            <AutoComplete
              options={autoCompleteOptions}
              onSearch={this.handleSearch}>
              <Input placeholder="Category" />
            </AutoComplete>
          </FormItem>

          <FormItem
            {...this._formItems.get(SaveResourceLinkDetailHandler.Request.description)}
            {...ValidationUtil.getValidation(SaveResourceLinkDetailHandler.Request.description, this.state.fieldErrors, this.state.submitted)}>
            <Input.TextArea maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

        </Form >
      </Space >
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AddResourceLinkForm;
