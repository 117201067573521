import { Space } from 'antd';
import * as React from 'react';
import InstitutionCourseBlockApiService from '../../../api/InstitutionCourseBlockApiService';
import Routes from '../../../config/Routes';
import InstitutionCourseBlockProgramDataTableDTO from '../../../models/InstitutionCourseBlockProgramDataTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormState from '../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import CourseSearch from '../../postSecondary/CourseSearch';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface InstitutionCourseBlockProgramsDataTableProps {
  institutionCourseBlocklId: string;
  institutionId: string;
  triggerUpdate?: () => void;
}

interface InstitutionCourseBlockProgramsDataTableState extends BaseDataTableState<InstitutionCourseBlockProgramDataTableDTO>, BaseFormState {
  showAddCourseModal: boolean | false;
  awardTypes: TableFilterOption[];
  iowaVariations: TableFilterOption[];
}

class InstitutionCourseBlockProgramsDataTable extends React.Component<InstitutionCourseBlockProgramsDataTableProps, InstitutionCourseBlockProgramsDataTableState> {
  private readonly _courseSearchRef = React.createRef<CourseSearch>();

  constructor(props: InstitutionCourseBlockProgramsDataTableProps) {
    super(props);
    this.state = {
      showAddCourseModal: false,
      awardTypes: [],
      iowaVariations: [],
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: InstitutionCourseBlockProgramsDataTableProps) {
    if (prevProps.institutionCourseBlocklId != this.props.institutionCourseBlocklId) {
      this.triggerRefresh();
    }
  }

  private fetchData = () => {
    const loaders = [] as any[];

    TableFilterUtil.AwardTypes().then(x => this.setState({ awardTypes: x }));
    TableFilterUtil.IowaVariations().then(x => this.setState({ iowaVariations: x }))

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private _dataTable: DataTable<InstitutionCourseBlockProgramDataTableDTO> | undefined;

  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }

  private openProgram = (record: InstitutionCourseBlockProgramDataTableDTO) => {
    if (record.programId) {
      HistoryUtil.push(Routes.generate(Routes.PROGRAM_DETAILS, { id: record.programId }, {}), '_blank');
    }
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Title',
        dataIndex: InstitutionCourseBlockProgramDataTableDTO.title,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Awards',
        dataIndex: InstitutionCourseBlockProgramDataTableDTO.awardTypeIds,
        render: (data: string, row: InstitutionCourseBlockProgramDataTableDTO) => {
          const types = this.state.awardTypes.filter(x => row.awardTypeIds.some(y => y == x.value)).map(x => x.text);
          return types.join(', ');
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.awardTypes,
        width: 225
      },
      {
        title: 'CIP Number ',
        dataIndex: InstitutionCourseBlockProgramDataTableDTO.cipNumberId,
        render: (data: string, row: InstitutionCourseBlockProgramDataTableDTO) => {
          if (row.cipNumber?.cipNumberDetails && row.cipNumber?.cipNumberDetails?.length > 0) {
            return row.cipNumber.cipNumberDetails[0].display + ' - ' + row.cipNumber.cipNumberDetails[0].title;
          }
        },
        sorter: true,
        filterType: FilterType.Text,
        width: 225
      },
      {
        title: 'Iowa Variation',
        dataIndex: InstitutionCourseBlockProgramDataTableDTO.iowaVariationId,
        render: (data: string, row: InstitutionCourseBlockProgramDataTableDTO) => {

          return row.iowaVariation?.display;
        },
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.iowaVariations,
        width: 225
      }
    ] as DataTableColumnProps<any>[];
  };

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <Space direction='vertical' >
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openProgram}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => InstitutionCourseBlockApiService.getProgramsForInstitutionCourseBlockDetailTableData(requestState, this.props.institutionCourseBlocklId)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'course_icb_list' }} />
      </Space>
    );
  }
}

export default InstitutionCourseBlockProgramsDataTable;
