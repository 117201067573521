import { Alert, Col, FormItemProps, Input, InputNumber, message, Row, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionLocationApiService from '../../../api/InstitutionLocationApiService';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import { ColumnWidths } from '../../../config/ColumnWidths';
import * as GetInstitutionLocationsHandler from '../../../handlerModels/GetInstitutionLocationsHandler';
import * as SaveNewProgramChangeRequestStep10Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep10Handler';
import * as SubmitNewProgramChangeRequestStep10Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep10Handler';
import * as GetNewProgramChangeRequestStep10Handler from '../../../handlerModels/GetNewProgramChangeRequestStep10Handler';
import AwardTypeDTO from '../../../models/AwardTypeDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import InstitutionLocationDTO from '../../../models/InstitutionLocationDTO';
import InstructionalLevelDTO from '../../../models/InstructionalLevelDTO';
import IowaVariationDTO from '../../../models/IowaVariationDTO';
import ModeOfDeliveryDTO from '../../../models/ModeOfDeliveryDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import NewProgramProgramAwardDTO from '../../../models/NewProgramProgramAwardDTO';
import ObjectPurposeDTO from '../../../models/ObjectPurposeDTO';
import SpecialEmphasisDTO from '../../../models/SpecialEmphasisDTO';
import TermDTO from '../../../models/TermDTO';
import TypeOfProgramDTO from '../../../models/TypeOfProgramDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import Dropdown from '../../inputs/Dropdown';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import ThirdPartyCredentialDisplay from '../../displays/ThirdPartyCredentialDisplay';

interface NewProgramChangeRequestStep10FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  award: NewProgramProgramAwardDTO;
  instructionalLevels: InstructionalLevelDTO[];
  modeOfDelivery: ModeOfDeliveryDTO[];
  objectPurposes: ObjectPurposeDTO[];
  terms: TermDTO[];
  specialEmphasis: SpecialEmphasisDTO[];
  typeOfPrograms: TypeOfProgramDTO[];
  awardTypes: AwardTypeDTO[];
  iowaVariations: IowaVariationDTO[];
  institutionLocations: InstitutionLocationDTO[];
}

interface NewProgramChangeRequestStep10FormProps extends BaseFormProps, BaseChangeRequestProps {
  awardId?: string;
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: (awardId: string) => void;
  onSubmit?: (awardId: string) => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep10Form extends React.Component<NewProgramChangeRequestStep10FormProps, NewProgramChangeRequestStep10FormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  _selectRef = React.createRef<any>();

  private _formItems = new Map<string, FormItemProps>()
    .set(NewProgramProgramAwardDTO.awardTitle, {
      required: true,
      name: NewProgramProgramAwardDTO.awardTitle,
      label: 'Award Title',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.awardTypeId, {
      required: true,
      name: NewProgramProgramAwardDTO.awardTypeId,
      label: 'Award Type',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.description, {
      required: true,
      name: NewProgramProgramAwardDTO.description,
      label: 'Award Description',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.instructionalLevelId, {
      required: true,
      name: NewProgramProgramAwardDTO.instructionalLevelId,
      label: 'Instructional Level',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.typeOfProgramId, {
      required: true,
      name: NewProgramProgramAwardDTO.typeOfProgramId,
      label: 'Type Of Program',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.specialEmphasisId, {
      required: true,
      name: NewProgramProgramAwardDTO.specialEmphasisId,
      label: 'Special Emphasis',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.objectPurposeId, {
      required: true,
      name: NewProgramProgramAwardDTO.objectPurposeId,
      label: 'Object Purpose',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.totalWeeks, {
      required: true,
      name: NewProgramProgramAwardDTO.totalWeeks,
      label: 'What is the total amount of weeks for this award?',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.totalCredits, {
      required: true,
      name: NewProgramProgramAwardDTO.totalCredits,
      label: 'What is the total amount of credits for this award?',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.programAwardModesOfDeliveryIds, {
      required: true,
      name: NewProgramProgramAwardDTO.programAwardModesOfDeliveryIds,
      label: 'How will this award be offered?',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramProgramAwardDTO.newProgramProgramAwardInsitutionLocationIds, {
      name: NewProgramProgramAwardDTO.newProgramProgramAwardInsitutionLocationIds,
      label: 'At which location(s) will this program be offered?',
    })

  constructor(props: NewProgramChangeRequestStep10FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      award: NewProgramProgramAwardDTO.create({
        awardTypeId: null,
        iowaVariationId: null,
        instructionalLevelId: null,
        typeOfProgramId: null,
        specialEmphasisId: null,
        objectPurposeId: null,
        totalWeeks: null,
        totalCredits: null
      }),
      awardTypes: [],
      iowaVariations: [],
      instructionalLevels: [],
      modeOfDelivery: [],
      objectPurposes: [],
      specialEmphasis: [],
      terms: [],
      typeOfPrograms: [],
      institutionLocations: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep10FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.terms || this.state.terms.length == 0) {
      loaders.push(this.loadTerms());
    }

    if (!this.state.instructionalLevels || this.state.instructionalLevels.length == 0) {
      loaders.push(this.loadInstructionalLevel());
    }

    if (!this.state.typeOfPrograms || this.state.typeOfPrograms.length == 0) {
      loaders.push(this.loadTypeOfProgram());
    }

    if (!this.state.specialEmphasis || this.state.specialEmphasis.length == 0) {
      loaders.push(this.loadSpecialEmphasis());
    }

    if (!this.state.objectPurposes || this.state.objectPurposes.length == 0) {
      loaders.push(this.loadObjectPurpose());
    }

    if (!this.state.modeOfDelivery || this.state.modeOfDelivery.length == 0) {
      loaders.push(this.loadModeOfDelivery());
    }

    if (!this.state.awardTypes || this.state.awardTypes.length == 0) {
      loaders.push(this.loadAwardType());
    }

    if (!this.state.iowaVariations || this.state.iowaVariations.length == 0) {
      loaders.push(this.loadIowaVariation());
    }

    if (this.state.institutionLocations.length == 0) {
      loaders.push(this.loadInstitutionLocations());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest())
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadInstitutionLocations = () => {
    InstitutionLocationApiService.getInstitutionLocations(this.props.selectedInstitution ?? Guid.Empty())
      .then((result: GetInstitutionLocationsHandler.Result) => {
        if (result?.succeeded) {
          this.setState({ institutionLocations: result.institutionLocations ?? [] })
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
  }

  private loadTerms = () => {
    return LookupsUtil.getAll<TermDTO>(TermDTO.className)
      .then((results: TermDTO[]) => {
        if (results) {
          this.setState({ terms: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAwardType = () => {
    return LookupsUtil.getAll<AwardTypeDTO>(AwardTypeDTO.className)
      .then((results: AwardTypeDTO[]) => {
        if (results) {
          this.setState({ awardTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadIowaVariation = () => {
    return LookupsUtil.getAll<IowaVariationDTO>(IowaVariationDTO.className)
      .then((results: IowaVariationDTO[]) => {
        if (results) {
          this.setState({ iowaVariations: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTypeOfProgram = () => {
    return LookupsUtil.getAll<TypeOfProgramDTO>(TypeOfProgramDTO.className)
      .then((results: TypeOfProgramDTO[]) => {
        if (results) {
          this.setState({ typeOfPrograms: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadInstructionalLevel = () => {
    return LookupsUtil.getAll<InstructionalLevelDTO>(InstructionalLevelDTO.className)
      .then((results: InstructionalLevelDTO[]) => {
        if (results) {
          this.setState({ instructionalLevels: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadSpecialEmphasis = () => {
    return LookupsUtil.getAll<SpecialEmphasisDTO>(SpecialEmphasisDTO.className)
      .then((results: SpecialEmphasisDTO[]) => {
        if (results) {
          this.setState({ specialEmphasis: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadObjectPurpose = () => {
    return LookupsUtil.getAll<ObjectPurposeDTO>(ObjectPurposeDTO.className)
      .then((results: ObjectPurposeDTO[]) => {
        if (results) {
          this.setState({ objectPurposes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  };

  private loadModeOfDelivery = () => {
    return LookupsUtil.getAll<ModeOfDeliveryDTO>(ModeOfDeliveryDTO.className)
      .then((results: ModeOfDeliveryDTO[]) => {
        if (results) {
          this.setState({ modeOfDelivery: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  };

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep10Handler.Request.create({
      changeRequestId: this.props.changeRequestId,
      programAwardId: this.props.awardId
    });

    return NewProgramChangeRequestApiService.getStep10(request)
      .then((results: GetNewProgramChangeRequestStep10Handler.Result) => {
        if (results) {

          const programAward = results.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards?.find(x => x.id == this.props.awardId) ?? NewProgramProgramAwardDTO.create({
            awardTypeId: null,
            iowaVariationId: null,
            instructionalLevelId: null,
            typeOfProgramId: null,
            specialEmphasisId: null,
            objectPurposeId: null,
            totalWeeks: null,
            totalCredits: null
          });

          if (results.changeRequest?.newProgramChangeRequest?.isTransferMajorProgram) {
            programAward.awardTypeId = results.changeRequest?.newProgramChangeRequest.primrayAwardTypeId ?? null;
          }

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            award: programAward,
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private handleChange = (altered: boolean) => {
    this.setState({ altered: altered });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const award = NewProgramProgramAwardDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    award.id = this.props.awardId ?? Guid.Empty();

    if (award.id != Guid.Empty()) {
      award.order = this.state.award.order;
    }

    if (award.id == 'new') {
      award.id = Guid.Empty();
    }
    const newProgram = this.state.changeRequest.newProgramChangeRequest;
    const model = NewProgramChangeRequestDTO.create({
      id: newProgram?.id ?? Guid.Empty(),
      programId: newProgram?.programId ?? Guid.Empty(),
      institutionId: this.state.changeRequest.institutionId ?? Guid.Empty(),
      changeRequestId: this.state.changeRequest.id == Guid.Empty() ? this.props.changeRequestId : this.state.changeRequest.id,
      newProgramProgramAwards: [award]
    });

    const request = SubmitNewProgramChangeRequestStep10Handler.Request.create({
      newProgramChangeRequest: model
    });

    NewProgramChangeRequestApiService.submitStep10(request)
      .then((result: SubmitNewProgramChangeRequestStep10Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          let newAwardId = this.props.awardId ?? Guid.Empty();
          if (result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards) {
            if (this.props.awardId == Guid.Empty()) {
              newAwardId = result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards[result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards.length - 1].id as string;
            }
          }

          const AwardId = newAwardId;
          if (this.props.onSave) {
            this.props.onSave(AwardId)
          }

          if (this.props.onSubmit) {
            this.props.onSubmit(AwardId)
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  private handleSave = () => {
    this.setState({ submitting: true });
    const award = NewProgramProgramAwardDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    award.id = this.props.awardId ?? Guid.Empty();

    if (award.id != Guid.Empty()) {
      award.order = this.state.award.order;
    }

    if (award.id == 'new') {
      award.id = Guid.Empty();
    }
    const newProgram = this.state.changeRequest.newProgramChangeRequest;
    const model = NewProgramChangeRequestDTO.create({
      id: newProgram?.id ?? Guid.Empty(),
      programId: newProgram?.programId ?? Guid.Empty(),
      institutionId: this.state.changeRequest.institutionId ?? Guid.Empty(),
      changeRequestId: this.state.changeRequest.id == Guid.Empty() ? this.props.changeRequestId : this.state.changeRequest.id,
      newProgramProgramAwards: [award]
    });

    const request = SubmitNewProgramChangeRequestStep10Handler.Request.create({
      newProgramChangeRequest: model
    });

    NewProgramChangeRequestApiService.submitStep10(request)
      .then((result: SaveNewProgramChangeRequestStep10Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          let newAwardId = this.props.awardId ?? Guid.Empty();
          if (result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards) {
            if (this.props.awardId == Guid.Empty()) {
              newAwardId = result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards[result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards.length - 1].id as string;
            }
          }

          const AwardId = newAwardId;
          if (this.props.onSave) {
            this.props.onSave(AwardId)
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  private getSelectedAward = () => {
    return this.state.awardTypes.find(x => x.id == this._formRef.current?.getFieldValue(NewProgramProgramAwardDTO.awardTypeId));
  }

  private handleAwardTypeChange = () => {
    const awardType = this.state.awardTypes.find(x => x.id == this._formRef.current?.getFieldValue(NewProgramProgramAwardDTO.awardTypeId)) ?? AwardTypeDTO.create();

    if (!awardType.instructionalLevels.includes(this._formRef.current?.getFieldValue(NewProgramProgramAwardDTO.instructionalLevelId) ?? 0)) {
      this._formRef.current?.setFieldsValue({ instructionalLevelId: undefined })
    }

    if (!awardType.typeOfPrograms.includes(this._formRef.current?.getFieldValue(NewProgramProgramAwardDTO.typeOfProgramId) ?? 0)) {
      this._formRef.current?.setFieldsValue({ typeOfProgramId: undefined })
    }

    if (!awardType.specialEmphases.includes(this._formRef.current?.getFieldValue(NewProgramProgramAwardDTO.specialEmphasisId) ?? 0)) {
      this._formRef.current?.setFieldsValue({ specialEmphasisId: undefined })
    }

    if (!awardType.objectPurposes.includes(this._formRef.current?.getFieldValue(NewProgramProgramAwardDTO.objectPurposeId) ?? 0)) {
      this._formRef.current?.setFieldsValue({ objectPurposeId: undefined })
    }
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const formPrimaryAwardTypeId = this._formRef.current?.getFieldValue(NewProgramProgramAwardDTO.awardTypeId);
    const primarayAwardId = formPrimaryAwardTypeId ? formPrimaryAwardTypeId : this.state.changeRequest.newProgramChangeRequest?.primrayAwardTypeId;

    return (
      <Space size="small" direction="vertical" >
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.award}
          onFinish={this.handleSubmit}
          onValuesChange={this.handleChange}
          requiredMark={!this.props.readonly}>

          <FormItem
            id={'Title Award ' + this.state.award.order}
            {...this._formItems.get(NewProgramProgramAwardDTO.awardTitle)}
            {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.awardTitle + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Input
              aria-label={'Title Award ' + this.state.award.order}
              id={'Title Award ' + this.state.award.order}
              disabled={this.props.readonly}
            />
          </FormItem>

          <label
            title={'Award Type Award ' + this.state.award.order}
            htmlFor={'Award Type Award ' + this.state.award.order} />
          <FormItem
            {...this._formItems.get(NewProgramProgramAwardDTO.awardTypeId)}
            {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.awardTypeId + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Dropdown
              id={'Award Type Award ' + this.state.award.order}
              showArrow={!this.props.readonly} disabled={this.props.readonly || this.state.award.order == 1 || (this.state.changeRequest.newProgramChangeRequest?.isTransferMajorProgram ?? false)}
              onChange={this.handleAwardTypeChange}>
              {this.renderAwardTypes()}
            </Dropdown>
          </FormItem>

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.ONE_QUARTER}>
              <label
                title={'Instructional Level Award ' + this.state.award.order}
                htmlFor={'Instructional Level Award ' + this.state.award.order} />
              <FormItem
                {...this._formItems.get(NewProgramProgramAwardDTO.instructionalLevelId)}
                {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.instructionalLevelId + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >

                <Dropdown id={'Instructional Level Award ' + this.state.award.order} showArrow={!this.props.readonly} disabled={this.props.readonly}>
                  {this.state.instructionalLevels.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.instructionalLevels.includes(x.id)).map(x => this.renderInsturctionalLevel(x))}
                </Dropdown>

              </FormItem>
            </Col>

            <Col {...ColumnWidths.ONE_QUARTER}>
              <label
                title={'Type Of Program Award ' + this.state.award.order}
                htmlFor={'Type Of Program Award ' + this.state.award.order} />
              <FormItem
                {...this._formItems.get(NewProgramProgramAwardDTO.typeOfProgramId)}
                {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.typeOfProgramId + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >

                <Dropdown id={'Type Of Program Award ' + this.state.award.order} showArrow={!this.props.readonly} disabled={this.props.readonly}>
                  {this.state.typeOfPrograms.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.typeOfPrograms.includes(x.id)).map(x => this.renderTypesOfProgram(x))}
                </Dropdown>

              </FormItem>
            </Col>

            <Col {...ColumnWidths.ONE_QUARTER}>
              <label
                title={'Special Emphasis Award ' + this.state.award.order}
                htmlFor={'Special Emphasis Award ' + this.state.award.order} />
              <FormItem
                {...this._formItems.get(NewProgramProgramAwardDTO.specialEmphasisId)}
                {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.specialEmphasisId + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >

                <Dropdown id={'Special Emphasis Award ' + this.state.award.order} showArrow={!this.props.readonly} disabled={this.props.readonly}>
                  {this.state.specialEmphasis.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.specialEmphases.includes(x.id)).map(x => this.renderSpecialEmphasis(x))}
                </Dropdown>

              </FormItem>
            </Col>

            <Col {...ColumnWidths.ONE_QUARTER}>
              <label
                title={'Object Purpose Award ' + this.state.award.order}
                htmlFor={'Object Purpose Award ' + this.state.award.order} />
              <FormItem
                {...this._formItems.get(NewProgramProgramAwardDTO.objectPurposeId)}
                {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.objectPurposeId + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >

                <Dropdown id={'Object Purpose Award ' + this.state.award.order} showArrow={!this.props.readonly} disabled={this.props.readonly}>
                  {this.state.objectPurposes.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.objectPurposes.includes(x.id)).map(x => this.renderObjectPurpose(x))}
                </Dropdown>

              </FormItem>
            </Col>
          </Row>
          <label
            title={'Description Award ' + this.state.award.order}
            htmlFor={'Description Award ' + this.state.award.order} />
          <FormItem
            {...this._formItems.get(NewProgramProgramAwardDTO.description)}
            {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.description + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >

            <Input.TextArea id={'Description Award ' + this.state.award.order} disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />

          </FormItem>

          {this.renderWeeks()}
          {this.renderCredits()}
          <label
            title={'Mode of Delivery Award ' + this.state.award.order}
            htmlFor={'Mode of Delivery Award ' + this.state.award.order} />
          <FormItem
            {...this._formItems.get(NewProgramProgramAwardDTO.programAwardModesOfDeliveryIds)}
            {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.programAwardModesOfDeliveryIds + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >

            <Dropdown id={'Mode of Delivery Award ' + this.state.award.order} mode="multiple" disabled={this.props.readonly}>
              {this.state.modeOfDelivery.map(x => this.renderModeOfDelivery(x))}
            </Dropdown>

          </FormItem>

          {this.renderLocation()}

          <ThirdPartyCredentialDisplay
            thirdPartyCredentials={this.state.award.thirdPartyCredentials ?? []}
            awardIndex={this.state.award.order}
            readonly={this.props.readonly || this.state.loading || this.state.submitting}
            submitted={this.state.submitted}
            fieldErrors={this.state.fieldErrors} />

          {this.renderActions()}
        </Form>
      </Space >
    );
  }

  private renderWeeks() {
    const maxWeeks = this.getSelectedAward()?.maxWeeks ?? Number.MAX_SAFE_INTEGER;

    return (
      <>
        <label
          title={'Total Weeks Award ' + this.state.award.order}
          htmlFor={'Total Weeks Award ' + this.state.award.order} />
        <FormItem
          {...this._formItems.get(NewProgramProgramAwardDTO.totalWeeks)}
          {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.totalWeeks + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >

          <InputNumber id={'Total Weeks Award ' + this.state.award.order} min={1} max={maxWeeks} disabled={this.props.readonly} />

        </FormItem>
      </>
    );
  }

  private renderCredits() {
    const minCredits = this.getSelectedAward()?.minCredits ?? 0;
    const maxCredits = this.getSelectedAward()?.maxCredits ?? Number.MAX_SAFE_INTEGER;

    return (
      <>
        <label
          title={'Total Credits Award ' + this.state.award.order}
          htmlFor={'Total Credits Award ' + this.state.award.order} />
        <FormItem
          {...this._formItems.get(NewProgramProgramAwardDTO.totalCredits)}
          {...ValidationUtil.getValidation('awardCredits' + NewProgramProgramAwardDTO.className + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
          <InputNumber id={'Total Credits Award ' + this.state.award.order} min={minCredits} max={maxCredits} disabled={this.props.readonly} />
        </FormItem>
      </>
    );
  }

  private renderAwardTypes() {
    let validAwardTypes = this.state.awardTypes;
    if (this.state.changeRequest.newProgramChangeRequest?.isTransferMajorProgram) {
      validAwardTypes = validAwardTypes.filter(x => x.transferMajor === true);
    }

    return validAwardTypes.map(x => this.renderAwardType(x));
  }

  private renderActions() {
    if (!this.props.readonly) {
      return <Space direction='horizontal' wrap={true}>
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved} />
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space>
    }
  }

  renderLocation() {
    if (this.state.institutionLocations.length > 1) {
      return <>
        <label
          title={'Institution Location Award ' + this.state.award.order}
          htmlFor={'Institution Location Award ' + this.state.award.order} />
        <FormItem
          {...this._formItems.get(NewProgramProgramAwardDTO.newProgramProgramAwardInsitutionLocationIds)}
          {...ValidationUtil.getValidation(NewProgramProgramAwardDTO.newProgramProgramAwardInsitutionLocationIds, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
          <Dropdown id={'Institution Location Award ' + this.state.award.order} mode="multiple" disabled={this.props.readonly}>
            {this.state.institutionLocations.map(x => this.renderInstitutionLocations(x))}
          </Dropdown>
        </FormItem>
      </>
    }
  }

  renderAwardType(awardType: AwardTypeDTO) {
    if (awardType.id) {
      return <Select.Option key={awardType.id ?? Guid.Empty()} value={awardType.id ?? Guid.Empty()}>{awardType.code} - {awardType.name}</Select.Option>
    }
  }

  renderTypesOfProgram(typesOfProgram: TypeOfProgramDTO) {
    if (typesOfProgram.id) {
      return <Select.Option key={typesOfProgram.id ?? Guid.Empty()} value={typesOfProgram.id ?? Guid.Empty()}>{typesOfProgram.code} - {typesOfProgram.name}</Select.Option>
    }
  }

  renderObjectPurpose(objectPurpose: ObjectPurposeDTO) {
    if (objectPurpose.id) {
      return <Select.Option key={objectPurpose.id ?? Guid.Empty()} value={objectPurpose.id ?? Guid.Empty()}>{objectPurpose.code} - {objectPurpose.name}</Select.Option>
    }
  }

  renderSpecialEmphasis(specialEmphasis: SpecialEmphasisDTO) {
    if (specialEmphasis.id) {
      return <Select.Option key={specialEmphasis.id ?? Guid.Empty()} value={specialEmphasis.id ?? Guid.Empty()}>{specialEmphasis.code} - {specialEmphasis.name}</Select.Option>
    }
  }

  renderInsturctionalLevel(instructionalLevel: InstructionalLevelDTO) {
    if (instructionalLevel.id) {
      return <Select.Option key={instructionalLevel.id ?? Guid.Empty()} value={instructionalLevel.id ?? Guid.Empty()}>{instructionalLevel.code} - {instructionalLevel.name}</Select.Option>
    }
  }

  renderIowaVariation(iowaVariation: IowaVariationDTO) {
    if (iowaVariation.id) {
      return <Select.Option key={iowaVariation.id ?? Guid.Empty()} value={iowaVariation.id ?? Guid.Empty()}>{iowaVariation.code} - {iowaVariation.name}</Select.Option>
    }
  }

  renderInstitutionLocations(institutionLocation: InstitutionLocationDTO) {
    if (institutionLocation.id) {
      return <Select.Option key={institutionLocation.id ?? Guid.Empty()} value={institutionLocation.id ?? Guid.Empty()}>{institutionLocation.name} - {institutionLocation.street1} </Select.Option>
    }
  }

  renderModeOfDelivery(modeOfDelivery: ModeOfDeliveryDTO) {
    if (modeOfDelivery.id) {
      return <Select.Option key={modeOfDelivery.id ?? Guid.Empty()} value={modeOfDelivery.id ?? Guid.Empty()}> {modeOfDelivery.name}</Select.Option>
    }
  }

  private renderErrors = () => {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NewProgramChangeRequestStep10Form