import { Checkbox, Form, FormInstance, notification, Skeleton, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { NamePath } from 'antd/lib/form/interface';
import * as React from 'react';
import InstitutionUsersApiService from '../../../api/InstitutionUsersApiService';
import RolesApiService from '../../../api/RolesApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetRoleStatusesForInstitutionUserHandler from '../../../handlerModels/GetRoleStatusesForInstitutionUserHandler';
import * as SaveRoleStatusesForInstituionUserHandler from '../../../handlerModels/SaveRoleStatusesForInstitutionUserHandler';
import RoleStatusDTO from '../../../models/RoleStatusDTO';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface InstitutionUserRolesFormState extends BaseFormState {
  institutionRoles: RoleStatusDTO[];
}

interface InstitutionUserRolesFormProps {
  userId: string;
  institutionId: string;
  isEditing: boolean | undefined;
  onSave?: () => void;
}

class InstitutionUserRolesForm extends React.Component<InstitutionUserRolesFormProps, InstitutionUserRolesFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: InstitutionUserRolesFormProps) {
    super(props);

    this.state = {
      institutionRoles: [],
      loading: true
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentDidUpdate() {
    if (this.state.institutionRoles.length == 0) {
      this.loadInstitutionUserRoles();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false })
  }

  private loadInstitutionUserRoles = () => {
    InstitutionUsersApiService.getInstitutionRolesForInsitutionUser(this.props.userId, this.props.institutionId)
      .then((results: GetRoleStatusesForInstitutionUserHandler.Result) => {
        if (results && results.institutionUserRoles) {
          this.setState({ institutionRoles: results.institutionUserRoles }, () => this.resetForm());
        }
      })
      .catch((error: any) => {
        this.setState({ error: true, message: error });
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = (this._formRef ? (this._formRef.current as any).getFieldsValue() : null) as [];
    const roleStatuses = this.state.institutionRoles;

    for (let i = 0; i < roleStatuses.length; i++) {
      roleStatuses[i].isEnabled = model[roleStatuses[i].role?.id as string];
    }

    const dto = SaveRoleStatusesForInstituionUserHandler.Request.create({
      roleStatuses: roleStatuses,
      insttutionId: this.props.institutionId,
      userId: this.props.userId,
    });

    RolesApiService.saveInstitutionUserRoles(dto)
      .then((result: SaveRoleStatusesForInstituionUserHandler.Result | null) => {
        if (result) {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors,
            altered: !result?.succeeded
          });

          if (result.succeeded) {
            this.setState({ institutionRoles: dto.roleStatuses });
            notification.success({ message: 'Success!', description: 'User roles have been saved.', });
            if (this.props.onSave) {
              this.props.onSave();
            }
          }
          else {
            notification.error({ message: 'Uh Oh!', description: result?.errors.join('\n'), });
          }
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        notification.error({ message: 'Uh oh!', description: 'Roles could not be saved.', });
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  handleChange = () => {
    this.setState({ altered: true })
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Form ref={this._formRef} layout='vertical' onChange={this.handleChange} onFinish={this.handleSubmit} requiredMark={true}>
        <Space direction="vertical" size="small">
          {this.renderInstitutionRoles()}
          {this.renderActions()}
        </Space>
      </Form>
    );
  }

  renderActions() {
    if (this.props.isEditing && AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_INSTITUTION_USER])) {
      return (<Space direction='horizontal' wrap={true}>
        <SaveButton disabled={this.state.loading} saving={this.state.submitting} saved={this.state.saved} />
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm.bind(this)} />
      </ Space>);
    }
  }

  renderInstitutionRoles() {
    if (this.state.institutionRoles.length) {
      const roles = this.state.institutionRoles.map(ir => this.renderRole(ir));

      return (
        <Space direction='horizontal' wrap={true} size='small'>
          {roles}
        </Space>
      );
    }
  }

  renderRole(roleStatus: RoleStatusDTO) {
    if (roleStatus != null && roleStatus.role != null) {
      return (
        <FormItem key={roleStatus.role.id} name={roleStatus.role.id as NamePath} initialValue={roleStatus.isEnabled} valuePropName="checked">
          <Checkbox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} >{roleStatus.role.name}</Checkbox>
        </FormItem>
      );
    }
  }
}

export default InstitutionUserRolesForm;