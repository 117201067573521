import { Alert, Form, FormInstance, FormItemProps, message, Space } from 'antd';
import * as React from 'react';
import DistrictApiService from '../../../api/DistrictApiService';
import SecondaryCourseApiService from '../../../api/SecondaryCourseApiService';
import SecondaryProgramApiService from '../../../api/SecondaryProgramApiService';
import SecondaryProgramCourseApiService from '../../../api/SecondaryProgramCourseApiService';
import * as GetSecondaryProgramCourseDetailsHandler from '../../../handlerModels/GetSecondaryProgramCourseDetailsHandler';
import * as SaveSecondaryProgramCourseHandler from '../../../handlerModels/SaveSecondaryProgramCourseHandler';
import * as SearchForDistrictsHandler from '../../../handlerModels/SearchForDistrictsHandler';
import * as SearchForSecondaryCoursesHandler from '../../../handlerModels/SearchForSecondaryCoursesHandler';
import * as SearchForSecondaryProgramsHandler from '../../../handlerModels/SearchForSecondaryProgramsHandler';
import DistrictDTO from '../../../models/DistrictDTO';
import SecondaryCourseDTO from '../../../models/SecondaryCourseDTO';
import SecondaryProgramCourseDTO from '../../../models/SecondaryProgramCourseDTO';
import SecondaryProgramDTO from '../../../models/SecondaryProgramDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AutoCompleteInput, { AutoCompleteInputOption } from '../../general/AutoCompleteInput';
import YesNoInput from '../../inputs/YesNoInput';

interface SecondaryProgramCourseDetailsFormProps extends BaseFormProps {
  academicYear?: number;
  districtId?: string;
  secondaryProgramId?: string;
  secondaryProgramCourseId: string;
  onSave?: (districtId: string) => void;
}

interface SecondaryProgramCourseDetailsFormState extends BaseFormState {
  secondaryProgramCourse: SecondaryProgramCourseDTO;
  searching: boolean;
  secondaryCourses: SecondaryCourseDTO[];
  programs: SecondaryProgramDTO[];
  districts: DistrictDTO[];
  districtSearch: number;
  programSearch: number;
  courseSearch: number;
}

class SecondaryProgramCourseDetailsForm extends React.Component<SecondaryProgramCourseDetailsFormProps, SecondaryProgramCourseDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(SecondaryProgramCourseDTO.districtId, {
        required: true,
        name: SecondaryProgramCourseDTO.districtId,
        label: 'District',
        rules: [
          ValidationRuleUtil.required()
        ]
      })
      .set(SecondaryProgramCourseDTO.programId, {
        required: true,
        name: SecondaryProgramCourseDTO.programId,
        label: 'Program',
        rules: [
          ValidationRuleUtil.required()
        ]
      })
      .set(SecondaryProgramCourseDTO.courseId, {
        required: true,
        name: SecondaryProgramCourseDTO.courseId,
        label: 'Course',
        rules: [
          ValidationRuleUtil.required()
        ]
      })
      .set(SecondaryProgramCourseDTO.isCoreCourse, {
        required: true,
        name: SecondaryProgramCourseDTO.isCoreCourse,
        label: 'Is this a core course?',
        rules: [
          ValidationRuleUtil.required()
        ]
      })
      .set(SecondaryProgramCourseDTO.isOfferTeach, {
        required: true,
        name: SecondaryProgramCourseDTO.isOfferTeach,
        label: 'Is Offer Teach',
        rules: [
          ValidationRuleUtil.required()
        ]
      });
  }

  constructor(props: SecondaryProgramCourseDetailsFormProps) {
    super(props);

    this.state = {
      secondaryCourses: [],
      programs: [],
      districts: [],
      searching: false,
      secondaryProgramCourse: SecondaryProgramCourseDTO.create(),
      districtSearch: 0,
      programSearch: 0,
      courseSearch: 0
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: SecondaryProgramCourseDetailsFormProps) {
    if (this.props != prevProps) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];

    loaders.push(this.loadSecondaryProgramCourse());
    loaders.push(this.handleDistrictSearch(''));
    // if (this.props.secondaryProgramId && this.props.secondaryProgramId != '' && this.props.secondaryProgramId != Guid.Empty()) {
    //   loaders.push(this.handleProgramSearch(''));
    // }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadSecondaryProgramCourse = () => {
    if (!this.state.loading) {
      if (this.props.secondaryProgramCourseId == Guid.Empty() || this.props.isNew) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  private loadExisting = () => {
    if (this.props.secondaryProgramCourseId && this.props.academicYear) {
      const request = GetSecondaryProgramCourseDetailsHandler.Request.create({
        id: this.props.secondaryProgramCourseId
      });

      SecondaryProgramCourseApiService.getSecondaryProgramCourseDetails(request)
        .then((results: GetSecondaryProgramCourseDetailsHandler.Result) => {
          if (results.secondaryProgramCourse && results.secondaryProgramCourse.course && results.secondaryProgramCourse.program && results.secondaryProgramCourse.program.district) {

            this.setState({
              secondaryProgramCourse: results.secondaryProgramCourse ?? SecondaryProgramCourseDTO.create(),
              secondaryCourses: [results.secondaryProgramCourse.course],
              programs: [results.secondaryProgramCourse.program],
              districts: [results.secondaryProgramCourse.program.district]
            });

            this.resetForm();
          }
          else {
            this.setState({
              isEditing: true
            })
          }
        }).catch(() => {
          this.setState({ error: true });
        });
    }
  }

  private loadNew = () => {
    const secondaryProgramCourse = SecondaryProgramCourseDTO.create({
      id: this.props.secondaryProgramCourseId == Guid.Empty() ? undefined : this.props.secondaryProgramCourseId,
      programId: '',
      courseId: '',
      districtId: '',
    });

    if (this.props.districtId && this.props.districtId != '' && this.props.districtId != Guid.Empty()) {
      secondaryProgramCourse.districtId = this.props.districtId;
    }

    if (this.props.secondaryProgramId && this.props.secondaryProgramId != '' && this.props.secondaryProgramId != Guid.Empty()) {
      secondaryProgramCourse.programId = this.props.secondaryProgramId;
    }

    this.setState({
      loading: false,
      secondaryProgramCourse: secondaryProgramCourse
    });
  }

  private resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private handleDistrictSearch = (value: string) => {
    const search = this.state.districtSearch + 1;
    this.setState({ searching: true, districtSearch: search });

    const request = SearchForDistrictsHandler.Request.create(
      {
        academicYear: this.props.academicYear,
        searchValue: value,
        districtSearch: search
      });

    DistrictApiService.searchForDistricts(request)
      .then((x: SearchForDistrictsHandler.Result) => {
        this.setState({ districts: x.districts ?? [] });
      })
      .finally(() => {
        this.setState({ searching: false });
      });
  }

  private handleProgramSearch = (value: string) => {
    let district = this._formRef.current?.getFieldValue(SecondaryProgramCourseDTO.districtId)

    if (district == Guid.Empty() || district == undefined) {
      district = this.props.districtId ?? Guid.Empty();
    }

    const search = this.state.programSearch + 1;
    this.setState({ searching: true, programSearch: search });

    const request = SearchForSecondaryProgramsHandler.Request.create(
      {
        academicYear: this.props.academicYear,
        searchValue: value,
        districtId: district,
        programSearch: search
      });

    SecondaryProgramApiService.searchForSecondaryPrograms(request)
      .then((x: SearchForSecondaryProgramsHandler.Result) => {
        this.setState({ programs: x.secondaryPrograms ?? [] });
      })
      .finally(() => {
        this.setState({ searching: false });
      });
  }

  private handleCourseSearch = (value: string) => {
    const district = this._formRef.current?.getFieldValue(SecondaryProgramCourseDTO.districtId)
    const program = this._formRef.current?.getFieldValue(SecondaryProgramCourseDTO.programId)

    const search = this.state.courseSearch + 1;
    this.setState({ searching: true, courseSearch: search });

    const request = SearchForSecondaryCoursesHandler.Request.create(
      {
        academicYear: this.props.academicYear,
        searchValue: value,
        districtId: district,
        programId: program,
        courseSearch: search
      });

    SecondaryCourseApiService.searchForSecondaryCourses(request)
      .then((x: SearchForSecondaryCoursesHandler.Result) => {
        console.log('s:' + this.state.courseSearch);
        console.log('r:' + x.courseSearch);
        if (this.state.courseSearch == x.courseSearch) {
          this.setState({ searching: false, secondaryCourses: x.secondaryCourses ?? [] });
        }
      })
      .finally(() => {
        this.setState({ searching: false });
      });
  }

  private handleDistrictSelect = () => {
    this.handleProgramSearch('');
    this.handleCourseSearch('');
  }

  private handleProgramSelect = () => {
    const district = this._formRef.current?.getFieldValue(SecondaryProgramCourseDTO.districtId)
    if (district?.localeCompare(Guid.Empty())) {
      this.handleCourseSearch('');
    }
  }

  private handleCourseSelect = () => {
    // no-op
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      () => this.handleSubmit(),
      () => this.setState({ error: true })
    );
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const loaders = [];
    loaders.push(this._formRef.current?.validateFields());
    const promise = Promise.all(loaders).then(() => {
      const model = (this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
      const request = SaveSecondaryProgramCourseHandler.Request.create({
        secondaryProgramCourseId: this.state.secondaryProgramCourse.id,
        secondaryProgramCourse: SecondaryProgramCourseDTO.create({
          ...model,
          academicYear: this.props.academicYear,
        })
      });

      SecondaryProgramCourseApiService.addSecondaryProgramCourseDetails(request)
        .then((result: SaveSecondaryProgramCourseHandler.Result) => {
          if (result.succeeded) {
            this.resetForm();
            this.setState({ submitting: false });
            message.success('ProgramCourse was added.');
            if (this.props.onSave && result.secondaryProgramCourse?.id) {
              this.props.onSave(result.secondaryProgramCourse.id);
              this.resetForm();
            }
          }
          else {
            message.error('ProgramCourse could not be added.');
            this.setState({ fieldErrors: result.fieldErrors, submitted: true, submitting: false });
          }
        }).catch(() => {
          this.setState({ error: true, submitting: false });
          message.error('ProgramCourse could not be added.');
        });
    });

    return promise;
  }

  render() {
    const districts = this.state.districts.sort((a, b) => a.districtId - b.districtId).map(x => {
      return {
        value: x.id,
        display: x.display
      } as AutoCompleteInputOption;
    });

    const programs = this.state.programs.sort((a, b) => a.secondaryProgramId - b.secondaryProgramId).map(x => {
      return {
        value: x.id,
        display: x.secondaryCipNumber?.display
      } as AutoCompleteInputOption;
    });

    const courses = this.state.secondaryCourses.sort((a, b) => a.localCourseNumber?.localeCompare(b.localCourseNumber ?? '') ?? 0).map(x => {
      return {
        value: x.id,
        display: x.display
      } as AutoCompleteInputOption;
    });

    return (
      <Space direction="vertical">
        <Form
          initialValues={this.state.secondaryProgramCourse}
          layout='vertical'
          onFinish={this.handleSubmit}
          ref={this._formRef}>
          <Space direction="vertical">

            <Form.Item
              {...this.getFormItems().get(SecondaryProgramCourseDTO.districtId)}
              {...ValidationUtil.getValidation(SecondaryProgramCourseDTO.districtId, this.state.fieldErrors, this.state.submitted)}>
              <AutoCompleteInput onSelect={this.handleDistrictSelect} options={districts ?? []} searching={this.state.searching} onSearch={this.handleDistrictSearch} disabled={!this.props.isEditing || this.state.loading || this.state.submitting || this.props.districtId != undefined} />
            </Form.Item>

            <Form.Item
              {...this.getFormItems().get(SecondaryProgramCourseDTO.programId)}
              {...ValidationUtil.getValidation(SecondaryProgramCourseDTO.programId, this.state.fieldErrors, this.state.submitted)}>
              <AutoCompleteInput onSelect={this.handleProgramSelect} options={programs ?? []} searching={this.state.searching} onSearch={this.handleProgramSearch} disabled={!this.props.isEditing || this.state.loading || this.state.submitting || this.props.secondaryProgramId != undefined} />
            </Form.Item>

            <Form.Item
              {...this.getFormItems().get(SecondaryProgramCourseDTO.courseId)}
              {...ValidationUtil.getValidation(SecondaryProgramCourseDTO.courseId, this.state.fieldErrors, this.state.submitted)}>
              <AutoCompleteInput onSelect={this.handleCourseSelect} options={courses ?? []} searching={this.state.searching} onSearch={this.handleCourseSearch} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </Form.Item>

            <Form.Item
              {...this.getFormItems().get(SecondaryProgramCourseDTO.isOfferTeach)}
              {...ValidationUtil.getValidation(SecondaryProgramCourseDTO.isOfferTeach, this.state.fieldErrors, this.state.submitted)}>
              <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </Form.Item>

            <Form.Item
              {...this.getFormItems().get(SecondaryProgramCourseDTO.isCoreCourse)}
              {...ValidationUtil.getValidation(SecondaryProgramCourseDTO.isCoreCourse, this.state.fieldErrors, this.state.submitted)}>
              <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </Form.Item>
            {this.renderErrors()}
          </Space>
        </Form>
      </Space>
    );
  }

  renderErrors() {
    if (this.state.error && this.state.message) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default SecondaryProgramCourseDetailsForm;
