// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CourseCourseBlocksDataTableDTO extends EntitySelectorDTO { 
	id: string | null;
	courseBlockId: string | null;
	code: string | null;
	title: string | null;
	courseCount: number;
}

const CourseCourseBlocksDataTableDTO = {
    className: 'CourseCourseBlocksDataTableDTO',
    create: (initValues?: Partial<CourseCourseBlocksDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			courseBlockId: '00000000-0000-0000-0000-000000000000',
			code: "",
			title: "",
			courseCount: 0,
        },
        initValues);
    },
	id: 'id',
	courseBlockId: 'courseBlockId',
	code: 'code',
	title: 'title',
	courseCount: 'courseCount',
};

export default CourseCourseBlocksDataTableDTO;