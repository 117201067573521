import * as antd from 'antd';
import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import CompleteSelfStudyForm from '../../../components/forms/secondary/CompleteSelfStudyForm';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as GetSelfStudyDetailsHandler from '../../../handlerModels/GetSelfStudyDetailsHandler';
import { default as SelfStudyDTO } from '../../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface SelfStudyDetailDisplayPageState extends BaseFormState {
  id: string;
  selfStudy: SelfStudyDTO;
  selfStudyResponse: SelfStudyResponseDTO;
  academicYear: number;
  programsForReview: boolean;
  activeTab: string;
}

interface SelfStudyDetailDisplayPageProps {
}

class SelfStudyDetailDisplayPage extends React.Component<SelfStudyDetailDisplayPageProps & RouteComponentProps<RouteObject>, SelfStudyDetailDisplayPageState> {

  constructor(props: SelfStudyDetailDisplayPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      id: Guid.Empty(),
      academicYear: 0,
      programsForReview: false,
      selfStudy: SelfStudyDTO.create(),
      selfStudyResponse: SelfStudyResponseDTO.create(),
      activeTab: 'details'
    };
  }

  componentDidMount() {
    const selfStudyResponseId = ParameterUtil.getPathPart(this.props.match, 'id');
    const selfStudyId = ParameterUtil.getPathPart(this.props.match, 'selfStudyId');
    const academicYearId = ParameterUtil.getParameter('academicYearId');
    const programsForReview = ParameterUtil.getParameter('programsForReview');
    this.setState({
      academicYear: Number.parseInt(academicYearId),
      id: selfStudyResponseId,
      loading: true,
      programsForReview: programsForReview == 'true' ? true : false
    });

    const loaders = [];
    loaders.push(this.loadSelfStudy(selfStudyResponseId, selfStudyId));

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadSelfStudy = (selfStudyResponseId: string, selfStudyId: string) => {
    this.setState({ loading: true });

    const request = GetSelfStudyDetailsHandler.Request.create({
      id: selfStudyId
    });

    SelfStudyApiService.getSelfStudyDetails(request)
      .then((results: GetSelfStudyDetailsHandler.Result) => {
        if (results.selfStudy) {
          this.setState({
            selfStudy: results.selfStudy,
            selfStudyResponse: results.selfStudy.selfStudyResponses?.find(x => x.id == selfStudyResponseId) ?? SelfStudyResponseDTO.create(),
            academicYear: results.selfStudy.academicYearId
          });
        }
      }).catch((error: any) => {
        antd.message.success(error.toString());
        this.setState({ error: true });
      });
  }

  goToListing = () => {
    if (this.state.programsForReview) {
      HistoryUtil.goBack();
    }
    else {
      HistoryUtil.goBack();
    }
  }

  onFillOutSelfStudy = () => {
    if ((this.state.selfStudyResponse?.id ?? Guid.Empty()) != Guid.Empty()) {
      HistoryUtil.push(Routes.generate(Routes.COMPLETE_SELF_STUDIES, { academicYearId: this.state.academicYear ?? 0, selfStudyResponseId: this.state.selfStudyResponse?.id ?? Guid.Empty(), sectionIndex: 0 }, { selfStudyResponseType: this.state.selfStudyResponse.selfStudyReponseTypeId, step: 0 }));
    }
  }

  render() {
    if (this.state.loading || this.state.loading == undefined || this.state.selfStudy == undefined) {
      return <antd.Skeleton active />
    }

    const title = this.state.selfStudy?.secondaryProgram?.secondaryProgramId ?? '...';
    return (
      <Content >
        <HeaderPortal
          title={title}
          subTitle={this.state.selfStudy?.secondaryProgram?.district?.name}
          onBack={this.goToListing}
          breadcrumbs={Breadcrumbs.selfStudyDisplayPage(this.state.selfStudy.id ?? Guid.Empty(), this.state.academicYear, this.state.selfStudy.secondaryProgram?.secondaryCipNumber?.description ?? '...')}
        />
        {this.renderProgramReview(this.state.selfStudyResponse)}
      </Content >
    );
  }


  private renderProgramReview = (response: SelfStudyResponseDTO) => {
    return <Card title={response.selfStudyResponseType?.name + ' Response'}>
      {response.selfStudySectionResponses?.map(x =>
        <>
          <CompleteSelfStudyForm
            key={x.selfStudySectionTemplate?.order}
            academicYear={this.state.selfStudy.academicYearId}
            selfStudyResponseId={response.id ?? Guid.Empty()}
            sectionIndex={x.selfStudySectionTemplate?.order ?? 0}
            isEditing={true}
            readonly={true}
            detailsPage={true}
            selfStudyResponseType={response.selfStudyReponseTypeId}></CompleteSelfStudyForm>
        </>
      )}
    </Card>
  }
}

export default withRouter(SelfStudyDetailDisplayPage);
