import { Card, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChangeRequestApiService from '../../../../api/ChangeRequestApiService';
import ChangeRequestLayout from '../../../../components/postSecondary/ChangeRequestLayout';
import AdministrativeChangeRequestDetails from '../../../../components/displays/postSecondary/AdministrativeChangeRequestDetails';
import ChangeRequestReviewForm from '../../../../components/forms/postSecondary/ChangeRequestReviewForm';
import Routes from '../../../../config/Routes';
import * as GetBaseChangeRequestDetailsHandler from '../../../../handlerModels/GetBaseChangeRequestDetailsHandler';
import ChangeRequestDTO from '../../../../models/ChangeRequestDTO';
import InstitutionDTO from '../../../../models/InstitutionDTO';
import UserSecurity from '../../../../models/UserSecurity';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface AdministrativeChangeRequestReviewPageState extends BaseFormState {
  changeRequestId: string;
  changeRequest: ChangeRequestDTO;
}

interface AdministrativeChangeRequestReviewPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class AdministrativeChangeRequestReviewPage extends React.Component<AdministrativeChangeRequestReviewPageProps & RouteComponentProps<RouteObject>, AdministrativeChangeRequestReviewPageState> {
  constructor(props: AdministrativeChangeRequestReviewPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequestId: Guid.Empty(),
      changeRequest: ChangeRequestDTO.create(),
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id !== Guid.Empty() && this.state.changeRequestId != id) {
      this.setState({ changeRequestId: id });
      this.fetchData(id);
    }
  }

  private fetchData = (id: string) => {
    this.setState({ loading: true });

    const loaders = [];

    if (id !== Guid.Empty()) {
      loaders.push(this.loadChangeRequest(id));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadChangeRequest = (id: string) => {
    return ChangeRequestApiService.getBaseChangeRequestDetails(id)
      .then((results: GetBaseChangeRequestDetailsHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  goToPreviousStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_2, { id: this.state.changeRequestId }, {}))
  }

  goToNextStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_SUBMITTED, { id: this.state.changeRequestId }, {}))
  }

  render() {
    return (
      <Content >
        <ChangeRequestLayout
          changeRequest={this.state.changeRequest ?? ChangeRequestDTO.create()}
          stepNumber={2}
          currentUser={this.props.currentUser ?? {} as UserSecurity}>
          <Card title="Review" >
            <Space direction="vertical">
              <AdministrativeChangeRequestDetails loading={this.state.loading} changeRequest={this.state.changeRequest} fieldErrors={this.state.fieldErrors} />
              <ChangeRequestReviewForm changeRequest={this.state.changeRequest} onSubmit={this.goToNextStep} onPrevious={this.goToPreviousStep} />
            </Space>
          </Card>
        </ChangeRequestLayout>
      </Content >
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(AdministrativeChangeRequestReviewPage));