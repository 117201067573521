import { Col, Input, message, Row, Select, Skeleton, Space, Table, Tooltip, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import CoursesApiService from '../../api/CoursesApiService';
import { ColumnWidths } from '../../config/ColumnWidths';
import * as CourseSearchHandler from '../../handlerModels/CourseSearchHandler';
import * as CourseSearchWithRetiredHandler from '../../handlerModels/CourseSearchWithRetiredHandler';
import * as GetCourseDetailsHandler from '../../handlerModels/GetCourseDetailsHandler';
import CourseDetailDTO from '../../models/CourseDetailDTO';
import CourseRequisiteDTO from '../../models/CourseRequisiteDTO';
import CourseRequisiteTypeDTO from '../../models/CourseRequisiteTypeDTO';
import DisciplineDTO from '../../models/DisciplineDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import Guid from '../../utils/Guid';
import LookupsUtil from '../../utils/LookupsUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ClearButton from '../buttons/ClearButton';
import SearchButton from '../buttons/SearchButton';
import Dropdown from '../inputs/Dropdown';

interface CourseSearchValue {
  courseId: string | null;
  course: CourseDetailDTO | null;
}

interface CourseSearchState extends BaseFormState {
  searching?: boolean;
  selectedCourseId?: string;
  value?: CourseSearchValue;
  courses: CourseDetailDTO[]
  disciplines: DisciplineDTO[];
  courseRequisiteTypes: CourseRequisiteTypeDTO[];
  limited: boolean;
}

interface CourseSearchProps extends BaseFormProps {
  institutionId?: string;
  value?: CourseSearchValue;
  existingCourseId?: string;
  includeRetired?: boolean
  onChange?: (value: CourseSearchValue) => void;
  disciplineId?: string;
  catalogNumber?: string;
  isCourseRequisite?: boolean
}

class CourseSearch extends React.Component<CourseSearchProps, CourseSearchState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _tableRef = React.createRef<any>();
  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(CourseSearchHandler.Request.title, {
        name: CourseSearchHandler.Request.title,
        label: 'Title',
      })
      .set(CourseSearchHandler.Request.disciplineId, {
        name: CourseSearchHandler.Request.disciplineId,
        label: 'Discipline',
      })
      .set(CourseSearchHandler.Request.catalogNumber, {
        name: CourseSearchHandler.Request.catalogNumber,
        label: 'Catalog Number',
      })
      .set(CourseRequisiteDTO.courseRequisiteTypeId, {
        required: true,
        name: CourseRequisiteDTO.courseRequisiteTypeId,
        label: 'Course Requisite Type',
        requiredMark: true,
        rules: [ValidationRuleUtil.required()]
      });
  }

  constructor(props: CourseSearchProps) {
    super(props);

    this.state = {
      courses: [],
      disciplines: [],
      limited: false,
      courseRequisiteTypes: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.disciplines || this.state.disciplines.length == 0) {
      loaders.push(this.loadDisciplines());
    }

    if (!this.state.courseRequisiteTypes || this.state.courseRequisiteTypes.length == 0) {
      loaders.push(this.loadCourseRequisiteType());
    }

    if (this.props.value != undefined) {
      this.setState({ value: this.props.value });
    }

    if (this.props.value?.course) {
      this.existingCourse(this.props.value.course);
    }

    if (this.props.existingCourseId && this.props.existingCourseId != Guid.Empty()) {
      this.loadExistingCourse(this.props.existingCourseId);
    }

    if (this.props.catalogNumber || this.props.disciplineId) {
      this.handleChange();
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadDisciplines = () => {
    return LookupsUtil.getAll<DisciplineDTO>(DisciplineDTO.className)
      .then((results: DisciplineDTO[]) => {
        if (results) {
          results = results.sort((a, b) => (a?.code ?? '') > (b?.code ?? '') ? 1 : -1)
          this.setState({ disciplines: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCourseRequisiteType = () => {
    return LookupsUtil.getAll<CourseRequisiteTypeDTO>(CourseRequisiteTypeDTO.className)
      .then((results: CourseRequisiteTypeDTO[]) => {
        if (results) {
          results = results.sort((a, b) => (a.displayOrder ?? '') > (b?.displayOrder ?? '') ? 1 : -1)
          this.setState({ courseRequisiteTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  componentDidUpdate(prevProps: CourseSearchProps) {
    if (this.props.value != undefined && this.props.value != prevProps.value) {
      this.setState({ value: this.props.value });
    }

    if (this._tableRef.current) {
      const radioButton = this._tableRef.current.querySelector('.ant-table-tbody').querySelectorAll('.ant-radio-input');
      if (radioButton && radioButton.length > 0) {
        radioButton.forEach((element: any) => {
          element.setAttribute('aria-label', 'radio button')
        });
      }

    }
  }

  public reset = () => {
    this._formRef.current?.resetFields();
    this.setState({
      courses: []
    });
  }

  public getValue = () => {
    const form = this._formRef.current?.getFieldsValue();

    return { ...this.state.value, ...{ courseRequisiteTypeId: form.courseRequisiteTypeId } }
  };

  public getForm = () => {
    return this._formRef;
  };

  handleSelected = (selectedRows: CourseDetailDTO[]) => {
    const course = selectedRows[0];

    this.setState({
      selectedCourseId: course.courseId ?? Guid.Empty(),
      value: {
        courseId: course.courseId ?? Guid.Empty(),
        course: course ?? null
      }
    })

    if (selectedRows && selectedRows[0] && this.props.onChange)
      this.props.onChange(
        {
          courseId: course.courseId ?? Guid.Empty(),
          course: course ?? null
        } as CourseSearchValue);
  }

  clearSearch = () => {
    this._formRef.current?.resetFields();
  }

  handleChange = () => {
    this.setState({ searching: true });
    const formValues = this._formRef.current?.getFieldsValue()
    const request = CourseSearchHandler.Request.create({
      disciplineId: formValues.disciplineId == '' ? null : formValues.disciplineId,
      catalogNumber: formValues.catalogNumber,
      title: formValues.title,
      institutionId: this.props.institutionId
    });

    if (this.props.includeRetired) {
      CoursesApiService.searchForCourseWithRetired(request)
        .then((result: CourseSearchWithRetiredHandler.Result) => {
          if (result?.succeeded) {
            this.setState({
              courses: result.courses ?? [],
              limited: result.exceedsNumberOfCourses
            });
          }
        })
        .catch((results: any) => {
          this.setState({ error: results });
        })
        .finally(() => {
          this.setState({ loading: false, searching: false });
        });
    }
    else {
      CoursesApiService.searchForCourse(request)
        .then((result: CourseSearchHandler.Result) => {
          if (result?.succeeded) {
            this.setState({
              courses: result.courses ?? [],
              limited: result.exceedsNumberOfCourses
            });
          }
        })
        .catch((results: any) => {
          this.setState({ error: results });
        })
        .finally(() => {
          this.setState({ loading: false, searching: false });
        });
    }
  }

  loadExistingCourse(existingCourseId: string) {
    CoursesApiService.getCourseDetails(existingCourseId)
      .then((results: GetCourseDetailsHandler.Result) => {
        if (results.course?.courseDetails) {
          this.setState({
            courses: [results.course.currentDetail ?? CourseDetailDTO.create()],
            selectedCourseId: results.course.id ?? Guid.Empty()
          });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  existingCourse = (existingCourse: CourseDetailDTO) => {
    const request = CourseSearchHandler.Request.create({
      ...existingCourse,
      institutionId: this.props.institutionId
    });

    if (this.props.includeRetired) {
      CoursesApiService.searchForCourseWithRetired(request)
        .then((result: CourseSearchWithRetiredHandler.Result) => {
          if (result?.succeeded) {
            this.setState({
              courses: result.courses ?? [],
              limited: result.exceedsNumberOfCourses
            });
          }
        })
        .catch((results: any) => {
          this.setState({ error: results });
        })
        .finally(() => {
          this.setState({ loading: false, submitting: false });
        });
    }
    else {
      CoursesApiService.searchForCourse(request)
        .then((result: CourseSearchHandler.Result) => {
          if (result?.succeeded) {
            this.setState({
              courses: result.courses ?? [],
            });

            if (result.exceedsNumberOfCourses) {
              message.info('All courses with current filters cannot be shown. Please use more filters');
            }
          }
        })
        .catch((results: any) => {
          this.setState({ error: results });
        })
        .finally(() => {
          this.setState({ loading: false, submitting: false });
        });
    }
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();
    const formValues = {
      ...this.state.value?.course,
      disciplineId: this.props.disciplineId ?? null,
      catalogNumber: this.props.catalogNumber ?? undefined
    }

    return (
      <Space direction='vertical'>
        <Typography.Paragraph italic={true}>
          Search for a course using the fields below to populate the table below to select a course.
        </Typography.Paragraph>

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={formValues}
          onChange={this.handleChange}
          requiredMark={true}>
          <Space direction='vertical' size='small'>
            <Row gutter={[16, 16]}>
              <Col {...ColumnWidths.HALF}>
                <Form.Item {...formItems.get(CourseSearchHandler.Request.disciplineId)}>
                  <Dropdown dropdownMatchSelectWidth={false}
                    onChange={this.handleChange}
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                  >
                    {this.renderDisciplineOptions()}
                  </Dropdown>
                </Form.Item>
              </Col>

              <Col {...ColumnWidths.HALF}>
                <Form.Item {...formItems.get(CourseSearchHandler.Request.catalogNumber)}>
                  <Input allowClear={true} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item {...formItems.get(CourseSearchHandler.Request.title)}>
              <Input allowClear={true} />
            </Form.Item>
          </Space>

          <Space direction='horizontal' >
            <SearchButton htmlType='button' onClick={this.handleChange} searching={this.state.searching} />
            <ClearButton htmlType='button' onClick={this.clearSearch} />
          </Space>


          {
            this.state.limited ?
              <Typography.Text>
                Too many results. Please narrow your search above.
              </Typography.Text>
              : null
          }

          <Table dataSource={this.state.courses}
            ref={this._tableRef}
            pagination={false}
            rowKey={CourseDetailDTO.courseId}
            rowSelection={{
              columnTitle: 'Actions',
              columnWidth: 100,
              type: 'radio',
              onChange: (selectedRowKeys: React.Key[], selectedRows: CourseDetailDTO[]) => {
                this.handleSelected(selectedRows);
              },
              defaultSelectedRowKeys: [this.props.existingCourseId ?? Guid.Empty()]
            }} >
            <Table.Column
              title='Discipline'
              dataIndex={CourseDetailDTO.discipline}
              width={125}
              render={
                (value: any, record: CourseDetailDTO) => {
                  return (
                    <Tooltip title={record.discipline?.name} placement='right'>
                      {record.discipline?.code}
                    </Tooltip>
                  );
                }} />
            <Table.Column title='Catalog Number' dataIndex={CourseDetailDTO.catalogNumber} width={125} />
            <Table.Column title='Title' dataIndex={CourseDetailDTO.title} />
          </Table>

          {this.props.isCourseRequisite ?
            <Form.Item {...formItems.get(CourseRequisiteDTO.courseRequisiteTypeId)}>
              <Dropdown dropdownMatchSelectWidth={false}
                onChange={this.handleChange}
                allowClear={true}
                showSearch
                optionFilterProp="children"
              >
                {this.renderCourseRequisiteTypes()}
              </Dropdown>
            </Form.Item>
            : null
          }
        </Form >
      </Space >
    );
  }

  renderDisciplineOptions() {
    return this.state.disciplines.map(x => { return this.renderDisciplineOption(x) });
  }

  renderDisciplineOption(discipline: DisciplineDTO): any {
    if (discipline.id) {
      return <Select.Option key={discipline.id} value={discipline.id} >{discipline.display}</Select.Option>;
    }
  }

  renderCourseRequisiteType(courseRequisiteType: CourseRequisiteTypeDTO): any {
    if (courseRequisiteType.id) {
      return <Select.Option key={courseRequisiteType.id} value={courseRequisiteType.id} >{courseRequisiteType.name}</Select.Option>;
    }
  }

  renderCourseRequisiteTypes() {
    return this.state.courseRequisiteTypes.map(x => { return this.renderCourseRequisiteType(x) });
  }
}

export default CourseSearch;
