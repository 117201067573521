import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ConsultantSearch from '../../../components/postSecondary/ConsultantSearch';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import StatusTypeDTO from '../../../models/StatusTypeDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import HistoryUtil from '../../../utils/HistoryUtil';

interface ConsultantsSearchPageProps {
}

interface ConsultantsSearchPageState {
  loading: boolean;
  exporting: boolean;
  statuses: StatusTypeDTO[] | null
}

class ConsultantsSearchPage extends React.Component<ConsultantsSearchPageProps & RouteComponentProps<RouteObject>, ConsultantsSearchPageState> {
  constructor(props: ConsultantsSearchPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      exporting: false,
      statuses: []
    };
  }

  private goToPublicLandingPage = () => {
    HistoryUtil.push(Routes.generate(Routes.POST_SECONDARY_HOME));
  }

  render() {
    return (
      <Content >
        <HeaderPortal title="Consultants" onBack={this.goToPublicLandingPage} breadcrumbs={Breadcrumbs.publicConsultantSearch()} />

        <Card>
          <ConsultantSearch />
        </Card>
      </Content>
    );
  }
}

export default withRouter(ConsultantsSearchPage);
