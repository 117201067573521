import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import WorkBasedLearningTypeDataTable from '../../../../components/datatables/postSecondary/WorkBasedLearningTypeDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface WorkBasedLearningTypeListProps {
}

interface WorkBasedLearningTypeListState {
}

class WorkBasedLearningTypeListPage extends React.Component<WorkBasedLearningTypeListProps & RouteComponentProps<RouteObject>, WorkBasedLearningTypeListState> {
  constructor(props: WorkBasedLearningTypeListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.push(Routes.generate(Routes.CONFIGURATIONS));
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Work Based Learning Types" onBack={this.goToConfig}
          breadcrumbs={Breadcrumbs.workBasedLearningTypeList()}
        />

        <Card >
          <WorkBasedLearningTypeDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(WorkBasedLearningTypeListPage);
