import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import WorkBasedLearningTypeApiService from '../../../../api/WorkBasedLearningTypeApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import WorkBasedLearningTypeDetailsForm from '../../../../components/forms/postSecondary/WorkBasedLearningTypeDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteWorkBasedLearningTypeHandler from '../../../../handlerModels/DeleteWorkBasedLearningTypeHandler';
import * as GetWorkBasedLearningTypeDetailsHandler from '../../../../handlerModels/GetWorkBasedLearningTypeDetailsHandler';
import WorkBasedLearningTypeDTO from '../../../../models/WorkBasedLearningTypeDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface WorkBasedLearningTypeDetailsPageState extends BaseFormState {
  workBasedLearningTypeId: number;
  workBasedLearningType?: WorkBasedLearningTypeDTO;
}

interface WorkBasedLearningTypeDetailsPageProps {
}

class WorkBasedLearningTypeDetailsPage extends React.Component<WorkBasedLearningTypeDetailsPageProps & RouteComponentProps<RouteObject>, WorkBasedLearningTypeDetailsPageState> {
  private readonly _detailsRef = React.createRef<WorkBasedLearningTypeDetailsForm>();

  constructor(props: WorkBasedLearningTypeDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      workBasedLearningTypeId: 0
    };
  }

  componentDidMount() {
    const workBasedLearningTypeId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (workBasedLearningTypeId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const workBasedLearningTypeIdNumber = Number.parseInt(workBasedLearningTypeId);
      this.loadWorkBasedLearningType(workBasedLearningTypeIdNumber);
    }
  }

  private loadWorkBasedLearningType = (workBasedLearningTypeId: number) => {
    this.setState({ workBasedLearningTypeId: workBasedLearningTypeId });

    WorkBasedLearningTypeApiService.getWorkBasedLearningTypeDetails(workBasedLearningTypeId)
      .then((results: GetWorkBasedLearningTypeDetailsHandler.Result) => {
        if (results.workBasedLearningType) {
          this.setState({ workBasedLearningType: results.workBasedLearningType });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._detailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private specialEmphasisDetailsSaved = (workBasedLearningTypeId: number) => {
    this.setState({ isEditing: false });
    if (workBasedLearningTypeId != this.state.workBasedLearningTypeId) {
      HistoryUtil.replace(Routes.generate(Routes.WORK_BASED_LEARNING_DETAILS, { id: workBasedLearningTypeId }));
    }
    else {
      this.loadWorkBasedLearningType(workBasedLearningTypeId);
    }
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Work Based Learning Type?',
      okText: 'Delete',
      onOk: () => this.deleteWorkBasedLearningType(id)
    });
  }

  private deleteWorkBasedLearningType = (workBasedLearningTypeId: number) => {
    this.setState({ submitting: true });

    const request = DeleteWorkBasedLearningTypeHandler.Request.create({
      workBasedLearningTypeId: workBasedLearningTypeId,
    });

    WorkBasedLearningTypeApiService.deleteWorkBasedLearningType(request)
      .then((result: DeleteWorkBasedLearningTypeHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Work Based Learning Type was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Work Based Learning Type could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToWorkBasedLearningType();
      });
  }

  private goToWorkBasedLearningType = () => {
    HistoryUtil.goBack()
  }

  render() {
    const title = this.state.loading ? '...' : this.state.workBasedLearningTypeId == 0 ? 'New Work Based Learning Type' : this.state.workBasedLearningType?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToWorkBasedLearningType}
          breadcrumbs={Breadcrumbs.workBasedLearningTypeDetails(title, this.state.workBasedLearningTypeId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.workBasedLearningTypeId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <WorkBasedLearningTypeDetailsForm
          ref={this._detailsRef}
          workBasedLearningTypeId={this.state.workBasedLearningTypeId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.specialEmphasisDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Work Based Learning Type' key={this.state.workBasedLearningTypeId} onClick={() => this.deleteConfirmation(this.state.workBasedLearningTypeId)}  >
          Delete Work Based Learning Type
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_WORK_BASED_LEARNING]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(WorkBasedLearningTypeDetailsPage);
