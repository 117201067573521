import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Card, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ProgramModificationChangeRequestApiService from '../../../api/ProgramModificationChangeRequestApiService';
import * as GetProgramModificationChangeRequestStep4Handler from '../../../handlerModels/GetProgramModificationChangeRequestStep4Handler';
import * as GetProgramModificationChangeRequestStep4TermHandler from '../../../handlerModels/GetProgramModificationChangeRequestStep4TermHandler';
import * as SaveProgramModificationChangeRequestStep4Handler from '../../../handlerModels/SaveProgramModificationChangeRequestStep4Handler';
import * as SubmitProgramModificationChangeRequestStep4Handler from '../../../handlerModels/SubmitProgramModificationChangeRequestStep4Handler';
import * as ValidateProgramModificationChangeRequestStep4Handler from '../../../handlerModels/ValidateProgramModificationChangeRequestStep4Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import FlatProgramModificationProgramTermCourseGroupOptionDTO from '../../../models/FlatProgramModificationProgramTermCourseGroupOptionDTO';
import ProgramAwardDTO from '../../../models/ProgramAwardDTO';
import ProgramModificationChangeRequestDTO from '../../../models/ProgramModificationChangeRequestDTO';
import ProgramModificationProgramAwardDTO from '../../../models/ProgramModificationProgramAwardDTO';
import ProgramModificationProgramTermDTO from '../../../models/ProgramModificationProgramTermDTO';
import ProgramTermDTO from '../../../models/ProgramTermDTO';
import YearTermDTO from '../../../models/YearTermDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import PreviousButton from '../../buttons/PreviousButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import RichTextEditor from '../../inputs/RichTextEditor';
import YesNoInput from '../../inputs/YesNoInput';
import ProgramTermEditor, { ProgramTermEditorValue } from '../../postSecondary/ProgramTermEditor';
import TermOnlyInput from '../../TermOnlyInput';
import SearchForProgramTerm from './SearchForProgramTerm';

interface ProgramModificationChangeRequestStep4FormState extends BaseFormState {
  addTerm: boolean;
  changeRequest: ChangeRequestDTO;
  programModification: ProgramModificationChangeRequestDTO;
  programAward: ProgramModificationProgramAwardDTO;
  programAwardTerms: ProgramModificationProgramTermDTO[];
  existingProgramTerms: ProgramTermDTO[];
  allTermsLoaded: boolean;
  validating: boolean;
  termLoading: Map<string, boolean>;
  totalCredits: Map<number, number>;
  totalTechCoreCredits: Map<number, number>;
}

interface ProgramModificationChangeRequestStep4FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  awardId?: string;
  onSubmit?: (id: string) => void;
  onSave?: (id: string) => void;
  onPrevious?: () => void;
  onChange?: (altered: boolean) => void;
  onIsValid?: (isValid: boolean) => void;
  selectedInstitution: string | null;
  readonly?: boolean;
  review?: boolean;
}

class ProgramModificationChangeRequestStep4Form extends React.Component<ProgramModificationChangeRequestStep4FormProps, ProgramModificationChangeRequestStep4FormState> {
  private readonly _programTermEditor = React.createRef<ProgramTermEditor>();
  private readonly _searchForProgramTermRef = React.createRef<SearchForProgramTerm>();

  private readonly _formRef = React.createRef<FormInstance>();
  private termsLoaded = false;
  private getProgramModificationFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(ProgramModificationProgramAwardDTO.prerequisite, {
        required: true,
        name: ProgramModificationProgramAwardDTO.prerequisite,
        label: 'Does this award have any prerequisites?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramModificationProgramAwardDTO.terms, {
        name: [index, ProgramModificationProgramAwardDTO.terms],
      })
      .set(ProgramModificationProgramAwardDTO.totalCredits, {
        name: ProgramModificationProgramAwardDTO.totalCredits,
      })
  }

  private getProgramTermsFormItems = (programTerm?: any) => {
    return new Map<string, FormItemProps>()
      .set(ProgramModificationProgramTermDTO.informationalContent, {
        label: 'Term Description',
        name: [programTerm, ProgramModificationProgramTermDTO.informationalContent],
      })
      .set(ProgramModificationProgramTermDTO.termId, {
        label: 'Term Type',
        name: [programTerm, ProgramModificationProgramTermDTO.termId],
      });
  }

  constructor(props: ProgramModificationChangeRequestStep4FormProps) {
    super(props);

    this.state = {
      addTerm: false,
      changeRequest: ChangeRequestDTO.create(),
      programModification: ProgramModificationChangeRequestDTO.create(),
      programAward: ProgramModificationProgramAwardDTO.create(),
      existingProgramTerms: [],
      programAwardTerms: [],
      allTermsLoaded: false,
      validating: false,
      termLoading: new Map<string, boolean>(),
      totalCredits: new Map<number, number>(),
      totalTechCoreCredits: new Map<number, number>()
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      this.setState({ loading: true });
      const loaders = [];

      loaders.push(this.loadChangeRequest());

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
        this.resetForm()
      });
    }
  }

  componentDidUpdate(prevProps: ProgramModificationChangeRequestStep4FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty() && prevProps.changeRequestId != this.props.changeRequestId) {
      const loaders = [];
      loaders.push(this.loadChangeRequest());
      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
        this.resetForm()
      });
    }

    if (this.props.review && !this.allTermsLoaded() && !this.state.allTermsLoaded && this.termsLoaded && !this.state.validating && this.state.termLoading.size > 0 && this.state.programAward.id != Guid.Empty()) {
      const loaders = [];
      loaders.push(this.ValidateStep4());
      Promise.all(loaders).then(() => {
        this.setState({
          loading: false,
          allTermsLoaded: true,
          validating: false
        });
      });
    }
  }

  private allTermsLoaded = () => {
    let termsLoaded = false;
    this.state.termLoading.forEach(x => {
      if (x.valueOf()) {
        termsLoaded = true;
      }
    });
    return termsLoaded;
  }

  handleTermsLoaded = (termId: string) => {
    const loadings = this.state.termLoading;
    loadings.set(termId, false);
    this.setState({ termLoading: loadings });
  }

  private ValidateStep4 = () => {
    this.setState({ loading: true, validating: true });
    let credits = 0;
    let techCoreCredits = 0;
    this.state.totalCredits.forEach(x => credits += x);
    this.state.totalTechCoreCredits.forEach(x => techCoreCredits += x);

    const request = ValidateProgramModificationChangeRequestStep4Handler.Request.create({
      changeRequestId: this.props.changeRequestId,
      programAward: this.state.programAward,
      totalCredits: credits,
      totalTechCoreCredits: techCoreCredits
    });

    return ProgramModificationChangeRequestApiService.validateProgramModificationChangeRequestStep4(request)
      .then((results: ValidateProgramModificationChangeRequestStep4Handler.Result) => {
        if (results) {

          const fieldErrors = this.state.fieldErrors ?? {};
          for (const fieldErrorName in results.fieldErrors) {
            if (!fieldErrors[fieldErrorName]) {
              fieldErrors[fieldErrorName] = results.fieldErrors[fieldErrorName];
            }
          }

          if (this.props.onIsValid) {
            this.props.onIsValid(results.succeeded);
          }

          this.setState({
            fieldErrors: this.props.changeRequestDetailsPage ? null : fieldErrors
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private loadChangeRequest = () => {

    const request = GetProgramModificationChangeRequestStep4Handler.Request.create({
      changeRequestId: this.props.changeRequestId,
      programAwardId: this.props.awardId
    });

    let terms: (string | null)[] = [];

    return ProgramModificationChangeRequestApiService.getStep4(request)
      .then((results: GetProgramModificationChangeRequestStep4Handler.Result) => {
        if (results) {
          const programAward = (results.changeRequest?.programModificationChangeRequest?.programModificationAwards?.find(x => x.id == this.props.awardId) ?? ProgramModificationProgramAwardDTO.create())
          if (programAward?.terms?.length == 0 && !this.props.readonly) {
            programAward.terms.push(ProgramTermDTO.create({ order: 1 }));
          }
          if (programAward?.terms?.length == 0 && !this.props.readonly) {
            programAward.terms.push(ProgramTermDTO.create({ order: 1 }));
          }

          terms = programAward.terms?.map(x => x.id) ?? [];

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            programModification: results.changeRequest?.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create(),
            programAward: programAward,
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors,
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      })
      .finally(() => {
        const loaders: any = [];

        terms.forEach(x => loaders.push(this.loadTerm(x ?? Guid.Empty())));
        Promise.all(loaders).then(() => {
          this.termsLoaded = true;
          this.resetForm();
        });
      });
  }

  private loadTerm = (termId: string) => {
    const termLoadings = this.state.termLoading;
    termLoadings.set(termId, true);
    this.setState({ termLoading: termLoadings });

    const request = GetProgramModificationChangeRequestStep4TermHandler.Request.create({
      changeRequestId: this.props.changeRequestId,
      programAwardId: this.props.awardId,
      institutionId: this.props.selectedInstitution,
      termId: termId
    });

    return ProgramModificationChangeRequestApiService.getStep4Term(request)
      .then((results: GetProgramModificationChangeRequestStep4TermHandler.Result) => {
        if (results) {
          const programTerms = this.state.programAward;
          if (programTerms.terms) {
            programTerms.terms[programTerms.terms?.findIndex(x => x.order == results.term?.order) ?? 0] = results.term ?? ProgramModificationProgramTermDTO.create();
          }

          const fieldErrors = this.state.fieldErrors ?? {};
          for (const fieldErrorName in results.fieldErrors) {

            if (!fieldErrors[fieldErrorName]) {
              fieldErrors[fieldErrorName] = results.fieldErrors[fieldErrorName];
            }
          }

          this.setState({
            programAward: programTerms,
            fieldErrors: this.props.changeRequestDetailsPage ? null : fieldErrors,
            allTermsLoaded: false
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields([ProgramModificationProgramAwardDTO.terms]);
  }

  private copyExistingTerm = (add: any, order: number) => {
    const existingTerm = this.state.existingProgramTerms.find(x => x.id == this._searchForProgramTermRef.current?.submit());

    if (existingTerm) {
      const setIdAndOrderForTerm = {
        id: Guid.Empty(),
        order: order
      }
      const newTerm = ProgramTermDTO.create({ ...existingTerm, ...setIdAndOrderForTerm });

      add(newTerm);
      this.setState({ addTerm: false })
    }
  }

  private handleSave = () => {
    this.setState({ saving: true });
    const model = ProgramModificationProgramAwardDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    const terms: never[] | (never[] & ProgramModificationProgramTermDTO[]) = [];

    for (let i = 0; i < model.terms.length; i++) {
      let term = model.terms[i];
      if (model.terms[i][ProgramModificationProgramAwardDTO.terms]) {
        term = model.terms[i][ProgramModificationProgramAwardDTO.terms];
        term[FlatProgramModificationProgramTermCourseGroupOptionDTO.order] = model.terms[i][FlatProgramModificationProgramTermCourseGroupOptionDTO.order];
        term[ProgramModificationProgramTermDTO.informationalContent] = model.terms[i][ProgramModificationProgramTermDTO.informationalContent];
        term[ProgramModificationProgramTermDTO.termId] = model.terms[i][ProgramModificationProgramTermDTO.termId];
        term[ProgramModificationProgramTermDTO.programAwardId] = this.props.awardId as never ?? Guid.Empty();
      }
      else {
        term = model.terms[i];
        term[FlatProgramModificationProgramTermCourseGroupOptionDTO.order] = model.terms[i][FlatProgramModificationProgramTermCourseGroupOptionDTO.order];
        term[ProgramModificationProgramTermDTO.informationalContent] = model.terms[i][ProgramModificationProgramTermDTO.informationalContent];
        term[ProgramModificationProgramTermDTO.termId] = model.terms[i][ProgramModificationProgramTermDTO.termId];
        term[ProgramModificationProgramTermDTO.programAwardId] = this.props.awardId as never ?? Guid.Empty();
      }
      terms.push(term);
    }

    model.terms = terms;
    model[ProgramModificationProgramAwardDTO.id] = this.props.awardId;

    let credits = 0;
    let techCoreCredits = 0;
    this.state.totalCredits.forEach(x => credits += x);
    this.state.totalTechCoreCredits.forEach(x => techCoreCredits += x);
    const request = SaveProgramModificationChangeRequestStep4Handler.Request.create({
      changeRequestId: this.state.changeRequest.id == Guid.Empty() ? this.props.changeRequestId : this.state.changeRequest.id,
      programModificationProgramAward: model,
      totalCredits: credits,
      totalTechCoreCredits: techCoreCredits
    }) as SaveProgramModificationChangeRequestStep4Handler.Request;

    ProgramModificationChangeRequestApiService.saveStep4(request)
      .then((result: SaveProgramModificationChangeRequestStep4Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');
          const programAward = result.changeRequest?.programModificationChangeRequest?.programModificationAwards?.find((x: any) => x.id == this.props.awardId) ?? ProgramModificationProgramAwardDTO.create();
          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            programModification: result.changeRequest?.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create(),
            programAward: result.changeRequest?.programModificationChangeRequest?.programModificationAwards?.find((x: any) => x.id == this.props.awardId) ?? ProgramModificationProgramAwardDTO.create()
          });
          this._formRef.current?.setFieldsValue({ terms: programAward.terms });
          this.resetForm();
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  calculateTotalCredits = (termOrder: number, termCredits: number, termTechCoreCredits: number) => {
    let totalCredits = this.state.totalCredits;
    totalCredits = totalCredits ? totalCredits : new Map<number, number>().set(termOrder, termCredits);
    totalCredits.set(termOrder, termCredits);

    let totalTechCoreCredits = this.state.totalTechCoreCredits;
    totalTechCoreCredits = totalTechCoreCredits ? totalTechCoreCredits : new Map<number, number>().set(termOrder, termTechCoreCredits);
    totalTechCoreCredits.set(termOrder, termTechCoreCredits);

    this.setState({
      totalCredits: totalCredits,
      totalTechCoreCredits: totalTechCoreCredits
    });
  }

  private handleSubmit = () => {
    this.setState({ submitted: true, submitting: true });
    const model = ProgramModificationProgramAwardDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    const terms: never[] | (never[] & ProgramModificationProgramTermDTO[]) = [];

    for (let i = 0; i < model.terms.length; i++) {
      let term = model.terms[i];
      if (model.terms[i][ProgramModificationProgramAwardDTO.terms]) {
        term = model.terms[i][ProgramModificationProgramAwardDTO.terms];
        term[FlatProgramModificationProgramTermCourseGroupOptionDTO.order] = model.terms[i][FlatProgramModificationProgramTermCourseGroupOptionDTO.order];
        term[ProgramModificationProgramTermDTO.informationalContent] = model.terms[i][ProgramModificationProgramTermDTO.informationalContent];
        term[ProgramModificationProgramTermDTO.termId] = model.terms[i][ProgramModificationProgramTermDTO.termId];
        term[ProgramModificationProgramTermDTO.programAwardId] = this.props.awardId as never ?? Guid.Empty();
      }
      else {
        term = model.terms[i];
        term[FlatProgramModificationProgramTermCourseGroupOptionDTO.order] = model.terms[i][FlatProgramModificationProgramTermCourseGroupOptionDTO.order];
        term[ProgramModificationProgramTermDTO.informationalContent] = model.terms[i][ProgramModificationProgramTermDTO.informationalContent];
        term[ProgramModificationProgramTermDTO.termId] = model.terms[i][ProgramModificationProgramTermDTO.termId];
        term[ProgramModificationProgramTermDTO.programAwardId] = this.props.awardId as never ?? Guid.Empty();
      }
      terms.push(term);
    }

    model.order = this.state.programAward.order;
    model[ProgramModificationProgramAwardDTO.totalCredits] = this.state.programAward.totalCredits;
    model[ProgramModificationProgramAwardDTO.awardTypeId] = this.state.programAward.awardTypeId;
    model.terms = terms;
    model[ProgramModificationProgramAwardDTO.id] = this.props.awardId;
    model[ProgramModificationProgramAwardDTO.programModificationChangeRequestId] = this.state.programModification.id;

    let credits = 0;
    let techCoreCredits = 0;
    this.state.totalCredits.forEach(x => credits += x);
    this.state.totalTechCoreCredits.forEach(x => techCoreCredits += x);

    const request = SubmitProgramModificationChangeRequestStep4Handler.Request.create({
      changeRequestId: this.state.changeRequest.id == Guid.Empty() ? this.props.changeRequestId : this.state.changeRequest.id,
      programModificationProgramAward: model,
      totalCredits: credits,
      totalTechCoreCredits: techCoreCredits
    }) as SubmitProgramModificationChangeRequestStep4Handler.Request;

    ProgramModificationChangeRequestApiService.submitStep4(request)
      .then((result: SubmitProgramModificationChangeRequestStep4Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSubmit) {
            this.props.onSubmit(this.state.changeRequest.id ?? Guid.Empty());
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handlePrequisiteOnClick = (e: any) => {
    const formValue = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const programAward = this.state.programAward;

    const terms = formValue.terms;
    const newProgramTerms: ProgramTermDTO[] = [];

    if (e) {
      newProgramTerms.push(ProgramTermDTO.create({ order: 0 }));

      newProgramTerms.pushAll(terms)
      this._formRef.current?.resetFields([0, ProgramModificationProgramAwardDTO.terms]);
    }
    else if (programAward.prerequisite == true) {
      const totalCredits = this.state.totalCredits;
      const totalTechCredits = this.state.totalTechCoreCredits;
      totalTechCredits.delete(0);
      totalCredits.delete(0);
      this.setState({ totalCredits: totalCredits, totalTechCoreCredits: totalTechCredits });
      terms?.remove(terms[0])

      newProgramTerms.pushAll(terms);
    }
    else {
      newProgramTerms.pushAll(terms);
    }

    programAward.terms?.removeAll(programAward.terms);
    programAward.terms?.pushAll(newProgramTerms);
    programAward.prerequisite = e;
    this._formRef.current?.setFieldsValue({ terms: programAward.terms });

    this.setState({ programAward: programAward });
  }

  public render = () => {
    if (this.state.loading || this.state.loading == undefined || this.props.loading) {
      return <Skeleton active={true} />;
    }

    let credits = 0;
    this.state.totalCredits.forEach(x => credits += x);

    let techCoreCredits = 0;
    this.state.totalTechCoreCredits.forEach(x => techCoreCredits += x);

    return (
      <Space size="small" direction="vertical">
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.programAward}
          onFinish={this.handleSubmit}
          requiredMark={!this.props.readonly}>
          <Space direction="vertical">

            <FormItem
              {...this.getProgramModificationFormItems(0).get(ProgramModificationProgramAwardDTO.prerequisite)}
              {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.prerequisite + this.state.programAward.order, this.state.fieldErrors, this.props.readonly || this.state.submitted)}
            >
              <YesNoInput disabled={this.props.readonly} onChange={this.handlePrequisiteOnClick} />
            </FormItem>

            {this.renderProgramTerm()}

            <b>Award Total Credits: {credits}</b>
            {this.state.programModification.program?.currentDetail?.isTransferMajor ? null : <b>Award Total Tech Core Credits: {techCoreCredits}</b>}
            {this.renderTermsErrors()}
            {this.renderCreditLimitErrors()}
            {this.renderTechCoreCreditsErrors()}

            {this.renderActions()}
          </Space>
        </Form>
      </Space >
    );
  }

  renderProgramTerm() {
    return (
      <Form.List name={ProgramModificationProgramAwardDTO.terms}>
        {
          (terms, { add, remove }) => {
            let formTerms = this._formRef.current?.getFieldValue(ProgramAwardDTO.terms) as ProgramModificationProgramTermDTO[];
            if (formTerms == undefined || formTerms.length == 0) {
              formTerms = (this.state.programAward.terms ?? []);
            }

            return (
              <Space direction="vertical">
                {
                  terms.map((term, index) => {

                    const prerequisite = this._formRef.current?.getFieldValue(ProgramModificationProgramAwardDTO.prerequisite) ? this._formRef.current?.getFieldValue(ProgramModificationProgramAwardDTO.prerequisite) : this.state.programAward.prerequisite;
                    const title = prerequisite && index == 0 ? 'Prerequisite' : 'Term ' + (index + (prerequisite ? 0 : 1));
                    const deleteTerm = (prerequisite && index == 0) || this.props.readonly ? null : <Button onClick={() => this.removeTerm(term, remove, prerequisite)} type="link" icon={<DeleteOutlined />} />
                    const formTerm = (formTerms ?? [ProgramTermDTO.create])[index];
                    const termValue = {
                      id: formTerm?.id,
                      prerequisite: formTerm?.prerequisite,
                      groups: formTerm?.groups,
                      flatCourseGroupOptions: formTerm?.flatCourseGroupOptions,
                      order: formTerm?.order
                    } as ProgramTermEditorValue;
                    const transferMajor = this.state.programModification.program?.currentDetail?.isTransferMajor ?? false;
                    return (
                      <Card type="inner" size="small" key={term.key} title={title} extra={deleteTerm}>
                        <FormItem
                          {...this.getProgramTermsFormItems(index).get(ProgramTermDTO.termId)}
                          {...ValidationUtil.getValidation(ProgramTermDTO.termId + this.state.programAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                          <TermOnlyInput readOnly={this.props.readonly} />
                        </FormItem>

                        <FormItem
                          {...this.getProgramTermsFormItems(index).get(ProgramTermDTO.informationalContent)}
                          {...ValidationUtil.getValidation(ProgramTermDTO.informationalContent + this.state.programAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                          <RichTextEditor readonly={this.props.readonly} />
                        </FormItem>

                        <FormItem initialValue={termValue}
                          {...this.getProgramModificationFormItems(index).get(ProgramModificationProgramAwardDTO.terms)}
                          {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.terms, this.state.fieldErrors, this.state.submitted)}
                        >
                          <ProgramTermEditor
                            ref={this._programTermEditor}
                            key={index}
                            programAward={this.state.programAward}
                            transferMajor={transferMajor}
                            selectedInstitution={this.state.changeRequest.institutionId}
                            loading={this.state.programAward.terms ? this.state.termLoading?.get(termValue.id ?? Guid.Empty()) : false}
                            readonly={this.props.readonly}
                            termIndex={index}
                            fieldErrors={this.state.fieldErrors}
                            submitted={this.state.submitted || this.props.readonly}
                            onCreditsChanged={this.calculateTotalCredits}
                            onTermLoaded={this.handleTermsLoaded}
                            isEditing={true}
                            prerequisite={prerequisite}
                            installTerm={this.state.programModification.install ?? YearTermDTO.create()} />
                        </FormItem>
                      </Card>
                    );
                  })
                }
                {!this.props.readonly ?
                  <Button onClick={() => this.addTerm(formTerms, add)}>
                    Add Term
                  </Button>
                  : null}
              </Space>
            );
          }
        }
      </Form.List >
    );
  }

  private addTerm = (formTerms: any, add: any) => {
    add(ProgramTermDTO.create({
      order: formTerms && formTerms.length != 0 && formTerms[formTerms.length - 1] != undefined ? formTerms[formTerms.length - 1].order + 1 : 1
    }))
  }

  private removeTerm = (term: any, remove: any, prerequisite: boolean) => {
    const totalCredits = this.state.totalCredits;
    const totalTechCoreCredits = this.state.totalTechCoreCredits;
    const termIndex = prerequisite ? term.name : (term.name + 1);
    totalCredits.delete(termIndex);
    totalTechCoreCredits.delete(termIndex);
    this.setState({ totalCredits: totalCredits, totalTechCoreCredits: totalTechCoreCredits });
    remove(term.name);
  }

  private renderActions() {
    if (!this.props.readonly) {
      return < Space direction='horizontal' wrap={true} >
        <PreviousButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton submitting={this.state.submitting || this.state.saving} />
        <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting || this.state.saving} saved={this.state.saved}></SaveButton>
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space >
    }
  }

  renderCreditLimitErrors = () => {
    if (this.state.fieldErrors) {
      return ValidationUtil.getValidation('awardCredits' + this.state.programAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)?.messages.map(x => this.renderErrors(x));
    }
  }

  renderTechCoreCreditsErrors = () => {
    if (this.state.fieldErrors) {
      return ValidationUtil.getValidation('techCoreCredits' + this.state.programAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)?.messages.map(x => this.renderErrors(x));
    }
  }

  renderTermsErrors = () => {
    if (this.state.fieldErrors) {
      return ValidationUtil.getValidation('termData' + this.state.programAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)?.messages.map(x => this.renderErrors(x));
    }
  }

  private renderErrors = (message?: string) => {
    return <Alert type="error" message='Error' showIcon={true} description={message ? message : this.state.message} />;
  }
}

export default ProgramModificationChangeRequestStep4Form;