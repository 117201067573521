import { CloseCircleFilled } from '@ant-design/icons';
import { Alert, DatePicker, Descriptions, Form, FormInstance, FormItemProps, Input, message, Select, Skeleton, Space, Tabs } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { FormListFieldData } from 'antd/lib/form/FormList';
import moment from 'moment';
import * as React from 'react';
import CipsApiService from '../../../api/CipsApiService';
import ProgramApiService from '../../../api/ProgramApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetActiveCipDetailsHandler from '../../../handlerModels/GetActiveCipDetailsHandler';
import * as GetSelectedProgramDetailHandler from '../../../handlerModels/GetSelectedProgramDetailHandler';
import * as SaveProgramDetailsHandler from '../../../handlerModels/SaveProgramDetailsHandler';
import CipNumberDetailDTO from '../../../models/CipNumberDetailDTO';
import FlatProgramTermCourseGroupOptionDTO from '../../../models/FlatProgramTermCourseGroupOptionDTO';
import IowaVariationDTO from '../../../models/IowaVariationDTO';
import ProgramAwardDTO from '../../../models/ProgramAwardDTO';
import ProgramDetailDTO from '../../../models/ProgramDetailDTO';
import ProgramDTO from '../../../models/ProgramDTO';
import ProgramTermDTO from '../../../models/ProgramTermDTO';
import TermDTO from '../../../models/TermDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import ProgramTermEditor from '../../postSecondary/ProgramTermEditor';
import TermInput from '../../TermInput';
import ProgramAwardDisplay from './ProgramAwardDisplay';

interface ProgramDisplayState extends BaseFormState {
  cipNumberDetails: CipNumberDetailDTO[];
  iowaVariations: IowaVariationDTO[];
  program: ProgramDTO;
  programDetail: ProgramDetailDTO;
  programDetails: ProgramDetailDTO[];
  totalCredits: Map<string, Map<number, number>>;
  totalTechCoreCredits: Map<string, Map<number, number>>;
  activeTab: string;
  terms: TermDTO[];
}

interface ProgramDisplayProps extends BaseFormProps {
  program: ProgramDTO;
  programDetail?: ProgramDetailDTO;
  selectedDetailId: string;
  onSave?: (id: string) => void;
  readonly?: boolean;
  disabled?: boolean;
}

class ProgramDisplay extends React.Component<ProgramDisplayProps, ProgramDisplayState> {
  private readonly _programTermEditor = React.createRef<ProgramTermEditor>();
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _programAwardDisplayRef = React.createRef<ProgramAwardDisplay>();

  private getProgramAwardFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(ProgramDetailDTO.title, {
        required: true,
        name: ProgramDetailDTO.title,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramDetailDTO.cipNumberId, {
        required: true,
        name: ProgramDetailDTO.cipNumberId,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramDetailDTO.iowaVariationId, {
        required: true,
        name: ProgramDetailDTO.iowaVariationId,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramDetailDTO.approvedByAdvisoryBoard, {
        name: ProgramDetailDTO.approvedByAdvisoryBoard,
      })
      .set(ProgramDetailDTO.approvedByDOE, {
        name: ProgramDetailDTO.approvedByDOE,
      })
      .set(ProgramDetailDTO.curriculumApprovalProcessDateOfApproval, {
        name: ProgramDetailDTO.curriculumApprovalProcessDateOfApproval,
      })
      .set(ProgramDetailDTO.awards, {
        name: [index, ProgramDetailDTO.awards],
      })
      .set(ProgramDetailDTO.install, {
        required: true,
        name: ProgramDetailDTO.install,
        rules: [
          ValidationRuleUtil.required(),
          ({ getFieldValue }) => ({ validator: ValidationUtil.termInputYearValidator(this.state.terms, getFieldValue(ProgramDetailDTO.retire), ProgramDetailDTO.install) }),
          () => ({ validator: ValidationUtil.termAndYearValidator() })
        ],
      })
      .set(ProgramDetailDTO.retire, {
        name: ProgramDetailDTO.retire,
        rules: [
          ({ getFieldValue }) => ({ validator: ValidationUtil.termInputYearValidator(this.state.terms, getFieldValue(ProgramDetailDTO.install), ProgramDetailDTO.retire) }),
          () => ({ validator: ValidationUtil.termAndYearValidatorNullable() })
        ]
      });
  }

  constructor(props: ProgramDisplayProps) {
    super(props);
    this.state = {
      activeTab: '0',
      cipNumberDetails: [],
      iowaVariations: [],
      program: ProgramDTO.create(),
      programDetail: ProgramDetailDTO.create(),
      programDetails: [],
      totalCredits: new Map<string, Map<number, number>>(),
      totalTechCoreCredits: new Map<string, Map<number, number>>(),
      terms: []
    };
  }

  componentDidMount() {
    if (this.props.program?.id != Guid.Empty()) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: ProgramDisplayProps) {
    if (this.props.program && prevProps.program != this.props.program) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }

    if (this.props.selectedDetailId != prevProps.selectedDetailId) {
      if (this.state.programDetails.findIndex(x => x.id == this.props.selectedDetailId) == -1) {
        this.fetchData();

      }
      else {
        this.resetForm();
      }
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.cipNumberDetails || this.state.cipNumberDetails.length == 0) {
      loaders.push(this.loadCipNumberDetails());
    }

    if (this.state.terms.length == 0) {
      loaders.push(this.loadTerms());
    }

    if (!this.state.iowaVariations || this.state.iowaVariations.length == 0) {
      loaders.push(this.loadIowaVariation());
    }

    if (this.props.selectedDetailId) {
      if (this.state.programDetails.findIndex(x => x.id == this.props.selectedDetailId) == -1) {
        loaders.push(this.loadProgram());
      }
    }

    this.setState({ program: this.props.program }, () => this.resetForm());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadTerms = () => {
    return LookupsUtil.getAll<TermDTO>(TermDTO.className)
      .then((results: TermDTO[]) => {
        if (results) {
          this.setState({ terms: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCipNumberDetails = () => {
    return CipsApiService.getActiveCipDetails()
      .then((results: GetActiveCipDetailsHandler.Result) => {
        if (results) {
          this.setState({
            cipNumberDetails: results.cipNumberDetails ?? []
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadIowaVariation = () => {
    return LookupsUtil.getAll<IowaVariationDTO>(IowaVariationDTO.className)
      .then((results: IowaVariationDTO[]) => {
        if (results) {
          this.setState({ iowaVariations: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadProgram = () => {
    this.setState({ loading: true });
    ProgramApiService.getProgramDetail(this.props.selectedDetailId ?? Guid.Empty())
      .then((results: GetSelectedProgramDetailHandler.Result) => {
        if (results.programDetail) {
          const programDetails = this.state.programDetails;
          programDetails.push(this.cleanDates(results.programDetail));

          this.setState({
            programDetails: programDetails,
          }, () => this.resetForm());
        }
      })
      .catch();
  }

  private cleanDates = (programDetail: ProgramDetailDTO) => {
    programDetail.approvedByAdvisoryBoard = moment(programDetail.approvedByAdvisoryBoard);
    programDetail.approvedByDOE = moment(programDetail.approvedByDOE);
    programDetail.curriculumApprovalProcessDateOfApproval = moment(programDetail.curriculumApprovalProcessDateOfApproval);
    return programDetail;
  }

  private handleChange = () => {
    if (this.state.altered == false) {
      this.setState({ altered: true });
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this._programAwardDisplayRef.current?.resetForm();
    this.setState({ altered: false });
  }


  private handleSubmit = () => {
    this.setState({ submitting: true });
    const form = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const program = ProgramDTO.create(form);
    const programDetail = ProgramDetailDTO.create({ ...form });
    programDetail.id = this.props.selectedDetailId ?? Guid.Empty();
    if (programDetail.awards) {
      programDetail.awards.forEach((x: never | ProgramAwardDTO) => {
        const terms: ProgramTermDTO[] = [];
        if (x.terms) {
          for (let i = 0; i < x.terms.length; i++) {
            let term = x.terms[i];
            if (x.terms[i][ProgramAwardDTO.terms]) {
              term = x.terms[i][ProgramAwardDTO.terms];
              term[ProgramTermDTO.informationalContent] = x.terms[i][ProgramTermDTO.informationalContent];
              term[ProgramTermDTO.termId] = x.terms[i][ProgramTermDTO.termId];
              term[FlatProgramTermCourseGroupOptionDTO.order] = x.terms[i][FlatProgramTermCourseGroupOptionDTO.order];
            }
            else {
              term = x.terms[i];
              term[ProgramTermDTO.informationalContent] = x.terms[i][ProgramTermDTO.informationalContent];
              term[ProgramTermDTO.termId] = x.terms[i][ProgramTermDTO.termId];
              term[FlatProgramTermCourseGroupOptionDTO.order] = x.terms[i][FlatProgramTermCourseGroupOptionDTO.order]
            }
            terms.push(term);
          }
        }
        x.terms = terms;
      });
    }

    const programDetails = [programDetail];
    program.id = this.state.program.id ?? Guid.Empty();
    program.programDetails.pushAll(programDetails);
    const request = SaveProgramDetailsHandler.Request.create({
      program: program
    });

    ProgramApiService.saveProgramDetails(request)
      .then((result: SaveProgramDetailsHandler.Result | null) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({

          });
          message.success('Saved');

          if (this.props.onSave && result.program?.id) {
            this.resetForm();
            this.props.onSave(result.program.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }

    const value = this.state.programDetails.find(x => x.id == this.props.selectedDetailId);

    return (
      <Form
        ref={this._formRef}
        layout="vertical"
        initialValues={value}
        onValuesChange={this.handleChange}
        onFinish={this.handleSubmit}
        requiredMark={true}>
        <Space direction='vertical' size='large'>
          <Descriptions size='small' bordered={true} layout='vertical' column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 4, xxl: 4 }}>
            <Descriptions.Item label="Program Title">
              {this.props.readonly || !this.props.isEditing ? value?.title :
                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(ProgramDetailDTO?.title)}
                  {...ValidationUtil.getValidation(ProgramDetailDTO.title, this.state.fieldErrors, this.state.submitted)}>
                  <Input disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting} />
                </FormItem>}
            </Descriptions.Item>

            <Descriptions.Item label="CIP Number">
              {this.props.readonly || !this.props.isEditing ? value?.cipNumber?.currentDetail?.friendlyDisplay :
                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(ProgramDetailDTO?.cipNumberId)}
                  {...ValidationUtil.getValidation(ProgramDetailDTO.cipNumberId, this.state.fieldErrors, this.state.submitted)}>
                  <Select
                    allowClear={true}
                    showSearch
                    showArrow={!this.props.readonly && this.props.isEditing}
                    optionFilterProp="children"
                    disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting}>
                    {this.renderCIPOptions()}
                  </Select>
                </FormItem>
              }
            </Descriptions.Item>

            <Descriptions.Item label="Iowa Variation" >
              {this.props.readonly || !this.props.isEditing ? value?.iowaVariation?.display :
                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(ProgramDetailDTO.iowaVariationId)}
                  {...ValidationUtil.getValidation(ProgramDetailDTO.iowaVariationId, this.state.fieldErrors, this.state.submitted)}>
                  <Select
                    showArrow={!this.props.readonly && this.props.isEditing}
                    allowClear={true}
                    disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting}>
                    {this.state.iowaVariations.map(x => this.renderIowaVariation(x))}
                  </Select>
                </FormItem>}
            </Descriptions.Item>

            <Descriptions.Item label="First Available"> {!AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN]) ? value?.install?.display : (this.props.readonly || !this.props.isEditing) ?
              value?.install?.display :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(0).get(ProgramDetailDTO.install)}
              >
                <TermInput anyYear={true}></TermInput>
              </FormItem>
            } </Descriptions.Item>

            <Descriptions.Item label="Last Available">{!AuthorizationUtil.isAuthorized([FeatureFlag.SYSTEM_ADMIN]) ? value?.retire?.display : (this.props.readonly || !this.props.isEditing) ?
              value?.retire?.display :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(0).get(ProgramDetailDTO.retire)}
              >
                <TermInput anyYear={true}></TermInput>
              </FormItem>
            }</Descriptions.Item>

            <Descriptions.Item label="Submitted By" >
              {value?.submittingUser?.fullName}
            </Descriptions.Item>

            <Descriptions.Item label="Submitted" >
              {DateTimeUtil.shortDate(value?.submittedOn)}
            </Descriptions.Item>

            <Descriptions.Item label="Approved By Advisory Board" >
              {(this.props.readonly || !this.props.isEditing) ?
                DateTimeUtil.shortDate(value?.approvedByAdvisoryBoard) :
                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(ProgramDetailDTO.approvedByAdvisoryBoard)}
                >
                  <DatePicker format='M/D/YYYY' disabled={this.props.readonly} ></DatePicker>
                </FormItem>}
            </Descriptions.Item>

            <Descriptions.Item label="Approved By DOE" >
              {(this.props.readonly || !this.props.isEditing) ?
                DateTimeUtil.shortDate(value?.approvedByDOE) :
                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(ProgramDetailDTO.approvedByDOE)}
                >
                  <DatePicker format='M/D/YYYY' disabled={this.props.readonly} ></DatePicker>
                </FormItem>}
            </Descriptions.Item>

            <Descriptions.Item label="Curriculum Approval Process Date of Approval" >
              {(this.props.readonly || !this.props.isEditing) ?
                DateTimeUtil.shortDate(value?.curriculumApprovalProcessDateOfApproval) :
                <FormItem
                  className={'description-container'}
                  {...this.getProgramAwardFormItems(0).get(ProgramDetailDTO.curriculumApprovalProcessDateOfApproval)}
                >
                  <DatePicker format='M/D/YYYY' disabled={this.props.readonly} ></DatePicker>
                </FormItem>}
            </Descriptions.Item>
          </Descriptions >

          {this.renderAwards()}
          {this.renderSave()}
        </Space >
      </Form>
    );
  }

  renderAwards() {
    return <Form.List name={ProgramDetailDTO.awards}>
      {
        (awards) => {
          return (
            <Tabs onChange={this.onTabChange} >
              {awards.map((award, index) => this.renderAward(award, index))}
            </Tabs>);
        }
      }
    </Form.List >
  }
  onTabChange = (activeKey: string) => {
    this.setState({ activeTab: activeKey });
  }

  renderAward(award: FormListFieldData, awardIndex: number) {
    let programAward: ProgramAwardDTO = ProgramAwardDTO.create();
    const value = this.state.programDetails.find(x => x.id == this.props.selectedDetailId);
    if (value?.awards) {
      programAward = value?.awards[award.key]
    }

    let credits = 0;
    this.state.totalCredits.get(programAward?.id ?? Guid.Empty())?.forEach(x => credits += x);

    let techCoreCredits = 0;
    this.state.totalTechCoreCredits.get(programAward?.id ?? Guid.Empty())?.forEach(x => techCoreCredits += x);

    const awardError = this.state.fieldErrors ? this.state.fieldErrors[ProgramDetailDTO.awards + awardIndex] ?? false : false;
    const title = awardError ? <span><CloseCircleFilled color={'red'} /> {programAward?.title}</span> : programAward?.title;

    return (
      <Tabs.TabPane tab={title} key={awardIndex}>

        <FormItem
          {...this.getProgramAwardFormItems(awardIndex).get(ProgramDetailDTO.awards)}
          {...ValidationUtil.getValidation(ProgramAwardDTO.terms, this.state.fieldErrors, this.state.submitted)}
        >
          <ProgramAwardDisplay
            ref={this._programAwardDisplayRef}
            key={awardIndex}
            program={this.state.program}
            programAwardId={programAward?.id ?? Guid.Empty()}
            award={award}
            awardIndex={awardIndex}
            programDetail={this.state.programDetails.find(x => x.id == this.props.selectedDetailId)}
            selectedDetailId={this.props.selectedDetailId}
            loading={this.state.loading}
            fieldErrors={this.state.fieldErrors}
            submitted={this.state.submitted}
            isEditing={this.props.isEditing}
            readonly={this.props.readonly}
            disabled={this.state.submitting} />
        </ FormItem>

        {this.renderCreditLimitErrors(awardIndex)}
      </Tabs.TabPane >
    )
  }

  renderCreditLimitErrors = (awardIndex: number) => {
    if (this.state.fieldErrors && this.state.activeTab == awardIndex.toString()) {
      return <>
        {ValidationUtil.getValidation('awardCredits' + this.state.activeTab, this.state.fieldErrors, this.state.submitted || this.props.readonly)?.messages.map(x => this.renderErrors(x))}
      </>
    }
  }

  private renderErrors = (message?: string) => {
    return <Alert type="error" message='Error' showIcon={true} description={message ? message : this.state.message} />;
  }

  renderCIPOptions() {
    return this.state.cipNumberDetails.map(x => { return this.renderCIPOption(x) });
  }

  renderCIPOption(cip: CipNumberDetailDTO): any {
    if (cip.id) {
      return <Select.Option key={cip.cipNumberId ?? Guid.Empty()} value={cip.cipNumberId ?? Guid.Empty()} >{cip.friendlyDisplay}</Select.Option>;
    }
  }

  renderIowaVariation(iowaVariation: IowaVariationDTO) {
    if (iowaVariation.id) {
      return <Select.Option key={iowaVariation.id} value={iowaVariation.id}>{iowaVariation.display}</Select.Option>
    }
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_PROGRAM]} >
          <Space direction="horizontal" >
            <SaveButton saving={this.state.submitting} />
            <ResetButton onConfirm={this.resetForm} disabled={this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }
}

export default ProgramDisplay;