// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import InstitutionCourseDataTableDTO from '../models/InstitutionCourseDataTableDTO';
import InstitutionCourseDTO from '../models/InstitutionCourseDTO';
import * as SearchForInstituionCoursesHandler from '../handlerModels/SearchForInstituionCoursesHandler';
import * as SearchForInstitutionCourseByStringHandler from '../handlerModels/SearchForInstitutionCourseByStringHandler';
import * as GetInstitutionCourseDetailByCourseAndInstitutionHandler from '../handlerModels/GetInstitutionCourseDetailByCourseAndInstitutionHandler';
import * as GetInstitutionCourseDetailHandler from '../handlerModels/GetInstitutionCourseDetailHandler';
import * as GetInstitutionCoursesHandler from '../handlerModels/GetInstitutionCoursesHandler';
import * as SearchForCoursesHandler from '../handlerModels/SearchForCoursesHandler';
import * as SaveInstitutionCourseDetailsHandler from '../handlerModels/SaveInstitutionCourseDetailsHandler';

export class InstitutionCoursesApiService extends BaseApi {

    // post: api/institutionCourses?institutionId=${encodeURIComponent(institutionId)}&termId=${encodeURIComponent(termId)}&year=${year}
    public getInstitutionCourseTableData(tableRequest: TableRequestDTO, institutionId: string, termId: string, year: number): Promise<TableResponseDTO<InstitutionCourseDataTableDTO>> {
        let url = `api/institutionCourses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }
        if (termId != null) {
            url += `${prefix}termId=${encodeURIComponent(termId)}`;
            prefix = '&';
        }
        if (year != null) {
            url += `${prefix}year=${year}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstitutionCourseDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutionCourses/institutionsForCourse?courseId=${encodeURIComponent(courseId)}
    public getInstitutionsForCourseTableData(tableRequest: TableRequestDTO, courseId: string): Promise<TableResponseDTO<InstitutionCourseDTO>> {
        let url = `api/institutionCourses/institutionsForCourse`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (courseId != null) {
            url += `${prefix}courseId=${encodeURIComponent(courseId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstitutionCourseDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutionCourses/searchInstitutionCourse
    public searchForInstituionCourses(searchForInstituionCourses: SearchForInstituionCoursesHandler.Request): Promise<SearchForInstituionCoursesHandler.Result> {
        let url = `api/institutionCourses/searchInstitutionCourse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForInstituionCoursesHandler.Result>(searchForInstituionCourses, 'post', url, true, false);
    }

    // post: api/institutionCourses/searchForInstitutionCourseByStringHandler
    public searchForInstitutionCourseByString(searchForInstitutionCourseByString: SearchForInstitutionCourseByStringHandler.Request): Promise<SearchForInstitutionCourseByStringHandler.Result> {
        let url = `api/institutionCourses/searchForInstitutionCourseByStringHandler`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForInstitutionCourseByStringHandler.Result>(searchForInstitutionCourseByString, 'post', url, true, false);
    }

    // post: api/institutionCourses/getInstitutionCourseDetailByCourseIdandInstitutionId?courseId=${encodeURIComponent(courseId)}&institutionId=${encodeURIComponent(institutionId)}
    public getInstitutionCourseDetailByCourseIdandInstitutionId(courseId: string, institutionId: string): Promise<GetInstitutionCourseDetailByCourseAndInstitutionHandler.Result> {
        let url = `api/institutionCourses/getInstitutionCourseDetailByCourseIdandInstitutionId`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (courseId != null) {
            url += `${prefix}courseId=${encodeURIComponent(courseId)}`;
            prefix = '&';
        }
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionCourseDetailByCourseAndInstitutionHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionCourses/getInstitutionCourseDetail?institutionCourseId=${encodeURIComponent(institutionCourseId)}
    public getInstitutionCourseDetail(institutionCourseId: string): Promise<GetInstitutionCourseDetailHandler.Result> {
        let url = `api/institutionCourses/getInstitutionCourseDetail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionCourseId != null) {
            url += `${prefix}institutionCourseId=${encodeURIComponent(institutionCourseId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionCourseDetailHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionCourses/getInstitutionCourses
    public getInstitutionCourses(getInstitutionCourses: GetInstitutionCoursesHandler.Request): Promise<GetInstitutionCoursesHandler.Result> {
        let url = `api/institutionCourses/getInstitutionCourses`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionCoursesHandler.Result>(getInstitutionCourses, 'post', url, true, false);
    }

    // post: api/institutionCourses/searchForCourses
    public searchForCourses(searchForCourses: SearchForCoursesHandler.Request): Promise<SearchForCoursesHandler.Result> {
        let url = `api/institutionCourses/searchForCourses`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForCoursesHandler.Result>(searchForCourses, 'post', url, true, false);
    }

    // post: api/institutionCourses/save
    public saveInstitutionCourseDetails(saveInstitutionCourseDetails: SaveInstitutionCourseDetailsHandler.Request): Promise<SaveInstitutionCourseDetailsHandler.Result> {
        let url = `api/institutionCourses/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveInstitutionCourseDetailsHandler.Result>(saveInstitutionCourseDetails, 'post', url, true, false);
    }

    // post: api/institutionCourses/generateCCNFile?termId=${encodeURIComponent(termId)}&year=${year}
    public generateCCNFile(tableRequest: TableRequestDTO, termId: string, year: number): Promise<string> {
        let url = `api/institutionCourses/generateCCNFile`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (termId != null) {
            url += `${prefix}termId=${encodeURIComponent(termId)}`;
            prefix = '&';
        }
        if (year != null) {
            url += `${prefix}year=${year}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }
}
var service = new InstitutionCoursesApiService();
export default service;
