// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import AwardTypeDTO from './AwardTypeDTO';
import InstructionalLevelDTO from './InstructionalLevelDTO';
import TypeOfProgramDTO from './TypeOfProgramDTO';
import SpecialEmphasisDTO from './SpecialEmphasisDTO';
import ObjectPurposeDTO from './ObjectPurposeDTO';
import NewProgramProgramTermDTO from './NewProgramProgramTermDTO';
import NewProgramProgramAwardInstitutionLocationDTO from './NewProgramProgramAwardInstitutionLocationDTO';
import NewProgramProgramAwardModeOfDeliveryDTO from './NewProgramProgramAwardModeOfDeliveryDTO';
import NewProgramChangeRequestProgramAwardThirdPartyCredentialDTO from './NewProgramChangeRequestProgramAwardThirdPartyCredentialDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NewProgramProgramAwardDTO extends EntitySelectorDTO { 
	id: string | null;
	awardTitle: string | null;
	description: string | null;
	order: number;
	newProgramChangeRequestId: string | null;
	awardTypeId: number | null | null;
	awardType: AwardTypeDTO | null;
	instructionalLevelId: number | null | null;
	instructionalLevel: InstructionalLevelDTO | null;
	typeOfProgramId: number | null | null;
	typeOfProgram: TypeOfProgramDTO | null;
	specialEmphasisId: number | null | null;
	specialEmphasis: SpecialEmphasisDTO | null;
	objectPurposeId: number | null | null;
	objectPurpose: ObjectPurposeDTO | null;
	prerequisite: boolean | null | null;
	totalWeeks: number | null | null;
	totalCredits: number | null | null;
	terms: NewProgramProgramTermDTO[] | null;
	newProgramProgramAwardInsitutionLocationIds: string[];
	newProgramProgramAwardInstitutionLocation: NewProgramProgramAwardInstitutionLocationDTO[] | null;
	programAwardModesOfDeliveryIds: number[];
	programAwardModesOfDelivery: NewProgramProgramAwardModeOfDeliveryDTO[] | null;
	thirdPartyCredentials: NewProgramChangeRequestProgramAwardThirdPartyCredentialDTO[] | null;
}

const NewProgramProgramAwardDTO = {
    className: 'NewProgramProgramAwardDTO',
    create: (initValues?: Partial<NewProgramProgramAwardDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			awardTitle: "",
			description: "",
			order: 0,
			newProgramChangeRequestId: '00000000-0000-0000-0000-000000000000',
			awardTypeId: null,
			awardType: null,
			instructionalLevelId: null,
			instructionalLevel: null,
			typeOfProgramId: null,
			typeOfProgram: null,
			specialEmphasisId: null,
			specialEmphasis: null,
			objectPurposeId: null,
			objectPurpose: null,
			prerequisite: null,
			totalWeeks: null,
			totalCredits: null,
			terms: [],
			newProgramProgramAwardInsitutionLocationIds: [],
			newProgramProgramAwardInstitutionLocation: [],
			programAwardModesOfDeliveryIds: [],
			programAwardModesOfDelivery: [],
			thirdPartyCredentials: [],
        },
        initValues);
    },
	id: 'id',
	awardTitle: 'awardTitle',
	description: 'description',
	order: 'order',
	newProgramChangeRequestId: 'newProgramChangeRequestId',
	awardTypeId: 'awardTypeId',
	awardType: 'awardType',
	instructionalLevelId: 'instructionalLevelId',
	instructionalLevel: 'instructionalLevel',
	typeOfProgramId: 'typeOfProgramId',
	typeOfProgram: 'typeOfProgram',
	specialEmphasisId: 'specialEmphasisId',
	specialEmphasis: 'specialEmphasis',
	objectPurposeId: 'objectPurposeId',
	objectPurpose: 'objectPurpose',
	prerequisite: 'prerequisite',
	totalWeeks: 'totalWeeks',
	totalCredits: 'totalCredits',
	terms: 'terms',
	newProgramProgramAwardInsitutionLocationIds: 'newProgramProgramAwardInsitutionLocationIds',
	newProgramProgramAwardInstitutionLocation: 'newProgramProgramAwardInstitutionLocation',
	programAwardModesOfDeliveryIds: 'programAwardModesOfDeliveryIds',
	programAwardModesOfDelivery: 'programAwardModesOfDelivery',
	thirdPartyCredentials: 'thirdPartyCredentials',
};

export default NewProgramProgramAwardDTO;