import { Card, Select } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../../api/AcademicYearApiService';
import SecondaryCipNumbersDataTable from '../../../../components/datatables/secondary/SecondaryCipNumbersDataTable';
import Dropdown from '../../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import * as GetAcademicYearsHandler from '../../../../handlerModels/GetAcademicYearsHandler';
import AcademicYearDTO from '../../../../models/AcademicYearDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import DateTimeUtil from '../../../../utils/DateTimeUtil';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface SecondaryCipNumberConfigurationListPageProps {
}

interface SecondaryCipNumberConfigurationListPageState {
  academicYears: AcademicYearDTO[];
  academicYear: number;
}

class SecondaryCipNumberConfigurationListPage extends React.Component<SecondaryCipNumberConfigurationListPageProps & RouteComponentProps<RouteObject>, SecondaryCipNumberConfigurationListPageState> {
  constructor(props: SecondaryCipNumberConfigurationListPageProps & RouteComponentProps<RouteObject>) {
    super(props);
    this.state = {
      academicYears: [],
      academicYear: 2022
    };
  }

  componentDidMount() {
    this.loadAcademicYears();
  }

  goToConfig = () => {
    HistoryUtil.goBack();
  }

  private onSelect = (value: any) => {
    this.setState({ academicYear: value });
  }

  private loadAcademicYears = () => {
    const request = GetAcademicYearsHandler.Request.create({
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
            academicYear: DateTimeUtil.getCurrentYear()
          });
        }
      })
      .catch()
  }

  render() {
    return (
      <Content>
        <HeaderPortal
          title="CIP Numbers"
          onBack={this.goToConfig}
          breadcrumbs={Breadcrumbs.secondaryCipNumberConfigurationList()}
          extra={<Dropdown value={this.state.academicYear} onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.academicYears.map(x => { return this.renderAcademicYear(x); })}</Dropdown>} />

        <Card>
          <SecondaryCipNumbersDataTable
            isConfiguration={true}
            academicYear={this.state.academicYear} />
        </Card>
      </Content>
    );
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</Select.Option>
  }
}

export default withRouter(SecondaryCipNumberConfigurationListPage);
