import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CourseModificationChangeRequestApiService from '../../../../api/CourseModificationChangeRequestApiService';
import ChangeRequestSubmitted from '../../../../components/displays/postSecondary/ChangeRequestSubmitted';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import * as GetCourseModificationChangeRequestHandler from '../../../../handlerModels/GetCourseModificationChangeRequestHandler';
import ChangeRequestDTO from '../../../../models/ChangeRequestDTO';
import UserSecurity from '../../../../models/UserSecurity';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface CourseModificationChangeRequestReviewPageState extends BaseFormState {
  changeRequestId: string;
  changeRequest: ChangeRequestDTO;
  progress: number;
}

interface CourseModificationChangeRequestReviewPageProps {
  currentUser: UserSecurity | null;
}

class CourseModificationChangeRequestSubmittedPage extends React.Component<CourseModificationChangeRequestReviewPageProps & RouteComponentProps<RouteObject>, CourseModificationChangeRequestReviewPageState> {
  constructor(props: CourseModificationChangeRequestReviewPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequestId: Guid.Empty(),
      changeRequest: ChangeRequestDTO.create(),
      progress: 0
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id !== Guid.Empty() && this.state.changeRequestId != id) {
      this.setState({ changeRequestId: id });
      this.fetchData(id);
    }
  }

  private fetchData = (id: string) => {
    this.setState({ loading: true });

    const loaders = [];

    if (id !== Guid.Empty()) {
      loaders.push(this.loadChangeRequest(id));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadChangeRequest = (id: string) => {
    const request = GetCourseModificationChangeRequestHandler.Request.create({
      changeRequestId: id
    });

    return CourseModificationChangeRequestApiService.get(request)
      .then((results: GetCourseModificationChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  goToPreviousStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_2, { id: this.state.changeRequestId }, {}))
  }

  submit = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_SUBMITTED, { id: this.state.changeRequestId }, {}))
  }

  render() {
    return (
      <Content >
        <HeaderPortal
          breadcrumbs={Breadcrumbs.changeRequestList()} >
        </HeaderPortal>

        <Card >
          <ChangeRequestSubmitted />
        </Card>
      </Content >
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
  };
}

export default withRouter(connect(mapStateToProps)(CourseModificationChangeRequestSubmittedPage));