import { DeleteOutlined, FormOutlined, RightOutlined } from '@ant-design/icons';
import { Alert, Button, Input, InputNumber, message, Modal, Select, Skeleton, Space, Table, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import modal from 'antd/lib/modal';
import * as React from 'react';
import CoursesApiService from '../../../api/CoursesApiService';
import NewCourseChangeRequestApiService from '../../../api/NewCourseChangeRequestApiService';
import Routes from '../../../config/Routes';
import * as ExistingCourseSearchHandler from '../../../handlerModels/ExistingCourseSearchHandler';
import * as ExistingInstitutionCourseSearchHandler from '../../../handlerModels/ExistingInstitutionCourseSearchHandler';
import * as GetNewCourseChangeRequestHandler from '../../../handlerModels/GetNewCourseChangeRequestHandler';
import * as SaveNewCourseChangeRequestHandler from '../../../handlerModels/SaveNewCourseChangeRequestHandler';
import * as SearchForExistitngNewCourseChangeRequestHandler from '../../../handlerModels/SearchForExistitngNewCourseChangeRequestHandler';
import * as SubmitNewCourseChangeRequestHandler from '../../../handlerModels/SubmitNewCourseChangeRequestHandler';
import CategoryDTO from '../../../models/CategoryDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import CourseRequisiteDTO from '../../../models/CourseRequisiteDTO';
import CourseRequisiteTypeDTO from '../../../models/CourseRequisiteTypeDTO';
import CreditTypeDTO from '../../../models/CreditTypeDTO';
import DisciplineDTO from '../../../models/DisciplineDTO';
import NewCourseChangeRequestCreditDTO from '../../../models/NewCourseChangeRequestCreditDTO';
import NewCourseChangeRequestDTO from '../../../models/NewCourseChangeRequestDTO';
import NewCourseChangeRequestCourseRequisiteDTO from '../../../models/NewProgramChangeRequestCourseRequisiteDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
// import NewCourseChangeRequestCourseRequisiteDataTable from '../../datatables/postSecondary/NewCourseChangeRequestCourseRequisiteDataTable';
import ValueLabel from '../../general/ValueLabel';
import Dropdown from '../../inputs/Dropdown';
import YesNoInput from '../../inputs/YesNoInput';
import CourseSearch from '../../postSecondary/CourseSearch';
import TermInput from '../../TermInput';

interface NewCourseChangeRequestStep1FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO | null;
  newCourseChangeRequest?: NewCourseChangeRequestDTO;
  existingCourse: boolean;
  institutionExistingCourse: boolean;
  showInstitutionCourseModal: boolean;
  existingNewCourseChangeRequest: boolean;
  showNewCourseChangeRequest: boolean;
  minCredits?: number;
  maxCredits?: number;
  checking?: boolean;
  disciplines: DisciplineDTO[];
  categories: CategoryDTO[];
  creditTypes: CreditTypeDTO[];
  courseRequisites: CourseRequisiteDTO[];
  courseRequisiteTypes: CourseRequisiteTypeDTO[];
}

interface NewCourseChangeRequestStep1FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  readonly?: boolean;
  onSave?: (id: string) => void;
  onSubmit?: (id: string) => void;
}

class NewCourseChangeRequestStep1Form extends React.Component<NewCourseChangeRequestStep1FormProps, NewCourseChangeRequestStep1FormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _courseRequisiteFormRef = React.createRef<FormInstance>();
  private readonly _courseSearch = React.createRef<CourseSearch>();

  disciplineIdentifier = 'discipline';
  categoryIdentifier = 'category';

  private _formItems = new Map<string, FormItemProps>()
    .set(NewCourseChangeRequestCourseRequisiteDTO.courseRequisiteTypeId, {
      required: true,
      name: NewCourseChangeRequestCourseRequisiteDTO.courseRequisiteTypeId,
      label: 'Course Requisite Type',
      requiredMark: true,
      rules: [ValidationRuleUtil.required()]
    });

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(NewCourseChangeRequestDTO.title, {
        required: true,
        name: NewCourseChangeRequestDTO.title,
        label: 'Please provide the course title.',
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(150)
        ],
      })
      .set(NewCourseChangeRequestDTO.description, {
        required: true,
        name: NewCourseChangeRequestDTO.description,
        label: 'Please provide a description for this course.',
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(5000)
        ],
      })
      .set(NewCourseChangeRequestDTO.objectives, {
        name: NewCourseChangeRequestDTO.objectives,
        label: 'Please list learning outcomes/objectives for this course.',
        rules: [
          ValidationRuleUtil.maxLength(5000)
        ],
      })
      .set(NewCourseChangeRequestDTO.catalogNumber, {
        required: true,
        name: NewCourseChangeRequestDTO.catalogNumber,
        label: 'What catalog number will this course use?',
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.onlyNumericCharacters(),
          ValidationRuleUtil.maxLength(3)
        ],
      })
      .set(NewCourseChangeRequestDTO.categoryId, {
        required: true,
        name: NewCourseChangeRequestDTO.categoryId,
        label: 'Please select a category for this course.',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(NewCourseChangeRequestDTO.disciplineId, {
        required: true,
        name: NewCourseChangeRequestDTO.disciplineId,
        label: 'Please select the discipline for this course.',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(NewCourseChangeRequestDTO.install, {
        required: true,
        name: NewCourseChangeRequestDTO.install,
        label: 'When will this course first be available?',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(NewCourseChangeRequestDTO.variableHours, {
        required: true,
        name: NewCourseChangeRequestDTO.variableHours,
        label: 'Will this course offer variable credits?',
        rules: [
          ValidationRuleUtil.required()
        ],
      });
  }

  private getCreditTypeFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(NewCourseChangeRequestCreditDTO.creditTypeId, {
        name: [index, NewCourseChangeRequestCreditDTO.creditTypeId],
      })
      .set(NewCourseChangeRequestCreditDTO.minHours, {
        name: [index, NewCourseChangeRequestCreditDTO.minHours],
      })
      .set(NewCourseChangeRequestCreditDTO.maxHours, {
        name: [index, NewCourseChangeRequestCreditDTO.maxHours],
      });
  }

  private getCreditColumns = () => {
    const variableHours = this._formRef.current?.getFieldValue(NewCourseChangeRequestDTO.variableHours);
    const columns = [
      {
        title: 'Credit Type',
        sorter: false,
        dataIndex: NewCourseChangeRequestCreditDTO.creditTypeId,
        render: (data: any, row: any, index: number) => {
          const creditTypeId = this._formRef.current?.getFieldValue(NewCourseChangeRequestDTO.newCourseRequestCredits)[index].creditTypeId;
          if (creditTypeId) {
            const creditType = this.state.creditTypes.filter(x => x.id == creditTypeId)[0];
            if (creditType) {
              return <ValueLabel text={creditType.name} />;
            }
          }
        }
      },
      {
        title: variableHours ? 'Min Credits' : 'Credits',
        dataIndex: NewCourseChangeRequestCreditDTO.minHours,
        render: (data: any, row: any, index: number) => {
          const title = variableHours ? 'Min Credits' : 'Credits';
          return (
            <FormItem
              {...this.getCreditTypeFormItems(index).get(NewCourseChangeRequestCreditDTO.minHours)}
              {...ValidationUtil.getValidation(NewCourseChangeRequestCreditDTO.minHours + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <InputNumber title={title} disabled={this.props.readonly} min={0} />
            </FormItem>
          );
        }
      },
    ];

    if (variableHours) {
      columns.push(
        {
          title: 'Max Credits',
          dataIndex: NewCourseChangeRequestCreditDTO.maxHours,
          render: (data: any, row: any, index: number) => {
            return (
              <FormItem
                {...this.getCreditTypeFormItems(index).get(NewCourseChangeRequestCreditDTO.maxHours)}
                {...ValidationUtil.getValidation(NewCourseChangeRequestCreditDTO.maxHours + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                <InputNumber title='Max Credits' disabled={this.props.readonly} min={0} />
              </FormItem>
            );
          }
        });
    }

    return columns;
  }

  private getNewCourseCourseRequisiteColumns = () => {
    const columns = [
      {
        title: 'Name',
        sorter: false,
        dataIndex: NewCourseChangeRequestCourseRequisiteDTO.requisiteCourseId,
        render: (data: any, row: any, index: number) => {
          const requisiteCourse = this._formRef.current?.getFieldValue(NewCourseChangeRequestDTO.newCourseChangeRequestCourseRequisites)[index]
          return requisiteCourse?.requisiteCourse?.currentDetail?.display ?? requisiteCourse?.requisiteCourse?.display;
        }
      },
      {
        title: 'Type',
        dataIndex: NewCourseChangeRequestCourseRequisiteDTO.courseRequisiteTypeId,
        render: (data: any, row: any, index: number) => {
          const requisiteCourse = this._formRef.current?.getFieldValue(NewCourseChangeRequestDTO.newCourseChangeRequestCourseRequisites)[index]
          return (
            this.state.courseRequisiteTypes.find(x => x.id == requisiteCourse?.courseRequisiteTypeId)?.name
          );
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (data: any, row: any, index: number) => {
          const requisiteCourse = this._formRef.current?.getFieldValue(NewCourseChangeRequestDTO.newCourseChangeRequestCourseRequisites)[index]
          return (<>
            <Button type="link" onClick={() => this.editCourseRequisite(requisiteCourse)}><FormOutlined /></Button>
            <Button type="link" onClick={() => this.removeCourseRequisite(requisiteCourse)}><DeleteOutlined /></Button>
          </>
          );
        }
      },
    ];

    return columns;
  }


  constructor(props: NewCourseChangeRequestStep1FormProps) {
    super(props);

    this.state = {
      changeRequest: null,
      existingCourse: false,
      institutionExistingCourse: false,
      showInstitutionCourseModal: false,
      disciplines: [],
      categories: [],
      creditTypes: [],
      showNewCourseChangeRequest: false,
      existingNewCourseChangeRequest: false,
      courseRequisites: [],
      courseRequisiteTypes: []
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      this.setState({
        fieldErrors: this.props.fieldErrors
      }, () => this.resetForm())
    }

    this.fetchData();
  }

  componentDidUpdate(prevProps: NewCourseChangeRequestStep1FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.setState({
        fieldErrors: this.props.fieldErrors
      }, () => this.resetForm());

      this.fetchData();
    }

    if (this.state.creditTypes?.length > 0) {
      const creditsLength = this.state.newCourseChangeRequest?.newCourseRequestCredits?.length ?? 0;
      if (creditsLength === 0) {
        const creditDtos = this.state.creditTypes.map(x => {
          return NewCourseChangeRequestCreditDTO.create({
            creditType: x,
            creditTypeId: x.id,
            minHours: null,
            maxHours: null,
            displayOrder: x.displayOrder
          });
        }).sort((a, b) => { return a.displayOrder - b.displayOrder });

        this.setState({
          newCourseChangeRequest: NewCourseChangeRequestDTO.create({
            disciplineId: null,
            categoryId: null,
            installTermId: null,
            variableHours: null,
            newCourseRequestCredits: creditDtos
          }),
        });

        this.resetForm();
      }
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });

    this._formRef.current?.resetFields();
    this.forceUpdate();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.disciplines || this.state.disciplines.length == 0) {
      loaders.push(this.loadDisciplines());
    }

    if (!this.state.courseRequisiteTypes || this.state.courseRequisiteTypes.length == 0) {
      loaders.push(this.loadCourseRequisiteType());
    }

    if (!this.state.categories || this.state.categories.length == 0) {
      loaders.push(this.loadCategories());
    }

    if (!this.state.creditTypes || this.state.creditTypes.length == 0) {
      loaders.push(this.loadCreditTypes());
    }

    if (this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadCategories = () => {
    return LookupsUtil.getAll<CategoryDTO>(CategoryDTO.className)
      .then((results: CategoryDTO[]) => {
        if (results) {
          this.setState({ categories: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCreditTypes = () => {
    return LookupsUtil.getAll<CreditTypeDTO>(CreditTypeDTO.className)
      .then((results: CreditTypeDTO[]) => {
        if (results) {
          this.setState({ creditTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadDisciplines = () => {
    return LookupsUtil.getAll<DisciplineDTO>(DisciplineDTO.className)
      .then((results: DisciplineDTO[]) => {
        if (results) {
          results = results.sort((a, b) => (a?.code ?? '') > (b?.code ?? '') ? 1 : -1)
          this.setState({ disciplines: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCourseRequisiteType = () => {
    return LookupsUtil.getAll<CourseRequisiteTypeDTO>(CourseRequisiteTypeDTO.className)
      .then((results: CourseRequisiteTypeDTO[]) => {
        if (results) {
          results = results.sort((a, b) => (a.displayOrder ?? '') > (b?.displayOrder ?? '') ? 1 : -1)
          this.setState({ courseRequisiteTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadChangeRequest = () => {
    const request = GetNewCourseChangeRequestHandler.Request.create({
      changeRequestId: this.props.changeRequestId
    });
    return NewCourseChangeRequestApiService.get(request)
      .then((results: GetNewCourseChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            newCourseChangeRequest: results.changeRequest?.newCourseChangeRequest ?? NewCourseChangeRequestDTO.create(),
            existingCourse: results.changeRequest?.newCourseChangeRequest?.existingCourse ?? false,
            minCredits: results.changeRequest?.newCourseChangeRequest?.minCredits ?? 0,
            maxCredits: results.changeRequest?.newCourseChangeRequest?.maxCredits ?? Number.MAX_SAFE_INTEGER,
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private checkInstitutionCourse = () => {
    this.setState({ checking: true });

    const model = NewCourseChangeRequestDTO.create(this._formRef.current?.getFieldsValue() ?? null);

    const request = ExistingInstitutionCourseSearchHandler.Request.create({
      disciplineId: model.disciplineId,
      catalogNumber: model.catalogNumber,
      institutionId: this.props.selectedInstitution,
    }) as ExistingInstitutionCourseSearchHandler.Request;

    CoursesApiService.existingInstitutionCourseSearch(request)
      .then((result: ExistingInstitutionCourseSearchHandler.Result) => {
        if (result.course != null) {
          this.setState({
            institutionExistingCourse: true,
            showInstitutionCourseModal: true,
          });
        }
        else {
          this.setState({
            institutionExistingCourse: false,
            showInstitutionCourseModal: false,

          });
          this.checkExisting();
        }
      });
  }

  private checkExisting = () => {
    this.setState({ checking: true });

    const model = NewCourseChangeRequestDTO.create(this._formRef.current?.getFieldsValue() ?? null);

    const request = ExistingCourseSearchHandler.Request.create({
      disciplineId: model.disciplineId,
      catalogNumber: model.catalogNumber
    }) as ExistingCourseSearchHandler.Request;

    CoursesApiService.existingCourseSearch(request)
      .then((result: ExistingCourseSearchHandler.Result) => {
        if (result.course && result.currentCourseDetails) {
          this.setState({
            existingCourse: true,
            minCredits: result.currentCourseDetails.minCredits,
            maxCredits: result.currentCourseDetails.maxCredits,
          });

          this._formRef.current?.setFieldsValue({
            title: result.currentCourseDetails.title,
            variableHours: result.currentCourseDetails.variableCredits,
          });

          modal.info({
            title: 'Existing Course',
            content: 'There is already an existing course in the state for this discipline and catalog number. You must adopt to this course at your institution in order to be approved.'
          });
        }
        else {
          this.setState({
            existingCourse: false,
            minCredits: undefined,
            maxCredits: undefined,
          });
        }
      })
      .finally(() => {
        this.setState({
          checking: false,
        })
      });
  }

  private checkExistingNewCourseChangeRequest = () => {
    this.setState({ checking: true });

    const model = NewCourseChangeRequestDTO.create(this._formRef.current?.getFieldsValue() ?? null);

    const request = SearchForExistitngNewCourseChangeRequestHandler.Request.create({
      disciplineId: model.disciplineId,
      catalogNumber: model.catalogNumber
    }) as SearchForExistitngNewCourseChangeRequestHandler.Request;

    NewCourseChangeRequestApiService.existingNewCourseSearch(request)
      .then((result: SearchForExistitngNewCourseChangeRequestHandler.Result) => {
        if (result.newCourseChangeRequest && result.newCourseChangeRequest.length > 0) {
          this.setState({
            showNewCourseChangeRequest: true,
            existingNewCourseChangeRequest: true,
          });
          modal.info({
            title: 'Course Reserved',
            content: 'This discipline and catalog number combination is already reserved in a pending new course request. Please contact the state to learn more.'
          });
        }
        else {
          this.setState({
            showNewCourseChangeRequest: false,
            existingNewCourseChangeRequest: false,
          });
          this.checkInstitutionCourse()
        }
      })
      .finally(() => {
        this.setState({
          checking: false,
        })
      });
  }

  private goToCourseModification = () => {
    const model = NewCourseChangeRequestDTO.create(this._formRef.current?.getFieldsValue() ?? null);
    const catalogNumber = model.catalogNumber;
    const disciplineId = model.disciplineId;

    HistoryUtil.push(Routes.generate(Routes.COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY, {}, { disciplineId: disciplineId, catalogNumber: catalogNumber }));
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = NewCourseChangeRequestDTO.create(this._formRef.current?.getFieldsValue() ?? null);
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();

    const request = SubmitNewCourseChangeRequestHandler.Request.create({
      institutionId: this.props.selectedInstitution,
      newCourseChangeRequest: model
    }) as SubmitNewCourseChangeRequestHandler.Request;

    NewCourseChangeRequestApiService.submitStep1(request)
      .then((result: SubmitNewCourseChangeRequestHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? null,
            newCourseChangeRequest: result.changeRequest?.newCourseChangeRequest ?? this.state.newCourseChangeRequest,
            existingCourse: result.changeRequest?.newCourseChangeRequest?.existingCourse ?? false,
            minCredits: result.changeRequest?.newCourseChangeRequest?.minCredits ?? 0,
            maxCredits: result.changeRequest?.newCourseChangeRequest?.maxCredits ?? Number.MAX_SAFE_INTEGER,
          });

          this.resetForm();

          if (this.props.onSubmit && result.changeRequest?.id) {
            this.props.onSubmit(result.changeRequest.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Submit Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Submit Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const model = NewCourseChangeRequestDTO.create(this._formRef.current?.getFieldsValue() ?? null);
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();

    const request = SaveNewCourseChangeRequestHandler.Request.create({
      institutionId: this.props.selectedInstitution,
      newCourseChangeRequest: model
    }) as SaveNewCourseChangeRequestHandler.Request;

    NewCourseChangeRequestApiService.save(request)
      .then((result: SaveNewCourseChangeRequestHandler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? null,
            newCourseChangeRequest: result.changeRequest?.newCourseChangeRequest ?? this.state.newCourseChangeRequest
          });

          if (this.props.onSave && result.changeRequest?.id) {
            this.props.onSave(result.changeRequest.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private hideInstitutionCourseModal = () => {
    this.setState({
      showInstitutionCourseModal: false
    });
  }

  private handleAddCourseRequisite = () => {
    const values = this._formRef.current?.getFieldsValue();
    const form = this._courseSearch.current?.getValue();
    this._courseSearch.current?.getForm().current?.validateFields();

    let newCourseChangeRequestCourseRequisites = values.newCourseChangeRequestCourseRequisites;
    const loaders = [];
    loaders.push(this._courseSearch.current?.getForm().current?.validateFields());

    return Promise.all(loaders).then(() => {
      if (!newCourseChangeRequestCourseRequisites) {
        newCourseChangeRequestCourseRequisites = [];
      }
      newCourseChangeRequestCourseRequisites.push({
        requisiteCourse: form?.course,
        requisiteCourseId: form?.courseId,
        courseRequisiteTypeId: form?.courseRequisiteTypeId
      })
      this._formRef.current?.setFieldsValue({ newCourseChangeRequestCourseRequisites: newCourseChangeRequestCourseRequisites })
    });
  }

  private handleRemoveCourseRequisite = (record: NewCourseChangeRequestCourseRequisiteDTO) => {
    const value = this._formRef.current?.getFieldValue(NewCourseChangeRequestDTO.newCourseChangeRequestCourseRequisites);
    const deletedValue = value.find((x: any) => x.requisiteCourseId == record.requisiteCourseId);
    value.remove(deletedValue);
    this._formRef.current?.setFieldsValue({ newCourseChangeRequestCourseRequisites: value })
  }

  private handleEditCourseRequisite = (record: NewCourseChangeRequestCourseRequisiteDTO) => {
    const value = this._formRef.current?.getFieldValue(NewCourseChangeRequestDTO.newCourseChangeRequestCourseRequisites);
    const courseRequisiteTypeId = this._courseRequisiteFormRef.current?.getFieldValue(NewCourseChangeRequestCourseRequisiteDTO.courseRequisiteTypeId);
    value.find((x: any) => x.requisiteCourseId == record.requisiteCourseId).courseRequisiteTypeId = courseRequisiteTypeId;
    this._formRef.current?.setFieldsValue({ newCourseChangeRequestCourseRequisites: value })
  }

  private addCourseRequisite = () => {
    Modal.confirm({
      title: 'Select Course',
      content: <CourseSearch isCourseRequisite={true} ref={this._courseSearch} institutionId={this.state.changeRequest?.institutionId ?? this.props.selectedInstitution ?? Guid.Empty()} />,
      onOk: () => this.handleAddCourseRequisite(),
      width: 1000
    });
  }

  private removeCourseRequisite = (record: NewCourseChangeRequestCourseRequisiteDTO) => {
    Modal.confirm({
      title: 'Remove Course Requisite',
      onOk: () => this.handleRemoveCourseRequisite(record),
      okType: 'danger',
      okText: 'Remove',
      width: 300
    });
  }

  private editCourseRequisite(record: NewCourseChangeRequestCourseRequisiteDTO) {
    Modal.confirm({
      title: 'Edit Course Requisite',
      onOk: () => this.handleEditCourseRequisite(record),
      content: <Form
        ref={this._courseRequisiteFormRef}
        layout="vertical"
        initialValues={record}
        onFinish={() => this.handleEditCourseRequisite(record)}
        requiredMark={true}>
        <FormItem
          {...this._formItems.get(NewCourseChangeRequestCourseRequisiteDTO.courseRequisiteTypeId)}>
          <Dropdown dropdownMatchSelectWidth={false}
            allowClear={true}
            showSearch
            optionFilterProp="children"
          >
            {this.renderCourseRequisiteTypes()}
          </Dropdown>
        </FormItem>
      </Form>,
      okText: 'Edit',
      okType: 'primary',
      width: 500
    });
  }

  render() {
    if (this.state.loading || !this.state.newCourseChangeRequest) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();

    return (
      <Space size="small" direction="vertical">
        {this.renderInstitutionCourseExists()}
        {this.renderInstitutionCourseExistsModal()}
        {this.renderNewCourseChangeRequestExistsModal()}
        {this.renderNewCourseChangeRequestExists()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.newCourseChangeRequest}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <Space size="small" direction="vertical">
            <FormItem
              key={NewCourseChangeRequestDTO.disciplineId}
              {...formItems.get(NewCourseChangeRequestDTO.disciplineId)}
              {...ValidationUtil.getValidation(NewCourseChangeRequestDTO.disciplineId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Dropdown disabled={this.props.readonly}
                onChange={this.checkExistingNewCourseChangeRequest}
                showSearch
                optionFilterProp="children">
                {this.state.disciplines.map(x => { return this.renderDisciplineOption(x) })}
              </Dropdown>
            </FormItem>

            <FormItem
              key={NewCourseChangeRequestDTO.catalogNumber}
              {...formItems.get(NewCourseChangeRequestDTO.catalogNumber)}
              {...ValidationUtil.getValidation(NewCourseChangeRequestDTO.catalogNumber, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input disabled={this.props.readonly} onChange={this.checkExistingNewCourseChangeRequest} />
            </FormItem>

            <FormItem
              key={NewCourseChangeRequestDTO.title}
              {...formItems.get(NewCourseChangeRequestDTO.title)}
              {...ValidationUtil.getValidation(NewCourseChangeRequestDTO.title, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input disabled={this.props.readonly || this.state.existingCourse} />
            </FormItem>

            <FormItem
              key={NewCourseChangeRequestDTO.categoryId}
              {...formItems.get(NewCourseChangeRequestDTO.categoryId)}
              {...ValidationUtil.getValidation(NewCourseChangeRequestDTO.categoryId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Dropdown disabled={this.props.readonly}>
                {this.state.categories.map(x => { return this.renderCategoryOption(x) })}
              </Dropdown>
            </FormItem>

            <FormItem
              key={NewCourseChangeRequestDTO.description}
              {...formItems.get(NewCourseChangeRequestDTO.description)}
              {...ValidationUtil.getValidation(NewCourseChangeRequestDTO.description, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea maxLength={5000} autoSize={{ minRows: 5 }} showCount={true} disabled={this.props.readonly} />
            </FormItem>

            <FormItem
              key={NewCourseChangeRequestDTO.objectives}
              {...formItems.get(NewCourseChangeRequestDTO.objectives)}
              {...ValidationUtil.getValidation(NewCourseChangeRequestDTO.objectives, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea maxLength={5000} autoSize={{ minRows: 5 }} showCount={true} disabled={this.props.readonly} />
            </FormItem>

            <FormItem
              key={NewCourseChangeRequestDTO.install}
              {...formItems.get(NewCourseChangeRequestDTO.install)}
              {...ValidationUtil.getValidation(NewCourseChangeRequestDTO.install, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <TermInput futureYears={true} disabled={this.props.readonly} />
            </FormItem>

            <FormItem
              key={NewCourseChangeRequestDTO.variableHours}
              {...formItems.get(NewCourseChangeRequestDTO.variableHours)}
              {...ValidationUtil.getValidation(NewCourseChangeRequestDTO.variableHours, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <YesNoInput disabled={this.props.readonly || this.state.existingCourse} />
            </FormItem>

            <Typography.Paragraph italic={true}>
              Please provide credits for at least one credit type in the table below.
            </Typography.Paragraph>

            <Form.List name={NewCourseChangeRequestDTO.newCourseRequestCredits}>
              {(credits) => {
                return (
                  <Form.Item
                    {...ValidationUtil.getValidation(NewCourseChangeRequestDTO.newCourseRequestCredits, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                    <Table dataSource={credits} summary={() => {
                      const variableHours = this._formRef.current?.getFieldValue(NewCourseChangeRequestDTO.variableHours);
                      const credits = this._formRef.current?.getFieldValue(NewCourseChangeRequestDTO.newCourseRequestCredits);

                      const min = credits?.map((x: NewCourseChangeRequestCreditDTO) => x.minHours ?? 0).reduce((a: number, b: number) => a + b) ?? 0;
                      const max = credits?.map((x: NewCourseChangeRequestCreditDTO) => x.maxHours ?? 0).reduce((a: number, b: number) => a + b) ?? 0;

                      let minLabel = min;
                      const maxLabel = max;

                      if (this.state.existingCourse && !variableHours) {
                        minLabel = minLabel + ' (' + this.state.minCredits + ' Required)';
                      }

                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}></Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            <Typography.Text strong={true} type="danger">{minLabel}</Typography.Text>
                          </Table.Summary.Cell>
                          {
                            variableHours ?
                              <Table.Summary.Cell index={1}>
                                <Typography.Text strong={true} type="danger">{maxLabel}</Typography.Text>
                              </Table.Summary.Cell> :
                              null
                          }
                        </Table.Summary.Row>
                      );
                    }} columns={this.getCreditColumns()} pagination={false} />
                  </Form.Item>
                );
              }}
            </Form.List>

            <Typography.Title level={5}>
              Prerequisites/Co-Requisites
            </Typography.Title>

            <Space direction='vertical'>
            {this.props.readonly ? null :<Button onClick={this.addCourseRequisite}>Add Course</Button>}
              <Form.List name={NewCourseChangeRequestDTO.newCourseChangeRequestCourseRequisites}>
                {(newCourseChangeRequestCourseRequisite) => {
                  return (<Table dataSource={newCourseChangeRequestCourseRequisite} columns={this.getNewCourseCourseRequisiteColumns()} pagination={false} />)
                }}
              </ Form.List>

            </ Space>
            {/* } */}

            {this.renderActions()}
          </Space >
        </Form >
      </Space >
    );
  }

  renderInstitutionCourseExistsModal() {
    if (this.state.showInstitutionCourseModal)
      return (
        <Modal
          title='Existing Course'
          visible={this.state.showInstitutionCourseModal}
          footer={null}
          onCancel={this.hideInstitutionCourseModal}>
          <Typography.Paragraph>
            This course already exists at your institution. Please complete a Course Modification to apply any changes.
          </Typography.Paragraph>
          <Button icon={<RightOutlined />} onClick={this.goToCourseModification}>Create Course Modification Change Request</Button>
        </Modal>
      );
  }

  renderInstitutionCourseExists() {
    if (this.state.institutionExistingCourse) {
      return (
        <Alert type='error'
          showIcon={true}
          message='Existing Course'
          description={
            <div>
              <Typography.Paragraph>
                This course already exists at your institution. Please complete a Course Modification to apply any changes.
              </Typography.Paragraph>
              <Button icon={<RightOutlined />} onClick={this.goToCourseModification}>Create Course Modification Change Request</Button>
            </div>
          } />
      )
    }
  }

  renderNewCourseChangeRequestExistsModal() {
    if (this.state.showNewCourseChangeRequest)
      return (
        <Modal
          title='Course Reserved'
          visible={this.state.showInstitutionCourseModal}
          footer={null}
          onCancel={this.hideInstitutionCourseModal}>
          <Typography.Paragraph>
            This discipline and catalog number combination is already reserved in a pending new course request. Please contact the state to learn more.
          </Typography.Paragraph>
        </Modal>
      );
  }

  renderNewCourseChangeRequestExists() {
    if (this.state.existingNewCourseChangeRequest) {
      return (
        <Alert type='error'
          showIcon={true}
          message='Course Reserved'
          description={
            <div>
              <Typography.Paragraph>
                This discipline and catalog number combination is already reserved in a pending new course request. Please contact the state to learn more.
              </Typography.Paragraph>
            </div>
          } />
      )
    }
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveAndContinueButton disabled={this.state.institutionExistingCourse || this.state.existingNewCourseChangeRequest} submitting={this.state.submitting} />
          <SaveButton disabled={this.state.institutionExistingCourse || this.state.existingNewCourseChangeRequest} type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  renderCategoryOption(category: CategoryDTO): any {
    if (category.id) {
      return <Select.Option key={category.id} value={category.id}>{category.code} - {category.name}</Select.Option>;
    }
  }

  renderDisciplineOption(discipline: DisciplineDTO): any {
    if (discipline.id) {
      return <Select.Option key={discipline.id} value={discipline.id}>{discipline.code} - {discipline.name}</Select.Option>;
    }
  }

  renderCourseRequisiteType(courseRequisiteType: CourseRequisiteTypeDTO): any {
    if (courseRequisiteType.id) {
      return <Select.Option key={courseRequisiteType.id} value={courseRequisiteType.id} >{courseRequisiteType.name}</Select.Option>;
    }
  }

  renderCourseRequisiteTypes() {
    return this.state.courseRequisiteTypes.map(x => { return this.renderCourseRequisiteType(x) });
  }
}

export default NewCourseChangeRequestStep1Form