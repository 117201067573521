import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChangeRequestSubmitted from '../../../../components/displays/postSecondary/ChangeRequestSubmitted';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import ChangeRequestDTO from '../../../../models/ChangeRequestDTO';
import UserSecurity from '../../../../models/UserSecurity';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import Guid from '../../../../utils/Guid';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface AdministrativeChangeRequestReviewPageState extends BaseFormState {
  changeRequestId: string;
  changeRequest: ChangeRequestDTO;
  progress: number;
}

interface AdministrativeChangeRequestReviewPageProps {
  currentUser: UserSecurity | null;
}

class AdministrativeChangeRequestSubmittedPage extends React.Component<AdministrativeChangeRequestReviewPageProps & RouteComponentProps<RouteObject>, AdministrativeChangeRequestReviewPageState> {
  constructor(props: AdministrativeChangeRequestReviewPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequestId: Guid.Empty(),
      changeRequest: ChangeRequestDTO.create(),
      progress: 0
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id !== Guid.Empty() && this.state.changeRequestId != id) {
      this.setState({ changeRequestId: id });
    }
  }

  render() {
    return (
      <Content >
        <HeaderPortal
          breadcrumbs={Breadcrumbs.changeRequestList()} >
        </HeaderPortal>

        <Card >
          <ChangeRequestSubmitted />
        </Card>
      </Content >
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
  };
}

export default withRouter(connect(mapStateToProps)(AdministrativeChangeRequestSubmittedPage));