// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ServiceAreaDTO extends EntitySelectorDTO { 
	id: string | null;
	serviceAreaIdentifier: number;
	academicYear: number;
	description: string | null;
	display: string | null;
}

const ServiceAreaDTO = {
    className: 'ServiceAreaDTO',
    create: (initValues?: Partial<ServiceAreaDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			serviceAreaIdentifier: 0,
			academicYear: 0,
			description: "",
			display: "",
        },
        initValues);
    },
	id: 'id',
	serviceAreaIdentifier: 'serviceAreaIdentifier',
	academicYear: 'academicYear',
	description: 'description',
	display: 'display',
};

export default ServiceAreaDTO;