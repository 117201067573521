import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import UsersApiService from '../../api/UsersApiService';
import FeatureFlag from '../../consts/FeatureFlag';
import * as AddUserHandler from '../../handlerModels/AddUserHandler';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import FormUtil from '../../utils/FormUtil';
import Guid from '../../utils/Guid';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import AuthorizedContent from '../AuthorizedContent';
import SaveButton from '../buttons/SaveButton';

interface AddUserFormState extends BaseFormState {
}

interface AddUserFormProps extends BaseFormProps {
  onSuccess?: () => void;
  institutionId?: string;
}

class AddUserForm extends React.Component<AddUserFormProps, AddUserFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(AddUserHandler.Request.firstName, {
      required: true,
      name: AddUserHandler.Request.firstName,
      label: 'First Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ],
    })
    .set(AddUserHandler.Request.lastName, {
      required: true,
      name: AddUserHandler.Request.lastName,
      label: 'Last Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    })
    .set(AddUserHandler.Request.email, {
      required: true,
      name: AddUserHandler.Request.email,
      label: 'Email',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    })
    .set(AddUserHandler.Request.phoneNumber, {
      required: false,
      name: AddUserHandler.Request.phoneNumber,
      label: 'Phone Number',
      rules: []
    });

  constructor(props: AddUserFormProps) {
    super(props);

    this.state = {
    };
  }

  public submit = () => {
    this.handleSubmit();
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = AddUserHandler.Request.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    request.institutionId = this.props.institutionId ?? Guid.Empty();

    UsersApiService.addUser(request)
      .then((result: AddUserHandler.Result) => {
        this.setState({
          submitted: true
        });

        if (result?.succeeded) {
          message.success('Added!');

          if (this.props.onSuccess) {
            this.props.onSuccess();
          }

          this._formRef.current?.resetFields();

          this.setState({ altered: false });

          if (this.props.onSuccess) {
            this.props.onSuccess();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Error Adding User');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Adding User');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>

          <FormItem
            {...this._formItems.get(AddUserHandler.Request.firstName)}
            {...ValidationUtil.getValidation(AddUserHandler.Request.firstName, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(AddUserHandler.Request.lastName)}
            {...ValidationUtil.getValidation(AddUserHandler.Request.lastName, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(AddUserHandler.Request.email)}
            {...ValidationUtil.getValidation(AddUserHandler.Request.email, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(AddUserHandler.Request.phoneNumber)}
            {...ValidationUtil.getValidation(AddUserHandler.Request.phoneNumber, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space >
    );
  }

  renderSave() {
    return (
      <AuthorizedContent validFeatureFlags={this.props.isNew ? [FeatureFlag.EDIT_CATEGORY] : [FeatureFlag.EDIT_CATEGORY]} >
        <Space direction={'horizontal'} >
          <SaveButton htmlType='submit' disabled={!FormUtil.canSubmit(this._formRef, this._formItems)} saving={this.state.submitting} hidden={true} />
        </Space>
      </AuthorizedContent>
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AddUserForm;
