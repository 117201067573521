import { DownloadOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import * as React from 'react';

interface DownloadButtonState { }

interface DownloadButtonProps extends ButtonProps {
  downloading?: boolean;
}

class DownloadButton extends React.Component<DownloadButtonProps, DownloadButtonState> {
  constructor(props: DownloadButtonProps) {
    super(props);

    this.state = {};
  }

  render() {
    const htmlType = this.props.htmlType ? this.props.htmlType : 'submit'
    const buttonType = this.props.type ? this.props.type : 'primary'

    return (
      <Button
        {...this.props}
        htmlType={htmlType}
        type={buttonType}
        icon={<DownloadOutlined />}
        loading={this.props.downloading || this.props.loading} >
        {this.props.title ?? 'Download'}
      </Button>
    );
  }
}

export default DownloadButton;
