import * as React from 'react';
import InstitutionApiService from '../../../api/InstitutionsApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import InstitutionDTO from '../../../models/InstitutionDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface InstitutionsDataTableProps {
}

interface InstitutionsDataTableState extends BaseDataTableState<InstitutionDTO> {
}

class InstitutionsDataTable extends React.Component<InstitutionsDataTableProps, InstitutionsDataTableState> {
  private _dataTable: DataTable<InstitutionDTO> | undefined;

  constructor(props: InstitutionsDataTableProps) {
    super(props);
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Code',
        dataIndex: InstitutionDTO.code,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Abbreviation',
        dataIndex: InstitutionDTO.abbreviation,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Name',
        dataIndex: InstitutionDTO.name,
        sorter: true,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];
  };

  private openInstitution = (record: InstitutionDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.INSTITUTION_DETAILS, { id: record.id }, {}));
    }
  }

  private addInstitution = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_INSTITUTION));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_INSTITUTION])) {
      actionButtons.push(DataTableButtonUtil.Default('New Institution', () => this.addInstitution()));
    }

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openInstitution}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => InstitutionApiService.getInstitutionTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'institution_list' }} />
    );
  }
}

export default InstitutionsDataTable;
