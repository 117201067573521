import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Form, FormInstance, message, Modal, Skeleton, Space, Typography } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import NoticeOfIntentChangeRequestApiService from '../../../api/NoticeOfIntentChangeRequestApiService';
import Routes from '../../../config/Routes';
import * as GetNoticeOfIntentChangeRequestsHandler from '../../../handlerModels/GetNoticeOfIntentChangeRequestsHandler';
import * as SaveNewProgramChangeRequestStep1Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep1Handler';
import * as SubmitNewProgramChangeRequestStep1Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep1Handler';
import * as GetNewProgramChangeRequestStep1Handler from '../../../handlerModels/GetNewProgramChangeRequestStep1Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import ExternalInstitutionDTO from '../../../models/ExternalInstitutionDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import NoticeOfIntentOptionDTO from '../../../models/NoticeOfIntentOptionDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import CheckboxInputGroup, { CheckboxInputGroupOption } from '../../CheckboxInputGroup';
import Dropdown from '../../inputs/Dropdown';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep1FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  newProgram: NewProgramChangeRequestDTO;
  noticeOfIntents: NoticeOfIntentOptionDTO[] | null;
  externalInstitutions: ExternalInstitutionDTO[];
}

interface NewProgramChangeRequestStep1FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: (id: string) => void;
  onSubmit?: (id: string) => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep1Form extends React.Component<NewProgramChangeRequestStep1FormProps, NewProgramChangeRequestStep1FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: NewProgramChangeRequestStep1FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      newProgram: NewProgramChangeRequestDTO.create(),
      noticeOfIntents: null,
      externalInstitutions: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }


  componentDidUpdate(prevProps: NewProgramChangeRequestStep1FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId !== prevProps.changeRequestId) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders: any[] = [];

    if (this.props.selectedInstitution != Guid.Empty()) {
      loaders.push(this.loadNoticeOfIntents());
    }

    loaders.push(this.loadExternalInstitutions());

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest())
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private loadNoticeOfIntents = () => {
    const request = GetNoticeOfIntentChangeRequestsHandler.Request.create({
      changeRequestId: this.props.changeRequestId ?? Guid.Empty(),
      instsitutionId: this.props?.selectedInstitution
    })

    return NoticeOfIntentChangeRequestApiService.getAllNoticeOfIntents(request)
      .then((results: GetNoticeOfIntentChangeRequestsHandler.Result) => {
        if (results) {
          this.setState({ noticeOfIntents: results.noticeOfIntents ?? [] })
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
  }

  private loadExternalInstitutions = () => {
    return LookupsUtil.getAll<ExternalInstitutionDTO>(ExternalInstitutionDTO.className)
      .then((results: ExternalInstitutionDTO[]) => {
        if (results) {
          this.setState({ externalInstitutions: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep1(request)
      .then((results: GetNewProgramChangeRequestStep1Handler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            newProgram: results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const model = this._formRef.current?.getFieldsValue() ?? NewProgramChangeRequestDTO.create();

    const request = SaveNewProgramChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      institutionId: this.props.selectedInstitution,
      newProgramChangeRequest: model
    });

    NewProgramChangeRequestApiService.saveStep1(request)
      .then((result: SaveNewProgramChangeRequestStep1Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            if (result.changeRequest) {
              this.props.onSave(result.changeRequest.id ?? Guid.Empty());
            }
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = this._formRef.current?.getFieldsValue() ?? NewProgramChangeRequestDTO.create();

    const request = SubmitNewProgramChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      institutionId: this.props.selectedInstitution,
      newProgramChangeRequest: model
    });

    NewProgramChangeRequestApiService.submitStep1(request)
      .then((result: SubmitNewProgramChangeRequestStep1Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            if (result.changeRequest) {
              this.props.onSave(result.changeRequest.id ?? Guid.Empty());
            }
          }

          if (this.props.onSubmit) {
            if (result.changeRequest) {
              this.props.onSubmit(result.changeRequest.id ?? Guid.Empty());
            }
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private promptExpiredNoticeOfIntent = () => {
    Modal.confirm({
      title: 'Expired',
      content: 'The Notice of Intent you have selected is expired. Additional steps may be required for this program approval.',
      okType: 'primary',
      icon: <InfoCircleOutlined />,
      onCancel: () => {
        this.resetForm()
      }
    });
  }

  private promptNoticeOfintentNotApproved = () => {
    Modal.confirm({
      title: 'NOI Approval Needed',
      content: 'The Notice of Intent you have selected has not been approved. Please wait for the NOI to be approved before submiiting.',
      okType: 'primary',
      icon: <InfoCircleOutlined />,
      onCancel: () => {
        this.resetForm()
      }
    });
  }

  private handleCheckboxChange = (noiId: string) => {
    const noi = this.state.noticeOfIntents?.find(x => x.noticeOfIntentChangeRequestId == noiId);
    if (noi?.expired) {
      this.promptExpiredNoticeOfIntent();
    }

    if (!noi?.approved) {
      this.promptNoticeOfintentNotApproved();
    }

    this.handleChange();
  }

  private setCheckBoxOptions = () => {
    return this.state.noticeOfIntents?.map(x => {
      return { id: x.noticeOfIntentChangeRequestId, display: x.display }
    }) as CheckboxInputGroupOption[];
  }

  private goToNOI = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY));
  }

  private getFormItems = () => {
    const isTransferMajorProgram = this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.isTransferMajorProgram) ?? false;

    return new Map<string, FormItemProps>()
      .set(NewProgramChangeRequestDTO.isTransferMajorProgram, {
        name: NewProgramChangeRequestDTO.isTransferMajorProgram,
        label: 'Is this request for a transfer major program?',
        required: true,
        rules: [ValidationRuleUtil.required()],
      })
      .set(NewProgramChangeRequestDTO.noticeOfIntentChangeRequestId, {
        name: NewProgramChangeRequestDTO.noticeOfIntentChangeRequestId,
        label: 'Please select the notice of intent for this request. If you do not see the Notice of Intent you are looking for, please make sure it has been submitted.',
        required: isTransferMajorProgram,
        rules: isTransferMajorProgram ? [] : [ValidationRuleUtil.required()],
      })
      .set(NewProgramChangeRequestDTO.externalInstitutionIds, {
        name: NewProgramChangeRequestDTO.externalInstitutionIds,
        label: 'Which regents are associated with this program?',
        required: isTransferMajorProgram,
        rules: isTransferMajorProgram ? [ValidationRuleUtil.required()] : [],
      });
  }

  render() {
    if (this.state.loading && this.props.changeRequestId && this.state.noticeOfIntents != null) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.newProgram ?? NewProgramChangeRequestDTO.create()}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <Form.Item
            {...formItems.get(NewProgramChangeRequestDTO.isTransferMajorProgram)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.isTransferMajorProgram, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <YesNoInput disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} />
          </Form.Item>

          {this.renderNonTransferMajor(formItems)}
          {this.renderTransferMajor(formItems)}
        </Form>

        {this.renderActions()}
      </Space >
    );
  }

  private renderNonTransferMajor(formItems: Map<string, FormItemProps>) {
    const isTransferMajorProgram = this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.isTransferMajorProgram) ?? this.state.newProgram?.isTransferMajorProgram;
    if (isTransferMajorProgram != null && isTransferMajorProgram != undefined && !isTransferMajorProgram) {
      const checkBoxOptions = this.setCheckBoxOptions() ?? [];

      if (checkBoxOptions.length == 0) {
        return (
          <Space size="small" direction="vertical">
            {this.renderErrors()}
            <Typography.Paragraph italic={true}>There are currently no active Notices of Intent for your institutuion. A Notice of Intent is required for all new programs. Please create and submit a Notice of Intent before proceeding with your requeest.</Typography.Paragraph>
            <Button type='primary' onClick={this.goToNOI}>Create Notice of Intent</Button>
          </Space >
        );
      }

      return (
        <FormItem
          {...formItems.get(NewProgramChangeRequestDTO.noticeOfIntentChangeRequestId)}
          {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.noticeOfIntentChangeRequestId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
          <CheckboxInputGroup
            disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting}
            options={checkBoxOptions}
            onChange={this.handleCheckboxChange} />
        </FormItem>
      );
    }
  }

  private renderTransferMajor(formItems: Map<string, FormItemProps>) {
    const isTransferMajorProgram = this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.isTransferMajorProgram) ?? this.state.newProgram?.isTransferMajorProgram ?? false;

    if (isTransferMajorProgram) {
      return <FormItem
        {...formItems.get(NewProgramChangeRequestDTO.externalInstitutionIds)}
        {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.noticeOfIntentChangeRequestId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
        <Dropdown
          mode="multiple"
          disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting}
          options={this.state.externalInstitutions.map(x => { return { value: x.id, label: x.name } })} />
      </FormItem>
    }
  }

  private renderActions() {
    const isTransferMajorProgram = this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.isTransferMajorProgram) ?? this.state.newProgram?.isTransferMajorProgram ?? false;

    if (!this.props.readonly) {
      if (isTransferMajorProgram || (this.state.noticeOfIntents?.length ?? 0 > 0)) {
        return < Space direction='horizontal' wrap={true} >
          <SaveAndContinueButton submitting={this.state.submitting} onClick={this.handleSubmit} />
          <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved}></SaveButton>
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space >
      }
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NewProgramChangeRequestStep1Form