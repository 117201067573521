// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetAcademicYearHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import AcademicYearDTO from '../models/AcademicYearDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYearId: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYearId: 0,
        },
        initValues);
    },
    academicYearId: 'academicYearId',
}

interface Result extends ResultDTO {
    academicYear: AcademicYearDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            academicYear: null,
        },
        initValues);
    },
    academicYear: 'academicYear',
}

export { Request, Result };