import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal } from 'antd';
import * as React from 'react';
import ContactApiService from '../../../api/ContactApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as RemoveContactHandler from '../../../handlerModels/RemoveContactHandler';
import ContactDTO from '../../../models/ContactDTO';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import AddContactForm from '../../forms/postSecondary/AddContactForm';
import FileDownloadLink from '../../general/FileDownloadLink';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface ContactDataTableProps {
  institutionId: string;
}

interface ContactDataTableState extends BaseFormState {
  contactTypes: TableFilterOption[];
  showModal: boolean;
  contact: ContactDTO;
  contacts: ContactDTO[];
  institutions: TableFilterOption[];
}

class ContactsDataTable extends React.Component<ContactDataTableProps, ContactDataTableState> {
  private _dataTable: DataTable<ContactDTO> | undefined;
  private readonly _addContactFormRef = React.createRef<AddContactForm>();

  constructor(props: ContactDataTableProps) {
    super(props);

    this.state = {
      contactTypes: [],
      contact: ContactDTO.create({ contactTypeId: '' }),
      contacts: [],
      institutions: [],
      showModal: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    TableFilterUtil.ContactTypes()
      .then(x => {
        this.setState({ contactTypes: x })
      });

    TableFilterUtil.Institutions()
      .then(x => {
        this.setState({ institutions: x })
      });
  }

  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }

  private getColumnDefinitions = () => {

    const contactColumns = [
      {
        title: 'First Name',
        dataIndex: ContactDTO.firstName,
        sorter: true,
        width: 200,
        filterType: FilterType.Text,
        ellipsis: true
      },
      {
        title: 'Last Name',
        dataIndex: ContactDTO.lastName,
        sorter: true,
        width: 200,
        filterType: FilterType.Text,
        ellipsis: true
      },
      {
        title: 'Title',
        dataIndex: ContactDTO.title,
        sorter: true,
        width: 200,
        filterType: FilterType.Text,
        ellipsis: true
      },
      {
        title: 'Email',
        dataIndex: ContactDTO.email,
        sorter: true,
        width: 200,
        filterType: FilterType.Text,
        ellipsis: true
      },
      {
        title: 'Phone',
        dataIndex: ContactDTO.phone,
        sorter: true,
        width: 100,
        filterType: FilterType.Text,
        ellipsis: true
      }

    ] as DataTableColumnProps<any>[];

    if (this.props.institutionId && this.props.institutionId == Guid.Empty()) {
      contactColumns.push({
        title: 'Community College',
        dataIndex: ContactDTO.institutionId,
        render: (data: string, row: ContactDTO) => {
          return row.institution?.name
        },
        sorter: true,
        width: 100,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.institutions,
        ellipsis: true
      })
    }

    contactColumns.push(
      {
        title: 'Contact Type',
        dataIndex: ContactDTO.contactTypeId,
        render: (data: string, row: ContactDTO) => {
          return this.state.contactTypes.find(x => x.value == row.contactTypeId)?.text;
        },
        sorter: true,
        width: 125,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.contactTypes,
        ellipsis: true
      },
      {
        title: 'Signature',
        dataIndex: ContactDTO.signatureAzureBlobFileId,
        sorter: true,
        width: 100,
        filterType: FilterType.Text,
        ellipsis: true,
        render: (data: string, row: ContactDTO) => {
          return row.signatureAzureBlobFileId ?
            <FileDownloadLink key={row.signatureAzureBlobFileId} azureBlobFileId={row.signatureAzureBlobFileId ?? Guid.Empty()} text={row.signatureAzureBlobFile?.fileName} /> :
            null;
        }
      },
      {
        title: '',
        dataIndex: ContactDTO.id,
        width: 50,
        render: (data: string, row: ContactDTO) => {
          if (row.contactTypeId == 0) {
            row.contactTypeId = null;
          }
          return AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CONTACT]) ?
            <Input.Group compact>
              {<Button type='link' onClick={() => this.addContact(row)} size='small'><EditOutlined /></Button>}
              {<Button type='link' size='small' onClick={() => this.promptConfirmRemove(row.id ?? Guid.Empty())}><DeleteOutlined /></Button>}
            </Input.Group>
            : null;
        },
        sorter: false,
        filterType: FilterType.NONE
      });

    return contactColumns;
  };

  private addContact = (contact: ContactDTO) => {
    this.setState({ contact: contact }, () => this.openModal());
  }

  private openModal = () => {
    this.setState({ showModal: true });
  }

  private submitContactChange = () => {
    this._addContactFormRef.current?.submit();
  }

  private cancelContactChange = () => {
    this.setState({ showModal: false });
    this._addContactFormRef.current?.reset();
  }

  private contactAdded = () => {
    this.setState({ showModal: false });
    this._dataTable?.refresh();
  }

  private promptConfirmRemove = (contactId: string | null) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this contact?',
      okText: 'Remove Contact',
      onOk: () => this.handlerRemoveContact(contactId)
    });
  }

  private handlerRemoveContact = (contactId: string | null) => {
    this.setState({ submitting: true });

    const request = RemoveContactHandler.Request.create({
      contactId: contactId ?? Guid.Empty()
    });

    ContactApiService.removeContact(request)
      .then((result: RemoveContactHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Contact was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Contact could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.triggerRefresh();
      });
  }

  renderAddContactModal() {
    return (
      <Modal
        visible={this.state.showModal}
        title='Contact'
        okText='Save'
        onOk={this.submitContactChange}
        onCancel={this.cancelContactChange}>
        <AddContactForm
          ref={this._addContactFormRef}
          onSuccess={this.contactAdded}
          contact={this.state.contact}
          institutionId={this.props.institutionId ?? Guid.Empty()} />
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this.renderAddContactModal()}
        {this.renderDataTable()}
      </div>
    );
  }

  renderDataTable() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CONTACT])) {
      actionButtons.push(DataTableButtonUtil.Default('New Contact', () => this.addContact(ContactDTO.create())));
    }

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        data={this.state.contacts}
        serverSide={true}
        tableProps={{ rowKey: 'id', scroll: { x: 1750 } }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => ContactApiService.getContactTableData(requestState, this.props.institutionId)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'contact_list' }} />
    );
  }
}

export default ContactsDataTable;
