// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudyResponseDTO from './SelfStudyResponseDTO';
import InstitutionDTO from './InstitutionDTO';
import SecondaryProgramScenarioDTO from './SecondaryProgramScenarioDTO';
import SelfStudyResponseProgramOverviewThirdPartyCredentialDTO from './SelfStudyResponseProgramOverviewThirdPartyCredentialDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudyResponseProgramOverviewDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudyResponseId: string | null;
	selfStudyResponse: SelfStudyResponseDTO | null;
	regionaPlanningPartnerIdentifier: number;
	communityCollegeId: string | null;
	communityCollege: InstitutionDTO | null;
	districtIdentifier: number;
	secondaryCipNumberIdentifier: number;
	secondaryProgramScenarioId: string | null;
	secondaryProgramScenario: SecondaryProgramScenarioDTO | null;
	thirdPartyCredentials: SelfStudyResponseProgramOverviewThirdPartyCredentialDTO[] | null;
}

const SelfStudyResponseProgramOverviewDTO = {
    className: 'SelfStudyResponseProgramOverviewDTO',
    create: (initValues?: Partial<SelfStudyResponseProgramOverviewDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudyResponseId: '00000000-0000-0000-0000-000000000000',
			selfStudyResponse: null,
			regionaPlanningPartnerIdentifier: 0,
			communityCollegeId: '00000000-0000-0000-0000-000000000000',
			communityCollege: null,
			districtIdentifier: 0,
			secondaryCipNumberIdentifier: 0,
			secondaryProgramScenarioId: '00000000-0000-0000-0000-000000000000',
			secondaryProgramScenario: null,
			thirdPartyCredentials: [],
        },
        initValues);
    },
	id: 'id',
	selfStudyResponseId: 'selfStudyResponseId',
	selfStudyResponse: 'selfStudyResponse',
	regionaPlanningPartnerIdentifier: 'regionaPlanningPartnerIdentifier',
	communityCollegeId: 'communityCollegeId',
	communityCollege: 'communityCollege',
	districtIdentifier: 'districtIdentifier',
	secondaryCipNumberIdentifier: 'secondaryCipNumberIdentifier',
	secondaryProgramScenarioId: 'secondaryProgramScenarioId',
	secondaryProgramScenario: 'secondaryProgramScenario',
	thirdPartyCredentials: 'thirdPartyCredentials',
};

export default SelfStudyResponseProgramOverviewDTO;