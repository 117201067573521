import * as React from 'react';
import InstructionalLevelApiService from '../../../api/InstructionalLevelApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import InstructionalLevelDataTableDTO from '../../../models/InstructionalLevelDataTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface InstructionalLevelDataTableProps extends BaseFormProps {
}

interface InstructionalLevelDataTableState extends BaseDataTableState<InstructionalLevelDataTableDTO>, BaseFormState {
}

class InstructionalLevelDataTable extends React.Component<InstructionalLevelDataTableProps, InstructionalLevelDataTableState> {
  private _dataTable: DataTable<InstructionalLevelDataTableDTO> | undefined;

  constructor(props: InstructionalLevelDataTableProps) {
    super(props);
    this.state = {
    };

  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Code',
        dataIndex: InstructionalLevelDataTableDTO.code,
        sorter: true,
        filterType: FilterType.Text,
        width: 125
      },
      {
        title: 'Name',
        dataIndex: InstructionalLevelDataTableDTO.name,
        sorter: true,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];
  };

  private openInstructionalLevel = (record: InstructionalLevelDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.INSTRUCTIONAL_LEVEL_DETAILS, { id: record.id }, {}));
    }
  }

  private addInstructionalLevel = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_INSTRUCTIONAL_LEVEL));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return InstructionalLevelApiService.getInstructionalLevelDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Instructional_Level_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL])) {
      actionButtons.push(DataTableButtonUtil.Default('New Instructional Level', () => this.addInstructionalLevel()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openInstructionalLevel}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => InstructionalLevelApiService.getInstructionalLevelTableData(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'instructional_level_list' }} />
      </>
    );
  }
}

export default InstructionalLevelDataTable;
