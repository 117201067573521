import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, List, Menu, Space, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { default as InstituionCoursesApiService } from '../../../api/InstitutionCoursesApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import ChangeRequestDataTable from '../../../components/datatables/postSecondary/ChangeRequestsDataTable';
import CourseCourseBlocksDataTable from '../../../components/datatables/postSecondary/CourseCourseBlocksDataTable';
import CourseProgramsDataTable from '../../../components/datatables/postSecondary/CourseProgramsDataTable';
import CourseDetailsForm from '../../../components/forms/postSecondary/CourseDetailsForm';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetInstitutionCourseDetailHandler from '../../../handlerModels/GetInstitutionCourseDetailHandler';
import CourseDetailDTO from '../../../models/CourseDetailDTO';
import CourseDTO from '../../../models/CourseDTO';
import InstitutionCourseDetailDTO from '../../../models/InstitutionCourseDetailDTO';
import InstitutionCourseDTO from '../../../models/InstitutionCourseDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ParameterUtil from '../../../utils/ParameterUtil';

interface CourseDetailsState extends BaseFormState {
  activeTab: string;
  courseId: string;
  course: CourseDTO;
  selectedCourseDetail: CourseDetailDTO;
  courseDetails: CourseDetailDTO[];
  institutionId: string;
  selectedInstitutionCourseDetail: InstitutionCourseDetailDTO;
  selectedInstitutionCourseDetailId: string;
  institutionCourse: InstitutionCourseDTO;
  showModal: boolean;
  fromSecondaryCourses: string;
  academicYear: number;
}

interface CourseDetailsProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class CourseDetailsPage extends React.Component<CourseDetailsProps & RouteComponentProps<RouteObject>, CourseDetailsState> {
  private readonly _courseDetailsRef = React.createRef<CourseDetailsForm>();

  constructor(props: CourseDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      activeTab: 'course_details',
      courseId: Guid.Empty(),
      course: CourseDTO.create(),
      selectedCourseDetail: CourseDetailDTO.create(),
      courseDetails: [],
      selectedInstitutionCourseDetail: InstitutionCourseDetailDTO.create(),
      selectedInstitutionCourseDetailId: Guid.Empty(),
      institutionId: Guid.Empty(),
      institutionCourse: InstitutionCourseDTO.create(),
      showModal: false,
      fromSecondaryCourses: Guid.Empty(),
      academicYear: 0
    };
  }

  componentDidMount() {
    const institutionCourseId = ParameterUtil.getPathPart(this.props.match, 'institutionCourseId');
    const fromSecondaryCourses = ParameterUtil.getParameter('fromSecondaryCourses');
    const academicYear = ParameterUtil.getParameter('academicYear');
    const activeTab = ParameterUtil.getParameter('activeTab', 'course_details');
    this.setState({ activeTab: activeTab });
    this.setState({ fromSecondaryCourses: fromSecondaryCourses, academicYear: Number.parseInt(academicYear) });
    this.loadInstitutionCourse(institutionCourseId);
  }

  private loadInstitutionCourse = (institutionCourseId: string, institutionCourseDetailId?: string) => {
    this.setState({ loading: true });
    return InstituionCoursesApiService.getInstitutionCourseDetail(institutionCourseId)
      .then((results: GetInstitutionCourseDetailHandler.Result) => {
        if (results.institutionCourse?.course?.courseDetails) {
          const selected = this.state.selectedInstitutionCourseDetailId != Guid.Empty() && institutionCourseDetailId && institutionCourseDetailId != Guid.Empty() ? results.institutionCourse.institutionCourseDetails?.find(x => x.id == institutionCourseDetailId) : results.institutionCourse.currentDetail;
          let activeCourseDetail = results.institutionCourse.course.courseDetails.find(x => x.install?.termId == selected?.install?.termId && x.install?.year == selected?.install?.year);
          if (!activeCourseDetail) {
            activeCourseDetail = results.institutionCourse.course.courseDetails.find(x => (x.install?.year ?? 0) < (selected?.install?.year ?? 0) || (x.install?.year == selected?.install?.year && (x.install?.term?.startDate ?? 0) < (selected?.install?.term?.startDate ?? 0)))
          }

          this.setState({
            selectedInstitutionCourseDetailId: selected?.id ?? Guid.Empty(),
            selectedInstitutionCourseDetail: results.institutionCourse.currentDetail ?? InstitutionCourseDetailDTO.create(),
            institutionCourse: results.institutionCourse ?? InstitutionCourseDTO.create(),
            selectedCourseDetail: activeCourseDetail ?? (results.institutionCourse.course.currentDetail ?? CourseDetailDTO.create()),
            institutionId: results.institutionCourse.institutionId ?? Guid.Empty(),
            course: results.institutionCourse.course ?? CourseDTO.create(),
            courseId: results.institutionCourse.courseId ?? Guid.Empty()
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false })
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._courseDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private handleVersionSelect = (e: any) => {
    this.setState({
      selectedInstitutionCourseDetailId: e.key
    });
  }

  private courseDetailsSaved = (institutionCourseDetailId: string, institutionCourseId: string) => {
    this.setState({ isEditing: false });
    if (institutionCourseId != this.state.institutionCourse.id) {
      HistoryUtil.replace(Routes.generate(Routes.COURSE_DETAILS, { institutionCourseId: institutionCourseId }));
    }
    else {
      this.loadInstitutionCourse(institutionCourseId, institutionCourseDetailId);
    }
  }

  private goToCourses = () => {
    if (this.state.fromSecondaryCourses != Guid.Empty() && this.state.fromSecondaryCourses != '') {
      HistoryUtil.push(Routes.generate(Routes.SECONDARY_COURSE_DETAILS, { id: this.state.fromSecondaryCourses }, { academicYear: this.state.academicYear }));
    }
    else {
      HistoryUtil.goBack();
    }
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  private getDownloadList = () => {
    return [
      {
        title: 'Course Syllabus Report',
        description: 'File contains the course syllabus report.',
        action: () => this.courseSyllabusReport()
      },
    ];
  }

  private courseSyllabusReport = () => {
    window.open(Routes.generateFull(Routes.COURSE_SYLLABUS, { institutionCourseDetailId: this.state.selectedInstitutionCourseDetailId ?? Guid.Empty() }), '_blank')
  }

  render() {
    const institutionName = this.state.loading ? '...' : this.state.institutionCourse.institution?.name ?? '...';
    const title = this.state.loading ? '...' : this.state.isNew ? 'New Course' : this.state.selectedCourseDetail.title ?? '...';
    const courseBreadcrumb = this.state.loading ? '...' : this.state.isNew ? 'New Course' : this.state.course.display ?? '...';
    const selected = this.state.institutionCourse?.institutionCourseDetails?.filter(x => x.id == this.state.selectedInstitutionCourseDetailId)[0] ?? InstitutionCourseDetailDTO.create();
    const versionMenu = (
      <Menu onSelect={this.handleVersionSelect} selectable={true} selectedKeys={[this.state.selectedInstitutionCourseDetailId]}>
        {this.state.institutionCourse.institutionCourseDetails?.map(x => <Menu.Item key={x.id} >{x.versionDisplay}</Menu.Item>)}
      </Menu>
    );

    const versionsDropdown = (
      <Space direction='vertical'>
        <Dropdown overlay={versionMenu} >
          <Button icon={<DownOutlined />} >{selected ? selected.versionDisplay : 'Versions'}</Button>
        </Dropdown>
      </Space>
    );

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToCourses}
          breadcrumbs={this.state.fromSecondaryCourses != Guid.Empty() && this.state.fromSecondaryCourses != '' ? Breadcrumbs.courseDetailFromSecondaryCourses(courseBreadcrumb, this.state.courseId, this.state.institutionId, this.state.institutionCourse.id ?? Guid.Empty(), 'Course', this.state.fromSecondaryCourses, this.state.academicYear) : Breadcrumbs.courseDetails(courseBreadcrumb, this.state.courseId, this.state.institutionId, this.state.institutionCourse.id ?? Guid.Empty())}
          subTitle={institutionName}
          extra={versionsDropdown}
          footer={
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab} >
              <Tabs.TabPane tab="Details" key="course_details" />
              <Tabs.TabPane tab="Programs" key="programs" />
              <Tabs.TabPane tab="Course Blocks" key="course_blocks" />
              <Tabs.TabPane tab="Downloads" key="downloads" />
              {
                AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_CHANGE_REQUEST]) ?
                  <Tabs.TabPane tab="Change Requests" key="course_change_request" /> :
                  null
              }
            </Tabs>
          } />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard(selected)}
        {this.renderCoursePrograms()}
        {this.renderCourseCourseBlocks()}
        {this.renderChangeRequest()}
        {this.renderDownloads()}
      </Content >
    );
  }

  renderDetailsCard(selected: InstitutionCourseDetailDTO) {
    if (this.state.activeTab === 'course_details') {
      const editButton = this.state.selectedInstitutionCourseDetailId == Guid.Empty() ?
        null :
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_COURSE]}>
          <EditCancelButton onClick={this.toggleEdit} isEditing={this.state.isEditing} />
        </AuthorizedContent>;
      let activeCourseDetail = this.state.institutionCourse?.course?.courseDetails?.find(x => x.install?.termId == selected?.install?.termId && x.install?.year == selected?.install?.year);
      if (!activeCourseDetail) {
        activeCourseDetail = this.state.institutionCourse?.course?.courseDetails?.find(x => (x.install?.year ?? 0) < (selected?.install?.year ?? 0) || (x.install?.year == selected?.install?.year && (x.install?.term?.startDate ?? 0) < (selected?.install?.term?.startDate ?? 0)))
      }

      let readonly = false;
      if (!AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_COURSE])) {
        readonly = true;
      }

      return (
        <Card title="Details" extra={editButton} >
          <CourseDetailsForm
            ref={this._courseDetailsRef}
            course={this.state.course}
            courseId={this.state.courseId}
            isEditing={this.state.isEditing}
            isNew={this.state.isNew}
            onSave={this.courseDetailsSaved}
            selectedCourseDetail={activeCourseDetail ?? (this.state.institutionCourse?.course?.currentDetail ?? CourseDetailDTO.create())}
            selectedInstitutionCourseDetail={selected}
            selectedInstitutionCourse={this.state.institutionCourse}
            institutionId={this.state.institutionId}
            readonly={readonly} />
        </Card>
      );
    }
  }

  renderDownloads() {
    return (
      <Card title={'Downloads'} hidden={this.state.activeTab !== 'downloads'}>
        <List
          itemLayout="horizontal"
          dataSource={this.getDownloadList()}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Button type="link" onClick={item.action}><DownloadOutlined /></Button>}
                title={<Button type="link" onClick={item.action}>{item.title}</Button>}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </ Card>
    );
  }

  renderChangeRequest() {
    if (this.state.activeTab === 'course_change_request') {
      if (this.state.courseId != Guid.Empty()) {
        return (
          <Card title='Change Request'>
            <ChangeRequestDataTable instituionId={this.state.institutionId} courseId={this.state.courseId} />
          </Card>
        );
      }
    }
  }

  renderCoursePrograms() {
    if (this.state.activeTab === 'programs') {
      if (this.state.courseId != Guid.Empty()) {
        return (
          <Card title='Programs'>
            <CourseProgramsDataTable courseId={this.state.courseId} />
          </Card>
        );
      }
    }
  }

  renderCourseCourseBlocks() {
    if (this.state.activeTab === 'course_blocks') {
      if (this.state.courseId != Guid.Empty()) {
        return (
          <Card title='Course Blocks'>
            <CourseCourseBlocksDataTable courseId={this.state.courseId} />
          </Card>
        );
      }
    }
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(CourseDetailsPage));
