// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// ConsultantSearchHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import BaseDataTableRequest from './BaseDataTableRequest';

interface Request extends BaseDataTableRequest {
    cipNumberId: string | null | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(BaseDataTableRequest.create(),
        {
            cipNumberId: null,
            firstName: "",
            lastName: "",
            email: "",
        },
        initValues);
    },
    cipNumberId: 'cipNumberId',
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
}

export { Request };