// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import TypeOfProgramDataTableDTO from '../models/TypeOfProgramDataTableDTO';
import * as GetTypeOfProgramDetailsHandler from '../handlerModels/GetTypeOfProgramDetailsHandler';
import * as SaveTypeOfProgramDetailsHandler from '../handlerModels/SaveTypeOfProgramDetailsHandler';
import * as GetTypeOfProgramHandler from '../handlerModels/GetTypeOfProgramHandler';
import * as DeleteTypeOfProgramHandler from '../handlerModels/DeleteTypeOfProgramHandler';

export class TypeOfProgramApiService extends BaseApi {

    // post: api/typeOfProgram
    public getTypeOfProgramTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<TypeOfProgramDataTableDTO>> {
        let url = `api/typeOfProgram`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<TypeOfProgramDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/typeOfProgram/export
    public getTypeOfProgramDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/typeOfProgram/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/typeOfProgram/${id}
    public getTypeOfProgramDetails(id: number): Promise<GetTypeOfProgramDetailsHandler.Result> {
        let url = `api/typeOfProgram/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetTypeOfProgramDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/typeOfProgram/save
    public saveTypeOfProgramDetails(saveTypeOfProgramDetails: SaveTypeOfProgramDetailsHandler.Request): Promise<SaveTypeOfProgramDetailsHandler.Result> {
        let url = `api/typeOfProgram/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveTypeOfProgramDetailsHandler.Result>(saveTypeOfProgramDetails, 'post', url, true, false);
    }

    // post: api/typeOfProgram/getTypeOfPrograms
    public getTypeOfPrograms(): Promise<GetTypeOfProgramHandler.Result> {
        let url = `api/typeOfProgram/getTypeOfPrograms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetTypeOfProgramHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/typeOfProgram/deleteTypeOfProgram
    public deleteTypeOfProgram(deleteTypeOfProgram: DeleteTypeOfProgramHandler.Request): Promise<DeleteTypeOfProgramHandler.Result> {
        let url = `api/typeOfProgram/deleteTypeOfProgram`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteTypeOfProgramHandler.Result>(deleteTypeOfProgram, 'post', url, true, false);
    }
}
var service = new TypeOfProgramApiService();
export default service;
