import { Alert, Input, InputNumber, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import IowaVariationApiService from '../../../api/IowaVariationApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetIowaVariationDetailsHandler from '../../../handlerModels/GetIowaVariationDetailsHandler';
import * as SaveIowaVariationDetailsHandler from '../../../handlerModels/SaveIowaVariationDetailsHandler';
import IowaVariationDTO from '../../../models/IowaVariationDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';

interface IowaVariationDetailsFormState extends BaseFormState {
  iowaVariationId: number;
  iowaVariation: IowaVariationDTO;
}

interface IowaVariationDetailsFormProps extends BaseFormProps {
  iowaVariationId: number;
  onSave?: (iowaVariationId: number) => void;
}

class IowaVariationDetailsForm extends React.Component<IowaVariationDetailsFormProps, IowaVariationDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(IowaVariationDTO.name, {
      required: true,
      name: IowaVariationDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    })
    .set(IowaVariationDTO.code, {
      required: true,
      name: IowaVariationDTO.code,
      label: 'Code',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(10)
      ],
    })
    .set(IowaVariationDTO.order, {
      required: true,
      name: IowaVariationDTO.order,
      label: 'Order',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(IowaVariationDTO.specialUse, {
      required: true,
      name: IowaVariationDTO.specialUse,
      label: 'For Special Use',
      rules: [
        ValidationRuleUtil.required(),
      ],
    });

  constructor(props: IowaVariationDetailsFormProps) {
    super(props);

    this.state = {
      iowaVariationId: 0,
      iowaVariation: IowaVariationDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadIowaVariation();
  }

  componentDidUpdate(prevProps: IowaVariationDetailsFormProps) {
    if (this.props.iowaVariationId && this.props.iowaVariationId != prevProps.iowaVariationId) {
      this.setState({ iowaVariationId: this.props.iowaVariationId });
      this.loadIowaVariation();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadIowaVariation() {
    if (this.props.iowaVariationId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.iowaVariationId) {
      IowaVariationApiService.getIowaVariationDetails(this.props.iowaVariationId)
        .then((results: GetIowaVariationDetailsHandler.Result) => {
          if (results && results.iowaVariation?.id) {
            this.setState({ iowaVariationId: results.iowaVariation.id, iowaVariation: results.iowaVariation ?? IowaVariationDTO.create });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ iowaVariation: IowaVariationDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveIowaVariationDetailsHandler.Request.create({
      iowaVariationId: this.state.iowaVariationId,
      iowaVariation: IowaVariationDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    IowaVariationApiService.saveIowaVariationDetails(request)
      .then((result: SaveIowaVariationDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          iowaVariation: result?.iowaVariation ?? IowaVariationDTO.create()
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.iowaVariation?.id) {
            this.props.onSave(result.iowaVariation.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(IowaVariationDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Iowa Variation could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Iowa Variation could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.iowaVariation}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(IowaVariationDTO.code)}
            {...ValidationUtil.getValidation(IowaVariationDTO.code, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(IowaVariationDTO.name)}
            {...ValidationUtil.getValidation(IowaVariationDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(IowaVariationDTO.specialUse)}
            {...ValidationUtil.getValidation(IowaVariationDTO.specialUse, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(IowaVariationDTO.order)}
            {...ValidationUtil.getValidation(IowaVariationDTO.order, this.state.fieldErrors, this.state.submitted)}>
            <InputNumber min={1} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_IOWA_VARIATION]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default IowaVariationDetailsForm;
