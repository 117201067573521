import { Skeleton } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { default as InstituionUsersApiService } from '../../../api/InstitutionUsersApiService';
import StatusType from '../../../consts/StatusType';
import * as GetInstitutionUserDetailsHandler from '../../../handlerModels/GetInstitutionUserDetailsHandler';
import InstitutionUserDTO from '../../../models/InstitutionUserDTO';
import StatusTypeDTO from '../../../models/StatusTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValueLabel from '../../general/ValueLabel';

interface InstitutionUserDetailsFormState extends BaseFormState {
  institutionUser: InstitutionUserDTO | null | undefined;
  statusTypes: StatusTypeDTO[];
}

interface InstitutionUserDetailsFormProps extends BaseFormProps {
  institutionId: string;
  userId: string;
}

class InstitutionUserDetailsForm extends React.Component<InstitutionUserDetailsFormProps, InstitutionUserDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: InstitutionUserDetailsFormProps) {
    super(props);

    this.state = {
      institutionUser: null,
      statusTypes: [],
      loading: true
    };
  }

  componentDidMount() {
    this.loadInstitutionUser();
  }

  componentDidUpdate(prevProps: InstitutionUserDetailsFormProps) {
    if (this.props.institutionId != Guid.Empty() && this.props.institutionId != prevProps.institutionId) {
      this.loadInstitutionUser();
    }
  }

  private loadInstitutionUser() {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.statusTypes.length == 0) {
      loaders.push(this.loadStatusTypes());
    }

    if (this.props.institutionId != Guid.Empty()) {
      loaders.push(this.loadExisting(this.props.userId, this.props.institutionId));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadExisting = (userId: string, institutionId: string) => {
    InstituionUsersApiService.getInstitutionUserDetails(userId, institutionId)
      .then((results: GetInstitutionUserDetailsHandler.Result) => {
        this.setState({ institutionUser: results.institutionUser });

      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadStatusTypes = () => {
    return LookupsUtil.getAll<StatusTypeDTO>(StatusTypeDTO.className)
      .then((results: StatusTypeDTO[]) => {
        if (results) {
          this.setState({ statusTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private _formItems = new Map<string, FormItemProps>()
    .set(InstitutionUserDTO.statusTypeId, {
      name: InstitutionUserDTO.statusTypeId,
      label: 'Status: '
    })
    .set(InstitutionUserDTO.createdOn, {
      name: InstitutionUserDTO.createdOn,
      label: 'Date Added:'
    })
    .set(InstitutionUserDTO.removedOn, {
      name: InstitutionUserDTO.removedOn,
      label: 'Date Removed'
    })

  private handleChange = () => {
    this.setState({ altered: true });
  }

  render() {
    if (this.state.loading || this.state.institutionUser == null || this.state.institutionUser == undefined) {
      return <Skeleton active={true} />;
    }

    return (
      <Form
        ref={this._formRef}
        layout="vertical"
        requiredMark={true}
        initialValues={this.state.institutionUser}>
        <FormItem {...this._formItems.get(InstitutionUserDTO.statusTypeId)}>
          <ValueLabel text={this.state.institutionUser?.statusType?.name} />
        </FormItem>

        <FormItem {...this._formItems.get(InstitutionUserDTO.createdOn)}>
          <ValueLabel text={DateTimeUtil.longDate(this.state.institutionUser?.createdOn)} />
        </FormItem>

        {this.renderRemovedDate()}
      </Form>
    );
  }

  renderRemovedDate() {
    if (this.state.institutionUser?.statusTypeId == StatusType.REMOVED && this.state.institutionUser.removedOn) {
      return (
        <FormItem {...this._formItems.get(InstitutionUserDTO.removedOn)}>
          <ValueLabel value={DateTimeUtil.dateAndTime(this.state.institutionUser.removedOn)} />
        </FormItem>
      );
    }
  }
}

export default InstitutionUserDetailsForm