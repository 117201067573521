import { Card, message, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { default as AnnouncementsApiService } from '../../api/AnnouncementsApiService';
import AuthorizedContent from '../../components/AuthorizedContent';
import AnnouncementDetailsForm from '../../components/forms/AnnouncementDetailsForm';
import EditCancelButton from '../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import * as GetAnnouncementDetailsHandler from '../../handlerModels/GetAnnouncementDetailsHandler';
import AnnouncementDTO from '../../models/AnnouncementDTO';
import HeaderPortal from '../../portals/HeaderPortal';
import BaseFormState from '../../redux/bases/BaseFormState';
import Guid from '../../utils/Guid';
import HistoryUtil from '../../utils/HistoryUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';
import ParameterUtil from '../../utils/ParameterUtil';

interface AnnouncementDetailsState extends BaseFormState {
  AnnouncementId: string;
  Announcement?: AnnouncementDTO;
}

interface AnnouncementDetailsProps {
}

class AnnouncementDetailsPage extends React.Component<AnnouncementDetailsProps & RouteComponentProps<RouteObject>, AnnouncementDetailsState> {
  private readonly _AnnouncementDetailsRef = React.createRef<AnnouncementDetailsForm>();

  constructor(props: AnnouncementDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      AnnouncementId: Guid.Empty(),
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id == 'new') {
      this.setState({ isEditing: true });
    }
    else {
      this.loadAnnouncement(id);
    }
  }

  private loadAnnouncement = (id: string) => {
    this.setState({ loading: true });
    AnnouncementsApiService.getAnnouncementDetails(id)
      .then((results: GetAnnouncementDetailsHandler.Result) => {
        if (results.announcement) {
          this.setState({
            AnnouncementId: id,
            Announcement: results.announcement
          });
        }
      }).catch(() => {
        this.setState({ error: true });
        message.error('Announcement could not be loaded')
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._AnnouncementDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private announcementDetailsSaved = (id: string) => {
    this.setState({ isEditing: false });
    if (id != this.state.AnnouncementId) {
      HistoryUtil.replace(Routes.generate(Routes.ANNOUNCEMENT_DETAILS, { id: id }));
    }
    else {
      this.loadAnnouncement(id);
    }
  }

  private goToAnnouncements = () => {
    HistoryUtil.push(Routes.generate(Routes.ANNOUNCEMENTS));
  }

  render() {
    const title = this.state.loading ? '...' : this.state.AnnouncementId == Guid.Empty() ? 'New Announcement' : this.state.Announcement?.title ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          pageTitle={title}
          onBack={this.goToAnnouncements}
          breadcrumbs={Breadcrumbs.announcementDetails(title, this.state.AnnouncementId)}
          loading={this.state.loading} />
        <PageStayPrompt when={this.state.isEditing} />

        <Space direction="vertical" size="middle">
          {this.renderDetailsCard()}
        </Space>
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.AnnouncementId == Guid.Empty() ?
      null :
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_ANNOUNCEMENT]}>
        <EditCancelButton onClick={this.toggleEdit} isEditing={this.state.isEditing} />
      </AuthorizedContent>

    return (
      <Card title="Details" extra={editButton} >
        <AnnouncementDetailsForm
          ref={this._AnnouncementDetailsRef}
          AnnouncementId={this.state.AnnouncementId}
          isEditing={this.state.isEditing}
          onSave={this.announcementDetailsSaved} />
      </Card>
    );
  }
}

export default withRouter(AnnouncementDetailsPage);
