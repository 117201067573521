// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionDTO from './InstitutionDTO';
import ProgramDetailDTO from './ProgramDetailDTO';
import ProgramAdvisoryBoardMemberDTO from './ProgramAdvisoryBoardMemberDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramDTO extends EntitySelectorDTO { 
	id: string | null;
	institutionId: string | null;
	institution: InstitutionDTO | null;
	programDetails: ProgramDetailDTO[] | null;
	programAdvisoryBoardMembers: ProgramAdvisoryBoardMemberDTO[] | null;
	currentDetail: ProgramDetailDTO | null;
}

const ProgramDTO = {
    className: 'ProgramDTO',
    create: (initValues?: Partial<ProgramDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			institutionId: '00000000-0000-0000-0000-000000000000',
			institution: null,
			programDetails: [],
			programAdvisoryBoardMembers: [],
			currentDetail: null,
        },
        initValues);
    },
	id: 'id',
	institutionId: 'institutionId',
	institution: 'institution',
	programDetails: 'programDetails',
	programAdvisoryBoardMembers: 'programAdvisoryBoardMembers',
	currentDetail: 'currentDetail',
};

export default ProgramDTO;