import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChangeRequestApiService from '../../../../api/ChangeRequestApiService';
import ChangeRequestLayout from '../../../../components/postSecondary/ChangeRequestLayout';
import ProgramModificationChangeRequestStep2Form from '../../../../components/forms/postSecondary/ProgramModificationChangeRequestStep2Form';
import Routes from '../../../../config/Routes';
import ChangeRequestType from '../../../../consts/ChangeRequestType';
import * as GetBaseChangeRequestDetailsHandler from '../../../../handlerModels/GetBaseChangeRequestDetailsHandler';
import ChangeRequestDTO from '../../../../models/ChangeRequestDTO';
import InstitutionDTO from '../../../../models/InstitutionDTO';
import UserSecurity from '../../../../models/UserSecurity';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface ProgramModificationChangeRequestStep2PageState extends BaseFormState {
  changeRequestId: string;
  changeRequest: ChangeRequestDTO;
}

interface ProgramModificationChangeRequestStep2PageProps {
  selectedInstitution: InstitutionDTO | null;
  currentUser: UserSecurity | null;
}

class ProgramModificationChangeRequestStep2Page extends React.Component<ProgramModificationChangeRequestStep2PageProps & RouteComponentProps<RouteObject>, ProgramModificationChangeRequestStep2PageState> {

  constructor(props: ProgramModificationChangeRequestStep2PageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequestId: Guid.Empty(),
      changeRequest: ChangeRequestDTO.create(),
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id == 'new') {
      this.setState({ isNew: true });
    }
    else if (id !== Guid.Empty() && this.state.changeRequestId != id) {
      this.setState({ changeRequestId: id });
      this.fetchData(id);
    }
  }

  private fetchData = (id: string) => {
    this.setState({ loading: true });

    const loaders = [];

    if (id !== Guid.Empty()) {
      loaders.push(this.loadChangeRequest(id));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadChangeRequest = (id: string) => {
    return ChangeRequestApiService.getBaseChangeRequestDetails(id)
      .then((results: GetBaseChangeRequestDetailsHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  goToPreviousStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_1, { id: this.state.changeRequestId }, {}))
  }


  private goToNextStep = () => {
    if (this.state.changeRequest.changeRequestTypeId == ChangeRequestType.TRANSFERMAJORPROGRAMMODIFICATION) {
      HistoryUtil.push(Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_6, { id: this.state.changeRequestId }));
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_5, { id: this.state.changeRequestId }));
    }
  }

  render() {
    return (
      <Content >
        <ChangeRequestLayout
          changeRequest={this.state.changeRequest ?? ChangeRequestDTO.create()}
          stepNumber={1}
          currentUser={this.props.currentUser ?? {} as UserSecurity}>
          <Card title="Award Details" >
            <ProgramModificationChangeRequestStep2Form
              changeRequestId={this.state.changeRequest.id}
              selectedInstitution={this.props.selectedInstitution?.id ?? Guid.Empty()}
              loading={this.state.loading}
              onPrevious={this.goToPreviousStep}
              onSubmit={this.goToNextStep}
            />
          </Card>
        </ChangeRequestLayout>
      </Content >
    );
  }
}

function mapDispatchToProps() {
  return {
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    selectedInstitution: state.Institution.Selected,
    currentUser: state.UserSession.Value
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgramModificationChangeRequestStep2Page));