import { Card, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SecondaryCourseApiService from '../../../api/SecondaryCourseApiService';
import ConfirmAndContinueButton from '../../../components/buttons/ConfirmAndContinueButton';
import PreviousButton from '../../../components/buttons/PreviousButton';
import SecondaryCoursesDataTable from '../../../components/datatables/secondary/SecondaryCoursesDataTable';
import DataReviewLayout from '../../../components/secondary/DataReviewLayout';
import SecondaryCoursesImporter from '../../../components/secondary/SecondaryCourseImporter';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as AcademicYearHasCoursesHandler from '../../../handlerModels/AcademicYearHasCoursesHandler';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';


interface SecondaryCourseListPageState {
  academicYear: number;
  hasCourses: boolean | undefined;
  errors: string[];
}

interface SecondaryCourseListPageProps {
}

class DataReviewSecondaryCourseListPage extends React.Component<SecondaryCourseListPageProps & RouteComponentProps<RouteObject>, SecondaryCourseListPageState> {

  constructor(props: SecondaryCourseListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0,
      hasCourses: undefined,
      errors: []
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });
      this.loadSecondaryCourses(id)
    }
  }

  private loadSecondaryCourses = (academicYear: number, force?: boolean) => {
    if (force || ((this.state.hasCourses == undefined || this.state.hasCourses == false) && academicYear > 0)) {
      SecondaryCourseApiService.academicYearHasCourses(academicYear)
        .then((results: AcademicYearHasCoursesHandler.Result) => {
          if (results) {
            this.setState({
              hasCourses: results.hasCourses
            });
          }
        });
    }
  }

  private onSubmit = (errors?: string[]) => {
    this.loadSecondaryCourses(this.state.academicYear);
    this.setState({ errors: errors ?? [] });
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SECONDARY_PROGRAMS, { academicYearId: this.state.academicYear }, {}));
  }

  private onContinue = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SECONDARY_PROGRAM_COURSES, { academicYearId: this.state.academicYear }, {}));
  }

  render() {
    const academicYear = this.state.academicYear;

    if (academicYear != 0) {
      return (
        <Content >
          <DataReviewLayout breadcrumbs={Breadcrumbs.academicYearDataReview(academicYear)} stepNumber={9} academicYear={academicYear}>
            <Card title="Courses">
              <Space direction='vertical' size="middle">
                {this.state.hasCourses ?
                  <SecondaryCoursesDataTable errors={this.state.errors} fromAcademicYearDataReview={true} academicYear={this.state.academicYear} /> :
                  <SecondaryCoursesImporter onSubmit={this.onSubmit} academicYear={this.state.academicYear} />
                }
                <Space>
                  <PreviousButton onClick={this.onBack} />
                  {this.state.hasCourses ? <ConfirmAndContinueButton onClick={this.onContinue} /> : null}
                </Space>
              </Space>
            </Card>
          </DataReviewLayout>
        </Content>
      );
    }

    return <Skeleton />;
  }
}

export default withRouter((DataReviewSecondaryCourseListPage));
