import { Checkbox, Space } from 'antd';
import * as React from 'react';
import RoleType from '../consts/RoleType';
import '../extensions/ArrayExtensions';
import RoleDTO from '../models/RoleDTO';
import BaseFormState from '../redux/bases/BaseFormState';
import Guid from '../utils/Guid';
import LookupsUtil from '../utils/LookupsUtil';

interface UserRolesEditorState extends BaseFormState {
  selectedIds: string[];
  roles: RoleDTO[];
}

interface UserRolesEditorProps {
  style?: React.CSSProperties;
  options?: RoleDTO[];
  selectedIds?: string[];
  disabled?: boolean;
  onChange?: (selectedIds: string[]) => void;
}

class UserRolesEditor extends React.Component<UserRolesEditorProps, UserRolesEditorState> {
  constructor(props: UserRolesEditorProps) {
    super(props);

    this.state = {
      selectedIds: [],
      roles: [],

    };
  }

  componentDidMount() {
    if (!this.props.options) {
      this.loadRoles();
    }
    else {
      this.setState({ roles: this.props.options });
    }

    this.setState({ selectedIds: JSON.parse(JSON.stringify(this.props.selectedIds)) ?? [] });
  }

  componentDidUpdate(prevProps: UserRolesEditorProps) {
    if (this.props.options && this.props.options != prevProps.options) {
      this.setState({ roles: this.props.options });
    }

    if (this.props.selectedIds != prevProps.selectedIds) {
      this.setState({ selectedIds: JSON.parse(JSON.stringify(this.props.selectedIds)) ?? [] });
    }
  }

  private loadRoles = () => {
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        if (results) {
          this.setState({ roles: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private onChange = (event: any, selectedId: string) => {
    const selectedIds = this.state.selectedIds;

    if (event.target.checked) {
      selectedIds.push(selectedId);
    }
    else {
      selectedIds.remove(selectedId);
    }

    this.setState({ selectedIds: selectedIds });

    if (this.props.onChange) {
      this.props.onChange(selectedIds);
    }
  }

  render() {
    return (
      <Space direction='horizontal' wrap={true} >
        {this.state.roles.map(r => this.renderRoles(r))}
      </Space>
    );
  }

  renderRoles(role: RoleDTO) {
    if (role.id && (role.roleTypeId == RoleType.STATE || role.roleTypeId == RoleType.CONSULTANT)) {
      return (
        <Checkbox checked={this.state.selectedIds.includes(role.id)} onChange={event => this.onChange(event, role?.id ?? Guid.Empty())} disabled={this.props.disabled} >
          {role.name}
        </Checkbox>
      );
    }

  }
}

export default UserRolesEditor;
