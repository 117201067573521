import { Col, Input, message, Row, Select, Skeleton, Space, Table, Tooltip, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionCoursesApiService from '../api/InstitutionCoursesApiService';
import { ColumnWidths } from '../config/ColumnWidths';
// import * as InstitutionCourseSearchWithRetiredHandler from '../handlerModels/InstitutionCourseSearchWithRetiredHandler';
import * as GetInstitutionCourseDetailHandler from '../handlerModels/GetInstitutionCourseDetailHandler';
import * as SearchForInstituionCoursesHandler from '../handlerModels/SearchForInstituionCoursesHandler';
import DisciplineDTO from '../models/DisciplineDTO';
import InstitutionCourseDetailDTO from '../models/InstitutionCourseDetailDTO';
import YearTermDTO from '../models/YearTermDTO';
import BaseFormProps from '../redux/bases/BaseFormProps';
import BaseFormState from '../redux/bases/BaseFormState';
import Guid from '../utils/Guid';
import LookupsUtil from '../utils/LookupsUtil';
import ClearButton from './buttons/ClearButton';
import SearchButton from './buttons/SearchButton';
import Dropdown from './inputs/Dropdown';

interface InstitutionCourseSearchValue {
  institutionCourseId?: string | null;
  institutionCourseDetail?: InstitutionCourseDetailDTO | null;
}

interface InstitutionCourseSearchState extends BaseFormState {
  searching?: boolean;
  selectedCourseId?: string;
  value?: InstitutionCourseSearchValue;
  institutionCourseDetails: InstitutionCourseDetailDTO[]
  disciplines: DisciplineDTO[];
  limited: boolean;
  installTerm: YearTermDTO;
}

interface InstitutionCourseSearchProps extends BaseFormProps {
  institutionId?: string;
  value?: InstitutionCourseSearchValue;
  existingCourseId?: string;
  includeRetired?: boolean
  onChange?: (value: InstitutionCourseSearchValue) => void;
  disciplineId?: string;
  catalogNumber?: string;
  installTerm?: YearTermDTO;
}

class InstitutionCourseSearch extends React.Component<InstitutionCourseSearchProps, InstitutionCourseSearchState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _tableRef = React.createRef<any>();
  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(SearchForInstituionCoursesHandler.Request.title, {
        name: SearchForInstituionCoursesHandler.Request.title,
        label: 'Title',
      })
      .set(SearchForInstituionCoursesHandler.Request.disciplineId, {
        name: SearchForInstituionCoursesHandler.Request.disciplineId,
        label: 'Discipline',
      })
      .set(SearchForInstituionCoursesHandler.Request.catalogNumber, {
        name: SearchForInstituionCoursesHandler.Request.catalogNumber,
        label: 'Catalog Number',
      });
  }

  constructor(props: InstitutionCourseSearchProps) {
    super(props);

    this.state = {
      institutionCourseDetails: [],
      disciplines: [],
      limited: false,
      installTerm: YearTermDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.disciplines || this.state.disciplines.length == 0) {
      loaders.push(this.loadDisciplines());
    }

    if (this.props.value != undefined) {
      this.setState({ value: this.props.value });
    }

    if (this.props.value?.institutionCourseDetail) {
      loaders.push(this.existingCourse(this.props.value.institutionCourseDetail));
    }

    if (this.props.existingCourseId && this.props.existingCourseId != Guid.Empty()) {
      loaders.push(this.loadExistingCourse(this.props.existingCourseId, this.props.institutionId ?? Guid.Empty()));
    }

    if (this.props.catalogNumber || this.props.disciplineId) {
      this.handleChange();
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadDisciplines = () => {
    return LookupsUtil.getAll<DisciplineDTO>(DisciplineDTO.className)
      .then((results: DisciplineDTO[]) => {
        if (results) {
          results = results.sort((a, b) => (a?.code ?? '') > (b?.code ?? '') ? 1 : -1)
          this.setState({ disciplines: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  componentDidUpdate(prevProps: InstitutionCourseSearchProps) {
    if (this.props.value != undefined && this.props.value != prevProps.value) {
      this.setState({ value: this.props.value });
    }

    if (this._tableRef.current) {
      const radioButton = this._tableRef.current.querySelector('.ant-table-tbody').querySelectorAll('.ant-radio-input');
      if (radioButton && radioButton.length > 0) {
        radioButton.forEach((element: any) => {
          element.setAttribute('aria-label', 'radio button')
        });
      }
    }
  }

  public reset = () => {
    this._formRef.current?.resetFields();
    this.setState({
      institutionCourseDetails: []
    });
  }

  public getValue = () => {
    return this.state.value;
  }

  public validateFields = () => {
    return this._formRef.current?.validateFields();
  }

  handleSelected = (selectedRows: InstitutionCourseDetailDTO[]) => {
    const course = selectedRows[0];

    this.setState({
      selectedCourseId: course.institutionCourseId ?? Guid.Empty(),
      value: {
        institutionCourseId: course.institutionCourseId ?? Guid.Empty(),
        institutionCourseDetail: course ?? null
      }
    })

    if (selectedRows && selectedRows[0] && this.props.onChange)
      this.props.onChange(
        {
          institutionCourseId: course.institutionCourseId ?? Guid.Empty(),
          institutionCourseDetail: course ?? null
        } as InstitutionCourseSearchValue);
  }

  clearSearch = () => {
    this._formRef.current?.resetFields();
  }

  handleChange = () => {
    this.setState({ searching: true });
    const formValues = this._formRef.current?.getFieldsValue()
    const request = SearchForInstituionCoursesHandler.Request.create({
      disciplineId: formValues.disciplineId == '' ? null : formValues.disciplineId,
      catalogNumber: formValues.catalogNumber,
      title: formValues.title,
      institutionId: this.props.institutionId,
      installTerm: this.props.installTerm
    });

    InstitutionCoursesApiService.searchForInstituionCourses(request)
      .then((result: SearchForInstituionCoursesHandler.Result) => {
        if (result?.succeeded) {
          this.setState({
            institutionCourseDetails: result.institutionCourseDetails ?? [],
            limited: result.exceedsNumberOfCourses
          });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
      })
      .finally(() => {
        this.setState({ searching: false });
      });
  }

  loadExistingCourse(existingCourseId: string, institutionId: string) {
    InstitutionCoursesApiService.getInstitutionCourseDetailByCourseIdandInstitutionId(existingCourseId, institutionId)
      .then((results: GetInstitutionCourseDetailHandler.Result) => {
        if (results.institutionCourse?.institutionCourseDetails) {
          this.setState({
            institutionCourseDetails: [results.institutionCourse.currentDetail ?? InstitutionCourseDetailDTO.create()],
            selectedCourseId: results.institutionCourse.id ?? Guid.Empty()
          });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  existingCourse = (existingCourse: InstitutionCourseDetailDTO) => {
    const request = SearchForInstituionCoursesHandler.Request.create({
      ...existingCourse,
      institutionId: this.props.institutionId
    });

    InstitutionCoursesApiService.searchForInstituionCourses(request)
      .then((result: SearchForInstituionCoursesHandler.Result) => {
        if (result?.succeeded) {
          this.setState({
            institutionCourseDetails: result.institutionCourseDetails ?? [],
          });

          if (result.exceedsNumberOfCourses) {
            message.info('All courses with current filters cannot be shown. Please use more filters');
          }
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();
    const formValues = {
      ...this.state.value?.institutionCourseDetail,
      disciplineId: this.props.disciplineId ?? null,
      catalogNumber: this.props.catalogNumber ?? undefined
    }

    return (
      <Space direction='vertical' size='large'>
        <Typography.Paragraph italic={true}>
          Search for a course using the fields below to populate the table below to select a course.
        </Typography.Paragraph>

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={formValues}
          onChange={this.handleChange}>
          <Space direction='vertical' size='small'>
            <Row gutter={[16, 16]}>
              <Col {...ColumnWidths.HALF}>
                <Form.Item {...formItems.get(SearchForInstituionCoursesHandler.Request.disciplineId)}>
                  <Dropdown dropdownMatchSelectWidth={false}
                    onChange={this.handleChange}
                    allowClear={true}
                    showSearch
                    optionFilterProp="children" >
                    {this.renderDisciplineOptions()}
                  </Dropdown>
                </Form.Item>
              </Col>

              <Col {...ColumnWidths.HALF}>
                <Form.Item {...formItems.get(SearchForInstituionCoursesHandler.Request.catalogNumber)}>
                  <Input allowClear={true} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item {...formItems.get(SearchForInstituionCoursesHandler.Request.title)}>
              <Input allowClear={true} />
            </Form.Item>
          </Space>

          <Space direction='horizontal' >
            <SearchButton htmlType='button' onClick={this.handleChange} searching={this.state.searching} />
            <ClearButton htmlType='button' onClick={this.clearSearch} />
          </Space>
        </Form >

        {
          this.state.limited ?
            <Typography.Text>
              Too many results. Please narrow your search above.
            </Typography.Text>
            : null
        }

        <Table dataSource={this.state.institutionCourseDetails}
          ref={this._tableRef}
          pagination={false}
          rowKey={InstitutionCourseDetailDTO.courseId}
          rowSelection={{
            columnTitle: 'Actions',
            columnWidth: 100,
            type: 'radio',
            onChange: (selectedRowKeys: React.Key[], selectedRows: InstitutionCourseDetailDTO[]) => {
              this.handleSelected(selectedRows);
            },
            defaultSelectedRowKeys: [this.props.existingCourseId ?? Guid.Empty()]
          }} >
          <Table.Column
            title='Discipline'
            dataIndex={InstitutionCourseDetailDTO.discipline}
            width={125}
            render={
              (value: any, record: InstitutionCourseDetailDTO) => {
                return (
                  <Tooltip title={record.discipline?.name} placement='right'>
                    {record.discipline?.code}
                  </Tooltip>
                );
              }} />
          <Table.Column title='Catalog Number' dataIndex={InstitutionCourseDetailDTO.catalogNumber} width={125} />
          <Table.Column title='Title' dataIndex={InstitutionCourseDetailDTO.title} />
        </Table>
      </Space >
    );
  }

  renderDisciplineOptions() {
    return this.state.disciplines.map(x => { return this.renderDisciplineOption(x) });
  }

  renderDisciplineOption(discipline: DisciplineDTO): any {
    if (discipline.id) {
      return <Select.Option key={discipline.id} value={discipline.id} >{discipline.display}</Select.Option>;
    }
  }
}

export default InstitutionCourseSearch;
