import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GenderDataTable from '../../../components/datatables/GenderDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import HeaderPortal from '../../../portals/HeaderPortal';
import HistoryUtil from '../../../utils/HistoryUtil';

interface GenderListProps {
}

interface GenderListState {
}

class GenderListPage extends React.Component<GenderListProps & RouteComponentProps<RouteObject>, GenderListState> {
  constructor(props: GenderListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Genders" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.genderList()} />

        <Card >
          <GenderDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(GenderListPage);
