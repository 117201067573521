import { LeftOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import * as React from 'react';

interface PreviousButtonState { }

interface PreviousButtonProps extends ButtonProps {
  submitting?: boolean;
}

class PreviousButton extends React.Component<PreviousButtonProps, PreviousButtonState> {
  constructor(props: PreviousButtonProps) {
    super(props);

    this.state = {};
  }

  render() {
    const htmlType = this.props.htmlType ? this.props.htmlType : 'button'
    const buttonType = this.props.type ? this.props.type : 'default'

    return (
      <Button
        {...this.props}
        htmlType={htmlType}
        type={buttonType}
        icon={<LeftOutlined />}
        loading={this.props.submitting || this.props.loading} >
        Previous
      </Button>
    );
  }
}

export default PreviousButton;
