import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ObjectPurposeApiService from '../../../../api/ObjectPurposeApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import ObjectPurposeDetailForm from '../../../../components/forms/postSecondary/ObjectPurposeDetailForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteObjectPurposeHandler from '../../../../handlerModels/DeleteObjectPurposeHandler';
import * as GetObjectPurposeDetailsHandler from '../../../../handlerModels/GetObjectPurposeDetailsHandler';
import ObjectPurposeDTO from '../../../../models/ObjectPurposeDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface ObjectPurposeDetailsPageState extends BaseFormState {
  objectPurposeId: number;
  objectPurpose?: ObjectPurposeDTO;
}

interface ObjectPurposeDetailsPageProps {
}

class ObjectPurposeDetailsPage extends React.Component<ObjectPurposeDetailsPageProps & RouteComponentProps<RouteObject>, ObjectPurposeDetailsPageState> {
  private readonly _objectPurposeDetailsRef = React.createRef<ObjectPurposeDetailForm>();

  constructor(props: ObjectPurposeDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      objectPurposeId: 0
    };
  }

  componentDidMount() {
    const objectPurposeId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (objectPurposeId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const objectPurposeIdNumber = Number.parseInt(objectPurposeId);
      this.loadObjectPurpose(objectPurposeIdNumber);
    }
  }

  private loadObjectPurpose = (objectPurposeId: number) => {
    this.setState({ objectPurposeId: objectPurposeId });

    ObjectPurposeApiService.getObjectPurposeDetails(objectPurposeId)
      .then((results: GetObjectPurposeDetailsHandler.Result) => {
        if (results.objectPurpose) {
          this.setState({ objectPurpose: results.objectPurpose });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._objectPurposeDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private objectPurposeDetailsSaved = (objectPurposeId: number) => {
    this.setState({ isEditing: false });
    if (objectPurposeId != this.state.objectPurposeId) {
      HistoryUtil.replace(Routes.generate(Routes.OBJECT_PURPOSE_DETAILS, { id: objectPurposeId }));
    }
    else {
      this.loadObjectPurpose(objectPurposeId);
    }
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Object Purpose?',
      okText: 'Delete',
      onOk: () => this.deleteObjectPurpose(id)
    });
  }

  private deleteObjectPurpose = (objectPurposeId: number) => {
    this.setState({ submitting: true });

    const request = DeleteObjectPurposeHandler.Request.create({
      objectPurposeId: objectPurposeId,
    });

    ObjectPurposeApiService.deleteObjectPurpose(request)
      .then((result: DeleteObjectPurposeHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Object Purpose was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Object Purpose could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToObjectPurposes();
      });
  }

  private goToObjectPurposes = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.objectPurposeId == 0 ? 'New Object Purpose' : this.state.objectPurpose?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToObjectPurposes}
          breadcrumbs={Breadcrumbs.objectPurposeDetails(title, this.state.objectPurposeId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.objectPurposeId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_OBJECT_PURPOSE]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <ObjectPurposeDetailForm
          ref={this._objectPurposeDetailsRef}
          objectPurposeId={this.state.objectPurposeId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.objectPurposeDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Object Purpose' key={this.state.objectPurposeId} onClick={() => this.deleteConfirmation(this.state.objectPurposeId)}  >
          Delete Object Purpose
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_OBJECT_PURPOSE]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(ObjectPurposeDetailsPage);