import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ContactTypeApiService from '../../../../api/ContactTypeApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import ContactTypeDetailsForm from '../../../../components/forms/postSecondary/ContactTypeDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteContactTypeHandler from '../../../../handlerModels/DeleteContactTypeHandler';
import * as GetContactTypeDetailsHandler from '../../../../handlerModels/GetContactTypeDetailsHandler';
import ContactTypeDTO from '../../../../models/ContactTypeDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface ContactTypeDetailsState extends BaseFormState {
  contactTypeId: number;
  contactType?: ContactTypeDTO;
}

interface ContactTypeDetailsProps {
}

class ContactTypeDetailsPage extends React.Component<ContactTypeDetailsProps & RouteComponentProps<RouteObject>, ContactTypeDetailsState> {
  private readonly _contactTypeDetailsRef = React.createRef<ContactTypeDetailsForm>();

  constructor(props: ContactTypeDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      contactTypeId: 0,
    };
  }

  componentDidMount() {
    const contactTypeId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (contactTypeId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false });
    }
    else {

      this.loadContactType(Number.parseInt(contactTypeId));
    }
  }

  private loadContactType = (contactTypeId: number) => {
    this.setState({ contactTypeId: contactTypeId });

    ContactTypeApiService.getContactTypeDetails(contactTypeId)
      .then((results: GetContactTypeDetailsHandler.Result) => {
        if (results.contactType) {
          this.setState({ contactType: results.contactType });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._contactTypeDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private contactTypeDetailsSaved = (contactTypeId: number) => {
    this.setState({ isEditing: false });
    if (contactTypeId != this.state.contactTypeId) {
      HistoryUtil.replace(Routes.generate(Routes.CONTACT_TYPE_DETAILS, { id: contactTypeId }));
    }
    else {
      this.loadContactType(contactTypeId);
    }
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this contact type?',
      okText: 'Delete',
      onOk: () => this.deleteContactType(id)
    });
  }

  private deleteContactType = (contactTypeId: number) => {
    this.setState({ submitting: true });

    const request = DeleteContactTypeHandler.Request.create({
      contactTypeId: contactTypeId,
    });

    ContactTypeApiService.deleteContactType(request)
      .then((result: DeleteContactTypeHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Contact Type was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Contact Type could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToListing();
      });
  }

  goToListing = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.isNew ? 'New Contact Type' : this.state.contactType?.name ?? '...';
    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToListing}
          breadcrumbs={Breadcrumbs.contactTypeDetails(title, this.state.contactTypeId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    const editButton = this.state.contactTypeId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CONTACT_TYPE]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton} >
        <ContactTypeDetailsForm
          ref={this._contactTypeDetailsRef}
          contactTypeId={this.state.contactTypeId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.contactTypeDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Contact Type' key={this.state.contactTypeId} onClick={() => this.deleteConfirmation(this.state.contactTypeId)}  >
          Delete Contact Type
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CREDIT_TYPE]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(ContactTypeDetailsPage);
