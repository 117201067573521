import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import SecondaryProgramScenarioApiService from '../../../api/SecondaryProgramScenarioApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteSecondaryProgramScenarioHandler from '../../../handlerModels/DeleteSecondaryProgramScenarioHandler';
import SecondaryProgramScenarioDTO from '../../../models/SecondaryProgramScenarioDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import SecondaryProgramScenarioDetailsModal from '../../modals/SecondaryProgramScenarioDetailsModal';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface SecondaryProgramScenariosDataTableProps {
  academicYear: number;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  programId?: string;
  fromProgramOverview?: boolean;
}

function SecondaryProgramScenariosDataTable(props: SecondaryProgramScenariosDataTableProps) {
  let _dataTable: DataTable<SecondaryProgramScenarioDTO> | undefined = undefined;

  const [SecondaryProgramScenarioId, setSecondaryProgramScenarioId] = useState<string | null>();

  const getColumnDefinitions = () => {
    const columns = [];
    if (!props.fromProgramOverview) {
      columns.push({
        title: 'Id',
        dataIndex: SecondaryProgramScenarioDTO.secondaryProgramScenarioId,
        sorter: true,
        width: 100,
        render: (data: string, row: SecondaryProgramScenarioDTO) => {
          return row.secondaryProgramScenarioId;
        },
        filterType: FilterType.Text
      });
    }

    columns.pushAll([{
      title: 'Title',
      dataIndex: SecondaryProgramScenarioDTO.title,
      sorter: true,
      ellipsis: true,
      width: 200,
      render: (data: string, row: SecondaryProgramScenarioDTO) => {
        return row.title;
      },
      filterType: FilterType.Text
    },
    {
      title: 'Description',
      dataIndex: SecondaryProgramScenarioDTO.description,
      sorter: true,
      ellipsis: true,
      render: (data: string, row: SecondaryProgramScenarioDTO) => {
        return row.description;
      },
      filterType: FilterType.Text
    }]);

    if (!props.fromProgramOverview) {
      columns.push({
        title: 'Actions',
        align: 'center',
        dataIndex: SecondaryProgramScenarioDTO.secondaryProgramScenarioId,
        sorter: false,
        render: (data: string, row: SecondaryProgramScenarioDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => openSecondaryProgramScenarioDetail(row)}><FormOutlined /></Button>
              <Button type="link" onClick={() => promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 100
      });
    }

    return columns as DataTableColumnProps<any>[];
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openSecondaryProgramScenarioDetail = (record: SecondaryProgramScenarioDTO) => {
    if (record.id) {
      setSecondaryProgramScenarioId(record.id);
    }
  }

  const newSecondaryProgramScenario = () => {
    setSecondaryProgramScenarioId(Guid.Empty);
  }

  const closeSecondaryProgramScenarioDetail = () => {
    setSecondaryProgramScenarioId(null);
  }

  const promptConfirmRemove = (SecondaryProgramScenarioId: string | null) => {
    if (SecondaryProgramScenarioId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this Scenario?',
      okText: 'Delete',
      onOk: () => deleteSecondaryProgramScenario(SecondaryProgramScenarioId, props.academicYear),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteSecondaryProgramScenario = (SecondaryProgramScenarioId: string, academicYear: number) => {
    const request = DeleteSecondaryProgramScenarioHandler.Request.create({
      SecondaryProgramScenarioId: SecondaryProgramScenarioId,
      academicYearId: academicYear
    });

    SecondaryProgramScenarioApiService.deleteSecondaryProgramScenario(request)
      .then((result: DeleteSecondaryProgramScenarioHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Scenario was removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Scenario could not be removed.');
      });
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_PROGRAM_SCENARIO]) && !props.programId && !props.fromProgramOverview) {
    actionButtons.push(DataTableButtonUtil.Default('New Scenario', () => newSecondaryProgramScenario()));
  }

  const renderModal = () => {
    if (SecondaryProgramScenarioId) {
      return <SecondaryProgramScenarioDetailsModal
        onCancel={closeSecondaryProgramScenarioDetail}
        visible={true}
        secondaryProgramScenarioId={SecondaryProgramScenarioId}
        academicYear={props.academicYear}
        onSave={closeSecondaryProgramScenarioDetail} />
    }
  }

  return (
    <>
      {renderModal()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => SecondaryProgramScenarioApiService.getSecondaryProgramScenarioTableData(requestState, props.academicYear)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'secondary_program_scenario_list' }} />
    </>
  );
}

export default SecondaryProgramScenariosDataTable;
