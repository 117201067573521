// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveTransferMajorLactsCategoryDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import TransferMajorLactsCategoryDTO from '../models/TransferMajorLactsCategoryDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    transferMajorLactsCategoryId: number;
    transferMajorLactsCategory: TransferMajorLactsCategoryDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            transferMajorLactsCategoryId: 0,
            transferMajorLactsCategory: null,
        },
        initValues);
    },
    transferMajorLactsCategoryId: 'transferMajorLactsCategoryId',
    transferMajorLactsCategory: 'transferMajorLactsCategory',
}

interface Result extends ResultDTO {
    transferMajorLactsCategory: TransferMajorLactsCategoryDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            transferMajorLactsCategory: null,
        },
        initValues);
    },
    transferMajorLactsCategory: 'transferMajorLactsCategory',
}

export { Request, Result };