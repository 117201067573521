// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import TransferMajorDisciplineFrameworkElementDTO from '../models/TransferMajorDisciplineFrameworkElementDTO';
import * as GetTransferMajorDisciplineFrameworkElementDetailsHandler from '../handlerModels/GetTransferMajorDisciplineFrameworkElementDetailsHandler';
import * as SaveTransferMajorDisciplineFrameworkElementDetailsHandler from '../handlerModels/SaveTransferMajorDisciplineFrameworkElementDetailsHandler';
import * as DeleteTransferMajorDisciplineFrameworkElementHandler from '../handlerModels/DeleteTransferMajorDisciplineFrameworkElementHandler';

export class TransferMajorDisciplineFrameworkElementApiService extends BaseApi {

    // post: api/transferMajorDisciplineFrameworkElement
    public getTransferMajorDisciplineFrameworkElementsDataTable(tableRequest: TableRequestDTO): Promise<TableResponseDTO<TransferMajorDisciplineFrameworkElementDTO>> {
        let url = `api/transferMajorDisciplineFrameworkElement`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<TransferMajorDisciplineFrameworkElementDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/transferMajorDisciplineFrameworkElement/${id}
    public getTransferMajorDisciplineFrameworkElementDetails(id: number): Promise<GetTransferMajorDisciplineFrameworkElementDetailsHandler.Result> {
        let url = `api/transferMajorDisciplineFrameworkElement/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetTransferMajorDisciplineFrameworkElementDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/transferMajorDisciplineFrameworkElement/export
    public getTransferMajorDisciplineFrameworkElementDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/transferMajorDisciplineFrameworkElement/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/transferMajorDisciplineFrameworkElement/save
    public saveTransferMajorDisciplineFrameworkElementDetails(saveTransferMajorDisciplineFrameworkElementDetails: SaveTransferMajorDisciplineFrameworkElementDetailsHandler.Request): Promise<SaveTransferMajorDisciplineFrameworkElementDetailsHandler.Result> {
        let url = `api/transferMajorDisciplineFrameworkElement/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveTransferMajorDisciplineFrameworkElementDetailsHandler.Result>(saveTransferMajorDisciplineFrameworkElementDetails, 'post', url, true, false);
    }

    // post: api/transferMajorDisciplineFrameworkElement/deleteTransferMajorDisciplineFrameworkElement
    public deleteTransferMajorDisciplineFrameworkElement(deleteTransferMajorDisciplineFrameworkElement: DeleteTransferMajorDisciplineFrameworkElementHandler.Request): Promise<DeleteTransferMajorDisciplineFrameworkElementHandler.Result> {
        let url = `api/transferMajorDisciplineFrameworkElement/deleteTransferMajorDisciplineFrameworkElement`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteTransferMajorDisciplineFrameworkElementHandler.Result>(deleteTransferMajorDisciplineFrameworkElement, 'post', url, true, false);
    }
}
var service = new TransferMajorDisciplineFrameworkElementApiService();
export default service;
