import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProgramModificationChangeRequestApiService from '../../../../api/ProgramModificationChangeRequestApiService';
import ChangeRequestSubmitted from '../../../../components/displays/postSecondary/ChangeRequestSubmitted';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import * as GetProgramModificationChangeRequestHandler from '../../../../handlerModels/GetProgramModificationChangeRequestHandler';
import ChangeRequestDTO from '../../../../models/ChangeRequestDTO';
import InstitutionDTO from '../../../../models/InstitutionDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface ProgramModificationChangeRequestSubmittedPageState extends BaseFormState {
  changeRequestId: string;
  changeRequest: ChangeRequestDTO;
  progress: number;
}

interface ProgramModificationChangeRequestSubmittedPageProps {
  selectedInstitution: InstitutionDTO | null;
}

class ProgramModificationChangeRequestSubmittedPage extends React.Component<ProgramModificationChangeRequestSubmittedPageProps & RouteComponentProps<RouteObject>, ProgramModificationChangeRequestSubmittedPageState> {
  constructor(props: ProgramModificationChangeRequestSubmittedPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequestId: Guid.Empty(),
      changeRequest: ChangeRequestDTO.create(),
      progress: 0
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id !== Guid.Empty() && this.state.changeRequestId != id) {
      this.setState({ changeRequestId: id });
      this.fetchData(id);
    }
  }

  private fetchData = (id: string) => {
    this.setState({ loading: true });

    const loaders = [];

    if (id !== Guid.Empty()) {
      loaders.push(this.loadChangeRequest(id));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadChangeRequest = (id: string) => {
    const request = GetProgramModificationChangeRequestHandler.Request.create({
      changeRequestId: id
    });

    return ProgramModificationChangeRequestApiService.get(request)
      .then((results: GetProgramModificationChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  goToPreviousStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_REVIEW, { id: this.state.changeRequestId }, {}))
  }

  submit = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_SUBMITTED, { id: this.state.changeRequestId }, {}))
  }

  render() {
    return (
      <Content >
        <HeaderPortal
          breadcrumbs={Breadcrumbs.changeRequestList()} >
        </HeaderPortal>

        <Card >
          <ChangeRequestSubmitted />
        </Card>
      </Content >
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ProgramModificationChangeRequestSubmittedPage));