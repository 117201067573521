// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import WorkQueueItemDTO from '../models/WorkQueueItemDTO';

export class WorkItemApiService extends BaseApi {

    // post: api/workItems/workQueue
    public getWorkQueue(tableRequest: TableRequestDTO): Promise<TableResponseDTO<WorkQueueItemDTO>> {
        let url = `api/workItems/workQueue`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<WorkQueueItemDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/workItems/user/${encodeURIComponent(userId)}
    public getWorkItemsForUser(userId: string, tableRequest: TableRequestDTO): Promise<TableResponseDTO<WorkQueueItemDTO>> {
        let url = `api/workItems/user/${encodeURIComponent(userId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<WorkQueueItemDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/workItems/institution/${encodeURIComponent(institutionId)}
    public getWorkItemsForInstitution(institutionId: string, tableRequest: TableRequestDTO): Promise<TableResponseDTO<WorkQueueItemDTO>> {
        let url = `api/workItems/institution/${encodeURIComponent(institutionId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<WorkQueueItemDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/workItems/all
    public getWorkItems(tableRequest: TableRequestDTO): Promise<TableResponseDTO<WorkQueueItemDTO>> {
        let url = `api/workItems/all`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<WorkQueueItemDTO>>(tableRequest, 'post', url, true, false);
    }
}
var service = new WorkItemApiService();
export default service;
