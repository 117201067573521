// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as SaveNewCourseChangeRequestHandler from '../handlerModels/SaveNewCourseChangeRequestHandler';
import * as SubmitNewCourseChangeRequestHandler from '../handlerModels/SubmitNewCourseChangeRequestHandler';
import * as GetNewCourseChangeRequestHandler from '../handlerModels/GetNewCourseChangeRequestHandler';
import * as SearchForExistitngNewCourseChangeRequestHandler from '../handlerModels/SearchForExistitngNewCourseChangeRequestHandler';

export class NewCourseChangeRequestApiService extends BaseApi {

    // post: api/newCourseChangeRequests/saveStep1
    public save(saveNewCourseChangeRequest: SaveNewCourseChangeRequestHandler.Request): Promise<SaveNewCourseChangeRequestHandler.Result> {
        let url = `api/newCourseChangeRequests/saveStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewCourseChangeRequestHandler.Result>(saveNewCourseChangeRequest, 'post', url, true, false);
    }

    // post: api/newCourseChangeRequests/submitStep1
    public submitStep1(submitNewCourseChangeRequest: SubmitNewCourseChangeRequestHandler.Request): Promise<SubmitNewCourseChangeRequestHandler.Result> {
        let url = `api/newCourseChangeRequests/submitStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewCourseChangeRequestHandler.Result>(submitNewCourseChangeRequest, 'post', url, true, false);
    }

    // post: api/newCourseChangeRequests/get
    public get(getNewCourseChangeRequest: GetNewCourseChangeRequestHandler.Request): Promise<GetNewCourseChangeRequestHandler.Result> {
        let url = `api/newCourseChangeRequests/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewCourseChangeRequestHandler.Result>(getNewCourseChangeRequest, 'post', url, true, false);
    }

    // post: api/newCourseChangeRequests/existingNewCourse
    public existingNewCourseSearch(searchForExistitngNewCourseChangeRequest: SearchForExistitngNewCourseChangeRequestHandler.Request): Promise<SearchForExistitngNewCourseChangeRequestHandler.Result> {
        let url = `api/newCourseChangeRequests/existingNewCourse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForExistitngNewCourseChangeRequestHandler.Result>(searchForExistitngNewCourseChangeRequest, 'post', url, true, false);
    }
}
var service = new NewCourseChangeRequestApiService();
export default service;
