// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import YearTermDTO from './YearTermDTO';
import TermDTO from './TermDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NewDisciplineChangeRequestDTO extends EntitySelectorDTO { 
	id: string | null | null;
	changeRequestId: string | null | null;
	code: string | null;
	name: string | null;
	rationale: string | null;
	additionalRationale: string | null;
	install: YearTermDTO | null;
	installTermId: string | null | null;
	installYear: number | null | null;
	installTerm: TermDTO | null;
}

const NewDisciplineChangeRequestDTO = {
    className: 'NewDisciplineChangeRequestDTO',
    create: (initValues?: Partial<NewDisciplineChangeRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			changeRequestId: null,
			code: "",
			name: "",
			rationale: "",
			additionalRationale: "",
			install: null,
			installTermId: null,
			installYear: null,
			installTerm: null,
        },
        initValues);
    },
	id: 'id',
	changeRequestId: 'changeRequestId',
	code: 'code',
	name: 'name',
	rationale: 'rationale',
	additionalRationale: 'additionalRationale',
	install: 'install',
	installTermId: 'installTermId',
	installYear: 'installYear',
	installTerm: 'installTerm',
};

export default NewDisciplineChangeRequestDTO;