import moment from 'moment';
import TermDTO from '../models/TermDTO';
import LookupsUtil from './LookupsUtil';

export default class TermYearUtil {
  public static async getCurrent(): Promise<TermYear> {
    return {
      termId: (await this.getCurrentTerm()).id,
      year: this.getCurrentYear()
    };
  }

  public static getCurrentYear(): number {
    return moment().year();
  }

  public static async getCurrentTerm(): Promise<TermDTO> {
    const terms = await LookupsUtil.getAll<TermDTO>(TermDTO.className);
    const currentDay = moment().add(1, 'day').dayOfYear();
    const validTerms = terms
      .filter(x => moment(this.getCurrentYear() + x.startDate.toString().substring(4)).add(1, 'day').dayOfYear() <= currentDay)
      .sort((a, b) => moment(b.startDate).add(1, 'day').dayOfYear() - moment(a.startDate).add(1, 'day').dayOfYear());

    return validTerms[0];
  }

  public static getYearOptions(): number[] {
    let year = moment().year() + 5;
    const options = [];

    while (year >= 2000) {
      options.push(year--);
    }

    return options;
  }

  public static async getTermOptions(): Promise<TermDTO[]> {
    return await LookupsUtil.getAll<TermDTO>(TermDTO.className).then((results: TermDTO[]) => {
      return results ?? [];
    });
  }
}

export interface TermYear {
  termId: string | null;
  year: number | null;
}