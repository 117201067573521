// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetSelfStudySectionComponentResponseByTypeHandler from '../handlerModels/GetSelfStudySectionComponentResponseByTypeHandler';

export class SelfStudySectionComponentResponseApiService extends BaseApi {

    // post: api/selfStudySectionComponentResponses/getSectionComponentResponseByType
    public getSelfStudySectionComponentResponseByType(getSelfStudySectionComponentResponseByType: GetSelfStudySectionComponentResponseByTypeHandler.Request): Promise<GetSelfStudySectionComponentResponseByTypeHandler.Result> {
        let url = `api/selfStudySectionComponentResponses/getSectionComponentResponseByType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudySectionComponentResponseByTypeHandler.Result>(getSelfStudySectionComponentResponseByType, 'post', url, true, false);
    }
}
var service = new SelfStudySectionComponentResponseApiService();
export default service;
