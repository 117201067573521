import { Alert, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import ServiceAreaAssignmentApiService from '../../../api/ServiceAreaAssignmentApiService';
import * as AddServiceAreaAssigmentHandler from '../../../handlerModels/AddServiceAreaAssigmentHandler';
import * as GetServiceAreasHandler from '../../../handlerModels/GetServiceAreasHandler';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import Dropdown from '../../inputs/Dropdown';

interface ServiceAreaAssignmentDetailFormState extends BaseFormState {
  serviceAreas: ServiceAreaDTO[];
}

interface ServiceAreaAssignmentDetailFormProps extends BaseFormProps {
  userId?: string;
  onSave?: (SecondaryProgramScenarioId: string) => void;
}

class ServiceAreaAssignmentDetailForm extends React.Component<ServiceAreaAssignmentDetailFormProps, ServiceAreaAssignmentDetailFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(AddServiceAreaAssigmentHandler.Request.userId, {
      label: 'User',
      required: true,
      name: AddServiceAreaAssigmentHandler.Request.userId,
      rules: [
        ValidationRuleUtil.required()
      ]
    })
    .set(AddServiceAreaAssigmentHandler.Request.serviceAreaId, {
      label: 'Service Area',
      required: true,
      name: AddServiceAreaAssigmentHandler.Request.serviceAreaId,
      rules: [
        ValidationRuleUtil.required()
      ]
    });

  constructor(props: ServiceAreaAssignmentDetailFormProps) {
    super(props);

    this.state = {
      serviceAreas: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: ServiceAreaAssignmentDetailFormProps) {
    if (this.props != prevProps) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];

    if (!this.state.serviceAreas || this.state.serviceAreas.length == 0) {
      loaders.push(this.loadServiceArea());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadServiceArea = () => {
    return ServiceAreaApiService.getServiceAreas(0)
      .then((results: GetServiceAreasHandler.Result) => {
        if (results.serviceAreas) {
          this.setState({ serviceAreas: results.serviceAreas ?? [] });
        }
      })
  }

  private handleSubmit = () => {
    this.setState({ submitted: true })

    const model = this._formRef.current?.getFieldsValue();

    const request = AddServiceAreaAssigmentHandler.Request.create({
      userId: this.props.userId,
      serviceAreaId: model.serviceAreaId
    });

    ServiceAreaAssignmentApiService.addServiceAreaAssignment(request)
      .then((result: AddServiceAreaAssigmentHandler.Result) => {
        if (result?.succeeded) {
          message.success('Service Area Added');
          this.setState({ submitted: false, fieldErrors: null })
        }
        else {
          this.setState({ fieldErrors: result.fieldErrors })
          message.error('Error Adding Service Area');
        }
      })
      .catch(() => {
        message.error('Error Adding Service Area');
      });
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      () => this.handleSubmit(),
      () => this.setState({ error: true })
    );
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private renderServiceArea = (serviceArea: ServiceAreaDTO) => {
    if (serviceArea.id) {
      return <Select.Option key={serviceArea.id ?? Guid.Empty()} value={serviceArea.id ?? Guid.Empty()}>{serviceArea.serviceAreaIdentifier} - {serviceArea.description}</Select.Option>
    }
  }


  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Form
        ref={this._formRef}
        layout="vertical"
        requiredMark={true}
        onFinish={this.handleSubmit}>
        <Space size="small" direction="vertical">

          <FormItem
            {...this._formItems.get(AddServiceAreaAssigmentHandler.Request.serviceAreaId)}
            {...ValidationUtil.getValidation(AddServiceAreaAssigmentHandler.Request.serviceAreaId, this.state.fieldErrors, this.state.submitted)}>
            <Dropdown dropdownMatchSelectWidth={false}>
              {this.state.serviceAreas.map(x => { return this.renderServiceArea(x) })}
            </Dropdown>
          </FormItem>
        </Space >
      </Form >
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default ServiceAreaAssignmentDetailForm;
