import { BorderOutlined, CheckSquareFilled } from '@ant-design/icons';
import * as React from 'react';
import { Button } from 'antd';

interface CheckBoxButtonState {
  checked: boolean;
}

interface CheckBoxButtonProps {
  children: any;
  checked?: boolean;
  checkBox?: boolean;
  style?: React.CSSProperties;
  textSize?: 'small' | 'default' | undefined;
  onClick?: (value: any) => any;
  onChange?: (value: boolean) => void;
  value?: boolean;
  disabled?: boolean;
}

class CheckboxButtons extends React.Component<CheckBoxButtonProps, CheckBoxButtonState> {
  constructor(props: CheckBoxButtonProps) {
    super(props);
    this.handleClicked = this.handleClicked.bind(this);
    const checked = props.checked ? props.checked : false;
    this.state = {
      checked: checked
    };
  }

  handleClicked() {
    const checked = !(this.state.checked ?? false);
    this.setState({
      checked: checked
    });

    if (this.props.onClick) {
      this.props.onClick(checked);
    }
  }

  componentDidUpdate(nextProps: CheckBoxButtonProps) {
    if (this.props.checked != nextProps.checked) {
      this.setState({
        checked: this.props.checked ?? false
      });
    }
  }

  render() {
    const fontClass = this.props.textSize === 'small' ? 'ant-btn-sm' : '';
    return (
      <Button
        disabled={this.props.disabled}
        className={'ant-btn-option btn-left-align ' + (this.state.checked ? 'checked ' : ' ') + fontClass}
        onClick={this.handleClicked}
        block={true}
        size="large"
        type={this.state.checked ? 'primary' : 'default'}
      >
        {this.props.checkBox ? null : this.renderCheck()}
        {this.props.children}
      </Button>
    );
  }

  renderCheck() {
    const icon = this.state.checked ? <CheckSquareFilled /> : <BorderOutlined />;
    return <span style={{ marginRight: 10 }}>{icon}</span>;
  }
}

export default CheckboxButtons;