import { Descriptions, Skeleton } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import * as React from 'react';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import DateTimeUtil from '../../../utils/DateTimeUtil';

interface ChangeRequestMainDetailsState extends BaseFormState {
}

interface ChangeRequestMainDetailsProps extends BaseFormProps {
  changeRequest: ChangeRequestDTO;
}

class ChangeRequestMainDetails extends React.Component<ChangeRequestMainDetailsProps, ChangeRequestMainDetailsState> {
  constructor(props: ChangeRequestMainDetailsProps) {
    super(props);

    this.state = {};
  }

  public render = () => {
    if (this.props.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Descriptions bordered={true} size='small' layout='vertical'>
        <DescriptionsItem label="Request Type" >{this.props.changeRequest.changeRequestType?.name}</DescriptionsItem>
        <DescriptionsItem label="Community College" >{this.props.changeRequest.institution?.name}</DescriptionsItem>
        <DescriptionsItem label="Requesting User" >{this.props.changeRequest.requestingUser?.fullDisplay}</DescriptionsItem>
        <DescriptionsItem label="Status" >{this.props.changeRequest.changeRequestStatusType?.name}</DescriptionsItem>
        <DescriptionsItem label="Request Submitted" >{DateTimeUtil.longDate(this.props.changeRequest.requestedOn)}</DescriptionsItem>
        <DescriptionsItem label="Approved" >{DateTimeUtil.longDate(this.props.changeRequest.approvedOn)}</DescriptionsItem>
      </Descriptions>
    );
  }
}

export default ChangeRequestMainDetails;