import { Skeleton } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import SelfStudyTemplateApiService from '../../../api/SelfStudyTemplateApiService';
import Routes from '../../../config/Routes';
import * as GetSelfStudyConfigurationHandler from '../../../handlerModels/GetSelfStudyConfigurationHandler';
import * as GetSelfStudyResponsesHandler from '../../../handlerModels/GetSelfStudyResponsesHandler';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import SelfStudyRankingReportDataTableDTO from '../../../models/SelfStudyRankingReportDataTableDTO';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import SelfStudyTemplateDTO from '../../../models/SelfStudyTemplateDTO';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';


interface SelfStudyRankingReportDataTableProps {
  academicYear?: number;
  selectedDistrict: DistrictDTO | null;
  selectedRPP: RegionalPlanningPartnerDTO | null;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  isPublic?: boolean;
  districtId?: string;
  fromAcademicDashboard?: boolean;
}

function SelfStudyRankingReportDataTable(props: SelfStudyRankingReportDataTableProps) {
  let _dataTable: DataTable<SelfStudyRankingReportDataTableDTO> | undefined = undefined;

  const [, setSelfStudyResponseTypes] = useState([] as TableFilterOption[]);
  const [selfStudyResponses, setSelfStudyResponses] = useState([] as SelfStudyResponseDTO[]);
  const [selfStudyTemplate, setSelfStudyTemplate] = useState({} as SelfStudyTemplateDTO);
  const [institutionOptions, setInstitutionOptions] = useState([] as TableFilterOption[]);
  const [, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const loaders = [];
    TableFilterUtil.SelfStudyResponseTypes()
      .then(x => {
        setSelfStudyResponseTypes(x);
      });
    TableFilterUtil.Institutions().then(x => setInstitutionOptions(x));

    triggerRefresh();
    loaders.push(loadSelfStudyTemplate());
    loaders.push(loadSelfStudyResponses());

    Promise.all(loaders).then(() => {
      setLoading(false);
      triggerRefresh();
    });

  }, []);

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const getColumnDefinitions = () => {

    const column = [
      {
        title: 'Regional Planning Partner',
        dataIndex: SelfStudyRankingReportDataTableDTO.rpp,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyRankingReportDataTableDTO) => {
          return row.rpp;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Community College',
        dataIndex: SelfStudyRankingReportDataTableDTO.communityCollege,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyRankingReportDataTableDTO) => {
          return row.communityCollege;
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: institutionOptions
      },
      {
        title: 'District',
        dataIndex: SelfStudyRankingReportDataTableDTO.district,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyRankingReportDataTableDTO) => {
          return row.district;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Service Area',
        dataIndex: SelfStudyRankingReportDataTableDTO.serviceArea,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyRankingReportDataTableDTO) => {
          return row.serviceArea;
        },
        filterType: FilterType.Text
      },
      {
        title: 'CIP Number',
        dataIndex: SelfStudyRankingReportDataTableDTO.cipNumber,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyRankingReportDataTableDTO) => {
          return row.cipNumber;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Program',
        dataIndex: SelfStudyRankingReportDataTableDTO.program,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyRankingReportDataTableDTO) => {
          return row.program;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Submitter',
        dataIndex: SelfStudyRankingReportDataTableDTO.submitter,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyRankingReportDataTableDTO) => {
          return row.submitter;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Review Year',
        dataIndex: SelfStudyRankingReportDataTableDTO.reviewYear,
        sorter: true,
        width: 50,
        render: (data: string, row: SelfStudyRankingReportDataTableDTO) => {
          return row.reviewYear + ' ' + row.reviewType;
        },
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];

    selfStudyTemplate.selfStudySectionTemplates?.forEach(x => {
      const components: any = [];
      x.selfStudySectionComponentTemplates?.forEach(y => {
        components.push({
          title: y.rankingQuestionIdentifier ?? (x.order + 1) + '.' + (y.order + 1),
          render: (data: string, row: SelfStudyRankingReportDataTableDTO) => {
            return selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudySectionResponses?.find(z => z.selfStudySectionTemplateId == x.id)?.selfStudySectionComponentResponses?.find(z => z.selfStudySectionComponentTemplateId == y.id)?.rating ?? 0;
          },
        })
      })

      column.push({
        title: x.title,
        dataIndex: SelfStudyDTO.id,
        width: 50,
        children: components as any,
      })
    });

    return column;
  };

  const loadSelfStudyTemplate = () => {
    return SelfStudyTemplateApiService.getSelfStudy(props.academicYear ?? 0)
      .then((results: GetSelfStudyConfigurationHandler.Result) => {
        if (results) {
          setSelfStudyTemplate(results.selfStudy ?? SelfStudyTemplateDTO.create());
        }
      })
      .catch(() => {
        setError(true);
      });
  }

  const loadSelfStudyResponses = () => {
    const request = GetSelfStudyResponsesHandler.Request.create({
      academicYear: props.academicYear
    });

    return SelfStudyApiService.getSelfStudyResponses(request)
      .then((results: GetSelfStudyResponsesHandler.Result) => {
        if (results.selfStudyResponses) {
          setSelfStudyResponses(results.selfStudyResponses);
        }
      });
  }

  const selfStudyResponseRankingExport = () => {
    window.open(Routes.generateFull(Routes.SELF_STUDY_RANKING_REPORT, {}, { academicYear: props.academicYear }), '_blank')
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());

  actionButtons.push(DataTableButtonUtil.Default('Export', selfStudyResponseRankingExport));

  if (loading) {
    return <Skeleton active />
  }
  else {
    return (<>

      <DataTable
        // @ts-ignore
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => SelfStudyApiService.getSelfStudyReportTableData(
            requestState,
            props.academicYear == 0 ? 0 : props.academicYear ?? 0)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'self_study_list' }} />
    </>
    );
  }
}

export default SelfStudyRankingReportDataTable;
