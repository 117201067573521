import * as React from 'react';
import IowaVariationApiService from '../../../api/IowaVariationApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import IowaVariationDataTableDTO from '../../../models/IowaVariationDataTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { ColumnType, DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import DataTableCellRendererUtil from '../core/DataTableCellRendererUtil';

interface IowaVariationDataTableProps extends BaseFormProps {
}

interface IowaVariationDataTableState extends BaseDataTableState<IowaVariationDataTableDTO>, BaseFormState {
}

class IowaVariationDataTable extends React.Component<IowaVariationDataTableProps, IowaVariationDataTableState> {
  private _dataTable: DataTable<IowaVariationDataTableDTO> | undefined;

  constructor(props: IowaVariationDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Order',
        dataIndex: IowaVariationDataTableDTO.order,
        sorter: true,
        filterType: FilterType.Text,
        width: 15
      },
      {
        title: 'Code',
        dataIndex: IowaVariationDataTableDTO.code,
        sorter: true,
        filterType: FilterType.Text,
        width: 15
      },
      {
        title: 'Name',
        dataIndex: IowaVariationDataTableDTO.name,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Special Use',
        dataIndex: IowaVariationDataTableDTO.specialUse,
        sorter: true,
        filterType: FilterType.BooleanRadio,
        columnType: ColumnType.Boolean,
        render: DataTableCellRendererUtil.BooleanYesEmpty,
        width: 35
      }
    ] as DataTableColumnProps<any>[];
  };

  private openIowaVariation = (record: IowaVariationDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.IOWA_VARIATION_DETAILS, { id: record.id }, {}));
    }
  }

  private addIowaVariation = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_IOWA_VARIATION));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return IowaVariationApiService.getIowaVariationDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Iowa_Variation_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_IOWA_VARIATION])) {
      actionButtons.push(DataTableButtonUtil.Default('New Iowa Variation', () => this.addIowaVariation()));
    }

    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openIowaVariation}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 600 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => IowaVariationApiService.getIowaVariationTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'iowa_varaiation_list' }} />
    );
  }
}

export default IowaVariationDataTable;
