import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import Modal from 'antd/lib/modal';
import * as React from 'react';
import { useEffect, useState } from 'react';
import UserRegionalPlanningPartnerAssignmentApiService from '../../../api/UserRegionalPlanningPartnerAssignmentApiService';
import AddRegionalPlanningPartnerToUserForm from '../../../components/forms/secondary/AddRegionalPlanningPartnerToUserForm';
import EditRegionalPlanningPartnerRoleForm from '../../../components/forms/secondary/EditRegionalPlanningPartnerRoleForm';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as UnassignUserRegionalPlanningPartnerAssignmentHandler from '../../../handlerModels/UnassignUserRegionalPlanningPartnerAssignmentHandler';
import * as GetAllRegionalPlanningPartnersHandler from '../../../handlerModels/GetRegionalPlanningPartnersByAcademicYearHandler';
import UserRegionalPlanningPartnerAssignmentDTO from '../../../models/UserRegionalPlanningPartnerAssignmentDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import StringUtil from '../../../utils/StringUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import AddUserToRPPForm from '../../forms/secondary/AddUserToRPPForm';
import Validator from '../../../models/Validator';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';

interface UserRegionalPlanningPartnerDataTableProps {
  userId?: string;
  regionalPlanningPartner?: number;
}

function UserRegionalPlanningPartnerDataTable(props: UserRegionalPlanningPartnerDataTableProps) {
  const _rppAddUserRef = React.useRef<any>();
  const _rppEditRppRolesRef = React.useRef<any>();
  const _addRppRef = React.useRef<any>();

  let _dataTable: DataTable<UserRegionalPlanningPartnerAssignmentDTO> | undefined = undefined;

  const [showAddRppModal, setShowAddRppModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditRppRolesModal, setShowEditRppRolesModal] = useState(false);
  const [rpps, setRpps] = useState<RegionalPlanningPartnerDTO[]>([]);

  const [editRppRolesData, setEditRppRolesData] = useState<UserRegionalPlanningPartnerAssignmentDTO | null>(null);
  const [fieldErrors] = useState({} as ({ [key: string]: Validator[]; } | null));

  useEffect(() => {
    RegionalPlanningPartnerApiService.getAllRpps()
      .then((results: GetAllRegionalPlanningPartnersHandler.Result) => {
        if (results) {
          setRpps(results.regions ?? []);
        }
      })
  }, []);

  const getColumnDefinitions = () => {
    const columns = [
    ] as DataTableColumnProps<any>[];

    if (props.regionalPlanningPartner) {
      columns.pushAll([{
        title: 'First Name',
        dataIndex: UserRegionalPlanningPartnerAssignmentDTO.firstName,
        sorter: true,
        render: (data: string, row: UserRegionalPlanningPartnerAssignmentDTO) => {
          return row.firstName;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Last Name',
        dataIndex: UserRegionalPlanningPartnerAssignmentDTO.lastName,
        sorter: true,
        render: (data: string, row: UserRegionalPlanningPartnerAssignmentDTO) => {
          return row.lastName;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Email',
        dataIndex: UserRegionalPlanningPartnerAssignmentDTO.email,
      }])
    }
    else {
      columns.pushAll([
        {
          title: 'RPP',
          dataIndex: UserRegionalPlanningPartnerAssignmentDTO.regionalPlanningPartnerIdentifier,
          width: 180,
          render: (data: string, row: UserRegionalPlanningPartnerAssignmentDTO) => {
            return rpps.find(x => x.regionalPlanningPartnerIdentifier === row.regionalPlanningPartnerIdentifier)?.name;
          },
        },])
    }


    columns.pushAll([
      {
        title: 'Roles',
        dataIndex: UserRegionalPlanningPartnerAssignmentDTO.roles,
        render: (value: string[]) => {
          if (value) {
            return StringUtil.newLineFormat(value, true);
          }
        },
      },
      {
        title: 'Actions',
        dataIndex: UserRegionalPlanningPartnerAssignmentDTO.className,
        render: (data: string, row: UserRegionalPlanningPartnerAssignmentDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_RPP_USERS])) {
            return <>
              <Button type="link" onClick={() => openEditRppRoles(row)}><FormOutlined /></Button>
              <Button type="link" className="ant-btn" onClick={() => promptConfirmRemoveRpp(row)}><DeleteOutlined /></Button>
            </>
          }
        },
        sorter: false,
        filterType: FilterType.NONE,
        width: 50
      }])

    return columns;
  };

  const refreshDataTable = () => {
    _dataTable?.refresh()
  }

  const openEditRppRoles = (record: UserRegionalPlanningPartnerAssignmentDTO) => {
    setEditRppRolesData(record);
    setShowEditRppRolesModal(true);
  }

  const closeEditRppRolesModal = () => {
    setEditRppRolesData(null);
    setShowEditRppRolesModal(false);
    refreshDataTable();
    _rppEditRppRolesRef?.current?.reset();
  }

  const onEditRppRolesSubmit = () => {
    _rppEditRppRolesRef?.current?.handleSubmit(editRppRolesData)
  }

  const handleEditRppRolesSubmit = () => {
    closeEditRppRolesModal();
  }

  const renderEditRppRoles = () => {
    const title = rpps.find(x => x.regionalPlanningPartnerIdentifier === editRppRolesData?.regionalPlanningPartnerIdentifier)?.name;
    return (
      <Modal
        visible={showEditRppRolesModal}
        title={'Edit ' + title + ' Roles'}
        onCancel={closeEditRppRolesModal}
        onOk={onEditRppRolesSubmit}
        okText='Submit'>
        <EditRegionalPlanningPartnerRoleForm
          ref={_rppEditRppRolesRef}
          userId={props.userId ?? Guid.Empty()}
          rppId={editRppRolesData?.regionalPlanningPartnerIdentifier ?? 0}
          onSubmit={handleEditRppRolesSubmit} />
      </Modal>
    );
  }

  const openAddUserModal = () => {
    setShowAddUserModal(true);
  }

  const closeAddUserModal = () => {
    setShowAddUserModal(false);
    refreshDataTable();
    _rppAddUserRef?.current?.reset();
  }

  const onAddUserSubmit = () => {
    _rppAddUserRef?.current?.handleSubmit();
  }

  const handleAddUserSubmit = () => {
    closeAddUserModal();
  }

  const renderAddUserModal = () => {
    return (
      <Modal
        visible={showAddUserModal}
        title='Add User'
        okText='Submit'
        onOk={onAddUserSubmit}
        onCancel={closeAddUserModal}>
        <AddUserToRPPForm
          ref={_rppAddUserRef}
          fieldErrors={fieldErrors}
          onSubmit={handleAddUserSubmit}
          userId={props.userId}
          rppIdentifier={props.regionalPlanningPartner ?? 0}
        />
      </Modal>
    );
  }

  const openAddRppModal = () => {
    setShowAddRppModal(true);
  }

  const closeAddRppModal = () => {
    setShowAddRppModal(false);
    refreshDataTable();
    _addRppRef?.current?.reset();
  }

  const onAddRppSubmit = () => {
    _addRppRef?.current?.handleSubmit()
  }

  const handleAddRppSubmit = () => {
    closeAddRppModal();
  }

  const renderAddRppModal = () => {
    return (
      <Modal
        visible={showAddRppModal}
        title='Add RPP User'
        okText='Submit'
        onOk={onAddRppSubmit}
        onCancel={closeAddRppModal}>
        <AddRegionalPlanningPartnerToUserForm
          ref={_addRppRef}
          userId={props.userId ?? Guid.Empty()}
          onSubmit={handleAddRppSubmit} />
      </Modal>
    );
  }

  const handleRemoveRPP = (rpp: UserRegionalPlanningPartnerAssignmentDTO) => {
    const request = UnassignUserRegionalPlanningPartnerAssignmentHandler.Request.create({
      regionalPlanningPartnerIdentifier: rpp.regionalPlanningPartnerIdentifier,
      userId: rpp.userId,
    });

    UserRegionalPlanningPartnerAssignmentApiService.unassignRegionalPartnerPlanningUser(request)
      .then((result: UnassignUserRegionalPlanningPartnerAssignmentHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('User has been unassigned.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Could Not Unassign.');
      })
      .finally(() => {
        refreshDataTable();
      });
  }

  const promptConfirmRemoveRpp = (rppId: UserRegionalPlanningPartnerAssignmentDTO) => {
    Modal.confirm({
      title: 'Unassign User?',
      content: 'Are you sure you want to unassign this user?',
      okText: 'Unassign User',
      okButtonProps: { type: 'primary', danger: true },
      onOk: () => handleRemoveRPP(rppId),
      width: 500
    });
  }

  const actionButtons = [];

  if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_RPP_USERS]) && props.userId) {
    actionButtons.push(DataTableButtonUtil.Default('Add RPP', openAddRppModal));
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_RPP_USERS]) && props.regionalPlanningPartner) {
    actionButtons.push(DataTableButtonUtil.Default('Add User', openAddUserModal));
  }

  return (
    <>
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => UserRegionalPlanningPartnerAssignmentApiService.getUserRegionalPlanningPartnerAssignmentTableData(requestState, props.userId ?? Guid.Empty(), props.regionalPlanningPartner ?? 0)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'rpp_user_list' }} />
      {renderAddRppModal()}
      {renderEditRppRoles()}
      {renderAddUserModal()}
    </>
  );
}

export default UserRegionalPlanningPartnerDataTable;
