// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ServiceAreaAssignmentDTO from '../models/ServiceAreaAssignmentDTO';
import * as AddServiceAreaAssigmentHandler from '../handlerModels/AddServiceAreaAssigmentHandler';
import * as RemoveServiceAreaAssignmentHandler from '../handlerModels/RemoveServiceAreaAssignmentHandler';

export class ServiceAreaAssignmentApiService extends BaseApi {

    // post: api/serviceAreaAssignments?userId=${encodeURIComponent(userId)}
    public getServiceAreaAssignmentTableData(tableRequest: TableRequestDTO, userId: string): Promise<TableResponseDTO<ServiceAreaAssignmentDTO>> {
        let url = `api/serviceAreaAssignments`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ServiceAreaAssignmentDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/serviceAreaAssignments/save
    public addServiceAreaAssignment(addServiceAreaAssigment: AddServiceAreaAssigmentHandler.Request): Promise<AddServiceAreaAssigmentHandler.Result> {
        let url = `api/serviceAreaAssignments/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddServiceAreaAssigmentHandler.Result>(addServiceAreaAssigment, 'post', url, true, false);
    }

    // post: api/serviceAreaAssignments/delete
    public removeServiceAreaAssignment(removeServiceAreaAssignment: RemoveServiceAreaAssignmentHandler.Request): Promise<RemoveServiceAreaAssignmentHandler.Result> {
        let url = `api/serviceAreaAssignments/delete`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveServiceAreaAssignmentHandler.Result>(removeServiceAreaAssignment, 'post', url, true, false);
    }
}
var service = new ServiceAreaAssignmentApiService();
export default service;
