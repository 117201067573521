import { RightOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import * as React from 'react';

interface ConfirmAndContinueButtonProps extends ButtonProps {
  title?: string;
}

function ConfirmAndContinueButton(props: ConfirmAndContinueButtonProps) {
  return (
    <Button
      {...props}
      htmlType={props.htmlType ? props.htmlType : 'button'}
      type={props.type ? props.type : 'primary'}
      icon={<RightOutlined />}>
      {props.title ?? 'Confirm and Continue'}
    </Button>
  );
}

export default ConfirmAndContinueButton;