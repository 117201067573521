// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// ProgramsForReviewHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYear: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYear: 0,
        },
        initValues);
    },
    academicYear: 'academicYear',
}

interface Result extends ResultDTO {
    totalPrograms: number;
    approvedPrograms: number;
    conditionalPrograms: number;
    extendedPrograms: number;
    newPrograms: number;
    pendingPrograms: number;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            totalPrograms: 0,
            approvedPrograms: 0,
            conditionalPrograms: 0,
            extendedPrograms: 0,
            newPrograms: 0,
            pendingPrograms: 0,
        },
        initValues);
    },
    totalPrograms: 'totalPrograms',
    approvedPrograms: 'approvedPrograms',
    conditionalPrograms: 'conditionalPrograms',
    extendedPrograms: 'extendedPrograms',
    newPrograms: 'newPrograms',
    pendingPrograms: 'pendingPrograms',
}

export { Request, Result };