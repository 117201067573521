// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SearchForDistrictsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import DistrictDTO from '../models/DistrictDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    searchValue: string | null;
    academicYear: number;
    districtSearch: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            searchValue: "",
            academicYear: 0,
            districtSearch: 0,
        },
        initValues);
    },
    searchValue: 'searchValue',
    academicYear: 'academicYear',
    districtSearch: 'districtSearch',
}

interface Result extends ResultDTO {
    districts: DistrictDTO[] | null;
    districtSearch: number;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            districts: [],
            districtSearch: 0,
        },
        initValues);
    },
    districts: 'districts',
    districtSearch: 'districtSearch',
}

export { Request, Result };