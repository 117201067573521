// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as SaveNewDisciplineChangeRequestHandler from '../handlerModels/SaveNewDisciplineChangeRequestHandler';
import * as SubmitNewDisciplineChangeRequestStep1Handler from '../handlerModels/SubmitNewDisciplineChangeRequestStep1Handler';
import * as GetNewDisciplineChangeRequestHandler from '../handlerModels/GetNewDisciplineChangeRequestHandler';

export class NewDisciplineChangeRequestApiService extends BaseApi {

    // post: api/NewDisciplineChangeRequests/save
    public save(saveNewDisciplineChangeRequest: SaveNewDisciplineChangeRequestHandler.Request): Promise<SaveNewDisciplineChangeRequestHandler.Result> {
        let url = `api/NewDisciplineChangeRequests/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewDisciplineChangeRequestHandler.Result>(saveNewDisciplineChangeRequest, 'post', url, true, false);
    }

    // post: api/NewDisciplineChangeRequests/submit
    public submit(submitNewDisciplineChangeRequestStep1: SubmitNewDisciplineChangeRequestStep1Handler.Request): Promise<SubmitNewDisciplineChangeRequestStep1Handler.Result> {
        let url = `api/NewDisciplineChangeRequests/submit`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewDisciplineChangeRequestStep1Handler.Result>(submitNewDisciplineChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/NewDisciplineChangeRequests/get
    public get(getNewDisciplineChangeRequest: GetNewDisciplineChangeRequestHandler.Request): Promise<GetNewDisciplineChangeRequestHandler.Result> {
        let url = `api/NewDisciplineChangeRequests/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewDisciplineChangeRequestHandler.Result>(getNewDisciplineChangeRequest, 'post', url, true, false);
    }
}
var service = new NewDisciplineChangeRequestApiService();
export default service;
