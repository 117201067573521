import { MoreOutlined } from '@ant-design/icons';
import * as antd from 'antd';
import { Card, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import DistrictsDataTable from '../../../components/datatables/secondary/DistrictsDataTable';
import UserRegionalPlanningPartnerDataTable from '../../../components/datatables/secondary/UserRegionalPlanningPartnerDataTable';
import RegionalPlanningPartnerDetailsForm from '../../../components/forms/secondary/RegionalPlanningPartnerDetailsForm';
import Dropdown from '../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteRegionalPlanningPartnerHandler from '../../../handlerModels/DeleteRegionalPlanningPartnerHandler';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import * as GetRegionalPlanningPartnersByAcademicYearHandler from '../../../handlerModels/GetRegionalPlanningPartnersByAcademicYearHandler';
import * as GetRegionPlanningPartnerDetailsHandler from '../../../handlerModels/GetRegionPlanningPartnerDetailsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface RegionalPlanningPartnerDetailsPageState extends BaseFormState {
  academicYear: number;
  rppId: string;
  rpp: RegionalPlanningPartnerDTO;
  academicYears: AcademicYearDTO[];
  rpps: RegionalPlanningPartnerDTO[];
  activeTab: string;
}

interface RegionalPlanningPartnerDetailsPageProps {
}

class RegionalPlanningPartnerDetailsPage extends React.Component<RegionalPlanningPartnerDetailsPageProps & RouteComponentProps<RouteObject>, RegionalPlanningPartnerDetailsPageState> {
  private readonly _rppDetailRef = React.createRef<RegionalPlanningPartnerDetailsForm>();

  constructor(props: RegionalPlanningPartnerDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0,
      rppId: Guid.Empty(),
      academicYears: [],
      rpp: RegionalPlanningPartnerDTO.create(),
      rpps: [],
      activeTab: 'details'
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');
    const academicYear = ParameterUtil.getParameter('academicYear');
    const activeTab = ParameterUtil.getParameter('activeTab', 'details');
    this.setState({ academicYear: Number.parseInt(academicYear), activeTab: activeTab });
    if (this.props.match.path.split('/').findIndex(x => x == 'new') > -1) {
      this.setState({ isEditing: true, isNew: true, loading: false, academicYear: Number.parseInt(academicYear) });
      const loaders = [];
      loaders.push(this.loadAcademicYears());
      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
    else {
      const loaders = [];
      loaders.push(this.loadRPP(id));
      loaders.push(this.loadAcademicYears());

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  private loadRpps = (rppId: any, academicYear: any) => {
    const request = GetRegionalPlanningPartnersByAcademicYearHandler.Request.create({
      academicYearId: academicYear,
      regionalPlanningPartnerIdentifier: rppId
    });

    RegionalPlanningPartnerApiService.getRegionalPlanningPartnersByAcademicYear(request)
      .then((results: GetRegionalPlanningPartnersByAcademicYearHandler.Result) => {
        if (results) {
          this.setState({
            rpps: results.regions ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadRPP = (id: string, academicYear?: any, rppId?: any) => {
    const request = GetRegionPlanningPartnerDetailsHandler.Request.create({
      id: id,
      rppId: rppId,
      academicYear: academicYear
    });

    RegionalPlanningPartnerApiService.getRegionDetails(request)
      .then((results: GetRegionPlanningPartnerDetailsHandler.Result) => {
        if (results.regionPlanningPartner) {
          this.setState({
            rpp: results.regionPlanningPartner,
            rppId: results.regionPlanningPartner.id ?? Guid.Empty(),
            isNew: false,
            isEditing: false
          });

          if (this.state.rpps && this.state.rpps.length == 0) {
            this.loadRpps(results.regionPlanningPartner.regionalPlanningPartnerIdentifier, this.state.academicYear);
          }
        }
        else {
          this.setState({
            isNew: true,
            isEditing: true
          }, () => this._rppDetailRef.current?.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAcademicYears = () => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  private deleteConfirmation = (id: string) => {
    antd.Modal.confirm({
      title: 'Are you sure you want to delete this Regional Planning Partner?',
      okText: 'Delete',
      onOk: () => this.deleteRPP(id)
    });
  }

  private deleteRPP = (rppId: string) => {
    this.setState({ submitting: true });

    const request = DeleteRegionalPlanningPartnerHandler.Request.create({
      regionalPlanningPartnerId: rppId,
    });

    RegionalPlanningPartnerApiService.deleteRegionalPlanningPartner(request)
      .then((result: DeleteRegionalPlanningPartnerHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          antd.message.success('Regional Planning Partner was removed.');
        }
        else {
          antd.message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        antd.message.error('Regional Planning Partner could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToListing();
      });
  }

  private onSelect = (value: any) => {
    this.setState({ academicYear: value, rppId: Guid.Empty() });
    this.loadRPP(Guid.Empty(), value, this.state.rpp.regionalPlanningPartnerIdentifier);
  }

  private goToListing = () => {
    HistoryUtil.goBack();
  }

  private onSave = (rppId: string) => {
    this.setState({ isEditing: false, isNew: false });
    if (rppId != this.state.rppId) {
      HistoryUtil.push(Routes.generate(Routes.REGIONAL_PLANNING_PARTNER_DETAIL, { id: rppId }, { academicYear: this.state.academicYear }));
      this.loadRPP(rppId);
    }
    else {
      this.loadRPP(rppId);
    }
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._rppDetailRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab, academicYear: this.state.academicYear }), { activeTab: activeTab, academicYear: this.state.academicYear });
  }

  render() {
    const title = this.state.loading ? '...' : this.state.isNew ? 'New Regional Planning Partner' : this.state.rpp?.name ?? '...';
    const configuration = (ParameterUtil.getParameter('configuration') == 'true');

    return (
      <Content>
        <HeaderPortal
          title={title}
          onBack={this.goToListing}
          extra={this.renderMoreActions()}
          breadcrumbs={configuration ? Breadcrumbs.regionConfigurationDetailPage(this.state.rpp.name ?? 'New Regional Planning Partner', this.state.rppId) : Breadcrumbs.regionDetailPage(this.state.rpp.name ?? 'New Regional Planning Partner', this.state.rppId)}
          footer={<Tabs activeKey={this.state.activeTab} onChange={this.changeTab} >
            <Tabs.TabPane tab="Details" key="details" />
            <Tabs.TabPane tab="Users" key="users" />
            <Tabs.TabPane tab="Districts" key="districts" />
          </Tabs>
          } />

        {this.renderDetailsCard()}
        {this.renderRppUsersCard()}
        {this.renderRppDistrict()}
      </Content>
    );
  }

  renderDetailsCard() {
    if (this.state.activeTab == 'details') {
      const editButton = this.state.rppId == Guid.Empty() ?
        null :
        (
          <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER]}>
            <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
          </AuthorizedContent>
        );

      return (
        <Card title="Details" extra={editButton} >
          <RegionalPlanningPartnerDetailsForm
            regionId={this.state.rppId}
            academicYear={this.state.academicYear ?? 0}
            isEditing={this.state.isEditing}
            onSave={this.onSave} />
        </Card>
      );
    }
  }

  renderRppUsersCard() {
    if (this.state.activeTab === 'users') {
      if (this.state.rpp) {
        return (
          <Card title="Rpp Users">
            <UserRegionalPlanningPartnerDataTable
              regionalPlanningPartner={this.state.rpp.regionalPlanningPartnerIdentifier} />
          </Card >
        );
      }
    }
  }

  renderRppDistrict() {
    if (this.state.activeTab === 'districts') {
      return (
        <Card title="Districts">
          <DistrictsDataTable
            rppId={this.state.rppId}
            academicYear={this.state.academicYear}
            isRPP={true} />
        </Card >
      );
    }
  }

  renderMoreActions() {
    const menu = (
      <antd.Menu>
        < antd.Menu.Item title='Delete Regional Planning Partner' key={this.state.rppId} onClick={() => this.deleteConfirmation(this.state.rppId)}  >
          Delete Regional Planning Partner
        </antd.Menu.Item>
      </antd.Menu>
    );
    return (
      <>
        <label title={'academicYear'} htmlFor={'academicYear'} />
        <Dropdown
          id='academicYear'
          value={this.state.academicYear}
          onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.rpps.map(x => { return this.renderSecondaryCoursesAcademicYear(x); })}</Dropdown>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_COURSE]}>
          {this.state.isNew ? null
            : <antd.Dropdown overlay={menu}>
              <antd.Button size='large' type='link' >
                <MoreOutlined />
              </antd.Button>
            </antd.Dropdown>}
        </AuthorizedContent>
      </>
    );
  }

  renderSecondaryCoursesAcademicYear(rppAcademicYear: RegionalPlanningPartnerDTO) {
    return <antd.Select.Option key={rppAcademicYear.regionalPlanningPartnerIdentifier} value={rppAcademicYear.academicYear}>{rppAcademicYear.academicYear}</antd.Select.Option>
  }
}

export default withRouter((RegionalPlanningPartnerDetailsPage));
