import { Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { BaseType } from 'antd/lib/typography/Base';
import * as React from 'react';
import StringUtil from '../../utils/StringUtil';

interface LabeledValueState { }

interface LabeledValueProps {
  value?: any;
  text?: any
  label?: any;
  children?: any;
  ellipsis?: boolean;
  type?: BaseType;
}

class LabeledValue extends React.Component<LabeledValueProps, LabeledValueState> {
  constructor(props: LabeledValueProps) {
    super(props);

  }

  render() {
    let text = this.props.text || this.props.text == 0 ? this.props.text : this.props.value;
    text = this.props.ellipsis ? StringUtil.ellipsisCharacterLimit(text, 30) : text;

    return (
      <Content>
        <Space direction='vertical'>
          {this.props.label}

          {this.props.children ? this.props.children : <Typography.Text type={this.props.type} strong={true}> {text} </ Typography.Text>}
        </Space>
      </Content>

    );
  }
}

export default LabeledValue;
