import { Card } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ResetPasswordForm from '../../components/forms/ResetPasswordForm';
import BaseFormState from '../../redux/bases/BaseFormState';
import Guid from '../../utils/Guid';
import ParameterUtil from '../../utils/ParameterUtil';

interface ResetPasswordState extends BaseFormState {
  userId: string;
  code: string;
}

interface ResetPasswordProps {
}

class ResetPasswordPage extends React.Component<ResetPasswordProps & RouteComponentProps<RouteObject>, ResetPasswordState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: ResetPasswordProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      userId: Guid.Empty(),
      code: ''
    };
  }

  componentDidMount() {
    const code = ParameterUtil.getParameter('code');
    const userId = ParameterUtil.getParameter('userId');

    this.setState({
      code: code,
      userId: userId
    });
  }

  render() {
    return (
      <Card>
        <ResetPasswordForm code={this.state.code} userId={this.state.userId} isEditing={true} />
      </Card>
    )
  }
}

export default withRouter(ResetPasswordPage);
