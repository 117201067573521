// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CourseDTO from './CourseDTO';
import YearTermDTO from './YearTermDTO';
import InstitutionDTO from './InstitutionDTO';
import InstitutionCourseDetailDTO from './InstitutionCourseDetailDTO';
import StatusTypeDTO from './StatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionCourseDTO extends EntitySelectorDTO { 
	id: string | null;
	courseId: string | null;
	course: CourseDTO | null;
	install: YearTermDTO | null;
	retire: YearTermDTO | null;
	institutionId: string | null;
	institution: InstitutionDTO | null;
	institutionCourseDetails: InstitutionCourseDetailDTO[] | null;
	deletedOn: moment.Moment | string | null;
	installDisplay: string | null;
	currentDetail: InstitutionCourseDetailDTO | null;
	status: StatusTypeDTO | null;
}

const InstitutionCourseDTO = {
    className: 'InstitutionCourseDTO',
    create: (initValues?: Partial<InstitutionCourseDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			courseId: '00000000-0000-0000-0000-000000000000',
			course: null,
			install: null,
			retire: null,
			institutionId: '00000000-0000-0000-0000-000000000000',
			institution: null,
			institutionCourseDetails: [],
			deletedOn: null,
			installDisplay: "",
			currentDetail: null,
			status: null,
        },
        initValues);
    },
	id: 'id',
	courseId: 'courseId',
	course: 'course',
	install: 'install',
	retire: 'retire',
	institutionId: 'institutionId',
	institution: 'institution',
	institutionCourseDetails: 'institutionCourseDetails',
	deletedOn: 'deletedOn',
	installDisplay: 'installDisplay',
	currentDetail: 'currentDetail',
	status: 'status',
};

export default InstitutionCourseDTO;