import { Alert, Input, message, Skeleton, Space, Switch } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionLocationApiService from '../../../api/InstitutionLocationApiService';
import * as GetInstitutionLocationDetailsHandler from '../../../handlerModels/GetInstitutionLocationDetailsHandler';
import * as SaveInstitutionLocationHandler from '../../../handlerModels/SaveInstitutionLocationHandler';
import InstitutionLocationDTO from '../../../models/InstitutionLocationDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';

interface AddInstitutionLocationFormState extends BaseFormState {
  institutionLocationId: string;
  institutionLocation: InstitutionLocationDTO | null;
}

interface AddInstitutionLocationFormProps extends BaseFormProps {
  onSuccess?: () => void;
  institutionLocationId: string;
  institutionId: string;
}

class AddInstitutionLocationForm extends React.Component<AddInstitutionLocationFormProps, AddInstitutionLocationFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(InstitutionLocationDTO.isPrimary, {
      name: InstitutionLocationDTO.isPrimary,
      label: 'Primary Location',
    })
    .set(InstitutionLocationDTO.name, {
      required: true,
      name: InstitutionLocationDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.maxLength(150),
        ValidationRuleUtil.required(),
      ],
    })
    .set(InstitutionLocationDTO.street1, {
      required: true,
      name: InstitutionLocationDTO.street1,
      label: 'Address Line 1',
      rules: [
        ValidationRuleUtil.maxLength(150),
        ValidationRuleUtil.required(),
      ]
    })
    .set(InstitutionLocationDTO.street2, {
      name: InstitutionLocationDTO.street2,
      label: 'Address Line 2'
    })
    .set(InstitutionLocationDTO.city, {
      required: true,
      name: InstitutionLocationDTO.city,
      label: 'City',
      rules: [
        ValidationRuleUtil.required(),
      ]
    })
    .set(InstitutionLocationDTO.state, {
      required: true,
      name: InstitutionLocationDTO.state,
      label: 'State',
      rules: [
        ValidationRuleUtil.maxLength(2),
        ValidationRuleUtil.required(),
      ]
    })
    .set(InstitutionLocationDTO.zip, {
      required: true,
      name: InstitutionLocationDTO.zip,
      label: 'Zip Code',
      rules: [
        ValidationRuleUtil.required(),
      ]
    })
    .set(InstitutionLocationDTO.phone, {
      name: InstitutionLocationDTO.phone,
      label: 'Phone Number',
      rules: [
        ValidationRuleUtil.maxLength(12),
        ValidationRuleUtil.phoneNumberValidator(),
      ]
    })
    .set(InstitutionLocationDTO.fax, {
      name: InstitutionLocationDTO.fax,
      label: 'Fax Number',
      rules: [
        ValidationRuleUtil.maxLength(12),
        ValidationRuleUtil.phoneNumberValidator(),
      ]
    })

  constructor(props: AddInstitutionLocationFormProps) {
    super(props);

    this.state = {
      institutionLocationId: Guid.Empty(),
      institutionLocation: InstitutionLocationDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadInstitutionLocation();
  }

  componentDidUpdate(prevProps: AddInstitutionLocationFormProps) {
    if (this.props.institutionLocationId && this.props.institutionLocationId.isNotEmpty() && this.props.institutionLocationId != prevProps.institutionLocationId) {
      this.setState({ institutionLocationId: this.props.institutionLocationId });
      this.loadInstitutionLocation();
    }
  }

  private loadInstitutionLocation() {
    if (this.props.institutionLocationId == Guid.Empty()) {
      return this.loadNew();
    }
    else {
      return this.loadExisting();
    }
  }

  private loadExisting = () => {
    return InstitutionLocationApiService.getInstitutionLocationDetails(this.props.institutionLocationId)
      .then((results: GetInstitutionLocationDetailsHandler.Result) => {
        if (results.institutionLocation?.id) {
          this.setState({ institutionLocationId: results.institutionLocation.id, institutionLocation: results.institutionLocation });
        }

        this.reset();
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadNew = () => {
    this.setState({ institutionLocation: InstitutionLocationDTO.create() }, () => this.reset());
    this.setState({ loading: false });
  }

  public submit = () => {
    this.handleSubmit();
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    if (!FormUtil.canSubmit(this._formRef, this._formItems)) {
      message.error('Please fill out all required fields.');
      this.setState({ loading: false, submitting: false });
      return;
    }

    const request = SaveInstitutionLocationHandler.Request.create({
      institutionId: this.props.institutionId,
      institutionLocationId: this.state.institutionLocationId,
      institutionLocation: InstitutionLocationDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    InstitutionLocationApiService.saveInstitutionLocation(request)
      .then((result: SaveInstitutionLocationHandler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSuccess) {
            this.props.onSuccess();
            this.reset();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Location could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Location could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(InstitutionLocationDTO.name)}
            initialValue={this.state.institutionLocation?.name}
            {...ValidationUtil.getValidation(InstitutionLocationDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionLocationDTO.street1)}
            initialValue={this.state.institutionLocation?.street1}
            {...ValidationUtil.getValidation(InstitutionLocationDTO.street1, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionLocationDTO.street2)}
            initialValue={this.state.institutionLocation?.street2}
            {...ValidationUtil.getValidation(InstitutionLocationDTO.street2, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionLocationDTO.city)}
            initialValue={this.state.institutionLocation?.city}
            {...ValidationUtil.getValidation(InstitutionLocationDTO.city, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionLocationDTO.state)}
            initialValue={this.state.institutionLocation?.state}
            {...ValidationUtil.getValidation(InstitutionLocationDTO.state, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionLocationDTO.zip)}
            initialValue={this.state.institutionLocation?.zip}
            {...ValidationUtil.getValidation(InstitutionLocationDTO.zip, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionLocationDTO.phone)}
            initialValue={this.state.institutionLocation?.phone}
            {...ValidationUtil.getValidation(InstitutionLocationDTO.phone, this.state.fieldErrors, this.state.submitted)}>
            <Input placeholder='###-###-#####' disabled={this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionLocationDTO.fax)}
            initialValue={this.state.institutionLocation?.fax}
            {...ValidationUtil.getValidation(InstitutionLocationDTO.fax, this.state.fieldErrors, this.state.submitted)}>
            <Input placeholder='###-###-#####' disabled={this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionLocationDTO.isPrimary)}
            initialValue={this.state.institutionLocation?.isPrimary}
            {...ValidationUtil.getValidation(InstitutionLocationDTO.isPrimary, this.state.fieldErrors, this.state.submitted)}>
            <Switch defaultChecked={this.state.institutionLocation?.isPrimary} disabled={this.state.loading || this.state.submitting} />
          </FormItem>

        </Form >
      </Space >
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AddInstitutionLocationForm;
