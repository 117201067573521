// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetCourseModificationChangeRequestHandler from '../handlerModels/GetCourseModificationChangeRequestHandler';
import * as SaveCourseModificationChangeRequestStep1Handler from '../handlerModels/SaveCourseModificationChangeRequestStep1Handler';
import * as SubmitCourseModificationChangeRequestStep1Handler from '../handlerModels/SubmitCourseModificationChangeRequestStep1Handler';
import * as SaveCourseModificationChangeRequestStep2Handler from '../handlerModels/SaveCourseModificationChangeRequestStep2Handler';
import * as SubmitCourseModificationChangeRequestStep2Handler from '../handlerModels/SubmitCourseModificationChangeRequestStep2Handler';
import * as ExistingCourseModificationSearchHandler from '../handlerModels/ExistingCourseModificationSearchHandler';

export class CourseModificationChangeRequestApiService extends BaseApi {

    // post: api/courseModificationChangeRequests/get
    public get(getCourseModificationChangeRequest: GetCourseModificationChangeRequestHandler.Request): Promise<GetCourseModificationChangeRequestHandler.Result> {
        let url = `api/courseModificationChangeRequests/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCourseModificationChangeRequestHandler.Result>(getCourseModificationChangeRequest, 'post', url, true, false);
    }

    // post: api/courseModificationChangeRequests/saveStep1
    public saveStep1(saveCourseModificationChangeRequestStep1: SaveCourseModificationChangeRequestStep1Handler.Request): Promise<SaveCourseModificationChangeRequestStep1Handler.Result> {
        let url = `api/courseModificationChangeRequests/saveStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveCourseModificationChangeRequestStep1Handler.Result>(saveCourseModificationChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/courseModificationChangeRequests/submitStep1
    public submitStep1(submitCourseModificationChangeRequestStep1: SubmitCourseModificationChangeRequestStep1Handler.Request): Promise<SubmitCourseModificationChangeRequestStep1Handler.Result> {
        let url = `api/courseModificationChangeRequests/submitStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitCourseModificationChangeRequestStep1Handler.Result>(submitCourseModificationChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/courseModificationChangeRequests/saveStep2
    public saveStep2(saveCourseModificationChangeRequestStep2: SaveCourseModificationChangeRequestStep2Handler.Request): Promise<SaveCourseModificationChangeRequestStep2Handler.Result> {
        let url = `api/courseModificationChangeRequests/saveStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveCourseModificationChangeRequestStep2Handler.Result>(saveCourseModificationChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/courseModificationChangeRequests/submitStep2
    public submitStep2(submitCourseModificationChangeRequestStep2: SubmitCourseModificationChangeRequestStep2Handler.Request): Promise<SubmitCourseModificationChangeRequestStep2Handler.Result> {
        let url = `api/courseModificationChangeRequests/submitStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitCourseModificationChangeRequestStep2Handler.Result>(submitCourseModificationChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/courseModificationChangeRequests/checkCourseModificationExisting
    public checkCourseModificationExisting(existingCourseModificationSearch: ExistingCourseModificationSearchHandler.Request): Promise<ExistingCourseModificationSearchHandler.Result> {
        let url = `api/courseModificationChangeRequests/checkCourseModificationExisting`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ExistingCourseModificationSearchHandler.Result>(existingCourseModificationSearch, 'post', url, true, false);
    }
}
var service = new CourseModificationChangeRequestApiService();
export default service;
