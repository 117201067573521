import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, InputNumber, message, Row, Select, Skeleton, Space, Table } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ChangeRequestTypeApprovalFlowApiService from '../../api/ChangeRequestTypeApprovalFlowApiService';
import { ColumnWidths } from '../../config/ColumnWidths';
import FeatureFlag from '../../consts/FeatureFlag';
import '../../extensions/StringExtensions';
import * as GetChangeRequestApprovalWorkflowDetailsHandler from '../../handlerModels/GetWorkflowDetailsHandler';
import * as SaveChangeRequestApprovalWorkflowDetailsHandler from '../../handlerModels/SaveWorkflowDetailsHandler';
import ChangeRequestTypeApprovalFlowDTO from '../../models/ChangeRequestTypeApprovalFlowDTO';
import ChangeRequestTypeApprovalFlowStepApprovalRoleDTO from '../../models/ChangeRequestTypeApprovalFlowStepApprovalRoleDTO';
import ChangeRequestTypeApprovalFlowStepDTO from '../../models/ChangeRequestTypeApprovalFlowStepDTO';
import RoleDTO from '../../models/RoleDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import LookupsUtil from '../../utils/LookupsUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import AuthorizedContent from '../AuthorizedContent';
import ResetButton from '../buttons/ResetButton';
import SaveButton from '../buttons/SaveButton';
import YesNoInput from '../inputs/YesNoInput';

interface WorkflowDetailsFormState extends BaseFormState {
  workflow: ChangeRequestTypeApprovalFlowDTO;
  roles: RoleDTO[];
}

interface WorkflowDetailsFormProps extends BaseFormProps {
  changeRequestTypeApprovalFlowId: number;
  onSave?: (id: number) => void;
}

class WorkflowDetailsForm extends React.Component<WorkflowDetailsFormProps, WorkflowDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getWorkflowFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(ChangeRequestTypeApprovalFlowStepDTO.stepNumber, {
        required: true,
        label: 'Step',
        name: [index, ChangeRequestTypeApprovalFlowStepDTO.stepNumber],
        rules: [ValidationRuleUtil.required()]
      })
      .set(ChangeRequestTypeApprovalFlowStepDTO.requireAll, {
        required: true,
        label: 'Require All Users',
        name: [index, ChangeRequestTypeApprovalFlowStepDTO.requireAll],
        rules: [ValidationRuleUtil.required()]
      })
      .set(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.roleId, {
        required: true,
        name: [index, ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.roleId],
        rules: [ValidationRuleUtil.required()]
      })
      .set(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.requiredAll, {
        required: true,
        name: [index, ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.requiredAll],
        rules: [ValidationRuleUtil.required()]
      })
      .set(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.required, {
        required: true,
        name: [index, ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.required],
        rules: [ValidationRuleUtil.required()]
      })
      .set(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.matchCipNumber, {
        required: true,
        name: [index, ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.matchCipNumber],
        rules: [ValidationRuleUtil.required()]
      })
      .set(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.daysToReview, {
        name: [index, ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.daysToReview],
      })
  }

  private getColumnDefinitionsForWorkflow = (remove: any, stepIndex: number) => {

    return [
      {
        title: 'Role',
        dataIndex: ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.roleId,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getWorkflowFormItems(index).get(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.roleId)}
            {...ValidationUtil.getValidation(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.roleId + stepIndex + index, this.state.fieldErrors, this.state.submitted)}>
            <Select allowClear={true} dropdownMatchSelectWidth={false} disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>{this.state.roles.map(x => this.renderRoleOption(x))}</Select>
          </FormItem>
        },
      },
      {
        title: 'Required',
        dataIndex: ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.required,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getWorkflowFormItems(index).get(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.required)}
            {...ValidationUtil.getValidation(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.required + stepIndex + index, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
        },
      },
      {
        title: 'Require All Users',
        dataIndex: ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.requiredAll,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getWorkflowFormItems(index).get(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.requiredAll)}
            {...ValidationUtil.getValidation(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.requiredAll + stepIndex + index, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
        },
      },
      {
        title: 'Match CIP Number',
        dataIndex: ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.matchCipNumber,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getWorkflowFormItems(index).get(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.matchCipNumber)}
            {...ValidationUtil.getValidation(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.matchCipNumber + stepIndex + index, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
        },
      },
      {
        title: 'Days To Review',
        dataIndex: ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.daysToReview,
        render: (data: string, row: any, index: any) => {
          return <FormItem
            {...this.getWorkflowFormItems(index).get(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.daysToReview)}
            {...ValidationUtil.getValidation(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.daysToReview + stepIndex + index, this.state.fieldErrors, this.state.submitted)}>
            <InputNumber min={1} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
        },
      },
      {
        dataIndex: ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.changeRequestTypeId,
        render: (data: string, row: any, index: any) => {
          if (this.props.isEditing && !this.state.loading) {
            return this._formRef.current?.getFieldValue(ChangeRequestTypeApprovalFlowDTO.changeRequestTypeApprovalFlowSteps)[stepIndex]?.changeRequestTypeApprovalFlowStepApprovalRoles?.length <= 1 ?
              null : <Button type="link" icon={<DeleteOutlined />} onClick={() => remove(index)} disabled={!this.props.isEditing || this.state.loading || this.state.submitting}></Button>
          }
        },
      },
    ] as any[];
  };

  constructor(props: WorkflowDetailsFormProps) {
    super(props);

    this.state = {
      workflow: ChangeRequestTypeApprovalFlowDTO.create(),
      roles: []
    };
  }

  componentDidMount() {
    this.fetchData();
    this.resetForm();
  }

  componentDidUpdate(prevProps: WorkflowDetailsFormProps) {
    if (this.props.changeRequestTypeApprovalFlowId &&
      this.props.changeRequestTypeApprovalFlowId != prevProps.changeRequestTypeApprovalFlowId
    ) {

      this.fetchData();
      this.resetForm();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];
    if (this.state.roles.length == 0) {
      loaders.push(this.loadRoles());
    }

    if (this.props.changeRequestTypeApprovalFlowId != 0) {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }


  private loadRoles = () => {
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        if (results) {
          this.setState({ roles: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadExisting = () => {
    return ChangeRequestTypeApprovalFlowApiService.getChangeRequestApprovalWorkflowDetailsHandler(this.props.changeRequestTypeApprovalFlowId)
      .then((results: GetChangeRequestApprovalWorkflowDetailsHandler.Result) => {
        if (results.changeRequestApprovalWorkflow) {
          this.setState({
            workflow: results.changeRequestApprovalWorkflow
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleRemoveStep(stepIndex: number, remove: any) {
    remove(stepIndex);
    const form = this._formRef ? (this._formRef.current) : null;
    const workflowSteps = this._formRef ? (this._formRef.current)?.getFieldValue(ChangeRequestTypeApprovalFlowDTO.changeRequestTypeApprovalFlowSteps) : null

    if (workflowSteps) {
      for (let i = stepIndex; i < workflowSteps.length; i++) {
        workflowSteps[i].stepNumber -= 1;
      }
      form?.setFieldsValue({ changeRequestTypeApprovalFlowSteps: workflowSteps });
    }
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const form = ChangeRequestTypeApprovalFlowDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    form.changeRequestTypeId = this.props.changeRequestTypeApprovalFlowId;
    const request = SaveChangeRequestApprovalWorkflowDetailsHandler.Request.create({
      changeRequestTypeApprovalFlow: form
    });

    ChangeRequestTypeApprovalFlowApiService.saveChangeRequestApprovalWorkflowDetailsHandler(request)
      .then((result: SaveChangeRequestApprovalWorkflowDetailsHandler.Result | null) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({
            workflow: result.changeRequestTypeApprovalFlow ?? ChangeRequestTypeApprovalFlowDTO.create(),
            submitted: false,
            error: !result.succeeded
          });
          this._formRef?.current?.setFieldsValue(result.changeRequestTypeApprovalFlow)
          message.success('Saved');

          if (this.props.onSave) {
            this.resetForm();
            this.props.onSave(result.changeRequestTypeApprovalFlow?.changeRequestTypeId ?? 0);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Error Saving');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <PageStayPrompt when={this.state.altered} />
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.workflow}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <Space size="small" direction="vertical">
            <Form.List name={ChangeRequestTypeApprovalFlowDTO.changeRequestTypeApprovalFlowSteps}>
              {
                (steps, { add, remove }) => {
                  return (
                    <Space direction='vertical'>
                      {
                        steps.map((step, index) => {
                          if (step) {
                            return this.renderStep(step, index, remove);
                          }
                        })
                      }
                      {this.renderAdditionalStep(add)}
                    </Space>
                  );
                }
              }
            </Form.List>
            {this.renderSave()}
          </Space>
        </Form>
      </Space>
    );
  }

  renderRoleOption(role: RoleDTO) {
    if (role.id) {
      return <Select.Option key={role.id} value={role.id}>{role.name}</Select.Option>;
    }
  }

  renderStep(step: any, stepIndex: number, remove: any) {
    const stepNumber = 'Step ' + (stepIndex + 1);
    const removeStep = this.props.isEditing ? <Button type="link" icon={<DeleteOutlined />} onClick={() => this.handleRemoveStep(stepIndex, remove)} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} /> : null;
    const changeRequestTypeApprovalFlowSteps = this._formRef ? (this._formRef.current)?.getFieldValue(ChangeRequestTypeApprovalFlowDTO.changeRequestTypeApprovalFlowSteps) : null
    const maxStepNumber = changeRequestTypeApprovalFlowSteps ? changeRequestTypeApprovalFlowSteps.length : this.state.workflow.changeRequestTypeApprovalFlowSteps?.length;

    return (
      <Card type="inner" title={stepNumber} extra={removeStep}>
        <Row gutter={[16, 16]}>
          <Col {...ColumnWidths.ONE_QUARTER} >
            <FormItem
              {...this.getWorkflowFormItems(stepIndex).get(ChangeRequestTypeApprovalFlowStepDTO.stepNumber)}
              {...ValidationUtil.getValidation(ChangeRequestTypeApprovalFlowStepDTO.stepNumber + stepIndex, this.state.fieldErrors, this.state.submitted)}>
              <InputNumber min={1} max={maxStepNumber} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </FormItem>
          </Col>
          <Col {...ColumnWidths.ONE_QUARTER}>
            <FormItem
              {...this.getWorkflowFormItems(stepIndex).get(ChangeRequestTypeApprovalFlowStepDTO.requireAll)}
              {...ValidationUtil.getValidation(ChangeRequestTypeApprovalFlowStepDTO.requireAll + stepIndex, this.state.fieldErrors, this.state.submitted)}>
              <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </FormItem>
          </Col>
        </Row>

        <Form.List name={[stepIndex, ChangeRequestTypeApprovalFlowStepDTO.changeRequestTypeApprovalFlowStepApprovalRoles]}>
          {
            (roles, { add, remove }) => {
              return (
                <Space direction="vertical">
                  {this.renderRoles(roles, stepIndex, remove)}
                  {this.renderAdditionalRole(add)}
                </ Space>
              );
            }
          }
        </Form.List>
      </Card>
    );
  }

  renderRoles(roles: any, stepIndex: number, remove: any) {
    return (
      <Table
        columns={this.getColumnDefinitionsForWorkflow(remove, stepIndex)}
        dataSource={roles}
        pagination={false}
      ></Table>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_COURSE]} >
          <Space direction="horizontal" >
            <SaveButton disabled={!this.state.altered}
              saving={this.state.submitting} />
            <ResetButton

              disabled={!this.state.altered || this.state.submitting}
              onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderAdditionalStep(add: any) {
    if (this.props.isEditing && !this.state.loading) {
      const changeRequestTypeApprovalFlowSteps = this._formRef ? (this._formRef.current)?.getFieldValue(ChangeRequestTypeApprovalFlowDTO.changeRequestTypeApprovalFlowSteps) : null
      let stepNumber = 1;
      if (changeRequestTypeApprovalFlowSteps) {
        if (changeRequestTypeApprovalFlowSteps.length > 0) {
          stepNumber = Number.parseInt(changeRequestTypeApprovalFlowSteps[changeRequestTypeApprovalFlowSteps.length - 1].stepNumber) + 1;
        }
      }

      return (
        <Button onClick={() => add(ChangeRequestTypeApprovalFlowStepDTO.create({
          stepNumber: stepNumber,
          requireAll: null,
          changeRequestTypeApprovalFlowStepApprovalRoles: [ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.create({
            roleId: null,
            required: null,
            requiredAll: null,
            matchCipNumber: null,
            daysToReview: null
          })]
        }))} icon={<PlusOutlined />}>
          Add Additional Approval Step
        </Button>
      );
    }
  }

  renderAdditionalRole(add: any) {
    if (this.props.isEditing && !this.state.loading) {
      return (
        <Button onClick={() => add(ChangeRequestTypeApprovalFlowStepApprovalRoleDTO.create({
          roleId: null,
          required: null,
          requiredAll: null,
          matchCipNumber: null,
          daysToReview: null
        }))} icon={<PlusOutlined />}>
          Add Additional Approval Role
        </Button>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default WorkflowDetailsForm;
