import { RPPAction, RPPActionTypes } from '../actions/RPPActions';
import { RPPStoreState } from '../state/StateStoreModel';

export const InitialRPPStoreState: RPPStoreState = {
  Selected: null,
  RPPId: null
};

export function RegionalPlanningPartner(
  state: RPPStoreState = InitialRPPStoreState,
  action: RPPAction
): RPPStoreState {
  let newState: RPPStoreState = state;

  switch (action.type) {
    case RPPActionTypes.RPP_SELECT:
      newState = {
        ...state, Selected: action.data, RPPId: action.data?.regionPlanningPartnerIdentifier ?? null
      };
      break;

    default:
      return newState;
  }
  return Object.freeze(newState);
}
