import { DeleteOutlined, DownloadOutlined, FormOutlined } from '@ant-design/icons';
import { Alert, Button, message, Modal } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import SecondaryCourseApiService from '../../../api/SecondaryCourseApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteSecondaryCourseHandler from '../../../handlerModels/DeleteSecondaryCourseHandler';
import SecondaryCourseDTO from '../../../models/SecondaryCourseDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import SecondaryCourseDetailsModal from '../../modals/SecondaryCourseDetailsModal';
import SecondaryProgramCourseDetailsModal from '../../modals/SecondaryProgramCourseDetailsModal';
import SecondaryCoursesImporter from '../../secondary/SecondaryCourseImporter';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';


interface SecondaryCoursesDataTableProps {
  academicYear: number;
  districtId?: string;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  fromAcademicYearDataReview?: boolean;
  fromSecondaryProgramCourse?: boolean;
  programId?: number;
  secondaryProgramId?: string;
  isPublic?: boolean;
  errors?: string[];
  readonly?: boolean;
}

function SecondaryCoursesDataTable(props: SecondaryCoursesDataTableProps) {
  let _dataTable: DataTable<SecondaryCourseDTO> | undefined = undefined;
  const _secondaryCourseImporterRef = React.createRef<SecondaryCoursesImporter>();
  const [loading, setLoading] = useState(false);
  const [secondaryCourseId, setSecondaryCourseId] = useState<string | null>();
  const [errors, setErrors] = useState(props.errors);
  const [institutions, setInstitutions] = useState([] as TableFilterOption[]);
  const [secondaryProgramCourseId, setSecondaryProgramCourseId] = useState<string | null>();

  React.useEffect(() => {
    TableFilterUtil.Institutions()
      .then(x => {
        setInstitutions(x);
      });
  }, []);

  const getColumnDefinitions = () => {
    if (props.isPublic) {
      return [
        {
          title: 'Identifier',
          dataIndex: SecondaryCourseDTO.secondaryCourseId,
          sorter: true,
          width: 50,
          render: (data: string, row: SecondaryCourseDTO) => {
            return row.secondaryCourseId;
          },
          filterType: FilterType.Text
        },
        {
          title: 'Name',
          dataIndex: SecondaryCourseDTO.courseName,
          sorter: true,
          filterType: FilterType.Text
        },
        {
          title: 'SCED',
          dataIndex: SecondaryCourseDTO.sced,
          sorter: true,
          filterType: FilterType.Text
        },
        {
          title: 'Carnegie Unit',
          dataIndex: SecondaryCourseDTO.carnegieUnit,
          sorter: true,
          render: (data: string, row: SecondaryCourseDTO) => {
            return row.carnegieUnit;
          },
          filterType: FilterType.Text
        },
      ] as DataTableColumnProps<any>[];
    }

    const columns = [
      {
        title: 'Identifier',
        dataIndex: SecondaryCourseDTO.secondaryCourseId,
        sorter: true,
        width: 50,
        render: (data: string, row: SecondaryCourseDTO) => {
          return row.secondaryCourseId;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Title',
        dataIndex: SecondaryCourseDTO.courseName,
        sorter: true,
        render: (data: string, row: SecondaryCourseDTO) => {
          return row.courseName;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Local Course Number',
        dataIndex: SecondaryCourseDTO.localCourseNumber,
        width: 175,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'District',
        dataIndex: SecondaryCourseDTO.districtId,
        width: 175,
        sorter: true,
        render: (data: string, row: SecondaryCourseDTO) => {
          return row.district?.name;
        },
        filterType: FilterType.Text
      },
      {
        title: 'SCED',
        dataIndex: SecondaryCourseDTO.sced,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Carnegie Unit',
        dataIndex: SecondaryCourseDTO.carnegieUnit,
        sorter: true,
        width: 150,
        render: (data: string, row: SecondaryCourseDTO) => {
          return row.carnegieUnit;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Community College',
        dataIndex: SecondaryCourseDTO.communityCollegeId,
        sorter: true,
        width: 150,
        render: (data: string, row: SecondaryCourseDTO) => {
          return row.communityCollege?.display;
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: institutions
      },
      {
        title: 'Institution Course',
        dataIndex: SecondaryCourseDTO.institutionCourseId,
        sorter: true,
        width: 150,
        render: (data: string, row: SecondaryCourseDTO) => {
          if (row.institutionCourse) {
            return row.institutionCourse?.course?.discipline?.code + '' + row.institutionCourse?.course?.catalogNumber + ' - ' + row.institutionCourse?.currentDetail?.title;
          }
        },
        filterType: FilterType.Text
      },
      {
        title: 'Enrollment',
        dataIndex: SecondaryCourseDTO.enrollment,
        sorter: true,
        width: 150,
        render: (data: string, row: SecondaryCourseDTO) => {
          return row.enrollment;
        },
        filterType: FilterType.Text
      },
    ] as DataTableColumnProps<any>[];

    if (props.fromAcademicYearDataReview) {
      columns.push({
        title: 'Actions',
        align: 'center',
        dataIndex: SecondaryCourseDTO.secondaryCourseId,
        sorter: false,
        render: (data: string, row: SecondaryCourseDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => openSecondaryCourseDetailModal(row)}><FormOutlined /></Button>
              <Button type="link" onClick={() => promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 50
      });
    }

    return columns;
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openSecondaryCourseDetailModal = (record: SecondaryCourseDTO) => {
    if (record.id) {
      setSecondaryCourseId(record.id);
    }
  }

  const openSecondaryCourseDetail = (record: SecondaryCourseDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.SECONDARY_COURSE_DETAILS, { id: record.id }, { academicYear: props.academicYear, fromSecondaryProgramCourse: props.fromSecondaryProgramCourse }));
    }
  }

  const newSecondaryProgramCourse = () => {
    setSecondaryProgramCourseId(Guid.Empty());
  }

  const newSecondaryCourse = () => {
    setSecondaryCourseId(Guid.Empty());
  }

  const closeSecondaryCourseDetail = () => {
    setSecondaryCourseId(null);
  }

  const promptConfirmRemove = (secondaryCourseId: string | null) => {
    if (secondaryCourseId == null) {
      return;
    }

    Modal.confirm({
      title: 'Are you sure you want to delete this course?',
      okText: 'Delete',
      onOk: () => deleteSecondaryCourse(secondaryCourseId, props.academicYear),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteSecondaryCourse = (secondaryCourseId: string, academicYear: number) => {
    const request = DeleteSecondaryCourseHandler.Request.create({
      secondaryCourseId: secondaryCourseId,
      academicYearId: academicYear
    });

    SecondaryCourseApiService.deleteSecondaryCourse(request)
      .then((result: DeleteSecondaryCourseHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Course was removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Course could not be removed.');
      });
  }

  const openSecondaryCoursesModal = () => {
    setLoading(true);

    Modal.confirm({
      title: 'Import',
      content: <SecondaryCoursesImporter
        ref={_secondaryCourseImporterRef}
        academicYear={props.academicYear}
        refreshDataTable={_dataTable}
        isInModal={true}
        onSubmit={modalSubmit} />,
      onCancel: () => { setLoading(false); },
      onOk: () => _secondaryCourseImporterRef.current?.confirmImport(),
      width: 500
    });
  }

  const modalSubmit = (errors?: string[]) => {
    setLoading(false);
    setErrors(errors);
  }

  const downloadTemplate = () => {
    window.open(Routes.generateFull(Routes.SECONDARY_COURSE_IMPORT_TEMPLATE, {}, {}), '_blank')
  }

  const actionButtons = [];

  if (!props.readonly) {
    actionButtons.push(DataTableButtonUtil.Reset());
    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_COURSE]) && !props.programId) {
      actionButtons.push(DataTableButtonUtil.Default('New Course', () => newSecondaryCourse()));
    }

    if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR]) && !props.programId && props.fromAcademicYearDataReview) {
      actionButtons.push(DataTableButtonUtil.Default('Re-import Courses', () => openSecondaryCoursesModal(), undefined, loading));
    }

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_PROGRAM]) && props.fromAcademicYearDataReview) {
      actionButtons.push(DataTableButtonUtil.Default('Template', () => downloadTemplate(), <DownloadOutlined />, loading));
    }

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_COURSE]) && props.programId) {
      actionButtons.push(DataTableButtonUtil.Default('New Program Course', () => newSecondaryProgramCourse()));
    }
  }

  const renderModal = () => {
    if (secondaryCourseId) {
      return <SecondaryCourseDetailsModal
        onCancel={closeSecondaryCourseDetail}
        visible={true}
        secondaryCourseId={secondaryCourseId}
        academicYear={props.academicYear}
        onSave={closeSecondaryCourseDetail} />
    }
  }

  const closeSecondaryProgramCourseDetail = () => {
    setSecondaryProgramCourseId(null);
  }

  const renderAddProgramCourseModal = () => {
    if (secondaryProgramCourseId) {
      return <SecondaryProgramCourseDetailsModal
        onCancel={closeSecondaryProgramCourseDetail}
        visible={true}
        districtId={props.districtId}
        secondaryProgramId={props.secondaryProgramId}
        secondaryProgramCourseId={secondaryProgramCourseId}
        academicYear={props.academicYear}
        onSave={closeSecondaryProgramCourseDetail} />
    }
  }

  const renderErrors = () => {
    if (errors && errors.length > 0) {
      return <Alert showIcon={true} type='error' description={renderErrorMessages()} message='Error Importing' />
    }
  }

  const renderErrorMessages = () => {
    if (errors && errors.length > 0) {
      return <>{errors.map((e: any) => <p key={e}>{e}</p>)}</>
    }
  }

  return (
    <>
      {renderErrors()}
      {renderModal()}
      {renderAddProgramCourseModal()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        onRowClick={props.fromAcademicYearDataReview || props.readonly ? undefined : openSecondaryCourseDetail}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={props.readonly ? false : true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => SecondaryCourseApiService.getSecondaryCourseTableData(requestState, props.academicYear, props.secondaryProgramId ?? Guid.Empty(), props.isPublic ?? false)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'secondary_course_list' }} />
    </>
  );
}

export default SecondaryCoursesDataTable;
