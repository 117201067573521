import { DatePicker, Skeleton } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import * as GetAcademicYearHandler from '../../../handlerModels/GetAcademicYearHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ReadableNumberInput from '../../inputs/ReadableNumberInput';

interface AcademicYearFormState extends BaseFormState {
  academicYearId: number;
  academicYear: AcademicYearDTO | null;
}

interface AcademicYearFormProps extends BaseFormProps {
  academicYearId: number;
  submitted?: boolean;
  submitting?: boolean;
  readonly?: boolean;
}

class AcademicYearForm extends React.Component<AcademicYearFormProps, AcademicYearFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  _formItems = new Map<string, FormItemProps>()
    .set(AcademicYearDTO.programInfoYear, {
      required: true,
      name: AcademicYearDTO.programInfoYear,
      label: 'Program Information Year',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(AcademicYearDTO.instructorStartDate, {
      required: true,
      name: AcademicYearDTO.instructorStartDate,
      label: 'Instructor Review Submission',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(AcademicYearDTO.rppStartDate, {
      required: true,
      name: AcademicYearDTO.rppStartDate,
      label: 'RPP Review Submission',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(AcademicYearDTO.deStartDate, {
      required: true,
      name: AcademicYearDTO.deStartDate,
      label: 'Department of Education Consultant Review Submission',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(AcademicYearDTO.commentReleaseDate, {
      // required: true,
      name: AcademicYearDTO.commentReleaseDate,
      label: 'Comment Release',
      rules: [
        // ValidationRuleUtil.required(),
      ],
    })
    .set(AcademicYearDTO.finalizationDate, {
      required: true,
      name: AcademicYearDTO.finalizationDate,
      label: 'Program Review Year Finalization and Letter Send Out',
      rules: [
        ValidationRuleUtil.required(),
      ],
    });

  constructor(props: AcademicYearFormProps) {
    super(props);

    this.state = {
      academicYear: null,
      academicYearId: 0
    };
  }

  componentDidMount() {
    if (this.props.academicYearId && this.props.academicYearId != 0) {
      this.setState({ loading: true });
      this.loadAcademicYear();
      this.setState({ academicYearId: this.props.academicYearId, fieldErrors: this.props.fieldErrors });
    }
  }

  componentDidUpdate(prevProps: AcademicYearFormProps) {
    if (this.props.academicYearId && this.props.academicYearId != 0 && prevProps.academicYearId != this.props.academicYearId) {
      this.setState({ loading: true });
      this.loadAcademicYear();
      this.setState({ academicYearId: this.props.academicYearId, fieldErrors: this.props.fieldErrors });
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private cleanResultDates = (academicYear: AcademicYearDTO) => {
    if (academicYear != null) {

      academicYear.instructorStartDate = academicYear.instructorStartDate ? moment(academicYear.instructorStartDate) : '';
      academicYear.instructorEndDate = academicYear.instructorEndDate ? moment(academicYear.instructorEndDate) : '';
      academicYear.rppStartDate = academicYear.rppStartDate ? moment(academicYear.rppStartDate) : '';
      academicYear.rppEndDate = academicYear.rppEndDate ? moment(academicYear.rppEndDate) : '';
      academicYear.deStartDate = academicYear.deStartDate ? moment(academicYear.deStartDate) : '';
      academicYear.deEndDate = academicYear.deEndDate ? moment(academicYear.deEndDate) : '';
      academicYear.commentReleaseDate = academicYear.commentReleaseDate ? moment(academicYear.commentReleaseDate) : '';
      academicYear.finalizationDate = academicYear.finalizationDate ? moment(academicYear.finalizationDate) : '';
    }

    return academicYear;
  }

  private loadAcademicYear = () => {
    this.setState({ loading: true });
    const request = GetAcademicYearHandler.Request.create({
      academicYearId: this.props.academicYearId
    });

    return AcademicYearApiService.getAcademicYear(request)
      .then((results: GetAcademicYearHandler.Result) => {
        if (results && results.academicYear) {
          this.setState({
            academicYear: this.cleanResultDates(results.academicYear) ?? AcademicYearDTO.create(),
            academicYearId: this.props.academicYearId
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load Program Review Year.' });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  public getValue = () => {
    return this._formRef;
  }

  private createAcademicYearValue = (academicYear: AcademicYearDTO | null) => {
    if (academicYear) {
      const cleaned = {
        programInfoYear: academicYear.programInfoYear,
        instructorStartDate: academicYear.instructorStartDate && academicYear.instructorEndDate ? [moment(academicYear.instructorStartDate), moment(academicYear.instructorEndDate)] : '',
        rppStartDate: academicYear.rppStartDate && academicYear.rppEndDate ? [moment(academicYear.rppStartDate), moment(academicYear.rppEndDate)] : '',
        deStartDate: academicYear.deStartDate && academicYear.deEndDate ? [moment(academicYear.deStartDate), moment(academicYear.deEndDate)] : '',
        commentReleaseDate: academicYear.commentReleaseDate ? moment(academicYear.commentReleaseDate) : '',
        finalizationDate: academicYear.finalizationDate ? moment(academicYear.finalizationDate) : '',
      };

      return cleaned;
    }

    return undefined;
  }

  render() {
    if (this.state.loading || this.state.loading == undefined) {
      return <Skeleton active={true} />;
    }

    const values = this.createAcademicYearValue(this.state.academicYear) ?? undefined;

    return (
      <Form ref={this._formRef}
        initialValues={values}
        layout="vertical"
        onValuesChange={this.handleChange}
        requiredMark={true}>
        <FormItem
          key={AcademicYearDTO.programInfoYear}
          {...this._formItems.get(AcademicYearDTO.programInfoYear)}
          {...ValidationUtil.getValidation(AcademicYearDTO.programInfoYear, this.props.fieldErrors, this.props.submitted || this.props.readonly)}>
          <ReadableNumberInput disabled={this.props.readonly || this.props.submitting || !this.props.isEditing} />
        </FormItem>

        <FormItem
          key={AcademicYearDTO.instructorStartDate}
          {...this._formItems.get(AcademicYearDTO.instructorStartDate)}
          {...ValidationUtil.getValidation(AcademicYearDTO.instructorStartDate, this.props.fieldErrors, this.props.submitted || this.props.readonly)}>
          <DatePicker.RangePicker format='M/D/YYYY' disabled={this.props.readonly || this.props.submitting || !this.props.isEditing} />
        </FormItem>

        <FormItem
          key={AcademicYearDTO.rppStartDate}
          {...this._formItems.get(AcademicYearDTO.rppStartDate)}
          {...ValidationUtil.getValidation(AcademicYearDTO.rppStartDate, this.props.fieldErrors, this.props.submitted || this.props.readonly)}>
          <DatePicker.RangePicker format='M/D/YYYY' disabled={this.props.readonly || this.props.submitting || !this.props.isEditing} />
        </FormItem>

        <FormItem
          key={AcademicYearDTO.deStartDate}
          {...this._formItems.get(AcademicYearDTO.deStartDate)}
          {...ValidationUtil.getValidation(AcademicYearDTO.deStartDate, this.props.fieldErrors, this.props.submitted || this.props.readonly)}>
          <DatePicker.RangePicker format='M/D/YYYY' disabled={this.props.readonly || this.props.submitting || !this.props.isEditing} />
        </FormItem>

        <FormItem
          key={AcademicYearDTO.commentReleaseDate}
          {...this._formItems.get(AcademicYearDTO.commentReleaseDate)}
          {...ValidationUtil.getValidation(AcademicYearDTO.commentReleaseDate, this.props.fieldErrors, this.props.submitted || this.props.readonly)}>
          <DatePicker format='M/D/YYYY' disabled={this.props.readonly || this.props.submitting || !this.props.isEditing} />
        </FormItem>

        <FormItem
          key={AcademicYearDTO.finalizationDate}
          {...this._formItems.get(AcademicYearDTO.finalizationDate)}
          {...ValidationUtil.getValidation(AcademicYearDTO.finalizationDate, this.props.fieldErrors, this.props.submitted || this.props.readonly)}>
          <DatePicker format='M/D/YYYY' disabled={this.props.readonly || this.props.submitting || !this.props.isEditing} />
        </FormItem>
      </Form>
    );
  }
}

export default AcademicYearForm;
