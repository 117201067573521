// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AdministrativeChangeRequestDTO extends EntitySelectorDTO { 
	id: string | null;
	changeRequestId: string | null;
	description: string | null;
	shortDescription: string | null;
}

const AdministrativeChangeRequestDTO = {
    className: 'AdministrativeChangeRequestDTO',
    create: (initValues?: Partial<AdministrativeChangeRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			changeRequestId: '00000000-0000-0000-0000-000000000000',
			description: "",
			shortDescription: "",
        },
        initValues);
    },
	id: 'id',
	changeRequestId: 'changeRequestId',
	description: 'description',
	shortDescription: 'shortDescription',
};

export default AdministrativeChangeRequestDTO;