import { DistrictAction, DistrictActionTypes } from '../actions/DistrictActions';
import { DistrictStoreState } from '../state/StateStoreModel';

export const InitialDistrictStoreState: DistrictStoreState = {
  Selected: null,
  DistrictId: null
};

export function District(
  state: DistrictStoreState = InitialDistrictStoreState,
  action: DistrictAction
): DistrictStoreState {
  let newState: DistrictStoreState = state;

  switch (action.type) {
    case DistrictActionTypes.DISTRICT_SELECT:
      newState = {
        ...state, Selected: action.data, DistrictId: action.data?.Id ?? null
      };
      break;

    default:
      return newState;
  }
  return Object.freeze(newState);
}
