// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import StatusTypeDTO from './StatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CategoryDTO extends EntitySelectorDTO { 
	id: string | null;
	code: string | null;
	name: string | null;
	statusType: StatusTypeDTO | null;
	statusTypeId: number;
	display: string | null;
}

const CategoryDTO = {
    className: 'CategoryDTO',
    create: (initValues?: Partial<CategoryDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			code: "",
			name: "",
			statusType: null,
			statusTypeId: 0,
			display: "",
        },
        initValues);
    },
	id: 'id',
	code: 'code',
	name: 'name',
	statusType: 'statusType',
	statusTypeId: 'statusTypeId',
	display: 'display',
};

export default CategoryDTO;