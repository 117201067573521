import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SpecialEmphasisApiService from '../../../../api/SpecialEmphasisApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import SpecialEmphasisDetailsForm from '../../../../components/forms/postSecondary/SpecialEmphasisDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteSpecialEmphasisHandler from '../../../../handlerModels/DeleteSpecialEmphasisHandler';
import * as GetSpecialEmphasisDetailsHandler from '../../../../handlerModels/GetSpecialEmphasisDetailsHandler';
import SpecialEmphasisDTO from '../../../../models/SpecialEmphasisDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface SpecialEmphasisDetailsPageState extends BaseFormState {
  specialEmphasisId: number;
  specialEmphasis?: SpecialEmphasisDTO;
}

interface SpecialEmphasisDetailsPageProps {
}

class SpecialEmphasisDetailsPage extends React.Component<SpecialEmphasisDetailsPageProps & RouteComponentProps<RouteObject>, SpecialEmphasisDetailsPageState> {
  private readonly _detailsRef = React.createRef<SpecialEmphasisDetailsForm>();

  constructor(props: SpecialEmphasisDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      specialEmphasisId: 0
    };
  }

  componentDidMount() {
    const specialEmphasisId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (specialEmphasisId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const specialEmphasisIdNumber = Number.parseInt(specialEmphasisId);
      this.loadSpecialEmphasis(specialEmphasisIdNumber);
    }
  }

  private loadSpecialEmphasis = (specialEmphasisId: number) => {
    this.setState({ specialEmphasisId: specialEmphasisId });

    SpecialEmphasisApiService.getSpecialEmphasisDetails(specialEmphasisId)
      .then((results: GetSpecialEmphasisDetailsHandler.Result) => {
        if (results.specialEmphasis) {
          this.setState({ specialEmphasis: results.specialEmphasis });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._detailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private specialEmphasisDetailsSaved = (specialEmphasisId: number) => {
    this.setState({ isEditing: false });
    if (specialEmphasisId != this.state.specialEmphasisId) {
      HistoryUtil.replace(Routes.generate(Routes.SPECIAL_EMPHASIS_DETAILS, { id: specialEmphasisId }));
    }
    else {
      this.loadSpecialEmphasis(specialEmphasisId);
    }
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Special Emphasis?',
      okText: 'Delete',
      onOk: () => this.deleteSpecialEmphasis(id)
    });
  }

  private deleteSpecialEmphasis = (specialEmphasisId: number) => {
    this.setState({ submitting: true });

    const request = DeleteSpecialEmphasisHandler.Request.create({
      specialEmphasisId: specialEmphasisId,
    });

    SpecialEmphasisApiService.deleteSpecialEmphasis(request)
      .then((result: DeleteSpecialEmphasisHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Special emphasis was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Special emphasis could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToSpecialEmphases();
      });
  }

  private goToSpecialEmphases = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.specialEmphasisId == 0 ? 'New Special Emphasis' : this.state.specialEmphasis?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToSpecialEmphases}
          breadcrumbs={Breadcrumbs.specialEmphasisDetails(title, this.state.specialEmphasisId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.specialEmphasisId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SPECIAL_EMPHASIS]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <SpecialEmphasisDetailsForm
          ref={this._detailsRef}
          specialEmphasisId={this.state.specialEmphasisId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.specialEmphasisDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Special Emphasis' key={this.state.specialEmphasisId} onClick={() => this.deleteConfirmation(this.state.specialEmphasisId)}  >
          Delete Special Emphasis
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SPECIAL_EMPHASIS]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(SpecialEmphasisDetailsPage);
