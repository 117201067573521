import * as React from 'react';
import CategoriesApiService from '../../../api/CategoriesApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import CategoryDTO from '../../../models/CategoryDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface CategoriesDataTableProps {
}

interface CategoriesDataTableState extends BaseDataTableState<CategoryDTO> {
  statuses: TableFilterOption[];
}

class CategoriesDataTable extends React.Component<CategoriesDataTableProps, CategoriesDataTableState> {
  constructor(props: CategoriesDataTableProps) {
    super(props);

    this.state = {
      statuses: []
    };
    this.loadStatusTypes();
  }

  componentDidMount() {
    this.loadStatusTypes();
  }

  private loadStatusTypes = () => {
    TableFilterUtil.StatusTypes()
      .then(x => this.setState({ statuses: x }));
  }

  private dataTable: DataTable<CategoryDTO> | undefined;

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Code',
        dataIndex: CategoryDTO.code,
        sorter: true,
        width: 50,
        filterType: FilterType.Text
      },
      {
        title: 'Name',
        dataIndex: CategoryDTO.name,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Status',
        dataIndex: CategoryDTO.statusTypeId,
        sorter: true,
        width: 75,
        render: (data: string, row: CategoryDTO) => {
          return row.statusType?.name;
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.statuses,
      }
    ] as DataTableColumnProps<any>[];
  };

  private openCategory = (record: CategoryDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.CATEGORY_DETAILS, { id: record.id }, {}));
    }
  }

  private addCategory = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_CATEGORY));
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return CategoriesApiService.exportCategoryList(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Categories_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CATEGORY])) {
      actionButtons.push(DataTableButtonUtil.Default('New Category', () => this.addCategory()));
    }

    return (
      <DataTable
        ref={(element: any) => (this.dataTable = element)}
        stateSaving={{ enabled: true, tableUniqueKey: 'categories_list' }}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => CategoriesApiService.getCategoryTableData(requestState)
        }}
        onRowClick={this.openCategory} />
    );
  }
}

export default CategoriesDataTable;
