import { Alert, AutoComplete, Input, message } from 'antd';
import Form, { FormInstance } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import UserCipAssignmentApiService from '../../api/UserCipAssignmentApiService';
import * as AssignCipNumberToUserHandler from '../../handlerModels/AssignCipNumberToUserHandler';
import * as SearchForCipNumberHandler from '../../handlerModels/SearchForCipNumberHandler';
import CipNumberDetailDTO from '../../models/CipNumberDetailDTO';
import CipNumberDTO from '../../models/CipNumberDTO';
import BaseFormState from '../../redux/bases/BaseFormState';
import Guid from '../../utils/Guid';
import ValidationUtil from '../../utils/ValidationUtil';

interface AssignUserCipNumberProps {
  userId: string;
  onAdd?: () => void;
}

interface AssignUserCipNumberState extends BaseFormState {
  cipNumberList: CipNumberDTO[];
  selectedCipNumberId: string;
}

class AssignUserCipNumberForm extends React.Component<AssignUserCipNumberProps, AssignUserCipNumberState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: AssignUserCipNumberProps) {
    super(props);

    this.state = {
      cipNumberList: [],
      selectedCipNumberId: Guid.Empty(),
      loading: true
    };
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  public submit = () => {
    this.handleSubmit();
  }

  private searchForCipNumbers = (searchText: string, userId: string) => {
    UserCipAssignmentApiService.searchForCipNumbers(searchText, userId)
      .then((results: SearchForCipNumberHandler.Result) => {
        if (results.cipNumbers) {
          this.setState({ cipNumberList: results.cipNumbers })
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const request = AssignCipNumberToUserHandler.Request.create({
      cipNumberId: this.state.selectedCipNumberId,
      userId: this.props.userId,
    });

    UserCipAssignmentApiService.assignCipNumberToUser(request)
      .then((result: AssignCipNumberToUserHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('CIP Number has been assigned');
          if (this.props.onAdd) {
            this.props.onAdd();
          }
        }
        else {
          message.error(result?.errors.join('\n'));
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('CIP number could not be assigned');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private handleSearch = (value: any) => {
    value.length >= 2 ? this.searchForCipNumbers(value, this.props.userId) : this.setState({ cipNumberList: [] });
  }

  private handleSelected = (value: any, option: any) => {
    this.setState({ selectedCipNumberId: option.cip.id });
  }

  render() {
    const autoCompleteOptions: any[] = this.state.cipNumberList.map(x => {
      const details = x.cipNumberDetails ?? [];
      const detail = details[0] as CipNumberDetailDTO;
      const display = detail.friendlyDisplay;
      return { value: display, key: x.id, cip: x }
    });

    return (
      <Content>
        {this.renderErrors}
        <Form ref={this._formRef} layout='horizontal' onFinish={this.handleSubmit} requiredMark={true}>
          <Input.Group>
            <FormItem name='cipNumberId'
              {...ValidationUtil.getValidation('CipNumber', this.state.fieldErrors, this.state.submitted)}>
              <AutoComplete
                options={autoCompleteOptions}
                onSearch={this.handleSearch}
                onSelect={this.handleSelected}>
                <Input placeholder="Select CIP Number" />
              </AutoComplete>
            </FormItem>
          </Input.Group>
        </Form>
      </Content>
    );
  }

  renderErrors() {
    if (this.state.fieldErrors) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AssignUserCipNumberForm;