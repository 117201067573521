// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ObjectPurposeDTO extends EntitySelectorDTO { 
	id: number;
	name: string | null;
	code: string | null;
	display: string | null;
}

const ObjectPurposeDTO = {
    className: 'ObjectPurposeDTO',
    create: (initValues?: Partial<ObjectPurposeDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: 0,
			name: "",
			code: "",
			display: "",
        },
        initValues);
    },
	id: 'id',
	name: 'name',
	code: 'code',
	display: 'display',
};

export default ObjectPurposeDTO;