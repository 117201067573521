// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import NoticeOfIntentChangeRequestDTO from './NoticeOfIntentChangeRequestDTO';
import AwardTypeDTO from './AwardTypeDTO';
import YearTermDTO from './YearTermDTO';
import InstructionalLevelDTO from './InstructionalLevelDTO';
import TypeOfProgramDTO from './TypeOfProgramDTO';
import SpecialEmphasisDTO from './SpecialEmphasisDTO';
import ObjectPurposeDTO from './ObjectPurposeDTO';
import NoticeOfIntentChangeRequestPlannedAwardInstitutionLocationDTO from './NoticeOfIntentChangeRequestPlannedAwardInstitutionLocationDTO';
import NoticeOfIntentChangeRequestPlannedAwardModeOfDeliveryDTO from './NoticeOfIntentChangeRequestPlannedAwardModeOfDeliveryDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoticeOfIntentChangeRequestPlannedAwardTypeDTO extends EntitySelectorDTO { 
	id: string | null;
	noticeOfIntentChangeRequestId: string | null;
	noticeOfIntentChangeRequest: NoticeOfIntentChangeRequestDTO | null;
	awardTypeId: number | null | null;
	awardType: AwardTypeDTO | null;
	title: string | null;
	description: string | null;
	primaryAward: boolean;
	install: YearTermDTO | null;
	instructionalLevelId: number | null | null;
	typeOfProgramId: number | null | null;
	specialEmphasisId: number | null | null;
	objectPurposeId: number | null | null;
	instructionalLevel: InstructionalLevelDTO | null;
	typeOfProgram: TypeOfProgramDTO | null;
	specialEmphasis: SpecialEmphasisDTO | null;
	objectPurpose: ObjectPurposeDTO | null;
	noticeOfIntentChangeRequestPlannedAwardInstitutionLocationIds: string[];
	noticeOfIntentChangeRequestPlannedAwardInstitutionLocations: NoticeOfIntentChangeRequestPlannedAwardInstitutionLocationDTO[] | null;
	noticeOfIntentChangeRequestPlannedAwardModeOfDeliverIds: number[];
	noticeOfIntentChangeRequestPlannedAwardModeOfDeliveries: NoticeOfIntentChangeRequestPlannedAwardModeOfDeliveryDTO[] | null;
}

const NoticeOfIntentChangeRequestPlannedAwardTypeDTO = {
    className: 'NoticeOfIntentChangeRequestPlannedAwardTypeDTO',
    create: (initValues?: Partial<NoticeOfIntentChangeRequestPlannedAwardTypeDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequestId: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequest: null,
			awardTypeId: null,
			awardType: null,
			title: "",
			description: "",
			primaryAward: false,
			install: null,
			instructionalLevelId: null,
			typeOfProgramId: null,
			specialEmphasisId: null,
			objectPurposeId: null,
			instructionalLevel: null,
			typeOfProgram: null,
			specialEmphasis: null,
			objectPurpose: null,
			noticeOfIntentChangeRequestPlannedAwardInstitutionLocationIds: [],
			noticeOfIntentChangeRequestPlannedAwardInstitutionLocations: [],
			noticeOfIntentChangeRequestPlannedAwardModeOfDeliverIds: [],
			noticeOfIntentChangeRequestPlannedAwardModeOfDeliveries: [],
        },
        initValues);
    },
	id: 'id',
	noticeOfIntentChangeRequestId: 'noticeOfIntentChangeRequestId',
	noticeOfIntentChangeRequest: 'noticeOfIntentChangeRequest',
	awardTypeId: 'awardTypeId',
	awardType: 'awardType',
	title: 'title',
	description: 'description',
	primaryAward: 'primaryAward',
	install: 'install',
	instructionalLevelId: 'instructionalLevelId',
	typeOfProgramId: 'typeOfProgramId',
	specialEmphasisId: 'specialEmphasisId',
	objectPurposeId: 'objectPurposeId',
	instructionalLevel: 'instructionalLevel',
	typeOfProgram: 'typeOfProgram',
	specialEmphasis: 'specialEmphasis',
	objectPurpose: 'objectPurpose',
	noticeOfIntentChangeRequestPlannedAwardInstitutionLocationIds: 'noticeOfIntentChangeRequestPlannedAwardInstitutionLocationIds',
	noticeOfIntentChangeRequestPlannedAwardInstitutionLocations: 'noticeOfIntentChangeRequestPlannedAwardInstitutionLocations',
	noticeOfIntentChangeRequestPlannedAwardModeOfDeliverIds: 'noticeOfIntentChangeRequestPlannedAwardModeOfDeliverIds',
	noticeOfIntentChangeRequestPlannedAwardModeOfDeliveries: 'noticeOfIntentChangeRequestPlannedAwardModeOfDeliveries',
};

export default NoticeOfIntentChangeRequestPlannedAwardTypeDTO;