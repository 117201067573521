import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import UsersDataTable from '../../components/datatables/UsersDataTable';
import DistrictDTO from '../../models/DistrictDTO';
import InstitutionDTO from '../../models/InstitutionDTO';
import RegionalPlanningPartnerDTO from '../../models/RegionalPlanningPartnerDTO';
import HeaderPortal from '../../portals/HeaderPortal';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import Breadcrumbs from './../../config/Breadcrumbs';

interface UsersListProps {
  institution: InstitutionDTO | null;
  selectedDistrict: DistrictDTO | null;
  selectedRPP: RegionalPlanningPartnerDTO | null;
}

interface UsersListState {
}

class UsersListPage extends React.Component<UsersListProps & RouteComponentProps<RouteObject>, UsersListState> {
  constructor(props: UsersListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  render() {
    return (
      <Content >
        <HeaderPortal title="Users" breadcrumbs={Breadcrumbs.userList()} />

        <Card>
          <UsersDataTable
            institution={this.props.institution}
            district={this.props.selectedDistrict}
            rpp={this.props.selectedRPP} />
        </Card>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected,
    selectedDistrict: state.District.Selected,
    selectedRPP: state.RegionalPlanningPartner.Selected
  };
}

export default withRouter(connect(mapStateToProps)(UsersListPage));
