// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetAdministrativeChangeRequestHandler from '../handlerModels/GetAdministrativeChangeRequestHandler';
import * as SaveAdministrativeChangeRequestStep1Handler from '../handlerModels/SaveAdministrativeChangeRequestStep1Handler';
import * as SubmitAdministrativeChangeRequestStep1Handler from '../handlerModels/SubmitAdministrativeChangeRequestStep1Handler';

export class AdministrativeChangeRequestApiService extends BaseApi {

    // post: api/administrativeChangeRequests/get
    public get(getAdministrativeChangeRequest: GetAdministrativeChangeRequestHandler.Request): Promise<GetAdministrativeChangeRequestHandler.Result> {
        let url = `api/administrativeChangeRequests/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAdministrativeChangeRequestHandler.Result>(getAdministrativeChangeRequest, 'post', url, true, false);
    }

    // post: api/administrativeChangeRequests/saveStep1
    public saveStep1(saveAdministrativeChangeRequestStep1: SaveAdministrativeChangeRequestStep1Handler.Request): Promise<SaveAdministrativeChangeRequestStep1Handler.Result> {
        let url = `api/administrativeChangeRequests/saveStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveAdministrativeChangeRequestStep1Handler.Result>(saveAdministrativeChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/administrativeChangeRequests/submitStep1
    public submitStep1(submitAdministrativeChangeRequestStep1: SubmitAdministrativeChangeRequestStep1Handler.Request): Promise<SubmitAdministrativeChangeRequestStep1Handler.Result> {
        let url = `api/administrativeChangeRequests/submitStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitAdministrativeChangeRequestStep1Handler.Result>(submitAdministrativeChangeRequestStep1, 'post', url, true, false);
    }
}
var service = new AdministrativeChangeRequestApiService();
export default service;
