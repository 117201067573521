// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudySectionComponentTemplateDTO from './SelfStudySectionComponentTemplateDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudySectionTemplateDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudyId: string | null;
	academicYearId: number;
	order: number;
	title: string | null;
	isSectionComments: boolean;
	selfStudySectionComponentTemplates: SelfStudySectionComponentTemplateDTO[] | null;
	selfStudySectionTemplateSecondaryProgramScenarios: string[];
}

const SelfStudySectionTemplateDTO = {
    className: 'SelfStudySectionTemplateDTO',
    create: (initValues?: Partial<SelfStudySectionTemplateDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudyId: '00000000-0000-0000-0000-000000000000',
			academicYearId: 0,
			order: 0,
			title: "",
			isSectionComments: false,
			selfStudySectionComponentTemplates: [],
			selfStudySectionTemplateSecondaryProgramScenarios: [],
        },
        initValues);
    },
	id: 'id',
	selfStudyId: 'selfStudyId',
	academicYearId: 'academicYearId',
	order: 'order',
	title: 'title',
	isSectionComments: 'isSectionComments',
	selfStudySectionComponentTemplates: 'selfStudySectionComponentTemplates',
	selfStudySectionTemplateSecondaryProgramScenarios: 'selfStudySectionTemplateSecondaryProgramScenarios',
};

export default SelfStudySectionTemplateDTO;