import { Card, Form, Space } from 'antd';
import * as React from 'react';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ChangeRequestAdditionalDetailsForm from '../../forms/postSecondary/ChangeRequestAdditionalDetailsForm';
import AdministrativeChangeRequestStep1Form from '../../forms/postSecondary/AdministrativeChangeRequestStep1Form';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface AdministrativeChangeRequestDetailsState extends BaseFormState {
}

interface AdministrativeChangeRequestDetailsProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequest: ChangeRequestDTO;
}

class AdministrativeChangeRequestDetails extends React.Component<AdministrativeChangeRequestDetailsProps, AdministrativeChangeRequestDetailsState> {

  constructor(props: AdministrativeChangeRequestDetailsProps) {
    super(props);
  }

  public render = () => {
    return (
      <Form layout="vertical">
        <Space size="large" direction="vertical">
          <Card type='inner' title='Course Information'>
            <AdministrativeChangeRequestStep1Form readonly={true} loading={this.props.loading} selectedInstitution={this.props.changeRequest.institutionId ?? Guid.Empty()} changeRequestId={this.props.changeRequest.id} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
          </Card>

          <Card type="inner" size="small" title="Additional Details" >
            <ChangeRequestAdditionalDetailsForm readonly={true} changeRequest={this.props.changeRequest} loading={this.props.loading} />
          </Card>
        </Space>
      </Form >
    );
  }
}

export default AdministrativeChangeRequestDetails;