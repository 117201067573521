import { MailOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Form, Input, Result, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import PageTitle from '../../components/shared/PageTitle';
import Routes from '../../config/Routes';
import BaseFormState from '../../redux/bases/BaseFormState';

interface RequestResetPasswordState extends BaseFormState {
  resetSuccess: boolean;
}

interface RequestResetPasswordProps {
}

class RequestResetPasswordPage extends React.Component<RequestResetPasswordProps & RouteComponentProps<RouteObject>, RequestResetPasswordState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RequestResetPasswordProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      resetSuccess: false,
    };
  }

  handleSubmit = () => {
    this.setState({ loading: true });

    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;

    AccountApiService.forgotPassword(model.email).then(
      () => {
        this.setState({ loading: false, resetSuccess: true, error: false });
      },
      (error) => {
        this.setState({ loading: false, error: true, message: error.message, resetSuccess: false });
      }
    );
  }

  render() {
    return this.state.resetSuccess ? this.renderSuccess() : this.renderForm();
  }

  renderForm() {
    return (
      <Card>
        <PageTitle title="Reset Password" />
        <Typography.Title level={5}>Please enter the email associated with your account.</Typography.Title>

        {this.renderMessage()}

        <Form ref={this._formRef} layout="vertical" onFinish={this.handleSubmit} requiredMark={false}>
          <Form.Item name="email" label="Email Address" rules={[{ required: true, message: 'Email is required' }]} >
            <Input disabled={this.state.loading} prefix={<MailOutlined />} />
          </Form.Item>

          <Form.Item >
            <Button type="primary" htmlType="submit" size="large" disabled={this.state.loading} loading={this.state.loading} block={true}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }

  renderMessage() {
    if (this.state.error) {
      let message = 'An error occurred while trying to send the reset password email.';
      if (this.state.message) {
        message = this.state.message;
      }

      return (
        <Alert
          message="Error"
          description={message}
          type="error"
          showIcon={true}
        />);
    }
  }

  renderSuccess() {
    return (
      <Card>
        <Result
          title="Check Your Inbox"
          icon={<MailOutlined />}
          subTitle="An email with a link to reset your password was sent to the email you provided. Please check your inbox and follow the included steps to reset your password."
          extra={
            <Link to={Routes.generate(Routes.LOGIN)}>
              <Button type="primary" size="large">
                Go to Login
              </Button>
            </Link>
          }
        />
      </Card>
    )
  }
}

export default withRouter(RequestResetPasswordPage);