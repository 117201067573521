// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetAvailableGeneralEducationParentsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import GeneralEducationDTO from '../models/GeneralEducationDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    generalEducationId: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            generalEducationId: 0,
        },
        initValues);
    },
    generalEducationId: 'generalEducationId',
}

interface Result extends ResultDTO {
    generalEducations: GeneralEducationDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            generalEducations: [],
        },
        initValues);
    },
    generalEducations: 'generalEducations',
}

export { Request, Result };