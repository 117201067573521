// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import RoleDTO from './RoleDTO';
import ChangeRequestTypeApprovalFlowStepDTO from './ChangeRequestTypeApprovalFlowStepDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ChangeRequestTypeApprovalFlowStepApprovalRoleDTO extends EntitySelectorDTO { 
	changeRequestTypeId: number;
	stepNumber: number;
	roleId: string | null;
	role: RoleDTO | null;
	required: boolean;
	requiredAll: boolean;
	matchCipNumber: boolean;
	daysToReview: number | null | null;
	changeRequestTypeApprovalFlowStep: ChangeRequestTypeApprovalFlowStepDTO | null;
}

const ChangeRequestTypeApprovalFlowStepApprovalRoleDTO = {
    className: 'ChangeRequestTypeApprovalFlowStepApprovalRoleDTO',
    create: (initValues?: Partial<ChangeRequestTypeApprovalFlowStepApprovalRoleDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			changeRequestTypeId: 0,
			stepNumber: 0,
			roleId: '00000000-0000-0000-0000-000000000000',
			role: null,
			required: false,
			requiredAll: false,
			matchCipNumber: false,
			daysToReview: null,
			changeRequestTypeApprovalFlowStep: null,
        },
        initValues);
    },
	changeRequestTypeId: 'changeRequestTypeId',
	stepNumber: 'stepNumber',
	roleId: 'roleId',
	role: 'role',
	required: 'required',
	requiredAll: 'requiredAll',
	matchCipNumber: 'matchCipNumber',
	daysToReview: 'daysToReview',
	changeRequestTypeApprovalFlowStep: 'changeRequestTypeApprovalFlowStep',
};

export default ChangeRequestTypeApprovalFlowStepApprovalRoleDTO;