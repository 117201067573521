// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// DownloadCoursesAttachedReportHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface Request {
    institutionId: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            institutionId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    institutionId: 'institutionId',
}

interface Result {
    file: any | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            file: null,
        },
        initValues);
    },
    file: 'file',
}

export { Request, Result };