import { DownOutlined } from '@ant-design/icons';
import { Button, Menu, Popover, Skeleton, Typography } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import DistrictApiService from '../../api/DistrictApiService';
import RegionalPlanningPartnerApiService from '../../api/RegionalPlanningPartnerApiService';
import * as GetDistrictsForUserHandler from '../../handlerModels/GetDistrictsForUserHandler';
import * as GetRegionPlanningPartnerForUserHandler from '../../handlerModels/GetRegionPlanningPartnerForUserHandler';
import DistrictDTO from '../../models/DistrictDTO';
import InstitutionDTO from '../../models/InstitutionDTO';
import RegionalPlanningPartnerDTO from '../../models/RegionalPlanningPartnerDTO';
import UserSecurity from '../../models/UserSecurity';
import { DistrictActions } from '../../redux/actions/DistrictActions';
import { InstitutionActions } from '../../redux/actions/InstitutionActions';
import { RPPActions } from '../../redux/actions/RPPActions';
import { UserSessionActions } from '../../redux/actions/UserSessionActions';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import Guid from '../../utils/Guid';
import LookupsUtil from '../../utils/LookupsUtil';

interface OrganizationSelectorState {
  institutions: InstitutionDTO[];
  districts: DistrictDTO[];
  rpps: RegionalPlanningPartnerDTO[];
  loading: boolean;
  error: boolean;
}

interface OrganizationSelectorProps {
  selectedInstitution: any;
  selectedDistrict: any;
  selectedRPP: any;
  userInfo?: UserSecurity | null;
  logoutAction?: () => void | undefined;
  selectInstitution: (institution: InstitutionDTO | null) => void | undefined;
  selectDistrict: (district: DistrictDTO | null) => void | undefined;
  selectRPP: (rpp: RegionalPlanningPartnerDTO | null) => void | undefined;
}

class OrganizationSelector extends React.Component<OrganizationSelectorProps, OrganizationSelectorState>  {
  constructor(props: OrganizationSelectorProps) {
    super(props);

    this.state = {
      institutions: [],
      districts: [],
      rpps: [],
      loading: false,
      error: false
    };
  }

  componentDidMount() {
    const loaders = [];

    loaders.push(this.loadInstitutions());
    loaders.push(this.loadDistricts());
    loaders.push(this.loadRPPs());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      });
  }

  private loadDistricts = () => {
    const request = GetDistrictsForUserHandler.Request.create({
      userId: this.props.userInfo?.userId
    });

    DistrictApiService.getDistrictsForUser(request)
      .then((results: GetDistrictsForUserHandler.Result) => {
        if (results) {
          this.setState({ districts: results.districts ?? [] });
        }
      })
      .catch(() => {
        this.setState({ error: false });
      });
  }

  private loadRPPs = () => {
    const request = GetRegionPlanningPartnerForUserHandler.Request.create({
      userId: this.props.userInfo?.userId
    });

    RegionalPlanningPartnerApiService.getRegionalPlanningPartnersByUser(request)
      .then((results: GetRegionPlanningPartnerForUserHandler.Result) => {
        if (results) {
          this.setState({ rpps: results.regions ?? [] });
        }
      })
      .catch(() => {
        this.setState({ error: false });
      });
  }

  private stateSelected = () => {
    this.props.selectInstitution(null);
    this.props.selectRPP(null);
    this.props.selectDistrict(null);
  }

  private institutionSelected = (id: string) => {
    const institutions = this.state.institutions.filter(x => x.id == id)[0] ?? null;

    this.props.selectDistrict(null);
    this.props.selectInstitution(institutions);
    this.props.selectRPP(null);
  }

  private RppSelected = (identifier: number) => {
    const rpp = this.state.rpps.filter(x => x.regionalPlanningPartnerIdentifier == identifier)[0] ?? null;

    this.props.selectDistrict(null);
    this.props.selectInstitution(null);
    this.props.selectRPP(rpp);
  }

  private districtSelector = (identifier: any) => {
    const district = this.state.districts.filter(x => x.districtId == identifier)[0] ?? null;

    this.props.selectDistrict(district);
    this.props.selectInstitution(null);
    this.props.selectRPP(null);
  }

  private selectSingle = () => {
    if (this.props.userInfo) {
      if (this.props.userInfo.institutionIds.length > 0) {
        const institution = this.props.userInfo.institutionIds[0];
        this.institutionSelected(institution);
      }
      else if (this.props.userInfo.districtIds.length > 0) {
        const district = this.props.userInfo.districtIds[0];
        this.districtSelector(district);
      }
      else if (this.props.userInfo.rppIds.length > 0) {
        const rpp = this.props.userInfo.rppIds[0];
        this.RppSelected(rpp);
      }
    }
  }

  render() {
    if (this.props.userInfo) {
      const isMultiple = this.props.userInfo.institutionIds.length + this.props.userInfo.districtIds.length + this.props.userInfo.rppIds.length;
      if (isMultiple > 1 || ((this.props.userInfo.institutionIds.length > 0 || this.props.userInfo.districtIds.length > 0 || this.props.userInfo.rppIds.length > 0) && this.props.userInfo.isStateUser)) {
        return this.renderMultiple();
      }

      this.selectSingle();

      return <Typography.Text style={{ color: 'white', marginRight: 15 }}>{this.getName()}</Typography.Text>;
    }
  }


  getName() {
    if (this.props.selectedInstitution?.abbreviation) {
      return this.props.selectedInstitution?.name;
    }
    else if (this.props.selectedDistrict?.districtId) {
      return this.props.selectedDistrict?.name;
    }
    else if (this.props.selectedRPP?.regionalPlanningPartnerIdentifier) {
      return this.props.selectedRPP?.name;
    }
    else if (this.props.userInfo?.isStateUser) {
      return 'State of Iowa'
    }
  }

  renderMultiple() {
    const content = (
      <div>
        {this.renderOrganizations()}
      </div >
    );

    return (
      <Popover key='organization_popover' placement="bottomRight" title='' content={content} trigger="click">
        <Button type='link' style={{ color: 'white' }}>
          {this.getName()} <DownOutlined />
        </Button>
      </Popover>
    );
  }

  renderOrganizations() {
    if ((this.props.userInfo?.institutionIds && this.props.userInfo?.institutionIds.length > 0) ||
      (this.props.userInfo?.districtIds && this.props.userInfo?.districtIds.length > 0) ||
      (this.props.userInfo?.rppIds && this.props.userInfo?.rppIds.length > 0)) {
      let selected: any[] = [];

      if (this.props.selectedInstitution?.abbreviation) {
        selected.push(this.props.selectedInstitution.id ?? Guid.Empty());
      }
      else if (this.props.selectedDistrict?.districtId) {
        selected.push(this.props.selectedDistrict.districtId ?? 0);
      }
      else if (this.props.selectedRPP?.regionalPlanningPartnerIdentifier) {
        selected.push(this.props.selectedRPP.regionalPlanningPartnerIdentifier ?? 0);
      }
      else {
        selected = [];
      }

      const organizations = [];
      if (this.props.userInfo.isStateUser === true) {
        organizations.push(
          <Menu.Item key={Guid.Empty()} onClick={() => this.stateSelected()}>
            State of Iowa
          </Menu.Item>);
      }

      organizations.push(this.props.userInfo?.institutionIds.map(x => this.renderInstitution(x)));
      organizations.push(this.props.userInfo?.districtIds?.map(x => this.renderDistrict(x)));
      organizations.push(this.props.userInfo?.rppIds?.map(x => this.renderRPP(x)));

      if (selected.length === 0) {
        if (this.props.userInfo.isStateUser === true) {
          //no op
        }
        else if (this.props.userInfo.institutionIds.length > 0) {
          const institution = this.props.userInfo.institutionIds[0];
          selected.push(this.props.userInfo.institutionIds[0]);
          this.institutionSelected(institution);
        }
        else if (this.props.userInfo.districtIds.length > 0) {
          const district = this.props.userInfo.districtIds[0];
          selected.push(district);
          this.districtSelector(district);
        }
        else if (this.props.userInfo.rppIds.length > 0) {
          const rpp = this.props.userInfo.rppIds[0];
          selected.push(rpp);
          this.RppSelected(rpp);
        }
      }

      return (
        <Menu theme="light"
          selectedKeys={selected}
          style={{ marginTop: '10px', marginLeft: '-16px', marginRight: '-16px' }}>
          {organizations}
        </Menu>
      );
    }
  }

  renderInstitution(institutionId: string) {
    const institution = this.state.institutions.filter(x => x.id == institutionId)[0] ?? null;

    if (institution == null) {
      return (
        <Menu.Item key={institutionId} onClick={() => this.stateSelected()}>
          State of Iowa
        </Menu.Item>
      );
    }

    if (this.state.institutions.length > 0) {
      return (
        <Menu.Item key={institutionId} onClick={() => this.institutionSelected(institution.id ?? Guid.Empty())}>
          {institution.name}
        </Menu.Item>
      );
    }

    return <Skeleton.Input active={true} />
  }

  renderDistrict(districtIdentifier: number) {
    const district = this.state.districts.filter(x => x.districtId == districtIdentifier)[0] ?? null;

    if (this.state.districts.length > 0) {
      return (
        <Menu.Item key={districtIdentifier} onClick={() => this.districtSelector(district.districtId)}>
          {district?.name ?? ''}
        </Menu.Item>
      );
    }

    return <Skeleton.Input active={true} />
  }

  renderRPP(rppId: number) {
    const rpp = this.state.rpps.filter(x => x.regionalPlanningPartnerIdentifier == rppId)[0] ?? null;

    if (this.state.rpps.length > 0) {
      return (
        <Menu.Item key={rppId} onClick={() => this.RppSelected(rpp.regionalPlanningPartnerIdentifier)}>
          {rpp?.name ?? ''}
        </Menu.Item>
      );
    }

    return <Skeleton.Input active={true} />
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    logoutAction: () => dispatch(UserSessionActions.logout()),
    selectInstitution: (institution: InstitutionDTO | null) => dispatch(InstitutionActions.select(institution)),
    selectDistrict: (district: DistrictDTO | null) => dispatch(DistrictActions.select(district)),
    selectRPP: (rpp: RegionalPlanningPartnerDTO | null) => dispatch(RPPActions.select(rpp))
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    userInfo: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected,
    selectedDistrict: state.District.Selected,
    selectedRPP: state.RegionalPlanningPartner.Selected
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSelector);
