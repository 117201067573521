import { Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import * as GetAdministrativeChangeRequestHandler from '../../../handlerModels/GetAdministrativeChangeRequestHandler';
import AdministrativeChangeRequestApiService from '../../../api/AdministrativeChangeRequestApiService';
import * as SaveAdministrativeChangeRequestStep1Handler from '../../../handlerModels/SaveAdministrativeChangeRequestStep1Handler';
import * as SubmitAdministrativeChangeRequestStep1Handler from '../../../handlerModels/SubmitAdministrativeChangeRequestStep1Handler';
import AdministrativeChangeRequestDTO from '../../../models/AdministrativeChangeRequestDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import TermDTO from '../../../models/TermDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';

interface AdministrativeChangeRequestStep1FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  administrativeChangeRequest: AdministrativeChangeRequestDTO;
  terms: TermDTO[];
}

interface AdministrativeChangeRequestStep1FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSubmit?: (id: string) => void;
  onSave?: (id: string) => void;
  readonly?: boolean;
}

class AdministrativeChangeRequestStep1Form extends React.Component<AdministrativeChangeRequestStep1FormProps, AdministrativeChangeRequestStep1FormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _formItems = new Map<string, FormItemProps>()
    .set(AdministrativeChangeRequestDTO.description, {
      required: true,
      name: AdministrativeChangeRequestDTO.description,
      label: 'Please describe your request. If your request regards a course, program, or notice of intent, please ensure that the request cannot be handled in a different type of change request.',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(10000)
      ],
    })
    .set(AdministrativeChangeRequestDTO.shortDescription, {
      required: true,
      name: AdministrativeChangeRequestDTO.shortDescription,
      label: 'Please give a short description of your request.',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(10000)
      ],
    });

  constructor(props: AdministrativeChangeRequestStep1FormProps) {
    super(props);

    this.state = {
      administrativeChangeRequest: AdministrativeChangeRequestDTO.create({
        installTermId: '',
      }),
      changeRequest: ChangeRequestDTO.create(),
      terms: []
    };
  }

  componentDidMount() {
    this.setState({
      fieldErrors: this.props.fieldErrors
    });

    this.fetchData();
  }

  componentDidUpdate(prevProps: AdministrativeChangeRequestStep1FormProps) {
    if (this.props.changeRequestId && prevProps.changeRequestId != this.props.changeRequestId) {
      this.setState({
        fieldErrors: this.props.fieldErrors
      });
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  public resetForm = () => {
    this.setState({ altered: false });
    this._formRef.current?.resetFields();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.terms || this.state.terms.length == 0) {
      loaders.push(this.loadTerms());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: this.props.loading ? true : false });
      this.handleChange();
      this.resetForm();
    });
  }

  private loadTerms = () => {
    return LookupsUtil.getAll<TermDTO>(TermDTO.className)
      .then((results: TermDTO[]) => {
        if (results) {
          this.setState({ terms: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadChangeRequest = () => {
    const request = GetAdministrativeChangeRequestHandler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return AdministrativeChangeRequestApiService.get(request)
      .then((results: GetAdministrativeChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            administrativeChangeRequest: results.changeRequest?.administrativeChangeRequest ?? AdministrativeChangeRequestDTO.create({
              installTermId: '',
            }),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SubmitAdministrativeChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? Guid.Empty(),
      institutionId: this.props.selectedInstitution,
      administrativeChangeRequest: AdministrativeChangeRequestDTO.create(this._formRef?.current?.getFieldsValue())
    });

    AdministrativeChangeRequestApiService.submitStep1(request)
      .then((result: SubmitAdministrativeChangeRequestStep1Handler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          this.setState({ saved: true });
          message.success('Saved');
          if (this.props.onSave) {
            this.props.onSave(result.changeRequest?.id ?? Guid.Empty());
          }

          if (this.props.onSubmit) {
            this.props.onSubmit(result.changeRequest?.id ?? Guid.Empty());
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Submit Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Change request could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const request = SaveAdministrativeChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? Guid.Empty(),
      institutionId: this.props.selectedInstitution,
      administrativeChangeRequest: AdministrativeChangeRequestDTO.create(this._formRef?.current?.getFieldsValue())
    });

    AdministrativeChangeRequestApiService.saveStep1(request)
      .then((result: SaveAdministrativeChangeRequestStep1Handler.Result) => {
        this.setState({ saved: true });

        if (result?.succeeded) {
          message.success('Saved');
          this.setState({ changeRequest: result.changeRequest ?? ChangeRequestDTO.create() });
          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            administrativeChangeRequest: result.changeRequest?.administrativeChangeRequest ?? AdministrativeChangeRequestDTO.create()
          });

          this.resetForm();
          this.resetForm();

          if (this.props.onSave) {
            this.props.onSave(result.changeRequest?.id ?? Guid.Empty());
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Submit Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Change request could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public render = () => {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }

    return (
      <Space size="small" direction="vertical">
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.administrativeChangeRequest}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            key={AdministrativeChangeRequestDTO.shortDescription}
            {...this._formItems.get(AdministrativeChangeRequestDTO.shortDescription)}
            {...ValidationUtil.getValidation(AdministrativeChangeRequestDTO.shortDescription, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea maxLength={75} showCount={true} autoSize={{ minRows: 1 }} disabled={this.props.readonly} />
          </FormItem>

          <FormItem
            key={AdministrativeChangeRequestDTO.description}
            {...this._formItems.get(AdministrativeChangeRequestDTO.description)}
            {...ValidationUtil.getValidation(AdministrativeChangeRequestDTO.description, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea maxLength={10000} showCount={true} autoSize={{ minRows: 5 }} disabled={this.props.readonly} />
          </FormItem>

          {this.renderActions()}
        </Form>
      </Space >
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveAndContinueButton submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.saving} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }
}

export default AdministrativeChangeRequestStep1Form;