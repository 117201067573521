// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class FeatureFlag {
    public static readonly SYSTEM_ADMIN: string = 'SYSTEM_ADMIN';
    public static readonly MANAGE_AA_USER: string = 'MANAGE_AA_USER';
    public static readonly CONFIG_ACADEMIC_YEAR: string = 'CONFIG_ACADEMIC_YEAR';
    public static readonly EDIT_AREA_EDUCATION_AGENCY: string = 'EDIT_AREA_EDUCATION_AGENCY';
    public static readonly EDIT_ANNOUNCEMENT: string = 'EDIT_ANNOUNCEMENT';
    public static readonly EDIT_APS_GENERAL_EDUCATION: string = 'EDIT_APS_GENERAL_EDUCATION';
    public static readonly VIEW_APS_GENERAL_EDUCATION: string = 'VIEW_APS_GENERAL_EDUCATION';
    public static readonly EDIT_AWARD_TYPE: string = 'EDIT_AWARD_TYPE';
    public static readonly VIEW_AWARD_TYPE: string = 'VIEW_AWARD_TYPE';
    public static readonly EDIT_PROGRAM_CLASSIFICATION: string = 'EDIT_PROGRAM_CLASSIFICATION';
    public static readonly VIEW_PROGRAM_CLASSIFICATION: string = 'VIEW_PROGRAM_CLASSIFICATION';
    public static readonly EDIT_CATEGORY: string = 'EDIT_CATEGORY';
    public static readonly VIEW_CATEGORY: string = 'VIEW_CATEGORY';
    public static readonly EDIT_CHANGE_REQUEST: string = 'EDIT_CHANGE_REQUEST';
    public static readonly VIEW_CHANGE_REQUEST: string = 'VIEW_CHANGE_REQUEST';
    public static readonly APPROVE_CHANGE_REQUEST: string = 'APPROVE_CHANGE_REQUEST';
    public static readonly OVERRIDE_CHANGE_REQUEST_USER: string = 'OVERRIDE_CHANGE_REQUEST_USER';
    public static readonly OVERRIDE_APPROVAL_USER: string = 'OVERRIDE_APPROVAL_USER';
    public static readonly MANAGE_APPROVAL_USER: string = 'MANAGE_APPROVAL_USER';
    public static readonly CREATE_ADMINISTRATIVE_CHANGE_REQUEST: string = 'CREATE_ADMINISTRATIVE_CHANGE_REQUEST';
    public static readonly CREATE_NEW_DISCIPLINE_CHANGE_REQUEST: string = 'CREATE_NEW_DISCIPLINE_CHANGE_REQUEST';
    public static readonly CREATE_NEW_COURSE_CHANGE_REQUEST: string = 'CREATE_NEW_COURSE_CHANGE_REQUEST';
    public static readonly CREATE_COURSE_DEACTIVATION_CHANGE_REQUEST: string = 'CREATE_COURSE_DEACTIVATION_CHANGE_REQUEST';
    public static readonly CREATE_MODIFY_COURSE_CHANGE_REQUEST: string = 'CREATE_MODIFY_COURSE_CHANGE_REQUEST';
    public static readonly CREATE_NOTICE_OF_INTENT_REQUEST: string = 'CREATE_NOTICE_OF_INTENT_REQUEST';
    public static readonly CREATE_NEW_PROGRAM_REQUEST: string = 'CREATE_NEW_PROGRAM_REQUEST';
    public static readonly CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST: string = 'CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST';
    public static readonly CREATE_PROGRAM_DEACTIVATION_REQUEST: string = 'CREATE_PROGRAM_DEACTIVATION_REQUEST';
    public static readonly EDIT_COURSE: string = 'EDIT_COURSE';
    public static readonly VIEW_COURSE: string = 'VIEW_COURSE';
    public static readonly EDIT_CONTACT: string = 'EDIT_CONTACT';
    public static readonly EDIT_CONTACT_TYPE: string = 'EDIT_CONTACT_TYPE';
    public static readonly VIEW_CONTACT_TYPE: string = 'VIEW_CONTACT_TYPE';
    public static readonly EDIT_CREDIT_TYPE: string = 'EDIT_CREDIT_TYPE';
    public static readonly VIEW_CREDIT_TYPE: string = 'VIEW_CREDIT_TYPE';
    public static readonly EDIT_DISCIPLINE: string = 'EDIT_DISCIPLINE';
    public static readonly VIEW_DISCIPLINE: string = 'VIEW_DISCIPLINE';
    public static readonly EDIT_DISTRICT: string = 'EDIT_DISTRICT';
    public static readonly MANAGE_DISTRICT_USERS: string = 'MANAGE_DISTRICT_USERS';
    public static readonly MANAGE_DISTRICT_CONTACT: string = 'MANAGE_DISTRICT_CONTACT';
    public static readonly DOWNLOAD_REPORTS: string = 'DOWNLOAD_REPORTS';
    public static readonly VIEW_DOCUMENT_ARCHIVE: string = 'VIEW_DOCUMENT_ARCHIVE';
    public static readonly EDIT_EXTERNAL_INSTITUTIONS: string = 'EDIT_EXTERNAL_INSTITUTIONS';
    public static readonly VIEW_EXTERNAL_INSTITUTIONS: string = 'VIEW_EXTERNAL_INSTITUTIONS';
    public static readonly EDIT_FEDERAL_CLUSTER: string = 'EDIT_FEDERAL_CLUSTER';
    public static readonly EDIT_GENDER: string = 'EDIT_GENDER';
    public static readonly VIEW_GENDER: string = 'VIEW_GENDER';
    public static readonly EDIT_GENERAL_EDUCATION: string = 'EDIT_GENERAL_EDUCATION';
    public static readonly VIEW_GENERAL_EDUCATION: string = 'VIEW_GENERAL_EDUCATION';
    public static readonly EDIT_ROLE: string = 'EDIT_ROLE';
    public static readonly VIEW_ROLE: string = 'VIEW_ROLE';
    public static readonly EDIT_REGIONAL_PLANNING_PARTNER: string = 'EDIT_REGIONAL_PLANNING_PARTNER';
    public static readonly EDIT_INSTITUTION: string = 'EDIT_INSTITUTION';
    public static readonly VIEW_INSTITUTION: string = 'VIEW_INSTITUTION';
    public static readonly EDIT_INSTITUTION_COURSE_BLOCK: string = 'EDIT_INSTITUTION_COURSE_BLOCK';
    public static readonly VIEW_INSTITUTION_COURSE_BLOCK: string = 'VIEW_INSTITUTION_COURSE_BLOCK';
    public static readonly EDIT_INSTITUTION_CREDIT_TYPE_MINUTE: string = 'EDIT_INSTITUTION_CREDIT_TYPE_MINUTE';
    public static readonly VIEW_INSTITUTION_CREDIT_TYPE_MINUTE: string = 'VIEW_INSTITUTION_CREDIT_TYPE_MINUTE';
    public static readonly EDIT_INSTITUTION_LOCATION: string = 'EDIT_INSTITUTION_LOCATION';
    public static readonly VIEW_INSTITUTION_LOCATION: string = 'VIEW_INSTITUTION_LOCATION';
    public static readonly EDIT_INSTITUTION_PRESIDENT: string = 'EDIT_INSTITUTION_PRESIDENT';
    public static readonly VIEW_INSTITUTION_PRESIDENT: string = 'VIEW_INSTITUTION_PRESIDENT';
    public static readonly MANAGE_INSTITUTION_USER: string = 'MANAGE_INSTITUTION_USER';
    public static readonly EDIT_INSTRUCTIONAL_LEVEL: string = 'EDIT_INSTRUCTIONAL_LEVEL';
    public static readonly VIEW_INSTRUCTIONAL_LEVEL: string = 'VIEW_INSTRUCTIONAL_LEVEL';
    public static readonly EDIT_IOWA_VARIATION: string = 'EDIT_IOWA_VARIATION';
    public static readonly VIEW_IOWA_VARIATION: string = 'VIEW_IOWA_VARIATION';
    public static readonly EDIT_PROGRAM: string = 'EDIT_PROGRAM';
    public static readonly VIEW_PROGRAM: string = 'VIEW_PROGRAM';
    public static readonly EDIT_TERM: string = 'EDIT_TERM';
    public static readonly VIEW_TERM: string = 'VIEW_TERM';
    public static readonly EDIT_THIRD_PARTY_CREDENTIAL_TYPE: string = 'EDIT_THIRD_PARTY_CREDENTIAL_TYPE';
    public static readonly VIEW_THIRD_PARTY_CREDENTIAL_TYPE: string = 'VIEW_THIRD_PARTY_CREDENTIAL_TYPE';
    public static readonly EDIT_USER: string = 'EDIT_USER';
    public static readonly VIEW_USER: string = 'VIEW_USER';
    public static readonly EDIT_CIP_NUMBER_ASSIGNMENTS: string = 'EDIT_CIP_NUMBER_ASSIGNMENTS';
    public static readonly VIEW_CIP_NUMBER_ASSIGNMENTS: string = 'VIEW_CIP_NUMBER_ASSIGNMENTS';
    public static readonly MANAGE_RPP_USERS: string = 'MANAGE_RPP_USERS';
    public static readonly EDIT_CIP_NUMBER: string = 'EDIT_CIP_NUMBER';
    public static readonly VIEW_CIP_NUMBER: string = 'VIEW_CIP_NUMBER';
    public static readonly EDIT_OBJECT_PURPOSE: string = 'EDIT_OBJECT_PURPOSE';
    public static readonly VIEW_OBJECT_PURPOSE: string = 'VIEW_OBJECT_PURPOSE';
    public static readonly EDIT_RESOURCE: string = 'EDIT_RESOURCE';
    public static readonly EDIT_RACIAL_EHTNIC_GROUPS: string = 'EDIT_RACIAL_EHTNIC_GROUPS';
    public static readonly VIEW_RACIAL_EHTNIC_GROUPS: string = 'VIEW_RACIAL_EHTNIC_GROUPS';
    public static readonly ASSIGN_DISTRICT_RESPONDER: string = 'ASSIGN_DISTRICT_RESPONDER';
    public static readonly ASSIGN_RPP_RESPONDER: string = 'ASSIGN_RPP_RESPONDER';
    public static readonly ASSIGN_DE_CONSULTANT_RESPONDER: string = 'ASSIGN_DE_CONSULTANT_RESPONDER';
    public static readonly APPROVE_DISTRICT_RESPONSE: string = 'APPROVE_DISTRICT_RESPONSE';
    public static readonly EDIT_PROGRAM_REVIEW: string = 'EDIT_PROGRAM_REVIEW';
    public static readonly EDIT_DISTRICT_RESPONSE: string = 'EDIT_DISTRICT_RESPONSE';
    public static readonly EDIT_RPP_RESPONSE: string = 'EDIT_RPP_RESPONSE';
    public static readonly EDIT_DE_RESPONSE: string = 'EDIT_DE_RESPONSE';
    public static readonly VIEW_CURRENT_PROGRAM_STATUS: string = 'VIEW_CURRENT_PROGRAM_STATUS';
    public static readonly VIEW_DISTRICT_RESPONSE: string = 'VIEW_DISTRICT_RESPONSE';
    public static readonly VIEW_RPP_RESPONSE: string = 'VIEW_RPP_RESPONSE';
    public static readonly VIEW_DE_CONSULTANT_RESPONSE: string = 'VIEW_DE_CONSULTANT_RESPONSE';
    public static readonly EDIT_SPECIAL_EMPHASIS: string = 'EDIT_SPECIAL_EMPHASIS';
    public static readonly VIEW_SPECIAL_EMPHASIS: string = 'VIEW_SPECIAL_EMPHASIS';
    public static readonly EDIT_SECONDARY_PROGRAM: string = 'EDIT_SECONDARY_PROGRAM';
    public static readonly EDIT_SECONDARY_PROGRAM_SCENARIO: string = 'EDIT_SECONDARY_PROGRAM_SCENARIO';
    public static readonly EDIT_SECONDARY_COURSE: string = 'EDIT_SECONDARY_COURSE';
    public static readonly EDIT_SECONDARY_CIP_NUMBER: string = 'EDIT_SECONDARY_CIP_NUMBER';
    public static readonly EDIT_SECONDARY_PROGRAM_COURSE: string = 'EDIT_SECONDARY_PROGRAM_COURSE';
    public static readonly EDIT_TRANSFER_MAJOR_LACTS_CATEGORY: string = 'EDIT_TRANSFER_MAJOR_LACTS_CATEGORY';
    public static readonly VIEW_TRANSFER_MAJOR_LACTS_CATEGORY: string = 'VIEW_TRANSFER_MAJOR_LACTS_CATEGORY';
    public static readonly EDIT_TRANSFER_MAJOR_COURSE_BLOCKS: string = 'EDIT_TRANSFER_MAJOR_COURSE_BLOCKS';
    public static readonly VIEW_TRANSFER_MAJOR_COURSE_BLOCKS: string = 'VIEW_TRANSFER_MAJOR_COURSE_BLOCKS';
    public static readonly EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE: string = 'EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE';
    public static readonly VIEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE: string = 'VIEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE';
    public static readonly EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS: string = 'EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS';
    public static readonly VIEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS: string = 'VIEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS';
    public static readonly EDIT_TYPE_OF_PROGRAM: string = 'EDIT_TYPE_OF_PROGRAM';
    public static readonly VIEW_TYPE_OF_PROGRAM: string = 'VIEW_TYPE_OF_PROGRAM';
    public static readonly EDIT_SERVICE_AREA: string = 'EDIT_SERVICE_AREA';
    public static readonly EDIT_USER_ROLE: string = 'EDIT_USER_ROLE';
    public static readonly VIEW_USER_ROLE: string = 'VIEW_USER_ROLE';
    public static readonly EDIT_WORKFLOW: string = 'EDIT_WORKFLOW';
    public static readonly VIEW_ALL_WORK_ITEMS: string = 'VIEW_ALL_WORK_ITEMS';
    public static readonly VIEW_INSTITUTION_WORK_ITEMS: string = 'VIEW_INSTITUTION_WORK_ITEMS';
    public static readonly EDIT_WORK_BASED_LEARNING: string = 'EDIT_WORK_BASED_LEARNING';
    public static readonly VIEW_WORK_BASED_LEARNING: string = 'VIEW_WORK_BASED_LEARNING';
    public static readonly SUPPORT_CONTACT: string = 'SUPPORT_CONTACT';
}
