import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import SpecialEmphasisApiService from '../../../api/SpecialEmphasisApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetSpecialEmphasisDetailsHandler from '../../../handlerModels/GetSpecialEmphasisDetailsHandler';
import * as SaveSpecialEmphasisDetailsHandler from '../../../handlerModels/SaveSpecialEmphasisDetailsHandler';
import SpecialEmphasisDTO from '../../../models/SpecialEmphasisDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface SpecialEmphasisDetailsFormState extends BaseFormState {
  specialEmphasisId: number;
  specialEmphasis: SpecialEmphasisDTO | null;
}

interface SpecialEmphasisDetailsFormProps extends BaseFormProps {
  specialEmphasisId: number;
  onSave?: (specialEmphasisId: number) => void;
}

class SpecialEmphasisDetailsForm extends React.Component<SpecialEmphasisDetailsFormProps, SpecialEmphasisDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(SpecialEmphasisDTO.name, {
      required: true,
      name: SpecialEmphasisDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    })
    .set(SpecialEmphasisDTO.code, {
      required: true,
      name: SpecialEmphasisDTO.code,
      label: 'Code',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(10)
      ],
    });

  constructor(props: SpecialEmphasisDetailsFormProps) {
    super(props);

    this.state = {
      specialEmphasisId: 0,
      specialEmphasis: SpecialEmphasisDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadSpecialEmphasis();
  }

  componentDidUpdate(prevProps: SpecialEmphasisDetailsFormProps) {
    if (this.props.specialEmphasisId && this.props.specialEmphasisId != prevProps.specialEmphasisId) {
      this.setState({ specialEmphasisId: this.props.specialEmphasisId });
      this.loadSpecialEmphasis();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadSpecialEmphasis() {
    if (this.props.specialEmphasisId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.specialEmphasisId) {
      SpecialEmphasisApiService.getSpecialEmphasisDetails(this.props.specialEmphasisId)
        .then((results: GetSpecialEmphasisDetailsHandler.Result) => {
          if (results && results.specialEmphasis) {
            this.setState({ specialEmphasisId: results.specialEmphasis.id, specialEmphasis: results.specialEmphasis, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ specialEmphasis: SpecialEmphasisDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveSpecialEmphasisDetailsHandler.Request.create({
      specialEmphasisId: this.state.specialEmphasisId,
      specialEmphasis: SpecialEmphasisDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    SpecialEmphasisApiService.saveSpecialEmphasisDetails(request)
      .then((result: SaveSpecialEmphasisDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          specialEmphasis: result?.specialEmphasis,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.specialEmphasis) {
            this.props.onSave(result.specialEmphasis.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(SpecialEmphasisDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Special Emphasis could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Special Emphasis could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(SpecialEmphasisDTO.code)}
            initialValue={this.state.specialEmphasis?.code}
            {...ValidationUtil.getValidation(SpecialEmphasisDTO.code, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(SpecialEmphasisDTO.name)}
            initialValue={this.state.specialEmphasis?.name}
            {...ValidationUtil.getValidation(SpecialEmphasisDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SPECIAL_EMPHASIS]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default SpecialEmphasisDetailsForm;
