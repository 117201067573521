// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ObjectPurposeDataTableDTO from '../models/ObjectPurposeDataTableDTO';
import * as GetObjectPurposeDetailsHandler from '../handlerModels/GetObjectPurposeDetailsHandler';
import * as SaveObjectPurposeDetailsHandler from '../handlerModels/SaveObjectPurposeDetailsHandler';
import * as GetObjectPurposeHandler from '../handlerModels/GetObjectPurposeHandler';
import * as DeleteObjectPurposeHandler from '../handlerModels/DeleteObjectPurposeHandler';

export class ObjectPurposeApiService extends BaseApi {

    // post: api/objectPurposes
    public getObjectPurposeTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ObjectPurposeDataTableDTO>> {
        let url = `api/objectPurposes`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ObjectPurposeDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/objectPurposes/export
    public getObjectPurposeDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/objectPurposes/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/objectPurposes/${id}
    public getObjectPurposeDetails(id: number): Promise<GetObjectPurposeDetailsHandler.Result> {
        let url = `api/objectPurposes/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetObjectPurposeDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/objectPurposes/save
    public saveObjectPurposeDetails(saveObjectPurposeDetails: SaveObjectPurposeDetailsHandler.Request): Promise<SaveObjectPurposeDetailsHandler.Result> {
        let url = `api/objectPurposes/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveObjectPurposeDetailsHandler.Result>(saveObjectPurposeDetails, 'post', url, true, false);
    }

    // post: api/objectPurposes/getObjectPurposes
    public getObjectPurposes(): Promise<GetObjectPurposeHandler.Result> {
        let url = `api/objectPurposes/getObjectPurposes`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetObjectPurposeHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/objectPurposes/deleteObjectPurpose
    public deleteObjectPurpose(deleteObjectPurpose: DeleteObjectPurposeHandler.Request): Promise<DeleteObjectPurposeHandler.Result> {
        let url = `api/objectPurposes/deleteObjectPurpose`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteObjectPurposeHandler.Result>(deleteObjectPurpose, 'post', url, true, false);
    }
}
var service = new ObjectPurposeApiService();
export default service;
