import { Card, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChangeRequestTypeApprovalFlowApiService from '../../../api/ChangeRequestTypeApprovalFlowApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import WorkflowDetailsForm from '../../../components/forms/WorkflowDetailsForm';
import ChangeRequestTypeDetailsForm from '../../../components/forms/postSecondary/ChangeRequestTypeDetailsForm';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetChangeRequestApprovalWorkflowDetailsHandler from '../../../handlerModels/GetWorkflowDetailsHandler';
import ChangeRequestTypeApprovalFlowDTO from '../../../models/ChangeRequestTypeApprovalFlowDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ParameterUtil from '../../../utils/ParameterUtil';

interface WorkflowDetailsPageState extends BaseFormState {
  changeRequestTypeId: number;
  changeRequestApprovalWorkflow: ChangeRequestTypeApprovalFlowDTO;
  activeTab: string;
}

interface WorkflowDetailsPageProps {
}

class WorkflowDetailsPage extends React.Component<WorkflowDetailsPageProps & RouteComponentProps<RouteObject>, WorkflowDetailsPageState> {
  private readonly _changeRequestApprovalWorkflowDetailsRef = React.createRef<WorkflowDetailsForm>();
  private readonly _changeRequestTypeDetailsRef = React.createRef<ChangeRequestTypeDetailsForm>();

  constructor(props: WorkflowDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    const editings = new Map<string, boolean>();
    editings.set('details', false);
    editings.set('approval_flow', false);

    this.state = {
      activeTab: 'details',
      editings: editings,
      changeRequestTypeId: 0,
      changeRequestApprovalWorkflow: ChangeRequestTypeApprovalFlowDTO.create(),
    };
  }

  componentDidMount() {
    const changeRequestTypeId = ParameterUtil.getPathPart(this.props.match, 'id');
    const activeTab = ParameterUtil.getParameter('activeTab', 'details');
    this.setState({ activeTab: activeTab });

    if (changeRequestTypeId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      this.loadChangeRequestApprovalWorkflow(Number.parseInt(changeRequestTypeId));
    }
  }

  private loadChangeRequestApprovalWorkflow = (changeRequestTypeId: number) => {

    ChangeRequestTypeApprovalFlowApiService.getChangeRequestApprovalWorkflowDetailsHandler(changeRequestTypeId)
      .then((results: GetChangeRequestApprovalWorkflowDetailsHandler.Result) => {
        if (results.changeRequestApprovalWorkflow) {
          this.setState({
            changeRequestApprovalWorkflow: results.changeRequestApprovalWorkflow,
            changeRequestTypeId: results.changeRequestApprovalWorkflow.changeRequestTypeId
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = (key: string) => {
    const editings = this.state.editings;
    if (editings?.get(key) && key == 'approval_flow') {
      this._changeRequestApprovalWorkflowDetailsRef.current?.resetForm();
    }
    else if (editings?.get(key) && key == 'details') {
      this._changeRequestTypeDetailsRef.current?.resetForm();
    }

    editings?.set(key, !editings.get(key));

    this.setState({ editings: editings });
  }

  private changeRequestApprovalWorkflowSave = (changeRequestTypeId: number) => {
    const editings = this.state.editings;
    editings?.set('approval_flow', !editings.get('approval_flow'));
    this.setState({ editings: editings });

    this.loadChangeRequestApprovalWorkflow(changeRequestTypeId);
  }

  private changeRequestDetailsSave = () => {
    const editings = this.state.editings;
    editings?.set('details', !editings.get('details'));
    this.setState({ editings: editings });
  }

  private goToChangeRequestApprovalWorkflow = () => {
    HistoryUtil.goBack();
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab })
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  render() {
    const title = this.state.loading ? '...' : this.state.changeRequestTypeId == 0 ? 'New Change Request Approval Workflow' : this.state.changeRequestApprovalWorkflow.changeRequestType?.name ?? '...';
    const breadcrumb = this.state.loading ? '...' : this.state.changeRequestTypeId == 0 ? 'New' : this.state.changeRequestApprovalWorkflow.changeRequestType?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToChangeRequestApprovalWorkflow}
          breadcrumbs={Breadcrumbs.changeRequestTypeDetails(breadcrumb, this.state.changeRequestTypeId)}
          footer={
            <Tabs activeKey={this.state.activeTab} key='content' onChange={this.changeTab}>
              <Tabs.TabPane key='details' tab='Details' />
              <Tabs.TabPane key='approval_flow' tab='Approval Flow' />
            </Tabs>
          } />
        <PageStayPrompt when={this.state.isEditing} />
        {this.renderDetailsCard()}
        {this.renderApprovalFlowCard()}
      </Content>
    );
  }

  renderApprovalFlowCard() {
    if (this.state.activeTab === 'approval_flow') {
      const editButton = this.state.changeRequestTypeId == 0 ?
        null :
        (
          <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_WORKFLOW]}>
            <EditCancelButton isEditing={this.state.editings?.get('approval_flow')} onClick={() => this.toggleEdit('approval_flow')} />
          </AuthorizedContent>
        );

      return (
        <Card title="Approval Flow" extra={editButton}>
          <WorkflowDetailsForm
            ref={this._changeRequestApprovalWorkflowDetailsRef}
            changeRequestTypeApprovalFlowId={this.state.changeRequestTypeId}
            isEditing={this.state.editings?.get('approval_flow')}
            onSave={this.changeRequestApprovalWorkflowSave} />
        </Card>
      );
    }
  }

  renderDetailsCard() {
    if (this.state.activeTab === 'details') {
      const editButton = this.state.changeRequestTypeId == 0 ?
        null :
        (
          <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_WORKFLOW]}>
            <EditCancelButton isEditing={this.state.editings?.get('details')} onClick={() => this.toggleEdit('details')} />
          </AuthorizedContent>
        );

      return (
        <Card title="Details" extra={editButton}>
          <ChangeRequestTypeDetailsForm
            ref={this._changeRequestTypeDetailsRef}
            onSave={this.changeRequestDetailsSave}
            changeRequestTypeId={this.state.changeRequestTypeId}
            isEditing={this.state.editings?.get('details')} />
        </Card>
      );
    }
  }
}

export default withRouter(WorkflowDetailsPage);
