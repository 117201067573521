// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import TransferMajorDisciplineFrameworkElementCourseDTO from './TransferMajorDisciplineFrameworkElementCourseDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface TransferMajorDisciplineFrameworkElementDTO extends EntitySelectorDTO { 
	id: number;
	name: string | null;
	transferMajorDisciplineFrameworkElementCourses: TransferMajorDisciplineFrameworkElementCourseDTO[] | null;
}

const TransferMajorDisciplineFrameworkElementDTO = {
    className: 'TransferMajorDisciplineFrameworkElementDTO',
    create: (initValues?: Partial<TransferMajorDisciplineFrameworkElementDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: 0,
			name: "",
			transferMajorDisciplineFrameworkElementCourses: [],
        },
        initValues);
    },
	id: 'id',
	name: 'name',
	transferMajorDisciplineFrameworkElementCourses: 'transferMajorDisciplineFrameworkElementCourses',
};

export default TransferMajorDisciplineFrameworkElementDTO;