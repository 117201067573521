import * as queryString from 'query-string';
import { generatePath, matchPath } from 'react-router';

class Routes {
  public static BASE_ROUTE = $appPath && $appPath.length > 0 ? '/' + $appPath : '';

  public static HOME = Routes.BASE_ROUTE + '/';
  public static LOGIN = Routes.BASE_ROUTE + '/login';
  public static PROFILE = Routes.BASE_ROUTE + '/profile';
  public static REQUEST_RESET_PASSWORD = Routes.BASE_ROUTE + '/reset-password';
  public static RESET_PASSWORD_LINK = Routes.BASE_ROUTE + '/resetPassword';
  public static REGISTER = Routes.BASE_ROUTE + '/register';
  public static CONFIRM_EMAIL = Routes.BASE_ROUTE + '/confirmEmail';

  public static RESEND_CONFIRMATION_EMAIL = Routes.BASE_ROUTE + '/resend-confirmation-email';
  public static DASHBOARD = Routes.BASE_ROUTE + '/dashboard';

  public static CONFIGURATIONS = Routes.BASE_ROUTE + '/configuration';
  public static LOOKUP_CONFIGURATIONS = Routes.CONFIGURATIONS + '/lookups';

  public static POST_SECONDARY_HOME = Routes.BASE_ROUTE + '/postSecondary';

  public static ANNOUNCEMENTS = Routes.BASE_ROUTE + '/announcements';
  public static ANNOUNCEMENT_DETAILS = Routes.ANNOUNCEMENTS + '/:id';
  public static NEW_ANNOUNCEMENT = Routes.ANNOUNCEMENTS + '/new';

  public static APS_GENERAL_EDUCATION = Routes.CONFIGURATIONS + '/apsGeneralEducation';
  public static APS_GENERAL_EDUCATION_DETAILS = Routes.APS_GENERAL_EDUCATION + '/:id';
  public static NEW_APS_GENERAL_EDUCATION = Routes.APS_GENERAL_EDUCATION + '/new';

  public static AWARD_TYPE = Routes.CONFIGURATIONS + '/awardType';
  public static AWARD_TYPE_DETAILS = Routes.AWARD_TYPE + '/:id';
  public static NEW_AWARD_TYPE = Routes.AWARD_TYPE + '/new';

  public static CATEGORIES = Routes.CONFIGURATIONS + '/categories';
  public static CATEGORY_DETAILS = Routes.CATEGORIES + '/:id';
  public static NEW_CATEGORY = Routes.CATEGORIES + '/new';

  public static CHANGE_REQUESTS = Routes.BASE_ROUTE + '/changeRequests';
  public static CHANGE_REQUEST_DRAFT = Routes.CHANGE_REQUESTS + '/draft/:key';
  public static CHANGE_REQUEST_DRAFT_ID = Routes.CHANGE_REQUESTS + '/draft/:id';
  public static CHANGE_REQUEST_DETAILS = Routes.CHANGE_REQUESTS + '/details/:id';
  public static CHANGE_REQUEST_APPROVAL = Routes.CHANGE_REQUESTS + '/approval/:approvalId';
  public static CHANGE_REQUEST_APPROVAL_REDIRECT = Routes.CHANGE_REQUESTS + '/redirect/:approvalId';

  public static CHANGE_REQUEST_TYPE = Routes.CONFIGURATIONS + '/changeRequestType';
  public static CHANGE_REQUEST_TYPE_DETAILS = Routes.CHANGE_REQUEST_TYPE + '/details/:id';

  public static CONTACTS = Routes.BASE_ROUTE + '/contact';

  public static CONTACT_TYPE = Routes.BASE_ROUTE + '/contactType';
  public static CONTACT_TYPE_DETAILS = Routes.CONTACT_TYPE + '/:id';
  public static NEW_CONTACT_TYPE = Routes.CONTACT_TYPE + '/new';

  public static ADMINISTRATIVE_CHANGE_REQUEST_ENTRY = Routes.CHANGE_REQUESTS + '/ac/entry/new';
  public static EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_1 = Routes.CHANGE_REQUESTS + '/ac/entry/1/:id';
  public static EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_2 = Routes.CHANGE_REQUESTS + '/ac/entry/2/:id';
  public static EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_REVIEW = Routes.CHANGE_REQUESTS + '/ac/entry/review/:id';
  public static EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_SUBMITTED = Routes.CHANGE_REQUESTS + '/ac/entry/submitted/:id';

  public static NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY = Routes.CHANGE_REQUESTS + '/nd/entry/new';
  public static EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_1 = Routes.CHANGE_REQUESTS + '/nd/entry/1/:id';
  public static EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_2 = Routes.CHANGE_REQUESTS + '/nd/entry/2/:id';
  public static EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_REVIEW = Routes.CHANGE_REQUESTS + '/nd/entry/review/:id';
  public static EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_SUBMITTED = Routes.CHANGE_REQUESTS + '/nd/entry/submitted/:id';

  public static NEW_COURSE_CHANGE_REQUEST_ENTRY = Routes.CHANGE_REQUESTS + '/nc/entry/new';
  public static EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_1 = Routes.CHANGE_REQUESTS + '/nc/entry/1/:id';
  public static EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_2 = Routes.CHANGE_REQUESTS + '/nc/entry/2/:id';
  public static EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_REVIEW = Routes.CHANGE_REQUESTS + '/nc/entry/review/:id';
  public static EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_SUBMITTED = Routes.CHANGE_REQUESTS + '/nc/entry/submitted/:id';

  public static COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY = Routes.CHANGE_REQUESTS + '/cm/entry/new';
  public static EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_1 = Routes.CHANGE_REQUESTS + '/cm/entry/1/:id';
  public static EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_2 = Routes.CHANGE_REQUESTS + '/cm/entry/2/:id';
  public static EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_3 = Routes.CHANGE_REQUESTS + '/cm/entry/3/:id';
  public static EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_REVIEW = Routes.CHANGE_REQUESTS + '/cm/entry/review/:id';
  public static EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_SUBMITTED = Routes.CHANGE_REQUESTS + '/cm/entry/submitted/:id';

  public static COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY = Routes.CHANGE_REQUESTS + '/cd/entry/new';
  public static EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_1 = Routes.CHANGE_REQUESTS + '/cd/entry/1/:id';
  public static EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_2 = Routes.CHANGE_REQUESTS + '/cd/entry/2/:id';
  public static EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_REVIEW = Routes.CHANGE_REQUESTS + '/cd/entry/review/:id';
  public static EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_SUBMITTED = Routes.CHANGE_REQUESTS + '/cd/entry/submitted/:id';

  public static NEW_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY = Routes.CHANGE_REQUESTS + '/noi/entry/new';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_1 = Routes.CHANGE_REQUESTS + '/noi/entry/1/:id';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_2 = Routes.CHANGE_REQUESTS + '/noi/entry/2/:id';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_3 = Routes.CHANGE_REQUESTS + '/noi/entry/3/:id';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_4 = Routes.CHANGE_REQUESTS + '/noi/entry/4/:id';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_5 = Routes.CHANGE_REQUESTS + '/noi/entry/5/:id';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_6 = Routes.CHANGE_REQUESTS + '/noi/entry/6/:id';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_7 = Routes.CHANGE_REQUESTS + '/noi/entry/7/:id';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_8 = Routes.CHANGE_REQUESTS + '/noi/entry/8/:id';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_REVIEW = Routes.CHANGE_REQUESTS + '/noi/entry/review/:id';
  public static EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_SUBMITTED = Routes.CHANGE_REQUESTS + '/noi/entry/submitted/:id';

  public static PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY = Routes.CHANGE_REQUESTS + '/pd/entry/new';
  public static EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_1 = Routes.CHANGE_REQUESTS + '/pd/entry/1/:id';
  public static EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_2 = Routes.CHANGE_REQUESTS + '/pd/entry/2/:id';
  public static EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_REVIEW = Routes.CHANGE_REQUESTS + '/pd/entry/review/:id';
  public static EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_SUBMITTED = Routes.CHANGE_REQUESTS + '/pd/entry/submitted/:id';

  public static NEW_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY = Routes.CHANGE_REQUESTS + '/pm/entry/new';
  public static EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_1 = Routes.CHANGE_REQUESTS + '/pm/entry/1/:id';
  public static EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_2 = Routes.CHANGE_REQUESTS + '/pm/entry/2/:id';
  public static EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_3 = Routes.CHANGE_REQUESTS + '/pm/entry/3/:id/award/:awardId';
  public static EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_4 = Routes.CHANGE_REQUESTS + '/pm/entry/4/:id/award/:awardId';
  public static EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_5 = Routes.CHANGE_REQUESTS + '/pm/entry/5/:id';
  public static EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_6 = Routes.CHANGE_REQUESTS + '/pm/entry/6/:id';
  public static EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_REVIEW = Routes.CHANGE_REQUESTS + '/pm/entry/review/:id';
  public static EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_SUBMITTED = Routes.CHANGE_REQUESTS + '/pm/entry/submitted/:id';

  public static NEW_PROGRAM_CHANGE_REQUEST_ENTRY = Routes.CHANGE_REQUESTS + '/np/entry/new';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_1 = Routes.CHANGE_REQUESTS + '/np/entry/1/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_2 = Routes.CHANGE_REQUESTS + '/np/entry/2/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_3 = Routes.CHANGE_REQUESTS + '/np/entry/3/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_4 = Routes.CHANGE_REQUESTS + '/np/entry/4/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_5 = Routes.CHANGE_REQUESTS + '/np/entry/5/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_6 = Routes.CHANGE_REQUESTS + '/np/entry/6/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_7 = Routes.CHANGE_REQUESTS + '/np/entry/7/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_8 = Routes.CHANGE_REQUESTS + '/np/entry/8/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_9 = Routes.CHANGE_REQUESTS + '/np/entry/9/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_10 = Routes.CHANGE_REQUESTS + '/np/entry/10/:id/award/:awardId';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_11 = Routes.CHANGE_REQUESTS + '/np/entry/11/:id/award/:awardId';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_12 = Routes.CHANGE_REQUESTS + '/np/entry/12/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_13 = Routes.CHANGE_REQUESTS + '/np/entry/13/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_14 = Routes.CHANGE_REQUESTS + '/np/entry/14/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_15 = Routes.CHANGE_REQUESTS + '/np/entry/15/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_REVIEW = Routes.CHANGE_REQUESTS + '/np/entry/review/:id';
  public static EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_SUBMITTED = Routes.CHANGE_REQUESTS + '/np/entry/submitted/:id';

  public static SECONDARY_HOME = Routes.BASE_ROUTE + '/secondary';

  public static COURSES = Routes.BASE_ROUTE + '/courses';
  public static COURSE_DETAILS = Routes.COURSES + '/institutionCourse/:institutionCourseId';
  public static NEW_COURSE = Routes.COURSES + '/new';

  public static PROGRAMS = Routes.BASE_ROUTE + '/programs';
  public static PROGRAM_DETAILS = Routes.PROGRAMS + '/:id';

  public static INSTITUITION_COURSE_BLOCK_DETAILS = Routes.COURSES + '/block/:id';
  public static NEW_INSTITUITION_COURSE_BLOCK = Routes.COURSES + '/block/new';

  public static CREDIT_TYPES = Routes.CONFIGURATIONS + '/creditTypes';
  public static CREDIT_TYPE_DETAILS = Routes.CREDIT_TYPES + '/:id';
  public static NEW_CREDIT_TYPE = Routes.CREDIT_TYPES + '/new';

  public static DISCIPLINES = Routes.CONFIGURATIONS + '/disciplines';
  public static DISCIPLINE_DETAILS = Routes.DISCIPLINES + '/:id';
  public static NEW_DISCIPLINE = Routes.DISCIPLINES + '/new';

  public static DOCUMENTARCHIVES = Routes.BASE_ROUTE + '/documentArchives';

  public static INSTITUTIONS = Routes.BASE_ROUTE + '/institutions';
  public static INSTITUTION_DETAILS = Routes.INSTITUTIONS + '/:id';
  public static NEW_INSTITUTION = Routes.INSTITUTIONS + '/new';

  public static INSTITUTION_USERS = '/:institutionId/users';
  public static INSTITUTION_USER_DETAILS = Routes.INSTITUTION_USERS + '/:userId';
  public static USER_INSTITUTION_DETAILS = Routes.BASE_ROUTE + '/users/:userId/institutions/:institutionId';

  public static COURSE_INSTITUTION_DETAILS = Routes.BASE_ROUTE + '/courses/:courseId/institutions/:institutionId/institutionCourse/:institutionCourseId';
  public static INSTITUTION_COURSE_DETAILS = '/:institutionId/courses/:courseId/institutionCourse/:institutionCourseId';
  public static SINGLE_INSTITUTION_COURSE_DETAILS = '/:institutionId/courses/:courseId/institutionCourseDetails';

  public static ADMIN_RESOURCES = Routes.BASE_ROUTE + '/adminResources';
  public static RESOURCES = Routes.BASE_ROUTE + '/resources';

  public static DOWNLOADS_SECTION = Routes.BASE_ROUTE + '/downloads';

  public static CONSULTANTS = Routes.BASE_ROUTE + '/consultants';
  public static PAST_ANNOUNCEMENTS = Routes.BASE_ROUTE + '/announcements';

  public static USERS = Routes.BASE_ROUTE + '/users';
  public static USER_DETAILS = Routes.USERS + '/:id';
  public static ADD_USER = Routes.USERS + '/new';
  public static AA_USER_IMPORT_TEMPLATE = Routes.BASE_ROUTE + 'api/aaUsers/aaUserTemplate';

  public static USER_SETTINGS = Routes.BASE_ROUTE + '/userSettings';

  public static ROLES = Routes.CONFIGURATIONS + '/roles';
  public static ROLE_DETAILS = Routes.ROLES + '/:id';
  public static NEW_ROLE = Routes.ROLES + '/new';

  public static TERMS = Routes.CONFIGURATIONS + '/terms';
  public static TERM_DETAILS = Routes.TERMS + '/:id';
  public static NEW_TERM = Routes.TERMS + '/new';

  public static THIRD_PARTY_CREDENTIAL_TYPES = Routes.CONFIGURATIONS + '/thirdPartyCredentialTypes';
  public static THIRD_PARTY_CREDENTIAL_TYPE_DETAILS = Routes.THIRD_PARTY_CREDENTIAL_TYPES + '/:id';
  public static NEW_THIRD_PARTY_CREDENTIAL_TYPE = Routes.THIRD_PARTY_CREDENTIAL_TYPES + '/new';

  public static RACIAL_ETHNIC_GROUPS = Routes.CONFIGURATIONS + '/racialEthnicGroups';
  public static RACIAL_ETHNIC_GROUP_DETAILS = Routes.RACIAL_ETHNIC_GROUPS + '/:id';
  public static NEW_RACIAL_ETHNIC_GROUP = Routes.RACIAL_ETHNIC_GROUPS + '/new';

  public static CIPS = Routes.CONFIGURATIONS + '/cips';
  public static CIP_DETAILS = Routes.CIPS + '/:id';
  public static NEW_CIP = Routes.CIPS + '/new';

  public static PROGRAM_CLASSIFICATION = Routes.CONFIGURATIONS + '/programClassification';
  public static PROGRAM_CLASSIFICATION_DETAILS = Routes.PROGRAM_CLASSIFICATION + '/:id';
  public static NEW_PROGRAM_CLASSIFICATION = Routes.PROGRAM_CLASSIFICATION + '/new';

  public static NOTICE_OF_INTENT_CHANGE_REQUEST_WORKFORCE_LINK = Routes.CONFIGURATIONS + '/noticeOfIntentChangeRequestWorkForceLink';

  public static EXTERNAL_INSTITUTIONS = Routes.CONFIGURATIONS + '/externalInstitution';
  public static EXTERNAL_INSTITUTIONS_DETAILS = Routes.EXTERNAL_INSTITUTIONS + '/:id';
  public static NEW_EXTERNAL_INSTITUTIONS = Routes.EXTERNAL_INSTITUTIONS + '/new';

  public static GENDER = Routes.CONFIGURATIONS + '/gender';
  public static GENDER_DETAILS = Routes.GENDER + '/:id';
  public static NEW_GENDER = Routes.GENDER + '/new';

  public static GENERAL_EDUCATION = Routes.CONFIGURATIONS + '/generalEducation';
  public static GENERAL_EDUCATION_DETAILS = Routes.GENERAL_EDUCATION + '/:id';
  public static NEW_GENERAL_EDUCATION = Routes.GENERAL_EDUCATION + '/new';

  public static INSTRUCTIONAL_LEVEL = Routes.CONFIGURATIONS + '/instructionalLevel';
  public static INSTRUCTIONAL_LEVEL_DETAILS = Routes.INSTRUCTIONAL_LEVEL + '/:id';
  public static NEW_INSTRUCTIONAL_LEVEL = Routes.INSTRUCTIONAL_LEVEL + '/new';

  public static IOWA_VARIATION = Routes.CONFIGURATIONS + '/iowaVariation';
  public static IOWA_VARIATION_DETAILS = Routes.IOWA_VARIATION + '/:id';
  public static NEW_IOWA_VARIATION = Routes.IOWA_VARIATION + '/new';

  public static OBJECT_PURPOSE = Routes.CONFIGURATIONS + '/objectPurposes';
  public static OBJECT_PURPOSE_DETAILS = Routes.OBJECT_PURPOSE + '/:id';
  public static NEW_OBJECT_PURPOSE = Routes.OBJECT_PURPOSE + '/new';

  public static SPECIAL_EMPHASIS = Routes.CONFIGURATIONS + '/specialEmphases';
  public static SPECIAL_EMPHASIS_DETAILS = Routes.SPECIAL_EMPHASIS + '/:id';
  public static NEW_SPECIAL_EMPHASIS = Routes.SPECIAL_EMPHASIS + '/new';

  public static ACADEMIC_YEARS = Routes.BASE_ROUTE + '/academicYears';

  public static SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD = Routes.ACADEMIC_YEARS + '/academicYear' + '/:academicYearId';

  public static SERVICE_AREAS = Routes.BASE_ROUTE + '/:academicYearId/serviceAreas';
  public static ALL_SERVICE_AREAS = Routes.BASE_ROUTE + '/serviceAreas';
  public static NEW_SERVICE_AREA = Routes.BASE_ROUTE + '/serviceArea/new';
  public static SERVICE_AREA_DETAILS = Routes.BASE_ROUTE + '/serviceArea/:id';
  public static ACADEMIC_YEAR_SERVICE_AREAS_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/serviceAreas';
  public static DATA_REVIEW_SERVICE_AREAS = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/serviceAreas';
  public static SERVICE_AREA_CONFIGURATION = Routes.CONFIGURATIONS + '/secondary/serviceAreas';

  public static FEDERAL_CLUSTERS = Routes.BASE_ROUTE + '/:academicYearId/federalClusters';
  public static ACADEMIC_YEAR_FEDERAL_CLUSTERS_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/federalClusters';
  public static ALL_FEDERAL_CLUSTERS = Routes.BASE_ROUTE + '/federalClusters';
  public static NEW_FEDERAL_CLUSTER = Routes.BASE_ROUTE + '/federalCluster/new';
  public static FEDERAL_CLUSTER_DETAILS = Routes.BASE_ROUTE + '/federalCluster/:id';
  public static DATA_REVIEW_FEDERAL_CLUSTERS = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/federalClusters';
  public static FEDERAL_CLUSTERS_CONFIGURATION = Routes.CONFIGURATIONS + '/secondary/federalClusters';

  public static TRANSFER_MAJOR_LACTS_CATEGORY = Routes.CONFIGURATIONS + '/transferMajorLactsCategory';
  public static TRANSFER_MAJOR_LACTS_CATEGORY_DETAILS = Routes.TRANSFER_MAJOR_LACTS_CATEGORY + '/:id';
  public static NEW_TRANSFER_MAJOR_LACTS_CATEGORY = Routes.TRANSFER_MAJOR_LACTS_CATEGORY + '/new';

  public static TRANSFER_MAJOR_COURSE_BLOCK = Routes.CONFIGURATIONS + '/transferMajorCourseBlock';
  public static TRANSFER_MAJOR_COURSE_BLOCK_DETAILS = Routes.TRANSFER_MAJOR_COURSE_BLOCK + '/:id';
  public static NEW_TRANSFER_MAJOR_COURSE_BLOCK = Routes.TRANSFER_MAJOR_COURSE_BLOCK + '/new';

  public static TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE = Routes.CONFIGURATIONS + '/TransferMajorGeneralEducationType';
  public static TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE_DETAILS = Routes.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE + '/:id';
  public static NEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE = Routes.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE + '/new';

  public static TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT = Routes.CONFIGURATIONS + '/transferMajorDisciplineFrameworkElement';
  public static TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT_DETAILS = Routes.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT + '/:id';
  public static NEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT = Routes.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT + '/new';

  public static TYPE_OF_PROGRAM = Routes.CONFIGURATIONS + '/typeOfProgram';
  public static TYPE_OF_PROGRAM_DETAILS = Routes.TYPE_OF_PROGRAM + '/:id';
  public static NEW_TYPE_OF_PROGRAM = Routes.TYPE_OF_PROGRAM + '/new';

  public static REGIONS = Routes.CONFIGURATIONS + '/rpp';
  public static REGION_DETAIL = Routes.REGIONS + '/:rppId';
  public static REGIONAL_PLANNING_PARTNERS = Routes.HOME + 'secondary/rpp';
  public static NEW_REGIONAL_PLANNING_PARTNER_DETAIL = Routes.REGIONAL_PLANNING_PARTNERS + '/new';
  public static REGIONAL_PLANNING_PARTNER_DETAIL = Routes.REGIONAL_PLANNING_PARTNERS + 'secondary/rpp/:id';
  public static DATA_REVIEW_REGIONAL_PLANNING_PARTNER = '/secondary/academicYears/:academicYear/config/regions';
  public static ACADEMIC_YEAR_REGIONAL_PLANNING_PARTNER_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/RPP';

  public static WORK_ITEMS = Routes.BASE_ROUTE + '/workItems';

  public static WORK_BASED_LEARNING_TYPE = Routes.CONFIGURATIONS + '/workBasedLearningType';
  public static WORK_BASED_LEARNING_DETAILS = Routes.WORK_BASED_LEARNING_TYPE + '/:id';
  public static NEW_WORK_BASED_LEARNING_TYPE = Routes.WORK_BASED_LEARNING_TYPE + '/new';

  public static UNAUTHORIZED = Routes.BASE_ROUTE + '/unauthorized';

  public static SECURITY_BASE = Routes.BASE_ROUTE + '/security';
  public static SECURITY_USERS = Routes.SECURITY_BASE + '/users';
  public static SECURITY_USER_DETAIL = Routes.SECURITY_USERS + '/:id';
  public static SECURITY_ROLEGROUPS = Routes.SECURITY_BASE + '/roleGroups';
  public static SECURITY_ROLEGROUP_DETAIL = Routes.SECURITY_ROLEGROUPS + '/:id';

  public static SELF_STUDIES_LIST = Routes.BASE_ROUTE + '/selfStudy/academicYears/:academicYearId/config/selfStudies';
  public static SELF_STUDIES_REVIEW = Routes.BASE_ROUTE + '/selfStudy/academicYears/:academicYearId/review/selfStudies';
  public static SELF_STUDIES_ACADEMIC_YEAR_LIST = Routes.BASE_ROUTE + '/academicYearList/academicYears/:academicYearId/config/selfStudies';
  public static SELF_STUDIES_DETAILS = Routes.BASE_ROUTE + '/secondary/selfStudy/:id';
  public static SELF_STUDIES_DISPLAY = Routes.BASE_ROUTE + '/secondary/selfStudyDisplay/:id';
  public static SELF_STUDIES_RESPONSE_DISPLAY = Routes.BASE_ROUTE + '/selfStudy/:selfStudyId/selfStudyResponseDisplay/:id';
  public static NEW_SELF_STUDY = Routes.BASE_ROUTE + '/selfStudy/new';
  //public static ACADEMIC_YEARS = Routes.BASE_ROUTE + '/academicYears';
  public static COMPLETE_SELF_STUDIES = Routes.BASE_ROUTE + '/self-studies/config/academicYear/:academicYearId/selfStudyResponse/:selfStudyResponseId/sectionIndex/:sectionIndex';
  public static SELF_STUDIES_CONFIGURATION = Routes.BASE_ROUTE + '/self-studies-configuration/:academicYearId';
  //public static SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD = Routes.ACADEMIC_YEARS + '/academicYear' + '/:academicYearId';
  public static NEW_ACADEMIC_YEAR = Routes.ACADEMIC_YEARS + '/new/:academicYearId';
  public static ALL_ACADEMIC_YEARS = Routes.ACADEMIC_YEARS + '/academicYearList';
  public static ACADEMIC_YEAR_DASHBOARD_FROM_LIST = Routes.ALL_ACADEMIC_YEARS + '/academicYear/:academicYearId';
  public static SELF_STUDIES_CONFIGURATION_FROM_LIST = Routes.ALL_ACADEMIC_YEARS + '/academicYear/self-studies-configuration/:academicYearId';
  public static NEW_ACADEMIC_YEAR_FROM_LIST = Routes.ALL_ACADEMIC_YEARS + '/academicYear-list/self-studies-configuration/:academicYearId';
  public static ACADEMIC_YEAR_SELF_STUDY_RANKING_REPORT = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/report/selfStudyRanking';
  public static ACADEMIC_YEAR_PROGRAM_CONSULTANT_COMMENT_REPORT = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/report/programConsultantComment';
  public static ACADEMIC_YEAR_SELF_STUDY_AGGREGATE_REPORT = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/report/selfStudyAggregate';
  public static ACADEMIC_YEAR_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/academicYear';

  public static AREA_EDUCATION_AGENCIES = Routes.BASE_ROUTE + '/:academicYearId/area-education-agencies';
  public static ACADEMIC_YEAR_AREA_EDUCATION_AGENCIES_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/area-education-agencies';
  public static ALL_AREA_EDUCATION_AGENCIES = Routes.BASE_ROUTE + '/area-education-agencies';
  public static NEW_AREA_EDUCATION_AGENCY = Routes.BASE_ROUTE + '/area-education-agency/new';
  public static AREA_EDUCATION_AGENCY_DETAILS = Routes.BASE_ROUTE + '/area-education-agency/:id';
  public static REVIEW_AREA_EDUCATION_AGENCY = Routes.BASE_ROUTE + '/academicYears/open/areaEducationAgency/:academicYearId';
  public static DATA_REVIEW_AREA_EDUCATION_AGENCY = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/areaEducationAgency';
  public static AREA_EDUCATION_AGENCIES_CONFIGURATION = Routes.CONFIGURATIONS + '/secondary/area-education-agencies';

  public static ACADEMIC_YEAR_CONTACTS_REPORT = Routes.BASE_ROUTE + '/academicYears/:academicYearId/reports/contactsReport';
  public static ACADEMIC_YEAR_CAREER_ACADEMY_REPORT = Routes.BASE_ROUTE + '/academicYears/:academicYearId/reports/careerAcademyReport';
  public static ACADEMIC_YEAR_ACTION_PLAN_REPORT = Routes.BASE_ROUTE + '/academicYears/:academicYearId/reports/actionPlanReport';
  public static ACADEMIC_YEAR_SCENARIO_REPORT = Routes.BASE_ROUTE + '/academicYears/:academicYearId/reports/scenarioReport';
  public static ACADEMIC_YEAR_PROGRAM_MASTER_REPORT = Routes.BASE_ROUTE + '/academicYears/:academicYearId/reports/programMasterReport';

  public static DISTRICTS = Routes.BASE_ROUTE + '/selfStudy/academicYears/:academicYearId/config/districts';
  public static DISTRICTS_ACADEMIC_YEAR_LIST = Routes.BASE_ROUTE + '/academicYearList/academicYears/:academicYearId/config/districts';
  public static PUBLIC_DISTRICTS = Routes.BASE_ROUTE + '/secondary/districts';
  public static ALL_DISTRICTS = Routes.BASE_ROUTE + '/districts';
  public static NEW_DISTRICT = Routes.BASE_ROUTE + '/district/new';
  public static DISTRICT_DETAILS = Routes.BASE_ROUTE + '/secondary/district/:id';
  public static DATA_REVIEW_DISTRICTS = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/districts';
  public static DATA_REVIEW_DISTRICT_CONTACTS = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/districtContacts';
  public static DISTRICT_IMPORT_TEMPLATE = Routes.BASE_ROUTE + 'api/districts/districtTemplate';
  public static DISTRICT_CONTACT_IMPORT_TEMPLATE = Routes.BASE_ROUTE + 'api/districts/districtContactTemplate';
  public static ACADEMIC_YEAR_DISTRICT_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/district';
  public static ACADEMIC_YEAR_DISTRICT_CONTACT_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/districtContact';

  public static SECONDARY_PROGRAM_TYPES = Routes.BASE_ROUTE + '/:academicYearId/SecondaryProgramScenario';
  public static ALL_SECONDARY_PROGRAM_TYPES = Routes.BASE_ROUTE + '/SecondaryProgramScenarios';
  public static NEW_SECONDARY_PROGRAM_TYPE = Routes.BASE_ROUTE + '/SecondaryProgramScenario/new';
  public static SECONDARY_PROGRAM_TYPE_DETAILS = Routes.BASE_ROUTE + '/SecondaryProgramScenario/:id';
  public static DATA_REVIEW_SECONDARY_PROGRAM_TYPES = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/programTypes';
  public static SCENARIO_CONFIGURATION = Routes.CONFIGURATIONS + '/secondary/scenario';
  public static ACADEMIC_YEAR_SCENARIOS_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/scenarios';

  public static SECONDARY_PROGRAMS = Routes.BASE_ROUTE + '/selfStudy/academicYears/:academicYearId/config/programs';
  public static PUBLIC_PROGRAMS = Routes.BASE_ROUTE + '/secondary/programs';
  public static SECONDARY_PROGRAMS_ACADEMIC_YEAR_LIST = Routes.BASE_ROUTE + '/academicYearList/academicYears/:academicYearId/config/programs';
  public static ALL_SECONDARY_PROGRAMS = Routes.BASE_ROUTE + '/secondary/programs';
  public static NEW_SECONDARY_PROGRAM = Routes.BASE_ROUTE + '/secondaryProgram/new';
  public static SECONDARY_PROGRAM_DETAILS = Routes.BASE_ROUTE + '/secondary/programs/:id';
  public static DATA_REVIEW_SECONDARY_PROGRAMS = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/programs';
  public static SECONDARY_PROGRAM_IMPORT_TEMPLATE = Routes.BASE_ROUTE + 'api/secondaryPrograms/secondaryProgramTemplate';
  public static ACADEMIC_YEAR_SECONDARY_PROGRAM_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/secondaryProgram';

  public static SECONDARY_CIP_NUMBERS = Routes.ACADEMIC_YEARS + '/secondary/:academicYearId/cip';
  public static SECONDARY_CIP_NUMBERS_CONFIGURATION = Routes.CONFIGURATIONS + '/secondary/cip';
  public static ALL_SECONDARY_CIP_NUMBERS = Routes.ACADEMIC_YEAR_DASHBOARD_FROM_LIST + '/secondary/cip';
  public static NEW_SECONDARY_CIP_NUMBER = Routes.ACADEMIC_YEARS + '/secondaryCipNumber/new';
  public static SECONDARY_CIP_NUMBER_DETAILS = Routes.ACADEMIC_YEARS + '/secondary/cip/:id';
  public static DATA_REVIEW_SECONDARY_CIP_NUMBERS = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/cipNumbers';
  public static SECONDARY_CIP_NUMBER_IMPORT_TEMPLATE = Routes.BASE_ROUTE + 'api/secondaryCipNumbers/secondaryCipNumberTemplate';
  public static ACADEMIC_YEAR_SECONDARY_CIP_NUMBER_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/secondaryCipNumber';

  public static SECONDARY_COURSES = Routes.BASE_ROUTE + '/selfStudy/academicYears/:academicYearId/config/secondaryCourses';
  public static SECONDARY_COURSES_ACADEMIC_YEAR_LIST = Routes.BASE_ROUTE + '/academicYearList/academicYears/:academicYearId/config/secondaryCourses';
  public static ALL_SECONDARY_COURSES = Routes.BASE_ROUTE + '/secondary/courses';
  public static NEW_SECONDARY_COURSE = Routes.BASE_ROUTE + '/secondaryCourse/new';
  public static SECONDARY_COURSE_DETAILS = Routes.BASE_ROUTE + '/secondary/courses/:id';
  public static DATA_REVIEW_SECONDARY_COURSES = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/courses';
  public static SECONDARY_COURSE_IMPORT_TEMPLATE = Routes.BASE_ROUTE + 'api/secondaryCourses/secondaryCourseTemplate';
  public static ACADEMIC_YEAR_SECONDARY_COURSES_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/secondaryCourses';

  public static SECONDARY_PROGRAM_COURSES = Routes.BASE_ROUTE + '/secondary/:academicYearId/programCourses';
  public static ALL_SECONDARY_PROGRAM_COURSES = Routes.BASE_ROUTE + '/secondary/programCourses';
  public static DATA_REVIEW_SECONDARY_PROGRAM_COURSES = Routes.BASE_ROUTE + '/secondary/academicYears/:academicYearId/config/programCourses';
  public static SECONDARY_PROGRAM_COURSES_IMPORT_TEMPLATE = Routes.BASE_ROUTE + 'api/secondaryProgramCourses/secondaryProgramCourseTemplate';
  public static ACADEMIC_YEAR_SECONDARY_PROGRAM_COURSES_CONFIGURATION = Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD + '/configuration/secondaryProgramCourses';

  public static GROUPS_BASE = Routes.BASE_ROUTE + '/groups';
  public static GROUP_DETAILS = Routes.GROUPS_BASE + '/edit/:id';

  public static FILE_DOWNLOAD = Routes.BASE_ROUTE + 'api/files/file/:id';
  public static COURSE_SYLLABUS = Routes.BASE_ROUTE + 'api/courses/courseSyllabus/:institutionCourseDetailId';
  public static PROGRAM_DETAILED_COURSE_LISTING = Routes.BASE_ROUTE + 'api/program/programDetailedCourseListing/:programDetailId';
  public static PROGRAM_COMPARISON_REPORT = Routes.BASE_ROUTE + 'api/ProgramModificationChangeRequests/programComparisonReport/:changeRequestId';
  public static SIDE_BY_SIDE_REPORT = Routes.BASE_ROUTE + 'api/ProgramModificationChangeRequests/programSideBySideComparisonReport/:changeRequestId';
  public static DRAFT_AS28_REPORT = Routes.BASE_ROUTE + 'api/NewProgramChangeRequests/draftAS28Report/:changeRequestId';
  public static PREVIOUS_PROGRAM_CURRICULUM_REPORT = Routes.BASE_ROUTE + 'api/ProgramModificationChangeRequests/previousProgramCurriculumReport/:programDetailId';
  public static COMPLIANCE_REPORT = Routes.BASE_ROUTE + 'api/program/complianceReport';
  public static NOTICE_OF_INTENT_REPORT = Routes.BASE_ROUTE + 'api/downloads/noiReport';
  public static NOTICE_OF_INTENT_ICCPHSE_REPORT = Routes.BASE_ROUTE + 'api/downloads/noiICCPHSEReport';
  public static PROGRAM_REPORT = Routes.BASE_ROUTE + 'api/downloads/programReport';
  public static NEW_PROGRAM_PROPOSAL = Routes.BASE_ROUTE + 'api/downloads/newProgramProposal';
  public static LABOR_MARKET_INFO_REPORT = Routes.BASE_ROUTE + 'api/downloads/laborMarketInformationReport';
  public static COMMON_COURSE_NUMBERING_REPORT = Routes.BASE_ROUTE + 'api/courses/ccn';
  public static USER_CIP_NUMBER_ASSIGNMENT = Routes.BASE_ROUTE + 'api/users/consultantCipNumberAssignment';
  public static INSTITUTION_COURSE_BLOCK_REPORT = Routes.BASE_ROUTE + 'api/institutionCourseBlocks/courseBlockReport';
  public static COURSE_ATTACHED_REPORT = Routes.BASE_ROUTE + 'api/courses/courseAttachedReport';
  public static CHANGE_REQUEST_APPROVAL_FLOW_REPORT = Routes.BASE_ROUTE + 'api/changeRequestsApprovalWorkFlow/changeRequestApprovalWorkFlowReport';
  public static PROGRAM_MASTER_REPORT = Routes.BASE_ROUTE + 'api/downloads/programMasterReport';
  public static EXPORT_EPR = Routes.BASE_ROUTE + 'api/downloads/exportEPR';
  public static BULK_COURSE_TEMPLATE = Routes.BASE_ROUTE + 'api/changeRequests/coursesTemplate';
  public static SELF_STUDY_RANKING_REPORT = Routes.BASE_ROUTE + 'api/selfStudies/selfStudyRankingExport';
  public static PROGRAM_CONSULTANT_COMMENTS = Routes.BASE_ROUTE + 'api/selfStudies/programConsultantCommentsExport';
  public static SELF_STUDY_ACTION_PLAN_REPORT = Routes.BASE_ROUTE + 'api/selfStudies/actionPlanReportExport';
  public static SELF_STUDY_AGGREGATE_REPORT = Routes.BASE_ROUTE + 'api/selfStudies/selfStudyAggregateExport';
  public static SELF_STUDY_PROGRAM_MASTER_REPORT = Routes.BASE_ROUTE + 'api/selfStudies/programMasterReportExport';

  /**
   * Generated a url from a route and parameters.
   * @param route Route that may contain parameter placeholders.
   * @param params Object where property names equal the parameter placeholders in the route an the property value is what will be injected.
   */
  public static generate(
    route: string,
    params?: { [paramName: string]: string | number | boolean },
    query?: { [name: string]: any }
  ) {
    let path = generatePath(route, params);

    // Add any query string variables to the route if passed
    if (query) {
      const q = queryString.stringify(query);
      if (q) {
        path += `?${q}`;
      }
    }

    return path;
  }

  public static generateFull(
    route: string,
    params?: { [paramName: string]: string | number | boolean },
    query?: { [name: string]: any }
  ) {
    const slash = route.charAt(0) == '/' ? '' : '/';
    return location.protocol + '//' + location.host + slash + this.generate(route, params, query);
  }

  public static generateDownload(azureBlobFileId: string) {
    return this.generateFull(Routes.FILE_DOWNLOAD, { id: azureBlobFileId }, {});
  }

  public static isMatch(pathname: string, route: string): boolean {
    const match = matchPath(route, {
      path: pathname,
      exact: true,
      strict: false
    });

    return match != null && match != undefined;
  }
}

export default Routes;
