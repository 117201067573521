import { Card, Col, Result, Row, Skeleton, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import AcademicYearDataReviewSteps from '../displays/secondary/AcademicYearDataReviewSteps';
import DataReviewHeaderPortal from '../../portals/DataReviewHeaderPortal';
import AcademicYearApiService from '../../api/AcademicYearApiService';
import * as IsValidAcademicYearHandler from '../../handlerModels/IsValidAcademicYearHandler';
import { useEffect, useState } from 'react';

interface DataReviewLayoutProps {
  stepNumber: number;
  academicYear: number;
  breadcrumbs: Route[]
}

function DataReviewLayout(props: React.PropsWithChildren<DataReviewLayoutProps>) {
  const [isValidAcademicYear, setIsValidAcademicYear] = useState(null as boolean | null);

  if (props.academicYear && props.academicYear != 0) {
    useEffect(() => {
      AcademicYearApiService.isValidAcademicYear(props.academicYear)
        .then((result: IsValidAcademicYearHandler.Result) => {
          setIsValidAcademicYear(result.isValid);
        });
    }, []);
  }

  const stepWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 4,
    xxl: 4,
  }

  const contentWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 20,
    xxl: 20,
  }

  const formWidth = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24
  }

  let steps = <AcademicYearDataReviewSteps currentStep={props.stepNumber} academicYear={props.academicYear} />;

  const size = useBreakpoint();

  if (size.xxl || size.xl) {
    steps = (
      <Col >
        <Card title='Steps'>
          {steps}
        </Card>
      </Col>);
  }
  else {
    steps = (
      <Card>
        <Space direction='horizontal' split='|' align='center'>
          {steps}
        </Space>
      </Card >
    );
  }

  const renderNotValid = () => {
    return (
      <Result
        status="error"
        title="Program Review Year Not Valid"
        subTitle="The requested program review year has not been opened."
      />
    );
  }

  return (
    <Content >
      <DataReviewHeaderPortal pageTitle='Data Review' breadcrumbs={props.breadcrumbs} />

      <Row gutter={[16, 16]}>
        <Col {...stepWidth} >
          {steps}
        </Col>

        <Col {...contentWidth} >
          <Row gutter={[16, 16]}>
            <Col {...formWidth}>
              {isValidAcademicYear ? props.children : isValidAcademicYear == false ? renderNotValid() : <Skeleton />}
            </Col >
          </Row>
        </Col>
      </Row>
    </Content >
  );
}

export default DataReviewLayout;