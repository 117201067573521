import { Alert, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetRegionPlanningPartnerDetailsHandler from '../../../handlerModels/GetRegionPlanningPartnerDetailsHandler';
import * as SaveRegionalPlanningPartnerDetailsHandler from '../../../handlerModels/SaveRegionalPlanningPartnerDetailsHandler';
import InstitutionDTO from '../../../models/InstitutionDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import Dropdown from '../../inputs/Dropdown';
import ReadableTextBox from '../../inputs/ReadableTextBox';

interface RegionalPlanningPartnerDetailsFormState extends BaseFormState {
  rpp: RegionalPlanningPartnerDTO;
  institutions: InstitutionDTO[];
}

interface RegionalPlanningPartnerDetailsFormProps extends BaseFormProps {
  regionId: string;
  onSave?: (regionId: string) => void;
  academicYear: number;
  fromAcademicYear?: boolean;
}

class RegionalPlanningPartnerDetailsForm extends React.Component<RegionalPlanningPartnerDetailsFormProps, RegionalPlanningPartnerDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(RegionalPlanningPartnerDTO.regionalPlanningPartnerIdentifier, {
      name: RegionalPlanningPartnerDTO.regionalPlanningPartnerIdentifier,
      label: 'Identifier',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    })
    .set(RegionalPlanningPartnerDTO.name, {
      required: true,
      name: RegionalPlanningPartnerDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(30)
      ],
    })
    .set(RegionalPlanningPartnerDTO.institutionId, {
      required: true,
      name: RegionalPlanningPartnerDTO.institutionId,
      label: 'Community College',
      rules: [
        ValidationRuleUtil.required()
      ],
    });

  constructor(props: RegionalPlanningPartnerDetailsFormProps) {
    super(props);

    this.state = {
      rpp: RegionalPlanningPartnerDTO.create(),
      institutions: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: RegionalPlanningPartnerDetailsFormProps) {
    if (this.props.regionId && this.props.regionId != prevProps.regionId) {
      this.fetchData();
    }
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      () => this.handleSubmit(),
      () => this.setState({ error: true })
    );
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];
    loaders.push(this.loadRegion());

    if (!this.state.institutions || this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadRegion() {
    if (!this.state.loading) {
      if (this.props.regionId == Guid.Empty()) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.regionId) {
      const request = GetRegionPlanningPartnerDetailsHandler.Request.create({
        id: this.props.regionId,
      });

      RegionalPlanningPartnerApiService.getRegionDetails(request)
        .then((results: GetRegionPlanningPartnerDetailsHandler.Result) => {
          if (results) {
            this.setState({
              rpp: results.regionPlanningPartner ?? RegionalPlanningPartnerDTO.create()
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          const insts = results.sort((a: InstitutionDTO, b: InstitutionDTO) => a.code - b.code);
          this.setState({ institutions: insts ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadNew = () => {
    this.setState({ loading: false, rpp: RegionalPlanningPartnerDTO.create({ institutionId: '', regionalPlanningPartnerIdentifier: '' }) });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveRegionalPlanningPartnerDetailsHandler.Request.create({
      regionalPlanningPartnerId: this.props.regionId,
      academicYear: this.props.academicYear,
      regionalPlanningPartner: RegionalPlanningPartnerDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    RegionalPlanningPartnerApiService.saveRegionalPlanningPartnerDetails(request)
      .then((result: SaveRegionalPlanningPartnerDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({
            rpp: result.regionalPlanningPartner ?? RegionalPlanningPartnerDTO.create(),
            fieldErrors: null,
            error: false
          });

          message.success('Saved');

          if (this.props.onSave && result.regionalPlanningPartner?.id) {
            this.props.onSave(result.regionalPlanningPartner.id);
            this.resetForm();
          }

        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error(result?.errors.join('\n'));
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Region could not be saved');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.rpp}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(RegionalPlanningPartnerDTO.regionalPlanningPartnerIdentifier)}
            {...ValidationUtil.getValidation(RegionalPlanningPartnerDTO.regionalPlanningPartnerIdentifier, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(RegionalPlanningPartnerDTO.name)}
            {...ValidationUtil.getValidation(RegionalPlanningPartnerDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(RegionalPlanningPartnerDTO.institutionId)}
            {...ValidationUtil.getValidation(RegionalPlanningPartnerDTO.institutionId, this.state.fieldErrors, this.state.submitted)}>
            <Dropdown disabled={!this.props.isEditing || this.state.loading || this.state.submitting}> {this.state.institutions.map(x => this.renderInstitution(x))}</Dropdown>
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderInstitution(institution: InstitutionDTO) {
    if (institution.id) {
      return <Select.Option title={institution.name ?? ''} key={institution.id ?? Guid.Empty()} value={institution.id ?? Guid.Empty()}>{institution.code} - {institution.name}</Select.Option>
    }
  }

  renderSave() {
    if (this.props.isEditing && !this.props.fromAcademicYear) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!this.state.altered} saving={this.state.submitting} />
            <ResetButton disabled={!this.state.altered} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default RegionalPlanningPartnerDetailsForm;
