import { Descriptions, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/Routes';
import BaseFormProps from '../../../redux/bases/BaseFormProps';

interface AcademicYearConfigurationFormProps extends BaseFormProps {
  academicYear: number;
  onSubmit?: () => void;
}

const AcademicYearConfigurationForm = React.forwardRef((props: AcademicYearConfigurationFormProps) => {
  const _formRef = React.createRef<FormInstance>();

  const [loading,] = useState(false);


  if (loading) {
    return <Skeleton active />
  }
  else {

    return (
      <Form ref={_formRef} layout="vertical" requiredMark={true}>
        <Space size="small" direction="vertical">
          <Descriptions title="Data" column={4}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_AREA_EDUCATION_AGENCIES_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Area Education Agencies</Link>
            </Descriptions.Item>

            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_FEDERAL_CLUSTERS_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Federal Clusters</Link>
            </Descriptions.Item>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_REGIONAL_PLANNING_PARTNER_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Regional Planning Partner</Link>
            </Descriptions.Item>

            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_SCENARIOS_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Scenarios</Link>
            </Descriptions.Item>

            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_SERVICE_AREAS_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Service Areas</Link>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions title="Imports" column={4}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_SECONDARY_CIP_NUMBER_CONFIGURATION, { academicYearId: props.academicYear }, {})} >CIP Numbers</Link>
            </Descriptions.Item>

            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_SECONDARY_COURSES_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Courses</Link>
            </Descriptions.Item>

            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_DISTRICT_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Districts</Link>
            </Descriptions.Item>

            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_DISTRICT_CONTACT_CONFIGURATION, { academicYearId: props.academicYear }, {})} >District Contacts</Link>
            </Descriptions.Item>

            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_SECONDARY_PROGRAM_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Programs</Link>
            </Descriptions.Item>

            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_SECONDARY_PROGRAM_COURSES_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Program Courses</Link>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions title="Program Review Year" column={4}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ACADEMIC_YEAR_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Dates Configuration</Link>
            </Descriptions.Item>

            <Descriptions.Item>
              <Link to={Routes.generate(Routes.SELF_STUDIES_CONFIGURATION, { academicYearId: props.academicYear }, {})} >Self Study Configuration</Link>
            </Descriptions.Item>
          </Descriptions>
        </Space >
      </Form >
    );
  }

})

AcademicYearConfigurationForm.displayName = 'AcademicYearConfigurationForm';

export default AcademicYearConfigurationForm;