// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import YearTermDTO from './YearTermDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramAdvisoryBoardDataTableDTO extends EntitySelectorDTO { 
	id: string | null;
	programId: string | null;
	firstName: string | null;
	lastName: string | null;
	genderId: number;
	employer: string | null;
	position: string | null;
	disability: boolean;
	organizedLabor: boolean;
	ethnicGroupId: number;
	joinedTermId: string | null;
	joinedYear: number;
	retireTermId: string | null | null;
	retireYear: number | null | null;
	name: string | null;
	joinTerm: YearTermDTO | null;
	retireTerm: YearTermDTO | null;
}

const ProgramAdvisoryBoardDataTableDTO = {
    className: 'ProgramAdvisoryBoardDataTableDTO',
    create: (initValues?: Partial<ProgramAdvisoryBoardDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			programId: '00000000-0000-0000-0000-000000000000',
			firstName: "",
			lastName: "",
			genderId: 0,
			employer: "",
			position: "",
			disability: false,
			organizedLabor: false,
			ethnicGroupId: 0,
			joinedTermId: '00000000-0000-0000-0000-000000000000',
			joinedYear: 0,
			retireTermId: null,
			retireYear: null,
			name: "",
			joinTerm: null,
			retireTerm: null,
        },
        initValues);
    },
	id: 'id',
	programId: 'programId',
	firstName: 'firstName',
	lastName: 'lastName',
	genderId: 'genderId',
	employer: 'employer',
	position: 'position',
	disability: 'disability',
	organizedLabor: 'organizedLabor',
	ethnicGroupId: 'ethnicGroupId',
	joinedTermId: 'joinedTermId',
	joinedYear: 'joinedYear',
	retireTermId: 'retireTermId',
	retireYear: 'retireYear',
	name: 'name',
	joinTerm: 'joinTerm',
	retireTerm: 'retireTerm',
};

export default ProgramAdvisoryBoardDataTableDTO;