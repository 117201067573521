import { Card, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DistrictApiService from '../../../api/DistrictApiService';
import DistrictsDataTable from '../../../components/datatables/secondary/DistrictsDataTable';
import DistrictsImporter from '../../../components/secondary/DistrictsImporter';
import Routes from '../../../config/Routes';
import * as AcademicYearHasDistrictsHandler from '../../../handlerModels/AcademicYearHasDistrictsHandler';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';
import ConfirmAndContinueButton from '../../../components/buttons/ConfirmAndContinueButton';
import Breadcrumbs from '../../../config/Breadcrumbs';
import PreviousButton from '../../../components/buttons/PreviousButton';
import DataReviewLayout from '../../../components/secondary/DataReviewLayout';

interface DistrictListPageState {
  academicYear: number;
  hasDistricts: boolean | undefined;
  errors?: string[];
}

interface DistrictListPageProps {
}

class DataReviewDistrictListPage extends React.Component<DistrictListPageProps & RouteComponentProps<RouteObject>, DistrictListPageState> {

  constructor(props: DistrictListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0,
      hasDistricts: undefined
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });
      this.loadDistricts(id);
    }
  }

  private loadDistricts = (academicYear: number, force?: boolean) => {
    if (force || (this.state.hasDistricts == undefined && academicYear > 0)) {
      DistrictApiService.academicYearHasDistricts(academicYear)
        .then((results: AcademicYearHasDistrictsHandler.Result) => {
          if (results) {
            this.setState({
              hasDistricts: results.hasDistricts
            });
          }
        });
    }
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SECONDARY_CIP_NUMBERS, { academicYearId: this.state.academicYear }, {}));
  }

  private onContinue = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_DISTRICT_CONTACTS, { academicYearId: this.state.academicYear }, {}));
  }

  render() {
    const academicYear = this.state.academicYear;

    if (academicYear != 0) {
      return (
        <Content >
          <DataReviewLayout breadcrumbs={Breadcrumbs.academicYearDataReview(academicYear)} stepNumber={6} academicYear={academicYear}>
            <Card title="Districts">
              <Space direction='vertical' size="middle">
                {this.state.hasDistricts ?
                  <DistrictsDataTable fromAcademicYearDataReview={true} academicYear={this.state.academicYear} /> :
                  <DistrictsImporter onSubmit={() => this.loadDistricts(this.state.academicYear, true)} academicYear={this.state.academicYear} />
                }
                <Space>
                  <PreviousButton onClick={this.onBack} />
                  {this.state.hasDistricts ? <ConfirmAndContinueButton onClick={this.onContinue} /> : null}
                </Space>
              </Space>
            </Card>
          </DataReviewLayout>
        </Content>
      );
    }

    return <Skeleton />;
  }
}

export default withRouter((DataReviewDistrictListPage));
