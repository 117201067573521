// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import NoticeOfIntentChangeRequestWorkForceLinkDTO from '../models/NoticeOfIntentChangeRequestWorkForceLinkDTO';
import * as SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler from '../handlerModels/SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler';
import * as RemoveNoticeOfIntentChangeRequestWorkForceLinkHandler from '../handlerModels/RemoveNoticeOfIntentChangeRequestWorkForceLinkHandler';
import * as GetNoticeOfIntentChangeRequestWorkForceLinksHandler from '../handlerModels/GetNoticeOfIntentChangeRequestWorkForceLinksHandler';

export class NoticeOfIntentChangeRequestWorkForceLinkApiService extends BaseApi {

    // post: api/noticeOfIntentChangeRequestWorkForceLink/getNoticeOfIntentChangeRequestWorkForceLinkTableData
    public getNoticeOfIntentChangeRequestWorkForceLinkTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<NoticeOfIntentChangeRequestWorkForceLinkDTO>> {
        let url = `api/noticeOfIntentChangeRequestWorkForceLink/getNoticeOfIntentChangeRequestWorkForceLinkTableData`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<NoticeOfIntentChangeRequestWorkForceLinkDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequestWorkForceLink/saveNoticeOfIntentChangeRequestWorkForceLink
    public saveNoticeOfIntentChangeRequestWorkForceLink(saveNoticeOfIntentChangeRequestWorkForceLinkDetail: SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request): Promise<SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Result> {
        let url = `api/noticeOfIntentChangeRequestWorkForceLink/saveNoticeOfIntentChangeRequestWorkForceLink`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Result>(saveNoticeOfIntentChangeRequestWorkForceLinkDetail, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequestWorkForceLink/removeNoticeOfIntentChangeRequestWorkForceLink
    public removeNoticeOfIntentChangeRequestWorkForceLink(removeNoticeOfIntentChangeRequestWorkForceLink: RemoveNoticeOfIntentChangeRequestWorkForceLinkHandler.Request): Promise<RemoveNoticeOfIntentChangeRequestWorkForceLinkHandler.Result> {
        let url = `api/noticeOfIntentChangeRequestWorkForceLink/removeNoticeOfIntentChangeRequestWorkForceLink`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveNoticeOfIntentChangeRequestWorkForceLinkHandler.Result>(removeNoticeOfIntentChangeRequestWorkForceLink, 'post', url, true, false);
    }

    // post: api/noticeOfIntentChangeRequestWorkForceLink/getNoticeOfIntentChangeRequestWorkForceLinks
    public getNoticeOfIntentChangeRequestWorkForceLinks(getNoticeOfIntentChangeRequestWorkForceLinks: GetNoticeOfIntentChangeRequestWorkForceLinksHandler.Request): Promise<GetNoticeOfIntentChangeRequestWorkForceLinksHandler.Result> {
        let url = `api/noticeOfIntentChangeRequestWorkForceLink/getNoticeOfIntentChangeRequestWorkForceLinks`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNoticeOfIntentChangeRequestWorkForceLinksHandler.Result>(getNoticeOfIntentChangeRequestWorkForceLinks, 'post', url, true, false);
    }
}
var service = new NoticeOfIntentChangeRequestWorkForceLinkApiService();
export default service;
