import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Input, message, Row, Select, Skeleton, Space, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionLocationApiService from '../../../api/InstitutionLocationApiService';
import NoticeOfIntentChangeRequestApiService from '../../../api/NoticeOfIntentChangeRequestApiService';
import { ColumnWidths } from '../../../config/ColumnWidths';
import * as GetInstitutionLocationsHandler from '../../../handlerModels/GetInstitutionLocationsHandler';
import * as SaveNoticeOfIntentChangeRequestStep2Handler from '../../../handlerModels/SaveNoticeOfIntentChangeRequestStep2Handler';
import * as SubmitNoticeOfIntentChangeRequestStep2Handler from '../../../handlerModels/SubmitNoticeOfIntentChangeRequestStep2Handler';
import * as GetNoticeOfIntentChangeRequestStep2Handler from '../../../handlerModels/GetNoticeOfIntentChangeRequestStep2Handler';
import AwardTypeDTO from '../../../models/AwardTypeDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import InstitutionLocationDTO from '../../../models/InstitutionLocationDTO';
import InstructionalLevelDTO from '../../../models/InstructionalLevelDTO';
import ModeOfDeliveryDTO from '../../../models/ModeOfDeliveryDTO';
import NoticeOfIntentChangeRequestDTO from '../../../models/NoticeOfIntentChangeRequestDTO';
import NoticeOfIntentChangeRequestPlannedAwardTypeDTO from '../../../models/NoticeOfIntentChangeRequestPlannedAwardTypeDTO';
import ObjectPurposeDTO from '../../../models/ObjectPurposeDTO';
import SpecialEmphasisDTO from '../../../models/SpecialEmphasisDTO';
import TypeOfProgramDTO from '../../../models/TypeOfProgramDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import TermInput from '../../TermInput';
import Dropdown from '../../inputs/Dropdown';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NoticeOfIntentChangeRequestStep2FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO | null;
  noticeOfIntent: NoticeOfIntentChangeRequestDTO;
  modeOfDelivery: ModeOfDeliveryDTO[];
  institutionLocations: InstitutionLocationDTO[];
  awardTypes: AwardTypeDTO[];
  instructionalLevels: InstructionalLevelDTO[];
  typeOfPrograms: TypeOfProgramDTO[];
  specialEmphasis: SpecialEmphasisDTO[];
  objectPurposes: ObjectPurposeDTO[];
}

interface NoticeOfIntentChangeRequestStep2FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  institutionId?: string | null;
  onPrevious?: () => void;
  onSave?: () => void;
  onSubmit?: () => void;
  readonly?: boolean;
}

class NoticeOfIntentChangeRequestStep2Form extends React.Component<NoticeOfIntentChangeRequestStep2FormProps, NoticeOfIntentChangeRequestStep2FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getPlannedAwardFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.title, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.title],
        label: 'Title',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.awardTypeId, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.awardTypeId],
        label: 'Award Type',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.description, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.description],
        label: 'Description',
        rules: [ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(5000)]
      })
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.install, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.install],
        label: 'What term will this program first be offered?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.instructionalLevelId, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.instructionalLevelId],
        label: 'Instructional Level',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.typeOfProgramId, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.typeOfProgramId],
        label: 'Type Of Program',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.specialEmphasisId, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.specialEmphasisId],
        label: 'Special Emphasis',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.objectPurposeId, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.objectPurposeId],
        label: 'Object Purpose',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.noticeOfIntentChangeRequestPlannedAwardInstitutionLocationIds, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.noticeOfIntentChangeRequestPlannedAwardInstitutionLocationIds],
        label: 'At which location(s) will this program be offered?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.noticeOfIntentChangeRequestPlannedAwardModeOfDeliverIds, {
        required: true,
        name: [index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.noticeOfIntentChangeRequestPlannedAwardModeOfDeliverIds],
        label: 'How will this award be offered?',
        rules: [ValidationRuleUtil.required()]
      });
  }

  constructor(props: NoticeOfIntentChangeRequestStep2FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      noticeOfIntent: NoticeOfIntentChangeRequestDTO.create({
        installTermId: '',
        installYear: DateTimeUtil.getCurrentYear(),
        cipReclassification: null,
        cipNumberId: null,
        plannedAwardTypes: [NoticeOfIntentChangeRequestPlannedAwardTypeDTO.create()]
      }),

      awardTypes: [],
      instructionalLevels: [],
      typeOfPrograms: [],
      specialEmphasis: [],
      objectPurposes: [],
      modeOfDelivery: [],
      institutionLocations: []
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: NoticeOfIntentChangeRequestStep2FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });

    this._formRef.current?.resetFields();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.awardTypes.length == 0) {
      loaders.push(this.loadAwardTypes());
    }

    if (!this.state.instructionalLevels || this.state.instructionalLevels.length == 0) {
      loaders.push(this.loadInstructionalLevel());
    }

    if (!this.state.typeOfPrograms || this.state.typeOfPrograms.length == 0) {
      loaders.push(this.loadTypeOfProgram());
    }

    if (!this.state.specialEmphasis || this.state.specialEmphasis.length == 0) {
      loaders.push(this.loadSpecialEmphasis());
    }

    if (!this.state.objectPurposes || this.state.objectPurposes.length == 0) {
      loaders.push(this.loadObjectPurpose());
    }

    if (!this.state.institutionLocations || this.state.institutionLocations.length == 0 && this.props.institutionId && this.props.institutionId != Guid.Empty()) {
      loaders.push(this.loadInstitutionLocation());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest());
    }

    if (!this.state.modeOfDelivery || this.state.modeOfDelivery.length == 0) {
      loaders.push(this.loadModeOfDelivery());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadAwardTypes = () => {
    return LookupsUtil.getAll<AwardTypeDTO>(AwardTypeDTO.className)
      .then((results: AwardTypeDTO[]) => {
        if (results) {
          this.setState({ awardTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadInstructionalLevel = () => {
    return LookupsUtil.getAll<InstructionalLevelDTO>(InstructionalLevelDTO.className)
      .then((results: InstructionalLevelDTO[]) => {
        if (results) {
          this.setState({ instructionalLevels: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTypeOfProgram = () => {
    return LookupsUtil.getAll<TypeOfProgramDTO>(TypeOfProgramDTO.className)
      .then((results: TypeOfProgramDTO[]) => {
        if (results) {
          this.setState({ typeOfPrograms: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadSpecialEmphasis = () => {
    return LookupsUtil.getAll<SpecialEmphasisDTO>(SpecialEmphasisDTO.className)
      .then((results: SpecialEmphasisDTO[]) => {
        if (results) {
          this.setState({ specialEmphasis: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadObjectPurpose = () => {
    return LookupsUtil.getAll<ObjectPurposeDTO>(ObjectPurposeDTO.className)
      .then((results: ObjectPurposeDTO[]) => {
        if (results) {
          this.setState({ objectPurposes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  };

  private loadInstitutionLocation = () => {
    InstitutionLocationApiService.getInstitutionLocations(this.props.institutionId ?? Guid.Empty())
      .then((result: GetInstitutionLocationsHandler.Result) => {
        if (result?.succeeded) {
          this.setState({ institutionLocations: result.institutionLocations ?? [] })
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
  }


  private loadModeOfDelivery = () => {
    return LookupsUtil.getAll<ModeOfDeliveryDTO>(ModeOfDeliveryDTO.className)
      .then((results: ModeOfDeliveryDTO[]) => {
        if (results) {
          this.setState({ modeOfDelivery: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  };

  private loadChangeRequest = () => {
    const request = GetNoticeOfIntentChangeRequestStep2Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NoticeOfIntentChangeRequestApiService.getStep2(request)
      .then((results: GetNoticeOfIntentChangeRequestStep2Handler.Result) => {
        if (results) {
          const noi = results.changeRequest?.noticeOfIntentChangeRequest ?? NoticeOfIntentChangeRequestDTO.create();

          // Add initial Award
          if (noi.plannedAwardTypes?.length == 0) {
            noi.plannedAwardTypes.push(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.create());
          }

          if (noi.plannedAwardTypes) {
            noi.plannedAwardTypes[0].awardTypeId = noi.primrayAwardTypeId;
          }

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            noticeOfIntent: noi ?? NoticeOfIntentChangeRequestDTO.create(),
            fieldErrors: this.props.fieldErrors,
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public getAward = () => {
    const model = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.plannedAwardTypes);
    return model;
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const model = this._formRef.current?.getFieldsValue() ?? NoticeOfIntentChangeRequestDTO.create();
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();
    model.id = this.state.changeRequest?.noticeOfIntentChangeRequest?.id ?? Guid.Empty();

    const request = SaveNoticeOfIntentChangeRequestStep2Handler.Request.create({
      noticeOfIntentChangeRequest: model,
    });

    NoticeOfIntentChangeRequestApiService.saveStep2(request)
      .then((result: SaveNoticeOfIntentChangeRequestStep2Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.changeRequest?.id) {
            this.props.onSave();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  };

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = NoticeOfIntentChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null) as NoticeOfIntentChangeRequestDTO;
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();
    model.id = this.state.changeRequest?.noticeOfIntentChangeRequest?.id ?? Guid.Empty();

    const request = SubmitNoticeOfIntentChangeRequestStep2Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      noticeOfIntentChangeRequest: model,
    });

    NoticeOfIntentChangeRequestApiService.submitStep2(request)
      .then((result: SubmitNoticeOfIntentChangeRequestStep2Handler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleAwardTypeChange = (index: number) => {
    const awardType = this.state.awardTypes.find(x => x.id == this._formRef.current?.getFieldValue([NoticeOfIntentChangeRequestDTO.plannedAwardTypes, index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.awardTypeId])) ?? AwardTypeDTO.create();
    const award = this._formRef.current?.getFieldsValue();
    if (!awardType.instructionalLevels.includes(this._formRef.current?.getFieldValue([NoticeOfIntentChangeRequestDTO.plannedAwardTypes, index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.instructionalLevelId]) ?? 0)) {
      award.plannedAwardTypes[index].instructionalLevelId = undefined;
      this._formRef.current?.setFieldsValue({ plannedAwardTypes: award.plannedAwardTypes })
    }

    if (!awardType.typeOfPrograms.includes(this._formRef.current?.getFieldValue([NoticeOfIntentChangeRequestDTO.plannedAwardTypes, index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.typeOfProgramId]) ?? 0)) {
      award.plannedAwardTypes[index].typeOfProgramId = undefined;
      this._formRef.current?.setFieldsValue({ plannedAwardTypes: award.plannedAwardTypes })
    }

    if (!awardType.specialEmphases.includes(this._formRef.current?.getFieldValue([NoticeOfIntentChangeRequestDTO.plannedAwardTypes, index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.specialEmphasisId]) ?? 0)) {
      award.plannedAwardTypes[index].specialEmphasisId = undefined;
      this._formRef.current?.setFieldsValue({ plannedAwardTypes: award.plannedAwardTypes })
    }

    if (!awardType.objectPurposes.includes(this._formRef.current?.getFieldValue([NoticeOfIntentChangeRequestDTO.plannedAwardTypes, index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.objectPurposeId]) ?? 0)) {
      award.plannedAwardTypes[index].objectPurposeId = undefined;
      this._formRef.current?.setFieldsValue({ plannedAwardTypes: award.plannedAwardTypes })
    }
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.noticeOfIntent}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <Space direction="vertical">

            <Typography.Paragraph italic={true}>
              If you do not know any of the following information, please contact your IDOE consultant.
            </Typography.Paragraph>

            {this.renderPlannedAwards()}

            {this.renderActions()}
          </Space>
        </Form>
      </Space >
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <GoBackButton onClick={this.props.onPrevious} />
          <SaveAndContinueButton submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  renderPlannedAwards() {
    return (
      <Form.List name={NoticeOfIntentChangeRequestDTO.plannedAwardTypes}>
        {
          (awards, { add, remove }) => {
            return (
              <Space direction='vertical'>
                {awards.map((award, index) => { if (award) { return this.renderPlannedAward(award, index, remove); } })}
                {this.renderAddAward(add)}
              </Space>);
          }
        }
      </Form.List>
    );
  }

  renderAddAward(add: any) {
    if (!this.props.readonly) {
      return (
        <Button onClick={() => add()} icon={<PlusOutlined />}>
          Add Award
        </Button>
      );
    }
  }

  renderPlannedAward(award: any, index: number, remove: any) {
    const title = index == 0 ? 'Primary Award' : 'Additional Award ' + (index);
    const deleteButton = <Button type="link" size="small" id={'' + index} onClick={() => remove(award.name)} icon={<DeleteOutlined />} />;

    const formPrimaryAwardTypeId = this._formRef.current?.getFieldValue([NoticeOfIntentChangeRequestDTO.plannedAwardTypes, index, NoticeOfIntentChangeRequestPlannedAwardTypeDTO.awardTypeId]);
    const primarayAwardId = formPrimaryAwardTypeId ? formPrimaryAwardTypeId : this.state.noticeOfIntent.primrayAwardTypeId;

    const formItems = this.getPlannedAwardFormItems(index);
    const responsiveQuarter = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
      xl: 12,
      xxl: 6
    }

    return (
      <Card title={title} size='small' type="inner" extra={index == 0 || this.props.readonly ? null : deleteButton}>
        <Row gutter={[16, 16]}>
          <Col {...ColumnWidths.HALF}>
            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.awardTypeId)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.awardTypeId + NoticeOfIntentChangeRequestPlannedAwardTypeDTO.className + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
              <Dropdown dropdownMatchSelectWidth={false}
                disabled={this.props.readonly || index == 0}
                showArrow={!this.props.readonly && index > 0}
                onChange={() => this.handleAwardTypeChange(index)}>
                {this.state.awardTypes.map(x => { return this.renderAwardTypeOption(x) })}
              </Dropdown>
            </FormItem>
          </ Col>

          <Col {...ColumnWidths.HALF}>
            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.title)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.title + NoticeOfIntentChangeRequestPlannedAwardTypeDTO.className + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
              <Input disabled={this.props.readonly} />
            </FormItem>
          </ Col>
        </ Row>

        <Row gutter={[16, 16]}>
          <Col {...responsiveQuarter}>
            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.instructionalLevelId)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.instructionalLevelId + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
              <Dropdown dropdownMatchSelectWidth={false}>
                {this.state.instructionalLevels.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.instructionalLevels.includes(x.id)).map(x => { return this.renderInstructionalLevelOption(x) })}
              </Dropdown>
            </FormItem>
          </Col>

          <Col {...responsiveQuarter}>
            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.typeOfProgramId)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.typeOfProgramId + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
              <Dropdown dropdownMatchSelectWidth={false}>
                {this.state.typeOfPrograms.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.typeOfPrograms.includes(x.id)).map(x => { return this.renderTypeOfProgramOption(x) })}
              </Dropdown>
            </FormItem>
          </Col>

          <Col {...responsiveQuarter}>
            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.specialEmphasisId)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.specialEmphasisId + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
              <Dropdown dropdownMatchSelectWidth={false}>
                {this.state.specialEmphasis.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.specialEmphases.includes(x.id)).map(x => { return this.renderSpecialEmphasis(x) })}
              </Dropdown>
            </FormItem>
          </Col>

          <Col {...responsiveQuarter}>
            <FormItem
              {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.objectPurposeId)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.objectPurposeId + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
              <Dropdown dropdownMatchSelectWidth={false}>
                {this.state.objectPurposes.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.objectPurposes.includes(x.id)).map(x => { return this.renderObjectPurpose(x) })}
              </Dropdown>
            </FormItem>
          </Col>
        </Row>

        <FormItem
          {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.description)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.description + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
          <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
        </FormItem>

        <FormItem
          {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.install)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.install + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
          <TermInput futureYears={true} disabled={this.props.readonly} minYear={DateTimeUtil.getCurrentYear()} />
        </FormItem>


        <FormItem
          {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.noticeOfIntentChangeRequestPlannedAwardModeOfDeliverIds)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.noticeOfIntentChangeRequestPlannedAwardModeOfDeliverIds + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
          <Dropdown dropdownMatchSelectWidth={false}
            mode="multiple">
            {this.state.modeOfDelivery.map(x => { return this.renderModeOfDelivery(x) })}
          </Dropdown>
        </FormItem>

        {this.renderLocation(formItems, index)}
      </Card >
    );
  }

  renderLocation(formItems: Map<string, FormItemProps<any>>, index: number) {
    if (this.state.institutionLocations.length > 1) {
      return <FormItem
        {...formItems.get(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.noticeOfIntentChangeRequestPlannedAwardInstitutionLocationIds)}
        {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestPlannedAwardTypeDTO.noticeOfIntentChangeRequestPlannedAwardInstitutionLocationIds + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
        <Dropdown dropdownMatchSelectWidth={false}
          mode="multiple">
          {this.state.institutionLocations.map(x => { return this.renderInstitutionLocations(x) })}
        </Dropdown>
      </FormItem>
    }
  }

  renderObjectPurpose(objectPurpose: ObjectPurposeDTO) {
    return <Select.Option key={objectPurpose.id} value={objectPurpose.id}>{objectPurpose.code} - {objectPurpose.name}</Select.Option>;
  }

  renderSpecialEmphasis(specialEmphasis: SpecialEmphasisDTO) {
    return <Select.Option key={specialEmphasis.id} value={specialEmphasis.id}>{specialEmphasis.code} - {specialEmphasis.name}</Select.Option>;
  }

  renderTypeOfProgramOption(typeOfProgram: TypeOfProgramDTO) {
    return <Select.Option key={typeOfProgram.id} value={typeOfProgram.id}>{typeOfProgram.code} - {typeOfProgram.name}</Select.Option>;
  }

  renderInstructionalLevelOption(instructionalLevel: InstructionalLevelDTO) {
    return <Select.Option key={instructionalLevel.id} value={instructionalLevel.id}>{instructionalLevel.code} - {instructionalLevel.name}</Select.Option>;
  }

  renderAwardTypeOption(awardType: AwardTypeDTO) {
    if (awardType.id) {
      return <Select.Option key={awardType.id} value={awardType.id}>{awardType.code} - {awardType.name}</Select.Option>
    }
  }

  renderInstitutionLocations(institutionLocation: InstitutionLocationDTO) {
    if (institutionLocation.id) {
      return <Select.Option key={institutionLocation.id ?? Guid.Empty()} value={institutionLocation.id ?? Guid.Empty()}>{institutionLocation.name} - {institutionLocation.street1} </Select.Option>
    }
  }

  renderModeOfDelivery(modeOfDelivery: ModeOfDeliveryDTO) {
    if (modeOfDelivery.id) {
      return <Select.Option key={modeOfDelivery.id ?? Guid.Empty()} value={modeOfDelivery.id ?? Guid.Empty()}> {modeOfDelivery.name}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error && this.state.message) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NoticeOfIntentChangeRequestStep2Form