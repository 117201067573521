import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IowaVariationApiService from '../../../../api/IowaVariationApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import IowaVariationDetailsForm from '../../../../components/forms/postSecondary/IowaVariationDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteIowaVariationHandler from '../../../../handlerModels/DeleteIowaVariationHandler';
import * as GetIowaVariationDetailsHandler from '../../../../handlerModels/GetIowaVariationDetailsHandler';
import IowaVariationDTO from '../../../../models/IowaVariationDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface IowaVariationDetailsPageState extends BaseFormState {
  iowaVariationId: number;
  iowaVariation?: IowaVariationDTO;
}

interface IowaVariationDetailsPageProps {
}

class IowaVariationDetailsPage extends React.Component<IowaVariationDetailsPageProps & RouteComponentProps<RouteObject>, IowaVariationDetailsPageState> {
  private readonly _detailsRef = React.createRef<IowaVariationDetailsForm>();

  constructor(props: IowaVariationDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      iowaVariationId: 0
    };
  }

  componentDidMount() {
    const iowaVariationId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (iowaVariationId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const iowaVariationIdNumber = Number.parseInt(iowaVariationId);
      this.loadIowaVariation(iowaVariationIdNumber);
    }
  }

  private loadIowaVariation = (iowaVariationId: number) => {
    this.setState({ iowaVariationId: iowaVariationId });

    IowaVariationApiService.getIowaVariationDetails(iowaVariationId)
      .then((results: GetIowaVariationDetailsHandler.Result) => {
        if (results.iowaVariation) {
          this.setState({ iowaVariation: results.iowaVariation });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._detailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private specialEmphasisDetailsSaved = (iowaVariationId: number) => {
    this.setState({ isEditing: false });
    if (iowaVariationId != this.state.iowaVariationId) {
      HistoryUtil.replace(Routes.generate(Routes.IOWA_VARIATION_DETAILS, { id: iowaVariationId }));
    }
    else {
      this.loadIowaVariation(iowaVariationId);
    }
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Iowa Variation?',
      okText: 'Delete',
      onOk: () => this.deleteIowaVariation(id)
    });
  }

  private deleteIowaVariation = (iowaVariationId: number) => {
    this.setState({ submitting: true });

    const request = DeleteIowaVariationHandler.Request.create({
      iowaVariationId: iowaVariationId,
    });

    IowaVariationApiService.deleteIowaVariation(request)
      .then((result: DeleteIowaVariationHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Iowa Variation was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Iowa Variation could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToIowaVariation();
      });
  }

  private goToIowaVariation = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.iowaVariationId == 0 ? 'New Iowa Variation' : this.state.iowaVariation?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToIowaVariation}
          breadcrumbs={Breadcrumbs.iowaVariationDetails(title, this.state.iowaVariationId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.iowaVariationId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_IOWA_VARIATION]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <IowaVariationDetailsForm
          ref={this._detailsRef}
          iowaVariationId={this.state.iowaVariationId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.specialEmphasisDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Iowa Variation' key={this.state.iowaVariationId} onClick={() => this.deleteConfirmation(this.state.iowaVariationId)}  >
          Delete Iowa Variation
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_IOWA_VARIATION]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(IowaVariationDetailsPage);
