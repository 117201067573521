import * as React from 'react';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { useState } from 'react';
import AreaEducationAgencyApiService from '../../../api/AreaEducationAgencyApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import AreaEducationAgencyDTO from '../../../models/AreaEducationAgencyDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import AreaEducationAgencyDetailsModal from '../../modals/AreaEducationAgencyDetailsModal';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import * as DeleteAreaEducationAgencyHandler from '../../../handlerModels/DeleteAreaEducationAgencyHandler';
import Guid from '../../../utils/Guid';

interface AreaEducationAgenciesDataTableProps {
  academicYear: number;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  fromDataReview?: boolean;
  programId?: string;
  reviewAcademicYear?: boolean;
}

function AreaEducationAgenciesDataTable(props: AreaEducationAgenciesDataTableProps) {
  let _dataTable: DataTable<AreaEducationAgencyDTO> | undefined = undefined;

  const [areaEducationAgencyId, setAreaEducationAgencyId] = useState<string | null>();

  const getColumnDefinitions = () => {
    return [
      {
        title: 'Identifier',
        dataIndex: AreaEducationAgencyDTO.areaEducationAgencyIdentifier,
        sorter: true,
        width: 50,
        render: (data: string, row: AreaEducationAgencyDTO) => {
          return row.areaEducationAgencyIdentifier;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Name',
        dataIndex: AreaEducationAgencyDTO.name,
        sorter: true,
        render: (data: string, row: AreaEducationAgencyDTO) => {
          return row.name;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Actions',
        align: 'center',
        dataIndex: AreaEducationAgencyDTO.id,
        sorter: false,
        render: (data: string, row: AreaEducationAgencyDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" title='Edit' onClick={() => openAreaEducationAgencyDetail(row)}><FormOutlined /></Button>
              <Button type="link" title='Remove' onClick={() => promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 50
      },
    ] as DataTableColumnProps<any>[];
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openAreaEducationAgencyDetail = (record: AreaEducationAgencyDTO) => {
    if (record.id) {
      setAreaEducationAgencyId(record.id);
    }
  }

  const newAreaEducationAgency = () => {
    setAreaEducationAgencyId(Guid.Empty());
  }

  const closeAreaEducationAgencyDetail = () => {
    setAreaEducationAgencyId(null);
  }

  const promptConfirmRemove = (areaEducationAgencyId: string | null) => {
    if (areaEducationAgencyId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this Area Education Agency?',
      okText: 'Delete',
      onOk: () => deleteAreaEducationAgency(areaEducationAgencyId),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteAreaEducationAgency = (areaEducationAgencyId: string) => {
    const request = DeleteAreaEducationAgencyHandler.Request.create({
      areaEducationAgencyId: areaEducationAgencyId,
    });

    AreaEducationAgencyApiService.deleteAreaEducationAgency(request)
      .then((result: DeleteAreaEducationAgencyHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Area Education Agency was removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }
      })
      .catch(() => {
        message.error('Area Education Agency could not be removed.');
      });
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_AREA_EDUCATION_AGENCY]) && !props.programId) {
    actionButtons.push(DataTableButtonUtil.Default('New Area Education Agency', () => newAreaEducationAgency()));
  }

  const renderModal = () => {
    if (areaEducationAgencyId) {
      return <AreaEducationAgencyDetailsModal
        onCancel={closeAreaEducationAgencyDetail}
        visible={true}
        areaEducationAgencyId={areaEducationAgencyId}
        academicYear={props.academicYear}
        onSave={closeAreaEducationAgencyDetail} />
    }
  }

  return (
    <>
      {renderModal()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => AreaEducationAgencyApiService.getAreaEducationAgencyTableData(requestState, props.academicYear)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'aea_list' }} />
    </>
  );

}

export default AreaEducationAgenciesDataTable;
