import { message, Space } from 'antd';
import modal from 'antd/lib/modal';
import * as React from 'react';
import ChangeRequestApiService from '../../../api/ChangeRequestApiService';
import * as SubmitChangeRequestHandler from '../../../handlerModels/SubmitChangeRequestHandler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import GoBackButton from '../../buttons/GoBackButton';
import SubmitButton from '../../buttons/SubmitButton';

interface ChangeRequestReviewState extends BaseFormState {
}

interface ChangeRequestReviewProps extends BaseFormProps {
  changeRequest: ChangeRequestDTO;
  onPrevious: () => void;
  onSubmit: () => void;
  isValid?: boolean;
  canSubmit?: boolean;
  submitErrorMessage?: string | null;
  notApproved?: boolean;
}

class ChangeRequestReviewForm extends React.Component<ChangeRequestReviewProps, ChangeRequestReviewState> {

  constructor(props: ChangeRequestReviewProps) {
    super(props);

    this.state = {
      submitting: false,
    };
  }

  goToPreviousStep = () => {
    this.props.onPrevious();
  }

  submit = () => {
    if (this.props.notApproved) {
      modal.error({
        title: 'NOI Not Approved',
        content: 'This NOI has not yet been approved. You cannot submit this request until it has gone through the approval process.',
        closable: true
      })
    }
    else {
      if (this.props.canSubmit || this.props.canSubmit == undefined) {
        this.setState({ submitting: true });

        const request = SubmitChangeRequestHandler.Request.create({
          changeRequestId: this.props.changeRequest?.id ?? Guid.Empty(),
        });

        ChangeRequestApiService.submitChangeRequest(request)
          .then((result: SubmitChangeRequestHandler.Result) => {
            if (result.succeeded) {
              this.props.onSubmit();
            }
            else {
              this.setState({
                error: !result?.succeeded,
                message: result?.errors.join('\n'),
                fieldErrors: result?.fieldErrors
              });
              message.error('Submit Failed');
            }
          })
          .catch((results: any) => {
            this.setState({ error: results });
            message.error('Submit Failed');
          })
          .finally(() => {
            this.setState({ loading: false, submitting: false });
          });
      }
      else {
        if (this.props.submitErrorMessage) {
          message.error(this.props.submitErrorMessage);
        }
        else {
          message.error('Unknown error');
        }
      }
    }
  }

  public render = () => {
    return (
      <Space direction='horizontal' wrap={true}>
        <GoBackButton submitting={this.state.submitting} onClick={this.goToPreviousStep} />
        <SubmitButton submitting={this.state.submitting} onClick={this.submit} disabled={this.props.isValid == undefined ? false : !this.props.isValid} />
      </Space>
    );
  }
}

export default ChangeRequestReviewForm;