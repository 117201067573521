import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SpecialEmphasisDataTable from '../../../../components/datatables/postSecondary/SpecialEmphasisDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface SpecialEmphasisListProps {
}

interface SpecialEmphasisListState {
}

class SpecialEmphasisListPage extends React.Component<SpecialEmphasisListProps & RouteComponentProps<RouteObject>, SpecialEmphasisListState> {
  constructor(props: SpecialEmphasisListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Special Emphases" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.specialEmphasisList()} />

        <Card >
          <SpecialEmphasisDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(SpecialEmphasisListPage);
