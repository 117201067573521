// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SecondaryProgramStatusTypeDTO from './SecondaryProgramStatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SecondaryProgramReviewHistoryDTO extends EntitySelectorDTO { 
	id: string | null;
	secondaryProgramIdentifier: number;
	secondaryProgramStatusTypeId: number;
	secondaryProgramStatusType: SecondaryProgramStatusTypeDTO | null;
	reviewYear: number | null | null;
	reviewUserId: string | null | null;
	nextReviewYear: number;
}

const SecondaryProgramReviewHistoryDTO = {
    className: 'SecondaryProgramReviewHistoryDTO',
    create: (initValues?: Partial<SecondaryProgramReviewHistoryDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			secondaryProgramIdentifier: 0,
			secondaryProgramStatusTypeId: 0,
			secondaryProgramStatusType: null,
			reviewYear: null,
			reviewUserId: null,
			nextReviewYear: 0,
        },
        initValues);
    },
	id: 'id',
	secondaryProgramIdentifier: 'secondaryProgramIdentifier',
	secondaryProgramStatusTypeId: 'secondaryProgramStatusTypeId',
	secondaryProgramStatusType: 'secondaryProgramStatusType',
	reviewYear: 'reviewYear',
	reviewUserId: 'reviewUserId',
	nextReviewYear: 'nextReviewYear',
};

export default SecondaryProgramReviewHistoryDTO;