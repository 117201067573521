import { Alert, Col, Input, InputNumber, message, Row, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionLocationApiService from '../../../api/InstitutionLocationApiService';
import ProgramModificationChangeRequestApiService from '../../../api/ProgramModificationChangeRequestApiService';
import { ColumnWidths } from '../../../config/ColumnWidths';
import * as GetInstitutionLocationsHandler from '../../../handlerModels/GetInstitutionLocationsHandler';
import * as GetProgramModificationChangeRequestStep3Handler from '../../../handlerModels/GetProgramModificationChangeRequestStep3Handler';
import * as SubmitProgramModificationChangeRequestStep3Handler from '../../../handlerModels/SubmitProgramModificationChangeRequestStep3Handler';
import AwardTypeDTO from '../../../models/AwardTypeDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import InstitutionLocationDTO from '../../../models/InstitutionLocationDTO';
import InstructionalLevelDTO from '../../../models/InstructionalLevelDTO';
import IowaVariationDTO from '../../../models/IowaVariationDTO';
import ModeOfDeliveryDTO from '../../../models/ModeOfDeliveryDTO';
import ObjectPurposeDTO from '../../../models/ObjectPurposeDTO';
import ProgramModificationChangeRequestDTO from '../../../models/ProgramModificationChangeRequestDTO';
import ProgramModificationProgramAwardDTO from '../../../models/ProgramModificationProgramAwardDTO';
import SpecialEmphasisDTO from '../../../models/SpecialEmphasisDTO';
import TermDTO from '../../../models/TermDTO';
import TypeOfProgramDTO from '../../../models/TypeOfProgramDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import ThirdPartyCredentialDisplay from '../../displays/ThirdPartyCredentialDisplay';
import Dropdown from '../../inputs/Dropdown';
import RichTextEditor from '../../inputs/RichTextEditor';

interface ProgramModificationChangeRequestStep3FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  award: ProgramModificationProgramAwardDTO;
  instructionalLevels: InstructionalLevelDTO[];
  modeOfDelivery: ModeOfDeliveryDTO[];
  objectPurposes: ObjectPurposeDTO[];
  terms: TermDTO[];
  specialEmphasis: SpecialEmphasisDTO[];
  typeOfPrograms: TypeOfProgramDTO[];
  awardTypes: AwardTypeDTO[];
  iowaVariations: IowaVariationDTO[];
  institutionLocations: InstitutionLocationDTO[];
}

interface ProgramModificationChangeRequestStep3FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  awardId?: string;
  onSubmit?: (id: string) => void;
  onSave?: (id: string) => void;
  onPrevious?: () => void;
  onChange?: (altered: boolean) => void;
  selectedInstitution: string | null;
  readonly?: boolean;
}

class ProgramModificationChangeRequestStep3Form extends React.Component<ProgramModificationChangeRequestStep3FormProps, ProgramModificationChangeRequestStep3FormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _formItems = new Map<string, FormItemProps>()
    .set(ProgramModificationProgramAwardDTO.title, {
      required: true,
      name: ProgramModificationProgramAwardDTO.title,
      label: 'Award Title',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.awardTypeId, {
      required: true,
      name: ProgramModificationProgramAwardDTO.awardTypeId,
      label: 'Award Type',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.description, {
      required: true,
      name: ProgramModificationProgramAwardDTO.description,
      label: 'Award Description',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.instructionalLevelId, {
      required: true,
      name: ProgramModificationProgramAwardDTO.instructionalLevelId,
      label: 'Instructional Level',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.typeOfProgramId, {
      required: true,
      name: ProgramModificationProgramAwardDTO.typeOfProgramId,
      label: 'Type Of Program',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.specialEmphasisId, {
      required: true,
      name: ProgramModificationProgramAwardDTO.specialEmphasisId,
      label: 'Special Emphasis',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.objectPurposeId, {
      required: true,
      name: ProgramModificationProgramAwardDTO.objectPurposeId,
      label: 'Object Purpose',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.totalWeeks, {
      required: true,
      name: ProgramModificationProgramAwardDTO.totalWeeks,
      label: 'What is the total amount of weeks for this award?',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.totalCredits, {
      required: true,
      name: ProgramModificationProgramAwardDTO.totalCredits,
      label: 'What is the total amount of credits for this award?',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.programAwardModesOfDeliveryIds, {
      required: true,
      name: ProgramModificationProgramAwardDTO.programAwardModesOfDeliveryIds,
      label: 'How will this award be delivered?',
      rules: [ValidationRuleUtil.required()]
    })
    .set(ProgramModificationProgramAwardDTO.programAwardInstitutionLocationIds, {
      name: ProgramModificationProgramAwardDTO.programAwardInstitutionLocationIds,
      label: 'What campus will this award be provided at?',
    })


  constructor(props: ProgramModificationChangeRequestStep3FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      award: ProgramModificationProgramAwardDTO.create({
        awardTypeId: null,
        iowaVariationId: null,
        instructionalLevelId: null,
        typeOfProgramId: null,
        specialEmphasisId: null,
        objectPurposeId: null,
        totalWeeks: null,
        totalCredits: null
      }),
      awardTypes: [],
      iowaVariations: [],
      instructionalLevels: [],
      modeOfDelivery: [],
      objectPurposes: [],
      specialEmphasis: [],
      terms: [],
      typeOfPrograms: [],
      institutionLocations: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: ProgramModificationChangeRequestStep3FormProps) {
    if (this.props.changeRequestId && prevProps.changeRequestId != this.props.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });

    this._formRef.current?.resetFields();
    if (this.props.onChange) {
      this.props.onChange(false)
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.terms || this.state.terms.length == 0) {
      loaders.push(this.loadTerms());
    }

    if (!this.state.instructionalLevels || this.state.instructionalLevels.length == 0) {
      loaders.push(this.loadInstructionalLevel());
    }

    if (!this.state.typeOfPrograms || this.state.typeOfPrograms.length == 0) {
      loaders.push(this.loadTypeOfProgram());
    }

    if (!this.state.specialEmphasis || this.state.specialEmphasis.length == 0) {
      loaders.push(this.loadSpecialEmphasis());
    }

    if (!this.state.objectPurposes || this.state.objectPurposes.length == 0) {
      loaders.push(this.loadObjectPurpose());
    }

    if (!this.state.modeOfDelivery || this.state.modeOfDelivery.length == 0) {
      loaders.push(this.loadModeOfDelivery());
    }

    if (!this.state.awardTypes || this.state.awardTypes.length == 0) {
      loaders.push(this.loadAwardType());
    }

    if (!this.state.iowaVariations || this.state.iowaVariations.length == 0) {
      loaders.push(this.loadIowaVariation());
    }

    if (!this.props.changeRequestId || this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest());
    }

    if (this.state.institutionLocations.length == 0 && this.props.selectedInstitution != Guid.Empty()) {
      loaders.push(this.loadInstitutionLocations());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false }, () => this.resetForm());
    });
  }

  private loadInstitutionLocations = () => {
    InstitutionLocationApiService.getInstitutionLocations(this.props.selectedInstitution ?? Guid.Empty())
      .then((result: GetInstitutionLocationsHandler.Result) => {
        if (result?.succeeded) {
          this.setState({ institutionLocations: result.institutionLocations ?? [] })
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
  }

  private loadTerms = () => {
    return LookupsUtil.getAll<TermDTO>(TermDTO.className)
      .then((results: TermDTO[]) => {
        if (results) {
          this.setState({ terms: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAwardType = () => {
    return LookupsUtil.getAll<AwardTypeDTO>(AwardTypeDTO.className)
      .then((results: AwardTypeDTO[]) => {
        if (results) {
          this.setState({ awardTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadIowaVariation = () => {
    return LookupsUtil.getAll<IowaVariationDTO>(IowaVariationDTO.className)
      .then((results: IowaVariationDTO[]) => {
        if (results) {
          this.setState({ iowaVariations: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTypeOfProgram = () => {
    return LookupsUtil.getAll<TypeOfProgramDTO>(TypeOfProgramDTO.className)
      .then((results: TypeOfProgramDTO[]) => {
        if (results) {
          this.setState({ typeOfPrograms: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadInstructionalLevel = () => {
    return LookupsUtil.getAll<InstructionalLevelDTO>(InstructionalLevelDTO.className)
      .then((results: InstructionalLevelDTO[]) => {
        if (results) {
          this.setState({ instructionalLevels: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadSpecialEmphasis = () => {
    return LookupsUtil.getAll<SpecialEmphasisDTO>(SpecialEmphasisDTO.className)
      .then((results: SpecialEmphasisDTO[]) => {
        if (results) {
          this.setState({ specialEmphasis: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadObjectPurpose = () => {
    return LookupsUtil.getAll<ObjectPurposeDTO>(ObjectPurposeDTO.className)
      .then((results: ObjectPurposeDTO[]) => {
        if (results) {
          this.setState({ objectPurposes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  };

  private loadModeOfDelivery = () => {
    return LookupsUtil.getAll<ModeOfDeliveryDTO>(ModeOfDeliveryDTO.className)
      .then((results: ModeOfDeliveryDTO[]) => {
        if (results) {
          this.setState({ modeOfDelivery: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  };

  private loadChangeRequest = () => {
    const request = GetProgramModificationChangeRequestStep3Handler.Request.create({
      changeRequestId: this.props.changeRequestId,
      programAwardId: this.props.awardId
    });

    return ProgramModificationChangeRequestApiService.getStep3(request)
      .then((results: GetProgramModificationChangeRequestStep3Handler.Result) => {
        if (results) {
          const programAward: ProgramModificationProgramAwardDTO = (results.changeRequest?.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create()).programModificationAwards?.find((x: any) => x.id == this.props.awardId) ?? ProgramModificationProgramAwardDTO.create({
            awardTypeId: null,
            iowaVariationId: null,
            instructionalLevelId: null,
            typeOfProgramId: null,
            specialEmphasisId: null,
            objectPurposeId: null,
            totalWeeks: null,
            totalCredits: null
          });

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            award: programAward,
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const programAward = ProgramModificationProgramAwardDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    programAward.id = this.props.awardId ?? Guid.Empty();

    if (programAward.id != Guid.Empty()) {
      programAward.order = this.state.award.order;
    }

    const programMod = this.state.changeRequest.programModificationChangeRequest;
    const model = ProgramModificationChangeRequestDTO.create({
      id: programMod?.id ?? Guid.Empty(),
      programId: programMod?.programId ?? Guid.Empty(),
      institutionId: this.state.changeRequest.institutionId ?? Guid.Empty(),
      changeRequestId: this.state.changeRequest.id == Guid.Empty() ? this.props.changeRequestId : this.state.changeRequest.id,
      programModificationAwards: [programAward]
    });

    const request = SubmitProgramModificationChangeRequestStep3Handler.Request.create({
      programModificationChangeRequest: model
    }) as SubmitProgramModificationChangeRequestStep3Handler.Request;

    ProgramModificationChangeRequestApiService.submitStep3(request)
      .then((result: SubmitProgramModificationChangeRequestStep3Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');
          this.setState({ changeRequest: result.changeRequest ?? ChangeRequestDTO.create() });

          let newAwardId = this.props.awardId ?? Guid.Empty();
          if (result.changeRequest?.programModificationChangeRequest?.programModificationAwards) {
            if (this.props.awardId == Guid.Empty()) {
              newAwardId = result.changeRequest?.programModificationChangeRequest?.programModificationAwards[result.changeRequest?.programModificationChangeRequest?.programModificationAwards.length - 1].id as string;
            }
          }

          const AwardId = newAwardId;
          if (this.props.onSubmit) {
            this.props.onSubmit(AwardId)
          }
          this.resetForm();
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
    if (this.props.onChange) {
      this.props.onChange(true)
    }
  }

  private handleAwardTypeChange = () => {
    const awardType = this.state.awardTypes.find(x => x.id == this._formRef.current?.getFieldValue(ProgramModificationProgramAwardDTO.awardTypeId)) ?? AwardTypeDTO.create();

    if (!awardType.instructionalLevels.includes(this._formRef.current?.getFieldValue(ProgramModificationProgramAwardDTO.instructionalLevelId) ?? 0)) {
      this._formRef.current?.setFieldsValue({ instructionalLevelId: undefined })
    }

    if (!awardType.typeOfPrograms.includes(this._formRef.current?.getFieldValue(ProgramModificationProgramAwardDTO.typeOfProgramId) ?? 0)) {
      this._formRef.current?.setFieldsValue({ typeOfProgramId: undefined })
    }

    if (!awardType.specialEmphases.includes(this._formRef.current?.getFieldValue(ProgramModificationProgramAwardDTO.specialEmphasisId) ?? 0)) {
      this._formRef.current?.setFieldsValue({ specialEmphasisId: undefined })
    }

    if (!awardType.objectPurposes.includes(this._formRef.current?.getFieldValue(ProgramModificationProgramAwardDTO.objectPurposeId) ?? 0)) {
      this._formRef.current?.setFieldsValue({ objectPurposeId: undefined })
    }
  }

  public render = () => {

    if (this.state.loading) {
      return <Skeleton active={true} />
    }

    const formPrimaryAwardTypeId = this._formRef.current?.getFieldValue(ProgramModificationProgramAwardDTO.awardTypeId);
    const primarayAwardId = formPrimaryAwardTypeId ? formPrimaryAwardTypeId : 0;

    return (
      <Space size="small" direction="vertical" >
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.award}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={!this.props.readonly}>

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.ONE_THIRD}>
              <label
                title={'Award Type Award ' + this.state.award.order}
                htmlFor={'Award Type Award ' + this.state.award.order} />
              <FormItem
                {...this._formItems.get(ProgramModificationProgramAwardDTO.awardTypeId)}
                {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.awardTypeId + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
                <Dropdown id={'Award Type Award ' + this.state.award.order} showArrow={!this.props.readonly} disabled={this.props.readonly}
                  onChange={this.handleAwardTypeChange}>
                  {this.state.awardTypes.map(x => this.renderAwardType(x))}
                </Dropdown>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.THREE_QUARTERS}>
              <FormItem
                {...this._formItems.get(ProgramModificationProgramAwardDTO.title)}
                {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.title + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
                <Input
                  aria-label={'Title Award ' + this.state.award.order}
                  id={'Title Award ' + this.state.award.order}
                  disabled={this.props.readonly} />
              </FormItem>
            </ Col>
          </ Row>

          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.ONE_QUARTER}>
              <label
                title={'Instructional Level Award ' + this.state.award.order}
                htmlFor={'Instructional Level Award ' + this.state.award.order} />
              <FormItem
                {...this._formItems.get(ProgramModificationProgramAwardDTO.instructionalLevelId)}
                {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.instructionalLevelId + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
                <Dropdown id={'Instructional Level Award ' + this.state.award.order} showArrow={!this.props.readonly} disabled={this.props.readonly}>
                  {this.state.instructionalLevels.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.instructionalLevels.includes(x.id)).map(x => this.renderInsturctionalLevel(x))}
                </Dropdown>
              </FormItem>
            </Col>

            <Col {...ColumnWidths.ONE_QUARTER}>
              <label
                title={'Type Of Program Award ' + this.state.award.order}
                htmlFor={'Type Of Program Award ' + this.state.award.order} />
              <FormItem
                {...this._formItems.get(ProgramModificationProgramAwardDTO.typeOfProgramId)}
                {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.typeOfProgramId + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
                <Dropdown id={'Type Of Program Award ' + this.state.award.order} showArrow={!this.props.readonly} disabled={this.props.readonly}>
                  {this.state.typeOfPrograms.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.typeOfPrograms.includes(x.id)).map(x => this.renderTypesOfProgram(x))}
                </Dropdown>
              </FormItem>
            </Col>

            <Col {...ColumnWidths.ONE_QUARTER}>
              <label
                title={'Special Emphasis Award ' + this.state.award.order}
                htmlFor={'Special Emphasis Award ' + this.state.award.order} />
              <FormItem
                {...this._formItems.get(ProgramModificationProgramAwardDTO.specialEmphasisId)}
                {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.specialEmphasisId + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
                <Dropdown id={'Special Emphasis Award ' + this.state.award.order} showArrow={!this.props.readonly} disabled={this.props.readonly}>
                  {this.state.specialEmphasis.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.specialEmphases.includes(x.id)).map(x => this.renderSpecialEmphasis(x))}
                </Dropdown>
              </FormItem>
            </Col>

            <Col {...ColumnWidths.ONE_QUARTER}>
              <label
                title={'Object Purpose Award ' + this.state.award.order}
                htmlFor={'Object Purpose Award ' + this.state.award.order} />
              <FormItem
                {...this._formItems.get(ProgramModificationProgramAwardDTO.objectPurposeId)}
                {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.objectPurposeId + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
                <Dropdown id={'Object Purpose Award ' + this.state.award.order} showArrow={!this.props.readonly} disabled={this.props.readonly}>
                  {this.state.objectPurposes.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.objectPurposes.includes(x.id)).map(x => this.renderObjectPurpose(x))}
                </Dropdown>
              </FormItem>
            </Col>
          </Row>

          <label
            title={'Description Award ' + this.state.award.order}
            htmlFor={'Description Award ' + this.state.award.order} />
          <FormItem
            {...this._formItems.get(ProgramModificationProgramAwardDTO.description)}
            {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.description + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
            <RichTextEditor id={'Description Award ' + this.state.award.order} readonly={this.props.readonly} />
          </FormItem>

          <label
            title={'Total Weeks Award ' + this.state.award.order}
            htmlFor={'Total Weeks Award ' + this.state.award.order} />
          <FormItem
            {...this._formItems.get(ProgramModificationProgramAwardDTO.totalWeeks)}
            {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.totalWeeks + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
            <InputNumber id={'Total Weeks Award ' + this.state.award.order} disabled={this.props.readonly} />
          </FormItem>

          <label
            title={'Total Credits Award ' + this.state.award.order}
            htmlFor={'Total Credits Award ' + this.state.award.order} />
          <FormItem
            {...this._formItems.get(ProgramModificationProgramAwardDTO.totalCredits)}
            {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.totalCredits + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
            <InputNumber id={'Total Credits Award ' + this.state.award.order} disabled={this.props.readonly} />
          </FormItem>

          <label
            title={'Mode of Delivery Award ' + this.state.award.order}
            htmlFor={'Mode of Delivery Award ' + this.state.award.order} />
          <FormItem
            {...this._formItems.get(ProgramModificationProgramAwardDTO.programAwardModesOfDeliveryIds)}
            {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.programAwardModesOfDeliveryIds + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
            <Dropdown id={'Mode of Delivery Award ' + this.state.award.order} mode="multiple" disabled={this.props.readonly}>
              {this.state.modeOfDelivery.map(x => this.renderModeOfDelivery(x))}
            </Dropdown>
          </FormItem>

          {this.renderLocation()}

          <ThirdPartyCredentialDisplay
            thirdPartyCredentials={this.state.award.thirdPartyCredentials ?? []}
            awardIndex={this.state.award.order}
            readonly={this.props.readonly || this.state.loading || this.state.submitting}
            submitted={this.state.submitted || this.props.readonly}
            fieldErrors={this.state.fieldErrors} />

          {this.renderActions()}
        </Form>
      </Space >
    );
  }

  private renderActions() {
    if (!this.props.readonly) {
      return <Space direction='horizontal' wrap={true}>
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton submitting={this.state.submitting} />
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space>
    }
  }

  renderLocation() {
    if (this.state.institutionLocations.length > 1) {
      return (
        <>
          <label
            title={'Institution Location Award ' + this.state.award.order}
            htmlFor={'Institution Location Award ' + this.state.award.order} />
          <FormItem
            {...this._formItems.get(ProgramModificationProgramAwardDTO.programAwardInstitutionLocationIds)}
            {...ValidationUtil.getValidation(ProgramModificationProgramAwardDTO.programAwardInstitutionLocationIds + this.state.award.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}  >
            <Dropdown id={'Institution Location Award ' + this.state.award.order} mode="multiple" disabled={this.props.readonly}>
              {this.state.institutionLocations.map(x => this.renderInstitutionLocations(x))}
            </Dropdown>
          </FormItem>
        </>
      );
    }
  }

  renderAwardType(awardType: AwardTypeDTO) {
    if (awardType.id) {
      return <Select.Option key={awardType.id ?? Guid.Empty()} value={awardType.id ?? Guid.Empty()}>{awardType.code} - {awardType.name}</Select.Option>
    }
  }

  renderTypesOfProgram(typesOfProgram: TypeOfProgramDTO) {
    if (typesOfProgram.id) {
      return <Select.Option key={typesOfProgram.id ?? Guid.Empty()} value={typesOfProgram.id ?? Guid.Empty()}>{typesOfProgram.code} - {typesOfProgram.name}</Select.Option>
    }
  }

  renderObjectPurpose(objectPurpose: ObjectPurposeDTO) {
    if (objectPurpose.id) {
      return <Select.Option key={objectPurpose.id ?? Guid.Empty()} value={objectPurpose.id ?? Guid.Empty()}>{objectPurpose.code} - {objectPurpose.name}</Select.Option>
    }
  }

  renderSpecialEmphasis(specialEmphasis: SpecialEmphasisDTO) {
    if (specialEmphasis.id) {
      return <Select.Option key={specialEmphasis.id ?? Guid.Empty()} value={specialEmphasis.id ?? Guid.Empty()}>{specialEmphasis.code} - {specialEmphasis.name}</Select.Option>
    }
  }

  renderInsturctionalLevel(instructionalLevel: InstructionalLevelDTO) {
    if (instructionalLevel.id) {
      return <Select.Option key={instructionalLevel.id ?? Guid.Empty()} value={instructionalLevel.id ?? Guid.Empty()}>{instructionalLevel.code} - {instructionalLevel.name}</Select.Option>
    }
  }

  renderIowaVariation(iowaVariation: IowaVariationDTO) {
    if (iowaVariation.id) {
      return <Select.Option key={iowaVariation.id ?? Guid.Empty()} value={iowaVariation.id ?? Guid.Empty()}>{iowaVariation.code} - {iowaVariation.name}</Select.Option>
    }
  }

  renderInstitutionLocations(institutionLocation: InstitutionLocationDTO) {
    if (institutionLocation.id) {
      return <Select.Option key={institutionLocation.id ?? Guid.Empty()} value={institutionLocation.id ?? Guid.Empty()}>{institutionLocation.name} - {institutionLocation.street1} </Select.Option>
    }
  }

  renderModeOfDelivery(modeOfDelivery: ModeOfDeliveryDTO) {
    if (modeOfDelivery.id) {
      return <Select.Option key={modeOfDelivery.id ?? Guid.Empty()} value={modeOfDelivery.id ?? Guid.Empty()}> {modeOfDelivery.name}</Select.Option>
    }
  }

  private renderErrors = () => {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default ProgramModificationChangeRequestStep3Form;