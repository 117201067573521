// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ProgramClassificationDTO from '../models/ProgramClassificationDTO';
import * as GetProgramClassificationDetailsHandler from '../handlerModels/GetProgramClassificationDetailsHandler';
import * as SaveProgramClassificationDetailsHandler from '../handlerModels/SaveProgramClassificationDetailsHandler';
import * as DeleteProgramClassificationHandler from '../handlerModels/DeleteProgramClassificationHandler';

export class ProgramClassificationApiService extends BaseApi {

    // post: api/programClassification
    public getProgramClassificationTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ProgramClassificationDTO>> {
        let url = `api/programClassification`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ProgramClassificationDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/programClassification/export
    public getProgramClassificationDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/programClassification/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // get: api/programClassification/${id}
    public getProgramClassificationDetails(id: number): Promise<GetProgramClassificationDetailsHandler.Result> {
        let url = `api/programClassification/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramClassificationDetailsHandler.Result>(null, 'get', url, true, false);
    }

    // post: api/programClassification/save
    public saveProgramClassificationDetails(saveProgramClassificationDetails: SaveProgramClassificationDetailsHandler.Request): Promise<SaveProgramClassificationDetailsHandler.Result> {
        let url = `api/programClassification/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveProgramClassificationDetailsHandler.Result>(saveProgramClassificationDetails, 'post', url, true, false);
    }

    // post: api/programClassification/deleteProgramClassification
    public deleteProgramClassification(deleteProgramClassification: DeleteProgramClassificationHandler.Request): Promise<DeleteProgramClassificationHandler.Result> {
        let url = `api/programClassification/deleteProgramClassification`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteProgramClassificationHandler.Result>(deleteProgramClassification, 'post', url, true, false);
    }
}
var service = new ProgramClassificationApiService();
export default service;
