// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CipNumberDetailDTO from './CipNumberDetailDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CipNumberDTO extends EntitySelectorDTO { 
	id: string | null;
	cipNumberDetails: CipNumberDetailDTO[] | null;
	currentDetail: CipNumberDetailDTO | null;
}

const CipNumberDTO = {
    className: 'CipNumberDTO',
    create: (initValues?: Partial<CipNumberDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			cipNumberDetails: [],
			currentDetail: null,
        },
        initValues);
    },
	id: 'id',
	cipNumberDetails: 'cipNumberDetails',
	currentDetail: 'currentDetail',
};

export default CipNumberDTO;