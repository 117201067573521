import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

function AcademicYearsDataTable() {
  const [, setAcademicYearStatusTypes] = useState([] as TableFilterOption[]);

  useEffect(() => {
    TableFilterUtil.AcademicYearStatusTypes()
      .then(x => {
        setAcademicYearStatusTypes(x)
      });
  }, []);

  const getColumnDefinitions = () => {
    return [
      {
        title: 'Year',
        dataIndex: AcademicYearDTO.id,
        sorter: true,
        width: 50,
        render: (data: string, row: AcademicYearDTO) => {
          return row.id + '-' + (row.id + 1);
        },
        filterType: FilterType.Text
      },
      {
        title: 'State',
        dataIndex: AcademicYearDTO.className,
        width: 75,
        render: (data: string, row: AcademicYearDTO) => {
          return inInstructorRange(row);
        },
      },
    ] as DataTableColumnProps<any>[];
  };

  const inInstructorRange = (academicYear: AcademicYearDTO) => {
    if (DateTimeUtil.isWithinRange(moment(academicYear.instructorStartDate), moment(academicYear.instructorEndDate))) {
      return 'Instructor Response';
    }
    else if (DateTimeUtil.isBeforeRange(moment(academicYear.instructorStartDate)) && AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
      return 'Open Program Review Year';
    }
    else {
      return inRPPRange(academicYear);
    }
  }
  const inRPPRange = (academicYear: AcademicYearDTO) => {
    if (DateTimeUtil.isWithinRange(moment(academicYear.rppStartDate), moment(academicYear.rppEndDate))) {
      return 'RPP Response';
    }
    else {
      return inDERange(academicYear);
    }
  }

  const inDERange = (academicYear: AcademicYearDTO) => {
    if (DateTimeUtil.isWithinRange(moment(academicYear.deStartDate), moment(academicYear.deStartDate))) {
      return 'DE Response';
    }
    else {
      return inCommentRelease(academicYear);
    }
  }

  const inCommentRelease = (academicYear: AcademicYearDTO) => {
    if (DateTimeUtil.isBeforeRange(moment(academicYear.commentReleaseDate))) {
      return 'Comment Release';
    }
    else {
      return inFinalization(academicYear);
    }
  }

  const inFinalization = (academicYear: AcademicYearDTO) => {
    if (DateTimeUtil.isBeforeRange(moment(academicYear.finalizationDate))) {
      return 'Finalization';
    }
    else {
      return 'Program Review Year Closed';
    }
  }

  const openAcademicYear = (record: AcademicYearDTO) => {
    HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: record.id }));
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  return (
    <DataTable
      onRowClick={openAcademicYear}
      serverSide={true}
      tableProps={{
        rowKey: 'id',
        scroll: { x: 500 }
      }}
      globalSearch={true}
      buttonBar={actionButtons}
      columns={getColumnDefinitions()}
      fetchData={{
        fetch: (requestState: TableRequestDTO) => AcademicYearApiService.getAcademicYearTableData(requestState)
      }}
      stateSaving={{ enabled: true, tableUniqueKey: 'academic_years_list' }} />
  );
}

export default AcademicYearsDataTable;
