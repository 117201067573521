// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CourseDTO from './CourseDTO';
import InstitutionCourseBlockDTO from './InstitutionCourseBlockDTO';
import WorkBasedLearningTypeDTO from './WorkBasedLearningTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramTermCourseGroupOptionDTO extends EntitySelectorDTO { 
	id: string | null;
	groupId: string | null | null;
	courseId: string | null | null;
	course: CourseDTO | null;
	courseBlockId: string | null | null;
	courseBlock: InstitutionCourseBlockDTO | null;
	description: string | null;
	groupOptionTypeId: number | null | null;
	order: number;
	generalEducationId: number | null | null;
	apsGeneralEducationId: number | null | null;
	transferMajorCourseBlockId: number | null | null;
	transferMajorLactsCategoryId: number | null | null;
	transferMajorGeneralEducationTypeId: number | null | null;
	transferMajorDisciplineFrameworkElementId: number | null | null;
	workBasedLearningTypeId: number | null | null;
	workBasedLearningType: WorkBasedLearningTypeDTO | null;
	credits: MinMaxDTO<number | null> | null;
	footnote: string | null;
	techCore: boolean | null | null;
	embeddedCredits: number | null | null;
}

const ProgramTermCourseGroupOptionDTO = {
    className: 'ProgramTermCourseGroupOptionDTO',
    create: (initValues?: Partial<ProgramTermCourseGroupOptionDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			groupId: null,
			courseId: null,
			course: null,
			courseBlockId: null,
			courseBlock: null,
			description: "",
			groupOptionTypeId: null,
			order: 0,
			generalEducationId: null,
			apsGeneralEducationId: null,
			transferMajorCourseBlockId: null,
			transferMajorLactsCategoryId: null,
			transferMajorGeneralEducationTypeId: null,
			transferMajorDisciplineFrameworkElementId: null,
			workBasedLearningTypeId: null,
			workBasedLearningType: null,
			credits: null,
			footnote: "",
			techCore: null,
			embeddedCredits: null,
        },
        initValues);
    },
	id: 'id',
	groupId: 'groupId',
	courseId: 'courseId',
	course: 'course',
	courseBlockId: 'courseBlockId',
	courseBlock: 'courseBlock',
	description: 'description',
	groupOptionTypeId: 'groupOptionTypeId',
	order: 'order',
	generalEducationId: 'generalEducationId',
	apsGeneralEducationId: 'apsGeneralEducationId',
	transferMajorCourseBlockId: 'transferMajorCourseBlockId',
	transferMajorLactsCategoryId: 'transferMajorLactsCategoryId',
	transferMajorGeneralEducationTypeId: 'transferMajorGeneralEducationTypeId',
	transferMajorDisciplineFrameworkElementId: 'transferMajorDisciplineFrameworkElementId',
	workBasedLearningTypeId: 'workBasedLearningTypeId',
	workBasedLearningType: 'workBasedLearningType',
	credits: 'credits',
	footnote: 'footnote',
	techCore: 'techCore',
	embeddedCredits: 'embeddedCredits',
};

export default ProgramTermCourseGroupOptionDTO;