import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import Modal from 'antd/lib/modal';
import * as React from 'react';
import { useState } from 'react';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import * as DeleteServiceAreaHandler from '../../../handlerModels/DeleteServiceAreaHandler';
import ServiceAreaDetailsModal from '../../modals/ServiceAreaDetailsModal';
import Guid from '../../../utils/Guid';

interface ServiceAreasDataTableProps {
  academicYear: number;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
}

function ServiceAreasDataTable(props: ServiceAreasDataTableProps) {
  let _dataTable: DataTable<ServiceAreaDTO> | undefined = undefined;

  const [serviceAreaId, setServiceAreaId] = useState<string | null>();

  const getColumnDefinitions = () => {
    return [
      {
        title: 'Identifier',
        dataIndex: ServiceAreaDTO.serviceAreaIdentifier,
        sorter: true,
        width: 25,
        filterType: FilterType.Text
      },
      {
        title: 'Title',
        dataIndex: ServiceAreaDTO.description,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Actions',
        align: 'center',
        dataIndex: ServiceAreaDTO.id,
        sorter: false,
        width: 50,
        render: (data: string, row: ServiceAreaDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => openServiceAreaDetail(row)}><FormOutlined /></Button>
              <Button type="link" onClick={() => promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
      },
    ] as DataTableColumnProps<any>[];
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openServiceAreaDetail = (record: ServiceAreaDTO) => {
    if (record.id) {
      setServiceAreaId(record.id);
    }
  }

  const addServiceArea = () => {
    setServiceAreaId(Guid.Empty());
  }

  const closeServiceAreaDetail = () => {
    setServiceAreaId(null);
  }

  const promptConfirmRemove = (serviceAreaId: string | null) => {
    if (serviceAreaId == null || serviceAreaId == Guid.Empty()) {
      return;
    }

    Modal.confirm({
      title: 'Are you sure you want to delete this Service Area?',
      okText: 'Delete',
      onOk: () => deleteServiceArea(serviceAreaId),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteServiceArea = (serviceAreaId: string) => {
    const request = DeleteServiceAreaHandler.Request.create({
      serviceAreaId: serviceAreaId
    });

    ServiceAreaApiService.deleteServiceArea(request)
      .then((result: DeleteServiceAreaHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Service Area could not be removed.');
      });
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SERVICE_AREA])) {
    actionButtons.push(DataTableButtonUtil.Default('New Service Area', () => addServiceArea()));
  }

  const renderModal = () => {
    if (serviceAreaId != null) {
      return <ServiceAreaDetailsModal
        onCancel={closeServiceAreaDetail}
        visible={true}
        serviceAreaId={serviceAreaId}
        academicYear={props.academicYear}
        onSave={closeServiceAreaDetail} />
    }
  }

  return (
    <>
      {renderModal()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => ServiceAreaApiService.getServiceAreaTableData(requestState, props.academicYear)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'service_area_list' }} />
    </>
  );
}

export default ServiceAreasDataTable;
