// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import NewProgramChangeRequestDTO from './NewProgramChangeRequestDTO';
import UserDTO from './UserDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NewProgramChangeRequestContactDTO extends EntitySelectorDTO { 
	id: string | null;
	newProgramChangeRequestId: string | null;
	newProgramChangeRequest: NewProgramChangeRequestDTO | null;
	userId: string | null | null;
	user: UserDTO | null;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	phone: string | null;
}

const NewProgramChangeRequestContactDTO = {
    className: 'NewProgramChangeRequestContactDTO',
    create: (initValues?: Partial<NewProgramChangeRequestContactDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			newProgramChangeRequestId: '00000000-0000-0000-0000-000000000000',
			newProgramChangeRequest: null,
			userId: null,
			user: null,
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
        },
        initValues);
    },
	id: 'id',
	newProgramChangeRequestId: 'newProgramChangeRequestId',
	newProgramChangeRequest: 'newProgramChangeRequest',
	userId: 'userId',
	user: 'user',
	firstName: 'firstName',
	lastName: 'lastName',
	email: 'email',
	phone: 'phone',
};

export default NewProgramChangeRequestContactDTO;