import { Dispatch } from 'redux';
import RegionalPlanningPartnerApiService from '../../api/RegionalPlanningPartnerApiService';
import * as GetRegionPlanningPartnerDetailsHandler from '../../handlerModels/GetRegionPlanningPartnerDetailsHandler';
import RegionalPlanningPartnerDTO from '../../models/RegionalPlanningPartnerDTO';
import UserSecurity from '../../models/UserSecurity';
import BaseAction from './BaseAction';


export type RPPAction = BaseAction<RPPActionTypes, any>;

export enum RPPActionTypes {
  RPP_SELECT = 'RPP_SELECT',
  RPP_ID_SELECT = 'RPP_SELECT',
  RPP_DEFAULT_SELECT = 'RPP_DEFAULT_SELECT',
}

export const RPPActions = {
  select: RPPSelectAction,
  idSelect: RPPIdSelectAction,
  defaultSelect: RPPDefaultSelectAction
};

function RPPSelectAction(rpp: RegionalPlanningPartnerDTO | null): RPPAction {
  return {
    type: RPPActionTypes.RPP_SELECT,
    data: rpp,
  }
}

function RPPIdSelectAction(rppId: number) {
  return async (dispatch: Dispatch<any>) => {
    const request = GetRegionPlanningPartnerDetailsHandler.Request.create({
      rppId: rppId
    });

    let rpp = {} as RegionalPlanningPartnerDTO;
    await RegionalPlanningPartnerApiService.getRegionDetails(request)
      .then((results: GetRegionPlanningPartnerDetailsHandler.Result) => {
        if (results) {
          rpp = results.regionPlanningPartner ?? RegionalPlanningPartnerDTO.create();
        }
      });
    dispatch(RPPSelectAction(rpp ?? null));
  };
}

function RPPDefaultSelectAction(us: UserSecurity | null) {
  return (dispatch: Dispatch<any>) => {
    if (!us?.isStateUser) {
      const rppId = us?.rppIds?.length ?? 0 > 0 ? us?.rppIds[0] : null;
      return dispatch(RPPIdSelectAction(rppId ?? 0));
    }

    return dispatch(RPPSelectAction(null));
  };
}
