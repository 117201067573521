// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import TermDTO from '../models/TermDTO';
import * as GetTermDetailsHandler from '../handlerModels/GetTermDetailsHandler';
import * as SaveTermDetailsHandler from '../handlerModels/SaveTermDetailsHandler';
import * as GetTermsHandler from '../handlerModels/GetTermsHandler';
import * as DeleteTermHandler from '../handlerModels/DeleteTermHandler';

export class TermsApiService extends BaseApi {

    // post: api/terms
    public getTermTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<TermDTO>> {
        let url = `api/terms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<TermDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/terms/export
    public getTermsDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/terms/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/terms/${encodeURIComponent(id)}
    public getTermDetails(id: string): Promise<GetTermDetailsHandler.Result> {
        let url = `api/terms/${encodeURIComponent(id)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetTermDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/terms/save
    public saveTermDetails(saveTermDetails: SaveTermDetailsHandler.Request): Promise<SaveTermDetailsHandler.Result> {
        let url = `api/terms/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveTermDetailsHandler.Result>(saveTermDetails, 'post', url, true, false);
    }

    // post: api/terms/getTerms
    public getTerms(): Promise<GetTermsHandler.Result> {
        let url = `api/terms/getTerms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetTermsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/terms/deleteTerm
    public deleteTerm(deleteTerm: DeleteTermHandler.Request): Promise<DeleteTermHandler.Result> {
        let url = `api/terms/deleteTerm`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteTermHandler.Result>(deleteTerm, 'post', url, true, false);
    }
}
var service = new TermsApiService();
export default service;
