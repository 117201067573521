import { Checkbox, Descriptions, Space, Typography } from 'antd';
import { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import * as React from 'react';
import FeatureFlagsApiService from '../../api/FeatureFlagsApiService';
import * as GetFeatureFlagGroupsHandler from '../../handlerModels/GetFeatureFlagGroupsHandler';
import FeatureFlagGroupDTO from '../../models/FeatureFlagGroupDTO';
import BaseFormState from '../../redux/bases/BaseFormState';

interface FeatureFlagEditorState extends BaseFormState {
  featureFlagsGroups: FeatureFlagGroupDTO[];
  selectedIds: string[];
}

interface FeatureFlagEditorProps {
  style?: React.CSSProperties;
  disabledIds?: string[];
  options?: FeatureFlagGroupDTO[];
  selectedIds: string[];
  isEditing?: boolean;
  activeFeatureFlags?: string;
  onChange?: (selectedIds: string[]) => void;
}

class FeatureFlagEditor extends React.Component<FeatureFlagEditorProps, FeatureFlagEditorState> {

  constructor(props: FeatureFlagEditorProps) {
    super(props);

    this.state = {
      featureFlagsGroups: [],
      selectedIds: []
    };
  }

  componentDidMount() {
    if (!this.props.options) {
      this.loadFeatureFlags();
    }
    else {
      this.setState({ featureFlagsGroups: this.props.options });
    }

    this.setState({ selectedIds: this.props.selectedIds });
  }

  componentDidUpdate(prevProps: FeatureFlagEditorProps) {
    if (this.props.options && this.props.options != prevProps.options) {
      this.setState({ featureFlagsGroups: this.props.options });
    }

    if (this.props.selectedIds != prevProps.selectedIds) {
      this.setState({ selectedIds: this.props.selectedIds });
    }
  }

  private loadFeatureFlags = () => {
    this.setState({ loading: true });
    const request = GetFeatureFlagGroupsHandler.Request.create({
      featureFlagArea: this.props.activeFeatureFlags
    });

    return FeatureFlagsApiService.getFeatureFlags(request)
      .then((results: GetFeatureFlagGroupsHandler.Result) => {
        if (results.featureFlagGroups) {
          this.setState({
            featureFlagsGroups: results.featureFlagGroups,
          });
        }
      })
      .catch((error: any) => {
        this.setState({ error: true, message: error });
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private handleGroupChange(event: any, featureFlagGroup: FeatureFlagGroupDTO) {
    const selectedIds = this.state.selectedIds;

    const featureFlagIds = featureFlagGroup.featureFlags?.map(ff => ff.id) ?? [];

    featureFlagIds.forEach(x => {
      if (this.props.disabledIds && x && this.props.disabledIds.includes(x)) {
        featureFlagIds.remove(x);
      }
    });

    selectedIds.removeAll(featureFlagIds);

    if (event.target.checked) {
      selectedIds.pushAll(featureFlagIds);
    }

    selectedIds.distinct();

    this.setState({ selectedIds: selectedIds });
    if (this.props.onChange) {
      this.props.onChange(selectedIds);
    }
  }

  private handleFeatureFlagChange = (selected: string, featureFlagGroup: FeatureFlagGroupDTO) => {
    const selectedFeatureFlagIds = selected.toString().split(',');
    const selectedIds = this.state.selectedIds;
    const featureFlagGroupIds = featureFlagGroup.featureFlags?.map(ff => ff.id) ?? [];

    selectedIds.removeAll(featureFlagGroupIds);
    selectedIds.pushAll(selectedFeatureFlagIds);
    selectedIds.distinct();

    this.setState({ selectedIds: selectedIds });
    if (this.props.onChange) {
      this.props.onChange(selectedIds);
    }
  }

  render() {
    return (
      <Space direction="vertical" size="large">
        {this.state.featureFlagsGroups.map(x => this.renderFeatureFlagGroup(x))}
      </Space>
    );
  }

  renderFeatureFlagGroup(featureFlagGroup: FeatureFlagGroupDTO) {
    const checkBoxOptions: CheckboxOptionType[] = [];
    const selectedIds: string[] = [];
    let checkedCount = 0;
    let roleCount = 0;

    if (this.state.featureFlagsGroups) {
      featureFlagGroup.featureFlags?.forEach(ff => {
        if (ff.id) {
          const checked = this.state.selectedIds.includes(ff.id);
          const disabled = this.props.disabledIds?.includes(ff.id);

          checkBoxOptions.push({
            value: ff.id,
            label: ff.name,
            disabled: disabled
          });

          if (checked) {
            selectedIds.push(ff.id);
          }

          checkedCount += checked ? 1 : 0;
          roleCount += disabled && checked ? 1 : 0;
        }
      });
    }

    if (featureFlagGroup?.featureFlags) {
      const groupChecked = checkedCount == featureFlagGroup.featureFlags.length;
      const partiallyChecked = checkedCount > 0 && !groupChecked;
      const groupDisabled = roleCount == featureFlagGroup.featureFlags.length;

      return (
        <Space direction="vertical" size="small" >
          <Checkbox key={featureFlagGroup.name}
            checked={groupChecked}
            indeterminate={partiallyChecked}
            onChange={(value: any) => this.handleGroupChange(value, featureFlagGroup)}
            disabled={groupDisabled || !this.props.isEditing || this.state.loading || this.state.submitting} >
            <Typography.Text strong={true}>
              {featureFlagGroup.name}
            </Typography.Text>
          </Checkbox>

          <Checkbox.Group value={selectedIds} style={{ marginLeft: '30px', marginTop: '5px' }}
            onChange={(checkedValue: any) => this.handleFeatureFlagChange(checkedValue, featureFlagGroup)}
            disabled={groupDisabled || !this.props.isEditing || this.state.loading || this.state.submitting} >
            <Descriptions column={4}>
              {checkBoxOptions.map((callbackfn) => {
                return <Descriptions.Item key={callbackfn.value.toString()}><Checkbox value={callbackfn.value}>{callbackfn.label}</Checkbox></Descriptions.Item>
              })}
            </Descriptions>
          </Checkbox.Group>
        </Space>
      );
    }
  }
}

export default FeatureFlagEditor;
