import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import * as React from 'react';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import Routes from '../../../config/Routes';
import * as SaveNewProgramChangeRequestStep6Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep6Handler';
import * as SubmitNewProgramChangeRequestStep6Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep6Handler';
import * as GetNewProgramChangeRequestStep6Handler from '../../../handlerModels/GetNewProgramChangeRequestStep6Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import NoticeOfIntentChangeRequestDTO from '../../../models/NoticeOfIntentChangeRequestDTO';
import NoticeOfIntentChangeRequestOccupationDTO from '../../../models/NoticeOfIntentChangeRequestOccupationDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FileUploadUtil from '../../../utils/FileUploadUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import FileUpload from '../../FileUpload';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep6FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
  noticeOfIntent: NoticeOfIntentChangeRequestDTO;
}

interface NewProgramChangeRequestStep6FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: () => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep6Form extends React.Component<NewProgramChangeRequestStep6FormProps, NewProgramChangeRequestStep6FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    const externalAccreditationRequirement = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements);
    const formItems = new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestDTO.additionalLaborMarketInformation, {
        name: NoticeOfIntentChangeRequestDTO.additionalLaborMarketInformation,
        label: 'Please provide any additional labor and market information.'
      })
      .set(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements, {
        required: true,
        name: NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements,
        label: 'Are there any external accreditation requirements for this program?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirementDetails, {
        required: externalAccreditationRequirement,
        name: NoticeOfIntentChangeRequestDTO.externalAccreditationRequirementDetails,
        label: 'Please provide any information about the external accreditation requirements.',
        rules: externalAccreditationRequirement ? [ValidationRuleUtil.required()] : []
      })
      .set(SaveNewProgramChangeRequestStep6Handler.Request.industryFiles, {
        name: SaveNewProgramChangeRequestStep6Handler.Request.industryFiles,
        label: 'Please provide any supporting labor market documents or information regarding industry standards and benchmarks.',
      });

    return formItems;
  }

  constructor(props: NewProgramChangeRequestStep6FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      newProgramChangeRequest: NewProgramChangeRequestDTO.create(),
      noticeOfIntent: NoticeOfIntentChangeRequestDTO.create({
        externalAccreditationRequirements: null,
      }),
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      this.loadChangeRequest()
    }
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep6FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {

      if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
        this.loadChangeRequest()
      }

    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep6Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep6(request)
      .then((results: GetNewProgramChangeRequestStep6Handler.Result) => {
        if (results) {

          let noi = results.changeRequest?.noticeOfIntentChangeRequest;

          if (results.changeRequest?.newProgramChangeRequest) {
            noi = NoticeOfIntentChangeRequestDTO.create({ ...results.changeRequest?.newProgramChangeRequest }) ?? NoticeOfIntentChangeRequestDTO.create();
          }

          if (noi == null) {
            noi = NoticeOfIntentChangeRequestDTO.create();
          }

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            noticeOfIntent: noi,
            newProgramChangeRequest: results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private handleChange = (altered: boolean) => {
    this.setState({ altered: altered });
  }

  private handleSave = () => {
    this.setState({ saving: true });
    const noi = this._formRef.current?.getFieldsValue() ?? null;
    let newProgramChangeRequest = this.state.changeRequest.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create();
    newProgramChangeRequest = noi;
    newProgramChangeRequest.changeRequestId = this.state.changeRequest.id;

    const model = SaveNewProgramChangeRequestStep6Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest,
      removedFiles: noi.industryFiles?.removedFiles ?? []
    })

    const request = FileUploadUtil.attachFilesToModel('saveNewProgramChangeRequestStep6', model, SaveNewProgramChangeRequestStep6Handler.Request.industryFiles, noi.industryFiles?.newFiles);

    NewProgramChangeRequestApiService.saveStep6(request)
      .then((result: SaveNewProgramChangeRequestStep6Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const noi = this._formRef.current?.getFieldsValue() ?? null;
    let newProgramChangeRequest = this.state.changeRequest.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create();
    newProgramChangeRequest = noi;
    newProgramChangeRequest.changeRequestId = this.state.changeRequest.id;

    const model = SaveNewProgramChangeRequestStep6Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest,
      removedFiles: noi.industryFiles?.removedFiles ?? []
    })

    const request = FileUploadUtil.attachFilesToModel('submitNewProgramChangeRequestStep6', model, SaveNewProgramChangeRequestStep6Handler.Request.industryFiles, noi.industryFiles?.newFiles);

    NewProgramChangeRequestApiService.submitStep6(request)
      .then((result: SubmitNewProgramChangeRequestStep6Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }


    const existingIndustryResources = this.state.changeRequest?.newProgramChangeRequest?.industryResources?.map(x => {
      return {
        uid: x.azureBlobFileId,
        url: Routes.generateDownload(x.azureBlobFileId ?? Guid.Empty()),
        name: x.azureBlobFile?.fileName
      }
    });


    const formItems = this.getFormItems();

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.noticeOfIntent}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}>
          <Space direction="vertical">
            <Form.Item
              {...formItems.get(NoticeOfIntentChangeRequestDTO.additionalLaborMarketInformation)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.additionalLaborMarketInformation, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
            </Form.Item>

            <Form.Item {...formItems.get(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements)}
              {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
              <YesNoInput disabled={this.props.readonly} />
            </Form.Item>

            {this.renderExternalAccreditationRequirementDetails(formItems)}

            <Form.Item
              {...formItems.get(SaveNewProgramChangeRequestStep6Handler.Request.industryFiles)}
              {...ValidationUtil.getValidation(SaveNewProgramChangeRequestStep6Handler.Request.industryFiles, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <FileUpload disabled={this.props.readonly} existingList={existingIndustryResources} />
            </Form.Item>
            {this.renderActions()}
          </Space>
        </Form >
      </Space >
    );
  }

  private renderActions() {
    if (!this.props.readonly) {
      return < Space direction='horizontal' wrap={true} >
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved} />
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space >
    }
  }

  renderExternalAccreditationRequirementDetails(formItems: Map<string, FormItemProps>) {
    if (this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirements)) {
      return (
        <Form.Item
          {...formItems.get(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirementDetails)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.externalAccreditationRequirementDetails, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
          <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
        </Form.Item >
      );
    }
  }

  renderProfessionalCredentialRequirementDetails(name: string, index: number, formItems: Map<string, FormItemProps>) {
    if (this._formRef.current?.getFieldValue([name, NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirements])) {
      return (
        <Form.Item
          {...formItems.get(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirementDetails)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestOccupationDTO.professionalCredentialRequirementDetails + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
          <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
        </Form.Item>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NewProgramChangeRequestStep6Form