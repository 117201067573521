// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CreditTypeDTO from './CreditTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionCreditTypeMinuteDTO extends EntitySelectorDTO { 
	id: string | null;
	institutionId: string | null;
	creditTypeId: string | null;
	minutes: number;
	creditType: CreditTypeDTO | null;
}

const InstitutionCreditTypeMinuteDTO = {
    className: 'InstitutionCreditTypeMinuteDTO',
    create: (initValues?: Partial<InstitutionCreditTypeMinuteDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			institutionId: '00000000-0000-0000-0000-000000000000',
			creditTypeId: '00000000-0000-0000-0000-000000000000',
			minutes: 0,
			creditType: null,
        },
        initValues);
    },
	id: 'id',
	institutionId: 'institutionId',
	creditTypeId: 'creditTypeId',
	minutes: 'minutes',
	creditType: 'creditType',
};

export default InstitutionCreditTypeMinuteDTO;