import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal, Skeleton, Tabs, Typography } from 'antd';
import modal from 'antd/lib/modal';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RoleApiService from '../../../api/RolesApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import { default as RoleDetails, default as RoleDetailsForm } from '../../../components/forms/RoleDetailsForm';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import RoleActions from '../../../components/RoleActions';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteRoleHandler from '../../../handlerModels/DeleteRoleHandler';
import * as GetRoleDetailsHandler from '../../../handlerModels/GetRoleDetailsHandler';
import RoleDTO from '../../../models/RoleDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ParameterUtil from '../../../utils/ParameterUtil';

interface RoleDetailsState extends BaseFormState {
  roleId: string;
  roleDetails: RoleDTO | null;
  isDeleted: boolean;
  activeTab: string;
}

interface RoleDetailsProps {
}

class RoleDetailsPage extends React.Component<RoleDetailsProps & RouteComponentProps<RouteObject>, RoleDetailsState> {
  private static readonly ROLE_DETAILS = 'ROLE_DETAILS';
  private static readonly ALLOWED_ACTIONS = 'ALLOWED_ACTIONS';

  private readonly _roleDetailsRef = React.createRef<RoleDetails>();
  private readonly _roleActionsRef = React.createRef<RoleActions>();

  constructor(props: RoleDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      activeTab: 'details',
      isDeleted: false,
      roleId: Guid.Empty(),
      roleDetails: null,
      editings: new Map<string, boolean>(),
    };
  }

  componentDidMount() {
    const roleId = ParameterUtil.getPathPart(this.props.match, 'id');
    const activeTab = ParameterUtil.getParameter('activeTab', 'details');
    this.setState({ activeTab: activeTab });

    if (roleId == 'new') {
      this.loadNew();
    }
    else {
      this.loadRole(roleId);
    }
  }

  private loadRole = (roleId: string) => {
    this.setState({ loading: true, roleId: roleId });
    RoleApiService.getRoleDetails(roleId)
      .then((results: GetRoleDetailsHandler.Result) => {
        this.setState({ roleDetails: results.role });
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadNew = () => {
    if (this.state.editings) {
      const editings = this.state.editings;
      editings.set(RoleDetailsPage.ROLE_DETAILS, true);
      this.setState({
        isEditing: true,
        loading: false,
        editings: editings
      });
    }
  }

  private isEditingCheck = () => {
    if (this.state.editings) {
      return Array.from(this.state.editings.values()).includes(true);
    }

    return false;
  };

  private toggleEditing = (key: string, value?: boolean) => {
    if (this.state.editings) {
      const editings = this.state.editings;

      if (value == undefined) {
        value = !editings.get(key) ?? true;
      }

      editings.set(key, value);

      if (!editings.get(key)) {
        switch (key) {
          case RoleDetailsPage.ROLE_DETAILS:
            this._roleDetailsRef.current?.resetForm();
            break;
          case RoleDetailsPage.ALLOWED_ACTIONS:
            this._roleActionsRef.current?.resetForm();
            break;
        }
      }

      this.setState({ editings: editings });
    }
  }

  private roleDetailsSaved = (roleId: string) => {
    this.toggleEditing(RoleDetailsPage.ROLE_DETAILS, false);

    if (roleId != this.state.roleId) {
      HistoryUtil.replace(Routes.generate(Routes.ROLE_DETAILS, { id: roleId }));
    }
    else {
      this.loadRole(roleId);
    }
  }

  private roleActionsSaved = () => {
    this.toggleEditing(RoleDetailsPage.ALLOWED_ACTIONS, false);
  }

  private deleteConfirmation = (id: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Role?',
      okText: 'Delete',
      onOk: () => this.deleteRole(id)
    });
  }

  private cancelDelete = () => {
    this.setState({
      isDeleted: false
    });
  };

  renderCancelRoleRemovalModal() {
    return (
      <Modal
        visible={this.state.isDeleted}
        title='Role Removed Canceled'
        okText='Ok'
        onCancel={this.cancelDelete}
        onOk={this.cancelDelete}>
        <Typography.Text>This Role cannot be deleted because this role is attached to an approval flow.</Typography.Text>
      </Modal>
    );
  }

  private deleteRole = (roleId: string) => {
    this.setState({ submitting: true });

    const request = DeleteRoleHandler.Request.create({
      roleId: roleId,
    });

    RoleApiService.deleteRole(request)
      .then((result: DeleteRoleHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          if (result.isDeleted) {
            message.success('Role was removed.');
            this.goToRoles();
          }
          else {
            modal.error({
              title: 'Not Deleted',
              content: 'Unable to delete this role due to it being used in an active change request approval flow. Please remove it from all approval flows before deleting'
            });
          }

          this.setState({ isDeleted: !result.isDeleted });
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Role could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private goToRoles = () => {
    HistoryUtil.goBack();
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const title = this.state.loading ? '...' : this.state.roleId == Guid.Empty() ? 'New Role' : this.state.roleDetails?.name ?? '...';
    return (
      <div>
        <HeaderPortal
          title={title}
          onBack={this.goToRoles}
          breadcrumbs={Breadcrumbs.roleDetails(title, this.state.roleId)}
          extra={this.renderMoreActions()}
          footer={
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab} >
              <Tabs.TabPane tab="Details" key="details" />
              <Tabs.TabPane tab="Actions" key="actions" />
            </Tabs>
          } />
        <PageStayPrompt when={this.isEditingCheck} />
        {this.renderDetailsCard()}
        {this.renderActionsCard()}
      </div>
    );
  }

  renderDetailsCard() {
    if (this.state.editings) {
      const editButton = this.state.roleId == Guid.Empty() ? null :
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_ROLE]} >
          <EditCancelButton onClick={() => this.toggleEditing(RoleDetailsPage.ROLE_DETAILS)} isEditing={this.state.editings.get(RoleDetailsPage.ROLE_DETAILS)} />
        </AuthorizedContent>

      return (
        <Card title="Details" extra={editButton} hidden={this.state.activeTab != 'details'}>
          <RoleDetailsForm
            ref={this._roleDetailsRef}
            roleId={this.state.roleId}
            isEditing={this.state.editings.get(RoleDetailsPage.ROLE_DETAILS)}
            onSave={this.roleDetailsSaved} />
        </Card>
      );
    }
  }

  renderActionsCard() {
    if (this.state.editings && this.state.roleId != Guid.Empty()) {
      const editButton = this.state.roleId == Guid.Empty() ? null :
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_ROLE]} >
          <EditCancelButton onClick={() => this.toggleEditing(RoleDetailsPage.ALLOWED_ACTIONS)} isEditing={this.state.editings.get(RoleDetailsPage.ALLOWED_ACTIONS)} />
        </AuthorizedContent>

      return (
        <Card title="Actions" extra={editButton} hidden={this.state.activeTab != 'actions'}>
          <RoleActions
            ref={this._roleActionsRef}
            roleId={this.state.roleId}
            isEditing={this.state.editings.get(RoleDetailsPage.ALLOWED_ACTIONS)}
            onSave={this.roleActionsSaved} />
        </Card>
      );
    }
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        <Menu.Item title='Delete Role' key={this.state.roleId} onClick={() => this.deleteConfirmation(this.state.roleId)}  >
          Delete Role
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_ROLE]} >
        <Dropdown overlay={menu}>
          <Button size='large' type='link' icon={<MoreOutlined />} />
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(RoleDetailsPage);
