import { DeleteOutlined, DownloadOutlined, FormOutlined } from '@ant-design/icons';
import { Alert, Button, message, Modal } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import SecondaryProgramApiService from '../../../api/SecondaryProgramApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteSecondaryProgramHandler from '../../../handlerModels/DeleteSecondaryProgramHandler';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import SecondaryProgramDTO from '../../../models/SecondaryProgramDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import SecondaryProgramDetailsModal from '../../modals/SecondaryProgramDetailsModal';
import SecondaryProgramsImporter from '../../secondary/SecondaryProgramsImporter';
import DataTable, { FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface SecondaryProgramsDataTableProps {
  academicYear: number;
  fromSelfStudies?: boolean;
  fromAcademicYearDataReview?: boolean;
  isPublic?: boolean;
  districtId?: string;
  errors?: string[];
  configuration?: boolean;
}

function SecondaryProgramsDataTable(props: SecondaryProgramsDataTableProps) {
  let _dataTable: DataTable<SecondaryProgramDTO> | undefined = undefined;
  const _secondaryProgramImporterRef = React.createRef<SecondaryProgramsImporter>();

  const [secondaryProgramId, setSecondaryProgramId] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(props.errors);
  const [rpps, setRPPs] = useState<RegionalPlanningPartnerDTO[]>([]);
  const [institutions, setInstitutions] = useState([] as TableFilterOption[]);

  useEffect(() => {
    if (!isNaN(props.academicYear)) {
      RegionalPlanningPartnerApiService.getRegionalPlanningPartners(props.academicYear)
        .then(x => {
          setRPPs(x.regions ?? []);
        });
      TableFilterUtil.Institutions()
        .then(x => {
          setInstitutions(x);
        });
    }
  }, [props.academicYear]);


  const getColumnDefinitions = () => {
    const columns: any = [];
    if (props.isPublic) {
      columns.pushAll([
        {
          title: 'CIP Number',
          dataIndex: SecondaryProgramDTO.cipNumberId,
          sorter: true,
          width: 50,
          render: (data: string, row: SecondaryProgramDTO) => {
            return row.secondaryCipNumber?.code + ' - ' + row.secondaryCipNumber?.description;
          },
          filterType: FilterType.Text
        }]);
    }
    else {
      columns.pushAll([
        {
          title: 'Identifier',
          dataIndex: SecondaryProgramDTO.secondaryProgramId,
          sorter: true,
          width: 50,
          filterType: FilterType.Text
        },
        {
          title: 'Title',
          dataIndex: SecondaryProgramDTO.secondaryCipNumber,
          sorter: true,
          render: (data: string, row: SecondaryProgramDTO) => {
            return row.secondaryCipNumber?.description;
          },
          filterType: FilterType.Text
        },
        {
          title: 'District',
          dataIndex: SecondaryProgramDTO.districtId,
          sorter: true,
          render: (data: string, row: SecondaryProgramDTO) => {
            return row.district?.name;
          },
          filterType: FilterType.Text
        },
        {
          title: 'RPP',
          dataIndex: DistrictDTO.regionIdentifier,
          sorter: true,
          width: 250,
          filterType: FilterType.DropdownMulti,
          dropdownFilterOptions: rpps.map(c => { return { text: c.regionalPlanningPartnerIdentifier + ' - ' + c.name, value: c.regionalPlanningPartnerIdentifier.toString() } }) as TableFilterOption[],
          render: (data: string, row: SecondaryProgramDTO) => {
            if (rpps.length > 0) {
              const sa = rpps.filter((x: RegionalPlanningPartnerDTO) => x.regionalPlanningPartnerIdentifier === row.district?.regionIdentifier);
              return sa[0]?.display ?? ''
            }

            return '';
          },
        },
        {
          title: 'Community College',
          dataIndex: RegionalPlanningPartnerDTO.institutionId,
          sorter: true,
          render: (data: string, row: SecondaryProgramDTO) => {
            return row.district?.region?.institution?.code + ' - ' + row.district?.region?.institution?.name;
          },
          filterType: FilterType.DropdownMulti,
          dropdownFilterOptions: institutions
        },
        {
          title: 'CIP Number',
          dataIndex: SecondaryProgramDTO.cipNumberId,
          sorter: true,
          render: (data: string, row: SecondaryProgramDTO) => {
            return row.secondaryCipNumber?.code;
          },
          filterType: FilterType.Text
        }
      ]);

      if (props.fromAcademicYearDataReview) {
        columns.push({
          title: 'Actions',
          align: 'center',
          dataIndex: SecondaryProgramDTO.secondaryProgramId,
          sorter: false,
          render: (data: string, row: SecondaryProgramDTO) => {
            if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
              return <>
                <Button type="link" onClick={() => openSecondaryProgramDetailModal(row)}><FormOutlined /></Button>
                <Button type="link" onClick={() => promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
              </>
            }
          },
          width: 50
        });
      }
    }

    return columns;
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openSecondaryProgramDetailModal = (record: SecondaryProgramDTO) => {
    if (record.id) {
      setSecondaryProgramId(record.id);
    }
  }

  const openSecondaryProgramDetail = (record: SecondaryProgramDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: record.id }, { academicYear: props.academicYear, districtId: props.districtId ?? Guid.Empty(), configuration: props.configuration }));
    }
  }

  const addSecondaryProgram = () => {
    setSecondaryProgramId(Guid.Empty());
  }

  const closeSecondaryProgramDetail = () => {
    setSecondaryProgramId(null);
  }

  const promptConfirmRemove = (secondaryProgramId: string | null) => {
    if (secondaryProgramId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this Program?',
      okText: 'Delete',
      onOk: () => deleteSecondaryProgram(secondaryProgramId, props.academicYear),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteSecondaryProgram = (secondaryProgramId: string, academicYear: number) => {
    const request = DeleteSecondaryProgramHandler.Request.create({
      secondaryProgramId: secondaryProgramId,
      academicYearId: academicYear
    });

    SecondaryProgramApiService.deleteSecondaryProgram(request)
      .then((result: DeleteSecondaryProgramHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Program was removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Program could not be removed.');
      });
  }

  const openModal = () => {
    setLoading(true);
    Modal.confirm({
      title: 'Import Programs',
      content: <SecondaryProgramsImporter
        ref={_secondaryProgramImporterRef}
        academicYear={props.academicYear}
        refreshDataTable={_dataTable}
        isInModal={true}
        onSubmit={modalSubmit} />,
      onCancel: () => { setLoading(false); },
      onOk: () => _secondaryProgramImporterRef.current?.confirmImport(),
      width: 500
    });
  }

  const modalSubmit = (errors?: string[]) => {
    setLoading(false);
    setErrors(errors);
  }

  const downloadTemplate = () => {
    window.open(Routes.generateFull(Routes.SECONDARY_PROGRAM_IMPORT_TEMPLATE, {}, {}), '_blank')
  }

  const actionButtons = [];
  actionButtons.push(DataTableButtonUtil.Reset());

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_PROGRAM]) && !props.districtId) {
    actionButtons.push(DataTableButtonUtil.Default('New Program', () => addSecondaryProgram()));
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_PROGRAM]) && !props.districtId && props.fromAcademicYearDataReview) {
    actionButtons.push(DataTableButtonUtil.Default('Re-import Programs', () => openModal(), undefined, loading));
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_PROGRAM]) && props.fromAcademicYearDataReview) {
    actionButtons.push(DataTableButtonUtil.Default('Template', () => downloadTemplate(), <DownloadOutlined />, loading));
  }

  const renderModal = () => {
    if (secondaryProgramId) {
      return <SecondaryProgramDetailsModal
        onCancel={closeSecondaryProgramDetail}
        visible={true}
        secondaryProgramId={secondaryProgramId}
        academicYear={props.academicYear}
        onSave={closeSecondaryProgramDetail} />
    }
  }

  const renderErrors = () => {
    if (errors && errors.length > 0) {
      return <Alert showIcon={true} type='error' description={renderErrorMessages()} message='Error Importing' />
    }
  }

  const renderErrorMessages = () => {
    if (errors && errors.length > 0) {
      return <>{errors.map((e: any) => <p key={e}>{e}</p>)}</>
    }
  }

  return (
    <>
      {renderErrors()}
      {renderModal()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        onRowClick={props.fromAcademicYearDataReview ? undefined : openSecondaryProgramDetail}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => SecondaryProgramApiService.getSecondaryProgramTableData(requestState, props.academicYear, props.isPublic ?? false, props.districtId ?? Guid.Empty())
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'secondary_program_list' }} />
    </>
  );
}

export default SecondaryProgramsDataTable;
