import { Skeleton, Tabs } from 'antd';
import InstitutionUserIsInDataTable from '../datatables/postSecondary/InstitutionsForUserDataTable';
import DistrictsForUserDataTable from '../datatables/secondary/DistrictUsersDataTable';
import UserRegionalPlanningPartnerDataTable from '../datatables/secondary/UserRegionalPlanningPartnerDataTable';
import * as React from 'react';

interface OrganizationDisplayProps {
  userId: string;
  subTab?: string;
  onChangeTab?: (tabName: string) => void;
}

function OrganizationDisplay(props: OrganizationDisplayProps) {
  if (props.userId) {
    return (
      <Tabs activeKey={props.subTab} onChange={props.onChangeTab}>
        <Tabs.TabPane key='districts' tab='Districts' >
          <DistrictsForUserDataTable userId={props.userId} />
        </Tabs.TabPane>

        <Tabs.TabPane key='communityColleges' tab='Community Colleges' >
          <InstitutionUserIsInDataTable userId={props.userId} />
        </Tabs.TabPane>

        <Tabs.TabPane key='rpps' tab='RPPs' >
          <UserRegionalPlanningPartnerDataTable userId={props.userId} />
        </Tabs.TabPane>
      </Tabs>
    );
  }

  return <Skeleton />;
}

export default OrganizationDisplay
