import { Card, Space, Tabs } from 'antd';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ResourceFileDataTable from '../../components/datatables/postSecondary/ResourceFileDataTable';
import ResourceLinkDataTable from '../../components/datatables/postSecondary/ResourceLinkDataTable';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import HeaderPortal from '../../portals/HeaderPortal';
import HistoryUtil from '../../utils/HistoryUtil';

interface AdminResourceProps {
}

interface AdminResourceState {
  activeTab: string;
}

class AdminResourcePage extends React.Component<AdminResourceProps & RouteComponentProps<RouteObject>, AdminResourceState> {
  constructor(props: AdminResourceProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      activeTab: 'links',
    };
  }

  private goHome = () => {
    HistoryUtil.push(Routes.generate(Routes.POST_SECONDARY_HOME));
  }

  private tabChanged = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
  }

  render() {

    return (
      <Space direction='vertical'>

        <HeaderPortal title="Resources" onBack={this.goHome} breadcrumbs={Breadcrumbs.adminResources()}
          footer={<Tabs onChange={this.tabChanged} >
            <Tabs.TabPane key="links" tab="Links" />
            <Tabs.TabPane key="files" tab="Files" />
          </Tabs>} />

        {this.renderLinks()}
        {this.renderFiles()}
      </Space>
    );
  }

  renderLinks() {
    if (this.state.activeTab === 'links') {
      return <Card title='Links'>
        <ResourceLinkDataTable />
      </Card>
    }
  }

  renderFiles() {
    if (this.state.activeTab === 'files') {
      return <Card title='Files'>
        <ResourceFileDataTable />
      </Card>
    }
  }
}

export default withRouter(AdminResourcePage);
