// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CipNumberDTO from './CipNumberDTO';
import YearTermDTO from './YearTermDTO';
import IowaVariationDTO from './IowaVariationDTO';
import UserDTO from './UserDTO';
import ProgramDTO from './ProgramDTO';
import ProgramAwardDTO from './ProgramAwardDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramDetailDTO extends EntitySelectorDTO {
  id: string | null;
  programId: string | null;
  cipNumberId: string | null;
  cipNumber: CipNumberDTO | null;
  display: string | null;
  install: YearTermDTO | null;
  retire: YearTermDTO | null;
  iowaVariationId: number | null | null;
  iowaVariation: IowaVariationDTO | null;
  title: string | null;
  programMarketingLink: string | null;
  isTransferMajor: boolean;
  submittedOn: moment.Moment | string | null;
  approvedByAdvisoryBoard: moment.Moment | string | null;
  approvedByDOE: moment.Moment | string | null;
  curriculumApprovalProcessDateOfApproval: moment.Moment | string | null;
  submittingUser: UserDTO | null;
  submittingUserId: string | null | null;
  program: ProgramDTO | null;
  awards: ProgramAwardDTO[] | null;
  versionDisplay: string | null;
}

const ProgramDetailDTO = {
  className: 'ProgramDetailDTO',
  create: (initValues?: Partial<ProgramDetailDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
      {
        id: '00000000-0000-0000-0000-000000000000',
        programId: '00000000-0000-0000-0000-000000000000',
        cipNumberId: '00000000-0000-0000-0000-000000000000',
        cipNumber: null,
        display: "",
        install: null,
        retire: null,
        iowaVariationId: null,
        iowaVariation: null,
        title: "",
        programMarketingLink: "",
        isTransferMajor: false,
        submittedOn: null,
        approvedByAdvisoryBoard: null,
        approvedByDOE: null,
        curriculumApprovalProcessDateOfApproval: null,
        submittingUser: null,
        submittingUserId: null,
        program: null,
        awards: [],
        versionDisplay: "",
      },
      initValues);
  },
  id: 'id',
  programId: 'programId',
  cipNumberId: 'cipNumberId',
  cipNumber: 'cipNumber',
  display: 'display',
  install: 'install',
  retire: 'retire',
  iowaVariationId: 'iowaVariationId',
  iowaVariation: 'iowaVariation',
  title: 'title',
  programMarketingLink: 'programMarketingLink',
  isTransferMajor: 'isTransferMajor',
  submittedOn: 'submittedOn',
  approvedByAdvisoryBoard: 'approvedByAdvisoryBoard',
  approvedByDOE: 'approvedByDOE',
  curriculumApprovalProcessDateOfApproval: 'curriculumApprovalProcessDateOfApproval',
  submittingUser: 'submittingUser',
  submittingUserId: 'submittingUserId',
  program: 'program',
  awards: 'awards',
  versionDisplay: 'versionDisplay',
};

export default ProgramDetailDTO;