import moment from 'moment';
import DateTimeUtil from './DateTimeUtil';
export default class AnnouncementUtil {
  public static getAnnouncementStatus(showOn: string | moment.Moment | undefined, showUntil: string | moment.Moment | undefined) {
    if (DateTimeUtil.isWithinRange(showOn, showUntil)) {
      return 'Active';
    } else if (DateTimeUtil.isBeforeRange(showOn)) {
      return 'Upcoming';
    } else {
      return 'Shown';
    }
  }
}