import { MoreOutlined } from '@ant-design/icons';
import * as antd from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import AreaEducationAgencyApiService from '../../../api/AreaEducationAgencyApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import AreaEducationAgencyDetailsForm from '../../../components/forms/secondary/AreaEducationAgencyDetailsForm';
import Dropdown from '../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteAreaEducationAgencyHandler from '../../../handlerModels/DeleteAreaEducationAgencyHandler';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import * as GetAreaEducationAgencyDetailsHandler from '../../../handlerModels/GetAreaEducationAgencyDetailsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import AreaEducationAgencyDTO from '../../../models/AreaEducationAgencyDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface AreaEducationAgencyDetailsPageState extends BaseFormState {
  id: string;
  areaEducationAgencyId: number;
  areaEducationAgency?: AreaEducationAgencyDTO;
  academicYears: AcademicYearDTO[];
  academicYearId: number;
  fromAcademicYearList: boolean;
  fromSelfStudies: boolean;
  fromDataReview: boolean;
  reviewAcademicYear: boolean;
}

interface AreaEducationAgencyDetailsPageProps {
}

class AreaEducationAgencyDetailsPage extends React.Component<AreaEducationAgencyDetailsPageProps & RouteComponentProps<RouteObject>, AreaEducationAgencyDetailsPageState> {
  private readonly _areaEducationAgencyDetailsRef = React.createRef<AreaEducationAgencyDetailsForm>();

  constructor(props: AreaEducationAgencyDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      id: Guid.Empty(),
      areaEducationAgencyId: 0,
      academicYears: [],
      academicYearId: 0,
      fromAcademicYearList: false,
      fromSelfStudies: false,
      fromDataReview: false,
      reviewAcademicYear: false
    };
  }

  componentDidMount() {
    const areaEducationAgencyId = ParameterUtil.getPathPart(this.props.match, 'id');
    const academicYearId = ParameterUtil.getParameter('academicYearId');
    const fromAcademicYearList = ParameterUtil.getParameter('fromAcademicYearList');
    const fromSelfStudies = ParameterUtil.getParameter('fromSelfStudies');
    const fromDataReview = ParameterUtil.getParameter('fromDataReview');
    const reviewAcademicYear = ParameterUtil.getParameter('reviewAcademicYear');
    this.setState({
      academicYearId: Number.parseInt(academicYearId),
      fromSelfStudies: fromSelfStudies == 'true',
      fromAcademicYearList: fromAcademicYearList == 'true',
      fromDataReview: fromDataReview == 'true',
      reviewAcademicYear: reviewAcademicYear == 'true'
    });

    if (this.props.match.path.split('/').findIndex(x => x == 'new') > -1) {
      this.setState({ isEditing: true, isNew: true, loading: false });
      const loaders = [];
      loaders.push(this.loadAcademicYears());
      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
    else {
      const loaders = [];
      loaders.push(this.loadAreaEducationAgency(areaEducationAgencyId));
      loaders.push(this.loadAcademicYears());

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  private loadAreaEducationAgency = (id: string, academicYear?: any, areaEducationAgencyId?: any) => {
    this.setState({ id: id });
    const request = GetAreaEducationAgencyDetailsHandler.Request.create({
      id: id,
      academicYear: academicYear,
      areaEducationAgencyId: areaEducationAgencyId
    })

    AreaEducationAgencyApiService.getAreaEducationAgencyDetails(request)
      .then((results: GetAreaEducationAgencyDetailsHandler.Result) => {
        if (results.areaEducationAgency) {
          this.setState({
            areaEducationAgency: results.areaEducationAgency,
            id: results.areaEducationAgency.id ?? Guid.Empty(),
            areaEducationAgencyId: results.areaEducationAgency.areaEducationAgencyIdentifier,
            isNew: false,
            isEditing: false
          });
        }
        else {
          this.setState({
            isNew: true,
            id: Guid.Empty(),
            areaEducationAgency: undefined,
            isEditing: true
          })
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAcademicYears = () => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._areaEducationAgencyDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private areaEducationAgencyDetailsPageSaved = (areaEducationAgencyId: string) => {
    this.setState({ isEditing: false, isNew: false });
    if (areaEducationAgencyId != this.state.id) {
      HistoryUtil.push(Routes.generate(Routes.AREA_EDUCATION_AGENCY_DETAILS, { id: areaEducationAgencyId }, { academicYearId: this.state.academicYearId, fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList, reviewAcademicYear: this.state.reviewAcademicYear, fromDataReview: this.state.fromDataReview }));
      this.loadAreaEducationAgency(areaEducationAgencyId);
    }
  }

  private deleteConfirmation = (id: string, academicYearId: number) => {
    antd.Modal.confirm({
      title: 'Are you sure you want to delete this Area Education Agency?',
      okText: 'Delete',
      onOk: () => this.deleteAreaEducationAgency(id, academicYearId)
    });
  }

  private deleteAreaEducationAgency = (areaEducationAgencyId: string, academicYearId: number) => {
    this.setState({ submitting: true });

    const request = DeleteAreaEducationAgencyHandler.Request.create({
      areaEducationAgencyId: areaEducationAgencyId,
      academicYearId: academicYearId
    });

    AreaEducationAgencyApiService.deleteAreaEducationAgency(request)
      .then((result: DeleteAreaEducationAgencyHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          antd.message.success('Area Education Agency was removed.');
        }
        else {
          antd.message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        antd.message.error('Area Education Agency could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToListing();
      });
  }

  goToListing = () => {
    if (this.state.reviewAcademicYear) {
      HistoryUtil.push(Routes.generate(Routes.REVIEW_AREA_EDUCATION_AGENCY, { academicYearId: this.state.academicYearId }, { fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList, reviewAcademicYear: true }));
    }
    else if (this.state.fromDataReview) {
      HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_AREA_EDUCATION_AGENCY, { academicYearId: this.state.academicYearId }, { fromAcademicYearList: this.state.fromAcademicYearList }));
    }
    else if (this.state.fromSelfStudies) {
      HistoryUtil.push(Routes.generate(Routes.AREA_EDUCATION_AGENCIES, { academicYearId: this.state.academicYearId }, { fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList }));
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.ALL_AREA_EDUCATION_AGENCIES));
    }

  }

  private onSelect = (value: any) => {
    this.setState({ academicYearId: value });
    this.loadAreaEducationAgency(this.state.id, value, this.state.areaEducationAgencyId);
  }

  render() {
    const title = this.state.loading ? '...' : this.state.isNew ? 'New Area Education Agency' : this.state.areaEducationAgency?.name ?? '...';
    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToListing}
          breadcrumbs={Breadcrumbs.areaEducationAgencyDetailPage(title, this.state.id, this.state.academicYearId, this.state.fromAcademicYearList, this.state.fromSelfStudies, this.state.reviewAcademicYear)}
          extra={this.renderMoreActions()}
        />

        {this.renderDetailsCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    const editButton = this.state.id == Guid.Empty() ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_AREA_EDUCATION_AGENCY]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <antd.Card title="Details" extra={editButton} >
        <AreaEducationAgencyDetailsForm
          ref={this._areaEducationAgencyDetailsRef}
          areaEducationAgencyId={this.state.id}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.areaEducationAgencyDetailsPageSaved}
          academicYear={this.state.academicYearId} />
      </antd.Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <antd.Menu>
        < antd.Menu.Item title='Delete Area Education Agency' key={this.state.id} onClick={() => this.deleteConfirmation(this.state.id, this.state.academicYearId)}  >
          Delete Area Education Agency
        </antd.Menu.Item>
      </antd.Menu>
    );

    return (
      <>
        {this.state.reviewAcademicYear ? null : <Dropdown value={this.state.academicYearId} onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.academicYears.map(x => { return this.renderAcademicYear(x); })}</Dropdown>}

        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_AREA_EDUCATION_AGENCY]}>
          {this.state.isNew ? null
            : <antd.Dropdown overlay={menu}>
              <antd.Button size='large' type='link' >
                <MoreOutlined />
              </antd.Button>
            </antd.Dropdown>}
        </AuthorizedContent>
      </>
    );
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <antd.Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</antd.Select.Option>
  }
}

export default withRouter(AreaEducationAgencyDetailsPage);
