import { DeleteFilled, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, InputNumber, message, Modal, Popover, Select, Skeleton, Space, Table, Tooltip } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import GroupOptionType from '../../consts/GroupOptionType';
import APSGeneralEducationDTO from '../../models/APSGeneralEducationDTO';
import CourseDTO from '../../models/CourseDTO';
import FlatProgramModificationProgramTermCourseGroupOptionDTO from '../../models/FlatProgramModificationProgramTermCourseGroupOptionDTO';
import FlatProgramTermCourseGroupOptionDTO from '../../models/FlatProgramTermCourseGroupOptionDTO';
import GeneralEducationDTO from '../../models/GeneralEducationDTO';
import GroupOptionTypeDTO from '../../models/GroupOptionTypeDTO';
import InstitutionCourseBlockDTO from '../../models/InstitutionCourseBlockDTO';
import InstitutionCourseDTO from '../../models/InstitutionCourseDTO';
import NewProgramProgramAwardDTO from '../../models/NewProgramProgramAwardDTO';
import ProgramAwardDTO from '../../models/ProgramAwardDTO';
import ProgramModificationProgramAwardDTO from '../../models/ProgramModificationProgramAwardDTO';
import ProgramModificationProgramTermDTO from '../../models/ProgramModificationProgramTermDTO';
import ProgramTermCourseGroupDTO from '../../models/ProgramTermCourseGroupDTO';
import ProgramTermCourseGroupOptionDTO from '../../models/ProgramTermCourseGroupOptionDTO';
import ProgramTermDTO from '../../models/ProgramTermDTO';
import TransferMajorCourseBlockDTO from '../../models/TransferMajorCourseBlockDTO';
import TransferMajorDisciplineFrameworkElementDTO from '../../models/TransferMajorDisciplineFrameworkElementDTO';
import TransferMajorGeneralEducationCategoryDTO from '../../models/TransferMajorGeneralEducationCategoryDTO';
import TransferMajorGeneralEducationTypeDTO from '../../models/TransferMajorGeneralEducationTypeDTO';
import TransferMajorLactsCategoryDTO from '../../models/TransferMajorLactsCategoryDTO';
import WorkBasedLearningTypeDTO from '../../models/WorkBasedLearningTypeDTO';
import YearTermDTO from '../../models/YearTermDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import Guid from '../../utils/Guid';
import LookupsUtil from '../../utils/LookupsUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import DisciplineFrameworkCourseSearch from '../DisciplineFrameworkCourseSearch';
import AddFootnoteModal from '../forms/postSecondary/AddFootnoteModal';
import SearchForCourseBlock from '../forms/postSecondary/SearchForCourseBlock';
import Dropdown from '../inputs/Dropdown';
import YesNoInput from '../inputs/YesNoInput';
import InstitutionCourseSearch from '../InstitutionCourseSearch';
import MinMaxInput from '../MinMaxInput';
import CourseSearch from './CourseSearch';

export interface ProgramTermEditorValue {
  id: string | null,
  order: number,
  prerequisite: boolean,
  groups: ProgramTermCourseGroupDTO[] | null,
  flatCourseGroupOptions: FlatProgramTermCourseGroupOptionDTO[] | null,
}

interface ProgramTermEditorState extends BaseFormState {
  apsGeneralEducationType: APSGeneralEducationDTO[];
  groupOptionTypes: GroupOptionTypeDTO[];
  generalEducationTypes: GeneralEducationDTO[];
  transferMajorCourseBlocks: TransferMajorCourseBlockDTO[];
  transferMajorLactsCategories: TransferMajorLactsCategoryDTO[];
  otherTerms: ProgramTermDTO[];
  value: ProgramTermEditorValue;
  disciplineFrameworkElement: TransferMajorDisciplineFrameworkElementDTO[];
  generalEducationCategory: TransferMajorGeneralEducationCategoryDTO[];
  programAward: ProgramAwardDTO | ProgramModificationProgramAwardDTO | NewProgramProgramAwardDTO;
  totalTermCredits: number;
  totalTermTechCoreCredits: number;
  workBasedLearningTypes: WorkBasedLearningTypeDTO[];
  showAddItemPopover: string | null;
}

interface ProgramTermEditorProps extends BaseFormProps {
  selectedInstitution: string | null;
  value?: ProgramTermEditorValue;
  programAward?: ProgramAwardDTO | ProgramModificationProgramAwardDTO | NewProgramProgramAwardDTO;
  onOptionChange?: (term: ProgramTermEditorValue) => void;
  onChange?: (value: ProgramTermEditorValue, optionIndex?: number) => void;
  onCreditsChanged?: (termOrder: number, termCredits: number, termTechCoreCredits: number, awardId?: string) => void;
  onTermLoaded?: (termId: string) => void;
  submitted?: boolean;
  transferMajor?: boolean;
  readonly?: boolean;
  termIndex?: number;
  programDisplay?: boolean;
  prerequisite?: boolean;
  installTerm?: YearTermDTO;
}

class ProgramTermEditor extends React.Component<ProgramTermEditorProps, ProgramTermEditorState> {
  private readonly _addCourseFormRef = React.createRef<CourseSearch>();
  private readonly _addInstitutionCourseFormRef = React.createRef<InstitutionCourseSearch>();
  private readonly _disciplineFrameworkCourseSearchRef = React.createRef<any>();
  private readonly _addCourseBlockFormRef = React.createRef<SearchForCourseBlock>();
  private readonly _footnoteFormRef = React.createRef<AddFootnoteModal>();
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _tableRef = React.createRef<any>();

  constructor(props: ProgramTermEditorProps) {
    super(props);

    this.state = {
      apsGeneralEducationType: [],
      value: ProgramModificationProgramTermDTO.create(),
      programAward: ProgramAwardDTO.create(),
      groupOptionTypes: [],
      generalEducationTypes: [],
      transferMajorCourseBlocks: [],
      transferMajorLactsCategories: [],
      otherTerms: [],
      disciplineFrameworkElement: [],
      generalEducationCategory: [],
      totalTermCredits: 0,
      totalTermTechCoreCredits: 0,
      workBasedLearningTypes: [],
      showAddItemPopover: ''
    };
  }

  componentDidMount() {
    if (this.props.value && this.props.value != this.state.value) {
      this.setState({
        value: this.props.value,
        programAward: this.props.programAward ?? ProgramAwardDTO.create(),
        fieldErrors: this.props.fieldErrors,
        submitted: this.props.submitted,
      }, () => this.resetForm());
      const values = {};
      values[ProgramModificationProgramTermDTO.flatCourseGroupOptions] = this.props.value.flatCourseGroupOptions;
      this._formRef.current?.setFieldsValue(values);
      if (this._tableRef.current) {
        const table = this._tableRef.current.querySelector('table');
        table.tabIndex = 0;
      }

      this.setState({
        fieldErrors: this.props.fieldErrors,
        submitted: this.props.submitted,
      });
      this.fetchData();

    }
  }

  componentDidUpdate(prevProps: ProgramTermEditorProps) {
    if (this.props.value && this.props.value.flatCourseGroupOptions != prevProps.value?.flatCourseGroupOptions) {
      this.fetchData();
      this.handleCreditsChange();
      this.setState({
        value: this.props.value,
        programAward: this.props.programAward ?? ProgramAwardDTO.create(),
        fieldErrors: this.props.fieldErrors,
        submitted: this.props.submitted,
      }, () => this.resetForm());
      const values = {};

      values[ProgramModificationProgramTermDTO.flatCourseGroupOptions] = this.props.value.flatCourseGroupOptions;
      this._formRef.current?.setFieldsValue(values);
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }

    if (this.props.programAward != prevProps.programAward) {
      this.setState({ programAward: this.props.programAward ?? ProgramAwardDTO.create() });
    }

    if (this.props.fieldErrors != prevProps.fieldErrors || this.props.submitted != prevProps.submitted) {
      this.setState({
        fieldErrors: this.props.fieldErrors,
        submitted: this.props.submitted
      });
    }

    if (this._tableRef.current) {
      const table = this._tableRef.current.querySelector('table');
      table.tabIndex = 0;
    }
  }

  public resetForm = () => {
    this.setState({ altered: false });
    this._formRef.current?.resetFields();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.groupOptionTypes || this.state.groupOptionTypes.length == 0) {
      loaders.push(this.loadGroupOptionTypes());
    }

    if (!this.state.transferMajorCourseBlocks || this.state.transferMajorCourseBlocks.length == 0) {
      loaders.push(this.loadTransferMajorCourseBlock());
    }

    if (!this.state.transferMajorLactsCategories || this.state.transferMajorLactsCategories.length == 0) {
      loaders.push(this.loadTransferMajorLactsCategories());
    }

    if (!this.state.generalEducationTypes || this.state.generalEducationTypes.length == 0) {
      loaders.push(this.loadGeneralEducationType());
    }

    if (!this.state.apsGeneralEducationType || this.state.apsGeneralEducationType.length == 0) {
      loaders.push(this.loadAPSGeneralEducationType());
    }

    if (!this.state.generalEducationCategory || this.state.generalEducationCategory.length == 0) {
      loaders.push(this.loadTransferMajorGeneralEducationCategory());
    }

    if (!this.state.disciplineFrameworkElement || this.state.disciplineFrameworkElement.length == 0) {
      loaders.push(this.loadTransferMajorDisciplineFrameworkElement());
    }

    if (!this.state.workBasedLearningTypes || this.state.workBasedLearningTypes.length == 0) {
      loaders.push(this.loadWorkBasedLearningType());
    }

    Promise.all(loaders).then(() => {
      this.handleCreditsChange();
      this.setState({ loading: false });
    });
  }

  private loadGroupOptionTypes = () => {
    return LookupsUtil.getAll<GroupOptionTypeDTO>(GroupOptionTypeDTO.className)
      .then((results: GroupOptionTypeDTO[]) => {
        if (results) {
          this.setState({ groupOptionTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTransferMajorCourseBlock = () => {
    return LookupsUtil.getAll<TransferMajorCourseBlockDTO>(TransferMajorCourseBlockDTO.className)
      .then((results: TransferMajorCourseBlockDTO[]) => {
        if (results) {
          this.setState({ transferMajorCourseBlocks: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTransferMajorLactsCategories = () => {
    return LookupsUtil.getAll<TransferMajorLactsCategoryDTO>(TransferMajorLactsCategoryDTO.className)
      .then((results: TransferMajorLactsCategoryDTO[]) => {
        if (results) {
          this.setState({ transferMajorLactsCategories: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTransferMajorGeneralEducationCategory = () => {
    return LookupsUtil.getAll<TransferMajorGeneralEducationTypeDTO>(TransferMajorGeneralEducationTypeDTO.className)
      .then((results: TransferMajorGeneralEducationTypeDTO[]) => {
        if (results) {
          this.setState({ generalEducationCategory: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTransferMajorDisciplineFrameworkElement = () => {
    return LookupsUtil.getAll<TransferMajorDisciplineFrameworkElementDTO>(TransferMajorDisciplineFrameworkElementDTO.className)
      .then((results: TransferMajorDisciplineFrameworkElementDTO[]) => {
        if (results) {
          this.setState({ disciplineFrameworkElement: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadGeneralEducationType = () => {
    return LookupsUtil.getAll<GeneralEducationDTO>(GeneralEducationDTO.className)
      .then((results: GeneralEducationDTO[]) => {
        if (results) {
          this.setState({ generalEducationTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadWorkBasedLearningType = () => {
    return LookupsUtil.getAll<WorkBasedLearningTypeDTO>(WorkBasedLearningTypeDTO.className)
      .then((results: WorkBasedLearningTypeDTO[]) => {
        if (results) {
          this.setState({ workBasedLearningTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAPSGeneralEducationType = () => {
    return LookupsUtil.getAll<APSGeneralEducationDTO>(APSGeneralEducationDTO.className)
      .then((results: APSGeneralEducationDTO[]) => {
        if (results) {
          this.setState({ apsGeneralEducationType: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }


  private getProgramTermFormItems = (programTermGroup: any) => {
    return new Map<string, FormItemProps>()
      .set(ProgramTermCourseGroupOptionDTO.courseId, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.courseId],
      })
      .set(ProgramTermCourseGroupOptionDTO.courseBlockId, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.courseBlockId],
      })
      .set(ProgramTermCourseGroupOptionDTO.credits, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.credits],
      })
      .set(ProgramTermCourseGroupOptionDTO.groupOptionTypeId, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.groupOptionTypeId],
      })
      .set(ProgramTermCourseGroupOptionDTO.generalEducationId, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.generalEducationId],
      })
      .set(ProgramTermCourseGroupOptionDTO.workBasedLearningTypeId, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.workBasedLearningTypeId],
      })
      .set(ProgramTermCourseGroupOptionDTO.apsGeneralEducationId, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.apsGeneralEducationId],
      })
      .set(ProgramTermCourseGroupOptionDTO.techCore, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.techCore],
      })
      .set(ProgramTermCourseGroupOptionDTO.transferMajorLactsCategoryId, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.transferMajorLactsCategoryId],
      })
      .set(ProgramTermCourseGroupOptionDTO.transferMajorDisciplineFrameworkElementId, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.transferMajorDisciplineFrameworkElementId],
      })
      .set(ProgramTermCourseGroupOptionDTO.transferMajorGeneralEducationTypeId, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.transferMajorGeneralEducationTypeId],
      })
      .set(ProgramTermCourseGroupOptionDTO.embeddedCredits, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.embeddedCredits],
      })
      .set(ProgramTermCourseGroupOptionDTO.footnote, {
        name: [programTermGroup, ProgramTermCourseGroupOptionDTO.footnote],
      })
  }

  private getProgramTermsFormItems = (programTermGroup?: any) => {
    return new Map<string, FormItemProps>()
      .set(ProgramModificationProgramTermDTO.informationalContent, {
        label: 'Additional Information',
        name: [programTermGroup, ProgramModificationProgramTermDTO.informationalContent],
      })

  }

  private getOption = (index: number) => {
    let options = this._formRef.current == null ? this.state.value.flatCourseGroupOptions : this._formRef.current?.getFieldsValue().flatCourseGroupOptions;
    if (this.props.readonly && !this.props.programDisplay) {
      options = options.filter((x: any) => x.courseId != null || x.courseBlockId != null);
    }

    if (options?.length > 0) {
      return options[index] ?? null;
    }
  }

  private getMinMax = (option: FlatProgramModificationProgramTermCourseGroupOptionDTO) => {
    const institutionCourse: any = option.course;

    const isVariable = institutionCourse?.variableHours == undefined ? option.isVariableCredits : institutionCourse?.variableHours;

    return { min: option.credits?.min ?? 0, max: option.credits?.max ?? 0, isVariable: isVariable }
  }

  private editCourseModal(groupIndex: number, row: number) {
    const form = this._formRef.current;
    const term = form?.getFieldsValue();
    const option = term.flatCourseGroupOptions[row];
    const course = option.course;
    Modal.confirm({
      title: 'Select Course',
      content: <InstitutionCourseSearch existingCourseId={option.courseId} value={{ institutionCourseId: course.institutionCourseId }} ref={this._addInstitutionCourseFormRef} institutionId={this.props.selectedInstitution ?? Guid.Empty()} installTerm={this.props.installTerm} />,
      onOk: () => this.editCourse(row),
      width: 1000
    });
  }

  private editCourse = (row: any) => {
    const institutionCourse = this._addInstitutionCourseFormRef.current?.getValue();
    const loaders = [];
    loaders.push(this._addInstitutionCourseFormRef.current?.validateFields());

    return Promise.all(loaders).then(() => {

      if (institutionCourse) {
        if (institutionCourse.institutionCourseDetail) {
          const newCourse: CourseDTO = CourseDTO.create(institutionCourse.institutionCourseDetail);

          const form = this._formRef.current;
          const term = form?.getFieldsValue()
          term.flatCourseGroupOptions[row].course = newCourse;
          term.flatCourseGroupOptions[row].courseId = institutionCourse.institutionCourseDetail?.courseId;
          term.flatCourseGroupOptions[row].description = institutionCourse.institutionCourseDetail?.display;
          term.flatCourseGroupOptions[row].credits = { min: institutionCourse.institutionCourseDetail?.totalMaxCredits, max: institutionCourse.institutionCourseDetail?.totalMinCredits }
          term.flatCourseGroupOptions[row].isVariableCredits = institutionCourse.institutionCourseDetail?.variableHours;
          const value = this.state.value;
          value.flatCourseGroupOptions = term.flatCourseGroupOptions;
          form?.setFieldsValue(term);

          if (this.props.onChange) {
            this.props.onChange(value, row);
          }

          this.handleCreditsChange();
        }
        return new Promise((resolve) => {
          resolve('Course Edited');
        });
      }
      else {
        message.error('Course not selected.');
        return new Promise((resolve, reject) => {
          reject('Course Not Selected');
        });
      }
    });
  }

  private editCourseBlockModal(groupIndex: number, row: number) {
    const form = this._formRef.current;
    const term = form?.getFieldsValue();
    const option = term.flatCourseGroupOptions[row];
    Modal.confirm({
      title: 'Select Course Block',
      content: <SearchForCourseBlock courseBlockId={option.courseBlockId} ref={this._addCourseBlockFormRef} institutionId={this.props.selectedInstitution ?? Guid.Empty()} />,
      onOk: () => this.editCourseBlock(row),
      width: 1000
    });
  }

  private editCourseBlock = (row: any) => {
    const institutionCourseBlock = this._addCourseBlockFormRef.current?.submit();
    const loaders = [];
    loaders.push(this._addCourseBlockFormRef.current?.validateFields());

    return Promise.all(loaders).then(() => {
      if (institutionCourseBlock) {
        const form = this._formRef.current;
        const term = form?.getFieldsValue()
        term.flatCourseGroupOptions[row].courseBlock = institutionCourseBlock;
        term.flatCourseGroupOptions[row].courseBlockId = institutionCourseBlock.id;
        term.flatCourseGroupOptions[row].description = 'BLK' + (institutionCourseBlock.currentDetail?.code ?? '') + institutionCourseBlock.currentDetail?.title;

        const value = this.state.value;
        value.flatCourseGroupOptions = term.flatCourseGroupOptions;
        form?.setFieldsValue(term);

        if (this.props.onChange) {
          this.props.onChange(value);
        }
        return new Promise((resolve) => {
          resolve('Course Block Edited');
        });
      }
      else {
        message.error('Course Block not selected.');
        return new Promise((resolve, reject) => {
          reject('Course Not Selected');
        });
      }
    });
  }

  private openFootnoteModal(row: number) {
    const allOptions = this._formRef.current?.getFieldValue(ProgramModificationProgramTermDTO.flatCourseGroupOptions) as FlatProgramModificationProgramTermCourseGroupOptionDTO[];

    Modal.confirm({
      title: 'Edit Footnote',
      content: <AddFootnoteModal ref={this._footnoteFormRef} footnote={allOptions[row]?.footnote ?? ''} institutionId={this.props.selectedInstitution ?? Guid.Empty()}></AddFootnoteModal>,
      onOk: () => this.submitFootnote(row),
      width: 1000
    });
  }

  private submitFootnote = (row: number) => {
    const footnote = this._footnoteFormRef.current?.submit();

    if (footnote != undefined && footnote != null) {
      const form = this._formRef.current;
      const term = form?.getFieldsValue()
      term.flatCourseGroupOptions[row].footnote = footnote;
      const value = this.state.value;
      value.flatCourseGroupOptions = term.flatCourseGroupOptions;
      form?.setFieldsValue(term);

      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }
  }

  private openCourseBlockModal(groupIndex: number, add: any) {
    this.setState({ showAddItemPopover: null });
    Modal.confirm({
      title: 'Select Course Block',
      content: < SearchForCourseBlock ref={this._addCourseBlockFormRef} institutionId={this.props.selectedInstitution ?? Guid.Empty()} />,
      onOk: () => this.submitAddCourseBlock(groupIndex, add),
      width: 1000
    });
  }

  private submitAddCourseBlock = (groupIndex: number, add: any) => {
    const institutionCourseBlock = this._addCourseBlockFormRef.current?.submit();
    const loaders = [];
    loaders.push(this._addCourseBlockFormRef.current?.validateFields());

    return Promise.all(loaders).then(() => {
      if (institutionCourseBlock) {
        const allOptions = this._formRef.current?.getFieldValue(ProgramModificationProgramTermDTO.flatCourseGroupOptions) as FlatProgramModificationProgramTermCourseGroupOptionDTO[];
        const groupOptions = allOptions.filter((x: FlatProgramModificationProgramTermCourseGroupOptionDTO) => x.groupIndex == groupIndex);
        let order = (groupOptions[groupOptions.length - 2]?.order ?? 0) + 1
        if (groupOptions.length == 1 && groupIndex > 1) {
          const newGroup = allOptions.filter((x: FlatProgramModificationProgramTermCourseGroupOptionDTO) => x.groupIndex == (groupIndex - 1));
          order = (newGroup[newGroup.length - 2]?.order ?? 0) + 1
        }

        const addPlaceholder = groupOptions[groupOptions.length - 1];
        const addIndex = allOptions.findIndex(x => x.groupIndex == addPlaceholder.groupIndex && x.order == addPlaceholder.order);

        add(FlatProgramModificationProgramTermCourseGroupOptionDTO.create({
          id: Guid.NewGuid(),
          courseBlock: institutionCourseBlock,
          courseBlockId: institutionCourseBlock.id,
          description: 'BLK' + (institutionCourseBlock.currentDetail?.code ?? '') + ' - ' + institutionCourseBlock.currentDetail?.title,
          groupIndex: groupIndex,
          order: order
        }), addIndex);
        return new Promise((resolve) => {
          resolve('Course Block Added');
        });
      }
      else {
        message.error('Course Block not selected.');
        return new Promise((resolve, reject) => {
          reject('Course Not Selected');
        });
      }
    });
  }

  private openCourseModal(groupIndex: number, add: any) {
    this.setState({ showAddItemPopover: null });
    Modal.confirm({
      title: 'Select Course',
      content: < InstitutionCourseSearch ref={this._addInstitutionCourseFormRef} institutionId={this.props.selectedInstitution ?? Guid.Empty()} installTerm={this.props.installTerm} />,
      onOk: () => this.submitAddCourse(groupIndex, add),
      width: 1000
    });
  }

  private submitAddCourse = (groupIndex: number, add: any) => {
    const institutionCourse = this._addInstitutionCourseFormRef.current?.getValue();
    const loaders = [];
    loaders.push(this._addInstitutionCourseFormRef.current?.validateFields());

    return Promise.all(loaders).then(() => {
      if (institutionCourse) {
        const allOptions = this._formRef.current?.getFieldValue(ProgramModificationProgramTermDTO.flatCourseGroupOptions) as FlatProgramModificationProgramTermCourseGroupOptionDTO[];

        const groupOptions = allOptions.filter((x: FlatProgramModificationProgramTermCourseGroupOptionDTO) => x.groupIndex == groupIndex);
        let order = (groupOptions[groupOptions.length - 2]?.order ?? 0) + 1
        if (groupOptions.length == 1 && groupIndex > 1) {
          const newGroup = allOptions.filter((x: FlatProgramModificationProgramTermCourseGroupOptionDTO) => x.groupIndex == (groupIndex - 1));
          order = (newGroup[newGroup.length - 2]?.order ?? 0) + 1
        }

        const addPlaceholder = groupOptions[groupOptions.length - 1];
        const addIndex = allOptions.findIndex(x => x.groupIndex == addPlaceholder.groupIndex && x.order == addPlaceholder.order);

        const newCourse: CourseDTO = CourseDTO.create(institutionCourse.institutionCourseDetail);

        add(FlatProgramModificationProgramTermCourseGroupOptionDTO.create({
          id: Guid.NewGuid(),
          course: newCourse,
          courseId: institutionCourse.institutionCourseDetail?.courseId,
          description: institutionCourse.institutionCourseDetail?.display,
          credits: { min: institutionCourse.institutionCourseDetail?.totalMaxCredits, max: institutionCourse.institutionCourseDetail?.totalMinCredits },
          groupIndex: groupIndex,
          order: order,
          isVariableCredits: institutionCourse.institutionCourseDetail?.variableHours
        }), addIndex);

        this.handleCreditsChange();
        return new Promise((resolve) => {
          resolve('Course Added');
        });
      }
      else {
        message.error('Course not selected.');
        return new Promise((resolve, reject) => {
          reject('Course Not Selected');
        });
      }
    });
  }

  private editDisciplineFrameworkCourses = (row: any) => {
    const institutionCourse = this._disciplineFrameworkCourseSearchRef.current?.getValue();
    const loaders = [];
    loaders.push(this._addInstitutionCourseFormRef.current?.validateFields());

    return Promise.all(loaders).then(() => {
      if (institutionCourse.institutionCourseId && institutionCourse.institutionCourseId != Guid.Empty()) {
        const form = this._formRef.current;
        const term = form?.getFieldsValue()
        term.flatCourseGroupOptions[row].courseId
        if (term.flatCourseGroupOptions[row].courseId != institutionCourse.institutionCourseId) {
          const newCourse: CourseDTO = CourseDTO.create(institutionCourse.institutionCourseDetail);

          term.flatCourseGroupOptions[row].course = newCourse;
          term.flatCourseGroupOptions[row].transferMajorDisciplineFrameworkElementId = institutionCourse.transferMajorDisciplineFrameworkElementId;
          term.flatCourseGroupOptions[row].courseId = institutionCourse.institutionCourseDetail?.courseId;
          term.flatCourseGroupOptions[row].description = institutionCourse.institutionCourseDetail?.display;
          term.flatCourseGroupOptions[row].credits = { min: institutionCourse.institutionCourseDetail?.totalMaxCredits, max: institutionCourse.institutionCourseDetail?.totalMinCredits }
          term.flatCourseGroupOptions[row].isVariableCredits = institutionCourse.institutionCourseDetail?.variableHours;
          const value = this.state.value;
          value.flatCourseGroupOptions = term.flatCourseGroupOptions;
          form?.setFieldsValue(term);

          if (this.props.onChange) {
            this.props.onChange(value, row);
          }


          this.handleCreditsChange();
        }
        return new Promise((resolve) => {
          resolve('Course Edited');
        });
      }
      else {
        message.error('Course not selected.');
        return new Promise((resolve, reject) => {
          reject('Course Not Selected');
        });
      }
    });
  }

  private openDisciplineFrameworkCourseModal(groupIndex: number, add: any) {
    this.setState({ showAddItemPopover: null });

    return Modal.confirm({
      title: 'Select Course',
      content: < DisciplineFrameworkCourseSearch ref={this._disciplineFrameworkCourseSearchRef} institutionId={this.props.selectedInstitution ?? Guid.Empty()} installTerm={this.props.installTerm} />
      ,
      onOk: () => this.submitAddDisciplineFrameworkCourse(groupIndex, add),
      width: 1000
    });
  }

  private submitAddDisciplineFrameworkCourse = (groupIndex: number, add: any) => {
    const institutionCourse = this._disciplineFrameworkCourseSearchRef.current.getValue();
    const loaders = [];
    loaders.push(this._disciplineFrameworkCourseSearchRef.current?.validateFields());

    return Promise.all(loaders).then(() => {
      if (institutionCourse.institutionCourseId && institutionCourse.institutionCourseId != Guid.Empty()) {
        const allOptions = this._formRef.current?.getFieldValue(ProgramModificationProgramTermDTO.flatCourseGroupOptions) as FlatProgramModificationProgramTermCourseGroupOptionDTO[];

        const groupOptions = allOptions.filter((x: FlatProgramModificationProgramTermCourseGroupOptionDTO) => x.groupIndex == groupIndex);
        let order = (groupOptions[groupOptions.length - 2]?.order ?? 0) + 1
        if (groupOptions.length == 1 && groupIndex > 1) {
          const newGroup = allOptions.filter((x: FlatProgramModificationProgramTermCourseGroupOptionDTO) => x.groupIndex == (groupIndex - 1));
          order = (newGroup[newGroup.length - 2]?.order ?? 0) + 1
        }

        const addPlaceholder = groupOptions[groupOptions.length - 1];
        const addIndex = allOptions.findIndex(x => x.groupIndex == addPlaceholder.groupIndex && x.order == addPlaceholder.order);

        const newCourse: CourseDTO = CourseDTO.create(institutionCourse.institutionCourseDetail);

        add(FlatProgramModificationProgramTermCourseGroupOptionDTO.create({
          id: Guid.NewGuid(),
          course: newCourse,
          courseId: institutionCourse.institutionCourseDetail?.courseId,
          description: institutionCourse.institutionCourseDetail?.display,
          credits: { min: institutionCourse.institutionCourseDetail?.totalMaxCredits, max: institutionCourse.institutionCourseDetail?.totalMinCredits },
          groupIndex: groupIndex,
          order: order,
          isVariableCredits: institutionCourse.institutionCourseDetail?.variableHours,
          transferMajorDisciplineFrameworkElementId: institutionCourse.transferMajorDisciplineFrameworkElementId
        }), addIndex);

        this.handleCreditsChange();
        return new Promise((resolve) => {
          resolve('Course Added');
        });
      }
      else {
        message.error('Course not selected.');
        return new Promise((resolve, reject) => {
          reject('Course Not Selected');
        });
      }
    });
  }

  private editDisciplineFrameworkCourseModal(groupIndex: number, row: number) {
    const form = this._formRef.current;
    const term = form?.getFieldsValue();
    const option = term.flatCourseGroupOptions[row];
    Modal.confirm({
      title: 'Select Course',
      content: < DisciplineFrameworkCourseSearch ref={this._disciplineFrameworkCourseSearchRef} existingCourseId={option.courseId} transferMajorDisciplineFrameworkElementId={option.transferMajorDisciplineFrameworkElementId} institutionId={this.props.selectedInstitution ?? Guid.Empty()} installTerm={this.props.installTerm}/>,
      onOk: () => this.editDisciplineFrameworkCourses(row),
      width: 1000
    });
  }

  private getAllOptions = () => {
    let options = this._formRef.current?.getFieldValue(ProgramModificationProgramTermDTO.flatCourseGroupOptions) as FlatProgramModificationProgramTermCourseGroupOptionDTO[] ?? this.props.value?.flatCourseGroupOptions
    if (options && this.props.readonly && !this.props.programDisplay) {
      options = options.filter((x: any) => x.courseId != null || x.courseBlockId != null);
    }
    return options;
  }

  private addGroup = (add: any) => {
    const allOptions = this._formRef.current?.getFieldValue(ProgramModificationProgramTermDTO.flatCourseGroupOptions) as FlatProgramModificationProgramTermCourseGroupOptionDTO[];
    let maxIndex = 0;
    allOptions?.forEach(x => maxIndex = x.groupIndex > maxIndex ? x.groupIndex : maxIndex);

    add(FlatProgramModificationProgramTermCourseGroupOptionDTO.create({
      id: Guid.NewGuid(),
      groupIndex: maxIndex + 1,
      order: 10000,
    }))

  }

  private removeOption = (remove: any, index: number) => {
    remove(index);

    this.handleCreditsChange();
  }

  private removeGroup = (remove: any, groupIndex: number, groupIndexes: number[]) => {
    remove(groupIndexes);

    this.handleCreditsChange();
  }

  private handleChange = () => {
    const options = this._formRef.current?.getFieldsValue();
    const value = this.state.value;
    if (value) {
      value.flatCourseGroupOptions = options.flatCourseGroupOptions;
      if (this.props.onChange) {
        this.props.onChange(value);
      }

      if (this.props.onOptionChange) {
        this.props.onOptionChange(value);
      }
    }

    this.setState({
      altered: true,
      value: value ?? ProgramModificationProgramTermDTO.create()
    });
  }

  private handleCreditsChange = () => {
    const groups = this.getAllOptions() ?? [];
    let credits = 0;
    let techCoreCredits = 0;

    if (groups?.length > 0) {
      const creditGroups = groups?.filter(x => x.groupOptionTypeId == GroupOptionType.MAIN || x.groupOptionTypeId == null) ?? [];
      const options = creditGroups.map(x => x?.credits?.min ?? 0);
      if (options.length > 0) {
        credits = options.reduce((a: number, b: number) => a + b) ?? 0;
      }

      const existingGroups: number[] = [];

      groups.forEach(x => {
        if (!existingGroups.includes(x.groupIndex)) {
          const techCore = groups?.filter(y => y.groupIndex == x.groupIndex && y.groupOptionTypeId != GroupOptionType.OPTIONAL && (y.courseId != null || y.courseBlockId != null));
          if (techCore.findIndex(y => !y.techCore) < 0) {
            const techCoreOptionTypes = techCore.map(x => x.groupOptionTypeId);
            let minCredits = null as number | null;

            techCoreOptionTypes.forEach(e => {
              let optionCredits = 0;

              const optionCreditList = techCore.filter(o => o.groupOptionTypeId === e).map(y => y.credits?.min ?? 0);
              optionCreditList.forEach(y => optionCredits += y);

              if (optionCredits == null || optionCredits < (minCredits ?? Number.MAX_SAFE_INTEGER)) {
                minCredits = optionCredits;
              }
            });

            techCoreCredits += minCredits ?? 0;
            existingGroups.push(x.groupIndex);
          }
        }
      });

      this.setState({
        totalTermCredits: credits,
        totalTermTechCoreCredits: techCoreCredits
      });

      if (this.props.onCreditsChanged) {
        this.props.onCreditsChanged(this.state.value.order ? this.state.value.order : (this.props.value?.order ?? -1), credits, techCoreCredits, this.state.programAward.id && this.state.programAward.id != Guid.Empty() ? this.state.programAward.id : (this.props.programAward?.id ?? Guid.Empty()));
      }

      if (this.props.onTermLoaded) {
        this.props.onTermLoaded(this.props.value?.id ?? Guid.Empty());
      }
    }
    else {
      if (this.props.onTermLoaded && this.props.value?.groups?.length == 0) {
        this.props.onTermLoaded(this.props.value?.id ?? Guid.Empty());
      }
    }
  }

  private handleFieldChange = (value: any) => {
    const fieldErrors = this.state.fieldErrors
    if (value[0].value != null && value[0].touched && value[0].name[2] == 'techCore') {
      const option = this.getOption(value[0].name[1]);
      (fieldErrors ?? [])[ProgramTermCourseGroupOptionDTO.techCore + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order] = [];
      this.setState({ fieldErrors: fieldErrors })
    }
  }

  private openAddItemPopover = (groupIndex: number, index: number) => {
    this.setState({ showAddItemPopover: this.props.termIndex + '' + groupIndex + '' + index });
  }

  private closeAddItemPopover = () => {
    this.setState({ showAddItemPopover: null });
  }

  renderToolTipContent = (groupIndex: number, add: any) => {
    return <Space direction='vertical'>
      Please select the option type.
      <Space>
        <Button onClick={() => this.openCourseModal(groupIndex, add)}>Course</Button>
        <Button onClick={() => this.openCourseBlockModal(groupIndex, add)}>Course Block</Button>
      </Space>
      {this.props.transferMajor ? <Button onClick={() => this.openDisciplineFrameworkCourseModal(groupIndex, add)}>Discipline Framework Course</Button> : null}
      <Button type='link' onClick={this.closeAddItemPopover}>Close</Button>
    </Space>
  }

  public render = () => {
    if (this.state.loading || this.props.loading) {
      return <Skeleton active={true} />
    }

    const values = this.state.value ?? [];
    if (this.props.readonly && !this.props.programDisplay) {
      values.flatCourseGroupOptions = (this.state.value.flatCourseGroupOptions?.filter(x => x.courseId != null || x.courseBlockId != null) as any) ?? [];
    }

    return (
      <Space size="small" direction="vertical">
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={values}
          onValuesChange={this.handleChange}
          onFieldsChange={this.handleFieldChange}
          requiredMark={true}>
          <Space direction="vertical">

            <Form.List name={ProgramModificationProgramTermDTO.flatCourseGroupOptions}>
              {
                (options, { add, remove }) => {
                  const formTerms = this._formRef.current?.getFieldsValue() as ProgramModificationProgramTermDTO;
                  let embedded = false;

                  if (formTerms && formTerms.flatCourseGroupOptions && (formTerms.flatCourseGroupOptions?.length ?? 0) > 0) {
                    formTerms.flatCourseGroupOptions.forEach(x => {
                      if (x != undefined && (this.state.generalEducationTypes?.find(y => x.generalEducationId == y.id)?.embeddedCredits)) {
                        embedded = true;
                      }
                    });
                  }
                  else {
                    if (this.state.value.flatCourseGroupOptions) {
                      this.state.value?.flatCourseGroupOptions.forEach((y: any) => {
                        if (this.state.generalEducationTypes?.find(z => y.generalEducationId == z.id)?.embeddedCredits) {
                          embedded = true;
                        }
                      })
                    }
                  }

                  let flatGroupOptions = options;
                  if (this.props.readonly && !this.props.programDisplay) {
                    flatGroupOptions = (this.state.value.flatCourseGroupOptions?.filter(x => x.courseId != null || x.courseBlockId != null) as any) ?? [];
                  }

                  return (
                    <Table
                      ref={this._tableRef}
                      className='diable-table-hover'
                      dataSource={flatGroupOptions}
                      pagination={false}
                      scroll={{ x: 500 }}
                      footer={() => <div
                      >
                        {!this.props.readonly && !this.props.programDisplay ? <Button onClick={() => this.addGroup(add)}>Add Course/Course Block Group</Button> : null}
                      </div>}
                    >
                      <Table.Column title=""
                        align='center'
                        width='27px'
                        dataIndex={FlatProgramModificationProgramTermCourseGroupOptionDTO.id}
                        ellipsis={this.props.readonly && !this.props.programDisplay}
                        render={
                          (data: number, row: any, index: number) => {
                            const allOptions = this.getAllOptions();
                            if (allOptions) {
                              const option = this.getOption(index);
                              const groupOptions = allOptions.filter((x: FlatProgramModificationProgramTermCourseGroupOptionDTO) => x.groupIndex == option.groupIndex);
                              if (option) {
                                const startIndex = groupOptions.findIndex(x => x.id == option.id && (option.courseId != null || option.courseBlockId != null))
                                const height = groupOptions.length * 60;
                                const className = option?.groupIndex % 2 == 0 ? 'table-span-light' : 'table-span-dark';

                                return {
                                  children: <div className={className} style={{ height: height }} />,
                                  props: {
                                    rowSpan: startIndex == 0 || groupOptions.length == 1 ? groupOptions.length : 0,
                                  },
                                };
                              }
                            }
                          }
                        }
                      />
                      <Table.Column title='Course/Block'
                        width='350px'
                        dataIndex={ProgramTermCourseGroupOptionDTO.description}
                        ellipsis={this.props.readonly && !this.props.programDisplay}
                        render={(data: any, row: any, index: number) => {
                          const option = this.getOption(index);

                          if (option != null && ((option.description != null && option.description != '') || (option.title != null) && (option?.courseId != null || option?.courseBlockId != null))) {
                            const editAction = option?.courseId != null ? option.transferMajorDisciplineFrameworkElementId ? () => this.editDisciplineFrameworkCourseModal(option.groupIndex, index) : () => this.editCourseModal(option.groupIndex, index) : () => this.editCourseBlockModal(option.groupIndex, index);
                            const editButton = this.props.readonly || this.props.programDisplay || !this.props.isEditing ? null : <Button type='link' onClick={editAction} icon={<EditOutlined />} />;
                            let title = option.description ? option.description : '';
                            if (title == '') {
                              if (option.courseId) {
                                title = option.course?.currentDetail?.display;
                              }
                              else if (option.courseBlockId) {
                                title = option.courseBlock && option.courseBlock?.currentDetail ? 'BLK' + (option.courseBlock?.currentDetail?.code ?? '') + ' - ' + option.courseBlock?.currentDetail?.title : 'BLK-' + option.courseBlock?.currentDetail?.code;
                              }
                            }

                            return <span>{title}{editButton}</span>;
                          }
                          else if (!this.props.readonly && !this.props.programDisplay && (option?.courseId == null && option?.courseBlockId == null)) {
                            return (
                              <div>
                                <Popover placement="topLeft" trigger="click" content={this.renderToolTipContent(option.groupIndex, add)}
                                  visible={this.state.showAddItemPopover == this.props.termIndex + '' + option.groupIndex + '' + index}
                                  onVisibleChange={() => this.openAddItemPopover(option.groupIndex, index)}
                                >
                                  <Button size='small' type='link' icon={<PlusOutlined />} >Add Option</Button>
                                </Popover>
                              </div>
                            );
                          }
                        }
                        }
                      />
                      <Table.Column title='Credits'
                        dataIndex={ProgramTermCourseGroupOptionDTO.credits}
                        ellipsis={this.props.readonly && !this.props.programDisplay}
                        width= {this.props.readonly || !this.props.isEditing ? '125px':'75px'}
                        align='center'
                        render={(data: any, row: any, index: number) => {
                          const option = this.getOption(index);
                          if (option && (option.courseId != null || option.courseBlockId != null)) {
                            const minMaxCredits = this.getMinMax(option);
                            let credits = '' 
                            if (this.props.readonly || !this.props.isEditing) {
                              if (option.courseBlockId != null) {
                                credits = option.credits?.min + '-' + option.credits?.max;
                              }
                              else if (option.courseId != null) {
                                credits = minMaxCredits?.isVariable ?
                                  option.credits.min + '-' + option.credits.max :
                                  option.credits.min;
                              }
                            }
                            return <FormItem
                              {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.credits)}
                              {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.credits + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                              { this.props.readonly || !this.props.isEditing ? credits :
                              minMaxCredits.isVariable || option.courseBlockId ?
                                <MinMaxInput onChange={this.handleCreditsChange} size='small' showPrefix={true} min={minMaxCredits.min} disabled={this.props.readonly || !this.props.isEditing} /> :
                                minMaxCredits.min || minMaxCredits.min == 0 ? minMaxCredits.min : null}
                            </FormItem>
                          }
                        }
                        }
                      />
                      <Table.Column title='Designation'
                        width='100px'
                        ellipsis={this.props.readonly && !this.props.programDisplay}
                        dataIndex={ProgramTermCourseGroupOptionDTO.groupOptionTypeId}
                        render={(data: any, row: any, index: number) => {
                          const option = this.getOption(index);
                          const identifier = ' Term ' + this.props.termIndex + ' Group ' + option.groupIndex + ' Option ' + option.order;
                          if (option && (option.courseId != null || option.courseBlockId != null)) {
                            return this.props.readonly || !this.props.isEditing ? this.state.groupOptionTypes.find(x => x.id == option.groupOptionTypeId)?.name :
                              <>
                                <label
                                  title={'Designation' + identifier}
                                  htmlFor={'Designation' + identifier} />
                                <FormItem
                                  {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.groupOptionTypeId)}
                                  {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.groupOptionTypeId + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>

                                  <Dropdown id={'Designation' + identifier} onChange={this.handleCreditsChange} size='small' showArrow={!this.props.readonly} disabled={this.props.readonly || !this.props.isEditing} allowClear={true} dropdownMatchSelectWidth={false}>
                                    {this.state.groupOptionTypes.map(x => { return this.renderGroupOptionType(x) })}
                                  </Dropdown>
                                </FormItem>
                              </>
                          }
                        }
                        }
                      />
                      {!this.props.transferMajor ?
                        <Table.Column title='General Education'
                          width='150px'
                          ellipsis={this.props.readonly && !this.props.programDisplay}
                          dataIndex={ProgramTermCourseGroupOptionDTO.generalEducationId}
                          render={
                            (data: any, row: any, index: number) => {
                              const option = this.getOption(index);
                              const identifier = ' Term ' + this.props.termIndex + ' Group ' + option.groupIndex + ' Option ' + option.order;
                              if (option && (option.courseId != null || option.courseBlockId != null)) {
                                return this.props.readonly || !this.props.isEditing ? this.state.generalEducationTypes.find(x => x.id == option.generalEducationId)?.name :
                                  <>
                                    <label
                                      title={'General Education' + identifier}
                                      htmlFor={'General Education' + identifier} />
                                    <FormItem
                                      {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.generalEducationId)}
                                      {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.generalEducationId + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                                      <Dropdown id={'General Education' + identifier} size='small' dropdownMatchSelectWidth={false} showArrow={!this.props.readonly} allowClear={true} disabled={this.props.readonly || !this.props.isEditing}>
                                        {this.state.generalEducationTypes.map(x => { return this.renderGeneralEducation(x) })}
                                      </Dropdown>
                                    </FormItem>
                                  </>
                              }
                            }
                          }
                        /> :
                        null
                      }
                      {!this.props.transferMajor ?
                        <Table.Column title='Work Based Learning'
                          width='150px'
                          ellipsis={this.props.readonly && !this.props.programDisplay}
                          dataIndex={ProgramTermCourseGroupOptionDTO.workBasedLearningTypeId}
                          render={
                            (data: any, row: any, index: number) => {
                              const option = this.getOption(index);
                              const identifier = ' Term ' + this.props.termIndex + ' Group ' + option.groupIndex + ' Option ' + option.order;
                              if (option && (option.courseId != null || option.courseBlockId != null)) {
                                return this.props.readonly || !this.props.isEditing ? this.state.workBasedLearningTypes.find(x => x.id == option.workBasedLearningTypeId)?.name :
                                  <>
                                    <label
                                      title={'Work Based Learning' + identifier}
                                      htmlFor={'Work Based Learning' + identifier} />
                                    <FormItem
                                      {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.workBasedLearningTypeId)}
                                      {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.workBasedLearningTypeId + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                                      <Dropdown id={'Work Based Learning' + identifier} size='small' dropdownMatchSelectWidth={false} showArrow={!this.props.readonly} allowClear={true} disabled={this.props.readonly || !this.props.isEditing}>
                                        {this.state.workBasedLearningTypes.map(x => { return this.renderWorkBasedLearning(x) })}
                                      </Dropdown>
                                    </FormItem>
                                  </>
                              }
                            }
                          }
                        /> :
                        null
                      }
                      {
                        this.state.programAward.typeOfProgram?.apsGeneralEducation ?
                          <Table.Column title='APS General Education'
                            width='170px'
                            ellipsis={this.props.readonly && !this.props.programDisplay}
                            dataIndex={ProgramTermCourseGroupOptionDTO.apsGeneralEducationId}
                            render={
                              (data: any, row: any, index: number) => {
                                const option = this.getOption(index);
                                const identifier = ' Term ' + this.props.termIndex + ' Group ' + option.groupIndex + ' Option ' + option.order;
                                if (option && (option.courseId != null || option.courseBlockId != null)) {
                                  return this.props.readonly || !this.props.isEditing ? this.state.apsGeneralEducationType.find(x => x.id == option.apsGeneralEducationId)?.name :
                                    <>
                                      <label
                                        title={'APS General Education' + identifier}
                                        htmlFor={'APS General Education' + identifier} />
                                      <FormItem
                                        {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.apsGeneralEducationId)}
                                        {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.apsGeneralEducationId.toLowerCase() + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>

                                        <Dropdown id={'APS General Education' + identifier} size='small' showArrow={!this.props.readonly} dropdownMatchSelectWidth={false} allowClear={true} disabled={this.props.readonly || !this.props.isEditing}>
                                          {this.state.apsGeneralEducationType.map(x => { return this.renderAPSGeneralEducation(x) })}
                                        </Dropdown>
                                      </FormItem>
                                    </>
                                }
                              }
                            }
                          /> :
                          null
                      }
                      {embedded ?
                        < Table.Column title="Embedded Credits"
                          width='150px'
                          ellipsis={this.props.readonly && !this.props.programDisplay}
                          dataIndex={ProgramTermCourseGroupOptionDTO.embeddedCredits}
                          render={
                            (data: any, row: any, index: number) => {
                              const option = this.getOption(index);
                              if (option && (option.courseId != null || option.courseBlockId != null) && (option.generalEducation?.embeddedCredits || this.state.generalEducationTypes.find(x => x.id == option.generalEducationId)?.embeddedCredits)) {
                                const identifier = ' Term ' + this.props.termIndex + ' Group ' + option.groupIndex + ' Option ' + option.order;
                                return this.props.readonly || !this.props.isEditing ? option.embeddedCredits :
                                  <>
                                    <label
                                      title={'Embedded Credits' + identifier}
                                      htmlFor={'Embedded Credits' + identifier} />
                                    <FormItem
                                      {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.embeddedCredits)}
                                      {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.embeddedCredits + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>

                                      <InputNumber id={'Embedded Credits' + identifier} size='small' disabled={this.props.readonly || !this.props.isEditing} />
                                    </FormItem>
                                  </>
                              }
                            }
                          }
                        /> : null}
                      {
                        this.props.transferMajor ? null :
                          <Table.Column title='Tech Core'
                            ellipsis={this.props.readonly && !this.props.programDisplay}
                            width='100px'
                            align='center'
                            dataIndex={ProgramTermCourseGroupOptionDTO.techCore}
                            render={
                              (data: any, row: any, index: number) => {

                                const option = this.getOption(index);
                                if (option && (option.courseId != null || option.courseBlockId != null)) {
                                  return <FormItem
                                    {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.techCore)}
                                    {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.techCore + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}
                                  >
                                    <YesNoInput onChange={this.handleCreditsChange} readonly={this.props.readonly || !this.props.isEditing} disabled={this.props.readonly || !this.props.isEditing} size='small' />
                                  </FormItem>
                                }
                              }
                            }
                          />
                      }
                      {this.props.transferMajor ?
                        <Table.Column title="General Education"
                          width='150px'
                          dataIndex={ProgramTermCourseGroupOptionDTO.transferMajorGeneralEducationTypeId}
                          ellipsis={this.props.readonly && !this.props.programDisplay}
                          render={
                            (data: any, row: any, index: number) => {
                              const option = this.getOption(index);
                              if (option && (option.courseId != null || option.courseBlockId != null)) {
                                const identifier = ' Term ' + this.props.termIndex + ' Group ' + option.groupIndex + ' Option ' + option.order;
                                return this.props.readonly || !this.props.isEditing ? this.state.generalEducationCategory.find(x => x.id == option.transferMajorGeneralEducationTypeId)?.name :
                                  <>
                                    <label title={'General Education' + identifier} htmlFor={'General Education' + identifier} />
                                    <FormItem
                                      {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.transferMajorGeneralEducationTypeId)}
                                      {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.transferMajorGeneralEducationTypeId + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>

                                      <Dropdown id={'General Education' + identifier} size='small' dropdownMatchSelectWidth={false} showArrow={!this.props.readonly} allowClear={true} disabled={this.props.readonly || !this.props.isEditing}>
                                        {this.state.generalEducationCategory.map(x => { return this.renderTransferMajorGeneralEducationCategory(x) })}
                                      </Dropdown>
                                    </FormItem>
                                  </>
                              }
                            }
                          }

                        />
                        : null
                      }
                      {this.props.transferMajor ?
                        <Table.Column title="LACTS Category"
                          width='150px'
                          dataIndex={ProgramTermCourseGroupOptionDTO.transferMajorLactsCategoryId}
                          ellipsis={this.props.readonly && !this.props.programDisplay}
                          render={
                            (data: any, row: any, index: number) => {
                              const option = this.getOption(index);
                              if (option && (option.courseId != null || option.courseBlockId != null)) {
                                const identifier = ' Term ' + this.props.termIndex + ' Group ' + option.groupIndex + ' Option ' + option.order;
                                return this.props.readonly || !this.props.isEditing ? this.state.transferMajorLactsCategories.find(x => x.id == option.transferMajorLactsCategoryId)?.name :
                                  <>
                                    <label title={'LACTS Category' + identifier} htmlFor={'LACTS Category' + identifier} />
                                    <FormItem
                                      {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.transferMajorLactsCategoryId)}
                                      {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.transferMajorLactsCategoryId + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>

                                      <Dropdown id={'LACTS Category' + identifier} size='small' dropdownMatchSelectWidth={false} showArrow={!this.props.readonly} allowClear={true} disabled={this.props.readonly || !this.props.isEditing}>
                                        {this.state.transferMajorLactsCategories.map(x => { return this.renderTransferMajorLactsCategories(x) })}
                                      </Dropdown>
                                    </FormItem>
                                  </>
                              }
                            }
                          }
                        />
                        : null
                      }
                      {this.props.transferMajor ?
                        <Table.Column title="Discipline Framework Element"
                          width='150px'
                          ellipsis={this.props.readonly && !this.props.programDisplay}
                          dataIndex={ProgramTermCourseGroupOptionDTO.transferMajorDisciplineFrameworkElementId}
                          render={(data: any, row: any, index: number) => {
                            const option = this.getOption(index);
                            if (option && (option.courseId != null || option.courseBlockId != null)) {
                              return this.state.disciplineFrameworkElement?.find(x => x.id == option.transferMajorDisciplineFrameworkElementId)?.name
                            }
                          }
                          }
                        />
                        : null
                      }
                      <Table.Column title='Footnote'
                        width='250px'
                        ellipsis={this.props.readonly && !this.props.programDisplay}
                        dataIndex={ProgramTermCourseGroupOptionDTO.footnote}
                        render={
                          (data: any, row: any, index: number) => {
                            const option = this.getOption(index);
                            if (option && (option.courseId != null || option.courseBlockId != null)) {
                              const identifier = ' Term ' + this.props.termIndex + ' Group ' + option.groupIndex + ' Option ' + option.order;
                              return option.footnote ?
                                (
                                  <Tooltip title={option.footnote} placement='bottomLeft'>
                                    <FormItem
                                      {...this.getProgramTermFormItems(index).get(ProgramTermCourseGroupOptionDTO.footnote)}
                                      {...ValidationUtil.getValidation(ProgramTermCourseGroupOptionDTO.footnote + this.state.programAward.order + (this.state.value.order ?? 0) + option.groupIndex + option.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                                      <Space id={'Footnote' + identifier} direction='horizontal'>{option.footnote}
                                        {
                                          !this.props.readonly && this.props.isEditing ?
                                            <Button size='small' type='link' onClick={() => this.openFootnoteModal(index)} icon={<EditOutlined />} />
                                            : null
                                        }
                                      </Space>
                                    </FormItem>
                                  </Tooltip>
                                )
                                :
                                !this.props.readonly && this.props.isEditing ?
                                  <Button size='small' type='link' onClick={() => this.openFootnoteModal(index)} icon={<PlusOutlined />} >Add Note</Button>
                                  :
                                  null
                            }
                          }
                        }

                      />
                      {!this.props.programDisplay && !this.props.readonly ?
                        <Table.Column
                          title="Remove Option"
                          ellipsis={this.props.readonly && !this.props.programDisplay}
                          sorter={false}
                          width='15px'
                          render={(data: string, row: any, index: any) => {

                            const option = this.getOption(index);
                            if (option && (option.courseId != null || option.courseBlockId != null)) {
                              if (!this.props.readonly && !this.props.programDisplay) {
                                return <Tooltip placement='left' title={'Remove Option'}>
                                  <Button icon={<DeleteOutlined />} onClick={() => this.removeOption(remove, index)} type="link" ></Button>
                                </Tooltip>
                              }
                            }
                          }
                          } />
                        : null}
                      {!this.props.programDisplay && !this.props.readonly ?
                        <Table.Column
                          title={'Remove Group'}
                          dataIndex={FlatProgramModificationProgramTermCourseGroupOptionDTO.id}
                          ellipsis={this.props.readonly && !this.props.programDisplay}
                          align='center'
                          width='10px'
                          render={
                            (data: number, row: any, index: number) => {
                              const allOptions = this.getAllOptions();
                              if (allOptions) {
                                const option = this.getOption(index);
                                const groupOptions = allOptions.filter((x: FlatProgramModificationProgramTermCourseGroupOptionDTO) => x.groupIndex == option.groupIndex);
                                let startIndex = 0;
                                allOptions.forEach((x: any) => {
                                  if (x?.groupIndex < option.groupIndex) {
                                    startIndex += 1;
                                  }
                                });

                                let count = startIndex;
                                const groupIndexes: number[] = [];
                                groupOptions.forEach(() => {
                                  groupIndexes.push(count++);
                                });

                                if (option) {
                                  const startIndex = groupOptions.findIndex(x => x.id == option.id && (option.courseId != null || option.courseBlockId != null))
                                  const height = groupOptions.length * 60;
                                  const className = option?.groupIndex % 2 == 0 ? 'table-span-light' : 'table-span-dark';

                                  return {
                                    children: <div className={className} style={{ height: height, width: 35 }} >
                                      {!this.props.readonly && !this.props.programDisplay ?
                                        <Tooltip placement='left' title={'Remove Group'}>
                                          <Button style={{
                                            position: 'absolute',
                                            top: 0, bottom: 0,
                                            marginTop: 'auto',
                                            marginBottom: 'auto',
                                            marginLeft: '-15px',
                                            marginRight: '0'
                                          }} className='ant-btn-link-light' type="link" onClick={() => this.removeGroup(remove, option.groupIndex, groupIndexes)} icon={<DeleteFilled />}></Button>
                                        </Tooltip>
                                        : null
                                      }
                                    </div>,
                                    props: {
                                      rowSpan: startIndex == 0 || groupOptions.length == 1 ? groupOptions.length : 0,
                                    },
                                  };
                                }
                              }
                            }
                          }
                        />
                        : null}
                    </ Table>
                  );
                }
              }
            </Form.List>

            <b> Total Credits: {this.state.totalTermCredits}</b>
            {this.props.transferMajor ? null : <b>Total Tech Core Credits: {this.state.totalTermTechCoreCredits}</b>}
          </Space>
        </Form >
      </Space >
    );
  }

  renderInstitutionCourse(institutionCourse: InstitutionCourseDTO) {
    if (institutionCourse.id) {
      const title = (institutionCourse.course?.courseDetails ?? []).length == 0 ? '' : (institutionCourse.course?.courseDetails ?? [])[0].title
      return <Select.Option key={institutionCourse.id ?? Guid.Empty()} value={institutionCourse.id ?? Guid.Empty()}>{title} - {institutionCourse.course?.display}</Select.Option>
    }
  }

  renderInstitutionCourseBlocks(institutionCourseBlock: InstitutionCourseBlockDTO) {
    if (institutionCourseBlock.id) {
      return <Select.Option key={institutionCourseBlock.id ?? Guid.Empty()} value={institutionCourseBlock.id ?? Guid.Empty()}>{institutionCourseBlock.currentDetail?.title}</Select.Option>
    }
  }

  renderGroupOptionType(groupOptionType: GroupOptionTypeDTO) {
    if (groupOptionType.id) {
      return <Select.Option key={groupOptionType.id ?? Guid.Empty()} value={groupOptionType.id ?? Guid.Empty()}>{groupOptionType.name}</Select.Option>
    }
  }

  renderTransferMajorCourseBlock(transferMajorCourseBlock: TransferMajorCourseBlockDTO) {
    if (transferMajorCourseBlock.id) {
      return <Select.Option key={transferMajorCourseBlock.id ?? Guid.Empty()} value={transferMajorCourseBlock.id ?? Guid.Empty()}>{transferMajorCourseBlock.name}</Select.Option>
    }
  }

  renderTransferMajorLactsCategories(transferMajorLactsCategories: TransferMajorLactsCategoryDTO) {
    if (transferMajorLactsCategories.id) {
      return <Select.Option key={transferMajorLactsCategories.id ?? Guid.Empty()} value={transferMajorLactsCategories.id ?? Guid.Empty()}>{transferMajorLactsCategories.name}</Select.Option>
    }
  }

  renderTransferMajorGeneralEducationCategory(generalEducationCategory: TransferMajorGeneralEducationCategoryDTO) {
    if (generalEducationCategory.id) {
      return <Select.Option key={generalEducationCategory.id ?? Guid.Empty()} value={generalEducationCategory.id ?? Guid.Empty()}>{generalEducationCategory.name}</Select.Option>
    }
  }

  renderTransferMajorDisciplineFrameworkElement(disciplineFrameworkElement: TransferMajorDisciplineFrameworkElementDTO) {
    if (disciplineFrameworkElement.id) {
      return <Select.Option key={disciplineFrameworkElement.id ?? Guid.Empty()} value={disciplineFrameworkElement.id ?? Guid.Empty()}>{disciplineFrameworkElement.name}</Select.Option>
    }
  }

  renderGeneralEducation(generalEducation: GeneralEducationDTO) {
    if (generalEducation.id) {
      return <Select.Option key={generalEducation.id ?? Guid.Empty()} value={generalEducation.id ?? Guid.Empty()}>{generalEducation.name}</Select.Option>
    }
  }

  renderWorkBasedLearning(workBasedLearning: WorkBasedLearningTypeDTO) {
    if (workBasedLearning.id) {
      return <Select.Option key={workBasedLearning.id ?? Guid.Empty()} value={workBasedLearning.id ?? Guid.Empty()}>{workBasedLearning.name}</Select.Option>
    }
  }

  renderAPSGeneralEducation(apsGeneralEducation: APSGeneralEducationDTO) {
    if (apsGeneralEducation.id) {
      return <Select.Option key={apsGeneralEducation.id ?? Guid.Empty()} value={apsGeneralEducation.id ?? Guid.Empty()}>{apsGeneralEducation.name}</Select.Option>
    }
  }

  private renderErrors = () => {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default ProgramTermEditor;