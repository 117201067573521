// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudyAggregateReportDataTableDTO extends EntitySelectorDTO { 
	selfStudyResponseId: string | null;
	rpp: string | null;
	communityCollege: string | null;
	district: string | null;
	serviceArea: string | null;
	cipNumber: string | null;
	program: string | null;
	submitter: string | null;
	reviewer: string | null;
	reviewYear: number;
	reviewType: string | null;
	careerAcademy: string | null;
}

const SelfStudyAggregateReportDataTableDTO = {
    className: 'SelfStudyAggregateReportDataTableDTO',
    create: (initValues?: Partial<SelfStudyAggregateReportDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			selfStudyResponseId: '00000000-0000-0000-0000-000000000000',
			rpp: "",
			communityCollege: "",
			district: "",
			serviceArea: "",
			cipNumber: "",
			program: "",
			submitter: "",
			reviewer: "",
			reviewYear: 0,
			reviewType: "",
			careerAcademy: "",
        },
        initValues);
    },
	selfStudyResponseId: 'selfStudyResponseId',
	rpp: 'rpp',
	communityCollege: 'communityCollege',
	district: 'district',
	serviceArea: 'serviceArea',
	cipNumber: 'cipNumber',
	program: 'program',
	submitter: 'submitter',
	reviewer: 'reviewer',
	reviewYear: 'reviewYear',
	reviewType: 'reviewType',
	careerAcademy: 'careerAcademy',
};

export default SelfStudyAggregateReportDataTableDTO;