import { Col, Descriptions, Input, Modal, Row, Select, Skeleton, Space, Table, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import CipsApiService from '../../api/CipsApiService';
import UsersApiService from '../../api/UsersApiService';
import { ColumnWidths } from '../../config/ColumnWidths';
import Routes from '../../config/Routes';
import * as ConsultantSearchHandler from '../../handlerModels/ConsultantSearchHandler';
import * as GetActiveCipDetailsHandler from '../../handlerModels/GetActiveCipDetailsHandler';
import CipNumberDetailDTO from '../../models/CipNumberDetailDTO';
import ConsultantDTO from '../../models/ConsultantDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import Guid from '../../utils/Guid';
import DataTable, { DataTableColumnProps, FilterType } from '../datatables/core/DataTable';
import DataTableButtonUtil from '../datatables/core/DataTableButtonUtil';
import CipNumberInput from '../general/CipNumberInput';
import ResetButton from '../buttons/ResetButton';
import SearchButton from '../buttons/SearchButton';

interface SearchParameters {
  firstName: string;
  lastName: string;
  email: string;
  cipNumberId: string;
}

interface ConsultantSearchState extends BaseFormState {
  selectedConsultant?: ConsultantDTO;
  consultants: ConsultantDTO[];
  cipNumberDetails: CipNumberDetailDTO[];
  showModal: boolean;
  searchValues: SearchParameters;
  isCipNumberSelectOpen: boolean;
}

interface ConsultantSearchProps extends BaseFormProps {
}

class ConsultantSearch extends React.Component<ConsultantSearchProps, ConsultantSearchState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _dataTable: DataTable<ConsultantDTO> | undefined;
  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(ConsultantSearchHandler.Request.firstName, {
        name: ConsultantSearchHandler.Request.firstName,
        label: 'First Name',
      })
      .set(ConsultantSearchHandler.Request.lastName, {
        name: ConsultantSearchHandler.Request.lastName,
        label: 'Last Name',
      })
      .set(ConsultantSearchHandler.Request.email, {
        name: ConsultantSearchHandler.Request.email,
        label: 'Email',
      })
      .set(ConsultantSearchHandler.Request.cipNumberId, {
        name: ConsultantSearchHandler.Request.cipNumberId,
        label: 'CIP Number',
      });
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'First Name',
        dataIndex: ConsultantDTO.firstName,
        sorter: true,
        filterType: FilterType.NONE
      },
      {
        title: 'Last Name',
        dataIndex: ConsultantDTO.lastName,
        sorter: true,
        filterType: FilterType.NONE
      },
      {
        title: 'Email',
        dataIndex: ConsultantDTO.email,
        sorter: true,
        filterType: FilterType.NONE
      }
    ] as DataTableColumnProps<any>[];
  };

  constructor(props: ConsultantSearchProps) {
    super(props);

    this.state = {
      consultants: [],
      cipNumberDetails: [],
      showModal: false,
      searchValues: {
        cipNumberId: Guid.Empty(),
        firstName: '',
        lastName: '',
        email: ''
      },
      isCipNumberSelectOpen: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.cipNumberDetails || this.state.cipNumberDetails.length == 0) {
      loaders.push(this.loadCipNumberDetails());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadCipNumberDetails = () => {
    return CipsApiService.getActiveCipDetails()
      .then((results: GetActiveCipDetailsHandler.Result) => {
        if (results) {
          this.setState({
            cipNumberDetails: results.cipNumberDetails ?? []
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  handleReset = () => {
    this._formRef.current?.resetFields();
    this.handleChange();
  }

  handleChange = () => {
    this.setState({
      searchValues: {
        cipNumberId: this._formRef.current?.getFieldValue(ConsultantSearchHandler.Request.cipNumberId),
        firstName: this._formRef.current?.getFieldValue(ConsultantSearchHandler.Request.firstName),
        lastName: this._formRef.current?.getFieldValue(ConsultantSearchHandler.Request.lastName),
        email: this._formRef.current?.getFieldValue(ConsultantSearchHandler.Request.email)
      }
    }, () => this._dataTable?.refresh());
  }

  handleExport = () => {
    const searchValue = {
      cipNumberId: this._formRef.current?.getFieldValue(ConsultantSearchHandler.Request.cipNumberId),
      firstName: this._formRef.current?.getFieldValue(ConsultantSearchHandler.Request.firstName),
      lastName: this._formRef.current?.getFieldValue(ConsultantSearchHandler.Request.lastName),
      email: this._formRef.current?.getFieldValue(ConsultantSearchHandler.Request.email)
    }

    window.open(Routes.generateFull(Routes.USER_CIP_NUMBER_ASSIGNMENT, {}, {
      cipNumberId: searchValue.cipNumberId,
      firstName: searchValue.firstName,
      lastName: searchValue.lastName,
      email: searchValue.email
    }), '_blank')
  }

  private openModal = (consultant: ConsultantDTO) => {
    if (consultant != undefined) {
      this.setState({ showModal: true, selectedConsultant: consultant });
    } else {
      this.setState({ showModal: false, selectedConsultant: undefined });
    }
  }

  private cancelModal = () => {
    this.setState({ showModal: false });
  }

  private handleCipNumberOnClick = () => {
    this.setState({ isCipNumberSelectOpen: !this.state.isCipNumberSelectOpen });
  }

  private handleCipNumberOnBlur = () => {
    this.setState({ isCipNumberSelectOpen: false });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();

    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Default('Export', this.handleExport));

    return (
      <Space direction='vertical'>
        {this.renderConsultantInfoModal()}
        <Typography.Paragraph italic={true}>
          Search for a consultant using the fields below to populate the table below.
        </Typography.Paragraph>

        <Form ref={this._formRef}
          layout="vertical"
          onChange={this.handleChange}
          onFinish={this.handleChange}>
          <Space direction='vertical' size='small'>
            <Row gutter={[16, 16]} >
              <Col {...ColumnWidths.HALF}>
                <Form.Item {...formItems.get(ConsultantSearchHandler.Request.firstName)}>
                  <Input allowClear={true} onChange={this.handleChange} />
                </Form.Item>
              </Col >
              <Col {...ColumnWidths.HALF}>
                <Form.Item {...formItems.get(ConsultantSearchHandler.Request.lastName)}>
                  <Input allowClear={true} onChange={this.handleChange} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]} >
              <Col {...ColumnWidths.HALF}>
                <Form.Item {...formItems.get(ConsultantSearchHandler.Request.email)}>
                  <Input allowClear={true} onChange={this.handleChange} />
                </Form.Item>
              </Col>

              <Col {...ColumnWidths.HALF}>
                <Form.Item {...formItems.get(ConsultantSearchHandler.Request.cipNumberId)}>
                  <CipNumberInput onChange={this.handleChange} />
                </Form.Item>
              </Col>
            </Row>
            < Space direction='horizontal' wrap={true} >
              <SearchButton onClick={this.handleChange} />
              <ResetButton onConfirm={this.handleReset} />
            </Space>
          </Space>
        </Form>

        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          buttonBar={actionButtons}
          onRowClick={this.openModal}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={false}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) =>
              UsersApiService.searchForConsultants(requestState, this.state.searchValues.cipNumberId, this.state.searchValues.firstName, this.state.searchValues.lastName, this.state.searchValues.email)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'consultants_list' }} />
      </Space >
    );
  }

  renderCIPOptions() {
    return this.state.cipNumberDetails.map(x => { return this.renderCIPOption(x) });
  }

  renderCIPOption(cip: CipNumberDetailDTO): any {
    if (cip.id) {
      return <Select.Option key={cip.cipNumberId ?? Guid.Empty()} value={cip.cipNumberId ?? Guid.Empty()} >{cip.friendlyDisplay}</Select.Option>;
    }
  }

  renderConsultantInfoModal() {
    return (
      <Modal
        visible={this.state.showModal}
        width={1000}
        title={this.state.selectedConsultant?.fullName}
        okButtonProps={{ hidden: true }}
        footer={null}
        onCancel={this.cancelModal}>

        <Space direction='vertical'>
          <Descriptions bordered={true} layout='vertical' size='small' column={2}>
            <Descriptions.Item label='Name'>{this.state.selectedConsultant?.fullName}</Descriptions.Item>
            <Descriptions.Item label='Email'>{this.state.selectedConsultant?.email}</Descriptions.Item>
            <Descriptions.Item label='Assigned CIP Numbers' span={2}>
              <Table dataSource={this.state.selectedConsultant?.cipNumbers ?? []}
                pagination={false}
                scroll={{ x: 500, y: 500 }}
                rowKey={CipNumberDetailDTO.id}>
                <Table.Column title='CIP Number' width={100} dataIndex={CipNumberDetailDTO.display} />
                <Table.Column title='Title' dataIndex={CipNumberDetailDTO.title} />
              </Table>
            </Descriptions.Item>
          </Descriptions>
        </Space>
      </Modal>
    );
  }
}

export default ConsultantSearch;
