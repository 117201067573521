import * as React from 'react';
import CreditTypesApiService from '../../../api/CreditTypesApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import CreditTypeDTO from '../../../models/CreditTypeDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface CreditTypeDataTableProps {
}

interface CreditTypeDataTableState extends BaseDataTableState<CreditTypeDTO> {
}

class CreditTypeDataTable extends React.Component<CreditTypeDataTableProps, CreditTypeDataTableState> {
  private _dataTable: DataTable<CreditTypeDTO> | undefined;

  constructor(props: CreditTypeDataTableProps) {
    super(props);
    this.state = {
    };

  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Order',
        dataIndex: CreditTypeDTO.displayOrder,
        sorter: true,
        filterType: FilterType.Text,
        width: 75,
      },
      {
        title: 'Name',
        dataIndex: CreditTypeDTO.name,
        sorter: true,
        filterType: FilterType.Text,
      },
    ] as DataTableColumnProps<any>[];
  };

  private openCreditType = (record: CreditTypeDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.CREDIT_TYPE_DETAILS, { id: record.id }, {}));
    }
  }

  private addCreditType = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_CREDIT_TYPE));
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return CreditTypesApiService.exportCreditTypeList(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`CreditType_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CREDIT_TYPE])) {
      actionButtons.push(DataTableButtonUtil.Default('New Credit Type', () => this.addCreditType()));
    }

    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openCreditType}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => CreditTypesApiService.getCreditTypeTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'credit_types_list' }} />
    );
  }
}

export default CreditTypeDataTable;
