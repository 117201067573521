import Skeleton from 'antd/lib/skeleton';
import * as React from 'react';
import { useEffect, useState } from 'react';
import LookupsApiService from '../../../api/LookupsApiService';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import Routes from '../../../config/Routes';
import * as GetActionPlanReportDataHandler from '../../../handlerModels/GetActionPlanReportDataHandler';
import * as GetServiceAreasHandler from '../../../handlerModels/GetServiceAreasHandler';
import ActionPlanReportDTO from '../../../models/ActionPlanReportDTO';
import LookupTableDTO from '../../../models/LookupTableDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import SelfStudyResponsePreviousGoalDTO from '../../../models/SelfStudyResponsePreviousGoalDTO';
import SelfStudyResponseStatusTypeDTO from '../../../models/SelfStudyResponseStatusTypeDTO';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface ActionPlanReportDataTableProps {
  academicYear?: number;
}

function ActionPlanReportDataTable(props: ActionPlanReportDataTableProps) {
  let _dataTable: DataTable<ActionPlanReportDTO> | undefined = undefined;

  const [statusTypes, setStatusTypes] = useState<LookupTableDTO[]>([]);
  const [rpps, setRPPs] = useState<RegionalPlanningPartnerDTO[]>([]);
  const [serviceAreas, setServiceAreas] = useState<ServiceAreaDTO[]>([]);
  const [dataRows, setDataRows] = useState([] as ActionPlanReportDTO[]);
  const [, setError] = useState(false);

  useEffect(() => {
    RegionalPlanningPartnerApiService.getRegionalPlanningPartners(props.academicYear ?? 0)
      .then(x => {
        setRPPs(x.regions ?? []);
        triggerRefresh();
      });

    LookupsApiService.getAll(SelfStudyResponseStatusTypeDTO.className)
      .then(x => {
        setStatusTypes(x);
        triggerRefresh();
      });

    ServiceAreaApiService.getServiceAreas(props.academicYear ?? 0).then((results: GetServiceAreasHandler.Result) => {
      if (results.serviceAreas) {
        setServiceAreas(results.serviceAreas);
        triggerRefresh();
      }
    });

    loadActionPlanRows();
  }, [props.academicYear]);

  const loadActionPlanRows = () => {
    const request = GetActionPlanReportDataHandler.Request.create({
      academicYearId: props.academicYear,
    });
    SelfStudyApiService.getActionPlanRows(request)
      .then((results: GetActionPlanReportDataHandler.Result) => {
        if (results) {
          setDataRows(results.dataRows ?? []);
        }
      })
      .catch(() => {
        setError(true);
      });
  }

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Status',
        dataIndex: ActionPlanReportDTO.status,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: statusTypes.map(c => { return { text: c.name, value: c.key.toString() } }) as TableFilterOption[],
        render: (data: string, row: ActionPlanReportDTO) => {
          return statusTypes.find(x => x.key == row.status)?.name;
        },
      },
      {
        title: 'RPP',
        dataIndex: ActionPlanReportDTO.rppId,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: rpps.map(c => { return { text: c.regionalPlanningPartnerIdentifier, value: c.regionalPlanningPartnerIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: ActionPlanReportDTO) => {
          return rpps.find(x => x.regionalPlanningPartnerIdentifier == row.rppId)?.name;
        },
      },
      {
        title: 'Service Area',
        dataIndex: ActionPlanReportDTO.serviceArea,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: serviceAreas.map(c => { return { text: c.serviceAreaIdentifier.toString(), value: c.serviceAreaIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: ActionPlanReportDTO) => {
          return serviceAreas.find(x => x.serviceAreaIdentifier == row.serviceArea)?.description;
        },
      },
      {
        title: 'District',
        dataIndex: ActionPlanReportDTO.district,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'CIP Number',
        dataIndex: ActionPlanReportDTO.cip,
        sorter: true,
        filterType: FilterType.Text,
        width: 200
      },
      {
        title: 'Program',
        dataIndex: ActionPlanReportDTO.program,
        sorter: true,
        filterType: FilterType.Text
      },
    ] as DataTableColumnProps<any>[];

    const prevGoalsCount = Math.max(...dataRows.flatMap(row => row.previousGoals?.length ?? 0));

    if (prevGoalsCount != undefined || prevGoalsCount != Infinity && prevGoalsCount > 0) {
      for (let i = 0; i < prevGoalsCount; i++) {
        const count = i + 1;
        columns.pushAll(
          [
            {
              title: 'Previous Goal ' + count,
              dataIndex: 'previous_' + SelfStudyResponsePreviousGoalDTO.section + i,
              render: (data: string, row: ActionPlanReportDTO) => {
                if (row.previousGoals != null && row.previousGoals[i] != null) {
                  return row.previousGoals[i].section
                }
              },
              sorter: false,
              filterType: FilterType.NONE
            },
            {
              title: 'Previous Goal ' + count + ': Action Plan',
              dataIndex: 'previous_' + SelfStudyResponsePreviousGoalDTO.actionStepsAndTimeline + i,
              render: (data: string, row: ActionPlanReportDTO) => {
                if (row.previousGoals != null && row.previousGoals[i] != null) {
                  return row.previousGoals[i].actionStepsAndTimeline
                }
              },
              sorter: false,
              filterType: FilterType.NONE
            },
            {
              title: 'Previous Goal ' + count + ': Measure of Success',
              dataIndex: 'previous_' + SelfStudyResponsePreviousGoalDTO.measureOfSuccess + i,
              render: (data: string, row: ActionPlanReportDTO) => {
                if (row.previousGoals != null && row.previousGoals[i] != null) {
                  return row.previousGoals[i].measureOfSuccess
                }
              },
              sorter: false,
              filterType: FilterType.NONE
            },
            {
              title: 'Previous Goal ' + count + ': Results',
              dataIndex: 'previous_' + SelfStudyResponsePreviousGoalDTO.results + i,
              render: (data: string, row: ActionPlanReportDTO) => {
                if (row.previousGoals != null && row.previousGoals[i] != null) {
                  return row.previousGoals[i].results
                }
              },
              sorter: false,
              filterType: FilterType.NONE
            }]
        );
      }
    }

    const newGoalsCount = Math.max(...dataRows.flatMap(row => row.newGoals?.length ?? 0));

    if (newGoalsCount != undefined || newGoalsCount != Infinity && newGoalsCount > 0) {
      for (let i = 0; i < newGoalsCount; i++) {
        const count = i + 1;
        columns.pushAll(
          [
            {
              title: 'New Goal ' + count,
              dataIndex: ActionPlanReportDTO.newGoals,
              render: (data: string, row: ActionPlanReportDTO) => {
                if (row.newGoals != null && row.newGoals[i] != null) {
                  return row.newGoals[i].section
                }
              },
              sorter: false,
              filterType: FilterType.NONE
            },
            {
              title: 'New Goal ' + count + ': Action Plan',
              dataIndex: ActionPlanReportDTO.newGoals,
              render: (data: string, row: ActionPlanReportDTO) => {
                if (row.newGoals != null && row.newGoals[i] != null) {
                  return row.newGoals[i].actionPlan
                }
              },
              sorter: false,
              filterType: FilterType.NONE
            },
            {
              title: 'New Goal ' + count + ': Measure of Success',
              dataIndex: ActionPlanReportDTO.newGoals,
              render: (data: string, row: ActionPlanReportDTO) => {
                if (row.newGoals != null && row.newGoals[i] != null) {
                  return row.newGoals[i].measureOfSuccess
                }
              },
              sorter: false,
              filterType: FilterType.NONE
            }]
        );
      }
    }

    return columns;
  };

  const actionPlanReport = () => {
    window.open(Routes.generateFull(Routes.SELF_STUDY_ACTION_PLAN_REPORT, {}, { academicYear: props.academicYear }), '_blank')
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  actionButtons.push(DataTableButtonUtil.Default('Export', actionPlanReport));

  if (rpps.length == 0 && statusTypes.length == 0 && serviceAreas.length == 0) {
    return <Skeleton />
  }

  return (
    <DataTable
      // @ts-ignore
      ref={(element: any) => (_dataTable = element)}
      serverSide={true}
      tableProps={{
        rowKey: 'id',
        scroll: { x: 500 }
      }}
      globalSearch={true}
      buttonBar={actionButtons}
      columns={getColumnDefinitions()}
      fetchData={{
        fetch: (requestState: TableRequestDTO) => SelfStudyApiService.getActionPlanReport(requestState, props.academicYear ?? 0)
      }}
      stateSaving={{ enabled: true, tableUniqueKey: 'action_plan_report_list' }} />
  );
}

export default ActionPlanReportDataTable;
