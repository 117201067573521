// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import InstitutionUserDTO from '../models/InstitutionUserDTO';
import * as SearchForUsersHandler from '../handlerModels/SearchForUsersHandler';
import * as SearchForUsersForNoticeOfIntentHandler from '../handlerModels/SearchForUsersForNoticeOfIntentHandler';
import * as SearchForConsultantsHandler from '../handlerModels/SearchForConsultantsHandler';
import * as GetInstitutionUsersHandler from '../handlerModels/GetInstitutionUsersHandler';
import * as GetRoleStatusesForInstitutionUserHandler from '../handlerModels/GetRoleStatusesForInstitutionUserHandler';
import * as GetInstitutionUserDetailsHandler from '../handlerModels/GetInstitutionUserDetailsHandler';
import * as AddInstitutionUserHandler from '../handlerModels/AddInstitutionUserHandler';
import * as RemoveInstitutionUserHandler from '../handlerModels/RemoveInstitutionUserHandler';

export class InstitutionUsersApiService extends BaseApi {

    // post: api/institutionUsers?institutionId=${encodeURIComponent(institutionId)}
    public getInstitutionUserTableData(tableRequest: TableRequestDTO, institutionId: string): Promise<TableResponseDTO<InstitutionUserDTO>> {
        let url = `api/institutionUsers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstitutionUserDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutionUsers/institutionsForUser?userId=${encodeURIComponent(userId)}
    public getInstitutionsForUserTableData(tableRequest: TableRequestDTO, userId: string): Promise<TableResponseDTO<InstitutionUserDTO>> {
        let url = `api/institutionUsers/institutionsForUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstitutionUserDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutionUsers/export?institutionId=${encodeURIComponent(institutionId)}
    public getInstitutionUsersDataTableExport(tableRequest: TableRequestDTO, institutionId: string): Promise<string> {
        let url = `api/institutionUsers/export`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutionUsers/searchUsers?searchText=${encodeURIComponent(searchText)}&institutionId=${encodeURIComponent(institutionId)}
    public searchForUsersNotInInstitution(searchText: string, institutionId: string): Promise<SearchForUsersHandler.Result> {
        let url = `api/institutionUsers/searchUsers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (searchText != null) {
            url += `${prefix}searchText=${encodeURIComponent(searchText)}`;
            prefix = '&';
        }
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForUsersHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionUsers/getUsers
    public searchForUsers(searchForUsersForNoticeOfIntent: SearchForUsersForNoticeOfIntentHandler.Request): Promise<SearchForUsersForNoticeOfIntentHandler.Result> {
        let url = `api/institutionUsers/getUsers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForUsersForNoticeOfIntentHandler.Result>(searchForUsersForNoticeOfIntent, 'post', url, true, false);
    }

    // post: api/institutionUsers/getConsultants
    public searchForConsultants(searchForConsultants: SearchForConsultantsHandler.Request): Promise<SearchForConsultantsHandler.Result> {
        let url = `api/institutionUsers/getConsultants`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForConsultantsHandler.Result>(searchForConsultants, 'post', url, true, false);
    }

    // post: api/institutionUsers/getAllUsers
    public getUsersNotInContact(getInstitutionUsers: GetInstitutionUsersHandler.Request): Promise<GetInstitutionUsersHandler.Result> {
        let url = `api/institutionUsers/getAllUsers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionUsersHandler.Result>(getInstitutionUsers, 'post', url, true, false);
    }

    // post: api/institutionUsers/getInstitutionRoles?userId=${encodeURIComponent(userId)}&institutionId=${encodeURIComponent(institutionId)}
    public getInstitutionRolesForInsitutionUser(userId: string, institutionId: string): Promise<GetRoleStatusesForInstitutionUserHandler.Result> {
        let url = `api/institutionUsers/getInstitutionRoles`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetRoleStatusesForInstitutionUserHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionUsers/getInstitutionUser?userId=${encodeURIComponent(userId)}&institutionId=${encodeURIComponent(institutionId)}
    public getInstitutionUserDetails(userId: string, institutionId: string): Promise<GetInstitutionUserDetailsHandler.Result> {
        let url = `api/institutionUsers/getInstitutionUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionUserDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionUsers/addInstitutionUser
    public addInstitutionUser(addInstitutionUser: AddInstitutionUserHandler.Request): Promise<AddInstitutionUserHandler.Result> {
        let url = `api/institutionUsers/addInstitutionUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddInstitutionUserHandler.Result>(addInstitutionUser, 'post', url, true, false);
    }

    // post: api/institutionUsers/removeInstitutionUser
    public removeInstitutionUser(removeInstitutionUser: RemoveInstitutionUserHandler.Request): Promise<RemoveInstitutionUserHandler.Result> {
        let url = `api/institutionUsers/removeInstitutionUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveInstitutionUserHandler.Result>(removeInstitutionUser, 'post', url, true, false);
    }
}
var service = new InstitutionUsersApiService();
export default service;
