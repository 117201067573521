import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RolesDataTable from '../../../components/datatables/RolesDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import HeaderPortal from '../../../portals/HeaderPortal';
import HistoryUtil from '../../../utils/HistoryUtil';

interface RoleListProps {
}

interface RoleListState {
}

class RoleListPage extends React.Component<RoleListProps & RouteComponentProps<RouteObject>, RoleListState> {
  constructor(props: RoleListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Roles" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.roleList()} />

        <Card >
          <RolesDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(RoleListPage);
