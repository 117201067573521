import { Card, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ConfirmAndContinueButton from '../../../components/buttons/ConfirmAndContinueButton';
import PreviousButton from '../../../components/buttons/PreviousButton';
import FederalClusterDataTable from '../../../components/datatables/secondary/FederalClusterDataTable';
import DataReviewLayout from '../../../components/secondary/DataReviewLayout';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface FederalClusterListPageState {
  academicYear: number;
}

interface FederalClusterListPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class DataReviewFederalClusterListPage extends React.Component<FederalClusterListPageProps & RouteComponentProps<RouteObject>, FederalClusterListPageState> {

  constructor(props: FederalClusterListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });
    }
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SERVICE_AREAS, { academicYearId: this.state.academicYear }, {}));
  }

  private onContinue = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SECONDARY_PROGRAM_TYPES, { academicYearId: this.state.academicYear }, {}));
  }

  render() {
    const academicYear = this.state.academicYear == 0 ? 0 : this.state.academicYear;

    if (academicYear != 0) {
      return (
        <Content >
          <DataReviewLayout breadcrumbs={Breadcrumbs.academicYearDataReview(academicYear)} stepNumber={2} academicYear={academicYear}>
            <Card title="Federal Clusters">
              <Space direction='vertical' size="middle">
                <FederalClusterDataTable academicYear={this.state.academicYear} />
                <Space>
                  <PreviousButton onClick={this.onBack} />
                  <ConfirmAndContinueButton onClick={this.onContinue} />
                </Space>
              </Space>
            </Card>
          </DataReviewLayout>
        </Content>
      );
    }

    return <Skeleton />;
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(DataReviewFederalClusterListPage));
