import * as React from 'react';
import GenderApiService from '../../api/GenderApiService';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import GenderDataTableDTO from '../../models/GenderDataTableDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import DateUtil from '../../utils/DateTimeUtil';
import FileDownload from '../../utils/FileDownload';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from './core/DataTable';
import DataTableButtonUtil from './core/DataTableButtonUtil';

interface GenderDataTableProps extends BaseFormProps {
}

interface GenderDataTableState extends BaseDataTableState<GenderDataTableDTO>, BaseFormState {
}

class GenderDataTable extends React.Component<GenderDataTableProps, GenderDataTableState> {
  private _dataTable: DataTable<GenderDataTableDTO> | undefined;

  constructor(props: GenderDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Abbreviation',
        dataIndex: GenderDataTableDTO.abbreviation,
        sorter: true,
        filterType: FilterType.Text,
        width: 85
      },
      {
        title: 'Name',
        dataIndex: GenderDataTableDTO.name,
        sorter: true,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];
  };

  private openGender = (record: GenderDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.GENDER_DETAILS, { id: record.id }, {}));
    }
  }

  private addGender = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_GENDER));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return GenderApiService.getGenderDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Gender_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_GENDER])) {
      actionButtons.push(DataTableButtonUtil.Default('New Gender', () => this.addGender()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openGender}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => GenderApiService.getGendersDataTable(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'gender_list' }} />
      </>
    );
  }
}

export default GenderDataTable;
