// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CipNumberDTO from './CipNumberDTO';
import IowaVariationDTO from './IowaVariationDTO';
import InstitutionDTO from './InstitutionDTO';
import YearTermDTO from './YearTermDTO';
import AwardTypeDTO from './AwardTypeDTO';
import StatusTypeDTO from './StatusTypeDTO';
import ProgramClassificationDTO from './ProgramClassificationDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramDataTableDTO extends EntitySelectorDTO { 
	id: string | null;
	programId: string | null;
	cipNumberId: string | null;
	cipNumber: CipNumberDTO | null;
	iowaVariationId: number;
	iowaVariation: IowaVariationDTO | null;
	title: string | null;
	institutionId: string | null;
	isTransferMajor: boolean;
	institution: InstitutionDTO | null;
	install: YearTermDTO | null;
	retire: YearTermDTO | null;
	awardTypeIds: number[];
	awards: AwardTypeDTO[] | null;
	status: StatusTypeDTO | null;
	programClassification: ProgramClassificationDTO | null;
}

const ProgramDataTableDTO = {
    className: 'ProgramDataTableDTO',
    create: (initValues?: Partial<ProgramDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			programId: '00000000-0000-0000-0000-000000000000',
			cipNumberId: '00000000-0000-0000-0000-000000000000',
			cipNumber: null,
			iowaVariationId: 0,
			iowaVariation: null,
			title: "",
			institutionId: '00000000-0000-0000-0000-000000000000',
			isTransferMajor: false,
			institution: null,
			install: null,
			retire: null,
			awardTypeIds: [],
			awards: [],
			status: null,
			programClassification: null,
        },
        initValues);
    },
	id: 'id',
	programId: 'programId',
	cipNumberId: 'cipNumberId',
	cipNumber: 'cipNumber',
	iowaVariationId: 'iowaVariationId',
	iowaVariation: 'iowaVariation',
	title: 'title',
	institutionId: 'institutionId',
	isTransferMajor: 'isTransferMajor',
	institution: 'institution',
	install: 'install',
	retire: 'retire',
	awardTypeIds: 'awardTypeIds',
	awards: 'awards',
	status: 'status',
	programClassification: 'programClassification',
};

export default ProgramDataTableDTO;