// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetInstitutionPresidentDetailsHandler from '../handlerModels/GetInstitutionPresidentDetailsHandler';
import * as SaveInstitutionPresidentDetailsHandler from '../handlerModels/SaveInstitutionPresidentDetailsHandler';

export class InstitutionPresidentApiService extends BaseApi {

    // post: api/institutionPresident/${encodeURIComponent(id)}
    public getInstitutionPresidentDetails(id: string): Promise<GetInstitutionPresidentDetailsHandler.Result> {
        let url = `api/institutionPresident/${encodeURIComponent(id)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionPresidentDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionPresident/save
    public saveInstitutionPresident(saveInstitutionPresidentDetails: SaveInstitutionPresidentDetailsHandler.Request): Promise<SaveInstitutionPresidentDetailsHandler.Result> {
        let url = `api/institutionPresident/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveInstitutionPresidentDetailsHandler.Result>(saveInstitutionPresidentDetails, 'post', url, true, false);
    }
}
var service = new InstitutionPresidentApiService();
export default service;
