import * as React from 'react';
import RolesApiService from '../../api/RolesApiService';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import RoleDTO from '../../models/RoleDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import DateTimeUtil from '../../utils/DateTimeUtil';
import FileDownload from '../../utils/FileDownload';
import HistoryUtil from '../../utils/HistoryUtil';
import TableFilterUtil, { TableFilterOption } from '../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from './core/DataTable';
import DataTableButtonUtil from './core/DataTableButtonUtil';
import TableRequestDTO from './core/models/TableRequestDTO';

interface RolesDataTableProps {
}

interface RolesDataTableState extends BaseDataTableState<RoleDTO> {
  roleTypes: TableFilterOption[]
}

class RolesDataTable extends React.Component<RolesDataTableProps, RolesDataTableState> {
  private _dataTable: DataTable<RoleDTO> | undefined;

  constructor(props: RolesDataTableProps) {
    super(props);
    this.state = {
      roleTypes: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    TableFilterUtil.RoleTypes().then(x => this.setState({ roleTypes: x }));
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Name',
        dataIndex: RoleDTO.name,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Role Type',
        dataIndex: RoleDTO.roleTypeId,
        sorter: true,
        render: (data: string, row: RoleDTO) => {
          return row.roleType?.name;
        },
        filterType: FilterType.DropdownMulti,
        width: 175,
        dropdownFilterOptions: this.state.roleTypes,
      }
    ] as DataTableColumnProps<any>[];
  };

  private openRole = (record: RoleDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.ROLE_DETAILS, { id: record.id }, {}));
    }
  }

  private addRole = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_ROLE));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return RolesApiService.exportRolesList(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Roles_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );
    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_ROLE])) {
      actionButtons.push(DataTableButtonUtil.Default('New Role', () => this.addRole()));
    }

    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openRole}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => RolesApiService.getRolesTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'role_list' }} />
    );
  }
}

export default RolesDataTable;
