import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import * as React from 'react';
import ResourceApiService from '../../../api/ResourceApiService';
import * as RemoveResourceFileHandler from '../../../handlerModels/RemoveResourceFileHandler';
import ResourceFileDTO from '../../../models/ResourceFileDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import Guid from '../../../utils/Guid';
import AddResourceFileForm from '../../forms/postSecondary/AddResourceFileForm';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FileDownloadLink from '../../general/FileDownloadLink';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';

interface ResourceFileDataTableProps {
  triggerUpdate?: () => void;
}

interface ResourceFileDataTableState extends BaseFormState, BaseDataTableState<ResourceFileDTO> {
  showModal: boolean;
  resourceFile: ResourceFileDTO;
  resourceCategories: TableFilterOption[];
}

class ResourceFileDataTable extends React.Component<ResourceFileDataTableProps, ResourceFileDataTableState> {
  private _dataTable: DataTable<ResourceFileDTO> | undefined;
  private readonly _addResourceFileFormRef = React.createRef<AddResourceFileForm>();

  constructor(props: ResourceFileDataTableProps) {
    super(props);
    this.state = ({
      showModal: false,
      resourceFile: ResourceFileDTO.create(),
      resourceCategories: []
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    TableFilterUtil.ResourceCategories().then(x => this.setState({ resourceCategories: x }));
  }

  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Title',
        dataIndex: ResourceFileDTO.title,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'File',
        dataIndex: ResourceFileDTO.azureBlobFileId,
        sorter: true,
        render: (data: string, row: ResourceFileDTO) => {
          return (
            <FileDownloadLink
              key={row.azureBlobFileId}
              azureBlobFileId={row.azureBlobFileId ?? Guid.Empty()}
              text={row?.azureBlobFile?.fileName ?? 'Unknown'} />
          );
        },
        filterType: FilterType.Text,
      },
      {
        title: 'Category',
        dataIndex: ResourceFileDTO.categoryTitle,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.resourceCategories
      },
      {
        title: '',
        dataIndex: ResourceFileDTO.id,
        width: '25px',
        render: (data: string, row: ResourceFileDTO) => {
          return <>
            {<Button type="link" className="ant-btn" onClick={() => this.addResource(row)}><EditOutlined /></Button>}
            {<Button type="link" className="ant-btn" onClick={() => this.promptConfirmRemove(row.id ?? Guid.Empty())}><DeleteOutlined /></Button>}
          </>
        },
        sorter: false,
        filterType: FilterType.NONE
      }
    ] as DataTableColumnProps<any>[];
  };

  private handleRemoveResource = (resourceFileId: string | null) => {
    this.setState({ submitting: true });

    const request = RemoveResourceFileHandler.Request.create({
      resourceFileId: resourceFileId ?? Guid.Empty()
    });

    ResourceApiService.removeResourceFile(request)
      .then((result: RemoveResourceFileHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Resource was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Resource could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.triggerRefresh();
      });
  }

  private promptConfirmRemove = (resourceFileId: string | null) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this resource?',
      okText: 'Remove Resource',
      onOk: () => this.handleRemoveResource(resourceFileId)
    });
  }

  private addResource = (record: ResourceFileDTO) => {
    this.setState({ resourceFile: record }, () => this.openModal());
  }

  private openModal = () => {
    this.setState({ showModal: true });
  }

  private submitResourceChange = () => {
    this._addResourceFileFormRef.current?.submit();
  }

  private cancelResourceChange = () => {
    this.setState({ showModal: false, resourceFile: ResourceFileDTO.create() },
      () => this._addResourceFileFormRef.current?.reset());
  }

  private resourceAdded = () => {
    this.setState({ showModal: false });
    this._dataTable?.refresh();
  }

  render() {
    return (
      <>
        {this.renderAddResourceModal()}
        {this.renderDataTable()}
      </>
    );
  }

  renderAddResourceModal() {
    return (
      <Modal
        visible={this.state.showModal}
        title='Resource File'
        okText='Save'
        onOk={this.submitResourceChange}
        onCancel={this.cancelResourceChange}>
        <AddResourceFileForm
          ref={this._addResourceFileFormRef}
          onSuccess={this.resourceAdded}
          resourceFile={this.state.resourceFile} />
      </Modal>
    );
  }


  renderDataTable() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(DataTableButtonUtil.Default('Add Resource', () => this.addResource(ResourceFileDTO.create())));

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => ResourceApiService.getResourceFileTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'resource_link_list' }} />
    );
  }

}

export default ResourceFileDataTable;
