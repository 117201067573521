import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import FederalClusterDetailsForm from '../forms/secondary/FederalClusterDetailsForm';


interface FederalClusterDetailsModalProps extends ModalProps {
  federalClusterId: string;
  academicYear: number;
  onSave?: () => void;
}

function FederalClusterDetailsModal(props: FederalClusterDetailsModalProps) {
  const _federalClusterDetailsRef = React.createRef<FederalClusterDetailsForm>();

  const isNew = props.federalClusterId == Guid.Empty();

  const submitSaveFederalCluster = () => {
    _federalClusterDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New Federal Cluster' : 'Edit Federal Cluster'}
      okText='Save'
      onOk={submitSaveFederalCluster}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <FederalClusterDetailsForm
        ref={_federalClusterDetailsRef}
        federalClusterId={props.federalClusterId ?? Guid.Empty()}
        isEditing={true}
        onSave={props.onSave}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default FederalClusterDetailsModal;