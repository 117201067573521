import * as React from 'react';
import ThirdPartyCredentialTypeApiService from '../../api/ThirdPartyCredentialTypeApiService';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import ThirdPartyCredentialTypeDTO from '../../models/ThirdPartyCredentialTypeDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import DateUtil from '../../utils/DateTimeUtil';
import FileDownload from '../../utils/FileDownload';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from './core/DataTable';
import DataTableButtonUtil from './core/DataTableButtonUtil';

interface ThirdPartyCredentialTypeDataTableProps extends BaseFormProps {
}

interface ThirdPartyCredentialTypeDataTableState extends BaseDataTableState<ThirdPartyCredentialTypeDTO>, BaseFormState {
}

class ThirdPartyCredentialTypeDataTable extends React.Component<ThirdPartyCredentialTypeDataTableProps, ThirdPartyCredentialTypeDataTableState> {
  private _dataTable: DataTable<ThirdPartyCredentialTypeDTO> | undefined;

  constructor(props: ThirdPartyCredentialTypeDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Name',
        dataIndex: ThirdPartyCredentialTypeDTO.name,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Enabled For Post Secondary Program',
        dataIndex: ThirdPartyCredentialTypeDTO.enabledForPostSecondaryProgram,
        render: (data: string, row: ThirdPartyCredentialTypeDTO) => {
          return row.enabledForPostSecondaryProgram ? 'Yes' : '';
        },
        sorter: true,
        filterType: FilterType.DropdownSingle,
        dropdownFilterOptions: [{ value: 'enabled', text: 'Yes' }, { value: 'diabled', text: 'No' }],
      },
      {
        title: 'Enabled For Secondary Program',
        dataIndex: ThirdPartyCredentialTypeDTO.enabledForSecondaryProgram,
        render: (data: string, row: ThirdPartyCredentialTypeDTO) => {
          return row.enabledForSecondaryProgram ? 'Yes' : '';
        },
        sorter: true,
        filterType: FilterType.DropdownSingle,
        dropdownFilterOptions: [{ value: 'enabled', text: 'Yes' }, { value: 'disabled', text: 'No' }],
      }
    ] as DataTableColumnProps<any>[];
  };

  private openThirdPartyCredentialType = (record: ThirdPartyCredentialTypeDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.THIRD_PARTY_CREDENTIAL_TYPE_DETAILS, { id: record.id }, {}));
    }
  }

  private addThirdPartyCredentialType = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_THIRD_PARTY_CREDENTIAL_TYPE));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return ThirdPartyCredentialTypeApiService.getThirdPartyCredentialTypeDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`ThirdPartyCredentialType_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_THIRD_PARTY_CREDENTIAL_TYPE])) {
      actionButtons.push(DataTableButtonUtil.Default('New Third Party Credential Type', () => this.addThirdPartyCredentialType()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openThirdPartyCredentialType}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => ThirdPartyCredentialTypeApiService.getThirdPartyCredentialTypeTableData(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'thirdPartyCredentialType_list' }} />
      </>
    );
  }
}

export default ThirdPartyCredentialTypeDataTable;
