import { Card, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ContactDataTable from '../../../components/datatables/postSecondary/ContactDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import InstitutionDTO from '../../../models/InstitutionDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import Guid from '../../../utils/Guid';

interface ContactListPageProps {
  institution: InstitutionDTO | null;
}

interface ContactListPageState {
}

class ContactListPage extends React.Component<ContactListPageProps & RouteComponentProps<RouteObject>, ContactListPageState> {
  constructor(props: ContactListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <Content >
        <HeaderPortal title="Contacts" breadcrumbs={Breadcrumbs.contactList()} />
        <Space direction='vertical' size='large'>
          <Card>
            <ContactDataTable institutionId={this.props.institution?.id ?? Guid.Empty()} />
          </Card>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ContactListPage));
