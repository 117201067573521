import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import InstructionalLevelApiService from '../../../../api/InstructionalLevelApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import InstructionalLevelDetailsForm from '../../../../components/forms/postSecondary/InstructionalLevelDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteInstructionalLevelHandler from '../../../../handlerModels/DeleteInstructionalLevelHandler';
import * as GetInstructionalLevelDetailsHandler from '../../../../handlerModels/GetInstructionalLevelDetailsHandler';
import InstructionalLevelDTO from '../../../../models/InstructionalLevelDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface InstructionalLevelDetailsPageState extends BaseFormState {
  instructionalLevelId: number;
  instructionalLevel?: InstructionalLevelDTO;
}

interface InstructionalLevelDetailsPageProps {
}

class InstructionalLevelDetailsPage extends React.Component<InstructionalLevelDetailsPageProps & RouteComponentProps<RouteObject>, InstructionalLevelDetailsPageState> {
  private readonly _detailsRef = React.createRef<InstructionalLevelDetailsForm>();

  constructor(props: InstructionalLevelDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      instructionalLevelId: 0
    };
  }

  componentDidMount() {
    const instructionalLevelId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (instructionalLevelId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const instructionalLevelIdNumber = Number.parseInt(instructionalLevelId);
      this.loadInstructionalLevel(instructionalLevelIdNumber);
    }
  }

  private loadInstructionalLevel = (instructionalLevelId: number) => {
    this.setState({ instructionalLevelId: instructionalLevelId });

    InstructionalLevelApiService.getInstructionalLevelDetails(instructionalLevelId)
      .then((results: GetInstructionalLevelDetailsHandler.Result) => {
        if (results.instructionalLevel) {
          this.setState({ instructionalLevel: results.instructionalLevel });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._detailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private specialEmphasisDetailsSaved = (instructionalLevelId: number) => {
    this.setState({ isEditing: false });
    if (instructionalLevelId != this.state.instructionalLevelId) {
      HistoryUtil.replace(Routes.generate(Routes.INSTRUCTIONAL_LEVEL_DETAILS, { id: instructionalLevelId }));
    }
    else {
      this.loadInstructionalLevel(instructionalLevelId);
    }
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Instructional Level?',
      okText: 'Delete',
      onOk: () => this.deleteInstructionalLevel(id)
    });
  }

  private deleteInstructionalLevel = (instructionalLevelId: number) => {
    this.setState({ submitting: true });

    const request = DeleteInstructionalLevelHandler.Request.create({
      instructionalLevelId: instructionalLevelId,
    });

    InstructionalLevelApiService.deleteInstructionalLevel(request)
      .then((result: DeleteInstructionalLevelHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Instructional level was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Instructional level could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToInstructionalLevel();
      });
  }

  private goToInstructionalLevel = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.instructionalLevelId == 0 ? 'New Instructional Level' : this.state.instructionalLevel?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToInstructionalLevel}
          breadcrumbs={Breadcrumbs.instructionalLevelDetails(title, this.state.instructionalLevelId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.instructionalLevelId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <InstructionalLevelDetailsForm
          ref={this._detailsRef}
          instructionalLevelId={this.state.instructionalLevelId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.specialEmphasisDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Instructional Level' key={this.state.instructionalLevelId} onClick={() => this.deleteConfirmation(this.state.instructionalLevelId)}  >
          Delete Instructional Level
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(InstructionalLevelDetailsPage);
