import * as React from 'react';
import APSGeneralEducationApiService from '../../../api/APSGeneralEducationApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import APSGeneralEducationDTO from '../../../models/APSGeneralEducationDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface APSGeneralEducationDataTableProps extends BaseFormProps {
}

interface APSGeneralEducationDataTableState extends BaseDataTableState<APSGeneralEducationDTO>, BaseFormState {
}

class APSGeneralEducationDataTable extends React.Component<APSGeneralEducationDataTableProps, APSGeneralEducationDataTableState> {
  private _dataTable: DataTable<APSGeneralEducationDTO> | undefined;

  constructor(props: APSGeneralEducationDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Display',
        dataIndex: APSGeneralEducationDTO.displayOrder,
        width: 50,
        sorter: true,
        filterType: FilterType.NONE
      },
      {
        title: 'Code',
        dataIndex: APSGeneralEducationDTO.code,
        width: 50,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Name',
        dataIndex: APSGeneralEducationDTO.name,
        sorter: true,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];
  };

  private openAPSGeneralEducation = (record: APSGeneralEducationDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.APS_GENERAL_EDUCATION_DETAILS, { id: record.id }, {}));
    }
  }

  private addAPSGeneralEducation = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_APS_GENERAL_EDUCATION));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return APSGeneralEducationApiService.getAPSGeneralEducationDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`APSGeneralEducation${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_APS_GENERAL_EDUCATION])) {
      actionButtons.push(DataTableButtonUtil.Default('New APS General Education', () => this.addAPSGeneralEducation()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openAPSGeneralEducation}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 400 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => APSGeneralEducationApiService.getAPSGeneralEducationsDataTable(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'apsAPSGeneralEducation_list' }} />
      </>
    );
  }
}

export default APSGeneralEducationDataTable;
