import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import ServiceAreaDetailsForm from '../forms/secondary/ServiceAreaDetailsForm';


interface ServiceAreaDetailsModalProps extends ModalProps {
  serviceAreaId: string;
  academicYear: number;
  onSave?: () => void;
}

function ServiceAreaDetailsModal(props: ServiceAreaDetailsModalProps) {
  const _serviceAreaDetailsRef = React.createRef<ServiceAreaDetailsForm>();

  const isNew = props.serviceAreaId == Guid.Empty();

  const submitSaveServiceArea = () => {
    _serviceAreaDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New Service Area' : 'Edit Service Area'}
      okText='Save'
      onOk={submitSaveServiceArea}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <ServiceAreaDetailsForm
        ref={_serviceAreaDetailsRef}
        serviceAreaId={props.serviceAreaId}
        isEditing={true}
        onSave={props.onSave}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default ServiceAreaDetailsModal;