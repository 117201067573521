// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CourseDTO from './CourseDTO';
import CourseRequisiteTypeDTO from './CourseRequisiteTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NewCourseChangeRequestCourseRequisiteDTO extends EntitySelectorDTO { 
    id: string | null;
    newCourseChangeRequestId: string | null;
    requisiteCourseId: string | null;
    requisiteCourse: CourseDTO | null;
    courseRequisiteTypeId: number;
    courseRequisiteType: CourseRequisiteTypeDTO | null;
}

const NewCourseChangeRequestCourseRequisiteDTO = {
    className: 'NewCourseChangeRequestCourseRequisiteDTO',
    create: (initValues?: Partial<NewCourseChangeRequestCourseRequisiteDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
            id: "00000000-0000-0000-0000-000000000000",
            newCourseChangeRequestId: "00000000-0000-0000-0000-000000000000",
            requisiteCourseId: "00000000-0000-0000-0000-000000000000",
            requisiteCourse: null,
            courseRequisiteTypeId: 0,
            courseRequisiteType: null,
        },
        initValues);
    },
    id: 'id',
    newCourseChangeRequestId: 'newCourseChangeRequestId',
    requisiteCourseId: 'requisiteCourseId',
    requisiteCourse: 'requisiteCourse',
    courseRequisiteTypeId: 'courseRequisiteTypeId',
    courseRequisiteType: 'courseRequisiteType',
};

export default NewCourseChangeRequestCourseRequisiteDTO;