// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import IowaVariationDataTableDTO from '../models/IowaVariationDataTableDTO';
import * as GetIowaVariationDetailsHandler from '../handlerModels/GetIowaVariationDetailsHandler';
import * as SaveIowaVariationDetailsHandler from '../handlerModels/SaveIowaVariationDetailsHandler';
import * as GetIowaVariationHandler from '../handlerModels/GetIowaVariationHandler';
import * as DeleteIowaVariationHandler from '../handlerModels/DeleteIowaVariationHandler';

export class IowaVariationApiService extends BaseApi {

    // post: api/iowaVariation
    public getIowaVariationTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<IowaVariationDataTableDTO>> {
        let url = `api/iowaVariation`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<IowaVariationDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/iowaVariation/export
    public getIowaVariationDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/iowaVariation/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/iowaVariation/${id}
    public getIowaVariationDetails(id: number): Promise<GetIowaVariationDetailsHandler.Result> {
        let url = `api/iowaVariation/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetIowaVariationDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/iowaVariation/save
    public saveIowaVariationDetails(saveIowaVariationDetails: SaveIowaVariationDetailsHandler.Request): Promise<SaveIowaVariationDetailsHandler.Result> {
        let url = `api/iowaVariation/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveIowaVariationDetailsHandler.Result>(saveIowaVariationDetails, 'post', url, true, false);
    }

    // post: api/iowaVariation/getIowaVariations
    public getIowaVariations(): Promise<GetIowaVariationHandler.Result> {
        let url = `api/iowaVariation/getIowaVariations`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetIowaVariationHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/iowaVariation/deleteIowaVariation
    public deleteIowaVariation(deleteIowaVariation: DeleteIowaVariationHandler.Request): Promise<DeleteIowaVariationHandler.Result> {
        let url = `api/iowaVariation/deleteIowaVariation`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteIowaVariationHandler.Result>(deleteIowaVariation, 'post', url, true, false);
    }
}
var service = new IowaVariationApiService();
export default service;
