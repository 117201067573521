// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class SelfStudyResponseStatusType {
  public static readonly PENDING: number = 1;
  public static readonly INPROGRESS: number = 2;
  public static readonly COMPLETE: number = 3;
  public static readonly PENDINGAPPROVAL: number = 4;
  public static readonly REVISING: number = 5;
  public static readonly REVISIONSNEEDED: number = 6;
}
