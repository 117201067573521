import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import NoticeOfIntentChangeRequestWorkForceLinkApiService from '../../../api/NoticeOfIntentChangeRequestWorkForceLinkApiService';
import * as SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler from '../../../handlerModels/SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler';
import NoticeOfIntentChangeRequestWorkForceLinkDTO from '../../../models/NoticeOfIntentChangeRequestWorkForceLinkDTO';
import ResourceCategoryDTO from '../../../models/ResourceCategoryDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';

interface AddNoticeOfIntentChangeRequestWorkForceLinkFormState extends BaseFormState {
  noticeOfIntentChangeRequestWorkForceLink: NoticeOfIntentChangeRequestWorkForceLinkDTO;
}

interface AddNoticeOfIntentChangeRequestWorkForceLinkFormProps extends BaseFormProps {
  onSuccess?: () => void;
  noticeOfIntentChangeRequestWorkForceLink: NoticeOfIntentChangeRequestWorkForceLinkDTO;
}

class AddNoticeOfIntentChangeRequestWorkForceLinkForm extends React.Component<AddNoticeOfIntentChangeRequestWorkForceLinkFormProps, AddNoticeOfIntentChangeRequestWorkForceLinkFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.title, {
      required: true,
      name: SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.title,
      label: 'Title',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.url, {
      required: true,
      name: SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.url,
      label: 'URL (Must include http:// or https://)',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.https(),
      ]
    });

  constructor(props: AddNoticeOfIntentChangeRequestWorkForceLinkFormProps) {
    super(props);

    this.state = {
      noticeOfIntentChangeRequestWorkForceLink: NoticeOfIntentChangeRequestWorkForceLinkDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ noticeOfIntentChangeRequestWorkForceLink: this.props.noticeOfIntentChangeRequestWorkForceLink }, () => this.reset());
  }

  componentDidUpdate(prevProps: AddNoticeOfIntentChangeRequestWorkForceLinkFormProps) {
    if (this.props.noticeOfIntentChangeRequestWorkForceLink && this.props.noticeOfIntentChangeRequestWorkForceLink != prevProps.noticeOfIntentChangeRequestWorkForceLink) {
      this.setState({ noticeOfIntentChangeRequestWorkForceLink: this.props.noticeOfIntentChangeRequestWorkForceLink }, () => this.reset());
    }
  }

  public submit = () => {
    this.handleSubmit();
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    if (!FormUtil.canSubmit(this._formRef, this._formItems)) {
      message.error('Please fill out all required fields.');
      this.setState({ loading: false, submitting: false });
      return;
    }

    const request = SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.create({
      noticeOfIntentChangeRequestWorkForceLinkId: this.props.noticeOfIntentChangeRequestWorkForceLink.id,
      url: this._formRef.current?.getFieldValue(SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.url),
      title: this._formRef.current?.getFieldValue(SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.title),
    });

    NoticeOfIntentChangeRequestWorkForceLinkApiService.saveNoticeOfIntentChangeRequestWorkForceLink(request)
      .then((result: SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSuccess) {
            LookupsUtil.invalidateCache(ResourceCategoryDTO.className);
            this.props.onSuccess();
            this.reset();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Link could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Link could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          initialValues={this.state.noticeOfIntentChangeRequestWorkForceLink}
          layout="vertical"
          onValuesChange={this.handleChange}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.title)}
            {...ValidationUtil.getValidation(SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.title, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.url)}
            {...ValidationUtil.getValidation(SaveNoticeOfIntentChangeRequestWorkForceLinkDetailHandler.Request.url, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>
        </Form >
      </Space >
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AddNoticeOfIntentChangeRequestWorkForceLinkForm;
