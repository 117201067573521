// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionDTO from './InstitutionDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionCourseBlockDataTableDTO extends EntitySelectorDTO { 
	id: string | null;
	code: string | null;
	title: string | null;
	institutionId: string | null;
	institution: InstitutionDTO | null;
	courseCount: number;
}

const InstitutionCourseBlockDataTableDTO = {
    className: 'InstitutionCourseBlockDataTableDTO',
    create: (initValues?: Partial<InstitutionCourseBlockDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			code: "",
			title: "",
			institutionId: '00000000-0000-0000-0000-000000000000',
			institution: null,
			courseCount: 0,
        },
        initValues);
    },
	id: 'id',
	code: 'code',
	title: 'title',
	institutionId: 'institutionId',
	institution: 'institution',
	courseCount: 'courseCount',
};

export default InstitutionCourseBlockDataTableDTO;