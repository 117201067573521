// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveAPSGeneralEducationDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import APSGeneralEducationDTO from '../models/APSGeneralEducationDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    apsGeneralEducationId: number;
    apsGeneralEducation: APSGeneralEducationDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            apsGeneralEducationId: 0,
            apsGeneralEducation: null,
        },
        initValues);
    },
    apsGeneralEducationId: 'apsGeneralEducationId',
    apsGeneralEducation: 'apsGeneralEducation',
}

interface Result extends ResultDTO {
    apsGeneralEducation: APSGeneralEducationDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            apsGeneralEducation: null,
        },
        initValues);
    },
    apsGeneralEducation: 'apsGeneralEducation',
}

export { Request, Result };