import BraftEditor, { BraftEditorProps, EditorState } from 'braft-editor';
import * as React from 'react';
import DOMPurify from 'dompurify';
import 'braft-editor/dist/index.css';
import { useEffect, useRef } from 'react';

interface RichTextEditorProps extends BraftEditorProps {
  readonly?: boolean;
  height?: number;
}

function RichTextEditor(props: React.PropsWithChildren<RichTextEditorProps>) {
  const [editorState, setEditorState] = React.useState(BraftEditor.createEditorState(props.value));
  const _ref = useRef<any>();
  const handleEditorChange = (value: EditorState) => {

    const htmlValue = value?.toHTML() ?? '';

    setEditorState(value);

    if (props.onChange) {
      props.onChange(htmlValue);
    }
  }

  const setAttributes = () => {
    let editorRef: any = {};
    editorRef = document.querySelector('[data-title = "Undo"]');
    editorRef?.setAttribute('title', 'Undo');

    editorRef = document.querySelector('[data-title = "Redo"]');
    editorRef?.setAttribute('title', 'Redo');

    editorRef = document.querySelector('[data-title = "Color"]');
    editorRef?.setAttribute('title', 'Color');

    editorRef = document.querySelector('[data-title = "Bold"]');
    editorRef?.setAttribute('title', 'Bold');

    editorRef = document.querySelector('[data-title = "Italic"]');
    editorRef?.setAttribute('title', 'Italic');

    editorRef = document.querySelector('[data-title = "Underline"]');
    editorRef?.setAttribute('title', 'Underline');

    editorRef = document.querySelector('[data-title = "Strike Through"]');
    editorRef?.setAttribute('title', 'Strike Through');

    editorRef = document.querySelector('[data-title = "Super Script"]');
    editorRef?.setAttribute('title', 'Super Script');

    editorRef = document.querySelector('[data-title = "Sub Script"]');
    editorRef?.setAttribute('title', 'Sub Script');

    editorRef = document.querySelector('[data-title = "Remove Styles"]');
    editorRef?.setAttribute('title', 'Remove Styles');

    editorRef = document.querySelector('[data-title = "Emoji"]');
    editorRef?.setAttribute('title', 'Emoji');

    editorRef = document.querySelector('[data-title = "Increase Indent"]');
    editorRef?.setAttribute('title', 'Increase Indent');

    editorRef = document.querySelector('[data-title = "Decrease Indent"]');
    editorRef?.setAttribute('title', 'Decrease Indent');

    editorRef = document.querySelector('[data-title = "Left Alignment"]');
    editorRef?.setAttribute('title', 'Left Alignment');

    editorRef = document.querySelector('[data-title = "Center Alignment"]');
    editorRef?.setAttribute('title', 'Center Alignment');

    editorRef = document.querySelector('[data-title = "Right Alignment"]');
    editorRef?.setAttribute('title', 'Right Alignment');

    editorRef = document.querySelector('[data-title = "Justify Alignment"]');
    editorRef?.setAttribute('title', 'Justify Alignment');

    editorRef = document.querySelector('[data-title = "Unordered List"]');
    editorRef?.setAttribute('title', 'Unordered List');

    editorRef = document.querySelector('[data-title = "Ordered List"]');
    editorRef?.setAttribute('title', 'Ordered List');

    editorRef = document.querySelector('[data-title = "Quote"]');
    editorRef?.setAttribute('title', 'Quote');

    editorRef = document.querySelector('[data-title = "Code"]');
    editorRef?.setAttribute('title', 'Code');

    editorRef = document.querySelector('[data-title = "Media"]');
    editorRef?.setAttribute('title', 'Media');

    editorRef = document.querySelector('[data-title = "Link"]');
    editorRef?.setAttribute('title', 'Link');

    editorRef = document.querySelector('[data-title = "Unlink"]');
    editorRef?.setAttribute('title', 'Unlink');

    editorRef = document.querySelector('[data-title = "Horizontal Line"]');
    editorRef?.setAttribute('title', 'Horizontal Line');

    editorRef = document.querySelector('[data-title = "Clear"]');
    editorRef?.setAttribute('title', 'Clear');

    editorRef = document.querySelector('[data-title = "Fullscreen"]');
    editorRef?.setAttribute('title', 'Fullscreen');

    editorRef = document.querySelector('[role = "textbox"]');
    editorRef?.setAttribute('title', 'Textbox');
  }

  useEffect(() => {
    if (props.value && editorState.toHTML() !== props.value && !props.readonly) {
      setEditorState(BraftEditor.createEditorState(props.value));
    }
    if (_ref.current) {
      setAttributes();
    }
  }, [props.value]);

  if (props.readonly) {
    if (props.value) {
      return (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.value ?? '') }} />
      );
    }

    return null;
  }

  return (
    <BraftEditor
      {...props}
      ref={_ref}
      contentStyle={{ height: props.height ?? 150, wordBreak: 'break-word' }}
      value={editorState}
      language={'en'}
      onChange={handleEditorChange}
    />
  );
}

export default RichTextEditor;
