import { Card, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DistrictApiService from '../../../api/DistrictApiService';
import DistrictContactsDataTable from '../../../components/datatables/secondary/DistrictContactsDataTable';
import DistrictContactImporter from '../../../components/secondary/DistrictContactImporter';
import Routes from '../../../config/Routes';
import * as GetDistrictContactsHandler from '../../../handlerModels/GetDistrictContactsHandler';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';
import ConfirmAndContinueButton from '../../../components/buttons/ConfirmAndContinueButton';
import Breadcrumbs from '../../../config/Breadcrumbs';
import PreviousButton from '../../../components/buttons/PreviousButton';
import DataReviewLayout from '../../../components/secondary/DataReviewLayout';

interface DataReviewDistrictContactListPageState {
  academicYear: number;
  hasDistricts: boolean | undefined;
  errors?: string[];
}

interface DataReviewDistrictContactListPageProps {
}

class DataReviewDistrictContactListPage extends React.Component<DataReviewDistrictContactListPageProps & RouteComponentProps<RouteObject>, DataReviewDistrictContactListPageState> {

  constructor(props: DataReviewDistrictContactListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0,
      hasDistricts: undefined
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });
      this.loadDistrictContacts(id);
    }
  }

  private onSubmit = (errors?: string[]) => {
    this.loadDistrictContacts(this.state.academicYear);
    this.setState({ errors: errors ?? [] });
  }

  private loadDistrictContacts = (academicYear: number) => {
    if (((this.state.hasDistricts == undefined || this.state.hasDistricts == false) && academicYear > 0)) {
      const request = GetDistrictContactsHandler.Request.create({
        academicYearId: academicYear
      })
      DistrictApiService.getDistrictContacts(request)
        .then((results: GetDistrictContactsHandler.Result) => {
          if (results) {
            this.setState({
              hasDistricts: (results.districtContacts?.length ?? 0) > 0
            });
          }
        });
    }
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_DISTRICTS, { academicYearId: this.state.academicYear }, {}));
  }

  private onContinue = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SECONDARY_PROGRAMS, { academicYearId: this.state.academicYear }, {}));
  }

  render() {
    const academicYear = this.state.academicYear;

    if (academicYear != 0) {
      return (
        <Content >
          <DataReviewLayout breadcrumbs={Breadcrumbs.academicYearDataReview(academicYear)} stepNumber={7} academicYear={academicYear}>
            <Card title="District Contacts">
              <Space direction='vertical' size="middle">
                {this.state.hasDistricts ?
                  <DistrictContactsDataTable fromDataReview={true} academicYear={this.state.academicYear} /> :
                  <DistrictContactImporter onSubmit={this.onSubmit} academicYear={this.state.academicYear} />
                }
                <Space>
                  <PreviousButton onClick={this.onBack} />
                  {this.state.hasDistricts ? <ConfirmAndContinueButton onClick={this.onContinue} /> : null}
                </Space>
              </Space>
            </Card>
          </DataReviewLayout>
        </Content>
      );
    }

    return <Skeleton />;
  }
}

export default withRouter((DataReviewDistrictContactListPage));
