import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import WorkBasedLearningTypeApiService from '../../../api/WorkBasedLearningTypeApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetWorkBasedLearningTypeDetailsHandler from '../../../handlerModels/GetWorkBasedLearningTypeDetailsHandler';
import * as SaveWorkBasedLearningTypeDetailsHandler from '../../../handlerModels/SaveWorkBasedLearningTypeDetailsHandler';
import WorkBasedLearningTypeDTO from '../../../models/WorkBasedLearningTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface WorkBasedLearningTypeDetailsFormState extends BaseFormState {
  workBasedLearningTypeId: number;
  workBasedLearningType: WorkBasedLearningTypeDTO | null;
}

interface WorkBasedLearningTypeDetailsFormProps extends BaseFormProps {
  workBasedLearningTypeId: number;
  onSave?: (workBasedLearningTypeId: number) => void;
}

class WorkBasedLearningTypeDetailsForm extends React.Component<WorkBasedLearningTypeDetailsFormProps, WorkBasedLearningTypeDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(WorkBasedLearningTypeDTO.name, {
      required: true,
      name: WorkBasedLearningTypeDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    });

  constructor(props: WorkBasedLearningTypeDetailsFormProps) {
    super(props);

    this.state = {
      workBasedLearningTypeId: 0,
      workBasedLearningType: WorkBasedLearningTypeDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadWorkBasedLearningType();
  }

  componentDidUpdate(prevProps: WorkBasedLearningTypeDetailsFormProps) {
    if (this.props.workBasedLearningTypeId && this.props.workBasedLearningTypeId != prevProps.workBasedLearningTypeId) {
      this.setState({ workBasedLearningTypeId: this.props.workBasedLearningTypeId });
      this.loadWorkBasedLearningType();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadWorkBasedLearningType() {
    if (this.props.workBasedLearningTypeId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.workBasedLearningTypeId) {
      WorkBasedLearningTypeApiService.getWorkBasedLearningTypeDetails(this.props.workBasedLearningTypeId)
        .then((results: GetWorkBasedLearningTypeDetailsHandler.Result) => {
          if (results && results.workBasedLearningType?.id) {
            this.setState({ workBasedLearningTypeId: results.workBasedLearningType.id, workBasedLearningType: results.workBasedLearningType, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ workBasedLearningType: WorkBasedLearningTypeDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveWorkBasedLearningTypeDetailsHandler.Request.create({
      workBasedLearningTypeId: this.state.workBasedLearningTypeId,
      workBasedLearningType: WorkBasedLearningTypeDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    WorkBasedLearningTypeApiService.saveWorkBasedLearningTypeDetails(request)
      .then((result: SaveWorkBasedLearningTypeDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          workBasedLearningType: result?.workBasedLearningType,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.workBasedLearningType?.id) {
            this.props.onSave(result.workBasedLearningType.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(WorkBasedLearningTypeDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Instructional Level could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Instructional Level could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(WorkBasedLearningTypeDTO.name)}
            initialValue={this.state.workBasedLearningType?.name}
            {...ValidationUtil.getValidation(WorkBasedLearningTypeDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default WorkBasedLearningTypeDetailsForm;
