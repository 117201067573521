// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AwardTypeDataTableDTO from '../models/AwardTypeDataTableDTO';
import * as GetAwardTypeDetailsHandler from '../handlerModels/GetAwardTypeDetailsHandler';
import * as SaveAwardTypeDetailsHandler from '../handlerModels/SaveAwardTypeDetailsHandler';
import * as GetAwardTypeHandler from '../handlerModels/GetAwardTypeHandler';
import * as DeleteAwardTypeHandler from '../handlerModels/DeleteAwardTypeHandler';

export class AwardTypeApiService extends BaseApi {

    // post: api/awardType
    public getAwardTypeTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AwardTypeDataTableDTO>> {
        let url = `api/awardType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<AwardTypeDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/awardType/export
    public getAwardTypeDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/awardType/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // get: api/awardType/${id}
    public getAwardTypeDetails(id: number): Promise<GetAwardTypeDetailsHandler.Result> {
        let url = `api/awardType/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAwardTypeDetailsHandler.Result>(null, 'get', url, true, false);
    }

    // post: api/awardType/save
    public saveAwardTypeDetails(saveAwardTypeDetails: SaveAwardTypeDetailsHandler.Request): Promise<SaveAwardTypeDetailsHandler.Result> {
        let url = `api/awardType/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveAwardTypeDetailsHandler.Result>(saveAwardTypeDetails, 'post', url, true, false);
    }

    // post: api/awardType/getAwardTypes
    public getAwardTypes(): Promise<GetAwardTypeHandler.Result> {
        let url = `api/awardType/getAwardTypes`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAwardTypeHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/awardType/deleteAwardType
    public deleteAwardType(deleteAwardType: DeleteAwardTypeHandler.Request): Promise<DeleteAwardTypeHandler.Result> {
        let url = `api/awardType/deleteAwardType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteAwardTypeHandler.Result>(deleteAwardType, 'post', url, true, false);
    }
}
var service = new AwardTypeApiService();
export default service;
