// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveCreditTypeDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import CreditTypeDTO from '../models/CreditTypeDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    creditTypeId: string | null | null;
    creditType: CreditTypeDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            creditTypeId: null,
            creditType: null,
        },
        initValues);
    },
    creditTypeId: 'creditTypeId',
    creditType: 'creditType',
}

interface Result extends ResultDTO {
    creditType: CreditTypeDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            creditType: null,
        },
        initValues);
    },
    creditType: 'creditType',
}

export { Request, Result };