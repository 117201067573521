import { DeleteOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Form, FormInstance, Input, Select, Skeleton, Space } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import FormList, { FormListFieldData } from 'antd/lib/form/FormList';
import { NamePath } from 'antd/lib/form/interface';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SecondaryProgramScenarioApiService from '../../../api/SecondaryProgramScenarioApiService';
import SelfStudySectionComponentType from '../../../consts/SelfStudySectionComponentType';
import * as GetSecondaryProgramScenariosHandler from '../../../handlerModels/GetSecondaryProgramScenariosHandler';
import SecondaryProgramScenarioDTO from '../../../models/SecondaryProgramScenarioDTO';
import SecondaryProgramStatusTypeDTO from '../../../models/SecondaryProgramStatusTypeDTO';
import SelfStudyResponseTemplateConsultantReviewQuestionDTO from '../../../models/SelfStudyResponseTemplateConsultantReviewQuestionDTO';
import SelfStudySectionComponentTemplateDTO from '../../../models/SelfStudySectionComponentTemplateDTO';
import SelfStudySectionComponentTypeDTO from '../../../models/SelfStudySectionComponentTypeDTO';
import SelfStudySectionTemplateDTO from '../../../models/SelfStudySectionTemplateDTO';
import SelfStudySectionTypeDTO from '../../../models/SelfStudySectionTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import { DraggableCard } from '../../general/DraggableCard';
import Dropdown from '../../inputs/Dropdown';
import ReadableNumberInput from '../../inputs/ReadableNumberInput';
import ReadableTextBox from '../../inputs/ReadableTextBox';
import RichTextEditor from '../../inputs/RichTextEditor';
import YesNoInput from '../../inputs/YesNoInput';
import SelfStudyConsultantReview from '../../secondary/SelfStudyConsultantReview';
import SelfStudyFreeTextQuestion from '../../secondary/SelfStudyFreeTextQuestion';
import SelfStudyInstruction from '../../secondary/SelfStudyInstruction';
import SelfStudyNewGoals from '../../secondary/SelfStudyNewGoals';
import SelfStudyPreviousGoals from '../../secondary/SelfStudyPreviousGoals';
import SelfStudyProgramOverview from '../../secondary/SelfStudyProgramOverview';
import SelfStudyRankingQuestion from '../../secondary/SelfStudyRankingQuestion';
import { View } from '@react-pdf/renderer';

interface SelfStudySectionFormProps extends BaseFormProps {
  section: SelfStudySectionTemplateDTO;
  academicYear: number;
  readonly?: boolean;
  submitted: boolean;
  sectionIndex: number;
  value?: any;
  preview?: boolean;
  onChange?: (value: SelfStudySectionTemplateDTO, index: number) => void;
  onAltered?: () => void;
  onTitleChange?: (sectionIndex: number, title: string) => void;
  resetForm?: (field: NamePath[] | undefined) => void;
  secondaryProgramScenarioId: Guid | null;
  form: FormInstance<any>;
  pdfView?: boolean;
  sectionComplete?: (sectionIndex: number,componentIndex: number, value: boolean) => void
}

function SelfStudySectionForm(props: SelfStudySectionFormProps) {
  const [section, setSection] = useState<SelfStudySectionTemplateDTO>(props.section);
  const [, setIsSubmitted] = useState(props.submitted);
  const [sectionTypes, setSectionTypes] = useState<SelfStudySectionTypeDTO[]>([]);
  const [componentTypes, setComponetTypes] = useState<SelfStudySectionComponentTypeDTO[]>([]);
  const [secondaryProgramScenarios, setSecondaryProgramScenarios] = useState([] as SecondaryProgramScenarioDTO[]);
  const [, setError] = useState<any>(null);
  const [added, setAdded] = useState(false);
  const [, setAltered] = useState(false);
  const [, setSecondaryProgramStatusTypes] = useState([] as SecondaryProgramStatusTypeDTO[]);

  useEffect(() => {
    fetchData();
  }, [props.academicYear, props.form]
  )

  const fetchData = () => {
    const loaders = [];

    if (sectionTypes.length == 0) {
      loaders.push(loadSelfStudySectionTypes());
    }

    if (componentTypes.length == 0) {
      loaders.push(loadSelfStudySectionComponentTypes());
    }

    if (secondaryProgramScenarios.length == 0) {
      loaders.push(loadSecondaryProgramScenarios());
    }

    loaders.push(loadSelfStudyProgramStatusTypes());

    Promise.all(loaders).then(() => {
      setIsSubmitted(false);
    });
  }

  const loadSelfStudySectionComponentTypes = () => {
    return LookupsUtil.getAll<SelfStudySectionComponentTypeDTO>(SelfStudySectionComponentTypeDTO.className)
      .then((results: SelfStudySectionComponentTypeDTO[]) => {
        setComponetTypes(results);
      }).catch(() => {
        setError(true);
      });
  }

  const loadSelfStudySectionTypes = () => {
    return LookupsUtil.getAll<SelfStudySectionTypeDTO>(SelfStudySectionTypeDTO.className)
      .then((results: SelfStudySectionTypeDTO[]) => {
        setSectionTypes(results);
      }).catch(() => {
        setError(true);
      });
  }

  const loadSelfStudyProgramStatusTypes = () => {
    return LookupsUtil.getAll<SecondaryProgramStatusTypeDTO>(SecondaryProgramStatusTypeDTO.className)
      .then((results: SecondaryProgramStatusTypeDTO[]) => {
        setSecondaryProgramStatusTypes(results.filter(x => x.selectable));
      }).catch(() => {
        setError(true);
      });
  }

  const loadSecondaryProgramScenarios = () => {
    const request = GetSecondaryProgramScenariosHandler.Request.create({
      academicYearId: props.academicYear ?? 0
    });

    return SecondaryProgramScenarioApiService.getSecondaryProgramScenarios(request)
      .then((results: GetSecondaryProgramScenariosHandler.Result) => {
        if (results) {
          setSecondaryProgramScenarios(results.secondaryProgramScenario ?? []);
        }

      })
      .catch(() => {
        setError(true);
      });
  }

  const moveCard = (dragIndex: number, hoverIndex: number, sectionIndex: number) => {
    const formSection = SelfStudySectionTemplateDTO.create(props.form ? (props.form as any).getFieldsValue() : null) as any;
    const section = formSection.selfStudySectionTemplates[sectionIndex] as SelfStudySectionTemplateDTO;
    const components = section.selfStudySectionComponentTemplates;
    const [removed] = components?.splice(dragIndex, 1) ?? [];
    components?.splice(hoverIndex, 0, removed);

    const sectionTemp = section;
    sectionTemp.selfStudySectionComponentTemplates = components;
    sectionTemp.title = section.title;

    setSection(sectionTemp);
    props.form.setFieldsValue({ selfStudySectionTemplates: formSection.selfStudySectionTemplates });
    onAltered();
  }

  const getSectionFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(SelfStudySectionTemplateDTO.title, {
        required: true,
        name: [index, SelfStudySectionTemplateDTO.title],
        label: 'Title',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionTemplateDTO.selfStudySectionTemplateSecondaryProgramScenarios, {
        name: [index, SelfStudySectionTemplateDTO.selfStudySectionTemplateSecondaryProgramScenarios],
        label: 'Scenario(s)',
      })
      .set(SelfStudySectionTemplateDTO.isSectionComments, {
        name: [index, SelfStudySectionTemplateDTO.isSectionComments],
        label: 'Allow section comments for reviews?',
      });
  }

  const getComponentFormItems = (index: number, componentType?: any) => {
    let label = 'Instructions';
    if (componentType == SelfStudySectionComponentType.RANKINGQUESTION) {
      label = 'Question';
    }
    else if (componentType == SelfStudySectionComponentType.FREETEXTQUESTION) {
      label = 'Text'
    }
    return new Map<string, FormItemProps>()
      .set(SelfStudySectionComponentTemplateDTO.selfStudySectionComponentTypeId, {
        required: true,
        name: [index, SelfStudySectionComponentTemplateDTO.selfStudySectionComponentTypeId],
        label: 'Type',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentTemplateDTO.primaryText, {
        required: true,
        name: [index, SelfStudySectionComponentTemplateDTO.primaryText],
        label: label,
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentTemplateDTO.rppInstructions, {
        required: true,
        name: [index, SelfStudySectionComponentTemplateDTO.rppInstructions],
        label: 'RPP Instruction',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentTemplateDTO.programOfStudyTenets, {
        required: true,
        name: [index, SelfStudySectionComponentTemplateDTO.programOfStudyTenets],
        label: 'Program of Study Tenet(s)',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentTemplateDTO.rankingQuestionIdentifier, {
        required: true,
        name: [index, SelfStudySectionComponentTemplateDTO.rankingQuestionIdentifier],
        label: 'Identifier',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentTemplateDTO.numberOfPreviousGoals, {
        required: true,
        name: [index, SelfStudySectionComponentTemplateDTO.numberOfPreviousGoals],
        label: 'Number of Previous Goals',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentTemplateDTO.numberOfNewGoals, {
        required: true,
        name: [index, SelfStudySectionComponentTemplateDTO.numberOfNewGoals],
        label: 'Number of New Goals',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentTemplateDTO.selfStudySectionComponentScenarios, {
        name: [index, SelfStudySectionComponentTemplateDTO.selfStudySectionComponentScenarios],
        label: 'Scenario',
      })
      .set(SelfStudySectionComponentTemplateDTO.careerAcademy, {
        required: true,
        name: [index, SelfStudySectionComponentTemplateDTO.careerAcademy],
        label: 'Career Academy',
        rules: [ValidationRuleUtil.required()]
      });
  }

  const getQuestionFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(SelfStudyResponseTemplateConsultantReviewQuestionDTO.question, {
        required: true,
        name: [index, SelfStudyResponseTemplateConsultantReviewQuestionDTO.question],
        label: 'Question',
        rules: [ValidationRuleUtil.required()]
      });
  }

  const onAltered = () => {
    if (props.onAltered) {
      props.onAltered();
      setAltered(true);
    }
  }

  const onChange = (value: any) => {
    props.form.setFieldsValue({ SelfStudySectionComponentTypeId: value })
  }

  const onAddClick = (add: any) => {
    add(SelfStudyResponseTemplateConsultantReviewQuestionDTO.create())
    setAdded(!added);
  }

  const onRemoveClick = (remove: any, index: number) => {
    remove(index)
    setAdded(!added);
  }

  const renderAdditionalQuestion = (add: any) => {
    return (
      <Button onClick={() => onAddClick(add)} icon={<PlusOutlined />}>
        Add Question
      </Button>
    );
  }
  const renderRemoveQuestion = (remove: any, index: number) => {
    return (
      <Button type="link" icon={<DeleteOutlined />} onClick={() => onRemoveClick(remove, index)}></Button>
    );
  }

  const renderConsultantReview = (sectionIndex: number, index: number) => {
    return (

      <FormList name={[index, SelfStudySectionComponentTemplateDTO.selfStudyResponseTemplateConsultantReviewQuestions]} >
        {
          (section, { add, remove }) => {
            let count = 1;
            return (<Space direction='vertical'>
              {section.map(x => {
                return (
                  <Card key={x.fieldKey} title={'Question ' + count++} type='inner' extra={renderRemoveQuestion(remove, x.name)}>
                    <FormItem
                      {...getQuestionFormItems(x.name).get(SelfStudyResponseTemplateConsultantReviewQuestionDTO.question)}
                      {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.selfStudyResponseTemplateConsultantReviewQuestions + sectionIndex + index + x.name, props.fieldErrors, props.submitted)} >
                      <ReadableTextBox />
                    </FormItem>
                  </Card>
                );
              })
              }
              {renderAdditionalQuestion(add)}
            </Space>
            );
          }
        }
      </FormList>
    );
  }

  const renderAddComponent = (add: any) => {
    return (
      <Button onClick={() => add()} icon={<PlusOutlined />}>
        Add Component
      </Button>
    );
  }

  const renderComponent = (component: FormListFieldData, index: number, sectionIndex: number, remove: any) => {
    const compoentTitle = 'Component ' + (index + 1);
    const deleteButton = <Button type="link" size="small" title='Delete Component' onClick={() => remove(component.name)} icon={<DeleteOutlined />} />;
    const componentType = (props.form?.getFieldsValue()?.selfStudySectionTemplates ?? [])[sectionIndex]?.selfStudySectionComponentTemplates[index]?.selfStudySectionComponentTypeId;
    const componentFormItems = getComponentFormItems(index);

    return (
      <DraggableCard
        id={index}
        key={index}
        index={index}
        moveCard={(x, y) => moveCard(x, y, sectionIndex)}
        content={
          <Card key={index} title={<><MenuOutlined /> {compoentTitle}</>} type='inner' extra={deleteButton}>
            <Space direction='vertical'>

              <FormItem
                {...componentFormItems.get(SelfStudySectionComponentTemplateDTO.selfStudySectionComponentTypeId)}
                {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.selfStudySectionComponentTypeId + sectionIndex + index, props.fieldErrors, props.submitted)} >
                <Dropdown onChange={onChange}>
                  {componentTypes.map(y => { return <Select.Option key={y.id ?? 0} value={y.id ?? 0} >{y.name}</Select.Option> })}
                </Dropdown>
              </FormItem>

              {componentType > 0 &&
                <Form.Item
                  {...componentFormItems.get(SelfStudySectionComponentTemplateDTO.selfStudySectionComponentScenarios)}
                  {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.selfStudySectionComponentScenarios + sectionIndex + index, props.fieldErrors, props.submitted)} >
                  <Dropdown mode="multiple" placeholder='Leave empty for all scenarios'>
                    {secondaryProgramScenarios.map(y => { return <Select.Option key={y.id ?? Guid.Empty()} value={y.id ?? Guid.Empty()} >{y.title}</Select.Option> })}
                  </Dropdown>
                </Form.Item>
              }

              {
                componentType == SelfStudySectionComponentType.RANKINGQUESTION ?
                  <FormItem
                    {...componentFormItems.get(SelfStudySectionComponentTemplateDTO.rankingQuestionIdentifier)}
                    {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.rankingQuestionIdentifier + sectionIndex + index, props.fieldErrors, props.submitted)} >
                    <ReadableTextBox />
                  </FormItem> : null
              }

              {
                componentType == SelfStudySectionComponentType.RANKINGQUESTION ?
                  <FormItem
                    {...componentFormItems.get(SelfStudySectionComponentTemplateDTO.programOfStudyTenets)}
                    {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.programOfStudyTenets + sectionIndex + index, props.fieldErrors, props.submitted)} >
                    <Input />
                  </FormItem> : null
              }

              {
                componentType == SelfStudySectionComponentType.RANKINGQUESTION ?
                  <FormItem
                    {...componentFormItems.get(SelfStudySectionComponentTemplateDTO.careerAcademy)}
                    {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.careerAcademy + sectionIndex + index, props.fieldErrors, props.submitted)} >
                    <YesNoInput />
                  </FormItem> : null
              }

              {
                componentType == SelfStudySectionComponentType.PREVIOUSGOALS ?
                  <FormItem
                    {...componentFormItems.get(SelfStudySectionComponentTemplateDTO.numberOfPreviousGoals)}
                    {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.numberOfPreviousGoals + sectionIndex + index, props.fieldErrors, props.submitted)} >
                    <ReadableNumberInput />
                  </FormItem> : null
              }

              {
                componentType == SelfStudySectionComponentType.NEWGOALS ?
                  <FormItem
                    {...componentFormItems.get(SelfStudySectionComponentTemplateDTO.numberOfNewGoals)}
                    {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.numberOfNewGoals + sectionIndex + index, props.fieldErrors, props.submitted)} >
                    <ReadableNumberInput />
                  </FormItem> : null
              }

              {
                componentType == SelfStudySectionComponentType.CONSULTANTREVIEW ?
                  renderConsultantReview(sectionIndex, index)
                  : null
              }

              {
                componentType == SelfStudySectionComponentType.CONSULTANTREVIEW ?
                  <FormItem
                    {...getComponentFormItems(index, componentType).get(SelfStudySectionComponentTemplateDTO.rppInstructions)}
                    {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.rppInstructions + sectionIndex + index, props.fieldErrors, props.submitted)} >
                    <RichTextEditor />
                  </FormItem>
                  : null
              }

              {componentType > 0 &&
                <FormItem
                  {...getComponentFormItems(index, componentType).get(SelfStudySectionComponentTemplateDTO.primaryText)}
                  {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.primaryText + sectionIndex + index, props.fieldErrors, props.submitted)} >
                  <RichTextEditor />
                </FormItem>
              }

            </Space >
          </Card >
        }>
      </DraggableCard >
    );
  }

  const renderSection = (sectionIndex: number) => {
    return (
      <>
        <Form.Item
          {...getSectionFormItems(sectionIndex).get(SelfStudySectionTemplateDTO.title)}
          {...ValidationUtil.getValidation(SelfStudySectionTemplateDTO.title + sectionIndex, props.fieldErrors, props.submitted)} >
          <ReadableTextBox disabled={false} />
        </Form.Item>

        <Form.Item
          {...getSectionFormItems(sectionIndex).get(SelfStudySectionTemplateDTO.selfStudySectionTemplateSecondaryProgramScenarios)}
          {...ValidationUtil.getValidation(SelfStudySectionTemplateDTO.selfStudySectionTemplateSecondaryProgramScenarios + sectionIndex, props.fieldErrors, props.submitted)} >
          <Dropdown mode="multiple" placeholder='Leave empty for all scenarios'>
            {secondaryProgramScenarios.map(y => { return <Select.Option key={y.id ?? Guid.Empty()} value={y.id ?? Guid.Empty()} >{y.title}</Select.Option> })}
          </Dropdown>
        </Form.Item>

        <Form.Item
          {...getSectionFormItems(sectionIndex).get(SelfStudySectionTemplateDTO.isSectionComments)}
          {...ValidationUtil.getValidation(SelfStudySectionTemplateDTO.isSectionComments + sectionIndex, props.fieldErrors, props.submitted)} >
          <YesNoInput />
        </Form.Item>

        <FormList name={[sectionIndex, SelfStudySectionTemplateDTO.selfStudySectionComponentTemplates]} >
          {
            (components, { add, remove }) => {
              return (
                <Space direction='vertical'>
                  {
                    components.map((component, index) => {
                      if (component) {
                        return renderComponent(component, index, sectionIndex, remove);
                      }
                    })
                  }
                  {renderAddComponent(add)}
                </Space>
              );
            }
          }
        </FormList>
      </>
    );
  }

  const renderComponentReview = (component: FormListFieldData, index: number, sectionIndex: number,) => {
    const componentNode = (props.form?.getFieldsValue()?.selfStudySectionTemplates ?? [])[sectionIndex]?.selfStudySectionComponentTemplates[index];
    const componentType = (props.form?.getFieldsValue()?.selfStudySectionTemplates ?? [])[sectionIndex]?.selfStudySectionComponentTemplates[index]?.selfStudySectionComponentTypeId;

    if (!props.secondaryProgramScenarioId || !props.preview || (componentNode?.selfStudySectionComponentScenarios == undefined || componentNode?.selfStudySectionComponentScenarios?.length == 0 || componentNode?.selfStudySectionComponentScenarios?.findIndex((x: any) => x == props.secondaryProgramScenarioId) > -1)) {
      switch (componentType) {
        case SelfStudySectionComponentType.INSTRUCTIONS:
          return <SelfStudyInstruction
            academicYear={props.academicYear}
            sectionIndex={sectionIndex}
            componentIndex={index}
          />
        case SelfStudySectionComponentType.PROGRAMOVERVIEW:
          return <SelfStudyProgramOverview
            readonly={true}
            academicYear={props.academicYear}
            sectionIndex={sectionIndex}
            disable={true}
            componentIndex={index}
            previewComponent={true}
          />
        case SelfStudySectionComponentType.RANKINGQUESTION:
          return <SelfStudyRankingQuestion
            disable={true}
            sectionIndex={sectionIndex}
            academicYear={props.academicYear}
            componentIndex={index}
            readonly={props.readonly}
            previewComponent={true}
          />;
        case SelfStudySectionComponentType.FREETEXTQUESTION:
          return <SelfStudyFreeTextQuestion
            disable={true}
            sectionIndex={sectionIndex}
            academicYear={props.academicYear}
            componentIndex={index}
            readonly={props.readonly}
            previewComponent={true}
          />;
        case SelfStudySectionComponentType.NEWGOALS:
          return <SelfStudyNewGoals
            sectionIndex={sectionIndex}
            componentIndex={index}
            disable={true}
            academicYear={props.academicYear}
            previewComponent={true}
            newGoalCount={componentNode.numberOfNewGoals ?? 0} />
        case SelfStudySectionComponentType.PREVIOUSGOALS:
          return <SelfStudyPreviousGoals
            sectionIndex={props.sectionIndex}
            componentIndex={index}
            disable={true}
            academicYear={props.academicYear}
            previewComponent={true}
            previousGoalCount={componentNode.numberOfPreviousGoals ?? 0} />
        case SelfStudySectionComponentType.CONSULTANTREVIEW:
          return (
            <>
              <SelfStudyConsultantReview
                disable={true}
                sectionIndex={props.sectionIndex}
                componentIndex={index}
                academicYear={props.academicYear}
                previewComponent={true}
                consultantReviewQuestionCount={componentNode.selfStudyResponseTemplateConsultantReviewQuestions.length ?? 0} />          </ >
          );
      }
    }
  }

  const renderPreview = () => {
    const sectionIndex = props.sectionIndex;
    return (<FormList name={[sectionIndex, SelfStudySectionTemplateDTO.selfStudySectionComponentTemplates]} >
      {
        (components) => {
          return (
            <Space direction='vertical'>
              {
                components.map((component, index) => {
                  if (component) {
                    return renderComponentReview(component, index, sectionIndex);
                  }
                })
              }
            </Space>
          );
        }
      }
    </FormList>
    )
  }

  
  const renderPdfComponentReview = (component: SelfStudySectionComponentTemplateDTO, index: number, sectionIndex: number,) => {
    const componentNode = (props.form?.getFieldsValue()?.selfStudySectionTemplates ?? [])[sectionIndex]?.selfStudySectionComponentTemplates[index];
    const componentType = (props.form?.getFieldsValue()?.selfStudySectionTemplates ?? [])[sectionIndex]?.selfStudySectionComponentTemplates[index]?.selfStudySectionComponentTypeId;

    if (!props.secondaryProgramScenarioId || !props.preview || (componentNode?.selfStudySectionComponentScenarios == undefined || componentNode?.selfStudySectionComponentScenarios?.length == 0 || componentNode?.selfStudySectionComponentScenarios?.findIndex((x: any) => x == props.secondaryProgramScenarioId) > -1)) {
      switch (componentType) {
        case SelfStudySectionComponentType.INSTRUCTIONS:
          return <SelfStudyInstruction
            academicYear={props.academicYear}
            sectionIndex={sectionIndex}
            componentIndex={index}
            pdfView={props.pdfView}
            renderComplete={props.sectionComplete}
          />
        case SelfStudySectionComponentType.PROGRAMOVERVIEW:
          return <SelfStudyProgramOverview
            readonly={true}
            academicYear={props.academicYear}
            disable={true}
            sectionIndex={sectionIndex}
            componentIndex={index}
            previewComponent={true}
            pdfView={props.pdfView}
            renderComplete={props.sectionComplete}
          />
        case SelfStudySectionComponentType.RANKINGQUESTION:
          return <SelfStudyRankingQuestion
            disable={true}
            sectionIndex={sectionIndex}
            academicYear={props.academicYear}
            componentIndex={index}
            readonly={props.readonly}
            previewComponent={true}
            pdfView={props.pdfView}
            renderComplete={props.sectionComplete}
          />;
        case SelfStudySectionComponentType.FREETEXTQUESTION:
          return <SelfStudyFreeTextQuestion
            disable={true}
            sectionIndex={sectionIndex}
            academicYear={props.academicYear}
            componentIndex={index}
            readonly={props.readonly}
            previewComponent={true}
            pdfView={props.pdfView}
            renderComplete={props.sectionComplete}
          />;
        case SelfStudySectionComponentType.NEWGOALS:
          return <SelfStudyNewGoals
            sectionIndex={sectionIndex}
            componentIndex={index}
            disable={true}
            academicYear={props.academicYear}
            previewComponent={true}
            newGoalCount={componentNode.numberOfNewGoals ?? 0}
            pdfView={true}
            renderComplete={props.sectionComplete}
             />
        case SelfStudySectionComponentType.PREVIOUSGOALS:
          return <SelfStudyPreviousGoals
            sectionIndex={props.sectionIndex}
            componentIndex={index}
            disable={true}
            academicYear={props.academicYear}
            previewComponent={true}
            previousGoalCount={componentNode.numberOfPreviousGoals ?? 0}
            pdfView={true}
            renderComplete={props.sectionComplete} />
        case SelfStudySectionComponentType.CONSULTANTREVIEW:
          return (
              <SelfStudyConsultantReview
                disable={true}
                sectionIndex={props.sectionIndex}
                componentIndex={index}
                academicYear={props.academicYear}
                previewComponent={true}
                consultantReviewQuestionCount={componentNode.selfStudyResponseTemplateConsultantReviewQuestions.length ?? 0}
                pdfView={true} 
                renderComplete={props.sectionComplete}/>
          );
      }
    }
    return null;
  }

  const renderPdfComponent = (component:SelfStudySectionComponentTemplateDTO, index: number) => {
    const sectionIndex = props.sectionIndex;
    return renderPdfComponentReview(component, index, sectionIndex);
  }

  const renderPdfSection = () => {
     return props?.section?.selfStudySectionComponentTemplates?.map((component, index) => renderPdfComponent(component,index))
  }

  const renderNeededSections = () => {
    if (props.fieldErrors) {
      return <>
        {ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.selfStudySectionComponentType, props.fieldErrors, props.submitted)?.messages.map(x => renderErrors(x))}
      </>
    }
  }

  const renderErrors = (message?: string) => {
    return <Alert type="error" message='Error' showIcon={true} description={message ? message : 'Needed section'} />;
  }

  if(props.pdfView){
    
    return <View>{renderPdfSection()}</View>

  }
  else if (section) {
    return (
      <Space direction='vertical' size='small'>
        {props.preview ? renderPreview() : renderSection(props.sectionIndex ?? 0)}
        {renderNeededSections()}
      </Space >
    );
  } else {
    return <Skeleton active={true} />
  }
}

export default SelfStudySectionForm;