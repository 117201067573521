// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class ChangeRequestStatusType {
    public static readonly DRAFT: number = 1;
    public static readonly SUBMITTED: number = 2;
    public static readonly AWATINGREVIEW: number = 3;
    public static readonly INREVIEW: number = 4;
    public static readonly ATTENTIONREQUIRED: number = 5;
    public static readonly APPROVED: number = 6;
    public static readonly CANCELLED: number = 7;
}
