import TableRequestDTO from './models/TableRequestDTO';
import { DataTableButton, DataTableButtonType, ExportButton, LinkButton, ResetButton } from './DataTable';

class DataTableButtonUtil {
  public static Default(label: string, onClick: () => void, icon?: any | undefined, loading?: boolean) {
    const button: DataTableButton = {
      dataTableButtonType: DataTableButtonType.Generic,
      label,
      onClick,
      icon: icon,
      loading: loading
    };
    return button;
  }

  public static Primary(label: string, onClick: () => void) {
    const button: DataTableButton = {
      dataTableButtonType: DataTableButtonType.Generic,
      label,
      onClick,
      type: 'primary',
      icon: undefined
    };
    return button;
  }

  public static Danger(label: string, onClick: () => void) {
    const button: DataTableButton = {
      dataTableButtonType: DataTableButtonType.Generic,
      label,
      onClick,
      danger: true,
      icon: undefined
    };
    return button;
  }

  public static Export(exportHandler: (tableState: TableRequestDTO) => any, loading?: boolean) {
    const button: ExportButton = {
      dataTableButtonType: DataTableButtonType.Export,
      label: 'Export',
      export: exportHandler,
      loading,
      disabled: loading,
      icon: undefined
    };
    return button;
  }

  public static Reset() {
    const button: ResetButton = {
      dataTableButtonType: DataTableButtonType.Reset,
      label: 'Reset',
      icon: undefined
    };
    return button;
  }

  public static Link(label: string, to: string) {
    const button: LinkButton = {
      dataTableButtonType: DataTableButtonType.Link,
      label,
      to,
      icon: undefined
    };
    return button;
  }
}

export default DataTableButtonUtil;