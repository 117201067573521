import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AnnouncementsDataTable from '../../components/datatables/AnnouncementsDataTable';
import Breadcrumbs from '../../config/Breadcrumbs';
import HeaderPortal from '../../portals/HeaderPortal';

interface AnnouncementListProps {
}

interface AnnouncementListState {
}

class AnnouncementListPage extends React.Component<AnnouncementListProps & RouteComponentProps<RouteObject>, AnnouncementListState> {
  constructor(props: AnnouncementListProps & RouteComponentProps<RouteObject>) {
    super(props);

  }

  render() {
    return (
      <Content >
        <HeaderPortal title="Announcements" breadcrumbs={Breadcrumbs.announcementList()} />

        <Card >
          <AnnouncementsDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(AnnouncementListPage);
