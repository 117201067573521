import { Card, Select, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import ServiceAreaDataTable from '../../../components/datatables/secondary/ServiceAreaDataTable';
import Dropdown from '../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface ServiceAreaListPageState {
  loading: boolean;
  fromSelfStudies: boolean;
  academicYear: number;
  academicYears: AcademicYearDTO[];
  fromAcademicYearList: boolean;
}

interface ServiceAreaListPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class ServiceAreaListPage extends React.Component<ServiceAreaListPageProps & RouteComponentProps<RouteObject>, ServiceAreaListPageState> {

  constructor(props: ServiceAreaListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
      academicYears: [],
      fromSelfStudies: false,
      fromAcademicYearList: false
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));
    const fromAcademicYearList = ParameterUtil.getParameter('fromAcademicYearList');
    this.setState({ fromAcademicYearList: fromAcademicYearList == 'true' });

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id, fromSelfStudies: true });
    }
    else {
      this.loadAcademicYears(id);
    }
  }

  private loadAcademicYears = (id: number) => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
      academicYearId: id
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
            academicYear: DateTimeUtil.getCurrentYear()
          });
        }
      })
      .catch()
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private onSelect = (value: number) => {
    this.setState({ academicYear: value });
  }

  private onBack = () => {
    if (this.state.fromSelfStudies) {
      HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: this.state.academicYear }, {}));
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.HOME, {}, {}));
    }
  }

  render() {
    const academicYear = this.state.academicYear;
    return (
      <Content >
        <HeaderPortal title='Service Areas' breadcrumbs={this.state.fromSelfStudies ? Breadcrumbs.academicYearDataReview(academicYear) : Breadcrumbs.serviceAreaList()}
          onBack={this.onBack}
          extra={this.state.fromSelfStudies ? null : <Dropdown value={this.state.academicYear} onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.academicYears.map(x => { return this.renderAcademicYear(x); })}</Dropdown>} />
        < Space direction="vertical" size="large">
          <Card title="Service Areas">
            <Space direction='vertical' size="large">
              <ServiceAreaDataTable
                academicYear={this.state.academicYear}
                fromSelfStudies={this.state.fromSelfStudies}
                fromAcademicYearList={this.state.fromAcademicYearList} />
            </Space>
          </Card>
        </Space>
      </Content>
    );
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</Select.Option>
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ServiceAreaListPage));
