import { Steps } from 'antd';
import * as React from 'react';
import Routes from '../../../config/Routes';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import Guid from '../../../utils/Guid';
import CollapsibleSteps from '../../general/CollapsibleSteps';
import NavigationStep from '../../general/NavigationStep';

interface NewProgramChangeRequestStepsProps {
  current: number;
  changeRequestId: string;
  changeRequest?: ChangeRequestDTO;
  awardId?: string;
}

function NewProgramChangeRequestSteps(props: NewProgramChangeRequestStepsProps) {
  const subAwards = props.changeRequest ? props.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards?.map(x => {
    const currentAward = props.awardId == x.id;
    const current = props.current != 9 && props.current != 10 ? -1 : props.current - 9;
    return (
      <Steps.Step key={x.id} title={x.awardTitle ?? 'New Award'} description={
        <Steps current={current} direction="vertical" size="small" progressDot={true}>
          <Steps.Step title={<NavigationStep enabled={(!currentAward || props.current != 9) && props.changeRequestId != Guid.Empty()} title={x.awardTitle + ' Award Details'} route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_10, { id: props.changeRequestId, awardId: x.id ?? Guid.Empty() })}/>} />
          <Steps.Step title={<NavigationStep enabled={(!currentAward || props.current != 10) && props.changeRequestId != Guid.Empty()} title={x.awardTitle + ' Term Details'} route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_11, { id: props.changeRequestId, awardId: x.id ?? Guid.Empty() })}/>} />
        </Steps>
      } />);
  }) : [];

  const awardSteps = (
    <Steps direction="vertical" size="small" progressDot={true}>
      {subAwards}
    </Steps>
  )

  let currentStep = props.current;
  if (currentStep == 9) {
    currentStep -= 1;
  }
  else if (currentStep >= 10) {
    currentStep -= 2;
  }

  return (
    <CollapsibleSteps current={currentStep} size='small' direction='vertical'>
      <Steps.Step title={<NavigationStep enabled={props.current != 0 && props.changeRequestId != Guid.Empty()} title='Program Information' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_1, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 1 && props.changeRequestId != Guid.Empty()} title='Program Plan' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_2, { id: props.changeRequestId })} />}/>
      <Steps.Step title={<NavigationStep enabled={props.current != 2 && props.changeRequestId != Guid.Empty()} title='Contact Information' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_3, { id: props.changeRequestId })} />}/>
      <Steps.Step title={<NavigationStep enabled={props.current != 3 && props.changeRequestId != Guid.Empty()} title='Consultant Meetings' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_4, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 4 && props.changeRequestId != Guid.Empty()} title='Occupational Information' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_5, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 5 && props.changeRequestId != Guid.Empty()} title='Industry Information' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_6, { id: props.changeRequestId })} />}/>
      <Steps.Step title={<NavigationStep enabled={props.current != 6 && props.changeRequestId != Guid.Empty()} title='Linking And Existing Entities' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_7, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 7 && props.changeRequestId != Guid.Empty()} title='Program Details' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_8, { id: props.changeRequestId })}/>} />
      <Steps.Step description={awardSteps} title={<NavigationStep enabled={props.current != 8 && props.changeRequestId != Guid.Empty()} title='Awards' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_9, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 11 && props.changeRequestId != Guid.Empty()} title='ICCPHSE' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_12, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 12 && props.changeRequestId != Guid.Empty()} title='Advisory Board' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_13, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 13 && props.changeRequestId != Guid.Empty()} title='Sign Offs and Assurances' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_14, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 14 && props.changeRequestId != Guid.Empty()} title='Additional Information' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_15, { id: props.changeRequestId })}/>} />
      <Steps.Step title={<NavigationStep enabled={props.current != 15 && props.changeRequestId != Guid.Empty()} title='Review' route={Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_REVIEW, { id: props.changeRequestId })}/>} />
      <Steps.Step title='Submitted' />
    </CollapsibleSteps>
  );
}

export default NewProgramChangeRequestSteps;
