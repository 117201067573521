// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import GenderDataTableDTO from '../models/GenderDataTableDTO';
import * as GetGenderDetailsHandler from '../handlerModels/GetGenderDetailsHandler';
import * as SaveGenderDetailsHandler from '../handlerModels/SaveGenderDetailsHandler';
import * as DeleteGenderHandler from '../handlerModels/DeleteGenderHandler';

export class GenderApiService extends BaseApi {

    // post: api/gender
    public getGendersDataTable(tableRequest: TableRequestDTO): Promise<TableResponseDTO<GenderDataTableDTO>> {
        let url = `api/gender`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<GenderDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/gender/export
    public getGenderDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/gender/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/gender/${id}
    public getGenderDetails(id: number): Promise<GetGenderDetailsHandler.Result> {
        let url = `api/gender/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetGenderDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/gender/save
    public saveGenderDetails(saveGenderDetails: SaveGenderDetailsHandler.Request): Promise<SaveGenderDetailsHandler.Result> {
        let url = `api/gender/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveGenderDetailsHandler.Result>(saveGenderDetails, 'post', url, true, false);
    }

    // post: api/gender/deleteGender
    public deleteGender(deleteGender: DeleteGenderHandler.Request): Promise<DeleteGenderHandler.Result> {
        let url = `api/gender/deleteGender`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteGenderHandler.Result>(deleteGender, 'post', url, true, false);
    }
}
var service = new GenderApiService();
export default service;
