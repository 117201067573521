// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import ResultDTO from '../models/ResultDTO';
import UserSecurityDTO from '../models/UserSecurityDTO';
import * as LocalLoginHandler from '../handlerModels/LocalLoginHandler';
import * as RegisterHandler from '../handlerModels/RegisterHandler';
import * as ConfirmEmailHandler from '../handlerModels/ConfirmEmailHandler';
import * as ResendConfirmEmailHandler from '../handlerModels/ResendConfirmEmailHandler';
import * as ResetPasswordHandler from '../handlerModels/ResetPasswordHandler';
import * as ValidateResetPasswordTokenHandler from '../handlerModels/ValidateResetPasswordTokenHandler';
import * as ChangePasswordHandler from '../handlerModels/ChangePasswordHandler';

export class AccountApiService extends BaseApi {

    // post: api/Account/Login
    public login(localLogin: LocalLoginHandler.Request): Promise<LocalLoginHandler.Result> {
        let url = `api/Account/Login`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LocalLoginHandler.Result>(localLogin, 'post', url, true, false);
    }

    // post: api/Account/Register
    public register(register: RegisterHandler.Request): Promise<RegisterHandler.Result> {
        let url = `api/Account/Register`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RegisterHandler.Result>(register, 'post', url, true, false);
    }

    // get: api/Account/ConfirmEmail?userId=${encodeURIComponent(userId)}&code=${encodeURIComponent(code)}
    public confirmEmail(userId: string, code: string): Promise<ConfirmEmailHandler.Result> {
        let url = `api/Account/ConfirmEmail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }
        if (code != null) {
            url += `${prefix}code=${encodeURIComponent(code)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ConfirmEmailHandler.Result>(null, 'get', url, true, false);
    }

    // post: api/Account/ResendConfirmEmail
    public resendConfirmEmail(resendConfirmEmail: ResendConfirmEmailHandler.Request): Promise<ResendConfirmEmailHandler.Result> {
        let url = `api/Account/ResendConfirmEmail`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResendConfirmEmailHandler.Result>(resendConfirmEmail, 'post', url, true, false);
    }

    // post: api/Account/ResendConfirmByEmail
    public resendConfirmByEmail(resendConfirmEmail: ResendConfirmEmailHandler.Request): Promise<ResendConfirmEmailHandler.Result> {
        let url = `api/Account/ResendConfirmByEmail`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResendConfirmEmailHandler.Result>(resendConfirmEmail, 'post', url, true, false);
    }

    // post: api/Account/ForgotPassword?email=${encodeURIComponent(email)}
    public forgotPassword(email: string): Promise<void> {
        let url = `api/Account/ForgotPassword`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/Account/ResetPassword
    public resetPassword(resetPassword: ResetPasswordHandler.Request): Promise<ResetPasswordHandler.Result> {
        let url = `api/Account/ResetPassword`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResetPasswordHandler.Result>(resetPassword, 'post', url, true, false);
    }

    // post: api/Account/ValidateResetPasswordToken
    public validateResetPasswordToken(validateResetPasswordToken: ValidateResetPasswordTokenHandler.Request): Promise<ValidateResetPasswordTokenHandler.Result> {
        let url = `api/Account/ValidateResetPasswordToken`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ValidateResetPasswordTokenHandler.Result>(validateResetPasswordToken, 'post', url, true, false);
    }

    // post: api/Account/ValidatePassword?password=${encodeURIComponent(password)}
    public validatePassword(password: string): Promise<ResultDTO> {
        let url = `api/Account/ValidatePassword`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (password != null) {
            url += `${prefix}password=${encodeURIComponent(password)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/Account/ChangePassword
    public changePassword(changePassword: ChangePasswordHandler.Request): Promise<ChangePasswordHandler.Result> {
        let url = `api/Account/ChangePassword`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ChangePasswordHandler.Result>(changePassword, 'post', url, true, false);
    }

    // get: api/Account/Logout
    public logout(): Promise<void> {
        let url = `api/Account/Logout`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'get', url, true, false);
    }

    // get: api/Account/GetCurrentUser
    public getCurrentUser(): Promise<UserSecurityDTO> {
        let url = `api/Account/GetCurrentUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UserSecurityDTO>(null, 'get', url, true, false);
    }

    // get: api/Account/Ping
    public ping(): Promise<string> {
        let url = `api/Account/Ping`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(null, 'get', url, true, false);
    }
}
var service = new AccountApiService();
export default service;
