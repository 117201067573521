import { InstitutionAction, InstitutionActionTypes } from '../actions/InstitutionActions';
import { InstitutionStoreState } from '../state/StateStoreModel';

export const InitialInstitutionStoreState: InstitutionStoreState = {
  Selected: null,
  InstitutionId: null
};

export function Institution(
  state: InstitutionStoreState = InitialInstitutionStoreState,
  action: InstitutionAction
): InstitutionStoreState {
  let newState: InstitutionStoreState = state;

  switch (action.type) {
    case InstitutionActionTypes.INSTITUTION_SELECT:
      newState = {
        ...state, Selected: action.data, InstitutionId: action.data?.Id ?? null
      };
      break;

    default:
      return newState;
  }
  return Object.freeze(newState);
}
