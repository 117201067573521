import { Form, FormInstance, FormItemProps, Modal, Space, Typography } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import TermDTO from '../../../models/TermDTO';
import YearTermDTO from '../../../models/YearTermDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import YesNoInput from '../../inputs/YesNoInput';
import TermInput from '../../TermInput';

interface FutureTermState extends BaseFormState {
  showTerms: boolean;
}

interface FutureTermProps extends BaseFormProps {
  terms: TermDTO[];
  onCancel?: () => void;
  submit?: (yearTerm?: YearTermDTO) => void;
  showModal: boolean | null;
  installTerm: YearTermDTO;
}

class FutureTerm extends React.Component<FutureTermProps, FutureTermState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private readonly FUTURE_TERM = 'futureTerm';
  private readonly VERSION_SELECTOR = 'versionSelector';

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(this.FUTURE_TERM, {
        label: 'Please specify the term to apply these changes.',
        required: true,
        rules: [
          () => ({ validator: ValidationUtil.termInputYearValidator(this.props.terms, this.props.installTerm, 'retire') }),
          () => ({ validator: ValidationUtil.termAndYearValidator() })
        ],
        name: this.FUTURE_TERM,
      })
      .set(this.VERSION_SELECTOR, {
        required: true,
        rules: [ValidationRuleUtil.required()],
        name: this.VERSION_SELECTOR,
      });
  }

  constructor(props: FutureTermProps) {
    super(props);

    this.state = {
      submitting: false,
      showTerms: false
    };
  }

  componentDidMount() {
    this.resetFields();
  }

  private saveVersion = () => {
    const loaders = [];
    loaders.push(this._formRef.current?.validateFields());
    Promise.all(loaders).
      finally(() => {
        const fieldError = this._formRef.current?.getFieldsError();
        let errors = true;

        fieldError?.forEach(x => {
          if (x.errors.length > 0 && errors) {
            errors = false;
          }
        });

        if (this.props.submit && errors) {
          const yearTerm = this._formRef.current?.getFieldValue([this.FUTURE_TERM]);
          this.state.showTerms ? this.props.submit(YearTermDTO.create(yearTerm)) : this.props.submit();
        }
      });
  }

  private cancelModal = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
      this.resetFields();
      this.setState({ showTerms: false });
    }
  }

  private resetFields = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = (value: boolean | null) => {
    this.setState({
      showTerms: value ?? false
    });
  }

  render() {
    const showTerm = this._formRef.current?.getFieldValue(this.VERSION_SELECTOR)

    return (
      <Modal
        visible={this.props.showModal ?? false}
        title='Update Current Version?'
        onCancel={this.cancelModal}
        okText='Submit'
        cancelText=''
        onOk={this.saveVersion}
      >
        <Space direction='vertical'>
          <Typography.Text strong> Would you like to apply these changes to the current version of this course or apply them in a specific term? Applying directly to this version will affect all terms since this version has been active.</Typography.Text>

          <Form
            ref={this._formRef}
            layout="vertical"
            requiredMark={true}>
            <Space direction='vertical' >
              <FormItem
                {...this.getFormItems().get(this.VERSION_SELECTOR)}
                {...ValidationUtil.getValidation(this.VERSION_SELECTOR, this.state.fieldErrors, this.state.submitted)}>
                <YesNoInput yesText="Specify Term" noText="Update Currently Selected" disabled={this.state.loading || this.state.saving || this.state.submitting} onChange={this.handleChange} />
              </FormItem>

              {
                showTerm ?
                  <Form.Item
                    {...this.getFormItems().get(this.FUTURE_TERM)}
                    {...ValidationUtil.getValidation(this.FUTURE_TERM, this.props.fieldErrors, true)}>
                    <TermInput />
                  </Form.Item>
                  : null
              }
            </Space>
          </Form >
        </Space>
      </Modal>
    );
  }
}

export default FutureTerm;