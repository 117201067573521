export default class Guid {
  public static Empty() {
    return '00000000-0000-0000-0000-000000000000';
  }

  public static NewGuid() {
    return (
      Guid.s4() +
      Guid.s4() +
      '-' +
      Guid.s4() +
      '-' +
      Guid.s4() +
      '-' +
      Guid.s4() +
      '-' +
      Guid.s4() +
      Guid.s4() +
      Guid.s4()
    );
  }

  private static s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  public static IsGuid(value: string) {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value);
  }
}
