// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AnnouncementDTO from '../models/AnnouncementDTO';
import * as GetAnnouncementDetailsHandler from '../handlerModels/GetAnnouncementDetailsHandler';
import * as GetAnnouncementsHandler from '../handlerModels/GetAnnouncementsHandler';
import * as SaveAnnouncementDetailsHandler from '../handlerModels/SaveAnnouncementDetailsHandler';

export class AnnouncementsApiService extends BaseApi {

    // post: api/announcements
    public getAnnouncementTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<AnnouncementDTO>> {
        let url = `api/announcements`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<AnnouncementDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/announcements/${encodeURIComponent(announcementId)}
    public getAnnouncementDetails(announcementId: string): Promise<GetAnnouncementDetailsHandler.Result> {
        let url = `api/announcements/${encodeURIComponent(announcementId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAnnouncementDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/announcements/getAnnouncementsForUser
    public getAnnouncementsForUser(getAnnouncements: GetAnnouncementsHandler.Request): Promise<GetAnnouncementsHandler.Result> {
        let url = `api/announcements/getAnnouncementsForUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAnnouncementsHandler.Result>(getAnnouncements, 'post', url, true, false);
    }

    // post: api/announcements/save
    public saveAnnouncementDetails(saveAnnouncementDetails: SaveAnnouncementDetailsHandler.Request): Promise<SaveAnnouncementDetailsHandler.Result> {
        let url = `api/announcements/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveAnnouncementDetailsHandler.Result>(saveAnnouncementDetails, 'post', url, true, false);
    }
}
var service = new AnnouncementsApiService();
export default service;
