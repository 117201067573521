import { Button, Space, Steps } from 'antd';
import * as React from 'react';
import Routes from '../../../../config/Routes';
import AcademicYearStatusType from '../../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../../consts/FeatureFlag';
import AcademicYearDTO from '../../../../models/AcademicYearDTO';
import BaseFormProps from '../../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface SelfStudyConfigurationStepProps extends BaseFormProps {
  academicYear: AcademicYearDTO;
  compactView?: boolean;
}

const finishedStatusTypes = [
  AcademicYearStatusType.REVIEWSELFSTUDYPROGRAMS,
  AcademicYearStatusType.SELFSTUDYSUBMITTED,
  AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS,
  AcademicYearStatusType.CLOSED,
]

export default function SelfStudyConfigurationStep(props: SelfStudyConfigurationStepProps) {
  const active = props.academicYear?.academicYearStatusTypeId === AcademicYearStatusType.SELFSTUDYPREPARATION;
  const finished = finishedStatusTypes.indexOf(props.academicYear.academicYearStatusTypeId) >= 0;

  const goToSelfStudyConfiguration = () => {
    HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_CONFIGURATION, { academicYearId: props.academicYear.id }, {}));
  }

  const renderContent = () => {
    if (active) {
      return <Space direction='vertical'>
        <Button type={'primary'} onClick={goToSelfStudyConfiguration}>Configure Self Study</Button>
      </ Space>;
    }

    return null;
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM_REVIEW, FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
    return <Steps.Step status={active ? 'process' : finished ? 'finish' : 'wait'} title='Self Study Configuration' description={renderContent()} />
  }

  return null;
}