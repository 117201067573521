// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CourseDTO from './CourseDTO';
import CourseDetailDTO from './CourseDetailDTO';
import DisciplineDTO from './DisciplineDTO';
import InstitutionDTO from './InstitutionDTO';
import InstitutionCourseDetailDTO from './InstitutionCourseDetailDTO';
import StatusTypeDTO from './StatusTypeDTO';
import YearTermDTO from './YearTermDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionCourseDataTableDTO extends EntitySelectorDTO { 
	id: string | null;
	course: CourseDTO | null;
	courseId: string | null;
	institutionId: string | null;
	courseDetails: CourseDetailDTO | null;
	collegeCode: string | null;
	institutionName: string | null;
	collegeNumber: number;
	discipline: DisciplineDTO | null;
	catalogNumber: string | null;
	title: string | null;
	institution: InstitutionDTO | null;
	institutionCourseDetails: InstitutionCourseDetailDTO[] | null;
	creditsMin: number;
	creditsMax: number;
	variableHours: string | null;
	category: string | null;
	description: string | null;
	status: StatusTypeDTO | null;
	institutionCourseId: string | null;
	install: YearTermDTO | null;
	retire: YearTermDTO | null;
}

const InstitutionCourseDataTableDTO = {
    className: 'InstitutionCourseDataTableDTO',
    create: (initValues?: Partial<InstitutionCourseDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			course: null,
			courseId: '00000000-0000-0000-0000-000000000000',
			institutionId: '00000000-0000-0000-0000-000000000000',
			courseDetails: null,
			collegeCode: "",
			institutionName: "",
			collegeNumber: 0,
			discipline: null,
			catalogNumber: "",
			title: "",
			institution: null,
			institutionCourseDetails: [],
			creditsMin: 0,
			creditsMax: 0,
			variableHours: "",
			category: "",
			description: "",
			status: null,
			institutionCourseId: '00000000-0000-0000-0000-000000000000',
			install: null,
			retire: null,
        },
        initValues);
    },
	id: 'id',
	course: 'course',
	courseId: 'courseId',
	institutionId: 'institutionId',
	courseDetails: 'courseDetails',
	collegeCode: 'collegeCode',
	institutionName: 'institutionName',
	collegeNumber: 'collegeNumber',
	discipline: 'discipline',
	catalogNumber: 'catalogNumber',
	title: 'title',
	institution: 'institution',
	institutionCourseDetails: 'institutionCourseDetails',
	creditsMin: 'creditsMin',
	creditsMax: 'creditsMax',
	variableHours: 'variableHours',
	category: 'category',
	description: 'description',
	status: 'status',
	institutionCourseId: 'institutionCourseId',
	install: 'install',
	retire: 'retire',
};

export default InstitutionCourseDataTableDTO;