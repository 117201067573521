// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SubmitNewCourseChangeRequestHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import NewCourseChangeRequestDTO from '../models/NewCourseChangeRequestDTO';
import ChangeRequestDTO from '../models/ChangeRequestDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    institutionId: string | null;
    newCourseChangeRequest: NewCourseChangeRequestDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            institutionId: '00000000-0000-0000-0000-000000000000',
            newCourseChangeRequest: null,
        },
        initValues);
    },
    institutionId: 'institutionId',
    newCourseChangeRequest: 'newCourseChangeRequest',
}

interface Result extends ResultDTO {
    changeRequest: ChangeRequestDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            changeRequest: null,
        },
        initValues);
    },
    changeRequest: 'changeRequest',
}

export { Request, Result };