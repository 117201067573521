// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import StatusTypeDTO from './StatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface DisciplineDTO extends EntitySelectorDTO { 
	id: string | null;
	code: string | null;
	name: string | null;
	statusTypeId: number;
	statusType: StatusTypeDTO | null;
	display: string | null;
}

const DisciplineDTO = {
    className: 'DisciplineDTO',
    create: (initValues?: Partial<DisciplineDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			code: "",
			name: "",
			statusTypeId: 0,
			statusType: null,
			display: "",
        },
        initValues);
    },
	id: 'id',
	code: 'code',
	name: 'name',
	statusTypeId: 'statusTypeId',
	statusType: 'statusType',
	display: 'display',
};

export default DisciplineDTO;