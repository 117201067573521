import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import InstructionalLevelDataTable from '../../../../components/datatables/postSecondary/InstructionalLevelDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface InstructionalLevelListProps {
}

interface InstructionalLevelListState {
}

class InstructionalLevelListPage extends React.Component<InstructionalLevelListProps & RouteComponentProps<RouteObject>, InstructionalLevelListState> {
  constructor(props: InstructionalLevelListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Instructional Level" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.instructionalLevelList()} />

        <Card >
          <InstructionalLevelDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(InstructionalLevelListPage);
