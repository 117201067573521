import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, List, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import InstitutionCourseBlockDataTable from '../../../components/datatables/postSecondary/InstitutionCourseBlockDataTable';
import InstitutionCoursesDataTable from '../../../components/datatables/postSecondary/InstitutionCoursesDataTable';
import CoursesAttachedDownloadForm from '../../../components/forms/postSecondary/CoursesAttachedDownloadForm';
import TermInput, { TermInputValue } from '../../../components/TermInput';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import CourseDTO from '../../../models/CourseDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import StatusTypeDTO from '../../../models/StatusTypeDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';
import TermYearUtil, { TermYear } from '../../../utils/TermYearUtil';

interface CourseListProps {
  institution: InstitutionDTO | null;
}

interface CourseListState {
  courseTableData: CourseDTO[];
  loading: boolean;
  exporting: boolean;
  statuses: StatusTypeDTO[] | null;
  activeTab: string;
  activeSubCourseTab: string;
  selectedTermYear: TermInputValue | undefined;
}

class CourseListPage extends React.Component<CourseListProps & RouteComponentProps<RouteObject>, CourseListState> {
  constructor(props: CourseListProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      courseTableData: [],
      loading: false,
      exporting: false,
      statuses: [],
      activeTab: 'courses',
      activeSubCourseTab: 'institution_courses',
      selectedTermYear: undefined
    };
  }

  componentDidMount() {
    const activeTab = ParameterUtil.getParameter('activeTab', 'courses');
    const subTab = ParameterUtil.getParameter('activeSubCourseTab', 'institution_courses');
    this.setState({ activeTab: activeTab, activeSubCourseTab: subTab });
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.selectedTermYear == null) {
      loaders.push(TermYearUtil.getCurrent().then((x: TermYear) => this.setState({
        selectedTermYear: {
          termId: x.termId ?? Guid.Empty(),
          year: x.year ?? 2021
        }
      })));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private tabChanged = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  private courseSubTabChanged = (activeTab: string) => {
    this.setState({ activeSubCourseTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: this.state.activeTab, activeSubCourseTab: activeTab }), { activeTab: this.state.activeTab, activeSubCourseTab: activeTab });
  }

  private handleTermYearChange = (termYear: TermInputValue) => {
    this.setState({
      selectedTermYear: {
        termId: termYear.termId,
        year: termYear.year
      } as TermInputValue,
    });
  }

  private getExcelDownloadList = () => {
    return [
      {
        title: 'Institution Course Blocks Report',
        description: 'File contains Course Title, Discipline, and Catalog Numbers for all Course Blocks.',
        action: () => this.CourseBlockExport()
      },
      {
        title: 'Common Course Numbering Report',
        description: 'File contains a large set of data for all courses in the term/year selected at the top of the screen.',
        action: () => this.downloadCCN()
      }
    ];
  }

  private CourseBlockExport = () => {
    window.open(Routes.generateFull(Routes.INSTITUTION_COURSE_BLOCK_REPORT, {}, { institutionId: this.props.institution?.id ?? Guid.Empty() }), '_blank')
  }

  private downloadCCN = () => {
    const termId = this.state.selectedTermYear?.termId;
    const year = this.state.selectedTermYear?.year;
    window.open(Routes.generateFull(Routes.COMMON_COURSE_NUMBERING_REPORT, {}, { termId: termId, year: year }), '_blank');
  }

  render() {
    return (
      <Content >
        <HeaderPortal
          title="Courses" breadcrumbs={Breadcrumbs.courseList()}
          footer={
            <Tabs activeKey={this.state.activeTab} onChange={this.tabChanged} >
              <Tabs.TabPane key="courses" tab="Courses" />
              <Tabs.TabPane key="course_blocks" tab="Course Blocks" />
              <Tabs.TabPane key="downloads" tab="Downloads" />
            </Tabs>
          }
          extra={<TermInput value={this.state.selectedTermYear} onChange={this.handleTermYearChange} />} />

        {this.renderCourse()}
        {this.renderInstitutionCourseBlockDataTable()}
        {this.renderDownloads()}
      </Content>
    );
  }

  renderCourse() {
    if (this.state.activeTab === 'courses') {

      return this.props.institution && this.props.institution?.id != Guid.Empty() ? <Card>
        <Tabs activeKey={this.state.activeSubCourseTab} onChange={this.courseSubTabChanged} >
          <Tabs.TabPane key="institution_courses" tab={(this.props.institution?.abbreviation ?? 'Community College') + ' Course'} />
          <Tabs.TabPane key="all_courses" tab="All Courses" />
        </Tabs>
        {this.renderAllCoursesDataTable()}
        {this.renderInstitutionCourseDataTable()}
      </Card> :
        <Card>
          <InstitutionCoursesDataTable
            institutionId={this.props.institution?.id ?? Guid.Empty()}
            termYear={{ termId: this.state.selectedTermYear?.termId ?? Guid.Empty(), year: this.state.selectedTermYear?.year ?? 0 }} />
        </Card>
    }
  }

  renderAllCoursesDataTable() {
    if (this.state.activeSubCourseTab === 'all_courses') {
      return <Card>
        <InstitutionCoursesDataTable
          institutionId={this.props.institution?.id ?? Guid.Empty()}
          termYear={{ termId: this.state.selectedTermYear?.termId ?? Guid.Empty(), year: this.state.selectedTermYear?.year ?? 0 }} />
      </Card>
    }
  }

  renderInstitutionCourseDataTable() {
    if (this.state.activeSubCourseTab === 'institution_courses') {
      return <Card>
        <InstitutionCoursesDataTable
          institutionId={this.props.institution?.id ?? Guid.Empty()}
          useSelectedInstitution={true}
          termYear={{ termId: this.state.selectedTermYear?.termId ?? Guid.Empty(), year: this.state.selectedTermYear?.year ?? 0 }} />
      </Card>
    }
  }

  renderInstitutionCourseBlockDataTable() {
    if (this.state.activeTab === 'course_blocks') {
      return <Card title={'Course Blocks'}>
        <InstitutionCourseBlockDataTable selectedInstitution={this.props.institution} />
      </Card>
    }
  }

  renderDownloads() {
    if (this.state.activeTab === 'downloads') {
      return (
        <>
          <Card title='Courses Attached'>
            <CoursesAttachedDownloadForm institutionId={this.props.institution?.id ?? undefined} />
          </Card>
          <Card title='Excel Downloads'>
            <List
              itemLayout="horizontal"
              dataSource={this.getExcelDownloadList()}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<DownloadOutlined />}
                    title={<Button type="link" onClick={item.action}>{item.title}</Button>}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Card>
        </>
      );
    }
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected,
  };
}

export default withRouter(connect(mapStateToProps)(CourseListPage));
