import { Card, Skeleton, Space } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import FormList from 'antd/lib/form/FormList';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SecondaryProgramAttachmentApiService from '../../api/SecondaryProgramAttachmentApiService';
import SelfStudyApiService from '../../api/SelfStudyApiService';
import SelfStudyTemplateApiService from '../../api/SelfStudyTemplateApiService';
import Routes from '../../config/Routes';
import SecondaryProgramStatusType from '../../consts/SecondaryProgramStatusType';
import SelfStudyResponseStatusType from '../../consts/SelfStudyResponseStatusType';
import SelfStudyResponseType from '../../consts/SelfStudyResponseType';
import * as GetSecondaryProgramAttachmentsHandler from '../../handlerModels/GetSecondaryProgramAttachmentsHandler';
import * as GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler from '../../handlerModels/GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler';
import * as GetSelfStudyWithConsultantReviewHandler from '../../handlerModels/GetSelfStudyWithConsultantReviewHandler';
import SecondaryProgramAttachmentDTO from '../../models/SecondaryProgramAttachmentDTO';
import SecondaryProgramReviewHistoryDTO from '../../models/SecondaryProgramReviewHistoryDTO';
import SecondaryProgramStatusTypeDTO from '../../models/SecondaryProgramStatusTypeDTO';
import SelfStudyDTO from '../../models/SelfStudyDTO';
import SelfStudyResponseConsultantReviewQuestionDTO from '../../models/SelfStudyResponseConsultantReviewQuestionDTO';
import SelfStudyResponseDTO from '../../models/SelfStudyResponseDTO';
import SelfStudySectionComponentResponseDTO from '../../models/SelfStudySectionComponentResponseDTO';
import SelfStudySectionComponentTemplateDTO from '../../models/SelfStudySectionComponentTemplateDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import Guid from '../../utils/Guid';
import LookupsUtil from '../../utils/LookupsUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import CheckboxInputGroup, { CheckboxInputGroupOption } from '../CheckboxInputGroup';
import FileUpload from '../FileUpload';
import ValueLabel from '../general/ValueLabel';
import RichTextEditor from '../inputs/RichTextEditor';
import SelfStudyConsultantReviewQuestion from './SelfStudyConsultantReviewQuestion';
import { UploadFile } from 'antd/lib/upload/interface';
import { Html } from 'react-pdf-html';
import { Text, View } from '@react-pdf/renderer';

interface SelfStudyConsultantReviewProps extends BaseFormProps {
  academicYear?: number;
  selfStudyResponseId?: string;
  selfStudyId?: string;
  sectionIndex?: number;
  readonly?: boolean;
  disable?: boolean;
  componentIndex: number;
  previewComponent?: boolean;
  consultantReviewQuestionCount?: number;
  handleFileUpload?: ((componentTemplateIndex: number, sectionTemplateIndex: number | undefined, value: UploadFile | undefined) => void) | undefined;
  handleFileRemoved?: ((componentTemplateIndex: number, sectionTemplateIndex: number, value: UploadFile | undefined) => void) | undefined;
  pdfView?: boolean;
  renderComplete?: (sectionIndex: number,componentIndex: number, value: boolean) => void;
  selfStudyResponseTypeId?: number;
}

const SelfStudyConsultantReview = React.forwardRef((props: SelfStudyConsultantReviewProps) => {

  const getConsultantReview = (index: number) => {
    const required = !props.disable;
    return new Map<string, FormItemProps>()
      .set(SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer, {
        required: required,
        name: [index, SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer],
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer, {
        required: required,
        name: [index, SelfStudyResponseConsultantReviewQuestionDTO.consultantAnswer],
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentTemplateDTO.primaryText, {
        name: [index, SelfStudySectionComponentTemplateDTO.primaryText],
      })
      .set(SelfStudySectionComponentTemplateDTO.rppInstructions, {
        name: [index, SelfStudySectionComponentTemplateDTO.rppInstructions],
      })
      .set(SelfStudyResponseConsultantReviewQuestionDTO.comment, {
        name: [index, SelfStudyResponseConsultantReviewQuestionDTO.comment],
        label: 'Additional Comments',
      })
      .set(SecondaryProgramReviewHistoryDTO.secondaryProgramStatusTypeId, {
        required: true,
        name: [index, SecondaryProgramReviewHistoryDTO.secondaryProgramStatusTypeId],
        label: 'Please set the approval status for this program.',
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentResponseDTO.responseText, {
        label: 'Additional Comments',
        name: [index, SelfStudySectionComponentResponseDTO.responseText],
      })
      .set(SelfStudySectionComponentResponseDTO.rppApproval, {
        required: selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP ? true : false,
        label: selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP ? 'Please approve or deny.' : '',
        name: [index, SelfStudySectionComponentResponseDTO.rppApproval],
        rules: selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP ? [ValidationRuleUtil.required()] : []
      });
  }

  const getComponentFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(SecondaryProgramAttachmentDTO.className, {
        name: [index, SecondaryProgramAttachmentDTO.className],
      })
  }

  const [loading, setLoading] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(props.fieldErrors);
  const [submitted, setSubmitted] = useState(props.submitted);
  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);
  const [selfStudyResponse, setSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [selfStudySectionComponentTemplate, setSelfStudySectionComponentTemplate] = useState({} as SelfStudySectionComponentTemplateDTO);
  const [secondaryProgramAttachments, setSecondaryProgramAttachments] = useState([] as SecondaryProgramAttachmentDTO[]);
  const [secondaryProgramStatusTypes, setSecondaryProgramStatusTypes] = useState([] as SecondaryProgramStatusTypeDTO[]);

  useEffect(() => {
    fetchData();
  }, [props.academicYear, props.selfStudyResponseId]
  )

  useEffect(() => {
    setFieldErrors(props.fieldErrors);
    setSubmitted(props.submitted);
  }, [props.submitted, props.fieldErrors])

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (props.academicYear != 0 &&
      props.selfStudyResponseId != Guid.Empty() &&
      !props.previewComponent) {
      loaders.push(loadSelfStudy(props.selfStudyResponseId ?? Guid.Empty()));
      loaders.push(loadSelfStudyResponseStatusTypes());
      loaders.push(loadSelfStudySectionComponentTemplate());

      Promise.all(loaders).then(() => {
        setLoading(false);
        if(props.renderComplete){
          props.renderComplete(props.sectionIndex ?? 0,props.componentIndex, true);
        }
      });
    }
    else if (props.previewComponent) {
      setLoading(false);
      if(props.renderComplete){
        props.renderComplete(props.sectionIndex ?? 0,props.componentIndex, true);
      }
    }
  }

  const loadSelfStudy = (id: string) => {
    const request = GetSelfStudyWithConsultantReviewHandler.Request.create({
      selfStudyResponseId: id,
      academicYear: props.academicYear
    });

    return SelfStudyApiService.getSelfStudyWithConsultantReview(request)
      .then((results: GetSelfStudyWithConsultantReviewHandler.Result) => {
        if (results.selfStudy) {
          setSelfStudy(results.selfStudy);
          setSelfStudyResponse(results.selfStudy.selfStudyResponses?.find(x => x.id == id) ?? SelfStudyResponseDTO.create());

          const loaders = [];
          loaders.push(loadSecondaryProgramAttachment(props.academicYear ?? 0, results.selfStudy.secondaryProgram?.secondaryProgramId ?? 0));

          Promise.all(loaders).then(() => {
            setAttachmentLoading(false);
          });
        }
      });
  }

  const loadSelfStudySectionComponentTemplate = () => {
    const request = GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Request.create({
      academicYear: props.academicYear,
      selfStudySectionOrder: props.sectionIndex,
      selfStudySectionComponentOrder: props.componentIndex
    })
    return SelfStudyTemplateApiService.getSelfStudySectionComponentTemplateBySectionOrder(request)
      .then((results: GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Result) => {
        if (results.selfStudySectionComponentTemplate) {
          setSelfStudySectionComponentTemplate(results.selfStudySectionComponentTemplate ?? SelfStudySectionComponentTemplateDTO.create());
        }
      });
  }


  const loadSelfStudyResponseStatusTypes = () => {
    return LookupsUtil.getAll<SecondaryProgramStatusTypeDTO>(SecondaryProgramStatusTypeDTO.className)
      .then((results: SecondaryProgramStatusTypeDTO[]) => {
        setSecondaryProgramStatusTypes(results.filter(x => x.selectable));
      });
  }

  const loadSecondaryProgramAttachment = (academicYear: number, secondaryProgramId: number) => {
    setAttachmentLoading(true);

    const request = GetSecondaryProgramAttachmentsHandler.Request.create({
      academicYear: academicYear,
      secondaryProgramId: secondaryProgramId
    });

    return SecondaryProgramAttachmentApiService.getSecondaryProgramAttachment(request)
      .then((results: GetSecondaryProgramAttachmentsHandler.Result) => {
        if (results.secondaryProgramAttachments) {
          setSecondaryProgramAttachments(results.secondaryProgramAttachments);
        }
      });
  }

  const renderRppReview = () => {
    if (selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR || revision) {
      return (
        <Space direction='vertical'>

          {selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP ?
            <RichTextEditor readonly={true} value={selfStudySectionComponentTemplate?.rppInstructions} /> :
            <ValueLabel text={'Regional Planning Partner Approval Status'}></ValueLabel>
          }
          <FormItem
            {...getConsultantReview(props.componentIndex).get(SelfStudySectionComponentResponseDTO.rppApproval)}
            {...ValidationUtil.getValidation(SelfStudySectionComponentResponseDTO.rppApproval + props.sectionIndex + props.componentIndex, fieldErrors, submitted)} >
            {props.readonly || selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE ? <ValueLabel strong={selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP ? true : false} text={'Approved'}></ValueLabel> :
              <CheckboxInputGroup
                direction='horizontal'
                options={[{ id: false, display: 'Approve' }, { id: true, display: 'Deny' }] ?? []}
                disabled={props.readonly} />}
          </FormItem>
        </Space>
      );
    }

    return null;
  }

  const renderPdfRppReview = () => {
    if (selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR || revision) {
      
      const rppResponse = selfStudyResponse.selfStudySectionResponses?.at(props.sectionIndex ?? 0)?.selfStudySectionComponentResponses?.at(props.componentIndex ?? 0)?.rppApproval;
      return (

        <View>
          {selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP ?
            <Html style={{ fontSize: 10, paddingBottom: '20pt' }}>{selfStudySectionComponentTemplate?.rppInstructions ?? ''}</Html> :
            <Text style={{ fontSize: 10, paddingBottom: '10pt' }}> Regional Planning Partner Approval Status</Text>
          }
          {props.readonly || selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE ?
            <Text style={{ fontSize: 8, paddingBottom: '10pt' }}>Approved</Text> :
            <View>
              <Text style={{ fontSize: 10, paddingBottom: '10pt' }}>Please approve or deny</Text>
              <Text style={{ fontSize: 8 }}>{ rppResponse == undefined ? '' : rppResponse == true ? 'Approved' : 'Deny'}</Text>
            </ View>}
        </View>
      );
    }

    return null;
  }

  const handleFileUpload = (file: UploadFile | undefined) => {
    if (props.handleFileUpload) {
      props.handleFileUpload(props.componentIndex, props.sectionIndex, file);
    }
  }

  const renderPdfDoeReview = () => {
    const statusName = secondaryProgramStatusTypes.find(x => x.id == selfStudy.secondaryProgram?.secondaryProgramReviewHistories?.find(x => x.reviewYear == props.academicYear)?.secondaryProgramStatusTypeId)?.name ?? secondaryProgramStatusTypes.find(x => x.id == selfStudy.secondaryProgram?.secondaryProgramReviewHistories?.find(x => x.nextReviewYear == props.academicYear)?.secondaryProgramStatusTypeId)?.name;
    if (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE) {
      return (
        <View>
          <Html style={{ paddingBottom: '10pt', fontSize: 10 }}>{selfStudySectionComponentTemplate?.primaryText ?? ''}</Html>
          <SelfStudyConsultantReviewQuestion
            academicYear={props.academicYear}
            selfStudyId={props.selfStudyId}
            selfStudyResponseId={props.selfStudyResponseId}
            sectionIndex={props.sectionIndex}
            componentIndex={props.componentIndex}
            readonly={props.readonly}
            fieldErrors={fieldErrors}
            submitted={submitted}
            pdfView={props.pdfView}
          />

          <Text style={{ paddingBottom: '10pt', fontSize: 10 }}>{selfStudy.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.RPP)?.selfStudySectionResponses?.find(x => x.selfStudySectionTemplate?.order == props.sectionIndex)?.selfStudySectionComponentResponses?.find(x => x.selfStudySectionComponentTemplate?.order == props.componentIndex)?.responseText}</Text>
          <Text style={{ paddingBottom: '10pt', fontSize: 10 }}>DOE Approval Status</Text>
          <Text style={{ paddingBottom: '10pt', fontSize: 8 }}>{statusName}</Text>
        </View>
      );
    }

    return null;
  }

  const renderDoeReview = () => {
    let checkboxOptions = secondaryProgramStatusTypes?.map(x => { return { id: x.id, display: x.name } }) as CheckboxInputGroupOption[];
    checkboxOptions = checkboxOptions.filter(x => x.id != SecondaryProgramStatusType.PENDING && x.id != SecondaryProgramStatusType.NEWPROGRAM);

    const status = selfStudy.secondaryProgram?.secondaryProgramReviewHistories?.find(x => x.reviewYear == props.academicYear)?.secondaryProgramStatusTypeId ?? selfStudy.secondaryProgram?.secondaryProgramReviewHistories?.find(x => x.nextReviewYear == props.academicYear)?.secondaryProgramStatusTypeId;
    const statusName = secondaryProgramStatusTypes.find(x => x.id == selfStudy.secondaryProgram?.secondaryProgramReviewHistories?.find(x => x.reviewYear == props.academicYear)?.secondaryProgramStatusTypeId)?.name ?? secondaryProgramStatusTypes.find(x => x.id == selfStudy.secondaryProgram?.secondaryProgramReviewHistories?.find(x => x.nextReviewYear == props.academicYear)?.secondaryProgramStatusTypeId)?.name;
    const existingFiles = getExistingDEConsultantReview();
    if (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE) {
      return (
        <Space direction='vertical'>
          <FormItem
            initialValue={selfStudySectionComponentTemplate?.primaryText}
            {...getConsultantReview(props.componentIndex ?? 0).get(SelfStudySectionComponentTemplateDTO.primaryText)}
            {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.primaryText + props.sectionIndex + props.componentIndex, fieldErrors, submitted)} >{
              <RichTextEditor readonly={true} />
            }
          </FormItem>

          <FormList name={[props.componentIndex, SelfStudySectionComponentResponseDTO.selfStudyResponseConsultantReviewQuestionDTOs]} >
            {
              () => {
                return <SelfStudyConsultantReviewQuestion
                  academicYear={props.academicYear}
                  selfStudyId={props.selfStudyId}
                  selfStudyResponseId={props.selfStudyResponseId}
                  sectionIndex={props.sectionIndex}
                  componentIndex={props.componentIndex}
                  readonly={props.readonly}
                  fieldErrors={fieldErrors}
                  submitted={submitted}
                />
              }
            }
          </FormList>

          <FormItem
            {...getConsultantReview(props.componentIndex ?? 0).get(SelfStudySectionComponentResponseDTO?.responseText)}
            {...ValidationUtil.getValidation(SelfStudySectionComponentResponseDTO.responseText + props.sectionIndex + props.componentIndex, fieldErrors, submitted)} >{
              revision ?
                <ValueLabel text={selfStudy.selfStudyResponses?.find(x => x.selfStudyReponseTypeId == SelfStudyResponseType.RPP)?.selfStudySectionResponses?.find(x => x.selfStudySectionTemplate?.order == props.sectionIndex)?.selfStudySectionComponentResponses?.find(x => x.selfStudySectionComponentTemplate?.order == props.componentIndex)?.responseText} /> :
                <RichTextEditor readonly={!props.disable && (props.readonly)} />
            }
          </FormItem>

          <FormItem
            {...getComponentFormItems(props.componentIndex).get(SecondaryProgramAttachmentDTO.className)}
            {...ValidationUtil.getValidation(SecondaryProgramAttachmentDTO.className, fieldErrors, submitted)} >
            <FileUpload onUploaded={handleFileUpload} disabled={props.readonly} readonly={props.readonly} existingList={existingFiles} style={{ width: 300 }} text={'Supporting documents'} />
          </FormItem>

          <FormItem
            initialValue={status}
            {...getConsultantReview(props.componentIndex).get(SecondaryProgramReviewHistoryDTO.secondaryProgramStatusTypeId)}
            {...ValidationUtil.getValidation(SecondaryProgramReviewHistoryDTO.secondaryProgramStatusTypeId + props.sectionIndex + props.componentIndex, fieldErrors, submitted)} >
            {props.readonly ? <ValueLabel text={statusName}></ValueLabel> :
              <CheckboxInputGroup
                direction='horizontal'
                options={checkboxOptions ?? []}
                disabled={props.readonly} />}
          </FormItem>
        </Space>);
    }
    return null;
  }

  const getExistingDEConsultantReview = () => {
    const existingFiles: any[] | undefined = [];
    if (secondaryProgramAttachments && secondaryProgramAttachments.length > 0) {
      secondaryProgramAttachments.forEach(x => {
        if (secondaryProgramAttachments != undefined) {
          existingFiles.push({
            uid: x.azureBlobFileId,
            url: Routes.generateDownload(x.azureBlobFileId ?? Guid.Empty()),
            name: x.azureBlobFile?.fileName
          });
        }
      });
    }

    return existingFiles;
  }

  const revision = selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR && (selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISING || selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISIONSNEEDED);
if(props.selfStudyResponseTypeId != SelfStudyResponseType.INSTRUCTOR){
  if (props.pdfView) {
    const text = selfStudySectionComponentTemplate?.primaryText ?? '';
    const finalText = text.replaceAll(new RegExp('font-size:?[0-9]{1,2}px','g'), 'font-size:10px');
    return (
      <View style={{ margin: 10 }}>
        {props.previewComponent ? <Html>{finalText}</Html> :
          <View style={{ border: '1pt solid #f0f0f0' }}>
            <Text style={{ padding: 5, fontSize: 10, border: '1pt solid #f0f0f0', backgroundColor: '#f0f0f0' }}>
              Program Approval
            </Text>
            <View style={{ margin: 10 }}>
              {renderPdfDoeReview()}
              {renderPdfRppReview()}
            </ View>
          </View>
        }
      </View>
    )
  }
  else if (loading || attachmentLoading) {
    return <Skeleton active />
  }
  else if (!props.previewComponent) {
    return (
      <Card title='Program Approval' type='inner'>
        {renderDoeReview()}
        {renderRppReview()}
      </Card>
    );
  }
  else {
    return <FormItem
      initialValue={selfStudySectionComponentTemplate?.primaryText}
      {...getConsultantReview(props.componentIndex ?? 0).get(SelfStudySectionComponentTemplateDTO.primaryText)}
      {...ValidationUtil.getValidation(SelfStudySectionComponentTemplateDTO.primaryText + props.sectionIndex + props.componentIndex, fieldErrors, submitted)} >{
        <RichTextEditor readonly={true} />
      }
    </FormItem>;
  }
}
return null
});

SelfStudyConsultantReview.displayName = 'SelfStudyConsultantReview';

export default SelfStudyConsultantReview;