import { ButtonProps } from 'antd';
import * as React from 'react';
import Routes from '../../config/Routes';
import DownloadButton from '../buttons/DownloadButton';

interface FileDownloadProps extends ButtonProps {
  azureBlobFileId?: string;
  link?: string;
  text?: any;
}

function FileDownload(props: FileDownloadProps) {
  const content = props.text ? props.text : props.children;
  const link = props.azureBlobFileId ? Routes.generateDownload(props.azureBlobFileId) : props.link;

  return (
    <a href={link} target='_blank' rel="noopener noreferrer">
      <DownloadButton type='link' title={content} />
    </a>
  );
}

export default FileDownload;
