import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TransferMajorLactsCategoryApiService from '../../../../api/TransferMajorLactsCategoryApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import TransferMajorLactsCategoryDetailsForm from '../../../../components/forms/postSecondary/TransferMajorLactsCategoryDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteTransferMajorLactsCategoryHandler from '../../../../handlerModels/DeleteTransferMajorLactsCategoryHandler';
import * as GetTransferMajorLactsCategoryDetailsHandler from '../../../../handlerModels/GetTransferMajorLactsCategoryDetailsHandler';
import TransferMajorLactsCategoryDTO from '../../../../models/TransferMajorLactsCategoryDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface TransferMajorLactsCategoryDetailsPageState extends BaseFormState {
  transferMajorLactsCategoryId: number;
  transferMajorLactsCategory?: TransferMajorLactsCategoryDTO;
}

interface TransferMajorLactsCategoryDetailsPageProps {
}

class TransferMajorLactsCategoryDetailsPage extends React.Component<TransferMajorLactsCategoryDetailsPageProps & RouteComponentProps<RouteObject>, TransferMajorLactsCategoryDetailsPageState> {
  private readonly _TransferMajorLactsCategoryDetailsRef = React.createRef<TransferMajorLactsCategoryDetailsForm>();

  constructor(props: TransferMajorLactsCategoryDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      transferMajorLactsCategoryId: 0
    };
  }

  componentDidMount() {
    const TransferMajorLactsCategoryId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (TransferMajorLactsCategoryId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const TransferMajorLactsCategoryIdNumber = Number.parseInt(TransferMajorLactsCategoryId);
      this.loadTransferMajorLactsCategory(TransferMajorLactsCategoryIdNumber);
    }
  }

  private loadTransferMajorLactsCategory = (transferMajorLactsCategoryId: number) => {
    this.setState({ transferMajorLactsCategoryId: transferMajorLactsCategoryId });

    TransferMajorLactsCategoryApiService.getTransferMajorLactsCategoryDetails(transferMajorLactsCategoryId)
      .then((results: GetTransferMajorLactsCategoryDetailsHandler.Result) => {
        if (results.transferMajorLactsCategory) {
          this.setState({ transferMajorLactsCategory: results.transferMajorLactsCategory });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Transfer Major LACTS Category?',
      okText: 'Delete',
      onOk: () => this.deleteTransferMajorLactsCategory(id)
    });
  }

  private deleteTransferMajorLactsCategory = (transferMajorLactsCategoryId: number) => {
    this.setState({ submitting: true });

    const request = DeleteTransferMajorLactsCategoryHandler.Request.create({
      transferMajorLactsCategoryId: transferMajorLactsCategoryId,
    });

    TransferMajorLactsCategoryApiService.deleteTransferMajorLactsCategory(request)
      .then((result: DeleteTransferMajorLactsCategoryHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Transfer Major Lacts Category was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Transfer Major Lacts Category could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToTransferMajorLactsCategory();
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._TransferMajorLactsCategoryDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private TransferMajorLactsCategoryDetailsSaved = (transferMajorLactsCategoryId: number) => {
    this.setState({ isEditing: false });
    if (transferMajorLactsCategoryId != this.state.transferMajorLactsCategoryId) {
      HistoryUtil.replace(Routes.generate(Routes.TRANSFER_MAJOR_LACTS_CATEGORY_DETAILS, { id: transferMajorLactsCategoryId }));
    }
    else {
      this.loadTransferMajorLactsCategory(transferMajorLactsCategoryId);
    }
  }

  private goToTransferMajorLactsCategory = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.transferMajorLactsCategoryId == 0 ? 'New Transfer Major Lacts Category' : this.state.transferMajorLactsCategory?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToTransferMajorLactsCategory}
          breadcrumbs={Breadcrumbs.transferMajorLactsCategoryDetails(title, this.state.transferMajorLactsCategoryId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.transferMajorLactsCategoryId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_LACTS_CATEGORY]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <TransferMajorLactsCategoryDetailsForm
          ref={this._TransferMajorLactsCategoryDetailsRef}
          transferMajorLactsCategoryId={this.state.transferMajorLactsCategoryId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.TransferMajorLactsCategoryDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Transfer Major Lacts Category' key={this.state.transferMajorLactsCategoryId} onClick={() => this.deleteConfirmation(this.state.transferMajorLactsCategoryId)}  >
          Delete Transfer Major Lacts Category
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_LACTS_CATEGORY]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(TransferMajorLactsCategoryDetailsPage);
