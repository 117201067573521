import { Skeleton } from 'antd';
import * as React from 'react';
import ProgramApiService from '../../../api/ProgramApiService';
import Routes from '../../../config/Routes';
import CipNumberDetailDTO from '../../../models/CipNumberDetailDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import ProgramDataTableDTO from '../../../models/ProgramDataTableDTO';
import TermDTO from '../../../models/TermDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import ActiveComponentUtil from '../../../utils/StatusUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import { TermYear } from '../../../utils/TermYearUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface ProgramDataTableProps {
  institution: InstitutionDTO | null;
  termYear: TermYear | null;
}

interface ProgramDataTableState extends BaseDataTableState<ProgramDataTableDTO> {
  statuses: TableFilterOption[]
  institutions: TableFilterOption[];
  awardTypes: TableFilterOption[];
  cipNumbers: CipNumberDetailDTO[];
  iowaVariations: TableFilterOption[];
  programClassifications: TableFilterOption[];
  terms: TermDTO[];

}

class ProgramDataTable extends React.Component<ProgramDataTableProps, ProgramDataTableState> {
  private _dataTable: DataTable<ProgramDataTableDTO> | undefined;

  constructor(props: ProgramDataTableProps) {
    super(props);

    this.state = {
      statuses: [],
      institutions: [],
      awardTypes: [],
      cipNumbers: [],
      iowaVariations: [],
      programClassifications: [],
      terms: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    const loaders = [] as any[];
    this.setState({ loading: true });

    TableFilterUtil.Institutions().then(x => this.setState({ institutions: x }));
    TableFilterUtil.AwardTypes(true).then(x => this.setState({ awardTypes: x }));
    TableFilterUtil.IowaVariations().then(x => this.setState({ iowaVariations: x }));
    TableFilterUtil.StatusTypes().then(x => this.setState({ statuses: x }));
    TableFilterUtil.ProgramClassificationTypes().then(x => this.setState({ programClassifications: x }));
    loaders.push(this.loadTerms());


    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadTerms = () => {
    return LookupsUtil.getAll<TermDTO>(TermDTO.className)
      .then((results: TermDTO[]) => {
        if (results) {
          this.setState({ terms: results ?? [] });
        }
      });
  }

  private getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Title',
        dataIndex: ProgramDataTableDTO.title,
        render: (data: string, row: ProgramDataTableDTO) => {
          return row.title;
        },
        sorter: true,
        filterType: FilterType.Text,
        width: 225
      },
      {
        title: 'Awards',
        dataIndex: ProgramDataTableDTO.awardTypeIds,
        render: (data: string, row: ProgramDataTableDTO) => {
          const types = this.state.awardTypes.filter(x => row.awardTypeIds.some(y => y == x.value)).map(x => x.text);
          return types.join(', ');
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.awardTypes,
        width: 225
      },
      {
        title: 'Program Type',
        dataIndex: ProgramDataTableDTO.programClassification,
        render: (data: string, row: ProgramDataTableDTO) => {
          return row.programClassification?.name
        },
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.programClassifications,
        width: 225
      },
      {
        title: 'CIP Number ',
        dataIndex: ProgramDataTableDTO.cipNumberId,
        render: (data: string, row: ProgramDataTableDTO) => {
          if (row.cipNumber?.cipNumberDetails && row.cipNumber?.cipNumberDetails?.length > 0) {
            return row.cipNumber.cipNumberDetails[0].friendlyDisplay;
          }
        },
        sorter: true,
        filterType: FilterType.Text,
        width: 225
      },
      {
        title: 'Transfer',
        dataIndex: ProgramDataTableDTO.isTransferMajor,
        render: (data: string, row: ProgramDataTableDTO) => {
          return row.isTransferMajor ? 'Yes' : '';
        },
        sorter: true,
        filterType: FilterType.DropdownSingle,
        dropdownFilterOptions: [{ value: 'transfer', text: 'Yes' }, { value: 'cte', text: 'No' }],
        width: 100
      },
      {
        title: 'Iowa Variation',
        dataIndex: ProgramDataTableDTO.iowaVariationId,
        render: (data: string, row: ProgramDataTableDTO) => {

          return row.iowaVariation?.display;
        },
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.iowaVariations,
        width: 225
      },
    ] as DataTableColumnProps<any>[];

    if (!this.props.institution) {
      columns.push({
        title: 'Community College',
        dataIndex: ProgramDataTableDTO.institutionId,
        render: (data: string, row: ProgramDataTableDTO) => {
          return row.institution?.name ?? '';
        },
        width: 275,
        filterType: FilterType.DropdownMulti,
        sorter: true,
        dropdownFilterOptions: this.state.institutions
      });
    }

    columns.push({
      title: 'Status',
      dataIndex: ProgramDataTableDTO.status,
      render: (data: string, row: ProgramDataTableDTO) => {
        return ActiveComponentUtil.isActiveFromYearTerm(row?.install, row?.retire, this.props.termYear, this.state.terms);
      },
      width: 175,
      filterType: FilterType.DropdownMulti,
      dropdownFilterOptions: this.state.statuses
    });

    return columns;
  };

  private openProgram = (record: ProgramDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.PROGRAM_DETAILS, { id: record.programId ?? Guid.Empty() }, {}));
    }
  }

  render() {
    if (this.state.loading == undefined || this.state.loading) {
      return <Skeleton />
    }

    return (
      <div>
        {this.renderDataTable()}
      </div>
    );
  }

  renderDataTable() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    return (
      <DataTable ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openProgram}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => ProgramApiService.getProgramTableData(requestState, this.props.institution?.id ?? Guid.Empty(), this.props.termYear?.termId ?? Guid.Empty(), this.props.termYear?.year ?? 0)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'programs_list' }} />
    );
  }
}

export default ProgramDataTable;
