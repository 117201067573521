import { UserSessionAction, UserSessionActionTypes } from '../actions/UserSessionActions';
import { UserSessionStoreState } from '../state/StateStoreModel';

export const InitialUserSessionStoreState: UserSessionStoreState = {
  Loading: false,
  Checking: true,
  Value: null,
  Result: null,
  ErrorMessage: null,
  LastInteraction: null
};

export function UserSession(
  state: UserSessionStoreState = InitialUserSessionStoreState,
  action: UserSessionAction
): UserSessionStoreState {
  let newState: UserSessionStoreState = state;
  let us: any = null;
  let error: any = null;

  switch (action.type) {
    case UserSessionActionTypes.USER_SESSION_CHANGED:
      us = action.data ? Object.freeze(action.data) : action.data;
      newState = { ...state, Value: us, Result: null, ErrorMessage: null };
      break;

    case UserSessionActionTypes.USER_SESSION_LOADING:
      error = state.Result;
      if (action.data) {
        error = null;
      }
      newState = { ...state, Loading: action.data, Result: error };
      break;

    case UserSessionActionTypes.USER_SESSION_CHECKING:
      newState = { ...state, Checking: action.data, ErrorMessage: action.data };
      break;

    case UserSessionActionTypes.USER_SESSION_LOGIN_ERROR:
      newState = { ...state, ErrorMessage: action.data };
      break;

    default:
      return newState;
  }
  return Object.freeze(newState);
}
