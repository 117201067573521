import { InputNumber, InputNumberProps } from 'antd';
import * as React from 'react';
import ValueLabel from '../general/ValueLabel';

interface ReadableNumberInputProps extends InputNumberProps {
  readonly?: boolean;
}

function ReadableNumberInput(props: React.PropsWithChildren<ReadableNumberInputProps>) {
  if (props.readonly) {
    return (
      <ValueLabel text={props.value} />
    );
  }
  return (
    <InputNumber {...props} />
  );
}

export default ReadableNumberInput;
