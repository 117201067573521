import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NewProgramChangeRequestApiService from '../../../../api/NewProgramChangeRequestApiService';
import ChangeRequestSubmitted from '../../../../components/displays/postSecondary/ChangeRequestSubmitted'
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import * as GetNewProgramChangeRequestHandler from '../../../../handlerModels/GetNewProgramChangeRequestHandler';
import ChangeRequestDTO from '../../../../models/ChangeRequestDTO';
import InstitutionDTO from '../../../../models/InstitutionDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface NewProgramChangeRequestSubmitPageState extends BaseFormState {
  changeRequestId: string;
  changeRequest: ChangeRequestDTO;
}

interface NewProgramChangeRequestSubmitPageProps {
  selectedInstitution: InstitutionDTO | null;
}

class NewProgramChangeRequestSubmitPage extends React.Component<NewProgramChangeRequestSubmitPageProps & RouteComponentProps<RouteObject>, NewProgramChangeRequestSubmitPageState> {

  constructor(props: NewProgramChangeRequestSubmitPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequestId: Guid.Empty(),
      changeRequest: ChangeRequestDTO.create(),
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id == 'new') {
      this.setState({ isNew: true });
    }
    else if (id !== Guid.Empty() && this.state.changeRequestId != id) {
      this.setState({ changeRequestId: id });
      this.fetchData(id);
    }
  }

  private fetchData = (id: string) => {
    this.setState({ loading: true });

    const loaders = [];

    if (id !== Guid.Empty()) {
      loaders.push(this.loadChangeRequest(id));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadChangeRequest = (id: string) => {
    const request = GetNewProgramChangeRequestHandler.Request.create({
      changeRequestId: id
    });

    return NewProgramChangeRequestApiService.get(request)
      .then((results: GetNewProgramChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private goToPreviousStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_REVIEW, { id: this.state.changeRequestId }));
  }


  render() {
    return (
      <Content >
        <HeaderPortal
          breadcrumbs={Breadcrumbs.changeRequestList()} >
        </HeaderPortal>

        <Card >
          <ChangeRequestSubmitted />
        </Card>
      </Content >
    );
  }

}

function mapDispatchToProps() {
  return {
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewProgramChangeRequestSubmitPage));