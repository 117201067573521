// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import YearTermDTO from './YearTermDTO';
import DisciplineDTO from './DisciplineDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CourseDetailDTO extends EntitySelectorDTO { 
	id: string | null;
	courseId: string | null;
	install: YearTermDTO | null;
	retire: YearTermDTO | null;
	title: string | null;
	installDisplay: string | null;
	retireDisplay: string | null;
	disciplineId: string | null | null;
	discipline: DisciplineDTO | null;
	catalogNumber: string | null;
	variableCredits: boolean;
	minCredits: number;
	maxCredits: number;
	display: string | null;
}

const CourseDetailDTO = {
    className: 'CourseDetailDTO',
    create: (initValues?: Partial<CourseDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			courseId: '00000000-0000-0000-0000-000000000000',
			install: null,
			retire: null,
			title: "",
			installDisplay: "",
			retireDisplay: "",
			disciplineId: null,
			discipline: null,
			catalogNumber: "",
			variableCredits: false,
			minCredits: 0,
			maxCredits: 0,
			display: "",
        },
        initValues);
    },
	id: 'id',
	courseId: 'courseId',
	install: 'install',
	retire: 'retire',
	title: 'title',
	installDisplay: 'installDisplay',
	retireDisplay: 'retireDisplay',
	disciplineId: 'disciplineId',
	discipline: 'discipline',
	catalogNumber: 'catalogNumber',
	variableCredits: 'variableCredits',
	minCredits: 'minCredits',
	maxCredits: 'maxCredits',
	display: 'display',
};

export default CourseDetailDTO;