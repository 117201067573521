import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TypeOfProgramDataTable from '../../../../components/datatables/postSecondary/TypeOfProgramDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface TypeOfProgramListProps {
}

interface TypeOfProgramListState {
}

class TypeOfProgramListPage extends React.Component<TypeOfProgramListProps & RouteComponentProps<RouteObject>, TypeOfProgramListState> {
  constructor(props: TypeOfProgramListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Type Of Program" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.typeOfProgramList()} />

        <Card >
          <TypeOfProgramDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(TypeOfProgramListPage);
