import { Alert, Input, InputNumber, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import GeneralEducationApiService from '../../../api/GeneralEducationApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetAvailableGeneralEducationParentsHandler from '../../../handlerModels/GetAvailableGeneralEducationParentsHandler';
import * as GetGeneralEducationDetailsHandler from '../../../handlerModels/GetGeneralEducationDetailsHandler';
import * as SaveGeneralEducationDetailsHandler from '../../../handlerModels/SaveGeneralEducationDetailsHandler';
import GeneralEducationDTO from '../../../models/GeneralEducationDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';

interface GeneralEducationDetailsFormState extends BaseFormState {
  generalEducationId: number;
  generalEducation: GeneralEducationDTO | null;
  availableParents: GeneralEducationDTO[];
}

interface GeneralEducationDetailsFormProps extends BaseFormProps {
  generalEducationId: number;
  onSave?: (generalEducationId: number) => void;
}

class GeneralEducationDetailsForm extends React.Component<GeneralEducationDetailsFormProps, GeneralEducationDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(GeneralEducationDTO.code, {
      required: true,
      name: GeneralEducationDTO.code,
      label: 'Code',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(3)
      ]
    })
    .set(GeneralEducationDTO.displayOrder, {
      required: true,
      name: GeneralEducationDTO.displayOrder,
      label: 'Display Order',
      rules: [
        ValidationRuleUtil.required(),
      ]
    })
    .set(GeneralEducationDTO.name, {
      required: true,
      name: GeneralEducationDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(50)
      ]
    })
    .set(GeneralEducationDTO.embeddedCredits, {
      name: GeneralEducationDTO.embeddedCredits,
      label: 'Has Embedded Credits',
    })
    .set(GeneralEducationDTO.parentGeneralEducationId, {
      name: GeneralEducationDTO.parentGeneralEducationId,
      label: 'Parent General Education'
    });

  constructor(props: GeneralEducationDetailsFormProps) {
    super(props);

    this.state = {
      generalEducationId: 0,
      generalEducation: GeneralEducationDTO.create(),
      availableParents: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadGeneralEducation();
    this.fetchData();
  }

  componentDidUpdate(prevProps: GeneralEducationDetailsFormProps) {
    if (this.props.generalEducationId && this.props.generalEducationId != prevProps.generalEducationId) {
      this.setState({ generalEducationId: this.props.generalEducationId });
      this.loadGeneralEducation();
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const request = GetAvailableGeneralEducationParentsHandler.Request.create({
      generalEducationId: this.props.generalEducationId
    });

    GeneralEducationApiService.getAvailableParents(request)
      .then((result: GetAvailableGeneralEducationParentsHandler.Result) => {
        this.setState({
          availableParents: result.generalEducations ?? []
        })
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadGeneralEducation() {
    if (this.props.generalEducationId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.generalEducationId) {
      GeneralEducationApiService.getGeneralEducationDetails(this.props.generalEducationId)
        .then((results: GetGeneralEducationDetailsHandler.Result) => {
          if (results && results.generalEducation) {
            this.setState({ generalEducationId: results.generalEducation.id, generalEducation: results.generalEducation, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ generalEducation: GeneralEducationDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveGeneralEducationDetailsHandler.Request.create({
      generalEducationId: this.state.generalEducationId,
      generalEducation: GeneralEducationDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    GeneralEducationApiService.saveGeneralEducationDetails(request)
      .then((result: SaveGeneralEducationDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          generalEducation: result?.generalEducation,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.generalEducation?.id) {
            this.props.onSave(result.generalEducation.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(GeneralEducationDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('General Education could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('General Education could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.generalEducation ?? GeneralEducationDTO.create()}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(GeneralEducationDTO.code)}
            {...ValidationUtil.getValidation(GeneralEducationDTO.code, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(GeneralEducationDTO.name)}
            {...ValidationUtil.getValidation(GeneralEducationDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(GeneralEducationDTO.displayOrder)}
            {...ValidationUtil.getValidation(GeneralEducationDTO.displayOrder, this.state.fieldErrors, this.state.submitted)}>
            <InputNumber disabled={!this.props.isEditing || this.state.loading || this.state.submitting} min={1} />
          </FormItem>

          <FormItem
            {...this._formItems.get(GeneralEducationDTO.embeddedCredits)}
            {...ValidationUtil.getValidation(GeneralEducationDTO.embeddedCredits, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(GeneralEducationDTO.parentGeneralEducationId)}
            {...ValidationUtil.getValidation(GeneralEducationDTO.parentGeneralEducationId, this.state.fieldErrors, this.state.submitted)}>
            <Select disabled={!this.props.isEditing || this.state.loading || this.state.submitting} allowClear={true}>
              {this.renderParentOptions()}
            </Select>
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderParentOptions() {
    return this.state.availableParents.map(x => { return <Select.Option key={x.id} value={x.id} >{x.name}</Select.Option> });
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_GENERAL_EDUCATION]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default GeneralEducationDetailsForm;
