// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ChangeRequestDTO from './ChangeRequestDTO';
import ProgramDTO from './ProgramDTO';
import NoticeOfIntentChangeRequestDTO from './NoticeOfIntentChangeRequestDTO';
import StatusTypeDTO from './StatusTypeDTO';
import CipNumberDTO from './CipNumberDTO';
import IowaVariationDTO from './IowaVariationDTO';
import YearTermDTO from './YearTermDTO';
import ProgramModificationProgramAwardDTO from './ProgramModificationProgramAwardDTO';
import ProgramModificationChangeRequestExternalInstitutionDTO from './ProgramModificationChangeRequestExternalInstitutionDTO';
import ProgramModificationAdvisoryBoardContactDTO from './ProgramModificationAdvisoryBoardContactDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramModificationChangeRequestDTO extends EntitySelectorDTO {
  id: string | null;
  changeRequestId: string | null;
  changeRequest: ChangeRequestDTO | null;
  cipReclassification: boolean | null | null;
  programId: string | null | null;
  program: ProgramDTO | null;
  noticeOfIntentChangeRequestId: string | null | null;
  noticeOfIntentChangeRequest: NoticeOfIntentChangeRequestDTO | null;
  institutionId: string | null | null;
  statusTypeId: number;
  statusType: StatusTypeDTO | null;
  cipNumberId: string | null | null;
  cipNumber: CipNumberDTO | null;
  iowaVariationId: number | null | null;
  iowaVariation: IowaVariationDTO | null;
  title: string | null;
  programMarketingLink: string | null;
  install: YearTermDTO | null;
  advisoryBoardApprovalDate: moment.Moment | string;
  curriculumApprovalProcessDateOfApproval: moment.Moment | string;
  programModificationAwards: ProgramModificationProgramAwardDTO[] | null;
  externalInstitutions: ProgramModificationChangeRequestExternalInstitutionDTO[] | null;
  externalInstitutionIds: number[];
  programModificationAdvisoryBoardContacts: ProgramModificationAdvisoryBoardContactDTO[] | null;
}

const ProgramModificationChangeRequestDTO = {
  className: 'ProgramModificationChangeRequestDTO',
  create: (initValues?: Partial<ProgramModificationChangeRequestDTO> | Record<string, unknown> | null) => {
    return Object.assign(EntitySelectorDTO.create(),
      {
        id: '00000000-0000-0000-0000-000000000000',
        changeRequestId: '00000000-0000-0000-0000-000000000000',
        changeRequest: null,
        cipReclassification: null,
        programId: null,
        program: null,
        noticeOfIntentChangeRequestId: null,
        noticeOfIntentChangeRequest: null,
        institutionId: null,
        statusTypeId: 0,
        statusType: null,
        cipNumberId: null,
        cipNumber: null,
        iowaVariationId: null,
        iowaVariation: null,
        title: "",
        programMarketingLink: "",
        install: null,
        advisoryBoardApprovalDate: new Date(0).toISOString(),
        curriculumApprovalProcessDateOfApproval: new Date(0).toISOString(),
        programModificationAwards: [],
        externalInstitutions: [],
        externalInstitutionIds: [],
        programModificationAdvisoryBoardContacts: [],
      },
      initValues);
  },
  id: 'id',
  changeRequestId: 'changeRequestId',
  changeRequest: 'changeRequest',
  cipReclassification: 'cipReclassification',
  programId: 'programId',
  program: 'program',
  noticeOfIntentChangeRequestId: 'noticeOfIntentChangeRequestId',
  noticeOfIntentChangeRequest: 'noticeOfIntentChangeRequest',
  institutionId: 'institutionId',
  statusTypeId: 'statusTypeId',
  statusType: 'statusType',
  cipNumberId: 'cipNumberId',
  cipNumber: 'cipNumber',
  iowaVariationId: 'iowaVariationId',
  iowaVariation: 'iowaVariation',
  title: 'title',
  programMarketingLink: 'programMarketingLink',
  install: 'install',
  advisoryBoardApprovalDate: 'advisoryBoardApprovalDate',
  curriculumApprovalProcessDateOfApproval: 'curriculumApprovalProcessDateOfApproval',
  programModificationAwards: 'programModificationAwards',
  externalInstitutions: 'externalInstitutions',
  externalInstitutionIds: 'externalInstitutionIds',
  programModificationAdvisoryBoardContacts: 'programModificationAdvisoryBoardContacts',
};

export default ProgramModificationChangeRequestDTO;