import { Card, Select, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import DistrictApiService from '../../../api/DistrictApiService';
import DistrictsDataTable from '../../../components/datatables/secondary/DistrictsDataTable';
import Dropdown from '../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import * as GetDistrictsHandler from '../../../handlerModels/GetDistrictsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface DistrictListPageState {
  loading: boolean;
  fromSelfStudies: boolean;
  academicYear: number;
  academicYears: AcademicYearDTO[];
  fromAcademicYearList: boolean;
  districts: DistrictDTO[];
}

interface DistrictListPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class DistrictListPage extends React.Component<DistrictListPageProps & RouteComponentProps<RouteObject>, DistrictListPageState> {

  constructor(props: DistrictListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
      academicYears: [],
      fromSelfStudies: false,
      fromAcademicYearList: false,
      districts: []
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));
    this.setState({
      fromAcademicYearList: this.props.match.path.split('/').findIndex(x => x == 'academicYearList') > -1,
      fromSelfStudies: this.props.match.path.split('/').findIndex(x => x == 'selfStudy') > -1
    });

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id, loading: true });
      this.loadDistricts(id);
      this.loadAcademicYears(id);
    }
    else {
      this.loadAcademicYears(id);
    }
  }

  private loadAcademicYears = (id: number) => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
      academicYearId: isNaN(id) ? null : id
    });

    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
            academicYear: DateTimeUtil.getCurrentYear()
          });
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private loadDistricts = (id: number) => {
    const request = GetDistrictsHandler.Request.create({
      academicYearId: isNaN(id) ? DateTimeUtil.getCurrentYear() : id
    });

    DistrictApiService.getDistricts(request)
      .then((results: GetDistrictsHandler.Result) => {
        if (results) {
          this.setState({
            districts: results.districts ?? []
          });
        }

      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private onSelect = (value: any) => {
    this.setState({ academicYear: value });
    this.loadDistricts(value)
  }

  private onBack = () => {
    if (this.state.fromAcademicYearList) {
      HistoryUtil.push(Routes.generate(Routes.ACADEMIC_YEAR_DASHBOARD_FROM_LIST, { academicYearId: this.state.academicYear }, {}));
    }
    else if (this.state.fromSelfStudies) {
      HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: this.state.academicYear }, {}));
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.SECONDARY_HOME, {}, {}));
    }
  }

  render() {
    if (this.state.loading || (this.state.academicYear == 0 || isNaN(this.state.academicYear))) {
      return <Skeleton active={true} />
    }

    return (
      <Content >
        <HeaderPortal title='Districts' breadcrumbs={Breadcrumbs.districtList()}
          onBack={this.onBack}
          extra={<>
            <label title={'academicYear'} htmlFor={'academicYear'} />
            <Dropdown
              id='academicYear'
              value={this.state.academicYear}
              onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.academicYears.map(x => { return this.renderAcademicYear(x); })}</Dropdown>
          </>} />
        < Space direction="vertical" size="large">
          <Card title="Districts">
            <Space direction='vertical' size="large">
              <DistrictsDataTable
                academicYear={this.state.academicYear}
                fromSelfStudies={this.state.fromSelfStudies}
                fromAcademicYearList={this.state.fromAcademicYearList} />
            </Space>
          </Card>
        </Space>
      </Content>
    );
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</Select.Option>
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(DistrictListPage));
