import { DeleteOutlined, DownloadOutlined, FormOutlined } from '@ant-design/icons';
import { Alert, Button, message } from 'antd';
import Modal from 'antd/lib/modal';
import * as React from 'react';
import { useEffect, useState } from 'react';
import AreaEducationAgencyApiService from '../../../api/AreaEducationAgencyApiService';
import DistrictApiService from '../../../api/DistrictApiService';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteDistrictHandler from '../../../handlerModels/DeleteDistrictHandler';
import AreaEducationAgencyDTO from '../../../models/AreaEducationAgencyDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import CurrentUser from '../../../utils/CurrentUser';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import { TableFilterOption } from '../../../utils/TableFilterUtil';
import DistrictDetailsModal from '../../modals/DistrictDetailsModal';
import DistrictsImporter from '../../secondary/DistrictsImporter';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface DistrictsDataTableProps {
  academicYear: number;
  rppId?: string;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  fromAcademicYearDataReview?: boolean;
  isRPP?: boolean;
}

function DistrictsDataTable(props: DistrictsDataTableProps) {
  let _dataTable: DataTable<DistrictDTO> | undefined = undefined;
  const _districtImporterRef = React.createRef<DistrictsImporter>();
  const [aeas, setAEAs] = useState<AreaEducationAgencyDTO[]>([]);
  const [rpps, setRPPs] = useState<RegionalPlanningPartnerDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [districtId, setDistrictId] = useState<string | null>();

  useEffect(() => {
    if (!isNaN(props.academicYear)) {
      RegionalPlanningPartnerApiService.getRegionalPlanningPartners(props.academicYear)
        .then(x => {
          setRPPs(x.regions ?? []);
        });

      AreaEducationAgencyApiService.getAreaEducationAgencies(props.academicYear)
        .then(x => {
          setAEAs(x.areaEducationAgency ?? []);
        });
    }
  }, [props.academicYear]);

  const getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Identifier',
        dataIndex: DistrictDTO.districtId,
        sorter: true,
        width: 50,
        render: (data: string, row: DistrictDTO) => {
          return row.districtId;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Name',
        dataIndex: DistrictDTO.name,
        sorter: true,
        render: (data: string, row: DistrictDTO) => {
          return row.name;
        },
        filterType: FilterType.Text
      },
      {
        title: 'RPP',
        dataIndex: DistrictDTO.regionIdentifier,
        sorter: true,
        width: 250,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: rpps.map(c => { return { text: c.regionalPlanningPartnerIdentifier + ' - ' + c.name, value: c.regionalPlanningPartnerIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: DistrictDTO) => {
          if (rpps.length > 0) {
            const sa = rpps.filter((x: RegionalPlanningPartnerDTO) => x.regionalPlanningPartnerIdentifier === row.regionIdentifier);
            return sa[0]?.display ?? ''
          }

          return '';
        },
      },
      {
        title: 'AEA',
        dataIndex: DistrictDTO.areaEducationAgencyIdentifier,
        sorter: true,
        width: 250,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: aeas.map(c => { return { text: c.areaEducationAgencyIdentifier + ' - ' + c.name, value: c.areaEducationAgencyIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: DistrictDTO) => {
          if (aeas.length > 0) {
            const sa = aeas.filter((x: AreaEducationAgencyDTO) => x.areaEducationAgencyIdentifier === row.areaEducationAgencyIdentifier);
            return sa[0]?.display ?? ''
          }

          return '';
        },
      },
    ] as DataTableColumnProps<any>[];

    if (props.fromAcademicYearDataReview) {
      columns.push({
        title: 'Actions',
        align: 'center',
        dataIndex: DistrictDTO.districtId,
        sorter: false,
        render: (data: string, row: DistrictDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => openDistrictDetailModal(row)}><FormOutlined /></Button>
              <Button type="link" onClick={() => promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 50
      })
    }

    if (CurrentUser.Get()) {
      const tempActionButtons = columns.pop();
      if (tempActionButtons) {
        columns.push(tempActionButtons);
      }
    }

    return columns;
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openDistrictDetailModal = (record: DistrictDTO) => {
    if (record.id) {
      setDistrictId(record.id);
    }
  }

  const openDistrictDetail = (record: DistrictDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.DISTRICT_DETAILS, { id: record.id }, { academicYear: props.academicYear, rppId: props.rppId }));
    }
  }

  const addDistrict = () => {
    setDistrictId(Guid.Empty());
  }

  const closeDistrictDetail = () => {
    setDistrictId(null);
  }

  const promptConfirmRemove = (districtId: string | null) => {
    if (districtId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this District?',
      okText: 'Delete',
      onOk: () => deleteDistrict(districtId, props.academicYear),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteDistrict = (districtId: string, academicYear: number) => {
    const request = DeleteDistrictHandler.Request.create({
      districtId: districtId,
      academicYearId: academicYear
    });

    DistrictApiService.deleteDistrict(request)
      .then((result: DeleteDistrictHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('District was removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }
      })
      .catch(() => {
        message.error('District could not be removed.');
      });
  }

  const openModal = () => {
    setLoading(true);
    Modal.confirm({
      title: 'Import',
      content: <DistrictsImporter
        ref={_districtImporterRef}
        academicYear={props.academicYear}
        refreshDataTable={_dataTable}
        isInModal={true}
        onSubmit={modalSubmit} />,
      onCancel: () => { setLoading(false) },
      onOk: () => _districtImporterRef.current?.confirmImport(),
      width: 500
    });
  }

  const modalSubmit = (errors: string[]) => {
    setLoading(false);
    setErrors(errors);
  }

  const downloadTemplate = () => {
    window.open(Routes.generateFull(Routes.DISTRICT_IMPORT_TEMPLATE, {}, {}), '_blank')
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT]) && !props.isRPP) {
    actionButtons.push(DataTableButtonUtil.Default('New District', () => addDistrict()));
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT]) && props.fromAcademicYearDataReview) {
    actionButtons.push(DataTableButtonUtil.Default('Re-import Districts', () => openModal(), undefined, loading));
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT]) && props.fromAcademicYearDataReview) {
    actionButtons.push(DataTableButtonUtil.Default('Template', () => downloadTemplate(), <DownloadOutlined />, loading));
  }

  const renderModal = () => {
    if (districtId) {
      return <DistrictDetailsModal
        onCancel={closeDistrictDetail}
        visible={true}
        districtId={districtId}
        academicYear={props.academicYear}
        onSave={closeDistrictDetail} />
    }
  }

  const renderErrors = () => {
    if (errors && errors.length > 0) {
      return <Alert showIcon={true} type='error' description={renderErrorMessages()} message='Error Importing' />
    }
  }

  const renderErrorMessages = () => {
    return <>{errors.map((e: any) => <p key={e}>{e}</p>)}</>
  }

  return (
    <>
      {renderModal()}
      {renderErrors()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        onRowClick={props.fromAcademicYearDataReview ? undefined : openDistrictDetail}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => DistrictApiService.getDistrictTableData(requestState, props.academicYear, CurrentUser.Get() == null, props.rppId ?? Guid.Empty())
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'districts_list' }} />
    </>
  );
}

export default DistrictsDataTable;
