import { Card, Descriptions, Form, FormInstance, FormItemProps, Input, InputNumber, Select, Skeleton, Space, Typography } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { SelectValue } from 'antd/lib/select';
import * as React from 'react';
import ProgramApiService from '../../../api/ProgramApiService';
import * as GetProgramAwardDetailsHandler from '../../../handlerModels/GetProgramAwardDetailsHandler';
import * as GetProgramAwardTermHandler from '../../../handlerModels/GetProgramAwardTermHandler';
import AwardTypeDTO from '../../../models/AwardTypeDTO';
import InstitutionLocationDTO from '../../../models/InstitutionLocationDTO';
import InstructionalLevelDTO from '../../../models/InstructionalLevelDTO';
import ObjectPurposeDTO from '../../../models/ObjectPurposeDTO';
import ProgramAwardDTO from '../../../models/ProgramAwardDTO';
import ProgramDetailDTO from '../../../models/ProgramDetailDTO';
import ProgramDTO from '../../../models/ProgramDTO';
import ProgramTermDTO from '../../../models/ProgramTermDTO';
import SpecialEmphasisDTO from '../../../models/SpecialEmphasisDTO';
import TypeOfProgramDTO from '../../../models/TypeOfProgramDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import RichTextEditor from '../../inputs/RichTextEditor';
import ProgramTermEditor, { ProgramTermEditorValue } from '../../postSecondary/ProgramTermEditor';
import TermOnlyInput from '../../TermOnlyInput';
import ThirdPartyCredentialDisplay from '../ThirdPartyCredentialDisplay';

interface ProgramAwardDisplayState extends BaseFormState {
  instructionalLevels: InstructionalLevelDTO[];
  specialEmphasis: SpecialEmphasisDTO[];
  typeOfPrograms: TypeOfProgramDTO[];
  objectPurposes: ObjectPurposeDTO[];
  awardTypes: AwardTypeDTO[];
  program: ProgramDTO;
  programDetail: ProgramDetailDTO;
  programDetails: ProgramDetailDTO[];
  termLoading: Map<string, boolean>;
  totalCredits: Map<string, Map<number, number>>;
  totalTechCoreCredits: Map<string, Map<number, number>>;
  programAward: ProgramAwardDTO;
  allTermsLoaded: boolean;
}

interface ProgramAwardDisplayProps extends BaseFormProps {
  program: ProgramDTO;
  programDetail?: ProgramDetailDTO;
  programAwardId?: string;
  award: FormListFieldData;
  awardIndex?: number;
  selectedDetailId: string;
  onSave?: (id: string) => void;
  readonly?: boolean;
  disabled?: boolean;
  submitted?: boolean
}

class ProgramAwardDisplay extends React.Component<ProgramAwardDisplayProps, ProgramAwardDisplayState> {
  private readonly _programTermEditor = React.createRef<ProgramTermEditor>();
  private readonly _formRef = React.createRef<FormInstance>();
  private getProgramAwardFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(ProgramAwardDTO.title, {
        required: true,
        name: ProgramAwardDTO.title,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramAwardDTO.awardTypeId, {
        required: true,
        name: ProgramAwardDTO.awardTypeId,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramAwardDTO.totalWeeks, {
        required: true,
        name: ProgramAwardDTO.totalWeeks,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramAwardDTO.description, {
        required: this.props.readonly || !this.props.isEditing ? false : true,
        label: this.props.readonly || !this.props.isEditing ? '' : 'Description',
        name: ProgramAwardDTO.description,
        rules: this.props.readonly || !this.props.isEditing ? [] : [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramAwardDTO.totalCredits, {
        required: true,
        name: ProgramAwardDTO.totalCredits,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramAwardDTO.instructionalLevelId, {
        required: true,
        name: ProgramAwardDTO.instructionalLevelId,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramAwardDTO.typeOfProgramId, {
        required: true,
        name: ProgramAwardDTO.typeOfProgramId,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramAwardDTO.specialEmphasisId, {
        required: true,
        name: ProgramAwardDTO.specialEmphasisId,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramAwardDTO.objectPurposeId, {
        required: true,
        name: ProgramAwardDTO.objectPurposeId,
        rules: [
          ValidationRuleUtil.required(),
        ],
      })
      .set(ProgramAwardDTO.terms, {
        name: [index, ProgramAwardDTO.terms],
      });
  }

  private getProgramTermsFormItems = (programTerm?: any) => {
    return new Map<string, FormItemProps>()
      .set(ProgramTermDTO.informationalContent, {
        label: this.props.readonly || !this.props.isEditing ? null : 'Additional Information',
        name: [programTerm, ProgramTermDTO.informationalContent],
      })
      .set(ProgramTermDTO.termId, {
        label: 'Term',
        name: [programTerm, ProgramTermDTO.termId],
      });
  }

  constructor(props: ProgramAwardDisplayProps) {
    super(props);
    this.state = {
      awardTypes: [],
      instructionalLevels: [],
      objectPurposes: [],
      specialEmphasis: [],
      typeOfPrograms: [],
      program: ProgramDTO.create(),
      programDetail: ProgramDetailDTO.create(),
      termLoading: new Map<string, boolean>(),
      programDetails: [],
      allTermsLoaded: false,
      programAward: ProgramAwardDTO.create(),
      totalCredits: new Map<string, Map<number, number>>(),
      totalTechCoreCredits: new Map<string, Map<number, number>>()
    };
  }

  componentDidMount() {
    if (this.props.program?.id != Guid.Empty()) {
      this.fetchData();
      this.setState({ fieldErrors: this.props.fieldErrors });
    }
  }

  componentDidUpdate(prevProps: ProgramAwardDisplayProps) {
    if (this.props.program && prevProps.program != this.props.program) {
      this.fetchData();
    }

    if (this.props.fieldErrors && prevProps.fieldErrors != this.props.fieldErrors) {
      this.setState({ fieldErrors: this.props.fieldErrors });
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }

    if (this.allTermsLoaded() && !this.state.allTermsLoaded) {
      this.setState({
        allTermsLoaded: true
      });
      this.setState({ loading: false });
    }
  }

  private allTermsLoaded = () => {
    let allTermsLoaded = this.state.termLoading.size > 0 ? true : false;

    this.state.termLoading.forEach(x => {
      if (x.valueOf() && allTermsLoaded) {
        allTermsLoaded = false;
      }
    });

    return allTermsLoaded;
  }

  private fetchData = () => {
    this.setState({ loading: true, programDetail: this.props.programDetail ?? ProgramDetailDTO.create() });

    const loaders = [];

    if (!this.state.instructionalLevels || this.state.instructionalLevels.length == 0) {
      loaders.push(this.loadInstructionalLevel());
    }

    if (!this.state.typeOfPrograms || this.state.typeOfPrograms.length == 0) {
      loaders.push(this.loadTypeOfProgram());
    }

    if (!this.state.specialEmphasis || this.state.specialEmphasis.length == 0) {
      loaders.push(this.loadSpecialEmphasis());
    }

    if (!this.state.objectPurposes || this.state.objectPurposes.length == 0) {
      loaders.push(this.loadObjectPurpose());
    }

    if (!this.state.awardTypes || this.state.awardTypes.length == 0) {
      loaders.push(this.loadAwardType());
    }

    if (this.props.programAwardId && this.props.programAwardId != Guid.Empty()) {
      loaders.push(this.loadProgramAward(this.props.programAwardId ?? Guid.Empty()));
    }


    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private onTermLoaded = (termId: string) => {
    const termLoadings = this.state.termLoading;
    termLoadings.set(termId, false);
    this.setState({ termLoading: termLoadings });
  }

  private loadAwardType = () => {
    return LookupsUtil.getAll<AwardTypeDTO>(AwardTypeDTO.className)
      .then((results: AwardTypeDTO[]) => {
        if (results) {
          this.setState({ awardTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadTypeOfProgram = () => {
    return LookupsUtil.getAll<TypeOfProgramDTO>(TypeOfProgramDTO.className)
      .then((results: TypeOfProgramDTO[]) => {
        if (results) {
          this.setState({ typeOfPrograms: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadInstructionalLevel = () => {
    return LookupsUtil.getAll<InstructionalLevelDTO>(InstructionalLevelDTO.className)
      .then((results: InstructionalLevelDTO[]) => {
        if (results) {
          this.setState({ instructionalLevels: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadSpecialEmphasis = () => {
    return LookupsUtil.getAll<SpecialEmphasisDTO>(SpecialEmphasisDTO.className)
      .then((results: SpecialEmphasisDTO[]) => {
        if (results) {
          this.setState({ specialEmphasis: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadObjectPurpose = () => {
    return LookupsUtil.getAll<ObjectPurposeDTO>(ObjectPurposeDTO.className)
      .then((results: ObjectPurposeDTO[]) => {
        if (results) {
          this.setState({ objectPurposes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  };

  private loadProgramAward = (active: string) => {
    this.setState({ loading: true });
    let terms: (string | null)[] = [];
    ProgramApiService.getProgramAward(active)
      .then((results: GetProgramAwardDetailsHandler.Result) => {
        if (results.programAward) {
          const programDetail = this.state.programDetail.id == Guid.Empty() ? this.props.programDetail : this.state.programDetail;
          const resultAward = results.programAward;
          const programAward = programDetail?.awards?.find((x: ProgramAwardDTO) => x.id == resultAward.id) ?? ProgramAwardDTO.create();

          programAward[ProgramAwardDTO.awardType] = resultAward.awardType;
          programAward[ProgramAwardDTO.description] = resultAward.description;
          programAward[ProgramAwardDTO.instructionalLevel] = resultAward.instructionalLevel;
          programAward[ProgramAwardDTO.typeOfProgram] = resultAward.typeOfProgram;
          programAward[ProgramAwardDTO.specialEmphasis] = resultAward.specialEmphasis;
          programAward[ProgramAwardDTO.objectPurpose] = resultAward.objectPurpose;
          programAward[ProgramAwardDTO.awardTypeId] = resultAward.awardTypeId;
          programAward[ProgramAwardDTO.instructionalLevelId] = resultAward.instructionalLevelId;
          programAward[ProgramAwardDTO.typeOfProgramId] = resultAward.typeOfProgramId;
          programAward[ProgramAwardDTO.specialEmphasisId] = resultAward.specialEmphasisId;
          programAward[ProgramAwardDTO.objectPurposeId] = resultAward.objectPurposeId;
          programAward[ProgramAwardDTO.totalWeeks] = resultAward.totalWeeks;
          programAward[ProgramAwardDTO.totalCredits] = resultAward.totalCredits;
          programAward[ProgramAwardDTO.terms] = resultAward.terms;
          programAward[ProgramAwardDTO.thirdPartyCredentials] = resultAward.thirdPartyCredentials;
          terms = programAward.terms?.map((x: ProgramTermDTO) => x.id) ?? [];
          this.setState({
            programAward: programAward,
            programDetail: programDetail ?? ProgramDetailDTO.create(),
          }, () => this.resetForm());
        }
      })
      .catch()
      .finally(() => {
        terms.forEach(x => this.loadTerm(x ?? Guid.Empty()));
      });
  }

  private loadTerm = (termId: string) => {
    const termLoadings = this.state.termLoading;
    termLoadings.set(termId, true);
    this.setState({ termLoading: termLoadings });

    return ProgramApiService.getProgramAwardTerm(termId)
      .then((results: GetProgramAwardTermHandler.Result) => {
        if (results) {
          const programAwardState = this.state.programAward;

          if (programAwardState.terms) {
            programAwardState.terms[programAwardState.terms?.findIndex(x => x.order == results.term?.order) ?? 0] = results.term ?? ProgramTermDTO.create();
          }

          this.setState({
            programAward: programAwardState,
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private handleChange = () => {
    const programAward = this.setProgramAward();

    this.setState({
      altered: true,
      programAward: programAward ?? ProgramAwardDTO.create()
    });
  }

  private setProgramAward = () => {
    const programAwardForm = this._formRef.current?.getFieldsValue();
    const programAward = this.state.programAward;
    if (programAward) {
      programAward.title = programAwardForm.title;
      programAward.totalCredits = programAwardForm.totalCredits;
      programAward.awardTypeId = programAwardForm.awardTypeId;
      programAward.description = programAwardForm.description;
      programAward.instructionalLevelId = programAwardForm.instructionalLevelId;
      programAward.typeOfProgramId = programAwardForm.typeOfProgramId;
      programAward.specialEmphasisId = programAwardForm.specialEmphasisId;
      programAward.objectPurposeId = programAwardForm.objectPurposeId;
      programAward.totalWeeks = programAwardForm.totalWeeks;
      programAward.terms = programAwardForm.terms;
      programAward.thirdPartyCredentials = programAwardForm.thirdPartyCredentials;
    }

    return programAward;
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this._programTermEditor.current?.resetForm();
    this.setState({ altered: false });
  }

  handleTypeOfProgramChange = (value: SelectValue, awardIndex: number) => {
    const programDetail = this.state.programDetail;
    if (programDetail.awards) {
      const typeOfProgram = this.state.typeOfPrograms.find(x => x.id == (value?.valueOf() as number) ?? 0);
      programDetail.awards[awardIndex].typeOfProgramId = typeOfProgram?.id ?? 0;
      programDetail.awards[awardIndex].typeOfProgram = typeOfProgram ?? TypeOfProgramDTO.create();
      this.setState({ programDetail: programDetail });
    }
  }

  calculateTotalCredits = (termOrder: number, termCredits: number, termTechCoreCredits: number, awardId?: string) => {
    const totalAwardCredits = this.state.totalCredits;
    const totalTechCoreCredits = this.state.totalTechCoreCredits;

    let totalTermCredits = totalAwardCredits.get(awardId ?? Guid.Empty());
    totalTermCredits = totalTermCredits ? totalTermCredits : new Map<number, number>().set(termOrder, termCredits);
    totalAwardCredits.set(awardId ?? Guid.Empty(), totalTermCredits?.set(termOrder, termCredits));

    let totalTermTechCoreCredits = totalTechCoreCredits.get(awardId ?? Guid.Empty());
    totalTermTechCoreCredits = totalTermTechCoreCredits ? totalTermTechCoreCredits : new Map<number, number>().set(termOrder, termTechCoreCredits);
    totalTechCoreCredits.set(awardId ?? Guid.Empty(), totalTermTechCoreCredits?.set(termOrder, termTechCoreCredits));

    this.setState({
      totalCredits: totalAwardCredits,
      totalTechCoreCredits: totalTechCoreCredits
    });
  }

  private onOptionChange = (term: ProgramTermEditorValue) => {
    const award = this.state.programAward;
    if (award.terms) {
      (award.terms.find(x => x.id == term.id) ?? ProgramTermDTO.create()).flatCourseGroupOptions = term.flatCourseGroupOptions;
    }

    this.setState({ programAward: award });
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return <Skeleton active={true} />
    }

    return (
      <Form
        ref={this._formRef}
        layout="vertical"
        initialValues={this.state.programAward}
        onValuesChange={this.handleChange}
        requiredMark={true}>
        <Space direction='vertical' size='large'>

          <Typography.Title level={5}>Awards</Typography.Title>
          {this.renderAward(this.props.awardIndex ?? 0)}
        </Space >
      </Form>
    );
  }

  renderAward(awardIndex: number) {
    const programAward = this.state.programAward ?? ProgramAwardDTO.create();

    let credits = 0;
    this.state.totalCredits.get(programAward?.id ?? Guid.Empty())?.forEach(x => credits += x);

    let techCoreCredits = 0;
    this.state.totalTechCoreCredits.get(programAward?.id ?? Guid.Empty())?.forEach(x => techCoreCredits += x);

    return (

      <Space direction='vertical'>
        {
          <FormItem
            {...this.getProgramAwardFormItems(awardIndex).get(ProgramAwardDTO.description)}
            {...ValidationUtil.getValidation(ProgramAwardDTO.description + awardIndex, this.state.fieldErrors, this.props.submitted)}>
            <RichTextEditor readonly={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting} />
          </FormItem>
        }

        <Descriptions size='small' bordered={true} layout='vertical' column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 4, xxl: 4 }} >
          <Descriptions.Item label="Title" >
            {this.props.readonly || !this.props.isEditing ? programAward?.title :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(awardIndex).get(ProgramAwardDTO.title)}
                {...ValidationUtil.getValidation(ProgramAwardDTO.title + awardIndex, this.state.fieldErrors, this.props.submitted)}>
                <Input allowClear={true} disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting} />
              </FormItem>
            }
          </Descriptions.Item>

          <Descriptions.Item label="Award Type" >
            {this.props.readonly || !this.props.isEditing ? programAward?.awardType?.display :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(awardIndex).get(ProgramAwardDTO.awardTypeId)}
                {...ValidationUtil.getValidation(ProgramAwardDTO.awardTypeId + awardIndex, this.state.fieldErrors, this.props.submitted)}>
                <Select
                  showArrow={!this.props.readonly && this.props.isEditing}
                  allowClear={true}
                  disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting}>
                  {this.state.awardTypes.map(x => this.renderAwardType(x))}
                </Select>
              </FormItem>
            }
          </Descriptions.Item>

          <Descriptions.Item contentStyle={{ paddingTop: '100px' }} label="Total Weeks">
            {this.props.readonly || !this.props.isEditing ? programAward?.totalWeeks :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(awardIndex).get(ProgramAwardDTO.totalWeeks)}
                {...ValidationUtil.getValidation(ProgramAwardDTO.totalWeeks + awardIndex, this.state.fieldErrors, this.props.submitted)}>
                <InputNumber min={0} disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting} />
              </FormItem>
            }
          </Descriptions.Item>

          <Descriptions.Item label="Total Credits">
            {this.props.readonly || !this.props.isEditing ? programAward?.totalCredits :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(awardIndex).get(ProgramAwardDTO.totalCredits)}
                {...ValidationUtil.getValidation(ProgramAwardDTO.totalCredits + awardIndex, this.state.fieldErrors, this.props.submitted)}>
                <InputNumber min={0} disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting} />
              </FormItem>
            }
          </Descriptions.Item>

          <Descriptions.Item label="Instructional Level" >
            {this.props.readonly || !this.props.isEditing ? programAward?.instructionalLevel?.display :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(awardIndex).get(ProgramAwardDTO.instructionalLevelId)}
                {...ValidationUtil.getValidation(ProgramAwardDTO.instructionalLevelId + awardIndex, this.state.fieldErrors, this.props.submitted)}>
                <Select
                  showArrow={!this.props.readonly && this.props.isEditing}
                  allowClear={true}
                  disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting}>
                  {this.state.instructionalLevels.map(x => this.renderInstructionalLevel(x))}
                </Select>
              </FormItem>
            }
          </Descriptions.Item>

          <Descriptions.Item label="Type of Program" >
            {this.props.readonly || !this.props.isEditing ? programAward?.typeOfProgram?.display :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(awardIndex).get(ProgramAwardDTO.typeOfProgramId)}
                {...ValidationUtil.getValidation(ProgramAwardDTO.typeOfProgramId + awardIndex, this.state.fieldErrors, this.props.submitted)}>
                <Select
                  showArrow={!this.props.readonly && this.props.isEditing}
                  allowClear={true}
                  disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting}
                  onChange={(value) => this.handleTypeOfProgramChange(value, awardIndex)}>
                  {this.state.typeOfPrograms.map(x => this.renderTypesOfProgram(x))}
                </Select>
              </FormItem>
            }
          </Descriptions.Item>

          <Descriptions.Item label="Special Emphasis" >
            {this.props.readonly || !this.props.isEditing ? programAward?.specialEmphasis?.display :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(awardIndex).get(ProgramAwardDTO.specialEmphasisId)}
                {...ValidationUtil.getValidation(ProgramAwardDTO.specialEmphasisId + awardIndex, this.state.fieldErrors, this.props.submitted)}>
                <Select
                  showArrow={!this.props.readonly && this.props.isEditing}
                  allowClear={true}
                  disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting}>
                  {this.state.specialEmphasis.map(x => this.renderSpecialEmphasis(x))}
                </Select>
              </FormItem>
            }
          </Descriptions.Item>

          <Descriptions.Item label="Object Purpose">
            {this.props.readonly || !this.props.isEditing ? programAward?.objectPurpose?.display :
              <FormItem
                className={'description-container'}
                {...this.getProgramAwardFormItems(awardIndex).get(ProgramAwardDTO.objectPurposeId)}
                {...ValidationUtil.getValidation(ProgramAwardDTO.objectPurposeId + awardIndex, this.state.fieldErrors, this.props.submitted)}>
                <Select
                  showArrow={!this.props.readonly && this.props.isEditing}
                  allowClear={true}
                  disabled={this.props.readonly || !this.props.isEditing || this.props.disabled || this.state.submitting}>
                  {this.state.objectPurposes.map(x => this.renderObjectPurpose(x))}
                </Select>
              </FormItem>
            }
          </Descriptions.Item>
        </Descriptions>

        <ThirdPartyCredentialDisplay
          thirdPartyCredentials={programAward.thirdPartyCredentials ?? []}
          readonly={!this.props.isEditing || this.state.loading || this.state.submitting}
          submitted={this.props.submitted}
          fieldErrors={this.state.fieldErrors}
          awardIndex={awardIndex} />
        {this.renderTerm(programAward)}

        <b>Award Total Credits: {credits}</b>
        {this.props.programDetail?.isTransferMajor ? null : <b>Award Total Tech Core Credits: {techCoreCredits}</b>}
      </Space>
    )
  }

  renderTerm(award: ProgramAwardDTO) {
    let credits = 0;
    this.state.totalCredits.get(award?.id ?? Guid.Empty())?.forEach(x => credits += x);
    return (
      <Form.List name={ProgramAwardDTO.terms}>
        {
          (terms) => {
            let formTerms = this._formRef.current?.getFieldValue(ProgramAwardDTO.terms) as ProgramTermDTO[];

            if (formTerms == undefined || formTerms.length == 0) {
              formTerms = (this.state.programAward?.terms ?? []);
            }
            return (

              <Space direction="vertical">
                {
                  terms.map((term, index) => {
                    const prerequisite = this._formRef.current?.getFieldValue(ProgramAwardDTO.prerequisite) ? this._formRef.current?.getFieldValue(ProgramAwardDTO.prerequisite) : award.prerequisite;
                    const title = prerequisite && index == 0 ? 'Prerequisite' : 'Term ' + (index + (prerequisite ? 0 : 1));
                    const formTerm = (formTerms ?? [ProgramTermDTO.create])[index];
                    const termValue = {
                      id: formTerm?.id,
                      prerequisite: formTerm?.prerequisite,
                      groups: formTerm?.groups,
                      flatCourseGroupOptions: formTerm?.flatCourseGroupOptions,
                      order: formTerm?.order
                    } as ProgramTermEditorValue;

                    const transferMajor = this.state.programDetail?.isTransferMajor ?? false;
                    return (
                      <Card type="inner" size="small" key={term.key} title={title} >
                        <FormItem
                          {...this.getProgramTermsFormItems(index).get(ProgramTermDTO.termId)}
                          {...ValidationUtil.getValidation(ProgramTermDTO.termId + this.state.programAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                          <TermOnlyInput readOnly={this.props.readonly || !this.props.isEditing} />
                        </FormItem>

                        <FormItem
                          {...this.getProgramTermsFormItems(index).get(ProgramTermDTO.informationalContent)}
                          {...ValidationUtil.getValidation(ProgramTermDTO.informationalContent + this.state.programAward.order, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                          <RichTextEditor readonly={this.props.readonly || !this.props.isEditing} />
                        </FormItem>

                        <ProgramTermEditor
                          ref={this._programTermEditor}
                          value={termValue}
                          isEditing={this.props.isEditing}
                          key={index}
                          programDisplay={true}
                          programAward={this.state.programAward}
                          transferMajor={transferMajor}
                          selectedInstitution={this.state.program.institutionId}
                          loading={this.state.termLoading?.get(termValue.id ?? Guid.Empty())}
                          readonly={this.props.readonly}
                          termIndex={index}
                          fieldErrors={this.state.fieldErrors}
                          submitted={this.props.submitted}
                          onCreditsChanged={this.calculateTotalCredits}
                          onTermLoaded={this.onTermLoaded}
                          onOptionChange={this.onOptionChange}
                        />
                      </Card>
                    );
                  })
                }
              </Space>
            );
          }
        }
      </Form.List >

    );
  }

  renderAwardType(awardType: AwardTypeDTO) {
    if (awardType.id) {
      return <Select.Option key={awardType.id} value={awardType.id}>{awardType.code} - {awardType.name}</Select.Option>
    }
  }

  renderTypesOfProgram(typesOfProgram: TypeOfProgramDTO) {
    if (typesOfProgram.id) {
      return <Select.Option key={typesOfProgram.id} value={typesOfProgram.id}>{typesOfProgram.code} - {typesOfProgram.name}</Select.Option>
    }
  }

  renderObjectPurpose(objectPurpose: ObjectPurposeDTO) {
    if (objectPurpose.id) {
      return <Select.Option key={objectPurpose.id} value={objectPurpose.id}>{objectPurpose.code} - {objectPurpose.name}</Select.Option>
    }
  }

  renderSpecialEmphasis(specialEmphasis: SpecialEmphasisDTO) {
    if (specialEmphasis.id) {
      return <Select.Option key={specialEmphasis.id} value={specialEmphasis.id}>{specialEmphasis.code} - {specialEmphasis.name}</Select.Option>
    }
  }

  renderInstructionalLevel(instructionalLevel: InstructionalLevelDTO) {
    if (instructionalLevel.id) {
      return <Select.Option key={instructionalLevel.id} value={instructionalLevel.id}>{instructionalLevel.code} - {instructionalLevel.name}</Select.Option>
    }
  }

  renderInstitutionLocations(institutionLocation: InstitutionLocationDTO) {
    if (institutionLocation.id) {
      return <Select.Option key={institutionLocation.id ?? Guid.Empty()} value={institutionLocation.id ?? Guid.Empty()}>{institutionLocation.name} - {institutionLocation.street1} </Select.Option>
    }
  }
}

export default ProgramAwardDisplay;