import * as React from 'react';
import TransferMajorCourseBlockApiService from '../../../api/TransferMajorCourseBlockApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import TransferMajorCourseBlockDTO from '../../../models/TransferMajorCourseBlockDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import FileDownload from '../../../utils/FileDownload';
import DateUtil from '../../../utils/DateTimeUtil';

interface TransferMajorCourseBlockDataTableProps extends BaseFormProps {
}

interface TransferMajorCourseBlockDataTableState extends BaseDataTableState<TransferMajorCourseBlockDTO>, BaseFormState {
}

class TransferMajorCourseBlockDataTable extends React.Component<TransferMajorCourseBlockDataTableProps, TransferMajorCourseBlockDataTableState> {
  private _dataTable: DataTable<TransferMajorCourseBlockDTO> | undefined;

  constructor(props: TransferMajorCourseBlockDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Name',
        dataIndex: TransferMajorCourseBlockDTO.name,
        sorter: true,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];
  };

  private openTransferMajorCourseBlock = (record: TransferMajorCourseBlockDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.TRANSFER_MAJOR_COURSE_BLOCK_DETAILS, { id: record.id }, {}));
    }
  }

  private addTransferMajorCourseBlock = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_TRANSFER_MAJOR_COURSE_BLOCK));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return TransferMajorCourseBlockApiService.getTransferMajorCourseBlockDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Transfer_Major_Course_Block_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_TRANSFER_MAJOR_COURSE_BLOCKS])) {
      actionButtons.push(DataTableButtonUtil.Default('New Transfer Major Course Block', () => this.addTransferMajorCourseBlock()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openTransferMajorCourseBlock}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => TransferMajorCourseBlockApiService.getTransferMajorCourseBlocksDataTable(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'transferMajorCourseBlock_list' }} />
      </>
    );
  }
}

export default TransferMajorCourseBlockDataTable;
