import { Skeleton } from 'antd';
import * as React from 'react';
import InstitutionCourseBlockApiService from '../../../api/InstitutionCourseBlockApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import InstitutionCourseBlockDataTableDTO from '../../../models/InstitutionCourseBlockDataTableDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface InstitutionCourseBlockDataTableProps extends BaseFormProps {
  selectedInstitution: InstitutionDTO | null;
}

interface InstitutionCourseBlockDataTableState extends BaseDataTableState<InstitutionCourseBlockDataTableDTO>, BaseFormState {
  institutions: InstitutionDTO[];
}

class InstitutionCourseBlockDataTable extends React.Component<InstitutionCourseBlockDataTableProps, InstitutionCourseBlockDataTableState> {
  private _dataTable: DataTable<InstitutionCourseBlockDataTableDTO> | undefined;

  constructor(props: InstitutionCourseBlockDataTableProps) {
    super(props);

    this.state = {
      institutions: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.institutions || this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Code',
        dataIndex: InstitutionCourseBlockDataTableDTO.code,
        width: 125,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Title',
        dataIndex: InstitutionCourseBlockDataTableDTO.title,
        sorter: true,
        filterType: FilterType.Text,
      }
    ] as DataTableColumnProps<InstitutionCourseBlockDataTableDTO>[];

    if (!this.props.selectedInstitution) {
      columns.push({
        title: 'Community College',
        dataIndex: InstitutionCourseBlockDataTableDTO.institutionId,
        key: InstitutionCourseBlockDataTableDTO.institutionId,
        render: (data: string, row: InstitutionCourseBlockDataTableDTO) => {
          return row.institution?.name ?? '';
        },
        ellipsis: {
          showTitle: true,
        },
        width: 275,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.institutions?.map(c => { return { text: c.name ?? '', value: c.id ?? '' } })
      });
    }

    columns.push({
      title: 'Course Count',
      dataIndex: InstitutionCourseBlockDataTableDTO.courseCount,
      sorter: true,
      width: 125,
    });

    return columns;
  };

  private openInstitutionCoureBlock = (record: InstitutionCourseBlockDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.INSTITUITION_COURSE_BLOCK_DETAILS, { id: record.id }, {}));
    }
  }

  private addInstitutionCourseBlok = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_INSTITUITION_COURSE_BLOCK));
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_INSTITUTION_COURSE_BLOCK])) {
      actionButtons.push(DataTableButtonUtil.Default('New Course Block', () => this.addInstitutionCourseBlok()));
    }

    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openInstitutionCoureBlock}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => InstitutionCourseBlockApiService.getInstitutionCourseBlockTableData(requestState, this.props.selectedInstitution?.id ?? Guid.Empty())
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'institution_course_block_list' }} />
    );
  }
}

export default InstitutionCourseBlockDataTable;
