import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TransferMajorDisciplineFrameworkElementApiService from '../../../../api/TransferMajorDisciplineFrameworkElementApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import TransferMajorDisciplineFrameworkElementDetailsForm from '../../../../components/forms/postSecondary/TransferMajorDisciplineFrameworkElementDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteTransferMajorDisciplineFrameworkElementHandler from '../../../../handlerModels/DeleteTransferMajorDisciplineFrameworkElementHandler';
import * as GetTransferMajorDisciplineFrameworkElementDetailsHandler from '../../../../handlerModels/GetTransferMajorDisciplineFrameworkElementDetailsHandler';
import TransferMajorDisciplineFrameworkElementDTO from '../../../../models/TransferMajorDisciplineFrameworkElementDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';
import TransferMajorDisciplineFrameworkElementCourseDataTable from '../../../../components/datatables/postSecondary/TransferMajorDisciplineFrameworkElementCourseDataTable';

interface TransferMajorDisciplineFrameworkElementDetailsPageState extends BaseFormState {
  transferMajorDisciplineFrameworkElementId: number;
  transferMajorDisciplineFrameworkElement?: TransferMajorDisciplineFrameworkElementDTO;
  activeTab: string;
}

interface TransferMajorDisciplineFrameworkElementDetailsPageProps {
}

class TransferMajorDisciplineFrameworkElementDetailsPage extends React.Component<TransferMajorDisciplineFrameworkElementDetailsPageProps & RouteComponentProps<RouteObject>, TransferMajorDisciplineFrameworkElementDetailsPageState> {
  private readonly _TransferMajorDisciplineFrameworkElementDetailsRef = React.createRef<TransferMajorDisciplineFrameworkElementDetailsForm>();

  constructor(props: TransferMajorDisciplineFrameworkElementDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      transferMajorDisciplineFrameworkElementId: 0,
      activeTab: 'details'
    };
  }

  componentDidMount() {
    const TransferMajorDisciplineFrameworkElementId = ParameterUtil.getPathPart(this.props.match, 'id');
    const activeTab = ParameterUtil.getParameter('activeTab', 'details');
    this.setState({ activeTab: activeTab });

    if (TransferMajorDisciplineFrameworkElementId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const TransferMajorDisciplineFrameworkElementIdNumber = Number.parseInt(TransferMajorDisciplineFrameworkElementId);
      this.loadTransferMajorDisciplineFrameworkElement(TransferMajorDisciplineFrameworkElementIdNumber);
    }
  }

  private loadTransferMajorDisciplineFrameworkElement = (transferMajorDisciplineFrameworkElementId: number) => {
    this.setState({ transferMajorDisciplineFrameworkElementId: transferMajorDisciplineFrameworkElementId });

    TransferMajorDisciplineFrameworkElementApiService.getTransferMajorDisciplineFrameworkElementDetails(transferMajorDisciplineFrameworkElementId)
      .then((results: GetTransferMajorDisciplineFrameworkElementDetailsHandler.Result) => {
        if (results.transferMajorDisciplineFrameworkElement) {
          this.setState({ transferMajorDisciplineFrameworkElement: results.transferMajorDisciplineFrameworkElement });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Discipline Framework Element?',
      okText: 'Delete',
      onOk: () => this.deleteTransferMajorDisciplineFrameworkElement(id)
    });
  }

  private deleteTransferMajorDisciplineFrameworkElement = (transferMajorDisciplineFrameworkElementId: number) => {
    this.setState({ submitting: true });

    const request = DeleteTransferMajorDisciplineFrameworkElementHandler.Request.create({
      transferMajorDisciplineFrameworkElementId: transferMajorDisciplineFrameworkElementId,
    });

    TransferMajorDisciplineFrameworkElementApiService.deleteTransferMajorDisciplineFrameworkElement(request)
      .then((result: DeleteTransferMajorDisciplineFrameworkElementHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Discipline Framework Element was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Discipline Framework Element could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToTransferMajorDisciplineFrameworkElement();
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._TransferMajorDisciplineFrameworkElementDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private TransferMajorDisciplineFrameworkElementDetailsSaved = (transferMajorDisciplineFrameworkElementId: number) => {
    this.setState({ isEditing: false });
    if (transferMajorDisciplineFrameworkElementId != this.state.transferMajorDisciplineFrameworkElementId) {
      HistoryUtil.replace(Routes.generate(Routes.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT_DETAILS, { id: transferMajorDisciplineFrameworkElementId }));
    }
    else {
      this.loadTransferMajorDisciplineFrameworkElement(transferMajorDisciplineFrameworkElementId);
    }
  }

  private goToTransferMajorDisciplineFrameworkElement = () => {
    HistoryUtil.goBack();
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.push(Routes.generate(Routes.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT_DETAILS, { id: this.state.transferMajorDisciplineFrameworkElementId }, { activeTab: activeTab }));
  }

  render() {
    const title = this.state.loading ? '...' : this.state.transferMajorDisciplineFrameworkElementId == 0 ? 'New Discipline Framework Element' : this.state.transferMajorDisciplineFrameworkElement?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToTransferMajorDisciplineFrameworkElement}
          breadcrumbs={Breadcrumbs.transferMajorDisciplineFrameworkElementDetails(title, this.state.transferMajorDisciplineFrameworkElementId)}
          extra={this.renderMoreActions()}
          footer={
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab} >
              <Tabs.TabPane tab="Details" key="details" />
              <Tabs.TabPane tab="Courses" key="courses" />
            </Tabs>
          } />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
        {this.renderCoursesCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    if (this.state.activeTab == 'details') {
      const editButton = this.state.transferMajorDisciplineFrameworkElementId == 0 ?
        null :
        (
          <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS]}>
            <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
          </AuthorizedContent>
        );

      return (
        <Card title="Details" extra={editButton}>
          <TransferMajorDisciplineFrameworkElementDetailsForm
            ref={this._TransferMajorDisciplineFrameworkElementDetailsRef}
            transferMajorDisciplineFrameworkElementId={this.state.transferMajorDisciplineFrameworkElementId}
            isEditing={this.state.isEditing}
            isNew={this.state.isNew}
            onSave={this.TransferMajorDisciplineFrameworkElementDetailsSaved} />
        </Card>
      );
    }
  }

  renderCoursesCard() {
    if (this.state.activeTab == 'courses') {
      return (
        <Card title="Courses" >
          <TransferMajorDisciplineFrameworkElementCourseDataTable transferMajorDisciplineFrameworkElementId={this.state.transferMajorDisciplineFrameworkElementId} />
        </Card>
      );
    }
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Discipline Framework Element' key={this.state.transferMajorDisciplineFrameworkElementId} onClick={() => this.deleteConfirmation(this.state.transferMajorDisciplineFrameworkElementId)}  >
          Delete Discipline Framework Element
        </Menu.Item>
      </Menu>
    );

    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(TransferMajorDisciplineFrameworkElementDetailsPage);
