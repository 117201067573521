import { DownloadOutlined, LockOutlined } from '@ant-design/icons';
import { Alert, Modal } from 'antd';
import * as React from 'react';
import RegionalPlanningPartnerApiService from '../../api/RegionalPlanningPartnerApiService';
import UsersApiService from '../../api/UsersApiService';
import AddUserForm from '../../components/forms/AddUserForm';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import DistrictDTO from '../../models/DistrictDTO';
import InstitutionDTO from '../../models/InstitutionDTO';
import RegionalPlanningPartnerDTO from '../../models/RegionalPlanningPartnerDTO';
import StatusTypeDTO from '../../models/StatusTypeDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import UserDTO from '../../models/UserDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import Guid from '../../utils/Guid';
import HistoryUtil from '../../utils/HistoryUtil';
import StringUtil from '../../utils/StringUtil';
import TableFilterUtil, { TableFilterOption } from '../../utils/TableFilterUtil';
import AAUserImporter from '../secondary/AAUserImporter';
import DataTable, { DataTableColumnProps, FilterType } from './core/DataTable';
import DataTableButtonUtil from './core/DataTableButtonUtil';

interface UsersDataTableProps {
  institution: InstitutionDTO | null;
  district: DistrictDTO | null;
  rpp: RegionalPlanningPartnerDTO | null;
}

interface UsersDataTableState extends BaseDataTableState<UserDTO> {
  institutions: TableFilterOption[];
  districts: TableFilterOption[];
  roles: TableFilterOption[];
  statuses: TableFilterOption[];
  rpps: RegionalPlanningPartnerDTO[];
  addUser: boolean;
  errors?: string[];
}

class UsersDataTable extends React.Component<UsersDataTableProps, UsersDataTableState> {
  private _dataTable: DataTable<UserDTO> | undefined;
  private readonly _addUserFormRef = React.createRef<AddUserForm>();
  private readonly _aaUserImporterref = React.createRef<AAUserImporter>();

  constructor(props: UsersDataTableProps) {
    super(props);

    this.state = {
      institutions: [],
      districts: [],
      roles: [],
      statuses: [],
      rpps: [],
      addUser: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    TableFilterUtil.Institutions().then(x => this.setState({ institutions: x }));
    TableFilterUtil.StatusTypes().then(x => this.setState({ statuses: x }));

    if (this.props.institution != null && this.props.institution != undefined) {
      TableFilterUtil.InstitutionRoles().then(x => this.setState({ roles: x }));
    }
    else {
      TableFilterUtil.Roles().then(x => this.setState({ roles: x }));
    }

    const loaders = [] as any[];

    if (this.state.rpps?.length === 0) {
      loaders.push(this.fetchRpps())
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private fetchRpps = () => {
    return RegionalPlanningPartnerApiService.getAllRpps().then((x => {
      if (x.succeeded) {
        this.setState({ rpps: x.regions ?? [] });
      }
    }))
  }

  private getColumnDefinitions = () => {
    const columns = [
      {
        title: '',
        dataIndex: UserDTO.isLocked,
        renderDataTransform: (value: boolean, data: UserDTO) => {
          return data.isLocked ? <LockOutlined /> : null;
        },
        width: 25,
      },
      {
        title: 'Name',
        dataIndex: UserDTO.fullName,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'Email',
        dataIndex: UserDTO.username,
        sorter: true,
        filterType: FilterType.Text,
        width: 350,
      }
    ] as DataTableColumnProps<any>[];

    if (this.props.institution == null) {
      columns.push({
        title: 'Institutions',
        dataIndex: UserDTO.institutionNames,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderDataTransform: (value: string[], data: UserDTO) => {
          return StringUtil.newLineFormat(value, true);
        },
        filterType: FilterType.DropdownMulti,
        width: 350,
        dropdownFilterOptions: this.state.institutions
      });
    }

    if (this.props.district == null) {
      columns.push({
        title: 'Districts',
        dataIndex: UserDTO.districtNames,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderDataTransform: (value: string[], data: UserDTO) => {
          if (value) {
            return StringUtil.newLineFormat(value, true);
          }
        },
        filterType: FilterType.Text,
        width: 350,
      });
    }

    if (this.props.rpp == null) {
      columns.push({
        title: 'RPPs',
        dataIndex: UserDTO.rppIds,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderDataTransform: (value: string[], data: UserDTO) => {
          const names = this.state.rpps?.filter(x => data.rppIds?.indexOf(x.regionalPlanningPartnerIdentifier) >= 0).map(x => x.name).sort();
          if (names) {
            return StringUtil.newLineFormat(names, true);
          }
        },
        filterType: FilterType.Text,
        width: 350,
      });
    }

    columns.push({
      title: 'Roles',
      dataIndex: UserDTO.roles,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderDataTransform: (value: string[], data: UserDTO) => {
        return StringUtil.newLineFormat(value, true);
      },
      filterType: FilterType.DropdownMulti,
      width: 350,
      dropdownFilterOptions: this.state.roles
    });

    columns.push({
      title: 'Status',
      dataIndex: UserDTO.statusTypeId,
      renderDataTransform: (value: StatusTypeDTO, data: UserDTO) => {
        return data.status?.name;
      },
      filterType: FilterType.DropdownMulti,
      width: 225,
      dropdownFilterOptions: this.state.statuses
    });

    return columns;
  };

  private openUser = (record: UserDTO) => {
    if (record.id) {
      if (this.props.institution == null) {
        HistoryUtil.push(Routes.generate(Routes.USER_DETAILS, { id: record.id }, {}));
      }
      else if (this.props.institution) {
        HistoryUtil.push(Routes.generate(Routes.INSTITUTION_USER_DETAILS, { institutionId: this.props.institution?.id ?? '', userId: record.id }));
      }
    }
  }

  private addUser = () => {
    this.setState({ addUser: true });
  }

  private submitAddUser = () => {
    this._addUserFormRef.current?.submit();
  }

  private cancelAddUser = () => {
    this.setState({ addUser: false });
    this._addUserFormRef.current?.reset();
  }

  private userAdded = () => {
    this.setState({ addUser: false });
    this._dataTable?.refresh();
  }

  private openAAImporterModal = () => {
    this.setState({
      loading: true
    });

    Modal.confirm({
      title: 'Import',
      content: <AAUserImporter
        ref={this._aaUserImporterref}
        isInModal={true}
        onSubmit={this.modalSubmit} />,
      onOk: () => this._aaUserImporterref.current?.confirmImport(),
      onCancel: () => this.modalCancel(),
      width: 500
    });
  }

  private modalSubmit = (errors?: string[]) => {
    this.setState({
      loading: false,
      errors: errors
    });
  }

  private modalCancel = () => {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <>
        {this.renderAddUserModal()}
        {this.renderDataTable()}
      </>
    );
  }

  renderAddUserModal() {
    return (
      <Modal
        visible={this.state.addUser}
        title='Add User'
        okText='Add'
        onOk={this.submitAddUser}
        onCancel={this.cancelAddUser}>
        <AddUserForm
          ref={this._addUserFormRef}
          onSuccess={this.userAdded}
          institutionId={this.props.institution?.id ?? Guid.Empty()} />
      </Modal>
    );
  }

  private downloadTemplate = () => {
    window.open(Routes.generateFull(Routes.AA_USER_IMPORT_TEMPLATE, {}, {}), '_blank')
  }

  renderDataTable() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_USER, FeatureFlag.MANAGE_INSTITUTION_USER])) {
      actionButtons.push(DataTableButtonUtil.Default('Add User', () => this.addUser()));
    }

    if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_AA_USER])) {
      actionButtons.push(DataTableButtonUtil.Default('Import AA Users', () => this.openAAImporterModal(), undefined, this.state.loading));
    }

    if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_AA_USER])) {
      actionButtons.push(DataTableButtonUtil.Default('Template', () => this.downloadTemplate(), <DownloadOutlined />, this.state.loading));
    }

    return (
      <>
        {this.renderErrors()}
        <DataTable ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openUser}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (request: TableRequestDTO) => UsersApiService.getUsersList(request, this.props.institution?.id ?? Guid.Empty(), this.props.district?.districtId ?? 0, this.props.rpp?.regionalPlanningPartnerIdentifier ?? 0)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'users_list' }} />
      </>
    );
  }

  renderErrors = () => {
    if (this.state.errors && this.state.errors.length > 0) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.errors.map((x: any) => <>{x} <br /></>)} />;
    }
  }
}

export default UsersDataTable;
