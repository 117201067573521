import moment from 'moment';
import * as React from 'react';
import TermsApiService from '../../../api/TermsApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TermDTO from '../../../models/TermDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import DateUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface TermDataTableProps {
}

interface TermDataTableState extends BaseDataTableState<TermDTO> {
}

class TermDataTable extends React.Component<TermDataTableProps, TermDataTableState> {
  private _dataTable: DataTable<TermDTO> | undefined;

  constructor(props: TermDataTableProps) {
    super(props);
    this.state = {
    };

  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Name',
        dataIndex: TermDTO.name,
        sorter: true,
      },
      {
        title: 'Start Date',
        dataIndex: TermDTO.startDate,
        sorter: true,
        width: 175,
        render: (value: moment.Moment) => {
          return DateUtil.longMonthAndDay(value);
        }
      }
    ] as DataTableColumnProps<any>[];
  };

  private openTerm = (record: TermDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.TERM_DETAILS, { id: record.id }, {}));
    }
  }

  private addTerm = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_TERM));
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return TermsApiService.getTermsDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Terms_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_TERM])) {
      actionButtons.push(DataTableButtonUtil.Default('New Term', () => this.addTerm()));
    }

    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openTerm}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => TermsApiService.getTermTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'term_list' }} />
    );
  }
}

export default TermDataTable;
