// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveInstructionalLevelDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import InstructionalLevelDTO from '../models/InstructionalLevelDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    instructionalLevelId: number;
    instructionalLevel: InstructionalLevelDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            instructionalLevelId: 0,
            instructionalLevel: null,
        },
        initValues);
    },
    instructionalLevelId: 'instructionalLevelId',
    instructionalLevel: 'instructionalLevel',
}

interface Result extends ResultDTO {
    instructionalLevel: InstructionalLevelDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            instructionalLevel: null,
        },
        initValues);
    },
    instructionalLevel: 'instructionalLevel',
}

export { Request, Result };