// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import CourseDataTableDTO from '../models/CourseDataTableDTO';
import CourseCourseBlocksDataTableDTO from '../models/CourseCourseBlocksDataTableDTO';
import CourseProgramDataTableDTO from '../models/CourseProgramDataTableDTO';
import * as GetCourseDetailsHandler from '../handlerModels/GetCourseDetailsHandler';
import * as SaveCourseDetailsHandler from '../handlerModels/SaveCourseDetailsHandler';
import * as ImportInstitutionCoursesHandler from '../handlerModels/ImportInstitutionCoursesHandler';
import * as GetCoursesByInstitutionHandler from '../handlerModels/GetCoursesByInstitutionHandler';
import * as GetCoursesInProgramTermsHandler from '../handlerModels/GetCoursesInProgramTermsHandler';
import * as GetCoursesInProgramModificationProgramTermsHandler from '../handlerModels/GetCoursesInProgramModificationProgramTermsHandler';
import * as GetCoursesInNewProgramProgramTermsHandler from '../handlerModels/GetCoursesInNewProgramProgramTermsHandler';
import * as CourseSearchHandler from '../handlerModels/CourseSearchHandler';
import * as CourseSearchWithRetiredHandler from '../handlerModels/CourseSearchWithRetiredHandler';
import * as ExistingCourseSearchHandler from '../handlerModels/ExistingCourseSearchHandler';
import * as ExistingInstitutionCourseSearchHandler from '../handlerModels/ExistingInstitutionCourseSearchHandler';

export class CoursesApiService extends BaseApi {

    // post: api/courses/list/${encodeURIComponent(institutionId)}
    public getCourseTableData(tableRequest: TableRequestDTO, institutionId: string): Promise<TableResponseDTO<CourseDataTableDTO>> {
        let url = `api/courses/list/${encodeURIComponent(institutionId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<CourseDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/courses/list/${encodeURIComponent(institutionId)}/export
    public exportCourseList(tableRequest: TableRequestDTO, institutionId: string): Promise<string> {
        let url = `api/courses/list/${encodeURIComponent(institutionId)}/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/courses/${encodeURIComponent(courseId)}
    public getCourseDetails(courseId: string): Promise<GetCourseDetailsHandler.Result> {
        let url = `api/courses/${encodeURIComponent(courseId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCourseDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/courses/save
    public saveCourseDetails(saveCourseDetails: SaveCourseDetailsHandler.Request): Promise<SaveCourseDetailsHandler.Result> {
        let url = `api/courses/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveCourseDetailsHandler.Result>(saveCourseDetails, 'post', url, true, false);
    }

    // post: api/courses/import
    public importInstitutionCourses(importInstitutionCourses: FormData): Promise<ImportInstitutionCoursesHandler.Result> {
        let url = `api/courses/import`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ImportInstitutionCoursesHandler.Result>(importInstitutionCourses, 'post', url, false, false);
    }

    // post: api/courses/getCourseByInstitutionId
    public getCourseByInstituionId(getCoursesByInstitution: GetCoursesByInstitutionHandler.Request): Promise<GetCoursesByInstitutionHandler.Result> {
        let url = `api/courses/getCourseByInstitutionId`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCoursesByInstitutionHandler.Result>(getCoursesByInstitution, 'post', url, true, false);
    }

    // post: api/courses/getCourseByCourseIds
    public getCourseByCourseIds(getCoursesInProgramTerms: GetCoursesInProgramTermsHandler.Request): Promise<GetCoursesInProgramTermsHandler.Result> {
        let url = `api/courses/getCourseByCourseIds`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCoursesInProgramTermsHandler.Result>(getCoursesInProgramTerms, 'post', url, true, false);
    }

    // post: api/courses/getCoursesInProgramModificationProgramAwards
    public getCoursesInProgramModificationProgramAwards(getCoursesInProgramModificationProgramTerms: GetCoursesInProgramModificationProgramTermsHandler.Request): Promise<GetCoursesInProgramModificationProgramTermsHandler.Result> {
        let url = `api/courses/getCoursesInProgramModificationProgramAwards`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCoursesInProgramModificationProgramTermsHandler.Result>(getCoursesInProgramModificationProgramTerms, 'post', url, true, false);
    }

    // post: api/courses/getCoursesInNewProgramProgramAwards
    public getCoursesInNewProgramProgramAwards(getCoursesInNewProgramProgramTerms: GetCoursesInNewProgramProgramTermsHandler.Request): Promise<GetCoursesInNewProgramProgramTermsHandler.Result> {
        let url = `api/courses/getCoursesInNewProgramProgramAwards`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCoursesInNewProgramProgramTermsHandler.Result>(getCoursesInNewProgramProgramTerms, 'post', url, true, false);
    }

    // post: api/courses/search
    public searchForCourse(courseSearch: CourseSearchHandler.Request): Promise<CourseSearchHandler.Result> {
        let url = `api/courses/search`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, CourseSearchHandler.Result>(courseSearch, 'post', url, true, false);
    }

    // post: api/courses/searchWithRetired
    public searchForCourseWithRetired(courseSearchWithRetired: CourseSearchWithRetiredHandler.Request): Promise<CourseSearchWithRetiredHandler.Result> {
        let url = `api/courses/searchWithRetired`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, CourseSearchWithRetiredHandler.Result>(courseSearchWithRetired, 'post', url, true, false);
    }

    // post: api/courses/existingCourseSearch
    public existingCourseSearch(existingCourseSearch: ExistingCourseSearchHandler.Request): Promise<ExistingCourseSearchHandler.Result> {
        let url = `api/courses/existingCourseSearch`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ExistingCourseSearchHandler.Result>(existingCourseSearch, 'post', url, true, false);
    }

    // post: api/courses/existingInstitutionCourseSearch
    public existingInstitutionCourseSearch(existingInstitutionCourseSearch: ExistingInstitutionCourseSearchHandler.Request): Promise<ExistingInstitutionCourseSearchHandler.Result> {
        let url = `api/courses/existingInstitutionCourseSearch`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ExistingInstitutionCourseSearchHandler.Result>(existingInstitutionCourseSearch, 'post', url, true, false);
    }

    // get: api/courses/courseSyllabus/${encodeURIComponent(institutionCourseDetailId)}
    public courseSyllabus(institutionCourseDetailId: string): Promise<any> {
        let url = `api/courses/courseSyllabus/${encodeURIComponent(institutionCourseDetailId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/courses/ccn?termId=${encodeURIComponent(termId)}&year=${year}
    public commonCourseNumberingReport(termId: string, year: number): Promise<any> {
        let url = `api/courses/ccn`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (termId != null) {
            url += `${prefix}termId=${encodeURIComponent(termId)}`;
            prefix = '&';
        }
        if (year != null) {
            url += `${prefix}year=${year}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/courses/courseAttachedReport?institutionId=${encodeURIComponent(institutionId)}
    public exportCourseAttachedReport(institutionId: string): Promise<any> {
        let url = `api/courses/courseAttachedReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // post: api/courses/courseCourseBlocks?courseId=${encodeURIComponent(courseId)}
    public getCourseCourseBlocksTableData(tableRequest: TableRequestDTO, courseId: string): Promise<TableResponseDTO<CourseCourseBlocksDataTableDTO>> {
        let url = `api/courses/courseCourseBlocks`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (courseId != null) {
            url += `${prefix}courseId=${encodeURIComponent(courseId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<CourseCourseBlocksDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/courses/coursePrograms?courseId=${encodeURIComponent(courseId)}
    public getCourseProgramsTableData(tableRequest: TableRequestDTO, courseId: string): Promise<TableResponseDTO<CourseProgramDataTableDTO>> {
        let url = `api/courses/coursePrograms`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (courseId != null) {
            url += `${prefix}courseId=${encodeURIComponent(courseId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<CourseProgramDataTableDTO>>(tableRequest, 'post', url, true, false);
    }
}
var service = new CoursesApiService();
export default service;
