import { Card, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChangeRequestApiService from '../../../../api/ChangeRequestApiService';
import NewProgramChangeRequestApiService from '../../../../api/NewProgramChangeRequestApiService';
import ChangeRequestLayout from '../../../../components/postSecondary/ChangeRequestLayout';
import NewProgramChangeRequestDetails from '../../../../components/displays/postSecondary/NewProgramChangeRequestDetails';
import ChangeRequestReviewForm from '../../../../components/forms/postSecondary/ChangeRequestReviewForm';
import Routes from '../../../../config/Routes';
import ChangeRequestType from '../../../../consts/ChangeRequestType';
import * as CheckNOIApprovalHandler from '../../../../handlerModels/CheckNOIApprovalHandler';
import * as GetBaseChangeRequestDetailsHandler from '../../../../handlerModels/GetBaseChangeRequestDetailsHandler';
import ChangeRequestDTO from '../../../../models/ChangeRequestDTO';
import InstitutionDTO from '../../../../models/InstitutionDTO';
import UserSecurity from '../../../../models/UserSecurity';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface NewProgramChangeRequestStepReviewPageState extends BaseFormState {
  changeRequestId: string;
  changeRequest: ChangeRequestDTO;
  canSubmit: boolean;
  submitErrorMessage: string | null;
  noiNotApproved: boolean;
  isValid: boolean;
}

interface NewProgramChangeRequestStepReviewPageProps {
  selectedInstitution: InstitutionDTO | null;
  currentUser: UserSecurity | null;
}

class NewProgramChangeRequestStepReviewPage extends React.Component<NewProgramChangeRequestStepReviewPageProps & RouteComponentProps<RouteObject>, NewProgramChangeRequestStepReviewPageState> {

  constructor(props: NewProgramChangeRequestStepReviewPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      changeRequestId: Guid.Empty(),
      changeRequest: ChangeRequestDTO.create(),
      canSubmit: true,
      submitErrorMessage: null,
      noiNotApproved: false,
      isValid: false
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id == 'new') {
      this.setState({ isNew: true });
    }
    else if (id !== Guid.Empty() && this.state.changeRequestId != id) {
      this.setState({ changeRequestId: id });
      this.fetchData(id);
    }
  }

  private fetchData = (id: string) => {
    this.setState({ loading: true });

    const loaders = [];

    if (id !== Guid.Empty()) {
      loaders.push(this.loadChangeRequest(id));
    }

    if (id !== Guid.Empty()) {
      loaders.push(this.checkNOIApproval(id ?? Guid.Empty()));
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private checkNOIApproval = (id: string) => {
    const request = CheckNOIApprovalHandler.Request.create({
      changeRequestId: id
    });

    return NewProgramChangeRequestApiService.checkNOIApproval(request)
      .then((results: CheckNOIApprovalHandler.Result) => {
        if (results) {
          if (!results.isApproved) {
            this.setState({
              noiNotApproved: !results.isApproved
            });
          }
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not confirm NOI approval status.' });
      });
  }

  private loadChangeRequest = (id: string) => {
    return ChangeRequestApiService.getBaseChangeRequestDetails(id)
      .then((results: GetBaseChangeRequestDetailsHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private goToNextStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_SUBMITTED, { id: this.state.changeRequestId }));
  }

  private goToPreviousStep = () => {
    HistoryUtil.push(Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_15, { id: this.state.changeRequestId }));
  }
  private onIsValidHandler = (isValid: boolean) => {
    this.setState({
      isValid: isValid
    });
  }
  render() {
    return (
      <Content >
        <ChangeRequestLayout
          changeRequest={this.state.changeRequest ?? ChangeRequestDTO.create()}
          stepNumber={this.state.changeRequest.changeRequestTypeId === ChangeRequestType.NEWTRANSFERMAJORPROGRAM ? 10 : 15}
          currentUser={this.props.currentUser ?? {} as UserSecurity}>
          <Card title="Review" >
            <Space direction="vertical">
              <NewProgramChangeRequestDetails
                fieldErrors={this.state.fieldErrors}
                changeRequest={this.state.changeRequest}
                loading={this.state.loading}
                onIsValid={this.onIsValidHandler} />
              <ChangeRequestReviewForm
                isValid={this.state.isValid}
                changeRequest={this.state.changeRequest}
                onSubmit={this.goToNextStep}
                onPrevious={this.goToPreviousStep}
                notApproved={this.state.changeRequest.changeRequestTypeId === ChangeRequestType.NEWTRANSFERMAJORPROGRAM ? false : this.state.noiNotApproved}
                canSubmit={this.state.canSubmit}
                submitErrorMessage={this.state.submitErrorMessage}
              />
            </Space>
          </Card >
        </ChangeRequestLayout>
      </Content >
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    selectedInstitution: state.Institution.Selected,
    currentUser: state.UserSession.Value
  };
}

export default withRouter(connect(mapStateToProps)(NewProgramChangeRequestStepReviewPage));