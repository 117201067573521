import { Card, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SecondaryCipNumberApiService from '../../../api/SecondaryCipNumberApiService';
import ConfirmAndContinueButton from '../../../components/buttons/ConfirmAndContinueButton';
import PreviousButton from '../../../components/buttons/PreviousButton';
import SecondaryCipNumbersDataTable from '../../../components/datatables/secondary/SecondaryCipNumbersDataTable';
import DataReviewLayout from '../../../components/secondary/DataReviewLayout';
import SecondaryCipNumbersImporter from '../../../components/secondary/SecondaryCipNumberImporter';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as AcademicYearHasCIPNumbersHandler from '../../../handlerModels/AcademicYearHasCIPNumbersHandler';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface SecondaryCipNumberListPageState {
  academicYear: number;
  hasCips: boolean | undefined;
  errors: string[];
}

interface SecondaryCipNumberListPageProps {
}

class DataReviewSecondaryCipNumberListPage extends React.Component<SecondaryCipNumberListPageProps & RouteComponentProps<RouteObject>, SecondaryCipNumberListPageState> {

  constructor(props: SecondaryCipNumberListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0,
      hasCips: undefined,
      errors: []
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });
      this.loadSecondaryCipNumebers(id)
    }
  }

  private loadSecondaryCipNumebers = (academicYear: number, force?: boolean) => {
    if (force || (this.state.hasCips === undefined && academicYear > 0)) {
      SecondaryCipNumberApiService.academicYearHasCIPNumbers(academicYear)
        .then((results: AcademicYearHasCIPNumbersHandler.Result) => {
          if (results) {
            this.setState({
              hasCips: results.hasCips
            });
          }
        });
    }
  }

  private onSubmit = (errors?: string[]) => {
    this.loadSecondaryCipNumebers(this.state.academicYear, true);
    this.setState({ errors: errors ?? [] });
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_REGIONAL_PLANNING_PARTNER, { academicYear: this.state.academicYear?.toString() ?? 0 }, {}));
  }

  private onContinue = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_DISTRICTS, { academicYearId: this.state.academicYear?.toString() ?? 0 }, {}));
  }

  render() {
    const academicYear = this.state.academicYear == 0 ? 0 : this.state.academicYear;

    if (academicYear != 0) {
      return (
        <Content >
          <DataReviewLayout breadcrumbs={Breadcrumbs.academicYearDataReview(academicYear)} stepNumber={5} academicYear={academicYear}>
            <Card title="CIP Numbers">
              <Space direction='vertical' size="middle">
                {this.state.hasCips ?
                  <SecondaryCipNumbersDataTable errors={this.state.errors} academicYear={this.state.academicYear} /> :
                  <SecondaryCipNumbersImporter onSubmit={this.onSubmit} academicYear={this.state.academicYear} />}
                <Space>
                  <PreviousButton onClick={this.onBack} />
                  <ConfirmAndContinueButton onClick={this.onContinue} />
                </Space>
              </Space>
            </Card>
          </DataReviewLayout>
        </Content>
      );
    }

    return <Skeleton />;
  }
}

export default withRouter((DataReviewSecondaryCipNumberListPage));
