import { Tabs, TabsProps } from 'antd';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableTabNode } from './DraggableTabNode';

interface DraggableTabProps extends React.HTMLAttributes<HTMLDivElement> {
  moveTabNode: (dragIndex: React.Key, hoverIndex: React.Key) => void;
}

export const DraggableTab: React.FC<{ children: React.ReactNode } & TabsProps & DraggableTabProps> = props => {
  const { children } = props;

  const renderTabBar: TabsProps['renderTabBar'] = (tabBarProps, DefaultTabBar: any) => (
    <DefaultTabBar {...tabBarProps}>
      {(node: any) => (
        <DraggableTabNode key={node.key} index={node.key} moveNode={props.moveTabNode}>
          {node}
        </DraggableTabNode>
      )}
    </DefaultTabBar>
  );

  const tabs: React.ReactElement[] = [];
  React.Children.forEach(children, (c: any) => {
    tabs.push(c);
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <Tabs
        renderTabBar={renderTabBar}
        {...props}>
        {tabs}
      </Tabs>
    </DndProvider>
  );
};
