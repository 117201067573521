import { Button, Card, Result, Space, Spin } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../config/Routes';
import * as ConfirmEmailHandler from '../../handlerModels/ConfirmEmailHandler';
import * as LocalLoginHandler from '../../handlerModels/LocalLoginHandler';
import BaseFormState from '../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import ParameterUtil from '../../utils/ParameterUtil';

interface ConfirmEmailState extends BaseFormState {
  alreadyConfirmed: boolean;
  confirmSuccess: boolean;
  loading: boolean;
  code: string;
  userId: string;
}

interface ConfirmEmailProps {
  error: LocalLoginHandler.Result | null;
}

class ConfirmEmailPage extends React.Component<ConfirmEmailProps & RouteComponentProps<RouteObject>, ConfirmEmailState> {
  constructor(props: ConfirmEmailProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      alreadyConfirmed: false,
      confirmSuccess: false,
      loading: true,
      code: '',
      userId: '',
    };
  }

  componentDidMount() {
    const code = ParameterUtil.getParameter('code');
    const userId = ParameterUtil.getParameter('userId');

    this.setState({
      code: code,
      userId: userId
    });

    if (userId && code) {
      this.setState({ submitting: true });

      AccountApiService.confirmEmail(userId, code)
        .then((result: ConfirmEmailHandler.Result) => {
          if (result.succeeded) {
            this.setState({ confirmSuccess: !result.alreadyConfirmed, alreadyConfirmed: result.alreadyConfirmed, error: null });
          } else {
            this.setState({ error: true, message: result.errors.join('\n'), confirmSuccess: false, });
          }
        })
        .catch((error) => {
          this.setState({ error: true, message: error.message, confirmSuccess: false, });
        })
        .finally(() => {
          this.setState({ loading: false, submitting: false });
        });
    } else {
      this.setState({ loading: false, submitting: false, error: true, confirmSuccess: false, });
    }
  }

  render() {
    if (this.state.error) {
      return this.renderError();
    }
    else if (this.state.alreadyConfirmed) {
      return this.renderAlreadyConfirmed();
    }
    else if (this.state.confirmSuccess) {
      return this.renderSuccess();
    }

    return this.renderChecking();
  }

  renderError() {
    let message = 'Invalid link. Please request another and try again.';
    if (this.state.message) {
      message = this.state.message;
    }

    return (
      <Card>
        <Result
          status="error"
          title="Account Not Confirmed"
          subTitle={message}
          extra={
            <Button htmlType="button" size="large" disabled={this.state.loading} >
              <Link to={Routes.RESEND_CONFIRMATION_EMAIL}>Resend Confirmation Email</Link>
            </Button>
          }
        />
      </Card >
    );
  }

  renderAlreadyConfirmed() {
    return (
      <Card>
        <Result
          status="warning"
          title="Already Verified"
          subTitle={'This account has already been verified. Please login to use the system or request a password reset.'}
          extra={
            <Space direction='vertical'>
              <Button htmlType="button" size="large" disabled={this.state.loading} >
                <Link to={Routes.LOGIN}>Go to Login</Link>
              </Button>
              <Button htmlType="button" size="large" disabled={this.state.loading} >
                <Link to={Routes.REQUEST_RESET_PASSWORD}>Reset Password</Link>
              </Button>
            </Space>
          }
        />
      </Card >
    );
  }

  renderChecking() {
    return (
      <Card>
        <Spin tip="Loading..." size="large" />
      </Card>
    );
  }

  renderSuccess() {
    return (
      <Card>
        <Result
          status="success"
          title="Account Confirmed"
          subTitle="Your account has been confirmed. Please check your email for instructions to reset your password."
        />
      </Card >
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    loading: state.UserSession.Loading,
    error: state.UserSession.Result,
    userName: state.UserSession.Value?.userName ?? null,
  };
}

export default withRouter(connect(mapStateToProps)(ConfirmEmailPage));
