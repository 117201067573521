// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveInstitutionCourseDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import CourseDetailDTO from '../models/CourseDetailDTO';
import InstitutionCourseDetailDTO from '../models/InstitutionCourseDetailDTO';
import YearTermDTO from '../models/YearTermDTO';
import InstitutionCourseDTO from '../models/InstitutionCourseDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    courseDetail: CourseDetailDTO | null;
    formCourseDetail: CourseDetailDTO | null;
    formInstitutionCourseDetail: InstitutionCourseDetailDTO | null;
    originalInstitutionCourseDetail: InstitutionCourseDetailDTO | null;
    institutionId: string | null;
    courseId: string | null;
    futureTerm: YearTermDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            courseDetail: null,
            formCourseDetail: null,
            formInstitutionCourseDetail: null,
            originalInstitutionCourseDetail: null,
            institutionId: '00000000-0000-0000-0000-000000000000',
            courseId: '00000000-0000-0000-0000-000000000000',
            futureTerm: null,
        },
        initValues);
    },
    courseDetail: 'courseDetail',
    formCourseDetail: 'formCourseDetail',
    formInstitutionCourseDetail: 'formInstitutionCourseDetail',
    originalInstitutionCourseDetail: 'originalInstitutionCourseDetail',
    institutionId: 'institutionId',
    courseId: 'courseId',
    futureTerm: 'futureTerm',
}

interface Result extends ResultDTO {
    institutionCourse: InstitutionCourseDTO | null;
    institutionCourseDetailId: string | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            institutionCourse: null,
            institutionCourseDetailId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    institutionCourse: 'institutionCourse',
    institutionCourseDetailId: 'institutionCourseDetailId',
}

export { Request, Result };