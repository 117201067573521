import { Button, Card, message, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SecondaryProgramCourseApiService from '../../../api/SecondaryProgramCourseApiService';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import * as CompleteAcademicYearDataReviewHandler from '../../../handlerModels/CompleteAcademicYearDataReviewHandler';
import SecondaryProgramCoursesDataTable from '../../../components/datatables/secondary/SecondaryProgramCoursesDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import * as AcademicYearHasSecondaryProgramCoursesHandler from '../../../handlerModels/AcademicYearHasSecondaryProgramCoursesHandler';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';
import SecondaryProgramCoursesImporter from '../../../components/secondary/SecondaryProgramCoursesImporter';
import DataReviewLayout from '../../../components/secondary/DataReviewLayout';
import PreviousButton from '../../../components/buttons/PreviousButton';
import Validator from '../../../models/Validator';

interface SecondaryProgramCourseListPageState {
  academicYear: number;
  hasProgramCourses?: boolean;
  submitting?: boolean;
  loading?: boolean;
  error?: boolean;
  message?: string;
  fieldErrors?: { [key: string]: Validator[]; };
  altered?: boolean;
  errors: string[];
}

interface SecondaryProgramCourseListPageProps {
}

class DataReviewSecondaryProgramCourseListPage extends React.Component<SecondaryProgramCourseListPageProps & RouteComponentProps<RouteObject>, SecondaryProgramCourseListPageState> {

  constructor(props: SecondaryProgramCourseListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0,
      errors: []
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });
      this.loadSecondaryCourses(id);
    }
  }

  private loadSecondaryCourses = (academicYear: number, force?: boolean) => {
    if (force || (this.state.hasProgramCourses == undefined && academicYear > 0)) {
      SecondaryProgramCourseApiService.academicYearHasProgramCourses(academicYear)
        .then((results: AcademicYearHasSecondaryProgramCoursesHandler.Result) => {
          if (results) {
            this.setState({
              hasProgramCourses: results.hasProgramCourses
            });
          }
        });
    }
  }

  private onSubmit = (errors?: string[]) => {
    this.loadSecondaryCourses(this.state.academicYear, true);
    this.setState({ errors: errors ?? [] });
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SECONDARY_COURSES, { academicYearId: this.state.academicYear }, {}));
  }

  private onFinalize = () => {
    this.setState({ submitting: true });

    const request = CompleteAcademicYearDataReviewHandler.Request.create({
      academicYear: this.state.academicYear
    });

    AcademicYearApiService.completeAcademicYearDataReview(request)
      .then((result: CompleteAcademicYearDataReviewHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Program Review Year Data Review Complete');
          HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: result.academicYear }, {}));
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Failed to Complete Program Review Year Data Review');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }


  render() {
    const academicYear = this.state.academicYear;

    if (academicYear != 0) {
      return (
        <Content >
          <DataReviewLayout breadcrumbs={Breadcrumbs.academicYearDataReview(academicYear)} stepNumber={10} academicYear={academicYear}>
            <Card title="Program Courses">
              <Space direction='vertical' size="middle">
                {this.state.hasProgramCourses ?
                  <SecondaryProgramCoursesDataTable academicYear={this.state.academicYear} errors={this.state.errors} fromAcademicYearDataReview={true} /> :
                  <SecondaryProgramCoursesImporter onSubmit={this.onSubmit} academicYear={this.state.academicYear} programId={0} />
                }
                <Space>
                  <PreviousButton onClick={this.onBack} />
                  {this.state.hasProgramCourses ?
                    <Button type='primary' disabled={!this.state.hasProgramCourses} onClick={this.onFinalize} >Finalize Program Review Year Data</Button>
                    : null}
                </Space>
              </Space>
            </Card>
          </DataReviewLayout>
        </Content>
      );
    }

    return <Skeleton />
  }
}

export default withRouter((DataReviewSecondaryProgramCourseListPage));
