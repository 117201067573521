// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import DistrictUserDTO from '../models/DistrictUserDTO';
import * as AddUserToDistrictHandler from '../handlerModels/AddUserToDistrictHandler';
import * as AddDistrictToUserHandler from '../handlerModels/AddDistrictToUserHandler';
import * as RemoveUserFromDistrictHandler from '../handlerModels/RemoveUserFromDistrictHandler';
import * as RemoveDistrictFromUserHandler from '../handlerModels/RemoveDistrictFromUserHandler';
import * as SearchForDistrictUserHandler from '../handlerModels/SearchForDistrictUserHandler';
import * as EditDistrictUserRolesHandler from '../handlerModels/EditDistrictUserRolesHandler';

export class DistrictUserApiService extends BaseApi {

    // post: api/districtUsers/getDistrictsInUser?userId=${encodeURIComponent(userId)}&districtIdentifier=${districtIdentifier}
    public getDistrictsForUserTableData(tableRequest: TableRequestDTO, userId: string, districtIdentifier: number): Promise<TableResponseDTO<DistrictUserDTO>> {
        let url = `api/districtUsers/getDistrictsInUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }
        if (districtIdentifier != null) {
            url += `${prefix}districtIdentifier=${districtIdentifier}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<DistrictUserDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/districtUsers/getUsersInDistrict?districtIdentifier=${districtIdentifier}
    public getUsersInDistrictTableData(tableRequest: TableRequestDTO, districtIdentifier: number): Promise<TableResponseDTO<DistrictUserDTO>> {
        let url = `api/districtUsers/getUsersInDistrict`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (districtIdentifier != null) {
            url += `${prefix}districtIdentifier=${districtIdentifier}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<DistrictUserDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/districtUsers/addUser
    public addDistrictUser(addUserToDistrict: AddUserToDistrictHandler.Request): Promise<AddUserToDistrictHandler.Result> {
        let url = `api/districtUsers/addUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddUserToDistrictHandler.Result>(addUserToDistrict, 'post', url, true, false);
    }

    // post: api/districtUsers/addDistrict
    public addDistrictToUser(addDistrictToUser: AddDistrictToUserHandler.Request): Promise<AddDistrictToUserHandler.Result> {
        let url = `api/districtUsers/addDistrict`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddDistrictToUserHandler.Result>(addDistrictToUser, 'post', url, true, false);
    }

    // post: api/districtUsers/removeUser
    public removeDistrictUser(removeUserFromDistrict: RemoveUserFromDistrictHandler.Request): Promise<RemoveUserFromDistrictHandler.Result> {
        let url = `api/districtUsers/removeUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveUserFromDistrictHandler.Result>(removeUserFromDistrict, 'post', url, true, false);
    }

    // post: api/districtUsers/removeDistrictFromUser
    public removeDistrictFromUser(removeDistrictFromUser: RemoveDistrictFromUserHandler.Request): Promise<RemoveDistrictFromUserHandler.Result> {
        let url = `api/districtUsers/removeDistrictFromUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveDistrictFromUserHandler.Result>(removeDistrictFromUser, 'post', url, true, false);
    }

    // post: api/districtUsers/searchDistrictUsers
    public searchDistrictUsers(searchForDistrictUser: SearchForDistrictUserHandler.Request): Promise<SearchForDistrictUserHandler.Result> {
        let url = `api/districtUsers/searchDistrictUsers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForDistrictUserHandler.Result>(searchForDistrictUser, 'post', url, true, false);
    }

    // post: api/districtUsers/editRoles
    public editDistrictUserRoles(editDistrictUserRoles: EditDistrictUserRolesHandler.Request): Promise<EditDistrictUserRolesHandler.Result> {
        let url = `api/districtUsers/editRoles`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, EditDistrictUserRolesHandler.Result>(editDistrictUserRoles, 'post', url, true, false);
    }
}
var service = new DistrictUserApiService();
export default service;
