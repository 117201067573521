import { Card, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ConfirmAndContinueButton from '../../../components/buttons/ConfirmAndContinueButton';
import PreviousButton from '../../../components/buttons/PreviousButton';
import RegionalPlanningPartnerDataTable from '../../../components/datatables/secondary/RegionalPlanningPartnersDataTable';
import DataReviewLayout from '../../../components/secondary/DataReviewLayout';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface RegionalPlanningPartnerListPageState {
  academicYear: number;
}

interface RegionalPlanningPartnerListPageProps {
}

class DataReviewRegionalPlanningPartnerListPage extends React.Component<RegionalPlanningPartnerListPageProps & RouteComponentProps<RouteObject>, RegionalPlanningPartnerListPageState> {

  constructor(props: RegionalPlanningPartnerListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYear'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });
    }
  }

  private onBack = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SECONDARY_PROGRAM_TYPES, { academicYearId: this.state.academicYear }, {}));
  }

  private onContinue = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SECONDARY_CIP_NUMBERS, { academicYearId: this.state.academicYear }, {}));
  }

  render() {
    const academicYear = this.state.academicYear == 0 ? 0 : this.state.academicYear;

    if (academicYear != 0) {
      return (
        <Content >
          <DataReviewLayout breadcrumbs={Breadcrumbs.academicYearDataReview(academicYear)} stepNumber={4} academicYear={academicYear}>
            <Card title="Regional Planning Partners">
              <Space direction='vertical' size="middle">
                <RegionalPlanningPartnerDataTable fromAcademicYear={true} academicYear={this.state.academicYear} />
                <Space>
                  <PreviousButton onClick={this.onBack} />
                  <ConfirmAndContinueButton onClick={this.onContinue} />
                </Space>
              </Space>
            </Card>
          </DataReviewLayout>
        </Content>
      );
    }

    return <Skeleton />;
  }
}

export default withRouter((DataReviewRegionalPlanningPartnerListPage));
