import UserSecurityDTO from './UserSecurityDTO';

export default class UserSecurity {
  public readonly userId: string | null;

  public readonly userName: string | null;

  public readonly firstName: string | null;

  public readonly lastName: string | null;

  public readonly fullName: string | null;

  public readonly initials: string | null;

  public readonly isStateUser: boolean;

  public readonly institutionIds: ReadonlyArray<string>;

  public readonly districtIds: ReadonlyArray<number>;

  public readonly rppIds: ReadonlyArray<number>;

  private readonly roles: ReadonlyArray<string>;

  private readonly featureFlags: ReadonlyArray<string>;

  constructor(userinfo: UserSecurityDTO) {
    this.userId = userinfo.userId;
    this.userName = userinfo.userName;
    this.roles = Object.freeze(userinfo.roles);
    this.featureFlags = Object.freeze(userinfo.featureFlags);
    this.institutionIds = userinfo.institutionIds;
    this.districtIds = userinfo.districtIdentifiers;
    this.rppIds = userinfo.rppIds;
    this.firstName = userinfo.firstName;
    this.lastName = userinfo.lastName;
    this.fullName = userinfo.fullName;
    this.isStateUser = userinfo.isStateUser;
    this.initials = userinfo.initials;
  }

  isInRole(role: string) {
    return this.roles.indexOf(role) >= 0;
  }

  hasFeatureFlag(featureFlag: string) {
    return this.featureFlags.indexOf(featureFlag) >= 0;
  }

  hasInstitutions() {
    return this.institutionIds.length > 0;
  }

  getRoles() {
    return this.roles;
  }
}
