import { AppstoreOutlined, BankOutlined, BellOutlined, BookOutlined, CalendarOutlined, ContactsOutlined, ContainerOutlined, DashboardOutlined, DownloadOutlined, FileTextOutlined, HddOutlined, IdcardOutlined, ImportOutlined, OrderedListOutlined, TeamOutlined, ToolOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../config/Routes';
import Sections from '../../config/Sections';
import FeatureFlag from '../../consts/FeatureFlag';
import InstitutionDTO from '../../models/InstitutionDTO';
import UserSecurity from '../../models/UserSecurity';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import DistrictDTO from '../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../models/RegionalPlanningPartnerDTO';

const { Sider } = Layout;

interface SticsSiderProps {
  collapsed?: boolean;
  collapseMenu?: () => void | null;
  institution?: InstitutionDTO | null;
  selectedDistrict?: DistrictDTO | null;
  selectedRPP?: RegionalPlanningPartnerDTO | null;
  userInfo?: UserSecurity | null;
}

interface SticsSiderState {
  current: string;
}

class SticsSider extends React.Component<SticsSiderProps, SticsSiderState>  {

  constructor(props: SticsSiderProps) {
    super(props);

    this.state = {
      current: ''
    };
  }

  render() {
    const selectedKeys = [this.state.current];
    const institution = this.props.institution != null && this.props.institution != undefined;

    const editResources = !this.props.institution && AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RESOURCE]);
    const resourceLink = editResources ?
      (< Link to={Routes.generate(Routes.ADMIN_RESOURCES)} >Resources</Link >) :
      (< Link to={Routes.generate(Routes.RESOURCES)} >Resources</Link >);

    return (
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={this.props.collapsed}
        theme="dark"
        width={250}
        collapsedWidth={80}
        breakpoint="lg">
        <Menu theme="dark"
          defaultSelectedKeys={[Sections.SectionsNames.DASHBOARD]}
          selectedKeys={selectedKeys}
          mode="inline">

          <Menu.Item
            key={Sections.SectionsNames.DASHBOARD}
            title="Dashboard"
            icon={<DashboardOutlined />}>
            <Link to={Routes.generate(Routes.HOME)}>
              Dashboard
            </Link>
          </Menu.Item>

          <Menu.Item
            key={Sections.SectionsNames.WORK_ITEMS}
            title="Work Items"
            icon={<OrderedListOutlined />}>
            <Link to={Routes.generate(Routes.WORK_ITEMS)}>
              Work Items
            </Link>
          </Menu.Item>

          {this.renderAnnouncements(institution)}

          {this.renderSecondariesEducation(institution, resourceLink)}

          <Menu.Item
            key={Sections.SectionsNames.USERS}
            title="Users"
            icon={<TeamOutlined />}
            hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.USERS)}>
            <Link to={Routes.generate(Routes.USERS)} >
              Users
            </Link>
          </Menu.Item>

          {this.renderStateActions(institution)}

          {this.renderDocumentArchives()}

        </Menu>
      </Sider >);
  }

  renderDocumentArchives(){
    return (
      <Menu.Item
            key={Sections.SectionsNames.DOCUMENTARCHIVE}
            title="DocumentArchive"
            icon={<HddOutlined />}
            hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.DOCUMENTARCHIVE)}>
            <Link to={Routes.generate(Routes.DOCUMENTARCHIVES)} >
              Document Archive
            </Link>
            </Menu.Item>
    )
  }

  renderSecondariesEducation(institution: boolean, resourceLink: JSX.Element) {

    const isSecondaryAuthorized =  AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.DISTRICT) || 
    AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.RPP) ||
    AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.SECONDARY_PROGRAM) ||
    AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.SECONDARY_COURSES) ||
    AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.PROGRAM_REVIEW);

    const isPostSecondaryAuthorized = AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.CHANGE_REQUESTS) ||
    AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.PROGRAMS)||
    AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.COURSES) ||
    AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CONTACT]) ||
    AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.INSTITUTIONS)||
    AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RESOURCE]);
    
    if(((this.props.userInfo?.districtIds.findIndex(x => x == this.props.selectedDistrict?.districtId) ?? -1) > -1 || 
    (this.props.userInfo?.rppIds.findIndex(x => x == this.props.selectedRPP?.regionalPlanningPartnerIdentifier) ?? -1) > -1) ||
    (isSecondaryAuthorized && !isPostSecondaryAuthorized)){
      return this.renderSecondaryEducation();
    }
    else if (((this.props.userInfo?.institutionIds.findIndex(x => x == this.props.institution?.id) ?? -1) > -1) ||
    (!isSecondaryAuthorized && isPostSecondaryAuthorized)) {
      return this.renderPostSecondaryEducation(institution, resourceLink);
    }
    else if (this.props.userInfo?.isStateUser || (isSecondaryAuthorized && isPostSecondaryAuthorized)) {
      return (
        <>
          <Menu.SubMenu title="Secondary Education">
            {this.renderSecondaryEducation()}
          </Menu.SubMenu>


          <Menu.SubMenu title="Post-Secondary Education">
            {this.renderPostSecondaryEducation(institution, resourceLink)}
          </Menu.SubMenu>
        </>
      );
    }
  }

  renderSecondaryEducation() {
    return (
      <>
        <Menu.Item
          key={'Program Review Years'}
          title="Program Review Years"
          icon={<CalendarOutlined />}
        //hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.CHANGE_REQUESTS)}
        >
          <Link to={Routes.generate(Routes.ACADEMIC_YEARS)}
          >
            Program Review Years
          </Link>
        </Menu.Item>

        <Menu.Item
          key={'Districts'}
          title="Districts"
          icon={<BankOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.DISTRICT)}
        >
          <Link to={Routes.generate(Routes.ALL_DISTRICTS)}
          >
            Districts
          </Link>
        </Menu.Item>

        <Menu.Item
          key={'RPP'}
          title="RPP"
          icon={<AppstoreOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.RPP)}
        >
          <Link to={Routes.generate(Routes.REGIONAL_PLANNING_PARTNERS)}
          >
            RPP
          </Link>
        </Menu.Item>

        <Menu.Item
          key={'Programs'}
          title="Programs"
          icon={<BookOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.SECONDARY_PROGRAM)}
        >
          <Link to={Routes.generate(Routes.ALL_SECONDARY_PROGRAMS)}>
            Programs
          </Link>
        </Menu.Item>

        <Menu.Item
          key={Sections.SectionsNames.COURSES}
          title="Courses"
          icon={<ContainerOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.SECONDARY_COURSES)}
        >
          <Link to={Routes.generate(Routes.ALL_SECONDARY_COURSES)}>
            Courses
          </Link>
        </Menu.Item>

      </>
    );
  }

  renderPostSecondaryEducation(institution: boolean, resourceLink: JSX.Element) {
    return (
      <>
        <Menu.Item
          key={Sections.SectionsNames.CHANGE_REQUESTS}
          title="Change Requests"
          icon={<ImportOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.CHANGE_REQUESTS)}>
          <Link to={Routes.generate(Routes.CHANGE_REQUESTS)} >
            Change Requests
          </Link>
        </Menu.Item>

        {this.renderInstitutionDetails(institution)}

        <Menu.Item
          key={Sections.SectionsNames.PROGRAMS}
          title="Programs"
          icon={<BookOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.PROGRAMS)}>
          <Link to={Routes.generate(Routes.PROGRAMS)}>
            Programs
          </Link>
        </Menu.Item>

        <Menu.Item
          key={Sections.SectionsNames.COURSES}
          title="Courses"
          icon={<ContainerOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.COURSES)}>
          <Link to={Routes.generate(Routes.COURSES)}>
            Courses
          </Link>
        </Menu.Item>

        <Menu.Item
          key={Sections.SectionsNames.RESOURCES}
          title="Resources"
          icon={<FileTextOutlined />}>
          {resourceLink}
        </Menu.Item>

        <Menu.Item
          key={Sections.SectionsNames.DOWNLOADS}
          title="Downloads"
          icon={<DownloadOutlined />}>
          <Link to={Routes.generate(Routes.DOWNLOADS_SECTION)} >
            Downloads
          </Link>
        </Menu.Item>

        <Menu.Item
          key={Sections.SectionsNames.CONTACTS}
          title="Contacts"
          icon={<IdcardOutlined />}
          hidden={!AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CONTACT])}
        ><Link to={Routes.generate(Routes.CONTACTS)} >
            Contacts
          </Link>
        </Menu.Item>

        <Menu.Item
          key={Sections.SectionsNames.CONSULTANTS}
          title="Consultants"
          icon={<ContactsOutlined />}>
          <Link to={Routes.generate(Routes.CONSULTANTS)} >
            Consultants
          </Link>
        </Menu.Item>
      </>
    );
  }

  renderStateActions(institution: boolean) {
    if (!institution) {
      return (
        <Menu.Item
          key={Sections.SectionsNames.CONFIGURATIONS}
          title="Configuration"
          icon={<ToolOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.CONFIGURATIONS)}>
          <Link to={Routes.generate(Routes.CONFIGURATIONS)} >
            Configuration
          </Link>
        </Menu.Item>
      );
    }
  }

  renderInstitutionDetails(institution: boolean) {
    if (institution) {
      return (
        <Menu.Item
          key={Sections.SectionsNames.INSTITUTIONS}
          title="Community College Details"
          icon={<BankOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.INSTITUTIONS)}>
          <Link to={Routes.generate(Routes.INSTITUTION_DETAILS, { id: this.props.institution?.id ?? '' })} >
            Community College
          </Link>
        </Menu.Item >
      );
    }
    else if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_INSTITUTION])) {
      return (
        <Menu.Item
          key={Sections.SectionsNames.INSTITUTIONS}
          title="Community Colleges"
          icon={<BankOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.INSTITUTIONS)}>
          <Link to={Routes.generate(Routes.INSTITUTIONS)} >
            Community Colleges
          </Link>
        </Menu.Item >
      );
    }
  }

  renderAnnouncements(institution: boolean) {
    if (!institution) {
      return (
        <Menu.Item
          key={Sections.SectionsNames.ANNOUNCEMENTS}
          title="Announcements"
          icon={<BellOutlined />}
          hidden={!AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.ANNOUNCEMENTS)}>
          <Link to={Routes.generate(Routes.ANNOUNCEMENTS)}>
            Announcements
          </Link>
        </Menu.Item>
      );
    }
  }
}

export default SticsSider;
