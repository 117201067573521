import { Alert, Input, InputNumber, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import APSGeneralEducationApiService from '../../../api/APSGeneralEducationApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetAPSGeneralEducationDetailsHandler from '../../../handlerModels/GetAPSGeneralEducationDetailsHandler';
import * as SaveAPSGeneralEducationDetailsHandler from '../../../handlerModels/SaveAPSGeneralEducationDetailsHandler';
import APSGeneralEducationDTO from '../../../models/APSGeneralEducationDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface APSGeneralEducationDetailsFormState extends BaseFormState {
  apsGeneralEducationId: number;
  apsGeneralEducation: APSGeneralEducationDTO | null;
}

interface APSGeneralEducationDetailsFormProps extends BaseFormProps {
  apsGeneralEducationId: number;
  onSave?: (apsGeneralEducationId: number) => void;
}

class APSGeneralEducationDetailsForm extends React.Component<APSGeneralEducationDetailsFormProps, APSGeneralEducationDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(APSGeneralEducationDTO.code, {
      required: true,
      name: APSGeneralEducationDTO.code,
      label: 'Code',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(3)
      ]
    })
    .set(APSGeneralEducationDTO.displayOrder, {
      required: true,
      name: APSGeneralEducationDTO.displayOrder,
      label: 'Display Order',
      rules: [
        ValidationRuleUtil.required(),
      ]
    })
    .set(APSGeneralEducationDTO.name, {
      required: true,
      name: APSGeneralEducationDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(50)
      ]
    });

  constructor(props: APSGeneralEducationDetailsFormProps) {
    super(props);

    this.state = {
      apsGeneralEducationId: 0,
      apsGeneralEducation: APSGeneralEducationDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadAPSGeneralEducation();
  }

  componentDidUpdate(prevProps: APSGeneralEducationDetailsFormProps) {
    if (this.props.apsGeneralEducationId && this.props.apsGeneralEducationId != prevProps.apsGeneralEducationId) {
      this.setState({ apsGeneralEducationId: this.props.apsGeneralEducationId });
      this.loadAPSGeneralEducation();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadAPSGeneralEducation() {
    if (this.props.apsGeneralEducationId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.apsGeneralEducationId) {
      APSGeneralEducationApiService.getAPSGeneralEducationDetails(this.props.apsGeneralEducationId)
        .then((results: GetAPSGeneralEducationDetailsHandler.Result) => {
          if (results && results.apsGeneralEducation) {
            this.setState({ apsGeneralEducationId: results.apsGeneralEducation.id, apsGeneralEducation: results.apsGeneralEducation, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ apsGeneralEducation: APSGeneralEducationDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveAPSGeneralEducationDetailsHandler.Request.create({
      apsGeneralEducationId: this.state.apsGeneralEducationId,
      apsGeneralEducation: APSGeneralEducationDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    APSGeneralEducationApiService.saveAPSGeneralEducationDetails(request)
      .then((result: SaveAPSGeneralEducationDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          apsGeneralEducation: result?.apsGeneralEducation,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.apsGeneralEducation?.id) {
            this.props.onSave(result.apsGeneralEducation.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(APSGeneralEducationDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('APS General Education could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('APS General Education could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          initialValues={this.state.apsGeneralEducation ?? APSGeneralEducationDTO.create()}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(APSGeneralEducationDTO.code)}
            {...ValidationUtil.getValidation(APSGeneralEducationDTO.code, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(APSGeneralEducationDTO.name)}
            {...ValidationUtil.getValidation(APSGeneralEducationDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(APSGeneralEducationDTO.displayOrder)}
            {...ValidationUtil.getValidation(APSGeneralEducationDTO.displayOrder, this.state.fieldErrors, this.state.submitted)}>
            <InputNumber disabled={!this.props.isEditing || this.state.loading || this.state.submitting} min={1} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_GENERAL_EDUCATION]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default APSGeneralEducationDetailsForm;
