import { message, Space } from 'antd';
import moment from 'moment';
import * as React from 'react';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import * as SaveAcademicYearHandler from '../../../handlerModels/SaveAcademicYearHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import AcademicYearDatesForm from './AcademicYearDatesForm';

interface AcademicYearFormState extends BaseFormState {
  academicYearId: number;
}

interface AcademicYearFormProps extends BaseFormProps {
  fromAcademicYearList?: boolean;
  academicYearId: number;
  readonly?: boolean;
  onSubmit?: (fromAcademicYearList: boolean) => void;
  onAcademicYearSave?: (academicYearId: number) => void;
}

class AcademicYearForm extends React.Component<AcademicYearFormProps, AcademicYearFormState> {
  private readonly _academicYearDatesFormRef = React.createRef<AcademicYearDatesForm>();

  constructor(props: AcademicYearFormProps) {
    super(props);

    this.state = {
      academicYearId: 0
    };
  }

  componentDidMount() {
    if (this.props.academicYearId && this.props.academicYearId != 0) {
      this.setState({ academicYearId: this.props.academicYearId });
    }
  }

  componentDidUpdate(prevProps: AcademicYearFormProps) {
    if (this.props.academicYearId && this.props.academicYearId != 0 && prevProps.academicYearId != this.props.academicYearId) {
      this.setState({ academicYearId: this.props.academicYearId });
    }
  }

  public onSubmit = () => {
    const form = this._academicYearDatesFormRef?.current?.getValue();
    const loaders = [];

    form?.current?.validateFields();
    loaders.push(form?.current?.validateFields());

    Promise.all(loaders).then(() => {
      this.handleSubmit(form?.current?.getFieldsValue());
    });
  }

  private handleSubmit = (form: any) => {
    this.setState({ submitting: true });

    const request = SaveAcademicYearHandler.Request.create({
      academicYear: AcademicYearDTO.create({
        programInfoYear: form.programInfoYear,
        instructorStartDate: moment(form.instructorStartDate[0]),
        instructorEndDate: moment(form.instructorStartDate[1]),
        rppStartDate: moment(form.rppStartDate[0]),
        rppEndDate: moment(form.rppStartDate[1]),
        deStartDate: moment(form.deStartDate[0]),
        deEndDate: moment(form.deStartDate[1]),
        commentReleaseDate: moment(form.commentReleaseDate),
        finalizationDate: moment(form.finalizationDate)
      }),
      academicYearId: this.state.academicYearId
    });

    AcademicYearApiService.saveAcademicYear(request)
      .then((result: SaveAcademicYearHandler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          if (this.props.isNew) {
            message.success('Program Review Year Opened');
          }
          else {
            message.success('Program Review Year Saved');
          }

          this.setState({
            saved: true,
            submitted: false,
            fieldErrors: undefined
          });

          if (this.props.onSubmit) {
            this.props.onSubmit(this.props.fromAcademicYearList ?? false)
          }

          if (this.props.onAcademicYearSave) {
            this.props.onAcademicYearSave(result.academicYearId)
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });

          message.error('Error');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results, submitting: false });
        message.error('Error');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    return (
      <Space direction="vertical" >
        <AcademicYearDatesForm
          ref={this._academicYearDatesFormRef}
          academicYearId={this.state.academicYearId}
          readonly={this.props.readonly}
          fieldErrors={this.state.fieldErrors}
          submitted={this.state.submitted}
          submitting={this.state.submitting}
          isNew={this.props.isNew}
          isEditing={this.props.isEditing || this.props.isNew} />
        {this.props.isNew ? null : this.renderActions()}
      </Space>
    );
  }

  renderActions() {
    if (!this.props.readonly && this.props.isEditing) {
      const saveButton = this.props.isNew ? <SaveAndContinueButton submitting={this.state.submitting} onClick={this.onSubmit} /> : <SaveButton onClick={this.onSubmit} />
      return (
        <Space direction='horizontal' wrap={true}>
          {saveButton}
        </Space>
      );
    }
  }
}

export default AcademicYearForm;
