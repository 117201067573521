import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TransferMajorDisciplineFrameworkElementDataTable from '../../../../components/datatables/postSecondary/TransferMajorDisciplineFrameworkElementDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface TransferMajorDisciplineFrameworkElementListProps {
}

interface TransferMajorDisciplineFrameworkElementListState {
}

class TransferMajorDisciplineFrameworkElementListPage extends React.Component<TransferMajorDisciplineFrameworkElementListProps & RouteComponentProps<RouteObject>, TransferMajorDisciplineFrameworkElementListState> {
  constructor(props: TransferMajorDisciplineFrameworkElementListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Discipline Framework Element" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.transferMajorDisciplineFrameworkElementList()} />

        <Card >
          <TransferMajorDisciplineFrameworkElementDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(TransferMajorDisciplineFrameworkElementListPage);
