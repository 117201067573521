import * as React from 'react';
import TransferMajorGeneralEducationTypeApiService from '../../../api/TransferMajorGeneralEducationTypeApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import TransferMajorGeneralEducationTypeDTO from '../../../models/TransferMajorGeneralEducationTypeDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import FileDownload from '../../../utils/FileDownload';
import DateUtil from '../../../utils/DateTimeUtil';

interface TransferMajorGeneralEducationTypeDataTableProps extends BaseFormProps {
}

interface TransferMajorGeneralEducationTypeDataTableState extends BaseDataTableState<TransferMajorGeneralEducationTypeDTO>, BaseFormState {
}

class TransferMajorGeneralEducationTypeDataTable extends React.Component<TransferMajorGeneralEducationTypeDataTableProps, TransferMajorGeneralEducationTypeDataTableState> {
  private _dataTable: DataTable<TransferMajorGeneralEducationTypeDTO> | undefined;

  constructor(props: TransferMajorGeneralEducationTypeDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Name',
        dataIndex: TransferMajorGeneralEducationTypeDTO.name,
        sorter: true,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];
  };

  private openTransferMajorGeneralEducationType = (record: TransferMajorGeneralEducationTypeDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE_DETAILS, { id: record.id }, {}));
    }
  }

  private addTransferMajorGeneralEducationType = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return TransferMajorGeneralEducationTypeApiService.getTransferMajorGeneralEducationTypeDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Transfer_Major_General_Education_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE])) {
      actionButtons.push(DataTableButtonUtil.Default('New General Education Type', () => this.addTransferMajorGeneralEducationType()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openTransferMajorGeneralEducationType}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => TransferMajorGeneralEducationTypeApiService.getTransferMajorGeneralEducationTypesDataTable(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'transferMajorGeneralEducationType_list' }} />
      </>
    );
  }
}

export default TransferMajorGeneralEducationTypeDataTable;
