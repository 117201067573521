import { DeleteOutlined, DownloadOutlined, FormOutlined } from '@ant-design/icons';
import { Alert, Button, message, Modal, Space } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import SecondaryCipNumberApiService from '../../../api/SecondaryCipNumberApiService';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteSecondaryCipNumberHandler from '../../../handlerModels/DeleteSecondaryCipNumberHandler';
import * as GetServiceAreasHandler from '../../../handlerModels/GetServiceAreasHandler';
import GenderDTO from '../../../models/GenderDTO';
import SecondaryCipNumberDTO from '../../../models/SecondaryCipNumberDTO';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import SecondaryCipNumberDetailsModal from '../../modals/SecondaryCipNumberDetailsModal';
import SecondaryCipNumbersImporter from '../../secondary/SecondaryCipNumberImporter';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface SecondaryCipNumbersDataTableProps {
  academicYear: number;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  errors?: string[];
  isConfiguration?: boolean
}

function SecondaryCipNumbersDataTable(props: SecondaryCipNumbersDataTableProps) {
  let _dataTable: DataTable<SecondaryCipNumberDTO> | undefined = undefined;
  const _secondaryCipNumberImporterRef = React.createRef<SecondaryCipNumbersImporter>();

  const [secondaryCipNumberId, setSecondaryCipNumberId] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(props.errors);
  const [serviceAreas, setServiceAreas] = useState<ServiceAreaDTO[]>([]);
  const [genders, setGenders] = useState<GenderDTO[]>([]);

  React.useEffect(() => {
    ServiceAreaApiService.getServiceAreas(props.academicYear).then((results: GetServiceAreasHandler.Result) => {
      if (results.serviceAreas) {
        setServiceAreas(results.serviceAreas);
      }
    })
  }, [props.academicYear]);

  React.useEffect(() => {
    LookupsUtil.getAll<GenderDTO>(GenderDTO.className).then((results: GenderDTO[]) => {
      if (results) {
        results.push(GenderDTO.create({ id: 0, name: 'None' }))
        setGenders(results);
      }
    })
  }, []);

  const getColumnDefinitions = () => {
    return [
      {
        title: 'Identifier',
        dataIndex: SecondaryCipNumberDTO.cipNumberIdentifier,
        sorter: true,
        width: 50,
        filterType: FilterType.Text
      },
      {
        title: 'CIP Number',
        dataIndex: SecondaryCipNumberDTO.code,
        sorter: true,
        width: 50,
        render: (data: string, row: SecondaryCipNumberDTO) => {
          return row.code;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Title',
        dataIndex: SecondaryCipNumberDTO.description,
        sorter: true,
        width: 50,
        render: (data: string, row: SecondaryCipNumberDTO) => {
          return row.description;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Special Unit',
        dataIndex: SecondaryCipNumberDTO.specialUnit,
        sorter: true,
        width: 50,
        render: (data: string, row: SecondaryCipNumberDTO) => {
          return row.specialUnit;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Service Area',
        dataIndex: SecondaryCipNumberDTO.serviceAreaIdentifier,
        sorter: true,
        width: 250,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: serviceAreas.map(c => { return { text: c.description, value: c.serviceAreaIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: SecondaryCipNumberDTO) => {
          if (serviceAreas.length > 0) {
            const sa = serviceAreas.filter((x: ServiceAreaDTO) => x.serviceAreaIdentifier === row.serviceAreaIdentifier);
            return sa[0]?.display ?? ''
          }

          return '';
        },
      },
      {
        title: 'State Approved',
        dataIndex: SecondaryCipNumberDTO.stateApproved,
        sorter: true,
        width: 50,
        render: (data: string, row: SecondaryCipNumberDTO) => {
          return row.stateApproved == true ? 'Yes' : 'No';
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: TableFilterUtil.GetTrueOrFalse(),
        filterDropdownVisible: true
      },
      {
        title: 'Non-Traditional Gender',
        dataIndex: SecondaryCipNumberDTO.nonTraditionalGenderId,
        sorter: true,
        width: 50,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: genders.map(c => { return { text: c.name, value: c.id.toString() } }) as TableFilterOption[],
        render: (data: string, row: SecondaryCipNumberDTO) => {
          if (genders.length > 0) {
            const sa = genders.filter((x: GenderDTO) => x.id === row.nonTraditionalGenderId);
            return sa[0]?.name ?? ''
          }

          return '';
        },
      },
      {
        title: 'Actions',
        align: 'center',
        dataIndex: SecondaryCipNumberDTO.cipNumberIdentifier,
        sorter: false,
        render: (data: string, row: SecondaryCipNumberDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => openSecondaryCipNumberDetail(row)}><FormOutlined /></Button>
              <Button type="link" onClick={() => promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 50
      },
    ] as DataTableColumnProps<any>[];
  };

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const openSecondaryCipNumberDetail = (record: SecondaryCipNumberDTO) => {
    setSecondaryCipNumberId(record.id);
  }

  const addSecondaryCipNumber = () => {
    setSecondaryCipNumberId(Guid.Empty);
  }

  const closeSecondaryCipNumberDetail = () => {
    setSecondaryCipNumberId(null);
  }

  const promptConfirmRemove = (secondaryCipNumberId: string | null) => {
    if (secondaryCipNumberId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this CIP Number?',
      okText: 'Delete',
      onOk: () => deleteSecondaryCipNumber(secondaryCipNumberId, props.academicYear),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteSecondaryCipNumber = (secondaryCipNumberId: string, academicYear: number) => {
    const request = DeleteSecondaryCipNumberHandler.Request.create({
      secondaryCipNumberId: secondaryCipNumberId,
      academicYearId: academicYear
    });

    SecondaryCipNumberApiService.deleteSecondaryCipNumber(request)
      .then((result: DeleteSecondaryCipNumberHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Secondary CIP Number was removed.');
          triggerRefresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('Secondary CIP Number could not be removed.');
      });
  }

  const openModal = () => {
    setLoading(true);
    Modal.confirm({
      title: 'Import',
      content: <SecondaryCipNumbersImporter
        ref={_secondaryCipNumberImporterRef}
        academicYear={props.academicYear}
        refreshDataTable={_dataTable}
        isInModal={true}
        onSubmit={modalSubmit} />,
      onOk: () => _secondaryCipNumberImporterRef.current?.confirmImport(),
      onCancel: () => { setLoading(false); },
      width: 500
    });
  }

  const modalSubmit = (errors?: string[]) => {
    setLoading(false);
    setErrors(errors);
  }

  const downloadTemplate = () => {
    window.open(Routes.generateFull(Routes.SECONDARY_CIP_NUMBER_IMPORT_TEMPLATE, {}, {}), '_blank')
  }


  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_CIP_NUMBER])) {
    actionButtons.push(DataTableButtonUtil.Default('New CIP Number', () => addSecondaryCipNumber()));
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_CIP_NUMBER]) && !props.isConfiguration) {
    actionButtons.push(DataTableButtonUtil.Default('Import CIP Numbers', () => openModal(), undefined, loading));
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_SECONDARY_CIP_NUMBER]) && !props.isConfiguration) {
    actionButtons.push(DataTableButtonUtil.Default('Template', () => downloadTemplate(), <DownloadOutlined />, loading));
  }

  const renderModal = () => {
    if (secondaryCipNumberId) {
      return <SecondaryCipNumberDetailsModal
        onCancel={closeSecondaryCipNumberDetail}
        visible={true}
        secondaryCipNumberId={secondaryCipNumberId}
        academicYear={props.academicYear}
        onSave={closeSecondaryCipNumberDetail} />
    }
  }

  const renderErrors = () => {
    if (errors && errors.length > 0) {
      return <Alert type="error" message='Error' showIcon={true} description={errors.map((x: any) => <>{x} <br /></>)} />;
    }
  }

  return (
    <Space direction='vertical'>
      {renderModal()}
      {renderErrors()}
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => SecondaryCipNumberApiService.getSecondaryCipNumberTableData(requestState, props.academicYear)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'secondary_cip_list' }} />
    </ Space>
  );
}

export default SecondaryCipNumbersDataTable;
