// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionDTO from './InstitutionDTO';
import CipNumberDTO from './CipNumberDTO';
import AwardTypeDTO from './AwardTypeDTO';
import IowaVariationDTO from './IowaVariationDTO';
import NoticeOfIntentChangeRequestPlannedAwardTypeDTO from './NoticeOfIntentChangeRequestPlannedAwardTypeDTO';
import NoticeOfIntentChangeRequestContactDTO from './NoticeOfIntentChangeRequestContactDTO';
import NoticeOfIntentChangeRequestConsultantMeetingDTO from './NoticeOfIntentChangeRequestConsultantMeetingDTO';
import NoticeOfIntentChangeRequestOccupationDTO from './NoticeOfIntentChangeRequestOccupationDTO';
import NoticeOfIntentChangeRequestIndustryResourceDTO from './NoticeOfIntentChangeRequestIndustryResourceDTO';
import NoticeOfIntentChangeRequestCompleterDataDTO from './NoticeOfIntentChangeRequestCompleterDataDTO';
import NoticeOfIntentChangeRequestInstitutionLinkageDTO from './NoticeOfIntentChangeRequestInstitutionLinkageDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoticeOfIntentChangeRequestDTO extends EntitySelectorDTO { 
	id: string | null;
	changeRequestId: string | null;
	institutionId: string | null;
	institution: InstitutionDTO | null;
	cipNumberId: string | null | null;
	cipNumber: CipNumberDTO | null;
	previousCipNumberId: string | null | null;
	previousCipNumber: CipNumberDTO | null;
	primrayAwardTypeId: number | null | null;
	primrayAwardType: AwardTypeDTO | null;
	iowaVariationId: number | null | null;
	iowaVariation: IowaVariationDTO | null;
	cipReclassification: boolean | null | null;
	isTransferMajorProgram: boolean | null | null;
	needForProgram: string | null;
	anticipatedAnnualEnrollment: number | null | null;
	anticipatedAnnualCompleters: number | null | null;
	fiveYearAnticipatedEnrollment: number | null | null;
	fiveYearAnticipatedCompleters: number | null | null;
	requiresWorkExperiences: boolean | null | null;
	requiredWorkExperiences: string | null;
	secondaryPathways: string | null;
	postSecondaryPathways: string | null;
	uniqueProgramFeatures: string | null;
	sharedProgramEntities: string | null;
	additionalLaborMarketInformation: string | null;
	externalAccreditationRequirements: boolean | null | null;
	externalAccreditationRequirementDetails: string | null;
	collaborationEfforts: string | null;
	statePriority: string | null;
	programDuplicationAdditionalComments: string | null;
	institutionSimilarOfferings: string | null;
	institutionProgramsAtNewLocation: string | null;
	plannedAwardTypes: NoticeOfIntentChangeRequestPlannedAwardTypeDTO[] | null;
	contacts: NoticeOfIntentChangeRequestContactDTO[] | null;
	consultantMeetings: NoticeOfIntentChangeRequestConsultantMeetingDTO[] | null;
	occupations: NoticeOfIntentChangeRequestOccupationDTO[] | null;
	industryResources: NoticeOfIntentChangeRequestIndustryResourceDTO[] | null;
	noticeOfIntentCompleterData: NoticeOfIntentChangeRequestCompleterDataDTO[] | null;
	externalInstitutionLinkages: NoticeOfIntentChangeRequestInstitutionLinkageDTO[] | null;
}

const NoticeOfIntentChangeRequestDTO = {
    className: 'NoticeOfIntentChangeRequestDTO',
    create: (initValues?: Partial<NoticeOfIntentChangeRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			changeRequestId: '00000000-0000-0000-0000-000000000000',
			institutionId: '00000000-0000-0000-0000-000000000000',
			institution: null,
			cipNumberId: null,
			cipNumber: null,
			previousCipNumberId: null,
			previousCipNumber: null,
			primrayAwardTypeId: null,
			primrayAwardType: null,
			iowaVariationId: null,
			iowaVariation: null,
			cipReclassification: null,
			isTransferMajorProgram: null,
			needForProgram: "",
			anticipatedAnnualEnrollment: null,
			anticipatedAnnualCompleters: null,
			fiveYearAnticipatedEnrollment: null,
			fiveYearAnticipatedCompleters: null,
			requiresWorkExperiences: null,
			requiredWorkExperiences: "",
			secondaryPathways: "",
			postSecondaryPathways: "",
			uniqueProgramFeatures: "",
			sharedProgramEntities: "",
			additionalLaborMarketInformation: "",
			externalAccreditationRequirements: null,
			externalAccreditationRequirementDetails: "",
			collaborationEfforts: "",
			statePriority: "",
			programDuplicationAdditionalComments: "",
			institutionSimilarOfferings: "",
			institutionProgramsAtNewLocation: "",
			plannedAwardTypes: [],
			contacts: [],
			consultantMeetings: [],
			occupations: [],
			industryResources: [],
			noticeOfIntentCompleterData: [],
			externalInstitutionLinkages: [],
        },
        initValues);
    },
	id: 'id',
	changeRequestId: 'changeRequestId',
	institutionId: 'institutionId',
	institution: 'institution',
	cipNumberId: 'cipNumberId',
	cipNumber: 'cipNumber',
	previousCipNumberId: 'previousCipNumberId',
	previousCipNumber: 'previousCipNumber',
	primrayAwardTypeId: 'primrayAwardTypeId',
	primrayAwardType: 'primrayAwardType',
	iowaVariationId: 'iowaVariationId',
	iowaVariation: 'iowaVariation',
	cipReclassification: 'cipReclassification',
	isTransferMajorProgram: 'isTransferMajorProgram',
	needForProgram: 'needForProgram',
	anticipatedAnnualEnrollment: 'anticipatedAnnualEnrollment',
	anticipatedAnnualCompleters: 'anticipatedAnnualCompleters',
	fiveYearAnticipatedEnrollment: 'fiveYearAnticipatedEnrollment',
	fiveYearAnticipatedCompleters: 'fiveYearAnticipatedCompleters',
	requiresWorkExperiences: 'requiresWorkExperiences',
	requiredWorkExperiences: 'requiredWorkExperiences',
	secondaryPathways: 'secondaryPathways',
	postSecondaryPathways: 'postSecondaryPathways',
	uniqueProgramFeatures: 'uniqueProgramFeatures',
	sharedProgramEntities: 'sharedProgramEntities',
	additionalLaborMarketInformation: 'additionalLaborMarketInformation',
	externalAccreditationRequirements: 'externalAccreditationRequirements',
	externalAccreditationRequirementDetails: 'externalAccreditationRequirementDetails',
	collaborationEfforts: 'collaborationEfforts',
	statePriority: 'statePriority',
	programDuplicationAdditionalComments: 'programDuplicationAdditionalComments',
	institutionSimilarOfferings: 'institutionSimilarOfferings',
	institutionProgramsAtNewLocation: 'institutionProgramsAtNewLocation',
	plannedAwardTypes: 'plannedAwardTypes',
	contacts: 'contacts',
	consultantMeetings: 'consultantMeetings',
	occupations: 'occupations',
	industryResources: 'industryResources',
	noticeOfIntentCompleterData: 'noticeOfIntentCompleterData',
	externalInstitutionLinkages: 'externalInstitutionLinkages',
};

export default NoticeOfIntentChangeRequestDTO;