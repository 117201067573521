// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionCourseBlockDetailDTO from './InstitutionCourseBlockDetailDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionCourseBlockDTO extends EntitySelectorDTO { 
	id: string | null;
	institutionCourseBlockDetails: InstitutionCourseBlockDetailDTO[] | null;
	institutionId: string | null;
	currentDetail: InstitutionCourseBlockDetailDTO | null;
}

const InstitutionCourseBlockDTO = {
    className: 'InstitutionCourseBlockDTO',
    create: (initValues?: Partial<InstitutionCourseBlockDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			institutionCourseBlockDetails: [],
			institutionId: '00000000-0000-0000-0000-000000000000',
			currentDetail: null,
        },
        initValues);
    },
	id: 'id',
	institutionCourseBlockDetails: 'institutionCourseBlockDetails',
	institutionId: 'institutionId',
	currentDetail: 'currentDetail',
};

export default InstitutionCourseBlockDTO;