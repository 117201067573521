import { Alert, DatePicker, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import TermsApiService from '../../../api/TermsApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetTermDetailsHandler from '../../../handlerModels/GetTermDetailsHandler';
import * as SaveTermDetailsHandler from '../../../handlerModels/SaveTermDetailsHandler';
import TermDTO from '../../../models/TermDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface TermDetailsFormState extends BaseFormState {
  termId: string;
  term: TermDTO;
}

interface TermDetailsFormProps extends BaseFormProps {
  termId: string;
  onSave?: (termId: string) => void;
}

class TermDetailsForm extends React.Component<TermDetailsFormProps, TermDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(TermDTO.name, {
      required: true,
      name: TermDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(TermDTO.startDate, {
      required: true,
      name: TermDTO.startDate,
      label: 'Start Date',
      rules: [
        ValidationRuleUtil.required(),
      ],
    });

  constructor(props: TermDetailsFormProps) {
    super(props);

    this.state = {
      termId: Guid.Empty(),
      term: TermDTO.create({
        startDate: ''
      }),
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadTerm();
  }

  componentDidUpdate(prevProps: TermDetailsFormProps) {
    if (this.props.termId && this.props.termId != prevProps.termId) {
      this.setState({ termId: this.props.termId });
      this.loadTerm();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadTerm() {
    if (!this.state.loading) {
      if (this.props.termId == Guid.Empty()) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  private cleanResultDates = (term: TermDTO) => {
    if (term != null) {
      term.startDate = moment(term.startDate)
    }

    return term;
  }

  private loadExisting = () => {
    if (this.props.termId) {
      TermsApiService.getTermDetails(this.props.termId)
        .then((results: GetTermDetailsHandler.Result) => {
          if (results && results.term) {
            results.term = this.cleanResultDates(results.term);

            this.setState({
              termId: results.term?.id ?? Guid.Empty(),
              term: results.term ?? TermDTO.create()
            });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveTermDetailsHandler.Request.create({
      termId: this.state.termId,
      term: TermDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    TermsApiService.saveTermDetails(request)
      .then((result: SaveTermDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded && result.term) {
          result.term = this.cleanResultDates(result.term);
          this.setState({
            termId: result.term.id ?? Guid.Empty(),
            term: result.term ?? TermDTO.create()
          });

          message.success('Saved');

          if (this.props.onSave && result.term.id) {
            this.props.onSave(result.term.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(TermDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Error Saving');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.term}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(TermDTO.name)}
            {...ValidationUtil.getValidation(TermDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(TermDTO.startDate)}
            {...ValidationUtil.getValidation(TermDTO.startDate, this.state.fieldErrors, this.state.submitted)}>
            <DatePicker format='M/D/YYYY' disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space >
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TERM]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default TermDetailsForm;
