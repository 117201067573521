export default class StringUtil {
  static shorten(value: string | null, length: number, elipsis: boolean) {
    if (value && value?.length <= length) {
      return value;
    }
    else {
      return value?.substring(0, length - (elipsis ? 4 : 1)) + (elipsis ? '...' : '');
    }
  }

  public static ignoreCaseComapre(stringValue1: string, stringValue2: string) {
    return stringValue1.toUpperCase().indexOf(stringValue2.toUpperCase()) !== -1
  }

  public static newLineFormat(values: (string | null | undefined)[], includeNewLine: boolean) {
    if (includeNewLine) {
      return values.join(',\n')
    }
    else {
      return values.join(',')
    }
  }

  public static ellipsisCharacterLimit(values: string, characterLimit: number) {
    if (values && values.length >= characterLimit) {
      return values.substring(0, characterLimit) + '...'
    }

    return values;
  }

  public static newLineCharacterLimit(values: string, characterLimit: number) {
    if (values && values.length >= characterLimit) {
      let string = '';
      for (let i = 0; i < values.length; i += characterLimit) {
        string += values.substring(i, i + characterLimit) + '\n';
      }
      return string;
    }

    return values;
  }
}