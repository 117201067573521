// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import APSGeneralEducationDTO from '../models/APSGeneralEducationDTO';
import * as GetAPSGeneralEducationDetailsHandler from '../handlerModels/GetAPSGeneralEducationDetailsHandler';
import * as SaveAPSGeneralEducationDetailsHandler from '../handlerModels/SaveAPSGeneralEducationDetailsHandler';
import * as DeleteAPSGeneralEducationHandler from '../handlerModels/DeleteAPSGeneralEducationHandler';

export class APSGeneralEducationApiService extends BaseApi {

    // post: api/apsGeneralEducation
    public getAPSGeneralEducationsDataTable(tableRequest: TableRequestDTO): Promise<TableResponseDTO<APSGeneralEducationDTO>> {
        let url = `api/apsGeneralEducation`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<APSGeneralEducationDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/apsGeneralEducation/export
    public getAPSGeneralEducationDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/apsGeneralEducation/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/apsGeneralEducation/${id}
    public getAPSGeneralEducationDetails(id: number): Promise<GetAPSGeneralEducationDetailsHandler.Result> {
        let url = `api/apsGeneralEducation/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAPSGeneralEducationDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/apsGeneralEducation/save
    public saveAPSGeneralEducationDetails(saveAPSGeneralEducationDetails: SaveAPSGeneralEducationDetailsHandler.Request): Promise<SaveAPSGeneralEducationDetailsHandler.Result> {
        let url = `api/apsGeneralEducation/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveAPSGeneralEducationDetailsHandler.Result>(saveAPSGeneralEducationDetails, 'post', url, true, false);
    }

    // post: api/apsGeneralEducation/deleteAPSGeneralEducation
    public deleteAPSGeneralEducation(deleteAPSGeneralEducation: DeleteAPSGeneralEducationHandler.Request): Promise<DeleteAPSGeneralEducationHandler.Result> {
        let url = `api/apsGeneralEducation/deleteAPSGeneralEducation`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteAPSGeneralEducationHandler.Result>(deleteAPSGeneralEducation, 'post', url, true, false);
    }
}
var service = new APSGeneralEducationApiService();
export default service;
