import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, FormItemProps, Input, message, Modal, Space } from 'antd';
import * as React from 'react';
import ChangeRequestApiService from '../../../api/ChangeRequestApiService';
import * as AddChangeRequestCommentHandler from '../../../handlerModels/AddChangeRequestCommentHandler';
import * as ApproveChangeRequestHandler from '../../../handlerModels/ApproveChangeRequestHandler';
import * as DenyChangeRequestHandler from '../../../handlerModels/DenyChangeRequestHandler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import UserSecurity from '../../../models/UserSecurity';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';

interface ChangeRequestApprovalReviewFormState extends BaseFormState {
}

interface ChangeRequestApprovalReviewFormProps extends BaseFormProps {
  currentUser?: UserSecurity | null;
  changeRequest: ChangeRequestDTO;
  approvalId?: string;
  approvalStatusTypeId?: number;
  onSubmit?: () => void;
}

class ChangeRequestApprovalReviewForm extends React.Component<ChangeRequestApprovalReviewFormProps, ChangeRequestApprovalReviewFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(AddChangeRequestCommentHandler.Request.comment, {
        label: 'If you any additional comments please enter them before approving or rejecting.',
        name: AddChangeRequestCommentHandler.Request.comment,
      });
  }

  constructor(props: ChangeRequestApprovalReviewFormProps) {
    super(props);

    this.state = {
      submitting: false,
    };
  }

  private confirmApprove = () => {
    Modal.confirm({
      title: 'Approve Change Request?',
      type: 'error',
      content: 'Are you sure you wish to approve this change request?',
      okText: 'Approve Change Request',
      cancelText: 'Cancel',
      onOk: () => this.handleApprove(),
      okButtonProps: { icon: <CheckOutlined /> }
    });
  }

  private confirmDeny = () => {
    Modal.confirm({
      title: 'Reject Change Request?',
      content: 'Are you sure you wish to reject this change request? It will be sent back to the submitting user.',
      okText: 'Reject Change Request',
      cancelText: 'Cancel',
      onOk: () => this.handleDeny(),
      okButtonProps: { danger: true, icon: <CloseOutlined /> }
    });
  }

  private handleDeny = () => {
    this.setState({ submitting: true });

    const request = DenyChangeRequestHandler.Request.create({
      comment: this._formRef.current?.getFieldValue(DenyChangeRequestHandler.Request.comment) ?? '',
      ChangeRequestApprovalId: this.props.approvalId ?? Guid.Empty()
    })

    ChangeRequestApiService.deny(request)
      .then((result: DenyChangeRequestHandler.Result | null) => {
        if (result?.succeeded) {
          this._formRef.current?.resetFields();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        } else {
          message.error('Error');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error');
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  private handleApprove = () => {
    this.setState({ submitting: true });

    const request = ApproveChangeRequestHandler.Request.create({
      comment: this._formRef.current?.getFieldValue(ApproveChangeRequestHandler.Request.comment) ?? '',
      changeRequestApprovalId: this.props.approvalId ?? Guid.Empty()
    })

    ChangeRequestApiService.approve(request)
      .then((result: ApproveChangeRequestHandler.Result | null) => {
        if (result?.succeeded) {
          this._formRef.current?.resetFields();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        } else {
          message.error('Error');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error');
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  private handleComment = () => {
    this.setState({ submitting: true });

    const request = AddChangeRequestCommentHandler.Request.create({
      comment: this._formRef.current?.getFieldValue(AddChangeRequestCommentHandler.Request.comment) ?? '',
      changeRequestId: this.props.changeRequest.id
    })

    ChangeRequestApiService.addComment(request)
      .then((result: AddChangeRequestCommentHandler.Result | null) => {
        if (!result?.succeeded) {
          this._formRef.current?.resetFields();

          message.error('Error');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error');
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <Space direction="vertical">
        {this.renderAddComment()}
      </Space>
    );
  }

  renderAddComment() {
    const formItems = this.getFormItems();
    return (
      <Form
        ref={this._formRef}
        layout="vertical"
        onFinish={this.handleComment}
        requiredMark={true}>
        <Form.Item {...formItems.get(AddChangeRequestCommentHandler.Request.comment)}>
          <Input.TextArea />
        </Form.Item>

        {this.renderActions()}
      </Form >
    );
  }

  renderActions() {
    if (this.props.approvalId) {
      return (
        <Space direction='horizontal' wrap={true}>
          <Button type='primary' onClick={this.confirmDeny} danger={true} loading={this.state.submitting} icon={<CloseOutlined />} >Reject</Button>
          <Button type='primary' onClick={this.confirmApprove} loading={this.state.submitting} icon={<CheckOutlined />}>Approve</Button>
        </Space>
      );
    }
  }
}

export default ChangeRequestApprovalReviewForm;