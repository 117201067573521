import { Typography } from 'antd';
import * as React from 'react';

interface SelectLabelOption {
  value: any;
  label: any;
}

interface SelectLabelState {
  value?: any;
  options?: SelectLabelOption[]
}

interface SelectLabelProps {
  value?: any;
  options?: SelectLabelOption[]
  onChange?: (value: boolean) => void;
}

class SelectLabel extends React.Component<SelectLabelProps, SelectLabelState> {
  constructor(props: SelectLabelProps) {
    super(props);

    this.state = {
      value: null,
      options: []
    }
  }

  componentDidMount() {
    if (this.props.value != undefined) {
      this.setState({ value: this.props.value });
    }

    if (this.props.options != undefined) {
      this.setState({ options: this.props.options });
    }
  }

  componentDidUpdate(prevProps: SelectLabelProps) {
    if (this.props.value != undefined && this.props.value != prevProps.value) {
      this.setState({ value: this.props.value });
    }

    if (this.props.options != undefined && this.props.options != prevProps.options) {
      this.setState({ options: this.props.options });
    }
  }

  render() {
    if (this.state.options && this.state.value) {
      const value = this.state.options?.filter(x => x.value == this.state.value)[0]?.label ?? '...';

      return (
        <Typography.Text>{value}</Typography.Text>
      );
    }

    return (
      <Typography.Text>...</Typography.Text>
    );
  }
}

export default SelectLabel;
