import { Alert, AutoComplete, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import ResourceApiService from '../../../api/ResourceApiService';
import FileUpload, { FileUploadValue } from '../../FileUpload';
import Routes from '../../../config/Routes';
import * as SaveResourceFileDetailHandler from '../../../handlerModels/SaveResourceFileDetailHandler';
import * as SearchForResourceCategoryHandler from '../../../handlerModels/SearchForResourceCategoryHandler';
import ResourceCategoryDTO from '../../../models/ResourceCategoryDTO';
import ResourceFileDTO from '../../../models/ResourceFileDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FileUploadUtil from '../../../utils/FileUploadUtil';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';

interface AddResourceFileFormState extends BaseFormState {
  categories: string[];
  additionalResources: FileUploadValue;
  resourceFile: ResourceFileDTO;
}

interface AddResourceFileFormProps extends BaseFormProps {
  onSuccess?: () => void;
  resourceFile: ResourceFileDTO;
}

class AddResourceFileForm extends React.Component<AddResourceFileFormProps, AddResourceFileFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(SaveResourceFileDetailHandler.Request.title, {
      required: true,
      name: SaveResourceFileDetailHandler.Request.title,
      label: 'Title',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(ResourceFileDTO.azureBlobFile, {
      required: true,
      name: ResourceFileDTO.azureBlobFile,
      label: 'File',
    })
    .set(SaveResourceFileDetailHandler.Request.category, {
      name: SaveResourceFileDetailHandler.Request.category,
      label: 'Category'
    })
    .set(SaveResourceFileDetailHandler.Request.description, {
      name: SaveResourceFileDetailHandler.Request.description,
      label: 'Description'
    });


  constructor(props: AddResourceFileFormProps) {
    super(props);

    this.state = {
      categories: [],
      additionalResources: {} as FileUploadValue,
      resourceFile: ResourceFileDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ resourceFile: this.props.resourceFile }, () => this.reset());
  }

  componentDidUpdate(prevProps: AddResourceFileFormProps) {
    if (this.props.resourceFile && this.props.resourceFile != prevProps.resourceFile) {
      this.setState({ resourceFile: this.props.resourceFile }, () => this.reset());
    }
  }

  public submit = () => {
    this.handleSubmit();
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private searchForResourceCategory = (searchText: string) => {
    ResourceApiService.searchForResourceCategory(searchText)
      .then((results: SearchForResourceCategoryHandler.Result) => {
        if (results.categories) {
          this.setState({ categories: results.categories })
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private handleSearch = (value: any) => {
    value.length >= 2 ? this.searchForResourceCategory(value) : this.setState({ categories: [] });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    if (!FormUtil.canSubmit(this._formRef, this._formItems)) {
      message.error('Please fill out all required fields.');
      this.setState({ loading: false, submitting: false });
      return;
    }

    const model = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    model.resourceFileId = this.props.resourceFile.id;
    model.removedFiles = model.azureBlobFile?.removedFiles;

    const request = FileUploadUtil.attachFilesToModel('saveResourceFileDetail', model, SaveResourceFileDetailHandler.Request.additionalFiles, model.azureBlobFile?.newFiles);

    ResourceApiService.saveResourceFile(request)
      .then((result: SaveResourceFileDetailHandler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSuccess) {
            LookupsUtil.invalidateCache(ResourceCategoryDTO.className);
            this.props.onSuccess();
            this.reset();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Resource could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Resource could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const autoCompleteOptions: any[] = this.state.categories.map(x => {
      return { value: x, key: x }
    });

    const existingFiles = [];
    existingFiles.push({
      uid: this.state.resourceFile.azureBlobFileId,
      url: Routes.generateDownload(this.state.resourceFile.azureBlobFileId ?? Guid.Empty()),
      name: this.state.resourceFile.azureBlobFile?.fileName
    });

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          initialValues={this.state.resourceFile}
          layout="vertical"
          onValuesChange={this.handleChange}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(SaveResourceFileDetailHandler.Request.title)}
            {...ValidationUtil.getValidation(SaveResourceFileDetailHandler.Request.title, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(ResourceFileDTO.azureBlobFile)}
            {...ValidationUtil.getValidation(ResourceFileDTO.azureBlobFile, this.state.fieldErrors, this.state.submitted)}>
            <FileUpload existingList={existingFiles} maxCount={1} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SaveResourceFileDetailHandler.Request.category)}
            initialValue={this.state.resourceFile.categoryTitle}
            {...ValidationUtil.getValidation(SaveResourceFileDetailHandler.Request.category, this.state.fieldErrors, this.state.submitted)}>
            <AutoComplete
              options={autoCompleteOptions}
              onSearch={this.handleSearch}>
              <Input placeholder="Category" />
            </AutoComplete>
          </FormItem>

          <FormItem
            {...this._formItems.get(SaveResourceFileDetailHandler.Request.description)}
            {...ValidationUtil.getValidation(SaveResourceFileDetailHandler.Request.description, this.state.fieldErrors, this.state.submitted)}>
            <Input.TextArea maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

        </Form >
      </Space >
    );
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AddResourceFileForm;
