import { RightOutlined } from '@ant-design/icons';
import { Button, Modal, Skeleton, Space, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import AcademicYearDashboardSteps from '../../displays/secondary/AcademicYearDashboardSteps';
import AcademicYearForm from './AcademicYearForm';

interface AcademicYearOverviewState extends BaseFormState {
  openYear: number | null;
  academicYear: AcademicYearDTO[];
}

interface AcademicYearOverviewProps extends BaseFormProps {
  institution: InstitutionDTO | null;
}

class AcademicYearOverview extends React.Component<AcademicYearOverviewProps, AcademicYearOverviewState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _academicYearFormRef = React.createRef<AcademicYearForm>();

  constructor(props: AcademicYearOverviewProps) {
    super(props);

    this.state = {
      academicYear: [],
      openYear: null
    };
  }

  componentDidMount() {
    this.loadAcademicYear();
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadAcademicYear = () => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
      numberOfAcademicYears: 20
    });

    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results) {
          this.setState({
            academicYear: results.academicYears ?? []
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private onAcademicYearSave = (academicYear: number) => {
    HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: academicYear }));
  }

  private openModal = (academicYear: number) => {
    this.setState({ openYear: academicYear })
  }

  private goToAcademicSelfStudyDashboard = (academicYear: number, isNew?: boolean) => {
    if (isNew) {
      this.openModal(academicYear);
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: academicYear }));
    }
  }

  render() {
    if (this.state.loading || this.state.loading == undefined) {
      return <Skeleton active={true} />;
    }

    return (
      <Space direction="horizontal" size={'large'} wrap={true}>
        {this.renderAcademicYears()}
        {this.renderNextAcademicYear()}
        {this.renderOpenModal()}
      </Space>
    );
  }

  renderOpenModal() {
    if (this.state.openYear) {
      return (
        <Modal title={'Open ' + this.state.openYear + '-' + (this.state.openYear + 1) + ' Program Review Year'}
          visible={true}
          onOk={() => this._academicYearFormRef.current?.onSubmit()}
          onCancel={() => this.setState({ openYear: null })}
          okText="Open Program Review Year"
          width={500}>
          <AcademicYearForm ref={this._academicYearFormRef} academicYearId={this.state.openYear} isNew={true} onAcademicYearSave={this.onAcademicYearSave} />
        </Modal >
      )
    }
  }

  renderAcademicYears() {
    return this.state.academicYear.map(x => this.renderAcademicYear(x));
  }

  renderAcademicYear = (academicYear: AcademicYearDTO) => {
    return (
      <Space className='academic-year-overview' direction='vertical'>
        <Typography.Title level={4}>{academicYear.id + ' - ' + (academicYear.id + 1)}</Typography.Title>
        <AcademicYearDashboardSteps academicYear={academicYear} compactView={true}></AcademicYearDashboardSteps>
        <Button onClick={() => this.goToAcademicSelfStudyDashboard(academicYear.id)}><RightOutlined />
          Go To Dashboard
        </Button>
      </Space>
    );
  }

  renderNextAcademicYear() {
    const lastOpenedAcademicYear = this.state.academicYear[this.state.academicYear.length - 1] ?? AcademicYearDTO.create({ id: (DateTimeUtil.getCurrentYear()) });
    if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
      return (
        <Space className='academic-year-overview' direction='vertical' >
          <Typography.Title level={4}>{(lastOpenedAcademicYear.id + 1) + ' - ' + (lastOpenedAcademicYear.id + 2)}</Typography.Title>
          <Typography.Paragraph>This program review year has not been opened.</Typography.Paragraph>
          <Button type='primary' onClick={() => this.goToAcademicSelfStudyDashboard(lastOpenedAcademicYear.id + 1, true)} icon={<RightOutlined />}>
            Open Program Review Year
          </Button>
        </Space>
      );
    }
  }
}

export default AcademicYearOverview;
