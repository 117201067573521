import { Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearForm from '../../../components/forms/secondary/AcademicYearForm';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import InstitutionDTO from '../../../models/InstitutionDTO';
import AcademicYearPortal from '../../../portals/secondary/AcademicYearPortal';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface NewAcademicYearPageProps {
  institution: InstitutionDTO | null;
}

interface NewAcademicYearPageState {
  loading: boolean;
  academicYearId: number;
}

class NewAcademicYearPage extends React.Component<NewAcademicYearPageProps & RouteComponentProps<RouteObject>, NewAcademicYearPageState> {
  constructor(props: NewAcademicYearPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYearId: 0
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));
    if (this.state.academicYearId != id) {
      this.setState({ academicYearId: id });
    }
  }

  goToNextStep = (fromAcademicYearList: boolean) => {
    HistoryUtil.push(Routes.generate(Routes.REVIEW_AREA_EDUCATION_AGENCY, { academicYearId: this.state.academicYearId }, { fromAcademicYearList: fromAcademicYearList, reviewAcademicYear: true }))
  }

  render() {
    let fromAcademicYearList = false;
    if (this.props.match.path.split('/').findIndex(x => x == 'academicYear-list') > -1) {
      fromAcademicYearList = true;
    }

    return (
      <Content >
        <HeaderPortal title="Program Review Year"
          breadcrumbs={Breadcrumbs.academicYearDashboard(this.state.academicYearId)} />

        <Space direction='vertical' size='large'>
          <div id="academicYearCardPortal" />
          <AcademicYearPortal currentStep={0} title={'Opening ' + this.state.academicYearId + '-' + (this.state.academicYearId + 1) + ' Program Review Year'} >
            <AcademicYearForm fromAcademicYearList={fromAcademicYearList} academicYearId={this.state.academicYearId} onSubmit={this.goToNextStep} />
          </AcademicYearPortal>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(NewAcademicYearPage));
