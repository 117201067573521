import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import * as React from 'react';
import TransferMajorDisciplineFrameworkElementCourseApiService from '../../../api/TransferMajorDisciplineFrameworkElementCourseApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as AddTransferMajorDisciplineFrameworkElementCourseHandler from '../../../handlerModels/AddTransferMajorDisciplineFrameworkElementCourseHandler';
import * as DeleteTransferMajorDisciplineFrameworkElementCourseHandler from '../../../handlerModels/DeleteTransferMajorDisciplineFrameworkElementCourseHandler';
import CourseDetailDTO from '../../../models/CourseDetailDTO';
import CourseDTO from '../../../models/CourseDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TransferMajorDisciplineFrameworkElementCourseDataTableDTO from '../../../models/TransferMajorDisciplineFrameworkElementCourseDataTableDTO';
import TransferMajorDisciplineFrameworkElementCourseDTO from '../../../models/TransferMajorDisciplineFrameworkElementCourseDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import Guid from '../../../utils/Guid';
import CourseSearch from '../../postSecondary/CourseSearch';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface TransferMajorDisciplineFrameworkElementCourseDataTableProps extends BaseFormProps {
  transferMajorDisciplineFrameworkElementId?: number;
}

interface TransferMajorDisciplineFrameworkElementCourseDataTableState extends BaseDataTableState<TransferMajorDisciplineFrameworkElementCourseDTO>, BaseFormState {
  showAddCourseModal: boolean;
}

class TransferMajorDisciplineFrameworkElementCourseDataTable extends React.Component<TransferMajorDisciplineFrameworkElementCourseDataTableProps, TransferMajorDisciplineFrameworkElementCourseDataTableState> {
  private _dataTable: DataTable<TransferMajorDisciplineFrameworkElementCourseDTO> | undefined;
  private readonly _courseSearchRef = React.createRef<CourseSearch>();

  constructor(props: TransferMajorDisciplineFrameworkElementCourseDataTableProps) {
    super(props);
    this.state = {
      showAddCourseModal: false
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Discipline',
        dataIndex: CourseDTO.disciplineId,
        sorter: true,
        filterType: FilterType.Text,
        render: (data: string, row: TransferMajorDisciplineFrameworkElementCourseDataTableDTO) => {
          return row.discipline
        }
      },
      {
        title: 'Catalog Number',
        dataIndex: CourseDTO.catalogNumber,
        sorter: true,
        filterType: FilterType.Text,
        render: (data: string, row: TransferMajorDisciplineFrameworkElementCourseDataTableDTO) => {
          return row.catalogNumber
        }
      },
      {
        title: 'Title',
        dataIndex: CourseDetailDTO.title,
        sorter: true,
        filterType: FilterType.Text,
        render: (data: string, row: TransferMajorDisciplineFrameworkElementCourseDataTableDTO) => {
          return row.title
        }
      },
      {
        title: 'Actions',
        align: 'center',
        dataIndex: TransferMajorDisciplineFrameworkElementCourseDTO.className,
        sorter: false,
        render: (data: string, row: TransferMajorDisciplineFrameworkElementCourseDataTableDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS])) {
            return <>
              <Button type="link" onClick={() => this.promptConfirmRemove(row.id ?? Guid.Empty())}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 50
      },
    ] as DataTableColumnProps<any>[];
  };

  private submitAddCourse = () => {
    const value = this._courseSearchRef.current?.getValue();
    this.setState({ submitting: true });
    const request = AddTransferMajorDisciplineFrameworkElementCourseHandler.Request.create({
      courseId: value?.courseId,
      transferMajorDisciplineFrameworkId: this.props.transferMajorDisciplineFrameworkElementId,
    });

    TransferMajorDisciplineFrameworkElementCourseApiService.addTransferMajorDisciplineFrameworkElementCourse(request)
      .then((result: AddTransferMajorDisciplineFrameworkElementCourseHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        this._dataTable?.refresh();
        this._courseSearchRef.current?.reset()

        if (result?.succeeded === true) {
          this.setState({
            showAddCourseModal: false
          });
          message.success('Course has been added');
        }
        else {
          message.error('Course is a duplicate');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Course could not be added');
      })
      .finally(() => {
        this.setState({ loading: false });
      });

  }

  private promptConfirmRemove = (transferMajorDisciplineFrameworkElementCourseId: string | null) => {
    if (transferMajorDisciplineFrameworkElementCourseId == null) {
      return;
    }

    Modal.confirm({
      title: 'Are you sure you want to delete this Course?',
      okText: 'Delete',
      onOk: () => this.deleteTransferMajorDisciplineFrameworkElementCourseId(transferMajorDisciplineFrameworkElementCourseId),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  private addCourse = () => {
    this.setState({ showAddCourseModal: true });
  }

  private cancelAddCourse = () => {
    this.setState({ showAddCourseModal: false });
    this._dataTable?.refresh();
  }


  private deleteTransferMajorDisciplineFrameworkElementCourseId = (transferMajorDisciplineFrameworkElementCourseId: string) => {
    const request = DeleteTransferMajorDisciplineFrameworkElementCourseHandler.Request.create({
      transferMajorDisciplineFrameworkElementCourseId: transferMajorDisciplineFrameworkElementCourseId
    });

    TransferMajorDisciplineFrameworkElementCourseApiService.deleteTransferMajorDisciplineFrameworkElementCourse(request)
      .then((result: DeleteTransferMajorDisciplineFrameworkElementCourseHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('Transfer Major Discipline Framework Element Course was removed.');
          this._dataTable?.refresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }
      })
      .catch(() => {
        message.error('Transfer Major Discipline Framework Element Course could not be removed.');
      });
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return TransferMajorDisciplineFrameworkElementCourseApiService.getTransferMajorDisciplineFrameworkElementCourseDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Transfer_Major_Discipline_Element_Course_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS])) {
      actionButtons.push(DataTableButtonUtil.Default('Add Course', () => this.addCourse()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => TransferMajorDisciplineFrameworkElementCourseApiService.getTransferMajorDisciplineFrameworkElementCoursesDataTable(requestState, this.props.transferMajorDisciplineFrameworkElementId ?? 0)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'transferMajorDisciplineFrameworkElementCourse_list' }} />
        {this.renderAddCourseModal()}
      </>
    );
  }

  renderAddCourseModal() {
    return (
      <Modal
        visible={this.state.showAddCourseModal}
        title='Add Course'
        okText='Add'
        width={1000}
        onOk={this.submitAddCourse}
        onCancel={this.cancelAddCourse}>
        <CourseSearch ref={this._courseSearchRef} value={{ courseId: null, course: null }} />
      </Modal>
    );
  }
}

export default TransferMajorDisciplineFrameworkElementCourseDataTable;
