// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import DocumentArchiveDTO from '../models/DocumentArchiveDTO';

export class DocumentArchiveApiService extends BaseApi {

    // post: api/documentArchive/getDocumentArchiveTableData
    public getDocumentArchiveTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<DocumentArchiveDTO>> {
        let url = `api/documentArchive/getDocumentArchiveTableData`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<DocumentArchiveDTO>>(tableRequest, 'post', url, true, false);
    }
}
var service = new DocumentArchiveApiService();
export default service;
