// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveObjectPurposeDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ObjectPurposeDTO from '../models/ObjectPurposeDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    objectPurposeId: number;
    objectPurpose: ObjectPurposeDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            objectPurposeId: 0,
            objectPurpose: null,
        },
        initValues);
    },
    objectPurposeId: 'objectPurposeId',
    objectPurpose: 'objectPurpose',
}

interface Result extends ResultDTO {
    objectPurpose: ObjectPurposeDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            objectPurpose: null,
        },
        initValues);
    },
    objectPurpose: 'objectPurpose',
}

export { Request, Result };