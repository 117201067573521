import FeatureFlag from '../consts/FeatureFlag';

class SectionsNames {
  public static DASHBOARD = 'dashboard';
  public static ANNOUNCEMENTS = 'announcements';
  public static SECONDARY_COURSES = 'secondaryCourses';
  public static CHANGE_REQUESTS = 'changeRequests';
  public static DISCIPLINES = 'disciplines';
  public static DISTRICTS = 'districts';
  public static CATEGORIES = 'categories';
  public static CREDIT_TYPES = 'crediTypes';
  public static COURSES = 'courses';
  public static CONTACTS = 'contacts';
  public static PROGRAMS = 'programs';
  public static INSTITUTIONS = 'institutions';
  public static USERS = 'users';
  public static DOCUMENTARCHIVE = 'documentArchive';
  public static CONSULTANTS = 'consultants';
  public static DOWNLOADS = 'downloads';
  public static RESOURCES = 'resources';
  public static CONFIGURATIONS = 'configuration';
  public static SECONDARY_PROGRAM = 'secondaryProgram';
  public static RPP = 'rpp';
  public static ROLES = 'roles'
  public static TERMS = 'terms'
  public static CIP_NUMBERS = 'cips';
  public static SPECIAL_EMPHASES = 'specialEmphasis';
  public static LOOKUP_CONFIGURATIONS = 'lookup_configuration';
  public static WORKFLOW_CONFIGURATIONS = 'workflows_configuration';
  public static WORK_ITEMS = 'work_items';
}

class SectionFeatureFlags {
  public static ANNOUNCEMENTS = [
    FeatureFlag.EDIT_ANNOUNCEMENT
  ];

  public static AWARD_TYPE = [
    FeatureFlag.EDIT_AWARD_TYPE,
    FeatureFlag.VIEW_AWARD_TYPE,
  ];

  public static APS_GENERAL_EDUCATION = [
    FeatureFlag.EDIT_APS_GENERAL_EDUCATION,
    FeatureFlag.VIEW_APS_GENERAL_EDUCATION,
  ];

  public static CHANGE_REQUESTS = [
    FeatureFlag.EDIT_CHANGE_REQUEST,
    FeatureFlag.VIEW_CHANGE_REQUEST,
  ];

  public static DISCIPLINES = [
    FeatureFlag.EDIT_DISCIPLINE,
    FeatureFlag.VIEW_DISCIPLINE,
  ];

  public static DOCUMENTARCHIVE = [
    FeatureFlag.VIEW_DOCUMENT_ARCHIVE,
  ];

  public static CATEGORIES = [
    FeatureFlag.EDIT_CATEGORY,
    FeatureFlag.VIEW_CATEGORY,
  ];

  public static CREDIT_TYPES = [
    FeatureFlag.EDIT_CREDIT_TYPE,
    FeatureFlag.VIEW_CREDIT_TYPE,
  ];

  public static COURSES = [
    FeatureFlag.EDIT_COURSE,
    FeatureFlag.VIEW_COURSE,
  ];

  public static CONTACT = [
    FeatureFlag.EDIT_CONTACT,
  ];

  public static PROGRAMS = [
    FeatureFlag.EDIT_PROGRAM,
    FeatureFlag.VIEW_PROGRAM,
  ];

  public static INSTITUTIONS = [
    FeatureFlag.EDIT_INSTITUTION,
    FeatureFlag.VIEW_INSTITUTION,
  ];

  public static EXTERNAL_INSTITUTIONS = [
    FeatureFlag.EDIT_EXTERNAL_INSTITUTIONS,
    FeatureFlag.VIEW_EXTERNAL_INSTITUTIONS,
  ];

  public static GENDERS = [
    FeatureFlag.EDIT_GENDER,
    FeatureFlag.VIEW_GENDER,
  ];

  public static GENERAL_EDUCATION = [
    FeatureFlag.EDIT_GENERAL_EDUCATION,
    FeatureFlag.VIEW_GENERAL_EDUCATION,
  ];

  public static USERS = [
    FeatureFlag.EDIT_USER,
    FeatureFlag.VIEW_USER,
    FeatureFlag.EDIT_USER_ROLE,
    FeatureFlag.VIEW_USER_ROLE,
    FeatureFlag.MANAGE_INSTITUTION_USER
  ];

  public static ROLES = [
    FeatureFlag.EDIT_ROLE,
    FeatureFlag.VIEW_ROLE,
  ];

  public static TERMS = [
    FeatureFlag.EDIT_TERM,
    FeatureFlag.VIEW_TERM,
  ];

  public static CIP_NUMBERS = [
    FeatureFlag.EDIT_CIP_NUMBER,
    FeatureFlag.VIEW_CIP_NUMBER,
  ];

  public static INSTRUCTIONAL_LEVEL = [
    FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL,
    FeatureFlag.VIEW_INSTRUCTIONAL_LEVEL,
  ];

  public static IOWA_VARIATION = [
    FeatureFlag.EDIT_IOWA_VARIATION,
    FeatureFlag.VIEW_IOWA_VARIATION,
  ];

  public static OBJECT_PURPOSE = [
    FeatureFlag.EDIT_OBJECT_PURPOSE,
    FeatureFlag.VIEW_OBJECT_PURPOSE,
  ];

  public static SPECIAL_EMPHASES = [
    FeatureFlag.EDIT_SPECIAL_EMPHASIS,
    FeatureFlag.VIEW_SPECIAL_EMPHASIS,
  ];

  public static TRANSFER_MAJOR_COURSE_BLOCKS = [
    FeatureFlag.EDIT_TRANSFER_MAJOR_COURSE_BLOCKS,
    FeatureFlag.VIEW_TRANSFER_MAJOR_COURSE_BLOCKS,
  ];

  public static TRANSFER_MAJOR_LACTS_CATEGORY = [
    FeatureFlag.EDIT_TRANSFER_MAJOR_LACTS_CATEGORY,
    FeatureFlag.VIEW_TRANSFER_MAJOR_LACTS_CATEGORY,
  ];

  public static TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS = [
    FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS,
    FeatureFlag.VIEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS,
  ];

  public static TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE = [
    FeatureFlag.EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE,
    FeatureFlag.VIEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE,
  ];

  public static TYPE_OF_PROGRAM = [
    FeatureFlag.EDIT_TYPE_OF_PROGRAM,
    FeatureFlag.VIEW_TYPE_OF_PROGRAM,
  ];

  // --- Secondary
  public static DISTRICT = [
    FeatureFlag.EDIT_DISTRICT,
    FeatureFlag.MANAGE_DISTRICT_CONTACT,
    FeatureFlag.MANAGE_DISTRICT_USERS
  ];

  public static RPP = [
    FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER,
    FeatureFlag.MANAGE_RPP_USERS,
  ];

  public static SECONDARY_PROGRAM = [
    FeatureFlag.EDIT_SECONDARY_PROGRAM,
  ];

  public static SECONDARY_COURSES = [
    FeatureFlag.EDIT_SECONDARY_COURSE,
  ];

  public static PROGRAM_REVIEW = [
    FeatureFlag.APPROVE_DISTRICT_RESPONSE,
    FeatureFlag.ASSIGN_DISTRICT_RESPONDER,
    FeatureFlag.ASSIGN_RPP_RESPONDER,
    FeatureFlag.ASSIGN_DE_CONSULTANT_RESPONDER,
    FeatureFlag.EDIT_PROGRAM_REVIEW,
    FeatureFlag.EDIT_DISTRICT_RESPONSE,
    FeatureFlag.EDIT_RPP_RESPONSE,
    FeatureFlag.EDIT_DE_RESPONSE,
    FeatureFlag.VIEW_CURRENT_PROGRAM_STATUS,
    FeatureFlag.VIEW_DISTRICT_RESPONSE,
    FeatureFlag.VIEW_RPP_RESPONSE,
    FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE
  ];


  public static CONFIGURATIONS =
    SectionFeatureFlags.CATEGORIES
      .concat(SectionFeatureFlags.AWARD_TYPE)
      .concat(SectionFeatureFlags.APS_GENERAL_EDUCATION)
      .concat(SectionFeatureFlags.CIP_NUMBERS)
      .concat(SectionFeatureFlags.COURSES)
      .concat(SectionFeatureFlags.EXTERNAL_INSTITUTIONS)
      .concat(SectionFeatureFlags.GENDERS)
      .concat(SectionFeatureFlags.GENERAL_EDUCATION)
      .concat(SectionFeatureFlags.CREDIT_TYPES)
      .concat(SectionFeatureFlags.DISCIPLINES)
      .concat(SectionFeatureFlags.OBJECT_PURPOSE)
      .concat(SectionFeatureFlags.ROLES)
      .concat(SectionFeatureFlags.INSTRUCTIONAL_LEVEL)
      .concat(SectionFeatureFlags.IOWA_VARIATION)
      .concat(SectionFeatureFlags.SPECIAL_EMPHASES)
      .concat(SectionFeatureFlags.TRANSFER_MAJOR_COURSE_BLOCKS)
      .concat(SectionFeatureFlags.TRANSFER_MAJOR_LACTS_CATEGORY)
      .concat(SectionFeatureFlags.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS)
      .concat(SectionFeatureFlags.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE)
      .concat(SectionFeatureFlags.TYPE_OF_PROGRAM)
      .concat(SectionFeatureFlags.TERMS);
}

export default { SectionsNames, SectionFeatureFlags };