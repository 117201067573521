// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// ExistingCourseModificationSearchHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    courseId: string | null;
    installTerm: string | null;
    installYear: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            courseId: '00000000-0000-0000-0000-000000000000',
            installTerm: '00000000-0000-0000-0000-000000000000',
            installYear: 0,
        },
        initValues);
    },
    courseId: 'courseId',
    installTerm: 'installTerm',
    installYear: 'installYear',
}

interface Result extends ResultDTO {
    existingCourseModification: boolean;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            existingCourseModification: false,
        },
        initValues);
    },
    existingCourseModification: 'existingCourseModification',
}

export { Request, Result };