// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveDisciplineDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import DisciplineDTO from '../models/DisciplineDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    disciplineId: string | null | null;
    discipline: DisciplineDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            disciplineId: null,
            discipline: null,
        },
        initValues);
    },
    disciplineId: 'disciplineId',
    discipline: 'discipline',
}

interface Result extends ResultDTO {
    discipline: DisciplineDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            discipline: null,
        },
        initValues);
    },
    discipline: 'discipline',
}

export { Request, Result };