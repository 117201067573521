// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import TermDTO from './TermDTO';

interface YearTermDTO { 
	termId: string | null | null;
	year: number | null | null;
	term: TermDTO | null;
	display: string | null;
}

const YearTermDTO = {
    className: 'YearTermDTO',
    create: (initValues?: Partial<YearTermDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
			termId: null,
			year: null,
			term: null,
			display: "",
        },
        initValues);
    },
	termId: 'termId',
	year: 'year',
	term: 'term',
	display: 'display',
};

export default YearTermDTO;