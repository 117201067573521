import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Input, Skeleton, Space, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Routes from '../../config/Routes';
import * as LocalLoginHandler from '../../handlerModels/LocalLoginHandler';
import { UserSessionActions } from '../../redux/actions/UserSessionActions';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import HistoryUtil from '../../utils/HistoryUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';

interface LoginFormState extends BaseFormState {
  showPassword: boolean;
}

interface LoginFormProps extends BaseFormProps {
  userName: string | null;
  returnUrl?: string;
  error: LocalLoginHandler.Result | null;
  errorMessage: string | null;
  loginAction: (dto: LocalLoginHandler.Request) => void;
  style?: CSSProperties;
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private passwordInput: any;

  private readonly _formItems = new Map<string, FormItemProps>()
    .set(LocalLoginHandler.Request.userName, {
      required: true,
      name: LocalLoginHandler.Request.userName,
      label: 'Email Address',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(LocalLoginHandler.Request.password, {
      required: true,
      name: LocalLoginHandler.Request.password,
      label: 'Password',
      rules: [
        ValidationRuleUtil.required(),
      ]
    })

  constructor(props: LoginFormProps) {
    super(props);

    this.passwordInput = React.createRef();

    this.state = {
      showPassword: false,
    };
  }

  componentDidUpdate() {
    if (this.props.userName !== null && this.props.error === null) {
      if (this.props.returnUrl) {
        HistoryUtil.push(this.props.returnUrl);
      } else {
        HistoryUtil.push(Routes.DASHBOARD);
      }
    }
  }

  private focusPassword = () => {
    if (this.passwordInput !== null && this.passwordInput !== undefined) {
      this.passwordInput?.current?.focus();
    }
  }

  private handleSubmit = () => {
    const model = LocalLoginHandler.Request.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);

    if (model.userName && model.password) {
      this.props.loginAction(model);
    }
    else {
      this.showPassword();
    }
  }

  private showPassword = () => {
    const model = LocalLoginHandler.Request.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);

    if (model !== null && model.userName) {
      this.setState({ showPassword: true });
      this.focusPassword();
    }
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <div style={this.props.style}>
        <Form
          ref={this._formRef}
          layout="vertical"
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <Typography.Title level={4} className="align-center">STICS Login</Typography.Title>
          {this.renderErrors()}
          <FormItem
            {...this._formItems.get(LocalLoginHandler.Request.userName)}
            {...ValidationUtil.getValidation(LocalLoginHandler.Request.userName, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={this.state.loading} prefix={<MailOutlined />} />
          </FormItem>

          {this.renderPassword()}

          <Form.Item>
            <Button type="primary" htmlType="submit" shape='round' size="large" disabled={this.state.loading} loading={this.state.loading} block={true}>
              {this.state.showPassword ? 'Login' : 'Next'}
            </Button>
          </Form.Item>

          <Space direction='vertical' className="align-center">
            <Link className="align-center" to={Routes.generate(Routes.REQUEST_RESET_PASSWORD)}>Forgot Password?</Link>
            <Link className="align-center" to={Routes.generate(Routes.RESEND_CONFIRMATION_EMAIL)}>Resend Confirmation Email</Link>
          </Space>
          <Divider />
          <Space direction='vertical' className="align-center">
            <Typography.Title level={4} className="align-center">Have an Iowa Department of Education Portal Account?</Typography.Title>
            <Typography.Paragraph className="align-center">Please make sure to log into your account via the portal.</Typography.Paragraph>
            <Button shape='round' size="large" href='https://portal.ed.iowa.gov/IowaLandingPage/Landing.aspx' className="align-center">Go to Portal</Button>
          </Space>
        </Form >
      </div>
    );
  }

  renderErrors() {
    if (this.props.errorMessage) {
      const messages = this.props.errorMessage;
      return (<Alert message="Error" description={messages} type="error" showIcon={true} />);
    }
  }

  renderPassword() {
    if (this.state.showPassword) {
      return (
        <Form.Item
          {...this._formItems.get(LocalLoginHandler.Request.password)}
          {...ValidationUtil.getValidation(LocalLoginHandler.Request.password, this.state.fieldErrors, this.state.submitted)} >
          <Input.Password ref={this.passwordInput} disabled={this.state.loading} prefix={<LockOutlined />} />
        </Form.Item>
      );
    }
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: (dto: LocalLoginHandler.Request) => dispatch(UserSessionActions.login(dto)),
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    loading: state.UserSession.Loading,
    submitting: state.UserSession.Loading,
    error: state.UserSession.Result,
    userName: state.UserSession?.Value?.userName ?? null,
    errorMessage: state.UserSession?.ErrorMessage ?? null
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
