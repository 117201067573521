import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, DatePicker, Input, message, Row, Select, Skeleton, Space, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import ProgramModificationChangeRequestApiService from '../../../api/ProgramModificationChangeRequestApiService';
import { ColumnWidths } from '../../../config/ColumnWidths';
import * as SaveProgramModificationChangeRequestStep5Handler from '../../../handlerModels/SaveProgramModificationChangeRequestStep5Handler';
import * as SubmitProgramModificationChangeRequestStep5Handler from '../../../handlerModels/SubmitProgramModificationChangeRequestStep5Handler';
import * as GetProgramModificationChangeRequestStep5Handler from '../../../handlerModels/GetProgramModificationChangeRequestStep5Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import GenderDTO from '../../../models/GenderDTO';
import ProgramModificationAdvisoryBoardContactDTO from '../../../models/ProgramModificationAdvisoryBoardContactDTO';
import ProgramModificationChangeRequestDTO from '../../../models/ProgramModificationChangeRequestDTO';
import RacialEthnicGroupDTO from '../../../models/RacialEthnicGroupDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';
import Dropdown from '../../inputs/Dropdown';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface ProgramModificationChangeRequestStep5FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  genders: GenderDTO[];
  racialEthnicGroups: RacialEthnicGroupDTO[];
  programModification: ProgramModificationChangeRequestDTO;
}

interface ProgramModificationChangeRequestStep5FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSubmit?: (id: string) => void;
  onSave?: (id: string) => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class ProgramModificationChangeRequestStep5Form extends React.Component<ProgramModificationChangeRequestStep5FormProps, ProgramModificationChangeRequestStep5FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: ProgramModificationChangeRequestStep5FormProps) {
    super(props);

    this.state = {
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      genders: [],
      racialEthnicGroups: [],
      programModification: ProgramModificationChangeRequestDTO.create({ advisoryBoardApprovalDate: '', curriculumApprovalProcessDateOfApproval: '' })
    };
  }

  private cleanDates = (programMod: ProgramModificationChangeRequestDTO) => {
    programMod.advisoryBoardApprovalDate = moment(programMod.advisoryBoardApprovalDate);
    programMod.curriculumApprovalProcessDateOfApproval = moment(programMod.curriculumApprovalProcessDateOfApproval);

    return programMod;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: ProgramModificationChangeRequestStep5FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {

      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (this.state.genders.length == 0) {
      loaders.push(this.loadGenders());
    }

    if (this.state.racialEthnicGroups.length == 0) {
      loaders.push(this.loadEthnicGroups());
    }

    if (!this.props.changeRequestId || this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadGenders = () => {
    return LookupsUtil.getAll<GenderDTO>(GenderDTO.className)
      .then((results: GenderDTO[]) => {
        if (results) {
          this.setState({ genders: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadEthnicGroups = () => {
    return LookupsUtil.getAll<RacialEthnicGroupDTO>(RacialEthnicGroupDTO.className)
      .then((results: RacialEthnicGroupDTO[]) => {
        if (results) {
          this.setState({ racialEthnicGroups: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadChangeRequest = () => {
    const request = GetProgramModificationChangeRequestStep5Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return ProgramModificationChangeRequestApiService.getStep5(request)
      .then((results: GetProgramModificationChangeRequestStep5Handler.Result) => {
        if (results) {
          const programMod = results.changeRequest?.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create();

          this.cleanApprovalDates(programMod);

          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            programModification: programMod,
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private cleanApprovalDates = (programMod: ProgramModificationChangeRequestDTO) => {
    if (programMod.curriculumApprovalProcessDateOfApproval == '1970-01-01T00:00:00.000Z' || programMod.curriculumApprovalProcessDateOfApproval == '0001-01-01T00:00:00+00:00') {
      programMod.curriculumApprovalProcessDateOfApproval = '';
    }
    else {
      programMod.curriculumApprovalProcessDateOfApproval = moment(programMod.curriculumApprovalProcessDateOfApproval);
    }

    if (programMod.advisoryBoardApprovalDate == '1970-01-01T00:00:00.000Z' || programMod.advisoryBoardApprovalDate == '0001-01-01T00:00:00+00:00') {
      programMod.advisoryBoardApprovalDate = '';
    }
    else {
      programMod.advisoryBoardApprovalDate = moment(programMod.advisoryBoardApprovalDate);
    }
  }

  private getAdvisoryBoardFormItems = () => {
    const fields = new Map<string, FormItemProps>()
      .set(ProgramModificationChangeRequestDTO.advisoryBoardApprovalDate, {
        label: ' Advisory Board Date Of Approval',
        name: ProgramModificationChangeRequestDTO.advisoryBoardApprovalDate,
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(ProgramModificationChangeRequestDTO.curriculumApprovalProcessDateOfApproval, {
        label: 'Curriculum Approval Process Date of Approval',
        name: ProgramModificationChangeRequestDTO.curriculumApprovalProcessDateOfApproval,
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
    return fields;
  }

  private getContactFormItems = (index: number) => {
    const fields = new Map<string, FormItemProps>()
      .set(ProgramModificationAdvisoryBoardContactDTO.firstName, {
        name: [index, ProgramModificationAdvisoryBoardContactDTO.firstName],
        label: 'First Name',
      })
      .set(ProgramModificationAdvisoryBoardContactDTO.lastName, {
        name: [index, ProgramModificationAdvisoryBoardContactDTO.lastName],
        label: 'Last Name',
      })
      .set(ProgramModificationAdvisoryBoardContactDTO.employer, {
        name: [index, ProgramModificationAdvisoryBoardContactDTO.employer],
        label: 'Employer',
      })
      .set(ProgramModificationAdvisoryBoardContactDTO.position, {
        name: [index, ProgramModificationAdvisoryBoardContactDTO.position],
        label: 'Postition',
      })
      .set(ProgramModificationAdvisoryBoardContactDTO.genderId, {
        name: [index, ProgramModificationAdvisoryBoardContactDTO.genderId],
        label: 'Gender',
      })
      .set(ProgramModificationAdvisoryBoardContactDTO.ethnicGroupId, {
        name: [index, ProgramModificationAdvisoryBoardContactDTO.ethnicGroupId],
        label: 'Ethnic Group',
      })
      .set(ProgramModificationAdvisoryBoardContactDTO.disability, {
        name: [index, ProgramModificationAdvisoryBoardContactDTO.disability],
        label: 'Has the advisory board member self-identified as having a disability?',
      })
      .set(ProgramModificationAdvisoryBoardContactDTO.organizedLabor, {
        name: [index, ProgramModificationAdvisoryBoardContactDTO.organizedLabor],
        label: 'Is this member a part of organized labor?',
      })
    return fields;
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const programModificationChangeRequestDTO = ProgramModificationChangeRequestDTO.create(this._formRef.current?.getFieldsValue());
    programModificationChangeRequestDTO.changeRequestId = this.state.changeRequest.id ?? Guid.Empty();

    const request = SaveProgramModificationChangeRequestStep5Handler.Request.create({
      changeRequestId: this.state.changeRequest.id,
      programModificationChangeRequest: programModificationChangeRequestDTO
    });

    ProgramModificationChangeRequestApiService.saveStep5(request)
      .then((result: SaveProgramModificationChangeRequestStep5Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            programModification: this.cleanDates(result.changeRequest?.programModificationChangeRequest ?? ProgramModificationChangeRequestDTO.create())
          });

          this.resetForm();
          if (this.props.onSave) {
            if (result.changeRequest) {
              this.props.onSave(result.changeRequest.id ?? Guid.Empty());
            }
            this.resetForm();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const programModificationChangeRequestDTO = ProgramModificationChangeRequestDTO.create(this._formRef.current?.getFieldsValue());
    programModificationChangeRequestDTO.changeRequestId = this.state.changeRequest.id ?? Guid.Empty();
    const request = SaveProgramModificationChangeRequestStep5Handler.Request.create({
      programModificationChangeRequest: programModificationChangeRequestDTO
    });

    ProgramModificationChangeRequestApiService.submitStep5(request)
      .then((result: SubmitProgramModificationChangeRequestStep5Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSubmit) {
            this.props.onSubmit(this.state.changeRequest.id ?? Guid.Empty());
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}

        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.programModification}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <Space direction="vertical" >


            <FormItem
              {...this.getAdvisoryBoardFormItems().get(ProgramModificationChangeRequestDTO.curriculumApprovalProcessDateOfApproval)}
              {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.curriculumApprovalProcessDateOfApproval, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <DatePicker format='M/D/YYYY' disabled={this.props.readonly} disabledDate={DateTimeUtil.isFutureDate}></DatePicker>
            </FormItem>

            <FormItem
              {...this.getAdvisoryBoardFormItems().get(ProgramModificationChangeRequestDTO.advisoryBoardApprovalDate)}
              {...ValidationUtil.getValidation(ProgramModificationChangeRequestDTO.advisoryBoardApprovalDate, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <DatePicker format='M/D/YYYY' disabled={this.props.readonly} disabledDate={DateTimeUtil.isFutureDate}></DatePicker>
            </FormItem>

            <Typography.Paragraph italic={true}>
              Please provide the advisor information for this program.
            </Typography.Paragraph>

            <Form.List name={ProgramModificationChangeRequestDTO.programModificationAdvisoryBoardContacts}>
              {
                (advisors, { add, remove }) => {
                  return (
                    <Space direction='vertical'>
                      {advisors.map((value, index) => this.renderAdvisors(index, remove))}
                      {this.renderAddAdditionalAdvisor(add)}
                    </Space>);
                }
              }
            </Form.List>
            {this.renderActions()}
          </Space>
        </ Form>
      </Space >
    );
  }

  private renderAdvisors(index: number, remove: any) {
    const title = 'Advisor ' + (index + 1);
    const deleteButton = this.props.readonly ? null : <Button type="link" icon={<DeleteOutlined />} onClick={() => remove(index)}></Button>;

    return <Card type="inner" title={title} extra={deleteButton}>
      <Row gutter={[16, 16]}>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(ProgramModificationAdvisoryBoardContactDTO.firstName)}
            {...ValidationUtil.getValidation(ProgramModificationAdvisoryBoardContactDTO.className.toLowerCase() + ProgramModificationAdvisoryBoardContactDTO.firstName.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>
        </Col>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(ProgramModificationAdvisoryBoardContactDTO.lastName)}
            {...ValidationUtil.getValidation(ProgramModificationAdvisoryBoardContactDTO.className.toLowerCase() + ProgramModificationAdvisoryBoardContactDTO.lastName.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(ProgramModificationAdvisoryBoardContactDTO.employer)}
            {...ValidationUtil.getValidation(ProgramModificationAdvisoryBoardContactDTO.className.toLowerCase() + ProgramModificationAdvisoryBoardContactDTO.employer.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>
        </Col>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(ProgramModificationAdvisoryBoardContactDTO.position)}
            {...ValidationUtil.getValidation(ProgramModificationAdvisoryBoardContactDTO.className.toLowerCase() + ProgramModificationAdvisoryBoardContactDTO.position.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(ProgramModificationAdvisoryBoardContactDTO.genderId)}
            {...ValidationUtil.getValidation(ProgramModificationAdvisoryBoardContactDTO.className.toLowerCase() + ProgramModificationAdvisoryBoardContactDTO.genderId.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Dropdown showArrow={!this.props.readonly} disabled={this.props.readonly} dropdownMatchSelectWidth={false}>{this.state.genders.map(x => this.renderGender(x))}</Dropdown>
          </FormItem>
        </Col>
        <Col {...ColumnWidths.HALF}>
          <FormItem
            {...this.getContactFormItems(index).get(ProgramModificationAdvisoryBoardContactDTO.ethnicGroupId)}
            {...ValidationUtil.getValidation(ProgramModificationAdvisoryBoardContactDTO.className.toLowerCase() + ProgramModificationAdvisoryBoardContactDTO.ethnicGroupId.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Dropdown showArrow={!this.props.readonly} disabled={this.props.readonly} dropdownMatchSelectWidth={false}>{this.state.racialEthnicGroups.map(x => this.renderEthnicGroup(x))}</Dropdown>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <FormItem
          {...this.getContactFormItems(index).get(ProgramModificationAdvisoryBoardContactDTO.organizedLabor)}
          {...ValidationUtil.getValidation(ProgramModificationAdvisoryBoardContactDTO.className.toLowerCase() + ProgramModificationAdvisoryBoardContactDTO.organizedLabor.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
          <YesNoInput disabled={this.props.readonly}></YesNoInput>
        </FormItem>
      </Row>
      <Row gutter={[16, 16]}>
        <FormItem
          {...this.getContactFormItems(index).get(ProgramModificationAdvisoryBoardContactDTO.disability)}
          {...ValidationUtil.getValidation(ProgramModificationAdvisoryBoardContactDTO.className.toLowerCase() + ProgramModificationAdvisoryBoardContactDTO.disability.toLowerCase() + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
          <YesNoInput disabled={this.props.readonly}></YesNoInput>
        </FormItem>
      </Row>
    </Card>
  }

  private renderActions() {
    if (!this.props.readonly) {
      return <Space direction='horizontal' wrap={true}>
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton onClick={this.handleSave} type="default" htmlType='button' saving={this.state.saving} />
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space>
    }
  }

  renderAddAdditionalAdvisor(add: any) {
    if (!this.props.readonly) {
      return (
        <Button onClick={() => add()} icon={<PlusOutlined />}>
          Add Additional Advisor
        </Button>
      );
    }
  }

  renderGender(gender: GenderDTO) {
    if (gender.id) {
      return <Select.Option key={gender.id ?? Guid.Empty()} value={gender.id ?? Guid.Empty()}>{gender.name}</Select.Option>
    }
  }

  renderEthnicGroup(ethnicGroup: RacialEthnicGroupDTO) {
    if (ethnicGroup.id) {
      return <Select.Option key={ethnicGroup.id ?? Guid.Empty()} value={ethnicGroup.id ?? Guid.Empty()}>{ethnicGroup.name}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default ProgramModificationChangeRequestStep5Form