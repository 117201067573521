// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import FederalClusterDTO from '../models/FederalClusterDTO';
import * as GetFederalClusterDetailsHandler from '../handlerModels/GetFederalClusterDetailsHandler';
import * as SaveFederalClusterDetailsHandler from '../handlerModels/SaveFederalClusterDetailsHandler';
import * as DeleteFederalClusterHandler from '../handlerModels/DeleteFederalClusterHandler';
import * as GetFederalClustersHandler from '../handlerModels/GetFederalClustersHandler';

export class FederalClusterApiService extends BaseApi {

    // post: api/federalClusters?academicYearId=${academicYearId}
    public getFederalClusterTableData(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<FederalClusterDTO>> {
        let url = `api/federalClusters`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<FederalClusterDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/federalClusters/${encodeURIComponent(federalClusterId)}
    public getFederalClusterDetails(federalClusterId: string): Promise<GetFederalClusterDetailsHandler.Result> {
        let url = `api/federalClusters/${encodeURIComponent(federalClusterId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetFederalClusterDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/federalClusters/save
    public saveFederalClusterDetails(saveFederalClusterDetails: SaveFederalClusterDetailsHandler.Request): Promise<SaveFederalClusterDetailsHandler.Result> {
        let url = `api/federalClusters/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveFederalClusterDetailsHandler.Result>(saveFederalClusterDetails, 'post', url, true, false);
    }

    // post: api/federalClusters/deleteFederalCluster
    public deleteFederalCluster(deleteFederalCluster: DeleteFederalClusterHandler.Request): Promise<DeleteFederalClusterHandler.Result> {
        let url = `api/federalClusters/deleteFederalCluster`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteFederalClusterHandler.Result>(deleteFederalCluster, 'post', url, true, false);
    }

    // post: api/federalClusters/federalClusters?academicYearId=${academicYearId}
    public getFederalClusters(academicYearId: number): Promise<GetFederalClustersHandler.Result> {
        let url = `api/federalClusters/federalClusters`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetFederalClustersHandler.Result>(null, 'post', url, true, false);
    }
}
var service = new FederalClusterApiService();
export default service;
