import { Text, View } from '@react-pdf/renderer';
import { Card, Skeleton, Space } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import { Content } from 'antd/lib/layout/layout';
import { UploadFile } from 'antd/lib/upload/interface';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Html from 'react-pdf-html';
import SelfStudyApiService from '../../api/SelfStudyApiService';
import SelfStudySectionComponentResponseApiService from '../../api/SelfStudySectionComponentResponseApiService';
import SelfStudyTemplateApiService from '../../api/SelfStudyTemplateApiService';
import Routes from '../../config/Routes';
import AcademicYearStatusType from '../../consts/AcademicYearStatusType';
import FeatureFlag from '../../consts/FeatureFlag';
import SelfStudyResponseStatusType from '../../consts/SelfStudyResponseStatusType';
import SelfStudyResponseType from '../../consts/SelfStudyResponseType';
import * as GetSelfStudySectionComponentResponseByTypeHandler from '../../handlerModels/GetSelfStudySectionComponentResponseByTypeHandler';
import * as GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler from '../../handlerModels/GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler';
import * as GetSelfStudyWithRankingQuestionHandler from '../../handlerModels/GetSelfStudyWithRankingQuestionHandler';
import SelfStudyDTO from '../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../models/SelfStudyResponseDTO';
import SelfStudySectionComponentResponseDTO from '../../models/SelfStudySectionComponentResponseDTO';
import SelfStudySectionComponentTemplateDTO from '../../models/SelfStudySectionComponentTemplateDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import Guid from '../../utils/Guid';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import CheckboxInputGroup, { CheckboxInputGroupOption } from '../CheckboxInputGroup';
import FileUpload from '../FileUpload';
import RichTextEditor from '../inputs/RichTextEditor';

interface SelfStudyRankingQuestionProps extends BaseFormProps {
  academicYear?: number;
  selfStudyResponseId?: string;
  selfStudyId?: string;
  componentIndex: number;
  sectionIndex: number;
  readonly?: boolean;
  disable?: boolean;
  previewComponent?: boolean;
  handleFileUpload?: ((componentTemplateIndex: number, sectionTemplateIndex: number, value: UploadFile | undefined) => void) | undefined;
  handleFileRemoved?: ((componentTemplateIndex: number, sectionTemplateIndex: number, value: UploadFile | undefined) => void) | undefined;
  pdfView?: boolean
  renderComplete?: (sectionIndex: number,componentIndex: number, value: boolean) => void;
}

const SelfStudyRankingQuestion = React.forwardRef((props: SelfStudyRankingQuestionProps) => {
  const _checkboxOptions =
    [{ id: '0', display: '0 - This area needs major improvement' },
    { id: '1', display: '1 - This area needs some improvement' },
    { id: '2', display: '2 - This is satisfactory' },
    { id: '3', display: '3 - This is a strength' }] as CheckboxInputGroupOption[];

  const getComponentFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(SelfStudySectionComponentTemplateDTO.primaryText, {
        name: [props.componentIndex, SelfStudySectionComponentTemplateDTO.primaryText],
      })
      .set(SelfStudySectionComponentTemplateDTO.programOfStudyTenets, {
        label: 'Program Study of Tenent(s)',
        name: [props.componentIndex, SelfStudySectionComponentTemplateDTO.programOfStudyTenets],
      })
      .set(SelfStudySectionComponentResponseDTO.rating, {
        required: true,
        name: [props.componentIndex, SelfStudySectionComponentResponseDTO.rating],
        rules: [ValidationRuleUtil.required()]
      })
      .set(SelfStudySectionComponentResponseDTO.selfStudySectionComponentResponseResources, {
        name: [props.componentIndex, SelfStudySectionComponentResponseDTO.selfStudySectionComponentResponseResources],
      })
      .set(SelfStudySectionComponentResponseDTO.responseText, {
        label: selfStudyResponse?.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR || props.previewComponent ? 'Please provide additional supporting information to your response.' : 'Comments',
        required: !props.disable && (props.readonly) ? false : true,
        name: [props.componentIndex, SelfStudySectionComponentResponseDTO.responseText],
        rules: props.readonly ? [] : [ValidationRuleUtil.required()]
      });
  }

  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(props.fieldErrors);
  const [submitted, setSubmitted] = useState(props.submitted);
  const [selfStudyResponse, setSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);
  const [selfStudySectionComponentTemplate, setSelfStudySectionComponentTemplate] = useState({} as SelfStudySectionComponentTemplateDTO);
  const [educatorSelfStudySectionComponentResponse, setEducatorSelfStudySectionComponentResponse] = useState({} as SelfStudySectionComponentResponseDTO);
  const [rppSelfStudySectionComponentResponse, setRPPSelfStudySectionComponentResponse] = useState({} as SelfStudySectionComponentResponseDTO);
  const [doeSelfStudySectionComponentResponse, setDOESelfStudySectionComponentResponse] = useState({} as SelfStudySectionComponentResponseDTO);

  useEffect(() => {
    fetchData();
  }, [props.academicYear, props.selfStudyResponseId]
  )

  useEffect(() => {
    setFieldErrors(props.fieldErrors);
    setSubmitted(props.submitted);
  }, [props.submitted, props.fieldErrors])

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (props.academicYear != 0 &&
      props.selfStudyResponseId &&
      props.selfStudyResponseId != Guid.Empty()) {
      loaders.push(loadSelfStudy(props.selfStudyResponseId ?? Guid.Empty()));
      if (props.selfStudyId && props.selfStudyId != Guid.Empty()) {
        loaders.push(loadSelfStudySectionResponse(SelfStudyResponseType.INSTRUCTOR, setEducatorSelfStudySectionComponentResponse));
        loaders.push(loadSelfStudySectionResponse(SelfStudyResponseType.RPP, setRPPSelfStudySectionComponentResponse));
        loaders.push(loadSelfStudySectionResponse(SelfStudyResponseType.DOE, setDOESelfStudySectionComponentResponse));
        // loaders.push(loadFiles());
        loaders.push(loadSelfStudySectionComponentTemplate());
      }
      Promise.all(loaders).then(() => {
        setLoading(false);
        if(props.renderComplete){
          props.renderComplete(props.sectionIndex ?? 0,props.componentIndex, true);
        }
      });
    }
    else if (props.previewComponent) {
      loaders.push(loadSelfStudySectionComponentTemplate());
      Promise.all(loaders).then(() => {
        setLoading(false);
        if(props.renderComplete){
          props.renderComplete(props.sectionIndex ?? 0,props.componentIndex, true);
        }
      });
      
    }
  }

  const loadSelfStudySectionResponse = (type: number, callback: React.Dispatch<React.SetStateAction<SelfStudySectionComponentResponseDTO>>) => {
    const request = GetSelfStudySectionComponentResponseByTypeHandler.Request.create({
      selfStudyId: props.selfStudyId,
      selfStudyResponseTypeId: type,
      sectionIndex: props.sectionIndex,
      componentIndex: props.componentIndex
    });

    return SelfStudySectionComponentResponseApiService.getSelfStudySectionComponentResponseByType(request)
      .then((results: GetSelfStudySectionComponentResponseByTypeHandler.Result) => {
        if (results.selfStudySectionComponentResponse) {
          callback(results.selfStudySectionComponentResponse ?? SelfStudySectionComponentResponseDTO.create());
        }
      });
  }

  const loadSelfStudy = (id: string) => {
    const request = GetSelfStudyWithRankingQuestionHandler.Request.create({
      selfStudyResponseId: id,
      academicYear: props.academicYear
    });

    return SelfStudyApiService.getSelfStudyWithRankingQuestion(request)
      .then((results: GetSelfStudyWithRankingQuestionHandler.Result) => {
        if (results.selfStudy) {
          setSelfStudy(results.selfStudy);
          setSelfStudyResponse(results.selfStudy.selfStudyResponses?.find(x => x.id == id) ?? SelfStudyResponseDTO.create());
        }
      });
  }

  const loadSelfStudySectionComponentTemplate = () => {
    const request = GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Request.create({
      academicYear: props.academicYear,
      selfStudySectionOrder: props.sectionIndex,
      selfStudySectionComponentOrder: props.componentIndex
    })
    return SelfStudyTemplateApiService.getSelfStudySectionComponentTemplateBySectionOrder(request)
      .then((results: GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Result) => {
        if (results.selfStudySectionComponentTemplate) {
          setSelfStudySectionComponentTemplate(results.selfStudySectionComponentTemplate ?? SelfStudySectionComponentTemplateDTO.create());
        }
      });
  }

  const getTitle = (tempalte: SelfStudySectionComponentTemplateDTO | undefined) => {
    let title = tempalte?.rankingQuestionIdentifier;
    let programOfStudyTenets = tempalte?.programOfStudyTenets;

    if (!title) {
      title = selfStudySectionComponentTemplate?.rankingQuestionIdentifier;
    }

    if (!programOfStudyTenets) {
      programOfStudyTenets = selfStudySectionComponentTemplate?.programOfStudyTenets;
    }

    return title + ' - ' + programOfStudyTenets;
  }

  const generateLinks = (files: any[]) => {
    const links: any[] | undefined = [];

    files.forEach((x: any) => {
      if (x != undefined) {
        links.push({
          uid: x.azureBlobStorageId,
          url: Routes.generateDownload(x.azureBlobStorageId ?? Guid.Empty()),
          name: x.azureBlobFile?.fileName
        });
      }
    });

    return links;
  }

  const getExistingDEFiles = () => {
    return generateLinks(doeSelfStudySectionComponentResponse?.selfStudySectionComponentResponseResources ?? []);
  }

  const getExistingInstructorsFiles = () => {
    return generateLinks(educatorSelfStudySectionComponentResponse.selfStudySectionComponentResponseResources ?? []);
  }

  const getExistingRPPFiles = () => {
    return generateLinks(rppSelfStudySectionComponentResponse.selfStudySectionComponentResponseResources ?? []);
  }

  const renderDistrictResponse = () => {
    if (selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR || (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR && props.readonly)) {
      return renderExistingResponse('District Response', educatorSelfStudySectionComponentResponse?.rating, educatorSelfStudySectionComponentResponse?.responseText ?? '', getExistingInstructorsFiles());
    }

    return <></>
  }

  const renderPdfDistrictResponse = () => {
    if (selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR || (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR && props.pdfView)) {
      return renderPDFExistingResponse('District Response', educatorSelfStudySectionComponentResponse?.rating, educatorSelfStudySectionComponentResponse?.responseText ?? '');
    }

    return <Text></Text>
  }

  const renderRppResponse = () => {
    if (((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE ||
      (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP && props.pdfView)) &&
      (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.VIEW_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISING ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISIONSNEEDED ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE) {
      return renderExistingResponse('RPP Response', rppSelfStudySectionComponentResponse?.rating, rppSelfStudySectionComponentResponse?.responseText ?? '', getExistingRPPFiles());
    }
  }

  const renderPdfRppResponse = () => {
    if (((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE ||
      (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP && props.pdfView)) &&
      (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.VIEW_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISING ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISIONSNEEDED ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE) {
      return renderPDFExistingResponse('RPP Response', rppSelfStudySectionComponentResponse?.rating, rppSelfStudySectionComponentResponse?.responseText ?? '');
    }
  }

  const renderDEResponse = () => {
    if ((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE && props.readonly &&
      (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudy.academicYear?.academicYearStatusTypeId == AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS) {
      return renderExistingResponse('DE Response', doeSelfStudySectionComponentResponse?.rating, doeSelfStudySectionComponentResponse?.responseText ?? '', getExistingDEFiles());
    }
  }

  const renderPdfDEResponse = () => {
    if ((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE && props.pdfView &&
      (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudy.academicYear?.academicYearStatusTypeId == AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS) {
      return renderPDFExistingResponse('DE Response', doeSelfStudySectionComponentResponse?.rating, doeSelfStudySectionComponentResponse?.responseText ?? '');
    }
  }

  const handleFileUpload = (file: UploadFile | undefined) => {
    if (props.handleFileUpload) {
      props.handleFileUpload(props.componentIndex, props.sectionIndex, file);
    }
  }

  const handleFileRemoved = (file: UploadFile | undefined) => {
    if (props.handleFileRemoved) {
      props.handleFileRemoved(props.componentIndex, props.sectionIndex, file);
    }
  }

  const renderExistingResponse = (title: string, rating: number, comments: string, files: any) => {
    return (
      <Card title={title} type='inner' className='sub-inner-card' size='small'>
        <Space direction='vertical'>
          <CheckboxInputGroup
            value={rating}
            direction='horizontal'
            options={_checkboxOptions}
            disabled={true}
            readonly={true} />
          <RichTextEditor value={comments} readonly={true} />
          <FileUpload onUploaded={handleFileUpload} onRemoved={handleFileRemoved} readonly={true} disabled={true} existingList={files} style={{ width: 300 }} text={'Add supporting documents'} />
        </Space>
      </Card>
    )
  }

  const renderPDFExistingResponse = (title: string, rating: number, comments: string) => {
    const fontSizeFix = comments.replaceAll(new RegExp('ptpx','g'), 'px');
    const decimal = fontSizeFix.replaceAll(new RegExp('font-size:?[0-9]{1,2}px','g'), 'font-size:8px');
    const fontFamily = decimal.replaceAll(new RegExp('font-size:?[0-9]{1,2}.[0-9]{1,2}px','g'), 'font-size:8px');
    const finalText = fontFamily.replaceAll(new RegExp('font-family:','g'), '');

    return (
      <View style={{ border: '1pt solid #f0f0f0', marginBottom: 10 }}>
        <Text style={{ fontSize: 10, border: '1pt solid #f0f0f0', backgroundColor: '#f0f0f0', padding: 5 }}>{title}</Text>
        <View style={{ margin: 10 }}>
          <View style={{ marginLeft: 5, marginTop: 2 }}>

            {props.previewComponent ?
              null
              :
              <View>
                <Text style={{ fontSize: 8 }}>{rating == undefined ? '' :_checkboxOptions.at(rating)?.display}</Text>
                <Text style={{ paddingTop: '10pt', fontSize: 10}}>Comments</Text>
              </ View>
            }

            <Html style={{ fontSize: 8 }}>{finalText}</Html>
          </View>
        </View>
      </View>
    )
  }

  const renderNonReadOnly = () => {
    const componentIndex = props.componentIndex;
    let existingFiles: any[] | undefined = [];

    switch (selfStudyResponse.selfStudyReponseTypeId) {
      case SelfStudyResponseType.INSTRUCTOR:
        existingFiles = getExistingInstructorsFiles()
        break;
      case SelfStudyResponseType.RPP:
        existingFiles = getExistingRPPFiles()
        break;
      case SelfStudyResponseType.DOE:
        existingFiles = getExistingDEFiles()
        break;
    }

    if (!props.readonly) {
      const body =
        <Content>
          <FormItem
            {...getComponentFormItems().get(SelfStudySectionComponentResponseDTO.rating)}
            {...ValidationUtil.getValidation(SelfStudySectionComponentResponseDTO.rating + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
            <CheckboxInputGroup
              direction='horizontal'
              options={_checkboxOptions ?? []}
              disabled={props.disable || props.readonly} />
          </FormItem>

          <label title={'richText'} htmlFor={'richText'} />
          <FormItem
            {...getComponentFormItems().get(SelfStudySectionComponentResponseDTO.responseText)}
            {...ValidationUtil.getValidation(SelfStudySectionComponentResponseDTO.responseText + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
            <RichTextEditor id='richText' editorId='richText' readonly={!props.disable && (props.readonly)} />
          </FormItem>

          <FormItem
            {...getComponentFormItems().get(SelfStudySectionComponentResponseDTO.selfStudySectionComponentResponseResources)}
            {...ValidationUtil.getValidation(SelfStudySectionComponentResponseDTO.selfStudySectionComponentResponseResources + componentIndex, fieldErrors, submitted)} >
            <FileUpload onUploaded={handleFileUpload} onRemoved={handleFileRemoved} disabled={props.disable || props.readonly} existingList={existingFiles} style={{ width: 300 }} text={'Add supporting documents'} />
          </FormItem>

        </Content>;

      return body;
    }

    return null;
  }


  if (props.pdfView) {
    const text = selfStudySectionComponentTemplate?.primaryText ?? '';
    const decimal = text.replaceAll(new RegExp('font-size:?[0-9]{1,2}px','g'), 'font-size:10px');
    const fontFamily = decimal.replaceAll(new RegExp('font-size:?[0-9]{1,2}.[0-9]{1,2}px','g'), 'font-size:10px');
    const finalText = fontFamily.replaceAll(new RegExp('font-family:','g'), '');
    return (
      <View style={{ paddingBottom: '10pt' }}>
        <View style={{ border: '1pt solid #f0f0f0', margin: 10 }}>
          <Text style={{ fontSize: 10, border: '1pt solid #f0f0f0', backgroundColor: '#f0f0f0', padding: 5 }}>{getTitle(selfStudySectionComponentTemplate)}</Text>
          <View style={{ margin: 10, paddingBottom: 10 }}>
            <Html style={{ fontSize: 10 }}>{finalText}</Html>

            {renderPdfDistrictResponse()}
            {renderPdfRppResponse()}
            {renderPdfDEResponse()}
            {props.previewComponent ?
              <View>
                <Text style={{ paddingTop: '10pt', width: '60%', fontSize: 8 }}>{_checkboxOptions.at(0)?.display}</Text>
                <Text style={{ width: '60%', fontSize: 8 }}>{_checkboxOptions.at(1)?.display}</Text>
                <Text style={{ width: '60%', fontSize: 8 }}>{_checkboxOptions.at(2)?.display}</Text>
                <Text style={{ width: '60%', fontSize: 8 }}>{_checkboxOptions.at(3)?.display}</Text>
                <Text style={{ paddingTop: '10pt', fontSize: 8 }}>Please provide additional supporting information to your response.</Text>
              </View>
              : null}
          </View>
        </View>
      </View>
    )

  }
  else if (loading) {
    return <Skeleton active />
  }
  else {
    return (
      <Card title={getTitle(selfStudySectionComponentTemplate)} type='inner' >
        <Space direction='vertical' size='small'>
          <RichTextEditor readonly={true} value={selfStudySectionComponentTemplate?.primaryText} />

          {renderDistrictResponse()}
          {renderRppResponse()}
          {renderDEResponse()}

          {renderNonReadOnly()}
        </Space>
      </Card >
    );
  }
})

SelfStudyRankingQuestion.displayName = 'SelfStudyRankingQuestion';

export default SelfStudyRankingQuestion;