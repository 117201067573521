// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import NoticeOfIntentChangeRequestDTO from './NoticeOfIntentChangeRequestDTO';
import InstitutionDTO from './InstitutionDTO';
import AwardTypeDTO from './AwardTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoticeOfIntentChangeRequestCompleterDataDTO extends EntitySelectorDTO { 
	id: string | null;
	noticeOfIntentChangeRequestId: string | null;
	noticeOfIntentChangeRequest: NoticeOfIntentChangeRequestDTO | null;
	institutionId: string | null | null;
	institution: InstitutionDTO | null;
	awardTypeId: number | null | null;
	awardType: AwardTypeDTO | null;
	dataYear: number | null | null;
	completersPerYear: number | null | null;
}

const NoticeOfIntentChangeRequestCompleterDataDTO = {
    className: 'NoticeOfIntentChangeRequestCompleterDataDTO',
    create: (initValues?: Partial<NoticeOfIntentChangeRequestCompleterDataDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequestId: '00000000-0000-0000-0000-000000000000',
			noticeOfIntentChangeRequest: null,
			institutionId: null,
			institution: null,
			awardTypeId: null,
			awardType: null,
			dataYear: null,
			completersPerYear: null,
        },
        initValues);
    },
	id: 'id',
	noticeOfIntentChangeRequestId: 'noticeOfIntentChangeRequestId',
	noticeOfIntentChangeRequest: 'noticeOfIntentChangeRequest',
	institutionId: 'institutionId',
	institution: 'institution',
	awardTypeId: 'awardTypeId',
	awardType: 'awardType',
	dataYear: 'dataYear',
	completersPerYear: 'completersPerYear',
};

export default NoticeOfIntentChangeRequestCompleterDataDTO;