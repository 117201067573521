import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, List, Menu, Skeleton, Space, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProgramApiService from '../../../api/ProgramApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import ChangeRequestDataTable from '../../../components/datatables/postSecondary/ChangeRequestsDataTable';
import ProgramAdvisoryBoardDataTable from '../../../components/datatables/postSecondary/ProgramAdvisoryBoardDataTable';
import ProgramDisplay from '../../../components/displays/postSecondary/ProgramDisplay';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetProgramHeaderHandler from '../../../handlerModels/GetProgramHeaderHandler';
import InstitutionDTO from '../../../models/InstitutionDTO';
import ProgramDetailDTO from '../../../models/ProgramDetailDTO';
import ProgramDTO from '../../../models/ProgramDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ParameterUtil from '../../../utils/ParameterUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import ProgramClassificationDTO from '../../../models/ProgramClassificationDTO';

interface ProgramDetailsState extends BaseFormState {
  programId: string;
  program: ProgramDTO;
  programClassifications: ProgramClassificationDTO[];
  selectedDetailId: string;
  activeTab: string;
}

interface ProgramDetailsProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class ProgramDetailsPage extends React.Component<ProgramDetailsProps & RouteComponentProps<RouteObject>, ProgramDetailsState> {
  private readonly _programDisplayRef = React.createRef<ProgramDisplay>();
  constructor(props: ProgramDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      activeTab: 'program_details',
      programId: Guid.Empty(),
      program: ProgramDTO.create(),
      selectedDetailId: Guid.Empty(),
      programClassifications: []
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');
    const loaders = [];

    loaders.push(this.loadProgram(id));
    loaders.push(this.loadProgramClassification());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
    const activeTab = ParameterUtil.getParameter('activeTab', 'program_details');
    this.setState({ activeTab: activeTab });
  }

  private loadProgram = (id: string) => {
    this.setState({ loading: true });

    return ProgramApiService.getProgramHeader(id)
      .then((results: GetProgramHeaderHandler.Result) => {
        if (results.program) {
          let selectedId = Guid.Empty();

          if (results.program?.programDetails?.length ?? 0 > 0) {
            const selected = results.program.programDetails != null ? results.program.programDetails[0] : ProgramDetailDTO.create();
            if (selected.id) {
              selectedId = selected.id;
            }
          }

          this.setState({
            program: results.program ?? ProgramDTO.create(),
            programId: results.program.id ?? Guid.Empty(),
            selectedDetailId: selectedId,
          });
        }
      })
      .catch()
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private loadProgramClassification = () => {
    return LookupsUtil.getAll<ProgramClassificationDTO>(ProgramClassificationDTO.className)
      .then((results: ProgramClassificationDTO[]) => {
        this.setState({ programClassifications: results });
      });
  }

  goBack = () => {
    HistoryUtil.goBack();
  }

  private programDetailsSaved = (id: string) => {
    this.setState({ isEditing: false });
    this.loadProgram(id);
  }

  private programAdvisorsSaved = (id: string) => {
    this.setState({ isEditing: false, activeTab: 'advisors' });
    this.loadProgram(id);
  }

  private handleVersionSelect = (e: any) => {
    this.setState({
      selectedDetailId: e.key
    });
  }

  private programCurriculumReport = () => {
    const selected = this.state.program?.programDetails?.filter(x => x.id == this.state.selectedDetailId)[0] ?? ProgramDetailDTO.create();
    window.open(Routes.generateFull(Routes.PROGRAM_DETAILED_COURSE_LISTING, { programDetailId: selected.id ?? Guid.Empty() }), '_blank')
  }

  private getDownloadList = () => {
    return [
      {
        title: 'Program Curriculum Report (AS-28)',
        description: 'File contains the curriculum for the selected program detail.',
        action: () => this.programCurriculumReport()
      },
    ];
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._programDisplayRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }

    const selected = this.state.program?.programDetails?.filter(x => x.id == this.state.selectedDetailId)[0] ?? ProgramDetailDTO.create();
    const title = selected ? selected.versionDisplay : 'Versions';

    const versionMenu = (
      <Menu onSelect={this.handleVersionSelect} selectable={true} selectedKeys={[this.state.selectedDetailId]}>
        {this.state.program.programDetails?.map(x => <Menu.Item key={x.id} >{x.versionDisplay}</Menu.Item>)}
      </Menu>
    );

    const versionsDropdown = (
      <Space direction='vertical'>
        <Dropdown overlay={versionMenu} >
          <Button icon={<DownOutlined />} >{title}</Button>
        </Dropdown>
      </Space>
    );
    const programType = selected.isTransferMajor ? this.state.programClassifications.find(x => x.id == (selected.awards ?? [])[0].awardType?.transferMajorProgramTypeId)?.name : this.state.programClassifications.find(x => x.id == (selected.awards ?? [])[0].awardType?.nonTrasferMajorProgramTypeId)?.name;
    return (
      <Content >

        <HeaderPortal
          title={selected?.title ?? 'Program'}
          breadcrumbs={Breadcrumbs.programList()}
          subTitle={this.state.program?.institution?.name + ' - ' + programType ?? 'Unknown'}
          extra={versionsDropdown}
          onBack={this.goBack}
          footer={
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab} >
              <Tabs.TabPane tab="Details" key="program_details" />
              <Tabs.TabPane tab="Advisory Board" key="advisors" />
              <Tabs.TabPane tab="Downloads" key="downloads" />
              {
                AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_CHANGE_REQUEST]) ?
                  <Tabs.TabPane tab="Change Requests" key="program_change_request" /> :
                  null
              }
            </Tabs>
          } />
        <PageStayPrompt when={this.state.isEditing} />
        {this.renderProgramDetails(selected)}
        {this.renderChangeRequestDataTable()}
        {this.renderDownloads()}
        {this.renderProgramAdvisoryBoards()}
      </Content >
    );
  }

  renderProgramDetails(selected: ProgramDetailDTO) {
    const editButton = this.state.programId == Guid.Empty() ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_PROGRAM]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );
    let readonly = false;
    if (!AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM])) {
      readonly = true;
    }

    return (
      <Card title="Program Details" extra={editButton} hidden={this.state.activeTab !== 'program_details'}>
        <ProgramDisplay
          ref={this._programDisplayRef}
          program={this.state.program}
          programDetail={selected}
          selectedDetailId={this.state.selectedDetailId}
          isEditing={this.state.isEditing}
          readonly={readonly}
          disabled={this.state.submitting}
          onSave={this.programDetailsSaved} />
      </Card>
    );
  }

  renderChangeRequestDataTable() {
    if (this.props.currentUser) {
      return (
        <Card title="Change Request" hidden={this.state.activeTab !== 'program_change_request'}>
          <ChangeRequestDataTable userId={this.props.currentUser?.userId ?? null} institution={this.props.selectedInstitution} programId={this.state.program.id ?? Guid.Empty()} />
        </Card>
      );
    }
  }

  renderDownloads() {
    return (
      <Card title={'Downloads'} hidden={this.state.activeTab !== 'downloads'}>
        <List
          itemLayout="horizontal"
          dataSource={this.getDownloadList()}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Button type="link" onClick={item.action}><DownloadOutlined /></Button>}
                title={<Button type="link" onClick={item.action}>{item.title}</Button>}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </ Card>
    );
  }

  renderProgramAdvisoryBoards() {
    return (
      <Card title="Advisory Board" hidden={this.state.activeTab !== 'advisors'}>
        <ProgramAdvisoryBoardDataTable programDetailId={this.state.selectedDetailId} />
      </Card>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ProgramDetailsPage));
