import { MoreOutlined } from '@ant-design/icons';
import * as antd from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import DistrictApiService from '../../../api/DistrictApiService';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import SecondaryCourseApiService from '../../../api/SecondaryCourseApiService';
import SecondaryProgramApiService from '../../../api/SecondaryProgramApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import SecondaryCourseDetailsForm from '../../../components/forms/secondary/SecondaryCourseDetailsForm';
import Dropdown from '../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteSecondaryCourseHandler from '../../../handlerModels/DeleteSecondaryCourseHandler';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import * as GetSecondaryCourseDetailsHandler from '../../../handlerModels/GetSecondaryCourseDetailsHandler';
import * as GetSecondaryCoursesHandler from '../../../handlerModels/GetSecondaryCoursesHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import SecondaryCourseDTO from '../../../models/SecondaryCourseDTO';
import SecondaryProgramDTO from '../../../models/SecondaryProgramDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import CurrentUser from '../../../utils/CurrentUser';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';
import * as GetRegionalPlanningPartnersHandler from '../../../handlerModels/GetRegionalPlanningPartnersHandler';
import * as GetSecondaryProgramsHandler from '../../../handlerModels/GetSecondaryProgramsHandler';
import * as GetDistrictsHandler from '../../../handlerModels/GetDistrictsHandler';
import * as GetSecondaryProgramCoursesHandler from '../../../handlerModels/GetSecondaryProgramCoursesHandler';
import SecondaryProgramCourseApiService from '../../../api/SecondaryProgramCourseApiService';
import SecondaryProgramCourseDTO from '../../../models/SecondaryProgramCourseDTO';

interface SecondaryCourseDetailsPageState extends BaseFormState {
  id: string;
  secondaryCourseId: number;
  secondaryCourse?: SecondaryCourseDTO;
  academicYears: AcademicYearDTO[];
  academicYear: number;
  fromAcademicYearList: boolean;
  fromSelfStudies: boolean;
  secondaryCourses: SecondaryCourseDTO[];
  secondaryPrograms: SecondaryProgramDTO[];
  districts: DistrictDTO[];
  regions: RegionalPlanningPartnerDTO[];
  secondaryProgramCourses: SecondaryProgramCourseDTO[];
  fromSecondaryProgramCourse: boolean;
}

interface SecondaryCourseDetailsPageProps {
}

class SecondaryCourseDetailsPage extends React.Component<SecondaryCourseDetailsPageProps & RouteComponentProps<RouteObject>, SecondaryCourseDetailsPageState> {
  private readonly _secondaryCourseDetailsRef = React.createRef<SecondaryCourseDetailsForm>();

  constructor(props: SecondaryCourseDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      id: Guid.Empty(),
      academicYears: [],
      academicYear: 0,
      secondaryCourseId: 0,
      fromAcademicYearList: false,
      fromSelfStudies: false,
      secondaryCourses: [],
      secondaryPrograms: [],
      districts: [],
      regions: [],
      secondaryProgramCourses: [],
      fromSecondaryProgramCourse: false
    };
  }

  componentDidMount() {
    const secondaryCourseId = ParameterUtil.getPathPart(this.props.match, 'id');
    const academicYear = ParameterUtil.getParameter('academicYear');
    const fromAcademicYearList = ParameterUtil.getParameter('fromAcademicYearList');
    const fromSelfStudies = ParameterUtil.getParameter('fromSelfStudies');
    const fromSecondaryProgramCourse = ParameterUtil.getParameter('fromSecondaryProgramCourse');
    this.setState({
      academicYear: Number.parseInt(academicYear),
      fromSelfStudies: fromSelfStudies == 'true',
      fromAcademicYearList: fromAcademicYearList == 'true',
      fromSecondaryProgramCourse: fromSecondaryProgramCourse == 'true' ? true : false
    });

    if (this.props.match.path.split('/').findIndex(x => x == 'new') > -1) {
      this.setState({ isEditing: true, isNew: true, loading: false });
      const loaders = [];
      loaders.push(this.loadAcademicYears());
      loaders.push(this.loadDistrict(academicYear));
      loaders.push(this.loadRegionalPlanningPartners(academicYear));
      loaders.push(this.loadSecondaryPrograms(academicYear));
      loaders.push(this.loadSecondaryProgramCourse(academicYear));
      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
    else {
      const loaders = [];
      loaders.push(this.loadSecondaryCourse(secondaryCourseId));
      loaders.push(this.loadAcademicYears());
      loaders.push(this.loadDistrict(academicYear));
      loaders.push(this.loadRegionalPlanningPartners(academicYear));
      loaders.push(this.loadSecondaryPrograms(academicYear));
      loaders.push(this.loadSecondaryProgramCourse(academicYear));

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  private loadRegionalPlanningPartners = (academicYear: any) => {
    RegionalPlanningPartnerApiService.getRegionalPlanningPartners(academicYear)
      .then((results: GetRegionalPlanningPartnersHandler.Result) => {
        if (results) {
          this.setState({
            regions: results.regions ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadSecondaryProgramCourse = (academicYear: any) => {
    const request = GetSecondaryProgramCoursesHandler.Request.create({
      academicYearId: academicYear,
    });
    SecondaryProgramCourseApiService.getSecondaryProgramCourses(request)
      .then((results: GetSecondaryProgramCoursesHandler.Result) => {
        if (results) {
          this.setState({
            secondaryProgramCourses: results.secondaryProgramCourse ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadSecondaryPrograms = (academicYear: any) => {
    const request = GetSecondaryProgramsHandler.Request.create({
      academicYearId: academicYear,
    });
    SecondaryProgramApiService.getSecondaryPrograms(request)
      .then((results: GetSecondaryProgramsHandler.Result) => {
        if (results) {
          this.setState({
            secondaryPrograms: results.secondaryPrograms ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadDistrict = (academicYear: any) => {
    const request = GetDistrictsHandler.Request.create({
      academicYearId: academicYear
    });
    DistrictApiService.getDistricts(request)
      .then((results: GetDistrictsHandler.Result) => {
        if (results) {
          this.setState({
            districts: results.districts ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadSecondaryCourses = (secondaryCourseId: any, academicYear: any) => {
    const request = GetSecondaryCoursesHandler.Request.create({
      academicYearId: academicYear,
      secondaryCourseId: secondaryCourseId
    });

    SecondaryCourseApiService.getSecondaryCourses(request)
      .then((results: GetSecondaryCoursesHandler.Result) => {
        if (results) {
          this.setState({
            secondaryCourses: results.secondaryCourse ?? []
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadSecondaryCourse = (id: string, academicYear?: any, secondaryCourseId?: any) => {
    const request = GetSecondaryCourseDetailsHandler.Request.create({
      id: id,
      secondaryCourseId: secondaryCourseId,
      academicYear: academicYear
    });

    SecondaryCourseApiService.getSecondaryCourseDetails(request)
      .then((results: GetSecondaryCourseDetailsHandler.Result) => {
        if (results.secondaryCourse) {
          this.setState({
            secondaryCourse: results.secondaryCourse,
            id: results.secondaryCourse.id ?? Guid.Empty(),
            secondaryCourseId: results.secondaryCourse.secondaryCourseId,
            isNew: false,
            isEditing: false
          });

          if (this.state.secondaryCourses && this.state.secondaryCourses.length == 0) {
            this.loadSecondaryCourses(results.secondaryCourse.secondaryCourseId, results.secondaryCourse.academicYear);
          }
        }
        else {
          this.setState({
            isNew: true,
            id: Guid.Empty(),
            secondaryCourse: undefined,
            isEditing: true
          }, () => this._secondaryCourseDetailsRef.current?.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAcademicYears = () => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
          });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._secondaryCourseDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private secondaryCourseDetailsPageSaved = (secondaryCourseId: string) => {
    this.setState({ isEditing: false, isNew: false });
    if (secondaryCourseId != this.state.id) {
      HistoryUtil.push(Routes.generate(Routes.SECONDARY_COURSE_DETAILS, { id: secondaryCourseId }, { academicYearId: this.state.academicYear, fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList }));
      this.loadSecondaryCourse(secondaryCourseId);
    }
    else {
      this.loadSecondaryCourse(secondaryCourseId);
    }
  }

  private deleteConfirmation = (id: string, academicYearId: number) => {
    antd.Modal.confirm({
      title: 'Are you sure you want to delete this course?',
      okText: 'Delete',
      onOk: () => this.deleteSecondaryCourse(id, academicYearId)
    });
  }

  private deleteSecondaryCourse = (secondaryCourseId: string, academicYearId: number) => {
    this.setState({ submitting: true });

    const request = DeleteSecondaryCourseHandler.Request.create({
      secondaryCourseId: secondaryCourseId,
      academicYearId: academicYearId
    });

    SecondaryCourseApiService.deleteSecondaryCourse(request)
      .then((result: DeleteSecondaryCourseHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          antd.message.success('Course was removed.');
        }
        else {
          antd.message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        antd.message.error('Course could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToListing();
      });
  }

  goToListing = () => {
    if (this.state.fromSelfStudies) {
      HistoryUtil.push(Routes.generate(Routes.SECONDARY_COURSES, { academicYearId: this.state.academicYear }, { fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList }));
    }
    else {
      if (CurrentUser.Get() == null || this.state.fromSecondaryProgramCourse) {
        HistoryUtil.goBack();
      }
      else {
        HistoryUtil.push(Routes.generate(Routes.ALL_SECONDARY_COURSES));
      }
    }
  }

  private onSelect = (value: any) => {
    this.setState({ academicYear: value, id: Guid.Empty() });
    this.loadSecondaryCourse(Guid.Empty(), value, this.state.secondaryCourseId);
  }

  render() {
    const secondaryProgramCourse = this.state.secondaryProgramCourses.find(x => x.courseIdentifier == this.state.secondaryCourse?.secondaryCourseId && x.academicYear == this.state.academicYear);
    const secondaryProgram = this.state.secondaryPrograms.find(x => x.secondaryProgramId == secondaryProgramCourse?.programIdentifier && x.academicYear == this.state.academicYear);
    const district = this.state.districts.find(x => x.id == secondaryProgram?.districtId);
    const title = this.state.loading ? '...' : this.state.isNew ? 'New Course' : this.state.secondaryCourse?.courseName ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToListing}
          breadcrumbs={this.state.fromSecondaryProgramCourse ? Breadcrumbs.secondaryCourseDetailFromSecondaryPRogram(title, this.state.id, this.state.academicYear, this.state.fromAcademicYearList, this.state.fromSelfStudies, district?.id ?? Guid.Empty(), district?.name ?? '', secondaryProgram?.id ?? Guid.Empty(), ((secondaryProgram?.secondaryCipNumber?.code ?? '') + ' - ' + secondaryProgram?.secondaryCipNumber?.description) ?? '') : Breadcrumbs.secondaryCourseDetailPage(title, this.state.id, this.state.academicYear, this.state.fromAcademicYearList, this.state.fromSelfStudies, district?.id ?? Guid.Empty(), district?.name ?? '', secondaryProgram?.id ?? Guid.Empty(), ((secondaryProgram?.secondaryCipNumber?.code ?? '') + ' - ' + secondaryProgram?.secondaryCipNumber?.description) ?? '')}
          extra={this.renderMoreActions()}
        />

        {this.renderDetailsCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    const editButton = this.state.id == Guid.Empty() ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_COURSE]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <antd.Card title="Details" extra={editButton} >
        <SecondaryCourseDetailsForm
          ref={this._secondaryCourseDetailsRef}
          secondaryCourseId={this.state.id}
          readonly={CurrentUser.Get() == null}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.secondaryCourseDetailsPageSaved}
          academicYear={this.state.academicYear} />
      </antd.Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <antd.Menu>
        < antd.Menu.Item title='Delete Course' key={this.state.id} onClick={() => this.deleteConfirmation(this.state.id, this.state.academicYear)}  >
          Delete Course
        </antd.Menu.Item>
      </antd.Menu>
    );
    return (
      <>
        <label title={'academicYear'} htmlFor={'academicYear'} />
        <Dropdown
          id='academicYear'
          value={this.state.academicYear}
          onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.secondaryCourses.map(x => { return this.renderSecondaryCoursesAcademicYear(x); })}</Dropdown>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_COURSE]}>
          {this.state.isNew ? null
            : <antd.Dropdown overlay={menu}>
              <antd.Button size='large' type='link' >
                <MoreOutlined />
              </antd.Button>
            </antd.Dropdown>}
        </AuthorizedContent>
      </>
    );
  }

  renderSecondaryCoursesAcademicYear(secondaryCourseAcademicYear: SecondaryCourseDTO) {
    return <antd.Select.Option key={secondaryCourseAcademicYear.secondaryCourseId} value={secondaryCourseAcademicYear.academicYear}>{secondaryCourseAcademicYear.academicYear}</antd.Select.Option>
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <antd.Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</antd.Select.Option>
  }
}

export default withRouter(SecondaryCourseDetailsPage);
