// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import AwardTypeDTO from './AwardTypeDTO';
import InstructionalLevelDTO from './InstructionalLevelDTO';
import TypeOfProgramDTO from './TypeOfProgramDTO';
import SpecialEmphasisDTO from './SpecialEmphasisDTO';
import ObjectPurposeDTO from './ObjectPurposeDTO';
import ProgramModificationProgramAwardInstitutionLocationDTO from './ProgramModificationProgramAwardInstitutionLocationDTO';
import ProgramModificationProgramAwardThirdPartyCredentialDTO from './ProgramModificationProgramAwardThirdPartyCredentialDTO';
import ProgramModificationProgramAwardModeOfDeliveryDTO from './ProgramModificationProgramAwardModeOfDeliveryDTO';
import ProgramModificationProgramTermDTO from './ProgramModificationProgramTermDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramModificationProgramAwardDTO extends EntitySelectorDTO { 
	id: string | null;
	title: string | null;
	description: string | null;
	order: number;
	programModificationChangeRequestId: string | null;
	awardTypeId: number | null | null;
	awardType: AwardTypeDTO | null;
	instructionalLevelId: number | null | null;
	instructionalLevel: InstructionalLevelDTO | null;
	typeOfProgramId: number | null | null;
	typeOfProgram: TypeOfProgramDTO | null;
	specialEmphasisId: number | null | null;
	specialEmphasis: SpecialEmphasisDTO | null;
	objectPurposeId: number | null | null;
	objectPurpose: ObjectPurposeDTO | null;
	programAwardInstitutionLocationIds: string[];
	programModificationProgramAwardInstitutionLocations: ProgramModificationProgramAwardInstitutionLocationDTO[] | null;
	thirdPartyCredentials: ProgramModificationProgramAwardThirdPartyCredentialDTO[] | null;
	prerequisite: boolean;
	totalWeeks: number | null | null;
	totalCredits: number | null | null;
	programAwardModesOfDeliveryIds: number[];
	programAwardModesOfDelivery: ProgramModificationProgramAwardModeOfDeliveryDTO[] | null;
	terms: ProgramModificationProgramTermDTO[] | null;
}

const ProgramModificationProgramAwardDTO = {
    className: 'ProgramModificationProgramAwardDTO',
    create: (initValues?: Partial<ProgramModificationProgramAwardDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			title: "",
			description: "",
			order: 0,
			programModificationChangeRequestId: '00000000-0000-0000-0000-000000000000',
			awardTypeId: null,
			awardType: null,
			instructionalLevelId: null,
			instructionalLevel: null,
			typeOfProgramId: null,
			typeOfProgram: null,
			specialEmphasisId: null,
			specialEmphasis: null,
			objectPurposeId: null,
			objectPurpose: null,
			programAwardInstitutionLocationIds: [],
			programModificationProgramAwardInstitutionLocations: [],
			thirdPartyCredentials: [],
			prerequisite: false,
			totalWeeks: null,
			totalCredits: null,
			programAwardModesOfDeliveryIds: [],
			programAwardModesOfDelivery: [],
			terms: [],
        },
        initValues);
    },
	id: 'id',
	title: 'title',
	description: 'description',
	order: 'order',
	programModificationChangeRequestId: 'programModificationChangeRequestId',
	awardTypeId: 'awardTypeId',
	awardType: 'awardType',
	instructionalLevelId: 'instructionalLevelId',
	instructionalLevel: 'instructionalLevel',
	typeOfProgramId: 'typeOfProgramId',
	typeOfProgram: 'typeOfProgram',
	specialEmphasisId: 'specialEmphasisId',
	specialEmphasis: 'specialEmphasis',
	objectPurposeId: 'objectPurposeId',
	objectPurpose: 'objectPurpose',
	programAwardInstitutionLocationIds: 'programAwardInstitutionLocationIds',
	programModificationProgramAwardInstitutionLocations: 'programModificationProgramAwardInstitutionLocations',
	thirdPartyCredentials: 'thirdPartyCredentials',
	prerequisite: 'prerequisite',
	totalWeeks: 'totalWeeks',
	totalCredits: 'totalCredits',
	programAwardModesOfDeliveryIds: 'programAwardModesOfDeliveryIds',
	programAwardModesOfDelivery: 'programAwardModesOfDelivery',
	terms: 'terms',
};

export default ProgramModificationProgramAwardDTO;