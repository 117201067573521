import { message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import * as AddInstitutionUserHandler from '../../../handlerModels/AddInstitutionUserHandler';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import InstitutionUsersApiService from '../../../api/InstitutionUsersApiService';
import InstitutionDTO from '../../../models/InstitutionDTO';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationUtil from '../../../utils/ValidationUtil';

interface AddToInstitutionFormState extends BaseFormState {
  institutions: InstitutionDTO[];
}

interface AddToInstitutionFormProps extends BaseFormProps {
  onSuccess?: () => void;
  userId?: string;
}

class AddToInstitutionForm extends React.Component<AddToInstitutionFormProps, AddToInstitutionFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(AddInstitutionUserHandler.Request.institutionId, {
      required: true,
      name: AddInstitutionUserHandler.Request.institutionId,
      label: 'Please select an institution',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ],
    });

  constructor(props: AddToInstitutionFormProps) {
    super(props);

    this.state = {
      institutions: []
    };
  }

  componentDidMount() {
    const loaders = [];

    loaders.push(this.loadInstitutions());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      });
  }

  public submit = () => {
    this.handleSubmit();
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = AddInstitutionUserHandler.Request.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    request.userId = this.props.userId ?? Guid.Empty();

    InstitutionUsersApiService.addInstitutionUser(request)
      .then((result: AddInstitutionUserHandler.Result) => {
        this.setState({
          submitted: true
        });

        if (result?.succeeded) {
          message.success('User Added');

          if (this.props.onSuccess) {
            this.props.onSuccess();
          }

          this._formRef.current?.resetFields();

          this.setState({ altered: false });

          if (this.props.onSuccess) {
            this.props.onSuccess();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Error Adding User');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Adding User');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <Form.Item
            {...this._formItems.get(AddInstitutionUserHandler.Request.institutionId)}
            {...ValidationUtil.getValidation(AddInstitutionUserHandler.Request.institutionId, this.state.fieldErrors, this.state.submitted)}>
            <Select disabled={this.state.loading || this.state.submitting}>
              {this.state.institutions.map(x => { return this.renderInstitution(x) })}
            </Select>
          </Form.Item>
        </Form>
      </Space >
    );
  }

  renderInstitution(institution: InstitutionDTO) {
    if (institution.id) {
      return <Select.Option key={institution.id} value={institution.id}>{institution.name}</Select.Option>
    }
  }
}

export default AddToInstitutionForm;
