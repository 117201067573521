// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveDistrictContactHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import DistrictContactDTO from '../models/DistrictContactDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    districtId: string | null | null;
    academicYearId: number;
    districtContact: DistrictContactDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            districtId: null,
            academicYearId: 0,
            districtContact: null,
        },
        initValues);
    },
    districtId: 'districtId',
    academicYearId: 'academicYearId',
    districtContact: 'districtContact',
}

interface Result extends ResultDTO {
    districtContact: DistrictContactDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            districtContact: null,
        },
        initValues);
    },
    districtContact: 'districtContact',
}

export { Request, Result };