import * as React from 'react';
import ProgramClassificationApiService from '../../../api/ProgramClassificationApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import ProgramClassificationDTO from '../../../models/ProgramClassificationDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface ProgramClassificationDataTableProps extends BaseFormProps {
}

interface ProgramClassificationDataTableState extends BaseDataTableState<ProgramClassificationDTO>, BaseFormState {
}

class ProgramClassificationDataTable extends React.Component<ProgramClassificationDataTableProps, ProgramClassificationDataTableState> {
  private _dataTable: DataTable<ProgramClassificationDTO> | undefined;

  constructor(props: ProgramClassificationDataTableProps) {
    super(props);
    this.state = {
    };

  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Name',
        dataIndex: ProgramClassificationDTO.name,
        sorter: true,
        filterType: FilterType.Text,
        width: 15
      }
    ] as DataTableColumnProps<any>[];
  };

  private openProgramClassification = (record: ProgramClassificationDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.PROGRAM_CLASSIFICATION_DETAILS, { id: record.id }, {}));
    }
  }

  private addProgramClassification = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_PROGRAM_CLASSIFICATION));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return ProgramClassificationApiService.getProgramClassificationDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Program_Classification_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM_CLASSIFICATION])) {
      actionButtons.push(DataTableButtonUtil.Default('New Program Classification', () => this.addProgramClassification()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openProgramClassification}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => ProgramClassificationApiService.getProgramClassificationTableData(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'program_classification_list' }} />
      </>
    );
  }
}

export default ProgramClassificationDataTable;
