import { message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import { useImperativeHandle, useState } from 'react';
import RoleType from '../../../consts/RoleType';
import RoleDTO from '../../../models/RoleDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import UserSearchInput from '../../general/UserSearchInput';
import Dropdown from '../../inputs/Dropdown';
import DistrictUserApiService from '../../../api/DistrictUserApiService';
import * as AddUserToDistrictHandler from '../../../handlerModels/AddUserToDistrictHandler';
import Validator from '../../../models/Validator';

interface AddUserToDistrictFormProps extends BaseFormProps {
  districtIdentifier: number;
  onSubmit?: () => void;
}

const AddUserToDistrictForm = React.forwardRef((props: AddUserToDistrictFormProps, ref) => {
  const _formRef = React.createRef<FormInstance>();

  const _formItems = new Map<string, FormItemProps>()
    .set(AddUserToDistrictHandler.Request.userId, {
      label: 'User',
      required: true,
      name: AddUserToDistrictHandler.Request.userId,
      rules: [
        ValidationRuleUtil.required()
      ]
    })
    .set(AddUserToDistrictHandler.Request.roleIds, {
      label: 'District Roles',
      required: true,
      name: AddUserToDistrictHandler.Request.roleIds,
      rules: [
        ValidationRuleUtil.required()
      ]
    });

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(),
    reset: () => reset()
  }));

  const reset = () => {
    setFieldErrors(null);
    setSubmitted(false);
    _formRef.current?.resetFields();
  }

  const handleSubmit = () => {
    setSubmitted(true);

    const model = _formRef.current?.getFieldsValue();
    const request = AddUserToDistrictHandler.Request.create({
      districtIdentifier: props.districtIdentifier,
      userId: model.userId.id,
      roleIds: model.roleIds
    });

    DistrictUserApiService.addDistrictUser(request)
      .then((result: AddUserToDistrictHandler.Result) => {
        if (result?.succeeded) {
          message.success('User Added Successfully!');
          setSubmitted(false);
          setFieldErrors(null);

          if (props.onSubmit) {
            props.onSubmit();
          }
        }
        else {
          setFieldErrors(result.fieldErrors);
          message.error('Error Adding User');
        }
      })
      .catch(() => {
        message.error('Error Adding User');
      });
  }

  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({} as ({ [key: string]: Validator[]; } | null));
  const [, setError] = useState(false);
  const [roles, setRoles] = useState([] as RoleDTO[]);

  React.useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (!roles || roles.length == 0) {
      loaders.push(loadRoles());
    }

    Promise.all(loaders).then(() => {
      setLoading(false);
    });
  }

  const loadRoles = () => {
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        if (results) {
          setRoles(results);
        }
      }).catch(() => {
        setError(true);
      });
  }

  const renderRole = (role: RoleDTO) => {
    if (role.id && role.roleTypeId == RoleType.DISTRICT) {
      return <Select.Option key={role.id ?? Guid.Empty()} value={role.id ?? Guid.Empty()}> {role.name}</Select.Option>
    }
  }

  if (loading) {
    return <Skeleton active />
  }
  else {

    return (
      <Form
        ref={_formRef}
        layout="vertical"
        requiredMark={true}
        onFinish={handleSubmit}>
        <Space size="small" direction="vertical">
          <Form.Item
            {..._formItems.get(AddUserToDistrictHandler.Request.userId)}
            {...ValidationUtil.getValidation(AddUserToDistrictHandler.Request.userId, fieldErrors, submitted)}>
            <UserSearchInput />
          </Form.Item>

          <FormItem
            {..._formItems.get(AddUserToDistrictHandler.Request.roleIds)}
            {...ValidationUtil.getValidation(AddUserToDistrictHandler.Request.roleIds, fieldErrors, submitted)}  >
            <Dropdown dropdownMatchSelectWidth={false}
              mode="multiple">
              {roles.map(x => { return renderRole(x) })}
            </Dropdown>
          </FormItem>
        </Space >
      </Form >
    );
  }

})

AddUserToDistrictForm.displayName = 'AddUserToDistrictForm'
export default AddUserToDistrictForm;