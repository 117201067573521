import { Card, Space } from 'antd';
import * as React from 'react';
import ChangeRequestType from '../../../consts/ChangeRequestType';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import ProgramModificationProgramAwardDTO from '../../../models/ProgramModificationProgramAwardDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ChangeRequestAdditionalDetailsForm from '../../forms/postSecondary/ChangeRequestAdditionalDetailsForm';
import ProgramModificationChangeRequestStep1Form from '../../forms/postSecondary/ProgramModificationChangeRequestStep1Form';
import ProgramModificationChangeRequestStep3Form from '../../forms/postSecondary/ProgramModificationChangeRequestStep3Form';
import ProgramModificationChangeRequestStep4Form from '../../forms/postSecondary/ProgramModificationChangeRequestStep4Form';
import ProgramModificationChangeRequestStep5Form from '../../forms/postSecondary/ProgramModificationChangeRequestStep5Form';

interface ProgramModificationChangeRequestReviewFormState extends BaseFormState {
}

interface ProgramModificationChangeRequestReviewFormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequest: ChangeRequestDTO;
  onIsValid?: (isValid: boolean) => void;
}

class ProgramModificationChangeRequestDetails extends React.Component<ProgramModificationChangeRequestReviewFormProps, ProgramModificationChangeRequestReviewFormState> {
  constructor(props: ProgramModificationChangeRequestReviewFormProps) {
    super(props);

    this.state = {};
  }

  public render = () => {
    return (
      <Space size="large" direction="vertical">
        <Card title="Program Plan" type='inner'>
          <ProgramModificationChangeRequestStep1Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>

        <Card title="Award Details" type='inner'>
          {this.renderAwards()}
        </Card>

        {
          this.props.changeRequest.changeRequestTypeId == ChangeRequestType.PROGRAMMODIFICATION ?
            <Card title="Advisory Board and Curriculum Committee" type='inner'>
              <ProgramModificationChangeRequestStep5Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
            </Card>
            : null
        }

        <Card title='Additional Information' type='inner'>
          <ChangeRequestAdditionalDetailsForm loading={this.props.loading} changeRequest={this.props.changeRequest} readonly={true} fieldErrors={this.props.fieldErrors} submitted={true} />
        </Card>

      </Space>
    );
  }
  renderAwards() {
    let awardCount = 0;
    if (this.props.changeRequest.id != Guid.Empty()) {
      return this.props.changeRequest.programModificationChangeRequest?.programModificationAwards?.map(x => this.renderAward(x, ++awardCount));
    }
  }
  renderAward(award: ProgramModificationProgramAwardDTO, awardCount: number) {
    return (
      <Space direction="vertical">
        <Card title={'Award ' + awardCount} type='inner' size="small">
          <ProgramModificationChangeRequestStep3Form loading={this.props.loading} changeRequestId={this.props.changeRequest.id} selectedInstitution={this.props.changeRequest.institutionId} awardId={award.id ?? Guid.Empty()} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
          <ProgramModificationChangeRequestStep4Form onIsValid={this.props.onIsValid} review={true} loading={this.props.loading} changeRequestId={this.props.changeRequest.id} awardId={award.id ?? Guid.Empty()} selectedInstitution={this.props.changeRequest.institutionId} readonly={true} changeRequestDetailsPage={this.props.changeRequestDetailsPage} />
        </Card>
      </Space>);
  }
}

export default ProgramModificationChangeRequestDetails;