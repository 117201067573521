import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ExternalInstitutionApiService from '../../../../api/ExternalInstitutionApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import ExternalInstitutionDetailsForm from '../../../../components/forms/postSecondary/ExternalInstitutionDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteExternalInstitutionHandler from '../../../../handlerModels/DeleteExternalInstitutionHandler';
import * as GetExternalInstitutionDetailsHandler from '../../../../handlerModels/GetExternalInstitutionDetailsHandler';
import ExternalInstitutionDTO from '../../../../models/ExternalInstitutionDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface ExternalInstitutionDetailsPageState extends BaseFormState {
  externalInstitutionId: number;
  externalInstitution?: ExternalInstitutionDTO;
}

interface ExternalInstitutionDetailsPageProps {
}

class ExternalInstitutionDetailsPage extends React.Component<ExternalInstitutionDetailsPageProps & RouteComponentProps<RouteObject>, ExternalInstitutionDetailsPageState> {
  private readonly _externalInstitutionDetailsRef = React.createRef<ExternalInstitutionDetailsForm>();

  constructor(props: ExternalInstitutionDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      externalInstitutionId: 0
    };
  }

  componentDidMount() {
    const externalInstitutionId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (externalInstitutionId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const externalInstitutionIdNumber = Number.parseInt(externalInstitutionId);
      this.loadExternalInstitution(externalInstitutionIdNumber);
    }
  }

  private loadExternalInstitution = (externalInstitutionId: number) => {
    this.setState({ externalInstitutionId: externalInstitutionId });

    ExternalInstitutionApiService.getExternalInstitutionDetails(externalInstitutionId)
      .then((results: GetExternalInstitutionDetailsHandler.Result) => {
        if (results.externalInstitution) {
          this.setState({ externalInstitution: results.externalInstitution });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this External Institution?',
      okText: 'Delete',
      onOk: () => this.deleteExternalInstitution(id)
    });
  }

  private deleteExternalInstitution = (externalInstitutionId: number) => {
    this.setState({ submitting: true });

    const request = DeleteExternalInstitutionHandler.Request.create({
      externalInstitutionId: externalInstitutionId,
    });

    ExternalInstitutionApiService.deleteExternalInstitution(request)
      .then((result: DeleteExternalInstitutionHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('External Institution was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('External Institution could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToExternalInstitution();
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._externalInstitutionDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private externalInstitutionDetailsSaved = (externalInstitutionId: number) => {
    this.setState({ isEditing: false });
    if (externalInstitutionId != this.state.externalInstitutionId) {
      HistoryUtil.replace(Routes.generate(Routes.EXTERNAL_INSTITUTIONS_DETAILS, { id: externalInstitutionId }));
    }
    else {
      this.loadExternalInstitution(externalInstitutionId);
    }
  }

  private goToExternalInstitution = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.externalInstitutionId == 0 ? 'New External Institution' : this.state.externalInstitution?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToExternalInstitution}
          breadcrumbs={Breadcrumbs.externalIntitutionDetails(title, this.state.externalInstitutionId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.externalInstitutionId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_EXTERNAL_INSTITUTIONS]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <ExternalInstitutionDetailsForm
          ref={this._externalInstitutionDetailsRef}
          externalInstitutionId={this.state.externalInstitutionId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.externalInstitutionDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete External Institution' key={this.state.externalInstitutionId} onClick={() => this.deleteConfirmation(this.state.externalInstitutionId)}  >
          Delete External Institution
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_EXTERNAL_INSTITUTIONS]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(ExternalInstitutionDetailsPage);
