// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class AcademicYearStatusType {
    public static readonly UPCOMING: number = 1;
    public static readonly OPEN: number = 2;
    public static readonly CLOSED: number = 3;
    public static readonly SELFSTUDYPREPARATION: number = 4;
    public static readonly REVIEWSELFSTUDYPROGRAMS: number = 5;
    public static readonly SELFSTUDYSUBMITTED: number = 6;
    public static readonly RELEASEDAPPROVALCOMMENTS: number = 7;
}
