// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import CategoryDTO from '../models/CategoryDTO';
import * as GetCategoryDetailsHandler from '../handlerModels/GetCategoryDetailsHandler';
import * as SaveCategoryDetailsHandler from '../handlerModels/SaveCategoryDetailsHandler';
import * as DeleteCategoriesHandler from '../handlerModels/DeleteCategoriesHandler';

export class CategoriesApiService extends BaseApi {

    // post: api/categories
    public getCategoryTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<CategoryDTO>> {
        let url = `api/categories`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<CategoryDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/categories/export
    public exportCategoryList(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/categories/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/categories/${encodeURIComponent(categoryId)}
    public getCategoryDetails(categoryId: string): Promise<GetCategoryDetailsHandler.Result> {
        let url = `api/categories/${encodeURIComponent(categoryId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCategoryDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/categories/save
    public saveCategoryDetails(saveCategoryDetails: SaveCategoryDetailsHandler.Request): Promise<SaveCategoryDetailsHandler.Result> {
        let url = `api/categories/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveCategoryDetailsHandler.Result>(saveCategoryDetails, 'post', url, true, false);
    }

    // post: api/categories/deleteCategories
    public deleteCategories(deleteCategories: DeleteCategoriesHandler.Request): Promise<DeleteCategoriesHandler.Result> {
        let url = `api/categories/deleteCategories`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteCategoriesHandler.Result>(deleteCategories, 'post', url, true, false);
    }
}
var service = new CategoriesApiService();
export default service;
