import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import RacialEthnicGroupApiService from '../../api/RacialEthnicGroupApiService';
import FeatureFlag from '../../consts/FeatureFlag';
import * as GetRacialEthnicGroupDetailsHandler from '../../handlerModels/GetRacialEthnicGroupDetailsHandler';
import * as SaveRacialEthnicGroupDetailsHandler from '../../handlerModels/SaveRacialEthnicGroupDetailsHandler';
import RacialEthnicGroupDTO from '../../models/RacialEthnicGroupDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import LookupsUtil from '../../utils/LookupsUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import AuthorizedContent from '../AuthorizedContent';
import ResetButton from '../buttons/ResetButton';
import SaveButton from '../buttons/SaveButton';

interface RacialEthnicGroupDetailsFormState extends BaseFormState {
  racialEthnicGroupId: number;
  racialEthnicGroup: RacialEthnicGroupDTO | null;
}

interface RacialEthnicGroupDetailsFormProps extends BaseFormProps {
  racialEthnicGroupId: number;
  onSave?: (racialEthnicGroupId: number) => void;
}

class RacialEthnicGroupDetailsForm extends React.Component<RacialEthnicGroupDetailsFormProps, RacialEthnicGroupDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(RacialEthnicGroupDTO.name, {
      required: true,
      name: RacialEthnicGroupDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    });

  constructor(props: RacialEthnicGroupDetailsFormProps) {
    super(props);

    this.state = {
      racialEthnicGroupId: 0,
      racialEthnicGroup: RacialEthnicGroupDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadRacialEthnicGroup();
  }

  componentDidUpdate(prevProps: RacialEthnicGroupDetailsFormProps) {
    if (this.props.racialEthnicGroupId && this.props.racialEthnicGroupId != prevProps.racialEthnicGroupId) {
      this.setState({ racialEthnicGroupId: this.props.racialEthnicGroupId });
      this.loadRacialEthnicGroup();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadRacialEthnicGroup() {
    if (this.props.racialEthnicGroupId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.racialEthnicGroupId) {
      RacialEthnicGroupApiService.getRacialEthnicGroupDetails(this.props.racialEthnicGroupId)
        .then((results: GetRacialEthnicGroupDetailsHandler.Result) => {
          if (results && results.racialEthnicGroup) {
            this.setState({ racialEthnicGroupId: results.racialEthnicGroup.id, racialEthnicGroup: results.racialEthnicGroup, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ racialEthnicGroup: RacialEthnicGroupDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveRacialEthnicGroupDetailsHandler.Request.create({
      racialEthnicGroupId: this.state.racialEthnicGroupId,
      racialEthnicGroup: RacialEthnicGroupDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    RacialEthnicGroupApiService.saveRacialEthnicGroupDetails(request)
      .then((result: SaveRacialEthnicGroupDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          racialEthnicGroup: result?.racialEthnicGroup,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.racialEthnicGroup) {
            this.props.onSave(result.racialEthnicGroup.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(RacialEthnicGroupDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Special Emphasis could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Special Emphasis could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(RacialEthnicGroupDTO.name)}
            initialValue={this.state.racialEthnicGroup?.name}
            {...ValidationUtil.getValidation(RacialEthnicGroupDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SPECIAL_EMPHASIS]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default RacialEthnicGroupDetailsForm;
