import Modal from 'antd/lib/modal/Modal';
import * as React from 'react';
import CipsApiService from '../../../api/CipsApiService';
import CipNumberImportForm from '../../forms/postSecondary/CipNumberImportForm';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import CipNumberDataTableDTO from '../../../models/CipNumberDataTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TermDTO from '../../../models/TermDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import DataTable, { ColumnType, DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import DataTableCellRendererUtil from '../core/DataTableCellRendererUtil';

interface CipNumberDataTableProps extends BaseFormProps {
}

interface CipNumberDataTableState extends BaseDataTableState<CipNumberDataTableDTO>, BaseFormState {
  importCipNumbers: boolean;
  importProcessing: boolean | false;
  terms: TermDTO[];
}

class CipNumberDataTable extends React.Component<CipNumberDataTableProps, CipNumberDataTableState> {
  private _dataTable: DataTable<CipNumberDataTableDTO> | undefined;
  private readonly _cipNumberImportFormRef = React.createRef<CipNumberImportForm>();

  constructor(props: CipNumberDataTableProps) {
    super(props);
    this.state = {
      importCipNumbers: false,
      importProcessing: false,
      terms: []
    };
  }

  private handler = () => {
    this.setState({ importProcessing: false });
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadTerms()
  }

  private loadTerms = () => {
    return LookupsUtil.getAll<TermDTO>(TermDTO.className)
      .then((results: TermDTO[]) => {
        if (results) {
          this.setState({ terms: results ?? [], loading: false });
        }
      }).catch(() => {
        this.setState({ error: true, loading: false });
      });
  }


  private getColumnDefinitions = () => {
    return [
      {
        title: 'CIP',
        dataIndex: CipNumberDataTableDTO.display,
        sorter: true,
        filterType: FilterType.Text,
        width: 135
      },
      {
        title: 'Title',
        dataIndex: CipNumberDataTableDTO.title,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Transfer Major',
        dataIndex: CipNumberDataTableDTO.transferMajor,
        filterType: FilterType.BooleanRadio,
        columnType: ColumnType.Boolean,
        render: DataTableCellRendererUtil.BooleanYesEmpty,
      },
      {
        title: 'Non-Transfer Major',
        dataIndex: CipNumberDataTableDTO.nonTransferMajor,
        filterType: FilterType.BooleanRadio,
        columnType: ColumnType.Boolean,
        render: DataTableCellRendererUtil.BooleanYesEmpty,
      }
    ] as DataTableColumnProps<any>[];
  };

  private openCip = (record: CipNumberDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.CIP_DETAILS, { id: record.cipNumberId ?? Guid.Empty() }, {}));
    }
  }

  private addCip = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_CIP));
  }

  private showModal = () => {
    this.setState({
      importCipNumbers: true,
    });
  };

  private cancelImportCipNumbers = () => {
    this.setState({
      importCipNumbers: false,
    });
  };

  private submitImportCipNumbers = () => {
    this.setState({
      importProcessing: true
    });
    this._cipNumberImportFormRef.current?.submit();
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return CipsApiService.getCipssDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Cip_Number_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CIP_NUMBER])) {
      actionButtons.push(DataTableButtonUtil.Default('New CIP Number', () => this.addCip()));
      actionButtons.push(DataTableButtonUtil.Default('Import CIP Numbers', () => this.showModal()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openCip}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => CipsApiService.getCipTableData(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'cip_list' }} />
        {this.renderImportModal()}
      </>
    );
  }

  renderImportModal() {
    return (
      <Modal
        visible={this.state.importCipNumbers}
        title='Import'
        okText='Import'
        closable={true}
        okButtonProps={
          {
            disabled: this.state.importProcessing,
            loading: this.state.importProcessing,
            shape: 'round'
          }
        }
        cancelButtonProps={{ shape: 'round' }}
        onOk={this.submitImportCipNumbers}
        onCancel={this.cancelImportCipNumbers}>
        <CipNumberImportForm handler={this.handler} ref={this._cipNumberImportFormRef} />
      </Modal>
    );
  }
}

export default CipNumberDataTable;
