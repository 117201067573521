// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ChangeRequestTypeDTO from './ChangeRequestTypeDTO';
import ChangeRequestTypeApprovalFlowStepDTO from './ChangeRequestTypeApprovalFlowStepDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ChangeRequestTypeApprovalFlowDTO extends EntitySelectorDTO { 
	changeRequestTypeId: number;
	changeRequestType: ChangeRequestTypeDTO | null;
	name: string | null;
	changeRequestTypeApprovalFlowSteps: ChangeRequestTypeApprovalFlowStepDTO[] | null;
}

const ChangeRequestTypeApprovalFlowDTO = {
    className: 'ChangeRequestTypeApprovalFlowDTO',
    create: (initValues?: Partial<ChangeRequestTypeApprovalFlowDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			changeRequestTypeId: 0,
			changeRequestType: null,
			name: "",
			changeRequestTypeApprovalFlowSteps: [],
        },
        initValues);
    },
	changeRequestTypeId: 'changeRequestTypeId',
	changeRequestType: 'changeRequestType',
	name: 'name',
	changeRequestTypeApprovalFlowSteps: 'changeRequestTypeApprovalFlowSteps',
};

export default ChangeRequestTypeApprovalFlowDTO;