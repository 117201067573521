// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import UserDTO from './UserDTO';
import InstitutionDTO from './InstitutionDTO';
import WorkItemTypeDTO from './WorkItemTypeDTO';
import WorkItemStatusTypeDTO from './WorkItemStatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface WorkQueueItemDTO extends EntitySelectorDTO { 
	workItemId: string | null;
	userId: string | null;
	user: UserDTO | null;
	institutionId: string | null | null;
	institution: InstitutionDTO | null;
	workItemTypeId: number;
	workItemType: WorkItemTypeDTO | null;
	workItemStatusTypeId: number;
	workItemStatusType: WorkItemStatusTypeDTO | null;
	key: string | null;
	description: string | null;
	createdOn: moment.Moment | string;
	activeOn: moment.Moment | string;
	expiresOn: moment.Moment | string | null;
	completedOn: moment.Moment | string | null;
}

const WorkQueueItemDTO = {
    className: 'WorkQueueItemDTO',
    create: (initValues?: Partial<WorkQueueItemDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			workItemId: '00000000-0000-0000-0000-000000000000',
			userId: '00000000-0000-0000-0000-000000000000',
			user: null,
			institutionId: null,
			institution: null,
			workItemTypeId: 0,
			workItemType: null,
			workItemStatusTypeId: 0,
			workItemStatusType: null,
			key: "",
			description: "",
			createdOn: new Date(0).toISOString(),
			activeOn: new Date(0).toISOString(),
			expiresOn: null,
			completedOn: null,
        },
        initValues);
    },
	workItemId: 'workItemId',
	userId: 'userId',
	user: 'user',
	institutionId: 'institutionId',
	institution: 'institution',
	workItemTypeId: 'workItemTypeId',
	workItemType: 'workItemType',
	workItemStatusTypeId: 'workItemStatusTypeId',
	workItemStatusType: 'workItemStatusType',
	key: 'key',
	description: 'description',
	createdOn: 'createdOn',
	activeOn: 'activeOn',
	expiresOn: 'expiresOn',
	completedOn: 'completedOn',
};

export default WorkQueueItemDTO;