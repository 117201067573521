import { Alert, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import AreaEducationAgencyApiService from '../../../api/AreaEducationAgencyApiService';
import DistrictApiService from '../../../api/DistrictApiService';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetAreaEducationAgenciesHandler from '../../../handlerModels/GetAreaEducationAgenciesHandler';
import * as GetDistrictDetailsHandler from '../../../handlerModels/GetDistrictDetailsHandler';
import * as GetRegionalPlanningPartnersHandler from '../../../handlerModels/GetRegionalPlanningPartnersHandler';
import * as SaveDistrictDetailsHandler from '../../../handlerModels/SaveDistrictDetailsHandler';
import AreaEducationAgencyDTO from '../../../models/AreaEducationAgencyDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import CurrentUser from '../../../utils/CurrentUser';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SubmitButton from '../../buttons/SubmitButton';
import DistrictUserSearchInput from '../../general/DistrictUserSearchInput';
import ValueLabel from '../../general/ValueLabel';
import Dropdown from '../../inputs/Dropdown';
import ReadableTextBox from '../../inputs/ReadableTextBox';

interface DistrictDetailsFormState extends BaseFormState {
  district: DistrictDTO;
  regions: RegionalPlanningPartnerDTO[];
  areaEducationAgencies: AreaEducationAgencyDTO[];

}

interface DistrictDetailsFormProps extends BaseFormProps {
  districtId: string;
  academicYear?: number;
  readonly?: boolean;
  onSave?: (districtId: string) => void;
  modal?: boolean;
}

class DistrictDetailsForm extends React.Component<DistrictDetailsFormProps, DistrictDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(DistrictDTO.districtId, {
      name: DistrictDTO.districtId,
      label: 'Identifier',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    })
    .set(DistrictDTO.name, {
      required: true,
      name: DistrictDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(DistrictDTO.districtApprovalUser, {
      name: DistrictDTO.districtApprovalUser,
      label: 'District Approval User',
      rules: [
      ],
    })
    .set(DistrictDTO.regionIdentifier, {
      required: true,
      name: DistrictDTO.regionIdentifier,
      label: 'Regional Planning Partner',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(DistrictDTO.areaEducationAgencyIdentifier, {
      required: true,
      name: DistrictDTO.areaEducationAgencyIdentifier,
      label: 'AEA',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })

  constructor(props: DistrictDetailsFormProps) {
    super(props);

    this.state = {
      district: DistrictDTO.create({
        id: null,
        districtId: undefined,
        regionId: null,
        aeA_Id: null
      }),
      regions: [],
      areaEducationAgencies: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: DistrictDetailsFormProps) {
    if (this.props.districtId && this.props != prevProps) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];
    loaders.push(this.loadDistrict());

    if (!this.state.regions || this.state.regions.length == 0) {
      loaders.push(this.loadRegionalPlanningPartners());
    }

    if (!this.state.areaEducationAgencies || this.state.areaEducationAgencies.length == 0) {
      loaders.push(this.loadAreaEducationAgency());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadDistrict() {
    if (!this.state.loading) {
      if (this.props.districtId == Guid.Empty() || this.props.isNew) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.districtId && this.props.academicYear) {
      const request = GetDistrictDetailsHandler.Request.create({
        id: this.props.districtId
      });
      DistrictApiService.getDistrictDetails(request)
        .then((results: GetDistrictDetailsHandler.Result) => {
          if (results) {
            this.setState({
              district: results.district ?? DistrictDTO.create({
                id: this.props.districtId == Guid.Empty() ? undefined : this.props.districtId,
                districtId: undefined,
                regionId: undefined,
                aeA_Id: undefined
              })
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadRegionalPlanningPartners = () => {
    RegionalPlanningPartnerApiService.getRegionalPlanningPartners(this.props.academicYear ?? 0)
      .then((results: GetRegionalPlanningPartnersHandler.Result) => {
        if (results) {
          this.setState({
            regions: results.regions ?? []
          });
        }

        this.resetForm();
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadAreaEducationAgency = () => {
    AreaEducationAgencyApiService.getAreaEducationAgencies(this.props.academicYear ?? 0)
      .then((results: GetAreaEducationAgenciesHandler.Result) => {
        if (results) {
          this.setState({
            areaEducationAgencies: results.areaEducationAgency ?? []
          });
        }

        this.resetForm();
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadNew = () => {
    this.setState({
      loading: false, district: DistrictDTO.create({
        id: this.props.districtId == Guid.Empty() ? undefined : this.props.districtId,
        districtId: undefined,
        regionIdentifier: '',
        areaEducationAgencyIdentifier: ''
      })
    });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      () => this.handleSubmit(),
      () => this.setState({ error: true })
    );
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const request = SaveDistrictDetailsHandler.Request.create({
      districtId: this.props.districtId,
      district: DistrictDTO.create({
        ...(this._formRef ? (this._formRef.current as any).getFieldsValue() : null),
        academicYear: this.props.academicYear
      })
    });

    DistrictApiService.saveDistrictDetails(request)
      .then((result: SaveDistrictDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ district: result.district ?? DistrictDTO.create() });

          message.success('Saved');

          if (this.props.onSave && result.district?.id) {
            this.props.onSave(result.district.id);
            this.resetForm();
          }

        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('District could not be saved');
          this.setState({ loading: false, submitting: false });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results, loading: false, submitting: false });
        message.error('District could not be saved');
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const isPublic = CurrentUser.Get() == null;
    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.district}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={!isPublic}>
          <FormItem
            {...this._formItems.get(DistrictDTO.districtId)}
            {...ValidationUtil.getValidation(DistrictDTO.districtId, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(DistrictDTO.name)}
            {...ValidationUtil.getValidation(DistrictDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>


          <FormItem
            {...this._formItems.get(DistrictDTO.regionIdentifier)}
            {...ValidationUtil.getValidation(DistrictDTO.regionIdentifier, this.state.fieldErrors, this.state.submitted)}>
            {isPublic ? <ValueLabel text={this.state.regions.find(x => x.regionalPlanningPartnerIdentifier == this.state.district.regionIdentifier)?.name} /> :
              <Dropdown dropdownMatchSelectWidth={false} readOnly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting}> {this.state.regions.sort((a, b) => a.regionalPlanningPartnerIdentifier - b.regionalPlanningPartnerIdentifier).map(x => this.renderRegion(x))}</Dropdown>}
          </FormItem>

          <FormItem
            {...this._formItems.get(DistrictDTO.areaEducationAgencyIdentifier)}
            {...ValidationUtil.getValidation(DistrictDTO.areaEducationAgencyIdentifier, this.state.fieldErrors, this.state.submitted)}>
            {isPublic ? <ValueLabel text={this.state.areaEducationAgencies.find(x => x.areaEducationAgencyIdentifier == this.state.district.areaEducationAgencyIdentifier)?.name} /> :
              <Dropdown dropdownMatchSelectWidth={false} readOnly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting}> {this.state.areaEducationAgencies.sort((a, b) => a.areaEducationAgencyIdentifier - b.areaEducationAgencyIdentifier).map(x => this.renderAreaEducationAgency(x))}</Dropdown>}
          </FormItem>

          {this.props.modal ? null :
            <Form.Item
              {...this._formItems.get(DistrictDTO.districtApprovalUser)}
              {...ValidationUtil.getValidation(DistrictDTO.districtApprovalUser, this.state.fieldErrors, this.state.submitted)}>
              {isPublic ? <ValueLabel text={this.state.district.districtApprovalUser?.fullName} /> :
                <DistrictUserSearchInput
                  value={this.state.district.districtApprovalUser} districtIdentifier={this.state.district.districtId ?? 0}
                  academicYear={this.props.academicYear ?? 0}
                  disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />}
            </Form.Item>
          }
        </Form>

        {this.renderSave()}
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing && !this.props.modal) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_DISTRICT]}>
          <Space direction={'horizontal'} >
            <SubmitButton onClick={this.handleSubmit} disabled={!FormUtil.canSubmit(this._formRef, this._formItems)} submitting={this.state.submitting} />
            <ResetButton disabled={!FormUtil.hasChanged(this._formRef, this._formItems)} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderRegion(region: RegionalPlanningPartnerDTO) {
    if (region.id) {
      return <Select.Option title={region.display ?? ''} key={region.id ?? Guid.Empty()} value={region.regionalPlanningPartnerIdentifier ?? Guid.Empty()}>{region?.display ?? ''}</Select.Option>
    }
  }

  renderAreaEducationAgency(areaEducationAgency: AreaEducationAgencyDTO) {
    if (areaEducationAgency.id) {
      return <Select.Option title={areaEducationAgency.display ?? ''} key={areaEducationAgency.id ?? Guid.Empty()} value={areaEducationAgency.areaEducationAgencyIdentifier ?? Guid.Empty()}>{areaEducationAgency?.display}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default DistrictDetailsForm;
