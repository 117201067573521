import moment from 'moment';
import YearTermDTO from '../models/YearTermDTO';
import DateTimeUtil from './DateTimeUtil';
import Guid from './Guid';
import { TermYear } from '../utils/TermYearUtil';
import TermDTO from '../models/TermDTO';

export default class StatusUtil {
  static isActive(install: YearTermDTO | null, retire: YearTermDTO | null): string {
    if (retire && retire?.termId != Guid.Empty()) {
      const retireDate = moment(retire.term?.startDate);

      if ((retire?.year ?? 0) < DateTimeUtil.getCurrentYear() || (install?.year ?? 0) > (retire?.year ?? 0)) {
        return 'Inactive';
      }

      const retireMonth = Number.parseInt(retireDate.format('M'));
      const retireDay = Number.parseInt(retireDate.format('D'));

      if (retire?.year == DateTimeUtil.getCurrentYear() &&
        (retireMonth < DateTimeUtil.getCurrentMonth() ||
          (retireMonth == DateTimeUtil.getCurrentMonth() && retireDay < DateTimeUtil.getCurrentDay()))) {
        return 'Inactive';
      }
    }

    return 'Active';
  }

  static isActiveFromYearTerm(install: YearTermDTO | null, retire: YearTermDTO | null, yearTerm: TermYear | null, terms: TermDTO[]): string { // fix this up for term
    if (yearTerm) {
      const selectedTerm = terms.find(x => x.id == yearTerm.termId);

      if (retire && retire?.termId != Guid.Empty()) {
        const retireDate = moment(retire.term?.startDate);

        if (retire.year && (retire?.year < (yearTerm.year ?? 0) || (install?.year ?? 0) > (retire?.year ?? 0))) {
          return 'Inactive';
        }

        const retireMonth = Number.parseInt(retireDate.format('M'));
        const retireDay = Number.parseInt(retireDate.format('D'));
        const termStartDate = moment(selectedTerm?.startDate);
        const termMonth = (termStartDate.month() + 2) > 12 ? termStartDate.month() + 2 - 12 : termStartDate.month() + 1;
        const termDay = Number.parseInt(termStartDate.format('D'));

        if (retire?.year == (yearTerm.year ?? 0) && (retireMonth < termMonth || (retireMonth == termMonth && retireDay <= termDay))) {
          return 'Inactive';
        }
      }

      if ((install?.year ?? 0) < (yearTerm.year ?? 0) || ((install?.year ?? 0) == (yearTerm.year ?? 0) && (install?.term?.startDate ?? 0) <= (selectedTerm?.startDate ?? 0))) {
        return 'Active';
      }

      return 'Pending'

    }

    return '';
  }
}
