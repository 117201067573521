import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row, Space, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Routes from '../../config/Routes';
import UserSecurity from '../../models/UserSecurity';
import { UserSessionActions } from '../../redux/actions/UserSessionActions';
import { StateStoreModel } from '../../redux/state/StateStoreModel';

interface UserHeaderMenuState {
  loading: boolean;
}

interface UserHeaderMenuProps {
  userInfo?: UserSecurity | null;
  logoutAction?: () => void | undefined;
}

class UserHeaderMenu extends React.Component<UserHeaderMenuProps, UserHeaderMenuState>  {

  constructor(props: UserHeaderMenuProps) {
    super(props);

    this.state = {
      loading: false
    };
  }

  private logout = () => {
    if (this.props.logoutAction) {
      this.props.logoutAction();
    }
  }

  render() {
    if (this.props.userInfo) {
      const content = (
        <div>
          {this.renderUserInfo()}
          {this.renderActions()}
        </div >
      );

      return (
        <Popover key='user_popover' placement="bottomRight" title='' content={content} trigger="click">
          <Button shape='circle' size='large' type='primary' icon={<UserOutlined />} />
        </Popover>
      );
    }
  }

  renderActions() {
    return (
      <div className='align-center'>
        <Space direction="vertical" style={{ marginTop: '10px' }} >
          <Link to={Routes.generate(Routes.USER_SETTINGS)} >
            <Button block={true} shape='round' icon={<SettingOutlined />}>
              Settings
            </Button>
          </Link>
          <Button block={true} shape='round' onClick={this.logout} icon={<LogoutOutlined />}>
            Logout
          </Button>
        </Space>
      </div>
    );
  }

  renderUserInfo() {
    return (
      <Row gutter={[15, 10]}>
        <Col>
          <Avatar size={64} icon={<UserOutlined />} />
        </Col>

        <Col>
          <Typography.Title level={5}>
            {this.props.userInfo?.fullName}
          </Typography.Title>

          <Typography.Text >
            {this.props.userInfo?.userName}
          </Typography.Text>
        </Col>
      </Row >);
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    logoutAction: () => dispatch(UserSessionActions.logout())
  };
}

function mapStateToProps(state: StateStoreModel) {
  return {
    userInfo: state.UserSession.Value
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHeaderMenu);
