// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveChangeRequestAdditionalDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ChangeRequestDTO from '../models/ChangeRequestDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    changeRequestId: string | null;
    additionalComments: string | null;
    additionalFiles: any[] | null;
    removedFiles: string[];
    finalize: boolean;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            changeRequestId: '00000000-0000-0000-0000-000000000000',
            additionalComments: "",
            additionalFiles: [],
            removedFiles: [],
            finalize: false,
        },
        initValues);
    },
    changeRequestId: 'changeRequestId',
    additionalComments: 'additionalComments',
    additionalFiles: 'additionalFiles',
    removedFiles: 'removedFiles',
    finalize: 'finalize',
}

interface Result extends ResultDTO {
    changeRequest: ChangeRequestDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            changeRequest: null,
        },
        initValues);
    },
    changeRequest: 'changeRequest',
}

export { Request, Result };