// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ValidationResult from './Validator';

interface LoginDTO {
}

const LoginDTO = {
  className: 'LoginDTO',
  create: (initValues?: Partial<LoginDTO> | Record<string, unknown> | null) => {
    return Object.assign(
      {
      },
      initValues);
  },
};

export default LoginDTO;