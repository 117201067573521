// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CareerAcademyReportDTO extends EntitySelectorDTO { 
	status: number;
	rppId: number;
	serviceAreaIdentifier: number;
	district: string | null;
	cip: string | null;
	program: string | null;
	submitter: string | null;
	reviewYear: string | null;
	careerAcademy: string | null;
}

const CareerAcademyReportDTO = {
    className: 'CareerAcademyReportDTO',
    create: (initValues?: Partial<CareerAcademyReportDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			status: 0,
			rppId: 0,
			serviceAreaIdentifier: 0,
			district: "",
			cip: "",
			program: "",
			submitter: "",
			reviewYear: "",
			careerAcademy: "",
        },
        initValues);
    },
	status: 'status',
	rppId: 'rppId',
	serviceAreaIdentifier: 'serviceAreaIdentifier',
	district: 'district',
	cip: 'cip',
	program: 'program',
	submitter: 'submitter',
	reviewYear: 'reviewYear',
	careerAcademy: 'careerAcademy',
};

export default CareerAcademyReportDTO;