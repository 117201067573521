// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import TermDTO from './TermDTO';
import NewProgramProgramTermCourseGroupDTO from './NewProgramProgramTermCourseGroupDTO';
import FlatNewProgramProgramTermCourseGroupOptionDTO from './FlatNewProgramProgramTermCourseGroupOptionDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NewProgramProgramTermDTO extends EntitySelectorDTO { 
	id: string | null | null;
	programAwardId: string | null;
	order: number;
	prerequisite: boolean;
	informationalContent: string | null;
	termId: string | null | null;
	term: TermDTO | null;
	groups: NewProgramProgramTermCourseGroupDTO[] | null;
	flatCourseGroupOptions: FlatNewProgramProgramTermCourseGroupOptionDTO[] | null;
}

const NewProgramProgramTermDTO = {
    className: 'NewProgramProgramTermDTO',
    create: (initValues?: Partial<NewProgramProgramTermDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			programAwardId: '00000000-0000-0000-0000-000000000000',
			order: 0,
			prerequisite: false,
			informationalContent: "",
			termId: null,
			term: null,
			groups: [],
			flatCourseGroupOptions: [],
        },
        initValues);
    },
	id: 'id',
	programAwardId: 'programAwardId',
	order: 'order',
	prerequisite: 'prerequisite',
	informationalContent: 'informationalContent',
	termId: 'termId',
	term: 'term',
	groups: 'groups',
	flatCourseGroupOptions: 'flatCourseGroupOptions',
};

export default NewProgramProgramTermDTO;