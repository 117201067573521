import { Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import NewDisciplineChangeRequestApiService from '../../../api/NewDisciplineChangeRequestApiService';
import * as SaveNewDisciplineChangeRequestHandler from '../../../handlerModels/SaveNewDisciplineChangeRequestHandler';
import * as SubmitNewDisciplineChangeRequestStep1Handler from '../../../handlerModels/SubmitNewDisciplineChangeRequestStep1Handler';
import * as GetNewDisciplineChangeRequestHandler from '../../../handlerModels/GetNewDisciplineChangeRequestHandler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import NewDisciplineChangeRequestDTO from '../../../models/NewDisciplineChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import TermInput from '../../TermInput';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewDisciplineChangeRequestStep1FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  newDisciplineChangeRequest: NewDisciplineChangeRequestDTO;
}

interface NewDisciplineChangeRequestStep1FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSubmit?: (id: string) => void;
  onSave?: (id: string) => void;
  readonly?: boolean;
}

class NewDisciplineChangeRequestStep1Form extends React.Component<NewDisciplineChangeRequestStep1FormProps, NewDisciplineChangeRequestStep1FormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private _formItems = new Map<string, FormItemProps>()
    .set(NewDisciplineChangeRequestDTO.code, {
      required: true,
      name: NewDisciplineChangeRequestDTO.code,
      label: 'What is the prefix you are requesting?',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(3),
        ValidationRuleUtil.onlyAlphaCharacters()
      ],
    })
    .set(NewDisciplineChangeRequestDTO.name, {
      required: true,
      name: NewDisciplineChangeRequestDTO.name,
      label: 'Please provide a descriptive name for this prefix.',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ],
    })
    .set(NewDisciplineChangeRequestDTO.rationale, {
      required: true,
      name: NewDisciplineChangeRequestDTO.rationale,
      label: 'Please provide a rationale for this new discipline. Include any course catalog numbers you plan to use.',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(5000)
      ],
    })
    .set(NewDisciplineChangeRequestDTO.additionalRationale, {
      required: true,
      name: NewDisciplineChangeRequestDTO.additionalRationale,
      label: 'Why do no other disciplines meet the requirements?',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(5000)
      ],
    })
    .set(NewDisciplineChangeRequestDTO.install, {
      required: true,
      name: NewDisciplineChangeRequestDTO.install,
      label: 'What term will this discipline first be offered?',
      rules: [
        ValidationRuleUtil.required()
      ],
    });

  constructor(props: NewDisciplineChangeRequestStep1FormProps) {
    super(props);

    this.state = {
      newDisciplineChangeRequest: NewDisciplineChangeRequestDTO.create({
        installTermId: '',
      }),
      changeRequest: ChangeRequestDTO.create(),
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      this.loadChangeRequest();
    }
  }

  componentDidUpdate(prevProps: NewDisciplineChangeRequestStep1FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty() && prevProps.changeRequestId != this.props.changeRequestId) {
      this.loadChangeRequest();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading }, () => this.resetForm());
    }
  }

  private loadChangeRequest = () => {
    const request = GetNewDisciplineChangeRequestHandler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewDisciplineChangeRequestApiService.get(request)
      .then((results: GetNewDisciplineChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors,
            newDisciplineChangeRequest: results.changeRequest?.newDisciplineChangeRequest ?? NewDisciplineChangeRequestDTO.create()
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({ altered: false });
    this._formRef.current?.resetFields();
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SubmitNewDisciplineChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? Guid.Empty(),
      institutionId: this.props.selectedInstitution,
      newDisciplineChangeRequest: NewDisciplineChangeRequestDTO.create(this._formRef?.current?.getFieldsValue())
    });

    NewDisciplineChangeRequestApiService.submit(request)
      .then((result: SubmitNewDisciplineChangeRequestStep1Handler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          message.success('Saved');
          this.setState({ changeRequest: result.changeRequest ?? ChangeRequestDTO.create() });

          if (result.changeRequest?.id) {
            if (this.props.onSave) {
              this.props.onSave(result.changeRequest.id);
              this.resetForm();
            }

            if (this.props.onSubmit) {
              this.props.onSubmit(result.changeRequest.id);
            }
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const model = NewDisciplineChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    model.installTermId = model.installTerm == '' ? null : model.installTerm;

    const request = SaveNewDisciplineChangeRequestHandler.Request.create({
      changeRequestId: this.props.changeRequestId ?? null,
      institutionId: this.props.selectedInstitution,
      newDisciplineChangeRequest: model
    }) as SaveNewDisciplineChangeRequestHandler.Request;

    NewDisciplineChangeRequestApiService.save(request)
      .then((result: SaveNewDisciplineChangeRequestHandler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            newDisciplineChangeRequest: result.changeRequest?.newDisciplineChangeRequest ?? NewDisciplineChangeRequestDTO.create()
          });

          this.resetForm();

          if (this.props.onSave && result.changeRequest?.id) {
            this.props.onSave(result.changeRequest.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public render = () => {
    if (this.state.loading) {
      return <Skeleton active={true} />
    }

    return (
      <Space size="small" direction="vertical">
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.newDisciplineChangeRequest}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            key={NewDisciplineChangeRequestDTO.code}
            {...this._formItems.get(NewDisciplineChangeRequestDTO.code)}
            {...ValidationUtil.getValidation(NewDisciplineChangeRequestDTO.code, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>

          <FormItem
            key={NewDisciplineChangeRequestDTO.name}
            {...this._formItems.get(NewDisciplineChangeRequestDTO.name)}
            {...ValidationUtil.getValidation(NewDisciplineChangeRequestDTO.name, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input disabled={this.props.readonly} />
          </FormItem>

          <FormItem
            key={NewDisciplineChangeRequestDTO.rationale}
            {...this._formItems.get(NewDisciplineChangeRequestDTO.rationale)}
            {...ValidationUtil.getValidation(NewDisciplineChangeRequestDTO.rationale, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} disabled={this.props.readonly} />
          </FormItem>

          <FormItem
            key={NewDisciplineChangeRequestDTO.additionalRationale}
            {...this._formItems.get(NewDisciplineChangeRequestDTO.additionalRationale)}
            {...ValidationUtil.getValidation(NewDisciplineChangeRequestDTO.additionalRationale, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} disabled={this.props.readonly} />
          </FormItem>

          <FormItem
            key={NewDisciplineChangeRequestDTO.install}
            {...this._formItems.get(NewDisciplineChangeRequestDTO.install)}
            {...ValidationUtil.getValidation(NewDisciplineChangeRequestDTO.install, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <TermInput futureYears={true} disabled={this.props.readonly} />
          </FormItem>

          {this.renderActions()}
        </Form>
      </Space >
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveAndContinueButton submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={() => this.handleSave()} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }
}

export default NewDisciplineChangeRequestStep1Form;