// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CreditTypeDTO from './CreditTypeDTO';
import InstitutionCourseDetailDTO from './InstitutionCourseDetailDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionCourseCreditDTO extends EntitySelectorDTO { 
	id: string | null;
	institutionCourseId: string | null;
	creditTypeId: string | null;
	minimum: number | null | null;
	maximum: number | null | null;
	creditType: CreditTypeDTO | null;
	institutionCourseDetail: InstitutionCourseDetailDTO | null;
}

const InstitutionCourseCreditDTO = {
    className: 'InstitutionCourseCreditDTO',
    create: (initValues?: Partial<InstitutionCourseCreditDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			institutionCourseId: '00000000-0000-0000-0000-000000000000',
			creditTypeId: '00000000-0000-0000-0000-000000000000',
			minimum: null,
			maximum: null,
			creditType: null,
			institutionCourseDetail: null,
        },
        initValues);
    },
	id: 'id',
	institutionCourseId: 'institutionCourseId',
	creditTypeId: 'creditTypeId',
	minimum: 'minimum',
	maximum: 'maximum',
	creditType: 'creditType',
	institutionCourseDetail: 'institutionCourseDetail',
};

export default InstitutionCourseCreditDTO;