import { Alert, Input, InputNumber, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import AwardTypeApiService from '../../../api/AwardTypeApiService';
import CipsApiService from '../../../api/CipsApiService';
import NoticeOfIntentChangeRequestApiService from '../../../api/NoticeOfIntentChangeRequestApiService';
import * as GetAwardTypeDetailsHandler from '../../../handlerModels/GetAwardTypeDetailsHandler';
import * as GetNonTransferCipDetailsHandler from '../../../handlerModels/GetNonTransferCipDetailsHandler';
import * as SaveNoticeOfIntentChangeRequestStep1Handler from '../../../handlerModels/SaveNoticeOfIntentChangeRequestStep1Handler';
import * as SubmitNoticeOfIntentChangeRequestStep1Handler from '../../../handlerModels/SubmitNoticeOfIntentChangeRequestStep1Handler';
import * as GetNoticeOfIntentChangeRequestStep1Handler from '../../../handlerModels/GetNoticeOfIntentChangeRequestStep1Handler';
import AwardTypeDTO from '../../../models/AwardTypeDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import CipNumberDetailDTO from '../../../models/CipNumberDetailDTO';
import IowaVariationDTO from '../../../models/IowaVariationDTO';
import NoticeOfIntentChangeRequestDTO from '../../../models/NoticeOfIntentChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';
import Dropdown from '../../inputs/Dropdown';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NoticeOfIntentChangeRequestStep1FormState extends BaseFormState {
  cipNumberDetails: CipNumberDetailDTO[];
  noticeOfIntent: NoticeOfIntentChangeRequestDTO;
  changeRequest: ChangeRequestDTO;
  awardTypes: AwardTypeDTO[];
  iowaVariations: IowaVariationDTO[];
  selectedIowaVariationId: number;
  selectedAwardType: AwardTypeDTO;
}

interface NoticeOfIntentChangeRequestStep1FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  readonly?: boolean;
  onSave?: (id: string) => void;
  onSubmit?: (id: string) => void;
  onChange?: (altered: boolean) => void;
}

class NoticeOfIntentChangeRequestStep1Form extends React.Component<NoticeOfIntentChangeRequestStep1FormProps, NoticeOfIntentChangeRequestStep1FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    const isTransferMajorProgram = this.state.cipNumberDetails.filter(x => x.id == this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.cipNumberId))[0]?.transferMajor ?? false;
    const requiresWorkExperiences = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.requiresWorkExperiences);
    const cipReclassification = this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.cipReclassification);

    return new Map<string, FormItemProps>()
      .set(NoticeOfIntentChangeRequestDTO.cipNumberId, {
        name: NoticeOfIntentChangeRequestDTO.cipNumberId,
        label: 'What CIP Number is this request for?',
        required: true,
        rules: [ValidationRuleUtil.required()],
      })
      .set(NoticeOfIntentChangeRequestDTO.previousCipNumberId, {
        name: NoticeOfIntentChangeRequestDTO.previousCipNumberId,
        label: 'What was the previous CIP Number for the program?',
        required: cipReclassification,
        rules: cipReclassification ? [ValidationRuleUtil.required()] : []
      })
      .set(NoticeOfIntentChangeRequestDTO.primrayAwardTypeId, {
        name: NoticeOfIntentChangeRequestDTO.primrayAwardTypeId,
        label: 'What is the primary award type? (This will affect the award type on the primary award)',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.iowaVariationId, {
        name: NoticeOfIntentChangeRequestDTO.iowaVariationId,
        label: 'Please select an Iowa Variation.',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.isTransferMajorProgram, {
        name: NoticeOfIntentChangeRequestDTO.isTransferMajorProgram,
        label: 'Is this request for a transfer major program?',
        required: isTransferMajorProgram,
        rules: isTransferMajorProgram ? [ValidationRuleUtil.required()] : []
      })
      .set(NoticeOfIntentChangeRequestDTO.needForProgram, {
        required: true,
        name: NoticeOfIntentChangeRequestDTO.needForProgram,
        label: 'What is the rationale or need for the program?',
        rules: [ValidationRuleUtil.required(), ValidationRuleUtil.maxLength(5000)]
      })
      .set(NoticeOfIntentChangeRequestDTO.anticipatedAnnualEnrollment, {
        required: true,
        name: NoticeOfIntentChangeRequestDTO.anticipatedAnnualEnrollment,
        label: 'What is the anticipated annual enrollment for the program?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.anticipatedAnnualCompleters, {
        required: true,
        name: NoticeOfIntentChangeRequestDTO.anticipatedAnnualCompleters,
        label: 'What is the anticipated annual number of completers for the program?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.fiveYearAnticipatedEnrollment, {
        required: true,
        name: NoticeOfIntentChangeRequestDTO.fiveYearAnticipatedEnrollment,
        label: 'What is the anticipated five year enrollment for the program?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.fiveYearAnticipatedCompleters, {
        required: true,
        name: NoticeOfIntentChangeRequestDTO.fiveYearAnticipatedCompleters,
        label: 'What is the anticipated five year number of completers for the program?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.cipReclassification, {
        name: NoticeOfIntentChangeRequestDTO.cipReclassification,
        label: 'Are you requesting the reclassification of a CIP number for an existing program?',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.requiresWorkExperiences, {
        name: NoticeOfIntentChangeRequestDTO.requiresWorkExperiences,
        label: 'Does the program include any required work experience and/or training?',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NoticeOfIntentChangeRequestDTO.requiredWorkExperiences, {
        name: NoticeOfIntentChangeRequestDTO.requiredWorkExperiences,
        label: 'Please describe the required work experiences and/or training needed for this program.',
        required: requiresWorkExperiences,
        rules: requiresWorkExperiences ? [ValidationRuleUtil.required()] : []
      })
  }

  constructor(props: NoticeOfIntentChangeRequestStep1FormProps) {
    super(props);

    this.state = {
      awardTypes: [],
      cipNumberDetails: [],
      changeRequest: ChangeRequestDTO.create(),
      noticeOfIntent: NoticeOfIntentChangeRequestDTO.create({
        installTermId: '',
        installYear: DateTimeUtil.getCurrentYear(),
        cipNumberId: null,
        anticipatedAnnualEnrollment: null,
        anticipatedAnnualCompleters: null,
        requiresWorkExperiences: null,
        isTransferMajorProgram: null,
        cipReclassification: null,
      }),
      iowaVariations: [],
      selectedIowaVariationId: 0,
      selectedAwardType: AwardTypeDTO.create(),
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: NoticeOfIntentChangeRequestStep1FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });

    this._formRef.current?.resetFields();
    if (this.props.onChange) {
      this.props.onChange(false)
    }
  }

  public getVlaue = () => {
    return this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.cipNumberDetails || this.state.cipNumberDetails.length == 0) {
      loaders.push(this.loadCipNumberDetails());
    }

    if (!this.state.awardTypes || this.state.awardTypes.length == 0) {
      loaders.push(this.loadAwardTypes());
    }

    if (!this.state.iowaVariations || this.state.iowaVariations.length == 0) {
      loaders.push(this.loadIowaVariation());
    }

    if (!this.props.changeRequestId || this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadCipNumberDetails = () => {
    return CipsApiService.getActiveNonTransferCipDetails()
      .then((results: GetNonTransferCipDetailsHandler.Result) => {
        if (results) {
          this.setState({
            cipNumberDetails: results.cipNumberDetails ?? []
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAwardTypes = () => {
    return LookupsUtil.getAll<AwardTypeDTO>(AwardTypeDTO.className)
      .then((results: AwardTypeDTO[]) => {
        if (results) {
          this.setState({ awardTypes: results ?? [], loading: false });
        }
      }).catch(() => {
        this.setState({ loading: false });
      });
  }

  private loadIowaVariation = () => {
    return LookupsUtil.getAll<IowaVariationDTO>(IowaVariationDTO.className)
      .then((results: IowaVariationDTO[]) => {
        if (results) {
          this.setState({ iowaVariations: results ?? [], loading: false });
        }
      }).catch(() => {
        this.setState({ loading: false });
      });
  }

  private loadAwardType = () => {
    const formValue = NoticeOfIntentChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null) as NoticeOfIntentChangeRequestDTO;
    const awardTypeId = formValue.primrayAwardTypeId;
    return AwardTypeApiService.getAwardTypeDetails(awardTypeId ?? 0)
      .then((results: GetAwardTypeDetailsHandler.Result) => {
        if (results.awardType) {
          const awardType = results.awardType ?? AwardTypeDTO.create();
          this.setState({ selectedAwardType: awardType });

          if (!awardType.iowaVariationIds.includes(formValue.iowaVariationId ?? 0)) {
            this._formRef.current?.setFieldsValue({ iowaVariationId: undefined })
          }
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadChangeRequest = () => {
    const request = GetNoticeOfIntentChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NoticeOfIntentChangeRequestApiService.getStep1(request)
      .then((results: GetNoticeOfIntentChangeRequestStep1Handler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            noticeOfIntent: results.changeRequest?.noticeOfIntentChangeRequest ?? NoticeOfIntentChangeRequestDTO.create(),
            fieldErrors: results.fieldErrors,
            selectedAwardType: results.changeRequest?.noticeOfIntentChangeRequest?.primrayAwardType ?? AwardTypeDTO.create()
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });

    if (this.props.onChange) {
      this.props.onChange(true)
    }
  }

  private handleAwardTypeChange = () => {
    const loaders = [];

    loaders.push(this.loadAwardType());

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const model = NoticeOfIntentChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null) as NoticeOfIntentChangeRequestDTO;
    model.id = this.state.changeRequest?.noticeOfIntentChangeRequest?.id ?? Guid.Empty();
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();

    const request = SaveNoticeOfIntentChangeRequestStep1Handler.Request.create({
      changeRequestId: this.props.changeRequestId ?? Guid.Empty(),
      institutionId: this.props.selectedInstitution,
      noticeOfIntentChangeRequest: model,
    });

    NoticeOfIntentChangeRequestApiService.saveStep1(request)
      .then((result: SaveNoticeOfIntentChangeRequestStep1Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.changeRequest?.id) {
            this.props.onSave(result.changeRequest.id);
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = NoticeOfIntentChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null) as NoticeOfIntentChangeRequestDTO;
    model.id = this.state.changeRequest?.noticeOfIntentChangeRequest?.id ?? Guid.Empty();
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();

    const request = SubmitNoticeOfIntentChangeRequestStep1Handler.Request.create({
      noticeOfIntentChangeRequest: model,
      institutionId: this.props.selectedInstitution
    });

    NoticeOfIntentChangeRequestApiService.submitStep1(request)
      .then((result: SubmitNoticeOfIntentChangeRequestStep1Handler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          message.success('Saved');

          if (result.changeRequest?.id) {
            if (this.props.onSave) {
              this.props.onSave(result.changeRequest.id);
            }

            if (this.props.onSubmit) {
              this.props.onSubmit(result.changeRequest.id);
            }
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();
    const values = this.state.noticeOfIntent ?? NoticeOfIntentChangeRequestDTO.create();
    values.primrayAwardTypeId = this.state.selectedAwardType.id == 0 ? null : this.state.selectedAwardType.id;

    const availableIowaVariations = this.state.iowaVariations.filter(x => this.state.selectedAwardType.iowaVariationIds.includes(x.id));

    return (
      <Form ref={this._formRef}
        layout="vertical"
        initialValues={values}
        onValuesChange={this.handleChange}
        onFinish={this.handleSubmit}
        requiredMark={true}>
        <Space direction='vertical'>
          {this.renderErrors()}

          <FormItem
            key={NoticeOfIntentChangeRequestDTO.cipNumberId}
            {...formItems.get(NoticeOfIntentChangeRequestDTO.cipNumberId)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.cipNumberId, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Dropdown
              showSearch
              disabled={this.props.readonly}
              optionFilterProp="children">
              {this.state.cipNumberDetails.map(x => { return this.renderCipNumberOption(x) })}
            </Dropdown>
          </FormItem>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.cipReclassification)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.cipReclassification, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <YesNoInput disabled={this.props.readonly} />
          </FormItem>

          {this.renderPreviousCipNumber(formItems)}

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.primrayAwardTypeId)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.primrayAwardTypeId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Dropdown
              showSearch
              disabled={this.props.readonly}
              optionFilterProp="children"
              onChange={this.handleAwardTypeChange}>
              {this.state.awardTypes.map(x => { return this.renderAwardTypes(x) })}
            </Dropdown>
          </FormItem>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.iowaVariationId)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.iowaVariationId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Dropdown

              showSearch
              disabled={this.props.readonly}
              optionFilterProp="children">
              {availableIowaVariations.map(x => { return this.renderIowaVariations(x) })}
            </Dropdown>
          </FormItem>

          {this.renderTransferMajor(formItems)}

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.needForProgram)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.needForProgram, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.anticipatedAnnualEnrollment)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.anticipatedAnnualEnrollment, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <InputNumber disabled={this.props.readonly} min={0} />
          </FormItem>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.fiveYearAnticipatedEnrollment)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.fiveYearAnticipatedEnrollment, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <InputNumber disabled={this.props.readonly} min={0} />
          </FormItem>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.anticipatedAnnualCompleters)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.anticipatedAnnualCompleters, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <InputNumber disabled={this.props.readonly} min={0} />
          </FormItem>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.fiveYearAnticipatedCompleters)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.fiveYearAnticipatedCompleters, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <InputNumber disabled={this.props.readonly} min={0} />
          </FormItem>

          <FormItem
            {...formItems.get(NoticeOfIntentChangeRequestDTO.requiresWorkExperiences)}
            {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.requiresWorkExperiences, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <YesNoInput disabled={this.props.readonly} />
          </FormItem>

          {this.renderRequiredWorkExperience(formItems)}

          {this.renderActions()}
        </Space>
      </Form>
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveAndContinueButton submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  renderPreviousCipNumber(formItems: Map<string, FormItemProps>) {
    if (this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.cipReclassification)
      || this.state.noticeOfIntent.cipReclassification)
      return (
        <FormItem
          key={NoticeOfIntentChangeRequestDTO.previousCipNumberId}
          {...formItems.get(NoticeOfIntentChangeRequestDTO.previousCipNumberId)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.previousCipNumberId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
          <Dropdown
            showSearch
            disabled={this.props.readonly}
            optionFilterProp="children">
            {this.state.cipNumberDetails.map(x => { return this.renderCipNumberOption(x) })}
          </Dropdown>
        </FormItem>
      );
  }

  renderCipNumberOption(x: CipNumberDetailDTO) {
    if (x.cipNumberId) {
      return <Select.Option key={x.cipNumberId} value={x.cipNumberId}>{x.display} - {x.title}</Select.Option>;
    }
  }

  renderAwardTypes(x: AwardTypeDTO) {
    if (x.id) {
      return <Select.Option key={x.id} value={x.id}>{x.display}</Select.Option>;
    }
  }

  renderIowaVariations(x: IowaVariationDTO) {
    if (x.id) {
      return <Select.Option key={x.id} value={x.id}>{x.display}</Select.Option>;
    }
  }

  private renderErrors() {
    if (this.state.error) {
      return (
        <Alert showIcon={true} message="Error Saving" type="error" description={this.state.message} />
      );
    }
  }

  private renderTransferMajor = (formItems: Map<string, FormItemProps>) => {
    if (this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.isTransferMajorProgram) ?? false) {
      return (
        <FormItem
          {...formItems.get(NoticeOfIntentChangeRequestDTO.isTransferMajorProgram)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.isTransferMajorProgram, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
          <YesNoInput disabled={this.props.readonly} />
        </FormItem>
      );
    }
  }

  private renderRequiredWorkExperience = (formItems: Map<string, FormItemProps>) => {
    if ((this._formRef.current?.getFieldValue(NoticeOfIntentChangeRequestDTO.requiresWorkExperiences) ?? false)
      || this.state.noticeOfIntent.requiresWorkExperiences) {
      return (
        <FormItem
          {...formItems.get(NoticeOfIntentChangeRequestDTO.requiredWorkExperiences)}
          {...ValidationUtil.getValidation(NoticeOfIntentChangeRequestDTO.requiredWorkExperiences, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
          <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
        </FormItem >
      );
    }
  }
}

export default NoticeOfIntentChangeRequestStep1Form