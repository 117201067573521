import { Card, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AuthorizedContent from '../../../components/AuthorizedContent';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import AcademicYearForm from '../../../components/forms/secondary/AcademicYearForm';
import Breadcrumbs from '../../../config/Breadcrumbs';
import FeatureFlag from '../../../consts/FeatureFlag';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface AcademicYearConfigurationPageState extends BaseFormState {
  loading: boolean;
  academicYear: number;
}

interface AcademicYearConfigurationPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class AcademicYearConfigurationPage extends React.Component<AcademicYearConfigurationPageProps & RouteComponentProps<RouteObject>, AcademicYearConfigurationPageState> {

  constructor(props: AcademicYearConfigurationPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    this.setState({ academicYear: id });
  }

  private onBack = () => {
    HistoryUtil.goBack();
  }

  private toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    const editButton =
      <AuthorizedContent validFeatureFlags={[FeatureFlag.CONFIG_ACADEMIC_YEAR]}>
        <EditCancelButton onClick={this.toggleEdit} isEditing={this.state.isEditing} />
      </AuthorizedContent>;
    return (
      <Content >
        <HeaderPortal title='Program Review Year Dates' breadcrumbs={Breadcrumbs.academicYearDatesConfigurationList(this.state.academicYear)}
          onBack={this.onBack} />
        < Space direction="vertical" size="large">
          <Card title="Program Review Year Dates" extra={editButton}>
            <Space direction='vertical' size="large">
              <AcademicYearForm academicYearId={this.state.academicYear} isEditing={this.state.isEditing} />
            </Space>
          </Card>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(AcademicYearConfigurationPage));
