// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CourseDTO from './CourseDTO';
import CourseRequisiteTypeDTO from './CourseRequisiteTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CourseModificationChangeRequestCourseRequisiteDTO extends EntitySelectorDTO { 
	id: string | null;
	courseModificationChangeRequestId: string | null;
	requisiteCourseId: string | null;
	requisiteCourse: CourseDTO | null;
	courseRequisiteTypeId: number;
	courseRequisiteType: CourseRequisiteTypeDTO | null;
}

const CourseModificationChangeRequestCourseRequisiteDTO = {
    className: 'CourseModificationChangeRequestCourseRequisiteDTO',
    create: (initValues?: Partial<CourseModificationChangeRequestCourseRequisiteDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			courseModificationChangeRequestId: '00000000-0000-0000-0000-000000000000',
			requisiteCourseId: '00000000-0000-0000-0000-000000000000',
			requisiteCourse: null,
			courseRequisiteTypeId: 0,
			courseRequisiteType: null,
        },
        initValues);
    },
	id: 'id',
	courseModificationChangeRequestId: 'courseModificationChangeRequestId',
	requisiteCourseId: 'requisiteCourseId',
	requisiteCourse: 'requisiteCourse',
	courseRequisiteTypeId: 'courseRequisiteTypeId',
	courseRequisiteType: 'courseRequisiteType',
};

export default CourseModificationChangeRequestCourseRequisiteDTO;