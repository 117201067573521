import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Drawer, StepProps, Steps, StepsProps } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as React from 'react';

interface CollapsibleStepsProps extends StepsProps {
}

function CollapsibleSteps(props: React.PropsWithChildren<CollapsibleStepsProps>) {
  const screens = useBreakpoint();
  const current = props.children ? props.children[props.current ?? 0] : {} as StepProps;

  const steps = <Steps {...props} >{props.children}</Steps>;

  const [visible, setVisible] = React.useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  if (screens.xl) {
    return (
      <Steps {...props} >{props.children}</Steps>
    );
  }
  else {
    return (
      <div >
        <Button size='large' type='link' onClick={showDrawer} >
          <RightOutlined />Current Step: {current?.props?.title}
        </Button>
        <Drawer width='100%' closeIcon={<CloseOutlined />} title="Steps" placement="left" onClose={onClose} visible={visible}>
          {steps}
        </Drawer>
      </div >
    )
  }
}

export default CollapsibleSteps;
