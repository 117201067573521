import { Button, Col, message, Row, Space, Statistic, Steps } from 'antd';
import moment from 'moment';
import * as React from 'react';
import AcademicYearApiService from '../../../../api/AcademicYearApiService';
import { ColumnWidths } from '../../../../config/ColumnWidths';
import AcademicYearStatusType from '../../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../../consts/FeatureFlag';
import SelfStudyResponseType from '../../../../consts/SelfStudyResponseType';
import AcademicYearDTO from '../../../../models/AcademicYearDTO';
import BaseFormProps from '../../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../../utils/AuthorizationUtil';
import DateUtil from '../../../../utils/DateTimeUtil';
import * as SendSelfStudyReminderEmailHandler from '../../../../handlerModels/SendSelfStudyReminderEmailHandler';
import AcademicYearSelfStudyStatsDTO from '../../../../models/AcademicYearSelfStudyStatsDTO';
import { WarningFilled } from '@ant-design/icons';

interface DeResponseStepProps extends BaseFormProps {
  academicYear: AcademicYearDTO;
  compactView?: boolean;
  selfStudyStats: AcademicYearSelfStudyStatsDTO | null;
}

const finishedStatusTypes = [
  AcademicYearStatusType.CLOSED,
  AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS,
]

export default function DeResponseStep(props: DeResponseStepProps) {
  const active = props.academicYear?.academicYearStatusTypeId === AcademicYearStatusType.REVIEWSELFSTUDYPROGRAMS && DateUtil.isAfterRange(props.academicYear.deStartDate ?? '');
  const finished = finishedStatusTypes.indexOf(props.academicYear.academicYearStatusTypeId) >= 0;

  const notSubmittedCount = (props.selfStudyStats?.doeNotStarted ?? 0) + (props.selfStudyStats?.doeInProgress ?? 0) + (props.selfStudyStats?.doePendingApproval ?? 0);
  const error = DateUtil.isAfterRange(props.academicYear.deEndDate ?? '') && notSubmittedCount > 0;
  const [sending, setSending] = React.useState(false);

  const handleSendReminderEmails = () => {
    setSending(true);
    const request = SendSelfStudyReminderEmailHandler.Request.create(
      {
        academicYear: props.academicYear,
        selfStudyResponseTypeId: SelfStudyResponseType.DOE
      });

    AcademicYearApiService.sendSelfStudyReminderEmails(request)
      .then((x: SendSelfStudyReminderEmailHandler.Result) => {
        if (x.succeeded) {
          message.success('Reminder Emails Sent!');
        }
        else {
          message.error('Failed to Send Reminder Emails');
        }
      }).catch(() => {
        message.error('Failed to Send Reminder Emails');
      }).finally(() => {
        setSending(false);
      });
  }

  const dateRange = DateUtil.dateRangeShortFormat(props.academicYear.deStartDate, props.academicYear.deEndDate);

  const renderContent = () => {
    if (active && props.compactView === false && DateUtil.isAfterRange(props.academicYear.deStartDate ?? moment())) {
      if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DE_RESPONSE])) {
        return <Space direction='vertical'>
          <Row>
            <Col {...ColumnWidths.ONE_QUARTER}>
              <Statistic title="Not Started" value={props.selfStudyStats?.doeNotStarted} />
            </Col>
            <Col {...ColumnWidths.ONE_QUARTER}>
              <Statistic title="In Progress" value={props.selfStudyStats?.doeInProgress} />
            </Col>
            <Col {...ColumnWidths.ONE_QUARTER}>
              <Statistic title="Pending Approval" value={props.selfStudyStats?.doePendingApproval} />
            </Col>
            <Col {...ColumnWidths.ONE_QUARTER}>
              <Statistic title="Completed" value={props.selfStudyStats?.doeCompleted} />
            </Col>
          </Row>
          {renderReminderButton()}
        </Space>;
      }
    }
  }

  const renderReminderButton = () => {
    if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR]) && DateUtil.isAfterRange(props.academicYear.deEndDate ?? moment())) {
      return <Button type={error ? 'primary' : 'default'} danger={error} loading={sending} onClick={handleSendReminderEmails}>Send Reminder Emails</Button>;
    }

    return null;
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DISTRICT_RESPONSE, FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE])) {
    return <Steps.Step title='Department of Education Response Window'
      icon={error ? <WarningFilled /> : null}
      status={error ? 'error' : finished ? 'finish' : active ? 'process' : 'wait'}
      subTitle={dateRange}
      description={renderContent()} />;
  }

  return null;
}