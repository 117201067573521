// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveServiceAreaDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ServiceAreaDTO from '../models/ServiceAreaDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    serviceAreaId: string | null;
    serviceArea: ServiceAreaDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            serviceAreaId: '00000000-0000-0000-0000-000000000000',
            serviceArea: null,
        },
        initValues);
    },
    serviceAreaId: 'serviceAreaId',
    serviceArea: 'serviceArea',
}

interface Result extends ResultDTO {
    serviceArea: ServiceAreaDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            serviceArea: null,
        },
        initValues);
    },
    serviceArea: 'serviceArea',
}

export { Request, Result };