// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SearchForSecondaryProgramsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SecondaryProgramDTO from '../models/SecondaryProgramDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    searchValue: string | null;
    districtId: string | null;
    academicYear: number;
    programSearch: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            searchValue: "",
            districtId: '00000000-0000-0000-0000-000000000000',
            academicYear: 0,
            programSearch: 0,
        },
        initValues);
    },
    searchValue: 'searchValue',
    districtId: 'districtId',
    academicYear: 'academicYear',
    programSearch: 'programSearch',
}

interface Result extends ResultDTO {
    secondaryPrograms: SecondaryProgramDTO[] | null;
    programSearch: number;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            secondaryPrograms: [],
            programSearch: 0,
        },
        initValues);
    },
    secondaryPrograms: 'secondaryPrograms',
    programSearch: 'programSearch',
}

export { Request, Result };