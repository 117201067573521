// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetCourseDeactivationChangeRequestHandler from '../handlerModels/GetCourseDeactivationChangeRequestHandler';
import * as SaveCourseDeactivationChangeRequestStep1Handler from '../handlerModels/SaveCourseDeactivationChangeRequestStep1Handler';
import * as SubmitCourseDeactivationChangeRequestStep1Handler from '../handlerModels/SubmitCourseDeactivationChangeRequestStep1Handler';

export class CourseDeactivationChangeRequestApiService extends BaseApi {

    // post: api/courseDeactivationChangeRequests/get
    public get(getCourseDeactivationChangeRequest: GetCourseDeactivationChangeRequestHandler.Request): Promise<GetCourseDeactivationChangeRequestHandler.Result> {
        let url = `api/courseDeactivationChangeRequests/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCourseDeactivationChangeRequestHandler.Result>(getCourseDeactivationChangeRequest, 'post', url, true, false);
    }

    // post: api/courseDeactivationChangeRequests/saveStep1
    public saveStep1(saveCourseDeactivationChangeRequestStep1: SaveCourseDeactivationChangeRequestStep1Handler.Request): Promise<SaveCourseDeactivationChangeRequestStep1Handler.Result> {
        let url = `api/courseDeactivationChangeRequests/saveStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveCourseDeactivationChangeRequestStep1Handler.Result>(saveCourseDeactivationChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/courseDeactivationChangeRequests/submitStep1
    public submitStep1(submitCourseDeactivationChangeRequestStep1: SubmitCourseDeactivationChangeRequestStep1Handler.Request): Promise<SubmitCourseDeactivationChangeRequestStep1Handler.Result> {
        let url = `api/courseDeactivationChangeRequests/submitStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitCourseDeactivationChangeRequestStep1Handler.Result>(submitCourseDeactivationChangeRequestStep1, 'post', url, true, false);
    }
}
var service = new CourseDeactivationChangeRequestApiService();
export default service;
