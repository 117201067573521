import { InputNumber } from 'antd';
import * as React from 'react';

interface NumberInputProps {
  value?: number;
  onChange?: (value: number) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  // onBlur?: (value: number) => void;
  prefixCls?: string;
  min?: number;
  max?: number;
  step?: number | string;
  defaultValue?: number;
  tabIndex?: number;
  onKeyDown?: React.FormEventHandler<any>;
  disabled?: boolean;
  readOnly?: boolean;
  size?: 'large' | 'small' | 'default';
  formatter?: (value: number | string | undefined) => string;
  parser?: (displayValue: string | undefined) => number;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  name?: string;
  id?: string;
  precision?: 0 | 1 | 2 | 3 | 4 | 5;
  minFractionDigits?: 0 | 1 | 2 | 3 | 4 | 5;
}
const parseRegex = /\$\s?|(,*)|(\w+)/g;
const removeAddlDotRegex = /(\..*)\./g;
const removeTrailingZeroRegex = /\.0+$|(\.0?[1-9]+)0+$/;
const formatRegex = /(?!^-)[^0-9,.]/g;
const empty = '';
const replace = '$1';

class NumberInput extends React.Component<NumberInputProps, unknown> {
  private readonly _inputRef = React.createRef<any>();
  private justFocused!: boolean;
  private formatterFunction: (num: number) => string;

  constructor(props: NumberInputProps) {
    super(props);
    this.formatter = this.formatter.bind(this);
    this.parser = this.parser.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    if (typeof this.props.precision === 'number') {
      this.formatterFunction = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: this.props.precision,
        minimumFractionDigits:
          this.props.minFractionDigits === null ? this.props.precision : this.props.minFractionDigits,
      }).format;
    } else {
      this.formatterFunction = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 5,
        minimumFractionDigits: this.props.minFractionDigits === null ? 0 : this.props.minFractionDigits,
      }).format;
    }
  }

  getFormat(val: string | number | undefined) {
    if (
      (typeof val === 'number' && isNaN(val)) ||
      (typeof val === 'string' && val === 'NaN') ||
      val === '' ||
      val === undefined
    ) {
      return '';
    }

    const num = parseFloat(`${val}`.replace(parseRegex, empty));

    return this.formatterFunction(num);
  }

  formatter(val: string | number | undefined) {
    if (!this._inputRef.current || !this._inputRef.current.state.focused) {
      return this.getFormat(val);
    }

    const newVal = `${val}`.replace(removeAddlDotRegex, replace).replace(formatRegex, empty);

    if (this.justFocused) {
      this.justFocused = false;
      return newVal.replace(removeTrailingZeroRegex, replace);
    }
    return newVal;
  }

  parser(val: string | undefined) {
    return (val || '').replace(parseRegex, '') as any;
  }

  onFocus(evt: any) {
    const input = evt.target;
    this.justFocused = true;
    setTimeout(() => input.setSelectionRange(0, 9999), 100);
  }

  onBlur(evt: any) {
    const input = evt.target;
    const formatted = this.getFormat(input.value);
    input.value = formatted;
    this.justFocused = false;
  }

  render() {
    const theseProps = {
      ref: this._inputRef,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      formatter: this.formatter,
      parser: this.parser,
    };

    const props = { ...theseProps, ...this.props };
    // props.onBlur = (evt: any) => {
    //     if (this.props.onBlur) {
    //         this.props.onBlur(evt.target.value);
    //     }
    //     this.onBlur(evt);
    // };
    props.className = 'right-align ' + this.props.className;

    return <InputNumber {...(props as any)} />;
  }
}

export default NumberInput;
