// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ContactReportDTO extends EntitySelectorDTO { 
	rppId: number;
	serviceArea: string | null;
	district: string | null;
	cip: string | null;
	user: string | null;
	email: string | null;
	phone: string | null;
}

const ContactReportDTO = {
    className: 'ContactReportDTO',
    create: (initValues?: Partial<ContactReportDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			rppId: 0,
			serviceArea: "",
			district: "",
			cip: "",
			user: "",
			email: "",
			phone: "",
        },
        initValues);
    },
	rppId: 'rppId',
	serviceArea: 'serviceArea',
	district: 'district',
	cip: 'cip',
	user: 'user',
	email: 'email',
	phone: 'phone',
};

export default ContactReportDTO;