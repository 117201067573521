import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ResourcesDisplay from '../../components/displays/ResourcesDisplay';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import ResourceDTO from '../../models/ResourceDTO';
import HeaderPortal from '../../portals/HeaderPortal';
import HistoryUtil from '../../utils/HistoryUtil';
import ResourceApiService from '../../api/ResourceApiService';
import * as GetResourcesHandler from '../../handlerModels/GetResourcesHandler';

interface PublicResourceProps {
}

interface PublicResourceState {
  resources: ResourceDTO[];
}

class PublicResourcePage extends React.Component<PublicResourceProps & RouteComponentProps<RouteObject>, PublicResourceState> {
  constructor(props: PublicResourceProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      resources: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    return ResourceApiService.getResources(GetResourcesHandler.Request.create())
      .then((results: GetResourcesHandler.Result) => {
        if (results.resources) {
          this.setState({
            resources: results.resources ?? []
          });
        }
      });
  }

  private goHome = () => {
    HistoryUtil.push(Routes.generate(Routes.HOME));
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Resources" onBack={this.goHome} breadcrumbs={Breadcrumbs.publicResources()} />

        <Card>
          <ResourcesDisplay resources={this.state.resources} />
        </Card>
      </Content>
    );
  }
}

export default withRouter(PublicResourcePage);
