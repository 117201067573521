import { Button, ButtonProps } from 'antd';
import * as React from 'react';
import { FormOutlined, CloseOutlined } from '@ant-design/icons';

interface EditCancelButtonState { }

interface EditCancelButtonProps extends ButtonProps {
  isEditing: boolean | undefined;
  onClick: () => void;
}

class EditCancelButton extends React.Component<EditCancelButtonProps, EditCancelButtonState> {
  constructor(props: EditCancelButtonProps) {
    super(props);

    this.state = {};
  }

  render() {
    const icon = this.props.isEditing ? <CloseOutlined /> : <FormOutlined />;
    const title = this.props.isEditing ? 'Cancel' : 'Edit';
    return (
      <Button type="link" size="small" onClick={this.props.onClick} icon={icon} title={title} />
    );
  }
}

export default EditCancelButton;
