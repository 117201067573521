// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import TransferMajorDisciplineFrameworkElementCourseDataTableDTO from '../models/TransferMajorDisciplineFrameworkElementCourseDataTableDTO';
import * as AddTransferMajorDisciplineFrameworkElementCourseHandler from '../handlerModels/AddTransferMajorDisciplineFrameworkElementCourseHandler';
import * as GetTransferMajorDisciplineFrameworkElementCoursesHandler from '../handlerModels/GetTransferMajorDisciplineFrameworkElementCoursesHandler';
import * as DeleteTransferMajorDisciplineFrameworkElementCourseHandler from '../handlerModels/DeleteTransferMajorDisciplineFrameworkElementCourseHandler';

export class TransferMajorDisciplineFrameworkElementCourseApiService extends BaseApi {

    // post: api/transferMajorDisciplineFrameworkElementCourse?transferMajorDisciplineFrameworkElementId=${transferMajorDisciplineFrameworkElementId}
    public getTransferMajorDisciplineFrameworkElementCoursesDataTable(tableRequest: TableRequestDTO, transferMajorDisciplineFrameworkElementId: number): Promise<TableResponseDTO<TransferMajorDisciplineFrameworkElementCourseDataTableDTO>> {
        let url = `api/transferMajorDisciplineFrameworkElementCourse`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (transferMajorDisciplineFrameworkElementId != null) {
            url += `${prefix}transferMajorDisciplineFrameworkElementId=${transferMajorDisciplineFrameworkElementId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<TransferMajorDisciplineFrameworkElementCourseDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/transferMajorDisciplineFrameworkElementCourse/export
    public getTransferMajorDisciplineFrameworkElementCourseDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/transferMajorDisciplineFrameworkElementCourse/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/transferMajorDisciplineFrameworkElementCourse/add
    public addTransferMajorDisciplineFrameworkElementCourse(addTransferMajorDisciplineFrameworkElementCourse: AddTransferMajorDisciplineFrameworkElementCourseHandler.Request): Promise<AddTransferMajorDisciplineFrameworkElementCourseHandler.Result> {
        let url = `api/transferMajorDisciplineFrameworkElementCourse/add`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddTransferMajorDisciplineFrameworkElementCourseHandler.Result>(addTransferMajorDisciplineFrameworkElementCourse, 'post', url, true, false);
    }

    // post: api/transferMajorDisciplineFrameworkElementCourse/get
    public getTransferMajorDisciplineFrameworkElementCourses(getTransferMajorDisciplineFrameworkElementCourses: GetTransferMajorDisciplineFrameworkElementCoursesHandler.Request): Promise<GetTransferMajorDisciplineFrameworkElementCoursesHandler.Result> {
        let url = `api/transferMajorDisciplineFrameworkElementCourse/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetTransferMajorDisciplineFrameworkElementCoursesHandler.Result>(getTransferMajorDisciplineFrameworkElementCourses, 'post', url, true, false);
    }

    // post: api/transferMajorDisciplineFrameworkElementCourse/deleteTransferMajorDisciplineFrameworkElementCourse
    public deleteTransferMajorDisciplineFrameworkElementCourse(deleteTransferMajorDisciplineFrameworkElementCourse: DeleteTransferMajorDisciplineFrameworkElementCourseHandler.Request): Promise<DeleteTransferMajorDisciplineFrameworkElementCourseHandler.Result> {
        let url = `api/transferMajorDisciplineFrameworkElementCourse/deleteTransferMajorDisciplineFrameworkElementCourse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteTransferMajorDisciplineFrameworkElementCourseHandler.Result>(deleteTransferMajorDisciplineFrameworkElementCourse, 'post', url, true, false);
    }
}
var service = new TransferMajorDisciplineFrameworkElementCourseApiService();
export default service;
