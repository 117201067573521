import { Dispatch } from 'redux';
import DistrictApiService from '../../api/DistrictApiService';
import * as GetDistrictDetailsHandler from '../../handlerModels/GetDistrictDetailsHandler';
import DistrictDTO from '../../models/DistrictDTO';
import UserSecurity from '../../models/UserSecurity';
import BaseAction from './BaseAction';

export type DistrictAction = BaseAction<DistrictActionTypes, any>;

export enum DistrictActionTypes {
  DISTRICT_SELECT = 'DISTRICT_SELECT',
  DISTRICT_ID_SELECT = 'DISTRICT_SELECT',
  DISTRICT_DEFAULT_SELECT = 'DISTRICT_DEFAULT_SELECT',
}

export const DistrictActions = {
  select: DistrictSelectAction,
  idSelect: DistrictIdSelectAction,
  defaultSelect: DistrictDefaultSelectAction
};

function DistrictSelectAction(district: DistrictDTO | null): DistrictAction {
  return {
    type: DistrictActionTypes.DISTRICT_SELECT,
    data: district,
  }
}

function DistrictIdSelectAction(districtIdentifier: number) {
  return async (dispatch: Dispatch<any>) => {
    const request = GetDistrictDetailsHandler.Request.create({
      districtId: districtIdentifier
    });

    let district = {} as DistrictDTO;
    await DistrictApiService.getDistrictDetails(request)
      .then((results: GetDistrictDetailsHandler.Result) => {
        if (results) {
          district = results.district ?? DistrictDTO.create();
        }
      });
    dispatch(DistrictSelectAction(district ?? null));
  };
}

function DistrictDefaultSelectAction(us: UserSecurity | null) {
  return (dispatch: Dispatch<any>) => {
    if (!us?.isStateUser) {
      const districtIdentifier = us?.districtIds?.length ?? 0 > 0 ? us?.districtIds[0] : null;
      return dispatch(DistrictIdSelectAction(districtIdentifier ?? 0));
    }

    return dispatch(DistrictSelectAction(null));
  };
}
