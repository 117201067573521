// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetProgramDeactivationChangeRequestHandler from '../handlerModels/GetProgramDeactivationChangeRequestHandler';
import * as SubmitProgramDeactivationChangeRequestStep1Handler from '../handlerModels/SubmitProgramDeactivationChangeRequestStep1Handler';
import * as SaveProgramDeactivationChangeRequestStep1Handler from '../handlerModels/SaveProgramDeactivationChangeRequestStep1Handler';

export class ProgramDeactivationChangeRequestApiService extends BaseApi {

    // post: api/programDeactivationChangeRequests/get
    public get(getProgramDeactivationChangeRequest: GetProgramDeactivationChangeRequestHandler.Request): Promise<GetProgramDeactivationChangeRequestHandler.Result> {
        let url = `api/programDeactivationChangeRequests/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramDeactivationChangeRequestHandler.Result>(getProgramDeactivationChangeRequest, 'post', url, true, false);
    }

    // post: api/programDeactivationChangeRequests/submitStep1
    public submitStep1(submitProgramDeactivationChangeRequestStep1: SubmitProgramDeactivationChangeRequestStep1Handler.Request): Promise<SubmitProgramDeactivationChangeRequestStep1Handler.Result> {
        let url = `api/programDeactivationChangeRequests/submitStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitProgramDeactivationChangeRequestStep1Handler.Result>(submitProgramDeactivationChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/programDeactivationChangeRequests/saveStep1
    public saveStep1(saveProgramDeactivationChangeRequestStep1: SaveProgramDeactivationChangeRequestStep1Handler.Request): Promise<SaveProgramDeactivationChangeRequestStep1Handler.Result> {
        let url = `api/programDeactivationChangeRequests/saveStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveProgramDeactivationChangeRequestStep1Handler.Result>(saveProgramDeactivationChangeRequestStep1, 'post', url, true, false);
    }
}
var service = new ProgramDeactivationChangeRequestApiService();
export default service;
