import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import SecondaryProgramScenarioDetailsForm from '../forms/secondary/SecondaryProgramScenarioDetailsForm';


interface SecondaryProgramScenarioDetailsModalProps extends ModalProps {
  secondaryProgramScenarioId: string;
  academicYear: number;
  onSave?: () => void;
}

function SecondaryProgramScenarioDetailsModal(props: SecondaryProgramScenarioDetailsModalProps) {
  const _secondaryProgramScenarioDetailsRef = React.createRef<SecondaryProgramScenarioDetailsForm>();

  const isNew = props.secondaryProgramScenarioId == Guid.Empty();

  const submitSaveProgramType = () => {
    _secondaryProgramScenarioDetailsRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New Program Type' : 'Edit Program Type'}
      okText='Save'
      onOk={submitSaveProgramType}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <SecondaryProgramScenarioDetailsForm
        ref={_secondaryProgramScenarioDetailsRef}
        SecondaryProgramScenarioId={props.secondaryProgramScenarioId ?? Guid.Empty}
        isEditing={true}
        onSave={props.onSave}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default SecondaryProgramScenarioDetailsModal;