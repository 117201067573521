import { Card, Modal, Space } from 'antd';
import * as React from 'react';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import NextButton from '../../buttons/NextButton';
import AreaEducationAgencyDataTable from '../../datatables/secondary/AreaEducationAgencyDataTable';

interface ReviewAreaEducationAgencyFormState extends BaseFormState {
  academicYearId: number;
  fromSelfStudies: boolean;
  fromAcademicYearList: boolean;
}

interface ReviewAreaEducationAgencyFormProps extends BaseFormProps {
  academicYearId: number;
  readonly?: boolean;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
}

class ReviewAreaEducationAgencyForm extends React.Component<ReviewAreaEducationAgencyFormProps, ReviewAreaEducationAgencyFormState> {

  constructor(props: ReviewAreaEducationAgencyFormProps) {
    super(props);

    this.state = {
      academicYearId: 0,
      fromSelfStudies: false,
      fromAcademicYearList: false
    };
  }

  componentDidMount() {
    if (this.props.academicYearId && this.props.academicYearId != 0) {
      this.setState({ academicYearId: this.props.academicYearId, fromAcademicYearList: this.props.fromAcademicYearList ?? false });
    }
  }

  componentDidUpdate(prevProps: ReviewAreaEducationAgencyFormProps) {
    if (this.props.academicYearId && this.props.academicYearId != 0 && prevProps.academicYearId != this.props.academicYearId) {
      this.setState({ academicYearId: this.props.academicYearId, fromAcademicYearList: this.props.fromAcademicYearList ?? false });
    }
  }

  onClick = () => { // TODO - Go to next step
    Modal.confirm({
      title: 'Are you sure you want to move onto the next review step?',
      width: 500
    });
  }

  render() {
    return (
      <Space direction="vertical" >
        <Card title="Area Education Agencies">
          <Space direction='vertical' size="large">
            <AreaEducationAgencyDataTable
              reviewAcademicYear={true}
              academicYear={this.state.academicYearId}
              fromSelfStudies={this.state.fromSelfStudies}
              fromAcademicYearList={this.state.fromAcademicYearList} />
          </Space>
        </Card>
        {this.renderActions()}
      </Space>
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <NextButton submitting={this.state.submitting} onClick={this.onClick} />
        </Space>
      );
    }
  }
}

export default ReviewAreaEducationAgencyForm;
