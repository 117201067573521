import { MoreOutlined } from '@ant-design/icons';
import * as antd from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SecondaryCipNumberApiService from '../../../api/SecondaryCipNumberApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import SecondaryCipNumberDetailsForm from '../../../components/forms/secondary/SecondaryCipNumberDetailsForm';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as DeleteSecondaryCipNumberHandler from '../../../handlerModels/DeleteSecondaryCipNumberHandler';
import * as GetSecondaryCipNumberDetailsHandler from '../../../handlerModels/GetSecondaryCipNumberDetailsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import SecondaryCipNumberDTO from '../../../models/SecondaryCipNumberDTO';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface SecondaryCipNumberDetailsPageState extends BaseFormState {
  secondaryCipNumberId: string;
  secondaryCipNumber?: SecondaryCipNumberDTO;
  academicYears: AcademicYearDTO[];
  academicYearId: number;
  fromAcademicYearList: boolean;
  fromSelfStudies: boolean;
}

interface SecondaryCipNumberDetailsPageProps {
}

class SecondaryCipNumberDetailsPage extends React.Component<SecondaryCipNumberDetailsPageProps & RouteComponentProps<RouteObject>, SecondaryCipNumberDetailsPageState> {
  private readonly _secondaryCipNumberDetailsRef = React.createRef<SecondaryCipNumberDetailsForm>();

  constructor(props: SecondaryCipNumberDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      secondaryCipNumberId: Guid.Empty(),
      academicYears: [],
      academicYearId: 0,
      fromAcademicYearList: false,
      fromSelfStudies: false
    };
  }

  componentDidMount() {
    const secondaryCipNumberId = ParameterUtil.getPathPart(this.props.match, 'id');
    const academicYearId = ParameterUtil.getParameter('academicYearId');
    const fromAcademicYearList = ParameterUtil.getParameter('fromAcademicYearList');
    const fromSelfStudies = ParameterUtil.getParameter('fromSelfStudies');
    this.setState({
      academicYearId: Number.parseInt(academicYearId),
      fromSelfStudies: fromSelfStudies == 'true',
      fromAcademicYearList: fromAcademicYearList == 'true'
    });

    if (this.props.match.path.split('/').findIndex(x => x == 'new') > -1) {
      this.setState({ isEditing: true, isNew: true, loading: false });
    }
    else {
      const loaders = [];
      loaders.push(this.loadSecondaryCipNumber(secondaryCipNumberId));

      Promise.all(loaders).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  private loadSecondaryCipNumber = (secondaryCipNumberId: string) => {
    this.setState({ secondaryCipNumberId: secondaryCipNumberId });

    SecondaryCipNumberApiService.getSecondaryCipNumberDetails(secondaryCipNumberId)
      .then((results: GetSecondaryCipNumberDetailsHandler.Result) => {
        if (results.secondaryCipNumber) {
          this.setState({ secondaryCipNumber: results.secondaryCipNumber, secondaryCipNumberId: results.secondaryCipNumber.id ?? Guid.Empty(), isNew: false, isEditing: false });
        }
        else {
          this.setState({
            isNew: true,
            secondaryCipNumberId: secondaryCipNumberId,
            secondaryCipNumber: undefined,
            isEditing: true
          })
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._secondaryCipNumberDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private secondaryCipNumberDetailsPageSaved = (secondaryCipNumberId: string) => {
    this.setState({ isEditing: false, isNew: false });
    if (secondaryCipNumberId != this.state.secondaryCipNumberId) {
      HistoryUtil.push(Routes.generate(Routes.SECONDARY_CIP_NUMBER_DETAILS, { id: secondaryCipNumberId }, { academicYearId: this.state.academicYearId, fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList }));
      this.loadSecondaryCipNumber(secondaryCipNumberId);
    }
    else {
      this.loadSecondaryCipNumber(secondaryCipNumberId);
    }
  }

  private deleteConfirmation = (id: string, academicYearId: number) => {
    antd.Modal.confirm({
      title: 'Are you sure you want to delete this Secondary CIP Number?',
      okText: 'Delete',
      onOk: () => this.deleteSecondaryCipNumber(id, academicYearId)
    });
  }

  private deleteSecondaryCipNumber = (secondaryCipNumberId: string, academicYearId: number) => {
    this.setState({ submitting: true });

    const request = DeleteSecondaryCipNumberHandler.Request.create({
      secondaryCipNumberId: secondaryCipNumberId,
      academicYearId: academicYearId
    });

    SecondaryCipNumberApiService.deleteSecondaryCipNumber(request)
      .then((result: DeleteSecondaryCipNumberHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          antd.message.success('Secondary CIP Number was removed.');
        }
        else {
          antd.message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        antd.message.error('Secondary CIP Number could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToListing();
      });
  }

  goToListing = () => {
    if (this.state.fromSelfStudies) {
      HistoryUtil.push(Routes.generate(Routes.ALL_SECONDARY_CIP_NUMBERS, { academicYearId: this.state.academicYearId }, { fromSelfStudies: this.state.fromSelfStudies, fromAcademicYearList: this.state.fromAcademicYearList }));
    }
    else {
      HistoryUtil.push(Routes.generate(Routes.SECONDARY_CIP_NUMBERS, { academicYearId: this.state.academicYearId }));
    }

  }

  render() {
    const title = this.state.loading ? '...' : this.state.isNew ? 'New Secondary CIP Number' : this.state.secondaryCipNumber?.code ?? '...';
    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToListing}
          breadcrumbs={Breadcrumbs.secondaryCipNumberDetailPage(title.toString(), this.state.secondaryCipNumberId, this.state.academicYearId, this.state.fromAcademicYearList, this.state.fromSelfStudies)}
          extra={this.renderMoreActions()}
        />
        {this.renderDetailsCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    const editButton = this.state.secondaryCipNumberId == Guid.Empty() ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_PROGRAM]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <antd.Card title="Details" extra={editButton} >
        <SecondaryCipNumberDetailsForm
          ref={this._secondaryCipNumberDetailsRef}
          secondaryCipNumberId={this.state.secondaryCipNumberId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.secondaryCipNumberDetailsPageSaved}
          academicYear={this.state.academicYearId} />
      </antd.Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <antd.Menu>
        < antd.Menu.Item title='Delete Secondary CIP Number' key={this.state.secondaryCipNumberId} onClick={() => this.deleteConfirmation(this.state.secondaryCipNumberId, this.state.academicYearId)}  >
          Delete Secondary CIP Number
        </antd.Menu.Item>
      </antd.Menu>
    );
    return (
      <>
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_PROGRAM]}>
          {this.state.isNew ? null
            : <antd.Dropdown overlay={menu}>
              <antd.Button size='large' type='link' >
                <MoreOutlined />
              </antd.Button>
            </antd.Dropdown>}
        </AuthorizedContent>
      </>
    );
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <antd.Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</antd.Select.Option>
  }
}

export default withRouter(SecondaryCipNumberDetailsPage);
