// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionLocationDTO extends EntitySelectorDTO { 
	id: string | null;
	instituionId: string | null;
	isPrimary: boolean;
	name: string | null;
	street1: string | null;
	street2: string | null;
	city: string | null;
	state: string | null;
	zip: string | null;
	phone: string | null;
	fax: string | null;
}

const InstitutionLocationDTO = {
    className: 'InstitutionLocationDTO',
    create: (initValues?: Partial<InstitutionLocationDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			instituionId: '00000000-0000-0000-0000-000000000000',
			isPrimary: false,
			name: "",
			street1: "",
			street2: "",
			city: "",
			state: "",
			zip: "",
			phone: "",
			fax: "",
        },
        initValues);
    },
	id: 'id',
	instituionId: 'instituionId',
	isPrimary: 'isPrimary',
	name: 'name',
	street1: 'street1',
	street2: 'street2',
	city: 'city',
	state: 'state',
	zip: 'zip',
	phone: 'phone',
	fax: 'fax',
};

export default InstitutionLocationDTO;