// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import FederalClusterDTO from './FederalClusterDTO';
import GenderDTO from './GenderDTO';
import ServiceAreaDTO from './ServiceAreaDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SecondaryCipNumberDTO extends EntitySelectorDTO { 
	id: string | null | null;
	cipNumberIdentifier: number;
	academicYear: number;
	code: string | null;
	description: string | null;
	specialUnit: number | null | null;
	federalClusterIdentifier: number;
	federalClusterId: string | null;
	federalCluster: FederalClusterDTO | null;
	nonTraditionalGenderIdentifier: number | null | null;
	nonTraditionalGenderId: number | null | null;
	gender: GenderDTO | null;
	serviceAreaIdentifier: number;
	serviceAreaId: string | null;
	serviceArea: ServiceAreaDTO | null;
	stateApproved: boolean;
	display: string | null;
}

const SecondaryCipNumberDTO = {
    className: 'SecondaryCipNumberDTO',
    create: (initValues?: Partial<SecondaryCipNumberDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			cipNumberIdentifier: 0,
			academicYear: 0,
			code: "",
			description: "",
			specialUnit: null,
			federalClusterIdentifier: 0,
			federalClusterId: '00000000-0000-0000-0000-000000000000',
			federalCluster: null,
			nonTraditionalGenderIdentifier: null,
			nonTraditionalGenderId: null,
			gender: null,
			serviceAreaIdentifier: 0,
			serviceAreaId: '00000000-0000-0000-0000-000000000000',
			serviceArea: null,
			stateApproved: false,
			display: "",
        },
        initValues);
    },
	id: 'id',
	cipNumberIdentifier: 'cipNumberIdentifier',
	academicYear: 'academicYear',
	code: 'code',
	description: 'description',
	specialUnit: 'specialUnit',
	federalClusterIdentifier: 'federalClusterIdentifier',
	federalClusterId: 'federalClusterId',
	federalCluster: 'federalCluster',
	nonTraditionalGenderIdentifier: 'nonTraditionalGenderIdentifier',
	nonTraditionalGenderId: 'nonTraditionalGenderId',
	gender: 'gender',
	serviceAreaIdentifier: 'serviceAreaIdentifier',
	serviceAreaId: 'serviceAreaId',
	serviceArea: 'serviceArea',
	stateApproved: 'stateApproved',
	display: 'display',
};

export default SecondaryCipNumberDTO;