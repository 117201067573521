import { Alert, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import FederalClusterApiService from '../../../api/FederalClusterApiService';
import SecondaryCipNumberApiService from '../../../api/SecondaryCipNumberApiService';
import ServiceAreaApiService from '../../../api/ServiceAreaApiService';
import * as GetFederalClustersHandler from '../../../handlerModels/GetFederalClustersHandler';
import * as GetSecondaryCipNumberDetailsHandler from '../../../handlerModels/GetSecondaryCipNumberDetailsHandler';
import * as GetServiceAreasHandler from '../../../handlerModels/GetServiceAreasHandler';
import * as SaveSecondaryCipNumberDetailsHandler from '../../../handlerModels/SaveSecondaryCipNumberDetailsHandler';
import FederalClusterDTO from '../../../models/FederalClusterDTO';
import GenderDTO from '../../../models/GenderDTO';
import SecondaryCipNumberDTO from '../../../models/SecondaryCipNumberDTO';
import ServiceAreaDTO from '../../../models/ServiceAreaDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import Dropdown from '../../inputs/Dropdown';
import ReadableTextBox from '../../inputs/ReadableTextBox';
import YesNoInput from '../../inputs/YesNoInput';

interface SecondaryCipNumberDetailsFormState extends BaseFormState {
  secondaryCipNumber: SecondaryCipNumberDTO;
  federalClusters: FederalClusterDTO[];
  serviceAreas: ServiceAreaDTO[];
  genders: GenderDTO[];
}

interface SecondaryCipNumberDetailsFormProps extends BaseFormProps {
  secondaryCipNumberId: string;
  academicYear?: number;
  onSave?: (secondaryCipNumberId: string) => void;
}

class SecondaryCipNumberDetailsForm extends React.Component<SecondaryCipNumberDetailsFormProps, SecondaryCipNumberDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(SecondaryCipNumberDTO.cipNumberIdentifier, {
      required: true,
      name: SecondaryCipNumberDTO.cipNumberIdentifier,
      label: 'Identifier',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    })
    .set(SecondaryCipNumberDTO.code, {
      required: true,
      name: SecondaryCipNumberDTO.code,
      label: 'CIP Number',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    })
    .set(SecondaryCipNumberDTO.description, {
      required: true,
      name: SecondaryCipNumberDTO.description,
      label: 'Description',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(SecondaryCipNumberDTO.specialUnit, {
      name: SecondaryCipNumberDTO.specialUnit,
      label: 'Special Unit',
      rules: [
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    })
    .set(SecondaryCipNumberDTO.federalClusterIdentifier, {
      required: true,
      name: SecondaryCipNumberDTO.federalClusterIdentifier,
      label: 'Federal Cluster',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(SecondaryCipNumberDTO.serviceAreaIdentifier, {
      required: true,
      name: SecondaryCipNumberDTO.serviceAreaIdentifier,
      label: 'Service Area',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(SecondaryCipNumberDTO.stateApproved, {
      required: true,
      name: SecondaryCipNumberDTO.stateApproved,
      label: 'State Approved',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(SecondaryCipNumberDTO.nonTraditionalGenderId, {
      required: false,
      name: SecondaryCipNumberDTO.nonTraditionalGenderId,
      label: 'Non-Traditional Gender',
    });

  constructor(props: SecondaryCipNumberDetailsFormProps) {
    super(props);

    this.state = {
      secondaryCipNumber: SecondaryCipNumberDTO.create(),
      serviceAreas: [],
      federalClusters: [],
      genders: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: SecondaryCipNumberDetailsFormProps) {
    if (this.props != prevProps) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];
    loaders.push(this.loadSecondaryCipNumber());

    if (this.state.federalClusters && this.state.federalClusters.length == 0) {
      loaders.push(this.loadFederalClusters());
    }

    if (this.state.serviceAreas && this.state.serviceAreas.length == 0) {
      loaders.push(this.loadStateClusters());
    }

    if (this.state.genders && this.state.genders.length == 0) {
      loaders.push(this.loadGenders());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadSecondaryCipNumber() {
    if (!this.state.loading) {
      if (this.props.secondaryCipNumberId == Guid.Empty() || this.props.isNew) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.secondaryCipNumberId && this.props.academicYear) {

      SecondaryCipNumberApiService.getSecondaryCipNumberDetails(this.props.secondaryCipNumberId ?? Guid.Empty())
        .then((results: GetSecondaryCipNumberDetailsHandler.Result) => {
          if (results) {
            this.setState({
              secondaryCipNumber: results.secondaryCipNumber ?? SecondaryCipNumberDTO.create()
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({
      loading: false, secondaryCipNumber: SecondaryCipNumberDTO.create({
        id: Guid.Empty(),
        cipNumberIdentifier: '',
        serviceAreaIdentifier: '',
        federalClusterId: '',
        federalClusterIdentifier: '',
        code: '',
        stateApproved: undefined
      })
    });
  }

  private loadStateClusters = () => {
    if (this.props.academicYear) {
      ServiceAreaApiService.getServiceAreas(this.props.academicYear)
        .then((results: GetServiceAreasHandler.Result) => {
          if (results) {
            this.setState({
              serviceAreas: results.serviceAreas?.sort((a, b) => a.serviceAreaIdentifier - b.serviceAreaIdentifier) ?? []
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadFederalClusters = () => {
    if (this.props.academicYear) {
      FederalClusterApiService.getFederalClusters(this.props.academicYear)
        .then((results: GetFederalClustersHandler.Result) => {
          if (results) {
            this.setState({
              federalClusters: results.federalClusters ?? []
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadGenders = () => {
    LookupsUtil.getAll<GenderDTO>(GenderDTO.className)
      .then((results: GenderDTO[]) => {
        this.setState({ genders: results ?? [] });
      }).catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      () => this.handleSubmit(),
      () => this.setState({ error: true })
    );
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveSecondaryCipNumberDetailsHandler.Request.create({
      secondaryCipNumberId: this.props.secondaryCipNumberId,
      secondaryCipNumber: SecondaryCipNumberDTO.create({
        ...(this._formRef ? (this._formRef.current as any).getFieldsValue() : null),
        academicYear: this.props.academicYear,
        federalClusterId: Guid.Empty(),
        serviceAreaId: Guid.Empty(),
        specialUnit: (this._formRef.current as any).getFieldsValue().specialUnit == undefined ? null : (this._formRef.current as any).getFieldsValue().specialUnit
      })
    });

    SecondaryCipNumberApiService.saveSecondaryCipNumberDetails(request)
      .then((result: SaveSecondaryCipNumberDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ secondaryCipNumber: result.secondaryCipNumber ?? SecondaryCipNumberDTO.create(), fieldErrors: undefined, error: false });

          message.success('Saved');

          if (this.props.onSave && result.secondaryCipNumber?.id) {
            this.resetForm();
            this.props.onSave(result.secondaryCipNumber.id);
          }

        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Secondary CIP Number could not be saved');
          this.setState({ loading: false, submitting: false });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results, loading: false, submitting: false });
        message.error('Secondary CIP Number could not be saved');
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.secondaryCipNumber}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(SecondaryCipNumberDTO.cipNumberIdentifier)}
            {...ValidationUtil.getValidation(SecondaryCipNumberDTO.cipNumberIdentifier, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCipNumberDTO.code)}
            {...ValidationUtil.getValidation(SecondaryCipNumberDTO.code, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCipNumberDTO.description)}
            {...ValidationUtil.getValidation(SecondaryCipNumberDTO.description, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCipNumberDTO.specialUnit)}
            {...ValidationUtil.getValidation(SecondaryCipNumberDTO.specialUnit, this.state.fieldErrors, this.state.submitted)}>
            <ReadableTextBox disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCipNumberDTO.federalClusterIdentifier)}
            {...ValidationUtil.getValidation(SecondaryCipNumberDTO.federalClusterIdentifier, this.state.fieldErrors, this.state.submitted)}>
            <Dropdown disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>{this.state.federalClusters.map(x => this.renderFederalCluster(x))}</Dropdown>
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCipNumberDTO.serviceAreaIdentifier)}
            {...ValidationUtil.getValidation(SecondaryCipNumberDTO.id, this.state.fieldErrors, this.state.submitted)}>
            <Dropdown disabled={!this.props.isEditing || this.state.loading || this.state.submitting} >{this.state.serviceAreas.map(x => this.renderServiceArea(x))}</Dropdown>
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCipNumberDTO.stateApproved)}
            {...ValidationUtil.getValidation(SecondaryCipNumberDTO.stateApproved, this.state.fieldErrors, this.state.submitted)}>
            <YesNoInput disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(SecondaryCipNumberDTO.nonTraditionalGenderId)}
            {...ValidationUtil.getValidation(SecondaryCipNumberDTO.nonTraditionalGenderId, this.state.fieldErrors, this.state.submitted)}>
            <Dropdown allowClear={true} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} >{this.state.genders.map(x => this.renderGender(x))}</Dropdown>
          </FormItem>
        </Form>
      </Space>
    );
  }

  renderFederalCluster(federalCluster: FederalClusterDTO) {
    if (federalCluster.id) {
      return <Select.Option title={federalCluster.display ?? ''} key={federalCluster.federalClusterIdentifier ?? 0} value={federalCluster.federalClusterIdentifier ?? 0}>{federalCluster.display}</Select.Option>
    }
  }


  renderServiceArea(serviceArea: ServiceAreaDTO) {
    if (serviceArea.id) {
      return <Select.Option title={serviceArea.display ?? ''} key={serviceArea.serviceAreaIdentifier ?? Guid.Empty()} value={serviceArea.serviceAreaIdentifier ?? Guid.Empty()}>{serviceArea.display}</Select.Option>
    }
  }

  renderGender(gender: GenderDTO) {
    if (gender.id) {
      return <Select.Option title={gender.name ?? ''} key={gender.id ?? Guid.Empty()} value={gender.id ?? Guid.Empty()}>{gender.name}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default SecondaryCipNumberDetailsForm;
