import { Spin } from 'antd';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChangeRequestApiService from '../../../api/ChangeRequestApiService';
import Routes from '../../../config/Routes';
import ChangeRequestType from '../../../consts/ChangeRequestType';
import * as GetChangeRequestDraftHandler from '../../../handlerModels/GetChangeRequestDraftHandler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface ChangeRequestDetailsState extends BaseFormState {
}

interface ChangeRequestDetailsProps {
}

class ChangeRequestDetailsPage extends React.Component<ChangeRequestDetailsProps & RouteComponentProps<RouteObject>, ChangeRequestDetailsState> {

  constructor(props: ChangeRequestDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');
    const key = ParameterUtil.getPathPart(this.props.match, 'key');

    if (id != null && id != undefined && id != '') {
      this.fetchData(id);
    }

    if (key != null && key != undefined && key != '') {
      this.fetchData(key);
    }
  }

  private fetchData = (id: string) => {
    const request = GetChangeRequestDraftHandler.Request.create({
      key: id
    });

    ChangeRequestApiService.getChangeRequestDraft(request)
      .then((result: GetChangeRequestDraftHandler.Result) => {
        if (result.changeRequest) {
          this.openChangeRequest(result.changeRequest);
        }
      })
      .catch(() => {
        this.setState({ error: true });
      })
  }

  private openChangeRequest = (changeRequest: ChangeRequestDTO) => {
    let route = '';
    switch (changeRequest.changeRequestTypeId) {
      case ChangeRequestType.ADMINISTRATIVECHANGE:
        route = Routes.EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_1;
        break;
      case ChangeRequestType.NEWDISCIPLINE:
        route = Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_1;
        break;
      case ChangeRequestType.NOTICEOFINTENT:
        route = Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_1;
        break;
      case ChangeRequestType.NEWCOURSE:
        route = Routes.EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_1;
        break;
      case ChangeRequestType.COURSEDEACTIVATION:
        route = Routes.EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_1;
        break;
      case ChangeRequestType.COURSEMODIFICATION:
        route = Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_2;
        break;
      case ChangeRequestType.PROGRAMDEACTIVATION:
        route = Routes.EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_1;
        break;
      case ChangeRequestType.PROGRAMMODIFICATION:
      case ChangeRequestType.TRANSFERMAJORPROGRAMMODIFICATION:
        route = Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_1;
        break;
      case ChangeRequestType.NEWPROGRAM:
      case ChangeRequestType.NEWTRANSFERMAJORPROGRAM:
        route = Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_1;
        break;
    }

    HistoryUtil.replace(Routes.generate(route, { id: changeRequest.id ?? Guid.Empty() }, {}));
  }

  render() {
    return (<Spin className='align-center' style={{ height: '100%', width: '100%' }} />);
  }
}

export default withRouter(ChangeRequestDetailsPage);
