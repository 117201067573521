import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import * as React from 'react';
import HeaderPortal from './HeaderPortal';
import BaseFormProps from '../redux/bases/BaseFormProps';
import BaseFormState from '../redux/bases/BaseFormState';

interface DataReviewHeaderPortalState extends BaseFormState {
}

interface DataReviewHeaderPortalProps extends BaseFormProps {
  pageTitle: string;
  breadcrumbs?: Route[];
}

class DataReviewHeaderPortal extends React.Component<DataReviewHeaderPortalProps, DataReviewHeaderPortalState> {
  public render = () => {
    return (
      <HeaderPortal
        title={this.props.pageTitle}
        breadcrumbs={this.props.breadcrumbs} />
    );
  }
}

export default DataReviewHeaderPortal;