// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// LocalLoginHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    userName: string | null;
    password: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            userName: "",
            password: "",
        },
        initValues);
    },
    userName: 'userName',
    password: 'password',
}

interface Result extends ResultDTO {
    isLockedOut: boolean;
    isNotAllowed: boolean;
    requiresTwoFactor: boolean;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            isLockedOut: false,
            isNotAllowed: false,
            requiresTwoFactor: false,
        },
        initValues);
    },
    isLockedOut: 'isLockedOut',
    isNotAllowed: 'isNotAllowed',
    requiresTwoFactor: 'requiresTwoFactor',
}

export { Request, Result };