import { UploadOutlined } from '@ant-design/icons';
import { Button, Space, Typography, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload/interface';
import * as React from 'react';

export interface FileUploadValue {
  newFiles: any[];
  existingFiles: any[];
  removedFiles: string[];
}

interface FileUploadState {
  loading: boolean;
  error: boolean;
  success: boolean;

  newList: any[];
  existingList: any[];
  removedList: string[];
}

interface FileUploadProps extends Omit<UploadProps, 'onChange'> {
  value?: FileUploadValue;
  onChange?: (value: FileUploadValue) => void;
  onUploaded?: (value: UploadFile | undefined) => void;
  onRemoved?: (value: UploadFile | undefined) => void;
  cancel?: (canClose: boolean) => void;
  existingList?: any[];
  cropImage?: boolean;
  text?: string;
  readonly?: boolean;
}

class FileUpload extends React.Component<FileUploadProps, FileUploadState> {
  constructor(props: FileUploadProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.beforeUpload = this.beforeUpload.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.goToLink = this.goToLink.bind(this);

    this.state = {
      loading: false,
      error: false,
      success: false,

      newList: [],
      existingList: [],
      removedList: []
    };
  }

  componentDidUpdate(prevProps: FileUploadProps) {
    if (this.props.value && this.props.value != prevProps.value) {
      this.setState({ existingList: this.props.value.existingFiles });
    }
  }

  private handleChange = (info: UploadChangeParam) => {
    if (this.props.onUploaded && info.file.status !== 'removed') {
      this.props.onUploaded(info.file);
    }
    if (this.props.onRemoved && info.file.status === 'removed') {
      this.props.onRemoved(info.file);
    }
    else {
      const newFileList = info.fileList.map(x => x.originFileObj);
      newFileList.removeAll([undefined]);
      this.setState({
        newList: newFileList
      });

      this.triggerChange();
    }
  };

  private handleRemove = (file: UploadFile) => {
    const existingFiles = this.props.existingList as any[];
    const removedFiles = this.state.removedList as string[];

    if (existingFiles.some(x => x.uid == file.uid)) {
      removedFiles.push(file.uid);
      this.setState({ removedList: removedFiles })
    }

    this.triggerChange();
  };

  private beforeUpload = (file: RcFile) => {
    const count = [] as any[];
    const files = this.state.newList as any[];

    this.setState({
      newList: [...files]
    });

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (files.length == this.props.maxCount) {
        files.splice(0, 1);
      }

      files.push(file)
      files.map((item, index) => {
        if (file.name === item.name) {
          count.push(index);
          if (count.length > 1) {
            files.splice(index, 1);
            return;
          }
        }
      });

      this.setState({
        newList: [...files]
      });

      this.triggerChange();
    };

    return false;
  }

  private triggerChange = () => {
    if (this.props.onChange) {
      const value = {
        newFiles: this.state.newList,
        existingFiles: this.state.existingList,
        removedFiles: this.state.removedList
      } as FileUploadValue;

      this.props.onChange(value);
    }
  }

  private goToLink = (link: string) => {
    window.open(link, '_blank')
  }

  private renderFileLinks = () => {
    if (this.props.existingList?.length ?? 0 > 0) {
      return <Space direction='vertical'>{this.props.existingList?.map(x => this.renderLink(x))}</Space>;
    }

    return null;
  }

  private renderLink = (x: UploadFile) => {
    return <Button type='link' onClick={() => this.goToLink(x.url ?? '/')}>{x.name}</Button>
  }

  render() {
    const existingList = (this.props.existingList ?? []) as UploadFile[];
    if (this.props.readonly) {
      return this.renderFileLinks();
    }

    if (this.props.cropImage) {
      return (
        <ImgCrop grid rotate aspect={3 / 1}>
          <Upload.Dragger
            {...this.props}
            name="file"
            defaultFileList={existingList}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
            onRemove={this.handleRemove}>
            {
              this.props.disabled ?

                <Typography.Text>
                  <UploadOutlined className="app-icon" /> {(this.props.existingList?.length ?? 0) + this.state.newList.length} Files Uploaded
                </Typography.Text> :
                <Typography.Text>
                  <UploadOutlined className="app-icon" /> Click or drag file to this area to upload
                </Typography.Text>
            }
          </Upload.Dragger >
        </ImgCrop>
      );
    }
    else {
      return (
        <Upload.Dragger
          {...this.props}
          name="file"

          defaultFileList={existingList}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          onRemove={this.handleRemove}>
          {
            this.props.disabled ?

              <Typography.Text>
                <UploadOutlined className="app-icon" /> {(this.props.existingList?.length ?? 0) + this.state.newList.length} Files Uploaded
              </Typography.Text> :
              <Typography.Text>
                <UploadOutlined className="app-icon" /> {this.props.text ? this.props.text : 'Click or drag file to this area to upload'}
              </Typography.Text>
          }
        </Upload.Dragger >
      );
    }
  }
}

export default FileUpload;