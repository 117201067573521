import { Button, Steps } from 'antd';
import * as React from 'react';
import Routes from '../../../../config/Routes';
import AcademicYearStatusType from '../../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../../consts/FeatureFlag';
import AcademicYearDTO from '../../../../models/AcademicYearDTO';
import BaseFormProps from '../../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../../utils/HistoryUtil';


interface DataConfigurationStepProps extends BaseFormProps {
  academicYear: AcademicYearDTO;
  compactView?: boolean;
}

export default function DataConfigurationStep(props: DataConfigurationStepProps) {
  const finished = props.academicYear?.academicYearStatusTypeId === AcademicYearStatusType.OPEN ? false : true;

  const dataReviewDescription = () => {
    if (!finished && props.academicYear.academicYearStatusTypeId == AcademicYearStatusType.OPEN) {
      return <Button title='Start Data Review' type={'primary'} onClick={goToDataReviewAreaEducationAgencies}>Start Data Review</Button>;
    }

    return null;
  }

  const goToDataReviewAreaEducationAgencies = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_AREA_EDUCATION_AGENCY, { academicYearId: props.academicYear.id }, {}));
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM_REVIEW, FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
    const content = props.compactView ? null : dataReviewDescription()
    return <Steps.Step status={finished ? 'finish' : 'process'} title='Data Configuration' description={content} />;
  }

  return null;
}