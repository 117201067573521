import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Button, InputNumber, message, Modal, Select, Skeleton, Space, Table, Typography } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import CourseModificationChangeRequestApiService from '../../../api/CourseModificationChangeRequestApiService';
import * as GetCourseModificationChangeRequestHandler from '../../../handlerModels/GetCourseModificationChangeRequestHandler';
import * as SaveCourseModificationChangeRequestStep2Handler from '../../../handlerModels/SaveCourseModificationChangeRequestStep2Handler';
import * as SubmitCourseModificationChangeRequestStep2Handler from '../../../handlerModels/SubmitCourseModificationChangeRequestStep2Handler';
import CategoryDTO from '../../../models/CategoryDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import CourseModificationChangeRequestCourseRequisiteDTO from '../../../models/CourseModificationChangeRequestCourseRequisiteDTO';
import CourseModificationChangeRequestCreditDTO from '../../../models/CourseModificationChangeRequestCreditDTO';
import CourseModificationChangeRequestDTO from '../../../models/CourseModificationChangeRequestDTO';
import CourseRequisiteTypeDTO from '../../../models/CourseRequisiteTypeDTO';
import CreditTypeDTO from '../../../models/CreditTypeDTO';
import DisciplineDTO from '../../../models/DisciplineDTO';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import ValueLabel from '../../general/ValueLabel';
import Dropdown from '../../inputs/Dropdown';
import RichTextEditor from '../../inputs/RichTextEditor';
import YesNoInput from '../../inputs/YesNoInput';
import CourseSearch from '../../postSecondary/CourseSearch';
import TermInput from '../../TermInput';

interface CourseModificationChangeRequestStep2FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO | null;
  courseModification: CourseModificationChangeRequestDTO;
  disciplines: DisciplineDTO[];
  categories: CategoryDTO[];
  creditTypes: CreditTypeDTO[];
  courseRequisiteTypes: CourseRequisiteTypeDTO[];
}

interface CourseModificationChangeRequestStep2FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  readonly?: boolean;
  onSubmit?: () => void;
  onPrevious?: () => void;
}

class CourseModificationChangeRequestStep2Form extends React.Component<CourseModificationChangeRequestStep2FormProps, CourseModificationChangeRequestStep2FormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _courseRequisiteFormRef = React.createRef<FormInstance>();
  private readonly _courseSearch = React.createRef<CourseSearch>();

  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(CourseModificationChangeRequestDTO.title, {
        name: CourseModificationChangeRequestDTO.title,
        label: 'Course Title',
      })
      .set(CourseModificationChangeRequestDTO.courseId, {
        label: 'Course',
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(150)
        ],
        initialValue: this.state.courseModification.course?.display
      })
      .set(CourseModificationChangeRequestDTO.description, {
        required: true,
        name: CourseModificationChangeRequestDTO.description,
        label: 'Course Description',
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(5000)
        ],
      })
      .set(CourseModificationChangeRequestDTO.objectives, {
        name: CourseModificationChangeRequestDTO.objectives,
        label: 'Please list learning outcomes/objectives for this course.',
        rules: [
          ValidationRuleUtil.maxLength(5000)
        ],
      })
      .set(CourseModificationChangeRequestDTO.categoryId, {
        required: true,
        name: CourseModificationChangeRequestDTO.categoryId,
        label: 'Please select a category for this course.',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(CourseModificationChangeRequestDTO.install, {
        required: true,
        name: CourseModificationChangeRequestDTO.install,
        label: 'When will these modifications be available?',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(CourseModificationChangeRequestDTO.variableHours, {
        required: true,
        name: CourseModificationChangeRequestDTO.variableHours,
        label: 'Will this course offer variable credits?',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(CourseModificationChangeRequestCourseRequisiteDTO.courseRequisiteTypeId, {
        required: true,
        name: CourseModificationChangeRequestCourseRequisiteDTO.courseRequisiteTypeId,
        label: 'Course Requisite Type',
        requiredMark: true,
        rules: [ValidationRuleUtil.required()]
      });
  }

  private getCourseModificationCourseCourseRequisiteColumns = () => {
    const columns = [
      {
        title: 'Name',
        sorter: false,
        dataIndex: CourseModificationChangeRequestCourseRequisiteDTO.requisiteCourseId,
        render: (data: any, row: any, index: number) => {
          const requisiteCourse = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.courseModificationChangeRequestCourseRequisites)[index]
          return requisiteCourse?.requisiteCourse?.currentDetail?.display ?? requisiteCourse?.requisiteCourse?.display;
        }
      },
      {
        title: 'Type',
        dataIndex: CourseModificationChangeRequestCourseRequisiteDTO.courseRequisiteTypeId,
        render: (data: any, row: any, index: number) => {
          const requisiteCourse = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.courseModificationChangeRequestCourseRequisites)[index]
          return (
            this.state.courseRequisiteTypes.find(x => x.id == requisiteCourse?.courseRequisiteTypeId)?.name
          );
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (data: any, row: any, index: number) => {
          const requisiteCourse = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.courseModificationChangeRequestCourseRequisites)[index]
          return (<>
            <Button type="link" onClick={() => this.editCourseRequisite(requisiteCourse)}><FormOutlined /></Button>
            <Button type="link" onClick={() => this.removeCourseRequisite(requisiteCourse)}><DeleteOutlined /></Button>
          </>
          );
        }
      },
    ];

    return columns;
  }

  private getCreditTypeFormItems = (index: number) => {
    return new Map<string, FormItemProps>()
      .set(CourseModificationChangeRequestCreditDTO.creditTypeId, {
        name: [index, CourseModificationChangeRequestCreditDTO.creditTypeId],
      })
      .set(CourseModificationChangeRequestCreditDTO.minHours, {
        name: [index, CourseModificationChangeRequestCreditDTO.minHours],
      })
      .set(CourseModificationChangeRequestCreditDTO.maxHours, {
        name: [index, CourseModificationChangeRequestCreditDTO.maxHours],
      });
  }

  constructor(props: CourseModificationChangeRequestStep2FormProps) {
    super(props);

    this.state = {
      changeRequest: null,
      disciplines: [],
      courseModification: CourseModificationChangeRequestDTO.create({
        disciplineId: '',
        categoryId: '',
        installTermId: '',
        courseModificationRequestCredits: []
      }),
      categories: [],
      creditTypes: [],
      courseRequisiteTypes: []
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      this.setState({

        fieldErrors: this.props.fieldErrors
      });
    }

    this.fetchData();
  }

  componentDidUpdate(prevProps: CourseModificationChangeRequestStep2FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.setState({
        fieldErrors: this.props.fieldErrors
      });

      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });

    this._formRef.current?.resetFields();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.categories || this.state.categories.length == 0) {
      loaders.push(this.loadCategories());
    }

    if (!this.state.creditTypes || this.state.creditTypes.length == 0) {
      loaders.push(this.loadCreditTypes());
    }

    if (this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest())
    }

    if (!this.state.courseRequisiteTypes || this.state.courseRequisiteTypes.length == 0) {
      loaders.push(this.loadCourseRequisiteType());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm()
    });
  }

  private loadChangeRequest = () => {
    const request = GetCourseModificationChangeRequestHandler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return CourseModificationChangeRequestApiService.get(request)
      .then((results: GetCourseModificationChangeRequestHandler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            courseModification: results.changeRequest?.courseModificationChangeRequest ?? CourseModificationChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          });
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  private loadCourseRequisiteType = () => {
    return LookupsUtil.getAll<CourseRequisiteTypeDTO>(CourseRequisiteTypeDTO.className)
      .then((results: CourseRequisiteTypeDTO[]) => {
        if (results) {
          results = results.sort((a, b) => (a.displayOrder ?? '') > (b?.displayOrder ?? '') ? 1 : -1)
          this.setState({ courseRequisiteTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCategories = () => {
    return LookupsUtil.getAll<CategoryDTO>(CategoryDTO.className)
      .then((results: CategoryDTO[]) => {
        if (results) {
          this.setState({ categories: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCreditTypes = () => {
    return LookupsUtil.getAll<CreditTypeDTO>(CreditTypeDTO.className)
      .then((results: CreditTypeDTO[]) => {
        if (results) {
          this.setState({ creditTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const formValues = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const model = CourseModificationChangeRequestDTO.create(formValues);
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();
    model[CourseModificationChangeRequestDTO.courseId] = this.state.courseModification?.courseId ?? Guid.Empty();

    const request = SubmitCourseModificationChangeRequestStep2Handler.Request.create({
      courseModificationChangeRequest: model
    });

    CourseModificationChangeRequestApiService.submitStep2(request)
      .then((result: SubmitCourseModificationChangeRequestStep2Handler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          message.success('Submitted');

          this.setState({
            changeRequest: result.changeRequest ?? null,
            courseModification: result.changeRequest?.courseModificationChangeRequest ?? CourseModificationChangeRequestDTO.create()
          });

          this.resetForm();

          if (this.props.onSubmit && result.changeRequest?.id) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Submit Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Submit Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleSave = () => {
    this.setState({ saving: true });

    const formValues = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const model = CourseModificationChangeRequestDTO.create(formValues);
    model.changeRequestId = this.props.changeRequestId ?? Guid.Empty();
    model.installTermId = model.installTerm == '' ? null : model.installTerm;

    const request = SaveCourseModificationChangeRequestStep2Handler.Request.create({
      courseModificationChangeRequest: model
    });

    CourseModificationChangeRequestApiService.saveStep2(request)
      .then((result: SaveCourseModificationChangeRequestStep2Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
            courseModification: result.changeRequest?.courseModificationChangeRequest ?? CourseModificationChangeRequestDTO.create()
          });

          this.resetForm();
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private getCreditColumns = () => {
    const variableHours = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.variableHours);
    const columns = [
      {
        title: 'Credit Type',
        dataIndex: CourseModificationChangeRequestCreditDTO.creditTypeId,
        render: (data: any, row: any, index: number) => {
          const creditTypeId = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.courseModificationChangeRequestCredits)[index].creditTypeId;
          if (creditTypeId) {
            const creditType = this.state.creditTypes.filter(x => x.id == creditTypeId)[0];
            if (creditType) {
              return <ValueLabel text={creditType.name} />;
            }
          }
        }
      },
      {
        title: variableHours ? 'Min Credits' : 'Credits',
        dataIndex: CourseModificationChangeRequestCreditDTO.minHours,
        render: (data: any, row: any, index: number) => {
          return (
            <label title={'Min' + index} >
              <FormItem
                {...this.getCreditTypeFormItems(index).get(CourseModificationChangeRequestCreditDTO.minHours)}
                {...ValidationUtil.getValidation(CourseModificationChangeRequestCreditDTO.minHours + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                <InputNumber disabled={this.props.readonly} />
              </FormItem>
            </label>
          );
        }
      },
    ];

    if (variableHours) {
      columns.push(
        {
          title: 'Max Credits',
          dataIndex: CourseModificationChangeRequestCreditDTO.maxHours,
          render: (data: any, row: any, index: number) => {
            return (
              <label title={'Max' + index}  >
                <FormItem
                  {...this.getCreditTypeFormItems(index).get(CourseModificationChangeRequestCreditDTO.maxHours)}
                  {...ValidationUtil.getValidation(CourseModificationChangeRequestCreditDTO.maxHours + index, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>

                  <InputNumber disabled={this.props.readonly} />
                </FormItem>
              </label>
            );
          }
        });
    }

    return columns;
  }

  private handleAddCourseRequisite = () => {
    const values = this._formRef.current?.getFieldsValue();
    const form = this._courseSearch.current?.getValue();
    this._courseSearch.current?.getForm().current?.validateFields();

    let courseModificationChangeRequestCourseRequisites = values.courseModificationChangeRequestCourseRequisites;
    const loaders = [];
    loaders.push(this._courseSearch.current?.getForm().current?.validateFields());

    return Promise.all(loaders).then(() => {
      if (!courseModificationChangeRequestCourseRequisites) {
        courseModificationChangeRequestCourseRequisites = [];
      }

      courseModificationChangeRequestCourseRequisites.push({
        requisiteCourse: form?.course,
        requisiteCourseId: form?.courseId,
        courseRequisiteTypeId: form?.courseRequisiteTypeId
      })
      this._formRef.current?.setFieldsValue({ courseModificationChangeRequestCourseRequisites: courseModificationChangeRequestCourseRequisites })
    });
  }

  private handleRemoveCourseRequisite = (record: CourseModificationChangeRequestCourseRequisiteDTO) => {
    const value = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.courseModificationChangeRequestCourseRequisites);
    const deletedValue = value.find((x: any) => x.requisiteCourseId == record.requisiteCourseId);
    value.remove(deletedValue);
    this._formRef.current?.setFieldsValue({ courseModificationChangeRequestCourseRequisites: value })
  }

  private handleEditCourseRequisite = (record: CourseModificationChangeRequestCourseRequisiteDTO) => {
    const value = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.courseModificationChangeRequestCourseRequisites);
    const courseRequisiteTypeId = this._courseRequisiteFormRef.current?.getFieldValue(CourseModificationChangeRequestCourseRequisiteDTO.courseRequisiteTypeId);
    value.find((x: any) => x.requisiteCourseId == record.requisiteCourseId).courseRequisiteTypeId = courseRequisiteTypeId;
    this._formRef.current?.setFieldsValue({ courseModificationChangeRequestCourseRequisites: value })
  }

  private addCourseRequisite = () => {
    Modal.confirm({
      title: 'Select Course',
      content: <CourseSearch isCourseRequisite={true} ref={this._courseSearch} institutionId={this.state.changeRequest?.institutionId ?? Guid.Empty()} />,
      onOk: () => this.handleAddCourseRequisite(),
      width: 1000
    });
  }

  private removeCourseRequisite = (record: CourseModificationChangeRequestCourseRequisiteDTO) => {
    Modal.confirm({
      title: 'Remove Course Requisite',
      onOk: () => this.handleRemoveCourseRequisite(record),
      okType: 'danger',
      okText: 'Remove',
      width: 300
    });
  }

  private editCourseRequisite(record: CourseModificationChangeRequestCourseRequisiteDTO) {
    Modal.confirm({
      title: 'Edit Course Requisite',
      onOk: () => this.handleEditCourseRequisite(record),
      content: <Form
        ref={this._courseRequisiteFormRef}
        layout="vertical"
        initialValues={record}
        onFinish={() => this.handleEditCourseRequisite(record)}
        requiredMark={true}>
        <FormItem
          {...this.getFormItems().get(CourseModificationChangeRequestCourseRequisiteDTO.courseRequisiteTypeId)}>
          <Dropdown dropdownMatchSelectWidth={false}
            allowClear={true}
            showSearch
            optionFilterProp="children"
          >
            {this.renderCourseRequisiteTypes()}
          </Dropdown>
        </FormItem>
      </Form>,
      okText: 'Edit',
      okType: 'primary',
      width: 500
    });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const otherInstitutions = (this.state.courseModification.course?.institutionCourses?.length ?? 0) > 1;

    const formItems = this.getFormItems();

    return (
      <Space size="small" direction="vertical">
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.courseModification}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>
          <Space size="small" direction="vertical">
            <FormItem
              {...formItems.get(CourseModificationChangeRequestDTO.courseId)}
              {...ValidationUtil.getValidation(CourseModificationChangeRequestDTO.courseId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <ValueLabel value={this.state.courseModification.course?.display} />
            </FormItem>

            <FormItem
              {...formItems.get(CourseModificationChangeRequestDTO.title)}
              {...ValidationUtil.getValidation(CourseModificationChangeRequestDTO.title, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <ValueLabel />
            </FormItem>

            <FormItem
              {...formItems.get(CourseModificationChangeRequestDTO.categoryId)}
              {...ValidationUtil.getValidation(CourseModificationChangeRequestDTO.categoryId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <Dropdown disabled={this.props.readonly}>
                {this.state.categories.map(x => { return this.renderCategoryOption(x) })}
              </Dropdown>
            </FormItem>

            <FormItem
              {...formItems.get(CourseModificationChangeRequestDTO.description)}
              {...ValidationUtil.getValidation(CourseModificationChangeRequestDTO.description, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <RichTextEditor readonly={this.props.readonly} />
            </FormItem>

            <FormItem
              key={CourseModificationChangeRequestDTO.objectives}
              {...formItems.get(CourseModificationChangeRequestDTO.objectives)}
              {...ValidationUtil.getValidation(CourseModificationChangeRequestDTO.objectives, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <RichTextEditor readonly={this.props.readonly} />
            </FormItem>

            <FormItem
              {...formItems.get(CourseModificationChangeRequestDTO.install)}
              {...ValidationUtil.getValidation(CourseModificationChangeRequestDTO.install, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
              <TermInput futureYears={true} disabled={this.props.readonly} />
            </FormItem>

            {
              <FormItem
                {...formItems.get(CourseModificationChangeRequestDTO.variableHours)}
                {...ValidationUtil.getValidation(CourseModificationChangeRequestDTO.variableHours, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                <YesNoInput disabled={this.props.readonly || otherInstitutions} />
              </FormItem>
            }

            <Typography.Paragraph italic={true}>
              Please provide credits for at least one credit type in the table below.
            </Typography.Paragraph>

            <Form.List name={CourseModificationChangeRequestDTO.courseModificationChangeRequestCredits}>
              {(credits) => {
                return (
                  <Form.Item
                    {...ValidationUtil.getValidation(CourseModificationChangeRequestDTO.courseModificationChangeRequestCredits, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                    <Table dataSource={credits} summary={() => {
                      const variableHours = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.variableHours);
                      const credits = this._formRef.current?.getFieldValue(CourseModificationChangeRequestDTO.courseModificationChangeRequestCredits);
                      let min = '';
                      let max = 0;
                      if (credits && credits.length > 0) {
                        min = credits?.map((x: CourseModificationChangeRequestCreditDTO) => x.minHours ?? 0).reduce((a: number, b: number) => a + b) ?? 0;
                        max = credits?.map((x: CourseModificationChangeRequestCreditDTO) => x.maxHours ?? 0).reduce((a: number, b: number) => a + b) ?? 0;
                      }

                      let minLabel = min;
                      const maxLabel = max;

                      if (!variableHours) {
                        minLabel = minLabel + ' (' + this.state.courseModification.course?.currentDetail?.minCredits + ' Required)';
                      }

                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}></Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            <Typography.Text strong={true} type="danger">{minLabel}</Typography.Text>
                          </Table.Summary.Cell>
                          {
                            variableHours ?
                              <Table.Summary.Cell index={1}>
                                <Typography.Text strong={true} type="danger">{maxLabel}</Typography.Text>
                              </Table.Summary.Cell> :
                              null
                          }
                        </Table.Summary.Row>
                      );
                    }} columns={this.getCreditColumns()} pagination={false} />
                  </Form.Item>
                );
              }}
            </Form.List>

            <Typography.Title level={5}>
              Prerequisites/Co-Requisites
            </Typography.Title>

            <Space direction='vertical'>
              {this.props.readonly ? null : <Button onClick={this.addCourseRequisite}>Add Course</Button>}
              <Form.List name={CourseModificationChangeRequestDTO.courseModificationChangeRequestCourseRequisites}>
                {(courseModificationChangeRequestCourseRequisites) => {
                  return (<Table dataSource={courseModificationChangeRequestCourseRequisites} columns={this.getCourseModificationCourseCourseRequisiteColumns()} pagination={false} />)
                }}
              </ Form.List>

            </ Space>

            {this.renderActions()}
          </Space >
        </Form>
      </Space >
    );
  }

  renderActions() {
    if (!this.props.readonly) {
      return (
        <Space direction='horizontal' wrap={true}>
          <GoBackButton onClick={this.props.onPrevious} />
          <SaveAndContinueButton submitting={this.state.submitting} />
          <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton resetting={this.state.resetting} onConfirm={this.resetForm} />
        </Space>
      );
    }
  }

  renderCategoryOption(category: CategoryDTO): any {
    if (category.id) {
      return <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>;
    }
  }

  renderCourseRequisiteType(courseRequisiteType: CourseRequisiteTypeDTO): any {
    if (courseRequisiteType.id) {
      return <Select.Option key={courseRequisiteType.id} value={courseRequisiteType.id} >{courseRequisiteType.name}</Select.Option>;
    }
  }

  renderCourseRequisiteTypes() {
    return this.state.courseRequisiteTypes.map(x => { return this.renderCourseRequisiteType(x) });
  }
}

export default CourseModificationChangeRequestStep2Form