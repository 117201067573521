import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TransferMajorGeneralEducationTypeDataTable from '../../../../components/datatables/postSecondary/TransferMajorGeneralEducationTypeDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface TransferMajorGeneralEducationTypeListProps {
}

interface TransferMajorGeneralEducationTypeListState {
}

class TransferMajorGeneralEducationTypeListPage extends React.Component<TransferMajorGeneralEducationTypeListProps & RouteComponentProps<RouteObject>, TransferMajorGeneralEducationTypeListState> {
  constructor(props: TransferMajorGeneralEducationTypeListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="General Education Type" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.transferMajorGeneralEducationTypeList()} />

        <Card >
          <TransferMajorGeneralEducationTypeDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(TransferMajorGeneralEducationTypeListPage);
