// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveThirdPartyCredentialTypeDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ThirdPartyCredentialTypeDTO from '../models/ThirdPartyCredentialTypeDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    thirdPartyCredentialTypeId: number;
    thirdPartyCredentialType: ThirdPartyCredentialTypeDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            thirdPartyCredentialTypeId: 0,
            thirdPartyCredentialType: null,
        },
        initValues);
    },
    thirdPartyCredentialTypeId: 'thirdPartyCredentialTypeId',
    thirdPartyCredentialType: 'thirdPartyCredentialType',
}

interface Result extends ResultDTO {
    thirdPartyCredentialType: ThirdPartyCredentialTypeDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            thirdPartyCredentialType: null,
        },
        initValues);
    },
    thirdPartyCredentialType: 'thirdPartyCredentialType',
}

export { Request, Result };