import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, Space, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { default as CoursesApiService } from '../../../api/CoursesApiService';
import { default as InstituionCoursesApiService } from '../../../api/InstitutionCoursesApiService';
import AuthorizedContent from '../../../components/AuthorizedContent';
import ChangeRequestDataTable from '../../../components/datatables/postSecondary/ChangeRequestsDataTable';
import CourseDetailsForm from '../../../components/forms/postSecondary/CourseDetailsForm';
import InstitutionCourseDetailsForm from '../../../components/forms/postSecondary/InstitutionCourseDetailsForm';
import EditCancelButton from '../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetCourseDetailsHandler from '../../../handlerModels/GetCourseDetailsHandler';
import * as GetInstitutionCourseDetailByCourseAndInstitutionHandler from '../../../handlerModels/GetInstitutionCourseDetailByCourseAndInstitutionHandler';
import CourseDetailDTO from '../../../models/CourseDetailDTO';
import CourseDTO from '../../../models/CourseDTO';
import InstitutionCourseDetailDTO from '../../../models/InstitutionCourseDetailDTO';
import InstitutionCourseDTO from '../../../models/InstitutionCourseDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import BaseFormState from '../../../redux/bases/BaseFormState';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import Guid from '../../../utils/Guid';
import HistoryUtil from '../../../utils/HistoryUtil';
import LookupsUtil from '../../../utils/LookupsUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import ParameterUtil from '../../../utils/ParameterUtil';

interface InstitutionCourseDetailsPageState extends BaseFormState {
  activeTab: string;
  course: CourseDTO;
  courseId: string;
  institution: InstitutionDTO;
  institutionCourse?: InstitutionCourseDTO;
  institutionCourseDetails?: InstitutionCourseDetailDTO[];
  institutionId: string;
  originatingPage: string;
  selectedCourseDetails: CourseDetailDTO;

}

interface InstitutionCourseDetailsPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class InstitutionCourseDetailsPage extends React.Component<InstitutionCourseDetailsPageProps & RouteComponentProps<RouteObject>, InstitutionCourseDetailsPageState> {
  private readonly _institutionCourseDetailsRef = React.createRef<InstitutionCourseDetailsForm>();
  private readonly _courseDetailsRef = React.createRef<CourseDetailsForm>();

  private static readonly COURSE_DETAILS = 'COURSE_DETAILS';
  private static readonly INSTITUTION_COURSE_DETAILS = 'INSTITUTION_COURSE_DETAILS';

  constructor(props: InstitutionCourseDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      activeTab: 'course_details',
      course: CourseDTO.create(),
      courseId: Guid.Empty(),
      editings: new Map<string, boolean>(),
      institution: InstitutionDTO.create(),
      institutionId: Guid.Empty(),
      institutionCourseDetails: [],
      selectedCourseDetails: CourseDetailDTO.create(),
      originatingPage: '',
    };
  }

  componentDidMount() {
    const institutionId = ParameterUtil.getPathPart(this.props.match, 'institutionId');
    const courseId = ParameterUtil.getPathPart(this.props.match, 'courseId');
    const institutionCourseId = ParameterUtil.getPathPart(this.props.match, 'institutionCourseId');
    const originatingPage = this.props.match.path.split('/')[1];

    this.setState({ originatingPage: originatingPage, institutionId: institutionId, loading: true });

    const loaders = [];

    if (institutionCourseId == 'new' && courseId == 'new') {
      const disciplineId = ParameterUtil.getParameter('disciplineId');
      const catalogNumber = ParameterUtil.getParameter('catalogNumber');

      const course = CourseDTO.create({
        id: Guid.Empty(),
        disciplineId: disciplineId,
        catalogNumber: catalogNumber
      });
      this.toggleIsEditing(InstitutionCourseDetailsPage.COURSE_DETAILS, true);

      this.setState({ course: course, isEditing: true, isNew: true });
    }
    else if (institutionCourseId == 'new') {
      this.toggleIsEditing(InstitutionCourseDetailsPage.INSTITUTION_COURSE_DETAILS, true);
      this.setState({ isNew: true, courseId: courseId })
      loaders.push(this.loadCourseDetails(courseId));
    }
    else {
      this.setState({ courseId: courseId });
      loaders.push(this.loadInstitutionCourse(courseId, institutionId));
    }

    loaders.push(this.loadInstitutions(institutionId));

    Promise.all(loaders).
      finally(() => {
        this.setState({ loading: false })
      });
  }

  private loadInstitutions = (institutionId: string) => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institution: results.find(x => x.id == institutionId) ?? InstitutionDTO.create() });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadCourseDetails = (courseId: string) => {
    return CoursesApiService.getCourseDetails(courseId)
      .then((results: GetCourseDetailsHandler.Result) => {
        if (results.course?.courseDetails) {
          this.setState({
            course: results.course ?? CourseDTO.create(),
            selectedCourseDetails: results.course?.courseDetails[results.course?.courseDetails.length - 1] ?? CourseDetailDTO.create()
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      })
  }

  private loadInstitutionCourse = (courseId: string, institutionId: string) => {
    return InstituionCoursesApiService.getInstitutionCourseDetailByCourseIdandInstitutionId(courseId, institutionId)
      .then((results: GetInstitutionCourseDetailByCourseAndInstitutionHandler.Result) => {
        if (results.courseDetails) {
          this.setState({
            course: results.institutionCourse?.course ?? CourseDTO.create(),
            institutionId: institutionId,
            institutionCourse: results.institutionCourse ?? InstitutionCourseDTO.create(),
            institutionCourseDetails: results.institutionCourseDetails ?? [],
            selectedCourseDetails: results.courseDetails[results.courseDetails.length - 1] ?? CourseDetailDTO.create()
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      })
  }

  private courseDetailsSaved = (courseId: string) => {
    this.toggleIsEditing(InstitutionCourseDetailsPage.COURSE_DETAILS, false);
    this.loadCourseDetails(courseId ?? Guid.Empty());
    this.goToInstitutionCourse(courseId ?? Guid.Empty(), this.state.institutionId, 'new');
  }

  private toggleIsEditing = (key: string, value?: boolean) => {
    if (this.state.editings) {
      const editings = this.state.editings;

      if (value !== undefined) {
        editings.set(key, value);
      }
      else {
        editings.set(key, !editings.get(key) ?? true);
      }

      if (!editings.get(key)) {
        switch (key) {
          case InstitutionCourseDetailsPage.COURSE_DETAILS:
            this._courseDetailsRef.current?.resetForm();
            break;
          case InstitutionCourseDetailsPage.INSTITUTION_COURSE_DETAILS:
            this._institutionCourseDetailsRef.current?.resetForm();
            break;
        }
      }

      this.setState({ editings: editings });
    }
  }

  private instituitionCourseDetailsSaved = (institutionCourseId: string) => {
    this.toggleIsEditing(InstitutionCourseDetailsPage.INSTITUTION_COURSE_DETAILS, false);

    this.loadInstitutionCourse(this.state.courseId, this.state.institutionId);
    if (this.state.isNew) {
      this.setState({ isNew: false });
      this.goToInstitutionCourse(this.state.courseId ?? Guid.Empty(), this.state.institutionId, institutionCourseId);
    }
  }

  private goToCourses = () => {
    HistoryUtil.push(Routes.COURSES);
  }

  private goToInstitutionCourse = (courseId: string, institutionId: string, institutionCourseId: string) => {
    this.state.originatingPage == 'courses' ?
      HistoryUtil.push(Routes.generate(Routes.COURSE_INSTITUTION_DETAILS, { courseId: courseId, institutionId: institutionId, institutionCourseId: institutionCourseId })) :
      HistoryUtil.push(Routes.generate(Routes.INSTITUTION_COURSE_DETAILS, { courseId: courseId, institutionId: institutionId, institutionCourseId: institutionCourseId }));
  }

  private goToInstitution = () => {
    HistoryUtil.push(Routes.generate(Routes.INSTITUTION_DETAILS, { id: this.state.institutionId }));
  }

  private goToCourse = () => {
    HistoryUtil.push(Routes.generate(Routes.COURSE_DETAILS, { id: this.state.courseId }));
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
  }

  render() {
    const courseTitle = this.state.loading ? '...' : this.state.selectedCourseDetails.title ?? '...';
    const courseDisplay = this.state.loading ? '...' : this.state.course?.display ?? '...';
    const institutionName = this.state.loading ? '...' : this.state.institution?.name ?? '...';
    const institutionAbbreviation = this.state.loading ? '...' : this.state.institution?.abbreviation ?? '...';
    let onBack = this.state.originatingPage == 'courses' ? this.goToCourse : this.goToInstitution;

    let breadcrumbs = this.state.originatingPage == 'courses' ?
      Breadcrumbs.courseInstitutionDetials(courseDisplay, this.state.courseId, institutionAbbreviation, this.state.institutionId, this.state.institutionCourse?.id ?? Guid.Empty()) :
      Breadcrumbs.institutionCourseDetails(institutionAbbreviation, this.state.institutionId, courseDisplay, this.state.courseId, this.state.institutionCourse?.id ?? Guid.Empty());

    if (this.props.selectedInstitution != null && this.props.selectedInstitution != undefined) {
      breadcrumbs = Breadcrumbs.singleInstitutionCourseDetails(courseDisplay, this.state.courseId, this.state.institutionId);
      onBack = this.goToCourses;
    }

    return (
      <Content >
        <HeaderPortal
          title={courseTitle}
          breadcrumbs={breadcrumbs}
          extra={this.renderMoreActions()}
          onBack={onBack}
          subTitle={institutionName}
          footer={
            <Tabs onChange={this.changeTab} >
              <Tabs.TabPane tab="Details" key="course_details" />
              <Tabs.TabPane tab="Change Requests" key="course_change_request" />
            </Tabs>
          } />
        <PageStayPrompt when={this.state.isEditing} />

        <Space direction="vertical" size="large">
          {this.renderCourseDetailsCard()}
          {this.renderDetailsCard()}
          {this.renderChangeRequests()}
        </ Space>
      </Content>
    );
  }

  renderDetailsCard() {
    if (this.state.selectedCourseDetails.id != Guid.Empty() && this.state.activeTab === 'course_details') {
      const editButton =
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_COURSE]}>
          <EditCancelButton
            onClick={() => this.toggleIsEditing(InstitutionCourseDetailsPage.INSTITUTION_COURSE_DETAILS)}
            isEditing={this.state.editings?.get(InstitutionCourseDetailsPage.INSTITUTION_COURSE_DETAILS)} />
        </AuthorizedContent>

      return (
        <Card title="Community College Details" extra={editButton} >
          <InstitutionCourseDetailsForm
            ref={this._institutionCourseDetailsRef}
            institutionId={this.state.institutionId}
            courseId={this.state.courseId}
            isEditing={this.state.editings?.get(InstitutionCourseDetailsPage.INSTITUTION_COURSE_DETAILS)}
            isNew={this.state.isNew}
            onSave={this.instituitionCourseDetailsSaved} />
        </Card>
      );
    }
  }

  renderCourseDetailsCard() {
    if (this.state.activeTab === 'course_details') {
      const editButton = this.state.selectedCourseDetails.id == Guid.Empty() ?
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_COURSE]}>
          <EditCancelButton
            onClick={() => this.toggleIsEditing(InstitutionCourseDetailsPage.COURSE_DETAILS)}
            isEditing={this.state.editings?.get(InstitutionCourseDetailsPage.COURSE_DETAILS)} />
        </AuthorizedContent>
        :
        null
      if (this.state.selectedCourseDetails.id != Guid.Empty()) {
        return (
          <Card title={'Course Details'} extra={editButton}>
            {/* <CourseDetailsForm
              readonly={this.state.selectedCourseDetails.id != Guid.Empty()}
              isEditing={this.state.editings?.get(InstitutionCourseDetailsPage.COURSE_DETAILS)}
              courseId={this.state.courseId}
              course={this.state.course.disciplineId != Guid.Empty() ? this.state.course : undefined}
              onSave={this.courseDetailsSaved} /> */}
          </Card>
        );
      }
    }
  }

  renderChangeRequests() {
    if (this.state.activeTab === 'course_change_request') {

      return (
        <Card title={'Change Request'}>
          <ChangeRequestDataTable userId={this.props.currentUser?.userId ?? null} institution={this.props.selectedInstitution} courseId={this.state.courseId ?? Guid.Empty()} />
        </Card>
      );
    }
  }

  renderMoreActions() {
    if (this.state.institutionCourseDetails) {
      const menu = (
        <Menu>
          {this.state.institutionCourseDetails.map(x => {
            const yearTerm = x.retire?.termId == Guid.Empty() ? x.installDisplay : x.installDisplay + ' | ' + x.retireDisplay;

            return (
              <Menu.Item key={x.id} >
                {yearTerm}
              </Menu.Item>
            );
          })
          }
        </Menu >
      );
      return (
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      );
    }
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(InstitutionCourseDetailsPage));