import { ReloadOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Modal } from 'antd';
import * as React from 'react';

interface ResetButtonState { }

interface ResetButtonProps extends ButtonProps {
  resetting?: boolean;
  onConfirm: () => void;
  promptConfirm?: boolean;
}

class ResetButton extends React.Component<ResetButtonProps, ResetButtonState> {
  constructor(props: ResetButtonProps) {
    super(props);

    this.state = {};
  }

  private handleClick = () => {
    if (this.props.promptConfirm) {
      Modal.confirm({
        title: 'Reset Form?',
        content: 'Are you sure you want to reset this form?',
        onOk: () => {
          this.props.onConfirm();
        },
        okType: 'danger',
        okText: 'Reset'
      });
    }
    else {
      this.props.onConfirm();
    }
  }

  render() {
    const handleClick = this.props.onClick ? this.props.onClick : this.handleClick;

    return (
      <Button
        {...this.props}
        icon={<ReloadOutlined />}
        type='text'
        loading={this.props.resetting || this.props.loading}
        onClick={handleClick}>
        Reset
      </Button>
    );
  }
}

export default ResetButton;
