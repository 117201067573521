import { Alert, Input, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import * as SaveNewProgramChangeRequestStep8Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep8Handler';
import * as SubmitNewProgramChangeRequestStep8Handler from '../../../handlerModels/SubmitNewProgramChangeRequestStep8Handler';
import * as GetNewProgramChangeRequestStep8Handler from '../../../handlerModels/GetNewProgramChangeRequestStep8Handler';
import AwardTypeDTO from '../../../models/AwardTypeDTO';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import ExternalInstitutionDTO from '../../../models/ExternalInstitutionDTO';
import IowaVariationDTO from '../../../models/IowaVariationDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import TermInput from '../../TermInput';
import Dropdown from '../../inputs/Dropdown';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep8FormState extends BaseFormState {
  awardTypes: AwardTypeDTO[];
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
  externalInstitution: ExternalInstitutionDTO[];
  iowaVariations: IowaVariationDTO[];
}

interface NewProgramChangeRequestStep8FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: () => void;
  onSubmit?: (awardId?: string) => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep8Form extends React.Component<NewProgramChangeRequestStep8FormProps, NewProgramChangeRequestStep8FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private _formItems = new Map<string, FormItemProps>()
    .set(NewProgramChangeRequestDTO.install, {
      required: true,
      name: NewProgramChangeRequestDTO.install,
      label: 'When will this program first be offered?',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramChangeRequestDTO.programMarketingLink, {
      required: true,
      name: NewProgramChangeRequestDTO.programMarketingLink,
      label: 'Please provide a link to the website used to market this program.',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.https()
      ]
    })
    .set(NewProgramChangeRequestDTO.standardsAndBenchmarks, {
      name: NewProgramChangeRequestDTO.standardsAndBenchmarks,
      label: 'If applicable, please describe the plan for assessing student attainment or program standards and benchmarks.',
    })
    .set(NewProgramChangeRequestDTO.title, {
      required: true,
      name: NewProgramChangeRequestDTO.title,
      label: 'Please provide a title for this program.',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramChangeRequestDTO.iowaVariationId, {
      required: true,
      name: NewProgramChangeRequestDTO.iowaVariationId,
      label: 'Please select an Iowa variation.',
      rules: [ValidationRuleUtil.required()]
    })
    .set(NewProgramChangeRequestDTO.primrayAwardTypeId, {
      required: true,
      name: NewProgramChangeRequestDTO.primrayAwardTypeId,
      label: 'Please select a primary award (This will affect the award type on the primary award).',
      rules: [ValidationRuleUtil.required()]
    });


  constructor(props: NewProgramChangeRequestStep8FormProps) {
    super(props);

    this.state = {
      awardTypes: [],
      changeRequest: ChangeRequestDTO.create(),
      changeRequests: [],
      newProgramChangeRequest: NewProgramChangeRequestDTO.create({
        programId: null,
        externalInstitutionIds: []
      }),
      externalInstitution: [],
      iowaVariations: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep8FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.iowaVariations || this.state.iowaVariations.length == 0) {
      loaders.push(this.loadIowaVariation());
    }

    if (!this.state.awardTypes || this.state.awardTypes.length == 0) {
      loaders.push(this.loadAwardTypes());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest())
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: this.props.loading ? true : false });
    });
  }

  private loadIowaVariation = () => {
    return LookupsUtil.getAll<IowaVariationDTO>(IowaVariationDTO.className)
      .then((results: IowaVariationDTO[]) => {
        if (results) {
          this.setState({ iowaVariations: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAwardTypes = () => {
    return LookupsUtil.getAll<AwardTypeDTO>(AwardTypeDTO.className)
      .then((results: AwardTypeDTO[]) => {
        if (results) {
          this.setState({ awardTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep8Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep8(request)
      .then((results: GetNewProgramChangeRequestStep8Handler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private handleChange = (altered: boolean) => {
    this.setState({ altered: altered });
  }

  private handleAwardTypeChange = () => {
    const awardType = this.state.awardTypes.find(x => x.id == this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.primrayAwardTypeId)) ?? AwardTypeDTO.create();

    if (!awardType.iowaVariationIds.includes(this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.iowaVariationId) ?? 0)) {
      this._formRef.current?.setFieldsValue({ iowaVariationId: undefined })
    }
  }

  private handleSave = () => {
    this.setState({ saving: true });

    let newProgramChangeRequest = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const stated = this.state.newProgramChangeRequest;
    newProgramChangeRequest = { ...stated, ...newProgramChangeRequest }

    const request = SaveNewProgramChangeRequestStep8Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest
    });

    return NewProgramChangeRequestApiService.saveStep8(request)
      .then((result: SaveNewProgramChangeRequestStep8Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    let newProgramChangeRequest = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;
    const stated = this.state.newProgramChangeRequest;
    newProgramChangeRequest = { ...stated, ...newProgramChangeRequest }

    const request = SubmitNewProgramChangeRequestStep8Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest
    });

    NewProgramChangeRequestApiService.submitStep8(request)
      .then((result: SubmitNewProgramChangeRequestStep8Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }

          if (this.props.onSubmit) {
            if (result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards) {
              this.props.onSubmit(result.changeRequest?.newProgramChangeRequest?.newProgramProgramAwards[0].id ?? Guid.Empty());
            }
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  render() {
    if (this.state.loading || this.props.changeRequestId == null || this.props.changeRequestId == undefined) {
      return <Skeleton active={true} />;
    }

    const formPrimaryAwardTypeId = this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.primrayAwardTypeId);
    const primarayAwardId = formPrimaryAwardTypeId ? formPrimaryAwardTypeId : this.state.newProgramChangeRequest.primrayAwardTypeId;

    return (
      <Space size="small" direction="vertical">
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.newProgramChangeRequest}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={!this.props.readonly}>

          <FormItem
            {...this._formItems.get(NewProgramChangeRequestDTO.title)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.title, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Input disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(NewProgramChangeRequestDTO.primrayAwardTypeId)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.primrayAwardTypeId, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Dropdown
              showSearch
              showArrow={!this.props.readonly}
              optionFilterProp="children"
              placeholder='Select Primary Award'
              disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting}
              onChange={this.handleAwardTypeChange}>
              {this.renderAwardTypes()}
            </Dropdown>
          </FormItem>

          <FormItem
            {...this._formItems.get(NewProgramChangeRequestDTO.iowaVariationId)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.iowaVariationId, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Dropdown
              showSearch
              showArrow={!this.props.readonly}
              optionFilterProp="children"
              placeholder='Select Iowa Variation'
              disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting}>
              {this.state.iowaVariations.filter(x => this.state.awardTypes.find(x => x.id == primarayAwardId)?.iowaVariationIds.includes(x.id)).map(x => {
                return this.renderIowaVariation(x);
              })}
            </Dropdown>
          </FormItem>

          <FormItem
            {...this._formItems.get(NewProgramChangeRequestDTO.install)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.install, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <TermInput futureYears={true} disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(NewProgramChangeRequestDTO.programMarketingLink)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.programMarketingLink, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Input disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(NewProgramChangeRequestDTO.standardsAndBenchmarks)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.standardsAndBenchmarks, this.state.fieldErrors, this.state.submitted || this.props.readonly)} >
            <Input.TextArea disabled={this.props.readonly} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>
          {this.renderActions()}
        </Form>
      </Space >
    );
  }

  private renderActions() {
    if (!this.props.readonly) {
      return < Space direction='horizontal' wrap={true} >
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved}></SaveButton>
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space >
    }
  }

  private renderAwardTypes() {
    let validAwardTypes = this.state.awardTypes;
    if (this.state.newProgramChangeRequest?.isTransferMajorProgram) {
      validAwardTypes = validAwardTypes.filter(x => x.transferMajor === true);
    }

    return validAwardTypes.map(x => this.renderAwardType(x));
  }

  renderAwardType(awardType: AwardTypeDTO) {
    if (awardType.id) {
      return <Select.Option key={awardType.id} value={awardType.id}>{awardType.display}</Select.Option>
    }
  }

  renderIowaVariation(iowaVariation: IowaVariationDTO) {
    if (iowaVariation.id) {
      return <Select.Option key={iowaVariation.id} value={iowaVariation.id}>{iowaVariation.display}</Select.Option>
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default NewProgramChangeRequestStep8Form