// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import AwardTypeDTO from './AwardTypeDTO';
import IowaVariationDTO from './IowaVariationDTO';
import InstructionalLevelDTO from './InstructionalLevelDTO';
import TypeOfProgramDTO from './TypeOfProgramDTO';
import SpecialEmphasisDTO from './SpecialEmphasisDTO';
import ObjectPurposeDTO from './ObjectPurposeDTO';
import InstitutionLocationDTO from './InstitutionLocationDTO';
import ProgramAwardModeOfDeliveryDTO from './ProgramAwardModeOfDeliveryDTO';
import ProgramAwardInstitutionLocationDTO from './ProgramAwardInstitutionLocationDTO';
import PostSecondaryProgramAwardThirdPartyCredentialDTO from './PostSecondaryProgramAwardThirdPartyCredentialDTO';
import ProgramTermDTO from './ProgramTermDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramAwardDTO extends EntitySelectorDTO { 
	id: string | null;
	programDetailId: string | null;
	title: string | null;
	description: string | null;
	order: number;
	awardTypeId: number | null | null;
	awardType: AwardTypeDTO | null;
	iowaVariationId: number | null | null;
	iowaVariation: IowaVariationDTO | null;
	instructionalLevelId: number | null | null;
	instructionalLevel: InstructionalLevelDTO | null;
	typeOfProgramId: number | null | null;
	typeOfProgram: TypeOfProgramDTO | null;
	specialEmphasisId: number | null | null;
	specialEmphasis: SpecialEmphasisDTO | null;
	objectPurposeId: number | null | null;
	objectPurpose: ObjectPurposeDTO | null;
	institutionLocationId: string | null | null;
	institutionLocation: InstitutionLocationDTO | null;
	prerequisite: boolean;
	totalWeeks: number | null | null;
	totalCredits: number | null | null;
	programAwardModesOfDeliveryIds: number[];
	programAwardModesOfDelivery: ProgramAwardModeOfDeliveryDTO[] | null;
	programAwardInstitutionLocationIds: string[];
	programAwardInstitutionLocations: ProgramAwardInstitutionLocationDTO[] | null;
	thirdPartyCredentials: PostSecondaryProgramAwardThirdPartyCredentialDTO[] | null;
	terms: ProgramTermDTO[] | null;
	deletedOn: moment.Moment | string | null;
	deletedBy: string | null;
}

const ProgramAwardDTO = {
    className: 'ProgramAwardDTO',
    create: (initValues?: Partial<ProgramAwardDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			programDetailId: '00000000-0000-0000-0000-000000000000',
			title: "",
			description: "",
			order: 0,
			awardTypeId: null,
			awardType: null,
			iowaVariationId: null,
			iowaVariation: null,
			instructionalLevelId: null,
			instructionalLevel: null,
			typeOfProgramId: null,
			typeOfProgram: null,
			specialEmphasisId: null,
			specialEmphasis: null,
			objectPurposeId: null,
			objectPurpose: null,
			institutionLocationId: null,
			institutionLocation: null,
			prerequisite: false,
			totalWeeks: null,
			totalCredits: null,
			programAwardModesOfDeliveryIds: [],
			programAwardModesOfDelivery: [],
			programAwardInstitutionLocationIds: [],
			programAwardInstitutionLocations: [],
			thirdPartyCredentials: [],
			terms: [],
			deletedOn: null,
			deletedBy: "",
        },
        initValues);
    },
	id: 'id',
	programDetailId: 'programDetailId',
	title: 'title',
	description: 'description',
	order: 'order',
	awardTypeId: 'awardTypeId',
	awardType: 'awardType',
	iowaVariationId: 'iowaVariationId',
	iowaVariation: 'iowaVariation',
	instructionalLevelId: 'instructionalLevelId',
	instructionalLevel: 'instructionalLevel',
	typeOfProgramId: 'typeOfProgramId',
	typeOfProgram: 'typeOfProgram',
	specialEmphasisId: 'specialEmphasisId',
	specialEmphasis: 'specialEmphasis',
	objectPurposeId: 'objectPurposeId',
	objectPurpose: 'objectPurpose',
	institutionLocationId: 'institutionLocationId',
	institutionLocation: 'institutionLocation',
	prerequisite: 'prerequisite',
	totalWeeks: 'totalWeeks',
	totalCredits: 'totalCredits',
	programAwardModesOfDeliveryIds: 'programAwardModesOfDeliveryIds',
	programAwardModesOfDelivery: 'programAwardModesOfDelivery',
	programAwardInstitutionLocationIds: 'programAwardInstitutionLocationIds',
	programAwardInstitutionLocations: 'programAwardInstitutionLocations',
	thirdPartyCredentials: 'thirdPartyCredentials',
	terms: 'terms',
	deletedOn: 'deletedOn',
	deletedBy: 'deletedBy',
};

export default ProgramAwardDTO;