import { Alert, Input, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionCourseBlockApiService from '../../../api/InstitutionCourseBlockApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as SaveInstitutionCourseBlockDetailsHandler from '../../../handlerModels/SaveInstitutionCourseBlockDetailsHandler';
import InstitutionCourseBlockDetailDTO from '../../../models/InstitutionCourseBlockDetailDTO';
import InstitutionCourseBlockDTO from '../../../models/InstitutionCourseBlockDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import TermInput from '../../TermInput';

interface InstitutionCourseBlockDetailsFormState extends BaseFormState {
  institutions: InstitutionDTO[];
  institutionCourseBlockDetail: InstitutionCourseBlockDetailDTO;
}

interface InstitutionCourseBlockDetailsFormProps extends BaseFormProps {
  institutionCourseBlockDetail: InstitutionCourseBlockDetailDTO;
  onSave?: (id: string) => void;
  selectedInstitution: InstitutionDTO | null;
}

class InstitutionCourseBlockDetailsForm extends React.Component<InstitutionCourseBlockDetailsFormProps, InstitutionCourseBlockDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: InstitutionCourseBlockDetailsFormProps) {
    super(props);

    this.state = {
      institutions: [],
      institutionCourseBlockDetail: InstitutionCourseBlockDetailDTO.create()
    };
  }

  componentDidMount() {
    this.fetchData();
    this.setState({ institutionCourseBlockDetail: this.props.institutionCourseBlockDetail }, () => this.resetForm());
  }

  componentDidUpdate(prevProps: InstitutionCourseBlockDetailsFormProps) {
    if (this.props.institutionCourseBlockDetail != prevProps.institutionCourseBlockDetail) {
      this.setState({ institutionCourseBlockDetail: this.props.institutionCourseBlockDetail }, () => this.resetForm());
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false })
  }

  private fetchData() {
    this.setState({ loading: true });

    const loaders = [];
    if (this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = InstitutionCourseBlockDetailDTO.create(this._formRef.current?.getFieldsValue() ?? InstitutionCourseBlockDetailDTO.create());
    model.id = this.props.institutionCourseBlockDetail?.id ?? Guid.Empty();
    model.institutionId = this.props.selectedInstitution ? this.props.selectedInstitution?.id ?? Guid.Empty() : model.institutionId;
    model.institutionCourseBlockId = this.props.institutionCourseBlockDetail.institutionCourseBlockId ?? Guid.Empty();

    const request = SaveInstitutionCourseBlockDetailsHandler.Request.create({
      institutionCourseBlockDetail: model
    });

    InstitutionCourseBlockApiService.saveInstitutionCourseBlock(request)
      .then((result: SaveInstitutionCourseBlockDetailsHandler.Result) => {
        this.setState({ submitted: true });

        if (result?.succeeded) {
          this.setState({
            altered: !result.succeeded
          });

          message.success('Saved');

          if (this.props.onSave && result.institutionCourseBlock?.id) {
            this.props.onSave(result.institutionCourseBlock.id);
            this._formRef.current?.resetFields();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Institution Course Block could not be saved.');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Institution Course Block could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private _formItems = new Map<string, FormItemProps>()
    .set(InstitutionCourseBlockDetailDTO.title, {
      required: true,
      name: InstitutionCourseBlockDetailDTO.title,
      label: 'Title',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(150)
      ]
    })
    .set(InstitutionCourseBlockDetailDTO.code, {
      required: true,
      name: InstitutionCourseBlockDetailDTO.code,
      label: 'Code',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(3),
        ValidationRuleUtil.onlyAlphanumericCharacters()
      ]
    })
    .set(InstitutionCourseBlockDetailDTO.install, {
      required: true,
      name: InstitutionCourseBlockDetailDTO.install,
      label: 'Install Term',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })
    .set(InstitutionCourseBlockDetailDTO.retire, {
      name: InstitutionCourseBlockDetailDTO.retire,
      label: 'Retire Term',
    })
    .set(InstitutionCourseBlockDTO.institutionId, {
      required: true,
      name: InstitutionCourseBlockDTO.institutionId,
      label: 'Community College',
      rules: [
        ValidationRuleUtil.required(),
      ],
    })

  render() {
    if (this.state.loading || this.props.institutionCourseBlockDetail == null || this.props.institutionCourseBlockDetail == undefined) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form ref={this._formRef}
          layout="vertical"
          onChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}
          initialValues={this.props.institutionCourseBlockDetail}>

          {this.renderInstitutionSelect()}

          <FormItem
            {...ValidationUtil.getValidation(InstitutionCourseBlockDetailDTO.code, this.state.fieldErrors, this.state.submitted)}
            {...this._formItems.get(InstitutionCourseBlockDetailDTO.code)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...ValidationUtil.getValidation(InstitutionCourseBlockDetailDTO.title, this.state.fieldErrors, this.state.submitted)}
            {...this._formItems.get(InstitutionCourseBlockDetailDTO.title)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(InstitutionCourseBlockDetailDTO.install)}
            {...ValidationUtil.getValidation(InstitutionCourseBlockDetailDTO.install, this.state.fieldErrors, this.state.submitted)}>
            <TermInput disabled={(!this.props.isEditing) || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...this._formItems.get(InstitutionCourseBlockDetailDTO.retire)}
            {...ValidationUtil.getValidation(InstitutionCourseBlockDetailDTO.retire, this.state.fieldErrors, this.state.submitted)}>
            <TermInput disabled={(!this.props.isEditing) || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderActions()}
        </Form>
      </Space>
    );
  }

  renderInstitutionSelect() {
    if (!this.props.selectedInstitution || this.props.selectedInstitution.id == Guid.Empty()) {
      return (
        <FormItem
          {...this._formItems.get(InstitutionCourseBlockDTO.institutionId)}
          {...ValidationUtil.getValidation(InstitutionCourseBlockDTO.institutionId, this.state.fieldErrors, this.state.submitted)}>
          <Select
            disabled={!this.props.isEditing || this.state.loading || this.state.submitting}
            showSearch
            placeholder='Add Institution'
            optionFilterProp="children">
            {this.state.institutions.map(x => { return this.renderInstitutionOption(x) })}

          </Select>
        </FormItem >
      );
    }
  }

  renderInstitutionOption(x: InstitutionDTO) {
    if (x.id) {
      return <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>;
    }
  }

  renderActions() {
    if (this.props.isEditing && AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_INSTITUTION_COURSE_BLOCK])) {
      return (
        <Space direction='horizontal' wrap={true}>
          <SaveButton disabled={this.state.loading} saving={this.state.submitting} saved={this.state.saved} />
          <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
        </ Space>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default InstitutionCourseBlockDetailsForm;
