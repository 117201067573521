import { Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import Routes from '../../../config/Routes';
import * as DownloadCoursesAttachedReportHandler from '../../../handlerModels/DownloadCoursesAttachedReportHandler';
import InstitutionDTO from '../../../models/InstitutionDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import DownloadButton from '../../buttons/DownloadButton';
import ResetButton from '../../buttons/ResetButton';

interface CoursesAttachedDownloadFormState extends BaseFormState {
  institutions: InstitutionDTO[];
}

interface CoursesAttachedDownloadFormProps extends BaseFormProps {
  institutionId?: string;
}

class CoursesAttachedDownloadForm extends React.Component<CoursesAttachedDownloadFormProps, CoursesAttachedDownloadFormState> {

  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(DownloadCoursesAttachedReportHandler.Request.institutionId, {
      name: DownloadCoursesAttachedReportHandler.Request.institutionId,
      label: 'Community College',
      required: true,
      rules: [ValidationRuleUtil.required()]
    });

  constructor(props: CoursesAttachedDownloadFormProps) {
    super(props);

    this.state = {
      institutions: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.institutions || this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        if (results) {
          this.setState({ institutions: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private handleSubmit = () => {
    const institutionId = this.props.institutionId ?
      this.props.institutionId :
      this._formRef.current?.getFieldValue(DownloadCoursesAttachedReportHandler.Request.institutionId);

    window.open(Routes.generateFull(Routes.COURSE_ATTACHED_REPORT, {}, { institutionId: institutionId }), '_blank');
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          layout="vertical"
          onFinish={this.handleSubmit}
          requiredMark={true}>

          {this.props.institutionId ?
            null :
            <FormItem
              {...this._formItems.get(DownloadCoursesAttachedReportHandler.Request.institutionId)}
              {...ValidationUtil.getValidation(DownloadCoursesAttachedReportHandler.Request.institutionId, this.state.fieldErrors, this.state.submitted)}>
              <Select disabled={this.state.loading || this.state.submitting}
                showArrow={true}
                options={this.state.institutions.map(x => { return { value: x.id ?? Guid.Empty(), label: x.name } })} />
            </FormItem>
          }

          <Space direction={'horizontal'} >
            <DownloadButton downloading={this.state.submitting} />
            <ResetButton onConfirm={this.resetForm} />
          </Space>
        </Form>
      </Space >
    );
  }
}

export default CoursesAttachedDownloadForm;
