import { CheckOutlined, SendOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import * as React from 'react';

interface SubmitButtonState { }

interface SubmitButtonProps extends ButtonProps {
  submitting?: boolean;
  submitted?: boolean;
}

class SubmitButton extends React.Component<SubmitButtonProps, SubmitButtonState> {
  constructor(props: SubmitButtonProps) {
    super(props);

    this.state = {};
  }

  render() {
    const htmlType = this.props.htmlType ? this.props.htmlType : 'submit'
    const buttonType = this.props.type ? this.props.type : 'primary'

    return (
      <Button
        {...this.props}
        htmlType={htmlType}
        type={buttonType}
        icon={this.props.submitted ? <CheckOutlined /> : <SendOutlined />}
        loading={this.props.submitting || this.props.loading} >
        Submit
      </Button>
    );
  }
}

export default SubmitButton;
