import { CheckOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';
import { Avatar, Button, Comment, Form, FormInstance, FormItemProps, Input, List, message, Modal, notification, Space } from 'antd';
import moment, { Moment } from 'moment';
import * as React from 'react';
import ChangeRequestApiService from '../../../api/ChangeRequestApiService';
import ApprovalStatusType from '../../../consts/ApprovalStatusType';
import ChangeRequestStatusType from '../../../consts/ChangeRequestStatusType';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as AddChangeRequestCommentHandler from '../../../handlerModels/AddChangeRequestCommentHandler';
import * as ApproveChangeRequestHandler from '../../../handlerModels/ApproveChangeRequestHandler';
import * as DenyChangeRequestHandler from '../../../handlerModels/DenyChangeRequestHandler';
import * as GetChangeRequestCommentsHandler from '../../../handlerModels/GetChangeRequestCommentsHandler';
import ChangeRequestCommentDTO from '../../../models/ChangeRequestCommentDTO';
import UserSecurity from '../../../models/UserSecurity';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';

interface ChangeRequestCommentsFormState extends BaseFormState {
  comments: ChangeRequestCommentDTO[];
}

interface ChangeRequestCommentsFormProps extends BaseFormProps {
  currentUser: UserSecurity | null;
  changeRequestId: string;
  approvalId?: string;
  approvalUserId?: string;
  approvalStatusTypeId?: number;
  onSubmit?: () => void;
  changeRequestStatusTypeId: number;
  disabled?: boolean;
  redirect?: boolean;
  isOverride?: boolean;
  approvalActiveOn: string | Moment | null;
}

class ChangeRequestCommentsForm extends React.Component<ChangeRequestCommentsFormProps, ChangeRequestCommentsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(AddChangeRequestCommentHandler.Request.comment, {
        required: true,
        name: AddChangeRequestCommentHandler.Request.comment,
        rules: [
          ValidationRuleUtil.required()
        ]
      });
  }

  constructor(props: ChangeRequestCommentsFormProps) {
    super(props);

    this.state = {
      submitting: false,
      comments: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: ChangeRequestCommentsFormProps) {
    if (this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.approvalId != prevProps.approvalId) {
      this.fetchData();
    }
  }

  public triggerUpdate = () => {
    this.fetchData();
  }

  private fetchData = () => {
    if (!this.state.loading && this.props.changeRequestId !== Guid.Empty()) {
      ChangeRequestApiService.comments(GetChangeRequestCommentsHandler.Request.create({
        changeRequestId: this.props.changeRequestId
      }))
        .then((result: GetChangeRequestCommentsHandler.Result) => {
          this.setState({
            comments: result.comments ?? []
          });
        });
    }
  }

  private confirmApprove = () => {
    Modal.confirm({
      title: 'Approve Change Request?',
      type: 'error',
      content: 'Are you sure you wish to approve this change request?',
      okText: 'Approve Change Request',
      cancelText: 'Cancel',
      onOk: () => this.handleApprove(),
      okButtonProps: { icon: <CheckOutlined /> }
    });
  }

  private confirmDeny = () => {
    Modal.confirm({
      title: 'Reject Change Request?',
      content: 'Are you sure you wish to reject this change request? It will be sent back to the submitting user.',
      okText: 'Reject Change Request',
      cancelText: 'Cancel',
      onOk: () => this.handleDeny(),
      okButtonProps: { danger: true, icon: <CloseOutlined /> }
    });
  }

  private handleDeny = () => {
    this.setState({ submitting: true });

    const request = DenyChangeRequestHandler.Request.create({
      comment: this._formRef.current?.getFieldValue(DenyChangeRequestHandler.Request.comment) ?? '',
      ChangeRequestApprovalId: this.props.approvalId ?? Guid.Empty()
    })

    ChangeRequestApiService.deny(request)
      .then((result: ApproveChangeRequestHandler.Result | null) => {
        if (result?.succeeded) {
          this._formRef.current?.resetFields();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }

          this.fetchData();
        } else {
          notification.error({
            message: 'Could Not Approve',
            description: result?.errors.join('\n')
          })
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error');
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  private handleApprove = () => {
    this.setState({ submitting: true });

    const request = ApproveChangeRequestHandler.Request.create({
      comment: this._formRef.current?.getFieldValue(ApproveChangeRequestHandler.Request.comment) ?? '',
      changeRequestApprovalId: this.props.approvalId ?? Guid.Empty()
    })

    ChangeRequestApiService.approve(request)
      .then((result: ApproveChangeRequestHandler.Result | null) => {
        if (result?.succeeded) {
          this._formRef.current?.resetFields();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }

          this.fetchData();
        } else {
          notification.error({
            message: 'Could Not Approve',
            description: result?.errors.join('\n')
          })
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error');
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  private handleComment = () => {
    this.setState({ submitting: true });

    const request = AddChangeRequestCommentHandler.Request.create({
      comment: this._formRef.current?.getFieldValue(AddChangeRequestCommentHandler.Request.comment) ?? '',
      changeRequestId: this.props.changeRequestId
    })

    ChangeRequestApiService.addComment(request)
      .then((result: AddChangeRequestCommentHandler.Result | null) => {
        if (result?.succeeded) {
          this._formRef.current?.resetFields();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }

          this.fetchData();
        } else {
          message.error('Error');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error');
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <Space direction="vertical">
        <List
          itemLayout="horizontal"
          dataSource={this.state.comments ?? []}
          renderItem={item => (
            <Comment
              author={item.user?.fullName ?? 'Unknown'}
              avatar={<Avatar>{this.props.currentUser?.initials ?? '??'}</Avatar>}
              content={item.comment}
              datetime={<span>{DateTimeUtil.shortDateAndTime(item.createdOn)}</span>} />
          )} />

        {this.renderAddComment()}
      </Space>
    );
  }

  renderAddComment() {
    if (this.props.changeRequestStatusTypeId != ChangeRequestStatusType.APPROVED && !this.props.disabled) {
      const formItems = this.getFormItems();
      return (
        <Comment
          avatar={<Avatar>{this.props.currentUser?.initials ?? '??'}</Avatar>}
          content={
            <Form
              ref={this._formRef}
              layout="vertical"
              onFinish={this.handleComment}
              requiredMark={true}>
              <Form.Item {...formItems.get(AddChangeRequestCommentHandler.Request.comment)}>
                <Input.TextArea title='Comment' />
              </Form.Item>

              {this.renderActions()}
            </Form >}
        />
      );
    }
  }

  renderActions() {
    if (this.props.changeRequestStatusTypeId != ChangeRequestStatusType.APPROVED && this.props.changeRequestStatusTypeId != ChangeRequestStatusType.CANCELLED && this.props.changeRequestStatusTypeId) {
      if (this.props.changeRequestStatusTypeId != ChangeRequestStatusType.ATTENTIONREQUIRED && (this.props.approvalActiveOn && !DateTimeUtil.isFutureDate(moment(this.props.approvalActiveOn))) && !DateTimeUtil.isFutureDate(moment(this.props.approvalActiveOn)) && this.props.approvalId && this.props.approvalStatusTypeId == ApprovalStatusType.PENDING && !this.props.redirect && (this.props.approvalUserId == this.props.currentUser?.userId || this.props.isOverride && AuthorizationUtil.isAuthorized([FeatureFlag.OVERRIDE_APPROVAL_USER]))) {
        return (
          <Space direction='horizontal' wrap={true}>
            <Button type='primary' onClick={this.confirmApprove} loading={this.state.submitting} ><CheckOutlined />Approve</Button>
            <Button type='primary' onClick={this.confirmDeny} danger={true} loading={this.state.submitting} ><CloseOutlined />Reject</Button>
            <Button htmlType='submit' type='default' disabled={this.state.submitting} ><SendOutlined />Add Comment</Button>
          </Space>
        );
      }
      else if (this.props.approvalStatusTypeId != ChangeRequestStatusType.CANCELLED) {
        return (
          <Button htmlType='submit' type='primary' disabled={this.state.submitting} >
            <SendOutlined />Add Comment
          </Button>
        );
      }
    }
  }
}

export default ChangeRequestCommentsForm;