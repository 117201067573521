// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CourseDTO from './CourseDTO';
import YearTermDTO from './YearTermDTO';
import TermDTO from './TermDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CourseDeactivationChangeRequestDTO extends EntitySelectorDTO { 
	id: string | null;
	changeRequestId: string | null;
	courseId: string | null | null;
	course: CourseDTO | null;
	rationale: string | null;
	deactivationYearTerm: YearTermDTO | null;
	deactivationTermId: string | null | null;
	deactivationYear: number | null | null;
	deactivationTerm: TermDTO | null;
}

const CourseDeactivationChangeRequestDTO = {
    className: 'CourseDeactivationChangeRequestDTO',
    create: (initValues?: Partial<CourseDeactivationChangeRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			changeRequestId: '00000000-0000-0000-0000-000000000000',
			courseId: null,
			course: null,
			rationale: "",
			deactivationYearTerm: null,
			deactivationTermId: null,
			deactivationYear: null,
			deactivationTerm: null,
        },
        initValues);
    },
	id: 'id',
	changeRequestId: 'changeRequestId',
	courseId: 'courseId',
	course: 'course',
	rationale: 'rationale',
	deactivationYearTerm: 'deactivationYearTerm',
	deactivationTermId: 'deactivationTermId',
	deactivationYear: 'deactivationYear',
	deactivationTerm: 'deactivationTerm',
};

export default CourseDeactivationChangeRequestDTO;