// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import DisciplineDTO from '../models/DisciplineDTO';
import * as GetDisciplineDetailsHandler from '../handlerModels/GetDisciplineDetailsHandler';
import * as SaveDisciplineDetailsHandler from '../handlerModels/SaveDisciplineDetailsHandler';
import * as GetDisciplinesHandler from '../handlerModels/GetDisciplinesHandler';

export class DisciplinesApiService extends BaseApi {

    // post: api/disciplines
    public getDisciplineTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<DisciplineDTO>> {
        let url = `api/disciplines`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<DisciplineDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/disciplines/export
    public exportDisciplineList(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/disciplines/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/disciplines/${encodeURIComponent(disciplineId)}
    public getDisciplineDetails(disciplineId: string): Promise<GetDisciplineDetailsHandler.Result> {
        let url = `api/disciplines/${encodeURIComponent(disciplineId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetDisciplineDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/disciplines/save
    public saveDisciplineDetails(saveDisciplineDetails: SaveDisciplineDetailsHandler.Request): Promise<SaveDisciplineDetailsHandler.Result> {
        let url = `api/disciplines/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveDisciplineDetailsHandler.Result>(saveDisciplineDetails, 'post', url, true, false);
    }

    // post: api/disciplines/getDisciplines
    public getDisciplines(): Promise<GetDisciplinesHandler.Result> {
        let url = `api/disciplines/getDisciplines`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetDisciplinesHandler.Result>(null, 'post', url, true, false);
    }
}
var service = new DisciplinesApiService();
export default service;
