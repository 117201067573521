import CurrentUser from '../utils/CurrentUser';
import Guid from '../utils/Guid';
import Routes from './Routes';

class Breadcrumbs {
  static home() {
    return [
      { breadcrumbName: 'Home', path: Routes.generate(Routes.HOME) }
    ];
  }

  static secondary() {
    return this.home().concat([
      { breadcrumbName: 'Secondary', path: Routes.generate(Routes.SECONDARY_HOME) },
    ]);
  }

  static postSecondary() {
    return this.home().concat([
      { breadcrumbName: 'Post-Secondary', path: Routes.generate(Routes.POST_SECONDARY_HOME) },
    ]);
  }

  static documentArchive() {
    return this.home().concat([
      { breadcrumbName: 'Document Archive', path: Routes.generate(Routes.DOCUMENTARCHIVES) },
    ]);
  }

  static academicYearList() {
    return this.academicYears().concat([
      { breadcrumbName: 'All', path: Routes.generate(Routes.ALL_ACADEMIC_YEARS) },
    ]);
  }

  static districtListFromSelfStudies(academicYearId: string) {
    return this.academicYearDashboard(Number.parseInt(academicYearId)).concat([
      { breadcrumbName: 'Districts', path: Routes.generate(Routes.DISTRICTS, { academicYearId: academicYearId }) },
    ]);
  }

  static districtReview(academicYearId: number) {
    return this.academicYearDashboard(academicYearId).concat([
      { breadcrumbName: 'Districts', path: Routes.generate(Routes.DISTRICTS, { academicYearId: academicYearId }) },
    ]);
  }

  static districtList() {
    if (CurrentUser.Get() == null) {
      return this.home().concat([
        { breadcrumbName: 'Districts', path: Routes.generate(Routes.ALL_DISTRICTS) },
      ]);
    }

    return this.secondary().concat([
      { breadcrumbName: 'Districts', path: Routes.generate(Routes.ALL_DISTRICTS) },
    ]);
  }

  static districtDetailPage(title: string, districtId: string, academicYearId: number) {
    return this.districtList().concat([
      { breadcrumbName: title, path: Routes.generate(Routes.DISTRICT_DETAILS, { id: districtId }, { academicYear: academicYearId }) },
    ]);
  }

  static districtDetailPageFromRPP(title: string, code: string, districtId: string, academicYearId: number, rppId: string) {
    return this.regionDetailPage(code, rppId).concat([
      { breadcrumbName: title, path: Routes.generate(Routes.DISTRICT_DETAILS, { id: districtId }, { academicYear: academicYearId }) },
    ]);
  }

  static secondaryProgramReview(academicYearId: number) {
    return this.academicYearDashboard(academicYearId).concat([
      { breadcrumbName: 'Program', path: Routes.generate(Routes.SECONDARY_PROGRAMS, { academicYearId: academicYearId }) },
    ]);
  }

  static secondaryProgramList() {
    if (CurrentUser.Get() == null) {
      return this.home().concat([
        { breadcrumbName: 'Programs', path: Routes.generate(Routes.ALL_SECONDARY_PROGRAMS) },
      ]);
    }

    return this.secondary().concat([
      { breadcrumbName: 'Programs', path: Routes.generate(Routes.ALL_SECONDARY_PROGRAMS) },
    ]);
  }

  static secondaryProgramDetailPage(title: string, secondaryProgramId: string, academicYearId: number, isDataReview?: boolean, districtId?: string, districtName?: string) {
    if (CurrentUser.Get() == null) {
      if (districtId == Guid.Empty() || districtId == null) {
        return this.secondaryProgramList().concat([
          { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId ?? Guid.Empty() }, { academicYear: academicYearId }) },
        ]);
      }
      return this.districtDetailPage(districtName ?? '', districtId ?? Guid.Empty(), academicYearId).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId ?? Guid.Empty() }, { academicYear: academicYearId }) },
      ])
    }
    return isDataReview ?
      this.secondaryProgramReview(academicYearId).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId }) },
      ]) :
      this.secondaryProgramList().concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId }, { academicYear: academicYearId }) },
      ]);
  }

  static secondaryProgramDetailFromDistrict(title: string, secondaryProgramId: string, academicYearId: number, isDataReview?: boolean, districtId?: string, districtName?: string) {
    if (CurrentUser.Get() == null) {
      return this.districtDetailPage(districtName ?? '', districtId ?? Guid.Empty(), academicYearId).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId ?? Guid.Empty() }, { academicYear: academicYearId }) },
      ])
    }
    return this.districtDetailPage(districtName ?? '', districtId ?? Guid.Empty(), academicYearId).concat([
      { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId }) },
    ]);
  }

  static selfStudyListFromSelfStudies(academicYear: number, fromAcademicYearList: boolean) {
    return fromAcademicYearList ? this.academicYearDashboardFromAcademicList(academicYear).concat([
      { breadcrumbName: 'Program Review', path: Routes.generate(Routes.SECONDARY_PROGRAMS, { academicYearId: academicYear }) },
    ]) : this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Program Review', path: Routes.generate(Routes.SECONDARY_PROGRAMS, { academicYearId: academicYear }) },
    ]);
  }

  static selfStudyReport(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Program Review Ranking Report', path: Routes.generate(Routes.ACADEMIC_YEAR_SELF_STUDY_RANKING_REPORT, { academicYearId: academicYear }) },
    ]);
  }

  static programConsultantCommentReport(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Program Consultant Comment Report', path: Routes.generate(Routes.ACADEMIC_YEAR_PROGRAM_CONSULTANT_COMMENT_REPORT, { academicYearId: academicYear }) },
    ]);
  }

  static aggregateReport(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Aggregate Report', path: Routes.generate(Routes.ACADEMIC_YEAR_PROGRAM_CONSULTANT_COMMENT_REPORT, { academicYearId: academicYear }) },
    ]);
  }

  static selfStudyList(academicYear: number) {
    return this.academicYears().concat([
      { breadcrumbName: 'Program Review Years ' + academicYear, path: Routes.generate(Routes.ACADEMIC_YEAR_DASHBOARD_FROM_LIST, { academicYearId: academicYear }) },
    ]);
  }

  static selfStudyListPage(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Confirm Programs', path: Routes.generate(Routes.SELF_STUDIES_REVIEW, { academicYearId: academicYear }) },
    ]);
  }

  static selfStudyDisplayPage(selfStudyId: string, academicYear: number, programTitle: string) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: programTitle, path: Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: selfStudyId }) }
    ]);
  }

  static selfStudyDisplayPageFromProgramForReview(selfStudyId: string, academicYear: number, programTitle: string) {
    return this.selfStudyListPage(academicYear).concat([
      { breadcrumbName: programTitle, path: Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: selfStudyId }) }
    ]);
  }

  static selfStudyDetailPage(title: string, secondaryProgramId: string, academicYear: number, fromAcademicYearList: boolean, fromSelfStudies: boolean) {
    return fromSelfStudies ?
      this.selfStudyListFromSelfStudies(academicYear, fromAcademicYearList).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId }) },
      ]) :
      this.selfStudyList(academicYear).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId }) },
      ]);
  }

  static selfStudyDetailFromDistrict(title: string, selfStudyId: string, academicYear: number, districtTitle: string, districtId: string) {
    return this.districtDetailPage(districtTitle, districtId, academicYear).concat([
      { breadcrumbName: title, path: Routes.generate(Routes.SELF_STUDIES_DISPLAY, { id: selfStudyId }) },
    ]);
  }

  static secondaryCipNumberReview(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Secondary CIP Number', path: Routes.generate(Routes.SECONDARY_CIP_NUMBERS, { academicYearId: academicYear }) },
    ]);
  }

  static completeSelfStudy(title: string, programTitle: string, academicYearId: number, selfStudyId: string, step: number) {
    return this.selfStudyDisplayPage(selfStudyId, academicYearId, programTitle).concat([
      { breadcrumbName: title, path: Routes.generate(Routes.COMPLETE_SELF_STUDIES, { selfStudyResponseId: Guid.Empty(), academicYearId: academicYearId, sectionIndex: 0 }, { step: step }), },
    ])
  }

  static secondaryCipNumberList() {
    return this.postSecondary().concat([
      { breadcrumbName: 'CIP Numbers', path: Routes.generate(Routes.ALL_SECONDARY_PROGRAMS) },
    ]);
  }

  static secondaryCipNumberConfigurationList() {
    return this.configuration().concat([
      { breadcrumbName: 'CIP Numbers', path: Routes.generate(Routes.SECONDARY_CIP_NUMBERS_CONFIGURATION) },
    ]);
  }

  static secondaryCipNumberDetailPage(title: string, secondaryProgramId: string, academicYear: number, fromAcademicYearList: boolean, fromSelfStudies: boolean) {
    return fromSelfStudies ?
      this.secondaryCipNumberReview(academicYear).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_CIP_NUMBER_DETAILS, { id: secondaryProgramId }) },
      ]) :
      this.secondaryCipNumberList().concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_CIP_NUMBER_DETAILS, { id: secondaryProgramId }) },
      ]);
  }

  static secondaryCourseListFromSelfStudies(academicYearId: string, fromAcademicYearList: boolean) {
    return fromAcademicYearList ? this.academicYearDashboardFromAcademicList(Number.parseInt(academicYearId)).concat([
      { breadcrumbName: 'Courses', path: Routes.generate(Routes.SECONDARY_COURSES, { academicYearId: academicYearId }) },
    ]) : this.academicYearDashboard(Number.parseInt(academicYearId)).concat([
      { breadcrumbName: 'Courses', path: Routes.generate(Routes.SECONDARY_COURSES, { academicYearId: academicYearId }) },
    ]);
  }

  static secondaryCourseReview(academicYearId: number) {
    return this.academicYearDashboard(academicYearId).concat([
      { breadcrumbName: 'Courses', path: Routes.generate(Routes.SECONDARY_COURSES, { academicYearId: academicYearId }) },
    ]);
  }

  static secondaryCourseList() {
    return this.secondary().concat([
      { breadcrumbName: 'Courses', path: Routes.generate(Routes.ALL_SECONDARY_COURSES) },
    ]);
  }

  static secondaryCourseDetailPage(title: string, secondaryCourseId: string, academicYearId: number, fromAcademicYearList: boolean, fromSelfStudies: boolean, districtId?: string, districtName?: string, secondaryProgramId?: string, secondaryProgramName?: string) {
    if (CurrentUser.Get() == null) {
      return this.secondaryProgramDetailPage(secondaryProgramName ?? '', secondaryProgramId ?? Guid.Empty(), academicYearId, fromSelfStudies, districtId, districtName).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_COURSE_DETAILS, { id: secondaryCourseId ?? Guid.Empty() }) },
      ])
    }

    return fromSelfStudies ?
      this.secondaryCourseListFromSelfStudies(academicYearId.toString(), fromAcademicYearList).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_COURSE_DETAILS, { id: secondaryCourseId }, { academicYear: academicYearId }) },
      ]) :
      this.secondaryCourseList().concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_COURSE_DETAILS, { id: secondaryCourseId }, { academicYear: academicYearId }) },
      ]);
  }

  static secondaryCourseDetailFromSecondaryPRogram(title: string, secondaryCourseId: string, academicYearId: number, fromAcademicYearList: boolean, fromSelfStudies: boolean, districtId?: string, districtName?: string, secondaryProgramId?: string, secondaryProgramName?: string) {
    return this.secondaryProgramDetailPage(secondaryProgramName ?? '', secondaryProgramId ?? Guid.Empty(), academicYearId, fromSelfStudies, districtId, districtName).concat([
      { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_COURSE_DETAILS, { id: secondaryCourseId ?? Guid.Empty() }) },
    ])
  }

  static academicYearActionPlanReport(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([{ breadcrumbName: 'Action Plan Report', path: Routes.generate(Routes.ACADEMIC_YEAR_ACTION_PLAN_REPORT, { academicYearId: academicYear }) }]);
  }

  static academicYearProgramMasterReport(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([{ breadcrumbName: 'Program Master Report', path: Routes.generate(Routes.ACADEMIC_YEAR_PROGRAM_MASTER_REPORT, { academicYearId: academicYear }) }]);
  }

  static academicYearCareerAcademyReport(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([{ breadcrumbName: 'Career Academy Report', path: Routes.generate(Routes.ACADEMIC_YEAR_CAREER_ACADEMY_REPORT, { academicYearId: academicYear }) }]);
  }

  static academicYearContactsReport(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([{ breadcrumbName: 'Contacts Report', path: Routes.generate(Routes.ACADEMIC_YEAR_CONTACTS_REPORT, { academicYearId: academicYear }) }]);
  }

  static academicYearScenarioReport(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([{ breadcrumbName: 'Scenario Report', path: Routes.generate(Routes.ACADEMIC_YEAR_SCENARIO_REPORT, { academicYearId: academicYear }) }]);
  }

  static academicYearDataReview(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([{ breadcrumbName: 'Data Review', path: '' }]);
  }

  static secondaryProgramCourseReview(academicYearId: number) {
    return this.academicYearDashboard(academicYearId).concat([
      { breadcrumbName: 'Program Courses', path: Routes.generate(Routes.DATA_REVIEW_SECONDARY_PROGRAM_COURSES, { academicYearId: academicYearId }) },
    ]);
  }

  static areaEducationAgencyReview(academicYearId: number) {
    return this.academicYearDashboard(academicYearId).concat([
      { breadcrumbName: 'Area Education Agency', path: Routes.generate(Routes.AREA_EDUCATION_AGENCIES, { academicYearId: academicYearId }) },
    ]);
  }

  static areaEducationAgencyList(academicYearId: number, reviewAcademicYear: boolean) {
    if (reviewAcademicYear) {
      return this.newAcademicYearFromAcademicList(academicYearId).concat([
        { breadcrumbName: 'Area Education Agencies', path: Routes.generate(Routes.REVIEW_AREA_EDUCATION_AGENCY, { academicYearId: academicYearId }, { reviewAcademicYear: true }) },
      ]);
    }
    else {
      return this.academicYearDashboardFromAcademicList(academicYearId).concat([
        { breadcrumbName: 'Area Education Agencies', path: Routes.generate(Routes.ALL_AREA_EDUCATION_AGENCIES) },
      ]);
    }
  }

  static areaEducationAgencyConfigurationList() {
    return this.configuration().concat([
      { breadcrumbName: 'Area Education Agencies', path: Routes.generate(Routes.AREA_EDUCATION_AGENCIES_CONFIGURATION) },
    ]);
  }

  static academicYearAreaEducationAgencyConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Area Education Agencies', path: Routes.generate(Routes.ACADEMIC_YEAR_AREA_EDUCATION_AGENCIES_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearDatesConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Dates', path: Routes.generate(Routes.ACADEMIC_YEAR_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearServiceAreaConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Service Areas', path: Routes.generate(Routes.ACADEMIC_YEAR_SERVICE_AREAS_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearFederalClusterConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Federal Clusters', path: Routes.generate(Routes.ACADEMIC_YEAR_FEDERAL_CLUSTERS_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearScenariosConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Scenarios', path: Routes.generate(Routes.ACADEMIC_YEAR_SCENARIOS_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearRPPConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Regional Planning Partners', path: Routes.generate(Routes.ACADEMIC_YEAR_REGIONAL_PLANNING_PARTNER_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearSecondaryCipNumberConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'CIP Numbers', path: Routes.generate(Routes.ACADEMIC_YEAR_SECONDARY_CIP_NUMBER_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearDistrictConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Districts', path: Routes.generate(Routes.ACADEMIC_YEAR_DISTRICT_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearDistrictContactsConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'District Contacts', path: Routes.generate(Routes.ACADEMIC_YEAR_DISTRICT_CONTACT_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearSecondaryProgramsConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Programs', path: Routes.generate(Routes.ACADEMIC_YEAR_SECONDARY_PROGRAM_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearSecondaryProgramsConfigurationDetail(academicYear: number, secondaryProgramId: string) {
    return this.academicYearSecondaryProgramsConfigurationList(academicYear).concat([
      { breadcrumbName: 'Program Details', path: Routes.generate(Routes.SECONDARY_PROGRAM_DETAILS, { id: secondaryProgramId }) },
    ]);
  }

  static academicYearSecondaryCoursesConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Courses', path: Routes.generate(Routes.ACADEMIC_YEAR_SECONDARY_COURSES_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static academicYearSecondaryProgramCoursesConfigurationList(academicYear: number) {
    return this.academicYearDashboard(academicYear).concat([
      { breadcrumbName: 'Program Courses', path: Routes.generate(Routes.ACADEMIC_YEAR_SECONDARY_PROGRAM_COURSES_CONFIGURATION, { academicYearId: academicYear }) },
    ]);
  }

  static areaEducationAgencyDetailPage(title: string, areaEducationAgencyId: string, academicYearId: number, fromAcademicYearList: boolean, fromSelfStudies: boolean, reviewAcademicYear: boolean) {
    if (reviewAcademicYear) {
      return this.areaEducationAgencyList(academicYearId, reviewAcademicYear).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.AREA_EDUCATION_AGENCY_DETAILS, { id: areaEducationAgencyId }, { academicYearId: academicYearId, fromAcademicYearList: fromAcademicYearList, fromSelfStudies: fromSelfStudies, reviewAcademicYear: reviewAcademicYear }) },
      ])
    }
    else if (fromSelfStudies) {
      return this.areaEducationAgencyReview(academicYearId).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.AREA_EDUCATION_AGENCY_DETAILS, { id: areaEducationAgencyId }) },
      ]);
    }
    else {
      return this.areaEducationAgencyList(academicYearId, reviewAcademicYear).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.AREA_EDUCATION_AGENCY_DETAILS, { id: areaEducationAgencyId }) },
      ])
    }
  }

  static SecondaryProgramScenarioReview(academicYearId: string) {
    return this.academicYearDashboard(Number.parseInt(academicYearId)).concat([
      { breadcrumbName: 'ProgramType', path: Routes.generate(Routes.SECONDARY_PROGRAM_TYPES, { academicYearId: academicYearId }) },
    ]);
  }

  static SecondaryProgramScenarioList() {
    return this.home().concat([
      { breadcrumbName: 'ProgramTypes', path: Routes.generate(Routes.ALL_SECONDARY_PROGRAM_TYPES) },
    ]);
  }

  static ScenarioConfigurationList() {
    return this.configuration().concat([
      { breadcrumbName: 'Scenarios', path: Routes.generate(Routes.SCENARIO_CONFIGURATION) },
    ]);
  }

  static SecondaryProgramScenarioDetailPage(title: string, SecondaryProgramScenarioId: string, academicYearId: number, fromAcademicYearList: boolean, fromSelfStudies: boolean) {
    return fromSelfStudies ?
      this.SecondaryProgramScenarioReview(academicYearId.toString()).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_TYPE_DETAILS, { id: SecondaryProgramScenarioId }) },
      ]) :
      this.SecondaryProgramScenarioList().concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SECONDARY_PROGRAM_TYPE_DETAILS, { id: SecondaryProgramScenarioId }) },
      ]);
  }

  static secondaryProgramCourseListFromSelfStudies(academicYearId: string, fromAcademicYearList: boolean) {
    return fromAcademicYearList ? this.academicYearDashboardFromAcademicList(Number.parseInt(academicYearId)).concat([
      { breadcrumbName: 'ProgramCourse', path: Routes.generate(Routes.SECONDARY_PROGRAM_COURSES, { academicYearId: academicYearId }) },
    ]) : this.academicYearDashboard(Number.parseInt(academicYearId)).concat([
      { breadcrumbName: 'ProgramCourse', path: Routes.generate(Routes.SECONDARY_PROGRAM_COURSES, { academicYearId: academicYearId }) },
    ]);
  }

  static secondaryProgramCourseList() {
    return this.home().concat([
      { breadcrumbName: 'Program Courses', path: Routes.generate(Routes.ALL_SECONDARY_PROGRAM_COURSES) },
    ]);
  }

  static announcementList() {
    return this.home().concat([
      { breadcrumbName: 'Announcements', path: Routes.generate(Routes.ANNOUNCEMENTS) },
    ]);
  }

  static allAnnouncements() {
    return this.home().concat([
      { breadcrumbName: 'Announcements', path: Routes.generate(Routes.ANNOUNCEMENTS) },
    ]);
  }

  static announcementDetails(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_ANNOUNCEMENT) :
      Routes.generate(Routes.ANNOUNCEMENT_DETAILS, { id: id });

    return this.announcementList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static awardTypeList() {
    return this.configuration().concat([
      { breadcrumbName: 'Award Type', path: Routes.generate(Routes.AWARD_TYPE) }
    ]);
  }

  static thirdPartyCredentialTypeList() {
    return this.configuration().concat([
      { breadcrumbName: 'Third Party Credential Type', path: Routes.generate(Routes.THIRD_PARTY_CREDENTIAL_TYPES) }
    ]);
  }

  static thirdPartyCredentialTypeDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_THIRD_PARTY_CREDENTIAL_TYPE) :
      Routes.generate(Routes.THIRD_PARTY_CREDENTIAL_TYPE_DETAILS, { id: id });

    return this.thirdPartyCredentialTypeList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static awardTypeDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_AWARD_TYPE) :
      Routes.generate(Routes.AWARD_TYPE_DETAILS, { id: id });

    return this.awardTypeList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static apsGeneralEducationList() {
    return this.configuration().concat([
      { breadcrumbName: 'APS General Education', path: Routes.generate(Routes.APS_GENERAL_EDUCATION) }
    ]);
  }

  static apsGeneralEducationDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_APS_GENERAL_EDUCATION) :
      Routes.generate(Routes.APS_GENERAL_EDUCATION_DETAILS, { id: id });

    return this.apsGeneralEducationList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static categoryList() {
    return this.configuration().concat([
      { breadcrumbName: 'Categories', path: Routes.generate(Routes.CATEGORIES) }
    ]);
  }

  static categoryDetails(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_CATEGORY) :
      Routes.generate(Routes.CATEGORY_DETAILS, { id: id });

    return this.categoryList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static changeRequestList() {
    return this.postSecondary().concat([
      { breadcrumbName: 'Change Requests', path: Routes.generate(Routes.CHANGE_REQUESTS) }
    ]);
  }

  static changeRequestDetails(name: string, id: string) {
    return this.changeRequestList().concat([
      { breadcrumbName: name, path: Routes.generate(Routes.CHANGE_REQUEST_DETAILS, { id: id }) }
    ]);
  }

  static changeRequestApproval(name: string, id: string) {
    return this.changeRequestList().concat([
      { breadcrumbName: name, path: Routes.generate(Routes.CHANGE_REQUEST_APPROVAL, { id: id }) }
    ]);
  }

  static changeRequestTypes() {
    return this.configuration().concat([
      { breadcrumbName: 'Change Request Types', path: Routes.generate(Routes.CHANGE_REQUEST_TYPE) }
    ]);
  }

  static changeRequestTypeDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.CHANGE_REQUEST_TYPE) :
      Routes.generate(Routes.CHANGE_REQUEST_TYPE_DETAILS, { id: id });

    return this.changeRequestTypes().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static programList() {
    return this.postSecondary().concat([
      { breadcrumbName: 'Programs', path: Routes.generate(Routes.PROGRAMS) }
    ]);
  }

  static programDetails(name: string, id: string) {
    return this.programList().concat([
      { breadcrumbName: name, path: Routes.generate(Routes.PROGRAM_DETAILS, { id: id }) }
    ]);
  }

  static courseList() {
    return this.postSecondary().concat([
      { breadcrumbName: 'Courses', path: Routes.generate(Routes.COURSES) }
    ]);
  }

  static courseDetails(name: string, id: string, institutionId: string, institutionCourseId: string) {
    const route = institutionCourseId == Guid.Empty() ?
      Routes.generate(Routes.NEW_COURSE) :
      Routes.generate(Routes.COURSE_DETAILS, { institutionCourseId: institutionCourseId });

    return this.courseList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static courseDetailFromSecondaryCourses(name: string, id: string, institutionId: string, institutionCourseId: string, secondaryCourseTitle: string, secondaryCourseId: string, academicYear: number) {
    const route = institutionCourseId == Guid.Empty() ?
      Routes.generate(Routes.NEW_COURSE) :
      Routes.generate(Routes.COURSE_DETAILS, { institutionCourseId: institutionCourseId });

    return this.secondaryCourseDetailPage(secondaryCourseTitle, secondaryCourseId, academicYear, false, false).concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static contactList() {
    return this.postSecondary().concat([
      { breadcrumbName: 'Contacts', path: Routes.generate(Routes.CONTACTS) }
    ]);
  }

  public static institutionCourseBlockDetails(name: string, id: string) {
    return this.courseList().concat([
      { breadcrumbName: name, path: Routes.generate(Routes.INSTITUITION_COURSE_BLOCK_DETAILS, { id: id }) }
    ]);
  }

  static singleInstitutionCourseDetails(courseName: string, courseId: string, institutionId: string) {
    return this.courseList().concat([
      { breadcrumbName: courseName, path: Routes.generate(Routes.SINGLE_INSTITUTION_COURSE_DETAILS, { courseId: courseId, institutionId: institutionId }) }
    ]);
  }

  static institutionCourseDetails(institutionAbbreviation: string, institutionId: string, title: string, courseId: string, institutionCourseId: string) {
    return this.institutionDetails(institutionAbbreviation, institutionId).concat([
      { breadcrumbName: title, path: Routes.generate(Routes.INSTITUTION_COURSE_DETAILS, { courseId: courseId, institutionId: institutionId, institutionCourseId: institutionCourseId }) }
    ]);
  }

  static courseInstitutionDetials(courseName: string, courseId: string, institutionAbbreviation: string, institutionId: string, institutionCourseId: string) {
    return this.courseDetails(courseName, courseId, institutionId, institutionCourseId).concat([
      { breadcrumbName: institutionAbbreviation, path: Routes.generate(Routes.COURSE_INSTITUTION_DETAILS, { courseId: courseId, institutionId: institutionId, institutionCourseId: institutionCourseId }) }
    ]);
  }

  static configuration() {
    return this.home().concat([
      { breadcrumbName: 'Configuration', path: Routes.generate(Routes.CONFIGURATIONS) }
    ]);
  }

  static creditTypeList() {
    return this.configuration().concat([
      { breadcrumbName: 'Credit Types', path: Routes.generate(Routes.CREDIT_TYPES) }
    ]);
  }

  static creditTypeDetails(name: string, creditTypeId: string) {
    const route = creditTypeId == Guid.Empty() ?
      Routes.generate(Routes.NEW_CREDIT_TYPE) :
      Routes.generate(Routes.CREDIT_TYPE_DETAILS, { id: creditTypeId });

    return this.creditTypeList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static contactTypeList() {
    return this.configuration().concat([
      { breadcrumbName: 'Contact Types', path: Routes.generate(Routes.CONTACT_TYPE) }
    ]);
  }

  static contactTypeDetails(name: string, contactTypeId: number) {
    const route = contactTypeId == 0 ?
      Routes.generate(Routes.NEW_CREDIT_TYPE) :
      Routes.generate(Routes.CREDIT_TYPE_DETAILS, { id: contactTypeId });

    return this.contactTypeList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static dashboard() {
    return [
      { breadcrumbName: 'Home', path: '' }
    ];
  }

  static disciplineList() {
    return this.configuration().concat([
      { breadcrumbName: 'Disciplines', path: Routes.generate(Routes.DISCIPLINES) }
    ]);
  }

  static disciplineDetails(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_DISCIPLINE) :
      Routes.generate(Routes.DISCIPLINE_DETAILS, { id: id });

    return this.disciplineList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static institutionList() {
    return this.postSecondary().concat([
      { breadcrumbName: 'Institutions', path: Routes.generate(Routes.INSTITUTIONS) }
    ]);
  }

  static singleInstitutionDetails(name: string, id: string) {
    return this.home().concat([
      { breadcrumbName: name, path: Routes.generate(Routes.INSTITUTION_DETAILS, { id: id }) }
    ]);
  }

  static institutionDetails(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_INSTITUTION) :
      Routes.generate(Routes.INSTITUTION_DETAILS, { id: id });

    return this.institutionList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static institutionUserDetails(institutionAbbreviation: string, institutionId: string, userName: string, userId: string) {
    return this.institutionDetails(institutionAbbreviation, institutionId).concat([
      { breadcrumbName: userName, path: Routes.generate(Routes.USER_INSTITUTION_DETAILS, { userId: userId, institutionId: institutionId }) }
    ]);
  }

  static userInstitutionDetials(userName: string, userId: string, institutionAbbreviation: string, institutionId: string) {
    return this.userDetails(userName, userId).concat([
      { breadcrumbName: institutionAbbreviation, path: Routes.generate(Routes.INSTITUTION_USER_DETAILS, { userId: userId, institutionId: institutionId }) }
    ]);
  }

  static newNoticeOfIntentChangeRequests() {

    return this.changeRequestList().concat([
      { breadcrumbName: 'Notice Of Intent Request', path: Routes.generate(Routes.CHANGE_REQUESTS) }
    ]);
  }

  static newDisciplineChangeRequestEntry(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY) :
      Routes.generate(Routes.EXISTING_NEW_DISCIPLINE_CHANGE_REQUEST_ENTRY_1, { id: id });

    return this.changeRequestList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static administrativeChangeRequestEntry(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.ADMINISTRATIVE_CHANGE_REQUEST_ENTRY) :
      Routes.generate(Routes.EXISTING_ADMINISTRATIVE_CHANGE_REQUEST_ENTRY_1, { id: id });

    return this.changeRequestList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static newCourseChangeRequestEntry(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_COURSE_CHANGE_REQUEST_ENTRY) :
      Routes.generate(Routes.EXISTING_NEW_COURSE_CHANGE_REQUEST_ENTRY_1, { id: id });

    return this.changeRequestList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static courseDeactivationChangeRequestEntry(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY) :
      Routes.generate(Routes.EXISTING_COURSE_DEACTIVATION_CHANGE_REQUEST_ENTRY_1, { id: id });

    return this.changeRequestList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static courseModificationChangeRequestEntry(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY) :
      Routes.generate(Routes.EXISTING_COURSE_MODIFICATION_CHANGE_REQUEST_ENTRY_2, { id: id });

    return this.changeRequestList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static newProgramChangeRequestEntry(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_PROGRAM_CHANGE_REQUEST_ENTRY) :
      Routes.generate(Routes.EXISTING_NEW_PROGRAM_CHANGE_REQUEST_ENTRY_1, { id: id });

    return this.changeRequestList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static noticeOfIntentChangeRequestEntry(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY) :
      Routes.generate(Routes.EXISTING_NOTICE_OF_INTENT_CHANGE_REQUEST_ENTRY_1, { id: id });

    return this.changeRequestList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static programDeactivationChangeRequestEntry(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY) :
      Routes.generate(Routes.EXISTING_PROGRAM_DEACTIVATION_CHANGE_REQUEST_ENTRY_1, { id: id });

    return this.changeRequestList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static programModificationChangeRequestEntry(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY) :
      Routes.generate(Routes.EXISTING_PROGRAM_MODIFICATION_CHANGE_REQUEST_ENTRY_1, { id: id });

    return this.changeRequestList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static publicConsultantSearch() {
    return this.postSecondary().concat([
      { breadcrumbName: 'Consultants', path: Routes.generate(Routes.CONSULTANTS) }
    ]);
  }

  static adminResources() {
    return this.postSecondary().concat([
      { breadcrumbName: 'Resources', path: Routes.generate(Routes.ADMIN_RESOURCES) }
    ]);
  }

  static publicResources() {
    return this.postSecondary().concat([
      { breadcrumbName: 'Resources', path: Routes.generate(Routes.RESOURCES) }
    ]);
  }

  static downLoads() {
    return this.postSecondary().concat([
      { breadcrumbName: 'Downloads', path: Routes.generate(Routes.DOWNLOADS_SECTION) }
    ]);
  }

  static roleDetails(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_ROLE) :
      Routes.generate(Routes.ROLE_DETAILS, { id: id });

    return this.roleList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static roleList() {
    return this.configuration().concat([
      { breadcrumbName: 'Roles', path: Routes.generate(Routes.ROLES) }
    ]);
  }

  static cipNumberList() {
    return this.configuration().concat([
      { breadcrumbName: 'CIP Numbers', path: Routes.generate(Routes.CIPS) }
    ]);
  }

  static cipNumberDetails(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_CIP) :
      Routes.generate(Routes.CIP_DETAILS, { id: id });

    return this.cipNumberList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static NoticeOfIntentChangeRequestWorkForceLinkList() {
    return this.configuration().concat([
      { breadcrumbName: 'Workforce Informational Links', path: Routes.generate(Routes.NOTICE_OF_INTENT_CHANGE_REQUEST_WORKFORCE_LINK) }
    ]);
  }

  static programClassificationList() {
    return this.configuration().concat([
      { breadcrumbName: 'Program Classifications', path: Routes.generate(Routes.PROGRAM_CLASSIFICATION) }
    ]);
  }

  static programClassificationDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_PROGRAM_CLASSIFICATION) :
      Routes.generate(Routes.PROGRAM_CLASSIFICATION_DETAILS, { id: id });

    return this.programClassificationList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static instructionalLevelList() {
    return this.configuration().concat([
      { breadcrumbName: 'Instructional Levels', path: Routes.generate(Routes.INSTRUCTIONAL_LEVEL) }
    ]);
  }

  static instructionalLevelDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_INSTRUCTIONAL_LEVEL) :
      Routes.generate(Routes.INSTRUCTIONAL_LEVEL_DETAILS, { id: id });

    return this.instructionalLevelList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static workBasedLearningTypeList() {
    return this.configuration().concat([
      { breadcrumbName: 'Work Based Learning Types', path: Routes.generate(Routes.WORK_BASED_LEARNING_TYPE) }
    ]);
  }

  static workBasedLearningTypeDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_WORK_BASED_LEARNING_TYPE) :
      Routes.generate(Routes.WORK_BASED_LEARNING_DETAILS, { id: id });

    return this.workBasedLearningTypeList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static externalInstitutionList() {
    return this.configuration().concat([
      { breadcrumbName: 'External Institutions', path: Routes.generate(Routes.EXTERNAL_INSTITUTIONS) }
    ]);
  }

  static externalIntitutionDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_EXTERNAL_INSTITUTIONS) :
      Routes.generate(Routes.EXTERNAL_INSTITUTIONS_DETAILS, { id: id });

    return this.externalInstitutionList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static genderList() {
    return this.configuration().concat([
      { breadcrumbName: 'Genders', path: Routes.generate(Routes.GENDER) }
    ]);
  }

  static genderDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_GENDER) :
      Routes.generate(Routes.GENDER_DETAILS, { id: id });

    return this.genderList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static generalEducationList() {
    return this.configuration().concat([
      { breadcrumbName: 'General Education', path: Routes.generate(Routes.GENERAL_EDUCATION) }
    ]);
  }

  static generalEducationDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_GENERAL_EDUCATION) :
      Routes.generate(Routes.GENERAL_EDUCATION_DETAILS, { id: id });

    return this.generalEducationList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static iowaVariationList() {
    return this.configuration().concat([
      { breadcrumbName: 'Iowa Variations', path: Routes.generate(Routes.IOWA_VARIATION) }
    ]);
  }

  static iowaVariationDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_IOWA_VARIATION) :
      Routes.generate(Routes.IOWA_VARIATION_DETAILS, { id: id });

    return this.iowaVariationList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static objectPurposeList() {
    return this.configuration().concat([
      { breadcrumbName: 'Object Purposes', path: Routes.generate(Routes.OBJECT_PURPOSE) }
    ]);
  }

  static objectPurposeDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_OBJECT_PURPOSE) :
      Routes.generate(Routes.OBJECT_PURPOSE_DETAILS, { id: id });

    return this.objectPurposeList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static specialEmphasisList() {
    return this.configuration().concat([
      { breadcrumbName: 'Special Emphases', path: Routes.generate(Routes.SPECIAL_EMPHASIS) }
    ]);
  }

  static specialEmphasisDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_SPECIAL_EMPHASIS) :
      Routes.generate(Routes.SPECIAL_EMPHASIS_DETAILS, { id: id });

    return this.specialEmphasisList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static racialEthnicGroupList() {
    return this.configuration().concat([
      { breadcrumbName: 'Racial/Ethnic Groups', path: Routes.generate(Routes.RACIAL_ETHNIC_GROUPS) }
    ]);
  }

  static racialEthnicGroupDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_RACIAL_ETHNIC_GROUP) :
      Routes.generate(Routes.RACIAL_ETHNIC_GROUP_DETAILS, { id: id });

    return this.racialEthnicGroupList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static federalClusterReview(academicYearId: string) {
    return this.academicYearDashboard(Number.parseInt(academicYearId)).concat([
      { breadcrumbName: 'Federal Clusters', path: Routes.generate(Routes.FEDERAL_CLUSTERS, { academicYearId: academicYearId }) },
    ]);
  }

  static federalClusterList() {
    return this.home().concat([
      { breadcrumbName: 'Federal Clusters', path: Routes.generate(Routes.ALL_FEDERAL_CLUSTERS) },
    ]);
  }

  static federalClusterConfigurationList() {
    return this.configuration().concat([
      { breadcrumbName: 'Federal Clusters', path: Routes.generate(Routes.FEDERAL_CLUSTERS_CONFIGURATION) },
    ]);
  }

  static federalClusterDetailPage(title: string, federalClusterId: number, academicYearId: number, fromAcademicYearList: boolean, fromSelfStudies: boolean) {
    return fromSelfStudies ?
      this.federalClusterReview(academicYearId.toString()).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.FEDERAL_CLUSTER_DETAILS, { id: federalClusterId }) },
      ]) :
      this.federalClusterList().concat([
        { breadcrumbName: title, path: Routes.generate(Routes.FEDERAL_CLUSTER_DETAILS, { id: federalClusterId }) },
      ]);
  }

  static serviceAreaReview(academicYearId: string) {
    return this.academicYearDashboard(Number.parseInt(academicYearId)).concat([
      { breadcrumbName: 'Service Areas', path: Routes.generate(Routes.SERVICE_AREAS, { academicYearId: academicYearId }) },
    ]);
  }

  static serviceAreaList() {
    return this.home().concat([
      { breadcrumbName: 'Service Areas', path: Routes.generate(Routes.ALL_SERVICE_AREAS) },
    ]);
  }

  static serviceAreaConfigurationList() {
    return this.configuration().concat([
      { breadcrumbName: 'Service Areas', path: Routes.generate(Routes.FEDERAL_CLUSTERS_CONFIGURATION) },
    ]);
  }

  static serviceAreaDetailPage(title: string, serviceAreaId: number, academicYearId: number, fromAcademicYearList: boolean, fromSelfStudies: boolean) {
    return fromSelfStudies ?
      this.serviceAreaReview(academicYearId.toString()).concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SERVICE_AREA_DETAILS, { id: serviceAreaId }) },
      ]) :
      this.serviceAreaList().concat([
        { breadcrumbName: title, path: Routes.generate(Routes.SERVICE_AREA_DETAILS, { id: serviceAreaId }) },
      ]);
  }

  static transferMajorLactsCategoryList() {
    return this.configuration().concat([
      { breadcrumbName: 'Transfer Major Lacts Category', path: Routes.generate(Routes.TRANSFER_MAJOR_LACTS_CATEGORY) }
    ]);
  }

  static transferMajorLactsCategoryDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_TRANSFER_MAJOR_LACTS_CATEGORY) :
      Routes.generate(Routes.TRANSFER_MAJOR_LACTS_CATEGORY_DETAILS, { id: id });

    return this.transferMajorLactsCategoryList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static transferMajorCourseBlockList() {
    return this.configuration().concat([
      { breadcrumbName: 'Transfer Major Course Block', path: Routes.generate(Routes.TRANSFER_MAJOR_COURSE_BLOCK) }
    ]);
  }

  static transferMajorCourseBlockDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_TRANSFER_MAJOR_COURSE_BLOCK) :
      Routes.generate(Routes.TRANSFER_MAJOR_COURSE_BLOCK_DETAILS, { id: id });

    return this.transferMajorCourseBlockList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static transferMajorDisciplineFrameworkElementList() {
    return this.configuration().concat([
      { breadcrumbName: 'Discipline Framework Element', path: Routes.generate(Routes.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT) }
    ]);
  }

  static transferMajorDisciplineFrameworkElementDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT) :
      Routes.generate(Routes.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT_DETAILS, { id: id });

    return this.transferMajorDisciplineFrameworkElementList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static transferMajorGeneralEducationTypeList() {
    return this.configuration().concat([
      { breadcrumbName: 'General Education Type', path: Routes.generate(Routes.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE) }
    ]);
  }

  static transferMajorGeneralEducationTypeDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE) :
      Routes.generate(Routes.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE_DETAILS, { id: id });

    return this.transferMajorGeneralEducationTypeList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static typeOfProgramList() {
    return this.configuration().concat([
      { breadcrumbName: 'Type Of Program', path: Routes.generate(Routes.TYPE_OF_PROGRAM) }
    ]);
  }

  static typeOfProgramDetails(name: string, id: number) {
    const route = id == 0 ?
      Routes.generate(Routes.NEW_TYPE_OF_PROGRAM) :
      Routes.generate(Routes.TYPE_OF_PROGRAM_DETAILS, { id: id });

    return this.typeOfProgramList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  static termList() {
    return this.configuration().concat([
      { breadcrumbName: 'Terms', path: Routes.generate(Routes.TERMS) }
    ]);
  }

  static termDetails(name: string, id: string) {
    const route = id == Guid.Empty() ?
      Routes.generate(Routes.NEW_TERM) :
      Routes.generate(Routes.TERM_DETAILS, { id: id });

    return this.termList().concat([
      { breadcrumbName: name, path: route }
    ]);
  }

  public static userSettings() {
    return [
      { breadcrumbName: 'User Settings', path: Routes.generate(Routes.USER_SETTINGS) }
    ];
  }

  public static userList() {
    return this.home().concat([
      { breadcrumbName: 'Users', path: Routes.generate(Routes.USERS) }
    ]);
  }

  public static userDetails(name: string, id: string) {
    return this.userList().concat([
      { breadcrumbName: name, path: Routes.generate(Routes.USER_DETAILS, { id: id }) }
    ]);
  }

  public static workItems() {
    return this.home().concat([
      { breadcrumbName: 'Work Items', path: Routes.generate(Routes.WORK_ITEMS) }
    ]);
  }

  static academicYears() {
    return this.secondary().concat([
      { breadcrumbName: 'Program Review Years', path: Routes.generate(Routes.ACADEMIC_YEARS) }
    ]);
  }

  static selfStudyConfiguration(academicYearId: number) {
    return this.academicYearDashboard(academicYearId).concat([
      { breadcrumbName: 'Self Study Configuration', path: Routes.generate(Routes.SELF_STUDIES_CONFIGURATION, { academicYearId: academicYearId }) }
    ]);
  }

  static selfStudyConfigurationFromAcademicList(academicYearId: number) {
    return this.academicYearDashboardFromAcademicList(academicYearId).concat([
      { breadcrumbName: 'Self Study Configuration', path: Routes.generate(Routes.SELF_STUDIES_CONFIGURATION_FROM_LIST, { academicYearId: academicYearId }) }
    ]);
  }

  static academicYearDashboard(academicYear: number) {
    return this.academicYears().concat([
      { breadcrumbName: academicYear.toString() + ' - ' + (academicYear + 1).toString(), path: Routes.generate(Routes.SELF_STUDIES_ACADEMIC_YEAR_DASHBOARD, { academicYearId: academicYear }) }
    ]);
  }

  static academicYearDashboardFromAcademicList(academicYear: number) {
    return this.academicYearList().concat([
      { breadcrumbName: academicYear.toString(), path: Routes.generate(Routes.ACADEMIC_YEAR_DASHBOARD_FROM_LIST, { academicYearId: academicYear }) }
    ]);
  }

  static newAcademicYearFromAcademicList(academicYear: number) {
    return this.academicYearList().concat([
      { breadcrumbName: academicYear.toString(), path: Routes.generate(Routes.NEW_ACADEMIC_YEAR_FROM_LIST, { academicYearId: academicYear }) }
    ]);
  }

  static regionListPage() {
    return this.secondary().concat([
      { breadcrumbName: 'Regional Planning Partners', path: Routes.generate(Routes.REGIONAL_PLANNING_PARTNERS) }
    ]);
  }

  static regionDetailPage(code: string, rppId: string,) {
    return this.regionListPage().concat([
      { breadcrumbName: code, path: Routes.generate(Routes.REGIONAL_PLANNING_PARTNER_DETAIL, { id: rppId }) }
    ]);
  }

  static regionConfigurationListPage() {
    return this.configuration().concat([
      { breadcrumbName: 'Regional Planning Partners', path: Routes.generate(Routes.REGIONAL_PLANNING_PARTNERS) }
    ]);
  }

  static regionConfigurationDetailPage(code: string, rppId: string,) {
    return this.regionConfigurationListPage().concat([
      { breadcrumbName: code, path: Routes.generate(Routes.REGIONAL_PLANNING_PARTNER_DETAIL, { id: rppId }) }
    ]);
  }

}

export default Breadcrumbs;