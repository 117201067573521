// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionDTO from './InstitutionDTO';
import UserDTO from './UserDTO';
import StatusTypeDTO from './StatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionUserDTO extends EntitySelectorDTO { 
	id: string | null;
	institutionId: string | null;
	userId: string | null;
	statusTypeId: number;
	institution: InstitutionDTO | null;
	user: UserDTO | null;
	emailAddress: string | null;
	fullName: string | null;
	createdOn: moment.Moment | string;
	removedOn: moment.Moment | string | null;
	roleIds: string[];
	roles: string[];
	statusType: StatusTypeDTO | null;
}

const InstitutionUserDTO = {
    className: 'InstitutionUserDTO',
    create: (initValues?: Partial<InstitutionUserDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			institutionId: '00000000-0000-0000-0000-000000000000',
			userId: '00000000-0000-0000-0000-000000000000',
			statusTypeId: 0,
			institution: null,
			user: null,
			emailAddress: "",
			fullName: "",
			createdOn: new Date(0).toISOString(),
			removedOn: null,
			roleIds: [],
			roles: [],
			statusType: null,
        },
        initValues);
    },
	id: 'id',
	institutionId: 'institutionId',
	userId: 'userId',
	statusTypeId: 'statusTypeId',
	institution: 'institution',
	user: 'user',
	emailAddress: 'emailAddress',
	fullName: 'fullName',
	createdOn: 'createdOn',
	removedOn: 'removedOn',
	roleIds: 'roleIds',
	roles: 'roles',
	statusType: 'statusType',
};

export default InstitutionUserDTO;