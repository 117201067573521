import { match } from 'react-router';

export default class ParameterUtil {
  static DEFAULT_STRING = '';
  static DEFAULT_INT = 0;
  static DEFAULT_FLOAT = 0;

  // Query Parameters
  static getParameter(key: string, defaultValue?: string): string {
    const urlParams = new URLSearchParams(location.search);

    if (!defaultValue) {
      defaultValue = ParameterUtil.DEFAULT_STRING;
    }

    return urlParams == null ? defaultValue : urlParams.get(key) ?? defaultValue;
  }

  static getIntParameter(key: string, defaultValue?: number): number {
    if (!defaultValue) {
      defaultValue = ParameterUtil.DEFAULT_INT;
    }

    return parseInt(this.getParameter(key, defaultValue.toString())) ?? defaultValue;
  }

  static getFloatParameter(key: string, defaultValue?: number): number {
    if (!defaultValue) {
      defaultValue = ParameterUtil.DEFAULT_FLOAT;
    }

    return parseFloat(this.getParameter(key, defaultValue.toString())) ?? defaultValue;
  }

  // Path Parts
  static getPathPart(match: match<RouteObject>, key: string, defaultValue?: string): string {
    if (!defaultValue) {
      defaultValue = ParameterUtil.DEFAULT_STRING;
    }

    return match == null ? defaultValue : match.params[key] ?? defaultValue;
  }

  static getIntPathPart(match: match<RouteObject>, key: string, defaultValue?: number): number {
    if (!defaultValue) {
      defaultValue = ParameterUtil.DEFAULT_INT;
    }

    return parseInt(this.getPathPart(match, key, defaultValue.toString())) ?? defaultValue;
  }

  static getFloatPathPart(match: match<RouteObject>, key: string, defaultValue?: number): number {
    if (!defaultValue) {
      defaultValue = ParameterUtil.DEFAULT_FLOAT;
    }

    return parseFloat(this.getPathPart(match, key, defaultValue.toString())) ?? defaultValue;
  }
}
