import { Alert, Form, FormInstance, FormItemProps, message, Space, Typography } from 'antd';
import * as React from 'react';
import ChangeRequestApiService from '../../../api/ChangeRequestApiService';
import Routes from '../../../config/Routes';
import * as BulkCourseRequestHandler from '../../../handlerModels/BulkCourseRequestHandler';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FileUploadUtil from '../../../utils/FileUploadUtil';
import FileDownload from '../../../utils/FileDownload';
import FormUtil from '../../../utils/FormUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import FileUpload from '../../FileUpload';
import DownloadButton from '../../buttons/DownloadButton';
import SubmitButton from '../../buttons/SubmitButton';
import ResetButton from '../../buttons/ResetButton';

interface BulkCourseChangeRequestFormProps extends BaseFormProps {
  institutionId: string;
}

interface BulkCourseChangeRequestFormState extends BaseFormState {
}

class BulkCourseChangeRequestForm extends React.Component<BulkCourseChangeRequestFormProps, BulkCourseChangeRequestFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(BulkCourseRequestHandler.Request.file, {
        name: BulkCourseRequestHandler.Request.file,
        label: 'Please select a file',
      });
  }

  constructor(props: BulkCourseChangeRequestFormProps) {
    super(props);

    this.state = {
    };
  }

  private resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const model = this._formRef.current?.getFieldsValue() ?? {} as BulkCourseRequestHandler.Request;

    if (!FormUtil.canSubmit(this._formRef, this.getFormItems())) {
      message.error('Please fill out all required fields.');
      this.setState({ loading: false, submitting: false });
    }
    else {
      const file = model.file.newFiles[0];
      const request = FileUploadUtil.attachFileToModel('bulkCourseRequest', { institutionId: this.props.institutionId }, BulkCourseRequestHandler.Request.file, file)

      ChangeRequestApiService.bulkCourseRequest(request)
        .then((result: BulkCourseRequestHandler.Result) => {
          this.resetForm();
          this.setState({ submitting: false });
          const fileName = result.succeeded ? 'Bulk Course Change Request Summary.xlsx' : 'Bulk Course Change Request Error Report.xlsx';
          FileDownload.downloadBase64StringAsExcel(fileName, result ?? undefined);
        });
    }
  }

  render() {
    return (
      <Space direction="vertical">
        <Typography.Title level={5}>Bulk Course Change Requests</Typography.Title>

        <Form layout='vertical' onFinish={this.handleSubmit} ref={this._formRef}>
          <Space direction="vertical">
            <DownloadButton type='link' target='_blank' href={Routes.generate(Routes.BULK_COURSE_TEMPLATE)} title='Download Template' />
            <Form.Item
              {...this.getFormItems().get(BulkCourseRequestHandler.Request.file)}
              {...ValidationUtil.getValidation(BulkCourseRequestHandler.Request.file, this.state.fieldErrors, this.state.submitted)}>
              <FileUpload maxCount={1} style={{ width: 300 }} />
            </Form.Item>
            {this.renderErrors()}

            <Space direction='horizontal'>
              <SubmitButton />
              <ResetButton onClick={this.resetForm} onConfirm={this.resetForm} />
            </Space>
          </Space>
        </Form>

      </Space>
    );
  }

  renderErrors() {
    if (this.state.error && this.state.message) {
      return <Alert type="error" message='Error' showIcon={true} description={this.state.message} />;
    }
  }
}

export default BulkCourseChangeRequestForm;
