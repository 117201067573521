import { Typography } from 'antd';
import * as React from 'react';
import StringUtil from '../../utils/StringUtil';
import { TextProps } from 'antd/lib/typography/Text';

interface ValueLabelState { }

interface ValueLabelProps extends TextProps  {
  value?: any;
  text?: any;
  ellipsis?: boolean;
  errorMessage?: boolean;
  strong?: boolean;
}

class ValueLabel extends React.Component<ValueLabelProps, ValueLabelState> {
  constructor(props: ValueLabelProps) {
    super(props);

  }

  render() {
    let text = this.props.text || this.props.text == 0 ? this.props.text : this.props.value;
    text = this.props.ellipsis ? StringUtil.ellipsisCharacterLimit(text, 30) : text;

    return (
      <Typography.Text style={this.props.style} className={this.props.errorMessage ? 'ant-error-message' : ''} strong={this.props.strong ?? true}> {text} </ Typography.Text>
    );
  }
}

export default ValueLabel;
