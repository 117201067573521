import { Collapse, Form, FormInstance, notification, Skeleton, Space } from 'antd';
import * as React from 'react';
import RolesApiService from '../api/RolesApiService';
import FeatureFlag from '../consts/FeatureFlag';
import * as GetFeatureFlagsForRoleHandler from '../handlerModels/GetFeatureFlagsForRoleHandler';
import * as SaveFeatureFlagsForRoleHandler from '../handlerModels/SaveFeatureFlagsForRoleHandler';
import RoleDTO from '../models/RoleDTO';
import FeatureFlagAreas from '../consts/FeatureFlagAreas';
import BaseFormState from '../redux/bases/BaseFormState';
import AuthorizationUtil from '../utils/AuthorizationUtil';
import LookupsUtil from '../utils/LookupsUtil';
import FeatureFlagEditor from './postSecondary/FeatureFlagEditor';
import ResetButton from './buttons/ResetButton';
import SaveButton from './buttons/SaveButton';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

interface RoleActionsState extends BaseFormState {
  selectedIds: string[];
  previousSelectedIds: string[];
  roles: RoleDTO[];
}

interface RoleActionsProps {
  isEditing?: boolean;
  roleId: string;
  onSave?: () => void;
}

class RoleActions extends React.Component<RoleActionsProps, RoleActionsState> {
  private readonly _formRef = React.createRef<FormInstance>();

  constructor(props: RoleActionsProps) {
    super(props);

    this.state = {
      previousSelectedIds: [],
      selectedIds: [],
      roles: [],
    };
  }

  componentDidMount() {
    this.loadRoleFeatureFlags()
  }

  componentDidUpdate(prevProps: RoleActionsProps) {
    if (this.props.roleId != prevProps.roleId) {
      this.loadRoleFeatureFlags()
    }
  }

  private loadRoleFeatureFlags = () => {
    this.setState({ loading: true })
    return RolesApiService.getFeatureFlagsForRole(this.props.roleId)
      .then((results: GetFeatureFlagsForRoleHandler.Result) => {
        this.setState({
          selectedIds: results.roleFeatureFlagIds,
          previousSelectedIds: JSON.parse(JSON.stringify(results.roleFeatureFlagIds))
        });
      })
      .catch((error: any) => {
        this.setState({ error: true, message: error });
      }).finally(() => {
        this.setState({ loading: false })
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveFeatureFlagsForRoleHandler.Request.create({
      roleId: this.props.roleId,
      selectedFeatureFlagIds: this.state.selectedIds
    });

    RolesApiService.saveFeatureFlagsForRole(request)
      .then((result: SaveFeatureFlagsForRoleHandler.Result) => {
        if (result) {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors,
            altered: !result?.succeeded
          });

          if (result.succeeded) {
            this.setState({
              previousSelectedIds: JSON.parse(JSON.stringify(this.state.selectedIds))
            });
            notification.success({ message: 'Success!', description: 'User roles have been saved.', });
            if (this.props.onSave) {
              this.props.onSave();
            }

            LookupsUtil.invalidateCache(RoleDTO.className);
          }
          else {
            notification.error({ message: 'Uh Oh!', description: result?.errors.join('\n'), });
          }
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        notification.error({ message: 'Uh oh!', description: 'Roles could not be saved.', });
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private onChange = (selectedIds: string[]) => {
    this.setState({ selectedIds: selectedIds });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({
      altered: false,
      selectedIds: JSON.parse(JSON.stringify(this.state.previousSelectedIds))
    });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Form ref={this._formRef} layout="vertical" onChange={this.handleChange} onFinish={this.handleSubmit} requiredMark={true}>
        <Space direction="vertical">
          {this.renderFeatureFlag()}
          {this.renderAction()}
        </Space>
      </Form>
    );
  }

  renderAction() {
    if (this.props.isEditing && AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_ROLE])) {
      return (<Space direction='horizontal' wrap={true}>
        <SaveButton disabled={this.state.loading} saving={this.state.submitting} saved={this.state.saved} />
        <ResetButton disabled={!this.state.altered} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </ Space>);
    }
  }

  renderFeatureFlag() {
    if (AuthorizationUtil.isAuthorized([FeatureFlag.VIEW_ROLE, FeatureFlag.EDIT_ROLE])) {

      const selectedFeatureFlagIds = this.state.selectedIds;
      selectedFeatureFlagIds.distinct();
      return (
        <Space direction="vertical" size="large">
          <Collapse >
            <CollapsePanel header={'Global'} key='1'>
              <FeatureFlagEditor
                activeFeatureFlags={FeatureFlagAreas.GLOBAL}
                isEditing={this.props.isEditing}
                selectedIds={selectedFeatureFlagIds}
                onChange={this.onChange} />
            </CollapsePanel>
            <CollapsePanel header={'Secondary'} key='2'>
              <FeatureFlagEditor
                activeFeatureFlags={FeatureFlagAreas.SECONDARY}
                isEditing={this.props.isEditing}
                selectedIds={selectedFeatureFlagIds}
                onChange={this.onChange} />
            </CollapsePanel>
            <CollapsePanel header={'Post-Secondary'} key='3'>
              <FeatureFlagEditor
                activeFeatureFlags={FeatureFlagAreas.POST_SECONDARY}
                isEditing={this.props.isEditing}
                selectedIds={selectedFeatureFlagIds}
                onChange={this.onChange} />
            </CollapsePanel>
          </Collapse>
        </ Space>
      );
    }
  }
}

export default RoleActions;
