// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveInstitutionLocationHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import InstitutionLocationDTO from '../models/InstitutionLocationDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    institutionId: string | null;
    institutionLocationId: string | null | null;
    institutionLocation: InstitutionLocationDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            institutionId: '00000000-0000-0000-0000-000000000000',
            institutionLocationId: null,
            institutionLocation: null,
        },
        initValues);
    },
    institutionId: 'institutionId',
    institutionLocationId: 'institutionLocationId',
    institutionLocation: 'institutionLocation',
}

interface Result extends ResultDTO {
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
        },
        initValues);
    },
}

export { Request, Result };