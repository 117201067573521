// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import CourseDTO from './CourseDTO';
import InstitutionCourseDTO from './InstitutionCourseDTO';
import InstitutionCourseBlockDTO from './InstitutionCourseBlockDTO';
import GroupOptionTypeDTO from './GroupOptionTypeDTO';
import WorkBasedLearningTypeDTO from './WorkBasedLearningTypeDTO';
import GeneralEducationDTO from './GeneralEducationDTO';
import APSGeneralEducationDTO from './APSGeneralEducationDTO';
import TransferMajorCourseBlockDTO from './TransferMajorCourseBlockDTO';
import TransferMajorLactsCategoryDTO from './TransferMajorLactsCategoryDTO';
import TransferMajorGeneralEducationTypeDTO from './TransferMajorGeneralEducationTypeDTO';
import TransferMajorDisciplineFrameworkElementDTO from './TransferMajorDisciplineFrameworkElementDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface FlatProgramTermCourseGroupOptionDTO extends EntitySelectorDTO { 
	id: string | null | null;
	programTermCourseGroupId: string | null | null;
	courseId: string | null | null;
	course: CourseDTO | null;
	institutionCourseId: string | null | null;
	institutionCourse: InstitutionCourseDTO | null;
	courseBlockId: string | null | null;
	courseBlock: InstitutionCourseBlockDTO | null;
	groupOptionTypeId: number | null | null;
	groupOptionType: GroupOptionTypeDTO | null;
	workBasedLearningTypeId: number | null | null;
	workBasedLearningType: WorkBasedLearningTypeDTO | null;
	order: number;
	generalEducationId: number | null | null;
	generalEducation: GeneralEducationDTO | null;
	apsGeneralEducationId: number | null | null;
	apsGeneralEducation: APSGeneralEducationDTO | null;
	transferMajorCourseBlockId: number | null | null;
	transferMajorCourseBlock: TransferMajorCourseBlockDTO | null;
	transferMajorLactsCategoryId: number | null | null;
	transferMajorLactsCategory: TransferMajorLactsCategoryDTO | null;
	transferMajorGeneralEducationTypeId: number | null | null;
	transferMajorGeneralEducationType: TransferMajorGeneralEducationTypeDTO | null;
	transferMajorDisciplineFrameworkElementId: number | null | null;
	transferMajorDisciplineFrameworkElement: TransferMajorDisciplineFrameworkElementDTO | null;
	credits: MinMaxDTO<number | null> | null;
	footnote: string | null;
	techCore: boolean | null | null;
	embeddedCredits: number | null | null;
	groupIndex: number;
	isVariableCredits: boolean | null | null;
	title: string | null;
}

const FlatProgramTermCourseGroupOptionDTO = {
    className: 'FlatProgramTermCourseGroupOptionDTO',
    create: (initValues?: Partial<FlatProgramTermCourseGroupOptionDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: null,
			programTermCourseGroupId: null,
			courseId: null,
			course: null,
			institutionCourseId: null,
			institutionCourse: null,
			courseBlockId: null,
			courseBlock: null,
			groupOptionTypeId: null,
			groupOptionType: null,
			workBasedLearningTypeId: null,
			workBasedLearningType: null,
			order: 0,
			generalEducationId: null,
			generalEducation: null,
			apsGeneralEducationId: null,
			apsGeneralEducation: null,
			transferMajorCourseBlockId: null,
			transferMajorCourseBlock: null,
			transferMajorLactsCategoryId: null,
			transferMajorLactsCategory: null,
			transferMajorGeneralEducationTypeId: null,
			transferMajorGeneralEducationType: null,
			transferMajorDisciplineFrameworkElementId: null,
			transferMajorDisciplineFrameworkElement: null,
			credits: null,
			footnote: "",
			techCore: null,
			embeddedCredits: null,
			groupIndex: 0,
			isVariableCredits: null,
			title: "",
        },
        initValues);
    },
	id: 'id',
	programTermCourseGroupId: 'programTermCourseGroupId',
	courseId: 'courseId',
	course: 'course',
	institutionCourseId: 'institutionCourseId',
	institutionCourse: 'institutionCourse',
	courseBlockId: 'courseBlockId',
	courseBlock: 'courseBlock',
	groupOptionTypeId: 'groupOptionTypeId',
	groupOptionType: 'groupOptionType',
	workBasedLearningTypeId: 'workBasedLearningTypeId',
	workBasedLearningType: 'workBasedLearningType',
	order: 'order',
	generalEducationId: 'generalEducationId',
	generalEducation: 'generalEducation',
	apsGeneralEducationId: 'apsGeneralEducationId',
	apsGeneralEducation: 'apsGeneralEducation',
	transferMajorCourseBlockId: 'transferMajorCourseBlockId',
	transferMajorCourseBlock: 'transferMajorCourseBlock',
	transferMajorLactsCategoryId: 'transferMajorLactsCategoryId',
	transferMajorLactsCategory: 'transferMajorLactsCategory',
	transferMajorGeneralEducationTypeId: 'transferMajorGeneralEducationTypeId',
	transferMajorGeneralEducationType: 'transferMajorGeneralEducationType',
	transferMajorDisciplineFrameworkElementId: 'transferMajorDisciplineFrameworkElementId',
	transferMajorDisciplineFrameworkElement: 'transferMajorDisciplineFrameworkElement',
	credits: 'credits',
	footnote: 'footnote',
	techCore: 'techCore',
	embeddedCredits: 'embeddedCredits',
	groupIndex: 'groupIndex',
	isVariableCredits: 'isVariableCredits',
	title: 'title',
};

export default FlatProgramTermCourseGroupOptionDTO;