import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Modal } from 'antd';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import * as React from 'react';
import ChangeRequestApiService from '../../api/ChangeRequestApiService';
import AuthorizedContent from '../../components/AuthorizedContent';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import * as CancelChangeRequestHandler from '../../handlerModels/CancelChangeRequestHandler';
import HeaderPortal from '../HeaderPortal';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import HistoryUtil from '../../utils/HistoryUtil';

interface ChangeRequestHeaderPortalState extends BaseFormState {
}

interface ChangeRequestHeaderPortalProps extends BaseFormProps {
  current: number;
  changeRequestId: string;
  changeReqeustType: number;
  pageTitle: string;
  breadcrumbs?: Route[];
}

class ChangeRequestHeaderPortal extends React.Component<ChangeRequestHeaderPortalProps, ChangeRequestHeaderPortalState> {

  private promptConfirmCancel = (changeRequestId: string) => {
    Modal.confirm({
      title: 'Are you sure you want to cancel this change request?',
      okText: 'Cancel Change Request',
      onOk: () => this.cancelChangeRequest(changeRequestId)
    });
  }

  private cancelChangeRequest = (changeRequestId: string) => {
    this.setState({ submitting: true });

    const request = CancelChangeRequestHandler.Request.create({
      changeRequestId: changeRequestId,
    });

    ChangeRequestApiService.cancelChangeRequest(request)
      .then((result: CancelChangeRequestHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Change Request Cancelled.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Change Request could not be cancelled.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToChangeRequest();
      });
  }

  private goToChangeRequest = () => {
    HistoryUtil.push(Routes.generate(Routes.CHANGE_REQUESTS));
  }

  public render = () => {
    return (
      <HeaderPortal
        title={this.props.pageTitle}
        breadcrumbs={this.props.breadcrumbs}
        extra={this.renderMoreActions()} />
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        <Menu.Item title='Cancel Change Request' key={this.props.changeRequestId} onClick={() => this.promptConfirmCancel(this.props.changeRequestId)}  >
          Cancel Change Request
        </Menu.Item>
      </Menu>
    );

    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CHANGE_REQUEST]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default ChangeRequestHeaderPortal;