import * as React from 'react';
import DocumentArchiveApiService from '../../api/DocumentArchiveApiService';
import DocumentStatus from '../../consts/DocumentStatus';
import DocumentArchiveDTO from '../../models/DocumentArchiveDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import ThirdPartyCredentialTypeDTO from '../../models/ThirdPartyCredentialTypeDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import DateTimeUtil from '../../utils/DateTimeUtil';
import Guid from '../../utils/Guid';
import TableFilterUtil, { TableFilterOption } from '../../utils/TableFilterUtil';
import FileDownloadLink from '../general/FileDownloadLink';
import DataTable, { DataTableColumnProps, FilterType } from './core/DataTable';
import DataTableButtonUtil from './core/DataTableButtonUtil';

interface DocumentArchiveDataTableProps extends BaseFormProps {}

interface DocumentArchiveDataTableState extends BaseDataTableState<ThirdPartyCredentialTypeDTO>, BaseFormState {
    documentTypes: TableFilterOption[];
    documentStatuses: TableFilterOption[];
}

class DocumentArchiveDataTable extends React.Component<DocumentArchiveDataTableProps, DocumentArchiveDataTableState> {
  private _dataTable: DataTable<ThirdPartyCredentialTypeDTO> | undefined;

  constructor(props: DocumentArchiveDataTableProps) {
    super(props);
    this.state = {
        documentTypes: [],
        documentStatuses: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    TableFilterUtil.DocumentTypes()
      .then(x => {
        this.setState({ documentTypes: x })
      });

    TableFilterUtil.DocumentStatuses()
      .then(x => {
        this.setState({ documentStatuses: x })
      });
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'User',
        dataIndex: DocumentArchiveDTO.generatingUserId,
        sorter: true,
        filterType: FilterType.Text,
        render: (data: string, row: DocumentArchiveDTO) => {
          return row.user?.fullName ?? '';
        },
      },
      {
        title: 'Document Type',
        dataIndex: DocumentArchiveDTO.documentTypeId,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.documentTypes,
        render: (data: string, row: DocumentArchiveDTO) => {
          return row.documentType?.name ?? '';
        },
      },
      {
        title: 'Status',
        dataIndex: DocumentArchiveDTO.documentStatusId,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.documentStatuses,
        render: (data: string, row: DocumentArchiveDTO) => {
          return row.documentStatus?.name ?? '';
        },
      },
      {
        title: 'Queued',
        dataIndex: DocumentArchiveDTO.queued,
        sorter: true,
        filterType: FilterType.DateRange,
        render: (data: string, row: DocumentArchiveDTO) => {
            if (row.queued) {
              return DateTimeUtil.shortDateAndTime(row.queued);
            }
          },
      },
      {
        title: 'Completed',
        dataIndex: DocumentArchiveDTO.completed,
        sorter: true,
        filterType: FilterType.DateRange,
        render: (data: string, row: DocumentArchiveDTO) => {
            if (row.completed) {
              return DateTimeUtil.shortDateAndTime(row.completed);
            }
          },
      },
      {
        title: 'File',
        dataIndex: DocumentArchiveDTO.azureBlobId,
        sorter: true,
        render: (data: string, row: DocumentArchiveDTO) => {
          if (row.documentStatusId == DocumentStatus.COMPLETE) {
            return (
              <FileDownloadLink
                key={row.azureBlobId}
                azureBlobFileId={row.azureBlobId ?? Guid.Empty()}
                text={row?.azureBlobFile?.fileName ?? 'Unknown'}
              />
            );
          }
          else if(row.documentStatusId == DocumentStatus.RUNNING){
            return row.azureBlobFile?.fileName
          }
        },
        filterType: FilterType.Text,
      },
      {
        title: 'Message',
        dataIndex: DocumentArchiveDTO.message,
        sorter: true,
        filterType: FilterType.Text,
      },
      {
        title: 'Id',
        dataIndex: DocumentArchiveDTO.id,
        sorter: true,
        filterType: FilterType.Text,
      },
    ] as DataTableColumnProps<any>[];
  };

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    // actionButtons.push(
    //   DataTableButtonUtil.Export(
    //     (tableRequest: TableRequestDTO) => {
    //       this.setState({ exporting: true });
    //       return ThirdPartyCredentialTypeApiService.getDocumentArchiveDataTableExport(tableRequest).then(file => {
    //         this.setState({ exporting: false });
    //         FileDownload.downloadBase64StringAsFile(`ThirdPartyCredentialType_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
    //       });
    //     },
    //     this.state.exporting
    //   )
    // );

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          //onRowClick={this.openThirdPartyCredentialType}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 },
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) =>
              DocumentArchiveApiService.getDocumentArchiveTableData(requestState),
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'thirdPartyCredentialType_list' }}
        />
      </>
    );
  }
}

export default DocumentArchiveDataTable;
