import * as React from 'react';
import ExternalInstitutionApiService from '../../../api/ExternalInstitutionApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import ExternalInstitutionDTO from '../../../models/ExternalInstitutionDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import FileDownload from '../../../utils/FileDownload';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface ExternalInstitutionDataTableProps extends BaseFormProps {
}

interface ExternalInstitutionDataTableState extends BaseDataTableState<ExternalInstitutionDTO>, BaseFormState {
}

class ExternalInstitutionDataTable extends React.Component<ExternalInstitutionDataTableProps, ExternalInstitutionDataTableState> {
  private _dataTable: DataTable<ExternalInstitutionDTO> | undefined;

  constructor(props: ExternalInstitutionDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Abbreviation',
        dataIndex: ExternalInstitutionDTO.abbreviation,
        sorter: true,
        filterType: FilterType.Text,
        width: 85
      },
      {
        title: 'Name',
        dataIndex: ExternalInstitutionDTO.name,
        sorter: true,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];
  };

  private openExternalInstitution = (record: ExternalInstitutionDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.EXTERNAL_INSTITUTIONS_DETAILS, { id: record.id }, {}));
    }
  }

  private addExternalInstitution = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_EXTERNAL_INSTITUTIONS));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return ExternalInstitutionApiService.getExternalInstitutionDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`External_Institution_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_EXTERNAL_INSTITUTIONS])) {
      actionButtons.push(DataTableButtonUtil.Default('New External Institution', () => this.addExternalInstitution()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openExternalInstitution}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => ExternalInstitutionApiService.getExternalInstitutionsDataTable(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'external_Institution_list' }} />
      </>
    );
  }
}

export default ExternalInstitutionDataTable;
