import { Skeleton } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import SelfStudyTemplateApiService from '../../../api/SelfStudyTemplateApiService';
import Routes from '../../../config/Routes';
import SelfStudyResponseType from '../../../consts/SelfStudyResponseType';
import SelfStudySectionComponentType from '../../../consts/SelfStudySectionComponentType';
import * as GetSelfStudyConfigurationHandler from '../../../handlerModels/GetSelfStudyConfigurationHandler';
import * as GetSelfStudyResponsesHandler from '../../../handlerModels/GetSelfStudyResponsesHandler';
import DistrictDTO from '../../../models/DistrictDTO';
import ProgramConsultantCommentDataTableDTO from '../../../models/ProgramConsultantCommentDataTableDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import SelfStudyDTO from '../../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../../models/SelfStudyResponseDTO';
import SelfStudyTemplateDTO from '../../../models/SelfStudyTemplateDTO';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';

interface ProgramConsultantCommentDataTableProps {
  academicYear?: number;
  selectedDistrict: DistrictDTO | null;
  selectedRPP: RegionalPlanningPartnerDTO | null;
  fromSelfStudies?: boolean;
  fromAcademicYearList?: boolean;
  isPublic?: boolean;
  districtId?: string;
  fromAcademicDashboard?: boolean;
}

function ProgramConsultantCommentDataTable(props: ProgramConsultantCommentDataTableProps) {
  let _dataTable: DataTable<ProgramConsultantCommentDataTableDTO> | undefined = undefined;

  const [, setSelfStudyResponseTypes] = useState([] as TableFilterOption[]);
  const [selfStudyResponses, setSelfStudyResponses] = useState([] as SelfStudyResponseDTO[]);
  const [selfStudyTemplate, setSelfStudyTemplate] = useState({} as SelfStudyTemplateDTO);
  const [loading, setLoading] = useState(false);
  const [institutionOptions, setInstitutionOptions] = useState([] as TableFilterOption[]);
  const [, setError] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    const loaders = [];

    TableFilterUtil.SelfStudyResponseTypes()
      .then(x => {
        setSelfStudyResponseTypes(x);
      });
    TableFilterUtil.Institutions().then(x => setInstitutionOptions(x));

    triggerRefresh();
    loaders.push(loadSelfStudyResponses());
    loaders.push(loadSelfStudyTemplate());

    Promise.all(loaders).then(() => {
      setLoading(false);
      triggerRefresh();
    });
  }, []);

  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const getColumnDefinitions = () => {
    const reviewYear: any = (data: string, row: ProgramConsultantCommentDataTableDTO) => (
      <span>{row?.reviewYear ?? '0'} {row?.responseType ?? 'district'} </span>
    );

    const column = [
      {
        title: 'Regional Planning Partner',
        dataIndex: ProgramConsultantCommentDataTableDTO.rpp,
        sorter: true,
        render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
          return row.rpp;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Community College',
        dataIndex: ProgramConsultantCommentDataTableDTO.communityCollege,
        sorter: true,
        render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
          return row.communityCollege;
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: institutionOptions
      },
      {
        title: 'District',
        dataIndex: ProgramConsultantCommentDataTableDTO.district,
        sorter: true,
        render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
          return row.district;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Service Area',
        dataIndex: ProgramConsultantCommentDataTableDTO.serviceArea,
        sorter: true,
        render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
          return row.serviceArea;
        },
        filterType: FilterType.Text
      },
      {
        title: 'CIP',
        dataIndex: ProgramConsultantCommentDataTableDTO.cipNumber,
        sorter: true,
        render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
          return row.cipNumber;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Program',
        dataIndex: ProgramConsultantCommentDataTableDTO.program,
        sorter: true,
        render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
          return row.program;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Submitter',
        dataIndex: ProgramConsultantCommentDataTableDTO.submitter,
        sorter: true,
        render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
          return row.submitter;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Review Year',
        dataIndex: ProgramConsultantCommentDataTableDTO.reviewYear,
        sorter: true,
        render: reviewYear,
        filterType: FilterType.Text
      }
    ] as DataTableColumnProps<any>[];

    selfStudyTemplate?.selfStudySectionTemplates?.forEach(x => {
      const components: any = [];
      components.push({
        title: 'RPP PEER REVIEW COMMENTS',
        render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
          return selfStudyResponses.find(x => x.selfStudyId == row.selfStudyId && x.selfStudyReponseTypeId == SelfStudyResponseType.RPP)?.selfStudySectionResponses?.find(z => z.selfStudySectionTemplate?.order == x.order)?.sectionComments;
        },
      });
      components.push({
        title: 'DE CONSULTANT COMMENTS',
        render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
          return selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudySectionResponses?.find(z => z.selfStudySectionTemplate?.order == x.order)?.sectionComments;
        },
      });

      column.push({
        title: x.title,
        dataIndex: SelfStudyDTO.id,
        children: components as any,
      })
    });

    const components: any = [];
    if (selfStudyTemplate && selfStudyResponses?.length != 0) {
      const sectionIndex = (selfStudyTemplate?.selfStudySectionTemplates?.findIndex(x => (x.selfStudySectionComponentTemplates?.findIndex(y => y.selfStudySectionComponentTypeId == SelfStudySectionComponentType.CONSULTANTREVIEW) ?? -1) > -1) ?? 0);
      const section = (selfStudyTemplate?.selfStudySectionTemplates ?? [])[sectionIndex] ?? 0;
      const component = section.selfStudySectionComponentTemplates?.find(x => x.selfStudySectionComponentTypeId == SelfStudySectionComponentType.CONSULTANTREVIEW);
      const consultantReviewQuestions = section.selfStudySectionComponentTemplates?.find(x => x.selfStudySectionComponentTypeId == SelfStudySectionComponentType.CONSULTANTREVIEW)?.selfStudyResponseTemplateConsultantReviewQuestions;

      consultantReviewQuestions?.forEach(x => {

        components.push({
          title: x.question,
          render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
            const answer = ((selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudySectionResponses ?? [])[sectionIndex]?.selfStudySectionComponentResponses?.find(y => y.selfStudySectionComponentTemplateId == component?.id)?.selfStudyResponseConsultantReviewQuestionDTOs ?? [])[x.order]?.consultantAnswer;
            return (answer != undefined ? answer == true ? 'Yes' : 'No' : '');
          },
        });
        components.push({
          title: 'Comments',
          render: (data: string, row: ProgramConsultantCommentDataTableDTO) => {
            return (((selfStudyResponses.find(x => x.id == row.selfStudyResponseId)?.selfStudySectionResponses ?? [])[sectionIndex]?.selfStudySectionComponentResponses?.find(y => y.selfStudySectionComponentTemplateId == component?.id)?.selfStudyResponseConsultantReviewQuestionDTOs ?? [])[x.order]?.comment) ?? '';
          },
        })
      });

      column.push({
        title: 'CONSULTANT REVIEW OF OVERALL PROGRAM PRIORITIES AND ACTION PLANS',
        dataIndex: SelfStudyDTO.id,
        children: components as any,
      });
    }

    return column;
  };

  const loadSelfStudyTemplate = () => {
    return SelfStudyTemplateApiService.getSelfStudy(props.academicYear ?? 0)
      .then((results: GetSelfStudyConfigurationHandler.Result) => {
        if (results) {
          setSelfStudyTemplate(results.selfStudy ?? SelfStudyTemplateDTO.create());
        }
      })
      .catch(() => {
        setError(true);
      });
  }


  const loadSelfStudyResponses = () => {
    const request = GetSelfStudyResponsesHandler.Request.create({
      academicYear: props.academicYear
    });

    SelfStudyApiService.getSelfStudyResponses(request)
      .then((results: GetSelfStudyResponsesHandler.Result) => {
        if (results.selfStudyResponses) {
          setSelfStudyResponses(results.selfStudyResponses);
        }
      });
  }

  const programConsultantCommentsExport = () => {
    window.open(Routes.generateFull(Routes.PROGRAM_CONSULTANT_COMMENTS, {}, { academicYear: props.academicYear }), '_blank')
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());

  actionButtons.push(DataTableButtonUtil.Default('Export', programConsultantCommentsExport));
  if (loading) {
    return <Skeleton active />
  }
  else {
    return (<>

      <DataTable
        // @ts-ignore
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => SelfStudyApiService.getProgramConsultantCommentDataTable(
            requestState,
            props.academicYear == 0 ? 0 : props.academicYear ?? 0)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'self_study_list' }} />
    </>
    );
  }
}

export default ProgramConsultantCommentDataTable;
