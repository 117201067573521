import { Modal, Space } from 'antd';
import * as React from 'react';
import InstitutionUsersApiService from '../../../api/InstitutionUsersApiService';
import AddToInstitutionForm from '../../forms/postSecondary/AddToInstitutionForm';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import InstitutionUserDTO from '../../../models/InstitutionUserDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import StringUtil from '../../../utils/StringUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';

interface InstitutionUserIsInDataTableProps {
  userId: string;
  triggerUpdate?: () => void;
}

interface InstitutionUserIsInDataTableState extends BaseDataTableState<InstitutionUserDTO>, BaseFormState {
  roles: TableFilterOption[];
  addToInstitution?: boolean;
}

class InstitutionUserIsInDataTable extends React.Component<InstitutionUserIsInDataTableProps, InstitutionUserIsInDataTableState> {
  private readonly _addToInstitutionFormRef = React.createRef<AddToInstitutionForm>();

  constructor(props: InstitutionUserIsInDataTableProps) {
    super(props);

    this.state = {
      roles: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    TableFilterUtil.InstitutionRoles().then(x => this.setState({ roles: x }));
  }

  private _dataTable: DataTable<InstitutionUserDTO> | undefined;

  public triggerRefresh = () => {
    this._dataTable?.refresh();
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Name',
        dataIndex: InstitutionUserDTO.institution,
        render: (data: string, row: InstitutionUserDTO) => {
          return row.institution?.name ?? 'Unknown';
        },
        sorter: true,
        filterType: FilterType.Text,
        width: 350
      },
      {
        title: 'Roles',
        dataIndex: InstitutionUserDTO.roles,
        renderDataTransform: (value: string[]) => {
          return StringUtil.newLineFormat(value, true);
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: this.state.roles
      }
    ] as DataTableColumnProps<any>[];
  };

  private openInstitutionUser = (record: InstitutionUserDTO) => {
    if (record.id && record.userId && record.institutionId) {
      HistoryUtil.push(Routes.generate(Routes.USER_INSTITUTION_DETAILS, { userId: record.userId, institutionId: record.institutionId }, {}));
    }
  }

  private closeAddToInstitution = () => {
    this.setState({ addToInstitution: false });
  }

  private openAddToInstitution = () => {
    this.setState({ addToInstitution: true });
  }

  private submitAddToInstitution = () => {
    this._addToInstitutionFormRef.current?.submit();
  }

  private addedToInstitution = () => {
    this.closeAddToInstitution();
    this._dataTable?.refresh();
  }

  render() {
    const actionButtons = [];
    actionButtons.push(DataTableButtonUtil.Reset());

    if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_INSTITUTION_USER])) {
      actionButtons.push(DataTableButtonUtil.Default('Add to Institution', this.openAddToInstitution));
    }

    return (
      <Space direction='vertical' >
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openInstitutionUser}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => InstitutionUsersApiService.getInstitutionsForUserTableData(requestState, this.props.userId)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'institution_for_user_list' }} />
        {this.renderAddToInstitutionModal()}
      </Space>
    );
  }

  renderAddToInstitutionModal() {
    return (
      <Modal
        visible={this.state.addToInstitution}
        title='Add To Institution'
        okText='Add'
        onOk={this.submitAddToInstitution}
        onCancel={this.closeAddToInstitution}>
        <AddToInstitutionForm
          ref={this._addToInstitutionFormRef}
          onSuccess={this.addedToInstitution}
          userId={this.props.userId} />
      </Modal>
    );
  }
}

export default InstitutionUserIsInDataTable;
