// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SecondaryCipNumberDTO from '../models/SecondaryCipNumberDTO';
import * as AcademicYearHasCIPNumbersHandler from '../handlerModels/AcademicYearHasCIPNumbersHandler';
import * as GetSecondaryCipNumberDetailsHandler from '../handlerModels/GetSecondaryCipNumberDetailsHandler';
import * as SaveSecondaryCipNumberDetailsHandler from '../handlerModels/SaveSecondaryCipNumberDetailsHandler';
import * as DeleteSecondaryCipNumberHandler from '../handlerModels/DeleteSecondaryCipNumberHandler';
import * as GetSecondaryCipNumbresHandler from '../handlerModels/GetSecondaryCipNumbresHandler';
import * as AssignUserSecondaryCipNumbersHandler from '../handlerModels/AssignUserSecondaryCipNumbersHandler';
import * as UnassignUserSecondaryCipNumberHandler from '../handlerModels/UnassignUserSecondaryCipNumberHandler';
import * as SearchForSecondaryCipNumbersHandler from '../handlerModels/SearchForSecondaryCipNumbersHandler';

export class SecondaryCipNumberApiService extends BaseApi {

    // post: api/secondaryCipNumbers?academicYear=${academicYear}
    public getSecondaryCipNumberTableData(tableRequest: TableRequestDTO, academicYear: number): Promise<TableResponseDTO<SecondaryCipNumberDTO>> {
        let url = `api/secondaryCipNumbers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SecondaryCipNumberDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/hasCips/${academicYear}
    public academicYearHasCIPNumbers(academicYear: number): Promise<AcademicYearHasCIPNumbersHandler.Result> {
        let url = `api/secondaryCipNumbers/hasCips/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AcademicYearHasCIPNumbersHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/userCipNumbers?userId=${encodeURIComponent(userId)}
    public getUserSecondaryCipNumberTableData(tableRequest: TableRequestDTO, userId: string): Promise<TableResponseDTO<SecondaryCipNumberDTO>> {
        let url = `api/secondaryCipNumbers/userCipNumbers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SecondaryCipNumberDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/${encodeURIComponent(secondaryCipNumberId)}
    public getSecondaryCipNumberDetails(secondaryCipNumberId: string): Promise<GetSecondaryCipNumberDetailsHandler.Result> {
        let url = `api/secondaryCipNumbers/${encodeURIComponent(secondaryCipNumberId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryCipNumberDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/save
    public saveSecondaryCipNumberDetails(saveSecondaryCipNumberDetails: SaveSecondaryCipNumberDetailsHandler.Request): Promise<SaveSecondaryCipNumberDetailsHandler.Result> {
        let url = `api/secondaryCipNumbers/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSecondaryCipNumberDetailsHandler.Result>(saveSecondaryCipNumberDetails, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/deleteSecondaryCipNumber
    public deleteSecondaryCipNumber(deleteSecondaryCipNumber: DeleteSecondaryCipNumberHandler.Request): Promise<DeleteSecondaryCipNumberHandler.Result> {
        let url = `api/secondaryCipNumbers/deleteSecondaryCipNumber`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteSecondaryCipNumberHandler.Result>(deleteSecondaryCipNumber, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/getSecondaryCipNumbers
    public getSecondaryCipNumbers(getSecondaryCipNumbres: GetSecondaryCipNumbresHandler.Request): Promise<GetSecondaryCipNumbresHandler.Result> {
        let url = `api/secondaryCipNumbers/getSecondaryCipNumbers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryCipNumbresHandler.Result>(getSecondaryCipNumbres, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/assignSecondaryCIPNumbers
    public assignSecondaryCIPNumbers(assignUserSecondaryCipNumbers: AssignUserSecondaryCipNumbersHandler.Request): Promise<AssignUserSecondaryCipNumbersHandler.Result> {
        let url = `api/secondaryCipNumbers/assignSecondaryCIPNumbers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AssignUserSecondaryCipNumbersHandler.Result>(assignUserSecondaryCipNumbers, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/unassignSecondaryCIPNumbers
    public unassignSecondaryCIPNumbers(unassignUserSecondaryCipNumber: UnassignUserSecondaryCipNumberHandler.Request): Promise<UnassignUserSecondaryCipNumberHandler.Result> {
        let url = `api/secondaryCipNumbers/unassignSecondaryCIPNumbers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UnassignUserSecondaryCipNumberHandler.Result>(unassignUserSecondaryCipNumber, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/searchForSecondaryCipNumbers
    public searchForSecondaryCipNumbers(searchForSecondaryCipNumbers: SearchForSecondaryCipNumbersHandler.Request): Promise<SearchForSecondaryCipNumbersHandler.Result> {
        let url = `api/secondaryCipNumbers/searchForSecondaryCipNumbers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForSecondaryCipNumbersHandler.Result>(searchForSecondaryCipNumbers, 'post', url, true, false);
    }

    // post: api/secondaryCipNumbers/secondaryCipNumberImporter
    public secondaryCipNumberImporter(secondaryCipNumberImporter: FormData): Promise<any> {
        let url = `api/secondaryCipNumbers/secondaryCipNumberImporter`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(secondaryCipNumberImporter, 'post', url, false, false);
    }

    // get: api/secondaryCipNumbers/secondaryCipNumberTemplate
    public secondaryCipNumberTemplate(): Promise<any> {
        let url = `api/secondaryCipNumbers/secondaryCipNumberTemplate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new SecondaryCipNumberApiService();
export default service;
