import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TermDataTable from '../../../../components/datatables/postSecondary/TermsDataTable';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import HeaderPortal from '../../../../portals/HeaderPortal';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface TermListProps {
}

interface TermListState {
}

class TermListPage extends React.Component<TermListProps & RouteComponentProps<RouteObject>, TermListState> {
  constructor(props: TermListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  private goToConfig = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content>
        <HeaderPortal title="Terms" onBack={this.goToConfig} breadcrumbs={Breadcrumbs.termList()} />

        <Card >
          <TermDataTable />
        </Card>
      </Content>
    );
  }
}

export default withRouter(TermListPage);
