// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SelfStudyDTO from '../models/SelfStudyDTO';
import SelfStudyRankingReportDataTableDTO from '../models/SelfStudyRankingReportDataTableDTO';
import SelfStudyAggregateReportDataTableDTO from '../models/SelfStudyAggregateReportDataTableDTO';
import ProgramConsultantCommentDataTableDTO from '../models/ProgramConsultantCommentDataTableDTO';
import ScenarioReportDataTableDTO from '../models/ScenarioReportDataTableDTO';
import ContactReportDTO from '../models/ContactReportDTO';
import CareerAcademyReportDTO from '../models/CareerAcademyReportDTO';
import ActionPlanReportDTO from '../models/ActionPlanReportDTO';
import * as GenerateSelfStudiesHandler from '../handlerModels/GenerateSelfStudiesHandler';
import * as GetSelfStudiesHandler from '../handlerModels/GetSelfStudiesHandler';
import * as SelfStudyRevokeResponseHandler from '../handlerModels/SelfStudyRevokeResponseHandler';
import * as GetSelfStudyResponseHandler from '../handlerModels/GetSelfStudyResponseHandler';
import * as GetSelfStudyResponseLiteHandler from '../handlerModels/GetSelfStudyResponseLiteHandler';
import * as GetSelfStudyResponsesHandler from '../handlerModels/GetSelfStudyResponsesHandler';
import * as GetSelfStudyWithProgramOverviewHandler from '../handlerModels/GetSelfStudyWithProgramOverviewHandler';
import * as GetSelfStudyWithRankingQuestionHandler from '../handlerModels/GetSelfStudyWithRankingQuestionHandler';
import * as GetSelfStudyWithFreeTextQuestionHandler from '../handlerModels/GetSelfStudyWithFreeTextQuestionHandler';
import * as GetSelfStudyWithNewGoalsHandler from '../handlerModels/GetSelfStudyWithNewGoalsHandler';
import * as GetSelfStudyWithPreviousGoalsHandler from '../handlerModels/GetSelfStudyWithPreviousGoalsHandler';
import * as GetSelfStudyWithConsultantReviewHandler from '../handlerModels/GetSelfStudyWithConsultantReviewHandler';
import * as GetSelfStudyBySelfStudyResponseHandler from '../handlerModels/GetSelfStudyBySelfStudyResponseHandler';
import * as ReopenSelfStudyResponseHandler from '../handlerModels/ReopenSelfStudyResponseHandler';
import * as GetSelfStudyDetailsHandler from '../handlerModels/GetSelfStudyDetailsHandler';
import * as SaveSelfStudyDetailsHandler from '../handlerModels/SaveSelfStudyDetailsHandler';
import * as SaveSelfStudySectionHandler from '../handlerModels/SaveSelfStudySectionHandler';
import * as SaveSelfStudySectionFileHandler from '../handlerModels/SaveSelfStudySectionFileHandler';
import * as RemoveSelfStudySectionFileHandler from '../handlerModels/RemoveSelfStudySectionFileHandler';
import * as GetSelfStudySectionFilesHandler from '../handlerModels/GetSelfStudySectionFilesHandler';
import * as GetSelfStudySectionHandler from '../handlerModels/GetSelfStudySectionHandler';
import * as GetSelfStudySectionBySectionOrderHandler from '../handlerModels/GetSelfStudySectionBySectionOrderHandler';
import * as DeleteSelfStudyHandler from '../handlerModels/DeleteSelfStudyHandler';
import * as SaveSelfStudyResponseUserHandler from '../handlerModels/SaveSelfStudyResponseUserHandler';
import * as GetSelfStudyResponseProgramOverviewHandler from '../handlerModels/GetSelfStudyResponseProgramOverviewHandler';
import * as GetSelfStudyStatsForAcademicYearDashboardHandler from '../handlerModels/GetSelfStudyStatsForAcademicYearDashboardHandler';
import * as SaveSelfStudyDECommentReleaseHandler from '../handlerModels/SaveSelfStudyDECommentReleaseHandler';
import * as SubmitSelfStudyInstructorResponseHandler from '../handlerModels/SubmitSelfStudyInstructorResponseHandler';
import * as ApproveSelfStudyResponseHandler from '../handlerModels/ApproveSelfStudyResponseHandler';
import * as GetActionPlanReportDataHandler from '../handlerModels/GetActionPlanReportDataHandler';
import * as ProgramsForReviewHandler from '../handlerModels/ProgramsForReviewHandler';
import * as GenerateSelfStudiesForProgramReviewHandler from '../handlerModels/GenerateSelfStudiesForProgramReviewHandler';
import * as AssignMissingDERespondersHandler from '../handlerModels/AssignMissingDERespondersHandler';

export class SelfStudyApiService extends BaseApi {

    // post: api/selfStudies?academicYearId=${academicYearId}&districtId=${districtId}&rppIdentifier=${rppIdentifier}
    public getSelfStudyTableData(tableRequest: TableRequestDTO, academicYearId: number, districtId: number, rppIdentifier: number): Promise<TableResponseDTO<SelfStudyDTO>> {
        let url = `api/selfStudies`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }
        if (districtId != null) {
            url += `${prefix}districtId=${districtId}`;
            prefix = '&';
        }
        if (rppIdentifier != null) {
            url += `${prefix}rppIdentifier=${rppIdentifier}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SelfStudyDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/selfStudies/selfStudyRanking?academicYearId=${academicYearId}
    public getSelfStudyReportTableData(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<SelfStudyRankingReportDataTableDTO>> {
        let url = `api/selfStudies/selfStudyRanking`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SelfStudyRankingReportDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/selfStudies/selfStudyAggregate?academicYearId=${academicYearId}
    public getSelfStudyAggregateReportTableData(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<SelfStudyAggregateReportDataTableDTO>> {
        let url = `api/selfStudies/selfStudyAggregate`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SelfStudyAggregateReportDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // get: api/selfStudies/selfStudyAggregateExport?academicYear=${academicYear}
    public getSelfStudyAggregateReportDataTableExport(academicYear: number): Promise<any> {
        let url = `api/selfStudies/selfStudyAggregateExport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/selfStudies/selfStudyRankingExport?academicYear=${academicYear}
    public getSelfStudyRankingReportDataTableExport(academicYear: number): Promise<any> {
        let url = `api/selfStudies/selfStudyRankingExport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // post: api/selfStudies/programConsultantComments?academicYearId=${academicYearId}
    public getProgramConsultantCommentDataTable(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<ProgramConsultantCommentDataTableDTO>> {
        let url = `api/selfStudies/programConsultantComments`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ProgramConsultantCommentDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // get: api/selfStudies/programConsultantCommentsExport?academicYear=${academicYear}
    public getProgramConsultantCommentReportDataTableExport(academicYear: number): Promise<any> {
        let url = `api/selfStudies/programConsultantCommentsExport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // post: api/selfStudies/generateSelfStudies
    public generateSelfStudies(generateSelfStudies: GenerateSelfStudiesHandler.Request): Promise<GenerateSelfStudiesHandler.Result> {
        let url = `api/selfStudies/generateSelfStudies`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GenerateSelfStudiesHandler.Result>(generateSelfStudies, 'post', url, true, false);
    }

    // post: api/selfStudies/get
    public getSelfStudies(getSelfStudies: GetSelfStudiesHandler.Request): Promise<GetSelfStudiesHandler.Result> {
        let url = `api/selfStudies/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudiesHandler.Result>(getSelfStudies, 'post', url, true, false);
    }

    // post: api/selfStudies/revokeResponse
    public revokeSelfStudyResponse(selfStudyRevokeResponse: SelfStudyRevokeResponseHandler.Request): Promise<SelfStudyRevokeResponseHandler.Result> {
        let url = `api/selfStudies/revokeResponse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SelfStudyRevokeResponseHandler.Result>(selfStudyRevokeResponse, 'post', url, true, false);
    }

    // post: api/selfStudies/getResponse
    public getSelfStudyResponse(getSelfStudyResponse: GetSelfStudyResponseHandler.Request): Promise<GetSelfStudyResponseHandler.Result> {
        let url = `api/selfStudies/getResponse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyResponseHandler.Result>(getSelfStudyResponse, 'post', url, true, false);
    }

    // post: api/selfStudies/getResponseLite
    public getSelfStudyResponseLite(getSelfStudyResponseLite: GetSelfStudyResponseLiteHandler.Request): Promise<GetSelfStudyResponseLiteHandler.Result> {
        let url = `api/selfStudies/getResponseLite`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyResponseLiteHandler.Result>(getSelfStudyResponseLite, 'post', url, true, false);
    }

    // post: api/selfStudies/getResponses
    public getSelfStudyResponses(getSelfStudyResponses: GetSelfStudyResponsesHandler.Request): Promise<GetSelfStudyResponsesHandler.Result> {
        let url = `api/selfStudies/getResponses`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyResponsesHandler.Result>(getSelfStudyResponses, 'post', url, true, false);
    }

    // post: api/selfStudies/getSelfStudyWithProgramOverview
    public getSelfStudyWithProgramOverview(getSelfStudyWithProgramOverview: GetSelfStudyWithProgramOverviewHandler.Request): Promise<GetSelfStudyWithProgramOverviewHandler.Result> {
        let url = `api/selfStudies/getSelfStudyWithProgramOverview`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyWithProgramOverviewHandler.Result>(getSelfStudyWithProgramOverview, 'post', url, true, false);
    }

    // post: api/selfStudies/getSelfStudyWithRankingQuestion
    public getSelfStudyWithRankingQuestion(getSelfStudyWithRankingQuestion: GetSelfStudyWithRankingQuestionHandler.Request): Promise<GetSelfStudyWithRankingQuestionHandler.Result> {
        let url = `api/selfStudies/getSelfStudyWithRankingQuestion`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyWithRankingQuestionHandler.Result>(getSelfStudyWithRankingQuestion, 'post', url, true, false);
    }

    // post: api/selfStudies/getSelfStudyWithFreeTextQuestion
    public getSelfStudyWithFreeTextQuestion(getSelfStudyWithFreeTextQuestion: GetSelfStudyWithFreeTextQuestionHandler.Request): Promise<GetSelfStudyWithFreeTextQuestionHandler.Result> {
        let url = `api/selfStudies/getSelfStudyWithFreeTextQuestion`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyWithFreeTextQuestionHandler.Result>(getSelfStudyWithFreeTextQuestion, 'post', url, true, false);
    }

    // post: api/selfStudies/getSelfStudyWithNewGoals
    public getSelfStudyWithNewGoals(getSelfStudyWithNewGoals: GetSelfStudyWithNewGoalsHandler.Request): Promise<GetSelfStudyWithNewGoalsHandler.Result> {
        let url = `api/selfStudies/getSelfStudyWithNewGoals`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyWithNewGoalsHandler.Result>(getSelfStudyWithNewGoals, 'post', url, true, false);
    }

    // post: api/selfStudies/getSelfStudyWithPreviousGoals
    public getSelfStudyWithPreviousGoals(getSelfStudyWithPreviousGoals: GetSelfStudyWithPreviousGoalsHandler.Request): Promise<GetSelfStudyWithPreviousGoalsHandler.Result> {
        let url = `api/selfStudies/getSelfStudyWithPreviousGoals`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyWithPreviousGoalsHandler.Result>(getSelfStudyWithPreviousGoals, 'post', url, true, false);
    }

    // post: api/selfStudies/getSelfStudyWithConsultantReview
    public getSelfStudyWithConsultantReview(getSelfStudyWithConsultantReview: GetSelfStudyWithConsultantReviewHandler.Request): Promise<GetSelfStudyWithConsultantReviewHandler.Result> {
        let url = `api/selfStudies/getSelfStudyWithConsultantReview`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyWithConsultantReviewHandler.Result>(getSelfStudyWithConsultantReview, 'post', url, true, false);
    }

    // post: api/selfStudies/getSelfStudyByResponse
    public getSelfStudyByResponse(getSelfStudyBySelfStudyResponse: GetSelfStudyBySelfStudyResponseHandler.Request): Promise<GetSelfStudyBySelfStudyResponseHandler.Result> {
        let url = `api/selfStudies/getSelfStudyByResponse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyBySelfStudyResponseHandler.Result>(getSelfStudyBySelfStudyResponse, 'post', url, true, false);
    }

    // post: api/selfStudies/reopenSelfStudyResponse
    public reopenSelfStudyResponse(reopenSelfStudyResponse: ReopenSelfStudyResponseHandler.Request): Promise<ReopenSelfStudyResponseHandler.Result> {
        let url = `api/selfStudies/reopenSelfStudyResponse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ReopenSelfStudyResponseHandler.Result>(reopenSelfStudyResponse, 'post', url, true, false);
    }

    // post: api/selfStudies/getDetails
    public getSelfStudyDetails(getSelfStudyDetails: GetSelfStudyDetailsHandler.Request): Promise<GetSelfStudyDetailsHandler.Result> {
        let url = `api/selfStudies/getDetails`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyDetailsHandler.Result>(getSelfStudyDetails, 'post', url, true, false);
    }

    // post: api/selfStudies/save
    public saveSelfStudyDetails(saveSelfStudyDetails: SaveSelfStudyDetailsHandler.Request): Promise<SaveSelfStudyDetailsHandler.Result> {
        let url = `api/selfStudies/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSelfStudyDetailsHandler.Result>(saveSelfStudyDetails, 'post', url, true, false);
    }

    // post: api/selfStudies/saveSection
    public saveSelfStudySection(saveSelfStudySection: SaveSelfStudySectionHandler.Request): Promise<SaveSelfStudySectionHandler.Result> {
        let url = `api/selfStudies/saveSection`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSelfStudySectionHandler.Result>(saveSelfStudySection, 'post', url, true, false);
    }

    // post: api/selfStudies/saveSectionFile
    public saveSelfStudySectionFile(saveSelfStudySectionFile: FormData): Promise<SaveSelfStudySectionFileHandler.Result> {
        let url = `api/selfStudies/saveSectionFile`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSelfStudySectionFileHandler.Result>(saveSelfStudySectionFile, 'post', url, false, false);
    }

    // post: api/selfStudies/removeSectionFile
    public removeSelfStudySectionFile(removeSelfStudySectionFile: RemoveSelfStudySectionFileHandler.Request): Promise<RemoveSelfStudySectionFileHandler.Result> {
        let url = `api/selfStudies/removeSectionFile`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveSelfStudySectionFileHandler.Result>(removeSelfStudySectionFile, 'post', url, true, false);
    }

    // post: api/selfStudies/getSelfStudySectionFiles
    public getSelfStudySectionFiles(getSelfStudySectionFiles: GetSelfStudySectionFilesHandler.Request): Promise<GetSelfStudySectionFilesHandler.Result> {
        let url = `api/selfStudies/getSelfStudySectionFiles`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudySectionFilesHandler.Result>(getSelfStudySectionFiles, 'post', url, true, false);
    }

    // get: api/selfStudies/section/${encodeURIComponent(selfStudySectionId)}
    public getSelfStudySection(selfStudySectionId: string): Promise<GetSelfStudySectionHandler.Result> {
        let url = `api/selfStudies/section/${encodeURIComponent(selfStudySectionId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudySectionHandler.Result>(null, 'get', url, true, false);
    }

    // post: api/selfStudies/getSelfStudySection
    public getSelfStudySectionBySectionOrder(getSelfStudySectionBySectionOrder: GetSelfStudySectionBySectionOrderHandler.Request): Promise<GetSelfStudySectionBySectionOrderHandler.Result> {
        let url = `api/selfStudies/getSelfStudySection`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudySectionBySectionOrderHandler.Result>(getSelfStudySectionBySectionOrder, 'post', url, true, false);
    }

    // post: api/selfStudies/deleteSelfStudy
    public deleteSelfStudy(deleteSelfStudy: DeleteSelfStudyHandler.Request): Promise<DeleteSelfStudyHandler.Result> {
        let url = `api/selfStudies/deleteSelfStudy`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteSelfStudyHandler.Result>(deleteSelfStudy, 'post', url, true, false);
    }

    // post: api/selfStudies/saveResponseUser
    public saveResponseUser(saveSelfStudyResponseUser: SaveSelfStudyResponseUserHandler.Request): Promise<SaveSelfStudyResponseUserHandler.Result> {
        let url = `api/selfStudies/saveResponseUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSelfStudyResponseUserHandler.Result>(saveSelfStudyResponseUser, 'post', url, true, false);
    }

    // post: api/selfStudies/getSelfStudyResponseProgramOverview
    public getSelfStudyResponseProgramOverview(getSelfStudyResponseProgramOverview: GetSelfStudyResponseProgramOverviewHandler.Request): Promise<GetSelfStudyResponseProgramOverviewHandler.Result> {
        let url = `api/selfStudies/getSelfStudyResponseProgramOverview`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyResponseProgramOverviewHandler.Result>(getSelfStudyResponseProgramOverview, 'post', url, true, false);
    }

    // post: api/selfStudies/getDashboardStats
    public getDashboardStats(getSelfStudyStatsForAcademicYearDashboard: GetSelfStudyStatsForAcademicYearDashboardHandler.Request): Promise<GetSelfStudyStatsForAcademicYearDashboardHandler.Result> {
        let url = `api/selfStudies/getDashboardStats`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyStatsForAcademicYearDashboardHandler.Result>(getSelfStudyStatsForAcademicYearDashboard, 'post', url, true, false);
    }

    // post: api/selfStudies/saveSelfStudyDECommentRelease
    public saveSelfStudyDECommentRelease(saveSelfStudyDECommentRelease: SaveSelfStudyDECommentReleaseHandler.Request): Promise<SaveSelfStudyDECommentReleaseHandler.Result> {
        let url = `api/selfStudies/saveSelfStudyDECommentRelease`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSelfStudyDECommentReleaseHandler.Result>(saveSelfStudyDECommentRelease, 'post', url, true, false);
    }

    // post: api/selfStudies/submitSelfStudyInstructorResponse
    public submitSelfStudyInstructorResponse(submitSelfStudyInstructorResponse: SubmitSelfStudyInstructorResponseHandler.Request): Promise<SubmitSelfStudyInstructorResponseHandler.Result> {
        let url = `api/selfStudies/submitSelfStudyInstructorResponse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitSelfStudyInstructorResponseHandler.Result>(submitSelfStudyInstructorResponse, 'post', url, true, false);
    }

    // post: api/selfStudies/approveSelfStudyResponseHandler
    public approveSelfStudyResponseHandler(approveSelfStudyResponse: ApproveSelfStudyResponseHandler.Request): Promise<ApproveSelfStudyResponseHandler.Result> {
        let url = `api/selfStudies/approveSelfStudyResponseHandler`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ApproveSelfStudyResponseHandler.Result>(approveSelfStudyResponse, 'post', url, true, false);
    }

    // post: api/selfStudies/getScenarioReport?academicYearId=${academicYearId}
    public getScenarioReport(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<ScenarioReportDataTableDTO>> {
        let url = `api/selfStudies/getScenarioReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ScenarioReportDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/selfStudies/exportScenarioReport?academicYearId=${academicYearId}
    public getScenarioReportExport(tableRequest: TableRequestDTO, academicYearId: number): Promise<string> {
        let url = `api/selfStudies/exportScenarioReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/selfStudies/getContactsReport?academicYearId=${academicYearId}
    public getContactsReport(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<ContactReportDTO>> {
        let url = `api/selfStudies/getContactsReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ContactReportDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/selfStudies/exportContactsReport?academicYearId=${academicYearId}
    public getContactsReportExport(tableRequest: TableRequestDTO, academicYearId: number): Promise<string> {
        let url = `api/selfStudies/exportContactsReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/selfStudies/getCareerAcademyReport?academicYearId=${academicYearId}
    public getCareerAcademyReport(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<CareerAcademyReportDTO>> {
        let url = `api/selfStudies/getCareerAcademyReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<CareerAcademyReportDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/selfStudies/exportCareerAcademyReport?academicYearId=${academicYearId}
    public getCareerAcademyReportExport(tableRequest: TableRequestDTO, academicYearId: number): Promise<string> {
        let url = `api/selfStudies/exportCareerAcademyReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/selfStudies/getActionPlanReport?academicYearId=${academicYearId}
    public getActionPlanReport(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<ActionPlanReportDTO>> {
        let url = `api/selfStudies/getActionPlanReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ActionPlanReportDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/selfStudies/getActionPlanRows
    public getActionPlanRows(getActionPlanReportData: GetActionPlanReportDataHandler.Request): Promise<GetActionPlanReportDataHandler.Result> {
        let url = `api/selfStudies/getActionPlanRows`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetActionPlanReportDataHandler.Result>(getActionPlanReportData, 'post', url, true, false);
    }

    // get: api/selfStudies/actionPlanReportExport?academicYear=${academicYear}
    public getActionPlanReportDataTableExport(academicYear: number): Promise<any> {
        let url = `api/selfStudies/actionPlanReportExport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/selfStudies/programMasterReportExport?academicYear=${academicYear}
    public getProgramMasterReportDataTableExport(academicYear: number): Promise<any> {
        let url = `api/selfStudies/programMasterReportExport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // post: api/selfStudies/ProgramsForReviewStats
    public programsForReviewStats(programsForReviewStats: ProgramsForReviewHandler.Request): Promise<ProgramsForReviewHandler.Result> {
        let url = `api/selfStudies/ProgramsForReviewStats`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ProgramsForReviewHandler.Result>(programsForReviewStats, 'post', url, true, false);
    }

    // post: api/selfStudies/generateSelfStudiesForProgramReview
    public generateSelfStudiesForProgramReview(generateSelfStudiesForProgramReview: GenerateSelfStudiesForProgramReviewHandler.Request): Promise<GenerateSelfStudiesForProgramReviewHandler.Result> {
        let url = `api/selfStudies/generateSelfStudiesForProgramReview`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GenerateSelfStudiesForProgramReviewHandler.Result>(generateSelfStudiesForProgramReview, 'post', url, true, false);
    }

    // post: api/selfStudies/assignMissingDEConsultants
    public assignMissingDEConsultants(assignMissingDEResponders: AssignMissingDERespondersHandler.Request): Promise<AssignMissingDERespondersHandler.Result> {
        let url = `api/selfStudies/assignMissingDEConsultants`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AssignMissingDERespondersHandler.Result>(assignMissingDEResponders, 'post', url, true, false);
    }
}
var service = new SelfStudyApiService();
export default service;
