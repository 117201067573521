import * as React from 'react';
import { useState } from 'react';
import SelfStudyApiService from '../../../api/SelfStudyApiService';
import ScenarioReportDataTableDTO from '../../../models/ScenarioReportDataTableDTO';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import FileDownload from '../../../utils/FileDownload';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';

interface ScenarioReportDataTableProps {
  academicYear?: number;
}

function ScenarioReportDataTable(props: ScenarioReportDataTableProps) {
  let _dataTable: DataTable<ScenarioReportDataTableDTO> | undefined = undefined;

  const [exporting, setExporting] = useState(false);
  const [institutionOptions, setInstitutionOptions] = useState([] as TableFilterOption[]);

  React.useEffect(() => {
    TableFilterUtil.Institutions().then(x => setInstitutionOptions(x));
    triggerRefresh();
  }, [props.academicYear]);


  const triggerRefresh = () => {
    _dataTable?.refresh();
  }

  const getColumnDefinitions = () => {
    return [
      {
        title: 'Regional Planning Partner',
        dataIndex: ScenarioReportDataTableDTO.rpp,
        filterType: FilterType.Text,
        sorter: true,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.rpp;
        },
      },
      {
        title: 'District',
        dataIndex: ScenarioReportDataTableDTO.district,
        filterType: FilterType.Text,
        sorter: true,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.district;
        },
      },
      {
        title: 'CIP Number',
        dataIndex: ScenarioReportDataTableDTO.cipNumber,
        filterType: FilterType.Text,
        sorter: true,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.cipNumber;
        },
      },
      {
        title: 'Program',
        dataIndex: ScenarioReportDataTableDTO.program,
        filterType: FilterType.Text,
        sorter: true,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.program;
        },
      },
      {
        title: 'Scenario',
        dataIndex: ScenarioReportDataTableDTO.scenario,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.scenario
        }
      },
      {
        title: 'Local Course Number',
        dataIndex: ScenarioReportDataTableDTO.localCourseNumber,
        sorter: true,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.localCourseNumber
        },
        filterType: FilterType.Text
      },
      {
        title: 'Course Name',
        dataIndex: ScenarioReportDataTableDTO.courseName,
        sorter: true,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.courseName
        },
        filterType: FilterType.Text
      },
      {
        title: 'SCED Id',
        dataIndex: ScenarioReportDataTableDTO.scedId,
        sorter: true,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.scedId
        },
        filterType: FilterType.Text
      },
      {
        title: 'SCED G or C',
        dataIndex: ScenarioReportDataTableDTO.scedGorC,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.scedGorC
        }
      },
      {
        title: 'SCED Units',
        dataIndex: ScenarioReportDataTableDTO.scedUnits,
        sorter: true,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.scedUnits
        },
        filterType: FilterType.Text
      },
      {
        title: 'Community Collage',
        dataIndex: ScenarioReportDataTableDTO.communityCollege,
        sorter: true,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.communityCollege
        },
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: institutionOptions
      },
      {
        title: 'CCN',
        dataIndex: ScenarioReportDataTableDTO.ccn,
        render: (data: string, row: ScenarioReportDataTableDTO) => {
          return row.ccn
        }
      },
    ] as DataTableColumnProps<any>[];
  };

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  actionButtons.push(
    DataTableButtonUtil.Export(
      (tableRequest: TableRequestDTO) => {
        return SelfStudyApiService.getScenarioReportExport(tableRequest, props.academicYear ?? 0).then(file => {
          setExporting(false);
          FileDownload.downloadBase64StringAsFile(`Scenario_Report_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
        });
      },
      exporting
    )
  );

  return (<>
    <DataTable
      // @ts-ignore
      ref={(element: any) => (_dataTable = element)}
      serverSide={true}
      tableProps={{
        rowKey: 'id',
        scroll: { x: 500 }
      }}
      globalSearch={true}
      buttonBar={actionButtons}
      columns={getColumnDefinitions()}
      fetchData={{
        fetch: (requestState: TableRequestDTO) => SelfStudyApiService.getScenarioReport(requestState, props.academicYear ?? 0)
      }}
      stateSaving={{ enabled: true, tableUniqueKey: 'scenario_report_list' }} />
  </>
  );
}

export default ScenarioReportDataTable;
