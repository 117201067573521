import { Modal, ModalProps } from 'antd';
import React from 'react';
import Guid from '../../utils/Guid';
import RegionalPlanningPartnerDetailsForm from '../forms/secondary/RegionalPlanningPartnerDetailsForm';

interface RegionalPlanningPartnerDetailsModalProps extends ModalProps {
  regionalPlanningPartnerId: string;
  academicYear: number;
  onSave?: (rppId: string) => void;
}

function RegionalPlanningPartnerDetailsModal(props: RegionalPlanningPartnerDetailsModalProps) {
  const _rrpFormRef = React.createRef<RegionalPlanningPartnerDetailsForm>();

  const isNew = props.regionalPlanningPartnerId == Guid.Empty();

  const submitSaveRegionalPlanningPartner = () => {
    _rrpFormRef.current?.submit()
  }

  return (
    <Modal
      visible={true}
      title={isNew ? 'New Regional Planning Partner' : 'Edit Regional Planning Partner'}
      okText='Save'
      onOk={submitSaveRegionalPlanningPartner}
      okButtonProps={{ htmlType: 'submit' }}
      onCancel={props.onCancel}
      destroyOnClose={true}>
      <RegionalPlanningPartnerDetailsForm
        fromAcademicYear={true}
        ref={_rrpFormRef}
        regionId={props.regionalPlanningPartnerId}
        isEditing={true}
        onSave={props.onSave}
        isNew={isNew}
        academicYear={props.academicYear} />
    </Modal>
  );
}

export default RegionalPlanningPartnerDetailsModal;