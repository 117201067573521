import * as React from 'react';
import { Prompt } from 'react-router';

interface PageStayPromptProps {
  when?: boolean | (() => boolean);
  message?: string;
  rootRoute?: string;
}

class PageStayPrompt extends React.Component<PageStayPromptProps> {
  constructor(props: PageStayPromptProps) {
    super(props);

    this.state = {};
  }

  shouldPrompt = (e?: any) => {
    // If navigating under the same page stay prompt -- do not prompt
    if (this.props.rootRoute && e && e.pathname && (e.pathname as string).indexOf(this.props.rootRoute) >= 0) {
      return true;
    }

    let when = false;
    const whenProp = this.props.when;
    if (typeof whenProp === 'function') {
      when = whenProp();
    } else {
      when = whenProp ? whenProp : false;
    }

    if (when) {
      return this.props.message || 'Unsaved Changes! Are you sure you want to leave?';
    }

    return true;
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.unloadEventHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.unloadEventHandler);
  }

  unloadEventHandler = (e: BeforeUnloadEvent) => {
    if (this.shouldPrompt() !== true) {
      e.stopImmediatePropagation();
      e.returnValue = true;
    }
  };

  render() {
    return <Prompt message={this.shouldPrompt} />;
  }
}

export default PageStayPrompt;
