import * as React from 'react';
import TypeOfProgramApiService from '../../../api/TypeOfProgramApiService';
import Routes from '../../../config/Routes';
import FeatureFlag from '../../../consts/FeatureFlag';
import TypeOfProgramDataTableDTO from '../../../models/TypeOfProgramDataTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import BaseDataTableState from '../../../redux/bases/BaseDataTableState';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import DataTable, { ColumnType, DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import DataTableCellRendererUtil from '../core/DataTableCellRendererUtil';
import FileDownload from '../../../utils/FileDownload';
import DateTimeUtil from '../../../utils/DateTimeUtil';

interface TypeOfProgramDataTableProps extends BaseFormProps {
}

interface TypeOfProgramDataTableState extends BaseDataTableState<TypeOfProgramDataTableDTO>, BaseFormState {
}

class TypeOfProgramDataTable extends React.Component<TypeOfProgramDataTableProps, TypeOfProgramDataTableState> {
  private _dataTable: DataTable<TypeOfProgramDataTableDTO> | undefined;

  constructor(props: TypeOfProgramDataTableProps) {
    super(props);
    this.state = {
    };

  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Code',
        dataIndex: TypeOfProgramDataTableDTO.code,
        sorter: true,
        filterType: FilterType.Text,
        width: 125
      },
      {
        title: 'Name',
        dataIndex: TypeOfProgramDataTableDTO.name,
        sorter: true,
        filterType: FilterType.Text
      },
      {
        title: 'APS General Education',
        dataIndex: TypeOfProgramDataTableDTO.apsGeneralEducation,
        filterType: FilterType.BooleanRadio,
        columnType: ColumnType.Boolean,
        render: DataTableCellRendererUtil.BooleanYesEmpty,
        sorter: false,
        width: 175
      }
    ] as DataTableColumnProps<any>[];
  };

  private openTypeOfProgram = (record: TypeOfProgramDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.TYPE_OF_PROGRAM_DETAILS, { id: record.id }, {}));
    }
  }

  private addTypeOfProgram = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_TYPE_OF_PROGRAM));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return TypeOfProgramApiService.getTypeOfProgramDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Type_Of_Program_${DateTimeUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_TYPE_OF_PROGRAM])) {
      actionButtons.push(DataTableButtonUtil.Default('New Type Of Program', () => this.addTypeOfProgram()));
    }

    return (
      <>
        <DataTable
          ref={(element: any) => (this._dataTable = element)}
          onRowClick={this.openTypeOfProgram}
          serverSide={true}
          tableProps={{
            rowKey: 'id',
            scroll: { x: 500 }
          }}
          globalSearch={true}
          buttonBar={actionButtons}
          columns={this.getColumnDefinitions()}
          fetchData={{
            fetch: (requestState: TableRequestDTO) => TypeOfProgramApiService.getTypeOfProgramTableData(requestState)
          }}
          stateSaving={{ enabled: true, tableUniqueKey: 'type_of_program_list' }} />
      </>
    );
  }
}

export default TypeOfProgramDataTable;
