import { Button, Row, Steps } from 'antd';
import * as React from 'react';
import Routes from '../../../../config/Routes';
import AcademicYearStatusType from '../../../../consts/AcademicYearStatusType';
import FeatureFlag from '../../../../consts/FeatureFlag';
import AcademicYearDTO from '../../../../models/AcademicYearDTO';
import BaseFormProps from '../../../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../../../utils/AuthorizationUtil';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface ConfirmProgramsStepProps extends BaseFormProps {
  academicYear: AcademicYearDTO;
  compactView?: boolean;
}

const finishedStatusTypes = [
  AcademicYearStatusType.CLOSED,
  AcademicYearStatusType.REVIEWSELFSTUDYPROGRAMS,
  AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS,
  AcademicYearStatusType.SELFSTUDYPREPARATION
]

export default function ConfirmProgramsStep(props: ConfirmProgramsStepProps) {
  const active = props.academicYear?.academicYearStatusTypeId === AcademicYearStatusType.SELFSTUDYSUBMITTED;
  const finished = finishedStatusTypes.indexOf(props.academicYear.academicYearStatusTypeId) >= 0

  const handleConfirmSelfStudies = () => {
    HistoryUtil.push(Routes.generate(Routes.SELF_STUDIES_REVIEW, { academicYearId: props.academicYear.id }, {}));
  }

  const renderContent = () => {
    if (active && props.compactView === false && AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM_REVIEW, FeatureFlag.CONFIG_ACADEMIC_YEAR]) && props.academicYear.academicYearStatusTypeId == AcademicYearStatusType.SELFSTUDYSUBMITTED) {
      return <Row>
        <Button title='Review Programs' type={'primary'}
          onClick={() => handleConfirmSelfStudies()}>Review Programs</Button>
      </Row>;
    }
  }

  if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_PROGRAM_REVIEW, FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
    return <Steps.Step status={active ? 'process' : finished ? 'finish' : 'wait'} title='Confirm Programs for Review' description={renderContent()} />;
  }

  return null;
}