import * as React from 'react';
import RacialEthnicGroupApiService from '../../api/RacialEthnicGroupApiService';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import RacialEthnicGroupDataTableDTO from '../../models/RacialEthnicGroupDataTableDTO';
import RacialEthnicGroupDTO from '../../models/RacialEthnicGroupDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import BaseDataTableState from '../../redux/bases/BaseDataTableState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import DateUtil from '../../utils/DateTimeUtil';
import FileDownload from '../../utils/FileDownload';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from './core/DataTable';
import DataTableButtonUtil from './core/DataTableButtonUtil';

interface RacialEthnicGroupDataTableProps {
}

interface RacialEthnicGroupDataTableState extends BaseDataTableState<RacialEthnicGroupDTO> {
}

class RacialEthnicGroupDataTable extends React.Component<RacialEthnicGroupDataTableProps, RacialEthnicGroupDataTableState> {
  private _dataTable: DataTable<RacialEthnicGroupDTO> | undefined;

  constructor(props: RacialEthnicGroupDataTableProps) {
    super(props);
    this.state = {
    };
  }

  private getColumnDefinitions = () => {
    return [
      {
        title: 'Name',
        dataIndex: RacialEthnicGroupDataTableDTO.name,
        sorter: true,
      }
    ] as DataTableColumnProps<any>[];
  };

  private openRacialEthnicGroup = (record: RacialEthnicGroupDataTableDTO) => {
    if (record.id) {
      HistoryUtil.push(Routes.generate(Routes.RACIAL_ETHNIC_GROUP_DETAILS, { id: record.id }, {}));
    }
  }

  private addRacialEthnicGroup = () => {
    HistoryUtil.push(Routes.generate(Routes.NEW_RACIAL_ETHNIC_GROUP));
  }

  render() {
    const actionButtons = [];

    actionButtons.push(DataTableButtonUtil.Reset());
    actionButtons.push(
      DataTableButtonUtil.Export(
        (tableRequest: TableRequestDTO) => {
          this.setState({ exporting: true });
          return RacialEthnicGroupApiService.getRacialEthnicGroupDataTableExport(tableRequest).then(file => {
            this.setState({ exporting: false });
            FileDownload.downloadBase64StringAsFile(`Racial_Ethinic_Group_${DateUtil.getDateStamp()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', file ?? undefined);
          });
        },
        this.state.exporting
      )
    );

    if (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RACIAL_EHTNIC_GROUPS])) {
      actionButtons.push(DataTableButtonUtil.Default('New Racial Ethnic Group', () => this.addRacialEthnicGroup()));
    }

    return (
      <DataTable
        ref={(element: any) => (this._dataTable = element)}
        onRowClick={this.openRacialEthnicGroup}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={this.getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => RacialEthnicGroupApiService.getRacialEthnicGroupTableData(requestState)
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'racial_ethnic_group_list' }} />
    );
  }
}

export default RacialEthnicGroupDataTable;
