// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SearchForExistitngNewCourseChangeRequestHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import NewCourseChangeRequestDTO from '../models/NewCourseChangeRequestDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    disciplineId: string | null | null;
    catalogNumber: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            disciplineId: null,
            catalogNumber: "",
        },
        initValues);
    },
    disciplineId: 'disciplineId',
    catalogNumber: 'catalogNumber',
}

interface Result extends ResultDTO {
    newCourseChangeRequest: NewCourseChangeRequestDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            newCourseChangeRequest: [],
        },
        initValues);
    },
    newCourseChangeRequest: 'newCourseChangeRequest',
}

export { Request, Result };