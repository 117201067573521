import { Card, Col, Row, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DataTable from '../../../components/datatables/core/DataTable';
import ChangeRequestDataTable from '../../../components/datatables/postSecondary/ChangeRequestsDataTable';
import BulkCourseChangeRequestForm from '../../../components/forms/postSecondary/BulkCourseChangeRequestForm';
import NewChangeRequestForm from '../../../components/forms/postSecondary/NewChangeRequestForm';
import Breadcrumbs from '../../../config/Breadcrumbs';
import { ColumnWidths } from '../../../config/ColumnWidths';
import FeatureFlag from '../../../consts/FeatureFlag';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import InstitutionDTO from '../../../models/InstitutionDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';

interface ChangeRequestListState {
}

interface ChangeRequestListProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class ChangeRequestListPage extends React.Component<ChangeRequestListProps & RouteComponentProps<RouteObject>, ChangeRequestListState> {
  private _dataTable: DataTable<ChangeRequestDTO> | undefined;

  constructor(props: ChangeRequestListProps & RouteComponentProps<RouteObject>) {
    super(props);
  }

  render() {
    return (
      <Content >
        <HeaderPortal title='Change Requests' breadcrumbs={Breadcrumbs.changeRequestList()} />
        <Space direction="vertical" size="large">
          <Card title="Change Requests">
            <Space direction='vertical' size="large">
              {this.renderYourChangeRequests()}
              {this.renderInstitutionChangeRequests()}
              {this.renderAllChangeRequests()}
            </Space>
          </Card>
        </Space>
      </Content>
    );
  }

  renderYourChangeRequests() {
    if ((this.props.selectedInstitution?.id ?? Guid.Empty()) != Guid.Empty() && AuthorizationUtil.isAuthorized([
      FeatureFlag.CREATE_ADMINISTRATIVE_CHANGE_REQUEST,
      FeatureFlag.CREATE_NEW_COURSE_CHANGE_REQUEST,
      FeatureFlag.CREATE_COURSE_DEACTIVATION_CHANGE_REQUEST,
      FeatureFlag.CREATE_MODIFY_COURSE_CHANGE_REQUEST,
      FeatureFlag.CREATE_NEW_DISCIPLINE_CHANGE_REQUEST,
      FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST,
      FeatureFlag.CREATE_PROGRAM_DEACTIVATION_REQUEST,
      FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST])) {
      return (
        <Space direction='vertical'>
          <Typography.Title level={4}>Submit Change Requests</Typography.Title>
          <Row gutter={[16, 16]}>
            <Col {...ColumnWidths.HALF} >
              <NewChangeRequestForm institutionId={this.props.selectedInstitution?.id ?? Guid.Empty()} />
            </Col>
            <Col {...ColumnWidths.HALF} >
              <BulkCourseChangeRequestForm institutionId={this.props.selectedInstitution?.id ?? Guid.Empty()} />
            </Col>
          </Row>
          <Typography.Title level={4}>Your Change Requests</Typography.Title>
          <ChangeRequestDataTable userId={this.props.currentUser?.userId ?? null} institution={this.props.selectedInstitution} />
        </Space>
      );
    }
  }

  renderInstitutionChangeRequests() {
    if ((this.props.selectedInstitution?.id ?? Guid.Empty()) != Guid.Empty() && AuthorizationUtil.isAuthorized([
      FeatureFlag.CREATE_ADMINISTRATIVE_CHANGE_REQUEST,
      FeatureFlag.CREATE_NEW_COURSE_CHANGE_REQUEST,
      FeatureFlag.CREATE_COURSE_DEACTIVATION_CHANGE_REQUEST,
      FeatureFlag.CREATE_MODIFY_COURSE_CHANGE_REQUEST,
      FeatureFlag.CREATE_NEW_DISCIPLINE_CHANGE_REQUEST,
      FeatureFlag.CREATE_NOTICE_OF_INTENT_REQUEST,
      FeatureFlag.CREATE_PROGRAM_DEACTIVATION_REQUEST,
      FeatureFlag.CREATE_PROGRAM_MODIFICATION_CHANGE_REQUEST,
      FeatureFlag.VIEW_CHANGE_REQUEST])) {
      return (
        <div>
          <Typography.Title level={4}>{this.props.selectedInstitution?.name} Change Request</Typography.Title>
          <ChangeRequestDataTable institution={this.props.selectedInstitution} />
        </div>
      );
    }
  }

  renderAllChangeRequests() {
    if (this.props.selectedInstitution == null && AuthorizationUtil.isAuthorized([
      FeatureFlag.APPROVE_CHANGE_REQUEST,
      FeatureFlag.VIEW_CHANGE_REQUEST])) {
      return (
        <ChangeRequestDataTable />
      );
    }
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ChangeRequestListPage));
