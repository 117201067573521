// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import YearTermDTO from './YearTermDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CipNumberDetailDTO extends EntitySelectorDTO { 
	id: string | null;
	cipNumberId: string | null;
	title: string | null;
	install: YearTermDTO | null;
	retire: YearTermDTO | null;
	twoDigit: string | null;
	fourDigit: string | null;
	sixDigit: string | null;
	definition: string | null;
	display: string | null;
	transferMajor: boolean;
	nonTransferMajor: boolean;
	friendlyDisplay: string | null;
}

const CipNumberDetailDTO = {
    className: 'CipNumberDetailDTO',
    create: (initValues?: Partial<CipNumberDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			cipNumberId: '00000000-0000-0000-0000-000000000000',
			title: "",
			install: null,
			retire: null,
			twoDigit: "",
			fourDigit: "",
			sixDigit: "",
			definition: "",
			display: "",
			transferMajor: false,
			nonTransferMajor: false,
			friendlyDisplay: "",
        },
        initValues);
    },
	id: 'id',
	cipNumberId: 'cipNumberId',
	title: 'title',
	install: 'install',
	retire: 'retire',
	twoDigit: 'twoDigit',
	fourDigit: 'fourDigit',
	sixDigit: 'sixDigit',
	definition: 'definition',
	display: 'display',
	transferMajor: 'transferMajor',
	nonTransferMajor: 'nonTransferMajor',
	friendlyDisplay: 'friendlyDisplay',
};

export default CipNumberDetailDTO;