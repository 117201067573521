import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import TransferMajorGeneralEducationTypeApiService from '../../../api/TransferMajorGeneralEducationTypeApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetTransferMajorGeneralEducationTypeDetailsHandler from '../../../handlerModels/GetTransferMajorGeneralEducationTypeDetailsHandler';
import * as SaveTransferMajorGeneralEducationTypeDetailsHandler from '../../../handlerModels/SaveTransferMajorGeneralEducationTypeDetailsHandler';
import TransferMajorGeneralEducationTypeDTO from '../../../models/TransferMajorGeneralEducationTypeDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface TransferMajorGeneralEducationTypeDetailsFormState extends BaseFormState {
  transferMajorGeneralEducationTypeId: number;
  transferMajorGeneralEducationType: TransferMajorGeneralEducationTypeDTO | null;
}

interface TransferMajorGeneralEducationTypeDetailsFormProps extends BaseFormProps {
  transferMajorGeneralEducationTypeId: number;
  onSave?: (transferMajorGeneralEducationTypeId: number) => void;
}

class TransferMajorGeneralEducationTypeDetailsForm extends React.Component<TransferMajorGeneralEducationTypeDetailsFormProps, TransferMajorGeneralEducationTypeDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(TransferMajorGeneralEducationTypeDTO.name, {
      required: true,
      name: TransferMajorGeneralEducationTypeDTO.name,
      label: 'Name',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.maxLength(50)
      ]
    });

  constructor(props: TransferMajorGeneralEducationTypeDetailsFormProps) {
    super(props);

    this.state = {
      transferMajorGeneralEducationTypeId: 0,
      transferMajorGeneralEducationType: TransferMajorGeneralEducationTypeDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadTransferMajorGeneralEducationType();
  }

  componentDidUpdate(prevProps: TransferMajorGeneralEducationTypeDetailsFormProps) {
    if (this.props.transferMajorGeneralEducationTypeId && this.props.transferMajorGeneralEducationTypeId != prevProps.transferMajorGeneralEducationTypeId) {
      this.setState({ transferMajorGeneralEducationTypeId: this.props.transferMajorGeneralEducationTypeId });
      this.loadTransferMajorGeneralEducationType();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadTransferMajorGeneralEducationType() {
    if (this.props.transferMajorGeneralEducationTypeId == 0) {
      this.loadNew();
    }
    else {
      this.loadExisting();
    }
  }

  private loadExisting = () => {
    if (this.props.transferMajorGeneralEducationTypeId) {
      TransferMajorGeneralEducationTypeApiService.getTransferMajorGeneralEducationTypeDetails(this.props.transferMajorGeneralEducationTypeId)
        .then((results: GetTransferMajorGeneralEducationTypeDetailsHandler.Result) => {
          if (results && results.transferMajorGeneralEducationType) {
            this.setState({ transferMajorGeneralEducationTypeId: results.transferMajorGeneralEducationType.id, transferMajorGeneralEducationType: results.transferMajorGeneralEducationType, loading: false });
          }

          this.resetForm();
        }).catch(() => {
          this.setState({ error: true });
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadNew = () => {
    this.setState({ transferMajorGeneralEducationType: TransferMajorGeneralEducationTypeDTO.create(), loading: false });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveTransferMajorGeneralEducationTypeDetailsHandler.Request.create({
      transferMajorGeneralEducationTypeId: this.state.transferMajorGeneralEducationTypeId,
      transferMajorGeneralEducationType: TransferMajorGeneralEducationTypeDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    TransferMajorGeneralEducationTypeApiService.saveTransferMajorGeneralEducationTypeDetails(request)
      .then((result: SaveTransferMajorGeneralEducationTypeDetailsHandler.Result) => {
        this.setState({
          submitted: true,
          transferMajorGeneralEducationType: result?.transferMajorGeneralEducationType,
        });

        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave && result.transferMajorGeneralEducationType?.id) {
            this.props.onSave(result.transferMajorGeneralEducationType.id);
            this.resetForm();
          }

          LookupsUtil.invalidateCache(TransferMajorGeneralEducationTypeDTO.className);
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('General Education Type could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('General Education Type could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit} requiredMark={true}>
          <FormItem
            {...this._formItems.get(TransferMajorGeneralEducationTypeDTO.name)}
            initialValue={this.state.transferMajorGeneralEducationType?.name}
            {...ValidationUtil.getValidation(TransferMajorGeneralEducationTypeDTO.name, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space>
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE]}>
          <Space direction={'horizontal'} >
            <SaveButton saving={this.state.saving} />
            <ResetButton onConfirm={this.resetForm} disabled={!this.state.altered} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error && this.state.submitted) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default TransferMajorGeneralEducationTypeDetailsForm;
