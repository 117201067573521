import { Card, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AreaEducationAgencyDataTable from '../../../components/datatables/secondary/AreaEducationAgencyDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import Routes from '../../../config/Routes';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';
import ConfirmAndContinueButton from '../../../components/buttons/ConfirmAndContinueButton';
import DataReviewLayout from '../../../components/secondary/DataReviewLayout';

interface AreaEducationAgencyListPageState {
  academicYear: number;
}

interface AreaEducationAgencyListPageProps {
}

class DataReviewAreaEducationAgencyListPage extends React.Component<AreaEducationAgencyListPageProps & RouteComponentProps<RouteObject>, AreaEducationAgencyListPageState> {

  constructor(props: AreaEducationAgencyListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      academicYear: 0
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'academicYearId');

    if (this.state.academicYear != Number.parseInt(id)) {
      this.setState({ academicYear: Number.parseInt(id) });
    }
  }

  private onContinue = () => {
    HistoryUtil.push(Routes.generate(Routes.DATA_REVIEW_SERVICE_AREAS, { academicYearId: this.state.academicYear }, {}));
  }

  render() {
    const academicYear = this.state.academicYear == 0 ? 0 : this.state.academicYear;

    if (academicYear != 0) {
      return (
        <Content >
          <DataReviewLayout breadcrumbs={Breadcrumbs.academicYearDataReview(academicYear)} stepNumber={0} academicYear={academicYear}>
            <Card title="Area Education Agencies">
              <Space direction='vertical' size="middle">
                <AreaEducationAgencyDataTable academicYear={this.state.academicYear} />
              </Space>
              <ConfirmAndContinueButton onClick={this.onContinue} />
            </Card>
          </DataReviewLayout>
        </Content>
      );
    }

    return <Skeleton />;
  }
}

export default withRouter(DataReviewAreaEducationAgencyListPage);
