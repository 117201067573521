import { Button, message } from 'antd';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal';
import * as React from 'react';
import { useState } from 'react';
import DistrictApiService from '../../../api/DistrictApiService';
import * as SaveDistrictContactHandler from '../../../handlerModels/SaveDistrictContactHandler';
import * as DeleteDistrictContactHandler from '../../../handlerModels/DeleteDistrictContactHandler';
import FeatureFlag from '../../../consts/FeatureFlag';
import DistrictContactDTO from '../../../models/DistrictContactDTO';
import AuthorizationUtil from '../../../utils/AuthorizationUtil';
import Guid from '../../../utils/Guid';
import DistrictContactDetailsForm from '../../forms/secondary/DistrictContactDetailsForm';
import DataTable, { DataTableColumnProps, FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import { DeleteOutlined, DownloadOutlined, FormOutlined } from '@ant-design/icons';
import DistrictContactImporter from '../../secondary/DistrictContactImporter';
import Routes from '../../../config/Routes';
import Validator from '../../../models/Validator';

interface DistrictContactsDataTableProps {
  districtId?: string;
  academicYear: number;
  fromDataReview?: boolean;
}

function DistrictContactsDataTable(props: DistrictContactsDataTableProps) {
  let _dataTable: DataTable<DistrictContactDTO> | undefined = undefined;
  const [modalDistrictContact, setModalDistrictContact] = useState<DistrictContactDTO | undefined>(undefined);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({} as ({ [key: string]: Validator[]; } | null));
  const _districtContactDetailsFormRef = React.useRef<typeof DistrictContactDetailsForm>();
  const _formRef = React.useRef<any>();
  const _districtContactImporterRef = React.createRef<DistrictContactImporter>();

  const getColumnDefinitions = () => {
    const columns = [
      {
        title: 'Full Name',
        dataIndex: DistrictContactDTO.fullName,
        sorter: true,
        render: (data: string, row: DistrictContactDTO) => {
          return row.fullName;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Email',
        dataIndex: DistrictContactDTO.email,
        render: (data: string, row: DistrictContactDTO) => {
          return row.email;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Title',
        dataIndex: DistrictContactDTO.title,
        sorter: true,
        render: (data: string, row: DistrictContactDTO) => {
          return row.title;
        },
        filterType: FilterType.Text
      },
      {
        title: 'District',
        dataIndex: DistrictContactDTO.districtId,
        sorter: true,
        render: (data: string, row: DistrictContactDTO) => {
          return row.district?.display;
        },
        filterType: FilterType.Text
      },
      {
        title: 'Actions',
        align: 'center',
        dataIndex: DistrictContactDTO.id,
        sorter: false,
        render: (data: string, row: DistrictContactDTO) => {
          if (AuthorizationUtil.isAuthorized([FeatureFlag.CONFIG_ACADEMIC_YEAR])) {
            return <>
              <Button type="link" onClick={() => openAddEditModal(row)}><FormOutlined /></Button>
              <Button type="link" onClick={() => promptConfirmRemove(row.id)}><DeleteOutlined /></Button>
            </>
          }
        },
        width: 50
      }

    ] as DataTableColumnProps<any>[];

    return columns;
  };

  const promptConfirmRemove = (districtContactId: string | null) => {
    if (districtContactId == null) {
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this District Contact?',
      okText: 'Delete',
      onOk: () => deleteDistrictContact(districtContactId),
      okButtonProps: { type: 'primary', danger: true }
    });
  }

  const deleteDistrictContact = (districtContactId: string) => {
    const request = DeleteDistrictContactHandler.Request.create({
      districtContactId: districtContactId
    });

    DistrictApiService.deleteDistrictContact(request)
      .then((result: DeleteDistrictContactHandler.Result | null) => {
        if (result?.succeeded === true) {
          message.success('District Contact was removed.');
          _dataTable?.refresh();
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch(() => {
        message.error('District Contact could not be removed.');
      });
  }

  const openAddEditModal = (contact: DistrictContactDTO | undefined) => {
    setModalDistrictContact(contact);
    setTimeout(() => resetForm(), 1);
    setShowAddModal(true);
  }

  const closeAddEditModal = () => {
    setShowAddModal(false);
    setModalDistrictContact(undefined);
    setSubmitted(false);
    setFieldErrors({});
  }

  const openImportModal = () => {
    setLoading(true);
    Modal.confirm({
      title: 'Import',
      content: <DistrictContactImporter
        ref={_districtContactImporterRef}
        academicYear={props.academicYear}
        isInModal={true}
        onSubmit={dataTableSubmit} />,
      onCancel: () => { setLoading(false) },
      onOk: () => _districtContactImporterRef.current?.confirmImport(),
      width: 500
    });
  }

  const dataTableSubmit = () => {
    setLoading(false);
    _dataTable?.refresh();
  }

  const modalSubmit = () => {
    setSubmitted(true);

    const model = DistrictContactDTO.create(_formRef.current?.getFieldsValue()) as DistrictContactDTO;

    model.id = modalDistrictContact?.id ?? Guid.Empty();

    const request = SaveDistrictContactHandler.Request.create({
      districtId: props.districtId,
      academicYearId: props.academicYear,
      districtContact: model
    });

    DistrictApiService.saveDistrictContactDetails(request)
      .then((result: SaveDistrictContactHandler.Result) => {
        if (result?.succeeded) {
          message.success('Contact Added Successfully!');
          closeAddEditModal();
          _dataTable?.refresh();
        }
        else {
          setFieldErrors(result.fieldErrors);
          message.error('Error Adding Contact');
        }
      })
      .catch(() => {
        message.error('Error Adding Contact');
      });
  }

  const resetForm = () => {
    _formRef.current?.resetFields();
  }

  const addedToDistrict = () => {
    _dataTable?.refresh();
    closeAddEditModal();
  }

  const downloadTemplate = () => {
    window.open(Routes.generateFull(Routes.DISTRICT_CONTACT_IMPORT_TEMPLATE, {}, {}), '_blank')
  }

  const actionButtons = [];

  actionButtons.push(DataTableButtonUtil.Reset());
  if (AuthorizationUtil.isAuthorized([FeatureFlag.MANAGE_DISTRICT_CONTACT])) {
    if (props.fromDataReview) {
      actionButtons.push(DataTableButtonUtil.Default('Re-Import District Contacts', () => openImportModal(), undefined, loading));
      actionButtons.push(DataTableButtonUtil.Default('District Contacts Template', () => downloadTemplate(), <DownloadOutlined />, loading));
    }
    actionButtons.push(DataTableButtonUtil.Default('Add Contact', () => openAddEditModal(undefined)));
  }

  const renderAddEditDistrictContactModal = () => {
    return (
      <Form
        ref={_formRef}
        layout="vertical"
        requiredMark={true}>
        <Modal
          visible={showAddModal}
          title='District Contact'
          okText={modalDistrictContact == undefined ? 'Add' : 'Save'}
          onOk={modalSubmit}
          onCancel={closeAddEditModal}>
          <DistrictContactDetailsForm
            districtId={props.districtId}
            ref={_districtContactDetailsFormRef}
            submitted={submitted}
            fieldErrors={fieldErrors}
            academicYear={props.academicYear}
            onSuccess={addedToDistrict}
            districtContact={modalDistrictContact}
            resetForm={resetForm} />
        </Modal>
      </Form>
    );
  }

  return (
    <>
      <DataTable
        ref={(element: any) => (_dataTable = element)}
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 }
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) => DistrictApiService.getDistrictContactsDataTable(requestState, props.academicYear, props.districtId ?? Guid.Empty())
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'district_contacts_list' }} />
      {renderAddEditDistrictContactModal()}
    </>
  );
}

export default DistrictContactsDataTable;
