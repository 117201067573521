import { Alert, DatePicker, Input, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import moment from 'moment';
import * as React from 'react';
import AnnouncementApiService from '../../api/AnnouncementsApiService';
import FeatureFlag from '../../consts/FeatureFlag';
import * as GetAnnouncementDetailsHandler from '../../handlerModels/GetAnnouncementDetailsHandler';
import * as SaveAnnouncementDetailsHandler from '../../handlerModels/SaveAnnouncementDetailsHandler';
import AnnouncementDTO from '../../models/AnnouncementDTO';
import AnnouncementTypeDTO from '../../models/AnnouncementTypeDTO';
import InstitutionDTO from '../../models/InstitutionDTO';
import RoleDTO from '../../models/RoleDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import BaseFormState from '../../redux/bases/BaseFormState';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import FormUtil from '../../utils/FormUtil';
import Guid from '../../utils/Guid';
import LookupsUtil from '../../utils/LookupsUtil';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import AuthorizedContent from '../AuthorizedContent';
import ResetButton from '../buttons/ResetButton';
import SaveButton from '../buttons/SaveButton';
import RichTextEditor from '../inputs/RichTextEditor';

interface AnnouncementDetailsFormState extends BaseFormState {
  announcement: AnnouncementDTO;
  announcementTypes: AnnouncementTypeDTO[];
  institutions: InstitutionDTO[];
  roles: RoleDTO[];
}

interface AnnouncementDetailsFormProps extends BaseFormProps {
  AnnouncementId: string;
  onSave?: (id: string) => void;
}

class AnnouncementDetailsForm extends React.Component<AnnouncementDetailsFormProps, AnnouncementDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private getFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(AnnouncementDTO.title, {
        required: true,
        name: AnnouncementDTO.title,
        label: 'Title',
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(150),
        ],
      })
      .set(AnnouncementDTO.message, {
        required: true,
        name: AnnouncementDTO.message,
        label: 'Message',
        rules: [
          ValidationRuleUtil.required(),
          ValidationRuleUtil.maxLength(5000),
        ],
      })
      .set(AnnouncementDTO.announcementTypeId, {
        required: true,
        name: AnnouncementDTO.announcementTypeId,
        label: 'Announcement Type',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(AnnouncementDTO.showDates, {
        required: true,
        name: AnnouncementDTO.showDates,
        label: 'Show Dates',
        rules: [
          ValidationRuleUtil.required()
        ],
      })
      .set(AnnouncementDTO.institutionIds, {
        required: false,
        name: AnnouncementDTO.institutionIds,
        label: 'Institutions',
      })
      .set(AnnouncementDTO.roleIds, {
        required: false,
        name: AnnouncementDTO.roleIds,
        label: 'Roles',
      });
  }

  constructor(props: AnnouncementDetailsFormProps) {
    super(props);

    this.state = {
      announcement: AnnouncementDTO.create({
        institutionIds: [],
        roleIds: [],
        announcementTypeId: '',
        showOnDate: null,
        showUntilDate: null
      }),
      institutions: [],
      roles: [],
      announcementTypes: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: AnnouncementDetailsFormProps) {
    if (this.props.AnnouncementId && this.props.AnnouncementId != prevProps.AnnouncementId) {
      this.fetchData();
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
  }

  private fetchData() {
    this.setState({ loading: true });

    const loaders = [];
    if (this.state.announcementTypes.length == 0) {
      loaders.push(this.loadAnnouncementTypes());
    }

    if (this.state.institutions.length == 0) {
      loaders.push(this.loadInstitutions());
    }

    if (this.state.roles.length == 0) {
      loaders.push(this.loadRoles());
    }

    if (this.props.AnnouncementId != Guid.Empty()) {
      loaders.push(this.loadExisting());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private cleanResultDates = (announcement: AnnouncementDTO) => {
    if (announcement != null) {
      announcement.showOnDate = moment(announcement.showOnDate);
      announcement.showUntilDate = moment(announcement.showUntilDate);
      announcement.showDates = [moment(announcement.showOnDate), moment(announcement.showUntilDate)];
    }

    return announcement;
  }

  private loadExisting = () => {
    return AnnouncementApiService.getAnnouncementDetails(this.props.AnnouncementId)
      .then((result: GetAnnouncementDetailsHandler.Result) => {
        if (result && result.announcement) {
          result.announcement = this.cleanResultDates(result.announcement);

          this.setState({ announcement: result.announcement ?? AnnouncementDTO.create() });
          this._formRef.current?.resetFields();
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  private loadAnnouncementTypes = () => {
    return LookupsUtil.getAll<AnnouncementTypeDTO>(AnnouncementTypeDTO.className)
      .then((results: AnnouncementTypeDTO[]) => {
        this.setState({ announcementTypes: results ?? [] });
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadInstitutions = () => {
    return LookupsUtil.getAll<InstitutionDTO>(InstitutionDTO.className)
      .then((results: InstitutionDTO[]) => {
        this.setState({ institutions: results ?? [] });
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadRoles = () => {
    return LookupsUtil.getAll<RoleDTO>(RoleDTO.className)
      .then((results: RoleDTO[]) => {
        this.setState({ roles: results ?? [] });
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const formValues = this._formRef ? (this._formRef.current as any).getFieldsValue() : null;

    const request = SaveAnnouncementDetailsHandler.Request.create({
      announcementId: this.props.AnnouncementId,
      announcement: AnnouncementDTO.create({
        ...formValues,
        showOnDate: moment(formValues.showDates[0]),
        showUntilDate: moment(formValues.showDates[1]),
        showDates: [moment(formValues.showDates[0]), moment(formValues.showDates[1])]
      }),
    });

    AnnouncementApiService.saveAnnouncementDetails(request)
      .then((result: SaveAnnouncementDetailsHandler.Result) => {
        if (result?.succeeded) {
          if (result.announcement != null) {
            result.announcement = this.cleanResultDates(result.announcement);
          }

          this.setState({ announcement: result?.announcement ?? AnnouncementDTO.create() });

          message.success('Success!');

          if (this.props.onSave && result.announcementId) {
            this.props.onSave(result.announcementId);
            this.resetForm();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });

          message.error('Error Saving');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Error Saving');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form ref={this._formRef}
          layout="vertical"
          initialValues={this.state.announcement}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...formItems.get(AnnouncementDTO.title)}
            {...ValidationUtil.getValidation(AnnouncementDTO.title, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...formItems.get(AnnouncementDTO.message)}
            {...ValidationUtil.getValidation(AnnouncementDTO.message, this.state.fieldErrors, this.state.submitted)}>
            <RichTextEditor
              readonly={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...formItems.get(AnnouncementDTO.announcementTypeId)}
            {...ValidationUtil.getValidation(AnnouncementDTO.announcementTypeId, this.state.fieldErrors, this.state.submitted)}>
            <Select disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
              {this.state.announcementTypes.map(x => { return this.renderAnnouncementTypeOption(x) })}
            </Select>
          </FormItem>

          <FormItem
            {...formItems.get(AnnouncementDTO.showDates)}
            {...ValidationUtil.getValidation(AnnouncementDTO.showDates, this.state.fieldErrors, this.state.submitted)}>
            <DatePicker.RangePicker format='M/D/YYYY' disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          <FormItem
            {...formItems.get(AnnouncementDTO.institutionIds)}
            {...ValidationUtil.getValidation(AnnouncementDTO.institutionIds, this.state.fieldErrors, this.state.submitted)}
          >
            <Select allowClear mode="multiple" disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
              {this.state.institutions.map(x => { return this.renderInstitutionOption(x) })}
            </Select>
          </FormItem>

          <FormItem
            {...formItems.get(AnnouncementDTO.roleIds)}
            {...ValidationUtil.getValidation(AnnouncementDTO.roleIds, this.state.fieldErrors, this.state.submitted)}
          >
            <Select allowClear mode="multiple" disabled={!this.props.isEditing || this.state.loading || this.state.submitting}>
              {this.state.roles.map(x => { return this.renderRoleOption(x) })}
            </Select>
          </FormItem>

          {this.renderSave()}
        </Form>
      </Space >
    );
  }

  renderRoleOption(role: RoleDTO) {
    if (role.id) {
      return <Select.Option key={role.id} value={role.id}>{role.name}</Select.Option>
    }
  }

  renderInstitutionOption(institution: InstitutionDTO) {
    if (institution.id) {
      return <Select.Option key={institution.id} value={institution.id}>{institution.name}</Select.Option>;
    }
  }

  renderAnnouncementTypeOption(announcementType: AnnouncementTypeDTO) {
    if (announcementType.id) {
      return <Select.Option key={announcementType.id} value={announcementType.id}>{announcementType.name}</Select.Option>;
    }
  }

  renderSave() {
    const formItems = this.getFormItems();

    if (this.props.isEditing && AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_ANNOUNCEMENT])) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_ANNOUNCEMENT]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!FormUtil.canSubmit(this._formRef, formItems)} saving={this.state.submitting} />
            <ResetButton disabled={!FormUtil.hasChanged(this._formRef, formItems)} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent >
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default AnnouncementDetailsForm
