import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TransferMajorCourseBlockApiService from '../../../../api/TransferMajorCourseBlockApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import TransferMajorCourseBlockDetailsForm from '../../../../components/forms/postSecondary/TransferMajorCourseBlockDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteTransferMajorCourseBlockHandler from '../../../../handlerModels/DeleteTransferMajorCourseBlockHandler';
import * as GetTransferMajorCourseBlockDetailsHandler from '../../../../handlerModels/GetTransferMajorCourseBlockDetailsHandler';
import TransferMajorCourseBlockDTO from '../../../../models/TransferMajorCourseBlockDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface TransferMajorCourseBlockDetailsPageState extends BaseFormState {
  transferMajorCourseBlockId: number;
  transferMajorCourseBlock?: TransferMajorCourseBlockDTO;
}

interface TransferMajorCourseBlockDetailsPageProps {
}

class TransferMajorCourseBlockDetailsPage extends React.Component<TransferMajorCourseBlockDetailsPageProps & RouteComponentProps<RouteObject>, TransferMajorCourseBlockDetailsPageState> {
  private readonly _TransferMajorCourseBlockDetailsRef = React.createRef<TransferMajorCourseBlockDetailsForm>();

  constructor(props: TransferMajorCourseBlockDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      transferMajorCourseBlockId: 0
    };
  }

  componentDidMount() {
    const TransferMajorCourseBlockId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (TransferMajorCourseBlockId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const TransferMajorCourseBlockIdNumber = Number.parseInt(TransferMajorCourseBlockId);
      this.loadTransferMajorCourseBlock(TransferMajorCourseBlockIdNumber);
    }
  }

  private loadTransferMajorCourseBlock = (transferMajorCourseBlockId: number) => {
    this.setState({ transferMajorCourseBlockId: transferMajorCourseBlockId });

    TransferMajorCourseBlockApiService.getTransferMajorCourseBlockDetails(transferMajorCourseBlockId)
      .then((results: GetTransferMajorCourseBlockDetailsHandler.Result) => {
        if (results.transferMajorCourseBlock) {
          this.setState({ transferMajorCourseBlock: results.transferMajorCourseBlock });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Transfer Major Course Block?',
      okText: 'Delete',
      onOk: () => this.deleteTransferMajorCourseBlock(id)
    });
  }

  private deleteTransferMajorCourseBlock = (transferMajorCourseBlockId: number) => {
    this.setState({ submitting: true });

    const request = DeleteTransferMajorCourseBlockHandler.Request.create({
      transferMajorCourseBlockId: transferMajorCourseBlockId,
    });

    TransferMajorCourseBlockApiService.deleteTransferMajorCourseBlock(request)
      .then((result: DeleteTransferMajorCourseBlockHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToTransferMajorCourseBlock();
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._TransferMajorCourseBlockDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private TransferMajorCourseBlockDetailsSaved = (transferMajorCourseBlockId: number) => {
    this.setState({ isEditing: false });
    if (transferMajorCourseBlockId != this.state.transferMajorCourseBlockId) {
      HistoryUtil.replace(Routes.generate(Routes.TRANSFER_MAJOR_COURSE_BLOCK_DETAILS, { id: transferMajorCourseBlockId }));
    }
    else {
      this.loadTransferMajorCourseBlock(transferMajorCourseBlockId);
    }
  }

  private goToTransferMajorCourseBlock = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.transferMajorCourseBlockId == 0 ? 'New Transfer Major Course Block' : this.state.transferMajorCourseBlock?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToTransferMajorCourseBlock}
          breadcrumbs={Breadcrumbs.transferMajorCourseBlockDetails(title, this.state.transferMajorCourseBlockId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.transferMajorCourseBlockId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_COURSE_BLOCKS]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <TransferMajorCourseBlockDetailsForm
          ref={this._TransferMajorCourseBlockDetailsRef}
          transferMajorCourseBlockId={this.state.transferMajorCourseBlockId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.TransferMajorCourseBlockDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Transfer Major Course Block' key={this.state.transferMajorCourseBlockId} onClick={() => this.deleteConfirmation(this.state.transferMajorCourseBlockId)}  >
          Delete Transfer Major Course Block
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_COURSE_BLOCKS]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(TransferMajorCourseBlockDetailsPage);
