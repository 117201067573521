import { Alert, Input, message, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import InstitutionPresidentApiService from '../../../api/InstitutionPresidentApiService';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetInstitutionPresidentDetailsHandler from '../../../handlerModels/GetInstitutionPresidentDetailsHandler';
import * as SaveInstitutionPresidentDetailsHandler from '../../../handlerModels/SaveInstitutionPresidentDetailsHandler';
import InstitutionPresidentDTO from '../../../models/InstitutionPresidentDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import FormUtil from '../../../utils/FormUtil';
import Guid from '../../../utils/Guid';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';

interface AddInstitutionPresidentFormState extends BaseFormState {
  institutionPresident: InstitutionPresidentDTO | null;
}

interface AddInstitutionPresidentFormProps extends BaseFormProps {
  onSuccess?: () => void;
  institutionId: string;
}

class InstitutionPresidentDetailsForm extends React.Component<AddInstitutionPresidentFormProps, AddInstitutionPresidentFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly _formItems = new Map<string, FormItemProps>()

    .set(InstitutionPresidentDTO.firstName, {
      required: true,
      name: InstitutionPresidentDTO.firstName,
      label: 'First Name',
      rules: [
        ValidationRuleUtil.maxLength(250),
        ValidationRuleUtil.required(),
      ]
    })
    .set(InstitutionPresidentDTO.lastName, {
      name: InstitutionPresidentDTO.lastName,
      required: true,
      label: 'Last Name',
      rules: [
        ValidationRuleUtil.maxLength(250),
        ValidationRuleUtil.required(),
      ]
    })
    .set(InstitutionPresidentDTO.email, {
      required: true,
      name: InstitutionPresidentDTO.email,
      label: 'Email',
      rules: [
        ValidationRuleUtil.maxLength(250),
        ValidationRuleUtil.required(),
      ],
    })
    .set(InstitutionPresidentDTO.phone, {
      name: InstitutionPresidentDTO.phone,
      label: 'Phone Number',
      rules: [
        ValidationRuleUtil.maxLength(12),
        ValidationRuleUtil.phoneNumberValidator(),
      ]
    })

  constructor(props: AddInstitutionPresidentFormProps) {
    super(props);

    this.state = {
      institutionPresident: InstitutionPresidentDTO.create()
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadInstitutionPresident();
  }

  componentDidUpdate(prevProps: AddInstitutionPresidentFormProps) {
    if (this.props.institutionId && this.props.institutionId != Guid.Empty() && this.props.institutionId != prevProps.institutionId) {
      this.loadInstitutionPresident();
    }
  }

  private loadInstitutionPresident() {
    if (this.props.institutionId == Guid.Empty()) {
      return this.loadNew();
    }
    else {
      return this.loadExisting();
    }
  }

  private loadExisting = () => {
    return InstitutionPresidentApiService.getInstitutionPresidentDetails(this.props.institutionId)
      .then((results: GetInstitutionPresidentDetailsHandler.Result) => {
        if (results.institutionPresident?.id) {
          this.setState({ institutionPresident: results.institutionPresident });
        }

        this.reset();
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadNew = () => {
    this.setState({ institutionPresident: InstitutionPresidentDTO.create() }, () => this.reset());
    this.setState({ loading: false });
  }

  public submit = () => {
    this.handleSubmit();
  }

  public reset = () => {
    this._formRef.current?.resetFields();
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveInstitutionPresidentDetailsHandler.Request.create({
      institutionId: this.props.institutionId,
      institutionPresident: InstitutionPresidentDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null)
    });

    InstitutionPresidentApiService.saveInstitutionPresident(request)
      .then((result: SaveInstitutionPresidentDetailsHandler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');
          this.setState({ institutionPresident: result.institutionPresident });

          if (this.props.onSuccess) {
            this.props.onSuccess();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('President could not be saved. ' + this.state.message);
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('President could not be saved.');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    return (
      <Space size="small" direction="vertical">
        <Form
          ref={this._formRef}
          layout="vertical"
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={true}>

          <FormItem
            {...this._formItems.get(InstitutionPresidentDTO.firstName)}
            initialValue={this.state.institutionPresident?.firstName}
            {...ValidationUtil.getValidation(InstitutionPresidentDTO.firstName, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionPresidentDTO.lastName)}
            initialValue={this.state.institutionPresident?.lastName}
            {...ValidationUtil.getValidation(InstitutionPresidentDTO.lastName, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionPresidentDTO.email)}
            initialValue={this.state.institutionPresident?.email}
            {...ValidationUtil.getValidation(InstitutionPresidentDTO.email, this.state.fieldErrors, this.state.submitted)}>
            <Input disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>
          <FormItem
            {...this._formItems.get(InstitutionPresidentDTO.phone)}
            initialValue={this.state.institutionPresident?.phone}
            {...ValidationUtil.getValidation(InstitutionPresidentDTO.phone, this.state.fieldErrors, this.state.submitted)}>
            <Input placeholder='###-###-#####' disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
          </FormItem>

          {this.renderSave()}

        </Form >
      </Space >
    );
  }

  renderSave() {
    if (this.props.isEditing) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_INSTITUTION_PRESIDENT]}>
          <Space direction={'horizontal'} >
            <SaveButton htmlType='submit' disabled={!FormUtil.canSubmit(this._formRef, this._formItems) || !this.props.isEditing || this.state.loading || this.state.submitting} saving={this.state.submitting} />
            <ResetButton disabled={!this.props.isEditing || this.state.loading || this.state.submitting} onConfirm={this.reset} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default InstitutionPresidentDetailsForm;
