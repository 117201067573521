import { Card, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../api/AcademicYearApiService';
import ProgramMasterReportDataTable from '../../../components/datatables/secondary/ProgramMasterReportDataTable';
import Breadcrumbs from '../../../config/Breadcrumbs';
import * as GetAcademicYearsHandler from '../../../handlerModels/GetAcademicYearsHandler';
import AcademicYearDTO from '../../../models/AcademicYearDTO';
import UserSecurity from '../../../models/UserSecurity';
import HeaderPortal from '../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../redux/state/StateStoreModel';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import HistoryUtil from '../../../utils/HistoryUtil';
import ParameterUtil from '../../../utils/ParameterUtil';

interface ProgramMasterReportPageState {
  loading: boolean;
  academicYear: number;
  academicYears: AcademicYearDTO[];
}

interface ProgramMasterReportPageProps {
  currentUser: UserSecurity | null;
}

class ProgramMasterReportPage extends React.Component<ProgramMasterReportPageProps & RouteComponentProps<RouteObject>, ProgramMasterReportPageState> {

  constructor(props: ProgramMasterReportPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
      academicYears: []
    };
  }

  componentDidMount() {
    const id = Number.parseInt(ParameterUtil.getPathPart(this.props.match, 'academicYearId'));

    if (this.state.academicYear != id) {
      this.setState({ academicYear: id });

    }
    else {
      this.loadAcademicYears(id);
    }
  }

  private loadAcademicYears = (id: number) => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
      academicYearId: id
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
            academicYear: DateTimeUtil.getCurrentYear()
          });
        }
      })
      .catch()
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private onBack = () => {
    HistoryUtil.goBack();
  }

  render() {
    if (!this.state.academicYear) {
      return <Skeleton active />
    }

    return (
      <Content >
        <HeaderPortal title='Program Master Report'
          breadcrumbs={Breadcrumbs.academicYearProgramMasterReport(this.state.academicYear)}
          onBack={this.onBack}/>
        < Space direction="vertical" size="large">
          <Card title="Program Master Report">
            <Space direction='vertical' size="large">
            <ProgramMasterReportDataTable
            academicYear={this.state.academicYear} />
            </Space>
          </Card>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected,
    selectedDistrict: state.District.Selected,
    selectedRPP: state.RegionalPlanningPartner.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ProgramMasterReportPage));
