// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import InstitutionCourseBlockDataTableDTO from '../models/InstitutionCourseBlockDataTableDTO';
import InstitutionCourseBlockCourseDataTableDTO from '../models/InstitutionCourseBlockCourseDataTableDTO';
import InstitutionCourseBlockProgramDataTableDTO from '../models/InstitutionCourseBlockProgramDataTableDTO';
import * as GetInstitutionCourseBlockDetailsHandler from '../handlerModels/GetInstitutionCourseBlockDetailsHandler';
import * as SaveInstitutionCourseBlockDetailsHandler from '../handlerModels/SaveInstitutionCourseBlockDetailsHandler';
import * as GetInstitutionCourseBlocksHandler from '../handlerModels/GetInstitutionCourseBlocksHandler';
import * as SearchForCoursesNotInBlockHandler from '../handlerModels/SearchForCoursesNotInBlockHandler';
import * as GetCourseBlocksInProgramTermsHandler from '../handlerModels/GetCourseBlocksInProgramTermsHandler';
import * as GetCourseBlocksInNewProgramProgramTermsHandler from '../handlerModels/GetCourseBlocksInNewProgramProgramTermsHandler';
import * as GetCourseBlocksInProgramModificationProgramTermsHandler from '../handlerModels/GetCourseBlocksInProgramModificationProgramTermsHandler';
import * as AddCourseToInstitutionCourseBlockDetailHandler from '../handlerModels/AddCourseToInstitutionCourseBlockDetailHandler';
import * as RemoveCourseFromInstitutionCourseBlockDetailHandler from '../handlerModels/RemoveCourseFromInstitutionCourseBlockDetailHandler';

export class InstitutionCourseBlockApiService extends BaseApi {

    // post: api/institutionCourseBlocks?institutionId=${encodeURIComponent(institutionId)}
    public getInstitutionCourseBlockTableData(tableRequest: TableRequestDTO, institutionId: string): Promise<TableResponseDTO<InstitutionCourseBlockDataTableDTO>> {
        let url = `api/institutionCourseBlocks`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstitutionCourseBlockDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/${encodeURIComponent(id)}
    public getInstitutionCourseBlockDetails(id: string): Promise<GetInstitutionCourseBlockDetailsHandler.Result> {
        let url = `api/institutionCourseBlocks/${encodeURIComponent(id)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionCourseBlockDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/save
    public saveInstitutionCourseBlock(saveInstitutionCourseBlockDetailsHandler: SaveInstitutionCourseBlockDetailsHandler.Request): Promise<SaveInstitutionCourseBlockDetailsHandler.Result> {
        let url = `api/institutionCourseBlocks/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveInstitutionCourseBlockDetailsHandler.Result>(saveInstitutionCourseBlockDetailsHandler, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/get
    public getInstitutionCourseBlocks(getInstitutionCourseBlocks: GetInstitutionCourseBlocksHandler.Request): Promise<GetInstitutionCourseBlocksHandler.Result> {
        let url = `api/institutionCourseBlocks/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetInstitutionCourseBlocksHandler.Result>(getInstitutionCourseBlocks, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/coursesForInstitutioinCourseBlockDetail?institutionCoureBlockDetailid=${encodeURIComponent(institutionCoureBlockDetailid)}
    public getCoursesForInstitutionCourseBlockDetailTableData(tableRequest: TableRequestDTO, institutionCoureBlockDetailid: string): Promise<TableResponseDTO<InstitutionCourseBlockCourseDataTableDTO>> {
        let url = `api/institutionCourseBlocks/coursesForInstitutioinCourseBlockDetail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionCoureBlockDetailid != null) {
            url += `${prefix}institutionCoureBlockDetailid=${encodeURIComponent(institutionCoureBlockDetailid)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstitutionCourseBlockCourseDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/searchForCourses?searchText=${encodeURIComponent(searchText)}&institutionCourseBlockDetailid=${encodeURIComponent(institutionCourseBlockDetailid)}&institutionId=${encodeURIComponent(institutionId)}
    public searchForCourses(searchText: string, institutionCourseBlockDetailid: string, institutionId: string): Promise<SearchForCoursesNotInBlockHandler.Result> {
        let url = `api/institutionCourseBlocks/searchForCourses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (searchText != null) {
            url += `${prefix}searchText=${encodeURIComponent(searchText)}`;
            prefix = '&';
        }
        if (institutionCourseBlockDetailid != null) {
            url += `${prefix}institutionCourseBlockDetailid=${encodeURIComponent(institutionCourseBlockDetailid)}`;
            prefix = '&';
        }
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForCoursesNotInBlockHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/programsForInstitutioinCourseBlockDetail?institutionCoureBlockid=${encodeURIComponent(institutionCoureBlockid)}
    public getProgramsForInstitutionCourseBlockDetailTableData(tableRequest: TableRequestDTO, institutionCoureBlockid: string): Promise<TableResponseDTO<InstitutionCourseBlockProgramDataTableDTO>> {
        let url = `api/institutionCourseBlocks/programsForInstitutioinCourseBlockDetail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionCoureBlockid != null) {
            url += `${prefix}institutionCoureBlockid=${encodeURIComponent(institutionCoureBlockid)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<InstitutionCourseBlockProgramDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/getCourseBlocksInProgramTerms
    public getCourseBlocksInProgramTerms(getCourseBlocksInProgramTerms: GetCourseBlocksInProgramTermsHandler.Request): Promise<GetCourseBlocksInProgramTermsHandler.Result> {
        let url = `api/institutionCourseBlocks/getCourseBlocksInProgramTerms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCourseBlocksInProgramTermsHandler.Result>(getCourseBlocksInProgramTerms, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/getCourseBlocksInNewProgramProgramTerms
    public getCourseBlocksInNewProgramProgramTerms(getCourseBlocksInNewProgramProgramTerms: GetCourseBlocksInNewProgramProgramTermsHandler.Request): Promise<GetCourseBlocksInNewProgramProgramTermsHandler.Result> {
        let url = `api/institutionCourseBlocks/getCourseBlocksInNewProgramProgramTerms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCourseBlocksInNewProgramProgramTermsHandler.Result>(getCourseBlocksInNewProgramProgramTerms, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/getCourseBlocksInProgramModificationProgramTerms
    public getCourseBlocksInProgramModificationProgramTerms(getCourseBlocksInProgramModificationProgramTerms: GetCourseBlocksInProgramModificationProgramTermsHandler.Request): Promise<GetCourseBlocksInProgramModificationProgramTermsHandler.Result> {
        let url = `api/institutionCourseBlocks/getCourseBlocksInProgramModificationProgramTerms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetCourseBlocksInProgramModificationProgramTermsHandler.Result>(getCourseBlocksInProgramModificationProgramTerms, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/addCourseToInstitutionCourseBlockDetail
    public addCourseToInstitutionCourseBlockDetail(addCourseToInstitutionCourseBlockDetail: AddCourseToInstitutionCourseBlockDetailHandler.Request): Promise<AddCourseToInstitutionCourseBlockDetailHandler.Result> {
        let url = `api/institutionCourseBlocks/addCourseToInstitutionCourseBlockDetail`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddCourseToInstitutionCourseBlockDetailHandler.Result>(addCourseToInstitutionCourseBlockDetail, 'post', url, true, false);
    }

    // post: api/institutionCourseBlocks/removeCourseFromInstitutionCourseBlockDetail
    public removeCourseFromInstitutionCourseBlockDetail(removeCourseFromInstitutionCourseBlockDetail: RemoveCourseFromInstitutionCourseBlockDetailHandler.Request): Promise<RemoveCourseFromInstitutionCourseBlockDetailHandler.Result> {
        let url = `api/institutionCourseBlocks/removeCourseFromInstitutionCourseBlockDetail`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveCourseFromInstitutionCourseBlockDetailHandler.Result>(removeCourseFromInstitutionCourseBlockDetail, 'post', url, true, false);
    }

    // get: api/institutionCourseBlocks/courseBlockReport?institutionId=${encodeURIComponent(institutionId)}
    public exportNOI(institutionId: string): Promise<any> {
        let url = `api/institutionCourseBlocks/courseBlockReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new InstitutionCourseBlockApiService();
export default service;
