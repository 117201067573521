import { Card, Descriptions, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AuthorizedContent from '../../components/AuthorizedContent';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import Sections from '../../config/Sections';
import FeatureFlag from '../../consts/FeatureFlag';
import HeaderPortal from '../../portals/HeaderPortal';
import { StateStoreModel } from '../../redux/state/StateStoreModel';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import HistoryUtil from '../../utils/HistoryUtil';

interface PostSecondaryLandingPageProps {
}

interface PostSecondaryLandingPageState {
  loading: boolean;
}

class PostSecondaryLandingPage extends React.Component<PostSecondaryLandingPageProps & RouteComponentProps<RouteObject>, PostSecondaryLandingPageState> {
  constructor(props: PostSecondaryLandingPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  private goBack = () => {
    HistoryUtil.replace(Routes.generate(Routes.HOME));
  }

  render() {
    return (
      <Content >
        <HeaderPortal
          title="Post-Secondary"
          onBack={this.goBack}
          breadcrumbs={Breadcrumbs.postSecondary()} />

        <Space direction='vertical' size='large'>
          <Card title="" >
            <Descriptions >
              {AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.CHANGE_REQUESTS) ?
                <AuthorizedContent validFeatureFlags={[]}>
                  <Descriptions.Item>
                    <Link to={Routes.generate(Routes.CHANGE_REQUESTS)} >Change Request</Link>
                  </Descriptions.Item>
                </AuthorizedContent> :
                null}

              {AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.INSTITUTIONS) ?
                <AuthorizedContent validFeatureFlags={Sections.SectionFeatureFlags.INSTITUTIONS}>
                  <Descriptions.Item>
                    <Link to={Routes.generate(Routes.INSTITUTIONS)} >Community Colleges</Link>
                  </Descriptions.Item>
                </AuthorizedContent> :
                null}

              {AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.PROGRAMS) ?
                <AuthorizedContent validFeatureFlags={Sections.SectionFeatureFlags.PROGRAMS}>
                  <Descriptions.Item>
                    <Link to={Routes.generate(Routes.PROGRAMS)} >Programs</Link>
                  </Descriptions.Item>
                </AuthorizedContent> :
                null}

              {AuthorizationUtil.isAuthorized(Sections.SectionFeatureFlags.COURSES) ?
                <AuthorizedContent validFeatureFlags={Sections.SectionFeatureFlags.COURSES}>
                  <Descriptions.Item>
                    <Link to={Routes.generate(Routes.COURSES)} >Courses</Link>
                  </Descriptions.Item>
                </AuthorizedContent> :
                null}

              <Descriptions.Item>
                <Link to={Routes.generate(Routes.ADMIN_RESOURCES)} >Resources</Link>
              </Descriptions.Item>

              <Descriptions.Item>
                <Link to={Routes.generate(Routes.DOWNLOADS_SECTION)} >Downloads</Link>
              </Descriptions.Item>

              {AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_CONTACT]) ?
                <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CONTACT]}>
                  <Descriptions.Item>
                    <Link to={Routes.generate(Routes.CONTACTS)} >Contacts</Link>
                  </Descriptions.Item>
                </AuthorizedContent> :
                null}

              <Descriptions.Item>
                <Link to={Routes.generate(Routes.CONSULTANTS)} >Consultants</Link>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Space>
      </Content>
    );
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    institution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(PostSecondaryLandingPage));
