// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionDTO from './InstitutionDTO';
import ChangeRequestTypeDTO from './ChangeRequestTypeDTO';
import ChangeRequestResourceDTO from './ChangeRequestResourceDTO';
import ChangeRequestApprovalDTO from './ChangeRequestApprovalDTO';
import ChangeRequestCommentDTO from './ChangeRequestCommentDTO';
import UserDTO from './UserDTO';
import AdministrativeChangeRequestDTO from './AdministrativeChangeRequestDTO';
import NewDisciplineChangeRequestDTO from './NewDisciplineChangeRequestDTO';
import NoticeOfIntentChangeRequestDTO from './NoticeOfIntentChangeRequestDTO';
import NewCourseChangeRequestDTO from './NewCourseChangeRequestDTO';
import CourseDeactivationChangeRequestDTO from './CourseDeactivationChangeRequestDTO';
import CourseModificationChangeRequestDTO from './CourseModificationChangeRequestDTO';
import ProgramDeactivationChangeRequestDTO from './ProgramDeactivationChangeRequestDTO';
import ProgramModificationChangeRequestDTO from './ProgramModificationChangeRequestDTO';
import NewProgramChangeRequestDTO from './NewProgramChangeRequestDTO';
import ChangeRequestStatusTypeDTO from './ChangeRequestStatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ChangeRequestDTO extends EntitySelectorDTO { 
	id: string | null;
	number: number;
	changeRequestTypeId: number;
	institutionId: string | null;
	institution: InstitutionDTO | null;
	changeRequestType: ChangeRequestTypeDTO | null;
	title: string | null;
	requestingUserId: string | null | null;
	statusTypeId: number;
	createdOn: moment.Moment | string | null;
	requestedOn: moment.Moment | string | null;
	approvedOn: moment.Moment | string | null;
	createdOnDisplay: string | null;
	requestedOnDisplay: string | null;
	approvedOnDisplay: string | null;
	additionalComments: string | null;
	additionalResources: ChangeRequestResourceDTO[] | null;
	changeRequestApprovals: ChangeRequestApprovalDTO[] | null;
	comments: ChangeRequestCommentDTO[] | null;
	display: string | null;
	displayNumber: string | null;
	requestingUser: UserDTO | null;
	administrativeChangeRequest: AdministrativeChangeRequestDTO | null;
	newDisciplineChangeRequest: NewDisciplineChangeRequestDTO | null;
	noticeOfIntentChangeRequest: NoticeOfIntentChangeRequestDTO | null;
	newCourseChangeRequest: NewCourseChangeRequestDTO | null;
	courseDeactivationChangeRequest: CourseDeactivationChangeRequestDTO | null;
	courseModificationChangeRequest: CourseModificationChangeRequestDTO | null;
	programDeactivationChangeRequest: ProgramDeactivationChangeRequestDTO | null;
	programModificationChangeRequest: ProgramModificationChangeRequestDTO | null;
	newProgramChangeRequest: NewProgramChangeRequestDTO | null;
	termName: string | null;
	termYear: number | null | null;
	changeRequestStatusType: ChangeRequestStatusTypeDTO | null;
	isExpired: boolean | null | null;
}

const ChangeRequestDTO = {
    className: 'ChangeRequestDTO',
    create: (initValues?: Partial<ChangeRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			number: 0,
			changeRequestTypeId: 0,
			institutionId: '00000000-0000-0000-0000-000000000000',
			institution: null,
			changeRequestType: null,
			title: "",
			requestingUserId: null,
			statusTypeId: 0,
			createdOn: null,
			requestedOn: null,
			approvedOn: null,
			createdOnDisplay: "",
			requestedOnDisplay: "",
			approvedOnDisplay: "",
			additionalComments: "",
			additionalResources: [],
			changeRequestApprovals: [],
			comments: [],
			display: "",
			displayNumber: "",
			requestingUser: null,
			administrativeChangeRequest: null,
			newDisciplineChangeRequest: null,
			noticeOfIntentChangeRequest: null,
			newCourseChangeRequest: null,
			courseDeactivationChangeRequest: null,
			courseModificationChangeRequest: null,
			programDeactivationChangeRequest: null,
			programModificationChangeRequest: null,
			newProgramChangeRequest: null,
			termName: "",
			termYear: null,
			changeRequestStatusType: null,
			isExpired: null,
        },
        initValues);
    },
	id: 'id',
	number: 'number',
	changeRequestTypeId: 'changeRequestTypeId',
	institutionId: 'institutionId',
	institution: 'institution',
	changeRequestType: 'changeRequestType',
	title: 'title',
	requestingUserId: 'requestingUserId',
	statusTypeId: 'statusTypeId',
	createdOn: 'createdOn',
	requestedOn: 'requestedOn',
	approvedOn: 'approvedOn',
	createdOnDisplay: 'createdOnDisplay',
	requestedOnDisplay: 'requestedOnDisplay',
	approvedOnDisplay: 'approvedOnDisplay',
	additionalComments: 'additionalComments',
	additionalResources: 'additionalResources',
	changeRequestApprovals: 'changeRequestApprovals',
	comments: 'comments',
	display: 'display',
	displayNumber: 'displayNumber',
	requestingUser: 'requestingUser',
	administrativeChangeRequest: 'administrativeChangeRequest',
	newDisciplineChangeRequest: 'newDisciplineChangeRequest',
	noticeOfIntentChangeRequest: 'noticeOfIntentChangeRequest',
	newCourseChangeRequest: 'newCourseChangeRequest',
	courseDeactivationChangeRequest: 'courseDeactivationChangeRequest',
	courseModificationChangeRequest: 'courseModificationChangeRequest',
	programDeactivationChangeRequest: 'programDeactivationChangeRequest',
	programModificationChangeRequest: 'programModificationChangeRequest',
	newProgramChangeRequest: 'newProgramChangeRequest',
	termName: 'termName',
	termYear: 'termYear',
	changeRequestStatusType: 'changeRequestStatusType',
	isExpired: 'isExpired',
};

export default ChangeRequestDTO;