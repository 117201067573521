import { Card, Select, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AcademicYearApiService from '../../../../api/AcademicYearApiService';
import ServiceAreaDataTable from '../../../../components/datatables/secondary/ServiceAreaDataTable';
import Dropdown from '../../../../components/inputs/Dropdown';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import * as GetAcademicYearsHandler from '../../../../handlerModels/GetAcademicYearsHandler';
import AcademicYearDTO from '../../../../models/AcademicYearDTO';
import InstitutionDTO from '../../../../models/InstitutionDTO';
import UserSecurity from '../../../../models/UserSecurity';
import HeaderPortal from '../../../../portals/HeaderPortal';
import { StateStoreModel } from '../../../../redux/state/StateStoreModel';
import DateTimeUtil from '../../../../utils/DateTimeUtil';
import HistoryUtil from '../../../../utils/HistoryUtil';

interface ServiceAreaConfigurationListPageState {
  loading: boolean;
  academicYear: number;
  academicYears: AcademicYearDTO[];
}

interface ServiceAreaConfigurationListPageProps {
  currentUser: UserSecurity | null;
  selectedInstitution: InstitutionDTO | null;
}

class ServiceAreaConfigurationListPage extends React.Component<ServiceAreaConfigurationListPageProps & RouteComponentProps<RouteObject>, ServiceAreaConfigurationListPageState> {

  constructor(props: ServiceAreaConfigurationListPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      loading: false,
      academicYear: 0,
      academicYears: [],
    };
  }

  componentDidMount() {
    this.loadAcademicYears();
  }

  private loadAcademicYears = () => {
    this.setState({ loading: true });
    const request = GetAcademicYearsHandler.Request.create({
    });
    return AcademicYearApiService.getAcademicYears(request)
      .then((results: GetAcademicYearsHandler.Result) => {
        if (results && results.academicYears) {
          this.setState({
            academicYears: results.academicYears ?? [],
            academicYear: DateTimeUtil.getCurrentYear()
          });
        }
      })
      .catch()
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  private onSelect = (value: number) => {
    this.setState({ academicYear: value });
  }

  private onBack = () => {
    HistoryUtil.goBack();
  }

  render() {
    return (
      <Content >
        <HeaderPortal title='Service Areas' breadcrumbs={Breadcrumbs.serviceAreaList()}
          onBack={this.onBack}
          extra={<Dropdown value={this.state.academicYear} onSelect={this.onSelect} dropdownMatchSelectWidth={false}>{this.state.academicYears.map(x => { return this.renderAcademicYear(x); })}</Dropdown>} />
        < Space direction="vertical" size="large">
          <Card title="Service Areas">
            <Space direction='vertical' size="large">
              <ServiceAreaDataTable
                academicYear={this.state.academicYear} />
            </Space>
          </Card>
        </Space>
      </Content>
    );
  }

  renderAcademicYear(academicYear: AcademicYearDTO) {
    return <Select.Option key={academicYear.id} value={academicYear.id}>{academicYear.id}</Select.Option>
  }
}

function mapStateToProps(state: StateStoreModel) {
  return {
    currentUser: state.UserSession.Value,
    selectedInstitution: state.Institution.Selected
  };
}

export default withRouter(connect(mapStateToProps)(ServiceAreaConfigurationListPage));
