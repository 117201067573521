// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface GeneralEducationDTO extends EntitySelectorDTO { 
	id: number;
	displayOrder: number;
	code: string | null;
	name: string | null;
	embeddedCredits: boolean;
	parentGeneralEducationId: number | null | null;
	parent: GeneralEducationDTO | null;
}

const GeneralEducationDTO = {
    className: 'GeneralEducationDTO',
    create: (initValues?: Partial<GeneralEducationDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: 0,
			displayOrder: 0,
			code: "",
			name: "",
			embeddedCredits: false,
			parentGeneralEducationId: null,
			parent: null,
        },
        initValues);
    },
	id: 'id',
	displayOrder: 'displayOrder',
	code: 'code',
	name: 'name',
	embeddedCredits: 'embeddedCredits',
	parentGeneralEducationId: 'parentGeneralEducationId',
	parent: 'parent',
};

export default GeneralEducationDTO;