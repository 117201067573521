import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import * as React from 'react';
import ValueLabel from '../general/ValueLabel';

interface ReadableTextAreaProps extends TextAreaProps, React.RefAttributes<TextAreaRef> {
  readonly?: boolean;
}

function ReadableTextArea(props: React.PropsWithChildren<ReadableTextAreaProps>) {

  if (props.readonly) {
    return (
      <ValueLabel text={props.value} />
    );
  }
  return (
    <Input.TextArea {...props} />
  );

}

export default ReadableTextArea;
