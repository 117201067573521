// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ProgramDataTableDTO from '../models/ProgramDataTableDTO';
import ProgramAdvisoryBoardDataTableDTO from '../models/ProgramAdvisoryBoardDataTableDTO';
import * as SearchForProgramsHandler from '../handlerModels/SearchForProgramsHandler';
import * as GetProgramsByInstitutionIdHandler from '../handlerModels/GetProgramsByInstitutionIdHandler';
import * as GetProgramHandler from '../handlerModels/GetProgramHandler';
import * as GetProgramHeaderHandler from '../handlerModels/GetProgramHeaderHandler';
import * as GetProgramWithAllDetailsHandler from '../handlerModels/GetProgramWithAllDetailsHandler';
import * as GetSelectedProgramDetailHandler from '../handlerModels/GetSelectedProgramDetailHandler';
import * as GetProgramAwardDetailsHandler from '../handlerModels/GetProgramAwardDetailsHandler';
import * as GetProgramAwardTermHandler from '../handlerModels/GetProgramAwardTermHandler';
import * as SaveProgramDetailsHandler from '../handlerModels/SaveProgramDetailsHandler';
import * as SaveProgramAdvisoryBoardHandler from '../handlerModels/SaveProgramAdvisoryBoardHandler';

export class ProgramApiService extends BaseApi {

    // post: api/program/searchForPrograms
    public searchForPrograms(searchForPrograms: SearchForProgramsHandler.Request): Promise<SearchForProgramsHandler.Result> {
        let url = `api/program/searchForPrograms`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForProgramsHandler.Result>(searchForPrograms, 'post', url, true, false);
    }

    // post: api/program/getProgramsByInstitutionId
    public getProgramsByInstitutionId(getProgramsByInstitutionId: GetProgramsByInstitutionIdHandler.Request): Promise<GetProgramsByInstitutionIdHandler.Result> {
        let url = `api/program/getProgramsByInstitutionId`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramsByInstitutionIdHandler.Result>(getProgramsByInstitutionId, 'post', url, true, false);
    }

    // post: api/program/list/${encodeURIComponent(institutionId)}?termId=${encodeURIComponent(termId)}&year=${year}
    public getProgramTableData(tableRequest: TableRequestDTO, institutionId: string, termId: string, year: number): Promise<TableResponseDTO<ProgramDataTableDTO>> {
        let url = `api/program/list/${encodeURIComponent(institutionId)}`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (termId != null) {
            url += `${prefix}termId=${encodeURIComponent(termId)}`;
            prefix = '&';
        }
        if (year != null) {
            url += `${prefix}year=${year}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ProgramDataTableDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/program/${encodeURIComponent(programId)}
    public getProgram(programId: string): Promise<GetProgramHandler.Result> {
        let url = `api/program/${encodeURIComponent(programId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/program/programHeader/${encodeURIComponent(programId)}
    public getProgramHeader(programId: string): Promise<GetProgramHeaderHandler.Result> {
        let url = `api/program/programHeader/${encodeURIComponent(programId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramHeaderHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/program/getProgramWithAllDetails/${encodeURIComponent(programId)}
    public getProgramWithAllDetails(programId: string): Promise<GetProgramWithAllDetailsHandler.Result> {
        let url = `api/program/getProgramWithAllDetails/${encodeURIComponent(programId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramWithAllDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/program/getProgramDetail/${encodeURIComponent(programDetailId)}
    public getProgramDetail(programDetailId: string): Promise<GetSelectedProgramDetailHandler.Result> {
        let url = `api/program/getProgramDetail/${encodeURIComponent(programDetailId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelectedProgramDetailHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/program/getProgramAward/${encodeURIComponent(programAwardId)}
    public getProgramAward(programAwardId: string): Promise<GetProgramAwardDetailsHandler.Result> {
        let url = `api/program/getProgramAward/${encodeURIComponent(programAwardId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramAwardDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/program/getProgramAwardTerm/${encodeURIComponent(programTermId)}
    public getProgramAwardTerm(programTermId: string): Promise<GetProgramAwardTermHandler.Result> {
        let url = `api/program/getProgramAwardTerm/${encodeURIComponent(programTermId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetProgramAwardTermHandler.Result>(null, 'post', url, true, false);
    }

    // get: api/program/programDetailedCourseListing/${encodeURIComponent(programDetailId)}
    public courseSyllabus(programDetailId: string): Promise<any> {
        let url = `api/program/programDetailedCourseListing/${encodeURIComponent(programDetailId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/program/complianceReport?termId=${encodeURIComponent(termId)}&year=${year}&institutionId=${encodeURIComponent(institutionId)}
    public complianceReport(termId: string, year: number, institutionId: string): Promise<any> {
        let url = `api/program/complianceReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (termId != null) {
            url += `${prefix}termId=${encodeURIComponent(termId)}`;
            prefix = '&';
        }
        if (year != null) {
            url += `${prefix}year=${year}`;
            prefix = '&';
        }
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // post: api/program/save
    public saveProgramDetails(saveProgramDetails: SaveProgramDetailsHandler.Request): Promise<SaveProgramDetailsHandler.Result> {
        let url = `api/program/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveProgramDetailsHandler.Result>(saveProgramDetails, 'post', url, true, false);
    }

    // post: api/program/saveAdvisoryBoard
    public saveAdvisoryBoard(saveProgramAdvisoryBoard: SaveProgramAdvisoryBoardHandler.Request): Promise<SaveProgramAdvisoryBoardHandler.Result> {
        let url = `api/program/saveAdvisoryBoard`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveProgramAdvisoryBoardHandler.Result>(saveProgramAdvisoryBoard, 'post', url, true, false);
    }

    // post: api/program/getAdvisoryBoard/${encodeURIComponent(programDetailId)}
    public getAdvisoryBoard(tableRequest: TableRequestDTO, programDetailId: string): Promise<TableResponseDTO<ProgramAdvisoryBoardDataTableDTO>> {
        let url = `api/program/getAdvisoryBoard/${encodeURIComponent(programDetailId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ProgramAdvisoryBoardDataTableDTO>>(tableRequest, 'post', url, true, false);
    }
}
var service = new ProgramApiService();
export default service;
