import { Input, InputProps } from 'antd';
import * as React from 'react';
import ValueLabel from '../general/ValueLabel';

interface ReadableTextBoxProps extends InputProps {
  readonly?: boolean;
  strong?: boolean;
}

function ReadableTextBox(props: React.PropsWithChildren<ReadableTextBoxProps>) {
  if (props.readonly) {
    return (
      <ValueLabel strong={props.strong} text={props.value} />
    );
  }
  return (
    <Input {...props} />
  );
}

export default ReadableTextBox;
