// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import DisciplineDTO from './DisciplineDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface InstitutionCourseBlockCourseDataTableDTO extends EntitySelectorDTO { 
	id: string | null;
	disciplineId: string | null;
	discipline: DisciplineDTO | null;
	catalogNumber: string | null;
	title: string | null;
}

const InstitutionCourseBlockCourseDataTableDTO = {
    className: 'InstitutionCourseBlockCourseDataTableDTO',
    create: (initValues?: Partial<InstitutionCourseBlockCourseDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			disciplineId: '00000000-0000-0000-0000-000000000000',
			discipline: null,
			catalogNumber: "",
			title: "",
        },
        initValues);
    },
	id: 'id',
	disciplineId: 'disciplineId',
	discipline: 'discipline',
	catalogNumber: 'catalogNumber',
	title: 'title',
};

export default InstitutionCourseBlockCourseDataTableDTO;