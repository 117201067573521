import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CategoriesApiService from '../../../../api/CategoriesApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import CategoryDetailsForm from '../../../../components/forms/postSecondary/CategoryDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteCategoriesHandler from '../../../../handlerModels/DeleteCategoriesHandler';
import * as GetCategoryDetailsHandler from '../../../../handlerModels/GetCategoryDetailsHandler';
import CategoryDTO from '../../../../models/CategoryDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import Guid from '../../../../utils/Guid';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface CategoryDetailsState extends BaseFormState {
  categoryId: string;
  category: CategoryDTO | null;
}

interface CategoryDetailsProps {
}

class CategoryDetailsPage extends React.Component<CategoryDetailsProps & RouteComponentProps<RouteObject>, CategoryDetailsState> {
  private readonly _categoryDetailsRef = React.createRef<CategoryDetailsForm>();

  constructor(props: CategoryDetailsProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      categoryId: Guid.Empty(),
      category: null,
    };
  }

  componentDidMount() {
    const id = ParameterUtil.getPathPart(this.props.match, 'id');

    if (id == 'new') {
      this.setState({ isEditing: true, isNew: true })
    }
    else {
      this.loadCategory(id);
    }
  }

  private loadCategory = (id: string) => {
    CategoriesApiService.getCategoryDetails(id)
      .then((results: GetCategoryDetailsHandler.Result) => {
        this.setState({
          categoryId: id,
          category: results?.category
        });
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._categoryDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private categoryDetailsSaved = (id: string) => {
    this.setState({ isEditing: false });
    if (id != this.state.categoryId) {
      HistoryUtil.replace(Routes.generate(Routes.CATEGORY_DETAILS, { id: id }));
    }
    else {
      this.loadCategory(id);
    }
  }

  private deleteConfirmation = (id: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this category?',
      okText: 'Delete',
      onOk: () => this.deleteCategory(id)
    });
  }

  private deleteCategory = (categoryId: string) => {
    this.setState({ submitting: true });

    const request = DeleteCategoriesHandler.Request.create({
      categoryId: categoryId,
    });

    CategoriesApiService.deleteCategories(request)
      .then((result: DeleteCategoriesHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('Category was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Category could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToListing();
      });
  }

  goToListing = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.isNew ? 'New Category' : this.state.category?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToListing}
          breadcrumbs={Breadcrumbs.categoryDetails(title, this.state.categoryId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content >
    );
  }

  renderDetailsCard() {
    const editButton = this.state.categoryId == Guid.Empty() ?
      null :
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CATEGORY]}>
        <EditCancelButton onClick={this.toggleEdit} isEditing={this.state.isEditing} />
      </AuthorizedContent>;

    return (
      <Card title="Details" extra={editButton} >
        <CategoryDetailsForm
          ref={this._categoryDetailsRef}
          categoryId={this.state.categoryId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.categoryDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete Category' key={this.state.categoryId} onClick={() => this.deleteConfirmation(this.state.categoryId)}  >
          Delete Category
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_CATEGORY]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(CategoryDetailsPage);
