// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as SaveNewProgramChangeRequestStep1Handler from '../handlerModels/SaveNewProgramChangeRequestStep1Handler';
import * as SaveNewProgramChangeRequestStep2Handler from '../handlerModels/SaveNewProgramChangeRequestStep2Handler';
import * as SaveNewProgramChangeRequestStep3Handler from '../handlerModels/SaveNewProgramChangeRequestStep3Handler';
import * as SaveNewProgramChangeRequestStep4Handler from '../handlerModels/SaveNewProgramChangeRequestStep4Handler';
import * as SaveNewProgramChangeRequestStep5Handler from '../handlerModels/SaveNewProgramChangeRequestStep5Handler';
import * as SaveNewProgramChangeRequestStep6Handler from '../handlerModels/SaveNewProgramChangeRequestStep6Handler';
import * as SaveNewProgramChangeRequestStep7Handler from '../handlerModels/SaveNewProgramChangeRequestStep7Handler';
import * as SaveNewProgramChangeRequestStep8Handler from '../handlerModels/SaveNewProgramChangeRequestStep8Handler';
import * as SaveNewProgramChangeRequestStep10Handler from '../handlerModels/SaveNewProgramChangeRequestStep10Handler';
import * as SaveNewProgramChangeRequestStep11Handler from '../handlerModels/SaveNewProgramChangeRequestStep11Handler';
import * as SaveNewProgramChangeRequestStep12Handler from '../handlerModels/SaveNewProgramChangeRequestStep12Handler';
import * as SaveNewProgramChangeRequestStep13Handler from '../handlerModels/SaveNewProgramChangeRequestStep13Handler';
import * as SaveNewProgramChangeRequestStep14Handler from '../handlerModels/SaveNewProgramChangeRequestStep14Handler';
import * as SubmitNewProgramChangeRequestStep1Handler from '../handlerModels/SubmitNewProgramChangeRequestStep1Handler';
import * as SubmitNewProgramChangeRequestStep2Handler from '../handlerModels/SubmitNewProgramChangeRequestStep2Handler';
import * as SubmitNewProgramChangeRequestStep3Handler from '../handlerModels/SubmitNewProgramChangeRequestStep3Handler';
import * as SubmitNewProgramChangeRequestStep4Handler from '../handlerModels/SubmitNewProgramChangeRequestStep4Handler';
import * as SubmitNewProgramChangeRequestStep5Handler from '../handlerModels/SubmitNewProgramChangeRequestStep5Handler';
import * as SubmitNewProgramChangeRequestStep6Handler from '../handlerModels/SubmitNewProgramChangeRequestStep6Handler';
import * as SubmitNewProgramChangeRequestStep7Handler from '../handlerModels/SubmitNewProgramChangeRequestStep7Handler';
import * as SubmitNewProgramChangeRequestStep8Handler from '../handlerModels/SubmitNewProgramChangeRequestStep8Handler';
import * as SubmitNewProgramChangeRequestStep9Handler from '../handlerModels/SubmitNewProgramChangeRequestStep9Handler';
import * as SubmitNewProgramChangeRequestStep10Handler from '../handlerModels/SubmitNewProgramChangeRequestStep10Handler';
import * as SubmitNewProgramChangeRequestStep11Handler from '../handlerModels/SubmitNewProgramChangeRequestStep11Handler';
import * as SubmitNewProgramChangeRequestStep12Handler from '../handlerModels/SubmitNewProgramChangeRequestStep12Handler';
import * as SubmitNewProgramChangeRequestStep13Handler from '../handlerModels/SubmitNewProgramChangeRequestStep13Handler';
import * as SubmitNewProgramChangeRequestStep14Handler from '../handlerModels/SubmitNewProgramChangeRequestStep14Handler';
import * as CheckNOIApprovalHandler from '../handlerModels/CheckNOIApprovalHandler';
import * as GetNewProgramChangeRequestHandler from '../handlerModels/GetNewProgramChangeRequestHandler';
import * as GetNewProgramChangeRequestStep1Handler from '../handlerModels/GetNewProgramChangeRequestStep1Handler';
import * as GetNewProgramChangeRequestStep2Handler from '../handlerModels/GetNewProgramChangeRequestStep2Handler';
import * as GetNewProgramChangeRequestStep3Handler from '../handlerModels/GetNewProgramChangeRequestStep3Handler';
import * as GetNewProgramChangeRequestStep4Handler from '../handlerModels/GetNewProgramChangeRequestStep4Handler';
import * as GetNewProgramChangeRequestStep5Handler from '../handlerModels/GetNewProgramChangeRequestStep5Handler';
import * as GetNewProgramChangeRequestStep6Handler from '../handlerModels/GetNewProgramChangeRequestStep6Handler';
import * as GetNewProgramChangeRequestStep7Handler from '../handlerModels/GetNewProgramChangeRequestStep7Handler';
import * as GetNewProgramChangeRequestStep8Handler from '../handlerModels/GetNewProgramChangeRequestStep8Handler';
import * as GetNewProgramChangeRequestStep9Handler from '../handlerModels/GetNewProgramChangeRequestStep9Handler';
import * as GetNewProgramChangeRequestStep10Handler from '../handlerModels/GetNewProgramChangeRequestStep10Handler';
import * as GetNewProgramChangeRequestStep11Handler from '../handlerModels/GetNewProgramChangeRequestStep11Handler';
import * as GetNewProgramChangeRequestStep11TermHandler from '../handlerModels/GetNewProgramChangeRequestStep11TermHandler';
import * as GetNewProgramChangeRequestStep12Handler from '../handlerModels/GetNewProgramChangeRequestStep12Handler';
import * as GetNewProgramChangeRequestStep13Handler from '../handlerModels/GetNewProgramChangeRequestStep13Handler';
import * as GetNewProgramChangeRequestStep14Handler from '../handlerModels/GetNewProgramChangeRequestStep14Handler';
import * as GetNewProgramChangeRequestStep15Handler from '../handlerModels/GetNewProgramChangeRequestStep15Handler';
import * as RemoveAwardFromNewProgramProgramHandler from '../handlerModels/RemoveAwardFromNewProgramProgramHandler';
import * as ValidateNewProgramChangeRequestStep11Handler from '../handlerModels/ValidateNewProgramChangeRequestStep11Handler';

export class NewProgramChangeRequestApiService extends BaseApi {

    // post: api/NewProgramChangeRequests/saveStep1
    public saveStep1(saveNewProgramChangeRequestStep1: SaveNewProgramChangeRequestStep1Handler.Request): Promise<SaveNewProgramChangeRequestStep1Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep1Handler.Result>(saveNewProgramChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep2
    public saveStep2(saveNewProgramChangeRequestStep2: SaveNewProgramChangeRequestStep2Handler.Request): Promise<SaveNewProgramChangeRequestStep2Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep2Handler.Result>(saveNewProgramChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep3
    public saveStep3(saveNewProgramChangeRequestStep3: SaveNewProgramChangeRequestStep3Handler.Request): Promise<SaveNewProgramChangeRequestStep3Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep3`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep3Handler.Result>(saveNewProgramChangeRequestStep3, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep4
    public saveStep4(saveNewProgramChangeRequestStep4: SaveNewProgramChangeRequestStep4Handler.Request): Promise<SaveNewProgramChangeRequestStep4Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep4Handler.Result>(saveNewProgramChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep5
    public saveStep5(saveNewProgramChangeRequestStep5: SaveNewProgramChangeRequestStep5Handler.Request): Promise<SaveNewProgramChangeRequestStep5Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep5`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep5Handler.Result>(saveNewProgramChangeRequestStep5, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep6
    public saveStep6(saveNewProgramChangeRequestStep6: FormData): Promise<SaveNewProgramChangeRequestStep6Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep6`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep6Handler.Result>(saveNewProgramChangeRequestStep6, 'post', url, false, false);
    }

    // post: api/NewProgramChangeRequests/saveStep7
    public saveStep7(saveNewProgramChangeRequestStep7: FormData): Promise<SaveNewProgramChangeRequestStep7Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep7`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep7Handler.Result>(saveNewProgramChangeRequestStep7, 'post', url, false, false);
    }

    // post: api/NewProgramChangeRequests/saveStep8
    public saveStep8(saveNewProgramChangeRequestStep8: SaveNewProgramChangeRequestStep8Handler.Request): Promise<SaveNewProgramChangeRequestStep8Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep8`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep8Handler.Result>(saveNewProgramChangeRequestStep8, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep9
    public saveStep9(saveNewProgramChangeRequestStep9: SaveNewProgramChangeRequestStep10Handler.Request): Promise<SaveNewProgramChangeRequestStep10Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep9`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep10Handler.Result>(saveNewProgramChangeRequestStep9, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep10
    public saveStep10(saveNewProgramChangeRequestStep10: SaveNewProgramChangeRequestStep10Handler.Request): Promise<SaveNewProgramChangeRequestStep10Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep10`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep10Handler.Result>(saveNewProgramChangeRequestStep10, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep11
    public saveStep11(saveNewProgramChangeRequestStep11: SaveNewProgramChangeRequestStep11Handler.Request): Promise<SaveNewProgramChangeRequestStep11Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep11`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep11Handler.Result>(saveNewProgramChangeRequestStep11, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep12
    public saveStep12(saveNewProgramChangeRequestStep12: SaveNewProgramChangeRequestStep12Handler.Request): Promise<SaveNewProgramChangeRequestStep12Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep12`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep12Handler.Result>(saveNewProgramChangeRequestStep12, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep13
    public saveStep13(saveNewProgramChangeRequestStep13: SaveNewProgramChangeRequestStep13Handler.Request): Promise<SaveNewProgramChangeRequestStep13Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep13`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep13Handler.Result>(saveNewProgramChangeRequestStep13, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/saveStep14
    public saveStep14(saveNewProgramChangeRequestStep14: SaveNewProgramChangeRequestStep14Handler.Request): Promise<SaveNewProgramChangeRequestStep14Handler.Result> {
        let url = `api/NewProgramChangeRequests/saveStep14`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveNewProgramChangeRequestStep14Handler.Result>(saveNewProgramChangeRequestStep14, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep1
    public submitStep1(submitNewProgramChangeRequestStep1: SubmitNewProgramChangeRequestStep1Handler.Request): Promise<SubmitNewProgramChangeRequestStep1Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep1Handler.Result>(submitNewProgramChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep2
    public submitStep2(submitNewProgramChangeRequestStep2: SubmitNewProgramChangeRequestStep2Handler.Request): Promise<SubmitNewProgramChangeRequestStep2Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep2Handler.Result>(submitNewProgramChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep3
    public submitStep3(submitNewProgramChangeRequestStep3: SubmitNewProgramChangeRequestStep3Handler.Request): Promise<SubmitNewProgramChangeRequestStep3Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep3`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep3Handler.Result>(submitNewProgramChangeRequestStep3, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep4
    public submitStep4(submitNewProgramChangeRequestStep4: SubmitNewProgramChangeRequestStep4Handler.Request): Promise<SubmitNewProgramChangeRequestStep4Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep4Handler.Result>(submitNewProgramChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep5
    public submitStep5(submitNewProgramChangeRequestStep5: SubmitNewProgramChangeRequestStep5Handler.Request): Promise<SubmitNewProgramChangeRequestStep5Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep5`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep5Handler.Result>(submitNewProgramChangeRequestStep5, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep6
    public submitStep6(submitNewProgramChangeRequestStep6: FormData): Promise<SubmitNewProgramChangeRequestStep6Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep6`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep6Handler.Result>(submitNewProgramChangeRequestStep6, 'post', url, false, false);
    }

    // post: api/NewProgramChangeRequests/submitStep7
    public submitStep7(submitNewProgramChangeRequestStep7: FormData): Promise<SubmitNewProgramChangeRequestStep7Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep7`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep7Handler.Result>(submitNewProgramChangeRequestStep7, 'post', url, false, false);
    }

    // post: api/NewProgramChangeRequests/submitStep8
    public submitStep8(submitNewProgramChangeRequestStep8: SubmitNewProgramChangeRequestStep8Handler.Request): Promise<SubmitNewProgramChangeRequestStep8Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep8`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep8Handler.Result>(submitNewProgramChangeRequestStep8, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep9
    public submitStep9(submitNewProgramChangeRequestStep9: SubmitNewProgramChangeRequestStep9Handler.Request): Promise<SubmitNewProgramChangeRequestStep9Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep9`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep9Handler.Result>(submitNewProgramChangeRequestStep9, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep10
    public submitStep10(submitNewProgramChangeRequestStep10: SubmitNewProgramChangeRequestStep10Handler.Request): Promise<SubmitNewProgramChangeRequestStep10Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep10`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep10Handler.Result>(submitNewProgramChangeRequestStep10, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep11
    public submitStep11(submitNewProgramChangeRequestStep11: SubmitNewProgramChangeRequestStep11Handler.Request): Promise<SubmitNewProgramChangeRequestStep11Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep11`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep11Handler.Result>(submitNewProgramChangeRequestStep11, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep12
    public submitStep12(submitNewProgramChangeRequestStep12: SubmitNewProgramChangeRequestStep12Handler.Request): Promise<SubmitNewProgramChangeRequestStep12Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep12`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep12Handler.Result>(submitNewProgramChangeRequestStep12, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep13
    public submitStep13(submitNewProgramChangeRequestStep13: SubmitNewProgramChangeRequestStep13Handler.Request): Promise<SubmitNewProgramChangeRequestStep13Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep13`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep13Handler.Result>(submitNewProgramChangeRequestStep13, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/submitStep14
    public submitStep14(submitNewProgramChangeRequestStep14: SubmitNewProgramChangeRequestStep14Handler.Request): Promise<SubmitNewProgramChangeRequestStep14Handler.Result> {
        let url = `api/NewProgramChangeRequests/submitStep14`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SubmitNewProgramChangeRequestStep14Handler.Result>(submitNewProgramChangeRequestStep14, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/checkNOIApproval
    public checkNOIApproval(checkNOIApproval: CheckNOIApprovalHandler.Request): Promise<CheckNOIApprovalHandler.Result> {
        let url = `api/NewProgramChangeRequests/checkNOIApproval`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, CheckNOIApprovalHandler.Result>(checkNOIApproval, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/get
    public get(getNewProgramChangeRequest: GetNewProgramChangeRequestHandler.Request): Promise<GetNewProgramChangeRequestHandler.Result> {
        let url = `api/NewProgramChangeRequests/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestHandler.Result>(getNewProgramChangeRequest, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep1
    public getStep1(getNewProgramChangeRequestStep1: GetNewProgramChangeRequestStep1Handler.Request): Promise<GetNewProgramChangeRequestStep1Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep1`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep1Handler.Result>(getNewProgramChangeRequestStep1, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep2
    public getStep2(getNewProgramChangeRequestStep2: GetNewProgramChangeRequestStep2Handler.Request): Promise<GetNewProgramChangeRequestStep2Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep2`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep2Handler.Result>(getNewProgramChangeRequestStep2, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep3
    public getStep3(getNewProgramChangeRequestStep3: GetNewProgramChangeRequestStep3Handler.Request): Promise<GetNewProgramChangeRequestStep3Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep3`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep3Handler.Result>(getNewProgramChangeRequestStep3, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep4
    public getStep4(getNewProgramChangeRequestStep4: GetNewProgramChangeRequestStep4Handler.Request): Promise<GetNewProgramChangeRequestStep4Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep4`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep4Handler.Result>(getNewProgramChangeRequestStep4, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep5
    public getStep5(getNewProgramChangeRequestStep5: GetNewProgramChangeRequestStep5Handler.Request): Promise<GetNewProgramChangeRequestStep5Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep5`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep5Handler.Result>(getNewProgramChangeRequestStep5, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep6
    public getStep6(getNewProgramChangeRequestStep6: GetNewProgramChangeRequestStep6Handler.Request): Promise<GetNewProgramChangeRequestStep6Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep6`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep6Handler.Result>(getNewProgramChangeRequestStep6, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep7
    public getStep7(getNewProgramChangeRequestStep7: GetNewProgramChangeRequestStep7Handler.Request): Promise<GetNewProgramChangeRequestStep7Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep7`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep7Handler.Result>(getNewProgramChangeRequestStep7, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep8
    public getStep8(getNewProgramChangeRequestStep8: GetNewProgramChangeRequestStep8Handler.Request): Promise<GetNewProgramChangeRequestStep8Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep8`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep8Handler.Result>(getNewProgramChangeRequestStep8, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep9
    public getStep9(getNewProgramChangeRequestStep9: GetNewProgramChangeRequestStep9Handler.Request): Promise<GetNewProgramChangeRequestStep9Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep9`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep9Handler.Result>(getNewProgramChangeRequestStep9, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep10
    public getStep10(getNewProgramChangeRequestStep10: GetNewProgramChangeRequestStep10Handler.Request): Promise<GetNewProgramChangeRequestStep10Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep10`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep10Handler.Result>(getNewProgramChangeRequestStep10, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep11
    public getStep11(getNewProgramChangeRequestStep11: GetNewProgramChangeRequestStep11Handler.Request): Promise<GetNewProgramChangeRequestStep11Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep11`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep11Handler.Result>(getNewProgramChangeRequestStep11, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep11Term
    public getStep11Term(getNewProgramChangeRequestStep11Term: GetNewProgramChangeRequestStep11TermHandler.Request): Promise<GetNewProgramChangeRequestStep11TermHandler.Result> {
        let url = `api/NewProgramChangeRequests/getStep11Term`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep11TermHandler.Result>(getNewProgramChangeRequestStep11Term, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep12
    public getStep12(getNewProgramChangeRequestStep12: GetNewProgramChangeRequestStep12Handler.Request): Promise<GetNewProgramChangeRequestStep12Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep12`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep12Handler.Result>(getNewProgramChangeRequestStep12, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep13
    public getStep13(getNewProgramChangeRequestStep13: GetNewProgramChangeRequestStep13Handler.Request): Promise<GetNewProgramChangeRequestStep13Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep13`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep13Handler.Result>(getNewProgramChangeRequestStep13, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep14
    public getStep14(getNewProgramChangeRequestStep14: GetNewProgramChangeRequestStep14Handler.Request): Promise<GetNewProgramChangeRequestStep14Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep14`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep14Handler.Result>(getNewProgramChangeRequestStep14, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/getStep15
    public getStep15(getNewProgramChangeRequestStep15: GetNewProgramChangeRequestStep15Handler.Request): Promise<GetNewProgramChangeRequestStep15Handler.Result> {
        let url = `api/NewProgramChangeRequests/getStep15`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetNewProgramChangeRequestStep15Handler.Result>(getNewProgramChangeRequestStep15, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/removeAwardFromNewProgramProgramHandler
    public removeAwardFromNewProgramProgram(removeAwardFromNewProgramProgram: RemoveAwardFromNewProgramProgramHandler.Request): Promise<RemoveAwardFromNewProgramProgramHandler.Result> {
        let url = `api/NewProgramChangeRequests/removeAwardFromNewProgramProgramHandler`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RemoveAwardFromNewProgramProgramHandler.Result>(removeAwardFromNewProgramProgram, 'post', url, true, false);
    }

    // post: api/NewProgramChangeRequests/validateNewProgramChangeRequestStep11
    public validateNewProgramChangeRequestStep11(validateNewProgramChangeRequestStep11: ValidateNewProgramChangeRequestStep11Handler.Request): Promise<ValidateNewProgramChangeRequestStep11Handler.Result> {
        let url = `api/NewProgramChangeRequests/validateNewProgramChangeRequestStep11`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ValidateNewProgramChangeRequestStep11Handler.Result>(validateNewProgramChangeRequestStep11, 'post', url, true, false);
    }

    // get: api/NewProgramChangeRequests/draftAS28Report/${encodeURIComponent(changeRequestId)}
    public draftAS28Report(changeRequestId: string): Promise<any> {
        let url = `api/NewProgramChangeRequests/draftAS28Report/${encodeURIComponent(changeRequestId)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new NewProgramChangeRequestApiService();
export default service;
