// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveCipDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import CipNumberDetailDTO from '../models/CipNumberDetailDTO';
import CipNumberDTO from '../models/CipNumberDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    cipId: string | null | null;
    cip: CipNumberDetailDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            cipId: null,
            cip: null,
        },
        initValues);
    },
    cipId: 'cipId',
    cip: 'cip',
}

interface Result extends ResultDTO {
    cip: CipNumberDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            cip: null,
        },
        initValues);
    },
    cip: 'cip',
}

export { Request, Result };