import { Card, Descriptions, Space, Tabs } from 'antd';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AuthorizedContent from '../../components/AuthorizedContent';
import Breadcrumbs from '../../config/Breadcrumbs';
import Routes from '../../config/Routes';
import FeatureFlag from '../../consts/FeatureFlag';
import LoginDTO from '../../models/LoginDTO';
import HeaderPortal from '../../portals/HeaderPortal';
import HistoryUtil from '../../utils/HistoryUtil';
import ParameterUtil from '../../utils/ParameterUtil';
interface ConfigurationPageState {
  model: LoginDTO;
  activeTab: string;
}

interface ConfigurationPageProps {
}

class ConfigurationPage extends React.Component<ConfigurationPageProps & RouteComponentProps<RouteObject>, ConfigurationPageState> {
  constructor(props: ConfigurationPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      model: LoginDTO.create(),
      activeTab: 'system'
    };
  }

  componentDidMount = () => {
    const activeTab = ParameterUtil.getParameter('activeTab', 'system');
    this.setState({ activeTab: activeTab });
  }

  private changeTab = (activeTab: string) => {
    this.setState({ activeTab: activeTab });
    HistoryUtil.replace(Routes.generate('', {}, { activeTab: activeTab }), { activeTab: activeTab });
  }

  render() {
    return (
      <Card>
        <HeaderPortal
          title='Configuration'
          breadcrumbs={Breadcrumbs.configuration()}
          footer={
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab} >
              <Tabs.TabPane tab="System" key="system" />
              <Tabs.TabPane tab="Secondary" key="secondary" />
              <Tabs.TabPane tab="Post-Secondary" key="postSecondary" />
            </Tabs>
          } />

        {this.renderSystemSection()}

        {this.renderSecondary()}

        {this.renderPostSecondary()}

        <Space size="small" direction="vertical">


        </Space>
      </Card >
    );
  }

  renderSecondary() {
    if (this.state.activeTab == 'secondary') {
      return (
        <Descriptions title="Secondary">
          <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_REGIONAL_PLANNING_PARTNER]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.REGIONAL_PLANNING_PARTNERS, {}, { configuration: true })} >Region</Link>
            </Descriptions.Item>
          </AuthorizedContent>

          <AuthorizedContent validFeatureFlags={[FeatureFlag.CONFIG_ACADEMIC_YEAR]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.SECONDARY_CIP_NUMBERS_CONFIGURATION)} >CIP Number</Link>
            </Descriptions.Item>
          </AuthorizedContent>

          <AuthorizedContent validFeatureFlags={[FeatureFlag.CONFIG_ACADEMIC_YEAR]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.SCENARIO_CONFIGURATION)} >Scenario</Link>
            </Descriptions.Item>
          </AuthorizedContent>

          <AuthorizedContent validFeatureFlags={[FeatureFlag.CONFIG_ACADEMIC_YEAR]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.FEDERAL_CLUSTERS_CONFIGURATION)} >Federal Cluster</Link>
            </Descriptions.Item>
          </AuthorizedContent>

          <AuthorizedContent validFeatureFlags={[FeatureFlag.CONFIG_ACADEMIC_YEAR]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.SERVICE_AREA_CONFIGURATION)} >Service Area</Link>
            </Descriptions.Item>
          </AuthorizedContent>

          <AuthorizedContent validFeatureFlags={[FeatureFlag.CONFIG_ACADEMIC_YEAR]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.AREA_EDUCATION_AGENCIES_CONFIGURATION)} >Area Education Agency</Link>
            </Descriptions.Item>
          </AuthorizedContent>
        </Descriptions>
      );
    }
  }

  renderSystemSection() {
    if (this.state.activeTab == 'system') {
      return (
        <Descriptions title="System" >
          <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_GENDER, FeatureFlag.EDIT_GENDER]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.GENDER)} >Genders</Link>
            </Descriptions.Item>
          </AuthorizedContent>

          <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_ROLE, FeatureFlag.EDIT_ROLE]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.ROLES)} >Roles</Link>
            </Descriptions.Item>
          </AuthorizedContent>

          <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_RACIAL_EHTNIC_GROUPS, FeatureFlag.EDIT_RACIAL_EHTNIC_GROUPS]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.RACIAL_ETHNIC_GROUPS)} >Racial/Ethnic Groups</Link>
            </Descriptions.Item>
          </AuthorizedContent>

          <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_THIRD_PARTY_CREDENTIAL_TYPE, FeatureFlag.EDIT_THIRD_PARTY_CREDENTIAL_TYPE]}>
            <Descriptions.Item>
              <Link to={Routes.generate(Routes.THIRD_PARTY_CREDENTIAL_TYPES)} >Third Party Credential Type</Link>
            </Descriptions.Item>
          </AuthorizedContent>
        </Descriptions>
      );
    }
  }

  renderPostSecondary() {
    if (this.state.activeTab == 'postSecondary') {
      return (
        <>
          <Descriptions title="Post-Secondary">
            <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_CIP_NUMBER, FeatureFlag.EDIT_CIP_NUMBER]}>
              <Descriptions.Item>
                <Link to={Routes.generate(Routes.CIPS)} >CIP Numbers</Link>
              </Descriptions.Item>
            </AuthorizedContent>

            <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_DISCIPLINE, FeatureFlag.EDIT_DISCIPLINE]}>
              <Descriptions.Item>
                <Link to={Routes.generate(Routes.DISCIPLINES)} >Disciplines</Link>
              </Descriptions.Item>
            </AuthorizedContent>

            <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_WORKFLOW]}>
              <Descriptions.Item>
                <Link to={Routes.generate(Routes.CHANGE_REQUEST_TYPE)} >Change Request Types</Link>
              </Descriptions.Item>
            </AuthorizedContent>

            <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_EXTERNAL_INSTITUTIONS, FeatureFlag.EDIT_EXTERNAL_INSTITUTIONS]}>
              <Descriptions.Item>
                <Link to={Routes.generate(Routes.EXTERNAL_INSTITUTIONS)} >External Institutions</Link>
              </Descriptions.Item>
            </AuthorizedContent>
          </Descriptions>

          <Descriptions title="Links">
            <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_CIP_NUMBER, FeatureFlag.EDIT_CIP_NUMBER]}>
              <Descriptions.Item>
                <Link to={Routes.generate(Routes.NOTICE_OF_INTENT_CHANGE_REQUEST_WORKFORCE_LINK)} >Workforce Informational Links</Link>
              </Descriptions.Item>
            </AuthorizedContent>
          </Descriptions>

          {this.renderItsoSection()}

          {this.renderLookupsSection()}

          {this.renderTransferMajorsSection()}
        </>
      );
    }
  }

  renderItsoSection() {
    return (
      <Descriptions title="ITSO">
        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_INSTRUCTIONAL_LEVEL, FeatureFlag.EDIT_INSTRUCTIONAL_LEVEL]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.INSTRUCTIONAL_LEVEL)} >Instructional Levels</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_TYPE_OF_PROGRAM, FeatureFlag.EDIT_TYPE_OF_PROGRAM]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.TYPE_OF_PROGRAM)} >Types Of Programs</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_SPECIAL_EMPHASIS, FeatureFlag.EDIT_SPECIAL_EMPHASIS]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.SPECIAL_EMPHASIS)} >Special Emphases</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_OBJECT_PURPOSE, FeatureFlag.EDIT_OBJECT_PURPOSE]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.OBJECT_PURPOSE)} >Object Purposes</Link>
          </Descriptions.Item>
        </AuthorizedContent>
      </Descriptions>
    );
  }

  renderLookupsSection() {
    return (
      <Descriptions title="Lookups" >
        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_APS_GENERAL_EDUCATION, FeatureFlag.EDIT_APS_GENERAL_EDUCATION]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.APS_GENERAL_EDUCATION)} >APS General Education Categories</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_AWARD_TYPE, FeatureFlag.EDIT_AWARD_TYPE]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.AWARD_TYPE)} >Award Types</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_CATEGORY, FeatureFlag.EDIT_CATEGORY]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.CATEGORIES)} >Categories</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_CONTACT_TYPE, FeatureFlag.EDIT_CONTACT_TYPE]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.CONTACT_TYPE)} >Contact Types</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_CREDIT_TYPE, FeatureFlag.EDIT_CREDIT_TYPE]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.CREDIT_TYPES)} >Credit Types</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_GENERAL_EDUCATION, FeatureFlag.EDIT_GENERAL_EDUCATION]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.GENERAL_EDUCATION)} >General Education Categories</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_IOWA_VARIATION, FeatureFlag.EDIT_IOWA_VARIATION]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.IOWA_VARIATION)} >Iowa Variations</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_TERM, FeatureFlag.EDIT_TERM]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.TERMS)} >Terms</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_WORK_BASED_LEARNING, FeatureFlag.EDIT_WORK_BASED_LEARNING]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.WORK_BASED_LEARNING_TYPE)} >Work Based Learning Types</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_PROGRAM_CLASSIFICATION, FeatureFlag.EDIT_PROGRAM_CLASSIFICATION]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.PROGRAM_CLASSIFICATION)} >Program Classifications</Link>
          </Descriptions.Item>
        </AuthorizedContent>
      </Descriptions>
    );
  }

  renderTransferMajorsSection() {
    return (
      <Descriptions title="Transfer Major Content">
        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_TRANSFER_MAJOR_COURSE_BLOCKS, FeatureFlag.EDIT_TRANSFER_MAJOR_COURSE_BLOCKS]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.TRANSFER_MAJOR_COURSE_BLOCK)} >Course Blocks</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_TRANSFER_MAJOR_LACTS_CATEGORY, FeatureFlag.EDIT_TRANSFER_MAJOR_LACTS_CATEGORY]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.TRANSFER_MAJOR_LACTS_CATEGORY)} >LACTS Categories</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS, FeatureFlag.EDIT_TRANSFER_MAJOR_DISCIPLINE_FRAMEWORKS]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.TRANSFER_MAJOR_DISCIPLINE_FRAMEWORK_ELEMENT)} >Discipline Framework Elements</Link>
          </Descriptions.Item>
        </AuthorizedContent>

        <AuthorizedContent validFeatureFlags={[FeatureFlag.VIEW_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE, FeatureFlag.EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE]}>
          <Descriptions.Item>
            <Link to={Routes.generate(Routes.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE)} >General Education Categories</Link>
          </Descriptions.Item>
        </AuthorizedContent>
      </Descriptions>
    );
  }
}

export default withRouter(ConfigurationPage);