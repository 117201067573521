import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, message, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TransferMajorGeneralEducationTypeApiService from '../../../../api/TransferMajorGeneralEducationTypeApiService';
import AuthorizedContent from '../../../../components/AuthorizedContent';
import TransferMajorGeneralEducationTypeDetailsForm from '../../../../components/forms/postSecondary/TransferMajorGeneralEducationTypeDetailsForm';
import EditCancelButton from '../../../../components/buttons/EditCancelButton';
import Breadcrumbs from '../../../../config/Breadcrumbs';
import Routes from '../../../../config/Routes';
import FeatureFlag from '../../../../consts/FeatureFlag';
import * as DeleteTransferMajorGeneralEducationTypeHandler from '../../../../handlerModels/DeleteTransferMajorGeneralEducationTypeHandler';
import * as GetTransferMajorGeneralEducationTypeDetailsHandler from '../../../../handlerModels/GetTransferMajorGeneralEducationTypeDetailsHandler';
import TransferMajorGeneralEducationTypeDTO from '../../../../models/TransferMajorGeneralEducationTypeDTO';
import HeaderPortal from '../../../../portals/HeaderPortal';
import BaseFormState from '../../../../redux/bases/BaseFormState';
import HistoryUtil from '../../../../utils/HistoryUtil';
import PageStayPrompt from '../../../../utils/PageStayPrompt';
import ParameterUtil from '../../../../utils/ParameterUtil';

interface TransferMajorGeneralEducationTypeDetailsPageState extends BaseFormState {
  transferMajorGeneralEducationTypeId: number;
  transferMajorGeneralEducationType?: TransferMajorGeneralEducationTypeDTO;
}

interface TransferMajorGeneralEducationTypeDetailsPageProps {
}

class TransferMajorGeneralEducationTypeDetailsPage extends React.Component<TransferMajorGeneralEducationTypeDetailsPageProps & RouteComponentProps<RouteObject>, TransferMajorGeneralEducationTypeDetailsPageState> {
  private readonly _TransferMajorGeneralEducationTypeDetailsRef = React.createRef<TransferMajorGeneralEducationTypeDetailsForm>();

  constructor(props: TransferMajorGeneralEducationTypeDetailsPageProps & RouteComponentProps<RouteObject>) {
    super(props);

    this.state = {
      transferMajorGeneralEducationTypeId: 0
    };
  }

  componentDidMount() {
    const TransferMajorGeneralEducationTypeId = ParameterUtil.getPathPart(this.props.match, 'id');

    if (TransferMajorGeneralEducationTypeId == 'new') {
      this.setState({ isEditing: true, isNew: true, loading: false })
    }
    else {
      const TransferMajorGeneralEducationTypeIdNumber = Number.parseInt(TransferMajorGeneralEducationTypeId);
      this.loadTransferMajorGeneralEducationType(TransferMajorGeneralEducationTypeIdNumber);
    }
  }

  private loadTransferMajorGeneralEducationType = (transferMajorGeneralEducationTypeId: number) => {
    this.setState({ transferMajorGeneralEducationTypeId: transferMajorGeneralEducationTypeId });

    TransferMajorGeneralEducationTypeApiService.getTransferMajorGeneralEducationTypeDetails(transferMajorGeneralEducationTypeId)
      .then((results: GetTransferMajorGeneralEducationTypeDetailsHandler.Result) => {
        if (results.transferMajorGeneralEducationType) {
          this.setState({ transferMajorGeneralEducationType: results.transferMajorGeneralEducationType });
        }
      }).catch(() => {
        this.setState({ error: true });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  private deleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this General Education Type?',
      okText: 'Delete',
      onOk: () => this.deleteTransferMajorGeneralEducationType(id)
    });
  }

  private deleteTransferMajorGeneralEducationType = (transferMajorGeneralEducationTypeId: number) => {
    this.setState({ submitting: true });

    const request = DeleteTransferMajorGeneralEducationTypeHandler.Request.create({
      transferMajorGeneralEducationTypeId: transferMajorGeneralEducationTypeId,
    });

    TransferMajorGeneralEducationTypeApiService.deleteTransferMajorGeneralEducationType(request)
      .then((result: DeleteTransferMajorGeneralEducationTypeHandler.Result | null) => {
        this.setState({
          error: !result?.succeeded,
          message: result?.errors.join('\n'),
          fieldErrors: result?.fieldErrors,
          altered: !result?.succeeded
        });

        if (result?.succeeded === true) {
          message.success('General Education Type was removed.');
        }
        else {
          message.error(result?.errors.join('\n'));
        }

      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('General Education Type could not be removed.');
      })
      .finally(() => {
        this.setState({ loading: false });
        this.goToTransferMajorGeneralEducationType();
      });
  }

  private toggleEdit = () => {
    if (this.state.isEditing) {
      this._TransferMajorGeneralEducationTypeDetailsRef.current?.resetForm();
    }

    this.setState({ isEditing: !this.state.isEditing });
  }

  private TransferMajorGeneralEducationTypeDetailsSaved = (transferMajorGeneralEducationTypeId: number) => {
    this.setState({ isEditing: false });
    if (transferMajorGeneralEducationTypeId != this.state.transferMajorGeneralEducationTypeId) {
      HistoryUtil.replace(Routes.generate(Routes.TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE_DETAILS, { id: transferMajorGeneralEducationTypeId }));
    }
    else {
      this.loadTransferMajorGeneralEducationType(transferMajorGeneralEducationTypeId);
    }
  }

  private goToTransferMajorGeneralEducationType = () => {
    HistoryUtil.goBack();
  }

  render() {
    const title = this.state.loading ? '...' : this.state.transferMajorGeneralEducationTypeId == 0 ? 'New General Education Type' : this.state.transferMajorGeneralEducationType?.name ?? '...';

    return (
      <Content >
        <HeaderPortal
          title={title}
          onBack={this.goToTransferMajorGeneralEducationType}
          breadcrumbs={Breadcrumbs.transferMajorGeneralEducationTypeDetails(title, this.state.transferMajorGeneralEducationTypeId)}
          extra={this.renderMoreActions()} />
        <PageStayPrompt when={this.state.isEditing} />

        {this.renderDetailsCard()}
      </Content>
    );
  }

  renderDetailsCard() {
    const editButton = this.state.transferMajorGeneralEducationTypeId == 0 ?
      null :
      (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE]}>
          <EditCancelButton isEditing={this.state.isEditing} onClick={this.toggleEdit} />
        </AuthorizedContent>
      );

    return (
      <Card title="Details" extra={editButton}>
        <TransferMajorGeneralEducationTypeDetailsForm
          ref={this._TransferMajorGeneralEducationTypeDetailsRef}
          transferMajorGeneralEducationTypeId={this.state.transferMajorGeneralEducationTypeId}
          isEditing={this.state.isEditing}
          isNew={this.state.isNew}
          onSave={this.TransferMajorGeneralEducationTypeDetailsSaved} />
      </Card>
    );
  }

  renderMoreActions() {
    const menu = (
      <Menu>
        < Menu.Item title='Delete General Education Type' key={this.state.transferMajorGeneralEducationTypeId} onClick={() => this.deleteConfirmation(this.state.transferMajorGeneralEducationTypeId)}  >
          Delete General Education Type
        </Menu.Item>
      </Menu>
    );
    return (
      <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_TRANSFER_MAJOR_GENERAL_EDUCATION_TYPE]}>
        <Dropdown overlay={menu}>
          <Button size='large' type='link' >
            <MoreOutlined />
          </Button>
        </Dropdown>
      </AuthorizedContent>
    );
  }
}

export default withRouter(TransferMajorGeneralEducationTypeDetailsPage);
