import { Alert } from 'antd';
import { useEffect, useState } from 'react';
import * as React from 'react';
import RegionalPlanningPartnerApiService from '../../../api/RegionalPlanningPartnerApiService';
import SecondaryProgramApiService from '../../../api/SecondaryProgramApiService';
import ThirdPartyCredentialTypeApiService from '../../../api/ThirdPartyCredentialTypeApiService';
import Routes from '../../../config/Routes';
import Requirements from '../../../consts/Requirements';
import ProgramMasterReportDataTableProviderDTO from '../../../models/ProgramMasterReportDataTableProviderDTO';
import RegionalPlanningPartnerDTO from '../../../models/RegionalPlanningPartnerDTO';
import TableFilterUtil, { TableFilterOption } from '../../../utils/TableFilterUtil';
import DataTable, { FilterType } from '../core/DataTable';
import DataTableButtonUtil from '../core/DataTableButtonUtil';
import TableRequestDTO from '../core/models/TableRequestDTO';
import AreaEducationAgencyApiService from '../../../api/AreaEducationAgencyApiService';
import AreaEducationAgencyDTO from '../../../models/AreaEducationAgencyDTO';

interface ProgramMasterReportDataTableProps {
  academicYear: number;
  fromSelfStudies?: boolean;
  fromAcademicYearDataReview?: boolean;
  isPublic?: boolean;
  districtId?: string;
  errors?: string[];
  configuration?: boolean;
}

function ProgramMasterReportDataTable(props: ProgramMasterReportDataTableProps) {
  const [errors] = useState(props.errors);
  const [rpps, setRPPs] = useState<RegionalPlanningPartnerDTO[]>([]);
  const [aeas, setAEAs] = useState<AreaEducationAgencyDTO[]>([]);
  const [institutions, setInstitutions] = useState<TableFilterOption[]>([]);
  const [thirdPartyMax, setThirdPartyMax] = useState(0);

  useEffect(() => {
    if (!isNaN(props.academicYear)) {
      RegionalPlanningPartnerApiService.getRegionalPlanningPartners(props.academicYear).then((x) => {
        setRPPs(x.regions ?? []);
      });
      ThirdPartyCredentialTypeApiService.getMaxSecondaryThirdPartyCredentials(props.academicYear).then((x) => {
        setThirdPartyMax(x.maxThirdParty);
      });
      AreaEducationAgencyApiService.getAreaEducationAgencies(props.academicYear)
      .then(x => {
        setAEAs(x.areaEducationAgency ?? []);
      });

      TableFilterUtil.Institutions().then(x => setInstitutions(x));
    }
  }, [props.academicYear]);

  const getColumnDefinitions = () => {
    const columns: any = [];

    columns.pushAll([
      {
        title: 'Identifier',
        dataIndex: ProgramMasterReportDataTableProviderDTO.identifier,
        sorter: true,
        width: 50,
        filterType: FilterType.Text,
      },
      {
        title: 'Secondary CTE CIP ID',
        dataIndex: ProgramMasterReportDataTableProviderDTO.secondaryCipNumber,
        sorter: true,
        render: (data: string, row: ProgramMasterReportDataTableProviderDTO) => {
          return row.secondaryCipNumber;
        },
        filterType: FilterType.Text,
      },
      {
        title: 'CTE Program Name',
        dataIndex: ProgramMasterReportDataTableProviderDTO.title,
        sorter: true,
        render: (data: string, row: ProgramMasterReportDataTableProviderDTO) => {
          return row.title;
        },
        filterType: FilterType.Text,
      },
      {
        title: 'District Id',
        dataIndex: ProgramMasterReportDataTableProviderDTO.districtIdentifier,
        sorter: true,
        render: (data: string, row: ProgramMasterReportDataTableProviderDTO) => {
          return row.districtIdentifier;
        },
        filterType: FilterType.Text,
      },
      {
        title: 'District Name',
        dataIndex: ProgramMasterReportDataTableProviderDTO.districtName,
        sorter: true,
        render: (data: string, row: ProgramMasterReportDataTableProviderDTO) => {
          return row.districtName;
        },
        filterType: FilterType.Text,
      },
      {
        title: 'AEA',
        dataIndex: ProgramMasterReportDataTableProviderDTO.aea,
        sorter: true,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: aeas.map(c => { return { text: c.areaEducationAgencyIdentifier + ' - ' + c.name, value: c.areaEducationAgencyIdentifier.toString() } }) as TableFilterOption[],
        render: (data: string, row: ProgramMasterReportDataTableProviderDTO) => {
          return row.aea;
        },
      },
      {
        title: 'Community College',
        dataIndex: ProgramMasterReportDataTableProviderDTO.communityCollege,
        sorter: true,
        width: 250,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: institutions,
        render: (data: string, row: ProgramMasterReportDataTableProviderDTO) => {
          return row.communityCollege;
        },
      },
      {
        title: 'Regional Planning Partner',
        dataIndex: ProgramMasterReportDataTableProviderDTO.regionalPlanningPartner,
        sorter: true,
        width: 250,
        filterType: FilterType.DropdownMulti,
        dropdownFilterOptions: rpps.map((c) => {
          return {
            text: c.regionalPlanningPartnerIdentifier + ' - ' + c.name,
            value: c.regionalPlanningPartnerIdentifier.toString(),
          };
        }) as TableFilterOption[],
        render: (data: string, row: ProgramMasterReportDataTableProviderDTO) => {
          return row.regionalPlanningPartner;
        },
      }
    ]);

    if (thirdPartyMax != undefined || (thirdPartyMax != Infinity && thirdPartyMax > 0)) {
      for (let i = 0; i < thirdPartyMax; i++) {
        const count = i + 1;
        columns.pushAll([
          {
            title: 'Third Party Credential ' + count,
            dataIndex: ProgramMasterReportDataTableProviderDTO.secondaryProgramThirdPartyCredential,
            render: (data: string, row: ProgramMasterReportDataTableProviderDTO) => {
              if (
                row.secondaryProgramThirdPartyCredential != null &&
                row.secondaryProgramThirdPartyCredential[i] != null
              ) {
                const thirdParty = row.secondaryProgramThirdPartyCredential[i];
                return thirdParty.title + ' - ' + thirdParty.thirdPartyCredentialType?.name + ' - ' + (thirdParty.requirement == Requirements.REQUIRED ? 'Required' : 'Optional') + ' - ' + thirdParty.organization;
              }
            },
            sorter: false,
            filterType: FilterType.NONE,
          }
        ]);
      }
    }

    return columns;
  };

  const programMasterReport = () => {
    window.open(
      Routes.generateFull(Routes.SELF_STUDY_PROGRAM_MASTER_REPORT, {}, { academicYear: props.academicYear }),
      '_blank'
    );
  };

  const actionButtons = [];
  actionButtons.push(DataTableButtonUtil.Reset());
  actionButtons.push(DataTableButtonUtil.Default('Export', programMasterReport));

  const renderErrors = () => {
    if (errors && errors.length > 0) {
      return <Alert showIcon={true} type="error" description={renderErrorMessages()} message="Error Importing" />;
    }
  };

  const renderErrorMessages = () => {
    if (errors && errors.length > 0) {
      return (
        <>
          {errors.map((e: any) => (
            <p key={e}>{e}</p>
          ))}
        </>
      );
    }
  };

  return (
    <>
      {renderErrors()}
      <DataTable
        serverSide={true}
        tableProps={{
          rowKey: 'id',
          scroll: { x: 500 },
        }}
        globalSearch={true}
        buttonBar={actionButtons}
        columns={getColumnDefinitions()}
        fetchData={{
          fetch: (requestState: TableRequestDTO) =>
            SecondaryProgramApiService.getProgramMasterReportTableData(requestState, props.academicYear),
        }}
        stateSaving={{ enabled: true, tableUniqueKey: 'secondary_program_list' }}
      />
    </>
  );
}

export default ProgramMasterReportDataTable;
