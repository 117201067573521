// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionDTO from './InstitutionDTO';
import ContactTypeDTO from './ContactTypeDTO';
import AzureBlobFileDTO from './AzureBlobFileDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ContactDTO extends EntitySelectorDTO { 
	id: string | null;
	institutionId: string | null | null;
	institution: InstitutionDTO | null;
	contactTypeId: number | null | null;
	contactType: ContactTypeDTO | null;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	phone: string | null;
	title: string | null;
	signatureAzureBlobFileId: string | null | null;
	signatureAzureBlobFile: AzureBlobFileDTO | null;
}

const ContactDTO = {
    className: 'ContactDTO',
    create: (initValues?: Partial<ContactDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			institutionId: null,
			institution: null,
			contactTypeId: null,
			contactType: null,
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			title: "",
			signatureAzureBlobFileId: null,
			signatureAzureBlobFile: null,
        },
        initValues);
    },
	id: 'id',
	institutionId: 'institutionId',
	institution: 'institution',
	contactTypeId: 'contactTypeId',
	contactType: 'contactType',
	firstName: 'firstName',
	lastName: 'lastName',
	email: 'email',
	phone: 'phone',
	title: 'title',
	signatureAzureBlobFileId: 'signatureAzureBlobFileId',
	signatureAzureBlobFile: 'signatureAzureBlobFile',
};

export default ContactDTO;