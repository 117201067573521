import { Alert, Input, InputNumber, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import * as React from 'react';
import CipsApiService from '../../../api/CipsApiService';
import NewProgramChangeRequestApiService from '../../../api/NewProgramChangeRequestApiService';
import * as GetActiveCipDetailsHandler from '../../../handlerModels/GetActiveCipDetailsHandler';
import * as SaveNewProgramChangeRequestStep2Handler from '../../../handlerModels/SaveNewProgramChangeRequestStep2Handler';
import * as GetNewProgramChangeRequestStep2Handler from '../../../handlerModels/GetNewProgramChangeRequestStep2Handler';
import ChangeRequestDTO from '../../../models/ChangeRequestDTO';
import CipNumberDetailDTO from '../../../models/CipNumberDetailDTO';
import NewProgramChangeRequestDTO from '../../../models/NewProgramChangeRequestDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import GoBackButton from '../../buttons/GoBackButton';
import ResetButton from '../../buttons/ResetButton';
import SaveAndContinueButton from '../../buttons/SaveAndContinueButton';
import SaveButton from '../../buttons/SaveButton';
import YesNoInput from '../../inputs/YesNoInput';
import Dropdown from '../../inputs/Dropdown';
import Guid from '../../../utils/Guid';
import BaseChangeRequestProps from '../../../redux/bases/BaseChangeRequestProps';

interface NewProgramChangeRequestStep2FormState extends BaseFormState {
  changeRequest: ChangeRequestDTO;
  changeRequests: ChangeRequestDTO[];
  newProgramChangeRequest: NewProgramChangeRequestDTO;
  cipNumberDetails: CipNumberDetailDTO[];
}

interface NewProgramChangeRequestStep2FormProps extends BaseFormProps, BaseChangeRequestProps {
  changeRequestId: string | null;
  selectedInstitution: string | null;
  onSave?: () => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  readonly?: boolean;
}

class NewProgramChangeRequestStep2Form extends React.Component<NewProgramChangeRequestStep2FormProps, NewProgramChangeRequestStep2FormState> {
  private readonly _formRef = React.createRef<FormInstance>();

  private getFormItems = () => {
    const isTransferMajorProgram = this.state.cipNumberDetails.filter(x => x.id == this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.cipNumberId))[0]?.transferMajor ?? false;
    const requiresWorkExperiences = this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.requiresWorkExperiences);
    const cipReclassification = this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.cipReclassification);

    return new Map<string, FormItemProps>()
      .set(NewProgramChangeRequestDTO.cipNumberId, {
        name: NewProgramChangeRequestDTO.cipNumberId,
        label: 'What CIP Number is this request for?',
        required: true,
        rules: [ValidationRuleUtil.required()],
      })
      .set(NewProgramChangeRequestDTO.previousCipNumberId, {
        name: NewProgramChangeRequestDTO.previousCipNumberId,
        label: 'What was the previous CIP Number for the program?',
        required: cipReclassification,
        rules: cipReclassification ? [ValidationRuleUtil.required()] : []
      })
      .set(NewProgramChangeRequestDTO.isTransferMajorProgram, {
        name: NewProgramChangeRequestDTO.isTransferMajorProgram,
        label: 'Is this request for a transfer major program?',
        required: isTransferMajorProgram,
        rules: isTransferMajorProgram ? [ValidationRuleUtil.required()] : []
      })
      .set(NewProgramChangeRequestDTO.needForProgram, {
        required: true,
        name: NewProgramChangeRequestDTO.needForProgram,
        label: 'What is the rationale or need for the program?',
        rules: [ValidationRuleUtil.required(), ValidationRuleUtil.maxLength(5000)]
      })
      .set(NewProgramChangeRequestDTO.anticipatedAnnualEnrollment, {
        required: true,
        name: NewProgramChangeRequestDTO.anticipatedAnnualEnrollment,
        label: 'What is the anticipated annual enrollment for the program?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NewProgramChangeRequestDTO.anticipatedAnnualCompleters, {
        required: true,
        name: NewProgramChangeRequestDTO.anticipatedAnnualCompleters,
        label: 'What is the anticipated annual number of completers for the program?',
        rules: [ValidationRuleUtil.required()]
      })
      .set(NewProgramChangeRequestDTO.cipReclassification, {
        name: NewProgramChangeRequestDTO.cipReclassification,
        label: 'Are you requesting the reclassification of a CIP number for an existing program?',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NewProgramChangeRequestDTO.requiresWorkExperiences, {
        name: NewProgramChangeRequestDTO.requiresWorkExperiences,
        label: 'Does the program include any required work experience and/or training?',
        required: true,
        rules: [ValidationRuleUtil.required()]
      })
      .set(NewProgramChangeRequestDTO.requiredWorkExperiences, {
        name: NewProgramChangeRequestDTO.requiredWorkExperiences,
        label: 'Please describe the required work experiences and/or training needed for this program.',
        required: requiresWorkExperiences,
        rules: requiresWorkExperiences ? [ValidationRuleUtil.required()] : []
      })
  }

  constructor(props: NewProgramChangeRequestStep2FormProps) {
    super(props);

    this.state = {
      cipNumberDetails: [],
      changeRequest: ChangeRequestDTO.create(),
      newProgramChangeRequest: NewProgramChangeRequestDTO.create({
        installTermId: '',
        installYear: DateTimeUtil.getCurrentYear(),
        cipNumberId: null,
        anticipatedAnnualEnrollment: null,
        anticipatedAnnualCompleters: null,
        requiresWorkExperiences: null,
        isTransferMajorProgram: null,
        cipReclassification: null,
      }),
      changeRequests: [],
    };
  }

  componentDidMount() {
    if (this.props.changeRequestId) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: NewProgramChangeRequestStep2FormProps) {
    if (this.props.changeRequestId && this.props.changeRequestId != prevProps.changeRequestId) {
      this.fetchData();
    }

    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });

    const loaders = [];

    if (!this.state.cipNumberDetails || this.state.cipNumberDetails.length == 0) {
      loaders.push(this.loadCipNumberDetails());
    }

    if (this.props.changeRequestId && this.props.changeRequestId != Guid.Empty()) {
      loaders.push(this.loadChangeRequest())
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
    });
  }

  private loadCipNumberDetails = () => {
    return CipsApiService.getActiveCipDetails()
      .then((results: GetActiveCipDetailsHandler.Result) => {
        if (results) {
          this.setState({
            cipNumberDetails: results.cipNumberDetails ?? []
          });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadChangeRequest = () => {
    const request = GetNewProgramChangeRequestStep2Handler.Request.create({
      changeRequestId: this.props.changeRequestId
    });

    return NewProgramChangeRequestApiService.getStep2(request)
      .then((results: GetNewProgramChangeRequestStep2Handler.Result) => {
        if (results) {
          this.setState({
            changeRequest: results.changeRequest ?? ChangeRequestDTO.create(),
            newProgramChangeRequest: results.changeRequest?.newProgramChangeRequest ?? NewProgramChangeRequestDTO.create(),
            fieldErrors: this.props.changeRequestDetailsPage ? null : results.fieldErrors
          }, () => this.resetForm());
        }
      }).catch(() => {
        this.setState({ error: true, message: 'Could not load change request.' });
      });
  }

  public resetForm = () => {
    this.setState({
      altered: false,
    });
    this._formRef.current?.resetFields();
  }

  private handleChange = (altered: boolean) => {
    this.setState({ altered: altered });
  }

  private handleSave = () => {
    this.setState({ saving: true });
    const value = NewProgramChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    let newProgramChangeRequest = this.state.changeRequest.newProgramChangeRequest;
    newProgramChangeRequest = value;
    newProgramChangeRequest.changeRequestId = this.state.changeRequest.id;

    const request = SaveNewProgramChangeRequestStep2Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest
    });

    NewProgramChangeRequestApiService.saveStep2(request)
      .then((result: SaveNewProgramChangeRequestStep2Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          this.setState({
            changeRequest: result.changeRequest ?? ChangeRequestDTO.create(),
          });

          if (this.props.onSave) {
            if (result.changeRequest) {
              this.props.onSave();
            }
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });
    const value = NewProgramChangeRequestDTO.create(this._formRef ? (this._formRef.current as any).getFieldsValue() : null);
    let newProgramChangeRequest = this.state.changeRequest.newProgramChangeRequest;
    newProgramChangeRequest = value;
    newProgramChangeRequest.changeRequestId = this.state.changeRequest.id;

    const request = SaveNewProgramChangeRequestStep2Handler.Request.create({
      newProgramChangeRequest: newProgramChangeRequest
    });

    NewProgramChangeRequestApiService.submitStep2(request)
      .then((result: SaveNewProgramChangeRequestStep2Handler.Result) => {
        if (result?.succeeded) {
          message.success('Saved');

          if (this.props.onSave) {
            this.props.onSave();
          }

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Save Failed');
        }
      })
      .catch((results: any) => {
        this.setState({ error: results });
        message.error('Save Failed');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false, submitted: true });
      });
  }

  render() {
    if (this.state.loading === true || this.props.loading === true || this.state.cipNumberDetails.length === 0) {
      return <Skeleton active={true} />;
    }

    const formItems = this.getFormItems();

    return (
      <Form ref={this._formRef}
        layout="vertical"
        initialValues={this.state.newProgramChangeRequest}
        onValuesChange={this.handleChange}
        onFinish={this.handleSubmit}
        requiredMark={true}>
        <Space direction='vertical'>
          {this.renderErrors()}

          <FormItem
            key={NewProgramChangeRequestDTO.cipNumberId}
            {...formItems.get(NewProgramChangeRequestDTO.cipNumberId)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.cipNumberId, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Dropdown
              showSearch
              disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting}
              optionFilterProp="children">
              {this.state.cipNumberDetails.map(x => { return this.renderCipNumberOption(x) })}
            </Dropdown>
          </FormItem>

          <FormItem
            {...formItems.get(NewProgramChangeRequestDTO.needForProgram)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.needForProgram, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <Input.TextArea disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
          </FormItem>

          <FormItem
            {...formItems.get(NewProgramChangeRequestDTO.anticipatedAnnualEnrollment)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.anticipatedAnnualEnrollment, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <InputNumber disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} min={0} />
          </FormItem>

          <FormItem
            {...formItems.get(NewProgramChangeRequestDTO.anticipatedAnnualCompleters)}
            {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.anticipatedAnnualCompleters, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
            <InputNumber disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} min={0} />
          </FormItem>

          {
            !this.state.newProgramChangeRequest?.isTransferMajorProgram ?
              <FormItem
                {...formItems.get(NewProgramChangeRequestDTO.requiresWorkExperiences)}
                {...ValidationUtil.getValidation(NewProgramChangeRequestDTO.requiresWorkExperiences, this.state.fieldErrors, this.state.submitted || this.props.readonly)}>
                <YesNoInput disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} />
              </FormItem>
              : null
          }

          {
            !this.state.newProgramChangeRequest?.isTransferMajorProgram ?
              this.renderRequiredWorkExperience(formItems)
              : null
          }

          {this.renderActions()}
        </Space>
      </Form>
    );
  }

  private renderActions() {
    if (!this.props.readonly) {
      return < Space direction='horizontal' wrap={true} >
        <GoBackButton onClick={this.props.onPrevious} />
        <SaveAndContinueButton htmlType="submit" submitting={this.state.submitting} />
        <SaveButton type='default' htmlType='button' onClick={this.handleSave} saving={this.state.submitting} saved={this.state.saved}></SaveButton>
        <ResetButton disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} resetting={this.state.resetting} onConfirm={this.resetForm} />
      </Space >
    }
  }

  renderCipNumberOption(x: CipNumberDetailDTO) {
    if (x.cipNumberId && (
      (!this.state.newProgramChangeRequest?.isTransferMajorProgram && x.nonTransferMajor) ||
      (this.state.newProgramChangeRequest?.isTransferMajorProgram && x.transferMajor)
    )
    ) {
      return <Select.Option key={x.cipNumberId} value={x.cipNumberId}>{x.display} - {x.title}</Select.Option>;
    }
  }

  private renderErrors() {
    if (this.state.error) {
      return (
        <Alert showIcon={true} message="Error Saving" type="error" description={this.state.message} />
      );
    }
  }

  private renderRequiredWorkExperience = (formItems: Map<string, FormItemProps>) => {
    const required = this._formRef.current?.getFieldValue(NewProgramChangeRequestDTO.requiresWorkExperiences) ??
      this.state.newProgramChangeRequest?.requiredWorkExperiences ??
      false;
    if (required) {
      return (
        <FormItem
          {...formItems.get(NewProgramChangeRequestDTO.requiredWorkExperiences)}>
          <Input.TextArea disabled={this.props.readonly || this.state.loading || this.state.saving || this.state.submitting} maxLength={5000} showCount={true} autoSize={{ minRows: 5 }} />
        </FormItem >
      );
    }
  }

}

export default NewProgramChangeRequestStep2Form